import { Skeleton } from '@travelpass/design-system'
import {
  experiencesBookingDetailsHeroActionsCss,
  experiencesBookingDetailsHeroContentCss,
  experiencesBookingDetailsHeroCss,
  experiencesBookingDetailsHeroImageCss,
} from './experiencesBookingDetailsHeroStyles'

export const ExperiencesBookingDetailsHeroLoading = () => (
  <div className={experiencesBookingDetailsHeroCss}>
    <div className={experiencesBookingDetailsHeroContentCss}>
      <div className='md:h-38px h-6 md:w-1/2'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-18px md:h-5'>
        <Skeleton variant='rounded' />
      </div>
      <div className={experiencesBookingDetailsHeroImageCss}>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className={experiencesBookingDetailsHeroActionsCss}>
      <div className='w-25 h-10'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
