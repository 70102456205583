import { Button } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'

export const StickyNav = ({
  totalPrice,
  onClick,
}: {
  totalPrice: string
  onClick: (e) => void
}) => {
  return (
    <div className='bg-warmGrey border-b-1 border-b-solid border-grey-300 z-1 fixed left-0 top--6 w-full md:hidden'>
      <PageLayoutContainer>
        <div className='type-h4 flex justify-between px-3 py-8 align-baseline'>
          <div className='flex flex-row space-x-3'>
            <Button
              endIcon='expandLess'
              variant='text'
              onClick={e => onClick(e)}
            >
              Price Summary
            </Button>
          </div>
          <div className='color-newForest'>Total: ${totalPrice}</div>
        </div>
      </PageLayoutContainer>
    </div>
  )
}
