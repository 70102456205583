import { Button, useScreenQuery, useSnackbar } from '@travelpass/design-system'
import { type ExperienceProduct } from 'src/__generated__/graphql'
import { copyToClipboard } from 'src/utils'
import { AddToCollectionsModalListener } from '../Collections/AddToCollectionsModalListener'
import { AddToMenu } from '../Collections/AddToMenu'
import { AddToGuideModalListener } from '../Guides/AddToGuideModalListener'

interface ShareAndSaveButtonsProps {
  product?: ExperienceProduct
  /** ID and Name from a hotel, experience, guide or event. */
  item?: AddToItem
}

export const ShareAndSaveButtons = ({ item }: ShareAndSaveButtonsProps) => {
  const { isMobileScreen } = useScreenQuery()
  const { addSuccessSnack } = useSnackbar()

  const onShare = () => {
    copyToClipboard(document.location.href)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  return (
    <div className='flex gap-4 pb-7 md:pb-8'>
      <AddToMenu item={item} variant='outlined' />
      <Button
        aria-label='Share'
        label='Share'
        size='small'
        startIcon='iosShare'
        variant={isMobileScreen ? 'text' : 'outlined'}
        onClick={onShare}
      />
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </div>
  )
}
