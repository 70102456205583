import type { Note } from 'src/__generated__/graphql'
import { NoteType } from 'src/__generated__/graphql'
import type { OverviewNoteListItem } from '../OverviewNotes'

const initialNote: Note = {
  body: '',
  id: '',
  title: '',
  type: NoteType.FreeText,
}

const initialNoteListItem: OverviewNoteListItem = {
  isChecked: false,
  title: '',
}

export { initialNote, initialNoteListItem }
