enum TripSearchParams {
  activeExploreId = 'activeExploreId',
  dateTab = 'dateTab',
  eventId = 'eventId',
  eventType = 'eventType',
  hoverId = 'hoverId',
  listGuide = 'listGuide',
  mapFullView = 'mapFullView',
  mapExpanded = 'mapExpanded',
  productType = 'productType',
  selectedId = 'selectedId',
  tripSharedBy = 'tripSharedBy',
}

const dateTemplate = 'YYYY-MM-DD'

const timeTemplate = 'THH:mm:ssZ[Z]'

export { TripSearchParams, dateTemplate, timeTemplate }
