import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { BookingDetailsPrice } from './BookingDetailsPrice'
import { BookingDetailsPriceApolloLoading } from './BookingDetailsPriceApolloLoading'
import type { BookingDetailsPriceNode } from './types'

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsPrice($nodeId: ID!) {
    node(id: $nodeId) {
      ... on Booking {
        id
        bookingDetails {
          price {
            allInTotal {
              amount
              currency
            }
            additionalFees {
              amount {
                amount
                currency
              }
              type
            }
            dueLater {
              amount
              currency
            }
            dueNow {
              amount
              currency
            }
            subtotal {
              amount
              currency
            }
            surchargeTotal {
              amount
              currency
            }
            surcharges {
              type
              amount {
                amount
              }
            }
          }
        }
        hotel {
          country
        }
        provider
      }
    }
  }
`)

interface BookingDetailsPriceApolloProps {
  onPrintButtonClick(): void
}

export const BookingDetailsPriceApollo = ({
  onPrintButtonClick,
}: BookingDetailsPriceApolloProps) => {
  const { bookingId } = useParams()
  const willSkip = !bookingId

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: willSkip,
    variables: {
      nodeId: bookingId,
    },
  })

  const { node } = data ?? {}
  const bookingDetailsData = node as BookingDetailsPriceNode

  if (isLoading || willSkip) return <BookingDetailsPriceApolloLoading />

  if (hasError) return

  return (
    <BookingDetailsPrice
      bookingDetailsData={bookingDetailsData}
      onPrintButtonClick={onPrintButtonClick}
    />
  )
}
