import classNames from 'classnames'
import type { ExperiencesPriceSummaryRowProps } from './types'

const variantStyles = ({ variant }: Partial<ExperiencesPriceSummaryRowProps>) =>
  classNames({
    'color-forest font-600': variant === 'subtitle',
    'color-new-forest font-700': variant === 'title',
    'color-grey-800': variant !== 'subtitle' && variant !== 'title',
  })

const experiencesPriceSummaryCss = 'flex flex-col gap-6'

const experiencesPriceSummaryOverviewCss = 'flex flex-col gap-2'

const experiencesPriceSummaryTitleCss = 'text-h6'

const experiencesPriceSummaryRowCss = ({
  variant,
}: Partial<ExperiencesPriceSummaryRowProps>) =>
  classNames(
    'flex gap-2 justify-between text-body-1',
    variantStyles({ variant })
  )

export {
  experiencesPriceSummaryCss,
  experiencesPriceSummaryOverviewCss,
  experiencesPriceSummaryTitleCss,
  experiencesPriceSummaryRowCss,
  variantStyles,
}
