import classNames from 'classnames'
import { constructDisplayName } from 'src/utils'
import { BookingCancellationBadge } from './BookingCancellationBadge'
import { BookingCardIconText } from './BookingCardIconText'
import { constructDisplayDate, constructDisplayBottomText } from './resources'
import type { BookingCardData } from './types'

/**
 * BookingCardContent renders the following pieces
 * - Card Title
 * - Duration
 * - Total Guests and Total Rooms
 * - Cancelled status
 */

interface BookingCardContentProps {
  bookingCardData: BookingCardData
}

export const BookingCardContent = ({
  bookingCardData,
}: BookingCardContentProps) => {
  const {
    cancelled,
    adults,
    kids,
    startDate,
    endDate,
    startTime,
    name,
    roomCount,
  } = bookingCardData ?? {}
  const displayName = constructDisplayName(name)
  const displayDate = constructDisplayDate({ startDate, endDate, startTime })
  const { guestText, roomText } = constructDisplayBottomText({
    adults,
    kids,
    roomCount,
  })

  return (
    <div className='min-h-21 flex flex-col gap-2 p-4'>
      <div className='min-h-10'>
        <h3 className='font-archivo c-forest my-0 line-clamp-2 text-base font-semibold leading-tight'>
          {displayName}
        </h3>
      </div>
      <div className='c-grey800 flex min-h-4 flex-row items-center'>
        <p className='c-grey800 text-body-2 line-clamp-1'>{displayDate}</p>
      </div>
      <div className='flex w-full flex-row items-center justify-between'>
        <div
          className={classNames('flex min-h-6 flex-row', {
            'gap-2': !!guestText && !!roomText,
          })}
        >
          <BookingCardIconText content={guestText} iconName='personOutline' />

          <BookingCardIconText content={roomText} iconName='bed' />
        </div>
        {cancelled && <BookingCancellationBadge name={name} />}
      </div>
    </div>
  )
}
