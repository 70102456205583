import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { DetailsMinimalHotelHero } from 'src/common/components'
import {
  Amenities,
  Overview,
} from 'src/pages/trips/components/TripDrawerContent/components'
import { TripDetailsExploreDrawerHotelFooter } from './TripDetailsExploreDrawerHotelFooter'
import type { ExploreHotel } from '../../../constants'
import { ExploreSearchParams } from '../../../constants'

interface TripDetailsExploreDrawerHotelProps {
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  tripDetailsExploreHotelsData: ExploreHotel[]
}

export const TripDetailsExploreDrawerHotel = ({
  tripDetailsData,
  tripDetailsExploreHotelsData,
}: TripDetailsExploreDrawerHotelProps) => {
  const [searchParams] = useSearchParams()
  const activeExploreId = searchParams.get(ExploreSearchParams.activeExploreId)
  const item: ExploreHotel = useMemo(
    () =>
      tripDetailsExploreHotelsData?.find(
        ({ id }) => id === activeExploreId
      ) ?? { id: activeExploreId },
    [activeExploreId, tripDetailsExploreHotelsData]
  )
  const { amenities, description, starRating } = item ?? {}

  return (
    <>
      <div className='space-y-4'>
        <div className='space-y-3'>
          <DetailsMinimalHotelHero detailsMinimalHotelHeroData={item} />
        </div>
        <Overview content={description} rating={starRating} />
        <Amenities amenities={amenities} />
      </div>
      <TripDetailsExploreDrawerHotelFooter
        item={item}
        tripDetailsData={tripDetailsData}
      />
    </>
  )
}
