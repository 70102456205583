import classNames from 'classnames'

export const Footer = () => {
  return (
    <div
      className={classNames(
        'h-75 w-full bg-[url(https://static.travelpass.com/assets/homev2-page/about-mobile.webp)] bg-[position:center_bottom_-9rem] bg-no-repeat',
        'sm:bg-[length:100vw_auto]',
        'md:bg-[position:center_bottom_-19rem]',
        // 'lg:mb--75 ',
        'lg:bg-[url(https://static.travelpass.com/assets/homev2-page/about.webp)] lg:bg-cover lg:bg-[position:center_bottom_-15rem]'
      )}
      data-testid='experience-details-footer'
      id='experience-details-footer'
    />
  )
}
