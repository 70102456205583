import type { ReactNode } from 'react'

interface MapButtonProps {
  label: ReactNode
  onClick(): void
}

export const MapButton = ({ label, onClick }: MapButtonProps) => (
  <button className={mapButtonCss} onClick={onClick}>
    {label}
  </button>
)

const mapButtonCss =
  'bg-transparent b-0 color-black cursor-pointer ease-linear flex gap-2 h-12 items-center justify-center px-4 relative text-button-large transition-opacity-200 active:opacity-40 hover:opacity-40'
