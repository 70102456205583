import '../common/styles/bookingsResultsCustomStyles.css'
import { ExperienceBooking } from './ExperienceBooking'
import type { ExperienceBookingTemp } from './types'
import { bookingsListContainerCss } from '../common/constants'

interface ExperienceBookingsListProps {
  bookings: ExperienceBookingTemp[]
}

export const ExperienceBookingsList = ({
  bookings,
}: ExperienceBookingsListProps) => (
  <div className={bookingsListContainerCss}>
    {bookings?.map(booking => (
      <ExperienceBooking
        key={booking?.node?.id}
        booking={booking}
        className='min-w-74'
      />
    ))}
  </div>
)
