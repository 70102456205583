import { Icon } from '@travelpass/design-system'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'

interface ExperiencesExclusionsInclusionsProps {
  exclusions: ExperienceProduct['exclusions']
  inclusions: ExperienceProduct['inclusions']
}

export const ExperiencesExclusionsInclusions = ({
  exclusions,
  inclusions,
}: ExperiencesExclusionsInclusionsProps) => {
  const constructedInclusions = inclusions
    ?.map(incl => incl.description)
    .filter(Boolean)

  const constructedExclusions = exclusions
    ?.map(excl => excl.description)
    .filter(Boolean)

  const hasInclusions = constructedInclusions?.length > 0
  const hasExclusions = constructedExclusions?.length > 0
  const hasList = hasInclusions || hasExclusions

  return (
    hasList && (
      <ReadMoreContentSection>
        <div className='color-grey-800 grid gap-4 md:grid-cols-2'>
          <ul className='m-0 list-none space-y-2 p-0'>
            {constructedInclusions?.map((title, index) => (
              <li
                key={`${title}-${index}`}
                className='flex gap-2 align-baseline'
              >
                <Icon name='check' size='small' />
                {title}
              </li>
            ))}
          </ul>
          <ul className='m-0 list-none space-y-2 p-0'>
            {constructedExclusions?.map((title, index) => (
              <li
                key={`${title}-${index}`}
                className='flex gap-2 align-baseline'
              >
                <Icon name='clear' size='small' />
                {title}
              </li>
            ))}
          </ul>
        </div>
      </ReadMoreContentSection>
    )
  )
}
