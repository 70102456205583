import isEmpty from 'lodash.isempty'
import type {
  GetGuidesEventsMapQueryInGuidesEventsMapQueryVariables,
  GetGuidesEventsMapUserQueryInGuidesEventsMapQuery,
} from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'

export const getGuidesEventsMapVariables = ({
  filters,
  guidesEventsMapUserData,
}: {
  filters: string[]
  guidesEventsMapUserData: GetGuidesEventsMapUserQueryInGuidesEventsMapQuery['currentUser']
}): GetGuidesEventsMapQueryInGuidesEventsMapQueryVariables => {
  const updatedFilters = {}
  const eventTypes = []
  const userIds = []
  const { userProfile } = guidesEventsMapUserData ?? {}
  const { following, userId } = userProfile ?? {}

  if (filters.includes('Experience')) eventTypes.push(EventType.Experience)

  if (filters.includes('Food & Drink')) eventTypes.push(EventType.FoodAndDrink)

  if (filters.includes('Miscellaneous'))
    eventTypes.push(EventType.Miscellaneous)

  if (filters.includes('Shopping')) eventTypes.push(EventType.Shopping)

  if (filters.includes('Stay')) eventTypes.push(EventType.Stay)

  if (!isEmpty(eventTypes)) updatedFilters['eventTypes'] = eventTypes

  if (!isEmpty(userProfile)) {
    if (filters.includes('Following'))
      userIds.push(...following.map(({ userId }) => userId))

    if (filters.includes('My Guides')) userIds.push(userId)
  }

  if (!isEmpty(userIds)) updatedFilters['userIds'] = userIds

  return updatedFilters
}
