import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetTripWeatherQueryInTripsQuery } from 'src/__generated__/graphql'

const getTripWeatherQuery = gql(`
  query GetTripWeatherQueryInTrips($tripId: ID!) {
    getTrip(id: $tripId) {
      id
      historicalWeather {
        maxTemp
        minTemp
        primaryCondition
      }
    }
  }
`)

type UseGetTripWeatherQuery = {
  hasError: ApolloError
  isLoading: boolean
  tripWeatherData: GetTripWeatherQueryInTripsQuery['getTrip']
}

export const useGetTripWeatherQuery = (
  tripId?: string
): UseGetTripWeatherQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getTripWeatherQuery, {
    skip: !tripId,
    variables: {
      tripId: tripId ?? '',
    },
  })

  return {
    hasError,
    isLoading,
    tripWeatherData: data?.getTrip ?? null,
  }
}
