import type { KeyboardEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { DateRange } from '@travelpass/design-system'
import {
  InlineDateRangePicker,
  KeyCode,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import type { DateInterval } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { ResultsInput } from 'src/common/components'
import type { DatesType } from 'src/constants/user'
import { formatDate } from 'src/utils'
import { HotelResultsSearchDivider } from './common'
import { dateTemplate } from './hotelResultsSearchConstants'
import { getHotelResultsSearchDates } from './hotelResultsSearchUtils'

interface HotelResultsSearchDatesProps {
  canSelectSameDay?: boolean
  dates: DatesType
  arrivalLabel?: string
  departureLabel?: string
  onSelect?(range): void
}

const TO_DATE = dayjs().add(1, 'year').toDate()

export const HotelResultsSearchDates = ({
  canSelectSameDay = false,
  dates,
  arrivalLabel = 'Check-in',
  departureLabel = 'Check-out',
  onSelect = () => {},
}: HotelResultsSearchDatesProps) => {
  const [searchParams] = useSearchParams()
  const autoFocus = !searchParams.has('arrival')
  const autoOpen =
    !searchParams.has('arrival') || !searchParams.has('departure')
  const arrivalRef = useRef(null)
  const departureRef = useRef(null)
  const popoverRef = useRef(null)
  const { isDesktopScreen } = useScreenQuery()
  const [isArrival, setIsArrival] = useState(autoFocus)
  const [isOpen, setIsOpen] = useState(autoOpen)
  const arrival = dates?.[0] ? formatDate(dates?.[0], dateTemplate) : ''
  const departure = dates?.[1] ? formatDate(dates?.[1], dateTemplate) : ''

  useEffect(() => {
    if (isOpen) {
      if (isArrival) arrivalRef?.current?.focus()
      else departureRef?.current?.focus()
    }
  }, [isArrival])

  // TODO: add "KeyCode.TAB"
  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (
      key === KeyCode.ESC ||
      key === KeyCode.ENTER ||
      key === KeyCode.SPACE ||
      key === 'Tab'
    )
      setIsOpen(false)
  }

  const onDateSelect = (range: DateRange) => {
    if (isArrival) setIsArrival(false)
    onSelect(
      getHotelResultsSearchDates({
        currentDates: range,
        isArrival,
        previousDates: dates,
      })
    )
  }

  const oneMonthIntervalMatcher: DateInterval = {
    after: dates[0].add(30, 'day').toDate(),
    before: dates[0].subtract(30, 'day').toDate(),
  }

  return (
    isDesktopScreen && (
      <Popover
        clickOutsideCapture={true}
        containerStyle={{ zIndex: '450' }}
        content={() => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className='rd-3 shadow-1 mr--5 mt-1 bg-white p-2'
            onKeyDown={onKeyDown}
          >
            <InlineDateRangePicker
              canSelectSameDay={canSelectSameDay}
              disabled={!dates[1] && oneMonthIntervalMatcher}
              numberOfMonths={2}
              selected={{
                from: dates?.[0],
                to: dates?.[1],
              }}
              showOutsideDays={false}
              toDate={TO_DATE}
              onSelect={onDateSelect}
            />
          </div>
        )}
        isOpen={isOpen}
        positions={['bottom']}
        ref={popoverRef}
        onClickOutside={() => setIsOpen(false)}
      >
        <div
          className={classNames(
            'search-gap flex items-center justify-center gap-4 md:gap-3',
            {
              '[&>:first-child>div>div]:b-canyon': isOpen && isArrival,
              '[&>:last-child>div>div]:b-canyon': isOpen && !isArrival,
            }
          )}
          ref={popoverRef}
        >
          <div className='search-date w-38 max-w-full'>
            <ResultsInput
              fullWidth
              aria-owns='simple-popover'
              label={arrivalLabel}
              labelIcon='calendarToday'
              readOnly={true}
              ref={arrivalRef}
              value={arrival}
              onFocus={() => {
                setIsArrival(true)
                setIsOpen(true)
              }}
              onKeyDown={onKeyDown}
            />
          </div>
          <HotelResultsSearchDivider />
          <div className='search-date w-38 max-w-full'>
            <ResultsInput
              fullWidth
              label={departureLabel}
              labelIcon='calendarToday'
              readOnly={true}
              ref={departureRef}
              value={departure}
              onFocus={() => {
                setIsArrival(false)
                setIsOpen(true)
              }}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
      </Popover>
    )
  )
}
