import { Divider, Skeleton } from '@travelpass/design-system'

interface GuideHeaderContentLoadingProps {
  isEdit: boolean
}

export const GuideHeaderContentLoading = ({
  isEdit,
}: GuideHeaderContentLoadingProps) => {
  if (isEdit)
    return (
      <>
        <div className='p-y-4 hidden flex-row justify-between gap-2 lg:flex'>
          <div className='flex flex-col gap-2'>
            <div className='md:w-100 h-5 w-60 md:h-7 lg:h-8'>
              <Skeleton />
            </div>
            <div className='flex flex-row items-center gap-8'>
              <div className='h-6.5 w-20'>
                <Skeleton variant='rounded' />
              </div>
              <div className='w-26 h-6'>
                <Skeleton variant='rounded' />
              </div>
            </div>
          </div>
          <div className='h-8 w-8'>
            <Skeleton variant='circular' />
          </div>
        </div>
        <div className='p-y-4 flex w-full flex-row justify-center lg:hidden'>
          <div className='w-26 h-6'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </>
    )

  return (
    <div className='space-y-6'>
      <div className='flex flex-row items-start justify-between'>
        <div className='md:w-100 h-5 w-60 md:h-7 lg:h-8'>
          <Skeleton />
        </div>
        <div className='h-6 w-6 md:h-8 md:w-8 lg:hidden'>
          <Skeleton variant='circular' />
        </div>
      </div>
      <div className='flex flex-col gap-5 md:flex-row md:gap-7'>
        <div className='flex min-w-fit flex-col gap-3 md:flex-row md:items-start md:gap-3.5'>
          <div className='flex flex-row items-center gap-3'>
            <div className='h-12 w-12'>
              <Skeleton variant='circular' />
            </div>
            <div className='space-y-1'>
              <div className='w-25 h-3 md:h-3.5'>
                <Skeleton />
              </div>
              <div className='w-25 h-2.5 md:h-4'>
                <Skeleton />
              </div>
            </div>
          </div>
          <Divider className='w-1px m-0 hidden min-h-12 md:block' />
        </div>
        <div className='hidden grow md:block'>
          <div className='h-26'>
            <Skeleton />
          </div>
        </div>
        <div className='grow md:hidden'>
          <Divider className='min-h-1px m-t--2 m-b-2 m-0 w-full md:hidden' />
          <div className='h-28 w-full'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  )
}
