import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_EXPERIENCE_BOOKING_DETAILS_QUERY = gql(`
query GetExperienceBookingDetails($externalConfirmationId: String!) {
  findExperienceBookingByExternalConfirmationId(externalConfirmationId: $externalConfirmationId) {
    id 
    dateBooked
    experiencePostBookingStateId
    externalConfirmationId
    productCode
    productName
    productOptionCode
    travelDate
    site
    startTime
    status
    rejectionReasonCode
    validUntil
    bookingDetails {
      departurePoint {
        name
        address {
          addressLine1
          city
          state
          postalCode
          countryCode
        }
      }
      pickupAdditionalInfo
      cancellationPolicy {
        type
        description
        cancelIfBadWeather
        cancelIfInsufficientTravelers
        refundEligibility {
          dayRangeMin
          dayRangeMax
          percentageRefundable
          startTimestamp
          endTimestamp
        }
      }
      totalPrice {
        price {
          # todo: need grandTotal instead?
          partnerTotalPrice {
            amount
            currency
          }
        }
      }
    }
    bookerInfo {
      firstName
      fullName
      lastName
    }
    # TODO: why are bookingQuestionAnswers required on this query??
    bookingQuestionAnswers {
      answer
      question
      travelerNum
      unit
    }
    communication {
      email
      phone
    }
    experienceOperatorContact {
      name
      address
      phone
    }
    languageGuide {
      language
      type
    }
    product {
      id
      cancellationPolicy {
        cancelIfBadWeather
        cancelIfInsufficientTravelers
        description
        refundEligibility {
          dayRangeMin
          dayRangeMax
          percentageRefundable
          startTimestamp
          endTimestamp
        }
        type
      }
      additionalInfo {
        type
        description
      }
      exclusions {
        description
      }
      images {
        size480x320
      }
      inclusions {
        description
      }
      itinerary {
        ... on ExperienceStandardItinerary {
          duration {
            durationRange
            fixedDurationInMinutes
            readableDurationRange
            unstructuredDuration
            variableDurationFromMinutes
            variableDurationToMinutes
          }
        }
        ... on ExperienceActivityItinerary {
          duration {
            durationRange
            fixedDurationInMinutes
            readableDurationRange
            unstructuredDuration
            variableDurationFromMinutes
            variableDurationToMinutes
          }
        }
        ... on ExperienceHopOnHopOffItinerary {
          duration {
            durationRange
            unstructuredDuration
            readableDurationRange
            fixedDurationInMinutes
            variableDurationToMinutes
            variableDurationFromMinutes
          }
        }
        ... on ExperienceUnstructuredItinerary {
          duration {
            durationRange
            unstructuredDuration
            fixedDurationInMinutes
            readableDurationRange
            variableDurationToMinutes
            variableDurationFromMinutes
          }
        }
      }
      logistics {
        start {
          description
          location {
            city
            name
            state
            unstructuredAddress
          }
        }
      }
      productCode
      title
    }
    productContactDetails {
      companyName
      email
      phone
      voucherText
    }
    voucherInfo {
      url
      format
    }
    totalPrice {
      price {
        # todo: need grandTotal instead
        recommendedRetailPrice {
          amount
          currency
        }
      }
    }
    paxMix {
      ageBand
      numberOfTravelers
    }
    trip {
      id
      timeZone
    }
  }
}`)

export const useGetExperienceBookingDetailsQuery = (
  externalConfirmationId: string
) => {
  return useQuery(GET_EXPERIENCE_BOOKING_DETAILS_QUERY, {
    variables: { externalConfirmationId },
    skip: !externalConfirmationId,
  })
}
