import { Skeleton } from '@travelpass/design-system'

export const TripDetailsExploreNavLoading = ({
  hideDivider = false,
}: {
  hideDivider?: boolean
}) => {
  return (
    <div className='flex flex-col items-center justify-center gap-7'>
      <div className='flex gap-5 md:gap-6'>
        <div className='h-9.5 w-54 md:h-13.5'>
          <Skeleton />
        </div>
      </div>
      {!hideDivider && (
        <div className='h-8 w-full'>
          <Skeleton variant='rounded' />
        </div>
      )}
    </div>
  )
}
