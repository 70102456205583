import { useRef, useState } from 'react'
import {
  Button,
  Icon,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideImage } from 'src/pages/guides/details/common'
import { guideDraftImageAcceptedTypes } from '../guideDraftConstants'
import type { GuideDraftPublishedEvent } from '../types'
import { useGuideDraftEventImagesUpload } from '../useGuideDraftEventImagesUpload'

interface GuideDraftEventImagesUploadModalOldProps {
  guideDraftId: string
  onDismiss: VoidFunction
  selectedEvent: GuideDraftPublishedEvent
}

export const GuideDraftEventImagesUploadModalOld = ({
  guideDraftId,
  onDismiss,
  selectedEvent,
}: GuideDraftEventImagesUploadModalOldProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const {
    imageError,
    images,
    isLoading,
    onSelected,
    onSubmit,
    onUpload,
    selectedImages,
    selectedImagesText,
  } = useGuideDraftEventImagesUpload({
    guideDraftId,
    onDismiss,
    selectedEvent,
  })

  return (
    <Modal size='large' title='Upload Your Photos' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
          <section className='rounded-3 bg-warm-grey p-x-6 p-t-4 p-b-8 space-y-3'>
            <div>
              <Input
                multiple
                accept={guideDraftImageAcceptedTypes}
                className='invisible absolute'
                hidden={true}
                ref={ref}
                type='file'
                onChange={events => onUpload(events?.target?.files)}
              />
              <button
                className={classNames(
                  '[&>i]:c-canyon [&>i]:op-50 b-2 b-solid transition-border-color-100 rounded-2 focus:b-canyon over:b-canyon md:p-y-10 flex w-full cursor-pointer select-none flex-col items-center gap-1 bg-white p-2 text-center outline-none ease-linear',
                  {
                    'b-canyon': isDragging,
                    'b-grey-200': !isDragging,
                  }
                )}
                onClick={() => ref?.current?.click()}
                onDragEnter={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  setIsDragging(true)
                }}
                onDragLeave={() => {
                  setIsDragging(false)
                }}
                onDragOver={event => {
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  onUpload(event?.dataTransfer?.files)
                  setIsDragging(false)
                }}
              >
                <Icon name='upload' size='large' />
                <span className='c-grey-700 type-body-1'>
                  Drag and drop or{' '}
                  <span className='font-600 c-forest-light underline'>
                    choose photos
                  </span>{' '}
                  to upload
                </span>
                <span className='c-grey-500 type-body-2'>
                  jpg, png (5mb max)
                </span>
              </button>
              {!!imageError && (
                <p className='c-red font-2.5 type-subtext p-l-3.5 p-t-1'>
                  {imageError}
                </p>
              )}
            </div>
            <div className='c-grey-900 type-body-1-medium-desktop flex flex-row justify-between'>
              <span className='grow'>
                Add up to 10 photos to this recommendation
              </span>
              <span className='c-forest-light min-w-fit'>
                {selectedImagesText}
              </span>
            </div>
            {!!images?.length && (
              <div className='b-1 b-solid b-grey-300 rounded-3 p-x-6 p-y-3 grid grid-cols-2 gap-1 bg-white md:grid-cols-4 md:gap-2'>
                {images?.map((image, index) => {
                  const selectedImageIndex = selectedImages?.findIndex(
                    selectedImage => selectedImage === image
                  )

                  return (
                    <button
                      key={index}
                      aria-pressed={selectedImageIndex !== -1}
                      className={classNames(
                        'b-2 b-transparent b-solid transition-border-200 rounded-2 aria-pressed:b-forest-light p-1.25 h-30 relative bg-transparent ease-linear hover:cursor-pointer'
                      )}
                      onClick={() => onSelected(image)}
                    >
                      {selectedImageIndex !== -1 && (
                        <span className='type-subtitle-2-mobile font-archivo bg-forest c-white b-1 b-solid b-white/60 rounded-1/2 left-2.25 top-2.25 absolute h-6 w-6 content-center text-center'>
                          {selectedImageIndex + 1}
                        </span>
                      )}
                      <GuideImage
                        className='rounded-1.5 bg-transparent'
                        draggable={false}
                        src={image}
                      />
                    </button>
                  )
                })}
              </div>
            )}
          </section>
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button autoFocus={true} isDisabled={isLoading} onClick={onSubmit}>
          Save
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
