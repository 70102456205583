import type {
  CurrentUserAddressFields,
  FormAddressFields,
} from './bookingTypes'

export const CURRENT_USER_ADDRESS_FIELDS_KEYS: Array<
  keyof CurrentUserAddressFields
> = ['addressFirstLine', 'addressSecondLine', 'city', 'state', 'zip', 'country']

export const FORM_ADDRESS_FIELDS_KEYS: Array<keyof FormAddressFields> = [
  'address1',
  'address2',
  'city',
  'stateProvince',
  'postalCode',
  'country',
]
