import type { MouseEvent } from 'react'
import { Button, StopPropagation } from '@travelpass/design-system'
import { getGuideMetricString } from 'src/pages/guides/details/guideUtils'
import type { GuideData } from 'src/pages/guides/details/types'

interface DashboardGuideOldCardMetricsProps {
  guideData: Pick<
    GuideData,
    | 'collectedCount'
    | 'id'
    | 'isCollected'
    | 'likedByCurrentUser'
    | 'name'
    | 'numberOfLikes'
    | 'ownerProfile'
    | 'shareCount'
    | 'viewCount'
  >
  isGuideLikesModalEnabled: boolean
  onIsModalOpenChange: (updatedIsModalOpen: boolean) => void
}

export const DashboardGuideOldCardMetrics = ({
  isGuideLikesModalEnabled,
  guideData,
  onIsModalOpenChange,
}: DashboardGuideOldCardMetricsProps) => {
  const {
    collectedCount,
    isCollected,
    likedByCurrentUser,
    numberOfLikes,
    shareCount,
    viewCount,
  } = guideData ?? {}

  const onGuideLikeClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.target instanceof HTMLDivElement) {
      onIsModalOpenChange(true)
    }
  }

  return (
    <div className='w-fit cursor-default'>
      <StopPropagation>
        <div className='[&>div>button]:p-x-0 [&>div>button>i]:color-grey-900 [&>div>button>div]:color-grey-900 [&>div>button>div]:normal-case [&>div>button]:gap-1'>
          <div className='inline-flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
            <Button
              aria-disabled={!isGuideLikesModalEnabled}
              aria-pressed={likedByCurrentUser}
              isDisabled={!isGuideLikesModalEnabled}
              size='small'
              startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
              variant='text'
              onClick={onGuideLikeClick}
            >
              {getGuideMetricString(numberOfLikes, '')}
            </Button>
            <Button
              aria-disabled={true}
              isDisabled={true}
              name='visibility'
              size='small'
              startIcon='visibility'
              variant='text'
            >
              {getGuideMetricString(viewCount, '')}
            </Button>
            <Button
              aria-disabled={true}
              aria-pressed={isCollected}
              isDisabled={true}
              size='small'
              startIcon='addCircleOutline'
              variant='text'
              onClick={() => {}}
            >
              {getGuideMetricString(collectedCount, '')}
            </Button>
            <Button
              aria-disabled={true}
              aria-pressed={isCollected}
              isDisabled={true}
              size='small'
              startIcon='iosShare'
              variant='text'
              onClick={() => {}}
            >
              {getGuideMetricString(shareCount, '')}
            </Button>
          </div>
        </div>
      </StopPropagation>
    </div>
  )
}
