import { useState } from 'react'
import { Button, useScreenQuery } from '@travelpass/design-system'
import type { ExperienceImage } from 'src/__generated__/graphql'
import { HeroImage } from 'src/pages/hotels/details/components/HotelHero/HotelHeroImages/HeroImage'
import { HeroImagesMobile } from './HeroImagesMobile'
import { HeroModal } from '../HeroModal'
import './heroImages.css'

export interface HeroImagesProps {
  images: [ExperienceImage['size720x480'], ExperienceImage['size674x446']]
  imagesSrcSet: string[]
  title: string
}

export const HeroImages = ({
  images,
  imagesSrcSet,
  title,
}: HeroImagesProps) => {
  const [galleryIndex, setGalleryIndex] = useState(-1)
  const { isMobileScreen } = useScreenQuery()
  const [desktopImages, mobileImages] = images
  const desktopImagesTail = desktopImages.slice(1, 5)
  const imagesSrcSetTail = imagesSrcSet.slice(1, 5)
  const galleryButtonLabel =
    desktopImages?.length > 25 ? '25+' : desktopImages?.length?.toString()
  const hasMultipleImages = imagesSrcSet.length > 1
  const hasTwoImages = imagesSrcSet.length === 2

  const openGallery = (index = 0) => {
    setGalleryIndex(index)
  }

  if (desktopImages.length === 0) {
    return null
  }

  if (isMobileScreen) {
    return (
      <HeroImagesMobile
        galleryIndex={galleryIndex}
        hasMultipleImages={hasMultipleImages}
        hasTwoImages={hasTwoImages}
        images={images}
        imagesSrcSet={imagesSrcSet}
        mobileImages={mobileImages}
        openGallery={openGallery}
        title={title}
      />
    )
  }

  return (
    imagesSrcSet.length > 0 && (
      <>
        {galleryIndex >= 0 && (
          <HeroModal
            experienceImages={images}
            experienceName={title}
            initialSlideIndex={galleryIndex}
            onCloseModal={() => openGallery(-1)}
          />
        )}
        <div
          className='flex w-full items-center justify-center'
          data-testid='hero-image-gallery'
        >
          <div className='max-w-100vw top-level-grid w-full gap-2 lg:w-[1440px]'>
            <div className='first-image-container h-auto w-full overflow-hidden lg:h-[564px]'>
              {imagesSrcSet.length > 1 ? (
                <HeroImage
                  alt={title + ' photo 1'}
                  src={images[0][1]}
                  srcSet={imagesSrcSet[0]}
                  onClick={() => openGallery(0)}
                />
              ) : (
                <img
                  alt={title + ' photo 1'}
                  className='h-75 max-h-full w-full bg-gray-200 object-cover lg:h-full'
                  loading='lazy'
                  src={images[0][1]}
                  srcSet={imagesSrcSet[0]}
                />
              )}
            </div>
            <div className='md:h-75 subgrid grid grid-cols-2 grid-rows-2 gap-2 lg:h-[564px]'>
              {desktopImagesTail.map((image, index) => {
                if (index === desktopImagesTail.length - 1) {
                  return (
                    <div
                      key={index}
                      className='image-with-button-container relative overflow-hidden'
                    >
                      <div className='z-1 absolute bottom-3 right-3'>
                        <Button
                          label={galleryButtonLabel}
                          size='small'
                          startIcon='photoLibrary'
                          variant='outlined'
                          onClick={() => openGallery(0)}
                        />
                      </div>
                      <HeroImage
                        alt={`${title} photo ${index + 2}`}
                        src={image}
                        srcSet={imagesSrcSetTail[index]}
                        onClick={() => openGallery(index + 1)}
                      />
                    </div>
                  )
                }

                return (
                  <div
                    key={index}
                    className='subgrid-image col-span-1 overflow-hidden'
                  >
                    <HeroImage
                      alt={`${title} photo ${index + 2}`}
                      src={image}
                      srcSet={imagesSrcSetTail[index]}
                      onClick={() => openGallery(index + 1)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
  )
}
