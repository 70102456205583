import { useEffect, useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSection } from './GuideDraftSection'
import { GuideDraftSectionAddModal } from './GuideDraftSectionAddModal'
import { GuideDraftSectionSearch } from './GuideDraftSectionSearch'
import { GuideDraftSectionsOverlay } from './GuideDraftSectionsOverlay'
import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useGuideDraftDrag } from '../../useGuideDraftDrag'
import { GuideDraftEvents } from '../GuideDraftEvents'

interface GuideDraftSectionsProps {
  data: UseGetGuideDraftQuery['data']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSelectedIdChange(updatedSelectedId: string): void
  owner: GuideOwner
  selectedId: string
}

export const GuideDraftSections = ({
  data,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSelectedIdChange,
  owner,
  selectedId,
}: GuideDraftSectionsProps) => {
  const {
    activeId,
    containers,
    dragProps,
    dropAnimation,
    isSortingContainer,
    items,
  } = useGuideDraftDrag(data)
  const [isSectionAddModalOpen, setIsSectionAddModalOpen] = useState(false)
  const [searchId, setSearchId] = useState('')
  const { id, paginatedEventCategories } = (data?.node as GuideDraftData) ?? {}

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (!searchId) return

      const geocoderElement = document.querySelector(
        `[data-geocoder-id="${searchId}"]`
      )

      if (
        geocoderElement &&
        !geocoderElement.contains(event.target as HTMLElement)
      )
        setSearchId('')
    }

    window.addEventListener('click', onClick)

    return () => window.removeEventListener('click', onClick)
  }, [searchId])

  const onSectionAddClick = () => {
    setIsSectionAddModalOpen(true)
    pushDataToDataLayer('guide_draft_section_add_modal_opened', {
      guide_id: id,
      trigger_url: window.location.href,
    })
  }

  const onSearchIdChange = (updatedSearchId: string) =>
    setSearchId(updatedSearchId)

  if (isEmpty(items)) return

  return (
    <>
      <div className='space-y-8 lg:space-y-10'>
        <DndContext {...dragProps}>
          <SortableContext
            items={containers}
            strategy={verticalListSortingStrategy}
          >
            {containers?.map(container => (
              <GuideDraftSection
                key={items?.[container]?.id}
                paginatedEventCategory={items?.[container]}
              >
                <GuideDraftEvents
                  isSortingContainer={isSortingContainer}
                  owner={owner}
                  paginatedEventCategories={paginatedEventCategories}
                  paginatedEventCategory={items?.[container]}
                  selectedId={selectedId}
                  onMapMarkerCenterChange={onMapMarkerCenterChange}
                  onSelectedIdChange={onSelectedIdChange}
                />
                {items?.[container]?.id && (
                  <GuideDraftSectionSearch
                    id={id}
                    location={location}
                    paginatedEventCategory={items?.[container]}
                    searchId={searchId}
                    onMapMarkerCenterChange={onMapMarkerCenterChange}
                    onPlacePointsChange={onPlacePointsChange}
                    onSearchIdChange={onSearchIdChange}
                  />
                )}
              </GuideDraftSection>
            ))}
          </SortableContext>
          <GuideDraftSectionsOverlay
            activeId={activeId}
            containers={containers}
            dropAnimation={dropAnimation}
            items={items}
            owner={owner}
            paginatedEventCategories={paginatedEventCategories}
          />
        </DndContext>
        <Button size='small' startIcon='add' onClick={onSectionAddClick}>
          New Section
        </Button>
      </div>
      {isSectionAddModalOpen && (
        <GuideDraftSectionAddModal
          guideId={id}
          onDismiss={() => setIsSectionAddModalOpen(false)}
        />
      )}
    </>
  )
}
