import { useEffect, useRef } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  SkeletonDots,
} from '@travelpass/design-system'
import { FormProvider } from 'react-hook-form'
import { type GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import { DashboardProfileInfoAbout } from './DashboardProfileInfoAbout'
import { DashboardProfileInfoEmbedYoutubeVideo } from './DashboardProfileInfoEmbedYoutubeVideo'
import { DashboardProfileInfoHeader } from './DashboardProfileInfoHeader'
import { DashboardProfileInfoLinks } from './DashboardProfileInfoLinks'
import { DashboardProfileInfoSocialMediaLinks } from './DashboardProfileInfoSocialMediaLinks'
import { DashboardProfileInfoTags } from './DashboardProfileInfoTags'
import { DashboardProfileInfoTravelTracker } from './DashboardProfileInfoTravelTracker'
import type { DashboardProfileFields } from './types'
import { useDashboardProfileInfo } from '../../../useDashboardProfileInfo'

interface DashboardProfileInfoModalProps {
  onDismiss: VoidFunction
  onModalAvatarOpen: VoidFunction
  onModalBannerOpen: VoidFunction
  onSubmitCompleted?: VoidFunction
  scrollId?: string
  userProfile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
}

export const DashboardProfileInfoModal = ({
  onDismiss,
  onModalAvatarOpen,
  onModalBannerOpen,
  onSubmitCompleted,
  scrollId,
  userProfile,
}: DashboardProfileInfoModalProps) => {
  const { isLoading, methods, onSubmit } = useDashboardProfileInfo({
    onDismiss,
    userProfile,
  })
  const ref = useRef<HTMLFormElement>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      if (scrollId) {
        const element = document.getElementById(scrollId)
        element?.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }, [scrollId])

  const handleOnSubmit = (data: DashboardProfileFields) => {
    onSubmitCompleted && onSubmitCompleted()
    onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <Modal title='Edit Profile' onDismiss={onDismiss}>
        <ModalScrollContents>
          <form
            className='md:p-x-0 relative space-y-8'
            ref={ref}
            onSubmit={methods.handleSubmit(handleOnSubmit)}
          >
            <DashboardProfileInfoHeader
              userProfile={userProfile}
              onModalAvatarOpen={onModalAvatarOpen}
              onModalBannerOpen={onModalBannerOpen}
            />
            <DashboardProfileInfoAbout />
            <DashboardProfileInfoTags />
            <DashboardProfileInfoTravelTracker />
            <DashboardProfileInfoEmbedYoutubeVideo />
            <DashboardProfileInfoSocialMediaLinks />
            <DashboardProfileInfoLinks />
          </form>
        </ModalScrollContents>
        <ModalActions>
          {isLoading && (
            <div className='w-26.75 flex h-10 flex-col items-center justify-center'>
              <SkeletonDots />
            </div>
          )}
          {!isLoading && (
            <Button
              autoFocus={true}
              type='submit'
              onClick={methods.handleSubmit(handleOnSubmit)}
            >
              Save Profile
            </Button>
          )}
          <Button variant='outlined' onClick={onDismiss}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    </FormProvider>
  )
}
