import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { AgeBandTypeEnum } from 'src/__generated__/graphql'
import type { PaxMixState } from './PaxMixState'
import { ExperiencesDetailsSearchParams } from './useExperienceDetailsSearchParams'
import { useExperienceProductDetails } from './useExperienceProductDetails'

export const useSearchParamPaxMixState = (): {
  paxMix: PaxMixState
  onUpdate: (ageBand: AgeBandTypeEnum, value: number) => void
  onClick: VoidFunction
} => {
  const { data } = useExperienceProductDetails()
  const [searchParams, updateSearchParams] = useSearchParams()
  const guests = searchParams.get(ExperiencesDetailsSearchParams.guests)

  const [paxMix, updatePaxMix] = useState<PaxMixState>({})

  // Sync paxMix from the URL but only after the data is available
  useEffect(() => {
    if (data?.pricingInfo?.ageBands) {
      const guestsObj = guests ? JSON.parse(guests) : {}
      const initialState = Object.fromEntries(
        data.pricingInfo.ageBands.map(({ ageBand }) => [
          ageBand,
          guestsObj?.[ageBand] || 0,
        ])
      )
      updatePaxMix(initialState)
    }
  }, [guests, data?.pricingInfo?.ageBands])

  const onUpdate = (ageBand: AgeBandTypeEnum, value: number) => {
    updatePaxMix(paxMix => ({
      ...paxMix,
      [ageBand]: value,
    }))
  }

  const onClick = () => {
    updateSearchParams(
      sp => {
        const paxMixCopy = { ...paxMix }
        for (let key in paxMixCopy) {
          // remove falsy values from paxMix
          if (!paxMixCopy[key]) delete paxMixCopy[key]
        }

        sp.set(
          ExperiencesDetailsSearchParams.guests,
          JSON.stringify(paxMixCopy)
        )
        return sp
      },
      { replace: true }
    )
  }

  return {
    paxMix,
    onUpdate,
    onClick,
  }
}
