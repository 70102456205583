import { Icon, Tag } from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import { EventStatus } from 'src/__generated__/graphql'
import { constructDisplayName, getDateFromUTC } from 'src/utils'
import { defaultEventData } from './eventCardConstants'
import { constructDisplayTime } from './eventCardUtils'

const bookedStatusToText = (bookedStatus: EventStatus) => {
  if (bookedStatus === EventStatus.Booked) return 'Booked'
  else if (bookedStatus === EventStatus.NotBooked) return 'Not Booked'
  else if (bookedStatus === EventStatus.Na) return 'Not Booked'
  else return null
}

export const EventCardContent = ({
  bookedStatus,
  name,
  notes,
  startTime,
  endTime,
  timezone,
  useEventTime,
}: {
  bookedStatus?: EventStatus
  name: string
  notes?: string
  startTime?: string
  endTime?: string
  timezone?: string
  useEventTime?: boolean
}) => {
  const displayName = constructDisplayName(name, defaultEventData.name)
  const getEndTime = dayjs(startTime).isSame(dayjs(endTime))
    ? null
    : getDateFromUTC(endTime, timezone)

  const isBooked = bookedStatus && bookedStatus === EventStatus.Booked
  const hasNotes = !isEmpty(notes)
  const showBadge = bookedStatus && bookedStatus !== EventStatus.Na

  const getDisplayTime = () => {
    if (useEventTime) {
      return constructDisplayTime({
        startTime: getDateFromUTC(startTime ?? '', timezone ?? ''),
        endTime: endTime ? getEndTime : null,
        timezone,
      })
    }
    return null
  }

  return (
    <div className='flex h-full w-full items-center justify-between'>
      <div className='max-w-10/12 flex flex-col gap-2 p-4'>
        <div className='flex items-center gap-2'>
          {useEventTime && (
            <p className='type-body-1 c-grey800'>{getDisplayTime()}</p>
          )}
          {showBadge && (
            <div className='hidden md:block'>
              <Tag
                color={isBooked ? 'valley' : 'newForestLight'}
                startIcon={isBooked ? 'check' : undefined}
                text={bookedStatusToText(bookedStatus) ?? ''}
              />
            </div>
          )}
        </div>
        <h3
          className={classNames('type-body-1 font-medium', {
            'line-clamp-1': hasNotes,
            'line-clamp-2': !hasNotes,
          })}
        >
          {displayName}
        </h3>
        {hasNotes && (
          <div className='c-newForest flex items-center gap-1'>
            <Icon name='stickyNote2' size='small' />
            <p className='type-body-2 c-newForest'>1 Note</p>
          </div>
        )}
      </div>
    </div>
  )
}
