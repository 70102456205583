const experiencesCancellationPolicyCardSubtitleCss =
  'color-grey-800 text-body-1'

const experiencesCancellationPolicyCardTitleCss = 'color-forest font-700'

const experiencesCancellationPolicyCardContentCss = 'flex flex-col w-full'

const experiencesCancellationPolicyCardCss =
  'bg-white b-grey-300 b-solid b-1 cursor-pointer flex gap-4 items-center p-4 rounded-2 text-body-1'

export {
  experiencesCancellationPolicyCardContentCss,
  experiencesCancellationPolicyCardCss,
  experiencesCancellationPolicyCardSubtitleCss,
  experiencesCancellationPolicyCardTitleCss,
}
