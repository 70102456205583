import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { getProductTypesAsObject } from 'src/utils'
import { TripDetailsExploreList } from './TripDetailsExploreList'
import { TripDetailsExploreListApolloLoading } from './TripDetailsExploreListApolloLoading'
import type {
  ExploreExperience,
  ExploreExperiencesResult,
  ExploreHotel,
  ExploreHotelsResult,
} from '../../constants'
import { ExploreSearchParams } from '../../constants'

import { TripDetailsExploreEmpty } from '../TripDetailsExploreEmpty'
import {
  TripDetailsExploreSearch,
  TripDetailsExploreSearchLoading,
} from '../TripDetailsExploreSearch'

interface TripDetailsExploreListApolloProps {
  experiencesResult: ExploreExperiencesResult
  hotelsResult: ExploreHotelsResult
  hoveredEventId?: string
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  onHoveredEventIdChange?(updatedHoveredId: string): void
}

export const TripDetailsExploreListApollo = ({
  experiencesResult,
  hotelsResult,
  hoveredEventId,
  tripDetailsData,
  onHoveredEventIdChange,
}: TripDetailsExploreListApolloProps) => {
  const [searchParams] = useSearchParams()
  const {
    data: experiencesData,
    error: hasExperiencesError,
    loading: isExperiencesLoading,
  } = experiencesResult ?? {}
  const {
    data: hotelsData,
    error: hasHotelsError,
    loading: isHotelsLoading,
  } = hotelsResult ?? {}
  const tripDetailsExploreExperiencesData =
    (experiencesData?.searchExperiences?.edges as ExploreExperience[]) ?? []
  const tripDetailsExploreHotelsData =
    (hotelsData?.searchHotelsWithoutRates
      ?.hotelSearchResults as ExploreHotel[]) ?? []
  const { isExperience } = getProductTypesAsObject(
    searchParams.get(ExploreSearchParams.productType)
  )
  const showError = isExperience ? hasExperiencesError : hasHotelsError

  const onRefetch = () => {
    if (isExperience) return experiencesResult?.refetch()

    return hotelsResult?.refetch()
  }

  if (isExperiencesLoading || isHotelsLoading)
    return (
      <>
        <TripDetailsExploreSearchLoading />
        <TripDetailsExploreListApolloLoading />
      </>
    )

  if (showError) return <TripDetailsExploreEmpty />

  return (
    <>
      <TripDetailsExploreSearch />
      <TripDetailsExploreList
        hoveredEventId={hoveredEventId}
        tripDetailsData={tripDetailsData}
        tripDetailsExploreExperiencesData={tripDetailsExploreExperiencesData}
        tripDetailsExploreHotelsData={tripDetailsExploreHotelsData}
        onHoveredEventIdChange={onHoveredEventIdChange}
        onRefetch={onRefetch}
      />
    </>
  )
}
