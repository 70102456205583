import type { WeatherIconMapping } from '@travelpass/design-system'
import { WeatherCondition } from 'src/__generated__/graphql'

export const getWeatherIcon = (
  weatherCondition: WeatherCondition
): keyof typeof WeatherIconMapping => {
  if (weatherCondition === WeatherCondition.Cloudy) return 'cloudy'

  if (weatherCondition === WeatherCondition.PartlyCloudy) return 'partlyCloudy'

  if (weatherCondition === WeatherCondition.Rain) return 'showers'

  if (weatherCondition === WeatherCondition.Snow) return 'snow'

  if (weatherCondition === WeatherCondition.Sunny) return 'sunny'

  return 'thunderstorms'
}
