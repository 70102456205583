import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import type {
  CurrentUserExperienceBookingsQueryQuery,
  CurrentUserHotelBookingsQueryQuery,
} from 'src/__generated__/graphql'
import {
  BookingTimeState,
  ExperienceBookingTimeState,
} from 'src/__generated__/graphql'
import { currentUserExperienceBookingsGQL } from '../bookings/MyBookings/ExperienceBookings/experienceBookingsGql'
import { currentUserHotelBookingsGQL } from '../bookings/MyBookings/HotelBookings/hotelBookingsGql'

type UseGetDashboardBookingsQuery = {
  hasError: ApolloError
  experienceBookingsPastData: CurrentUserExperienceBookingsQueryQuery['currentUser']['experienceBookings']['edges']
  experienceBookingsUpcomingData: CurrentUserExperienceBookingsQueryQuery['currentUser']['experienceBookings']['edges']
  hotelBookingsPastData: CurrentUserHotelBookingsQueryQuery['currentUser']['hotelBookings']['edges']
  hotelBookingsUpcomingData: CurrentUserHotelBookingsQueryQuery['currentUser']['hotelBookings']['edges']
  isLoading: boolean
}

/**
 * @todo
 * add paginatation or BE removes the first argument
 * request BE return upcoming and past bookings in one query
 *
 * */
export const useGetDashboardBookingsQuery =
  (): UseGetDashboardBookingsQuery => {
    const {
      data: initialHotelBookingsPastData,
      error: hasHotelBookingsPastError,
      loading: isHotelBookingsPastLoading,
    } = useQuery(currentUserHotelBookingsGQL, {
      variables: {
        first: 100,
        searchUserHotelBookingsArgs: {
          bookingTimeState: BookingTimeState.Past,
        },
      },
    })
    const {
      data: initialHotelBookingsUpcomingData,
      error: hasHotelBookingsUpcomingError,
      loading: isHotelBookingsUpcomingLoading,
    } = useQuery(currentUserHotelBookingsGQL, {
      variables: {
        first: 100,
        searchUserHotelBookingsArgs: {
          bookingTimeState: BookingTimeState.Upcoming,
        },
      },
    })
    const {
      data: initialExperienceBookingsUpcomingData,
      error: hasExperienceBookingsUpcomingError,
      loading: isExperienceBookingsUpcomingLoading,
    } = useQuery(currentUserExperienceBookingsGQL, {
      variables: {
        first: 100,
        searchUserExperienceBookingsArgs: {
          bookingTimeState: ExperienceBookingTimeState.Upcoming,
        },
      },
    })
    const {
      data: initialExperienceBookingsPastData,
      error: hasExperienceBookingsPastError,
      loading: isExperienceBookingsPastLoading,
    } = useQuery(currentUserExperienceBookingsGQL, {
      variables: {
        first: 100,
        searchUserExperienceBookingsArgs: {
          bookingTimeState: ExperienceBookingTimeState.Past,
        },
      },
    })
    const hasError =
      hasHotelBookingsPastError ||
      hasHotelBookingsUpcomingError ||
      hasExperienceBookingsUpcomingError ||
      hasExperienceBookingsPastError
    const hotelBookingsPastData =
      initialHotelBookingsPastData?.currentUser?.hotelBookings?.edges ?? []
    const hotelBookingsUpcomingData =
      initialHotelBookingsUpcomingData?.currentUser?.hotelBookings?.edges ?? []
    const experienceBookingsUpcomingData =
      initialExperienceBookingsUpcomingData?.currentUser?.experienceBookings
        ?.edges ?? []
    const experienceBookingsPastData =
      initialExperienceBookingsPastData?.currentUser?.experienceBookings
        ?.edges ?? []
    const isLoading =
      isHotelBookingsPastLoading ||
      isHotelBookingsUpcomingLoading ||
      isExperienceBookingsUpcomingLoading ||
      isExperienceBookingsPastLoading

    return {
      experienceBookingsPastData,
      experienceBookingsUpcomingData,
      hasError,
      hotelBookingsPastData,
      hotelBookingsUpcomingData,
      isLoading,
    }
  }
