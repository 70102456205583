import { useState } from 'react'
import {
  Button,
  Dropdown,
  DropdownOption,
  IconButton,
  Modal,
} from '@travelpass/design-system'
import { BookingTimeState } from 'src/__generated__/graphql'
import { SearchBooking } from 'src/common/components'
import { DashboardBookingsEmpty } from './DashboardBookingsEmpty'
import { DashboardBookingsLoading } from './DashboardBookingsLoading'
import { DashboardBookingsPast } from './DashboardBookingsPast'
import { DashboardBookingsUpcoming } from './DashboardBookingsUpcoming'
import { useGetDashboardBookingsQuery } from '../../useGetDashboardBookingsQuery'

export const DashboardBookings = () => {
  /** @todo Build this query so that we are only fetching what is being rendered (past/upcoming) based on the selected bookingsTimestate */
  const {
    experienceBookingsUpcomingData,
    experienceBookingsPastData,
    hasError,
    hotelBookingsPastData,
    hotelBookingsUpcomingData,
    isLoading,
  } = useGetDashboardBookingsQuery()
  const [bookingsTimeState, setBookingsTimeState] = useState<BookingTimeState>(
    BookingTimeState.Upcoming
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => setIsModalOpen(false)

  const onModalOpen = () => setIsModalOpen(true)

  if (isLoading) return <DashboardBookingsLoading />

  if (hasError) return <DashboardBookingsEmpty />

  return (
    <>
      <section className='space-y-6'>
        <div className='flex flex-row items-center justify-between gap-2'>
          <div className='md:min-w-50 min-w-35'>
            <Dropdown
              fullWidth={true}
              name='Bookings TimeState'
              value={bookingsTimeState}
              onChange={event => {
                setBookingsTimeState(event as BookingTimeState)
              }}
            >
              <DropdownOption key={0} value={BookingTimeState.Upcoming}>
                Upcoming
              </DropdownOption>
              <DropdownOption key={1} value={BookingTimeState.Past}>
                Past
              </DropdownOption>
            </Dropdown>
          </div>
          <div className='hidden md:block'>
            <Button
              aria-label='Find a booking'
              size='small'
              variant='outlined'
              onClick={onModalOpen}
            >
              Find Booking
            </Button>
          </div>
          <div className='md:hidden'>
            <IconButton
              color='forestLight'
              icon='search'
              outline='round'
              size='small'
              onClick={onModalOpen}
            />
          </div>
        </div>
        {bookingsTimeState === BookingTimeState.Upcoming && (
          <DashboardBookingsUpcoming
            experienceBookingsUpcomingData={experienceBookingsUpcomingData}
            hotelBookingsUpcomingData={hotelBookingsUpcomingData}
          />
        )}
        {bookingsTimeState === BookingTimeState.Past && (
          <DashboardBookingsPast
            experienceBookingsPastData={experienceBookingsPastData}
            hotelBookingsPastData={hotelBookingsPastData}
          />
        )}
      </section>
      {isModalOpen && (
        <Modal size='medium' title='Find Booking' onDismiss={onModalClose}>
          <SearchBooking showTitle={false} />
        </Modal>
      )}
    </>
  )
}
