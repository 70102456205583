import { useState } from 'react'
import { MarkerF } from '@react-google-maps/api'
import { Button } from '@travelpass/design-system'
import { google as googleCalendarLink } from 'calendar-link'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import {
  DateSummary,
  HotelCheckInInstructionsModal,
} from 'src/common/components'
import { Map, constructMapCenter } from 'src/common/components/Map'
import { isOwner, isPublic } from 'src/pages/trips/utils/permissionUtils'
import { constructAddress } from 'src/utils'
import {
  bookingDetailsOverviewAddressCss,
  bookingDetailsOverviewCss,
  bookingDetailsOverviewDatesCss,
  bookingDetailsOverviewDividerCss,
  bookingDetailsOverviewLocationCss,
  bookingDetailsOverviewMapCss,
} from './bookingDetailsOverviewStyles'
import {
  useBookingDetailsDatesQuery,
  useBookingDetailsIdsQuery,
  useBookingDetailsLocationQuery,
  useBookingDetailsPoliciesQuery,
} from '../../hooks'
import { BookingDetailsShareModal } from '../BookingDetailsShareModal'

export const BookingDetailsOverview = () => {
  const { bookingId } = useParams()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const [isBookingDetailsShareModalOpen, setIsBookingDetailsShareModalOpen] =
    useState(false)
  const [
    isHotelCheckInInstructionsModalOpen,
    setIsHotelCheckInInstructionsModalOpen,
  ] = useState(false)
  const { hotelId, trip } = bookingDetailsIdsData ?? {}
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({ bookingId })
  const { bookingDetailsLocationData } = useBookingDetailsLocationQuery({
    hotelId,
  })
  const { bookingDetailsPoliciesHotelData } = useBookingDetailsPoliciesQuery({
    bookingId,
    hotelId,
  })
  const { arrival, departure } = bookingDetailsDatesData ?? {}
  const {
    address,
    city,
    googlePlaceIds,
    latitude,
    longitude,
    name,
    postalCode,
    stateCode,
  } = bookingDetailsLocationData ?? {}
  const { checkin, checkout, checkinInstructions } =
    bookingDetailsPoliciesHotelData ?? {}
  const constructedAddress = constructAddress({
    addressFirstLine: address,
    city,
    state: stateCode,
    zipcode: postalCode,
  })
  const showMap = !!latitude && !!longitude

  const displayShareBtn =
    isOwner(trip?.currentUserRole) || isPublic(trip?.currentUserRole)

  const onCalendarButtonClick = () =>
    window.open(
      googleCalendarLink({
        allDay: true,
        end: dayjs(departure).endOf('day'),
        start: arrival,
        title: `Stay at ${name}`,
      })
    )

  const onDirectionsButtonClick = () => {
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      constructedAddress
    )}`
    const googlePlaceId = googlePlaceIds?.[0]

    if (googlePlaceId)
      return window.open(
        `${googleMapUrl}&query_place_id=${googlePlaceId}`,
        '_blank'
      )

    return window.open(googleMapUrl, '_blank')
  }

  return (
    <>
      {isBookingDetailsShareModalOpen && (
        <BookingDetailsShareModal
          onClose={() => setIsBookingDetailsShareModalOpen(false)}
        />
      )}
      {isHotelCheckInInstructionsModalOpen && (
        <HotelCheckInInstructionsModal
          checkin={checkin}
          checkinInstructions={checkinInstructions}
          onClose={() => setIsHotelCheckInInstructionsModalOpen(false)}
        />
      )}
      <div
        className={bookingDetailsOverviewCss}
        data-testid='BookingDetailsOverview'
      >
        <div className={bookingDetailsOverviewDatesCss}>
          <DateSummary
            arrival={arrival}
            checkin={checkin}
            checkout={checkout}
            departure={departure}
          />
          <div className='hide-on-print'>
            <Button
              label='Check-in instructions'
              size='small'
              startIcon='roomService'
              variant='text'
              onClick={() => setIsHotelCheckInInstructionsModalOpen(true)}
            />
          </div>
          <div className='hide-on-print'>
            <Button
              label='Add to calendar'
              size='small'
              startIcon='event'
              variant='text'
              onClick={onCalendarButtonClick}
            />
          </div>
          {displayShareBtn && (
            <div className='hide-on-print'>
              <Button
                label='Share Booking'
                size='large'
                startIcon='share'
                onClick={() => setIsBookingDetailsShareModalOpen(true)}
              />
            </div>
          )}
        </div>
        <div className={bookingDetailsOverviewDividerCss} />
        <div className={bookingDetailsOverviewLocationCss}>
          {showMap && (
            <div
              className={bookingDetailsOverviewMapCss}
              data-testid='BookingDetailsOverview-map'
            >
              <Map
                defaultCenter={constructMapCenter(latitude, longitude)}
                defaultZoom={18}
                enableScrollWheel={false}
              >
                <MarkerF
                  icon='https://static.travelpass.com/assets/map-pin.png'
                  position={{
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }}
                />
              </Map>
            </div>
          )}
          <p className={bookingDetailsOverviewAddressCss}>
            {constructedAddress}
          </p>
          <div className='hide-on-print'>
            <Button
              label='Get directions'
              size='small'
              startIcon='nearMe'
              variant='text'
              onClick={onDirectionsButtonClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}
