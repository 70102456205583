import { Skeleton } from '@travelpass/design-system'
import {
  bookingDetailsHeroActionsCss,
  bookingDetailsHeroContentCss,
  bookingDetailsHeroCss,
  bookingDetailsHeroImageCss,
} from './bookingDetailsHeroStyles'

export const BookingDetailsHeroApolloLoading = () => (
  <div className={bookingDetailsHeroCss}>
    <div className={bookingDetailsHeroContentCss}>
      <div className='md:h-38px h-6 md:w-1/2'>
        <Skeleton />
      </div>
      <div className='h-18px md:h-5'>
        <Skeleton />
      </div>
      <div className={bookingDetailsHeroImageCss}>
        <Skeleton />
      </div>
    </div>
    <div className={bookingDetailsHeroActionsCss}>
      <div className='h-40px w-100px'>
        <Skeleton />
      </div>
      <div className='h-40px w-100px'>
        <Skeleton />
      </div>
    </div>
  </div>
)
