import { useState } from 'react'
import { Carousel } from '@travelpass/design-system'
import type { CarouselProps } from '@travelpass/design-system'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import type { MappedImage } from 'src/__generated__/graphql'
import { hotelRoomCarouselHeight } from './hotelRoomCarouselConstants'
import { constructHotelRoomImages } from '../../hotelRoomUtils'
import type { HotelRoom } from '../../hotelRoomsTypes'

interface HotelRoomCarouselProps {
  afterChange?: CarouselProps['afterChange']
  altSuffix: string
  height?: string
  hideDots?: boolean
  images: HotelRoom['images']
  isModal?: boolean
  keyBoardControl?: CarouselProps['keyBoardControl']
  maxWidth?: string
  slideIndex?: CarouselProps['slideIndex']
  onClick?(updatedIsCarouselDragging: boolean): void
  onImageError?(errorImage: MappedImage): void
}

export const HotelRoomCarousel = ({
  afterChange = () => {},
  altSuffix = '',
  height = hotelRoomCarouselHeight,
  hideDots = false,
  images,
  isModal = false,
  keyBoardControl,
  maxWidth = '',
  slideIndex,
  onClick = () => {},
  onImageError = () => {},
}: HotelRoomCarouselProps) => {
  const [isCarouselDragging, setIsCarouselDragging] = useState(false)
  const constructImages = constructHotelRoomImages({
    hideDots,
    images,
    size: 'large',
  })
  const constructedImages = constructImages.map(image => {
    const { caption, href } = image ?? {}
    const constructedCaption = caption ? `${caption} - ` : ''
    const constructedAlt = `${constructedCaption}${altSuffix}`

    return (
      <img
        key={constructedAlt}
        alt={constructedAlt}
        className={hotelRoomCarouselImageCss({ height })}
        draggable={false}
        loading='lazy'
        src={href}
        onError={() => onImageError(image)}
      />
    )
  })

  const hasSingleImage = constructedImages.length === 1

  const onAfterChange = (previousSlide, state) => {
    afterChange(previousSlide, state)
    setIsCarouselDragging(false)
  }

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <div
      className={hotelRoomCarouselCss({ maxWidth })}
      onClick={() => onClick(isCarouselDragging)}
    >
      <Carousel
        isLight
        afterChange={onAfterChange}
        beforeChange={() => setIsCarouselDragging(true)}
        borderRadius={14}
        hideArrows={hasSingleImage}
        hideDots={hideDots || hasSingleImage}
        keyBoardControl={keyBoardControl}
        showArrowsOnHover={isModal ? true : !isMobile}
        size={isModal ? 'medium' : 'small'}
        slideIndex={slideIndex}
      >
        {constructedImages}
      </Carousel>
    </div>
  )
}

const hotelRoomCarouselCss = ({ maxWidth }: Partial<HotelRoomCarouselProps>) =>
  classNames('h-fit w-full', maxWidth)

const hotelRoomCarouselImageCss = ({
  height,
}: Partial<HotelRoomCarouselProps>) =>
  classNames('flex flex-col object-cover w-full', height)
