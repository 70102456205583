import { colors } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { GenericAddress } from 'src/__generated__/graphql'
import { stringToNumber } from 'src/utils'
import type { StyledMarkerVariant } from '../components/TripDetailsMap/markers'
import type { TripAnchorLocation } from '../types'

const getAnchorLocation = (addresses: GenericAddress[]): TripAnchorLocation => {
  if (!isEmpty(addresses)) {
    const [address] = addresses ?? []

    return {
      ...address,
      lat: stringToNumber(address?.lat ?? '0'),
      lng: stringToNumber(address?.long ?? '0'),
    }
  }

  return null
}

const getMarkerColors = ({
  isHovered,
  isSelected,
  variant = 'timeline',
}: {
  isHovered: boolean
  isSelected: boolean
  variant?: StyledMarkerVariant
}): { fill: string; stroke: string } => {
  if (isHovered || isSelected)
    return {
      fill: colors.valley,
      stroke: colors.valley,
    }

  if (variant === 'explore')
    return {
      fill: colors.orange,
      stroke: colors.orange,
    }

  return {
    fill: colors.newForest,
    stroke: colors.newForest,
  }
}

export { getAnchorLocation, getMarkerColors }
