import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import type { ExperienceAdditionalInfo } from 'src/__generated__/graphql'
import { SectionHeader } from 'src/common/components'
import { AdditonalInfoModal } from './AdditionalInfoModal'

interface AdditionalInfoProps {
  infoItems: ExperienceAdditionalInfo[]
}

export const AdditionalInfo = ({ infoItems }: AdditionalInfoProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      {isModalOpen && (
        <AdditonalInfoModal
          infoItems={infoItems}
          onDismiss={() => setIsModalOpen(false)}
        />
      )}
      <section className='flex flex-col gap-y-12'>
        <header className='space-y-2 md:flex md:flex-row md:justify-between'>
          <SectionHeader title='Additional Info' />
          {infoItems?.length > 6 && (
            <div className='hidden md:block'>
              <Button
                aria-label='View more additional information items'
                label='View More'
                size='medium'
                variant='outlined'
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          )}
        </header>
        <ul className='type-body-1 color-grey-800 space-y-3 pl-4 md:columns-2 md:gap-20'>
          {infoItems
            ?.slice(0, 6)
            .map((item, index) => <li key={index}>{item?.description}</li>)}
        </ul>
        {infoItems?.length > 6 && (
          <div className='mx-auto md:hidden'>
            <Button
              aria-label='View more additional information items'
              label='View More'
              size='small'
              variant='outlined'
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        )}
      </section>
    </>
  )
}
