import { useState } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  TripExploreHeader,
  TripExploreMap,
  TripExploreList,
  TripExploreLoading,
  TripExploreActions,
} from './common'
import {
  TripContainer,
  TripContentWrapper,
  TripMapWrapper,
  TripNav,
  TripTopNav,
} from '../components'
import { useGetTripDetailsQuery } from '../hooks'
import { getAnchorLocation } from '../utils/mapUtils'

/** @todo hasEmpty (check with product). */
export const TripExplore = () => {
  const { tripId } = useParams()
  const { isLoading, tripDetailsData } = useGetTripDetailsQuery(tripId)
  const [placePoints, setPlacePoints] = useState<
    google.maps.places.PlaceResult[]
  >([])
  const [searchParams] = useSearchParams()
  const { name, tripPreference } = tripDetailsData ?? {}
  const { addresses } = tripPreference ?? {}
  const anchorLocation = getAnchorLocation(addresses)
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'

  if (isLoading || isEmpty(tripDetailsData)) return <TripExploreLoading />

  return (
    <>
      <TripTopNav tripDetailsData={tripDetailsData} />
      <TripContainer>
        {/** @todo convert back to className */}
        {!mobileMapView && (
          <TripContentWrapper>
            <TripNav name={name} />
            <TripExploreHeader
              tripDetailsData={tripDetailsData}
              onPlacePointsChange={updatedPlacePoints =>
                setPlacePoints(updatedPlacePoints)
              }
            />
            <TripExploreList />
          </TripContentWrapper>
        )}
        {anchorLocation && (
          <div
            className={classNames('grow lg:block', {
              hidden: !mobileMapView,
            })}
          >
            <TripMapWrapper isDrawerOpen={false}>
              <TripExploreMap
                placePoints={placePoints}
                tripDetailsData={tripDetailsData}
              />
            </TripMapWrapper>
          </div>
        )}
        <div
          className={classNames('block lg:hidden', {
            hidden: mobileMapView,
          })}
        >
          <TripExploreActions />
        </div>
      </TripContainer>
    </>
  )
}
