import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const nodeQuery = gql(`
  query GetPlaceDetails($googlePlaceId: String!) {
    getPlaceDetails(placeDetailsRequest: {
      placeId: $googlePlaceId
    }) {
      formattedAddress   
    }
  }
`)

export const useGetGooglePlaceForContentCard = ({
  destination,
  googlePlaceId,
}: {
  destination: string
  googlePlaceId: string
}) => {
  const { data, loading, error } = useQuery(nodeQuery, {
    variables: {
      googlePlaceId,
    },
    skip: !!destination,
  })

  return {
    googlePlace: data?.getPlaceDetails,
    loading,
    error,
  }
}
