import { type AvailableIcons } from '@travelpass/design-system'
import classNames from 'classnames'

type NativeButton = React.ComponentPropsWithoutRef<'button'>

interface UnderlineTabProps extends NativeButton {
  title: string
  subtitle?: string
  isDisabled?: boolean
  isSelected?: boolean
  isTitleCase?: boolean
  icon?: AvailableIcons
}
export const DateTab = ({
  title,
  subtitle,
  isSelected = false,
  isTitleCase = false,
  onClick,
  ...props
}: UnderlineTabProps) => {
  const disabled = props?.disabled

  return (
    <div className='snap-start snap-normal'>
      <button
        {...props}
        aria-selected={isSelected}
        className={classNames(
          'aria-selected:b-new-forest aria-selected:bg-mint-light aria-selected:c-new-forest',
          'b-transparent c-grey-500 b-1px b-solid rounded-3 p-b-2 p-t-3 min-h-15 hover:c-grey800 focus:b-new-forest flex h-full min-w-14 cursor-pointer flex-col items-center whitespace-nowrap bg-transparent outline-none transition-all duration-200 ease-linear focus:outline-none',
          {
            'c-grey300': disabled,
            'c-transparent': disabled && isSelected,
            'hover:bg-op-60 hover:c-new-forest': isSelected,
          }
        )}
        role='tab'
        onClick={onClick}
      >
        <span className='flex items-center gap-1'>
          <h3 className='type-body-1-medium c-inherit font-semibold'>
            {title}
          </h3>
        </span>
        {subtitle && (
          <p className='type-body-1-medium c-inherit p-b-.5 font-semibold'>
            {subtitle}
          </p>
        )}
        {/** @todo current day button below: */}
        {/* <div className='w-1 h-1 rounded-1/2 bg-new-forest' /> */}
      </button>
    </div>
  )
}
