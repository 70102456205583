import { useId } from 'react'
import type { DropdownProps } from '@travelpass/design-system'
import { Dropdown } from '@travelpass/design-system'
import { useController } from 'react-hook-form'
import type { UseControllerProps } from 'react-hook-form'

type SupportedDropdownProps = Omit<DropdownProps, 'name' | 'required'>

type FormDropdownProps<N> = {
  name: UseControllerProps<N>['name']
  defaultValue?: UseControllerProps['defaultValue']
  required?: boolean | string
} & SupportedDropdownProps

export const FormDropdown = <N,>({
  name,
  defaultValue,
  isDisabled = false,
  required,
  label,
  ...props
}: FormDropdownProps<N>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    rules: {
      required,
    },
  })

  const id = useId()
  const hasError = !!error
  const errorContentId = hasError ? `${id}-error` : null

  return (
    <div>
      <Dropdown
        aria-errormessage={errorContentId}
        id={id}
        isDisabled={isDisabled}
        label={label}
        name={field.name}
        required={Boolean(required)}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        {...props}
      />
      {hasError && (
        <div className='color-error type-subtext' id={errorContentId}>
          {error?.message}
        </div>
      )}
    </div>
  )
}
