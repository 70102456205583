export const FallbackImage = ({ src, alt, className, fallbackSrc }) => {
  const onError = e => {
    e.target.onerror = null
    e.target.src = fallbackSrc
  }

  return (
    <img
      alt={alt}
      className={className}
      src={src ?? fallbackSrc}
      onError={onError}
    />
  )
}
