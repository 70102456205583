import { Skeleton } from '@travelpass/design-system'

export const DashboardGuideOldCardLoading = () => (
  <div className='rounded-3 border-grey-300 of-hidden shadow-3 relative border-2 border-solid hover:cursor-pointer'>
    <div className='relative flex flex-col justify-between gap-4 p-3 md:min-h-52 md:flex-row md:justify-start lg:gap-6'>
      {/* Top (Mobile) Picture */}
      <div className='h-44 w-full overflow-hidden rounded-lg md:hidden'>
        <Skeleton />
      </div>
      {/* Lefthand (Desktop) Picture */}
      <div className='rounded-3 max-w-50 min-w-50 hidden overflow-hidden md:block'>
        <Skeleton />
      </div>
      <div className='flex grow flex-col gap-3 lg:gap-4'>
        <div className='space-y-3'>
          <div className='flex min-w-0 flex-col gap-2'>
            <div className='flex min-h-12 flex-row items-center justify-between gap-2'>
              <div className='h-5.25 inline-block w-40 lg:h-5'>
                <Skeleton />
              </div>
              <div className='h-6 w-6'>
                <Skeleton />
              </div>
            </div>
            <div className='flex min-h-5 flex-row items-center gap-2'>
              <div className='h-4 w-4'>
                <Skeleton />
              </div>
              <div className='w-25 h-4'>
                <Skeleton />
              </div>
            </div>
          </div>
          <div className='lg:h-13.5 h-9 w-full'>
            <Skeleton />
          </div>
        </div>
        <div className='mt-a flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
          <div className='h-4 w-6'>
            <Skeleton />
          </div>
          <div className='h-4 w-6'>
            <Skeleton />
          </div>
          <div className='h-4 w-6'>
            <Skeleton />
          </div>
          <div className='h-4 w-6'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
)
