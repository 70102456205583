import { capitalizeFirstLetterOfWords } from 'src/utils'
import type { DestinationType } from './destinationConstants'
import { destinations } from './destinationConstants'

export const constructDestination = ({
  city,
  stateCode,
}: {
  city: string
  stateCode: string
}): DestinationType => {
  const updatedCity = capitalizeFirstLetterOfWords(
    city.replaceAll('-', ' ')
  ).replaceAll(' ', '')

  return destinations?.[`${stateCode}${updatedCity}`]
}
