import { Skeleton } from '@travelpass/design-system'
import {
  bookingConfirmationTripsContentCss,
  bookingConfirmationTripsCss,
} from './bookingConfirmationTripsStyles'

export const BookingConfirmationTripsApolloLoading = () => {
  return (
    <div className={bookingConfirmationTripsCss(true)}>
      <div className='h-22 w-47 max-w-full'>
        <Skeleton variant='rounded' />
      </div>
      <div className={bookingConfirmationTripsContentCss}>
        <div className='flex flex-col gap-4'>
          <div className='h-21px md:h-34px w-40'>
            <Skeleton variant='rounded' />
          </div>
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <div key={index} className='h-18px flex w-full flex-col md:h-5'>
                <Skeleton />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
