import { useEffect } from 'react'
import Cookies from 'js-cookie'
import load from 'load-script'
import { useLocation } from 'react-router-dom'
import { FORTER_SCRIPT } from './script'

export const ForterProtection = () => {
  const location = useLocation()

  const xRsClientCode = Cookies.get('RS-CLIENT')

  // Priceline (Forter) Fraud Protection
  useEffect(() => {
    if (!xRsClientCode) {
      load(FORTER_SCRIPT, error => {})
    }
  }, [xRsClientCode, location])

  return null
}
