import {
  CollectionItemIdType,
  type ExperienceProduct,
} from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { ShareAndSaveButtons } from 'src/common/components/ShareAndSaveButtons'
import { getImageSrcSet } from 'src/utils'
import { HeroImages } from './HeroImages'
import { ReviewBadge } from '../ReviewBadge'

interface HeroProps {
  heroData: ExperienceProduct
}

export const Hero = ({ heroData }: HeroProps) => {
  const { id, images, reviews, title } = heroData ?? {}
  const { combinedAverageRating, totalReviews } = reviews ?? {}
  const { size674x446: mobileImages, size720x480: desktopImages } = images ?? {}
  const imagesSrcSet = []

  const largerImageArray =
    mobileImages?.length > desktopImages?.length ? mobileImages : desktopImages
  for (let i = 0; i < largerImageArray.length; i++) {
    const imgSrcSet = getImageSrcSet({
      desktop: desktopImages[i] ?? '',
      mobile: mobileImages[i] ?? '',
    })

    imagesSrcSet.push(imgSrcSet)
  }

  return (
    <>
      <HeroImages
        images={[images.size720x480, images.size674x446]}
        imagesSrcSet={imagesSrcSet}
        title={title}
      />
      <PageLayoutContainer>
        <div className='flex flex-col justify-between gap-8 md:flex-row'>
          <section className='md:max-w-50% lg:max-w-60% flex flex-col gap-2'>
            <h1 className='type-h3-desktop line-clamp-2'>{title}</h1>
            {!!combinedAverageRating || !!totalReviews ? (
              <ReviewBadge
                customerReviewScore={combinedAverageRating}
                maxScore={5}
                totalCustomerReviews={totalReviews}
              />
            ) : (
              <span className='text-gray'>No customer reviews</span>
            )}
          </section>
          <ShareAndSaveButtons
            item={{ id, name: title, type: CollectionItemIdType.Experience }}
            product={heroData}
          />
        </div>
      </PageLayoutContainer>
    </>
  )
}
