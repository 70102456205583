import { defaultLocationImage } from 'src/constants'
import { constructImageLink, formatImageLink } from 'src/utils'

const getEventImage = (
  imageUrl?: string,
  size?: 'small' | 'medium'
): null | string => {
  const images: { constructedImg: null | string } = { constructedImg: null }
  const maxHeight = size === 'medium' ? 700 : 250

  // Default Image
  if (!imageUrl || imageUrl === defaultLocationImage)
    return defaultLocationImage

  if (imageUrl) {
    // Add https
    images.constructedImg = formatImageLink(imageUrl)

    // TPG Hotel Images
    if (imageUrl.includes('img')) {
      images.constructedImg = constructImageLink(images.constructedImg)
    }

    // Google Place Event Images
    if (imageUrl?.includes('googleapis')) {
      const [domain, searchParams] = images.constructedImg.split('?')

      images.constructedImg = `${domain}?maxheight=${maxHeight}&${searchParams}`
    }
  }

  return images.constructedImg
}

export { getEventImage }

// Pre formatted TPG Hotel Image
// img.int.travelpass.com/hotel/625673c/60007078/0
// Formatted TPG Hotel Image
// https://img.int.travelpass.com/hotel/625673c/60007078/0/320x200

// Pre formatted Viator Image
// https://hare-media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/06/8e/3b/76.jpg
// Formatted Viator Image
// https://hare-media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/06/8e/3b/76.jpg

// Pre formatted Google Image
// https://maps.googleapis.com/maps/api/place/photo?photo_reference=ATJ83zhiIzx0FG8xyBVBh3EGAwFE8BguX7U55qrvkIW8YQ6Re3XVCsh3To3QlEIox2L6zBCiPsN8EtKJvpNPcUv2ufmbckZVXfxdxq1HJ3VSSBjGFKYn_nQFKNNePEbH3SdAicjPcs9LfWrRBySIVQjYhOEDwLrz6r1pQhms4Ymjl6eVFO51&key=AIzaSyDDPLLIno1jXP5Wgf8ttDUzYXuad6U2r2k
// Formatted Google Image
// https://maps.googleapis.com/maps/api/place/photo?maxheight-250&photo_reference=ATJ83zhiIzx0FG8xyBVBh3EGAwFE8BguX7U55qrvkIW8YQ6Re3XVCsh3To3QlEIox2L6zBCiPsN8EtKJvpNPcUv2ufmbckZVXfxdxq1HJ3VSSBjGFKYn_nQFKNNePEbH3SdAicjPcs9LfWrRBySIVQjYhOEDwLrz6r1pQhms4Ymjl6eVFO51&key=AIzaSyDDPLLIno1jXP5Wgf8ttDUzYXuad6U2r2k

// Pre formatted Default Img
// https://static.travelpass.com/assets/trip-state-pics/defaultEventImg.webp
// Formatted Default Img
// https://static.travelpass.com/assets/trip-state-pics/defaultEventImg.webp
