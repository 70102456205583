import type { ReactNode } from 'react'
import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { createPortal } from 'react-dom'

/** @todo remove and use design-system drawer */
export const TripDrawer = ({
  children,
  isOpen,
  onClose,
}: {
  children: ReactNode
  isOpen: boolean
  onClose(): void
}) => (
  <>
    {createPortal(
      <div
        className={classNames(
          'z-5 h-100dvh fixed bottom-0 left-0 right-0 top-0 block w-full bg-white lg:hidden',
          {
            hidden: !isOpen,
            'visible fixed bottom-0 bg-white': isOpen,
          }
        )}
      >
        <div className='flex justify-end'>
          <IconButton icon='clear' size='large' onClick={onClose} />
        </div>
        {children}
      </div>,
      document.body
    )}
    <div
      className={classNames(
        'b-0 transition-transform-175 w-119 z-2 lg:top-77px lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 fixed relative bottom-0 hidden bg-white ease-[cubic-bezier(0,0,0.2,1)] lg:sticky lg:block lg:h-[calc(100svh-77px)]',
        {
          '-translate-x-119': !isOpen,
        }
      )}
    >
      <div className='flex justify-end'>
        <IconButton icon='clear' size='large' onClick={onClose} />
      </div>
      {children}
    </div>
  </>
)
