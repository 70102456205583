import { Link, Modal } from '@travelpass/design-system'
import { privacyPath } from 'src/constants'

interface BookingSendSmsTermsModalProps {
  onClose: () => void
}

export const BookingSendSmsTermsModal = ({
  onClose,
}: BookingSendSmsTermsModalProps) => {
  return (
    <Modal size='medium' title='SMS Terms and conditions' onDismiss={onClose}>
      <div className='type-body-1 c-grey-800'>
        <div className='space-y-4'>
          <p>
            TravelPass.com may provide you with an opportunity to opt in to
            receive special offers and other text messages on your wireless
            telephone. By opting in, you consent to receiving autodialed,
            telemarketing text or e-mail messages at the telephone number,
            including Short Message Service (SMS) messages.
          </p>
          <p>
            By opting in, you accept and agree to be bound by
            TravelPass.com&apos;s web site privacy policy (&quot;Web Site
            Privacy Policy&quot;), and any other applicable terms, and you
            consent to the processing of your information as set forth in those
            policies. We strongly urge you to review the Web Site Privacy Policy
            in its entirety{' '}
            <Link label='here' onClick={() => window.open(privacyPath)} />.
          </p>
        </div>
      </div>
    </Modal>
  )
}
