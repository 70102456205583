import { Skeleton, SkeletonDots } from '@travelpass/design-system'

// TODO update to match design
export const EventCardLoading = () => (
  <div
    className='group flex flex-row items-center'
    data-testid='booking-card-loading-skeleton'
  >
    <div className='h-98px w-full'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
