import { useMemo } from 'react'
import type { UnderlineTabProps } from '@travelpass/design-system'
import { UnderlineTab } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { getProductTypesAsObject } from 'src/utils'
import { ExploreSearchParams } from '../../constants'

interface TripDetailsExploreNavProps {
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripDetailsExploreNav = ({
  tripDetailsData,
}: TripDetailsExploreNavProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const productType = searchParams.get(ExploreSearchParams.productType)
  const activeIndex = useMemo(() => {
    const { isExperience } = getProductTypesAsObject(productType ?? '')

    return isExperience ? 1 : 0
  }, [productType])
  const { name } = tripDetailsData ?? {}

  const onClick = event => {
    const { innerText } = event.currentTarget ?? {}
    const updatedProductType =
      innerText.toLowerCase() === 'hotels'
        ? EventType.Stay
        : EventType.Experience

    if (updatedProductType !== productType) {
      searchParams.delete(ExploreSearchParams.activeExploreId)
      searchParams.set(ExploreSearchParams.productType, updatedProductType)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }

  const tabs: UnderlineTabProps['tabs'] = [
    {
      label: 'Hotels',
      onClick,
    },
    {
      label: 'Experiences',
      onClick,
    },
  ]

  return (
    <div className='flex flex-col items-center gap-8 text-center'>
      <UnderlineTab activeIndex={activeIndex} tabs={tabs} willScroll={false} />
    </div>
  )
}
