import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { getEventImage } from 'src/pages/trips/utils'
import { getDateFromUTC, getTimeFromUTC } from 'src/utils'
import { defaultDrawerEvent } from '../../constants'
import type { EventNode, PlaceNode } from '../constants'

const convertToStringArray = (array?: (string | null)[] | null) => {
  if (array && !isEmpty(array)) {
    return array.reduce((result: string[], currentValue) => {
      if (currentValue && typeof currentValue === 'string') {
        result.push(currentValue)
      }
      return result
    }, [])
  }

  return []
}

const groupAddress = ({
  eventData,
  placeData,
}: {
  eventData?: EventNode | null
  placeData?: PlaceNode
}) => {
  const updatedData = {
    address: defaultDrawerEvent.address,
  }
  const eventAddress = eventData?.addresses?.[0] ?? null
  const placesAddress = placeData?.address ?? null
  const {
    addressLine1: defaultAddressLine1,
    city: defaultCity,
    country: defaultCountry,
    googlePlaceId: defaultGooglePlaceId,
    id: defaultId,
    lat: defaultLat,
    long: defaultLong,
    state: defaultState,
    zipcode: defaultZipcode,
  } = defaultDrawerEvent.address

  if (eventAddress?.state) {
    updatedData.address = {
      addressLine1: eventAddress?.addressLine1 ?? defaultAddressLine1,
      city: eventAddress?.city ?? defaultCity,
      country: eventAddress?.country ?? defaultCountry,
      googlePlaceId: eventAddress?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: eventAddress?.lat ?? defaultLat,
      long: eventAddress?.long ?? defaultLong,
      state: eventAddress?.state ?? defaultState,
      zipcode: eventAddress?.zipcode ?? defaultZipcode,
    }
  } else if (placesAddress) {
    updatedData.address = {
      addressLine1: placeData?.address ?? defaultAddressLine1,
      city: placeData?.city ?? defaultCity,
      country: placeData?.country ?? defaultCountry,
      googlePlaceId: placeData?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: placeData?.latitude ?? defaultLat,
      long: placeData?.longitude ?? defaultLong,
      state: placeData?.state ?? defaultState,
      zipcode: placeData?.postalCode ?? defaultZipcode,
    }
  }

  return updatedData?.address
}

const groupDates = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    startDate: defaultDrawerEvent.startDate,
    endDate: defaultDrawerEvent.endDate,
  }
  const timezone = tripData?.timeZone ?? defaultDrawerEvent.timezone
  const { startDate, endDate } = eventData ?? {}

  if (startDate) {
    updatedData.startDate = getDateFromUTC(startDate, timezone)
  }
  if (endDate) {
    updatedData.endDate = getDateFromUTC(endDate, timezone)
  }

  return updatedData
}

const groupTime = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    startTime: defaultDrawerEvent.startTime,
    endTime: defaultDrawerEvent.endTime,
    timezone: defaultDrawerEvent.timezone,
  }
  const { timeZone } = tripData ?? {}
  const { startDate, endDate } = eventData ?? {}
  if (timeZone) updatedData.timezone = timeZone

  if (eventData?.useEventTime) {
    if (startDate) {
      updatedData.startTime = getTimeFromUTC({
        utcDate: dayjs(startDate)?.toISOString(),
        timezone: timeZone ?? defaultDrawerEvent.timezone,
      })
    }
    if (endDate) {
      updatedData.endTime = getTimeFromUTC({
        utcDate: dayjs(endDate)?.toISOString(),
        timezone: timeZone ?? defaultDrawerEvent.timezone,
      })
    }
  }

  return updatedData
}

const groupImages = ({
  eventData,
  placeData,
}: {
  eventData?: EventNode | null
  placeData?: PlaceNode
}) => {
  const updatedData = {
    images: defaultDrawerEvent.images,
  }
  const placeImages = placeData?.imageLinks ?? null

  if (placeImages) {
    updatedData.images = convertToStringArray(placeImages)
  } else if (eventData?.imageUrl) {
    updatedData.images = [eventData?.imageUrl]
  }

  if (!isEmpty(updatedData.images)) {
    const images = updatedData.images
      ?.slice(0, 5)
      ?.map(image => getEventImage(image))

    updatedData.images = convertToStringArray(images)
  }

  return updatedData.images
}

const groupName = ({
  eventData,
  placeData,
}: {
  eventData?: EventNode | null
  placeData?: PlaceNode
}) => {
  const updatedData = {
    name: defaultDrawerEvent.name,
  }
  const eventName = eventData?.name ?? null
  const placeName = placeData?.name ?? null

  if (eventName) {
    updatedData.name = eventName
  } else if (placeName) {
    updatedData.name = placeName
  }

  return updatedData.name
}

const groupMiscellaneousData = ({
  eventData,
  placeData,
  tripData,
}: {
  eventData?: EventNode | null
  placeData?: PlaceNode
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const data = cloneDeep(defaultDrawerEvent)

  data.address = groupAddress({ eventData, placeData })

  const { startDate, endDate } = groupDates({ eventData, tripData })
  data.startDate = startDate
  data.endDate = endDate

  const { startTime, endTime, timezone } = groupTime({
    eventData,
    tripData,
  })
  data.startTime = startTime
  data.endTime = endTime
  data.timezone = timezone

  if (eventData?.id) data.id = eventData?.id
  data.images = groupImages({ eventData, placeData })

  data.name = groupName({ eventData, placeData })

  if (eventData?.notes) data.notes = eventData?.notes

  data.status = eventData?.status ?? defaultDrawerEvent?.status

  return data
}

export { groupMiscellaneousData }
