import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  Divider,
  Dropdown,
  DropdownOption,
  Icon,
  IconButton,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import { useFlag } from 'src/common/hooks'
import { InteractionWrapper } from './InteractionWrapper'
import {
  SEARCH_BAR_OPTIONS,
  assetOptions,
  SEARCH_BAR_STATES,
  TRAVELERS_RESULTS_QUERY_PREFIX,
} from './constants'
import { useSearchBarState } from './useSearchBarState'

export const SearchBar = () => {
  const enableTravelersSearch = useFlag('enableTravelersSearch')

  const {
    isMobileScreen,
    handleClearErrorMessages,
    isSearchBarOpen,
    isSearchBarClosed,
    autocompleteParentRef,
    isHomePage,
    asset,
    setAsset,
    errorMessage,
    ready,
    autocompleteRef,
    state,
    value,
    setShowSearchTerm,
    handleSearchTermEnter,
    handleOptionSelection,
    showGeocoderOptions,
    handleButtonClick,
    travelersOptions,
    searchTerm,
    searchTermEnabled,
    geocoderOptions,
    setValue,
    onClear,
    showTravelersOptions,
  } = useSearchBarState()

  const ClearButton = () => (
    <span className='mr--3'>
      <IconButton icon='clear' onClick={onClear} />
    </span>
  )

  const showAutocompleteOptions =
    showGeocoderOptions || searchTermEnabled || showTravelersOptions

  if (isMobileScreen) return null

  return (
    <InteractionWrapper
      className='z-5 w-full max-w-sm lg:max-w-lg'
      eventTypes={['click', 'keydown']}
      onInteraction={handleClearErrorMessages}
    >
      <div
        className={classNames(
          'flex max-h-12 w-full max-w-sm items-stretch justify-end lg:max-w-lg',
          'before:duration-350 before:z--1 relative before:absolute before:inset-y-0 before:right-0 before:rounded-lg before:transition-all before:ease-in before:content-[""]',
          isSearchBarOpen &&
            'before:w-full before:rounded-l-lg before:bg-white',
          isSearchBarClosed && 'before:w-0 before:rounded-lg'
        )}
        ref={autocompleteParentRef}
      >
        <div
          className={classNames(
            'b-grey-300 b-r-0 b-r-transparent rounded-l-lg border border-solid',
            isHomePage && 'border-transparent',
            'duration-350 transition-all ease-in',
            'flex',
            isSearchBarOpen && 'w-full! -mr-2',
            isSearchBarClosed && 'pointer-events-none w-0 rounded-lg opacity-0'
          )}
        >
          <div
            className={classNames(
              '[&>div_span]:b-0! [&>div_span]:text-grey-800 [&>div>span]:rounded-0! [&>div>span]:bg-transparent! [&>div_span]:focus:border-r-grey-300! capitalize [&>div_span]:capitalize',
              'duration-350 min-w-38 transition-all ease-in',
              isSearchBarClosed && 'pointer-events-none w-0 opacity-0'
            )}
          >
            <Dropdown
              value={asset}
              onChange={e => setAsset(e as SEARCH_BAR_OPTIONS)}
            >
              {assetOptions?.map(({ label, value }) => (
                <div
                  key={value}
                  className={classNames(
                    (value === asset || isSearchBarClosed) && 'hidden'
                  )}
                >
                  <DropdownOption value={value}>
                    <span className='capitalize'>{label}</span>
                  </DropdownOption>
                </div>
              ))}
            </Dropdown>
          </div>
          <div
            className={classNames(
              'w-full',
              '[&>div>div>div]:rounded-0! [&>div>div>div]:b-0! [&>div>div>div]:bg-transparent! [&>div>div>div]:b-l [&>div>div>div]:b-l-grey-300 [&>div>div>div]:b-l-solid [&>div>div>div]:my-0 [&>div>div>div]:h-full',
              'duration-350 transition-all ease-in',
              isSearchBarClosed && 'pointer-events-none w-0 opacity-0'
            )}
          >
            <Autocomplete
              autoExpand
              className={classNames(
                isSearchBarOpen && 'b-l b-l-solid b-l-grey-300'
              )}
              isDisabled={!ready}
              ref={autocompleteRef}
              slotAfter={<ClearButton />}
              tabIndex={state !== SEARCH_BAR_STATES.open && -1}
              value={value}
              onChange={e => {
                setShowSearchTerm(true)
                setValue(e.target.value)
              }}
              onFocus={
                isMobileScreen
                  ? () => {
                      autocompleteParentRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                  : null
              }
              onKeyDown={handleSearchTermEnter}
              onOptionSelect={handleOptionSelection}
            >
              {showAutocompleteOptions && (
                <div className='max-h-sm overflow-y-auto'>
                  {showGeocoderOptions &&
                    geocoderOptions.map(option => (
                      <GeocoderOption
                        key={option.place_id}
                        option={option}
                        searchTerm={searchTerm}
                      />
                    ))}

                  {showGeocoderOptions && searchTermEnabled && <Divider />}

                  {searchTermEnabled && (
                    <AutocompleteOption
                      className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey mt-2 flex w-full cursor-pointer flex-row items-center gap-2 px-4 text-left outline-none'
                      value={searchTerm}
                    >
                      <span className='c-valleyDark mt1'>
                        <Icon name='search' />
                      </span>
                      Search for Keyword
                      <span className='font500'>&quot;{searchTerm}&quot;</span>
                    </AutocompleteOption>
                  )}

                  {showTravelersOptions &&
                    travelersOptions?.map(traveler => (
                      <AutocompleteOption
                        key={traveler?.accountHandle}
                        className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-4 bg-white px-4 py-2 text-left outline-none'
                        value={traveler?.accountHandle}
                      >
                        <Avatar
                          border='none'
                          size='sm'
                          src={traveler?.profileImageUrl}
                        />
                        <span>
                          <span className='c-forest font-medium'>
                            {traveler?.displayName}
                          </span>
                          <br />
                          <span className='type-subtitle-2 text-xs'>
                            @{traveler?.accountHandle}
                          </span>
                        </span>
                      </AutocompleteOption>
                    ))}
                </div>
              )}
              {!showAutocompleteOptions && errorMessage && (
                <span className='type-subtitle-2 mx-auto block px-4 text-sm'>
                  {errorMessage}
                </span>
              )}

              {enableTravelersSearch &&
                searchTerm &&
                asset === SEARCH_BAR_OPTIONS.travelers && (
                  <AutocompleteOption
                    className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey border-t-zinc-3 border-t-solid mt-2 flex w-full cursor-pointer flex-row items-center gap-2 border-t px-4 py-4 text-left outline-none'
                    value={TRAVELERS_RESULTS_QUERY_PREFIX + searchTerm}
                  >
                    <Icon name='search' />
                    <span className='c-valleyDark mt1'></span>
                    Search for Keyword
                    <span className='font500'>&quot;{searchTerm}&quot;</span>
                  </AutocompleteOption>
                )}
            </Autocomplete>
          </div>
        </div>
        <div
          className={classNames(
            'w-min',
            isHomePage &&
              !isSearchBarOpen &&
              '[&>button]:text-white [&>button]:hover:bg-white/20 [&>button]:hover:text-white'
          )}
        >
          <IconButton
            color={isSearchBarOpen ? 'valley' : 'transparent'}
            icon='search'
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </InteractionWrapper>
  )
}
