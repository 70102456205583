import {
  Button,
  Icon,
  Input,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import type {
  Collection,
  CreateCollectionInput,
} from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import { useCreateCollection } from './useCreateCollection'
import { useUpdateCollection } from './useUpdateCollection'

interface FormFields {
  name: string
  geocode: GeocoderType
}

interface CollectionFormModalProps {
  onClose?(): void
  onConfirm?(shouldRefetch?: boolean): void
  onDelete?(collection?: Collection): void
  collection?: Collection
}

export const CollectionFormModal = ({
  onClose,
  onConfirm,
  onDelete,
  collection,
}: CollectionFormModalProps) => {
  const { name, id, locationName, latitude, longitude } = { ...collection }
  //Mutations
  const [createCollection] = useCreateCollection()
  const [updateCollection] = useUpdateCollection()
  //Hooks
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const { handleSubmit, setValue, register, formState, getValues } =
    useForm<FormFields>({
      defaultValues: {
        name: name || '',
        geocode: locationName
          ? { center: [latitude, longitude], placeName: locationName }
          : initialGeocoder,
      },
    })

  const onGeocoderResult = ({ center, placeName }: GeocoderType) => {
    setValue('geocode', {
      center: [center[0], center[1]],
      placeName: placeName,
    })
  }

  const onSubmit = async () => {
    try {
      const name = getValues('name')
      const geocode = getValues('geocode')
      let input: CreateCollectionInput = { name }

      if (geocode.center[0] && geocode.center[1]) {
        input.latitude = geocode.center[0]
        input.longitude = geocode.center[1]
        input.locationName = geocode.placeName
      }

      const { errors } = collection
        ? await updateCollection({ variables: { input: { ...input, id } } })
        : await createCollection({ variables: { input } })

      if (!errors) {
        addSuccessSnack({
          title: `Collection ${collection ? 'edited' : 'created'} successfully`,
        })
        onConfirm && onConfirm(true)
        if (!collection) {
          pushDataToDataLayer('create_collection', {
            collection_name: input.name,
            event: 'create_collection',
          })
        }
      }
    } catch (error) {
      addErrorSnack({ title: 'Failed to create collection' })
    }
  }

  return (
    <Modal
      size='medium'
      title={collection ? 'Edit Collection' : 'New Collection'}
      onDismiss={onClose}
    >
      <form
        className='space-y-4 pb-4 lg:px-5'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          fullWidth
          errorText={formState?.errors?.name?.message}
          label='Collection name *'
          {...register('name', {
            required: 'Collection name is required',
          })}
        />
        <Geocoder
          focusOnInput={false}
          geocoder={getValues('geocode')}
          label='Destination (Optional)'
          listboxPosition='bottom'
          placeholder='Destination'
          slotBefore={
            <span className='c-new-forest'>
              <Icon name='placeOutline' />
            </span>
          }
          onResult={onGeocoderResult}
        />
      </form>
      {collection && (
        <Button
          label='Delete Collection'
          size='small'
          startIcon='deleteOutline'
          variant='text'
          onClick={() => onDelete(collection)}
        />
      )}
      <ModalActions>
        <div className='md:w-45% flex w-full flex-col-reverse justify-center gap-x-3 gap-y-3 md:flex-row'>
          <Button
            fullWidth
            label='Cancel'
            variant='outlined'
            onClick={onClose}
          />
          <Button
            fullWidth
            label='Save'
            type='submit'
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </ModalActions>
    </Modal>
  )
}
