import { useState, useRef, useEffect } from 'react'
import { Carousel, useScreenQuery } from '@travelpass/design-system'
import type { HeroModalProps } from './HeroModal'
import { HeroModalImageScroller } from './HeroModalImageScroller'

type HeroModalImagesProps = Pick<
  HeroModalProps,
  'experienceImages' | 'experienceName' | 'initialSlideIndex'
>

export const HeroModalImages = ({
  experienceName,
  experienceImages,
  initialSlideIndex = 0,
}: HeroModalImagesProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [slideIndex, setSlideIndex] = useState(initialSlideIndex)
  const [imageLinks, setImageLinks] = useState(experienceImages)
  const scrollContainerRef = useRef(null)
  const carouselImages = isMobileScreen ? imageLinks[0] : imageLinks[1]

  // TODO: why isn't this working?
  const minifiedImagesList = imageLinks[1]?.map(link => {
    return isMobileScreen
      ? link.replace(/[\d]+x[\d]+/, '80x80')
      : link.replace(/[\d]+x[\d]+/, '150x130')
  })

  const hasSingleImage = imageLinks[0].length === 1
  const onSetSlideIndex = (index: number) => setSlideIndex(index)

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }
  const onError = (errorImage: string) =>
    setImageLinks(experienceImages => {
      experienceImages.forEach(sizeArray =>
        sizeArray.filter(image => errorImage !== image)
      )
      return experienceImages
    })

  useEffect(() => {
    document?.getElementById(`wheel-images-${slideIndex}`)?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [slideIndex])

  return (
    <div className='flex h-auto flex-col gap-4 md:h-[calc(100vh-360px)] md:flex-row md:gap-3'>
      <div className='md:w-244 grow md:max-w-[calc(100%-180px)]'>
        <Carousel
          disableInfinite
          hideDots
          isLight
          keyBoardControl
          showArrowsOnHover
          beforeChange={nextSlide => setSlideIndex(nextSlide)}
          borderRadius={12}
          hideArrows={hasSingleImage}
          isDisabled={!hasFirstImageLoaded}
          size='small'
          slideIndex={slideIndex}
        >
          {carouselImages.map((image, index) => {
            const imageAlt = `${experienceName} hero modal carousel image ${
              index + 1
            }`

            return (
              <div key={imageAlt}>
                <img
                  alt={imageAlt}
                  className='h-auto w-full object-cover md:h-[calc(100vh-360px)]'
                  src={image}
                  onError={() => onError}
                  onLoad={onLoad}
                />
              </div>
            )
          })}
        </Carousel>
      </div>
      <HeroModalImageScroller
        experienceName={experienceName}
        images={imageLinks[0]}
        scrollContainerRef={scrollContainerRef}
        slideIndex={slideIndex}
        onClick={onSetSlideIndex}
      />
    </div>
  )
}
