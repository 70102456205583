const constructImageLinks = (imageLinks: string[], dimensions?: string) =>
  imageLinks.map(imageLink => {
    const formattedImageLink = imageLink.includes('://')
      ? imageLink.split('://')[1]
      : imageLink

    return `https://${formattedImageLink}/${
      dimensions ? dimensions : '320x200'
    }`
  })

export { constructImageLinks }
