import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_GUIDE_INTO_COLLECTION = gql(`
    mutation AddGuideIntoCollection($input: AddCollectedGuideInput!, $itemId: String){
        addCollectedGuide(input: $input){
            collectedGuide{
                guide{
                    id
                    collectedCount
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: GUIDE)
                    id
                    name
                }
            }
        }
    }    
`)

export const useAddGuideIntoCollection = () =>
  useMutation(ADD_GUIDE_INTO_COLLECTION)
