import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const incrementGuideViewCountMutation = gql(`
  mutation IncrementGuideViewCount($guideId: ID!) {
    incrementGuideViewCount(guideId: $guideId) {
      id
      viewCount
    }
  }
`)

export const useIncrementGuideViewCountMutation = () =>
  useMutation(incrementGuideViewCountMutation)
