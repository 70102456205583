import type { ChangeEvent } from 'react'
import { useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { EmailType } from 'src/__generated__/graphql'
import { rules } from 'src/utils'
import {
  constructSendEmailArguments,
  useBookingDetailsSendEmailMutation,
} from '../hooks'

interface BookingDetailsShareModalProps {
  onClose(): void
}

const { Share } = EmailType

export const BookingDetailsShareModal = ({
  onClose,
}: BookingDetailsShareModalProps) => {
  const [sendEmail] = useBookingDetailsSendEmailMutation()
  const { bookingId } = useParams()
  const { addSuccessSnack } = useSnackbar()
  const [hasError, setHasError] = useState(false)
  const [value, setValue] = useState('')
  const { email } = rules
  const constructedSendEmailArguments = () =>
    constructSendEmailArguments({
      bookingId,
      emailType: Share,
      toEmail: value,
    })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: updatedValue } = event?.currentTarget ?? {}

    setValue(updatedValue?.trim())
  }

  const onConfirm = async () => {
    const updatedHasError = !email.value.test(value)
    setHasError(updatedHasError)

    if (!updatedHasError) {
      await sendEmail({
        onCompleted: () => {
          addSuccessSnack({ title: 'Successfully sent email' })
          onClose()
        },
        variables: constructedSendEmailArguments(),
      })
    }
  }

  return (
    <Modal size='medium' title='Send Email' onDismiss={onClose}>
      <Input
        fullWidth
        errorText={!!hasError && email.message}
        label='Send email to'
        value={value}
        onChange={onChange}
      />
      <ModalActions>
        <Button label='Send Email' onClick={onConfirm} />
        <Button label='Cancel' variant='outlined' onClick={onClose} />
      </ModalActions>
    </Modal>
  )
}
