import type { ReactNode } from 'react'

interface HotelResultsCardHeaderProps {
  children: ReactNode
}

export const HotelResultsCardHeader = ({
  children,
}: HotelResultsCardHeaderProps) => (
  <header className='bg-grey-400 of-hidden rounded-3 relative'>
    {children}
  </header>
)
