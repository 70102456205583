import { copyToClipboard } from 'src/utils'

export const useShareGuide = () => {
  const shareGuide = async ({ shareUrl, onSuccessfulShare }) => {
    copyToClipboard(shareUrl)
    onSuccessfulShare()
  }

  return shareGuide
}
