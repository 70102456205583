export const DashboardFeedEmptyState = () => (
  <div className='flex flex-col items-center gap-8'>
    <div className='max-w-50 w-50'>
      <svg fill='none' viewBox='0 0 201 52'>
        <path
          d='M25.9 50.8c13.973 0 25.3-11.327 25.3-25.3S39.873.2 25.9.2C11.928.2.6 11.527.6 25.5s11.328 25.3 25.3 25.3Z'
          fill='#F2F2F2'
        />
        <path
          d='M18.953 16.008a3.572 3.572 0 1 0 0-7.144 3.572 3.572 0 0 0 0 7.144Z'
          fill='#042E30'
        />
        <path
          d='M24.668 29.58a9.049 9.049 0 1 0 0-18.099 9.049 9.049 0 0 0 0 18.098Z'
          fill='#042E30'
        />
        <path
          d='M25.417 29.578a7.353 7.353 0 1 0 0-14.706 7.353 7.353 0 0 0 0 14.706Z'
          fill='#D9B59B'
        />
        <path
          d='M30.41 14.728a8.422 8.422 0 0 0-12.805 7.351c3.747.94 7.683 1.66 11.679.225l.944-2.31.556 2.312c1.217.497 2.437.918 3.657-.005a8.6 8.6 0 0 0-4.03-7.573Z'
          fill='#042E30'
        />
        <path
          d='M15.627 2.74a7.306 7.306 0 0 0-7.626 1.175 8.263 8.263 0 0 0-2.637 7.354c.405 2.742 2.072 5.103 3.609 7.41 1.536 2.307 3.038 4.835 3.036 7.607a7.54 7.54 0 0 1-7.526 7.536c2.344 1.01 4.79 2.038 7.338 1.918 2.548-.12 5.22-1.758 5.635-4.275.3-1.813-.59-3.582-1.333-5.264a37.237 37.237 0 0 1-2.602-8.566 8.61 8.61 0 0 1 .05-4.241 4.98 4.98 0 0 1 4.948-3.269l.842-.757a6.556 6.556 0 0 0-3.734-6.628Z'
          fill='#042E30'
        />
        <path
          d='M44.936 42.426A25.29 25.29 0 0 1 9.54 44.985l2.17-6.056a6.512 6.512 0 0 1 5.531-3.797l1.38-.085 3.292-2.84s8.87-.316 8.738-.468l5.373 3.232-.004-.022 2.112.129a6.509 6.509 0 0 1 5.53 3.798l1.274 3.55Z'
          fill='#158481'
        />
        <path
          d='M100.5 50.8c13.973 0 25.3-11.327 25.3-25.3S114.473.2 100.5.2 75.2 11.527 75.2 25.5s11.327 25.3 25.3 25.3Z'
          fill='#F2F2F2'
        />
        <path
          d='M99.802 28.633a8.076 8.076 0 1 0 0-16.152 8.076 8.076 0 0 0 0 16.152Z'
          fill='#FFB6B6'
        />
        <path
          d='M119.48 42.426a25.291 25.291 0 0 1-35.396 2.559l2.17-6.056a6.512 6.512 0 0 1 5.531-3.797l1.38-.085 3.292-2.84s8.87-.316 8.738-.468l5.373 3.232-.004-.022 2.112.129a6.506 6.506 0 0 1 5.531 3.798l1.273 3.55Z'
          fill='#DADCE2'
        />
        <path
          d='M108.319 15.548a10.412 10.412 0 0 0-4.36-5.932c-1.696-1.085-3.811-1.638-5.732-1.065-1.921.574-3.511 2.459-3.386 4.495a1.971 1.971 0 0 0-1.989.615 4.56 4.56 0 0 0-.976 1.976 12.563 12.563 0 0 0 .118 6.956l-.038.324a3.148 3.148 0 0 0 2.09-1.816c.442-.856.696-1.807 1.175-2.642a2.868 2.868 0 0 1 2.224-1.608l-.083 1.451 2.449-1.262-.583 1.394 2.14-.894-.24 1.34a3.177 3.177 0 0 1 1.888-1.646 3.186 3.186 0 0 1 2.494.229 4.225 4.225 0 0 1 1.729 2.432c.259.992.398 2.011.414 3.036a10.611 10.611 0 0 0 .666-7.383Z'
          fill='#042E30'
        />
        <path
          d='M200.399 25.637a25.303 25.303 0 0 1-15.659 23.346A25.299 25.299 0 1 1 175.144.292a25.235 25.235 0 0 1 25.255 25.345Z'
          fill='#F2F2F2'
        />
        <path
          d='M163.373 17.363c1.046-3.333 3.425-6.391 6.715-7.518 3.29-1.127 8.108.59 10.582 3.055 4.524 4.509 5.628 20 2.52 24.895-.618-.033-2.752-.057-3.378-.08l-.885-2.95v2.92a156.86 156.86 0 0 0-3.014-.063 13.118 13.118 0 0 1-12.952-12.795c-.081-3.247 0-6.15.412-7.464Z'
          fill='#042E30'
        />
        <path
          d='M174.724 28.412a7.21 7.21 0 1 0 0-14.42 7.21 7.21 0 0 0 0 14.42Z'
          fill='#FFB6B6'
        />
        <path
          d='m168.502 14.87 8.302-4.156a10.432 10.432 0 0 1 6.111 8.645l.207 2.479-3.476-.885-.381-3.17-.59 2.923-1.605-.409.016-4.92-1.606 4.917-5.73-1.317-1.248-4.106Z'
          fill='#042E30'
        />
        <path
          d='M193.874 42.335a25.294 25.294 0 0 1-35.396 2.558l2.17-6.055a6.51 6.51 0 0 1 5.531-3.798l1.38-.085 3.292-2.84s8.87-.316 8.738-.468l5.373 3.232-.005-.022 2.112.13a6.511 6.511 0 0 1 5.531 3.797l1.274 3.55Z'
          fill='#E2CFBF'
        />
      </svg>
    </div>
    <div className='space-y-3 text-center'>
      <h3 className='type-subtitle-1-desktop c-forest-light'>
        It looks like you&apos;re not following anyone (yet).
      </h3>
      <p className='type-body-1-desktop'>
        Connect with friends and other travelers to inspire your next{' '}
        <br className='hidden md:block' />
        adventure!
      </p>
    </div>
  </div>
)
