import { Divider, Skeleton } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'

export const TripNavLoading = () => {
  return (
    <>
      <div className='lg:top-77px lg:z-4 hidden bg-white md:block lg:sticky'>
        <div className='h-9.5 md:h-15.75 flex flex-row items-center gap-4'>
          <div className='h-4 w-14'>
            <Skeleton />
          </div>
          <div className='h-4 w-12'>
            <Skeleton />
          </div>
          <div className='h-4 w-10'>
            <Skeleton />
          </div>
        </div>
        <div className='-m-x-2 md:-m-x-6 lg:-m-x-12'>
          <Divider className='bg-grey-200 h-1px -m-t-1px' />
        </div>
      </div>
      <div className='md:p-b-6 hidden md:block' />
    </>
  )
}
