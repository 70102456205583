import { useMemo, useState } from 'react'
import { Link, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { MainAmenity } from 'src/common/MainAmenities'
import { constructPopularHotelAmenities } from 'src/utils'
import { Overview } from '../../components'
import { defaultStayEvent, type StayEvent } from '../constants'

const errorMessage: MainAmenity = {
  icon: 'errorOutline',
  label: 'Amenities not available.',
}

export const StayProduct = ({ data }: { data: StayEvent | null }) => {
  const [displayAmount, setDisplayAmount] = useState<6 | 100>(6)

  const amenitiesAndIcons = useMemo(() => {
    return isEmpty(data?.amenities)
      ? [errorMessage]
      : constructPopularHotelAmenities(data?.amenities ?? [])
  }, [data])

  const showViewMore = amenitiesAndIcons?.length > 6

  const onClick = () => {
    if (displayAmount === 6) {
      setDisplayAmount(100)
    } else {
      setDisplayAmount(6)
    }
  }

  const hasDescription =
    data?.description && data.description !== defaultStayEvent.description
  const hasAmenities = amenitiesAndIcons?.[0]?.label !== errorMessage?.label
  const hasContent = hasDescription || hasAmenities

  return hasContent ? (
    <div className='space-y-4 px-8 pb-7 pt-11'>
      {hasDescription && (
        <Overview content={data?.description} rating={data?.stars} />
      )}
      {hasAmenities && (
        <div className='flex flex-col gap-2'>
          <h2 className='text-subtitle-2'>Amenities</h2>
          <div className='grid grid-flow-dense grid-cols-2 gap-x-2 gap-y-1.5'>
            {amenitiesAndIcons
              ?.slice(0, displayAmount)
              ?.map(({ icon, label }, index) => (
                <div
                  key={`${label}-${index}`}
                  className='text-body-1 flex flex-row items-center gap-2'
                >
                  <Icon name={icon} />
                  <p className='type-body-1'>{label}</p>
                </div>
              ))}
          </div>
          {showViewMore && (
            <div className='flex w-full flex-row items-center justify-end'>
              <Link
                label={`View ${displayAmount === 6 ? 'More' : 'Less'}`}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  ) : null
}
