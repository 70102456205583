import { useMutation } from '@apollo/client'
import { useSnackbar } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { tripTimelinePath } from 'src/constants'
import { generateCreateTripInput } from './createTripUtils'
import type { TripFormValues } from '../../../constants'

const createTripGql = gql(`
    mutation CreateTrip($tripInput: CreateTripInput!) {
        createTrip(tripInput: $tripInput) {
            id
            owner {
                externalId
            }
        }
    }
`)

const refetchUserTripsAfterCreationGql = gql(`
  query RefetchUserTripsAfterCreation($tripSearchInput: TripSearchInput!) {
    currentUser {
      id
      externalId
      trips(tripSearchInput: $tripSearchInput, first: 100) {
        edges{
          node {
            id
            currentUserRole
          }
        }
        totalCount
      }
    }
  }
`)

export const useCreateTrip = () => {
  const [createTripMutation, { loading, error }] = useMutation(createTripGql)
  const navigate = useNavigate()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const createTrip = (formValues: TripFormValues) => {
    const tripInput = generateCreateTripInput(formValues)

    createTripMutation({
      variables: { tripInput },
      refetchQueries: [
        {
          query: refetchUserTripsAfterCreationGql,
          variables: {
            tripSearchInput: {
              status: 'UPCOMING',
            },
          },
        },
      ],
      onCompleted: ({ createTrip }) => {
        addSuccessSnack({ title: 'Trip created' })
        navigate(`${tripTimelinePath}/${createTrip?.id}`)
      },
      onError: () => {
        addErrorSnack({ title: 'Failed to create trip' })
      },
    })
  }
  return {
    createTrip,
    loading,
    error,
  }
}
