import { useParams, useSearchParams } from 'react-router-dom'
import { DetailsMinimalHero } from 'src/common/components'
import { constructAddress, constructImageLinks } from 'src/utils'
import { HotelRecommendedHeroError } from './HotelRecommendedHeroError'
import { HotelRecommendedHeroLoading } from './HotelRecommendedHeroLoading'
import { useHotelRecommended } from './useHotelRecommended'
import { TripSearchParams } from '../../../constants'

export const HotelRecommendedHero = () => {
  const { tripId } = useParams()
  const [searchParams] = useSearchParams()
  const { hasError, isLoading, recommendedHotelData, refetch } =
    useHotelRecommended({
      hotelId: searchParams.get(TripSearchParams.eventId),
      tripId,
    })
  const { address, city, imageUrls, name, state, postalCode } =
    recommendedHotelData ?? {}
  const subtitle = constructAddress({
    addressFirstLine: address,
    city,
    state,
    zipcode: postalCode,
  })

  if (isLoading) return <HotelRecommendedHeroLoading />

  if (hasError) return <HotelRecommendedHeroError onClick={() => refetch()} />

  return (
    <DetailsMinimalHero
      images={constructImageLinks(imageUrls, '1280x720')}
      subtitle={subtitle}
      title={name}
    />
  )
}
