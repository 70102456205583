import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const archiveTrip = gql(`
  mutation ArchiveTrip($tripId: ID!) {
    deleteTrip(tripInput: {
      id: $tripId
    }) {
      id
      isDeleted
    }
  }
`)

export const useDeleteTrip = () => {
  const [deleteTrip, { loading, error }] = useMutation(archiveTrip)

  return {
    deleteTrip,
    loading,
    error,
  }
}
