import { useEffect, useState } from 'react'
import { Button, Popover, useScreenQuery } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Helmet,
  LoadMore,
  ResultsEmpty,
  ResultsDot,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { constructCityAndState } from 'src/common/components/Geocoder/geocoderUtils'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { updateExperiencesResultsUrl } from 'src/utils'
import { ExperienceResultsListLoading } from './ExperienceResultsListLoading'
import { ExperienceResultsLoading } from './ExperienceResultsLoading'
import { ExperienceResultsTags } from './ExperienceResultsTags'
import { ExperienceSearchResults } from './ExperienceSearchResults'
import {
  ResultsFiltersModal,
  constructFilterChange,
} from './ExperiencesResultsFilters'
import { ExperiencesResultsSearchNew } from './ExperiencesResultsSearchNew'
import { ResultsSort, constructSortChange } from './ExperiencesResultsSort'
import { useExperienceSearchQuery } from './useExperienceSearchQuery'

export const ExperienceSearch = () => {
  //Hooks
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const resultsQuery = useExperienceSearchQuery()
  const { isMobileScreen } = useScreenQuery()
  const {
    isLoading,
    isMoreResultsLoading,
    hasError,
    hasMoreResults,
    resultsData,
    onGetMoreResults,
  } = resultsQuery ?? {}
  //State
  const [filterChange, setFilterChange] = useState(0)
  const [sortChange, setSortChange] = useState(0)
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  //Others
  const location = searchParams.get('location')

  useEffect(() => {
    setFilterChange(constructFilterChange(searchParams))
    setSortChange(constructSortChange(searchParams))
  }, [searchParams])

  const onFiltersModalClose = () => setIsFiltersModalOpen(false)

  const onFiltersModalOpen = () => setIsFiltersModalOpen(true)

  const onClearFilters = () => {
    const updatedArguments = { searchParams, filterValues: { tags: [] } }
    const noFiltersURL = updateExperiencesResultsUrl(updatedArguments)
    navigate(noFiltersURL)
  }

  const { cityAndState, city } = constructCityAndState(location)

  return (
    <div className='relative max-w-full bg-white'>
      <Helmet
        pageName={
          isLoading
            ? 'Search Experiences'
            : `${city} Experiences | Book Things to Do in ${cityAndState || city}`
        }
      />
      <div className='z-3 sticky top-[calc(var(--top-nav-height-offset-px))] bg-white'>
        <div className='border-1px border-b-solid flex flex-col flex-wrap items-center justify-between gap-4 border-gray-200 px-12 py-4 md:flex-row md:gap-2'>
          <ExperiencesResultsSearchNew />
          {/* show Filters and Sort in one row beneath the search bar on mobile sizes */}
          <div className='flex w-full justify-start md:justify-evenly lg:hidden'>
            <div className='relative flex items-center gap-1'>
              <Button
                label='Filters'
                size='large'
                startIcon='tune'
                variant='text'
                onClick={onFiltersModalOpen}
              />
              {!!filterChange && (
                <ResultsDot label={filterChange?.toString()} />
              )}
            </div>
            <Popover
              placement='bottom-end'
              trigger={
                <div className='relative flex items-center gap-1'>
                  <Button
                    label={isMobileScreen ? 'Sort list' : 'Sort'}
                    size='large'
                    startIcon='sort'
                    variant='text'
                  />
                  {!!sortChange && <ResultsDot />}
                </div>
              }
            >
              <ResultsSort />
            </Popover>
          </div>
          {/* show Filters and Sort on the same row as the search bar on large */}
          <div className='relative hidden items-center gap-1 lg:order--1 lg:flex'>
            <Button
              label='Filters'
              size='large'
              startIcon='tune'
              variant='text'
              onClick={onFiltersModalOpen}
            />
            {!!filterChange && <ResultsDot label={filterChange?.toString()} />}
          </div>
          <div className='hidden lg:block'>
            <Popover
              placement='bottom-end'
              trigger={
                <div className='relative flex items-center gap-1'>
                  <Button
                    label={isMobileScreen ? 'Sort list' : 'Sort'}
                    size='large'
                    startIcon='sort'
                    variant='text'
                  />
                  {!!sortChange && <ResultsDot />}
                </div>
              }
            >
              <ResultsSort />
            </Popover>
          </div>
          {isFiltersModalOpen && (
            <ResultsFiltersModal onClose={onFiltersModalClose} />
          )}
        </div>
        <ExperienceResultsTags />
      </div>
      <div className='px-15'>
        {isLoading ? (
          <ExperienceResultsLoading showList />
        ) : (
          <>
            {!!hasError || isEmpty(resultsData) ? (
              <ResultsEmpty
                ctaLabel='Clear Filters'
                title="We're sorry, there aren't any experiences available that match your search."
                onCtaClick={onClearFilters}
              />
            ) : (
              <>
                <div className='experience-results-list grid grid-flow-dense grid-cols-1 gap-4 pb-6 sm:pt-4 md:pt-8'>
                  <ExperienceSearchResults experiences={resultsData} />
                  {isMoreResultsLoading && (
                    <ExperienceResultsListLoading isMoreResultsLoading />
                  )}
                </div>
                <LoadMore
                  hasMoreResults={hasMoreResults}
                  isMoreResultsLoading={isMoreResultsLoading}
                  onGetMoreResults={onGetMoreResults}
                />
              </>
            )}
          </>
        )}
        {isMoreResultsLoading && <ExperienceResultsLoading />}
        <AddToCollectionsModalListener />
        <AddToGuideModalListener />
      </div>
    </div>
  )
}
