import { Divider } from '@travelpass/design-system'
import { EventType } from 'src/__generated__/graphql'
import { ResultsCardReviews } from 'src/common/components'
import { constructAddress } from 'src/utils'
import { defaultStayEvent, type StayEvent } from '../constants'

export const StayTop = ({ data }: { data: StayEvent | null }) => {
  const address = data?.address?.state
    ? constructAddress({
        ...data?.address,
        addressFirstLine: data?.address?.addressLine1,
      })
    : 'Address failed to load'

  return (
    <header className='px-8 pt-8'>
      <h1 className='type-h6 mb-1'>{data?.name}</h1>
      <p className='type-body-2 line-clamp-1'>{address}</p>
      <div className='pb-3 pt-1.5'>
        {data?.rating !== defaultStayEvent?.rating && (
          <ResultsCardReviews
            average={data?.rating}
            total={data?.totalReviews}
            type={EventType.Stay}
          />
        )}
      </div>
      <Divider />
    </header>
  )
}
