import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const UPDATE_USER_PROFILE = gql(`
  mutation UpdateUserProfile($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...ProfileCurrentUser
      }
    }
  }
`)

export const useUserProfileMutation = () => useMutation(UPDATE_USER_PROFILE)
