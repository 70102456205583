import { useState } from 'react'
import { Avatar } from '@travelpass/design-system'
import classNames from 'classnames'
import { UploadAvatarModal } from '../components/UploadAvatarModal/UploadAvatarModal'

interface ProfileAvatarAndHandleProps {
  accountHandle?: string
  displayName?: string
  profileImageUrl?: string
}

export const ProfileAvatarAndHandle = ({
  accountHandle,
  displayName,
  profileImageUrl,
}: ProfileAvatarAndHandleProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={classNames('flex flex-col items-center justify-center')}>
      <Avatar size='xl' src={profileImageUrl} />
      <div>
        {(accountHandle || displayName) && (
          <>
            <p className='type-h3 max-w-80 text-wrap break-words text-center'>
              {`@${accountHandle}`}
            </p>
            <p className='type-body-1 text-caption-1 break-all text-center'>
              {displayName}
            </p>
          </>
        )}
        {modalOpen && (
          <UploadAvatarModal
            avatar={profileImageUrl}
            onClose={() => setModalOpen(false)}
          />
        )}
      </div>
    </div>
  )
}
