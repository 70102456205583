import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { GuideDraftGeocoder } from '../GuideDraftGeocoder'
import { GuideDraftGeocoderOld } from '../GuideDraftGeocoderOld'

interface GuideDraftMapSearchOldProps {
  id: GuideDraftDataOld['id']
  isGuideNewEventAddingEnabled: boolean
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  searchValue: string
}

export const GuideDraftMapSearchOld = ({
  id,
  isGuideNewEventAddingEnabled,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchValueChange,
  searchValue,
}: GuideDraftMapSearchOldProps) => (
  <div className='p-x-3 p-y-2 shadow-2 lg:p-y-3 of-visible absolute left-0 right-0 top-0 h-16 bg-white/60 lg:hidden'>
    <div className='p-x-3 top-7px'>
      {isGuideNewEventAddingEnabled && (
        <GuideDraftGeocoder
          id={id}
          location={location}
          searchValue={searchValue}
          willAddEvent={false}
          onMapMarkerCenterChange={onMapMarkerCenterChange}
          onPlacePointsChange={onPlacePointsChange}
          onSearchValueChange={onSearchValueChange}
        />
      )}
      {!isGuideNewEventAddingEnabled && (
        <GuideDraftGeocoderOld
          id={id}
          location={location}
          searchValue={searchValue}
          slotAfter={
            <IconButton color='valley' icon='search' onClick={() => {}} />
          }
          willAddEvent={false}
          onMapMarkerCenterChange={onMapMarkerCenterChange}
          onPlacePointsChange={onPlacePointsChange}
          onSearchValueChange={onSearchValueChange}
        />
      )}
    </div>
  </div>
)
