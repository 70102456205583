import type { ReactNode } from 'react'
import classNames from 'classnames'
import { HotelResultsListView } from '../hotelResultsConstants'

interface HotelResultsListContainerProps {
  children: ReactNode
  listView?: HotelResultsListView
}

export const HotelResultsListContainer = ({
  children,
  listView = HotelResultsListView.grid,
}: HotelResultsListContainerProps) => (
  <div
    className={classNames({
      'grid gap-4 md:grid-cols-2': listView === HotelResultsListView.grid,
      'space-y-4': listView === HotelResultsListView.list,
    })}
  >
    {children}
  </div>
)
