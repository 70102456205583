import { useEffect, useState } from 'react'
import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetGuideDrawerQueryInGuideQuery,
  PublishedEvent,
} from 'src/__generated__/graphql'
import {
  checkGuideEventIsHotelSearch,
  generateExperienceDetailsUrl,
  generateHotelDetailsUrl,
  getStateAbbreviation,
} from 'src/utils'
import { getGuideDrawerImages } from './guideUtils'

const getGuideDrawerQuery = gql(`
  query GetGuideDrawerQueryInGuide($includePlaceDetails: Boolean = false, $includePublishedEvent: Boolean = false, $placeDetailsRequest: PlaceDetailsRequest!, $placeId: ID!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) @include (if: $includePlaceDetails) {
      address
      city
      country
      countryCode
      description
      googlePlaceId
      imageLinks
      latitude
      longitude
      name
      phoneNumber
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      standardHotelId
      state
      type
      website
    }
    node (id: $placeId) @include(if: $includePublishedEvent) {
      ... on PublishedEvent {
        id
        appearsInGuides(first: 1) {
          totalCount
        }
        experienceProduct {
          images {
            size720x480(limit: 10)
          }
        }
      }
    }
  }
`)

type UseGetGuideDrawerQuery = {
  guideDrawerData: GetGuideDrawerQueryInGuideQuery['getPlaceDetails']
  guideDrawerImages: string[]
  guideDrawerNumberOfGuidesCount: number
  guideDrawerWebsite: GetGuideDrawerQueryInGuideQuery['getPlaceDetails']['website']
  hasError: ApolloError
  isLoading: boolean
}

export const useGetGuideDrawerQuery = (
  selectedEvent: PublishedEvent
): UseGetGuideDrawerQuery => {
  const googlePlaceId = selectedEvent?.addresses?.[0]?.googlePlaceId
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getGuideDrawerQuery, {
    variables: {
      includePlaceDetails: !!googlePlaceId,
      includePublishedEvent: !!selectedEvent?.id,
      placeDetailsRequest: {
        isHotelSearch: checkGuideEventIsHotelSearch(selectedEvent),
        placeId: googlePlaceId ?? '',
      },
      placeId: selectedEvent?.id,
    },
  })
  const guideDrawerData = data?.getPlaceDetails ?? {}
  const publishedEventData = data?.node as Extract<
    GetGuideDrawerQueryInGuideQuery['node'],
    { __typename?: 'PublishedEvent' }
  >
  const guideDrawerNumberOfGuidesCount =
    (publishedEventData?.appearsInGuides?.totalCount ?? 0) - 1
  const guideDrawerImages = getGuideDrawerImages({
    internalImages: selectedEvent?.images,
    externalImages: guideDrawerData?.imageLinks
      ? guideDrawerData?.imageLinks
      : publishedEventData?.experienceProduct?.images?.size720x480,
  })
  const [guideDrawerWebsite, setGuideDrawerWebsite] =
    useState<UseGetGuideDrawerQuery['guideDrawerWebsite']>(null)

  /** @todo abstract this useEffect into a util */
  useEffect(() => {
    if (selectedEvent?.standardHotelId || selectedEvent?.productId) {
      const { addresses, name, productId, standardHotelId } =
        selectedEvent ?? {}
      const { city, state } = addresses?.[0] ?? {}
      let website = ''
      if (standardHotelId) {
        website = generateHotelDetailsUrl({
          city,
          id: standardHotelId,
          name,
          state,
          stateAbbreviation: getStateAbbreviation(state, ''),
        })
      } else {
        website = generateExperienceDetailsUrl({
          city,
          name,
          id: encodeURIComponent(btoa(`ExperienceSearchProduct:${productId}`)),
          state,
          stateAbbreviation: getStateAbbreviation(state, ''),
        })
      }
      setGuideDrawerWebsite(`${window.origin}${website}`)
    } else {
      setGuideDrawerWebsite(
        guideDrawerData?.website ? guideDrawerData?.website : ''
      )
    }
  }, [guideDrawerData, selectedEvent])

  return {
    guideDrawerData,
    guideDrawerImages,
    guideDrawerNumberOfGuidesCount,
    guideDrawerWebsite,
    hasError,
    isLoading,
  }
}
