import {
  Input,
  Button,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import { firebaseResetPassword } from 'src/config/firebase/firebaseUtils'
import { emailValidationRegex } from 'src/constants/validation'

export const ForgotPasswordModal = ({
  onClose,
}: {
  onClose(): void
}): JSX.Element => {
  const { formState, handleSubmit, register } = useForm()
  const { errors } = formState
  const { email: emailError } = errors

  const { addMailSnack } = useSnackbar()

  const constructEmailErrorText = () => {
    if (emailError?.type === 'required') return 'Email is required'
    if (emailError?.type === 'validate')
      return 'Please enter a valid email address'
  }

  const onSubmit = async ({ email }) => {
    await firebaseResetPassword(email)
    addMailSnack({
      subTitle:
        'A message with instructions has been sent to your email. Check your email to reset your password',
      title: 'Check your email',
    })
    onClose()
  }

  return (
    <Modal size='medium' title='Having trouble signing in?' onDismiss={onClose}>
      <div className={textContainerCss}>
        <h3 className={textCss}>
          Please enter your email address to get started.
        </h3>
      </div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Input
          fullWidth
          errorText={constructEmailErrorText()}
          label='Email'
          type='email'
          {...register('email', {
            required: true,
            validate: (value: string) => {
              const regex = new RegExp(emailValidationRegex)
              return regex.test(value)
            },
          })}
        />
      </form>
      <ModalActions>
        <Button label='Submit' type='submit' onClick={handleSubmit(onSubmit)} />
      </ModalActions>
    </Modal>
  )
}

const textContainerCss = 'mb-4'
const textCss = 'type-body-1'
