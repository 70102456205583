import {
  Tile,
  Button,
  Chip,
  Dropdown,
  DropdownOption,
  useScreenQuery,
  SkeletonDots,
  Icon,
} from '@travelpass/design-system'
import classNames from 'classnames'
import type {
  GetExperienceAvailabilityCheckQuery,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { getTimeFromMilitaryTimeString } from 'src/utils'
import { PerPersonBreakdown } from './PerPersonBreakdown'
import { useAvailabilityOption } from './useAvailabilityOption'

type AvailabilityOptionProps = {
  bookableItems?: GetExperienceAvailabilityCheckQuery['availabilityCheck']['groupedBookableItems'][0]['bookableItems']
  pricing: ExperienceProduct['pricingInfo']
  maxTravelersPerBooking: number
  hasFreeCancellation?: boolean
  title: string
  description: string
}

export const AvailabilityOption = (props: AvailabilityOptionProps) => {
  const { isMobileScreen } = useScreenQuery()
  const AvailabilityOption = isMobileScreen
    ? AvailabilityOptionMobile
    : AvailabilityOptionDesktop

  return <AvailabilityOption {...props} />
}

const FreeCancellationPolicy = () => (
  <p className='text-body-1 color-grey-800 flex items-center gap-2'>
    <span>
      <Icon name='check' />
    </span>
    Free Cancellation up to 24 hours before start time.
  </p>
)

const AvailabilityOptionDesktop = ({
  bookableItems,
  pricing,
  maxTravelersPerBooking,
  hasFreeCancellation,
  title,
  description,
}: AvailabilityOptionProps) => {
  const {
    selectedOptionToken,
    setSelectedOptionToken,
    handleCreateExperienceHold,
    loading,
    price,
    hasAvailableItems,
  } = useAvailabilityOption(bookableItems)
  return (
    <div className='shadow-3 rounded-2 grid grid-cols-4 p-6'>
      <div
        className={classNames(
          'space-y-4 md:pr-4',
          hasAvailableItems ? 'col-span-3' : 'col-span-full'
        )}
      >
        <h2 className='type-h5'>{title}</h2>
        <p className='type-body-1'>{description}</p>
        {!hasAvailableItems ? (
          <Chip isDisabled label='Sold out' onClick={() => {}} />
        ) : (
          <div className='flex flex-wrap gap-2'>
            {bookableItems?.map(
              ({ available, bookingFlowToken, startTime }) => {
                const pressed =
                  !!bookingFlowToken && selectedOptionToken === bookingFlowToken
                return (
                  startTime && (
                    <Tile
                      key={bookingFlowToken}
                      isDisabled={!available}
                      pressed={pressed}
                      onClick={() => {
                        setSelectedOptionToken(bookingFlowToken)
                      }}
                    >
                      {getTimeFromMilitaryTimeString(startTime)}
                    </Tile>
                  )
                )
              }
            )}
          </div>
        )}
        {hasFreeCancellation && <FreeCancellationPolicy />}
      </div>
      {hasAvailableItems && (
        <div className='border-l-1 border-l-solid border-l-grey-400 col-span-1 flex flex-col items-end justify-center gap-4 text-right md:pl-4'>
          {price && <span className='text-h5'>{price}</span>}
          {pricing?.type === 'PER_PERSON' ? (
            <PerPersonBreakdown lineItems={bookableItems[0].lineItems} />
          ) : (
            <span>{`per ${
              pricing.unitType?.toLowerCase() ?? 'group'
            } (up to ${maxTravelersPerBooking})`}</span>
          )}
          <div>
            {loading ? (
              <span className='block h-6 p-4'>
                <SkeletonDots />
              </span>
            ) : (
              <Button
                isDisabled={!selectedOptionToken}
                onClick={() => {
                  handleCreateExperienceHold(selectedOptionToken)
                }}
              >
                Book Now
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const AvailabilityOptionMobile = ({
  bookableItems,
  pricing,
  maxTravelersPerBooking,
  hasFreeCancellation,
  title,
  description,
}: AvailabilityOptionProps) => {
  const {
    selectedOptionToken,
    setSelectedOptionToken,
    handleCreateExperienceHold,
    loading,
    price,
    hasAvailableItems,
  } = useAvailabilityOption(bookableItems)

  return (
    <div className='border-1px border-grey-300 rounded-4 space-y-2 border-solid p-4'>
      <h2 className='type-subtitle-1'>{title}</h2>
      {!hasAvailableItems ? (
        <Button fullWidth isDisabled size='large'>
          Sold out
        </Button>
      ) : (
        <>
          {price && <div className='text-h5'>{price}</div>}
          {pricing?.type === 'PER_PERSON' ? (
            <PerPersonBreakdown lineItems={bookableItems[0].lineItems} />
          ) : (
            <span>{`per ${
              pricing.unitType?.toLowerCase() ?? 'group'
            } (up to ${maxTravelersPerBooking})`}</span>
          )}
          <p className='type-body-1'>{description}</p>
          {hasAvailableItems && (
            <Dropdown
              label='Times'
              placeholder='Select a time'
              portal={false}
              value={selectedOptionToken || ''}
              onChange={updatedToken => {
                setSelectedOptionToken(updatedToken)
              }}
            >
              {bookableItems.map(item => {
                const { available, bookingFlowToken, startTime } = item
                if (!startTime) return null
                return (
                  <DropdownOption
                    key={bookingFlowToken}
                    disabled={!available}
                    value={bookingFlowToken}
                  >
                    {getTimeFromMilitaryTimeString(startTime)}
                  </DropdownOption>
                )
              })}
            </Dropdown>
          )}
          <div>
            {loading ? (
              <span className='block h-8 p-4'>
                <SkeletonDots />
              </span>
            ) : (
              <Button
                fullWidth
                isDisabled={!selectedOptionToken}
                size='large'
                onClick={() => {
                  handleCreateExperienceHold(selectedOptionToken)
                }}
              >
                Book now
              </Button>
            )}
          </div>
          {hasFreeCancellation && <FreeCancellationPolicy />}
        </>
      )}
    </div>
  )
}
