import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_PRESIGNED_URL = gql(`
  query GetPresignedUrl($presignedUrlType: PresignedUrlType!) {
    getPresignedUrl(presignedUrlType: $presignedUrlType) {
      url
    }
  }
`)

export const useGetPresignedUrl = () => useLazyQuery(GET_PRESIGNED_URL)
