import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { FoodTourLocations } from './FoodToursLocations'

export const FoodTours = () => {
  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/food-tours/'

  const navigate = useNavigate()

  const onExploreMoreClick = () => {
    const url = '/'
    navigate(url)
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className={mainBgGradient}>
        <div className={videoContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={videoBg}
            poster={`${imagePath}food-tours-video-poster.webp`}
            preload='metadata'
          >
            <source src={`${imagePath}food-tours-loop.mp4`} type='video/mp4' />
            <track kind='captions' />
          </video>
        </div>

        <div className={lowerContainer}>
          <PageLayoutContainer>
            <div className={lowerContainerInner}>
              <div className={h1Container}>
                <h1 className={h1Styles}>
                  <span className={h1Upper}>
                    Satisfy Your Hunger For Exploration With
                  </span>
                  <span className={h1Lower}>Food Tours</span>
                </h1>
              </div>
              <div className={carouselContainer}>
                <FoodTourLocations />
              </div>
            </div>
          </PageLayoutContainer>
        </div>
      </div>
      <div className={bottomContainer}>
        <PageLayoutContainer>
          <h2 className={bottomContainerTitleTop}>Eager for more adventure?</h2>
          <h2 className={bottomContainerTitle}>
            Travelpass has incredible, top-rated activities available in all of
            your favorite cities.
          </h2>
          <div className={buttonContainer}>
            <Button
              fullWidth
              label='Explore More'
              size='large'
              onClick={onExploreMoreClick}
            />
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}

const canonicalUrl = 'https://www.travelpass.com/food-tours'
const metaDescription = '8 amazing food tours for hungry travelers.'
const pageName = 'Food Tours'
const mainBgGradient =
  'relative bg-gradient-to-t from-beachDark from-83% lg:from-5% to-transparent to-92% lg:to-30% pt40 lg:pt-10 pb20 lg:pb-50'
const videoContainer =
  'absolute h-full top-0 bottom-0 left-0 right-0 z--2 bg-beachDark'
const videoBg =
  'absolute top-0 right-0 bottom-0 left-0 aspect-auto w-full z-1 lg:z--1'
const lowerContainer = 'relative lg:z-10 w-full lg:w-50%'
const lowerContainerInner =
  'lg:flex lg:flex-col w-full max-w-1280px ma items-start'
const h1Container = 'w-full mt-30 lg:mt-0'
const h1Styles =
  'color-white flex flex-col font-archivo font-not-oblique font-700 m0 text-center tracking-5%'
const h1Upper =
  'leading-34px mb3 text-21px lg:leading-19 lg:text-6 lg:text-shadow-lg'
const h1Lower =
  'leading-15 text-16 lg:mb-10 lg:leading-24 lg:text-24 lg:text-shadow-lg'
const carouselContainer = 'w-full max-w-800px mxa'
const bottomContainer = 'w-full pt0 pb16 lg:py16 bg-beachDark text-center'
const bottomContainerTitleTop =
  'type-h2 text-8 leading-8 lg:text-14 lg:leading-14 font-900 color-forest max-w65% ma mb8'
const bottomContainerTitle = 'type-h2 color-forest max-w65% ma'
const buttonContainer = 'w230px mxa mt14'
