import { Icon } from '@travelpass/design-system'
import type { ExperienceProductAgeBand } from 'src/__generated__/graphql'
import type { PaxMixState } from './PaxMixState'
import { getExperiencesGuestTitle } from '../common/components/ExperiencesGuests/experiencesGuestsUtils'

export const PaxMixConfiguration = ({
  ageBands,
  onChange,
  paxMix,
  maxTravelersPerBooking,
  minTravelersPerBooking: overallMinTravelers,
  requiresAdultForBooking,
}: {
  ageBands: ExperienceProductAgeBand[]
  onChange: (ageBand: string, value: number) => void
  paxMix: PaxMixState
  maxTravelersPerBooking: number
  minTravelersPerBooking: number
  requiresAdultForBooking: boolean
}) => {
  if (!ageBands?.length) return null

  const decAgeBand = ageBand => () => {
    onChange(ageBand, (paxMix[ageBand] || 0) - 1)
  }

  const incAgeBand = ageBand => () => {
    onChange(ageBand, (paxMix[ageBand] || 0) + 1)
  }

  const totalTravelers = Object.values(paxMix).reduce(
    (acc: number, curr: number) => acc + curr,
    0
  ) as number

  const atMaxTravelers = totalTravelers >= maxTravelersPerBooking

  return (
    <>
      <span className='type-body-2 c-grey-700 text-center'>
        {maxTravelersPerBooking &&
          `Please select up to ${maxTravelersPerBooking} travelers total.`}
        {overallMinTravelers && ` Must select at least ${overallMinTravelers}.`}
        {requiresAdultForBooking &&
          ' At least one adult or senior is required.'}
      </span>
      <div className='mx-auto my-4 flex max-w-[260px] flex-col items-center gap-4'>
        {ageBands.map(
          ({
            ageBand,
            endAge,
            startAge,
            minTravelersPerBooking,
            maxTravelersPerBooking,
          }) => {
            const value = paxMix[ageBand] || 0
            const title = getExperiencesGuestTitle(ageBand)
            if (maxTravelersPerBooking === 0) return null // hide if maxTravelersPerBooking is 0

            return (
              <div
                key={ageBand}
                className='color-forest flex flex-row items-center justify-between gap-8'
                data-testid='ExperiencesGuest'
              >
                <div className='min-w-23'>
                  <h4 className='type-h6'>{title}:</h4>
                  <small className='type-small-text'>
                    Ages {startAge}-{endAge}
                  </small>
                </div>
                <div className='flex grow flex-row items-center gap-2'>
                  <PaxMixIconButton
                    disabled={value <= minTravelersPerBooking}
                    onClick={decAgeBand(ageBand)}
                  >
                    <Icon name='removeCircleOutline' />
                  </PaxMixIconButton>
                  <span className='type-body-1 grow text-center font-bold'>
                    {value}
                  </span>
                  <PaxMixIconButton
                    disabled={value >= maxTravelersPerBooking || atMaxTravelers}
                    onClick={incAgeBand(ageBand)}
                  >
                    <Icon name='addCircleOutline' />
                  </PaxMixIconButton>
                </div>
              </div>
            )
          }
        )}
      </div>
    </>
  )
}

const PaxMixIconButton = props => (
  <button
    {...props}
    className='c-forest-light rd-full disabled:c-grey-600 aria-disabled:c-grey-600 border-none bg-transparent p-2 hover:bg-black/5 aria-disabled:hover:bg-none [&>i]:block'
  />
)
