import type { Dispatch, SetStateAction } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { HotelRecommendedContentFooter } from './HotelRecommendedContentFooter'
import { useHotelRecommended } from './useHotelRecommended'
import { TripSearchParams } from '../../../constants'
import { Amenities, Overview } from '../components'

interface HotelRecommendedContentProps {
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  onEventAdded?: () => void
}

export const HotelRecommendedContent = ({
  setScrollToEventId,
  onEventAdded,
}: HotelRecommendedContentProps) => {
  const { tripId } = useParams()
  const [searchParams] = useSearchParams()
  const { isLoading, hasError, recommendedHotelData, tripDetailsData } =
    useHotelRecommended({
      hotelId: searchParams.get(TripSearchParams.eventId),
      tripId,
    })
  const { descriptions, amenities, rating } = recommendedHotelData ?? {}
  const constructedDecriptions = descriptions
    ?.map(description => description.text)
    .join(' ')

  if (isLoading) return // TODO: Loading component

  if (hasError) return // TODO: Error component

  return (
    <div>
      <div className='pb-24 pt-4'>
        <Overview content={constructedDecriptions} rating={rating} />
        <Amenities amenities={amenities} />
      </div>
      <HotelRecommendedContentFooter
        recommendedHotelData={recommendedHotelData}
        setScrollToEventId={setScrollToEventId}
        tripDetailsData={tripDetailsData}
        onEventAdded={onEventAdded}
      />
    </div>
  )
}
