import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { FollowersList } from 'src/pages/profile/components/FollowersModal/FollowersList'
import { useGetUsersLikeGuideQuery } from '../useGetUsersLikeGuideQuery'

interface GuideLikesModalProps {
  guideId: string
  onClose: VoidFunction
}

export const GuideLikesModal = ({ guideId, onClose }: GuideLikesModalProps) => {
  const { usersLikeGuideData, hasError, isLoading, onGetMoreResults } =
    useGetUsersLikeGuideQuery(guideId)

  if (!usersLikeGuideData) return

  return (
    <Modal size='medium' title='Likes' onDismiss={onClose}>
      <ModalScrollContents>
        <FollowersList
          emptyListMessage='No travelers have liked this guide yet.'
          isLoading={false}
          list={usersLikeGuideData}
        />
      </ModalScrollContents>
    </Modal>
  )
}
