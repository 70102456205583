import { DefaultMarker, HotelMarker } from 'src/common/components/Map'
import type { MarkerColor } from 'src/component-library/theme/colors'

interface HotelMapMarkersProps {
  type: 'hotel' | 'generic'
  color: MarkerColor
  isSelected?: boolean
}

export const HotelMapMarkers = ({
  type,
  color,
  isSelected = false,
}: HotelMapMarkersProps) => {
  switch (type) {
    case 'hotel':
      return (
        <HotelMarker
          color={color}
          disableHover={false}
          isSelected={isSelected}
        />
      )
    default:
      return <DefaultMarker color={color} isSelected={isSelected} />
  }
}
