import { Button, Illustration } from '@travelpass/design-system'

export const ProfileGuidesZeroState = ({
  isUserOwner,
  displayName,
  onClick,
}) =>
  isUserOwner ? (
    <div className='bg-beach rounded-13px flex flex-col gap-10 p-10 md:flex-row md:gap-0'>
      <div className='order-last flex w-full flex-col items-center gap-3 md:order-first md:grid md:items-start'>
        <p className='type-h3-desktop text-center md:text-left'>
          Share your travel tips with the world
        </p>
        <p className='type-body-1 text-center md:text-left'>
          Showcase your adventures and reveal incredible destinations by
          publishing your trips
        </p>
        <Button label='Create a Guide' onClick={onClick} />
      </div>
      <div className='order-first w-full md:order-last'>
        <Illustration name='jeep' />
      </div>
    </div>
  ) : (
    <>
      <div className='mb-10 flex justify-center'>
        <div className='md:w-25% w-50%'>
          <Illustration name='cardLady' />
        </div>
      </div>
      <p className='type-subtitle-2 c-grey800 mb-4 w-full text-center'>
        {`Looks like ${displayName} hasn't published any guides yet.`}
      </p>
      <p className='type-subtitle-2 c-grey800 w-full text-center'>
        But hey, everyone starts somewhere!
      </p>
    </>
  )
