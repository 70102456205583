import { Divider, today } from '@travelpass/design-system'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import type {
  GetTripDetailsQueryInTripsQuery,
  GetTripEventsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { getDateDiff } from 'src/utils'
import { OverviewOnboardingAction } from './OverviewOnboardingAction'
import { OverviewOnboardingSummary } from './OverviewOnboardingSummary'

interface OverviewOnboardingProps
  extends Pick<
    GetTripDetailsQueryInTripsQuery['getTrip'],
    'guides' | 'startDate'
  > {
  eventFlags: GetTripEventsQueryInTripsQuery['getTrip']['eventFlags']
}

/** @todo replace guides with guide */
export const OverviewOnboarding = ({
  eventFlags,
  guides,
  startDate,
}: OverviewOnboardingProps) => {
  const { hasExperienceEvent, hasFoodAndDrinkEvent, hasStayEvent } =
    eventFlags ?? {}
  const [guide] = guides ?? []
  const hasAddedGuide = !isEmpty(guide)
  const completedActions =
    Number(hasAddedGuide) +
    Number(hasExperienceEvent) +
    Number(hasFoodAndDrinkEvent) +
    Number(hasStayEvent)
  const hasCompletedAllActions = completedActions === 3

  const daysRemaining = getDateDiff([
    dayjs(startDate)?.startOf('day').toString(),
    today?.startOf('day').toString(),
  ])

  return (
    <section
      className='bg-warm-grey p-x-6 p-y-4 rounded-2 md:p-l-7 h-full grow space-y-4'
      data-testid='OverviewOnboarding'
    >
      <OverviewOnboardingSummary
        daysRemaining={daysRemaining}
        hasCompletedAllActions={hasCompletedAllActions}
      />
      <div className='flex flex-row gap-4 md:items-center md:gap-11'>
        <div className='relative grow'>
          <Divider className='m-a max-w-72% top-10px absolute left-0 right-0 md:block' />
          <div className='relative grid grid-cols-4 gap-4 md:gap-2'>
            <OverviewOnboardingAction
              data-testid='BookedHotelAction'
              isCompleted={hasStayEvent}
            >
              <p className='color-grey-700 type-small-text-desktop type-body-2-mobile text-center'>
                Add
                <br className='block' /> <span>Hotel</span>
              </p>
            </OverviewOnboardingAction>
            <OverviewOnboardingAction
              data-testid='BookedExperienceAction'
              isCompleted={hasExperienceEvent}
            >
              <p className='color-grey-700 type-small-text-desktop type-body-2-mobile text-center'>
                Add <br className='block' /> <span>Experience</span>
              </p>
            </OverviewOnboardingAction>
            <OverviewOnboardingAction
              data-testid='BookedRestaurantAction'
              isCompleted={hasFoodAndDrinkEvent}
            >
              <p className='color-grey-700 type-small-text-desktop type-body-2-mobile text-center'>
                Add
                <br className='block' /> <span>Restaurant</span>
              </p>
            </OverviewOnboardingAction>
            <OverviewOnboardingAction
              data-testid='PublishedTripAction'
              isCompleted={hasAddedGuide}
            >
              <p className='color-grey-700 type-small-text-desktop type-body-2-mobile text-center'>
                Publish
                <br className='block' /> <span>Trip</span>
              </p>
            </OverviewOnboardingAction>
          </div>
        </div>
      </div>
    </section>
  )
}
