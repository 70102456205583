import type { Note } from 'src/__generated__/graphql'
import { stringToBoolean } from 'src/utils'
import type { OverviewNoteListItem } from './types'

const getOverviewNoteLinkAsArray = (body: Note['body']) => {
  if (!body) return []

  return (
    body
      ?.split('^https://')
      ?.slice(1)
      ?.map(link => `https://${link}`) ?? []
  )
}

const getOverviewNoteLinkAsString = (links: string[]): string =>
  links?.map(url => `^${url}`).join('') ?? ''

const getOverviewNoteListAsArray = (
  body: Note['body']
): OverviewNoteListItem[] => {
  if (!body)
    return [
      {
        isChecked: false,
        title: '',
      },
    ]

  return (
    body
      ?.split('checked:')
      ?.slice(1)
      ?.map(item => {
        const [isCheckedAsString, title] = item.split(':') ?? []

        return {
          isChecked: stringToBoolean(isCheckedAsString),
          title,
        }
      }) ?? []
  )
}

const getOverviewNoteListAsString = (items: OverviewNoteListItem[]): string =>
  items
    ?.map(({ isChecked, title }) => `checked:${isChecked}:${title}`)
    ?.join('') ?? ''

export {
  getOverviewNoteLinkAsArray,
  getOverviewNoteLinkAsString,
  getOverviewNoteListAsArray,
  getOverviewNoteListAsString,
}
