import { useEffect, useState } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { popupHeight, popupWidth } from './hotelResultsMapConstants'
import type { MarkerPopupPosition } from './hotelResultsMapTypes'
import {
  getMarkerPointFromLatLng,
  getMarkerPopupPixelLocation,
  getMarkerPopupPosition,
} from './hotelResultsMapUtils'
import type { HotelResultsCardMinimalRowData } from '../common'
import { HotelResultsCardMinimalRow } from '../common'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import type { GetHotelResultsCardUrl } from '../hotelResultsTypes'

interface HotelResultsMapMarkerPopupProps {
  hotelResultsMapMarkerPopupData: HotelResultsCardMinimalRowData & {
    latitude: number
    longitude: number
  }
  onClick?(
    /** @todo update type to hotelResultsMapMarkerPopupData */
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ): void
}

// TODO: update to match mobile design
export const HotelResultsMapMarkerPopup = ({
  hotelResultsMapMarkerPopupData,
  onClick = () => {},
}: HotelResultsMapMarkerPopupProps) => {
  const map = useGoogleMap()
  const [searchParams, setSearchParams] = useSearchParams()
  const [position, setPosition] = useState<MarkerPopupPosition>()
  const { latitude, longitude, title } = hotelResultsMapMarkerPopupData ?? {}
  const mapWidth = map.getDiv().offsetWidth
  const markerPoint = getMarkerPointFromLatLng({
    latLng: {
      lat: latitude,
      lng: longitude,
    },
    map,
  })
  const popupPixelLocation = getMarkerPopupPixelLocation({
    markerPoint,
    position,
  })
  const showPopup =
    !isEmpty(hotelResultsMapMarkerPopupData) && !!title && !!position

  useEffect(() => {
    const onDragListener = map.addListener('drag', () => {
      searchParams.delete(HotelResultsSearchParams.activeId)
      setSearchParams(searchParams, {
        replace: true,
      })
    })
    const onZoomChangedListener = map.addListener('zoom_changed', () => {
      searchParams.delete(HotelResultsSearchParams.activeId)
      setSearchParams(searchParams, {
        replace: true,
      })
    })

    setPosition(
      getMarkerPopupPosition({
        mapWidth,
        markerPoint,
      })
    )

    return () => {
      onDragListener.remove()
      onZoomChangedListener.remove()
    }
  }, [])

  return (
    showPopup && (
      <div
        className='absolute w-fit p-4'
        style={{
          height: popupHeight,
          left: popupPixelLocation?.x,
          top: popupPixelLocation?.y,
          width: popupWidth,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className='of-hidden rounded-3 shadow-1 transition-shadow-200 hover:shadow-2 cursor-pointer ease-linear'
          onClick={() => onClick(hotelResultsMapMarkerPopupData)}
        >
          <HotelResultsCardMinimalRow
            hotelResultsCardMinimalRowData={hotelResultsMapMarkerPopupData}
          />
        </div>
      </div>
    )
  )
}
