import { Link } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'

const destinations: { name: string; to: string }[] = [
  { name: 'Las Vegas', to: '/destinations/nv/las-vegas' },
  { name: 'Myrtle Beach', to: '/destinations/sc/myrtle-beach' },
  { name: 'Miami', to: '/destinations/fl/miami' },
  { name: 'San Diego', to: '/destinations/ca/san-diego' },
  { name: 'Nashville', to: '/destinations/tn/nashville' },
  { name: 'Chicago', to: '/destinations/il/chicago' },
  { name: 'New Orleans', to: '/destinations/la/new-orleans' },
  { name: 'Boston', to: '/destinations/ma/boston' },
  { name: 'Knoxville', to: '/destinations/tn/knoxville' },
  { name: 'Orlando', to: '/destinations/fl/orlando' },
  { name: 'San Antonio', to: '/destinations/tx/san-antonio' },
  { name: 'Fort Lauderdale', to: '/destinations/fl/fort-lauderdale' },
  { name: 'San Francisco', to: '/destinations/ca/san-francisco' },
  { name: 'Seattle', to: '/destinations/wa/seattle' },
  { name: 'Austin', to: '/destinations/tx/austin' },
  { name: 'Washington DC', to: '/destinations/dc/washington-dc' },
  { name: 'Denver', to: '/destinations/co/denver' },
  { name: 'Los Angeles', to: '/destinations/ca/los-angeles' },
  { name: 'New York City', to: '/destinations/ny/new-york-city' },
  { name: 'Philadelphia', to: '/destinations/pa/philadelphia' },
]

export const UtahJazzBook = () => {
  const destinationImage =
    'https://static.travelpass.com/assets/home-page/destination-circle-image.webp'
  return (
    <div className='w-full pb-12 pt-0 md:py-12'>
      <PageLayoutContainer>
        <div className='flex w-full flex-col gap-4'>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            <div className='place-content-center'>
              <div className='mb-8 space-y-4'>
                <p className='type-overline text-grey-700 text-center uppercase tracking-widest'>
                  Hotels &amp; Experiences
                </p>
                <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo text-forest-dark m-0 text-balance text-center'>
                  Book Your Best Trip Yet
                </h3>
                <div className='w-30 mx-a bg-valley mb-4 h-1 rounded-full md:h-1.5 lg:w-40'></div>
              </div>
              <div className='grid grid-cols-3 gap-2.5 px-6 lg:grid-cols-4 lg:gap-3'>
                {destinations.map(({ name, to }) => (
                  <Link key={name} className='' href={to}>
                    {name}
                  </Link>
                ))}
              </div>
            </div>
            <div className='order-first mt--36 place-content-center md:order-2 md:mt-0'>
              <img
                alt='Two women on vacation looking at a map and laughing'
                className='max-w-300px mx-a md:max-w-500px mb-8 block w-full'
                src={destinationImage}
              />
            </div>
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  )
}
