import type {
  ConfirmationContent,
  ConfirmationContentArguments,
  ConfirmationTrips,
} from './bookingConfirmationTripsConstants'

const constructContent = ({
  isFirstTrip,
  name,
  showAlternative = false,
}: ConfirmationContentArguments): ConfirmationContent => {
  const imageSrc = showAlternative
    ? 'https://static.travelpass.com/assets/confirmation-page/plan-your-trip.webp'
    : 'https://static.travelpass.com/assets/confirmation-page/plan-your-trip-old.webp'
  const content: ConfirmationContent = {
    imageSrc,
  }

  if (showAlternative) {
    if (isFirstTrip) {
      content.subtitle = [
        `Your ${name} trip is in motion! Visit "My Trips" to build your itinerary and find handpicked suggestions for extra stays or experiences.`,
      ]
      content.title = `Ready, Set, ${name}! Your Trip Awaits.`
    } else {
      content.subtitle = [
        "Looks like you've got the travel bug! Start a new journey or add to an existing trip below. Your next adventure is just a few steps away.",
      ]
      content.title = "Continue or Create: What's Next?"
    }
  } else {
    content.subtitle = [
      'Access your new booking under "My Trips", where you can also plan out your itinerary, receive personalized suggestions for activities, and add additional hotel stays.',
    ]
    content.title = 'Next Steps: Plan your trip'
  }

  return content
}

const constructFilteredTrips = ({
  currentTripId,
  trips,
}: {
  currentTripId: string
  trips: ConfirmationTrips
}): ConfirmationTrips => trips?.filter(({ id }) => currentTripId !== id) ?? []

export { constructContent, constructFilteredTrips }
