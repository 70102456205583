const articles = ['a', 'an', 'and']
const allCaps = ['mgm', 'jw', 'oyo', 'lvh', 'nyc', 'tryp']

export const parseBrandText = (value: string) =>
  value
    ?.replaceAll('_', ' ')
    ?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    ?.split(' ')
    ?.map(word => {
      const lowerCaseWord = word.toLocaleLowerCase()

      if (articles.includes(lowerCaseWord)) return lowerCaseWord
      if (allCaps.includes(lowerCaseWord)) return word.toLocaleUpperCase()

      return word
    })
    ?.join(' ')
