import { CreateTrip } from './CreateTrip/CreateTrip'

export const MyTripsCreate = () => (
  <section
    className='min-h-197 md:min-h-176 relative h-full'
    id='my-trips-create'
  >
    <div className='h-141 w-full'>
      <div className='box-border grid h-full grid-cols-1 grid-rows-2 gap-2 md:grid-cols-2 lg:grid-cols-3'>
        <div className='col-span-1 row-span-2 box-border grid h-full w-full'>
          <img
            alt=''
            className='h-full w-full object-cover'
            src='https://static.travelpass.com/assets/my-trips/my_trips_hero_0.webp'
          />
        </div>
        <div className='invisible box-border grid hidden h-full w-full md:visible md:grid'>
          <img
            alt=''
            className='max-h-71 h-full w-full object-cover'
            src='https://static.travelpass.com/assets/my-trips/my_trips_hero_1.webp'
          />
        </div>
        <div className='invisible box-border grid hidden h-full w-full lg:visible lg:grid'>
          <img
            alt=''
            className='max-h-71 h-full w-full object-cover'
            src='https://static.travelpass.com/assets/my-trips/my_trips_hero_2.webp'
          />
        </div>
        <div className='invisible box-border grid hidden h-full w-full md:visible md:grid'>
          <img
            alt=''
            className='max-h-71 h-full w-full object-cover'
            src='https://static.travelpass.com/assets/my-trips/my_trips_hero_5.webp'
          />
        </div>
        <div className='invisible box-border grid hidden h-full w-full lg:visible lg:grid'>
          <img
            alt=''
            className='max-h-71 h-full w-full object-cover'
            src='https://static.travelpass.com/assets/my-trips/my_trips_hero_3.webp'
          />
        </div>
      </div>
    </div>
    <div className='z-2 pt-65 md:pt-60.25 md:px-21 absolute inset-x-0 top-0 flex flex-row items-center justify-center px-6'>
      <CreateTrip />
    </div>
  </section>
)
