import { useEffect, useState } from 'react'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { ProfileModal } from './ProfileModal'
import {
  dispatchToggleProfileModal,
  TOGGLE_PROFILE_MODAL,
} from './dispatchToggleProfileModal'

const ProfileModalListener = () => {
  const [accountHandle, setAccountHandle] = useState<string | null>(null)
  const isProfileModalOpen = !!accountHandle

  useEffect(() => {
    const listen = (event: CustomEvent) => {
      setAccountHandle(event?.detail?.accountHandle)
    }
    window?.addEventListener(TOGGLE_PROFILE_MODAL, listen)
    return () => {
      window?.removeEventListener(TOGGLE_PROFILE_MODAL, listen)
    }
  }, [])

  useEffect(() => {
    if (isProfileModalOpen) {
      pushDataToDataLayer('guide_search_view_profile_modal', {
        value: accountHandle,
      })
    }
  }, [isProfileModalOpen])

  if (!isProfileModalOpen) return <></>

  return (
    <ProfileModal
      accountHandle={accountHandle}
      onClose={() => dispatchToggleProfileModal({ accountHandle: null })}
    />
  )
}

export { ProfileModalListener }
