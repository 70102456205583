import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import type { OpenGraphData } from 'src/__generated__/graphql'
import { ResultsCard } from 'src/common/components'
import { GuideImage } from 'src/pages/guides/details/common'

export const OverviewNoteLinkCard = ({
  ogImage,
  ogTitle,
  ogUrl,
}: OpenGraphData) => (
  <ResultsCard
    onClick={event => {
      event.stopPropagation()
      window.open(ogUrl, '_blank')
    }}
  >
    <div className='b-grey400 bg-grey100 border-1 rounded-3 flex w-full border-solid'>
      <GuideImage
        className='rounded-3 w-25 h-20'
        emptyState={
          <div className='w-20'>
            <EmptyState name='noImages' />
          </div>
        }
        src={ogImage}
      />

      <div
        className={classNames('max-w-[calc(100%-100px)] grow space-y-2 p-4', {
          'flex items-center': !ogTitle,
        })}
      >
        <p className='c-grey-800 type-body-1-desktop truncate'>{ogUrl}</p>
        <p className='color-black text-body-1 truncate'>{ogTitle}</p>
      </div>
    </div>
  </ResultsCard>
)
