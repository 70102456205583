import {
  Button,
  ModalNext,
  Illustration,
  useSnackbar,
} from '@travelpass/design-system'
import { sendEmailVerification } from 'firebase/auth'
import { auth } from 'src/config/firebase/firebaseUtils'

export const VerificationModal = ({
  onClose,
}: {
  onClose(): void
}): JSX.Element => {
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const onResendEmailClick = async () => {
    try {
      await sendEmailVerification(auth.currentUser, {
        url: window.location.href,
      })
      addSuccessSnack({ title: 'Verification email sent' })
      onClose()
    } catch {
      addErrorSnack({
        title:
          'Unable to send the verification email. Try again in a minute or two.',
      })
      onClose()
    }
  }

  return (
    <ModalNext
      actions={
        <div className='flex w-full items-center justify-center gap-6'>
          <Button type='button' variant='filled' onClick={onResendEmailClick}>
            Resend email
          </Button>
          <Button type='button' variant='outlined' onClick={onClose}>
            Done
          </Button>
        </div>
      }
      size='medium'
      onClose={onClose}
    >
      <div className='flex flex-col items-center space-y-5'>
        <div className='w-96.5 pb-3'>
          <Illustration name='cardLady' />
        </div>
        <h2 className='type-h2'>Please Verify Your Account</h2>
        <p className='type-subtitle-2'>
          Thanks for voting! To ensure valid votes, email verification is now
          required. Until your account is verified, your vote cannot be
          recorded.
        </p>
        <p className='type-subtitle-2'>
          Come back to vote for [Profile] after clicking the verification link
          sent to your email.
        </p>
      </div>
    </ModalNext>
  )
}
