import { useEffect, useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import { useParams, useSearchParams } from 'react-router-dom'
import { EventType } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { useTripDetailsMapPoints, TripDrawer } from 'src/pages/trips/components'
import {
  useGetTripDetailsQuery,
  useGetTripEventsQuery,
} from 'src/pages/trips/hooks'
import {
  getTripEventMarkers,
  getTripTimelineActiveDates,
  pushTripAddToTripClickToDataLayer,
  pushTripStayAddClickToDataLayer,
  constructTripDetailsDates,
} from 'src/pages/trips/utils'
import { getAnchorLocation } from 'src/pages/trips/utils/mapUtils'
import { MONTH_DAY, getDateFromUTC } from 'src/utils'
import {
  TripContainer,
  TripContentWrapper,
  TripDrawerContent,
  TripMapWrapper,
  TripNav,
  TripTopNav,
} from '../../../components'
import { TripDetailsMap } from '../../../components/TripDetailsMap'
import { TripSearchParams } from '../../../constants'
import { AddEvent, type Tabs } from '../AddEvents/AddEvent/AddEvent'
import { RecommendedExperiencesHorizontal } from '../AddEvents/ExperienceEvent'
import { RecommendedStaysHorizontal } from '../AddEvents/StayEvent'
import { DateTabs } from '../DateTabs'
import { SplitDivider } from '../SplitDivider/SplitDivider'
import {
  TimelineEvents,
  TimelineLoading,
  TripUpdateDayNameButton,
} from '../TimelineSections'

export const Timeline = () => {
  const [hoveredEventId, setHoverEventId] = useState<string | undefined>()
  const [isAddingEvent, setIsAddingEvent] = useState(false)
  const [defaultTab, setDefaultTab] = useState<Tabs>('stays')
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [scrollToEventId, setScrollToEventId] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedEventId = searchParams.get(TripSearchParams.eventId)
  const { tripId } = useParams()
  const { points, setPoints } = useTripDetailsMapPoints()
  const { isLoading: isTripDetailsLoading, tripDetailsData } =
    useGetTripDetailsQuery(tripId)
  const { isLoading: isTripEventsLoading, tripEventsData } =
    useGetTripEventsQuery(tripId)
  const { endDate, name, startDate, status, timeZone, tripPreference } =
    tripDetailsData ?? {}
  const dates = constructTripDetailsDates({
    arrival: startDate,
    departure: endDate,
    timezone: timeZone,
  })
  const pageName = `My ${name} Trip | ${dates}`
  const { dailyEventBreakdown } = tripEventsData ?? {}
  const anchorLocation = getAnchorLocation(tripPreference?.addresses)
  const dayName =
    dailyEventBreakdown?.scheduled?.[activeTabIndex]?.dayName ??
    `Day ${activeTabIndex + 1}`
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'
  const eventId = searchParams.get(TripSearchParams.eventId)
  const isLoading = isTripDetailsLoading || isTripEventsLoading
  const todayStays =
    dailyEventBreakdown?.scheduled?.[activeTabIndex]?.events.filter(
      event => event.type === 'STAY'
    ) ?? []
  const activeDates = getTripTimelineActiveDates({
    activeTabIndex,
    endDate,
    startDate,
    timeZone,
  })

  useEffect(() => {
    setPoints(
      getTripEventMarkers({
        activeTabIndex,
        scheduled: dailyEventBreakdown?.scheduled,
        startDate,
        timeZone,
      })
    )
  }, [activeTabIndex, dailyEventBreakdown?.scheduled, startDate, timeZone])

  const setSelectedEventId = (eventId: string) => {
    // find if selection exists among trip events
    let event = points?.find(event => event.id === eventId)
    if (!event) {
      // find if selection exists among collected items
      // @ts-ignore - Jason - Solve this
      event = points.find(mapPoint => mapPoint.id === eventId && mapPoint.type)
    }

    if (event) {
      searchParams.set(TripSearchParams.eventId, eventId)
      searchParams.set(TripSearchParams.eventType, event?.drawerType)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }

  const updateActiveTabIndex = (tabIndex: number) => {
    setActiveTabIndex(tabIndex)
  }

  const setMobileMapOpen = () => {
    searchParams.set('mapFullView', 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onAddToDateClick = () => {
    setIsAddingEvent(true)
    setDefaultTab('experiences')
    pushTripAddToTripClickToDataLayer({
      itemCategory: EventType.Experience,
      itemDate: activeDates?.startDate,
      triggerVariant: 'TripTimeline AddToDayButton',
      tripId: tripId,
      tripName: name,
    })
  }

  const getAddButtonText = () => {
    if (activeDates?.startDate)
      return `ADD TO ${dayjs(getDateFromUTC(activeDates?.startDate, timeZone)).format(MONTH_DAY)}`

    return 'ADD TO YOUR DAY'
  }

  const onDrawerClose = () => {
    searchParams.delete(TripSearchParams.eventId)
    searchParams.delete(TripSearchParams.eventType)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onHoveredEventIdChange = (updatedHoveredId: string) =>
    setHoverEventId(updatedHoveredId)

  const onAddEventClick = (tab: Tabs) => {
    setIsAddingEvent(true)
    setDefaultTab(tab)

    if (tab === 'stays')
      pushTripStayAddClickToDataLayer({
        itemEndDate: endDate,
        itemCategory: EventType.Stay,
        itemStartDate: startDate,
        triggerVariant: 'TripTimeline AddAPlaceToStayButton',
        tripId: tripId,
        tripName: name,
      })
  }

  if (isLoading && !tripDetailsData && !tripEventsData)
    return <TimelineLoading />

  return (
    <>
      <Helmet pageName={pageName} />
      <TripTopNav tripDetailsData={tripDetailsData} />
      <TripContainer>
        {!mobileMapView && tripId && (
          <TripContentWrapper>
            {isAddingEvent ? (
              <AddEvent
                activeTabIndex={activeTabIndex}
                defaultTab={defaultTab}
                hoveredEventId={hoveredEventId}
                selectedEventId={selectedEventId}
                setScrollToEventId={setScrollToEventId}
                tripId={tripId}
                onBack={() => {
                  setIsAddingEvent(false)
                  setDefaultTab('stays')
                }}
                onHoverCard={(eventId?: string) => setHoverEventId(eventId)}
              />
            ) : (
              <>
                <TripNav name={name} />
                <div className='-m-t-3'>
                  <div className='p-y-3 lg:top-140px lg:z-4 space-y-3 bg-white lg:sticky'>
                    <DateTabs
                      activeTabIndex={activeTabIndex}
                      endDate={endDate}
                      startDate={startDate}
                      status={status}
                      timeZone={timeZone}
                      updateActiveTabIndex={updateActiveTabIndex}
                    />
                    <div className='flex flex-row justify-between'>
                      <TripUpdateDayNameButton
                        dayIndex={activeTabIndex}
                        dayName={dayName}
                        tripId={tripId}
                      />
                      <div className='hidden lg:block'>
                        <Button
                          aria-label={getAddButtonText()}
                          size='small'
                          startIcon='add'
                          onClick={onAddToDateClick}
                        >
                          {getAddButtonText()}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='p-t-3'>
                    <div className='space-y-6'>
                      <div className='space-y-6'>
                        <TimelineEvents
                          activeTabIndex={activeTabIndex}
                          dailyEventBreakdown={dailyEventBreakdown}
                          scrollToEventId={scrollToEventId}
                          selectedEventId={selectedEventId}
                          setScrollToEventId={setScrollToEventId}
                          setSelectedEventId={setSelectedEventId}
                          timezone={timeZone}
                          onClick={onAddEventClick}
                          onHover={onHoveredEventIdChange}
                        />
                      </div>
                      <div className='flex flex-col gap-3.5 pb-5'>
                        <SplitDivider>Recommended for you</SplitDivider>
                        {todayStays.length ? (
                          <RecommendedExperiencesHorizontal
                            arrival={activeDates?.startDate}
                            departure={activeDates?.startDate}
                            latitude={tripPreference?.addresses?.[0]?.lat}
                            longitude={tripPreference?.addresses?.[0]?.long}
                            // TODO - What should this value be??
                            tracker={startDate}
                            tripId={tripId}
                          />
                        ) : (
                          <RecommendedStaysHorizontal
                            arrival={activeDates?.startDate}
                            departure={activeDates?.endDate}
                            latitude={tripPreference?.addresses?.[0]?.lat}
                            longitude={tripPreference?.addresses?.[0]?.long}
                            // TODO - What should this value be??
                            tracker={startDate}
                            tripId={tripId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </TripContentWrapper>
        )}
        <TripDrawer isOpen={!isEmpty(eventId)} onClose={onDrawerClose}>
          <TripDrawerContent
            setScrollToEventId={setScrollToEventId}
            timezone={timeZone ?? undefined}
            onEventAdded={() => setIsAddingEvent(false)}
          />
        </TripDrawer>
        {anchorLocation && (
          <div
            className={classNames('grow lg:block', {
              hidden: !mobileMapView,
            })}
          >
            <TripMapWrapper isDrawerOpen={!!selectedEventId}>
              <TripDetailsMap
                anchorLocation={anchorLocation}
                hoveredEventId={hoveredEventId}
                isAddingEvent={isAddingEvent}
                selectedEventId={selectedEventId}
                setSelectedEventId={setSelectedEventId}
                onHoveredEventIdChange={onHoveredEventIdChange}
              />
            </TripMapWrapper>
          </div>
        )}
        {!mobileMapView && !isAddingEvent && (
          <div className='p-x-14 z-3 fixed bottom-10 flex w-full flex-row items-center justify-center gap-3 lg:hidden'>
            <Button
              fullWidth
              aria-label={getAddButtonText()}
              size='large'
              startIcon='add'
              onClick={onAddToDateClick}
            >
              {getAddButtonText()}
            </Button>
            <IconButton
              aria-label='menu'
              color='white'
              icon='mapOutlined'
              size='large'
              onClick={setMobileMapOpen}
            />
          </div>
        )}
      </TripContainer>
    </>
  )
}
