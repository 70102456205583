import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { ResultsCardReviews } from 'src/common/components'
import {
  DrawerType,
  TripSearchParams,
  dateTemplate,
  timeTemplate,
} from 'src/pages/trips/constants'
import { pushTripEventCreateToDataLayer } from 'src/pages/trips/utils'
import { formatDate } from 'src/utils'
import { useCreateAddExperienceEvent } from './useCreateAddExperienceEvent'
import { useGetRecommendedEventsForExperienceEvent } from './useGetRecommendedEventsForExperienceEvent'
import { useTripDetailsMapPoints } from '../../../../components/TripDetailsMap/TripDetailsMapPointsProvider/useTripDetailsMapPoints'
import { EventCard } from '../../EventCard/EventCard'
import { ExperienceCardPricing } from '../../EventCard/ExperienceCardPricing'
import { SkeletonCard } from '../../EventCard/SkeletonCard'
import { AddEventError } from '../AddEventError'
import { EventScrollBox } from '../EventScrollBox'

export const RecommendedExperiences = ({
  arrival,
  departure,
  hoveredEventId,
  endDateTime,
  latitude,
  longitude,
  selectedEventId,
  setScrollToEventId,
  startDateTime,
  tracker,
  tripId,
  onComplete,
  onHoverCard,
}: {
  hoveredEventId?: string
  endDateTime: string
  latitude: string
  longitude: string
  arrival: string
  departure: string
  selectedEventId?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  startDateTime: string
  tracker: string
  tripId: string
  onComplete: () => void
  onHoverCard: (eventId?: string) => void
}) => {
  const { setPoints } = useTripDetailsMapPoints()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [searchParams, setSearchParams] = useSearchParams()
  const { createAddExperienceEvent } = useCreateAddExperienceEvent()
  const { experiences, loading, error } =
    useGetRecommendedEventsForExperienceEvent({
      latitude,
      longitude,
      arrival,
      departure,
      tracker,
    })

  useEffect(() => {
    if (!loading) {
      setPoints(experiences)
    }
  }, [JSON.stringify(experiences), loading])

  const onCardClick = (eventId: string) => {
    searchParams.set(TripSearchParams.eventId, eventId)
    searchParams.set(TripSearchParams.eventType, DrawerType.InternalExperience)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onSelect = async experienceId => {
    const experience = experiences.find(e => e.id === experienceId)
    const isSameDate = dayjs(departure).isSame(arrival, 'day')
    const updatedEndDate = dayjs(
      `${formatDate(dayjs(departure))}${endDateTime}`,
      `${dateTemplate}${timeTemplate}`
    )
      .utc()
      .format()
    const updatedStartDate = dayjs(
      `${formatDate(dayjs(arrival))}${startDateTime}`,
      `${dateTemplate}${timeTemplate}`
    )
      .utc()
      .format()

    try {
      const newEvent = await createAddExperienceEvent({
        variables: {
          eventInput: {
            addresses: [
              {
                lat: experience?.lat.toString(),
                long: experience?.lng.toString(),
              },
            ],
            description: experience?.description,
            durationInMinutes:
              // @ts-ignore - TODO - Fix this
              experience?.itinerary?.duration?.fixedDurationInMinutes,
            endDate: isSameDate ? updatedStartDate : updatedEndDate,
            externalTypeId: experience?.id,
            name: experience?.title,
            startDate: updatedStartDate,
            tripId,
            type: EventType.Experience,
            productId: experience?.productCode,
            status: EventStatus.NotBooked,
            useEventTime: false,
          },
        },
      })
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant: 'RecommendedExperiences EventCardSelect',
        tripId,
        tripName,
      })
      searchParams.delete(TripSearchParams.activeExploreId)
      searchParams.delete(TripSearchParams.eventId)
      searchParams.delete(TripSearchParams.eventType)
      setSearchParams(searchParams, {
        replace: true,
      })
      addSuccessSnack({
        title: 'Experience successfully added to trip',
      })

      if (setScrollToEventId && newEvent?.data?.createEvent?.id) {
        setScrollToEventId(newEvent.data.createEvent.id)
      }

      onComplete()
    } catch (error) {
      console.error(error)
      addErrorSnack({
        title: 'Something went wrong. Try again later.',
      })
    }
  }

  if (loading) {
    return (
      <EventScrollBox>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </EventScrollBox>
    )
  }

  if (error) {
    return <AddEventError />
  }

  return (
    <EventScrollBox>
      {experiences?.map(experience => (
        <div key={experience?.id} className='w-324px md:w-543px'>
          <EventCard
            id={experience?.id}
            imageUrl={experience?.imageUrl}
            isActive={selectedEventId === experience?.id}
            isHovered={hoveredEventId === experience?.id}
            title={experience?.title}
            variant='recommended'
            onClick={experienceId => onCardClick(experienceId)}
            onHover={id => onHoverCard(id)}
            onSelect={onSelect}
          >
            <ExperienceCardPricing amount={experience?.nightlyAverage} />
            <ResultsCardReviews
              average={experience?.customerReviewScore}
              total={experience?.totalReviews}
              type={experience?.type}
            />
          </EventCard>
        </div>
      ))}
    </EventScrollBox>
  )
}
