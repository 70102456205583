import type { ChangeEvent, KeyboardEvent } from 'react'
import { useState } from 'react'
import { Icon, IconButton, Input, KeyCode } from '@travelpass/design-system'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { HotelReviewsFilterType } from '../HotelReviewsApollo/hotelReviewsApolloConstants'

interface HotelReviewsSearchProps {
  filters: HotelReviewsFilterType
  onChange(updatedFilters: HotelReviewsFilterType): void
}

export const HotelReviewsSearch = ({
  filters,
  onChange,
}: HotelReviewsSearchProps) => {
  const { comment } = filters ?? {}
  const [commentValue, setCommentValue] = useState(comment?.toString() ?? '')

  const inputSlotAfter = commentValue?.trim() !== '' && (
    <IconButton icon='clear' onClick={() => onClear()} />
  )

  const inputSlotBefore = (
    <div className='color-canyon'>
      <Icon name='search' />
    </div>
  )

  const onClear = () => {
    setCommentValue('')
    onChange({ comment: '' })
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event?.key === KeyCode.ENTER) {
      onChange({ comment: commentValue })
      pushDataToDataLayer('search_reviews', {
        review_term: commentValue,
      })
    }
  }

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.currentTarget ?? {}
    setCommentValue(value)
  }

  return (
    <Input
      fullWidth
      aria-label='search'
      placeholder='Search'
      slotAfter={inputSlotAfter}
      slotBefore={inputSlotBefore}
      value={commentValue}
      onChange={onSearchChange}
      onKeyDown={onKeyDown}
    />
  )
}
