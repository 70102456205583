import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { useSearchParams } from 'react-router-dom'
import { TripSearchParams } from '../../constants'
import type { TripMapPoint } from '../../types'

interface TripDetailsMapEventsProps {
  points: TripMapPoint[]
}

export const TripDetailsMapEvents = ({ points }: TripDetailsMapEventsProps) => {
  const map = useGoogleMap()
  const [searchParams] = useSearchParams()
  const eventId = searchParams.get(TripSearchParams.eventId)

  useEffect(() => {
    if (eventId) {
      const { lat, lng } = points?.find(({ id }) => id === eventId) ?? {}

      if (lat && lng) {
        map?.setCenter({
          lat,
          lng,
        })
      }
    }
  }, [eventId])

  return <></>
}
