import { useEffect, useState } from 'react'
import { UnderlineTab } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { bookingsPath, experiencesSegment, hotelsSegment } from 'src/constants'

const hotelProduct = {
  tabLabel: 'HOTELS',
  tabIndex: 0,
  route: `${bookingsPath}?tab=${hotelsSegment}`,
}
const experienceProduct = {
  tabLabel: 'EXPERIENCES',
  tabIndex: 1,
  route: `${bookingsPath}?tab=${experiencesSegment}`,
}

export const MyBookingsTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const tabs = [hotelProduct, experienceProduct].map(
    ({ tabLabel, tabIndex }) => ({
      label: tabLabel,
      onClick: () => onTabChange(tabIndex),
    })
  )

  // Control Tab and URL
  const onTabChange = (tabIndex: number) => {
    if (tabIndex === hotelProduct.tabIndex) {
      navigate(hotelProduct.route, { replace: true })
    } else if (tabIndex === experienceProduct.tabIndex) {
      navigate(experienceProduct.route, { replace: true })
    }
  }

  useEffect(() => {
    const tab = searchParams.get('tab')

    if (!tab) {
      navigate(hotelProduct.route, {
        replace: true,
      })
    }
    if (tab === hotelsSegment) setActiveTabIndex(hotelProduct.tabIndex)
    if (tab === experiencesSegment)
      setActiveTabIndex(experienceProduct.tabIndex)
  }, [searchParams])

  return (
    <div className='md:justify-initial flex sm:justify-center'>
      <UnderlineTab activeIndex={activeTabIndex} tabs={tabs} />
    </div>
  )
}
