import { Button, Icon, SkeletonDots } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { KeywordSorts } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  PageLayoutContainer,
  useOnClickOwnerProfile,
  Helmet,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { ProfileModalListener } from 'src/pages/guides/results/ProfileModalListener'
import { KEYWORD_SEARCH_PHRASE } from './constants'
import { ExploreZeroState } from '../components/ExploreZeroState'
import { FiltersAndSortContainer } from '../components/FiltersAndSortContainer'
import { EXPLORE_SORT_SP } from '../constants'
import { useKeywordGuideSearch } from '../hooks/useKeywordGuideSearch'
import { buildKeywordSearchArgs } from '../utils/exploreUtils'

export const KeywordSearch = () => {
  const onClickOwnerProfile = useOnClickOwnerProfile()
  const [searchParams] = useSearchParams()
  const sort = searchParams.get(EXPLORE_SORT_SP)
  const keywordPhrase = searchParams.get(KEYWORD_SEARCH_PHRASE)
  const keywordSearchArgs = buildKeywordSearchArgs(searchParams)
  const { loading, data, fetchMore } = useKeywordGuideSearch({
    variables: {
      keywordSearchArgs: { ...keywordSearchArgs, sort: sort as KeywordSorts },
      first: 21,
    },
  })
  const {
    pageInfo,
    edges: guides,
    totalCount,
  } = { ...data?.keywordGuideSearch }
  const showResults = !loading && !!guides?.length
  const showViewMore = !loading && pageInfo?.hasNextPage

  return (
    <>
      <Helmet
        pageName={`Explore${keywordPhrase ? ` "${keywordPhrase}" Travel Guides` : ''}`}
      />
      <PageLayoutContainer>
        <div className='space-y-10 py-10'>
          <FiltersAndSortContainer
            loading={loading}
            searchedTerm={keywordPhrase}
            totalCount={totalCount}
          />
          {guides?.length === 0 && <ExploreZeroState />}
          {loading && <SkeletonDots />}
          {showResults && (
            <>
              <section>
                <h3 className='type-h3 flex flex-row items-center'>
                  <span className='c-valley'>
                    <Icon name='navigationCompass' />
                  </span>{' '}
                  Guides {keywordPhrase.trim() && `For "${keywordPhrase}"`}
                </h3>
              </section>
              <section className='grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3'>
                {guides?.map(({ node }) => (
                  <GuideRecommendedCard
                    key={node.id}
                    asLink
                    guideRecommendedCardData={node}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </section>
            </>
          )}
          {showViewMore && (
            <div className='flex flex-row justify-center'>
              <Button
                aria-label='Load more guides'
                label='View more'
                variant='outlined'
                onClick={async () => {
                  await fetchMore({
                    variables: { after: pageInfo?.endCursor },
                  })
                  pushDataToDataLayer('guide_search_load_more_click')
                }}
              />
            </div>
          )}
        </div>
      </PageLayoutContainer>
      <AddToCollectionsModalListener />
      <ProfileModalListener />
    </>
  )
}
