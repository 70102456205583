import { Icon, Tile } from '@travelpass/design-system'
import type { FilterBy } from 'src/__generated__/graphql'
import { initialMaxStars, initialMinStars } from 'src/constants'
import { starItems } from './hotelResultsFiltersStarsConstants'

interface HotelResultsFiltersStarsProps {
  filters: FilterBy
  onChange?(updatedFilters: FilterBy): void
}

export const HotelResultsFiltersStars = ({
  filters,
  onChange = () => {},
}: HotelResultsFiltersStarsProps) => {
  const { minStars } = filters?.starRating ?? {}

  const onClick = (value: string) => {
    const updatedFilters = { ...filters }
    const isMinStars = value === initialMinStars
    updatedFilters.starRating = {
      maxStars: initialMaxStars,
      minStars: value,
    }

    onChange(updatedFilters)
  }

  return (
    <div className='grid grid-cols-2 gap-4 lg:grid-cols-4'>
      {starItems.map(({ label, value, condition }) => (
        <Tile
          key={value}
          pressed={condition(minStars)}
          onClick={() => onClick(value)}
        >
          <span className='flex items-center gap-1'>
            <Icon name='star' size='small' />
            <span className='text-nowrap'>{label}</span>
          </span>
        </Tile>
      ))}
    </div>
  )
}
