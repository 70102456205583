import { useEffect, useState } from 'react'
import creditCardType from 'credit-card-type'
import { useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useBookingRateQuery } from '../../hooks'

export const CardPaymentMandatoryText = () => {
  const { rateToken } = useParams()
  const [mandatoryText, setMandatoryText] = useState('')
  const ccNumber = useWatch({ name: 'number' })
  const { data } = useBookingRateQuery({ rateToken })
  const { acceptedCreditCards } = data?.validatedRate ?? {}

  useEffect(() => {
    if (!acceptedCreditCards || !ccNumber) return setMandatoryText('')

    const ccType = creditCardType(ccNumber)?.[0]?.niceType
    const updatedMessage = acceptedCreditCards.find(
      ({ name, mandatoryText }) =>
        !!mandatoryText && ccType?.toLowerCase() === name?.toLowerCase()
    )?.mandatoryText
    setMandatoryText(updatedMessage)
  }, [acceptedCreditCards, ccNumber])

  return mandatoryText ? (
    <p className='type-body-2 color-grey-800 col-span-2'>*{mandatoryText}</p>
  ) : (
    <></>
  )
}
