import { Skeleton } from '@travelpass/design-system'

export const ExperienceResultsTagsLoading = () => (
  <div className='b-1 border-b-solid b-gray200 px-15 mx--15 z-3 sticky top-16 flex hidden h-16 items-center justify-between gap-2 overflow-hidden bg-white md:flex lg:top-[77px]'>
    <div className='h-10 w-10'>
      <Skeleton variant='circular' />
    </div>
    <div className='flex h-full w-full items-center justify-center overflow-hidden'>
      <div className='w-750 h-8'>
        <Skeleton key='experience loading tag' variant='rounded' />
      </div>
    </div>
    <div className='h-10 w-10'>
      <Skeleton variant='circular' />
    </div>
  </div>
)
