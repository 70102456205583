import { useState } from 'react'
import { Icon, Link } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'

export const Overview = ({
  content,
  rating,
}: {
  content?: string
  rating?: number | string
}) => {
  const [minimizeOverview, setMinimizeOverview] = useState(true)

  const displayOverview = isEmpty(content) ? 'Overview not available.' : content
  const displayRating = !!rating && String(rating) !== '0'
  const showReadMore = content && content?.length > 200

  const onClick = () => {
    const updatedMinimizeStatus = !minimizeOverview
    setMinimizeOverview(updatedMinimizeStatus)
  }
  return (
    <div className='flex w-full flex-col items-start justify-center gap-2'>
      <div className='flex w-full flex-row items-center justify-between'>
        <h2 className='type-h5'>Overview</h2>
        {displayRating && (
          <div className='flex flex-row items-center gap-2'>
            <Icon name='star' />
            <p className='type-h5'>{rating}-star hotel</p>
          </div>
        )}
      </div>
      <p
        className={classNames('text-body-1', {
          'line-clamp-3': minimizeOverview,
        })}
      >
        {displayOverview}
      </p>
      {showReadMore && (
        <div className='flex w-full flex-row items-center justify-end'>
          <Link
            label={`Read ${minimizeOverview ? 'More' : 'Less'}`}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  )
}
