import { Illustration } from '@travelpass/design-system'

export const AddStayCTA = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className='rd-4 bg-mintLight h-86px flex cursor-pointer flex-row items-center gap-4 p-5'
      role='button'
      tabIndex={0}
      onClick={() => onClick()}
    >
      <div className='w-80px md:w-110px'>
        <Illustration name='suitcase' />
      </div>

      <div className='flex flex-col gap-2'>
        <h3 className='type-body-1 text-newForest font-medium'>
          <span className='text-xl'>+</span> Add A Place To Stay
        </h3>
      </div>
    </div>
  )
}
