import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetGuidesEventsMapQueryInGuidesEventsMapQuery,
  GetGuidesEventsMapUserQueryInGuidesEventsMapQuery,
} from 'src/__generated__/graphql'
import { getGuidesEventsMapVariables } from './guidesEventsMapUtils'
import { useGetGuidesEventsMapUserQuery } from './useGetGuidesEventsMapUserQuery'

const getGuidesEventsMapQuery = gql(`
  query GetGuidesEventsMapQueryInGuidesEventsMap($eventTypes: [EventType], $userIds: [ID]) {
    allPublishedEvents(first: 5000, eventTypes: $eventTypes, userIds: $userIds) {
      edges {
        node {
          id
          addresses {
            id
            lat
            long
          }
          type
        }
      }
    }
  }
`)

type UseGetGuidesEventsMapQuery = {
  guidesEventsMapData: GetGuidesEventsMapQueryInGuidesEventsMapQuery['allPublishedEvents']['edges']
  guidesEventsMapUserData: GetGuidesEventsMapUserQueryInGuidesEventsMapQuery['currentUser']
  hasError: ApolloError
  isLoading: boolean
}

export const useGetGuidesEventsMapQuery = (
  filters = []
): UseGetGuidesEventsMapQuery => {
  const { guidesEventsMapUserData } = useGetGuidesEventsMapUserQuery()
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery<GetGuidesEventsMapQueryInGuidesEventsMapQuery>(
    getGuidesEventsMapQuery,
    {
      variables: getGuidesEventsMapVariables({
        filters,
        guidesEventsMapUserData,
      }),
    }
  )

  return {
    guidesEventsMapData: data?.allPublishedEvents?.edges,
    guidesEventsMapUserData,
    hasError,
    isLoading,
  }
}
