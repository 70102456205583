import { popupHeight, popupWidth } from './hotelResultsMapConstants'
import type { MarkerPopupPosition } from './hotelResultsMapTypes'

const initialPopupPosition: MarkerPopupPosition = {
  x: 'center',
  y: 'top',
}

const markerHeight = 35

const popupOffsetXCenter = popupWidth / 2 + 8

const popupOffsetXLeft = -48

const popupOffsetXRight = -popupWidth + 48

const popupOffsetYBottom = -10

const popupOffsetYTop = markerHeight + popupHeight - 20

// TODO: write tests
const getMarkerPopupPixelLocation = ({
  markerPoint,
  position = initialPopupPosition,
}: {
  markerPoint: google.maps.Point
  position: MarkerPopupPosition
}): google.maps.Point => {
  let currentLocationXWithOffset = 0
  let currentLocationYWithOffset = 0

  if (position.x === 'left') {
    currentLocationXWithOffset = markerPoint.x + popupOffsetXLeft
  } else if (position.x === 'right') {
    currentLocationXWithOffset = markerPoint.x + popupOffsetXRight
  } else {
    currentLocationXWithOffset = markerPoint.x + popupOffsetXCenter * -1
  }

  if (position.y === 'top') {
    currentLocationYWithOffset = markerPoint.y + popupOffsetYTop * -1.07
  } else {
    currentLocationYWithOffset = markerPoint.y + popupOffsetYBottom * 1.07
  }

  const currentLocationWithOffset = new google.maps.Point(
    currentLocationXWithOffset,
    currentLocationYWithOffset
  )

  return currentLocationWithOffset
}

// TODO: write tests
const getMarkerPointFromLatLng = ({
  latLng,
  map,
}: {
  latLng: google.maps.LatLngLiteral
  map: google.maps.Map
}): google.maps.Point => {
  const mapNorthWest = new google.maps.LatLng(
    map.getBounds().getNorthEast().lat(),
    map.getBounds().getSouthWest().lng()
  )
  const scale = Math.pow(2, map.getZoom())
  const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng)
  const worldCoordinateNorthWest = map
    .getProjection()
    .fromLatLngToPoint(mapNorthWest)

  return new google.maps.Point(
    Math.floor((worldCoordinate.x - worldCoordinateNorthWest.x) * scale),
    Math.floor((worldCoordinate.y - worldCoordinateNorthWest.y) * scale)
  )
}

// TODO: write tests
const getMarkerPopupPosition = ({
  mapWidth,
  markerPoint,
}: {
  mapWidth: number
  markerPoint: google.maps.Point
}): MarkerPopupPosition => {
  const position: MarkerPopupPosition = initialPopupPosition

  if (markerPoint.x + popupOffsetXCenter >= mapWidth) {
    position.x = 'right'
  } else if (markerPoint.x - popupOffsetXCenter >= 0) {
    position.x = 'center'
  } else {
    position.x = 'left'
  }

  if (markerPoint.y - popupOffsetYTop >= 0) {
    position.y = 'top'
  } else {
    position.y = 'bottom'
  }

  return position
}

export {
  getMarkerPopupPixelLocation,
  getMarkerPointFromLatLng,
  getMarkerPopupPosition,
}
