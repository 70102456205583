import { Skeleton } from '@travelpass/design-system'

export const GuideHeaderOldTagsLoading = () => (
  <div className='flex flex-row items-center gap-2'>
    <div className='rounded-7.5 h-8 w-20'>
      <Skeleton variant='rounded' />
    </div>
    <div className='rounded-7.5 h-8 w-20'>
      <Skeleton variant='rounded' />
    </div>
    <div className='rounded-7.5 h-8 w-20'>
      <Skeleton variant='rounded' />
    </div>
    <div className='rounded-7.5 hidden h-8 w-20 md:block'>
      <Skeleton variant='rounded' />
    </div>
    <div className='rounded-7.5 hidden h-8 w-20 md:block'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
