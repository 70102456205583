import { useRef } from 'react'
import type { AutocompleteProps } from '@travelpass/design-system'
import { Autocomplete } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { GuideDraftGeocoderOption } from './GuideDraftGeocoderOption'
import { GuideDraftGeocoderSectionPopover } from './GuideDraftGeocoderSectionPopover'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useGuideDraftGeocoder } from '../../useGuideDraftGeocoder'

/** @todo remove slotAfter if we remove isGuideDraftMapFiltersEnabled */
interface GuideDraftGeocoderProps {
  autoFocus?: boolean
  id: GuideDraftDataOld['id']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  paginatedEventCategories?: GuideDraftDataOld['paginatedEventCategories']
  paginatedEventCategoryId?: string
  searchValue?: string
  slotAfter?: AutocompleteProps['slotAfter']
  willAddEvent?: boolean
}

export const GuideDraftGeocoder = ({
  autoFocus = false,
  id,
  location,
  onMapMarkerCenterChange,
  onSearchValueChange,
  onPlacePointsChange,
  paginatedEventCategories,
  paginatedEventCategoryId,
  searchValue,
  slotAfter,
  willAddEvent = true,
}: GuideDraftGeocoderProps) => {
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const {
    isLoading,
    onChange,
    onKeyDown,
    onOptionSelect,
    onSectionIdChange,
    options,
    sectionId,
    showOptions,
  } = useGuideDraftGeocoder({
    autocompleteRef,
    id,
    location,
    onMapMarkerCenterChange,
    onPlacePointsChange,
    onSearchValueChange,
    paginatedEventCategoryId,
    searchValue,
    willAddEvent,
  })

  if (isLoading) return

  return (
    <div className='[&>div>div>div:first-child]:shadow-3 relative [&>div>div>div:first-child]:m-0'>
      <Autocomplete
        aria-label='Search and add a google event for this guide'
        autoComplete='off'
        autoExpand={true}
        autoFocus={autoFocus}
        placeholder='Search here for hotels, restaurants, etc.'
        ref={autocompleteRef}
        slotAfter={slotAfter}
        slotBefore={
          !isEmpty(paginatedEventCategories) && (
            <GuideDraftGeocoderSectionPopover
              guideDraftId={id}
              paginatedEventCategories={paginatedEventCategories}
              sectionId={sectionId}
              onSectionIdChange={onSectionIdChange}
            />
          )
        }
        value={searchValue}
        onChange={onChange}
        onFocus={() => onPlacePointsChange(options ?? [])}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      >
        {/** @todo look into aria-selected no longer working */}
        {showOptions && (
          <div className='max-h-92.25 overflow-y-auto md:max-h-96'>
            {options?.map(
              option =>
                !!option?.place_id && (
                  <GuideDraftGeocoderOption
                    key={option?.place_id}
                    option={option}
                  />
                )
            )}
            <GuideDraftGeocoderOption
              option={{
                name: `See results for "${searchValue}" on map`,
                place_id: 'SEARCH',
                types: [],
              }}
            />
          </div>
        )}
      </Autocomplete>
    </div>
  )
}
