import { useState, type MouseEvent } from 'react'
import { Button } from '@travelpass/design-system'
import {
  useGuideMetricLike,
  useGuideMetricSave,
  useGuideMetricShare,
} from '../..'
import { getGuideMetricString } from '../../guideUtils'
import type { GuideData } from '../../types'

interface GuideHeaderOldActionsProps {
  guideData: Pick<
    GuideData,
    | 'collectedCount'
    | 'id'
    | 'isCollected'
    | 'likedByCurrentUser'
    | 'name'
    | 'numberOfLikes'
    | 'shareCount'
  >
}

export const GuideHeaderOldActions = ({
  guideData,
}: GuideHeaderOldActionsProps) => {
  const {
    collectedCount,
    id,
    isCollected,
    likedByCurrentUser,
    name,
    numberOfLikes,
    shareCount,
  } = guideData ?? {}
  const { isModalOpen, onGuideLike } = useGuideMetricLike(id)
  const onGuideSave = useGuideMetricSave({
    id,
    name,
  })
  const onGuideShare = useGuideMetricShare(id)

  if (!guideData?.id) return

  return (
    <div className='[&>button]:p-x-0 [&>button>i]:color-forest-dark [&>button>div]:color-forest-dark flex flex-row items-center justify-end gap-3 [&>button]:gap-1'>
      <Button
        aria-label='Add this Guide to your collection'
        aria-pressed={isCollected}
        size='small'
        startIcon='addCircleOutline'
        variant='white'
        onClick={onGuideSave}
      >
        {getGuideMetricString(collectedCount, '')}
      </Button>
      <Button
        aria-label='Like this Guide'
        aria-pressed={likedByCurrentUser}
        size='small'
        startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
        variant='white'
        onClick={onGuideLike}
      >
        {getGuideMetricString(numberOfLikes, '')}
      </Button>
      <Button
        aria-label='Share this guide'
        size='small'
        startIcon='iosShare'
        variant='white'
        onClick={onGuideShare}
      >
        {getGuideMetricString(shareCount, '')}
      </Button>
    </div>
  )
}
