import {
  getAdultGuests,
  getArrivalDate,
  getDepartureDate,
  getGeocoderPlaceName,
  getKidGuests,
  getTotalGuests,
} from 'src/utils'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

export const getHotelResultsNavSearchLabelsFromSearchParams = (
  searchParams: URLSearchParams
): { dates: string; guests: string; location: string } => {
  const adults = getAdultGuests(
    searchParams.get(HotelResultsSearchParams.adults)
  )
  const arrival = getArrivalDate({
    date: searchParams.get(HotelResultsSearchParams.arrival),
    format: 'MMM D',
  })
  const departure = getDepartureDate({
    date: searchParams.get(HotelResultsSearchParams.departure),
    format: 'MMM D',
  })
  const dates = `${arrival} - ${departure}`
  const kids = getKidGuests(searchParams.get(HotelResultsSearchParams.kids))
  const guests = getTotalGuests({ guests: [adults, kids] })
  const location = getGeocoderPlaceName(
    searchParams.get(HotelResultsSearchParams.location)
  )?.replace(', USA', '')

  return {
    dates,
    guests,
    location,
  }
}
