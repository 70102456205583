import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'src/common/components'
import {
  Accordion,
  AccordionItemGroup,
  AccordionPanel,
  AccordionButton,
} from 'src/common/components/Accordion'
import type { HelmetProps } from 'src/common/components/Helmet/Helmet'
import { competitionPath } from 'src/constants'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { COMPETITION_IMAGE_SOURCE_PREFIX } from './competitionConstants'

const AccordionSection = ({ title, children }) => {
  return (
    <AccordionItemGroup className='b-b-1 b-b-grey-300 b-b-solid children:px-4'>
      <AccordionButton className='hover:bg-black/3 text-forest cursor-pointer appearance-none py-4 text-xl transition-colors'>
        {title}
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItemGroup>
  )
}

export const Faq = () => {
  const [isTnCModalOpen, setIsTnCModalOpen] = useState(false)

  const metadata: HelmetProps = {
    canonicalUrl: `${window.location.origin}${competitionPath}/faq`,
    metaDescription: 'Win $20,000 For Your Dream Vacation',
    metaImage:
      COMPETITION_IMAGE_SOURCE_PREFIX + '/competition-meta-banner.webp',
    pageName: 'About The Competition',
  }

  return (
    <>
      <Helmet {...metadata} />
      <section className='[&_button]:text-left'>
        <Accordion multiple={isMobile}>
          <AccordionSection title='Who is eligible to enter the Travelpass dream vacation contest?'>
            <p>
              Anyone 18 years or older is eligible to enter to win this contest,
              regardless of location. Contestants may enter through an employee
              referral link or directly on <a href='/'>travelpass.com</a>. The
              contest is subject to local contest rules and conditions.
            </p>
          </AccordionSection>
          <AccordionSection title='How do I enter to win the Travelpass dream vacation?'>
            <p>To officially enter the contest, you will need to:</p>
            <ol>
              <li>
                Create a public profile on Travelpass.com complete with your
                name, bio, and profile photo.
              </li>
              <li>
                Create at least three high-quality Guides for destinations that
                you know well and love. These Guides help share experiences and
                recommendations to inspire and educate fellow travelers.
                <ol type='a'>
                  <li>
                    Each high-quality Guide must include the following elements:
                    <ol type='i'>
                      <li>
                        A written description of what your Guide is about.
                      </li>
                      <li>
                        At least five “events” (hotels, activities, restaurants,
                        points of interest, etc.).
                      </li>
                      <li>
                        At least five personalized notes on events (your honest
                        opinion, info, and/or recommendations about the event,
                        location, or activity).
                      </li>
                      <li>
                        At least one personal photo attached to the Guide or an
                        event.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Employees who have previously created Guides will have their
                    past Guides count towards the contest entry so long as they
                    meet the above criteria.
                  </li>
                  <li>
                    Guides submitted before the contest that are updated to meet
                    the above requirements will be eligible for the three Guides
                    required by contest entry.
                  </li>
                </ol>
              </li>
              <li>
                Share your Travelpass profile with friends, family, and social
                media networks so they can vote for your profile.
              </li>
              <li>
                Read and agree to the contest&apos;s{' '}
                <span
                  aria-label='Open terms and conditions modal'
                  className='cursor-pointer underline'
                  role='button'
                  tabIndex={0}
                  onClick={() => setIsTnCModalOpen(true)}
                >
                  Terms and Conditions.
                </span>
              </li>
            </ol>
          </AccordionSection>
          <AccordionSection title='Can I create more than three Guides?'>
            <p>
              We encourage everyone to create as many high-quality Guides as
              they would like, but a minimum of three high-quality Guides are
              required to be eligible to win.
            </p>
          </AccordionSection>
          <AccordionSection title='How do I increase my chances of winning?'>
            <ol>
              <li className='underline'>Round 1</li>
              <ol type='a'>
                <li>
                  Votes will determine the top 20 profiles moving to Round 2.
                </li>
                <ol type='i'>
                  <li>Voting</li>
                  <ol>
                    <li>
                      Votes may be solicited by word of mouth, posting on social
                      media, or other similar organic methods.
                    </li>
                    <li>
                      Votes may not be accumulated through bots, scripts, or any
                      other form of automated vote manipulation.
                    </li>
                    <li>
                      Votes may not be obtained through the exchange of money or
                      other compensation.
                    </li>
                  </ol>
                  <li>
                    Anyone can vote 1 time per 24-hour period per profile (a
                    Travelpass account is required to vote).
                  </li>
                </ol>
              </ol>
              <br></br>
              <li className='underline'>Round 2</li>
              <ol type='a'>
                <li>
                  The 20 profiles with the highest number of votes will move to
                  Round 2.
                </li>
                <li>Vote totals will be reset on Monday, November 11, 2024.</li>
                <li>Entrants can again solicit votes for their profiles.</li>
                <li>Only vote totals will be considered for this round.</li>
                <li>
                  Anyone can vote one time per 24-hour period, per account (a
                  Travelpass account is required to vote).
                </li>
              </ol>
              <br></br>
              <li className='underline'>Winner</li>
              <ol type='a'>
                <li>
                  The profile with the most votes by 11:59 p.m., MST, on
                  November 21, 2024, will be the winner.
                </li>
                <li>
                  Any contestant determined to have manipulated votes in any
                  way, as determined exclusively by the discretion of Travelpass
                  leadership, shall be disqualified.
                </li>
              </ol>
            </ol>
          </AccordionSection>
          <AccordionSection title='How can I solicit votes for my profile?'>
            <p>
              Voting is encouraged throughout the contest. Entrants can solicit
              votes from friends and family through word of mouth or posting on
              social media accounts. Any paid subscriptions or services used to
              increase vote count will result in disqualification. Travelpass
              Group reserves the right to determine inappropriate methods of
              vote manipulation and disqualify candidates accordingly.
            </p>
          </AccordionSection>
          <AccordionSection title='Do I win if I get the most votes?'>
            <p>
              The top 20 profiles with the most votes advance to Round 2.
              Ultimately, the winner will be the person who receives the most
              votes during Round 2.
            </p>
          </AccordionSection>
          <AccordionSection title="What's the timeline for all of this? When will you announce the finalists? The winner?">
            <p>Here&apos;s what each stage looks like in a nutshell:</p>
            <ul className='list-none'>
              <li>
                <strong>Oct 16: </strong>Contest launch, entries open, voting
                begins.
              </li>
              <li>
                <strong>Nov 1: </strong>
                Deadline for entering.
              </li>
              <li>
                <strong>Nov 8: </strong>
                Deadline for Round 1 voting.
              </li>
              <li>
                <strong>Nov 11: </strong>
                Top 20 announced, Round 2 voting opens (vote counts reset to 0).
              </li>
              <li>
                <strong>Nov 21: </strong>
                Round 2 voting ends. The top vote earner wins the prize.
              </li>
            </ul>
            <p>
              For a more detailed timeline, check out our{' '}
              <span
                aria-label='Open terms and conditions modal'
                className='cursor-pointer underline'
                role='button'
                tabIndex={0}
                onClick={() => setIsTnCModalOpen(true)}
              >
                Terms and Conditions.
              </span>
            </p>
          </AccordionSection>
          <AccordionSection title='How and when will the winner be notified?'>
            <p>
              The winner will be notified by email or phone on November 22,
              2024.
            </p>
          </AccordionSection>
          <AccordionSection title='How will the grand prize be delivered?'>
            <p>
              The $20,000 USD contest prize and $5,000 USD employee-referral
              prize will both be issued by check along with a 1099 for tax
              purposes. Taxes will be the responsibility of the winners and will
              vary based on local tax rules.
            </p>
          </AccordionSection>
          <AccordionSection title='Do I have to pay taxes on the prize?'>
            <p>
              Taxes will be the responsibility of the winner and can be paid
              with a portion of the prize money. Taxes will vary depending on
              location and the tax situation of each person.
            </p>
          </AccordionSection>
          <AccordionSection title='Why did you decide to run this contest?'>
            <p>
              Our goal is to make Travelpass the largest and best platform for
              discovery, planning, and travel booking. So many travelers have
              great ideas, recommendations, and memories from their trips, and
              our platform allows you to record and share that information with
              others. Through travel Guides, Travelpass is building an
              authentic, unbiased community for travelers to share their
              recommendations and inspire others to see more of the world.
            </p>
            <p>
              This contest will help us add more Guides to the Travelpass
              community and gather valuable feedback on the Guide creation
              process.
            </p>
            <p>
              We will also be launching a campaign in 2025 to hire a content
              creator to travel the world while creating Guides for new
              destinations. This contest will help us test the platform for that
              job search campaign.
            </p>
          </AccordionSection>
          <AccordionSection title='How do I know this is for real?'>
            <p>
              Learn more about Travelpass on our website, social media accounts,
              and LinkedIn page:
            </p>
            <ul>
              <li>
                <a href='/' target='_blank'>
                  Travelpass.com
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/explorewithtravelpass/'
                  rel='noreferrer'
                  target='_blank'
                >
                  Travelpass on Instagram
                </a>
              </li>
              <li>
                <a
                  href='https://www.tiktok.com/@explorewithtravelpass?lang=en'
                  rel='noreferrer'
                  target='_blank'
                >
                  Travelpass on TikTok
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/company/travelpass-group/'
                  rel='noreferrer'
                  target='_blank'
                >
                  Travelpass Group on LinkedIn
                </a>
              </li>
            </ul>
          </AccordionSection>
        </Accordion>
        {isTnCModalOpen && (
          <TermsAndConditionsModal
            controlledByParent={true}
            onClose={() => setIsTnCModalOpen(false)}
          />
        )}
      </section>
      <p className='type-button mt-10 text-center'>
        Have additional questions? Email us at DreamVacation@travelpassgroup.com
      </p>
    </>
  )
}
