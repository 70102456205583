import type { Dispatch, SetStateAction } from 'react'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  TouchSensor,
  useSensors,
  DragOverlay,
  type DragEndEvent,
  MouseSensor,
} from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import type { TripDailyEvent } from 'src/pages/trips/types'
import { AddExperienceCTA } from './AddExperienceCTA'
import { DraggableEventCard } from './DraggableEventCard'
import { useSortTripEvents } from './useSortTripEvents'
import { EventCard } from '../../../components'
import { SectionTitle } from '../TimelineSections'

export const TimelineDo = ({
  currentDayEvents,
  selectedEventId,
  timezone,
  setSelectedEventId,
  setScrollToEventId,
  scrollToEventId,
  activeTabIndex,
  onClick,
  onHover,
}: {
  currentDayEvents?: TripDailyEvent[]
  selectedEventId: string | null
  timezone: string
  activeTabIndex: number
  setSelectedEventId: (selectedEventId: string) => void
  setScrollToEventId: Dispatch<SetStateAction<string | null>>
  scrollToEventId: string | null
  onClick: () => void
  onHover: (eventId?: string) => void
}) => {
  const [sortedEvents, setSortedEvents] = useState<[] | TripDailyEvent[]>([])
  const [overlayEvent, setOverlayEvent] = useState<null | TripDailyEvent>(null)

  const { tripId } = useParams()
  // DND kit sensors to customize UI / UX
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // drag activates for Mouse users after a user drags the mouse >= 15px
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      // drag activates for Touch users after 300ms hold, 10px of movement allowed during hold
      activationConstraint: {
        delay: 300,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      // adds keyboard accessibility to sortable container
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const { sortTripEvents, loading } = useSortTripEvents()
  const sortTripEventsDebounce = useCallback(debounce(sortTripEvents, 3000), [])

  // controls which card is rendered in the overlay
  const onDragStart = (event: DragEndEvent) => {
    const activeEventData = sortedEvents?.find(
      ({ id }) => id === event?.active?.id
    )
    setOverlayEvent(activeEventData)
  }

  // controls logic for reordering events array after drag and drop
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    const activeId = active?.id
    const overId = over?.id
    if (activeId && overId && activeId !== overId) {
      const oldIndex = sortedEvents.findIndex(({ id }) => id === activeId)
      const newIndex = sortedEvents.findIndex(({ id }) => id === overId)
      const updatedSortedEvents = arrayMove(sortedEvents, oldIndex, newIndex)
      const eventSortOrder = updatedSortedEvents.map(({ id }) => id)
      setSortedEvents(updatedSortedEvents)
      sortTripEventsDebounce({
        variables: {
          tripInput: {
            id: tripId ?? '',
            eventSortOrder,
          },
        },
        onCompleted: data => {
          setSortedEvents(
            data.updateTrip.dailyEventBreakdown.scheduled[activeTabIndex].events
          )
        },
      })
    }
    setOverlayEvent(null)
  }

  const eventRefs = useRef({})

  useEffect(() => {
    if (!loading) {
      setSortedEvents(currentDayEvents)
    }
    // We want the state to change from props if there is an edit, but not when sort order is changing
  }, [currentDayEvents, loading])

  useLayoutEffect(() => {
    if (scrollToEventId && eventRefs.current[scrollToEventId]) {
      eventRefs.current[scrollToEventId].scrollIntoView({
        behavior: 'smooth',
      })
      setScrollToEventId(null)
    }
  }, [sortedEvents])

  return (
    <div>
      <SectionTitle title='What to do' />
      {/* dnd context makes content inside drag and droppable */}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        sensors={sensors}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        {/* drag and droppable container*/}
        <SortableContext
          items={sortedEvents}
          strategy={verticalListSortingStrategy}
        >
          {!isEmpty(sortedEvents) &&
            sortedEvents?.map((event: TripDailyEvent, index: number) => {
              const eventId = event?.id

              return (
                // drag and droppable eventCard
                <div
                  key={`eventCard-${eventId}`}
                  className={classNames(
                    index === sortedEvents.length - 1 && 'pb-3'
                  )}
                  ref={ref => (eventRefs.current[eventId] = ref)}
                >
                  <DraggableEventCard
                    bookedStatus={event?.status}
                    endDate={event?.endDate}
                    eventId={eventId}
                    imageUrl={event?.imageUrl}
                    isActive={selectedEventId === eventId}
                    name={event?.name}
                    notes={event?.notes}
                    startDate={event?.startDate}
                    timezone={timezone}
                    useEventTime={event?.useEventTime}
                    onEventCardClick={setSelectedEventId}
                    onHover={onHover}
                  />
                </div>
              )
            })}
        </SortableContext>
        {/* card overlay so that moving element renders above other list items */}
        <DragOverlay>
          <div className='rd-4 shadow-[0_30px_40px_rgba(0,0,0,0.5) mr-12 bg-white shadow-2xl'>
            {!isEmpty(overlayEvent) && (
              <EventCard
                isButtonsHidden
                isDraggable
                bookedStatus={overlayEvent?.status}
                endDate={overlayEvent?.endDate}
                eventId={overlayEvent?.id}
                imageUrl={overlayEvent?.imageUrl}
                name={overlayEvent?.name}
                notes={overlayEvent?.notes}
                startDate={overlayEvent?.startDate}
                timezone={timezone}
                useEventTime={overlayEvent?.useEventTime}
                onHover={onHover}
              />
            )}
          </div>
        </DragOverlay>
      </DndContext>
      {isEmpty(sortedEvents) && <AddExperienceCTA onClick={() => onClick()} />}
    </div>
  )
}
