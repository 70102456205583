import { useState } from 'react'
import type { AvailableIcons } from '@travelpass/design-system'
import {
  Button,
  Divider,
  Dropdown,
  DropdownOption,
  Icon,
  Link,
  Modal,
  ModalScrollContents,
  Radio,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import type {
  ExperienceBooking,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { getTimeFromMilitaryTimeString } from 'src/utils'
import { useCancelExperienceBookingMutation } from './useCancelExperienceBookingMutation'
import { useGetExperienceCancelQuoteQuery } from './useGetExperienceCancelQuoteQuery'
import { paxMixFormatter } from '../common/components/utils'

interface SummaryLineProps {
  children: React.ReactNode
  iconName: AvailableIcons
  className?: string
}

const SummaryLine = ({
  children,
  iconName,
  className = '',
}: SummaryLineProps) => {
  return (
    <p className={`text-body-1 mb-2 flex items-center gap-2 ${className}`}>
      <Icon name={iconName} />
      {children}
    </p>
  )
}

/** @warn `reason.code`s come from Viator and are not stored in our system, so this could break in future */
const SUPPLIER_NO_SHOW = 'Customer_Service.Supplier_no_show' as const

export const CancelBookingButtonWithModal = ({
  bookingDetails,
  productDetails,
}: {
  bookingDetails: ExperienceBooking
  productDetails: ExperienceProduct
}) => {
  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState('')
  const [didProviderAsk, setDidProviderAsk] = useState(null)
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [cancelBooking, { loading: isSubmitting }] =
    useCancelExperienceBookingMutation()

  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)

  const handleSetDidProviderAsk = value => _e => setDidProviderAsk(value)

  const { data, loading, error } = useGetExperienceCancelQuoteQuery(
    open && bookingDetails.id
  )

  const { refundDetails, reasons } =
    data?.getExperienceCancelQuote?.experienceCancellationQuote || {}

  const handleCancelBookingSubmit = async () => {
    if (loading || isSubmitting) return
    // TODO: update with actual mutation
    try {
      const result = await cancelBooking({
        variables: {
          input: {
            bookingId: bookingDetails.id,
            reasonCode: reason,
          },
        },
      })
      if (result?.data?.cancelExperienceBooking?.experienceCancellation?.id) {
        addSuccessSnack({ title: 'Cancellation confirmed' })
        closeModal()
      }
    } catch (err) {
      addErrorSnack({ title: err.message })
    }
  }

  const coverImage = productDetails.images?.size480x320?.[0]
  const readyToSubmit = Boolean(reason) && didProviderAsk !== null
  const displaySupportBanner =
    reason === SUPPLIER_NO_SHOW || didProviderAsk === 'YES'

  return (
    <div id='cancel-booking'>
      <Button
        label='Request Cancellation'
        variant='error'
        onClick={openModal}
      />
      {open && (
        <Modal size='medium' onDismiss={closeModal}>
          <ModalScrollContents>
            <form className='flex flex-col gap-6'>
              <div
                className='flex flex-col gap-4 md:flex-row'
                data-id='experience-summary'
              >
                <img
                  alt=''
                  className='aspect-3/2 rounded-4 w-full object-cover md:w-1/4'
                  src={coverImage}
                />
                <div className='flex flex-col gap-2'>
                  <p className='text-h6'>{productDetails.title}</p>
                  <SummaryLine iconName='person'>
                    {paxMixFormatter(bookingDetails?.paxMix)}
                  </SummaryLine>
                  <SummaryLine iconName='calendar'>
                    {dayjs(bookingDetails?.travelDate).format(
                      'dddd, MMMM D, YYYY'
                    )}
                  </SummaryLine>
                  {bookingDetails?.startTime && (
                    <SummaryLine iconName='accessTime'>
                      {getTimeFromMilitaryTimeString(bookingDetails?.startTime)}
                    </SummaryLine>
                  )}
                </div>
              </div>
              <Divider />
              {loading ? <SkeletonDots /> : null}
              {error ? (
                <p>
                  There was an error!
                  <br />
                  <span className='font-mono'>{error.toString()}</span>
                </p>
              ) : null}
              {!loading && !error && (
                <>
                  <label className='text-h6' htmlFor='reason'>
                    Why are you cancelling this booking?
                  </label>
                  <Dropdown
                    id='reason'
                    name='reason'
                    placeholder='Select a reason'
                    portal={false}
                    required={true}
                    value={reason}
                    onChange={setReason}
                  >
                    {reasons?.map(({ code, text }) => (
                      <DropdownOption key={code} value={code}>
                        {text}
                      </DropdownOption>
                    ))}
                  </Dropdown>
                  <fieldset className='m-0 border-none p-0'>
                    <legend className='text-h6 mb-4'>
                      Did the provider ask you to cancel?
                    </legend>
                    <Radio
                      id='didProviderAsk-yes'
                      isChecked={didProviderAsk === 'YES'}
                      label='Yes'
                      name='didProviderAsk'
                      required={true}
                      onClick={handleSetDidProviderAsk('YES')}
                    />
                    <Radio
                      id='didProviderAsk-no'
                      isChecked={didProviderAsk === 'NO'}
                      label='No'
                      name='didProviderAsk'
                      required={true}
                      onClick={handleSetDidProviderAsk('NO')}
                    />
                  </fieldset>
                  <Divider />
                  <h3 className='text-h5'>Refund Information</h3>
                  <div>
                    {displaySupportBanner ? (
                      <p
                        className='text-body-1'
                        data-testid='experience-booking-refund-support-banner'
                      >
                        If you believe the refund amount listed below is
                        incorrect, please contact our support team{' '}
                        <Link href='tel:8338979178' label='(833-897-9178)' />{' '}
                        for further assistance.
                      </p>
                    ) : null}
                    <p className='flex justify-between'>
                      <span className='text-h6 text-newForest'>
                        Your total refund:
                      </span>
                      <span className='text-h6 text-newForest'>
                        ${refundDetails?.refundAmount?.amount}
                      </span>
                    </p>
                    <p className='flex justify-between'>
                      <span className='text-body-1 text-grey800'>
                        Amount you paid:
                      </span>
                      <span className='text-body-1 text-grey800'>
                        ${refundDetails?.itemPrice?.amount}
                      </span>
                    </p>
                  </div>
                </>
              )}
              <div className='flex flex-row-reverse justify-center gap-2'>
                {isSubmitting ? (
                  <SkeletonDots />
                ) : (
                  <>
                    <Button
                      isDisabled={!readyToSubmit}
                      label='Cancel Booking'
                      onClick={handleCancelBookingSubmit}
                    />
                    <Button
                      label='Dismiss'
                      variant='outlined'
                      onClick={closeModal}
                    />
                  </>
                )}
              </div>
            </form>
          </ModalScrollContents>
        </Modal>
      )}
    </div>
  )
}
