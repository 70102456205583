import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { stringToBoolean } from 'src/utils'
import { GuideHeaderLoading } from './GuideHeader'
import { GuideHeaderOldLoading } from './GuideHeaderOld'
import { GuideHeroLoading } from './GuideHero'
import { GuideMapLoading, GuideMapWrapper } from './GuideMap'
import { GuideSectionsLoading, GuideSectionsWrapper } from './GuideSection'
import { GuideStickyTopNavLoading } from './GuideStickyTopNav'
import { GuideStickyTopNavOldLoading } from './GuideStickyTopNavOld'
import { GuideSearchParam } from '../guideConstants'

interface GuideLoadingProps {
  isGuidePublishingV3Enabled: boolean
  selectedId: string
}

export const GuideLoading = ({
  isGuidePublishingV3Enabled,
  selectedId,
}: GuideLoadingProps) => {
  const [searchParams] = useSearchParams()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView)
  )

  return (
    <>
      {isGuidePublishingV3Enabled && <GuideStickyTopNavLoading />}
      {!isGuidePublishingV3Enabled && <GuideStickyTopNavOldLoading />}
      <div
        className={classNames({
          'invisible absolute lg:visible lg:static': mapFullView,
        })}
      >
        <GuideHeroLoading />
      </div>
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapFullView={mapFullView} selectedId={selectedId}>
          <div className='bg-white'>
            <PageLayoutContainer size='none'>
              {isGuidePublishingV3Enabled && (
                <GuideHeaderLoading isEdit={false} />
              )}
              {!isGuidePublishingV3Enabled && (
                <GuideHeaderOldLoading isEdit={false} />
              )}
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <GuideSectionsLoading />
            <div className='p-b-5 lg:p-bv-4' />
          </PageLayoutContainer>
        </GuideSectionsWrapper>
        <GuideMapWrapper
          className='lg:h-100svh h-[calc(100svh-78px)] lg:top-0'
          mapFullView={mapFullView}
          selectedId={selectedId}
        >
          <GuideMapLoading />
        </GuideMapWrapper>
      </div>
    </>
  )
}
