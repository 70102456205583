import { useQuery } from '@apollo/client'
import { Skeleton } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { BookingDetailsBreadcrumbs } from './BookingDetailsBreadcrumbs'
import type { BookingBreadcrumbsNode } from './types'
import { useBookingDetailsIdsQuery } from '../../hooks'

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsBreadcrumbs($nodeId: ID!) {
    node(id: $nodeId) {
      id
      ... on Trip {
        id
        name
      }
    }
  }
`)

export const BookingDetailsBreadcrumbsApollo = () => {
  const { bookingId } = useParams()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const { id: tripId } = bookingDetailsIdsData?.trip ?? {}

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: !tripId,
    variables: {
      nodeId: tripId,
    },
  })

  const { node } = data ?? {}
  const bookingDetailsData = node as BookingBreadcrumbsNode

  if (isLoading)
    return (
      <div className='h-12px w-100px'>
        <Skeleton />
      </div>
    )

  if (hasError) return

  return <BookingDetailsBreadcrumbs bookingDetailsData={bookingDetailsData} />
}
