import {
  Button,
  Icon,
  Illustration,
  SkeletonDots,
  Toggle,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideStatus } from 'src/__generated__/graphql'
import type { GuideData } from 'src/pages/guides/details/types'
import { GuideDraftGroup } from '../../GuideDraftGroup'

interface GuideDraftShareIntroSettingsProps {
  guideData: GuideData
  isLoading: boolean
  onChange: VoidFunction
  onShare: VoidFunction
}

export const GuideDraftShareIntroSettings = ({
  guideData,
  isLoading,
  onChange,
  onShare,
}: GuideDraftShareIntroSettingsProps) => {
  const isPublished = guideData?.status === GuideStatus.Published

  if (guideData?.firstPublishedAt)
    return (
      <section className='md:p-t-3 md:p-x-6 md:p-b-4 md:bg-warm-grey md:rounded-3 space-y-5'>
        <header className='space-y-3'>
          <h4 className='c-black type-h5-mobile lg:type-h4-desktop'>
            Privacy Settings
          </h4>
        </header>
        <div className='p-x-5 flex flex-row items-center gap-3'>
          <div className='grow space-y-1'>
            <h5 className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop [&>i]:c-new-forest flex flex-row items-center gap-1'>
              <Icon
                name={isPublished ? 'visibility' : 'visibilityOff'}
                size='small'
              />
              <span className='font-700'>
                {isPublished ? 'Your Guide Is Public' : 'Your Guide Is Private'}
              </span>
              <span
                className={classNames('rounded-1/2 h-3 w-3', {
                  'bg-valley': isPublished,
                  'bg-red': !isPublished,
                })}
              />
            </h5>
            <p className='color-grey-800 type-body-2 p-l-5'>
              {isPublished
                ? 'Visible to anyone visiting Travelpass.com. Use the toggle to make it private.'
                : 'Only visible to you and those with the link below. Use the toggle to publicly share your Guide with anyone who visits Travelpass.com.'}
            </p>
          </div>
          {isLoading && (
            <div className='flex h-10 flex-col items-center justify-center'>
              <SkeletonDots />
            </div>
          )}
          {!isLoading && (
            <Toggle
              autoFocus={true}
              checked={isPublished}
              onChange={onChange}
            />
          )}
        </div>
      </section>
    )

  return (
    <GuideDraftGroup className='flex flex-col-reverse items-center justify-center gap-6 text-center md:flex-row md:text-left'>
      <header className='grow space-y-3'>
        <h4 className='c-black type-h4'>Share Publicly</h4>
        <p className='type-body-2 c-grey-900'>
          Make your Guide public to share travel tips and recommendations with
          friends, family, and fellow explorers on Travelpass.com.
        </p>
        <div className='m-a w-fit md:w-auto'>
          <Button autoFocus={true} isDisabled={isLoading} onClick={onShare}>
            Make Public
          </Button>
        </div>
      </header>
      <div className='w-56 min-w-56'>
        <Illustration name='scooter' />
      </div>
    </GuideDraftGroup>
  )
}
