import { useRef } from 'react'
import { StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import type { Collection } from 'src/__generated__/graphql'
import { ImagePanel } from 'src/common/components'
import {
  collectionsPath,
  defaultCollectionImage,
  defaultLocationImage,
} from 'src/constants'
import { formatImageLinkForProductCard } from 'src/utils'
import { CollectionMenu } from './CollectionMenu'

interface DashboardCollectionCardProps {
  collection: Collection
  openFormModal(collection?: Collection): void
  openDeleteModal(collection?: Collection): void
}

export const DashboardCollectionCard = ({
  collection,
  openFormModal,
  openDeleteModal,
}: DashboardCollectionCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  const { isDefault, name, id } = collection
  const collectedItemsCount = collection?.collectedItems?.totalCount || 0
  const previewImage = formatImageLinkForProductCard(
    collection?.previewImages[0] || defaultCollectionImage,
    '640x400',
    '200'
  )

  const showImagePanel = isDefault && collection?.previewImages?.length > 3
  const panelImages = showImagePanel
    ? collection?.previewImages?.map((url, index) => ({
        url: formatImageLinkForProductCard(
          url || defaultLocationImage,
          '320x200',
          '200'
        ),
        alt: `${name} - ${index}`,
      }))
    : []

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className='rounded-2 flex cursor-pointer flex-col bg-white'
      onClick={() => {
        anchorRef?.current?.click?.()
      }}
    >
      <div
        className={classNames('h-50 rounded-2 flex', {
          'border-1 border-grey-400 border-solid px-5 py-7':
            previewImage === defaultCollectionImage,
        })}
      >
        {showImagePanel ? (
          <ImagePanel images={panelImages} />
        ) : (
          <img
            alt={name}
            className={`rounded-2 relative inset-0 w-full ${
              previewImage === defaultCollectionImage
                ? 'object-contain'
                : 'object-cover'
            }`}
            src={previewImage}
          />
        )}
      </div>
      <div className='py-4 pl-3'>
        <div className='flex min-h-8 flex-row items-center justify-between gap-1'>
          <a
            className='no-underline'
            href={`${collectionsPath}/${id}`}
            ref={anchorRef}
            rel='noreferrer'
            target='_blank'
          >
            <div className='type-h6 line-clamp-1'>{name}</div>
          </a>
          {!isDefault && (
            <StopPropagation>
              <CollectionMenu
                onDelete={() => openDeleteModal(collection)}
                onEdit={() => openFormModal(collection)}
              />
            </StopPropagation>
          )}
        </div>
        <div className='type-small-text-desktop c-grey-800'>
          {collectedItemsCount} saved items
        </div>
      </div>
    </div>
  )
}
