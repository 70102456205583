import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import type { DateRange } from '@travelpass/design-system'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import { getArrivalDate } from 'src/utils'
import { RecommendedStays } from './RecommendedStays'
import { AddEventGeocoderInput } from '../../AddEventGeocoderInput'
import { SplitDivider } from '../../SplitDivider/SplitDivider'

export const StayEvent = ({
  tripId,
  dateRange,
  endDateTime,
  hoveredEventId,
  selectedEventId,
  setScrollToEventId,
  startDateTime,
  onComplete,
  onHoverCard,
}: {
  tripId: string
  dateRange: DateRange
  endDateTime: string
  hoveredEventId?: string
  selectedEventId?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  startDateTime: string
  onComplete: () => void
  onHoverCard: (eventId?: string) => void
}) => {
  const { tripDetailsData } = useGetTripDetailsQuery(tripId)
  const [picker, setPicker] = useState<'recommended'>('recommended')
  const { startDate, timeZone, tripPreference } = tripDetailsData ?? {}
  const { lat, long } = tripPreference?.addresses?.[0] ?? {}
  const arrival = getArrivalDate({ date: dateRange?.from })
  const departure = getArrivalDate({ date: dateRange?.to ?? dateRange.from })

  return (
    <div>
      <div className='m-b-6'>
        <div className='m-l-4 text-body-2 c-grey-700'>Add a place</div>
        <AddEventGeocoderInput
          isHotelSearch
          latitude={lat}
          longitude={long}
          placeholder='Add places from anywhere on the web!'
          setScrollToEventId={setScrollToEventId}
          startDate={startDate}
          onSelect={onComplete}
        />
      </div>
      <div className='p-b-9 space-y-9'>
        <SplitDivider>Or choose from</SplitDivider>
        <div className='relative z-0 flex w-full justify-center'>
          {/* <Picker
            defaultValue='recommended'
            labels={[
              {
                label: 'Recommended',
                value: 'recommended',
              },
            ]}
            onChange={event => {
              searchParams.delete(TripSearchParams.eventId)
              searchParams.delete(TripSearchParams.eventType)
              setSearchParams(searchParams, {
                replace: true,
              })

              if (event.target.value === 'recommended') setPicker('recommended')
            }}
          /> */}
        </div>
      </div>
      {picker === 'recommended' && (
        <RecommendedStays
          arrival={arrival}
          departure={departure}
          endDateTime={endDateTime}
          hoveredEventId={hoveredEventId}
          latitude={lat}
          longitude={long}
          selectedEventId={selectedEventId}
          setScrollToEventId={setScrollToEventId}
          startDateTime={startDateTime}
          timeZone={timeZone}
          // TODO - What should this value be??
          tracker={startDate}
          tripId={tripId}
          onComplete={onComplete}
          onHoverCard={onHoverCard}
        />
      )}
    </div>
  )
}
