import { AutocompleteOption, Icon } from '@travelpass/design-system'
import { onGuideSessionStorageHoverIdChange } from '../../../details'
import { getGuideDraftGeocoderOptionVariant } from '../../guideDraftUtils'

export const GuideDraftGeocoderOption = ({
  option,
}: {
  option: google.maps.places.PlaceResult
}) => {
  const { color, icon } = getGuideDraftGeocoderOptionVariant(option)

  if (!option?.name) return

  return (
    <AutocompleteOption
      className='b-none b-b-grey-300 b-b-solid b-b-1 c-black p-x-4 p-y-3 type-body-1 focus:bg-grey-100 hover:bg-grey-100 w-full cursor-pointer bg-white text-left outline-none'
      value={option}
    >
      <div
        className='flex flex-row items-center gap-2'
        onMouseEnter={() =>
          onGuideSessionStorageHoverIdChange(option?.place_id)
        }
        onMouseLeave={() => onGuideSessionStorageHoverIdChange('')}
      >
        <div className={color}>
          <Icon name={icon} />
        </div>
        <div className='space-y-1'>
          <p className='type-body-1-medium line-clamp-1'>{option?.name}</p>
          {option?.formatted_address && (
            <p className='c-black type-body-2 line-clamp-1'>
              {option?.formatted_address}
            </p>
          )}
        </div>
      </div>
    </AutocompleteOption>
  )
}
