import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_EXPERIENCE_STATIC_MAP_LAZY = gql(`
  query GetExperienceStaticMapLazy($nodeId: ID!, $height: Int, $width: Int, $zoom: Int) {
    node(id: $nodeId) {
      ... on ExperienceProduct {
        staticMap(height: $height, width: $width, zoom: $zoom)
      }
    }
  }`)

export const useGetStaticMapLazy = () => {
  return useLazyQuery(GET_EXPERIENCE_STATIC_MAP_LAZY)
}
