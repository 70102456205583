import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Slider from 'rc-slider'
import type { FilterBy } from 'src/__generated__/graphql'
import { HotelResultsFiltersPriceFields } from './HotelResultsFiltersPriceFields'

const initialMaxPrice = 1000
const maxOffset = 500
const min = 0

interface HotelResultsFiltersPriceProps {
  filters: FilterBy
  onChange?(updatedFilters: FilterBy): void
}

export const HotelResultsFiltersPrice = ({
  filters,
  onChange = () => {},
}: HotelResultsFiltersPriceProps) => {
  const { priceRange } = filters ?? {}
  const [max, setMax] = useState<number>()
  const maxValue = priceRange?.maxPrice ?? max
  const minValue = priceRange?.minPrice ?? min
  const showPrice = !!max

  useEffect(() => {
    const initialMax =
      !priceRange?.maxPrice || priceRange?.maxPrice <= initialMaxPrice
        ? initialMaxPrice
        : priceRange.maxPrice
    const max =
      priceRange?.minPrice >= initialMax
        ? priceRange?.minPrice + maxOffset
        : initialMax
    setMax(max)
  }, [])

  const onSliderChange = (value: number[]) => {
    const [minPrice, maxPrice] = value ?? []

    onChange({
      ...filters,
      priceRange: {
        maxPrice,
        minPrice,
      },
    })
  }

  return (
    showPrice && (
      <div className='space-y-4' data-testid='HotelResultsFiltersPrice'>
        <div className='type-body-1-medium-desktop flex justify-between'>
          <span>${min}</span>
          <span>${max}+</span>
        </div>
        <div className='px-4' data-testid='HotelResultsFiltersPriceSlider'>
          <Slider
            range
            className={classNames(
              'select-none py-4',
              '[&>.rc-slider-handle]:bg-forest-light [&>.rc-slider-handle]:b-none [&>.rc-slider-handle]:mt--4 [&>.rc-slider-handle]:op-100 [&>.rc-slider-handle]:shadow-1 [&>.rc-slider-handle]:transition-background-color-100 [&>.rc-slider-handle]:h-8 [&>.rc-slider-handle]:w-8 [&>.rc-slider-handle]:ease-linear',
              '[&>.rc-slider-handle.rc-slider-handle-dragging]:b-none [&>.rc-slider-handle.rc-slider-handle-dragging]:bg-forest [&>.rc-slider-handle.rc-slider-handle-dragging]:shadow-1',
              '[&>.rc-slider-rail]:bg-grey-400 [&>.rc-slider-rail]:rounded-0 [&>.rc-slider-rail]:h-0.5',
              '[&>.rc-slider-track]:bg-forest [&>.rc-slider-track]:b-forest [&>.rc-slider-track]:h-0.5'
            )}
            max={max}
            min={min}
            value={[minValue, maxValue]}
            onChange={onSliderChange}
          />
        </div>
        <HotelResultsFiltersPriceFields filters={filters} onChange={onChange} />
      </div>
    )
  )
}
