import type { ReactNode } from 'react'

interface MapButtonsProps {
  children: ReactNode
}

export const MapButtons = ({ children }: MapButtonsProps) => (
  <div className={mapButtonsCss}>{children}</div>
)

const mapButtonsCss = 'bg-white flex flex-col min-w-12 shadow-1 rounded-10px'
