import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const removePublishedEventImagesMutation = gql(`
  mutation RemoveGuideEventImagesMutationInGuideDraft($input: RemovePublishedEventImagesInput!) {
    removePublishedEventImages(input: $input) {
      publishedEvent {
        id
        guide {
          id
        }
        images {
          id
        }
      }
    }
  }
`)

export const useRemoveGuideDraftEventImagesMutation = () =>
  useMutation(removePublishedEventImagesMutation)
