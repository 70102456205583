import type { DropdownOptionProps } from '@travelpass/design-system'
import { Dropdown, DropdownOption } from '@travelpass/design-system'

const options: Pick<DropdownOptionProps, 'value'>[] = [
  {
    value: 'All',
  },
  {
    value: 'Following',
  },
]

interface DashboardFeedDropdownProps {
  includeAll: boolean
  onDropdownChange(updatedValue: string): void
}

export const DashboardFeedDropdown = ({
  includeAll,
  onDropdownChange,
}: DashboardFeedDropdownProps) => (
  <div className='max-w-50'>
    <Dropdown
      fullWidth={false}
      portal={false}
      value={includeAll ? options[0].value : options[1].value}
      onChange={onDropdownChange}
    >
      {options.map(({ value }) => (
        <DropdownOption key={value} value={value}>
          {value}
        </DropdownOption>
      ))}
    </Dropdown>
  </div>
)
