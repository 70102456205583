import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { CollectionItemIdType, EventType } from 'src/__generated__/graphql'
import {
  ResultsCardContent,
  ResultsCardBanner,
  ResultsCardReviews,
} from 'src/common/components'
import { AddToMenu } from 'src/common/components/Collections/AddToMenu'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { constructAddress, getPrice } from 'src/utils'
import { HotelResultsSearchParams } from '../../../hotelResultsConstants'
import type { HotelResultsItemData } from '../../../hotelResultsTypes'
import { HotelResultsCardAmenities } from '../../HotelResultsCard'
import { HotelResultsCardRowAmenitiesContainer } from '../HotelResultsCardRowAmenitiesContainer'

interface HotelResultsCardRowContentProps {
  hotelResultsCardData: HotelResultsItemData
  onBook: Function
}

export const HotelResultsCardRowContent = ({
  hotelResultsCardData,
  onBook,
}: HotelResultsCardRowContentProps) => {
  const { isAnonymous } = useFirebaseUser()
  const [searchParams] = useSearchParams()
  const {
    id,
    amenities,
    customerReviewScore,
    hotelAddress,
    name,
    nightlyAverage: initialNightlyAverage,
    totalCustomerReviews,
  } = hotelResultsCardData ?? {}
  const { city, distance, postalCode, state, streetAddress } =
    hotelAddress ?? {}
  const address = constructAddress({
    addressFirstLine: streetAddress,
    city,
    state,
    zipcode: postalCode,
  })
  const location = searchParams.get(HotelResultsSearchParams.location)
  const nightlyAverage =
    initialNightlyAverage && getPrice(initialNightlyAverage)
  const placeName = location === 'Map area' ? address : location
  const showCardContent = !isEmpty(hotelResultsCardData) && !!name

  return (
    showCardContent && (
      <ResultsCardContent>
        <section className='space-y-4'>
          <header className='flex justify-between gap-4'>
            <div className='min-w-0 grow space-y-1'>
              <h2 className='type-h6 line-clamp-1'>{name}</h2>
              {distance && (
                <p className='color-grey-800 type-small-text-desktop truncate'>
                  {distance} to {placeName}
                </p>
              )}
            </div>
            {isAnonymous && (
              <div className='min-w-fit'>
                <ResultsCardBanner />
              </div>
            )}
          </header>
          <div className='flex gap-6'>
            <div className='grow space-y-4'>
              <ResultsCardReviews
                average={customerReviewScore}
                total={totalCustomerReviews}
                type={EventType.Stay}
              />
              <HotelResultsCardRowAmenitiesContainer>
                <HotelResultsCardAmenities amenities={amenities} />
              </HotelResultsCardRowAmenitiesContainer>
              <AddToMenu
                item={{ id, name, type: CollectionItemIdType.Hotel }}
                variant='text'
              />
            </div>
            <aside className='min-w-fit space-y-2 text-right'>
              {!!nightlyAverage && (
                <div className='space-y-1'>
                  <p className='type-body-1'>{nightlyAverage}</p>
                  <p className='color-grey-800 type-small-text'>per night</p>
                </div>
              )}
              <Button
                label='Book Now'
                size='small'
                onClick={() => onBook(hotelResultsCardData)}
              />
            </aside>
          </div>
        </section>
      </ResultsCardContent>
    )
  )
}
