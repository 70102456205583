import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { Link } from 'src/__generated__/graphql'

interface GeneralLinksProps {
  links?: Link[]
  isUserOwner?: boolean
}

export const GeneralLinks = ({ links, isUserOwner }: GeneralLinksProps) => {
  if (isEmpty(links) && !isUserOwner) return null

  return (
    <div className='box-border flex flex-col items-center gap-y-2 self-center'>
      <p className='type-h4 pb-2 sm:hidden md:block'>Links</p>
      {links?.map(({ url, title }) => (
        <Button
          key={`${title}-${url}`}
          fullWidth
          className='px-2 py-1'
          size='small'
          type='button'
          variant='outlined'
          onClick={() => window.open(url, '_blank')}
        >
          {title}
        </Button>
      ))}
    </div>
  )
}
