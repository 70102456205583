import { useQuery } from '@apollo/client'
import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import type { HotelSearchResult } from 'src/__generated__/graphql'
import { HotelCategory } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { generateHotelDetailsUrl } from 'src/utils'
import { Header } from './components/header'
import { focusSearchInput } from './utils/focusSearchInput'
import { ButtonLink } from '../../common/components/ButtonLink/ButtonLink'
import {
  HotelResultsCard,
  HotelResultsCardLoading,
} from '../hotels/results/common'

const CURATED_HOTELS_QUERY = gql(
  `query getCuratedHotels($hotelCategory: HotelCategory!, $tracker: String) {
    curatedHotels(hotelCategory: $hotelCategory, tracker: $tracker) {
      cursor
      facets {
        name
        buckets {
          count
          value
        }
      }
      sort
      hotelSearchResults {
        amenities
        id
        currencyCode
        description
        grandtotal
        hotelAddress {
          city
          country
          countryAbbreviation
          distance(unit: IMPERIAL)
          latitude
          longitude
          postalCode
          state
          stateAbbreviation
          streetAddress
        }
        images
        imagesSrcSet
        imagesData(imagesDomain: TRAVELPASS, urlVariant: PUBLIC) {
          url
          srcSet
        }
        name
        nightlyAverage
        phoneNumber
        starRating
        staticMap(height: 600)
        strikethrough
        subtotal
        totalCustomerReviews
        googlePlaceId
        isFavorited
        isCollected
        customerReviewScore
        isFeatured
      }
    }
  }`
)

export const Hotels = () => {
  const {
    error,
    loading: isLoading,
    data,
  } = useQuery(CURATED_HOTELS_QUERY, {
    variables: {
      hotelCategory: HotelCategory.SearchPageFeatured,
      tracker: 'tracker',
    },
  })

  const hotels = data?.curatedHotels?.[0]?.hotelSearchResults || []

  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !isLoading && !!hotels.length

  const getHref = (hotel: HotelSearchResult) =>
    generateHotelDetailsUrl({
      city: hotel.hotelAddress.city,
      name: hotel.name,
      id: hotel.id,
      state: hotel.hotelAddress.state,
      stateAbbreviation: hotel.hotelAddress.stateAbbreviation,
    })

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <PageLayoutContainer>
      <section className='space-y-12'>
        <div className='flex items-end justify-between'>
          <Header
            className='text-left'
            description='Make your next stay unforgettable '
            title='Featured Hotels'
          />
          <ButtonLink
            state={{ enableSmoothScroll: true }}
            to={`/${pathSegment}/hotels`}
            variant='outline'
            onClick={() => focusSearchInput()}
          >
            Search Hotels
          </ButtonLink>
        </div>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {isLoading && (
          <ul
            aria-busy={isLoading}
            className='ps-none grid list-none gap-4 md:grid-cols-2 lg:grid-cols-3'
          >
            <HotelResultsCardLoading />
            <HotelResultsCardLoading className='max-md:hidden' />
            <HotelResultsCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {hotels?.slice(0, 3).map(hotel => (
              <div
                key={hotel.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <HotelResultsCard
                  hotelResultsCardData={hotel}
                  href={getHref(hotel)}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none grid list-none gap-4 md:grid-cols-3'>
            {hotels?.slice(0, 3).map(hotel => (
              <li key={hotel.id}>
                <HotelResultsCard
                  hotelResultsCardData={hotel}
                  href={getHref(hotel)}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
    </PageLayoutContainer>
  )
}
