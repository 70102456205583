import { useCallback, useRef, useState } from 'react'
import {
  Button,
  Icon,
  Input,
  Modal,
  useScreenQuery,
} from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type { ExperiencePickupLocation } from 'src/__generated__/graphql'
import { Address } from './Address'

const LIMIT = 3
const SearchIcon = (
  <span className='c-forest-light'>
    <Icon name='search' />
  </span>
)

export const PickupPoints = ({
  points,
  details,
}: {
  points: ExperiencePickupLocation[]
  details?: string
}) => {
  const inputRef = useRef(null)
  const { isMobileScreen } = useScreenQuery()
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const pointsWithAddress = points
    .filter(p => Boolean(p.location?.address))
    .sort((a, b) => a.location?.name.localeCompare(b.location?.name) || 0)
    .map(({ location }) => {
      const address = [
        location.address,
        location?.city,
        location?.state,
        location?.postalCode,
      ]
        .filter(Boolean)
        .join(' ')
      return [location?.name, address] as [string, string]
    })

  const handleSearchTerm = useCallback(
    debounce(e => {
      setSearchTerm(e?.target?.value ?? '')
    }, 200),
    []
  )

  if (pointsWithAddress.length === 0) return null

  const addresses = searchTerm
    ? pointsWithAddress.filter(([name, address]) => {
        // case insensitive match for name or address
        const search = searchTerm.toLowerCase()
        return (
          name.toLowerCase().includes(search) ||
          address.toLowerCase().includes(search)
        )
      })
    : pointsWithAddress

  const showViewMore = pointsWithAddress.length > LIMIT

  return (
    <>
      {!!details && (
        <div className='flex flex-col gap-4'>
          <h3 className='color-grey-800 type-body-1-medium'>Pickup Details</h3>
          <p className='color-grey-800'>{details}</p>
        </div>
      )}
      <div className='flex flex-col gap-4'>
        <h3 className='color-grey-800 type-body-1-medium'>
          {`Pickup Point${pointsWithAddress.length > 1 ? 's' : ''}`}
        </h3>
        <PointsList points={pointsWithAddress?.slice(0, LIMIT)} />
      </div>
      {showModal && (
        <Modal
          size='small'
          title='Pickup Points'
          onDismiss={() => {
            setShowModal(false)
            setSearchTerm('') // reset search term
          }}
        >
          <div
            className='min-h-80svh md:min-h-50svh h-full overflow-y-auto'
            data-testid='pickup-points-scroll-contents'
          >
            <Input
              fullWidth
              aria-label='Search among pickup point addresses'
              placeholder='Search'
              ref={inputRef}
              slotBefore={SearchIcon}
              type='text'
              onKeyDown={handleSearchTerm}
            />
            {addresses.length > 0 ? (
              <PointsList points={addresses} />
            ) : (
              <p className='color-grey-800 type-body-1 py-12 text-center'>
                No pickup points found
              </p>
            )}
          </div>
        </Modal>
      )}
      {showViewMore && (
        <div className='flex items-center justify-center md:justify-start'>
          <Button
            aria-label='View more pickup points'
            label='View More'
            size={isMobileScreen ? 'small' : 'medium'}
            variant='outlined'
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
    </>
  )
}

const PointsList = ({ points }: { points: [string, string][] }) => (
  <ul className='list-disc space-y-2 pl-4'>
    {points.map(([name, address]) => (
      <li key={name + address} className='c-grey-800 type-body-1'>
        <Address address={address} name={name} />
      </li>
    ))}
  </ul>
)
