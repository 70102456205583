import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import { Button, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { isLastIndex } from 'src/utils'
import { SupportFaqEmpty } from './SupportFaqEmpty'
import type { FaqItemFuseResult } from '../../common/components'
import { SupportContainer } from '../common'

const pageSize = 5

interface SupportFaqProps {
  indices: number[]
  items: FaqItemFuseResult[]
  onIndicesChange(updatedIndices: number[]): void
}

export const SupportFaq = ({
  indices,
  items,
  onIndicesChange,
}: SupportFaqProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const paginatedItems =
    items?.slice(pageSize * currentPage - pageSize, pageSize * currentPage) ??
    []
  const totalPages = items?.length / 5

  const updateIndices = (updatedIndex: number) => {
    if (indices.includes(updatedIndex)) {
      onIndicesChange(
        indices.filter(currentIndex => currentIndex !== updatedIndex)
      )
    } else {
      onIndicesChange([...indices, updatedIndex])
    }
  }

  const onPaginate = (isNext = true) => {
    setCurrentPage(previousCurrentPage =>
      isNext ? ++previousCurrentPage : --previousCurrentPage
    )
    onIndicesChange([])
  }

  useEffect(() => setCurrentPage(1), [items])

  if (isEmpty(items)) return <SupportFaqEmpty />

  return (
    <SupportContainer>
      <div
        className='p-b-10 p-t-36 md:p-t-30 lg:p-t-36 space-y-10'
        data-testid='SupportFaq'
      >
        <Accordion
          collapsible
          multiple
          index={indices}
          onChange={updatedIndex => updateIndices(updatedIndex)}
        >
          {paginatedItems.map(({ item }, index) => (
            <AccordionItem
              key={item?.title}
              className={classNames('b-grey-300 b-solid b-t-1 b-0', {
                'b-b-1': isLastIndex(paginatedItems, index),
              })}
            >
              <AccordionButton className='b-0 pointer flex h-16 w-full items-center justify-between bg-white p-4 text-left'>
                <p className='type-body-1'>{item?.title}</p>
                <div className='color-grey-800'>
                  <Icon name='expandMore' size='large' />
                </div>
              </AccordionButton>
              <AccordionPanel>
                <div className='p-l-3 p-r-4 p-y-6 space-y-4'>
                  {item?.content}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        <div className='flex items-center justify-between gap-6'>
          <Button
            isDisabled={currentPage <= 1}
            label='Back'
            size='large'
            startIcon='arrowBack'
            variant='outlined'
            onClick={() => onPaginate(false)}
          />
          <Button
            endIcon='arrowForward'
            isDisabled={currentPage >= totalPages}
            label='Next'
            size='large'
            onClick={() => onPaginate()}
          />
        </div>
      </div>
    </SupportContainer>
  )
}
