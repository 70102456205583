import type {
  ExperienceActivityItinerary,
  ExperienceProduct,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { BookingDetailsSectionHeader } from 'src/common/components'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'
import {
  ExperiencesItineraryActivityMenu,
  ExperiencesItineraryOverview,
  ExperiencesItineraryTimeline,
} from '../common/components'
import {
  checkItineraryActivityMenu,
  checkItineraryOverview,
  checkItineraryTimeline,
} from '../common/components/utils'

interface ExperiencesBookingDetailsOverviewProps {
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsOverview = ({
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsOverviewProps) => {
  const { additionalInfo, itinerary } =
    experiencesBookingDetailsProductData ?? {}
  const { foodMenus } = (itinerary as ExperienceActivityItinerary) ?? {}
  const { unstructuredDescription, unstructuredItinerary } =
    (itinerary as ExperienceUnstructuredItinerary) ?? {}
  const showAdditionalInfo = additionalInfo?.length
  const showItineraryFoodMenu = checkItineraryActivityMenu(itinerary)
  const showItineraryOverview = checkItineraryOverview(itinerary)
  const showItineraryTimeline = checkItineraryTimeline(itinerary)
  const showOverview =
    showAdditionalInfo ||
    showItineraryFoodMenu ||
    showItineraryOverview ||
    showItineraryTimeline

  return (
    showOverview && (
      <div>
        <BookingDetailsSectionHeader title='Overview' />
        <div className={experiencesBookingDetailsOverviewCss}>
          {showAdditionalInfo && (
            <div className={experiencesBookingDetailsOverviewItemCss}>
              <h3 className={experiencesBookingDetailsOverviewItemTitleCss}>
                Additional Information
              </h3>
              <div className={experiencesBookingDetailsOverviewItemContentCss}>
                {Boolean(additionalInfo) && (
                  <ReadMoreContentSection>
                    <ul className='text-grey-800 text-body-1 m-0 list-disc gap-3 space-y-2 pl-4 md:columns-2'>
                      {additionalInfo.map(({ description }) => (
                        <li key={description}>{description}</li>
                      ))}
                    </ul>
                  </ReadMoreContentSection>
                )}
              </div>
            </div>
          )}
          {showItineraryOverview && (
            <div className={experiencesBookingDetailsOverviewItemCss}>
              <h3 className={experiencesBookingDetailsOverviewItemTitleCss}>
                What To Expect
              </h3>
              <ExperiencesItineraryOverview
                size='small'
                unstructuredDescription={unstructuredDescription}
                unstructuredItinerary={unstructuredItinerary}
              />
            </div>
          )}
          {showItineraryTimeline && (
            <div className={experiencesBookingDetailsOverviewItemCss}>
              <h3 className={experiencesBookingDetailsOverviewItemTitleCss}>
                Itinerary
              </h3>
              <ExperiencesItineraryTimeline
                itinerary={itinerary}
                size='small'
              />
            </div>
          )}
          {showItineraryFoodMenu && (
            <div className={experiencesBookingDetailsOverviewItemCss}>
              <h3 className={experiencesBookingDetailsOverviewItemTitleCss}>
                Sample Menu
              </h3>
              <ExperiencesItineraryActivityMenu foodMenus={foodMenus} />
            </div>
          )}
        </div>
      </div>
    )
  )
}

const flexWithGapCss = 'flex flex-col gap-9 md:gap-12'

const experiencesBookingDetailsOverviewCss = flexWithGapCss

const experiencesBookingDetailsOverviewItemCss = 'flex flex-col gap-4'

const experiencesBookingDetailsOverviewItemContentCss =
  'flex flex-col gap-4 md:gap-8'

const experiencesBookingDetailsOverviewItemTitleCss = 'text-h6'
