import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  GuideDraftStatus,
  type GetGuideDraftsForUserQueryInDashboardOldQuery,
  type Scalars,
} from 'src/__generated__/graphql'

export const getGuideDraftsForUserQueryOld = gql(`
  query GetGuideDraftsForUserQueryInDashboardOld($after: String, $first: Int = 10, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    currentUser {
      id
      userGuideDrafts(after: $after, first: $first, userGuideDraftsArgs: $userGuideDraftsArgs) {
        edges {
          node {
            id
            addresses {
              id
              addressLine1
              city
              country
              googlePlaceId
              lat
              long
              state
            }
            description
            guide {
              id
              addresses {
                id
                addressLine1
                city
                country
                googlePlaceId
                lat
                long
                state
              }
              collectedCount
              description
              imageUrl
              isCollected
              likedByCurrentUser
              name
              numberOfLikes
              paginatedImages(first: 1) {
                edges {
                  node {
                    id
                    source
                    url
                  }
                }
              }
              shareCount
              status
              viewCount
            }
            imageUrl
            name
            owner {
              id
            }
            ownerProfile {
              id
              accountHandle
              displayName
              isUserOwner
              profileImageUrl
              profileImageSource
              userId
            }
            status
            tags {
              id
              name
            }
            timeZone
            updatedAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`)

type UseGetDashboardGuideDraftsQueryOld = {
  hasError: ApolloError
  dashboardGuideDraftsData: GetGuideDraftsForUserQueryInDashboardOldQuery['currentUser']['userGuideDrafts']
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

const useGetDashboardGuideDraftsQueryOld =
  (): UseGetDashboardGuideDraftsQueryOld => {
    const {
      data,
      error: hasError,
      fetchMore: onGetMoreResultsQuery,
      loading: isLoading,
    } = useQuery(getGuideDraftsForUserQueryOld, {
      /** @todo remove errorPolicy when BE errors are fixed. */
      errorPolicy: 'ignore',
      variables: {
        userGuideDraftsArgs: {
          includeStatuses: [GuideDraftStatus.Active],
        },
      },
    })

    const onGetMoreResults = async ({
      after,
    }: {
      after: Scalars['String']['input']
    }) => {
      try {
        await onGetMoreResultsQuery({
          variables: {
            after,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            ...previousResult,
            currentUser: {
              ...previousResult.currentUser,
              userGuideDrafts: {
                ...fetchMoreResult?.currentUser?.userGuideDrafts,
                edges:
                  previousResult?.currentUser?.userGuideDrafts?.edges?.concat(
                    fetchMoreResult?.currentUser?.userGuideDrafts?.edges
                  ),
              },
            },
          }),
        })
      } catch (error) {
        console.error('Server error')
      }
    }

    return {
      dashboardGuideDraftsData: data?.currentUser?.userGuideDrafts,
      hasError,
      isLoading,
      onGetMoreResults,
    }
  }

export {
  type UseGetDashboardGuideDraftsQueryOld,
  useGetDashboardGuideDraftsQueryOld,
}
