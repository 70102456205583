import { MenuItem } from '@travelpass/design-system'
import { menuItems } from './hotelResultsSortConstants'

interface HotelResultsSortMenuItemsProps {
  onClick?(updatedValue: string): void
  value?: string
}

export const HotelResultsSortMenuItems = ({
  onClick = () => {},
  value,
}: HotelResultsSortMenuItemsProps) => (
  <>
    {menuItems.map(item => (
      <MenuItem
        key={item.value}
        isHighlighted={item.value === value}
        label={item.label}
        value={value}
        onClick={() => onClick(item.value)}
      />
    ))}
  </>
)
