import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { getProductTypesAsObject } from 'src/utils'
import { TripDetailsExploreListApollo } from './TripDetailsExploreList'
import { TripDetailsExploreNav } from './TripDetailsExploreNav'
import type {
  ExploreExperiencesQueryResult,
  ExploreHotelsQueryResult,
} from '../constants'
import { ExploreSearchParams } from '../constants'
import { constructExperienceArguments, constructHotelArguments } from '../utils'

interface TripDetailsExploreProps {
  experiencesQueryResult: ExploreExperiencesQueryResult
  hotelsQueryResult: ExploreHotelsQueryResult
  hoveredEventId?: string
  onHoveredEventIdChange?(updatedHoveredId: string): void
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripDetailsExplore = ({
  experiencesQueryResult,
  hotelsQueryResult,
  hoveredEventId,
  onHoveredEventIdChange,
  tripDetailsData,
}: TripDetailsExploreProps) => {
  const [searchParams] = useSearchParams()
  const [getExperiences, experiencesResult] = experiencesQueryResult
  const [getHotels, hotelsResult] = hotelsQueryResult

  useEffect(() => {
    const { isExperience } = getProductTypesAsObject(
      searchParams.get(ExploreSearchParams.productType)
    )

    if (isExperience) {
      getExperiences(
        constructExperienceArguments({
          tagIds: searchParams.get(ExploreSearchParams.tagIds),
          tripDetailsData,
        })
      )
    } else {
      getHotels(constructHotelArguments(tripDetailsData))
    }
  }, [searchParams])

  return (
    <div className='space-y-3 md:space-y-4'>
      <div className='space-y-4'>
        <TripDetailsExploreNav tripDetailsData={tripDetailsData} />
      </div>
      <TripDetailsExploreListApollo
        experiencesResult={experiencesResult}
        hotelsResult={hotelsResult}
        hoveredEventId={hoveredEventId}
        tripDetailsData={tripDetailsData}
        onHoveredEventIdChange={onHoveredEventIdChange}
      />
    </div>
  )
}
