import { DestinationLocationAbout } from './DestinationLocationAbout'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocation = ({
  destination,
}: DestinationLocationProps) => (
  <div className='p-t-10 lg:p-t-20'>
    <DestinationLocationAbout destination={destination} />
  </div>
)
