const bookingDetailsHeroActionsCss = 'flex gap-3 justify-between'

const bookingDetailsHeroContentCss = 'flex flex-col gap-4'

const bookingDetailsHeroCopyButtonCss =
  'cursor-pointer ease-linear flex gap-3 items-center text-body-1 transition-opacity-200 active:opacity-70 hover:opacity-70'

const bookingDetailsHeroCss = 'flex flex-col gap-6'

const bookingDetailsHeroImageCss =
  'h-50 object-cover overflow-hidden rounded-4 w-full md:h-103'

const bookingDetailsHeroTitleCss = 'text-h4'

export {
  bookingDetailsHeroActionsCss,
  bookingDetailsHeroContentCss,
  bookingDetailsHeroCopyButtonCss,
  bookingDetailsHeroCss,
  bookingDetailsHeroImageCss,
  bookingDetailsHeroTitleCss,
}
