import { Icon, type AvailableIcons, Divider } from '@travelpass/design-system'

interface OverviewAtAGlanceItemProps {
  hideDivider?: boolean
  number: number
  suffix: string
}

export const OverviewAtAGlanceItem = ({
  hideDivider = false,
  suffix,
  number = 0,
}: OverviewAtAGlanceItemProps) => (
  <div className='flex gap-6 lg:gap-9'>
    <div className='flex flex-col'>
      <span className='color-forest-light type-h1-mobile type-h2-desktop'>
        {String(number).padStart(number > 0 ? 2 : 1, '0')}
      </span>
      <p className='type-body-2-mobile type-small-text-desktop'>{`${number} ${suffix}`}</p>
    </div>
    {!hideDivider && (
      <Divider
        className='w-1px m-0 h-full'
        data-testid='OverviewAtAGlanceItem-divider'
      />
    )}
  </div>
)
