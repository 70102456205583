import { useRef, useState } from 'react'
import {
  Chip,
  Divider,
  IconButton,
  MenuItem,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'
import type { PublishedEventCategory } from 'src/__generated__/graphql'
import type { GuideDraftDataOld } from '../../../types'
import { useArchiveGuideDraftEventMutation } from '../../../useArchiveGuideDraftEventMutation'
import { useUpdateGuideDraftEventSortOrderMutation } from '../../../useUpdateGuideDraftEventSortOrderMutation'
import { GuideDraftEventDeleteModal } from '../../GuideDraftEventDeleteModal'

interface GuideDraftEventCardMenuProps {
  onSelectedIdChange(updatedSelectedId: string): void
  paginatedEventCategories?: GuideDraftDataOld['paginatedEventCategories']
  paginatedEventCategoryId: string
  publishedEventId: string
  showDeleteWarningModal: boolean
}

export const GuideDraftEventCardMenu = ({
  onSelectedIdChange,
  paginatedEventCategories,
  paginatedEventCategoryId,
  publishedEventId,
  showDeleteWarningModal,
}: GuideDraftEventCardMenuProps) => {
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const ref = useRef<HTMLDivElement>(null)
  const { isMobileOrTablet } = useScreenQuery()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [updatePublishedEventSortOrder] =
    useUpdateGuideDraftEventSortOrderMutation()

  const onDelete = async () => {
    try {
      if (showDeleteWarningModal && !isDeleteModalOpen) {
        setIsDeleteModalOpen(true)
        return
      }
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId,
          },
        },
      })
      setIsDeleteModalOpen(false)
      onSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    } finally {
      setIsPopoverOpen(false)
    }
  }

  const onMoveToSectionClick = async (
    paginatedEventCategoryId: PublishedEventCategory['id']
  ) => {
    try {
      const eventSortOrder =
        paginatedEventCategories?.edges
          ?.find(({ node }) => node?.id === paginatedEventCategoryId)
          ?.node?.publishedEvents?.edges?.map(({ node }) => node?.id) ?? []
      await updatePublishedEventSortOrder({
        variables: {
          input: {
            eventSortOrder,
            publishedEventCategoryId: paginatedEventCategoryId,
            publishedEventId,
          },
        },
      })
      onSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    } finally {
      setIsPopoverOpen(false)
    }
  }

  return (
    <>
      <Popover
        align={isMobileOrTablet ? 'end' : 'center'}
        containerClassName='z-5'
        content={
          <div className='max-h-45 of-y-auto shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            {paginatedEventCategories?.edges?.map(({ node }) => (
              <MenuItem
                key={node?.id}
                aria-label={`Move event to ${node?.name}`}
                isHighlighted={node?.id === paginatedEventCategoryId}
                label={node?.name}
                value={node?.id}
                onClick={() => onMoveToSectionClick(node?.id)}
              />
            ))}
            {!!paginatedEventCategories?.edges?.length && <Divider />}
            <MenuItem
              aria-label='Delete event'
              label='Delete'
              startIcon='deleteOutline'
              value='delete'
              onClick={onDelete}
            />
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div className='relative' ref={ref}>
          <div className='hidden md:block'>
            {paginatedEventCategoryId && (
              <div className='[&>button]:color-forest'>
                <IconButton
                  icon='expandMore'
                  size='large'
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                />
              </div>
            )}
            {!paginatedEventCategoryId && (
              <Chip
                icon='arrowDownIos'
                isSelected={false}
                label='Move to'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            )}
          </div>
          <div className='[&>button]:color-forest md:hidden'>
            <IconButton
              icon='expandMore'
              size='small'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
        </div>
      </Popover>
      {isDeleteModalOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
