import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import type { CurrentUserTripsQuery } from 'src/__generated__/graphql'
import { TripStatus } from 'src/__generated__/graphql'
import { currentUserTripsGQL } from '../trips/home/components/MyTripsList/hooks'

type UseGetDashboardTripsQuery = {
  hasError: ApolloError
  isLoading: boolean
  tripsPastData: CurrentUserTripsQuery['currentUser']['trips']['edges']
  tripsUpcomingData: CurrentUserTripsQuery['currentUser']['trips']['edges']
}

/** @todo add paginatation or BE removes the first argument */
export const useGetDashboardTripsQuery = (): UseGetDashboardTripsQuery => {
  const {
    data: initialTripsPastData,
    error: hasError,
    loading: isLoading,
  } = useQuery(currentUserTripsGQL, {
    variables: {
      tripSearchInput: {
        status: TripStatus.Past,
      },
    },
  })
  const { data: initialTripsUpcomingData } = useQuery(currentUserTripsGQL, {
    variables: {
      tripSearchInput: {
        status: TripStatus.Upcoming,
      },
    },
  })
  const tripsPastData = initialTripsPastData?.currentUser?.trips?.edges ?? []
  const tripsUpcomingData =
    initialTripsUpcomingData?.currentUser?.trips?.edges ?? []

  return {
    hasError,
    isLoading,
    tripsPastData,
    tripsUpcomingData,
  }
}
