import { useEffect, useRef } from 'react'
import { Icon } from '@travelpass/design-system'
import { createPortal } from 'react-dom'

interface HowToEnterModalProps {
  onClose: VoidFunction
  src: string
  title: string
}

export const VideoModal = ({ onClose, src, title }: HowToEnterModalProps) => {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      if (ref.current === e.target) {
        onClose?.()
      }
    }
    window.addEventListener('mousedown', handleOutsideClick)
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const modal = (
    <dialog
      open
      className='of-hidden fixed bottom-0 left-0 right-0 top-0 z-10 flex h-full w-full border-none bg-black/60 p-0'
      ref={ref}
    >
      <div className='m-auto flex h-fit w-fit w-full flex-col'>
        <button
          className='c-white b-none -mt-8 cursor-pointer self-end bg-transparent p-0'
          type='button'
          onClick={onClose}
        >
          <Icon name='clear' size='large' />
        </button>
        <iframe
          allowFullScreen
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          className='b-none w-1206px aspect-video max-w-full'
          referrerPolicy='strict-origin-when-cross-origin'
          src={src}
          title={title}
        ></iframe>
      </div>
    </dialog>
  )

  return createPortal(modal, document.body)
}
