import type { ContextType } from 'react'
import { useRef, useState } from 'react'
import { Button } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { createSearchParams, useNavigate } from 'react-router-dom'
import type { Trip } from 'src/__generated__/graphql'
import { Scroller, ScrollerButton } from 'src/common/components'
import { tripTimelinePath } from 'src/constants'
import { TripDeleteModal, TripEditModal } from 'src/pages/trips/components'
import { TripCard } from './TripCard'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>
type CurrentUserTrips = Trip[]

export const UserTripsList = ({
  userTrips,
}: {
  userTrips: CurrentUserTrips
}) => {
  const [displayCount, setDisplayCount] = useState(4)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [currentTripId, setCurrentTripId] = useState<null | string>(null)
  const [currentTripName, setCurrentTripName] = useState('')

  const apiRef = useRef({} as ScrollVisibilityApiType)

  const trips = userTrips ?? []
  const navigate = useNavigate()

  const onLoadMore = () => {
    if (displayCount < trips.length) {
      const updatedDisplayCount = displayCount + 4
      setDisplayCount(updatedDisplayCount)
    }
  }

  const onDeleteTripOpen = (tripId: string, tripName: string) => {
    setCurrentTripId(tripId)
    setCurrentTripName(tripName)
    setIsEditModalOpen(false)
    setIsDeleteModalOpen(true)
  }

  const onDeleteTripClose = () => {
    setCurrentTripId(null)
    setIsEditModalOpen(true)
    setIsDeleteModalOpen(false)
  }

  const onEditTripOpen = (tripId: string, tripName: string) => {
    setCurrentTripId(tripId)
    setCurrentTripName(tripName)
    setIsEditModalOpen(true)
  }

  const onEditTripClose = () => {
    setCurrentTripId(null)
    setIsEditModalOpen(false)
  }

  const onCardClick = (tripId: string) => {
    const url = `${tripTimelinePath}/${tripId}`
    window.open(url)
  }

  return (
    <div className='md:pl-18 flex w-full flex-col-reverse items-center justify-center px-12 md:flex-row md:pr-0 lg:px-12'>
      <div className='display-initial max-w-770px lg:max-w-1440px invisible hidden h-0 w-0 w-full min-w-0 grow px-2 md:visible md:block md:h-auto md:w-auto'>
        <Scroller
          LeftArrow={
            <span className='invisible hidden pr-5 lg:visible lg:block'>
              <ScrollerButton icon='arrowBackIos' size='small' />
            </span>
          }
          RightArrow={
            <span className='invisible hidden pl-5 lg:visible lg:block'>
              <ScrollerButton isNext icon='arrowForwardIos' size='small' />
            </span>
          }
          apiRef={apiRef}
          scrollContainerClassName='gap-3 p-4'
        >
          {trips?.map(userTrip => {
            const tripId = userTrip?.id ?? ''
            const tripName = userTrip?.name ?? ''
            return (
              <div key={`trip-card-${tripId}`} className='md:w-70.75 w-full'>
                <TripCard
                  userTripData={userTrip}
                  onClick={() => onCardClick(tripId)}
                  onDeleteClick={() => onDeleteTripOpen(tripId, tripName)}
                  onEditClick={() => onEditTripOpen(tripId, tripName)}
                />
              </div>
            )
          })}
        </Scroller>
      </div>

      <div className='visible block w-full space-y-6 md:invisible md:hidden'>
        {userTrips?.slice(0, displayCount)?.map(userTrip => {
          const tripId = userTrip?.id ?? ''
          const tripName = userTrip?.name ?? ''

          return (
            <TripCard
              key={`trip-card-${tripId}`}
              userTripData={userTrip}
              onClick={() => onCardClick(tripId)}
              onDeleteClick={() => onDeleteTripOpen(tripId, tripName)}
              onEditClick={() => onEditTripOpen(tripId, tripName)}
            />
          )
        })}
        <div className='flex w-full flex-row items-center justify-center pt-12'>
          <Button
            aria-label='Load more trips'
            disabled={displayCount >= trips.length}
            size='large'
            variant='text'
            onClick={onLoadMore}
          >
            Load More
          </Button>
        </div>
      </div>
      {isEditModalOpen && currentTripId && (
        <TripEditModal
          tripId={currentTripId}
          onClose={onEditTripClose}
          onDeleteOpen={() => onDeleteTripOpen(currentTripId, currentTripName)}
        />
      )}
      {isDeleteModalOpen && currentTripId && (
        <TripDeleteModal
          tripId={currentTripId}
          tripName={currentTripName}
          onClose={onDeleteTripClose}
        />
      )}
    </div>
  )
}
