import { useEffect, useMemo } from 'react'
import groupBy from 'lodash.groupby'
import type {
  PaxMix,
  ExperienceBookingQuestion,
  ExperienceLanguageGuide,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { BookingQuestionAnswer } from './BookingQuestionAnswer'
import { Fieldset } from './Fieldset'
import { LanguageGuideField } from './LanguageGuideField'
import { PickupPointField } from './PickupPointField'
import { ProductIdContext } from './ProductIdContext'
import { TransferArrivalModeFields } from './TransferArrivalModeFields'
import { TransferDepartureModeFields } from './TransferDepartureModeFields'
import { conditionalQuestionsMap } from './bookingQuestionUtil'
import { useFormContext } from './useExperienceBookingForm'

interface ExperienceBookingQuestionField extends ExperienceBookingQuestion {
  travelerNum?: number
  fieldIndex?: number
}

export interface BookingQuestionsFieldsProps {
  experienceProduct: ExperienceProduct
  languageGuides: ExperienceLanguageGuide[]
  pickupAllowed: boolean
  paxMix: PaxMix[]
}

export const BookingQuestionsFields = ({
  experienceProduct,
  languageGuides,
  pickupAllowed,
  paxMix,
}: BookingQuestionsFieldsProps) => {
  const { setValue } = useFormContext()
  const questions = useMemo(() => {
    if (!experienceProduct) return {}

    // sum number of travelers from paxMix
    const numberOfTravelers = paxMix.reduce((total, { numberOfTravelers }) => {
      total += numberOfTravelers
      return total
    }, 0)

    const transferArrivalModeQuestionIds = [].concat(
      ...Object.values(conditionalQuestionsMap.TRANSFER_ARRIVAL_MODE)
    )

    const transferDepartureModeQuestionIds = [].concat(
      ...Object.values(conditionalQuestionsMap.TRANSFER_DEPARTURE_MODE)
    )

    let pickupPoint: ExperienceBookingQuestion

    // Helper to categorize questions based on conditions

    const transferArrivalMode = experienceProduct.bookingQuestions.find(
      question => question.id === 'TRANSFER_ARRIVAL_MODE'
    )

    const transferDepartureMode = experienceProduct.bookingQuestions.find(
      question => question.id === 'TRANSFER_DEPARTURE_MODE'
    )

    const transformExperienceProductWithCategorization =
      (
        transferArrivalMode: ExperienceBookingQuestion,
        transferDepartureMode: ExperienceBookingQuestion,
        transferArrivalModeQuestionIds: string[],
        transferDepartureModeQuestionIds: string[]
      ) =>
      (inputBookingQuestions: ExperienceBookingQuestion[]) => {
        type Output = {
          conditionalTransferArrivalMode: ExperienceBookingQuestion[]
          conditionalTransferDepartureMode: ExperienceBookingQuestion[]
          hasAgeBand: boolean
          bookingQuestions: ExperienceBookingQuestion[]
        }
        const initialState: Output = {
          conditionalTransferArrivalMode: [],
          conditionalTransferDepartureMode: [],
          hasAgeBand: false,
          bookingQuestions: [],
        }

        return inputBookingQuestions.reduce(
          (acc: Output, question: ExperienceBookingQuestion) => {
            // Integrated categorizeQuestion logic
            let category
            if (
              question.id === 'TRANSFER_ARRIVAL_MODE' ||
              question.id === 'TRANSFER_DEPARTURE_MODE'
            ) {
              category = undefined // skip models
            } else if (question.id === 'TRANSFER_PORT_CRUISE_SHIP') {
              if (transferArrivalMode)
                category = 'conditionalTransferArrivalMode'
              else if (transferDepartureMode)
                category = 'conditionalTransferDepartureMode'
              else category = 'bookingQuestions'
            } else if (transferArrivalModeQuestionIds.includes(question.id)) {
              category = 'conditionalTransferArrivalMode'
            } else if (transferDepartureModeQuestionIds.includes(question.id)) {
              category = 'conditionalTransferDepartureMode'
            } else if (question.id === 'AGEBAND') {
              category = 'hasAgeBand'
            } else {
              category = 'bookingQuestions'
            }

            if (category === 'hasAgeBand') return { ...acc, hasAgeBand: true }
            if (category === undefined) return acc // skipping transfers mode
            return {
              ...acc,
              [category]: [...acc[category], question],
            }
          },
          initialState
        )
      }

    const q = transformExperienceProductWithCategorization(
      transferArrivalMode,
      transferDepartureMode,
      transferArrivalModeQuestionIds,
      transferDepartureModeQuestionIds
    )(experienceProduct.bookingQuestions)

    const {
      conditionalTransferArrivalMode,
      conditionalTransferDepartureMode,
      hasAgeBand,
      bookingQuestions,
    } = q

    /**
     * All “CONDITIONAL” booking questions that depend on the answer given to either “TRANSFER_ARRIVAL_MODE” or “TRANSFER_DEPARTURE_MODE”
     * should be considered “MANDATORY” if they are stipulated in the “bookingQuestions” array of the product content response,
     * and the respective transfer mode question is not stipulated.
     */
    const hasTransferArrivalMode = Boolean(transferArrivalMode)
    if (!hasTransferArrivalMode) {
      conditionalTransferArrivalMode.forEach(question => {
        const updatedQuestion = { ...question, required: 'MANDATORY' }
        if (question.id === 'PICKUP_POINT') {
          // save a reference to PICKUP_POINT for if TRANSFER_ARRIVAL_MODE isn't present
          pickupPoint = updatedQuestion
        } else {
          bookingQuestions.push(updatedQuestion)
        }
      })
    }

    const hasDepartureArrivalMode = Boolean(transferDepartureMode)
    if (!hasDepartureArrivalMode) {
      conditionalTransferDepartureMode.forEach(question => {
        const updatedQuestion = { ...question, required: 'MANDATORY' }
        bookingQuestions.push(updatedQuestion)
      })
    }

    // group by 'grouping' to display in the correct order
    const { PER_BOOKING, PER_TRAVELER } = groupBy(bookingQuestions, 'grouping')

    const perTraveler =
      PER_TRAVELER?.length &&
      Array.from({ length: numberOfTravelers }, (_, travelerIndex) => {
        // create copy of PER_TRAVELER questions per traveler, and add travelerNum
        return PER_TRAVELER?.map(
          question =>
            ({
              ...question,
              travelerNum: travelerIndex + 1, // add travelerNum value
            }) as ExperienceBookingQuestionField
        )
      })

    return {
      perBooking: PER_BOOKING,
      perTraveler,
      transferArrivalMode,
      conditionalTransferArrivalMode,
      transferDepartureMode,
      conditionalTransferDepartureMode,
      pickupPoint,
      numberOfTravelers,
      hasAgeBand,
    }
  }, [experienceProduct])

  useEffect(() => {
    if (questions.hasAgeBand) {
      const agebandAnswers = paxMix
        .map(({ numberOfTravelers, ageBand }) => {
          return Array.from(
            { length: numberOfTravelers },
            (_, travelerIndex) => {
              return {
                question: 'AGEBAND',
                travelerNum: travelerIndex + 1,
                answer: ageBand,
              }
            }
          )
        })
        .flat()

      setValue('answers.ageband', agebandAnswers)
    }
  }, [questions.hasAgeBand, paxMix])

  const isTransferDepartureOtherAllowed =
    !!questions.transferArrivalMode?.allowedAnswers?.some(
      answer => answer === 'OTHER'
    )

  return (
    <ProductIdContext.Provider value={experienceProduct?.id}>
      {questions.perTraveler?.map((questions, travelerIndex) => {
        const { travelerNum } = questions[0]
        const legend =
          travelerNum === 1 ? 'Lead Traveler' : `Traveler ${travelerIndex + 1}`
        return (
          <Fieldset
            key={`experience-traveler-${travelerNum}`}
            id={`experience-traveler-${travelerNum}`}
            legend={legend}
          >
            {questions.map((question, questionIndex) => (
              <BookingQuestionAnswer
                key={`${travelerNum}-${question.id}`}
                field={`answers.booking.${travelerNum}.${questionIndex}`}
                question={question}
                travelerNum={travelerNum}
              />
            ))}
          </Fieldset>
        )
      })}
      <Fieldset id='experience-group-options' legend='Group Options'>
        {questions.perBooking?.map((question, questionIndex) => (
          <BookingQuestionAnswer
            key={question.id}
            field={`answers.booking.0.${questionIndex}`}
            question={question}
          />
        ))}
        {Boolean(questions.transferArrivalMode) && (
          <TransferArrivalModeFields
            conditionalFields={questions.conditionalTransferArrivalMode}
            experienceProduct={experienceProduct}
            pickupAllowed={pickupAllowed}
            question={questions.transferArrivalMode}
          />
        )}
        {Boolean(questions.pickupPoint) && pickupAllowed && (
          <PickupPointField
            allowCustomTravelerPickup={
              experienceProduct.logistics.travelerPickup
                .allowCustomTravelerPickup
            }
            locations={experienceProduct.logistics.travelerPickup.locations}
            name='answers.transferArrivalMode.0'
            pickupPoint={questions.pickupPoint}
          />
        )}
        {Boolean(questions.transferDepartureMode) && (
          <TransferDepartureModeFields
            allowOther={isTransferDepartureOtherAllowed}
            conditionalFields={questions.conditionalTransferDepartureMode}
            question={questions.transferDepartureMode}
          />
        )}
        {Boolean(languageGuides?.length) && (
          <LanguageGuideField languageGuides={languageGuides} />
        )}
      </Fieldset>
    </ProductIdContext.Provider>
  )
}
