import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query miscellaneousPlacesData($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      address
      amenities
      city
      country
      description
      googlePlaceId
      imageLinks
      longitude
      latitude
      name
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      starRating
      state
      website
    }
  }
`)

export const useMiscellaneousPlacesData = () => {
  const [
    getMiscellaneousPlacesData,
    { data: miscellaneousPlacesData, loading, error },
  ] = useLazyQuery(getPlaceDetailsQuery)
  const data = miscellaneousPlacesData?.getPlaceDetails ?? null

  return {
    getMiscellaneousPlacesData,
    data,
    error,
    loading,
  }
}
