import { useState } from 'react'
import { Icon, Link } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import {
  BookingDetailsSectionHeader,
  HotelAmenitiesModal,
  HotelCancellationBadge,
  HotelCancellationModal,
  OccupancySummary,
} from 'src/common/components'

import { constructPopularHotelAmenities } from 'src/utils'
import {
  bookingDetailsRoomAmenitiesClasses,
  bookingDetailsRoomAmenityClasses,
  bookingDetailsRoomClasses,
  bookingDetailsRoomGroupClasses,
  bookingDetailsRoomSummaryContentClasses,
  bookingDetailsRoomSummaryClasses,
  bookingDetailsRoomSummaryImageClasses,
  bookingDetailsRoomTitleClasses,
} from './bookingDetailsRoomStyles'
import type {
  BookingDetailsRoomHotel,
  BookingDetailsRoomNode,
} from './bookingDetailsRoomTypes'
import {
  useBookingDetailsCancellationPolicyQuery,
  useBookingDetailsDatesQuery,
} from '../../hooks'

interface BookingDetailsRoomProps {
  bookingDetailsData: BookingDetailsRoomNode
  bookingDetailsHotelData: BookingDetailsRoomHotel
}

export const BookingDetailsRoom = ({
  bookingDetailsData,
  bookingDetailsHotelData,
}: BookingDetailsRoomProps) => {
  const { bookingId } = useParams()
  const [isHotelAmenitiesModalOpen, setIsHotelAmenitiesModalOpen] =
    useState(false)
  const [isHotelCancellationModalOpen, setIsHotelCancellationModalOpen] =
    useState(false)
  const { bookingDetailsCancellationPolicyData } =
    useBookingDetailsCancellationPolicyQuery({ bookingId })
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({
    bookingId,
  })
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = bookingDetailsCancellationPolicyData?.bookingDetails?.cancelPolicy ?? {}
  const { hotelDetails } = bookingDetailsData?.bookingDetails ?? {}
  const { arrival, bookingDetails, departure } = bookingDetailsDatesData ?? {}
  const { occupancies } = bookingDetails ?? {}
  const { amenities } = bookingDetailsHotelData ?? {}
  const { policyText } = constructedCancellationPolicies ?? {}
  const { images, name } = hotelDetails?.room ?? {}
  const image = images?.[0]?.href
  const showCancellationPolicy = refundType !== 'NONE'

  return (
    <>
      {isHotelAmenitiesModalOpen && (
        <HotelAmenitiesModal
          amenities={amenities}
          onClose={() => setIsHotelAmenitiesModalOpen(false)}
        />
      )}
      {isHotelCancellationModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={policyText}
          onClose={() => setIsHotelCancellationModalOpen(false)}
        />
      )}
      <div>
        <BookingDetailsSectionHeader title='Booking Details' />
        <div
          className={bookingDetailsRoomClasses}
          data-testid='BookingDetailsRoom'
        >
          <div className={bookingDetailsRoomSummaryClasses}>
            {!!image && (
              <img
                alt={`${name} hero - Booking Details`}
                className={bookingDetailsRoomSummaryImageClasses}
                src={image}
              />
            )}
            <div className={bookingDetailsRoomSummaryContentClasses}>
              <h3 className={bookingDetailsRoomTitleClasses}>{name}</h3>
              <OccupancySummary
                arrival={arrival}
                departure={departure}
                occupancies={occupancies}
              />
              {showCancellationPolicy && (
                <div className='hide-on-print'>
                  <HotelCancellationBadge
                    type={refundType}
                    onClick={() => setIsHotelCancellationModalOpen(true)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='hide-on-print'>
            {!isEmpty(amenities) && (
              <div className={bookingDetailsRoomGroupClasses}>
                <h3 className={bookingDetailsRoomTitleClasses}>
                  Popular Hotel Amenities
                </h3>
                <div
                  className={bookingDetailsRoomAmenitiesClasses}
                  data-testid='BookingDetailsRoom-amenities'
                >
                  {constructPopularHotelAmenities(amenities)
                    ?.slice(0, 6)
                    ?.map(({ icon, label }) => (
                      <div
                        key={label}
                        className={bookingDetailsRoomAmenityClasses}
                      >
                        <Icon name={icon} />
                        <p>{label}</p>
                      </div>
                    ))}
                </div>
                <Link
                  label='Show all amenities'
                  onClick={() => setIsHotelAmenitiesModalOpen(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
