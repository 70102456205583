import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { BookingStatusEnum } from 'src/__generated__/graphql'
import { experiencesSegment } from 'src/constants'
import { getDateFromUTC } from 'src/utils'
import type { ExperienceBookingTemp } from './types'
import { useExperienceImage } from './useExperienceImage'
import { BookingCard, BookingCardLoading } from '../common/components'
dayjs.extend(customParseFormat)

interface ExperienceBookingProps {
  booking: ExperienceBookingTemp
  className?: string
  onClick?: () => void
}

export const ExperienceBooking = ({
  booking,
  className,
  onClick,
}: ExperienceBookingProps) => {
  const {
    id,
    externalConfirmationId,
    travelDate,
    startTime: milTime,
    productName,
    productCode,
    status,
    paxMix,
  } = booking?.node ?? {}
  const { numberOfTravelers } = paxMix?.[0] ?? {}
  const cancelled = status === BookingStatusEnum.Canceled
  const startDate = getDateFromUTC(travelDate)
  const startTime = dayjs(milTime, 'HHmm')

  const route = `/${experiencesSegment}/confirmation/${externalConfirmationId}`

  const { imageLink, loading } = useExperienceImage(productCode)

  if (loading) return <BookingCardLoading className='min-w-74 w-full' />

  return (
    <BookingCard
      key={id}
      bookingCardData={{
        adults: numberOfTravelers,
        cancelled,
        id,
        image: imageLink,
        name: productName,
        route,
        startDate,
        startTime,
      }}
      className={className}
      onBookingCardClick={onClick}
    />
  )
}
