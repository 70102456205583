import { useState } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { Icon } from '@travelpass/design-system'
import { Geocoder } from 'src/common/components'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'

export const GuidesEventsMapGeocoder = () => {
  const map = useGoogleMap()
  const [geocoder, setGeocoder] = useState(initialGeocoder)

  const onGeocoderChange = async (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      setGeocoder(updatedGeocoder)

      if (updatedGeocoder.viewport) {
        const bounds = new google.maps.LatLngBounds(
          updatedGeocoder.viewport.getSouthWest(),
          updatedGeocoder.viewport.getNorthEast()
        )
        map?.fitBounds(bounds)
      }
    }
  }

  return (
    <div className='lg:w-170 w-full'>
      <Geocoder
        fullWidth
        aria-label='Filter options by location'
        config={{
          requestOptions: {
            componentRestrictions: {
              country: null,
            },
          },
        }}
        focusOnInput={false}
        geocoder={geocoder}
        placeholder='Filter options by location'
        slotBefore={
          <div className='color-new-forest'>
            <Icon name='placeOutline' />
          </div>
        }
        onResult={onGeocoderChange}
      />
    </div>
  )
}
