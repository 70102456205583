export const Address = ({
  name,
  address,
  description,
}: {
  name?: string
  address?: string
  description?: string
}) => {
  return (
    <>
      {name && address && (
        <address className='not-italic'>
          <p className='color-grey-800 type-body-1'>{name}</p>
          <p className='color-grey-800 type-body-1'>{address}</p>
        </address>
      )}
      {description && (
        <p className='color-grey-800 type-body-1'>{description}</p>
      )}
    </>
  )
}
