import isEmpty from 'lodash.isempty'
import type {
  ExperienceActivityItinerary,
  ExperienceHopOnHopOffItinerary,
  ExperienceItinerary,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'

const checkItineraryActivityMenu = (itinerary: ExperienceItinerary) => {
  const { foodMenus } = (itinerary as ExperienceActivityItinerary) || {}

  return !isEmpty(foodMenus)
}

const checkItineraryOverview = (itinerary: ExperienceItinerary) => {
  const { unstructuredDescription, unstructuredItinerary } =
    (itinerary as ExperienceUnstructuredItinerary) || {}

  return !!unstructuredDescription || !!unstructuredItinerary
}

const checkItineraryTimeline = (itinerary: ExperienceItinerary) => {
  const { itineraryItems } = (itinerary as ExperienceStandardItinerary) || {}
  const { routes } = (itinerary as ExperienceHopOnHopOffItinerary) || {}

  return !isEmpty(itineraryItems) || !isEmpty(routes)
}

export {
  checkItineraryActivityMenu,
  checkItineraryOverview,
  checkItineraryTimeline,
}
