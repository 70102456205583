import type {
  ExperienceFilters,
  ExperienceStartEndPoint,
  ExperiencePickupLocation,
  GetTripDetailsQueryInTripsQuery,
  CreateEventMutationInTripDetailsMutationVariables,
  ExperienceStandardItinerary,
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQueryVariables,
  SearchHotelsQueryInTripsDetailsExploreHotelsQueryVariables,
} from 'src/__generated__/graphql'
import { EventType, EventStatus } from 'src/__generated__/graphql'
import {
  constructImageLink,
  formatDate,
  getDateFromUTC,
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGeocoderPlaceName,
  getTracker,
} from 'src/utils'
import type {
  ExploreExperience,
  ExploreHotel,
  ExploreItem,
  ExploreMarker,
} from '../constants/tripDetailsExploreConstants'
import { initialSize } from '../constants/tripDetailsExploreConstants'

const constructExperienceArguments = ({
  tagIds,
  tripDetailsData,
}: {
  tagIds?: string
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}): {
  variables: SearchExperiencesQueryInTripsDetailsExploreExperiencesQueryVariables
} => {
  const { endDate, name, startDate, timeZone, tripPreference } =
    tripDetailsData ?? {}
  const { lat, long } = tripPreference?.addresses?.[0] ?? {}
  const constructedLocation = getGeocoderPlaceName(name)
  const filters: ExperienceFilters = {}

  if (tagIds) {
    filters.tags = JSON.parse(tagIds)
  }

  return {
    variables: {
      first: initialSize,
      searchExperiencesArgs: {
        arrival: formatDate(getDateFromUTC(startDate, timeZone)),
        departure: formatDate(getDateFromUTC(endDate, timeZone)),
        filters,
        latitude: getGeocoderLatitude(lat),
        longitude: getGeocoderLongitude(long),
        tracker: getTracker(constructedLocation),
      },
    },
  }
}

const constructExperienceEventArguments = ({
  endDate,
  id,
  startDate,
  tripDetailsExploreExperienceData,
}: Partial<
  GetTripDetailsQueryInTripsQuery['getTrip'] & {
    tripDetailsExploreExperienceData?: ExploreExperience['node']
  }
>): {
  variables: CreateEventMutationInTripDetailsMutationVariables
} => {
  const {
    destinations,
    description,
    id: externalTypeId,
    itinerary,
    logistics,
    title,
    productCode,
  } = tripDetailsExploreExperienceData ?? {}
  const { lat, long } = getExperienceAsLatLong({
    destinations,
    logistics,
  })
  const { fixedDurationInMinutes: durationInMinutes } =
    (itinerary as ExperienceStandardItinerary)?.duration ?? {}

  return {
    variables: {
      eventInput: {
        addresses: [
          {
            lat,
            long,
          },
        ],
        description,
        durationInMinutes,
        endDate,
        externalTypeId,
        name: title,
        startDate,
        tripId: id,
        type: EventType.Experience,
        productId: productCode,
        status: EventStatus.NotBooked,
        useEventTime: false,
      },
    },
  }
}

const constructExperienceItems = (
  tripDetailsExploreExperiencesData: ExploreExperience[]
): ExploreItem[] =>
  tripDetailsExploreExperiencesData?.map(({ node }) => {
    const { id, images, isFavorited, reviews, title } = node ?? {}
    const image = images?.size720x480?.[0]
    const { combinedAverageRating, totalReviews } = reviews ?? {}

    return {
      id,
      image,
      isFavorited,
      reviewAverage: combinedAverageRating,
      reviewTotal: totalReviews,
      title,
    }
  }) ?? []

const constructHotelArguments = (
  tripDetailsData?: GetTripDetailsQueryInTripsQuery['getTrip']
): {
  variables: SearchHotelsQueryInTripsDetailsExploreHotelsQueryVariables
} => {
  const { lat, long } = tripDetailsData?.tripPreference?.addresses?.[0] ?? {}

  return {
    variables: {
      searchHotelsWithoutRatesArgs: {
        latitude: getGeocoderLatitude(lat),
        longitude: getGeocoderLongitude(long),
        size: initialSize,
        radius: 20,
      },
    },
  }
}

const constructHotelEventArguments = ({
  endDate,
  id,
  startDate,
  tripDetailsExploreHotelData,
  googlePlaceId,
}: Partial<
  GetTripDetailsQueryInTripsQuery['getTrip'] & {
    tripDetailsExploreHotelData?: ExploreHotel
    googlePlaceId: string
  }
>): {
  variables: CreateEventMutationInTripDetailsMutationVariables
} => {
  const {
    description,
    hotelAddress,
    id: externalTypeId,
    name,
  } = tripDetailsExploreHotelData ?? {}
  const { latitude, longitude } = hotelAddress ?? {}

  return {
    variables: {
      eventInput: {
        addresses: [
          {
            lat: latitude,
            long: longitude,
            googlePlaceId,
          },
        ],
        description,
        endDate,
        productId: externalTypeId,
        name,
        startDate,
        tripId: id,
        type: EventType.Stay,
        useEventTime: false,
        status: EventStatus.NotBooked,
      },
    },
  }
}

const constructHotelItems = (
  tripDetailsExploreHotelsData: ExploreHotel[]
): ExploreItem[] =>
  tripDetailsExploreHotelsData?.map(hotel => {
    const {
      customerReviewScore,
      id,
      images,
      isFavorited,
      name,
      totalCustomerReviews,
    } = hotel ?? {}
    const image = constructImageLink(images?.[0], '720x480')

    return {
      id,
      image,
      isFavorited,
      reviewAverage: customerReviewScore,
      reviewTotal: totalCustomerReviews,
      title: name,
    }
  }) ?? []

const getExperienceAsLatLong = ({
  destinations,
  logistics,
}: Partial<ExploreExperience['node']>): Partial<ExploreMarker> => {
  const { start, travelerPickup } = logistics ?? {}
  const destination = destinations?.[0] ?? {}
  const { latitude: lat, longitude: long } =
    [start ?? [], travelerPickup?.locations ?? []]
      ?.flat()
      ?.find(
        ({ location }: ExperienceStartEndPoint | ExperiencePickupLocation) =>
          !!location?.latitude && !!location?.longitude
      )?.location ?? {}

  if (!lat || !long)
    return {
      lat: destination?.latitude,
      long: destination?.longitude,
    }

  return { lat, long }
}

export {
  constructExperienceArguments,
  constructExperienceEventArguments,
  constructExperienceItems,
  constructHotelArguments,
  constructHotelEventArguments,
  constructHotelItems,
  getExperienceAsLatLong,
}
