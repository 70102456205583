import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  NodeQueryInBookingDetailsCancellationPolicyQuery,
  NodeQueryInBookingDetailsCancellationPolicyQueryVariables,
} from 'src/__generated__/graphql'

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsCancellationPolicy($nodeId: ID!) {
    node(id: $nodeId) {
      ... on Booking {
        id
        bookingDetails {
          cancelPolicy {
            constructedCancellationPolicies {
              policyText
              shortText
              tpgServiceFeeText
            }
            fullyRefundableUntil
            nonRefundableAfter
            partiallyRefundableUntil
            refundType
          }
        }
      }
    }
  }
`)

export const useBookingDetailsCancellationPolicyQuery = ({
  bookingId,
}: {
  bookingId: NodeQueryInBookingDetailsCancellationPolicyQueryVariables['nodeId']
}) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: !bookingId,
    variables: {
      nodeId: bookingId,
    },
  })

  const { node } = data ?? {}
  const bookingDetailsCancellationPolicyData = node as Extract<
    NodeQueryInBookingDetailsCancellationPolicyQuery['node'],
    { __typename?: 'Booking' }
  >

  return {
    hasError,
    bookingDetailsCancellationPolicyData,
    isLoading,
  }
}
