import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_HOTEL_INTO_COLLECTION = gql(`
    mutation AddHotelIntoCollection($itemId: String, $input: CreateCollectedHotelInput!){
        createCollectedHotel(input: $input){
            collectedHotel{
                id
                hotelId
                hotel{
                    id
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: HOTEL)
                    id
                    name
                }
            }
        }
    }
`)

export const useAddHotelIntoCollection = () =>
  useMutation(ADD_HOTEL_INTO_COLLECTION)
