import { Modal } from '@travelpass/design-system'
import { TripEditForm } from './TripEditForm'

export const TripEditModal = ({
  successSnackTitle,
  tripId,
  isEditModal = true,
  onClose,
  onCompleted,
  onDeleteOpen,
}: {
  successSnackTitle?: string
  tripId: string
  isEditModal?: boolean
  onClose: () => void
  onCompleted?: () => void
  onDeleteOpen?: () => void
}) => {
  return (
    <>
      <Modal
        size='medium'
        title={`${isEditModal ? 'Edit' : 'Create'} Trip`}
        onDismiss={onClose}
      >
        <TripEditForm
          successSnackTitle={successSnackTitle}
          tripId={tripId}
          onClose={onClose}
          onCompleted={onCompleted}
          onDeleteOpen={onDeleteOpen}
        />
      </Modal>
    </>
  )
}
