import { Button } from '@travelpass/design-system'
import type { DatesType } from 'src/constants/user'

interface EditDateContentProps {
  editedDates: DatesType
  onCancelEditDate: () => void
  onOpenTripForm: () => void
}

export const EditDateContent = ({
  editedDates,
  onCancelEditDate,
  onOpenTripForm,
}: EditDateContentProps) => {
  const startDate = editedDates?.[0]?.format('MM/DD/YYYY')
  const endDate = editedDates?.[1]?.format('MM/DD/YYYY')
  const isStartEndSame = editedDates?.[0].isSame(editedDates?.[1])
  const displayedDates = isStartEndSame
    ? startDate
    : `${startDate} to ${endDate}`

  return (
    <div className='space-y-6'>
      <p className='type-body-1'>
        Oops! It looks like you have events added on {displayedDates}, which are
        outside the new trip dates selected. We&apos;ve extended your trip dates
        so your events are still included.
      </p>
      <div className='flex justify-center space-x-6'>
        <Button
          aria-label='Cancel date changes'
          variant='outlined'
          onClick={onCancelEditDate}
        >
          Cancel
        </Button>
        <Button aria-label='Submit date changes' onClick={onOpenTripForm}>
          Update
        </Button>
      </div>
    </div>
  )
}
