import { useSearchParams } from 'react-router-dom'
import { MarkerWrapper } from 'src/common/components/Map'
import { StyledPlaceMarker } from 'src/pages/trips/components'
import { TripSearchParams } from 'src/pages/trips/constants'
import { getGuidePlaceLatLng } from 'src/utils'

/** @todo add onClose when TripExploreMapMarkerPopup is added. */
interface TripExploreMapPlaceMarkerProps {
  tripExploreMapMarkerPlaceData: google.maps.places.PlaceResult
}

export const TripExploreMapPlaceMarker = ({
  tripExploreMapMarkerPlaceData,
}: TripExploreMapPlaceMarkerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { geometry, place_id: id } = tripExploreMapMarkerPlaceData ?? {}
  const { location } = geometry ?? {}
  const { lat, lng } = getGuidePlaceLatLng(location)
  const hoverId = searchParams.get(TripSearchParams.hoverId)
  const selectedId = searchParams.get(TripSearchParams.selectedId)
  const isHovered = id === hoverId
  const isSelected = id === selectedId

  const onClick = () => {
    searchParams.set(TripSearchParams.selectedId, id)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onMouseEnter = () => {
    searchParams.set(TripSearchParams.hoverId, id)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onMouseLeave = () => {
    searchParams.delete(TripSearchParams.hoverId)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  if (!lat || !lng) return null

  return (
    <MarkerWrapper
      isHovered={isHovered || isSelected}
      position={{
        lat,
        lng,
      }}
      onClick={onClick}
    >
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <StyledPlaceMarker
          isHovered={isHovered}
          isSelected={isHovered || isSelected}
        />
      </div>
      {/** @todo TripExploreMapMarkerPopup  */}
    </MarkerWrapper>
  )
}
