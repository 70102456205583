import type { ApolloError, ApolloQueryResult } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetTripDetailsQueryInTripsQuery,
  GetTripEventsQueryInTripsQuery,
  GetTripOverviewQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { useGetTripDetailsQuery, useGetTripEventsQuery } from '../../hooks'

const getTripOverviewQuery = gql(`
  query GetTripOverviewQueryInTrips($tripId: ID!) {
    getTrip(id: $tripId) {
      id
      notes {
        id
        body
        openGraph {
          ogImage
          ogTitle
          ogUrl
        }
        title
        type
      }
    }
  }
`)

type UseGetTripOverviewQuery = {
  hasError: ApolloError
  isLoading: boolean
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  tripEventsData: GetTripEventsQueryInTripsQuery['getTrip']
  tripOverviewData: GetTripOverviewQueryInTripsQuery['getTrip']
}

export const useGetTripOverviewQuery = (
  tripId?: string
): UseGetTripOverviewQuery => {
  const {
    hasError: hasTripDetailsError,
    isLoading: isTripDetailsLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    hasError: hasTripEventsError,
    isLoading: isTripEventsLoading,
    tripEventsData,
  } = useGetTripEventsQuery(tripId)
  const {
    data,
    error: hasTripOverviewError,
    loading: isTripOverviewLoading,
  } = useQuery(getTripOverviewQuery, {
    skip: !tripId,
    variables: {
      tripId: tripId ?? '',
    },
  })
  const hasError =
    hasTripOverviewError || hasTripDetailsError || hasTripEventsError
  const isLoading =
    isTripOverviewLoading || isTripDetailsLoading || isTripEventsLoading
  const tripOverviewData = data?.getTrip ?? null

  return {
    hasError,
    isLoading,
    tripDetailsData,
    tripEventsData,
    tripOverviewData,
  }
}
