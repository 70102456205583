import type { ReactNode } from 'react'

interface HotelResultsNavContainerProps {
  children: ReactNode
}

export const HotelResultsNavContainer = ({
  children,
}: HotelResultsNavContainerProps) => (
  <div className='flex flex-col-reverse gap-3 px-3 pb-3 pt-4 lg:flex-row lg:items-center lg:justify-between lg:gap-4 lg:px-8 lg:pb-4 lg:pt-5'>
    {children}
  </div>
)
