import type { KeyboardEvent } from 'react'
import { useState } from 'react'
import {
  Button,
  Input,
  KeyCode,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import {
  guideDraftSectionDescriptionMaxLength,
  guideDraftSectionNameMaxLength,
} from '../../guideDraftConstants'
import type { GuideDraftDragItem } from '../../types'
import { useUpdateGuideDraftEventCategoryMutation } from '../../useUpdateGuideDraftEventCategoryMutation'

interface GuideDraftSectionEditModalProps {
  onDismiss: () => void
  onSectionDeleteOpen: VoidFunction
  paginatedEventCategory: GuideDraftDragItem
}

export const GuideDraftSectionEditModal = ({
  onDismiss,
  onSectionDeleteOpen,
  paginatedEventCategory,
}: GuideDraftSectionEditModalProps) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [updatePublishedEventCategory] =
    useUpdateGuideDraftEventCategoryMutation()
  const [description, setDescription] = useState(
    paginatedEventCategory?.description ?? ''
  )
  const [name, setName] = useState(paginatedEventCategory?.name ?? '')

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event ?? {}

    if (key === KeyCode.ENTER) {
      event.preventDefault()
      onSubmit()
      onDismiss()
    }
  }

  const onSubmit = async () => {
    const validatedName = name?.trim()
    const validatedDescription = description?.trim()
    setIsInvalid(!validatedName)

    if (validatedName) {
      try {
        await updatePublishedEventCategory({
          variables: {
            updateCategoryInput: {
              description: validatedDescription,
              name: validatedName,
              id: paginatedEventCategory?.id,
            },
          },
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Section updated',
        })
        onDismiss()
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
      }
    }
  }

  return (
    <Modal size='medium' title='Edit Section' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-y-1 space-y-4'>
          <Input
            aria-label='Edit section name'
            errorText={isInvalid && 'Section name is required'}
            fullWidth={true}
            helperText={`${name.length}/${guideDraftSectionNameMaxLength} characters`}
            isInvalid={isInvalid}
            label='Name'
            maxLength={guideDraftSectionNameMaxLength}
            placeholder='Where to stay'
            required={true}
            value={name}
            onChange={event => setName(event.target.value)}
            onKeyDown={onKeyDown}
          />
          <TextArea
            aria-label='Edit section description'
            helperText={`${description.length}/${guideDraftSectionDescriptionMaxLength} characters`}
            label='Description'
            maxLength={guideDraftSectionDescriptionMaxLength}
            placeholder='Add details to your guide section to share more specific information and make your travel tips shine! Your insights can inspire and guide fellow travelers on their adventures.'
            rows={5}
            value={description}
            onInput={event => setDescription(event.currentTarget.value)}
          />
          <Button
            aria-label='Delete section'
            startIcon='deleteOutline'
            variant='text'
            onClick={onSectionDeleteOpen}
          >
            Delete this section
          </Button>
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Save section changes'
          autoFocus={true}
          size='large'
          onClick={onSubmit}
        >
          Save Changes
        </Button>
        <Button
          aria-label='Cancel section changes'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
