import { useEffect, useRef, useState } from 'react'
import { Button, EmptyState } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useIsElementOnScreen } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { generateHotelResultsUrl } from 'src/utils'
import {
  HotelAmenities,
  HotelFooter,
  HotelHero,
  HotelOverview,
  HotelPolicy,
  HotelRooms,
  HotelStickyMenu,
  HotelReviewsApollo,
} from './components'
import { HotelReviewsModal } from './components/HotelReviews/HotelReviewsModal'
import { hotelTabs, HOTEL_ROOMS_DIV_ID } from './constants'
import { useHotelReviewsSummaryListHotelReviewsQuery } from './hooks'
import { useHotelDetailsStandardHotel } from './hooks/useHotelDetailsStandardQuery'

export const Hotel = () => {
  const [searchParams] = useSearchParams()
  const { data: hotelDetails, error, loading } = useHotelDetailsStandardHotel()
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const [isHotelReviewsModalOpen, setIsHotelReviewsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { totalReviews } = hotelReviewsSummaryData ?? {}

  const hotelOverviewRef = useRef<HTMLDivElement>(null)
  const hotelRoomsRef = useRef<HTMLDivElement>(null)
  const hotelAmenitiesRef = useRef<HTMLDivElement>(null)
  const hotelReviewsRef = useRef<HTMLDivElement>(null)
  const hotelPolicyRef = useRef<HTMLDivElement>(null)

  const isHotelOverviewOnScreen = useIsElementOnScreen(hotelOverviewRef)
  const isHotelRoomsOnScreen = useIsElementOnScreen(hotelRoomsRef)
  const isHotelAmenitiesOnScreen = useIsElementOnScreen(hotelAmenitiesRef)
  const isHotelReviewsOnScreen = useIsElementOnScreen(hotelReviewsRef)
  const isHotelPolicyOnScreen = useIsElementOnScreen(hotelPolicyRef)

  const { latitude, longitude } = hotelDetails ?? {}
  const adults = searchParams.get('adults')
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const kids = searchParams.get('kids')
  const location = searchParams.get('location')

  useEffect(() => {
    if (!isEmpty(hotelDetails)) {
      pushDataToDataLayer('hotelPageVisited', {
        currency: 'USD',
        hotel: { arrival, departure, ...hotelDetails },
        cityPageSearchUrl: `https://travelpass.com${generateHotelResultsUrl({
          adults,
          arrival,
          departure,
          kids,
          latitude,
          location,
          longitude,
        })}`,
        cityPageSearchUrlNoDates: `https://travelpass.com${generateHotelResultsUrl(
          {
            adults,
            kids,
            latitude,
            location,
            longitude,
          }
        )}`,
      })
    }
  }, [
    adults,
    arrival,
    departure,
    kids,
    latitude,
    location,
    longitude,
    hotelDetails,
  ])

  const elementsOnScreen = [
    isHotelOverviewOnScreen,
    isHotelRoomsOnScreen,
    isHotelAmenitiesOnScreen,
    isHotelReviewsOnScreen,
    isHotelPolicyOnScreen,
  ]
  const sectionRefs = [
    hotelOverviewRef,
    hotelRoomsRef,
    hotelAmenitiesRef,
    hotelReviewsRef,
    hotelPolicyRef,
  ]
  const activeIndex = elementsOnScreen.findIndex(refOnScreen => !!refOnScreen)

  const onHotelReviewsModalClose = () => setIsHotelReviewsModalOpen(false)

  const onHotelReviewsModalOpen = async () => setIsHotelReviewsModalOpen(true)

  const tabs = sectionRefs
    .map((ref, index) => {
      if (hotelTabs?.[index] === 'Reviews' && totalReviews === 0) return null
      return {
        label: hotelTabs?.[index] ?? '',
        onClick:
          hotelTabs?.[index] === 'Reviews'
            ? () =>
                onHotelReviewsModalOpen().then(() => {
                  ref.current?.scrollIntoView({ behavior: 'smooth' })
                })
            : () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
      }
    })
    .filter(tab => tab !== null)

  const dataIsMissing = !loading && !hotelDetails

  if (error || dataIsMissing) {
    return (
      <div className='mx-8 mt-20 flex flex-col items-center justify-center gap-4'>
        <div className='type-h3'>Unable to locate hotel</div>
        <div className='type-h4'>
          Oops! We weren&apos;t able to locate the hotel you&apos;re looking
          for.
        </div>
        <div className='w-250px'>
          <EmptyState name='error' />
        </div>{' '}
        <Button
          label='Back to Hotel Search'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </div>
    )
  }

  return (
    <div>
      {isHotelReviewsModalOpen && (
        <HotelReviewsModal onClose={onHotelReviewsModalClose} />
      )}
      <HotelHero hotelRoomsRef={hotelRoomsRef} />
      <HotelStickyMenu activeIndex={activeIndex} tabs={tabs} />
      <div
        className='bg-warm-grey scroll-m-46px md:scroll-m-30px'
        ref={hotelOverviewRef}
      >
        <HotelOverview hotelDetailsData={hotelDetails} />
      </div>
      <div
        className='md:scroll-m-29 scroll-m-24'
        id={HOTEL_ROOMS_DIV_ID}
        ref={hotelRoomsRef}
      >
        <HotelRooms />
      </div>
      <div className='md:scroll-m-55px scroll-m-9' ref={hotelAmenitiesRef}>
        <HotelAmenities amenities={hotelDetails?.amenities} />
      </div>
      <div className='md:scroll-m-55px scroll-m-9' ref={hotelReviewsRef}>
        <HotelReviewsApollo />
      </div>
      <div className='md:scroll-m-55px scroll-m-9' ref={hotelPolicyRef}>
        <HotelPolicy />
      </div>
      <HotelFooter />
    </div>
  )
}
