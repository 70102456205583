import { useEffect, type CSSProperties, type ReactNode } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface GuideDraftEventProps {
  children: ReactNode
  isOverlay?: boolean
  isSortingContainer: boolean
  publishedEventId: string
}

export const GuideDraftEvent = ({
  children,
  isOverlay = false,
  isSortingContainer,
  publishedEventId,
}: GuideDraftEventProps) => {
  const { setNodeRef, listeners, isDragging, transform, transition } =
    useSortable({
      id: publishedEventId,
    })

  useEffect(() => {
    if (!isOverlay) return

    document.body.style.cursor = 'grabbing'

    return () => {
      document.body.style.cursor = ''
    }
  }, [isOverlay])

  return (
    <div
      className={classNames('box-border flex', {
        'animate-[guideDraftPop_500ms_ease]': isDragging,
      })}
      ref={isSortingContainer ? undefined : setNodeRef}
      style={
        {
          animation: isDragging ? 'guideDraftPop 500ms ease' : undefined,
          opacity: isDragging && !isOverlay ? 0.5 : undefined,
          touchAction: 'manipulation',
          transform:
            'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
          transformOrigin: '0 0',
          transition,
          zIndex: isOverlay ? 999 : undefined,
          '--scale': isOverlay ? 1.0 : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          '--translate-x': transform
            ? `${Math.round(transform.x)}px`
            : undefined,
          '--translate-y': transform
            ? `${Math.round(transform.y)}px`
            : undefined,
        } as React.CSSProperties
      }
    >
      <div
        className={classNames(
          'c-grey-800 rounded-1 focus-visible:shadow-2 group relative box-border flex w-full origin-[50%_50%] list-none flex-row items-center gap-2 outline-none'
        )}
        style={
          {
            animation: isOverlay
              ? 'guideDraftPop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)'
              : undefined,
            cursor: isOverlay ? 'inherit' : undefined,
            opacity: isOverlay ? 1 : undefined,
            transform: isOverlay
              ? 'scale(var(--scale))'
              : 'scale(var(--scale, 1))',
          } as CSSProperties
        }
      >
        <div
          className='c-forest-dark w-6 min-w-6 cursor-pointer'
          {...listeners}
        >
          <Icon name='draggable' />
        </div>
        <div className='grow'>{children}</div>
      </div>
    </div>
  )
}
