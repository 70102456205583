import type { RefObject } from 'react'
import { forwardRef, useCallback, useEffect } from 'react'
import type { AutocompleteProps } from '@travelpass/design-system'
import {
  Autocomplete,
  AutocompleteOption,
  Icon,
  IconButton,
  KeyCode,
} from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import type {
  Suggestion,
  HookArgs as PlacesAutocompleteOptions,
} from 'use-places-autocomplete'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { GeocoderOption } from '../Geocoder/GeocoderOption'
import { constructGeocoderOverride } from '../Geocoder/geocoderUtils'
import { useGeocoderSearch } from '../Geocoder/useGeocoderSearch'

const SearchIcon = () => (
  <span className='c-new-forest'>
    <Icon name='search' />
  </span>
)

export interface GeocoderWithTextSearchProps
  extends Pick<
    AutocompleteProps,
    | 'defaultValue'
    | 'id'
    | 'isInvalid'
    | 'placeholder'
    | 'onClick'
    | 'isDisabled'
    | 'name'
    | 'onBlur'
    | 'onFocus'
    | 'required'
    | 'fullWidth'
    | 'slotBefore'
  > {
  checkTypes?: boolean
  label?: string
  focusOnInput?: boolean
  geocoder: GeocoderType
  helperText?: string
  errorText?: string
  listboxPosition?: AutocompleteProps['listboxPosition']
  onResult?: (result: string, rawResult?: GeocoderType) => void
  onClear?: VoidFunction
  config?: PlacesAutocompleteOptions
  allowTextSearch?: boolean
}

const GeocoderWithTextSearch = forwardRef<
  HTMLInputElement,
  GeocoderWithTextSearchProps
>(function GeocoderWithTextSearch(
  {
    defaultValue,
    label,
    checkTypes = false,
    focusOnInput = true,
    geocoder = {} as GeocoderType,
    helperText,
    errorText,
    isInvalid,
    isDisabled,
    listboxPosition,
    name,
    onBlur,
    placeholder,
    onResult,
    onClear,
    onClick,
    config,
    slotBefore = <SearchIcon />,
    allowTextSearch = false,
    ...props
  }: GeocoderWithTextSearchProps,
  ref: RefObject<HTMLInputElement>
) {
  const { ready, suggestions, value, setValue, clearSuggestions } =
    useGeocoderSearch({
      defaultValue: defaultValue?.toString(),
      ...config,
    })

  const { data: options } = suggestions ?? {}
  const hasTextSearch = allowTextSearch && value.trim() !== '' && value
  const showGeocoderOptions =
    hasTextSearch || (value.trim() !== '' && !!options?.length)

  const onKeyDown = event => {
    if (event.key === KeyCode.ESC) {
      clearSuggestions()
    }
  }

  const onChange = useCallback(e => {
    const value = e.target.value
    if (value === '') {
      onClear()
    }
    setValue(value, true)
  }, [])

  const onOptionSelect = (selectedSuggestion: Suggestion | string) => {
    if (typeof selectedSuggestion === 'string') {
      clearSuggestions()
      onResult(selectedSuggestion)
      return
    }
    const address = selectedSuggestion.description
    ref.current.value = address
    clearSuggestions()
    getGeocode({ address }).then(data => {
      const { place_id, types, address_components, geometry } = data?.[0]
      const valid_address_components = [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'country',
        'geocode',
        'locality',
        'political',
      ].some(type => selectedSuggestion.types.includes(type))
        ? address_components.filter(component =>
            component.types.every(
              type =>
                selectedSuggestion.types.includes(type) || type === 'country'
            )
          )
        : address_components
      const { lat, lng } = getLatLng(data?.[0])
      const currentGeocoder = {
        addressComponents: valid_address_components,
        center: [lat, lng],
        placeName: address,
        types,
        viewport: geometry?.viewport,
      }

      if (!checkTypes || types.includes('lodging'))
        currentGeocoder['placeId'] = place_id

      const constructedGeocoder = constructGeocoderOverride(currentGeocoder)

      onResult('', constructedGeocoder)
    })
  }

  const onClearHandler = () => {
    setValue('', true)
    ref.current.value = ''
    ref.current?.focus() // return focus to input
    onClear?.()
    onResult('', initialGeocoder)
  }

  const slotAfter = ref.current?.value?.trim() !== '' && (
    <IconButton icon='clear' onClick={onClearHandler} />
  )

  useEffect(() => {
    setTimeout(() => {
      if (focusOnInput) ref?.current?.focus()
    }, 300)
  }, [])

  useEffect(() => {
    // keep input value in sync with current geocoder placeName
    if (ref.current && geocoder?.placeName) {
      ref.current.value = geocoder?.placeName
    }
  }, [geocoder])

  return (
    <Autocomplete
      {...props}
      autoExpand
      autoComplete='off'
      defaultValue={defaultValue}
      errorText={errorText}
      helperText={helperText}
      isDisabled={!ready || isDisabled}
      isInvalid={isInvalid}
      label={label}
      listboxPosition={listboxPosition}
      name={name}
      placeholder={placeholder}
      ref={ref}
      slotAfter={slotAfter}
      slotBefore={slotBefore}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onOptionSelect={onOptionSelect}
    >
      {showGeocoderOptions && (
        <>
          {options.map(option => (
            <GeocoderOption key={option.place_id} option={option} />
          ))}
          {hasTextSearch && (
            <AutocompleteOption
              className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
              value={hasTextSearch}
            >
              Search for &quot;{hasTextSearch}&quot;
            </AutocompleteOption>
          )}
        </>
      )}
    </Autocomplete>
  )
})

export { GeocoderWithTextSearch }
