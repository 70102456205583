import type { Dispatch, SetStateAction } from 'react'
import type { DateRange } from '@travelpass/design-system'
import { CustomAddEventForm } from '../CustomAddEvent/CustomAddEventForm'
import { ExperienceEvent } from '../ExperienceEvent'
import { StayEvent } from '../StayEvent'

export const AddEventByType = ({
  dateRange,
  endDateTime,
  hoveredEventId,
  selectedEventId,
  setScrollToEventId,
  startDateTime,
  type,
  tripId,
  onComplete,
  onHoverCard,
}: {
  dateRange: DateRange
  endDateTime: string
  hoveredEventId?: string
  selectedEventId?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  startDateTime: string
  type: 'stays' | 'experiences' | 'custom'
  tripId: string
  onComplete: () => void
  onHoverCard: (eventId?: string) => void
}) => {
  if (type === 'stays') {
    return (
      <StayEvent
        dateRange={dateRange}
        endDateTime={endDateTime}
        hoveredEventId={hoveredEventId}
        selectedEventId={selectedEventId}
        setScrollToEventId={setScrollToEventId}
        startDateTime={startDateTime}
        tripId={tripId}
        onComplete={onComplete}
        onHoverCard={onHoverCard}
      />
    )
  }
  if (type === 'experiences') {
    return (
      <ExperienceEvent
        dateRange={dateRange}
        endDateTime={endDateTime}
        hoveredEventId={hoveredEventId}
        selectedEventId={selectedEventId}
        setScrollToEventId={setScrollToEventId}
        startDateTime={startDateTime}
        tripId={tripId}
        onComplete={onComplete}
        onHoverCard={onHoverCard}
      />
    )
  }
  if (type === 'custom') {
    return (
      <CustomAddEventForm
        dateRange={dateRange}
        tripId={tripId}
        onComplete={onComplete}
      />
    )
  }
  return null
}
