import { Button, Icon, IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { SocialNetwork, SocialLink } from 'src/__generated__/graphql'
import { getSocialPlatformIcon, identifySocialLink } from 'src/utils'
import type { DashboardProfileFields } from './types'

const placeholder = 'Add a link to your FB, IG, TikTok, X, or LinkedIn'

export const DashboardProfileInfoSocialMediaLinks = () => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<DashboardProfileFields>()
  const socialLinks: SocialLink[] = watch('socialLinks') ?? []

  const isRepeatedLink = (value: string) =>
    socialLinks.find(({ identifier }) => value === identifier)

  const onAdd = async () => {
    const socialLinkValue = getValues('socialLink')

    // Check if the socialLink field is empty
    if (!socialLinkValue) {
      // Manually set an error for the socialLink field
      setError('socialLink', {
        type: 'manual',
        message: 'A social media link is required',
      })
      return
    }

    // If not empty, trigger validation for the socialLink field
    const isValid = await trigger('socialLink')

    if (isValid) {
      // Proceed to add the link, clear the field, and clear any errors
      clearErrors('socialLink')
      let updatedSocialLinks = [
        ...socialLinks,
        {
          network: identifySocialLink(socialLinkValue) as SocialNetwork,
          identifier: socialLinkValue,
        },
      ]
      setValue('socialLinks', updatedSocialLinks)
      setValue('socialLink', '')
    }
  }

  const onDelete = (linkIndex: number) => {
    let updatedSocialLinks = socialLinks.filter((_, i) => i !== linkIndex)
    setValue('socialLinks', updatedSocialLinks)
  }

  return (
    <section className='space-y-6' id='dashboard-profile-info-social-links'>
      <h6 className='type-h6-desktop c-black'>Connect On Social</h6>
      {socialLinks.map(({ network, identifier }, index) => {
        const platformIcon = getSocialPlatformIcon(network)

        return (
          <div key={identifier} className='flex flex-row items-center gap-2.5'>
            <Icon name={platformIcon} />
            <Input
              aria-label='Add a social media link'
              fullWidth={true}
              name='socialMediaURL'
              placeholder={placeholder}
              readOnly={true}
              value={identifier}
            />
            <div className='w-12 min-w-12'>
              <IconButton
                aria-label='Remove a social media link'
                icon='deleteOutline'
                onClick={() => onDelete(index)}
              />
            </div>
          </div>
        )
      })}
      <div className='md:p-r-14.5 flex flex-row gap-2.5'>
        <div className={socialLinks?.length ? 'invisible' : 'hidden'}>
          {/* dummy icon component to easily preserve correct spacing */}
          <Icon name='addCircleOutline' />
        </div>
        <Input
          {...register('socialLink', {
            pattern: {
              value:
                /^(?:http:\/\/|https:\/\/)?(?:www\.)?([\w\d-]+\.)+\w{2,}(\/.+)?$/,
              message: 'Please enter a valid URL',
            },
            validate: {
              isUniqueLink: (value: string) =>
                value === '' ||
                !isRepeatedLink(value) ||
                'This profile link was already added.', // Check uniqueness only if filled out
              isSupportedPlatform: (value: string) =>
                value === '' ||
                !!identifySocialLink(value) ||
                'Only Facebook, Instagram, TikTok, Twitter, and LinkedIn profile links are accepted.', // Check platform support only if filled out
            },
          })}
          aria-label='Add a social media link'
          autoCapitalize='off'
          autoCorrect='off'
          errorText={errors?.socialLink?.message}
          fullWidth={true}
          placeholder={placeholder}
          spellCheck='false'
        />
      </div>
      <div className='[&>button>div]:text-'>
        <Button
          aria-label='Add another link'
          size='small'
          startIcon='add'
          variant='text'
          onClick={onAdd}
        >
          Add New Link
        </Button>
      </div>
    </section>
  )
}
