import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import type { Root } from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import type { MapPoint } from '../constants'

interface MarkerWrapperProps {
  position: MapPoint
  children?: ReactNode
  isHovered?: boolean
  onClick?: (map: google.maps.Map) => void
}

export const MarkerWrapper = ({
  position,
  children,
  isHovered = false,
  onClick,
}: MarkerWrapperProps) => {
  const map = useGoogleMap()
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>()
  const rootRef = useRef<Root>()

  useEffect(() => {
    // In dev mode this hook runs 2 times
    if (!rootRef.current) {
      const container = document.createElement('div')
      rootRef.current = createRoot(container)

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position,
        content: container,
        zIndex: isHovered ? 1 : 0,
      })

      // This is how you remove an AdvancedMarkerElement
      return () => {
        if (markerRef.current) {
          markerRef.current.map = null
        }
      }
    }
  }, [])

  useEffect(() => {
    // TODO - Fix this TS issue
    // @ts-ignore
    rootRef.current?.render(children)
    if (markerRef.current) {
      markerRef.current.position = position
      markerRef.current.map = map
      markerRef.current.zIndex = isHovered ? 1 : 0

      // If we don't at least attach an empty event,
      // then none of the events get passed to the child component
      const listener = markerRef.current.addListener(
        'click',
        onClick ? () => onClick(map) : () => {}
      )

      return () => listener?.remove()
    }
  }, [map, position, children, isHovered, onClick])

  return null
}
