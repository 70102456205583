import { BedType } from 'src/__generated__/graphql'

const { Double, King, Queen, Single } = BedType ?? {}

const bedTypeItems: { title: string; value: BedType }[] = [
  {
    title: 'Any',
    value: null,
  },
  {
    title: 'Double',
    value: Double,
  },
  {
    title: 'King',
    value: King,
  },

  {
    title: 'Queen',
    value: Queen,
  },
  {
    title: 'Single',
    value: Single,
  },
]

export { bedTypeItems }
