import { Divider, Skeleton } from '@travelpass/design-system'

export const DashboardCtasLoading = () => (
  <div className='pt-18 space-y-12 px-4 pb-12'>
    <div className='pb-21 space-y-8'>
      <div className='flex flex-row items-center gap-4'>
        <div className='min-w-21.5 w-21.5 h-15'>
          <Skeleton />
        </div>
        <div className='grow space-y-2.5'>
          <div className='w-37.5 h-5'>
            <Skeleton />
          </div>
          <div className='w-55.5 h-13.5'>
            <Skeleton />
          </div>
          <div className='w-37.5 h-8'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
      <Divider />
      <div className='flex flex-row items-center gap-4'>
        <div className='min-w-21.5 w-21.5 h-13'>
          <Skeleton />
        </div>
        <div className='grow space-y-2.5'>
          <div className='w-22 h-5'>
            <Skeleton />
          </div>
          <div className='w-55.5 h-9'>
            <Skeleton />
          </div>
          <div className='w-42.5 h-8'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
      <Divider />
      <div className='flex flex-row items-center gap-4'>
        <div className='min-w-21.5 w-21.5 h-13'>
          <Skeleton />
        </div>
        <div className='grow space-y-2.5'>
          <div className='w-21.5 h-5'>
            <Skeleton />
          </div>
          <div className='w-55.5 h-9'>
            <Skeleton />
          </div>
          <div className='h-8 w-32'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
    </div>
    <div className='my-0 flex flex-col justify-center gap-10 text-center'>
      <div className='h-68.5 rounded-11px flex w-full flex-row justify-center'>
        <Skeleton />
      </div>
      <div className='w-62.5 h-6 self-center'>
        <Skeleton />
      </div>
      <div className='h-10 w-full'>
        <Skeleton />
      </div>
      <div className='h-10 w-full'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
