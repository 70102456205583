const cityImages =
  'https://static.travelpass.com/assets/landing-pages/fast-friends/'

const fastFriendsLocations = [
  {
    image:
      'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities/washington-dc.webp',
    city: 'Washington',
    state: 'D.C.',
    description:
      'This city is for the passionate. Whether it’s politics, culture, art, or food, the energy in Washington, D.C. is infectious and exciting. If you’re a traveler who loves a destination with a thriving social calendar, this is the place for you! There are constantly fun events going on, from networking pub crawls to live music. You’ll never feel alone in this beautiful, historic city!',
    urlData: [
      {
        latitude: '38.9072',
        longitude: '-77.0369',
      },
    ],
  },
  {
    image:
      'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities/boston-massachusetts.webp',
    city: 'Boston',
    state: 'Massachusetts',
    description:
      'Boston has a little bit of everything for travelevers who want to experience something fun and new while meeting new friends. You can walk the Freedom Trail and soak in our nation’s history, or catch a Red Sox game at Fenway Park. Want to try some lobster rolls in a new way? Take a ride on a Boston cruise for drinks, dinner, and a stunning view of the city! There’s always something to do in Boston!',
    urlData: [
      {
        latitude: '42.3601',
        longitude: '-71.0589',
      },
    ],
  },
  {
    image: `${cityImages}san-diego-california.webp`,
    city: 'San Diego',
    state: 'California',
    description:
      'For the more outdoorsy, laidback adventurer, San Diego is the city for you! From the beaches of La Jolla to the historic corridors of the Gaslamp Quarter, there are beautiful and interesting things to discover around every corner. Hiking the trails of Torrey Pines State Reserve or sampling some of the freshest seafood around the piers are excellent ways to meet fellow travelers while enjoying something new! ',
    urlData: [
      {
        latitude: '32.7157',
        longitude: '-117.1611',
      },
    ],
  },
  {
    image:
      'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities/philadelphia-pennsylvania.webp',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    description:
      'Philly has so much to offer travelers, from history to an incredibly fun nightlife scene with bars often open ‘til 2 a.m., many offering live music and even dancing! There is a thriving food scene (be sure to hit up the Reading Terminal Market) and many friendly visitors ready to make a new connection in a new city.',
    urlData: [
      {
        latitude: '39.9526',
        longitude: '-75.1652',
      },
    ],
  },
  {
    image: `${cityImages}knoxville-tennessee.webp`,
    city: 'Knoxville',
    state: 'Tennessee',
    description:
      'Get ready for Knoxville, TN to become your unexpected favorite place to travel! The city is quickly growing in popularity as artists, musicians, and foodies are discovering this hidden gem. College-aged travelers will have a blast with the local school spirit and sports events, while travelers of all ages can enjoy making new connections at bars, restaurants, and music venues against the backdrop of the nearby Great Smoky Mountains.',
    urlData: [
      {
        latitude: '35.9606',
        longitude: '-83.9207',
      },
    ],
  },
  {
    image: `${cityImages}asheville-north-carolina.webp`,
    city: 'Asheville',
    state: 'North Carolina',
    description:
      'Asheville, North Carolina was made for travelers who love an eclectic arts scene and have a bit of a bohemian spirit! Along the Blue Ridge Mountains, the historic Biltmore Estate contrasts with vibrant street art and live music downtown. Surrounded by wilderness, the city is a gateway to outdoor activities, from the scenic drives along the Blue Ridge Parkway to the adventurous trails and waterfalls in the Pisgah National Forest. There’s something for everyone here, and plenty of opportunity to meet some incredible fellow travelers!',
    urlData: [
      {
        latitude: '35.5951',
        longitude: '-82.5515',
      },
    ],
  },
  {
    image:
      'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities/san-antonio-texas.webp',
    city: 'San Antonio',
    state: 'Texas',
    description:
      'For those who love connecting with people in nature, San Antonio might be an unexpected favorite travel destination! The rolling Texas Hill Country offers outdoor enthusiasts picturesque landscapes to discover through hiking, biking, and tons of other fun recreational activities where you will make memories (and friends) to last a lifetime. More of a city person? The Alamo and the vibrant River Walk downtown is lined with shops and restaurants, bustling with a fun and casual atmosphere.',
    urlData: [
      {
        latitude: '29.4252',
        longitude: '-98.4946',
      },
    ],
  },
  {
    image:
      'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities/chicago-illinois.webp',
    city: 'Chicago',
    state: 'Illinois',
    description:
      'Chicago, Illinois might be one of the best places to make new friends while traveling! Whether through a tour, a class, or striking up some good ol’ friendly conversation, Chicago offers so many opportunities to make new connections. Take your pick from museums, boat days on Lake Michigan, yoga on Magnificent Mile, or dancing the weekend away at one of the Windy City’s clubs!',
    urlData: [
      {
        latitude: '41.8781',
        longitude: '-87.6298',
      },
    ],
  },
]

export { fastFriendsLocations }
