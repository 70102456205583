import { useRef } from 'react'
import { Icon, StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { CollectedItemTip, CollectionDetailsMenu } from 'src/common/components'
import { generateExperienceDetailsUrl } from 'src/utils'

interface CollectionExperienceCardProps {
  experience: ExperienceProduct
  onRemove: Function
}

export const CollectionExperienceCard = ({
  experience,
  onRemove,
}: CollectionExperienceCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const { title, destinations, id, images } = experience

  const handleOnRemove = () => {
    onRemove({
      item: experience,
      type: CollectionItemIdType.Experience,
    })
  }

  return (
    <div
      className={classNames(
        'rounded-2 shadow-2 block hover:cursor-pointer',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group'
        /** @desc fallback styling if coverImg fails to load */
      )}
      role='link'
      tabIndex={0}
      onClick={() => {
        anchorRef?.current?.click?.()
      }}
    >
      <div className='of-hidden rounded-2 h-50 relative'>
        <img
          alt=''
          className='z--1 absolute inset-0 h-full w-full object-cover transition-transform duration-200 ease-in-out group-focus-within:scale-105 group-hover:scale-105'
          draggable={false}
          src={images?.size480x320[0]}
        />
        <div className='flex justify-between pl-4 pr-2 pt-2.5'>
          <CollectedItemTip label='Experience' />
          <StopPropagation>
            <CollectionDetailsMenu
              item={{
                name: title,
                id: id,
                type: CollectionItemIdType.Experience,
              }}
              onRemove={handleOnRemove}
            />
          </StopPropagation>
        </div>
      </div>
      <div className='space-y-3 p-4'>
        <div className='space-y-3'>
          <a
            className='decoration-none'
            href={generateExperienceDetailsUrl({ id, name: title })}
            ref={anchorRef}
            rel='noreferrer'
            target='_blank'
          >
            <h2 className='type-h5 line-clamp-2'>{title}</h2>
          </a>
          <div className='line-clamp-1 flex flex-row items-center gap-1'>
            <div className='color-orange'>
              <Icon name='placeOutline' size='small' />
            </div>
            <span className='type-small-text color-black'>
              {destinations?.[0]?.destinationName}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
