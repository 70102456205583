import { Avatar } from '@travelpass/design-system'

export const AvatarRow = ({ displayName, profileImageUrl, dateTimeText }) => (
  <div className='flex items-center'>
    <div className='relative -left-2'>
      <Avatar border='sm' size='sm' src={profileImageUrl} />
    </div>
    <div className='relative -left-1 flex flex-col justify-center'>
      <div className='text-body-2'>{displayName}</div>
      <div className='text-small-text c-grey700'>{dateTimeText}</div>
    </div>
  </div>
)
