import type { User } from 'firebase/auth'
import { auth } from 'src/config/firebase/firebaseUtils'

export const useFirebaseUser = (): Pick<
  User,
  'isAnonymous' | 'providerData' | 'email' | 'emailVerified'
> => {
  return (
    auth?.currentUser ?? {
      isAnonymous: true,
      providerData: [],
      email: '',
      emailVerified: false,
    }
  )
}
