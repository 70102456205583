import { useEffect, useRef, useState } from 'react'
import {
  DropdownOption,
  Autocomplete,
  KeyCode,
  Icon,
  IconButton,
} from '@travelpass/design-system'
import type { Suggestion } from 'use-places-autocomplete'
import { getDetails } from 'use-places-autocomplete'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { countries } from 'src/constants'
import { states } from 'src/utils'
import { usesAlternateCityDesignation } from 'src/utils/addressUtils'
import type { BookingFormFields } from '../../hooks'
import { rules, useFormContext, useWatch } from '../../hooks'

const Input = FormInput<BookingFormFields>

const getStateInputElement = (country: string) => {
  if (country.match(/US/i)) {
    return (
      <FormDropdown
        fullWidth
        label='State'
        name='stateProvince'
        placeholder='State'
        required={rules.required}
      >
        {states.map(({ value, label }) => (
          <DropdownOption key={value} value={value}>
            {label}
          </DropdownOption>
        ))}
      </FormDropdown>
    )
  } else
    return (
      <Input
        fullWidth
        autoComplete='address-level1'
        label='State/Province'
        name='stateProvince'
        placeholder='Enter State/Province'
        required={rules.required}
      />
    )
}

export const ConditionalAddressFields = () => {
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const [addressState, setAddressState] = useState<{
    address2: boolean
    city: string
    stateProvince: string
    postalCode: string
  }>(null)
  const [showAllAddressFields, setShowAllAddressFields] = useState(false)

  const {
    formState,
    register,
    resetField,
    setValue: setFormFieldValue,
    watch,
  } = useFormContext()
  const country = useWatch({ name: 'country' })
  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: {
        types: ['address'],
        componentRestrictions: {
          country: [watch('country')],
        },
      },
      cacheKey: CACHE_KEYS.withoutRestriction,
    })

  const { errors } = formState
  const options = suggestions?.data

  const handleClearAndFocus = ({
    newValue,
    focusesInput = false,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput?: boolean
    shouldFetchData?: boolean
  }) => {
    setValue(newValue, shouldFetchData)
    clearSuggestions()
    resetField('address1')
    resetField('address2')
    resetField('city')
    resetField('postalCode')
    resetField('stateProvince')
    setShowAllAddressFields(true)
    focusesInput && autocompleteRef?.current?.focus()
  }

  const onOptionSelect = async (option: Suggestion) => {
    const result = await getDetails({
      placeId: option.place_id,
      fields: ['address_components'],
    })
    if (!result || typeof result === 'string') return
    const addressLine1 = option.structured_formatting?.main_text || ''
    const addressComponents = result?.address_components

    setFormFieldValue('address1', addressLine1)
    setValue(addressLine1)

    const usesAlternativeCityDesignation =
      usesAlternateCityDesignation(addressComponents)

    addressComponents.forEach(({ types, short_name }) => {
      if (types.includes('postal_code')) {
        setAddressState(prev => ({ ...prev, postalCode: short_name }))
        setFormFieldValue('postalCode', short_name)
      }

      if (types.includes('administrative_area_level_1')) {
        setAddressState(prev => ({ ...prev, stateProvince: short_name }))
        setFormFieldValue('stateProvince', short_name)
      }
      if (usesAlternativeCityDesignation) {
        if (types.includes('administrative_area_level_2')) {
          setAddressState(prev => ({ ...prev, city: short_name }))
          setFormFieldValue('city', short_name)
        }
      } else {
        if (types.includes('locality') || types.includes('postal_town')) {
          setAddressState(prev => ({ ...prev, city: short_name }))
          setFormFieldValue('city', short_name)
        }
      }
    })

    setAddressState(prev => ({ ...prev, address2: true }))
    clearSuggestions()
  }

  // reset all fields besides country if a user changes the country
  useEffect(() => {
    resetField('address1')
    resetField('address2')
    resetField('city')
    resetField('postalCode')
    resetField('stateProvince')
    setValue('')
  }, [country])

  return (
    <div className='grid grid-cols-1 gap-x-4 md:grid-cols-4'>
      <section className='md:col-span-1'>
        <FormDropdown required label='Country' name='country'>
          {countries.map(({ value, label }) => (
            <DropdownOption key={label} value={value}>
              {value}
            </DropdownOption>
          ))}
        </FormDropdown>
      </section>
      <section className='md:col-span-3'>
        <Autocomplete
          {...register('address1', { required: rules.required })}
          autoExpand
          fullWidth
          required
          autoComplete='off'
          errorText={errors?.['address1']?.message}
          isDisabled={!ready}
          label='Billing address'
          placeholder='Search Address'
          ref={autocompleteRef}
          slotAfter={
            value ? (
              <IconButton
                icon='clear'
                size='large'
                onClick={() => {
                  handleClearAndFocus({ newValue: '', focusesInput: true })
                  setFormFieldValue('address1', '')
                }}
              />
            ) : null
          }
          slotBefore={
            <span className='c-grey-700'>
              <Icon name='search' size='medium' />
            </span>
          }
          value={value}
          onChange={e => setValue(e.target.value)}
          onKeyDown={e => {
            // prevent enter from submitting form
            if (e.key === KeyCode.ENTER) e.preventDefault()
          }}
          onOptionSelect={onOptionSelect}
        >
          {options.map(option => (
            <GeocoderOption key={option.place_id} option={option} />
          ))}
        </Autocomplete>
      </section>
      {(addressState?.address2 || showAllAddressFields) && (
        <section className='md:col-span-4'>
          <Input
            fullWidth
            autoComplete='address-line2'
            label='Address Line 2'
            name='address2'
            placeholder='Suite or Apt #'
          />
        </section>
      )}
      {(addressState?.city || showAllAddressFields) && (
        <section className='md:col-span-4'>
          <Input
            fullWidth
            autoComplete='address-level2'
            label='City'
            name='city'
            placeholder='City'
            required={rules.required}
          />
        </section>
      )}
      <section className='md:children-col-span-2 md:col-span-4 md:grid md:grid-cols-subgrid'>
        {(addressState?.postalCode || showAllAddressFields) && (
          <Input
            fullWidth
            autoComplete='postal-code'
            label='Zip Code'
            name='postalCode'
            placeholder='Enter Zip Code'
            required={rules.required}
            rules={rules.postalCode}
          />
        )}
        {(addressState?.stateProvince || showAllAddressFields) &&
          getStateInputElement(country)}
      </section>
    </div>
  )
}
