import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { guideDraftPublishedEventFragment } from './useGetGuideDraftQuery'

const archivePublishedEventMutation = gql(`
  mutation ArchivePublishedEventMutationInGuideDraft($archivePublishedEventInput: ArchivePublishedEventInput!, $first: Int = 100) {
    archivePublishedEvent(archivePublishedEventInput: $archivePublishedEventInput) {
      guide {
        id
        eventCounts {
          numberEvents
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        status
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useArchiveGuideDraftEventMutation = () =>
  useMutation(archivePublishedEventMutation)
