import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import type {
  GetTripDetailsQueryInTripsQuery,
  GetTripEventsQueryInTripsQuery,
  HotelSearchResultWithoutRates,
} from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { MarkerWrapper, Map } from 'src/common/components/Map'
import { centerMap, getDefaultZoom } from 'src/common/components/Map/utils'
import { MarkerColors } from 'src/component-library/theme/colors'
import { HotelResultsCardMinimalRow } from 'src/pages/hotels/results/common'
import { StyledMarker, TripMapTogglePins } from 'src/pages/trips/components'
import { getAnchorLocation } from 'src/pages/trips/utils/mapUtils'
import { getProductTypesAsObject } from 'src/utils'
import { TripDetailsExploreMapEvents } from './TripDetailsExploreMapEvents'
import {
  dedupeTripExploreMarkers,
  getTripExploreEventsAsMarkerObject,
  getTripExploreExperiencesAsMarkerObject,
  getTripExploreHotelsAsMarkerObject,
} from './tripDetailsExploreMapUtils'
import { MobileMapsHeader } from '../../../components/MobileMapsHeader'
import type {
  ExploreExperience,
  ExploreExperiencesResult,
  ExploreHotelsResult,
  ExploreMarker,
} from '../../constants'
import { ExploreSearchParams } from '../../constants'

interface TripDetailsExploreMapProps {
  experiencesResult: ExploreExperiencesResult
  hotelsResult: ExploreHotelsResult
  hoveredEventId?: string
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  tripEventsData: GetTripEventsQueryInTripsQuery['getTrip']
  onHoveredEventIdChange?(updatedHoveredId: string): void
}

export const TripDetailsExploreMap = ({
  experiencesResult,
  hotelsResult,
  hoveredEventId,
  tripDetailsData,
  tripEventsData,
  onHoveredEventIdChange,
}: TripDetailsExploreMapProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showAllPoints, setShowAllPoints] = useState(false)
  const anchorLocation = getAnchorLocation(
    tripDetailsData?.tripPreference?.addresses
  )
  const productType = searchParams.get(ExploreSearchParams.productType)
  const selectedEventId = searchParams.get(ExploreSearchParams.activeExploreId)
  const { isExperience } = getProductTypesAsObject(productType ?? '')
  const items: ExploreMarker[] = useMemo(() => {
    const tripDetailsExploreExperiencesData =
      (experiencesResult?.data?.searchExperiences
        ?.edges as ExploreExperience[]) ?? []
    const tripDetailsExploreHotelsData =
      hotelsResult?.data?.searchHotelsWithoutRates?.hotelSearchResults ?? []
    const eventMarkers = showAllPoints
      ? getTripExploreEventsAsMarkerObject(tripEventsData)
      : []

    if (isExperience)
      return dedupeTripExploreMarkers([
        ...getTripExploreExperiencesAsMarkerObject(
          tripDetailsExploreExperiencesData
        ),
        ...eventMarkers,
      ])

    return dedupeTripExploreMarkers([
      ...getTripExploreHotelsAsMarkerObject(
        tripDetailsExploreHotelsData.filter(
          (
            data: HotelSearchResultWithoutRates | null | undefined
          ): data is HotelSearchResultWithoutRates =>
            data !== undefined && data !== null
        )
      ),
      ...eventMarkers,
    ])
  }, [
    experiencesResult?.data?.searchExperiences,
    hotelsResult?.data?.searchHotelsWithoutRates,
    productType,
    showAllPoints,
    tripEventsData,
  ])

  const setProductType = (
    productType: EventType.Experience | EventType.Stay
  ) => {
    searchParams.set(ExploreSearchParams.productType, productType)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <div className='h-full grow'>
      <div className='block lg:hidden'>
        <MobileMapsHeader
          onExperiencesClicked={() => {
            setProductType(EventType.Experience)
          }}
          onHotelsClicked={() => {
            setProductType(EventType.Stay)
          }}
        />
      </div>
      <Map
        defaultCenter={{
          lat: anchorLocation?.lat ?? 0,
          lng: anchorLocation?.lng ?? 0,
        }}
        defaultZoom={getDefaultZoom({
          city: anchorLocation?.city ?? '',
          state: anchorLocation?.state ?? '',
          country: anchorLocation?.country ?? '',
        })}
        maxZoom={20}
        minZoom={3}
        onDataChanged={map => {
          if (items.length > 0) {
            centerMap({
              map,
              points: items?.map(({ lat, long }) => ({
                lat: parseFloat(lat),
                lng: parseFloat(long),
              })),
            })
          }
        }}
      >
        <TripDetailsExploreMapEvents points={items} />
        {items.map(
          ({
            color,
            customerReviewScore,
            id,
            image,
            lat,
            long,
            title,
            totalReviews,
            type,
            variant,
          }) => {
            const isDisabled = color === MarkerColors.NOT_IN_ACTIVE_VIEW
            const isHovered = id === hoveredEventId
            const isSelected = id === selectedEventId

            return (
              <MarkerWrapper
                key={id}
                isHovered={isHovered || isSelected}
                position={{
                  lat: parseFloat(lat),
                  lng: parseFloat(long),
                }}
                onClick={() => {
                  if (isDisabled) return

                  searchParams.set(ExploreSearchParams.activeExploreId, id)
                  setSearchParams(searchParams, {
                    replace: true,
                  })
                }}
              >
                <div
                  className='relative'
                  onMouseEnter={() => onHoveredEventIdChange?.(id)}
                  onMouseLeave={() => onHoveredEventIdChange?.('')}
                >
                  <StyledMarker
                    isHovered={isHovered}
                    isSelected={isSelected}
                    type={type}
                    variant={variant}
                  />
                  {isHovered && (
                    <div className='w-340px absolute top-[calc(100%+8px)] -translate-x-2/4'>
                      <HotelResultsCardMinimalRow
                        hotelResultsCardMinimalRowData={{
                          customerReviewScore,
                          id,
                          images: [image],
                          title,
                          totalCustomerReviews: totalReviews,
                          type,
                        }}
                        imageDimensions={type === EventType.Stay ? '400' : ''}
                      />
                    </div>
                  )}
                </div>
              </MarkerWrapper>
            )
          }
        )}
        <TripMapTogglePins
          isChecked={showAllPoints}
          onChange={event => setShowAllPoints(!!event.target.checked)}
        />
      </Map>
    </div>
  )
}
