import { useEffect } from 'react'
import { Button, MenuItem, Popover } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

/**
 * @description BOOK-2374 - limited list of GuideSort options to display only on this page.
 * @see GuideSorts from src/__generated__/graphql for full list of options.
 */
const GuideSorts = {
  DISTANCE_NEAR_TO_FAR: 'Distance',
  LAST_UPDATED: 'Last updated',
  RECOMMENDED: 'Recommended',
} as const

export const AllGuidesSortBy = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const sortBy = searchParams.get('sort')
  const selectedSort = GuideSorts[sortBy]
  const selectedSortLabel =
    'Sort by' + (selectedSort ? `: ${selectedSort}` : '...')

  useEffect(() => {
    if (!sortBy) {
      setSearchParams(
        sp => {
          sp.set('sort', 'RECOMMENDED')
          return sp
        },
        { replace: true }
      )
    }
  }, [])

  return (
    <Popover
      placement='bottom-start'
      trigger={
        <Button
          aria-label='Sort guides'
          endIcon='expandMore'
          size='small'
          startIcon='sort'
          variant='outlined'
        >
          {selectedSortLabel}
        </Button>
      }
    >
      {Object.entries(GuideSorts).map(([sortValue, sortValueLabel]) => {
        return (
          <MenuItem
            key={sortValue}
            isHighlighted={sortValue === sortBy}
            label={sortValueLabel}
            value={sortValue}
            onClick={() => {
              setSearchParams(sp => {
                sp.set('sort', sortValue)
                return sp
              })
              // pushDataToDataLayer('guides_results_sort_by', {
              //   value: sortValue,
              // })
            }}
          />
        )
      })}
    </Popover>
  )
}
