interface ImageWithFallbackProps {
  displayName: string
  src: string
  fallbackSrc: string
  className?: string
}

export const ImageWithFallback = ({
  displayName,
  src,
  fallbackSrc,
  className,
}: ImageWithFallbackProps) => {
  const handleError = event => {
    event.target.src = fallbackSrc
  }

  return (
    <img
      alt={`${displayName}'s profile`}
      className={
        className || 'rounded-2 md:h-26 h-18 w-20 object-cover md:w-full'
      }
      src={src}
      style={{
        overflowClipMargin: 'unset',
      }}
      onError={handleError}
    />
  )
}
