const IMAGE_SIZE_MOBILE = '400x400'
const IMAGE_SIZE_DESKTOP = '860x800'
const MINI_IMAGE_SIZE_MOBILE = '80x80'
const MINI_IMAGE_SIZE_DESKTOP = '150x130'

export {
  IMAGE_SIZE_MOBILE,
  IMAGE_SIZE_DESKTOP,
  MINI_IMAGE_SIZE_MOBILE,
  MINI_IMAGE_SIZE_DESKTOP,
}
