import { IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'

export const TripExploreActions = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onMobileMapOpen = () => {
    searchParams.set('mapFullView', 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <div className='p-x-8 p-y-6 z-3 fixed bottom-0 right-0 flex h-24 flex-row justify-end bg-white'>
      <IconButton
        aria-label='Menu'
        color='white'
        icon='mapOutlined'
        outline='round'
        size='large'
        onClick={onMobileMapOpen}
      />
    </div>
  )
}
