import type { ReactNode } from 'react'
import classNames from 'classnames'

interface GuideMapWrapperProps {
  children: ReactNode
  className?: string
  mapFullView: boolean
  selectedId: string
}

export const GuideMapWrapper = ({
  children,
  className,
  mapFullView,
  selectedId,
}: GuideMapWrapperProps) => (
  <div
    className={classNames(
      className,
      'bg-grey-100 lg:transition-margin-175 relative lg:sticky lg:grow lg:ease-[cubic-bezier(0,0,0.2,1)]',
      {
        'lg:-m-l-130': !selectedId,
        'lg:w-initial hidden lg:block lg:grow': !mapFullView,
      }
    )}
  >
    {children}
  </div>
)
