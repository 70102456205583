import { useRef } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type { Bucket } from 'src/__generated__/graphql'
import { stringToBoolean } from 'src/utils'
import { HotelResultsNavChips } from './HotelResultsNavChips'
import { HotelResultsNavContainer } from './HotelResultsNavContainer'
import { HotelResultsNavSearch } from './HotelResultsNavSearch'
import { HotelResultsListNav } from '../HotelResultsList/HotelResultsListNav'
import { HotelResultsFilters, HotelResultsSort } from '../common'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import type { HotelResultsData } from '../hotelResultsTypes'

interface HotelResultsNavProps {
  hotelResultsBrandData?: Bucket[]
  hotelResultsData?: HotelResultsData
}

export const HotelResultsNav = ({
  hotelResultsBrandData = [],
  hotelResultsData = [],
}: HotelResultsNavProps) => {
  const sortRef = useRef(null)
  const [searchParams] = useSearchParams()
  const mapExpanded = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.mapExpanded)
  )

  return (
    <div data-testid='HotelResultsNav'>
      <HotelResultsNavContainer>
        <div
          className={classNames(
            'flex items-center justify-between gap-2 lg:block',
            {
              hidden: mapExpanded,
            }
          )}
          ref={sortRef}
        >
          <HotelResultsSort />
          {!isEmpty(hotelResultsData) && (
            <div className='block lg:hidden'>
              <HotelResultsListNav />
            </div>
          )}
        </div>
        <div className='lg:display-initial hidden lg:min-w-0 lg:grow'>
          <HotelResultsNavChips />
        </div>
        <div>
          <div className='flex items-center'>
            <HotelResultsNavSearch />
            <HotelResultsFilters
              hotelResultsBrandData={hotelResultsBrandData}
            />
          </div>
        </div>
      </HotelResultsNavContainer>
    </div>
  )
}
