import { MenuLink } from './MenuLink'

interface MenuSubtitleProps {
  to: string
  title: string
}

export const MenuSubtitle = ({ to, title }: MenuSubtitleProps) => (
  <MenuLink to={to}>
    <span className='c-grey800'>{title}</span>
  </MenuLink>
)
