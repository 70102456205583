import { useEffect } from 'react'
import {
  Helmet,
  NativeAppCta,
  PageLayoutContainer,
} from 'src/common/components'
import { BookingConfirmationTripsApollo } from 'src/common/components/BookingConfirmationTrips'
import { useFlag } from 'src/common/hooks'
import { BookingConfirmationSummaryApollo } from './components'

const useScrollToTopOnMount = () => {
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])
}

export const BookingConfirmation = () => {
  const isTripsHideEnabled = useFlag('tripsHide')
  useScrollToTopOnMount()
  return (
    <>
      <Helmet pageName='Booking Confirmation' />
      <div className='rebrand pb-10 pt-10 md:pb-36 md:pt-16'>
        <PageLayoutContainer>
          <div className='gap-18 max-w-113 md:max-w-198 m-auto flex flex-col'>
            <BookingConfirmationSummaryApollo />
            {!isTripsHideEnabled && <BookingConfirmationTripsApollo />}
            <NativeAppCta />
            <div className='stjr-instant-feedback'></div>
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}
