import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const experienceProductDataGql = gql(`
  query ExperienceProductData($productCode: String!) {
    findProduct(productCode: $productCode) {
      id
      additionalInfo {
        description
        type
      }
      availabilitySchedule {
        bookableItems {
          seasons {
            endDate
            startDate
            operatingHours {
              operatingHours {
                opensAt
                closesAt
              }
              dayOfWeek
            }
            pricingRecords {
              timedEntries {
                startTime
              }
            }
          }
        }
      }
      description
      destinations {
        destinationName
        latitude
        longitude
      }
      images {
        size720x480
      }
      inclusions {
        description
      }
      itinerary {
        ... on ExperienceActivityItinerary {
          duration {
            readableDurationRange
          }
          foodMenus {
            course
            dishName
            dishDescription
          }
          itineraryType
          unstructuredDescription
        }
        ... on ExperienceHopOnHopOffItinerary {
          duration {
            readableDurationRange
          }
          itineraryType
          routes {
            duration {
              readableDurationRange
            }
            name
            operatingSchedule
            pointsOfInterest {
              location {
                name
              }
            }
            stops {
              description
              stopLocation {
                name
              }
            }
          }
          unstructuredDescription
        }
        ... on ExperienceStandardItinerary {
          duration {
            readableDurationRange
          }
          itineraryItems {
            description
            duration {
              readableDurationRange
            }
            passByWithoutStopping
            pointOfInterestLocation {
              location {
                name
              }
            }
          }
          itineraryType
          unstructuredDescription
        }
        ... on ExperienceUnstructuredItinerary {
          itineraryType
          unstructuredDescription
          unstructuredItinerary
        }
      }
      languageGuides {
        language
        type
      }
      logistics {
        travelerPickup {
          additionalInfo
          locations {
            location {
              address
              city
              country
              latitude
              longitude
              name
              postalCode
              reference
              state
            }
            pickupType
          }
        }
        start {
          description
          location {
            address
            city
            country
            latitude
            longitude
            name
            postalCode
            reference
            state
          }
        }
      }
      productCode
      productOptions {
        productOptionCode
        description
        title
      }
      reviews {
        combinedAverageRating
        totalReviews
      }
      title
      ticketInfo {
        ticketTypeDescription
      }
    }
  }
`)

export const useExperienceProductData = () => {
  const [
    getExperienceProductData,
    { data: experienceProductData, loading, error },
  ] = useLazyQuery(experienceProductDataGql)

  const data = experienceProductData?.findProduct ?? null

  return {
    getExperienceProductData,
    data,
    error,
    loading,
  }
}
