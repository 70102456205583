import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import type {
  ExperienceBooking,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { NativeAppCta, PageLayoutContainer } from 'src/common/components'
import { ExperiencesBookingDetailsBreadcrumbs } from './ExperiencesBookingDetailsBreadcrumbs'
import { ExperiencesBookingDetailsHero } from './ExperiencesBookingDetailsHero'
import { ExperiencesBookingDetailsManage } from './ExperiencesBookingDetailsManage'
import { ExperiencesBookingDetailsOverview } from './ExperiencesBookingDetailsOverview'
import { ExperiencesBookingDetailsPrice } from './ExperiencesBookingDetailsPrice'
import { ExperiencesBookingDetailsSummary } from './ExperiencesBookingDetailsSummary'
import {
  experiencesBookingDetailsCss,
  experiencesBookingDetailsContentCss,
  experiencesBookingDetailsHeaderCss,
  experiencesBookingDetailsWrapperCss,
} from './experiencesBookingDetailsStyles'

interface ExperiencesBookingDetailsProps {
  experiencesBookingDetailsData: ExperienceBooking
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetails = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsProps) => {
  const experiencesBookingDetailsRef = useRef(null)

  const onPrintButtonClick = useReactToPrint({
    content: () => experiencesBookingDetailsRef.current,
  })

  return (
    <div
      className={experiencesBookingDetailsWrapperCss}
      ref={experiencesBookingDetailsRef}
    >
      <PageLayoutContainer size='medium'>
        <div className={experiencesBookingDetailsCss}>
          <div className={experiencesBookingDetailsHeaderCss}>
            <ExperiencesBookingDetailsBreadcrumbs
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
            <ExperiencesBookingDetailsHero
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
          </div>
          <div className={experiencesBookingDetailsContentCss}>
            <ExperiencesBookingDetailsSummary
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
            <ExperiencesBookingDetailsOverview
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
            <ExperiencesBookingDetailsManage
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
            <ExperiencesBookingDetailsPrice
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              onPrintButtonClick={onPrintButtonClick}
            />
            <NativeAppCta />
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  )
}
