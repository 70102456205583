import { Button, ModalActions, ModalNext } from '@travelpass/design-system'

interface GuideDraftEventDeleteModalProps {
  onClose: VoidFunction
  onDelete: VoidFunction
}

export const GuideDraftEventDeleteModal = ({
  onClose,
  onDelete,
}: GuideDraftEventDeleteModalProps) => {
  return (
    <ModalNext
      size='small'
      title='Are you sure you want to delete this Guide event?'
      onClose={onClose}
    >
      <p className='c-grey-800 type-body-1-desktop'>
        Notes for this event will be removed.
      </p>
      <ModalActions>
        <Button
          aria-label='Delete Guide event'
          fullWidth={true}
          variant='error'
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          aria-label='Cancel Guide event deletion'
          fullWidth={true}
          variant='outlined'
          onClick={onClose}
        >
          Cancel
        </Button>
      </ModalActions>
    </ModalNext>
  )
}
