import { useSnackbar } from '@travelpass/design-system'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useIncrementGuideEventShareCountMutation } from './useIncrementGuideEventShareCountMutation'
import { useShareGuide } from '../useShareGuide'

export const useGuideEventMetricShare = (id: PublishedEvent['id']) => {
  const { addSuccessSnack } = useSnackbar()
  const shareGuide = useShareGuide()
  const [incrementGuideEventShareCount] =
    useIncrementGuideEventShareCountMutation()

  const onGuideEventShare = async () => {
    await shareGuide({
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
      },
    })
    await incrementGuideEventShareCount({
      variables: {
        publishedEventId: id,
      },
    })
  }

  return onGuideEventShare
}
