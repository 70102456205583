import type { ContextType } from 'react'
import { useRef, useState } from 'react'
import { Chip } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller } from 'src/common/components'

const chipLabels = [
  'Experience',
  'Food & Drink',
  'Miscellaneous',
  'Shopping',
  'Stay',
  'Following',
  'My Guides',
]

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface GuidesEventsMapFiltersProps {
  onFiltersChange: (updatedFilters: string[]) => void
}

export const GuidesEventsMapFilters = ({
  onFiltersChange,
}: GuidesEventsMapFiltersProps) => {
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const [chips, setChips] = useState(
    chipLabels.map(label => ({
      label,
      value: label,
      isSelected: false,
    }))
  )

  const onChipClick = index => {
    const updatedChips =
      chips.map((chip, i) =>
        i === index ? { ...chip, isSelected: !chip.isSelected } : chip
      ) ?? []
    setChips(updatedChips)
    onFiltersChange(
      updatedChips.reduce((total, chip) => {
        if (chip.isSelected) total.push(chip.value)

        return total
      }, [])
    )
  }

  return (
    <Scroller
      allowTouch={true}
      apiRef={apiRef}
      scrollContainerClassName='gap-1 flex flex-row'
    >
      {chips.map(({ label, value, isSelected }, index) => (
        <div key={value} className='ws-nowrap'>
          <Chip
            isSelected={isSelected}
            label={label}
            value={value}
            onClick={() => onChipClick(index)}
          />
        </div>
      ))}
    </Scroller>
  )
}
