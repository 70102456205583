import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  checkIsNativeAppUser,
  getRateOptions,
  stringToBoolean,
} from 'src/utils'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import type {
  HotelResultsItemData,
  UseGetHotelResultsQuery,
} from '../hotelResultsTypes'
import {
  getHotelResultsBucketsByBrand,
  getHotelResultsVariablesFromSearchParams,
} from '../hotelResultsUtils'

const searchHotelsQuery = gql(`
  query SearchHotelsQueryInHotelResults($searchHotelsArgs: SearchHotelsArgs!, $specificLodgingArgs: SpecificLodgingArgs!, $unit: Unit) {
    searchHotels(searchHotelsArgs: $searchHotelsArgs) {
      cursor
      facets {
        name
        buckets {
          value
        }
      }
      hotelSearchResults {
        id
        amenities
        customerReviewScore
        hotelAddress {
          city
          distance(unit: $unit)
          latitude
          longitude
          postalCode
          state
          stateAbbreviation
          streetAddress
        }
        images
        isCollected
        isFavorited
        isFeatured
        name
        nightlyAverage
        starRating
        totalCustomerReviews
      }
    }
    specificLodging(specificLodgingArgs: $specificLodgingArgs) {
      id
      amenities
      customerReviewScore
      hotelAddress {
        city
        distance(unit: $unit)
        latitude
        longitude
        postalCode
        state
        stateAbbreviation
        streetAddress
      }
      images
      isCollected
      isFavorited
      name
      nightlyAverage
      starRating
      totalCustomerReviews
    }
  }
`)

export const useGetHotelResultsQuery = (): UseGetHotelResultsQuery => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isAnonymous } = useFirebaseUser()
  const variables = useMemo(() => {
    const isNativeAppUser = checkIsNativeAppUser()

    return getHotelResultsVariablesFromSearchParams({
      options: getRateOptions(isAnonymous, isNativeAppUser),
      searchParams,
    })
  }, [isAnonymous, searchParams])
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isMoreResultsLoading, setIsMoreResultsLoading] = useState(false)
  const mapSearchThisArea = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.mapSearchThisArea)
  )
  const {
    data,
    error: hasError,
    fetchMore,
    loading,
    networkStatus,
  } = useQuery(searchHotelsQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const { hotelSearchResults } = data?.searchHotels ?? {}
      searchParams.set(
        HotelResultsSearchParams.arrival,
        variables.searchHotelsArgs.arrival
      )
      searchParams.set(
        HotelResultsSearchParams.departure,
        variables.searchHotelsArgs.departure
      )
      searchParams.set(
        HotelResultsSearchParams.extendedLoading,
        !isMoreResultsLoading ? 'true' : 'false'
      )
      searchParams.set(
        HotelResultsSearchParams.mapFitBounds,
        !mapSearchThisArea ? 'true' : 'false'
      )
      searchParams.delete(HotelResultsSearchParams.mapSearchThisArea)
      setSearchParams(searchParams, {
        replace: true,
      })
      setIsMoreResultsLoading(false)
      setHasMoreResults(!!data?.searchHotels?.cursor)

      // TODO: might have delete variables.searchHotelsArgs.filters when object equals {}

      pushDataToDataLayer('hotelSearchResults', {
        hotels: hotelSearchResults,
        searchArgs: variables.searchHotelsArgs,
        searchTerm: searchParams.get(HotelResultsSearchParams.location),
        numberOfResults: hotelSearchResults.length,
      })
    },
    skip: !variables,
    variables: variables,
  })
  const placeId = searchParams.get(HotelResultsSearchParams.placeId)
  const hotelResultsBrandData = getHotelResultsBucketsByBrand(
    data?.searchHotels?.facets
  )
  let hotelResultsData = [...(data?.searchHotels?.hotelSearchResults ?? [])]
  let hotelResultsFeaturedData: HotelResultsItemData[] = []
  const hasFeaturedResults = !!data?.specificLodging?.nightlyAverage
  const hotelResultsLodgingData = data?.specificLodging || null
  const isLoading = loading || !variables

  if (placeId) {
    if (hasFeaturedResults) {
      hotelResultsData = data?.searchHotels?.hotelSearchResults.filter(
        ({ id }) => id !== hotelResultsLodgingData?.id
      )
      hotelResultsFeaturedData = [
        {
          ...hotelResultsLodgingData,
          isLodging: true,
        },
      ]
    } else {
      hotelResultsFeaturedData = hotelResultsLodgingData
        ? [
            {
              ...hotelResultsLodgingData,
            },
          ]
        : []
    }
  } else {
    hotelResultsFeaturedData = hotelResultsData
      .filter(({ isFeatured }) => !!isFeatured)
      .slice(0, 3)
  }

  const loadMore = async () => {
    const { cursor } = data?.searchHotels ?? {}
    setIsMoreResultsLoading(true)
    searchParams.set(HotelResultsSearchParams.extendedLoading, 'false')
    setSearchParams(searchParams, {
      replace: true,
    })

    try {
      if (cursor) {
        await fetchMore({
          variables: {
            searchHotelsArgs: {
              ...variables?.searchHotelsArgs,
              cursor,
            },
          },
          updateQuery: (previousResults, { fetchMoreResult }) => {
            const updatedResults = cloneDeep(fetchMoreResult)
            const { hotelSearchResults: previousResultsData } =
              previousResults?.searchHotels ?? {}
            const { cursor, hotelSearchResults: currentResultsData } =
              fetchMoreResult?.searchHotels ?? {}
            setHasMoreResults(!!cursor)
            const updatedResultsData =
              previousResultsData.concat(currentResultsData)
            updatedResults.searchHotels.hotelSearchResults = updatedResultsData

            return updatedResults
          },
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    data,
    hasError,
    hasMoreResults,
    hotelResultsBrandData,
    hotelResultsData,
    hotelResultsFeaturedData,
    isLoading,
    loadMore,
    networkStatus,
  }
}
