import isEmpty from 'lodash.isempty'
import type { TripStatus } from 'src/__generated__/graphql'
import { arrayTypeNotNull } from 'src/utils'
import { UserTripsEmpty } from './UserTripsEmpty'
import { UserTripsError } from './UserTripsError'
import { UserTripsList } from './UserTripsList'
import { UserTripsLoading } from './UserTripsLoading'
import { useCurrentUserTrips } from '../hooks'

export const UserTrips = ({ tripStatus }: { tripStatus: TripStatus }) => {
  const { loading, error, trips, currentUser, refetch } =
    useCurrentUserTrips(tripStatus)

  if (loading) return <UserTripsLoading />

  const noTrips = isEmpty(trips)
  const noData = error || noTrips || !currentUser?.externalId
  if (!loading && noData) {
    if (error) {
      return <UserTripsError onClick={refetch} />
    }
    return <UserTripsEmpty tripStatus={tripStatus} />
  }

  const tripsNonNull = arrayTypeNotNull(trips ?? [])

  return <UserTripsList userTrips={tripsNonNull} />
}
