import { Skeleton } from '@travelpass/design-system'
import {
  HotelCancellationBadgeLoading,
  OccupancySummaryLoading,
} from 'src/common/components'
import {
  bookingDetailsRoomAmenitiesClasses,
  bookingDetailsRoomAmenityClasses,
  bookingDetailsRoomClasses,
  bookingDetailsRoomGroupClasses,
  bookingDetailsRoomSummaryContentClasses,
  bookingDetailsRoomSummaryClasses,
  bookingDetailsRoomSummaryImageClasses,
} from './bookingDetailsRoomStyles'

export const BookingDetailsRoomApolloLoading = () => {
  const constructedAmenities = () =>
    Array(6)
      .fill(0)
      .map((_, index) => (
        <div
          key={`booking-details-room-amenity-${index}`}
          className={bookingDetailsRoomAmenityClasses}
        >
          <div className='h-24px w-24px'>
            <Skeleton />
          </div>
          <div className='h-16px md:h-18px w-100px'>
            <Skeleton />
          </div>
        </div>
      ))
  const constructedTitle = () => (
    <div className='h-20px md:h-24px'>
      <Skeleton />
    </div>
  )

  return (
    <div className={bookingDetailsRoomClasses}>
      <div className={bookingDetailsRoomSummaryClasses}>
        <div className={bookingDetailsRoomSummaryImageClasses}>
          <Skeleton variant='rounded' />
        </div>
        <div className={bookingDetailsRoomSummaryContentClasses}>
          {constructedTitle()}
          <OccupancySummaryLoading />
          <HotelCancellationBadgeLoading />
        </div>
      </div>
      <div className={bookingDetailsRoomGroupClasses}>
        {constructedTitle()}
        <div className={bookingDetailsRoomAmenitiesClasses}>
          {constructedAmenities()}
        </div>
        <div className='h-16px md:h-18px w-115px'>
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
