import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const UNFOLLOW_USER = gql(`
    mutation unfollowProfile($input: UnfollowUserInput!){
        unfollowUser(input: $input) {
            userProfile {
                ...FollowUserProfileFields
            }
        }
    }
`)

export const useUnfollowMutation = options =>
  useMutation(UNFOLLOW_USER, options)
