import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_USER_PROFILE = gql(`
  query GetUserProfile ($userId: ID, $accountHandle: String) {
    viewUserProfile (userId: $userId, accountHandle: $accountHandle) {
      accountHandle
      bio
      bragContinents
      bragCountries
      bragStates
      displayName
      followingUsers {
        id
        accountHandle
      }
      followCount {
        countOfFollowed
        countOfFollowing
      }
      guideCount
      isFollowed
      id
      isUserOwner
      links {
        title
        url
      }
      profileImageUrl
      socialLinks {
        identifier
        network
      }
      userId
      visibilityLevel
    }
  }
`)

type GetUserProfileQueryArgs =
  | {
      userId: string
      accountHandle?: never
    }
  | {
      userId?: never
      accountHandle: string
    }

const useGetUserProfile = ({
  userId,
  accountHandle,
}: GetUserProfileQueryArgs) => {
  const getUserProfileQueryVariables = () => {
    if (userId) {
      return { userId }
    }
    if (accountHandle) {
      return { accountHandle }
    }
  }

  const { data, loading, error } = useQuery(GET_USER_PROFILE, {
    variables: getUserProfileQueryVariables(),
  })

  const { viewUserProfile: profileData } = data ?? {}

  return {
    profileData,
    loading,
    error,
  }
}

export { GET_USER_PROFILE, useGetUserProfile }
