import {
  Button,
  Icon,
  IconButton,
  Input,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import type { PaxMix } from 'src/__generated__/graphql'

import { experiencesPath } from 'src/constants'
import {
  constructImageLinks,
  getTimeFromMilitaryTimeString,
  copyToClipboard,
} from 'src/utils'
import { paxMixFormatter } from '../utils'

const getDuration = ({ start, durationRange }) => {
  let duration = ''
  if (start) {
    duration += getTimeFromMilitaryTimeString(start)
  }
  if (durationRange) {
    if (duration) duration += ' - ' /* add separator */
    duration += durationRange
  }
  return duration
}

interface ExperiencesConfirmationSummaryProps {
  title: string
  arrival: string
  departure?: string
  durationRange?: string
  hideButton?: boolean
  startTime: string
  paxMix: PaxMix[]
  externalConfirmationId: string
  // TODO: imageLinks need to be added to the api
  imageLinks?: string[]
}

export const ExperiencesConfirmationSummary = ({
  title,
  arrival,
  departure,
  durationRange,
  hideButton = false,
  externalConfirmationId,
  startTime,
  paxMix,
  imageLinks,
}: ExperiencesConfirmationSummaryProps) => {
  const { addSuccessSnack } = useSnackbar()
  const navigate = useNavigate()
  const showImage = false // !isEmpty(confirmationDetails.imageLinks)
  const constructedImage = showImage
    ? constructImageLinks(imageLinks).shift()
    : ''
  const time = getDuration({ start: startTime, durationRange })

  const goToBookingDetails = () =>
    navigate(`${experiencesPath}/confirmation/${externalConfirmationId}`)

  const onSnackbarOpen = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  return (
    <section>
      <div className='flex flex-col items-center gap-5 md:flex-row'>
        {showImage && (
          <img
            alt=''
            className='rd-3 h-30 md:max-w-45 w-full max-w-full object-cover md:h-auto'
            src={constructedImage}
          />
        )}
        <div>
          <h2 className='text-h4'>{title}</h2>
          <div>
            <SummaryLine className='flex items-center gap-1'>
              <Icon name='personOutline' />
              <span className='capitalize'>{paxMixFormatter(paxMix)}</span>
            </SummaryLine>
            <SummaryLine>
              <Icon name='calendarToday' />
              <span>
                {dayjs(arrival).format('dddd MMM D, YYYY')}
                {departure ? (
                  <> &ndash; {dayjs(departure).format('dddd MMM D, YYYY')}</>
                ) : null}
              </span>
            </SummaryLine>
            <SummaryLine>
              <Icon name='accessTime' />
              {time ?? '--:--'}
            </SummaryLine>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end gap-6 md:flex-row'>
        <div
          className={classNames('w-full', {
            'md:w-1/2': !hideButton,
          })}
        >
          <Input
            fullWidth
            readOnly
            id='experience-confirmation-number'
            label='Confirmation number'
            slotAfter={
              <IconButton
                aria-label='Copy confirmation number'
                icon='contentCopy'
                onClick={onSnackbarOpen}
              />
            }
            value={externalConfirmationId}
            onFocus={event => {
              event.target.blur()
              onSnackbarOpen()
            }}
          />
        </div>
        {!hideButton && (
          <div className='mb-1 w-full md:w-1/2'>
            <Button // TODO: this should be a Link
              fullWidth
              label='Booking Details'
              size='large'
              onClick={goToBookingDetails}
            />
          </div>
        )}
      </div>
    </section>
  )
}

const SummaryLine = props => (
  <p {...props} className='flex items-center gap-2' />
)
