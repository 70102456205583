import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'

export const GuideHeroLoading = () => (
  <div
    className={classNames(
      'of-hidden grid gap-1 lg:flex lg:flex-row lg:items-center',
      {
        'lg:[&>div:first-child]:w-194 lg:[&>div:first-child]:max-w-194 grid-cols-2 [&>div:nth-of-type(3)]:col-span-2':
          true,
      }
    )}
  >
    <div className='h-37.5 lg:h-92 grow'>
      <Skeleton />
    </div>
    <div className='h-37.5 lg:h-92 grow'>
      <Skeleton />
    </div>
    <div className='h-37.5 lg:h-92 grow'>
      <Skeleton />
    </div>
  </div>
)
