import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type { SearchHotelsQueryInHotelResultsQuery } from 'src/__generated__/graphql'
import { stringToBoolean, stringToNumber } from 'src/utils'
import {
  HotelResultsSearchParams,
  mapBoundsPadding,
} from '../hotelResultsConstants'

interface HotelResultsMapEventsProps {
  data: SearchHotelsQueryInHotelResultsQuery
  isLoading?: boolean
  onChange?(): void
}

export const HotelResultsMapEvents = ({
  data,
  isLoading = false,
  onChange = () => {},
}: HotelResultsMapEventsProps) => {
  const map = useGoogleMap()
  const [searchParams, setSearchParams] = useSearchParams()
  const latitude = searchParams.get(HotelResultsSearchParams.latitude)
  const longitude = searchParams.get(HotelResultsSearchParams.longitude)
  const mapBounds = searchParams.get(HotelResultsSearchParams.mapBounds)
  const mapFitBounds = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.mapFitBounds) ?? 'true'
  )

  useEffect(() => {
    map.panTo({
      lat: stringToNumber(latitude),
      lng: stringToNumber(longitude),
    })
  }, [latitude, longitude])

  useEffect(() => {
    const { searchHotels, specificLodging } = data ?? {}

    if (
      !isEmpty(searchHotels?.hotelSearchResults) &&
      !isLoading &&
      mapFitBounds
    ) {
      const bounds = new google.maps.LatLngBounds()
      searchHotels?.hotelSearchResults?.map(
        ({ hotelAddress }) =>
          latitude &&
          longitude &&
          bounds.extend({
            lat: stringToNumber(hotelAddress?.latitude),
            lng: stringToNumber(hotelAddress?.longitude),
          })
      )

      if (latitude && longitude && mapBounds)
        bounds.extend({
          lat: stringToNumber(latitude),
          lng: stringToNumber(longitude),
        })

      if (!isEmpty(specificLodging))
        bounds.extend({
          lat: stringToNumber(specificLodging?.hotelAddress?.latitude),
          lng: stringToNumber(specificLodging?.hotelAddress?.longitude),
        })

      searchParams.delete(HotelResultsSearchParams.mapFitBounds)
      setSearchParams(searchParams, {
        replace: true,
      })
      map?.fitBounds(bounds, mapBoundsPadding)
      onChange()
    }
  }, [data, isLoading, mapFitBounds])

  return <></>
}
