import { Skeleton } from '@travelpass/design-system'

export const TripDetailsExploreSearchExperiencesTagsLoading = () => (
  <div className='flex gap-1'>
    <div className='h-32px w-32px'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-32px flex grow flex-col'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-32px w-32px'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
