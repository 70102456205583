const imgPath =
  'https://static.travelpass.com/assets/landing-pages/utah-jazz/cities'
const guideOwner = 'Travelpass Official'
const guideProfileImage =
  'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/profile_images/f1e2fc3d-71a0-4423-8456-26f959e9951e'

export const awayGameDetails = [
  {
    team: 'Atlanta Hawks',
    city: 'Atlanta',
    cityImg: `${imgPath}/atlanta-georgia.webp`,
    state: 'GA',
    arena: 'State Farm Arena',
    guide: {
      id: '01J8T5C2PF1HM1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Atlanta, GA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/0a2b54fa-b4d2-491f-a3e6-1a57c6b83225',
      url: 'https://www.travelpass.com/guides/01J8T5C2PF1HM1?name=Utah-Jazz-Away-Game-Guide-To-Atlanta%2C-GA',
    },
  },
  {
    team: 'Boston Celtics',
    city: 'Boston',
    cityImg: `${imgPath}/boston-massachusetts.webp`,
    state: 'MA',
    arena: 'TD Garden',
    arenaImg:
      'https://www.tdgarden.com/assets/img/default_slideshow-1600x600-7d0781f96b.jpg',
    guide: {
      id: '01J8MWVT48DWP0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Boston, MA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/c2da1dda-3ef1-494a-b81f-bc78df3ac0f8',
      url: 'https://www.travelpass.com/guides/01J8MWVT48DWP0?name=Utah-Jazz-Away-Game-Guide-To-Boston%2C-MA',
    },
  },
  {
    team: 'Brooklyn Nets',
    city: 'Brooklyn',
    cityImg: `${imgPath}/brooklyn-new-york.webp`,
    state: 'NY',
    arena: 'Barclays Center',
    guide: {
      id: '01J80B8D01CZH1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Brooklyn, NY',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/fb6140da-ebcc-41ef-9d58-844041de0d0b',
      url: 'https://www.travelpass.com/guides/01J80B8D01CZH1?name=Utah-Jazz-Away-Game-Guide-To-Brooklyn%2C-NY',
    },
  },
  {
    team: 'Charlotte Hornets',
    city: 'Charlotte',
    cityImg: `${imgPath}/charlotte-north-carolina.webp`,
    state: 'NC',
    arena: 'Spectrum Center',
    guide: {
      id: '01J8NHVVBY3GG0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Charlotte, NC',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/cb0420cb-efd1-4a48-9e24-45f5ca860970',
      url: 'https://www.travelpass.com/guides/01J8NHVVBY3GG0?name=Utah-Jazz-Away-Game-Guide-To-Charlotte%2C-NC',
    },
  },
  {
    team: 'Chicago Bulls',
    city: 'Chicago',
    cityImg: `${imgPath}/chicago-illinois.webp`,
    state: 'IL',
    arena: 'United Center',
    guide: {
      id: '01J7CDBZ8XJ4Y1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Chicago, IL',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/faa6174f-fa99-4083-91e9-65ac7a230e90',
      url: 'https://www.travelpass.com/guides/01J7CDBZ8XJ4Y1?name=Utah-Jazz-Away-Game-Guide-To-Chicago%2C-IL',
    },
  },
  {
    team: 'Cleveland Cavaliers',
    city: 'Cleveland',
    cityImg: `${imgPath}/cleveland-ohio.webp`,
    state: 'OH',
    arena: 'Rocket Mortgage FieldHouse',
    guide: {
      id: '01J80TSQMP1B51',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Cleveland, OH',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/f82432c9-42ea-4b07-afbd-e422794cf86d',
      url: 'https://www.travelpass.com/guides/01J80TSQMP1B51?name=Utah-Jazz-Away-Game-Guide-To-Cleveland%2C-OH',
    },
  },
  {
    team: 'Dallas Mavericks',
    city: 'Dallas',
    cityImg: `${imgPath}/dallas-texas.webp`,
    state: 'TX',
    arena: 'American Airlines Center',
    guide: {
      id: '01J7BRXW7JH681',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Dallas, TX',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/ef03fc42-cfc9-4966-862f-e936b545883a',
      url: 'https://www.travelpass.com/guides/01J7BRXW7JH681?name=Utah-Jazz-Away-Game-Guide-To-Dallas%2C-TX',
    },
  },
  {
    team: 'Denver Nuggets',
    city: 'Denver',
    cityImg: `${imgPath}/denver-colorado.webp`,
    state: 'CO',
    arena: 'Ball Arena',
    guide: {
      id: '01J74EV11CYCE1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Denver, CO',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/9d51316b-c0e2-4a0e-9a3e-945b6bde688e',
      url: 'https://www.travelpass.com/guides/01J74EV11CYCE1?name=Utah-Jazz-Away-Game-Guide-To-Denver%2C-CO',
    },
  },
  {
    team: 'Detroit Pistons',
    city: 'Detroit',
    cityImg: `${imgPath}/detroit-michigan.webp`,
    state: 'MI',
    arena: 'Little Caesars Arena',
    guide: {
      id: '01J7Y67CJKV7T1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Detroit, MI',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/9e855115-f4c3-4ee7-a9d8-2fe34b230a91',
      url: 'https://www.travelpass.com/guides/01J7Y67CJKV7T1?name=Utah-Jazz-Away-Game-Guide-To-Detroit%2C-MI',
    },
  },
  {
    team: 'Golden State Warriors',
    city: 'San Francisco',
    cityImg: `${imgPath}/san-francisco-california.webp`,
    state: 'CA',
    arena: 'Chase Center',
    guide: {
      id: '01J8G07MGNN731',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To San Francisco, CA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/78f7575f-994e-4894-adff-7f1e9f70d594',
      url: 'https://www.travelpass.com/guides/01J8G07MGNN731?name=Utah-Jazz-Away-Game-Guide-To-San-Francisco%2C-CA',
    },
  },
  {
    team: 'Houston Rockets',
    city: 'Houston',
    cityImg: `${imgPath}/houston-texas.webp`,
    state: 'TX',
    arena: 'Toyota Center',
    guide: {
      id: '01J8R41MDG96V0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Houston, TX',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/9f30346f-80ab-4400-ac0b-afcd08cf7467',
      url: 'https://www.travelpass.com/guides/01J8R41MDG96V0?name=Utah-Jazz-Away-Game-Guide-To-Houston%2C-TX',
    },
  },
  {
    team: 'Indiana Pacers',
    city: 'Indianapolis',
    cityImg: `${imgPath}/indianapolis-indiana.webp`,
    state: 'IN',
    arena: 'Gainbridge Fieldhouse',
    guide: {
      id: '01J8QN2EG7ZH01',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Indianapolis, IN',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/399257a0-fc82-418d-a3bd-533e1f457433',
      url: 'https://www.travelpass.com/guides/01J8QN2EG7ZH01?name=Utah-Jazz-Away-Game-Guide-To-Indianapolis%2C-IN',
    },
  },
  {
    team: 'Los Angeles Clippers',
    city: 'Inglewood',
    cityImg: `${imgPath}/los-angeles-california.webp`,
    state: 'CA',
    arena: 'Intuit Dome',
    guide: {
      id: '01J7KZB0RJ6EK0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Inglewood, CA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/49902a5a-75b4-4e85-8e9f-a89dfd816ea1',
      url: 'https://www.travelpass.com/guides/01J7KZB0RJ6EK0?name=Utah-Jazz-Away-Game-Guide-To-Inglewood%2C-CA',
    },
  },
  {
    team: 'Los Angeles Lakers',
    city: 'Los Angeles',
    cityImg: `${imgPath}/los-angeles-california.webp`,
    state: 'CA',
    arena: 'Crypto.com Arena',
    guide: {
      id: '01J7P1SXGZBK31',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Los Angeles, CA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/72a8e956-5169-4231-a680-0fe7b8d64626',
      url: 'https://www.travelpass.com/guides/01J7P1SXGZBK31?name=Utah-Jazz-Away-Game-Guide-To-Los-Angeles%2C-CA',
    },
  },
  {
    team: 'Memphis Grizzlies',
    city: 'Memphis',
    cityImg: `${imgPath}/memphis-tennessee.webp`,
    state: 'TN',
    arena: 'Fedex Forum',
    guide: {
      id: '01J88MK30VGNC1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Memphis, TN',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/73ba15ed-c440-4716-aae0-5c80ae96f529',
      url: 'https://www.travelpass.com/guides/01J88MK30VGNC1?name=Utah-Jazz-Away-Game-Guide-To-Memphis%2C-TN',
    },
  },
  {
    team: 'Miami Heat',
    city: 'Miami',
    cityImg: `${imgPath}/miami-florida.webp`,
    state: 'FL',
    arena: 'Kaseya Center',
    guide: {
      id: '01J837MCZA0P41',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Jazz Away Game Guide To Miami, FL',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/8c1df6ca-d17a-461f-a057-2ef80301ddd5',
      url: 'https://www.travelpass.com/guides/01J837MCZA0P41?name=Jazz-Away-Game-Guide-To-Miami%2C-FL',
    },
  },
  {
    team: 'Milwaukee Bucks',
    city: 'Milwaukee',
    cityImg: `${imgPath}/milwaukee-wisconsin.webp`,
    state: 'WI',
    arena: 'Fiserv Forum',
    guide: {
      id: '01J7EN3ARM7Q51',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Milwaukee, WI',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/3bbc9a76-3810-4dfa-88b4-e34894a00142',
      url: 'https://www.travelpass.com/guides/01J7EN3ARM7Q51?name=Utah-Jazz-Away-Game-Guide-To-Milwaukee%2C-WI',
    },
  },
  {
    team: 'Minnesota Timberwolves',
    city: 'Minneapolis',
    cityImg: `${imgPath}/minneapolis-minnesota.webp`,
    state: 'MN',
    arena: 'Target Center',
    guide: {
      id: '01J8N6YB1MPPA0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Minneapolis, MN',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/b429bf46-f115-46c8-860e-a3badc8994e9',
      url: 'https://www.travelpass.com/guides/01J8N6YB1MPPA0?name=Utah-Jazz-Away-Game-Guide-To-Minneapolis%2C-MN',
    },
  },
  {
    team: 'New Orleans Pelicans',
    city: 'New Orleans',
    cityImg: `${imgPath}/new-orleans-louisiana.webp`,
    state: 'LA',
    arena: 'Smoothie King Center',
    guide: {
      id: '01J88699PNCHX0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To New Orleans, LA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/f11e821c-dfe3-48db-821d-90f18ded4d54',
      url: 'https://www.travelpass.com/guides/01J88699PNCHX0?name=Jazz-Away-Game-Guide-To-New-Orleans%2C-LA',
    },
  },
  {
    team: 'New York Knicks',
    city: 'New York',
    cityImg: `${imgPath}/new-york-city.webp`,
    state: 'NY',
    arena: 'Madison Square Garden',
    guide: {
      id: '01J91TH639RNQ1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Manhattan, NY',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/159de352-1ceb-4f57-b054-de3414d4f881',
      url: 'https://www.travelpass.com/guides/01J91TH639RNQ1?name=Utah-Jazz-Away-Game-Guide-To-Manhattan%2C-NY',
    },
  },
  {
    team: 'Oklahoma City Thunder',
    city: 'Oklahoma City',
    cityImg: `${imgPath}/oklahoma-city-oklahoma.webp`,
    state: 'OK',
    arena: 'Paycom Center',
    guide: {
      id: '01J7PEJ4DP8GY1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Oklahoma City, OK',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/a333fe9a-71ed-4e6f-be25-099a6a93a776',
      url: 'https://www.travelpass.com/guides/01J7PEJ4DP8GY1?name=Utah-Jazz-Away-Game-Guide-To-Oklahoma-City%2C-OK',
    },
  },
  {
    team: 'Orlando Magic',
    city: 'Orlando',
    cityImg: `${imgPath}/orlando-florida.webp`,
    state: 'FL',
    arena: 'Kia Center',
    guide: {
      id: '01J83MRHYK9SM0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Orlando, FL',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/e424914b-8bfa-4b5b-9989-e8e153158b12',
      url: 'https://www.travelpass.com/guides/01J83MRHYK9SM0?name=Utah-Jazz-Away-Game-Guide-To-Orlando%2C-FL',
    },
  },
  {
    team: 'Philadelphia 76ers',
    city: 'Philadelphia',
    cityImg: `${imgPath}/philadelphia-pennsylvania.webp`,
    state: 'PA',
    arena: 'Wells Fargo Center',
    guide: {
      id: '01J8JQK2ZWTDK1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Philadelphia, PA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/a8306a8f-a3dc-4d74-8730-e704810104f9',
      url: 'https://www.travelpass.com/guides/01J8JQK2ZWTDK1?name=Utah-Jazz-Away-Game-Guide-To-Philadelphia%2C-PA',
    },
  },
  {
    team: 'Phoenix Suns',
    city: 'Phoenix',
    cityImg: `${imgPath}/phoenix-arizona.webp`,
    state: 'AZ',
    arena: 'Footprint Center',
    guide: {
      id: '01J85YEBB5QWS0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Phoenix, AZ',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/d931551e-84af-492e-9d22-6683b6a160f8',
      url: 'https://www.travelpass.com/guides/01J85YEBB5QWS0?name=Utah-Jazz-Away-Game-Guide-To-Phoenix%2C-AZ',
    },
  },
  {
    team: 'Portland Trail Blazers',
    city: 'Portland',
    cityImg: `${imgPath}/portland-oregon.webp`,
    state: 'OR',
    arena: 'Moda Center',
    guide: {
      id: '01J7XRKWZ011S1',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Portland, OR',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/51154ff9-8678-4541-b9ec-7dc13fba7979',
      url: 'https://www.travelpass.com/guides/01J7XRKWZ011S1?name=Utah-Jazz-Away-Game-Guide-To-Portland%2C-OR',
    },
  },
  {
    team: 'Sacramento Kings',
    city: 'Sacramento',
    cityImg: `${imgPath}/sacramento-california.webp`,
    state: 'CA',
    arena: 'Golden 1 Center',
    guide: {
      id: '01J7KMTF5RM161',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Sacramento, CA',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/48df2e84-ef3e-4792-8912-0c2ba12fa0f8',
      url: 'https://www.travelpass.com/guides/01J7KMTF5RM161?name=Utah-Jazz-Away-Game-Guide-To-Sacramento%2C-CA',
    },
  },
  {
    team: 'San Antonio Spurs',
    city: 'San Antonio',
    cityImg: `${imgPath}/san-antonio-texas.webp`,
    state: 'TX',
    arena: 'Frost Bank Center',
    guide: {
      id: '01J7HFFX56MRX0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To San Antonio, TX',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/c672da5e-68f1-4f53-94f5-db918b05d0b9',
      url: 'https://www.travelpass.com/guides/01J7HFFX56MRX0?name=Utah-Jazz-Away-Game-Guide-To-San-Antonio%2C-TX',
    },
  },
  {
    team: 'Toronto Raptors',
    city: 'Toronto',
    cityImg: `${imgPath}/toronto-canada.webp`,
    state: 'ON',
    arena: 'Scotiabank Arena',
    guide: {
      id: '01J8JEYMA903T0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Toronto, ON',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/27d78cfb-169f-450f-9a63-b7947d64bf77',
      url: 'https://www.travelpass.com/guides/01J8JEYMA903T0?name=Utah-Jazz-Away-Game-Guide-To-Toronto%2C-ON',
    },
  },
  {
    team: 'Washington Wizards',
    city: 'Washington',
    cityImg: `${imgPath}/washington-dc.webp`,
    state: 'DC',
    arena: 'Capital One Arena',
    guide: {
      id: '01J8G8ZM6DQTS0',
      owner: {
        name: `${guideOwner}`,
        profileImage: `${guideProfileImage}`,
      },
      title: 'Utah Jazz Away Game Guide To Washington, D.C.',
      image:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J1R301SDZ70N0RMK39SZK4HP/guide_images/3edf9e67-d25d-4b42-9a8a-4f843de72ecb',
      url: 'https://www.travelpass.com/guides/01J8G8ZM6DQTS0?name=Jazz-Away-Game-Guide-To-Washington-D-C',
    },
  },
]
