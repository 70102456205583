import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'

export const GET_EXPERIENCE_PRODUCT_DETAILS_QUERY = gql(`
  query GetExperienceProductDetails($imageLimit: Int, $nodeId: ID!) {
    node(id: $nodeId) {
      ... on ExperienceProduct {
        id
        active
        additionalInfo {
          description
          type
        }
        # @todo: check if this is actually correct/necessary as it triggers another query without cache
        availabilitySchedule {
          bookableItems {
            seasons {
              startDate
              endDate
              operatingHours {
                dayOfWeek
                operatingHours {
                  closesAt
                  opensAt
                }
              }
            }
          }
        }
        bookingRequirements {
          maxTravelersPerBooking
          minTravelersPerBooking
          requiresAdultForBooking
        }
        cancellationPolicy {
          description
          type
        }
        description
        destinations {
          destinationName
          latitude
          longitude
        }
        exclusions {
          description
        }
        images {
          # This is an arbitrary number, however, querying the database shows that under 300 products have more than 60 images
          size240x160(limit: $imageLimit)
          size360x240(limit: $imageLimit)
          size540x360(limit: $imageLimit)
          size674x446(limit: $imageLimit)
          size720x480(limit: $imageLimit)
        }
        inclusions {
          description
        }
        itinerary {
          ... on ExperienceActivityItinerary {
            duration {
              readableDurationRange
            }
            foodMenus {
              course
              dishName
              dishDescription
            }
            itineraryType
            unstructuredDescription
          }
          ... on ExperienceHopOnHopOffItinerary {
            duration {
              readableDurationRange
            }
            itineraryType
            routes {
              duration {
                readableDurationRange
              }
              name
              operatingSchedule
              pointsOfInterest {
                location {
                  name
                }
              }
              stops {
                description
                stopLocation {
                  name
                }
              }
            }
            unstructuredDescription
          }
          ... on ExperienceStandardItinerary {
            duration {
              readableDurationRange
            }
            itineraryItems {
              description
              duration {
                readableDurationRange
              }
              passByWithoutStopping
              pointOfInterestLocation {
                location {
                  name
                }
              }
            }
            itineraryType
            unstructuredDescription
          }
          ... on ExperienceUnstructuredItinerary {
            itineraryType
            unstructuredDescription
            unstructuredItinerary
          }
        }
        languageGuides {
          language
          type
        }
        logistics {
          redemption {
            specialInstructions
            locations {
              address
              city
              name
              postalCode
              reference
              state
            }
          }
          travelerPickup {
            additionalInfo
            locations {
              location {
                address
                city
                name
                postalCode
                reference
                state
              }
              pickupType
            }
          }
          start {
            description
            location {
              address
              city
              name
              postalCode
              reference
              state
            }
          }
          end {
            description
            location {
              address
              city
              name
              postalCode
              reference
              state
            }
          }
        }
        pricingInfo {
          ageBands {
            ageBand
            startAge
            endAge
            maxTravelersPerBooking
            minTravelersPerBooking
          }
          type
          unitType
        }
        productCode
        productOptions {
          productOptionCode
          description
          title
        }
        reviews {
          combinedAverageRating
          sources {
            provider
            totalCount
          }
          totalReviews
        }
        ticketInfo {
          ticketTypeDescription
        }
        title
        isFavorited
        isCollected
      }
    }
  }
`)

export const useExperienceProductDetails = (imageLimit: number = 60) => {
  const { experienceId } = useParams()

  const { data, loading, error } = useQuery(
    GET_EXPERIENCE_PRODUCT_DETAILS_QUERY,
    {
      skip: !experienceId,
      variables: {
        imageLimit: imageLimit,
        nodeId: experienceId,
      },
    }
  )

  const node = data?.node?.__typename === 'ExperienceProduct' ? data.node : null
  return {
    data: node,
    loading,
    error,
  }
}
