import { Button, Carousel } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { PageLayoutContainer } from 'src/common/components'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocationBestAreas = ({
  destination,
}: DestinationLocationProps) => {
  const { address, bestAreas } = destination ?? {}
  const { city, state } = address ?? {}
  const title = `The best areas to visit in ${city}`

  return !isEmpty(bestAreas) ? (
    <div className='bg-center-top p-t-20 p-b-10 block w-full bg-white bg-[url(https://static.travelpass.com/assets/home-page/mountain.webp)] bg-[length:auto_809px] bg-repeat-x md:bg-[length:auto_480px]'>
      <PageLayoutContainer>
        <div className='lg:flex-items-start block lg:flex'>
          <div className='lg:w-20% lg:m-r-5% block w-full'>
            <div className='type-h1 p-b-3 lg:p-t-14 lg:p-b-9'>Where to go</div>
            <h2 className='type-subtitle-2 lg:line-height-6 color-grey-700'>
              {title}
            </h2>
          </div>
          <div className='lg:w-75% block w-full'>
            <Carousel
              size='medium'
              slidesPerViewOnDesktop={2}
              slidesPerViewOnMobile={1}
              slidesPerViewOnTablet={2}
            >
              {bestAreas?.map(({ area, summary, image, imageAlt, link }) => (
                <div
                  key={area}
                  className='position-relative w-85% md:w-97% lg:h-400px sm:h-320px m-x-a m-y-10 border-rounded-1 block overflow-hidden bg-transparent shadow-lg'
                >
                  <div className='w-100% h-100% position-absolute m-0 block overflow-auto'>
                    <img
                      alt={imageAlt ? imageAlt : `${area}, ${city}, ${state}`}
                      className='block h-full w-full object-cover'
                      src={image}
                    />
                  </div>
                  <div className='p-x-7 h-100% position-absolute bg-forest-dark bottom-0 left-0 right-0 block opacity-50'></div>
                  <div className='p-x-7 h-90% m-t-10% position-absolute z-index-99 bottom-0 block'>
                    <h3 className='type-h3 color-white'>{area}</h3>
                    <p className='type-body-1 p-t-10 m-b-6 color-grey-100'>
                      {summary}
                    </p>
                    {link && (
                      <div className='position-absolute bottom-4 right-5 w-auto lg:bottom-6'>
                        <Button
                          endIcon='chevronRight'
                          formAction={link}
                          formTarget=''
                          label={`More about ${area}`}
                          type='submit'
                          onClick={() => window.open(`${link}`)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  ) : null
}
