import { Divider } from '@travelpass/design-system'
import { BookingTimeState } from 'src/__generated__/graphql'
import { HotelBookingsApollo } from './HotelBookingsApollo'

export const HotelBookings = () => (
  <div className='flex flex-col gap-6'>
    <HotelBookingsApollo bookingStatus={BookingTimeState.Upcoming} />
    <Divider />
    <HotelBookingsApollo bookingStatus={BookingTimeState.Past} />
    <Divider />
    <HotelBookingsApollo bookingStatus={BookingTimeState.Cancelled} />
  </div>
)
