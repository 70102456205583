import { useSearchParams } from 'react-router-dom'

export enum ExperiencesDetailsSearchParams {
  arrival = 'arrival',
  departure = 'departure',
  eventId = 'eventId',
  guests = 'guests',
  pickupPoint = 'pickupPoint',
  token = 'token',
  tripId = 'tripId',
  travelDate = 'travelDate',
}

export const useExperienceDetailsSearchParams = () => {
  const [searchParams] = useSearchParams()

  return {
    /** @desc startDate is used to query for AvailabilitySchedules and PricesPerDay **/
    startDate: searchParams.get(ExperiencesDetailsSearchParams.arrival),
    /** @desc endDate is used to query for AvailabilitySchedules and PricesPerDay */
    endDate: searchParams.get(ExperiencesDetailsSearchParams.departure),
    /** @desc eventId is used to query for ExperienceDetails */
    eventId: searchParams.get(ExperiencesDetailsSearchParams.eventId),
    /**
     * @desc pickupPoint is used to query for ExperienceDetails
     * @deprecated was used in previous implementation of Details page, new UI does not allow user to select
     */
    pickupPoint: searchParams.get(ExperiencesDetailsSearchParams.pickupPoint),
    /** @desc guests are a JSON-encoded represenation of a Viator PaxMix; used to query for AvailabilitySchedules and PricesPerDay */
    guests: searchParams.get(ExperiencesDetailsSearchParams.guests),
    /** @desc travelDate is used to create a ExperienceBookingHold for a specific date/time/option */
    travelDate: searchParams.get(ExperiencesDetailsSearchParams.travelDate),
    /** @desc token represents an ExperienceBookingHold flowStateId, to be consumed by experiences/Checkout page */
    token: searchParams.get(ExperiencesDetailsSearchParams.token),
  }
}
