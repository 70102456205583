import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { AvailabilityCheckArgs } from 'src/__generated__/graphql'

const GET_EXPERIENCE_AVAILABILITY_CHECK = gql(`
  query GetExperienceAvailabilityCheck($availabilityCheckArgs: AvailabilityCheckArgs!) {
    availabilityCheck(availabilityCheckArgs: $availabilityCheckArgs) {
      currency
      groupedBookableItems {
        bookableItems {
          available
          bookingFlowToken
          lineItems {
            numberOfTravelers
            subtotalPrice {
              price {
                partnerNetPrice {
                  amount
                }
                recommendedRetailPrice {
                  amount
                }
              }
            }
          }
          startTime
          totalPrice {
            price {
              grandtotal {
                amount
              }
              recommendedRetailPrice {
                amount
              }
            }
          }
        }
        productOptionCode
      }
      productCode
      travelDate
    }
  }
`)

export const useGetExperienceAvailabilityCheck = ({
  paxMix,
  productCode,
  tracker,
  travelDate,
}: AvailabilityCheckArgs) =>
  useQuery(GET_EXPERIENCE_AVAILABILITY_CHECK, {
    variables: {
      availabilityCheckArgs: {
        paxMix,
        productCode,
        tracker,
        travelDate,
      },
    },
    skip: !paxMix || !productCode || !tracker || !travelDate,
  })
