import { EventType, RefundType } from 'src/__generated__/graphql'
import { initialAdultGuests } from 'src/constants/user'
import type { ExperienceEvent } from './experienceTypes'
import { defaultDrawerEvent } from '../../constants'

const defaultExperienceEvent: ExperienceEvent = {
  ...defaultDrawerEvent,
  bookingConfirmation: null,
  bookingDetailsUrl: null,
  bookingVoucherUrl: null,
  description: 'Description failed to load',
  duration: null,
  externalUrl: 'Hotel Details failed to load',
  guests: initialAdultGuests,
  hasMobileTicket: false,
  languages: null,
  nightlyAverage: null,
  phone: 'Phone failed to load',
  productDetailsUrl: null,
  rating: 0,
  refundType: RefundType.None,
  totalReviews: 0,
  type: EventType.Experience,
}

export { defaultExperienceEvent }
