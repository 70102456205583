const cityImages =
  'https://static.travelpass.com/assets/landing-pages/outdoor-adventures/'

const outdoorAdventuresLocations = [
  {
    image: `${cityImages}kauai-hawaii.webp`,
    city: 'Kauai',
    state: 'Hawaii',
    description:
      'Kauai, Hawaii, offers some incredible outdoor adventures, from hiking the rugged, emerald cliffs of the Na Pali Coast to kayaking down the serene Wailua River, surrounded by lush tropical forests. Thrill-seekers and nature enthusiasts alike will fall in love with Kauai’s diverse landscapes, whether through exploring the depths of Waimea Canyon or snorkeling in the crystal-clear waters of Tunnels Beach!',
    urlData: [
      {
        latitude: '22.0946',
        longitude: '-159.5261',
      },
    ],
  },
  {
    image: `${cityImages}southern-utah.webp`,
    city: 'Southern Utah',
    state: '',
    description:
      'Southern Utah is an adventurer’s playground, boasting dramatic landscapes like the sprawling red rock formations of Bryce Canyon and the intricate arches and canyons of Arches National Park. From the challenging backcountry trails of Zion National Park to the off-road escapades in Moab, the region is one thrilling outdoor activity after another set against a backdrop of some of the most stunning natural beauty the USA has to offer.',
    urlData: [
      {
        latitude: '37.16983921399318',
        longitude: '-113.23240204726417',
      },
    ],
  },
  {
    image: `${cityImages}sedona-arizona.webp`,
    city: 'Sedona',
    state: 'Arizona',
    description:
      'Sedona, Arizona, is an outdoor enthusiasts’ paradise with its iconic red rock buttes and mesas, offering tons of trails for hiking and mountain biking that provide panoramic views and encounters with vibrant desert flora. The area is also renowned for its off-road jeep tours, which will take you through rugged landscapes and bring travelers up close to geological wonders and energy-vortex sites. Crystal readings, anyone?',
    urlData: [
      {
        latitude: '34.8697',
        longitude: '-111.7610',
      },
    ],
  },
  {
    image: `${cityImages}san-francisco-california.webp`,
    city: 'San Francisco',
    state: 'California',
    description:
      'San Francisco, California, presents a unique blend of urban and natural outdoor adventures, from biking across the iconic Golden Gate Bridge to exploring the vast trails and historic sites of the Presidio. The city’s coastal location also invites a range of water-based activities, such as sailing on the bay, surfing at Ocean Beach, or kayaking near the marina with views of Alcatraz Island. It’s impossible to get bored in this beautiful city!',
    urlData: [
      {
        latitude: '37.7749',
        longitude: '-122.4194',
      },
    ],
  },
  {
    image: `${cityImages}new-orleans-louisiana.webp`,
    city: 'New Orleans',
    state: 'Louisiana',
    description:
      'For those looking for a truly unique outdoor adventure experience, New Orleans, Louisiana is a must. With our tours, you can wander through the mysterious and moss-draped bayous, where wildlife abounds. Or, beyond the city’s famous jazz-filled streets, travelers can embark on fishing expeditions in the Gulf of Mexico or paddle along the peaceful waterways of the Louisiana wetlands.',
    urlData: [
      {
        latitude: '29.9511',
        longitude: '-90.0715',
      },
    ],
  },
  {
    image: `${cityImages}denver-colorado.webp`,
    city: 'Denver',
    state: 'Colorado',
    description:
      'Are you a mountain lover? Well then add Denver, Colorado, to your bucket list! Providing ample opportunities for hiking, rock climbing, and mountain biking against a backdrop of breathtaking Rocky Mountain vistas, you’ll be breathing in clear air and pure adventure. In the winter, the surrounding peaks become powdery paradise for skiers and snowboarders, while the city’s proximity to rivers and lakes makes it a favorite for kayakers and anglers year-round.',
    urlData: [
      {
        latitude: '39.7392',
        longitude: '-104.9903',
      },
    ],
  },
  {
    image: `${cityImages}orlando-florida.webp`,
    city: 'Orlando',
    state: 'Florida',
    description:
      'Looking for a break from the theme parks? Orlando, Florida is also a hub for outdoor adventures such as airboat tours across the headwaters of the Everglades, where you can hardly go a few feet without spotting alligators and incredible birdlife. The city’s numerous lakes offer serene kayaking and paddleboarding experiences, while its proximity to Florida’s coasts allows for quick escapes to world-class beaches and snorkeling adventures.',
    urlData: [
      {
        latitude: '28.5384',
        longitude: '-81.3789',
      },
    ],
  },
  {
    image: `${cityImages}myrtle-beach-south-carolina.webp`,
    city: 'Myrtle Beach',
    state: 'South Carolina',
    description:
      'Myrtle Beach, South Carolina, is a favorite destination for beachgoers and water sports enthusiasts, offering everything from parasailing and jet skiing to leisurely kayaking in the Atlantic waters. Beyond the sandy shores, adventurers can explore the area’s lush state parks, where hiking trails wind through maritime forests and over dunes, revealing stunning coastal vistas.',
    urlData: [
      {
        latitude: '33.6954',
        longitude: '-78.8802',
      },
    ],
  },
]

export { outdoorAdventuresLocations }
