import { useMemo, useState } from 'react'
import { Link } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type { ExperienceUnstructuredItinerary } from 'src/__generated__/graphql'

interface ExperiencesItineraryOverviewProps
  extends Partial<ExperienceUnstructuredItinerary> {
  size?: 'small' | 'medium'
}

type Descriptions = string[]

export const ExperiencesItineraryOverview = ({
  size = 'medium',
  unstructuredDescription,
  unstructuredItinerary,
}: ExperiencesItineraryOverviewProps) => {
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false)

  const descriptions = useMemo<Descriptions>(() => {
    const updatedDescriptions = []

    if (unstructuredDescription)
      updatedDescriptions.push(unstructuredDescription)

    if (unstructuredItinerary) updatedDescriptions.push(unstructuredItinerary)

    return updatedDescriptions
  }, [])

  const showOverview = !isEmpty(descriptions)

  return (
    showOverview && (
      <div className={experiencesTimelineOverviewCss({ size })}>
        <div
          className={experiencesTimelineOverviewContentCss({
            isOverviewExpanded,
            size,
          })}
        >
          {descriptions?.map((description, index) => (
            <p key={index}>{description}</p>
          ))}
        </div>
        <div className='hide-on-print flex flex-row justify-center md:justify-start'>
          <Link
            aria-pressed={!!isOverviewExpanded}
            label={isOverviewExpanded ? 'Read Less' : 'Read More'}
            onClick={() => setIsOverviewExpanded(!isOverviewExpanded)}
          />
        </div>
      </div>
    )
  )
}

const experiencesTimelineOverviewCss = ({
  size,
}: Partial<ExperiencesItineraryOverviewProps>) =>
  classNames({
    'flex flex-col gap-4 md:gap-8': size === 'small',
    'flex flex-col gap-6 md:gap-12': size !== 'small',
  })

const experiencesTimelineOverviewContentCss = ({
  isOverviewExpanded = false,
  size,
}: {
  isOverviewExpanded: boolean
  size: ExperiencesItineraryOverviewProps['size']
}) =>
  classNames('color-grey-800 flex flex-col gap-4', {
    'line-clamp-5': !isOverviewExpanded,
    'md:text-body-2': size === 'small',
  })
