import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_HOTEL_TO_GUIDE = gql(` 
    mutation AddHotelToGuide($input: AddHotelToGuideInput!){
        addHotelToGuide(input: $input) {
            guideDraft {
                id
                name
                publishedEvents {
                    id
                    externalTypeId
                    productId
                }
            }
        }
    }    
`)

export const useAddHotelToGuide = (options?) =>
  useMutation(ADD_HOTEL_TO_GUIDE, options)
