import type { ReactNode } from 'react'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import { Icon } from '@travelpass/design-system'
import type { DestinationType } from '../../destinationConstants'

interface DestinationFaqCardProps {
  destination: DestinationType
}

export const DestinationFaqCard = ({
  destination,
}: DestinationFaqCardProps) => {
  const { faqs } = destination ?? {}

  return (
    <>
      {faqs.map(questionItem => (
        <AccordionItem key={`${questionItem.question}`}>
          <AccordionButton className='flex-items-center flex-justify-between h-48px p-16px m-b-1px flex w-full cursor-pointer border-0 bg-white text-left'>
            <div className='type-h5 color-forest-light'>
              {questionItem.question}
            </div>
            <Icon name='expandMore' />
          </AccordionButton>
          <AccordionPanel>
            <div className='p-t-12px p-b-24px p-x-16px flex flex-col gap-4'>
              <div className='type-body-1 color-grey-800'>
                {questionItem.answer}
              </div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </>
  )
}
