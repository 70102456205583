const awayGameSchedule = [
  {
    month: 'October 2024',
    games: [
      {
        game: '1',
        teamName: 'Dallas Mavericks',
        colors: { primary: '#00538c', secondary: '#b8c4ca' },
        city: 'Dallas',
        state: 'TX',
        date: 'Mon, Oct 28',
        fullDate: 'Monday, October 28, 2024',
        time: '6:30 PM MST',
        latitude: '32.790556',
        longitude: '-96.810278',
        arrival: '2024-10-27',
        departure: '2024-10-29',
      },
    ],
  },
  {
    month: 'November 2024',
    games: [
      {
        game: '1',
        teamName: 'Denver Nuggets',
        colors: { primary: '#0e2240', secondary: '#fec524' },
        city: 'Denver',
        state: 'CO',
        date: 'Sat, Nov 2',
        fullDate: 'Saturday, November 2, 2024',
        time: '8:00 PM MST',
        latitude: '39.748611',
        longitude: '-105.0075',
        arrival: '2024-11-01',
        departure: '2024-11-03',
      },
      {
        game: '2',
        teamName: 'Chicago Bulls',
        colors: { primary: '#ce1141', secondary: '#000000' },
        city: 'Chicago',
        state: 'IL',
        date: 'Mon, Nov 4',
        fullDate: 'Monday, November 4, 2024',
        time: '6:30 PM MST',
        latitude: '41.880556',
        longitude: '-87.674167',
        arrival: '2024-11-03',
        departure: '2024-11-05',
      },
      {
        game: '3',
        teamName: 'Milwaukee Bucks',
        colors: { primary: '#00471b', secondary: '#eee1c6' },
        city: 'Milwaukee',
        state: 'WI',
        date: 'Thurs, Nov 7',
        fullDate: 'Thursday, November 7, 2024',
        time: '6:00 PM MST',
        latitude: '43.045028',
        longitude: '-87.918167',
        arrival: '2024-11-06',
        departure: '2024-11-08',
      },
      {
        game: '4',
        teamName: 'San Antonio Spurs',
        colors: { primary: '#c4ced4', secondary: '#000000' },
        city: 'San Antonio',
        state: 'TX',
        date: 'Sat, Nov 9',
        fullDate: 'Saturday, November 9, 2024',
        time: '3:00 PM MST',
        latitude: '29.424832213231575',
        longitude: '-98.49160898042439',
        arrival: '2024-11-08',
        departure: '2024-11-10',
      },
      {
        game: '5',
        teamName: 'Sacramento Kings',
        colors: { primary: '#5a2d81', secondary: '#63727a' },
        city: 'Sacramento',
        state: 'CA',
        date: 'Sat, Nov 16',
        fullDate: 'Saturday, November 16, 2024',
        time: '8:00 PM MST',
        latitude: '38.580361',
        longitude: '-121.499611',
        arrival: '2024-11-15',
        departure: '2024-11-17',
      },
      {
        game: '6',
        teamName: 'Los Angeles Clippers',
        colors: { primary: '#c8102e', secondary: '#63727a' },
        city: 'Los Angeles',
        state: 'CA',
        date: 'Sun, Nov 17',
        fullDate: 'Sunday, November 17, 2024',
        time: '7:00 PM MST',
        latitude: '33.9451',
        longitude: '-118.3431',
        arrival: '2024-11-16',
        departure: '2024-11-18',
      },
      {
        game: '7',
        teamName: 'Los Angeles Lakers',
        colors: { primary: '#552583', secondary: '#fdb927' },
        city: 'Los Angeles',
        state: 'CA',
        date: 'Tues, Nov 19',
        fullDate: 'Tuesday, November 19, 2024',
        time: '8:30 PM MST',
        latitude: '34.043056',
        longitude: '-118.267222',
        arrival: '2024-11-18',
        departure: '2024-11-20',
      },
      {
        game: '8',
        teamName: 'San Antonio Spurs',
        colors: { primary: '#c4ced4', secondary: '#000000' },
        city: 'San Antonio',
        state: 'TX',
        date: 'Thurs, Nov 21',
        fullDate: 'Thursday, November 21, 2024',
        time: '6:00 PM MST',
        latitude: '29.424832213231575',
        longitude: '-98.49160898042439',
        arrival: '2024-11-20',
        departure: '2024-11-22',
      },
    ],
  },
  {
    month: 'December 2024',
    games: [
      {
        game: '1',
        teamName: 'Oklahoma City Thunder',
        colors: { primary: '#007ac1', secondary: '#ef3b24' },
        city: 'Oklahoma City',
        state: 'OK',
        date: 'Tues, Dec 3',
        fullDate: 'Tuesday, December 3, 2024',
        time: '6:00 PM MST',
        latitude: '35.463333',
        longitude: '-97.515',
        arrival: '2024-12-02',
        departure: '2024-12-04',
      },
      {
        game: '2',
        teamName: 'Portland Trail Blazers',
        colors: { primary: '#e03a3e', secondary: '#000000' },
        city: 'Portland',
        state: 'OR',
        date: 'Fri, Dec 6',
        fullDate: 'Friday, December 6, 2024',
        time: '8:00 PM MST',
        latitude: '45.531667',
        longitude: '-122.666667',
        arrival: '2024-12-05',
        departure: '2024-12-07',
      },
      {
        game: '3',
        teamName: 'Sacramento Kings',
        colors: { primary: '#5a2d81', secondary: '#63727a' },
        city: 'Sacramento',
        state: 'CA',
        date: 'Sun, Dec 8',
        fullDate: 'Sunday, December 8, 2024',
        time: '7:00 PM MST',
        latitude: '38.580361',
        longitude: '-121.499611',
        arrival: '2024-12-07',
        departure: '2024-12-09',
      },
      {
        game: '4',
        teamName: 'Detroit Pistons',
        colors: { primary: '#c8102e', secondary: '#1d42ba' },
        city: 'Detroit',
        state: 'MI',
        date: 'Thurs, Dec 19',
        fullDate: 'Thursday, December 19, 2024',
        time: '5:00 PM MST',
        latitude: '42.341111',
        longitude: '-83.055',
        arrival: '2024-12-18',
        departure: '2024-12-20',
      },
      {
        game: '5',
        teamName: 'Brooklyn Nets',
        colors: { primary: '#000000', secondary: '#000000' },
        city: 'Brooklyn',
        state: 'NY',
        date: 'Sat, Dec 21',
        fullDate: 'Saturday, December 21, 2024',
        time: '5:30 PM MST',
        latitude: '40.682661',
        longitude: '-73.975225',
        arrival: '2024-12-20',
        departure: '2024-12-22',
      },
      {
        game: '6',
        teamName: 'Cleveland Cavaliers',
        colors: { primary: '#860038', secondary: '#fdbb30' },
        city: 'Cleveland',
        state: 'OH',
        date: 'Mon, Dec 23',
        fullDate: 'Monday, December 23, 2024',
        time: '5:00 PM MST',
        latitude: '41.496389',
        longitude: '-81.688056',
        arrival: '2024-12-22',
        departure: '2024-12-24',
      },
      {
        game: '7',
        teamName: 'Portland Trail Blazers',
        colors: { primary: '#e03a3e', secondary: '#000000' },
        city: 'Portland',
        state: 'OR',
        date: 'Thurs, Dec 26',
        fullDate: 'Thursday, December 26, 2024',
        time: '8:00 PM MST',
        latitude: '45.531667',
        longitude: '-122.666667',
        arrival: '2024-12-25',
        departure: '2024-12-27',
      },
    ],
  },
  {
    month: 'January 2025',
    games: [
      {
        game: '1',
        teamName: 'New York Knicks',
        colors: { primary: '#1d428a', secondary: '#f58426' },
        city: 'New York',
        state: 'NY',
        date: 'Wed, Jan 1',
        fullDate: 'Wednesday, January 1, 2025',
        time: '5:30 PM MST',
        latitude: '40.750556',
        longitude: '-73.993611',
        arrival: '2024-12-31',
        departure: '2025-01-02',
      },
      {
        game: '2',
        teamName: 'Miami Heat',
        colors: { primary: '#98002e', secondary: '#f9a01b' },
        city: 'Miami',
        state: 'FL',
        date: 'Sat, Jan 4',
        fullDate: 'Saturday, January 4, 2025',
        time: '6:00 PM MST',
        latitude: '25.781389',
        longitude: '-80.188056',
        arrival: '2025-01-03',
        departure: '2025-01-05',
      },
      {
        game: '3',
        teamName: 'Orlando Magic',
        colors: { primary: '#0077c0', secondary: '#000000' },
        city: 'Orlando',
        state: 'FL',
        date: 'Sun, Jan 5',
        fullDate: 'Sunday, January 5, 2025',
        time: '4:30 PM MST',
        latitude: '28.539167',
        longitude: '-81.383611',
        arrival: '2025-01-04',
        departure: '2025-01-06',
      },
      {
        game: '4',
        teamName: 'Phoenix Suns',
        colors: { primary: '#1d1160', secondary: '#e56020' },
        city: 'Phoenix',
        state: 'AZ',
        date: 'Sat, Jan 11',
        fullDate: 'Saturday, January 11, 2025',
        time: '3:00 PM MST',
        latitude: '33.445833',
        longitude: '-112.071389',
        arrival: '2025-01-10',
        departure: '2025-01-12',
      },
      {
        game: '5',
        teamName: 'New Orleans Pelicans',
        colors: { primary: '#0c2340', secondary: '#c8102e' },
        city: 'New Orleans',
        state: 'LA',
        date: 'Fri, Jan 17',
        fullDate: 'Friday, January 17, 2025',
        time: '6:00 PM MST',
        latitude: '29.948889',
        longitude: '-90.081944',
        arrival: '2025-01-16',
        departure: '2025-01-18',
      },
      {
        game: '6',
        teamName: 'New Orleans Pelicans',
        colors: { primary: '#0c2340', secondary: '#c8102e' },
        city: 'New Orleans',
        state: 'LA',
        date: 'Mon, Jan 20',
        fullDate: 'Monday, January 20, 2025',
        time: '6:00 PM MST',
        latitude: '29.948889',
        longitude: '-90.081944',
        arrival: '2025-01-19',
        departure: '2025-01-21',
      },
      {
        game: '7',
        teamName: 'Oklahoma City Thunder',
        colors: { primary: '#007ac1', secondary: '#ef3b24' },
        city: 'Oklahoma City',
        state: 'OK',
        date: 'Wed, Jan 22',
        fullDate: 'Wednesday, January 22, 2025',
        time: '6:00 PM MST',
        latitude: '35.463333',
        longitude: '-97.515',
        arrival: '2025-01-21',
        departure: '2025-01-23',
      },
      {
        game: '8',
        teamName: 'Memphis Grizzlies',
        colors: { primary: '#5d76a9', secondary: '#12173f' },
        city: 'Memphis',
        state: 'TN',
        date: 'Sat, Jan 25',
        fullDate: 'Saturday, January 25, 2025',
        time: '6:00 PM MST',
        latitude: '35.138333',
        longitude: '-90.050556',
        arrival: '2025-01-24',
        departure: '2025-01-26',
      },
      {
        game: '9',
        teamName: 'Golden State Warriors',
        colors: { primary: '#1d428a', secondary: '#fab927' },
        city: 'San Francisco',
        state: 'CA',
        date: 'Tues, Jan 28',
        fullDate: 'Tuesday, January 28, 2025',
        time: '8:00 PM MST',
        latitude: '37.768056',
        longitude: '-122.3875',
        arrival: '2025-01-27',
        departure: '2025-01-29',
      },
    ],
  },
  {
    month: 'February 2025',
    games: [
      {
        game: '1',
        teamName: 'Phoenix Suns',
        colors: { primary: '#1d1160', secondary: '#e56020' },
        city: 'Phoenix',
        state: 'AZ',
        date: 'Fri, Feb 7',
        fullDate: 'Friday, February 7, 2025',
        time: '8:00 PM MST',
        latitude: '33.445833',
        longitude: '-112.071389',
        arrival: '2025-02-06',
        departure: '2025-02-08',
      },
      {
        game: '2',
        teamName: 'Los Angeles Clippers',
        colors: { primary: '#c8102E', secondary: '#1d428a' },
        city: 'Los Angeles',
        state: 'CA',
        date: 'Sat, Feb 8',
        fullDate: 'Saturday, Februrary 8, 2025',
        time: '8:30 PM MST',
        latitude: '33.9451',
        longitude: '-118.3431',
        arrival: '2025-02-07',
        departure: '2025-02-09',
      },
      {
        game: '3',
        teamName: 'Los Angeles Lakers',
        colors: { primary: '#552583', secondary: '#fdb927' },
        city: 'Los Angeles',
        state: 'CA',
        date: 'Tues, Feb 11',
        fullDate: 'Tuesday, Februrary 11, 2025',
        time: '8:30 PM MST',
        latitude: '34.043056',
        longitude: '-118.267222',
        arrival: '2025-02-10',
        departure: '2025-02-12',
      },
    ],
  },
  {
    month: 'March 2025',
    games: [
      {
        game: '1',
        teamName: 'Washington Wizards',
        colors: { primary: '#002b5c', secondary: '#e31837' },
        city: 'Washington',
        state: 'DC',
        date: 'Wed, Mar 5',
        fullDate: 'Wednesday, March 5, 2025',
        time: '5:00 PM MST',
        latitude: '38.898056',
        longitude: '-77.020833',
        arrival: '2025-03-04',
        departure: '2025-03-06',
      },
      {
        game: '2',
        teamName: 'Toronto Raptors',
        colors: { primary: '#ce1141', secondary: '#000000' },
        city: 'Toronto',
        state: 'ON',
        date: 'Fri, Mar 7',
        fullDate: 'Friday, March 7, 2025',
        time: '5:30 PM MST',
        latitude: '43.643333',
        longitude: '-79.379167',
        arrival: '2025-03-06',
        departure: '2025-03-08',
      },
      {
        game: '3',
        teamName: 'Philadelphia 76ers',
        colors: { primary: '#006bb6', secondary: '#ed174c' },
        city: 'Philadelphia',
        state: 'PA',
        date: 'Sun, Mar 9',
        fullDate: 'Sunday, March 9, 2025',
        time: '5:30 PM MST',
        latitude: '39.901111',
        longitude: '-75.171944',
        arrival: '2025-03-08',
        departure: '2025-03-10',
      },
      {
        game: '4',
        teamName: 'Boston Celtics',
        colors: { primary: '#007a33', secondary: '#ba9653' },
        city: 'Boston',
        state: 'MA',
        date: 'Mon, Mar 10',
        fullDate: 'Monday, March 10, 2025',
        time: '5:30 PM MST',
        latitude: '42.366303',
        longitude: '-71.062228',
        arrival: '2025-03-09',
        departure: '2025-03-11',
      },
      {
        game: '5',
        teamName: 'Memphis Grizzlies',
        colors: { primary: '#5d76a9', secondary: '#12173f' },
        city: 'Memphis',
        state: 'TN',
        date: 'Wed, Mar 12',
        fullDate: 'Wednesday, March 12, 2025',
        time: '6:00 PM MST',
        latitude: '35.138333',
        longitude: '-90.050556',
        arrival: '2025-03-11',
        departure: '2025-03-13',
      },
      {
        game: '6',
        teamName: 'Minnesota Timberwolves',
        colors: { primary: '#0c233f', secondary: '#266092' },
        city: 'Minneapolis',
        state: 'MN',
        date: 'Sun, Mar 16',
        fullDate: 'Sunday, March 16, 2025',
        time: '5:00 PM MST',
        latitude: '44.979444',
        longitude: '-93.276111',
        arrival: '2025-03-15',
        departure: '2025-03-17',
      },
      {
        game: '7',
        teamName: 'Denver Nuggets',
        colors: { primary: '#0e2240', secondary: '#fec524' },
        city: 'Denver',
        state: 'CO',
        date: 'Fri, Mar 28',
        fullDate: 'Friday, March 28, 2025',
        time: '7:00 PM MST',
        latitude: '39.748611',
        longitude: '-105.0075',
        arrival: '2025-03-27',
        departure: '2025-03-29',
      },
      {
        game: '8',
        teamName: 'Charlotte Hornets',
        colors: { primary: '#1d1160', secondary: '#00788c' },
        city: 'Charlotte',
        state: 'NC',
        date: 'Mon, Mar 31',
        fullDate: 'Monday, March 31, 2025',
        time: '5:00 PM MST',
        latitude: '35.225',
        longitude: '-80.839167',
        arrival: '2025-03-30',
        departure: '2025-04-01',
      },
    ],
  },
  {
    month: 'April 2025',
    games: [
      {
        game: '1',
        teamName: 'Houston Rockets',
        colors: { primary: '#ce1141', secondary: '#000000' },
        city: 'Houston',
        state: 'TX',
        date: 'Wed, Apr 2',
        fullDate: 'Wednesday, April 2, 2025',
        time: '6:00 PM MST',
        latitude: '29.750833',
        longitude: '-95.362222',
        arrival: '2025-04-01',
        departure: '2025-04-03',
      },
      {
        game: '2',
        teamName: 'Indiana Pacers',
        colors: { primary: '#002d62', secondary: '#fdbb30' },
        city: 'Indianapolis',
        state: 'IN',
        date: 'Fri, Apr 4',
        fullDate: 'Friday, April 4, 2025',
        time: '5:00 PM MST',
        latitude: '39.763889',
        longitude: '-86.155556',
        arrival: '2025-04-03',
        departure: '2025-04-05',
      },
      {
        game: '3',
        teamName: 'Atlanta Hawks',
        colors: { primary: '#e03a3e', secondary: '#26282a' },
        city: 'Atlanta',
        state: 'GA',
        date: 'Sun, Apr 6',
        fullDate: 'Sunday, April 6, 2025',
        time: '4:00 PM MST',
        latitude: '33.757222',
        longitude: '-84.396389',
        arrival: '2025-04-05',
        departure: '2025-04-07',
      },
      {
        game: '4',
        teamName: 'Minnesota Timberwolves',
        colors: { primary: '#0c233f', secondary: '#266092' },
        city: 'Minneapolis',
        state: 'MN',
        date: 'Sun, Apr 13',
        fullDate: 'Sunday, April 13, 2025',
        time: '1:30 PM MST',
        latitude: '44.979444',
        longitude: '-93.276111',
        arrival: '2025-04-12',
        departure: '2025-04-14',
      },
    ],
  },
]

export { awayGameSchedule }
