import { useEffect, useState } from 'react'
import {
  Icon,
  Input,
  Modal,
  ModalScrollContents,
  UnderlineTab,
  useScreenQuery,
} from '@travelpass/design-system'
import { FollowType } from 'src/__generated__/graphql'
import { FollowersList } from './FollowersList'
import type { FollowersModalType } from '../../types'
import { useSearchFollowUserProfiles } from '../hooks/useSearchFollowUserProfiles'

const getViewTypeIndex = (viewType: FollowersModalType) => {
  if (viewType === 'followers') return 0
  if (viewType === 'following') return 1
  return 0
}

interface FollowersModalProps {
  accountHandle: string
  onClose(): void
  toggleModal?: (viewType: FollowersModalType) => void
  userId: string
  viewType: FollowersModalType
}

export const FollowersModal = ({
  accountHandle,
  onClose,
  toggleModal,
  userId,
  viewType,
}: FollowersModalProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [searchString, setSearchString] = useState('')
  const [tabIndex, setTabindex] = useState(getViewTypeIndex(viewType))
  const followTypeSearch =
    tabIndex == 0 ? FollowType.Following : FollowType.Followed
  const {
    countOfFollowing,
    countOfFollowed,
    displayName,
    allFollowedUsers,
    allFollowingUsers,
    followArray,
    loading: isLoading,
    refetch,
  } = useSearchFollowUserProfiles({
    searchString,
    followTypeSearch,
    userId,
    accountHandle,
  })
  const tabs = [
    {
      label: `Followers ${countOfFollowing || 0}`,
      onClick: () => onTabClick('followers', 0),
    },
    {
      label: `Following ${countOfFollowed || 0}`,
      onClick: () => onTabClick('following', 1),
    },
  ]

  const getListToDisplay = () => {
    if (searchString.length >= 3) {
      return followArray
    } else if (tabIndex === 0) {
      return allFollowingUsers
    } else {
      return allFollowedUsers
    }
  }

  const onTabClick = (viewType: FollowersModalType, index) => {
    toggleModal(viewType)
    setTabindex(index)
    setSearchString('')
  }

  useEffect(() => {
    if (searchString) {
      refetch({ searchInput: { searchString, followTypeSearch, userId } })
    }
  }, [searchString, refetch, tabIndex])

  /** @todo refactor Modal to allow for dynamic ScrollContents like this */
  return (
    <Modal
      data-testid='followers-modal'
      size='medium'
      title={displayName}
      onDismiss={onClose}
    >
      <div className='flex flex-col space-y-6 overflow-y-hidden md:space-y-8'>
        <div className='lg:w-82.5 m-x-a w-full'>
          <UnderlineTab
            activeIndex={tabIndex}
            fullWidth={true}
            tabs={tabs}
            willScroll={isMobileScreen}
          />
        </div>
        <Input
          aria-label='Search'
          autoFocus={true}
          fullWidth={true}
          placeholder='Search'
          slotBefore={<Icon name='search' />}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
        <ModalScrollContents>
          <FollowersList
            emptyListMessage={
              tabIndex == 0
                ? "You don't have followers yet"
                : "You're not following anybody yet"
            }
            isLoading={isLoading}
            list={getListToDisplay()}
          />
        </ModalScrollContents>
      </div>
    </Modal>
  )
}
