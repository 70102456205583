import { Skeleton } from '@travelpass/design-system'
import { HotelCancellationBadgeLoading } from 'src/common/components/HotelPolicies'

interface BookingCardLoadingProps {
  cancelled?: boolean
  className?: string
}

export const BookingCardLoading = ({
  cancelled,
  className,
}: BookingCardLoadingProps) => (
  <div
    className={`rounded-20px shadow-2 rounded-2 relative flex flex-col bg-white text-inherit ${className}`}
    data-testid='booking-card-loading-skeleton'
  >
    <h3 className='sr-only'>booking loading card</h3>
    {/* image */}
    <div className='h-165px'>
      <Skeleton variant='rounded' />
    </div>
    {/* title, and  date */}
    <div className='min-h-21 space-y-2 p-4'>
      <div className='h-40px'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-18px'>
        <Skeleton variant='rounded' />
      </div>
      <div className='flex min-h-6 flex-row items-end justify-between'>
        {/* guests, rooms, cancellation */}
        <div className='flex flex-row gap-2'>
          <div className='h-16px w-80px md:w-100px'>
            <Skeleton variant='rounded' />
          </div>
          <div className='h-16px w-80px md:w-100px'>
            <Skeleton variant='rounded' />
          </div>
        </div>
        <div className='space-y-2 text-right'>
          {cancelled && <HotelCancellationBadgeLoading />}
        </div>
      </div>
    </div>
  </div>
)
