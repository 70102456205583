import { Button } from '@travelpass/design-system'
import { EventStatus } from 'src/__generated__/graphql'
import { useDeleteDrawerEvent } from '../../hooks'
import type { ExperienceEvent } from '../constants'

export const ExperienceActions = ({
  data,
}: {
  data: ExperienceEvent | null
}) => {
  const { deleteDrawerEvent } = useDeleteDrawerEvent()

  const isBooked = data?.status === EventStatus.Booked

  const onClick = (url?: string | null) => {
    if (url) {
      window.open(url, '_blank')
    }
  }
  const onDelete = () => {
    deleteDrawerEvent({
      eventId: data?.id,
      triggerVariant: 'TripExperienceDrawer DeleteButton',
    })
  }

  return (
    <div className='shadow-1 border-grey300 border-t-1 absolute sticky bottom-0 left-0 flex w-full flex-row items-center justify-center gap-4 border-0 border-solid bg-white px-8 py-6'>
      <Button
        fullWidth
        aria-label='Delete experience'
        startIcon='deleteOutline'
        variant='outlined'
        onClick={onDelete}
      >
        Delete
      </Button>
      {isBooked && data?.bookingDetailsUrl && (
        <Button
          fullWidth
          aria-label='View experience details'
          onClick={() => onClick(data?.bookingDetailsUrl)}
        >
          View Details
        </Button>
      )}
      {!isBooked && data?.productDetailsUrl && (
        <Button
          fullWidth
          aria-label='Book Experience'
          onClick={() => onClick(data?.productDetailsUrl)}
        >
          Book Experience
        </Button>
      )}
    </div>
  )
}
