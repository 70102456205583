import { SortBy } from 'src/__generated__/graphql'

type MenuItem = {
  label: string
  value: string
}

export const menuItems: MenuItem[] = [
  {
    label: 'Distance',
    value: SortBy.Distance,
  },
  {
    label: 'Hotel Star',
    value: SortBy.HotelStars,
  },
  {
    label: 'Guest Rating',
    value: SortBy.CustomerReviewScore,
  },
  {
    label: 'Price Low to High',
    value: SortBy.PriceLowToHigh,
  },
  {
    label: 'Price High to Low',
    value: SortBy.PriceHighToLow,
  },
  {
    label: 'Recommended',
    value: SortBy.Recommended,
  },
]
