import { useState } from 'react'
import {
  Avatar,
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
} from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'
import {
  GuideDraftEditModal,
  GuideDraftMenu,
} from 'src/pages/guides/lists/common'
import { GuideDraftShare } from 'src/pages/guides/lists/common/GuideDraftShare'
import {
  decodeGuideId,
  getGuideDraftDetailsUrl,
  getGuidePublishedDate,
} from 'src/utils'
import { GuideSearchParam } from '../../guideConstants'
import type { GuideData, GuideOwner } from '../../types'
import { GuideBadge } from '../GuideBadge'

interface GuideHeaderContentProps {
  guideData: GuideData
  isEdit: boolean
  isExpanded: boolean
  onIsExpandedChange?: VoidFunction
  owner: GuideOwner
}

export const GuideHeaderContent = ({
  guideData,
  isEdit,
  isExpanded,
  onIsExpandedChange,
  owner,
}: GuideHeaderContentProps) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const { description, insertedAt, name, status, timeZone, updatedAt } =
    guideData ?? {}
  const guideIdDecoded = decodeGuideId(guideData?.id)
  const guidePublishedDate = getGuidePublishedDate({
    insertedAt,
    timeZone,
    updatedAt,
  })

  const onMapClick = () => {
    searchParams.set(GuideSearchParam.mapFullView, 'true')
    setSearchParams(searchParams, { replace: true })
  }

  if (!guideData) return

  if (isEdit) {
    return (
      <>
        <div className='space-y-0 lg:space-y-6'>
          <div className='hidden flex-row justify-between gap-2 lg:flex'>
            <div className='flex flex-col gap-2'>
              <h1 className='type-h3-mobile line-clamp-1'>{guideData?.name}</h1>
              <div className='flex flex-row items-center gap-8'>
                <div className='[&>button]:b-0 [&>button]:bg-grey-200 [&>button]:rounded-1 w-fit'>
                  <GuideBadge
                    size='medium'
                    onClick={() => setIsModalShareOpen(true)}
                  >
                    <Icon
                      name={
                        status === GuideStatus.Published
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      size='small'
                    />
                    <span>
                      {status === GuideStatus.Published ? 'Public' : 'Private'}
                    </span>
                  </GuideBadge>
                </div>
                <div className='[&>button]:p-b-0'>
                  <Link
                    endIcon={isExpanded ? 'arrowUpIos' : 'arrowDownIos'}
                    onClick={onIsExpandedChange}
                  >
                    Guide Details
                  </Link>
                </div>
              </div>
            </div>
            {owner?.isUserOwner && <GuideDraftMenu guideData={guideData} />}
          </div>
          {isExpanded && (
            <div className='flex flex-col gap-5 md:flex-row md:gap-7'>
              <div className='flex min-w-fit flex-col gap-3 md:flex-row md:items-start md:gap-3.5'>
                <div className='flex flex-row items-center gap-3'>
                  <a
                    aria-label={`View profile of ${owner?.name}`}
                    className='transition-opacity-200 ease-linear hover:opacity-50 active:opacity-50'
                    href={owner?.url}
                    rel='noreferrer'
                    target='_BLANK'
                  >
                    <Avatar border='none' size='sm' src={owner?.image} />
                  </a>
                  <div className='space-y-1'>
                    <a
                      aria-label={`View profile of ${owner?.name}`}
                      className='transition-opacity-200 decoration-none type-body-2 block w-fit ease-linear hover:opacity-50 active:opacity-50'
                      href={owner?.url}
                      rel='noreferrer'
                      target='_BLANK'
                    >
                      {`By ${owner?.name}`}
                    </a>
                    <time className='type-small-text'>
                      {guidePublishedDate}
                    </time>
                  </div>
                </div>
                {description && (
                  <Divider className='w-1px m-0 hidden min-h-12 md:block' />
                )}
              </div>
              <div className='hidden overflow-hidden md:block'>
                <ReadMoreContentSection maxHeightClass='max-h-16'>
                  <p className='type-body-1 break-words'>{description}</p>
                </ReadMoreContentSection>
              </div>
              <div className='overflow-hidden break-words md:hidden'>
                <ReadMoreContentSection maxHeightClass='max-h-18.5'>
                  <p className='type-body-1'>{description}</p>
                </ReadMoreContentSection>
              </div>
            </div>
          )}
        </div>
        {isModalEditOpen && (
          <GuideDraftEditModal
            guideData={guideData}
            onDismiss={() => setIsModalEditOpen(false)}
          />
        )}
        {isModalShareOpen && (
          <GuideDraftShare
            guideData={guideData}
            onDismiss={() => setIsModalShareOpen(false)}
          />
        )}
      </>
    )
  }

  return (
    <div className='space-y-6'>
      <div className='flex flex-row justify-between gap-2'>
        <div className='flex flex-col gap-2'>
          <h1 className='type-h3-mobile line-clamp-1'>{name}</h1>
        </div>
        <div className='min-w-39 hidden flex-row items-center gap-3 md:flex'>
          <div className='lg:hidden'>
            <IconButton
              aria-label='View Guide Map'
              color='forestLight'
              icon='map'
              outline='round'
              size='medium'
              onClick={onMapClick}
            />
          </div>
          {owner?.isUserOwner && (
            <Button
              aria-label='Edit this Guide'
              color='transparent'
              size='small'
              startIcon='modeEdit'
              onClick={() =>
                navigate(
                  getGuideDraftDetailsUrl({
                    id: guideIdDecoded,
                    isUserOwner: owner?.isUserOwner,
                    name,
                  })
                )
              }
            >
              Edit Guide
            </Button>
          )}
        </div>
        <div className='flex flex-row items-center gap-3 md:hidden'>
          <IconButton
            aria-label='Open Guide map'
            color='forestLight'
            icon='map'
            outline='round'
            size='small'
            onClick={onMapClick}
          />
          {owner?.isUserOwner && (
            <IconButton
              aria-label='Edit this Guide'
              color='forestLight'
              icon='modeEdit'
              outline='round'
              size='small'
              onClick={() =>
                navigate(
                  getGuideDraftDetailsUrl({
                    id: guideData?.strippedId,
                    isUserOwner: owner?.isUserOwner,
                    name,
                  })
                )
              }
            />
          )}
        </div>
      </div>
      <div className='flex flex-col gap-5 md:flex-row md:gap-7'>
        <div className='flex min-w-fit flex-col gap-3 md:flex-row md:items-start md:gap-3.5'>
          <div className='flex flex-row items-center gap-3'>
            <a
              aria-label={`View profile of ${owner?.name}`}
              className='transition-opacity-200 ease-linear hover:opacity-50 active:opacity-50'
              href={owner?.url}
              rel='noreferrer'
              target='_BLANK'
            >
              <Avatar border='none' size='sm' src={owner?.image} />
            </a>
            <div className='space-y-1'>
              <a
                aria-label={`View profile of ${owner?.name}`}
                className='transition-opacity-200 decoration-none type-body-2 block w-fit ease-linear hover:opacity-50 active:opacity-50'
                href={owner?.url}
                rel='noreferrer'
                target='_BLANK'
              >
                {`By ${owner?.name}`}
              </a>
              <time className='type-small-text'>{guidePublishedDate}</time>
            </div>
          </div>
          {description && (
            <Divider className='w-1px m-0 hidden min-h-12 md:block' />
          )}
        </div>
        <div className='hidden overflow-hidden md:block'>
          <ReadMoreContentSection maxHeightClass='max-h-16'>
            <p className='type-body-1 break-words'>{description}</p>
          </ReadMoreContentSection>
        </div>
        <div className='overflow-hidden break-words md:hidden'>
          <ReadMoreContentSection maxHeightClass='max-h-18.5'>
            <p className='type-body-1'>{description}</p>
          </ReadMoreContentSection>
        </div>
      </div>
    </div>
  )
}
