import { EmptyState } from '@travelpass/design-system'

interface TripDetailsExploreEmptyProps {
  title?: string
}

const TripDetailsExploreEmpty = ({
  title = 'Unable to load results, please refresh the page.',
}: TripDetailsExploreEmptyProps) => (
  <div className='flex flex-col items-center text-center'>
    <div className='w-250px'>
      <EmptyState name='error' />
    </div>
    <div className='flex flex-col gap-4 lg:gap-8'>
      <p className='color-grey-800 type-body-1'>{title}</p>
    </div>
  </div>
)

export { TripDetailsExploreEmpty }
export type { TripDetailsExploreEmptyProps }
