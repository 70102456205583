import { Divider, Icon } from '@travelpass/design-system'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'

interface TripExploreHeaderProps {
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripExploreHeader = ({
  onPlacePointsChange,
  tripDetailsData,
}: TripExploreHeaderProps) => (
  <header className='space-y-6'>
    <div className='space-y-2'>
      <div className='space-y-1'>
        <div className='flex flex-row items-center gap-2'>
          {/** @todo replace this icon with bookmark when it has been added to the DS */}
          <Icon name='addFavorited' />
          <h1 className='type-h5-desktop'>Saved Ideas</h1>
        </div>
        <p className='type-small-text-desktop'>
          Save your favorites here for easy access later.
        </p>
      </div>
      <Divider />
    </div>
    {/** @todo TripExploreGeocoder */}
  </header>
)
