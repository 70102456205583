import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GuideCategory } from 'src/__generated__/graphql'

const GET_HOMEPAGE_GUIDES_QUERY = gql(`
  query getCuratedGuides($guideCategory: GuideCategory!){
    curatedGuides(guideCategory: $guideCategory){
      ...FeaturedGuidesFields
    }
  }
`)

export const useGetCuratedGuides = (guideCategory: GuideCategory) => {
  const { data, loading, error } = useQuery(GET_HOMEPAGE_GUIDES_QUERY, {
    variables: {
      guideCategory,
    },
    skip: !guideCategory,
  })

  return { data, loading, error }
}
