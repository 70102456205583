import { useEffect, useState } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import type { GetGuideDraftQueryInGuideDraftOldQuery } from 'src/__generated__/graphql'
import { onGuideSessionStorageGeocoderChange } from 'src/pages/guides/details'
import { stringToNumber } from 'src/utils'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'

interface GuideDraftMapEventsProps {
  data: GetGuideDraftQueryInGuideDraftOldQuery
  mapMarkerCenter: UseGetGuideDraftQuery['mapMarkerCenter']
}

export const GuideDraftMapEvents = ({
  data,
  mapMarkerCenter,
}: GuideDraftMapEventsProps) => {
  const map = useGoogleMap()
  const [hasFitBounds, setHasFitBounds] = useState(false)

  const debouncedHasFitBoundsChange = debounce(() => setHasFitBounds(true), 100)

  // When the map is idle, the map bounds/center in sessionStorage is updated
  useEffect(() => {
    const onIdleListener = map.addListener(
      'idle',
      debounce(() => {
        onGuideSessionStorageGeocoderChange({
          bounds: map.getBounds(),
          location: map.getCenter(),
        })
      }, 50)
    )

    return () => {
      onIdleListener.remove()
    }
  }, [map])

  // When hasFitBounds is true, the map bounds are updated
  useEffect(() => {
    if (!hasFitBounds && !isEmpty(data?.node)) {
      const events =
        (
          data?.node as GuideDraftDataOld
        )?.paginatedEventCategories?.edges?.flatMap(
          paginatedEventCategory =>
            paginatedEventCategory?.node?.publishedEvents?.edges
        ) ?? []
      const bounds = new google.maps.LatLngBounds()
      events?.forEach(event => {
        const [address] = event?.node?.addresses ?? []
        const { lat, long } = address ?? {}

        if (lat && long) {
          bounds.extend({
            lat: stringToNumber(lat),
            lng: stringToNumber(long),
          })
        }
      })

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, {
          bottom: 50,
          left: 50,
          right: 50,
          top: 150,
        })
      }

      debouncedHasFitBoundsChange()
    }
  }, [data, hasFitBounds, map])

  // When a marker or event is clicked, the map center is updated
  useEffect(() => {
    if (
      hasFitBounds &&
      map?.setCenter &&
      mapMarkerCenter?.lat &&
      mapMarkerCenter?.lng
    )
      map?.setCenter(mapMarkerCenter)
  }, [hasFitBounds, map?.setCenter, mapMarkerCenter?.lat, mapMarkerCenter?.lng])

  return <></>
}
