import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const deletePublishedEventCategoryMutation = gql(`
  mutation DeletePublishedEventCategoryMutationInGuideDraft($deleteCategoryInput: DeletePublishedEventCategoryInput!) {
    deletePublishedEventCategory(deleteCategoryInput: $deleteCategoryInput) {
      guide {
        id
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        status
        uncategorizedPublishedEvents(first: 100) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useDeleteGuideDraftEventCategoryMutation = () =>
  useMutation(deletePublishedEventCategoryMutation)
