import { TripExploreCardLoading } from '../TripExploreCard'

export const TripExploreListLoading = () => (
  <div className='p-t-6 p-x-10 space-y-4'>
    <TripExploreCardLoading />
    <TripExploreCardLoading />
    <TripExploreCardLoading />
    <TripExploreCardLoading />
    <TripExploreCardLoading />
  </div>
)
