import { useEffect, useState } from 'react'
import { Button, Modal } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import {
  Helmet,
  PageLayoutContainer,
  SearchBooking,
  SectionHeader,
} from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from 'src/pages/profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { MyBookingsContent } from './MyBookingsContent'
import { MyBookingsTabs } from './MyBookingsTabs'

export const MyBookings = () => {
  const [isSearchModalOpen, setSearchModalOpen] = useState(false)
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const displayName = data?.currentUser?.userProfile?.displayName

  const { isAnonymous } = useFirebaseUser()
  const navigate = useNavigate()

  // Anon users get redirected
  useEffect(() => {
    if (isAnonymous) navigate('/find-my-booking')
  }, [isAnonymous])

  const onSearchModalOpen = () => setSearchModalOpen(true)
  const onSearchModalClose = () => setSearchModalOpen(false)

  return (
    <>
      <Helmet
        pageName={`Bookings ${displayName ? '|' : ''} ${displayName || ''}`}
      />
      <div className='rebrand'>
        <PageLayoutContainer>
          <div className='my-12 flex flex-col items-start gap-6 md:flex-row md:items-end md:justify-between'>
            <SectionHeader subtitle='Reservations' title='My Bookings' />
            <Button
              fullWidth={isMobile}
              label='Find your booking'
              size='large'
              startIcon='search'
              onClick={onSearchModalOpen}
            />
          </div>
          <MyBookingsTabs />
        </PageLayoutContainer>

        <MyBookingsContent />
      </div>
      {isSearchModalOpen && (
        <Modal
          size='medium'
          title='Find Booking'
          onDismiss={onSearchModalClose}
        >
          <SearchBooking showTitle={false} />
        </Modal>
      )}
    </>
  )
}
