import { EventStatus } from 'src/__generated__/graphql'
import { initialDates, initialGeocoder } from 'src/constants/user'
import type { DrawerEditValues } from './drawerEditTypes'

const drawerEditDefaultValues: DrawerEditValues = {
  customBooked: EventStatus.Na,
  dates: { to: initialDates[0], from: initialDates[1] },
  name: 'Name not found',
  notes: '',
  startLocation: initialGeocoder,
  endLocation: initialGeocoder,
  times: { startTime: 'None', endTime: 'None' },
}

export { drawerEditDefaultValues }
