import { useEffect } from 'react'
import { EventType, type PublishedEvent } from 'src/__generated__/graphql'
import { MarkerWrapper } from 'src/common/components/Map'
import { StyledMarker } from 'src/pages/trips/components'
import { stringToNumber } from 'src/utils'
import { GuidesEventsMapMarkerPopup } from './GuidesEventsMapMarkerPopup'
import { useGetGuidesEventMapLazyQuery } from './useGetGuidesEventMapLazyQuery'

interface GuidesEventsMapMarkerProps {
  isHovered: boolean
  isSelected: boolean
  onHoverIdChange: (updatedHoveredId: string) => void
  onSelectedIdChange: (updatedSelectedId: string) => void
  point: PublishedEvent
}

export const GuidesEventsMapMarker = ({
  isHovered,
  isSelected,
  onHoverIdChange,
  onSelectedIdChange,
  point,
}: GuidesEventsMapMarkerProps) => {
  const [getGuidesEventMap, { data, loading: isLoading }] =
    useGetGuidesEventMapLazyQuery()
  const { addresses, id, type } = point ?? {}
  const [address] = addresses ?? []
  const lat = stringToNumber(address?.lat)
  const lng = stringToNumber(address?.long)

  useEffect(() => {
    if (isHovered || isSelected) getGuidesEventMap({ variables: { id } })
  }, [isHovered, isSelected])

  return (
    <>
      <MarkerWrapper
        isHovered={isHovered || isSelected}
        position={{
          lat,
          lng,
        }}
        onClick={() => onSelectedIdChange(id)}
      >
        <div
          className='relative'
          onMouseEnter={() => onHoverIdChange(id)}
          onMouseLeave={() => onHoverIdChange('')}
        >
          <StyledMarker
            isHovered={isHovered}
            isSelected={isSelected}
            type={type ?? EventType.Miscellaneous}
          />
          {(isHovered || isSelected) && (
            <div className='w-95 lg:w-125 absolute top-[calc(100%+12px)] hidden translate-x--1/2 lg:block'>
              <GuidesEventsMapMarkerPopup point={data?.getPublishedEvent} />
            </div>
          )}
        </div>
      </MarkerWrapper>
      {(isHovered || isSelected) && (
        <div className='m-a md:w-125 absolute bottom-2 left-5 right-5 max-w-full lg:hidden'>
          <GuidesEventsMapMarkerPopup point={data?.getPublishedEvent} />
        </div>
      )}
    </>
  )
}
