import { useQuery } from '@apollo/client'
import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { gql } from 'src/__generated__'
import {
  ExperienceCategory,
  type ExperienceSearchProductEdge,
} from 'src/__generated__/graphql'
import {
  ButtonLink,
  PageLayoutContainer,
  ProductCard,
  ProductCardLoading,
} from 'src/common/components'
import type { ProductCardProps } from 'src/common/components/ProductCard'
import { useFlag } from 'src/common/hooks'
import { generateExperienceDetailsUrl } from 'src/utils'
import { Header } from './components/header'
import { focusSearchInput } from './utils/focusSearchInput'
import { validateAndFormatImages } from '../experiences/results/common'

const CURATED_EXPERIENCES_QUERY = gql(
  `query getCuratedExperiences($experienceCategory: ExperienceCategory!, $first: Int) {
    curatedExperiences(experienceCategory: $experienceCategory, first: $first) {
      edges {
        node {
          id
          title
          description
          fromPrice {
            currency
            amount
          }
          images {
            size360x240
          }
          isCollected
          productCode
          reviews {
            totalReviews
            combinedAverageRating
          }
        }
      }
    }
  }`
)

const getProductCardProps = (
  experience: ExperienceSearchProductEdge
): ProductCardProps => {
  const { node } = experience ?? {}
  const {
    id,
    cancellationPolicy,
    images,
    fromPrice,
    pricingInfo,
    reviews,
    title,
    isFavorited,
    isCollected,
  } = node ?? {}
  const imageLinks = validateAndFormatImages(images?.size360x240)
  const { combinedAverageRating, totalReviews } = reviews ?? {}
  const date = dayjs()
  const route = generateExperienceDetailsUrl({
    arrival: date,
    departure: date.add(3, 'd'),
    id,
    name: title,
  })

  return {
    productCardData: {
      id,
      cancellationPolicy: cancellationPolicy?.type,
      customerReviewScore: combinedAverageRating,
      customerReviewScoreScale: 5,
      displayFrom: true,
      displayPriceUnit: true,
      // durationMinutes,
      name: title,
      images: imageLinks,
      priceUnit: pricingInfo?.unitType,
      primaryPrice: fromPrice?.amount,
      route,
      // strikethroughPrice: fromPriceBeforeDiscount?.amount,
      totalCustomerReviews: totalReviews,
      isFavorited,
      isCollected,
    },
  }
}

export const Experiences = () => {
  const {
    error,
    loading: isLoading,
    data: { curatedExperiences: { edges: experiences = [] } = {} } = {},
  } = useQuery(CURATED_EXPERIENCES_QUERY, {
    variables: {
      experienceCategory: ExperienceCategory.SearchPageFeatured,
      first: 4,
    },
  }) // change this whenever this ticket is finished -> https://linear.app/travelpass/issue/BOOK-2469/[be]-new-search-page-experience-support

  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !isLoading && !!experiences.length

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <PageLayoutContainer>
      <section className='space-y-12'>
        <div className='flex items-end justify-between'>
          <Header
            className='text-left'
            description='Find your next favorite memory'
            title='Featured Experiences'
          />
          <ButtonLink
            state={{ enableSmoothScroll: true }}
            to={`/${pathSegment}/experiences`}
            variant='outline'
            onClick={() => focusSearchInput()}
          >
            Search Experiences
          </ButtonLink>
        </div>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {isLoading && (
          <ul
            aria-busy={isLoading}
            className='ps-none grid list-none gap-4 md:grid-cols-2 lg:grid-cols-4'
          >
            <ProductCardLoading />
            <ProductCardLoading className='max-md:hidden' />
            <ProductCardLoading className='max-lg:hidden' />
            <ProductCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {experiences?.slice(0, 4).map(experience => (
              <div
                key={experience.node.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <ProductCard {...getProductCardProps(experience)} />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none grid list-none gap-4 md:grid-cols-4'>
            {experiences?.slice(0, 4).map(experience => (
              <li key={experience.node.id}>
                <ProductCard {...getProductCardProps(experience)} />
              </li>
            ))}
          </ul>
        )}
      </section>
    </PageLayoutContainer>
  )
}
