import type { RoomFilters } from 'src/__generated__/graphql'
import { HotelRoomsSearchFormFilters } from './HotelRoomsSearchFormFilters'

interface HotelRoomsSearchFormFiltersWrapperProps {
  filters: RoomFilters
  onChange(updatedFilters: RoomFilters): void
  disableOnLoading: boolean
}

export const HotelRoomsSearchFormFiltersWrapper = ({
  filters,
  onChange,
  disableOnLoading,
}: HotelRoomsSearchFormFiltersWrapperProps) => (
  <div className='flex justify-center gap-2 md:justify-start'>
    <HotelRoomsSearchFormFilters
      disableOnLoading={disableOnLoading}
      filters={filters}
      onChange={onChange}
    />
  </div>
)
