import {
  DetailsMinimalHeroLoading,
  DetailsOverviewLoading,
  HotelAmenitiesLoading,
} from 'src/common/components'

export const TripDetailsExploreDrawerLoading = () => (
  <div className='space-y-4'>
    <div className='space-y-3'>
      <DetailsMinimalHeroLoading />
    </div>
    <DetailsOverviewLoading />
    <HotelAmenitiesLoading />
  </div>
)
