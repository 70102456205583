import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { useState, useEffect } from 'react'
import classNames from 'classnames'
import debounce from 'lodash.debounce'

interface GuideStickyScrollUpProps extends ComponentPropsWithoutRef<'div'> {
  /** The contents of the sticky element */
  children: ReactNode
  /** The default visibility of the sticky element */
  defaultVisibility?: boolean
  /** Controls the threshold of scroll */
  threshold?: number
}

export const GuideStickyScrollUp = ({
  children,
  className,
  defaultVisibility = false,
  threshold = 3,
}: GuideStickyScrollUpProps) => {
  const [isVisible, setIsVisible] = useState(defaultVisibility)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const onScroll = () => {
      const scrollY = window.scrollY

      if (Math.abs(scrollY - lastScrollY) < threshold) return

      setIsVisible(scrollY <= lastScrollY)
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    const debouncedHandleScroll = debounce(onScroll, 100)
    window.addEventListener('scroll', debouncedHandleScroll)
    window.addEventListener('touchmove', debouncedHandleScroll)

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
      window.removeEventListener('touchmove', debouncedHandleScroll)
    }
  }, [threshold])

  return (
    <div
      className={classNames(
        className,
        'transition-bottom-300 p-x-6 z-4 b-t-1 b-t-solid b-t-grey-200 fixed left-0 flex h-20 items-center justify-center bg-white ease-in-out lg:h-24',
        {
          'transition-delay-0 bottom-0': isVisible,
          'bottom--20 lg:bottom--24': !isVisible,
        }
      )}
    >
      {children}
    </div>
  )
}
