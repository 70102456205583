import { forwardRef, useState } from 'react'
import { MenuItem } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { tripTimelinePath } from 'src/constants'
import { TripEditModal } from 'src/pages/trips/components/TripEditModal/TripEditModal'
import type { SelectedTrip } from './types'

interface BookingConfirmationTripsSelectTripPopoverProps {
  children: JSX.Element
  currentTripId: string
  isOpen: boolean
  selectedTrip: SelectedTrip
  trips: SelectedTrip[]
  onChange(updatedTrips: SelectedTrip): void
  onClose(): void
}

export const BookingConfirmationTripsSelectTripPopover = forwardRef<
  HTMLInputElement,
  BookingConfirmationTripsSelectTripPopoverProps
>(function (
  { children, currentTripId, isOpen, selectedTrip, trips, onClose, onChange },
  ref
) {
  const navigate = useNavigate()
  const [isEditTripOpen, setIsEditTripOpen] = useState(false)
  const constructedTrips = [
    {
      id: currentTripId,
      name: '+ Create new trip',
    },
    ...trips,
  ]

  const onClick = ({ id, name }: SelectedTrip) => {
    onClose()
    if (id === currentTripId) {
      setIsEditTripOpen(true)
    } else {
      onChange({ id, name })
    }
  }

  const popoverContent = (
    <div className={bookingConfirmationTripsSelectTripPopoverCss}>
      {constructedTrips.map(({ id, name }) => (
        <MenuItem
          key={id}
          isHighlighted={selectedTrip.id === id}
          label={name ?? ''}
          value={id ?? ''}
          onClick={() => onClick({ id, name })}
        />
      ))}
    </div>
  )

  return (
    <>
      {isEditTripOpen && (
        <TripEditModal
          isEditModal={false}
          successSnackTitle='Trip Successfully Created'
          tripId={currentTripId}
          onClose={() => setIsEditTripOpen(false)}
          onCompleted={() => navigate(`${tripTimelinePath}/${currentTripId}`)}
        />
      )}

      <Popover
        content={popoverContent}
        isOpen={isOpen}
        positions={['bottom']}
        ref={ref}
        onClickOutside={onClose}
      >
        {children}
      </Popover>
    </>
  )
})

BookingConfirmationTripsSelectTripPopover.displayName =
  'BookingConfirmationTripsSelectTripPopover'

const bookingConfirmationTripsSelectTripPopoverCss =
  'bg-white max-h-54 overflow-y-auto rd-3 shadow-1 ml-21 mt-1 p-2 w-50'
