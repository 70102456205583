interface OverviewOnboardingSummaryProps {
  hasCompletedAllActions: boolean
  daysRemaining: number
}

export const OverviewOnboardingSummary = ({
  hasCompletedAllActions,
  daysRemaining,
}: OverviewOnboardingSummaryProps) => {
  const daysRemainingText = `${daysRemaining} ${daysRemaining > 1 ? 'days' : 'day'}`

  return (
    <p className='type-body-1-desktop'>
      <span>
        {daysRemaining > 0 ? (
          <>
            Starts in <b>{daysRemainingText}</b>!
          </>
        ) : (
          <>Your trip has started!</>
        )}
      </span>
      <br />
      <span>
        {hasCompletedAllActions
          ? "You've completed all the steps for your trip!"
          : 'You still have some things to plan:'}
      </span>
    </p>
  )
}
