import { useRef, useState } from 'react'
import { Button } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { SlideUpDrawer } from 'src/common/components'
import { HotelResultsSortMenuItems } from './HotelResultsSortMenuItems'
import { menuItems } from './hotelResultsSortConstants'
import {
  HotelResultsSearchParams,
  initialSort,
} from '../../hotelResultsConstants'

export const HotelResultsSort = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const popoverRef = useRef(null)
  const sort = searchParams.get(HotelResultsSearchParams.sort) ?? initialSort
  const label =
    menuItems.find(({ value }) => sort === value)?.label ?? 'Recommended'
  const labelWithText = `Sort by: ${label}`

  const onClick = (updatedValue: string) => {
    searchParams.set(HotelResultsSearchParams.sort, updatedValue)
    setSearchParams(searchParams)
    setIsDrawerOpen(false)
    setIsPopoverOpen(false)
  }

  return (
    <>
      <div className='block lg:hidden'>
        <Button
          data-testid='HotelResultsSort-drawer-button'
          label={labelWithText}
          size='small'
          startIcon='sort'
          variant='outlined'
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </div>
      <Popover
        containerStyle={{ zIndex: '450' }}
        content={() => (
          <div className='rd-3 shadow-1 w-50 mr--5 mt-1 bg-white p-2'>
            <HotelResultsSortMenuItems value={sort} onClick={onClick} />
          </div>
        )}
        isOpen={isPopoverOpen}
        positions={['bottom']}
        ref={popoverRef}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div
          className='lg:display-initial lg:ws-nowrap hidden'
          data-testid='HotelResultsSort-popover-button'
          ref={popoverRef}
        >
          <Button
            label={labelWithText}
            size='small'
            startIcon='sort'
            variant='outlined'
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          />
        </div>
      </Popover>
      {/* TODO: remove drawer wrapper/extra check when component is improved. */}
      {isDrawerOpen && (
        <div className='w-100vw z-2 fixed bottom-0 left-0 h-full'>
          <SlideUpDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <HotelResultsSortMenuItems value={sort} onClick={onClick} />
          </SlideUpDrawer>
        </div>
      )}
    </>
  )
}
