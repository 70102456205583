import { Icon } from '@travelpass/design-system'
import { ExperienceCancellationType } from 'src/__generated__/graphql'

const TYPE_COLOR_MAP = {
  [ExperienceCancellationType.Full]: 'color-forest-light',
  [ExperienceCancellationType.Custom]: 'color-canyon-dark',
  [ExperienceCancellationType.None]: 'color-forest',
}

export const CancellationCard = ({
  type,
  description,
}: {
  type: ExperienceCancellationType
  description: string
}) => {
  const title =
    type === ExperienceCancellationType.Full
      ? 'Free Cancellation'
      : 'Non Refundable'

  return (
    <div className='b-grey-300 b-solid b-1 rounded-2 flex flex-row items-center gap-4 p-4'>
      <span className={TYPE_COLOR_MAP[type]}>
        <Icon name='event' size='large' />
      </span>
      <div>
        <p className={`type-body-1 font-bold ${TYPE_COLOR_MAP[type]}`}>
          {title}
        </p>
        {description && (
          <p className='color-grey-800 type-body-2'>{description}</p>
        )}
      </div>
    </div>
  )
}
