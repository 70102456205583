import { Icon } from '@travelpass/design-system'

export const FormattedLocation = ({ address }) => {
  let contents = null

  if (address?.fullAddress) {
    contents = address.fullAddress
  } else if (address?.city) {
    contents = address.city
  } else if (address?.state) {
    contents = address.state
  } else if (address?.country) {
    contents = address.country
  } else {
    contents = null
  }

  return (
    <div className='flex min-h-5 flex-row items-center gap-1'>
      {contents && (
        <>
          <div className='c-orange'>
            <Icon name='placeOutline' size='small' />
          </div>
          <span className='type-small-text color-grey-900 line-clamp-1'>
            {contents}
          </span>
        </>
      )}
    </div>
  )
}
