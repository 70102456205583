import { Skeleton } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'

export const ExperiencesBookingDetailsBreadcrumbsLoading = () => {
  const constructedBreadcrumbs = () => {
    if (isMobile)
      return (
        <div className='h-26 w-75'>
          <Skeleton />
        </div>
      )

    return (
      <div className={experiencesBookingBreadcrumbsLoadingCss}>
        <div className='gap-9px md:gap-18px flex items-center'>
          <div className='h-18px w-75px'>
            <Skeleton />
          </div>
          <div className='h-18px w-150px'>
            <Skeleton />
          </div>
          <div className='h-18px w-225px'>
            <Skeleton />
          </div>
        </div>
      </div>
    )
  }

  return constructedBreadcrumbs()
}

const experiencesBookingBreadcrumbsLoadingCss = 'md:pb-8'
