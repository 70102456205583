import { useEffect, useState } from 'react'
import { Divider, Dropdown, DropdownOption } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type {
  ExperienceBookingQuestion,
  ExperiencePickupLocation,
} from 'src/__generated__/graphql'
import { LocationRefOrFreeText } from './LocationRefOrFreeTextField'
import { useFormContext } from './useExperienceBookingForm'
import { ExperiencesDetailsSearchParams } from '../details/experiencesDetailsConstants'

const CUSTOM_PICKUP_POINT = 'CUSTOM_PICKUP_POINT'

export const PickupPointField = ({
  pickupPoint,
  locations,
  name,
  allowCustomTravelerPickup = false,
}: {
  pickupPoint: ExperienceBookingQuestion
  locations: ExperiencePickupLocation[]
  name: `answers.transferArrivalMode.${number}`
  allowCustomTravelerPickup?: boolean
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { setValue, watch } = useFormContext()
  const value = watch(name)

  const isCustomPoint = value?.unit === 'FREETEXT'
  const displayValue = isCustomPoint ? CUSTOM_PICKUP_POINT : value?.answer ?? ''

  useEffect(() => {
    const qpPickupPoint =
      searchParams.get(ExperiencesDetailsSearchParams.pickupPoint) ?? ''
    const pickupLocation = locations.find(
      ({ location }) => location?.reference === qpPickupPoint
    )
    if (pickupLocation) {
      handleSelectedPoint(pickupLocation.location.reference)
      setSearchParams(sp => {
        sp.delete(ExperiencesDetailsSearchParams.pickupPoint)
        return sp
      })
    }
  }, [])

  const handleSelectedPoint = point => {
    if (point === CUSTOM_PICKUP_POINT) {
      setValue(name, {
        question: pickupPoint.id,
        answer: '',
        unit: 'FREETEXT',
      })
    } else {
      setValue(name, {
        question: pickupPoint.id,
        answer: point,
        unit: 'LOCATION_REFERENCE',
      })
    }
  }

  return (
    <>
      <Dropdown
        id='pickupPoint.answer'
        label={pickupPoint.label}
        required={true}
        value={displayValue}
        onChange={handleSelectedPoint}
      >
        <>
          {locations.map(({ location }) => {
            if (!location) return null
            return (
              <DropdownOption
                key={location.reference}
                value={location.reference}
              >
                {location.name}
              </DropdownOption>
            )
          })}
          {allowCustomTravelerPickup && (
            <>
              <Divider />
              <DropdownOption value={CUSTOM_PICKUP_POINT}>
                I want to select a custom location
              </DropdownOption>
            </>
          )}
        </>
      </Dropdown>
      {isCustomPoint && (
        <LocationRefOrFreeText field={name} label='Custom pickup location' />
      )}
    </>
  )
}
