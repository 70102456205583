import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const useCreateCustomEvent = () => {
  const [createCustomEvent, { loading, error }] = useMutation(MUTATION)
  return {
    createCustomEvent,
    loading,
    error,
  }
}

const MUTATION = gql(`
mutation CreateAddCustomTripEvent($eventInput: CreateEventInput!) {
    createEvent(eventInput: $eventInput) {
      id
      name
      type
      startDate
      endDate
      notes
      isCustom
      status
      useEventTime
      addresses {
        id
        addressLine1
        addressLine2
        city
        state
        long
        lat
        googlePlaceId
        country
        zipcode
      }
    }
}
`)
