import { useQuery } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { gql } from 'src/__generated__'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'

const nodeQuery = gql(`
  query GetTripDetailsForNewEdit($tripId: ID!) {
    node(id: $tripId) {
      ... on Trip {
        id
        dailyEventBreakdown {
          scheduled {
            events {
              id
            }
          }
        }
      }
    }
  }
`)

export const useGetTripForEdit = ({ tripId }: { tripId: string }) => {
  const {
    hasError: hasTripDetailsError,
    isLoading: isTripDetailsLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    data,
    error: hasTripForEditError,
    loading: isTripForEditLoading,
  } = useQuery(nodeQuery, {
    variables: {
      tripId,
    },
    skip: !tripId,
  })

  return {
    hasError: hasTripDetailsError || hasTripForEditError,
    isLoading: isTripDetailsLoading || isTripForEditLoading,
    tripDetailsData,
    tripForEditData: data?.node?.__typename === 'Trip' ? data.node : null,
  }
}
