import isEmpty from 'lodash.isempty'
import type { GeocodeResult } from 'use-places-autocomplete'
import type { GenericAddressInput } from 'src/__generated__/graphql'

const assembleAddressLine1 = ({
  streetNumber,
  route,
}: {
  streetNumber: string | null
  route: string | null
}): string | null => {
  const addressPieces: string[] = []
  if (streetNumber) addressPieces.push(streetNumber)
  if (route) addressPieces.push(route)
  if (!isEmpty(addressPieces)) return addressPieces.join(' ')

  return null
}

type constructAddressInputArgs = {
  addressComponents?: GeocodeResult['address_components']
  center: number[]
  placeId?: string
}

export const constructAddressInput = (
  geocodeData: constructAddressInputArgs | null
): GenericAddressInput => {
  const { addressComponents, center, placeId } = geocodeData ?? {}
  let validAddress: GenericAddressInput = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    lat: null,
    long: null,
    googlePlaceId: null,
  }
  const preAssembledAddress: {
    streetNumber: null | string
    route: null | string
  } = { streetNumber: null, route: null }

  const [lat, lng] = center ?? []
  if (lat && lng) {
    validAddress.lat = lat.toString()
    validAddress.long = lng.toString()
  }

  addressComponents?.forEach(({ long_name, types }) => {
    if (types.includes('country')) validAddress.country = long_name
    if (types.includes('administrative_area_level_1')) {
      validAddress.state = long_name
    }
    if (types.includes('locality')) validAddress.city = long_name
    if (types.includes('street_number')) {
      preAssembledAddress.streetNumber = long_name
    }
    if (types.includes('route')) {
      preAssembledAddress.route = long_name
    }
    if (types.includes('postal_code')) {
      validAddress.zipcode = long_name
    }
  })

  if (placeId) validAddress.googlePlaceId = placeId

  const assembledAddressLine1 = assembleAddressLine1(preAssembledAddress)
  if (assembledAddressLine1) {
    validAddress.addressLine1 = assembledAddressLine1
  }

  return validAddress
}

export const geocoderLocationBiasConfig = (
  lat?: string | null,
  lon?: string | null
) => {
  const latitude = lat ? parseFloat(lat) : null
  const longitude = lon ? parseFloat(lon) : null
  const missingValue = !latitude || !longitude
  const invalidValue = !missingValue && (isNaN(latitude) || isNaN(longitude))

  if (missingValue || invalidValue) return
  return {
    cacheKey: 'location-bias-preferred', // So it don't mess with other geocoders cache that dont have location bias
    requestOptions: {
      locationBias: new google.maps.Circle({
        radius: 5000,
        center: { lat: latitude, lng: longitude },
      }),
    },
  }
}
