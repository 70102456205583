import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { createHotelReviewsArguments } from './hotelReviewsApolloUtils'
import { constructHotelId } from '../../../utils'
import { HotelLoading } from '../../HotelLoading'
import { HotelReviews } from '../HotelReviews'

const hotelReviews = gql(`
  query HotelReviewsListHotelReviews(
    $listHotelReviewsArgs: ListHotelReviewsArgs!
  ) {
    listHotelReviews(listHotelReviewsArgs: $listHotelReviewsArgs) {
      reviews {
        addedDate
        averageRate
        id
        name
        negativeComments
        positiveComments
        travelerType
      }
    }
  }
`)

export const HotelReviewsApollo = () => {
  const { hotelId } = useParams()
  const constructedHotelId = constructHotelId(hotelId)

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(hotelReviews, {
    skip: !constructedHotelId,
    variables: createHotelReviewsArguments({
      pageSize: 4,
      standardHotelId: constructedHotelId,
    }),
  })

  const { listHotelReviews: hotelReviewsData } = data ?? {}

  if (isLoading) return <HotelLoading />

  if (hasError) return null

  return <HotelReviews hotelReviewsData={hotelReviewsData} />
}
