import { Link } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { privacyPath, supportPath } from 'src/constants'

export const TermsContent = () => {
  const navigate = useNavigate()

  return (
    <div className={contentCss}>
      <h4 className={titleCss}>
        Agreement Between Customers and Travelpass.com
      </h4>
      <div className={bodyCss}>
        Thank you for using the Travelpass.com website (the
        &quot;Website&quot;). This Website is provided solely to assist
        customers in gathering travel information, determining the availability
        of travel-related goods and services, making legitimate reservations or
        otherwise transacting business with travel suppliers, and for no other
        purposes. The terms &quot;we&quot;, &quot;us&quot;, &quot;our&quot;, and
        &quot;Travelpass.com&quot; refer to Travelpass.com, and its parent
        company and company affiliates, including Travelpass Group, Inc.
        (collectively, the &quot;Travelpass.com Companies&quot;). The term
        &quot;you&quot; and &quot;your&quot; refers to the customer visiting the
        Website and/or booking a reservation through us on this Website, or
        through our customer service agents.
      </div>
      <div className={bodyCss}>
        This Website is offered to you conditioned upon your acceptance without
        modification of all the terms, conditions, and notices set forth below
        (collectively, the &quot;Terms and Conditions&quot; or
        &quot;Agreement&quot;).{' '}
        <strong>
          Please read these Terms and Conditions carefully, as they contain
          important information about limitations of liability and resolution of
          disputes through arbitration rather than in court.
        </strong>{' '}
        You should also read our{' '}
        <Link label='Privacy Policy' onClick={() => navigate(privacyPath)} />,
        which also governs your use of the Website, and is incorporated by
        reference in this Agreement. By accessing or using this Website, booking
        any reservations for travel products or services on this Website, or
        contacting our call center agents, you agree that the Terms and
        Conditions then in force shall apply. If you do not agree to all of the
        Terms and Conditions, please do not access, use or book any reservations
        through this Website or our call center agents.
      </div>
      <h4 className={titleCss}>Use of the Website</h4>
      <div className={bodyCss}>
        As a condition of your use of this Website, you represent and warrant
        that:
        <ol className={olCss}>
          <li className={liCss}>you are at least 18 years of age;</li>
          <li className={liCss}>
            you possess the legal authority to create a binding legal
            obligation;
          </li>
          <li className={liCss}>
            you will use this Website in accordance with these Terms and
            Conditions;
          </li>
          <li className={liCss}>
            you will only use this Website to make legitimate reservations for
            you or for another person for whom you are legally authorized to
            act;
          </li>
          <li className={liCss}>
            you will inform such other persons about the Terms and Conditions
            that apply to the reservations you have made on their behalf,
            including all rules and restrictions applicable thereto;
          </li>
          <li className={liCss}>
            all information supplied by you on this Website is true, accurate,
            current and complete; and
          </li>
          <li className={liCss}>
            if you have a Travelpass.com account, you will safeguard your
            account information and will supervise and be completely responsible
            for any use of your account by you and anyone other than you.
          </li>
        </ol>
        We retain the right at our sole discretion to deny access to anyone to
        this Website and the services we offer, at any time and for any reason,
        including, but not limited to, for violation of these Terms and
        Conditions.
      </div>
      <h4 className={titleCss}>Disputes</h4>
      <div className={bodyCss}>
        Travelpass.com is committed to customer satisfaction, so if you have a
        problem or dispute, we will try to resolve your concerns. But if we are
        unsuccessful, you may pursue claims as explained below in this section.
      </div>
      <div className={bodyCss}>
        You agree to give us an opportunity to resolve any disputes or claims
        relating in any way to the Website, any dealings with our customer
        service agents, any services or products provided, any representations
        made by us, or our Privacy Policy (&quot;Claims&quot;) by contacting
        Travelpass.com Customer Support. If we are not able to resolve your
        Claims within 60 days, you may seek relief through arbitration or in
        small claims court, as set forth below.
      </div>
      <div className={bodyCss}>
        <strong>
          Any and all Claims will be resolved by binding arbitration, rather
          than in court.
        </strong>{' '}
        except you may assert Claims on an individual basis in small claims
        court if they qualify.This includes any Claims you may assert against
        us, travel suppliers, or any companies offering products or services
        through us (which are beneficiaries of this arbitration agreement). This
        also includes any Claims that arose before you accepted these Terms and
        Conditions, regardless of whether prior versions of the Terms and
        Conditions required arbitration.
      </div>
      <div className={bodyCss}>
        <strong>
          There is no judge or jury in arbitration, and court review of an
          arbitration award is limited. However, an arbitrator can award on an
          individual basis the same damages and relief as a court (including
          statutory damages, attorneys&apos; fees and costs), and must follow
          and enforce these Terms and Conditions as a court would.
        </strong>
      </div>
      <div className={bodyCss}>
        Arbitrations will be conducted by the American Arbitration Association
        (AAA) under its rules, including the AAA Consumer Rules. Payment of all
        filing, administration and arbitrator fees will be governed by the
        AAA&apos;s rules, except as provided in this Section. If your total
        Claims seek less than $10,000, we will reimburse you for filing fees you
        pay to the AAA and will pay arbitrator&apos;s fees. You may choose to
        have an arbitration conducted by telephone, based on written
        submissions, or in person in the state where you live or at another
        mutually agreed upon location.
      </div>
      <div className={bodyCss}>
        To begin an arbitration proceeding, you must send a letter requesting
        arbitration and describing your Claims to &quot;Travelpass.com Legal:
        Arbitration Claim Manager,&quot; at Travelpass Group, Inc., 4700 West
        Daybreak Parkway STE 100 N, South Jordan, UT 84009. If we request
        arbitration against you, we will give you notice at the email address or
        street address you have provided. The AAA&apos;s rules and filing
        instructions are available at{' '}
        <Link
          label='www.adr.org'
          onClick={() => window.open('https://www.adr.org')}
        />
        or by calling{' '}
        <Link
          label='1-800-778-7879'
          onClick={() => (window.location.href = 'tel:1-800-778-7879')}
        />
        .
      </div>
      <div className={bodyCss}>
        <strong>
          Any and all proceedings to resolve Claims will be conducted only on an
          individual basis and not in a class, consolidated or representative
          action.
        </strong>{' '}
        The Federal Arbitration Act and federal arbitration law apply to this
        Agreement. An arbitration decision may be confirmed by any court with
        competent jurisdiction.
      </div>
      <h4 className={titleCss}>Prohibited Activities</h4>
      <div className={bodyCss}>
        The content and information on this Website (including, but not limited
        to, price and availability of travel services) as well as the
        infrastructure used to provide such content and information, is
        proprietary to us or our suppliers and providers. While you may make
        limited copies of your travel trip (and related documents) for travel or
        service reservations booked through this Website, you agree not to
        otherwise modify, copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell or re-sell any information, software, products, or services
        obtained from or through this Website. Additionally, you agree not to:
        <ol className={olCss}>
          <li className={liCss}>
            use this Website or its contents for any commercial purpose;
          </li>
          <li className={liCss}>
            maky any speculative, false, fraudulent, or illegal reservation or
            any reservation in anticipation of demand;
          </li>
          <li className={liCss}>
            access, monitor or copy any content or information of this Website
            using any robot, spider, scraper or other automated means or any
            manual process for any purpose without our express prior written
            permission;
          </li>
          <li className={liCss}>
            violate the restrictions in any robot exclusion headers on this
            Website or bypass or circumvent other measures employed to prevent
            or limit access to this Website;
          </li>
          <li className={liCss}>
            take any action that imposes, or may impose, in our discretion, an
            unreasonable or disproportionately large load or burden on our
            infrastructure;
          </li>
          <li className={liCss}>
            deep-link to any portion of this Website (including, without
            limitation, the purchase path for any travel services) for any
            purpose without our express prior written permission; or
          </li>
          <li className={liCss}>
            &quot;frame&quot;, &quot;mirror&quot; or otherwise incorporate any
            part of this Website into any other website without our express
            prior written authorization.
          </li>
        </ol>
        If your booking or account shows signs of fraud, abuse, suspicious, or
        illegal activity, Travelpass.com may cancel any travel service
        reservations associated with your name, email address or account, and
        close any associated Travelpass.com accounts. If you have conducted any
        fraudulent or illegal activity, Travelpass.com reserves the right to
        take any necessary legal action and you may be liable for monetary
        losses to Travelpass.com , including litigation costs and damages. To
        contest the cancellation of a booking or freezing or closure of an
        account, please contact Travelpass.com Customer Service.
      </div>
      <h4 className={titleCss}>Supplier Rules and Restrictions</h4>
      <div className={bodyCss}>
        <strong>
          Additional terms and conditions will apply to your reservation and
          purchase of travel-related goods and services that you select.
        </strong>{' '}
        Please read these additional terms and conditions carefully. You agree
        to abide by the terms and conditions of purchase imposed by any supplier
        with whom you elect to deal, including, but not limited to, payment of
        all amounts when due and compliance with the supplier&apos;s rules and
        restrictions regarding availability and use of fares, products, or
        services.
      </div>
      <div className={bodyCss}>
        You acknowledge and agree that some third-party providers offering
        certain services and/or activities may require you to sign their
        liability waiver prior to participating in the service and/or activity
        they offer.
      </div>
      <div className={bodyCss}>
        You understand and agree that any violation of any such supplier&apos;s
        rules and restrictions may result in cancellation of your
        reservation(s), in your being denied access to the applicable travel
        product or services, in your forfeiting any monies paid for such
        reservation(s), and/or in our debiting your account for any costs we
        incur as a result of such violation.
      </div>
      <div className={bodyCss}>
        Travelpass.com is not liable for any costs incurred due to hotel
        relocation.
      </div>
      <h4 className={titleCss}>Suppliers Policies</h4>
      <div className={bodyCss}>
        <Link
          label='Supplier Terms and Conditions'
          onClick={() =>
            window.open(
              'https://developer.expediapartnersolutions.com/terms/en'
            )
          }
        />
      </div>
      <h4 className={titleCss}>Privacy Policy</h4>
      <div className={bodyCss}>
        Travelpass.com Inc. believes in protecting your privacy. Please{' '}
        <Link label='click here' onClick={() => navigate(privacyPath)} /> to
        review our current Privacy Policy, which also governs your use of the
        Website and, as stated above, is incorporated herein by reference, to
        understand our practices: Privacy Policy.
      </div>
      <h4 className={titleCss}>Prepaid Hotel Reservations</h4>
      <div className={bodyCss}>
        You acknowledge and agree that the Travelpass.com Companies
        pre-negotiate certain room rates with hotel suppliers to facilitate the
        booking of reservations. You also acknowledge and agree that the
        Travelpass.com Companies provide you services to facilitate such booking
        of reservations in consideration of your payment of a fee (the
        &quot;facilitation fee&quot;). The room rate displayed on the Website is
        a combination of the pre-negotiated room rate for rooms reserved on your
        behalf by the Travelpass.com Companies and the facilitation fee retained
        by the Travelpass.com Companies for their services. You authorize the
        Travelpass.com Companies to book reservations for the total reservation
        price, which includes the room rate displayed on the Website, plus tax
        recovery charges, service fees, and where applicable, taxes on the
        Travelpass.com Companies&apos; services. You agree that your credit card
        will be charged by the Travelpass.com Companies for the total
        reservation price. Upon submitting your reservation request you
        authorize the Travelpass.com Companies to facilitate hotel reservations
        on your behalf, including making payment arrangements with hotel
        suppliers.
      </div>
      <div className={bodyCss}>
        You acknowledge that except as provided below with respect to tax
        obligations on the amounts we retain for our services, the
        Travelpass.com Companies do not collect taxes for remittance to
        applicable taxing authorities. The tax recovery charges on prepaid hotel
        transactions are a recovery of the estimated taxes (e.g. sales and use,
        occupancy, room tax, excise tax, value added tax, etc.) that the
        Travelpass.com Companies pay to the hotel supplier for taxes due on the
        hotel&apos;s rental rate for the room. The hotel suppliers invoice the
        Travelpass.com Companies for certain charges, including tax amounts. The
        hotel suppliers are responsible for remitting applicable taxes to the
        applicable taxing jurisdictions. None of the Travelpass.com Companies
        act as co-vendors with the supplier with whom we book or reserve our
        customer&apos;s travel arrangements. Taxability and the appropriate tax
        rate vary greatly by location. The actual tax amounts paid by the
        Travelpass.com Companies to the hotel suppliers may vary from the tax
        recovery charge amounts, depending upon the rates, taxability, etc. in
        effect at the time of the actual use of the hotel by our customers. We
        retain service fees as additional compensation in servicing your travel
        reservation. Service fees retained by the Travelpass.com Companies for
        their services vary based on the amount and type of hotel reservation.
      </div>
      <div className={bodyCss}>
        You may cancel or change your prepaid hotel reservation, but you may be
        charged the cancellation or change fee indicated in the rules and
        restrictions for the hotel reservation. If you do not cancel or change
        your reservation before the cancellation policy period applicable to the
        hotel you reserved, which varies by hotel (usually 24 to 72 hours) prior
        to your date of arrival, you will be subject to a charge equal to
        applicable nightly rates, tax recovery charges and service fees. In the
        event you do not show for the first night of the reservation and plan to
        check-in for subsequent nights in your reservation, you must confirm the
        reservation changes with us no later than the date of the first night of
        the reservation to prevent cancellation of your reservation.
      </div>
      <div className={bodyCss}>
        You agree to pay any cancellation or change fees that you incur. In
        limited cases, some hotels do not permit changes to or cancellations of
        reservations after they are made, as indicated in the rules and
        restrictions for the hotel reservation. You agree to abide by the Terms
        and Conditions imposed with respect to your prepaid hotel reservations.
      </div>
      <div className={bodyCss}>
        Sales, use and/or local hotel occupancy taxes are imposed on the fees or
        amounts that we charge for our services (e.g. service fee and/or
        facilitation fee) in certain jurisdictions. The actual tax amounts on
        our services may vary depending on the rates in effect at the time of
        your hotel stay.
      </div>
      <div className={bodyCss}>
        You may not book reservations for more than 9 rooms online for the same
        hotel/stay dates. If we determine that you have booked reservations for
        more than 9 rooms in total in separate reservations, we may cancel your
        reservations, and charge you a cancellation fee, if applicable. If you
        paid a non-refundable deposit, your deposit will be forfeited. If you
        wish to book reservations for 9 or more rooms, you must contact
        Travelpass.com &apos;s call center. One of our group travel specialists
        will research your request and contact you to complete your reservation.
        You may be asked to sign a written contract and/or pay a nonrefundable
        deposit.
      </div>
      <div className={bodyCss}>
        Some hotel suppliers may require you to present a credit card or cash
        deposit upon check-in to cover additional expenses incurred during your
        stay. Such deposit is unrelated to any payment received by
        Travelpass.com for your hotel booking.
      </div>
      <h4 className={titleCss}>Bank and Credit Card Fees</h4>
      <div className={bodyCss}>
        Some banks and credit card companies impose fees for international
        transactions. If you are making a booking from outside of the United
        States on a US credit card, your bank may convert the payment amount to
        your local currency and charge you a conversion fee. This means the
        amount listed on your credit or bank card statement may be in your local
        currency and therefore a different figure than the figure shown on the
        billing summary page for a reservation booked on the Website. In
        addition, a foreign transaction fee may be assessed if the bank that
        issued your credit card is located outside of the United States. Booking
        international travel may be considered to be an international
        transaction by the bank or card company, since Travelpass.com may pass
        on your payment to an international travel supplier. The currency
        exchange rate and foreign transaction fee is determined solely by your
        bank on the day that they process the transaction. If you have any
        questions about these fees or the exchange rate applied to your booking,
        please contact your bank.
      </div>
      <h4 className={titleCss}>International Travel</h4>
      <div className={bodyCss}>
        You are responsible for ensuring that you meet all foreign entry
        requirements and that your travel documents, such as passports and visas
        (e.g. transit, business, tourist, and otherwise), are in order and any
        other foreign entry requirements are met. Travelpass.com has no special
        knowledge regarding foreign entry requirements or travel documents. We
        urge customers to review travel prohibitions, requirements, warnings,
        announcements, and advisories issued by the relevant governments prior
        to booking travel to international destinations.
      </div>
      <div className={bodyCss}>
        <strong>
          BY OFFERING RESERVATIONS FOR TRAVEL PRODUCTS IN PARTICULAR
          INTERNATIONAL DESTINATIONS, TRAVELPASS.COM DOES NOT REPRESENT OR
          WARRANT THAT TRAVEL TO OR FROM SUCH AREAS IS ADVISABLE, LEGAL OR
          WITHOUT RISK, AND IS NOT LIABLE FOR ANY DAMAGES OR LOSSES THAT MAY
          RESULT FROM TRAVEL TO OR FROM SUCH DESTINATIONS.
        </strong>
      </div>
      <h4 className={titleCss}>
        Limitation of Liability and Warranty Disclaimer
      </h4>
      <div className={bodyCss}>
        <strong>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PUBLISHED OR MADE
          AVAILABLE ON OR THROUGH THIS WEBSITE MAY INCLUDE INACCURACIES OR
          ERRORS, INCLUDING PRICING ERRORS. IN PARTICULAR, THE TRAVELPASS.COM
          COMPANIES AND TRAVELPASS.COM PARTNERS DO NOT GUARANTEE THE ACCURACY
          OF, AND DISCLAIM ALL LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES
          RELATING TO THE INFORMATION AND DESCRIPTION OF THE HOTEL, AIR, CRUISE,
          CAR AND OTHER TRAVEL PRODUCTS AND SERVICES DISPLAYED ON THIS WEBSITE
          (INCLUDING, WITHOUT LIMITATION, THE PRICING, PHOTOGRAPHS, LIST OF
          HOTEL AMENITIES, GENERAL PRODUCT DESCRIPTIONS, ETC.). IN ADDITION,
          TRAVELPASS.COM EXPRESSLY RESERVES THE RIGHT TO CORRECT ANY PRICING
          ERRORS ON OUR WEBSITE AND/OR PENDING RESERVATIONS MADE UNDER AN
          INCORRECT PRICE. IN SUCH EVENT, IF AVAILABLE, WE WILL OFFER YOU THE
          OPPORTUNITY TO KEEP YOUR PENDING RESERVATION AT THE CORRECT PRICE OR
          WE WILL CANCEL YOUR RESERVATION WITHOUT PENALTY.
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          HOTEL RATINGS DISPLAYED ON THIS WEBSITE ARE INTENDED AS ONLY GENERAL
          GUIDELINES, AND THE TRAVELPASS.COM COMPANIES DO NOT GUARANTEE THE
          ACCURACY OF THE RATINGS. THE TRAVELPASS.COM COMPANIES AND THEIR
          RESPECTIVE SUPPLIERS MAKE NO GUARANTEES ABOUT THE AVAILABILITY OF
          SPECIFIC PRODUCTS AND SERVICES. THE TRAVELPASS.COM COMPANIES AND THEIR
          RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES TO THIS
          WEBSITE AT ANY TIME AND WITHOUT NOTICE.
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          THE TRAVELPASS.COM COMPANIES AND THEIR RESPECTIVE SUPPLIERS MAKE NO
          REPRESENTATIONS OR WARRANTIES WHATSOEVER ABOUT THE SUITABILITY OF ANY
          OF THE INFORMATION, SOFTWARE, PRODUCTS, AND/OR SERVICES CONTAINED OR
          MADE AVAILABLE ON OR THROUGH THIS WEBSITE FOR ANY PURPOSE, AND THE
          INCLUSION OR OFFERING OF ANY PRODUCTS OR SERVICES ON OR THROUGH THIS
          WEBSITE DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF SUCH
          PRODUCTS OR SERVICES BY THE TRAVELPASS.COM COMPANIES. ALL SUCH
          INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES ARE PROVIDED OR MADE
          AVAILABLE ON OR THROUGH THIS WEBSITE STRICTLY ON AN &quot;AS IS&quot;
          BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND
          WHATSOEVER. THE TRAVELPASS.COM COMPANIES AND THEIR RESPECTIVE
          SUPPLIERS DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES THAT
          THIS WEBSITE, ITS SERVERS, OR ANY EMAIL SENT FROM THE TRAVELPASS.COM
          COMPANIES AND/OR THEIR RESPECTIVE SUPPLIERS ARE FREE OF VIRUSES OR
          OTHER HARMFUL CODE OR COMPONENTS. WITHOUT LIMITING THE FOREGOING, THE
          TRAVELPASS.COM COMPANIES AND THEIR RESPECTIVE SUPPLIERS HEREBY
          DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES WHATSOEVER WITH
          REGARD TO THE INFORMATION, SOFTWARE, PRODUCTS, AND/OR SERVICES
          CONTAINED OR MADE AVAILABLE ON OR THROUGH THIS WEBSITE, INCLUDING ANY
          AND ALL IMPLIED REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR RESPONSE, TITLE, AND NON-INFRINGEMENT.
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          THE CARRIERS, HOTELS, AND OTHER SUPPLIERS PROVIDING TRAVEL OR OTHER
          SERVICES ON OR THROUGH THIS WEBSITE ARE INDEPENDENT CONTRACTORS AND
          NOT AGENTS OR EMPLOYEES OF ANY OF THE TRAVELPASS.COM COMPANIES. THE
          TRAVELPASS.COM COMPANIES ARE NOT LIABLE FOR ANY OF THE ACTS, ERRORS,
          OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES, OR NEGLIGENCE OF ANY
          SUCH SUPPLIERS OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE,
          OR OTHER DAMAGES, LOSSES, OR EXPENSES RESULTING THERE FROM. THE
          TRAVELPASS.COM COMPANIES HAVE NO RESPONSIBILITY OR LIABILITY FOR AND
          WILL MAKE NO REFUND IN THE EVENT OF ANY DELAY, CANCELLATION,
          OVERBOOKING, STRIKE, FORCE MAJEURE, OR OTHER CAUSES BEYOND THEIR
          REASONABLE DIRECT CONTROL, AND THEY HAVE NO RESPONSIBILITY OR
          LIABILITY FOR ANY ADDITIONAL EXPENSES, OMISSIONS, DELAYS, RE-ROUTING,
          OR ACTS OF ANY GOVERNMENT OR AUTHORITY.
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          IN NO EVENT SHALL THE TRAVELPASS.COM COMPANIES AND/OR THEIR RESPECTIVE
          SUPPLIERS BE RESPONSIBLE OR LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
          INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OR LOSSES
          WHATSOEVER ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS
          TO, DISPLAY OF, OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY
          TO ACCESS, DISPLAY, OR USE THIS WEBSITE (INCLUDING, BUT NOT LIMITED
          TO, YOUR RELIANCE UPON OPINIONS APPEARING ON THIS WEBSITE, ANY
          COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS,
          AND/OR SERVICES OBTAINING THROUGH THIS WEBSITE, OR OTHERWISE ARISING
          OUT OF OR IN CONNECTION WITH THE ACCESS TO, DISPLAY OF, OR USE OF THIS
          WEBSITE, REGARDLESS OF THE LEGAL THEORY OR BASIS OF SUCH DAMAGES,
          LOSSES, OR CLAIM, INCLUDING WHETHER BASED ON NEGLIGENCE, CONTRACT,
          TORT, STRICT LIABILITY, CONSUMER PROTECTION STATUTES, OR OTHERWISE,
          AND EVEN IF THE TRAVELPASS.COM COMPANIES AND/OR THEIR RESPECTIVE
          SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OR LIKELIHOOD OF ANY
          SUCH DAMAGES OR LOSSES).
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          IF, DESPITE THE LIMITATIONS ABOVE, THE TRAVELPASS.COM COMPANIES OR
          THEIR RESPECTIVE SUPPLIERS ARE FOUND RESPONSIBLE OR LIABLE FOR ANY
          DAMAGES OR LOSSES WHICH ARISE OUT OF OR IN ANY WAY ARE CONNECTED WITH
          ANY OF THE OCCURRENCES DESCRIBED ABOVE, THEN THE RESPONSIBILITY OR
          LIABILITY OF THE TRAVELPASS.COM COMPANIES AND/OR THEIR RESPECTIVE
          SUPPLIERS WILL IN NO EVENT EXCEED, IN THE AGGREGATE, THE GREATER OF
          (A) THE SERVICE FEES YOU PAID TO TRAVELPASS.COM IN CONNECTION WITH
          SUCH TRANSACTION(S) ON THIS WEBSITE, OR (B) ONE-HUNDRED DOLLARS
          (US$100.00) OR THE EQUIVALENT IN THE APPLICABLE LOCAL CURRENCY.
        </strong>
      </div>
      <div className={bodyCss}>
        <strong>
          THE DISCLAIMERS AND LIMITATIONS OF LIABILITY ABOVE REFLECT THE
          ALLOCATION OF RISK BETWEEN YOU AND THE TRAVELPASS.COM COMPANIES AND
          THEIR RESPECTIVE SUPPLIERS. THE DISCLAIMERS AND LIMITATIONS OF
          LIABILITY ABOVE WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY
          SPECIFIED IN THESE TERMS AND CONDITIONS IS FOUND TO HAVE FAILED OF ITS
          ESSENTIAL PURPOSE. THE DISCLAIMERS AND LIMITATIONS OF LIABILITY ABOVE
          INURE TO THE BENEFIT OF THE TRAVELPASS.COM COMPANIES AND/OR THEIR
          RESPECTIVE SUPPLIERS.
        </strong>
      </div>
      <h4 className={titleCss}>Indemnification</h4>
      <div className={bodyCss}>
        <strong>
          YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE TRAVELPASS.COM
          COMPANIES AND THEIR RESPECTIVE SUPPLIERS, AND ANY AND ALL OF THEIR
          RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS, FROM AND
          AGAINST ANY AND ALL CLAIMS, CAUSES OF ACTION, PROCEEDINGS, DEMANDS,
          RECOVERIES, LOSSES, DAMAGES, FINES, PENALTIES, AND OTHER COSTS AND
          EXPENSES OF ANY KIND OR NATURE, INCLUDING BUT NOT LIMITED TO
          REASONABLE LEGAL AND ACCOUNTING FEES, BROUGHT BY THIRD PARTIES AS A
          RESULT OF:
        </strong>
        <ol className={olCss}>
          <li className={liMarkerCss}>
            <strong>
              YOUR BREACH OF THESE TERMS AND CONDITIONS OR THE DOCUMENTS
              REFERENCED HEREIN;
            </strong>
          </li>
          <li className={liMarkerCss}>
            <strong>
              YOUR VIOLATION OF ANY LAW OR THE RIGHTS OF ANY THIRD PARTY; OR
            </strong>
          </li>
          <li className={liMarkerCss}>
            <strong>
              YOUR ACCESS TO OR USE OF THIS WEBSITE OR ANY OF THE SERVICES OR
              PRODUCTS AVAILABLE ON OR THROUGH THIS WEBSITE.
            </strong>
          </li>
        </ol>
      </div>
      <h4 className={titleCss}>Links to Third-Party Sites</h4>
      <div className={bodyCss}>
        This Website may contain hyperlinks to websites operated by parties
        other than Travelpass.com. Such hyperlinks are provided for your
        reference and convenience only. We do not control such websites and are
        not responsible or liable for any of their contents or the privacy or
        other practices of such websites. Further, it is up to you to take
        precautions to ensure that whatever links you select or software you
        download (whether from this Website or other websites) is free of
        viruses, worms, trojan horses, defects, and other items of a harmful or
        destructive nature. Our inclusion of such hyperlinks to such websites
        does not imply any endorsement of any of the products, services,
        materials, items, or information on or available through such websites
        or any association with their operators.
      </div>
      <h4 className={titleCss}>Patent Notices</h4>
      <div className={bodyCss}>
        One or more patents owned by the Travelpass.com Companies may apply to
        this Website and to the features and services available on or through
        this Website. Portions of this Website may operate under license of one
        or more patents owned by third parties. Other patents applicable to this
        Website may currently be pending.
      </div>
      <h4 className={titleCss}>General</h4>
      <div className={bodyCss}>
        Your use of mapping available on this Website is governed by the Google
        Terms and Conditions and Google Privacy Statement. Google reserves the
        right to change their Terms and Conditions and Privacy Statement at any
        time, at their sole discretion. Please click here for additional
        information:
      </div>
      <div className={bodyCss}>
        <Link
          label='https://policies.google.com/privacy'
          onClick={() => window.open('https://policies.google.com/privacy')}
        />
      </div>
      <div className={bodyCss}>
        <Link
          label='https://cloud.google.com/maps-platform/terms/other/universal-aup/'
          onClick={() =>
            window.open(
              'https://cloud.google.com/maps-platform/terms/other/universal-aup/'
            )
          }
        />
      </div>
      <div className={bodyCss}>
        <Link
          label='https://www.google.com/help/legalnotices_maps/'
          onClick={() =>
            window.open('https://www.google.com/help/legalnotices_maps/')
          }
        />
      </div>
      <div className={bodyCss}>
        <Link
          label='https://maps.google.com/help/terms_maps/'
          onClick={() =>
            window.open('https://maps.google.com/help/terms_maps/')
          }
        />
      </div>
      <div className={bodyCss}>
        These Terms and Conditions are governed by the Federal Arbitration Act,
        federal arbitration law, and for reservations made by U.S. residents,
        the laws of the state in which your billing address is located, without
        regard to principles or rules of conflicts or choice of laws. Use of
        this Website is unauthorized in any jurisdiction or country that does
        not give effect to all provisions of this Agreement, including, without
        limitation, this paragraph.
      </div>
      <div className={bodyCss}>
        You acknowledge and agree that no joint venture, partnership, franchise,
        or employment relationship exists between you and the Travelpass.com
        Companies as a result of this Agreement or your access to or use of this
        Website.
      </div>
      <div className={bodyCss}>
        Our performance of this Agreement is subject to all existing laws and
        legal process, and nothing contained in this Agreement limits our right
        to comply with law enforcement or other governmental or legal requests
        or requirements relating to your access to or use of this Website or
        information provided to or gathered by us with respect to such access or
        use.
      </div>
      <div className={bodyCss}>
        If any part of this Agreement is found to be invalid, illegal, or
        unenforceable, the validity, legality, and enforceability of the
        remaining provisions of this Agreement will not in any way be affected
        or impaired by such finding. Our failure or delay in enforcing any
        provision of this Agreement at any time does not waive our right to
        enforce the same or any other provision(s) hereof in the future.
      </div>
      <div className={bodyCss}>
        This Agreement (and any other terms and conditions or policies
        referenced herein) constitutes the entire agreement between you and
        Travelpass.com with respect to this Website, our services, products, and
        your dealings and relationships with us, and it supersedes all prior or
        contemporaneous communications and proposals, whether electronic, oral,
        or written, between you and us regarding this Website, our services,
        products, and your dealings and relationships with us. A printed version
        of this Agreement and of any notice given by us to you hereunder in
        electronic form shall be admissible in judicial, arbitration, or any
        other administrative proceedings to the same extent and subject to the
        same conditions as other business documents and records originally
        generated and maintained in printed form. You may not and shall not
        assign or transfer this Agreement, or subcontract or delegate any your
        rights, duties, or obligations under this Agreement, to any third party
        without our express prior written consent. We may assign or transfer
        this Agreement, and subcontract or delegate any our rights, duties, or
        obligations under this Agreement, to any third party. The terms and
        conditions of this Agreement which are expressly stated to survive, or
        which by their nature are reasonably intended to survive, the expiration
        or termination of this Agreement shall survive the expiration or
        termination of this Agreement.
      </div>
      <div className={bodyCss}>
        Fictitious names of companies, products, people, characters, data,
        and/or events mentioned on this Website are not intended to represent
        any real or actual company, product, people, character, data, and/or
        event.
      </div>
      <div className={bodyCss}>
        Any rights not expressly granted herein are reserved by the
        Travelpass.com Companies and/or their respective suppliers, as
        applicable.
      </div>
      <h4 className={titleCss}>How to Contact Us</h4>
      <div className={bodyCss}>
        If you have any questions, concerns or comments, please{' '}
        <Link label='click here' onClick={() => navigate(supportPath)} />.
        Travelpass.com is a website owned, maintained, and operated by
        Travelpass Group, Inc. located at 4700 West Daybreak Parkway STE 100 N,
        South Jordan, UT 84009.
      </div>
      <div className={bodyCss}>
        <p className={endOfTermsCss}>END OF TERMS AND CONDITIONS</p>
      </div>
    </div>
  )
}

const titleCss = 'type-h5 mt-16 mb-4 uppercase c-forestDark'

const bodyCss = 'type-body-1 mt-4'

const olCss = 'my-4 mx-auto'
const liCss = 'py-1.5 px-0'
const liMarkerCss = `${liCss} font-bold`

const contentCss = 'pb-20'

const endOfTermsCss = 'text-center mt-16'
