import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isEmpty from 'lodash.isempty'
import type {
  Event,
  GetTripEventsQueryInTripsQuery,
  Trip,
} from 'src/__generated__/graphql'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { getArrivalDate, getDepartureDate } from 'src/utils'
import type { GenericEventType } from '../constants'
import { DrawerType } from '../constants'
import type { TripMapPoint } from '../types'
dayjs.extend(isBetween)

const getEventType = (type: string) => {
  if (type === 'stay') return EventType.Stay
  else if (type === 'experience') return EventType.Experience
  else if (type === 'transportation') return EventType.Transportation
  else if (type === 'food_and_drink') return EventType.FoodAndDrink
  else if (type === 'shopping') return EventType.Shopping
  else if (type === 'flight') return EventType.Flight
  else return EventType.Miscellaneous
}

const constructTripDetailsDates = ({ arrival, departure, timezone }) => {
  const updatedArrival = getArrivalDate({
    date: arrival,
    format: 'MMM D',
    timezone,
  })
  const isSameMonth = dayjs(departure).isSame(dayjs(arrival), 'month')

  const departureComparator = getDepartureDate({
    date: departure,
    format: 'MMM D',
    timezone,
  })

  const updatedDeparture = getDepartureDate({
    date: departure,
    format: isSameMonth ? 'D' : 'MMM D',
    timezone,
  })

  return updatedArrival === departureComparator
    ? `${updatedArrival}`
    : `${updatedArrival} - ${updatedDeparture}`
}

// TODO test this
const getDrawerType = (event: GenericEventType | Event) => {
  const {
    status,
    type,
    isCustom,
    productId,
    addresses,
    booking,
    experienceBooking,
  } = event ?? null

  const isHotel = type === EventType.Stay
  const isExperiences = type === EventType.Experience
  const notBooked =
    !status || status === EventStatus.NotBooked || status === EventStatus.Na

  if (isExperiences && (experienceBooking?.length ?? 0) > 0) {
    return DrawerType.InternalExperienceBooked
  }

  if (isHotel && status === EventStatus.Booked && !isEmpty(booking)) {
    return DrawerType.InternalHotelBooked
  }

  if (isHotel && productId) {
    return DrawerType.InternalHotelUnbooked
  }

  if (isHotel && notBooked && !productId && !isCustom) {
    return DrawerType.ExternalHotel
  }

  if (isExperiences && !addresses?.[0]?.googlePlaceId && productId) {
    return DrawerType.InternalExperienceUnbooked
  }

  if (isCustom) {
    switch (type) {
      case EventType.Miscellaneous:
        return DrawerType.EventCustom
      case EventType.Transportation:
        return DrawerType.EventTransportation
      case EventType.Flight:
        return DrawerType.EventFlight
    }
  }

  return DrawerType.ExternalExperience
}

/** @todo remove in TMNT-2348 */
const isEventActive = ({
  tripStartDate,
  eventStartDate,
  eventEndDate,
  timezone,
  tabIndex,
}: {
  tripStartDate: string
  eventStartDate: string
  eventEndDate: string
  timezone: string
  tabIndex: number
}): boolean => {
  const current = dayjs.tz(tripStartDate, timezone).add(tabIndex, 'day')
  const start = dayjs.tz(eventStartDate, timezone)
  const end = dayjs.tz(eventEndDate, timezone)

  return (
    current.isBetween(start, end, 'day') ||
    current.isSame(start, 'day') ||
    current.isSame(end, 'day')
  )
}

const getTripEventMarkers = ({
  activeTabIndex = 0,
  scheduled,
  startDate,
  timeZone,
}: {
  activeTabIndex?: number
  scheduled: GetTripEventsQueryInTripsQuery['getTrip']['dailyEventBreakdown']['scheduled']
  startDate: Trip['startDate']
  timeZone: Trip['timeZone']
}) => {
  if (isEmpty(scheduled) || !startDate || !timeZone) return []
  const locationBasedEventsWithActiveFlag = (
    scheduled?.flatMap(schedule => schedule?.events) ?? []
  )
    .filter(
      event =>
        !!event?.addresses?.[0]?.long &&
        !!event?.addresses?.[0]?.lat &&
        !event?.isDeleted
    )
    .map(event => ({
      active: isEventActive({
        tripStartDate: startDate,
        eventStartDate: event.startDate ?? '',
        eventEndDate: event.endDate ?? '',
        tabIndex: activeTabIndex,
        timezone: timeZone ?? '',
      }),
      lat: parseFloat(event?.addresses?.[0]?.lat ?? '0'),
      lng: parseFloat(event?.addresses?.[0]?.long ?? '0'),
      title: event.name,
      id: event.id,
      type: event.type,
      drawerType: getDrawerType(event),
      imageUrl: event.imageUrl,
      totalReviews: 0,
      customerReviewScore: 0,
    }))
  const deduped = locationBasedEventsWithActiveFlag.reduce(
    (acc, item) => {
      if (acc[item.id]) {
        acc[item.id] = {
          ...item,
          active: item.active || acc[item.id].active,
        }
      } else {
        acc[item.id] = item
      }
      return acc
    },
    {} as {
      [key: string]: TripMapPoint
    }
  )

  return Object.values(deduped)
}

export {
  constructTripDetailsDates,
  getEventType,
  getDrawerType,
  getTripEventMarkers,
}
