import classNames from 'classnames'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { FooterLink } from 'src/common/components/Footer/FooterLink'
import { privacyPath, termsPath } from 'src/constants'

export const FooterLegal = ({ className }: { className?: string }) => {
  const currentYear = dayjs().format('YYYY')
  const navigate = useNavigate()

  return (
    <div
      className={classNames(
        'flex flex-col items-center lg:flex-row lg:gap-4',
        className
      )}
    >
      <p className='color-beach text-body-1'>
        &copy; {currentYear} Travelpass, Inc. All rights reserved.
      </p>
      <div className='flex items-center gap-4'>
        <FooterLink label='Terms of Use' onClick={() => navigate(termsPath)} />
        <span>|</span>
        <FooterLink
          label='Privacy Policy'
          onClick={() => navigate(privacyPath)}
        />
      </div>
    </div>
  )
}
