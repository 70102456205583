import { Icon } from '@travelpass/design-system'

export const MyTripsAbout = () => (
  <section className='lg:py-25 lg:px-30 flex flex-col items-center justify-center gap-12 py-16 md:gap-4 lg:flex-row-reverse lg:items-start lg:justify-between'>
    <div className='h-67 md:h-121 lg:h-138 flex w-full flex-row items-center justify-center px-1 md:px-16 lg:px-0'>
      <img
        alt='Trip Details Overview'
        className='h-full w-auto object-cover lg:h-auto lg:w-full'
        src='https://static.travelpass.com/assets/my-trips/my_trips_info.webp'
      />
    </div>

    <div className='lg:w-6/10 space-y-7 px-4 md:px-20 lg:px-0'>
      <header className='pb-7'>
        <h1 className='type-h2'>Create a unique trip just for you!</h1>
      </header>

      <article className='space-y-2.5'>
        <header className='c-forestLight flex flex-row items-center gap-3'>
          <Icon name='add' size='medium' />
          <h3 className='type-h4 c-forest'>Add events to trip</h3>
        </header>

        <p className='type-body-2 pl-9'>
          Effortlessly add events to your travel itinerary with our sleek
          timeline interface!
        </p>
      </article>

      <article className='space-y-2.5'>
        <header className='c-forestLight flex flex-row items-center gap-3'>
          <Icon name='add' size='medium' />
          <h3 className='type-h4 c-forest'>Instant Book</h3>
        </header>
        <p className='type-body-2 pl-9'>
          Unlock instant, hassle-free hotel bookings with our service!
          Experience comfort and convenience with premium stays ready the moment
          you decide to travel
        </p>
      </article>

      <article className='space-y-2.5'>
        <header className='c-forestLight flex flex-row items-center gap-3'>
          <Icon name='add' size='medium' />
          <h3 className='type-h4 c-forest'>Explore</h3>
        </header>
        <p className='type-body-2 pl-9'>
          Check out handpicked premium hotels and experiences in our
          &apos;Explore&apos; section, curated just for you
        </p>
      </article>
    </div>
  </section>
)
