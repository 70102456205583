import { Skeleton } from '@travelpass/design-system'

export const TripExploreCardLoading = () => (
  <section className='b-grey-300 b-solid b-1px p-l-33 of-hidden rounded-3 md:p-l-31 pointer-events-none relative'>
    <div className='of-hidden rounded-br-3 rounded-tr-3 w-33 md:w-31 absolute left-0 top-0 h-full'>
      <Skeleton />
    </div>
    <div className='min-h-23.5 flex flex-row justify-between p-3'>
      <div className='space-y-1'>
        <div className='h-4.5 w-65 lg:h-5'>
          <Skeleton />
        </div>
        <div className='color-grey-800 flex flex-row items-center gap-1'>
          <div className='h-2.5 w-40 lg:h-3'>
            <Skeleton />
          </div>
          <p className='type-small-text line-clamp-1'></p>
        </div>
      </div>
      <div className='m-y-auto'>
        <div className='h-8 w-8'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    </div>
  </section>
)
