type featureFlagNames =
  | 'alwaysRefundable'
  | 'appCtaEnabled'
  | 'basicTravelerSearch'
  | 'checkoutV2'
  | 'competitionPhaseTwoStarted'
  | 'contestPhase2'
  | 'dashboardAllFeed'
  | 'dashboardV3FeedNewNote'
  | 'disableVoting'
  | 'enableAddGuideOnGuideSearch'
  | 'enableApplySearchPrefFilters'
  | 'enableCompetition2024'
  | 'enableTravelersSearch'
  | 'enableSmsCompetition2024'
  | 'enableExplore'
  | 'enableQuickResponsePrototype'
  | 'enablePlanPage'
  | 'guideDraftMapFilters'
  | 'guideEventsMap'
  | 'guideLikesModal'
  | 'guideNewEventAdding'
  | 'guidePublishingV3'
  | 'isBookingSendSmsEnabled'
  | 'isDestinationCarouselEnabled'
  | 'isHotelRateProviderNameEnabled'
  | 'isInternationalGeocoderEnabled'
  | 'newSignUpFlow'
  | 'newVoteModal'
  | 'searchInTopNav'
  | 'tripExploreV2'
  | 'tripsHide'
  | 'votingModalV3'

const featureFlags: Record<featureFlagNames, boolean> = {
  alwaysRefundable: false,
  appCtaEnabled: false,
  basicTravelerSearch: false,
  checkoutV2: false,
  competitionPhaseTwoStarted: false,
  contestPhase2: false,
  dashboardAllFeed: false,
  dashboardV3FeedNewNote: false,
  disableVoting: false,
  enableAddGuideOnGuideSearch: false,
  enableApplySearchPrefFilters: false,
  enableCompetition2024: false,
  enableTravelersSearch: false,
  enableSmsCompetition2024: false,
  enableExplore: false,
  enableQuickResponsePrototype: false,
  enablePlanPage: false,
  guideDraftMapFilters: false,
  guideEventsMap: false,
  guideLikesModal: false,
  guideNewEventAdding: false,
  guidePublishingV3: false,
  isBookingSendSmsEnabled: false,
  isDestinationCarouselEnabled: false,
  isHotelRateProviderNameEnabled: false,
  isInternationalGeocoderEnabled: false,
  newSignUpFlow: false,
  newVoteModal: false,
  searchInTopNav: false,
  tripExploreV2: false,
  tripsHide: false,
  votingModalV3: false,
}

export type { featureFlagNames }
export { featureFlags }
