import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { getTripEventsQuery } from 'src/pages/trips/hooks'

const createEventMutation = gql(`
  mutation CreateEventMutationInTrips($eventInput: CreateEventInput!) {
    createEvent(eventInput: $eventInput) {
      id
      addresses {
        id
        addressLine1
        addressLine2
        city
        googlePlaceId
        long
        lat
        state
        zipcode
      }
      endDate
      insertedAt
      name
      notes
      productId
      startDate
      status
      trip {
        id
        name
      }
      type
    }
  }
`)

export const useCreateTripEventMutation = (tripId: string) =>
  useMutation(createEventMutation, {
    refetchQueries: [
      {
        query: getTripEventsQuery,
        variables: {
          nodeId: tripId,
        },
      },
    ],
  })
