import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetTripEventsQueryInTripsQuery } from 'src/__generated__/graphql'

const getTripEventsQuery = gql(`
  query GetTripEventsQueryInTrips($tripId: ID!) {
    getTrip(id: $tripId) {
      id
      dailyEventBreakdown {
        scheduled {
          dayName
          events {
            id            
            addresses {
              id
              addressLine1
              city
              googlePlaceId
              lat
              long
              state
            }
            booking {
              id
              hotelName
              hotelId
            }
            endDate
            experienceBooking {
              id
            }
            imageUrl
            isCustom
            isDeleted
            name
            notes
            productId
            status
            startDate
            type
            useEventTime
          }
        }
      }
      eventFlags {
        hasExperienceEvent
        hasFoodAndDrinkEvent
        hasStayEvent
      }
      name
      numberOfEvents
      numberOfStayEvents
    }
  }
`)

type UseGetTripEventsQuery = {
  hasError: ApolloError
  isLoading: boolean
  tripEventsData: GetTripEventsQueryInTripsQuery['getTrip']
}

const useGetTripEventsQuery = (tripId?: string): UseGetTripEventsQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getTripEventsQuery, {
    skip: !tripId,
    variables: {
      tripId: tripId ?? '',
    },
  })

  return {
    hasError,
    isLoading,
    tripEventsData: data?.getTrip ?? null,
  }
}

export { getTripEventsQuery, useGetTripEventsQuery }
