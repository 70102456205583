import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  InlineDatePicker,
  Modal,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { TripDetailsDrawerFooter } from 'src/pages/trips/components'
import {
  TripSearchParams,
  dateTemplate,
  timeTemplate,
} from 'src/pages/trips/constants'
import {
  useGetTripDetailsQuery,
  useTripsDetailsCreateEvent,
} from 'src/pages/trips/hooks'
import {
  getTripTimelineActiveDates,
  pushTripEventCreateToDataLayer,
} from 'src/pages/trips/utils'
import {
  formatDate,
  generateExperienceDetailsUrl,
  getArrivalDate,
  getDateDiff,
  getDateFromUTC,
  getDepartureDate,
  stringToNumber,
} from 'src/utils'
/** @todo move into common util */
import { constructExperienceEventArguments } from '../../explore-old/utils'

interface TripDetailsExploreDrawerExperienceFooterProps {
  item: ExperienceProduct
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  tripId: string
  onEventAdded?: () => void
}

export const TripDetailsExploreDrawerExperienceFooter = ({
  item,
  setScrollToEventId,
  tripId,
  onEventAdded,
}: TripDetailsExploreDrawerExperienceFooterProps) => {
  const { isLoading, tripDetailsData } = useGetTripDetailsQuery(tripId)
  const [date, setDate] = useState<Dayjs>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [createEvent, { loading: isCreateEventLoading }] =
    useTripsDetailsCreateEvent()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    endDate: initialEndDate,
    name: tripName,
    startDate: initialStartDate,
    timeZone,
  } = tripDetailsData ?? {}

  useEffect(() => {
    const activeTabIndex = stringToNumber(
      searchParams.get(TripSearchParams.dateTab) ?? '0'
    )
    const activeDates = getTripTimelineActiveDates({
      activeTabIndex,
      endDate: initialEndDate,
      startDate: initialStartDate,
      timeZone,
    })
    const activeStartDate = dayjs(formatDate(dayjs(activeDates.startDate)))

    if (activeStartDate && timeZone)
      setDate(dayjs(formatDate(dayjs(activeDates.startDate))))
  }, [initialEndDate, initialStartDate, timeZone])

  if (isLoading || !tripDetailsData) return null

  const endDate = dayjs(getDateFromUTC(initialEndDate, timeZone))
  const startDate = dayjs(getDateFromUTC(initialStartDate, timeZone))
  const dateTime = startDate.format(timeTemplate)
  const formattedEndDate = dayjs(formatDate(endDate))
  const formattedStartDate = dayjs(formatDate(startDate))
  const { id, title } = item ?? {}

  const onAddToTripClick = async () => {
    try {
      setIsModalOpen(false)
      const updatedDate = dayjs(
        `${formatDate(date)}${dateTime}`,
        `${dateTemplate}${timeTemplate}`
      )
        .utc()
        .format()
      const newEvent = await createEvent(
        constructExperienceEventArguments({
          endDate: updatedDate,
          id: tripId,
          startDate: updatedDate,
          tripDetailsExploreExperienceData: item,
        })
      )
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant:
          'TripDetailsExploreDrawerExperienceFooter AddToTripButton',
        tripId,
        tripName,
      })
      const updatedActiveTabIndex =
        getDateDiff([
          formatDate(getDateFromUTC(updatedDate, timeZone)),
          formatDate(formattedStartDate),
        ]) ?? 0
      searchParams.delete(TripSearchParams.activeExploreId)
      searchParams.delete(TripSearchParams.eventId)
      searchParams.delete(TripSearchParams.eventType)
      searchParams.set(
        TripSearchParams.dateTab,
        updatedActiveTabIndex.toString()
      )
      setSearchParams(searchParams, {
        replace: true,
      })
      addSuccessSnack({ title: 'Added to Timeline' })

      if (setScrollToEventId && newEvent?.data?.createEvent?.id) {
        setScrollToEventId(newEvent.data.createEvent.id)
      }

      onEventAdded?.()
    } catch (error) {
      console.error(error)
      addErrorSnack({ title: 'Server error' })
    }
  }

  const onBookClick = () =>
    window.open(
      generateExperienceDetailsUrl({
        arrival: getArrivalDate({ date: startDate, timezone: timeZone }),
        departure: getDepartureDate({ date: endDate, timezone: timeZone }),
        id,
        name: title,
        tripId,
      })
    )

  return (
    <>
      {isModalOpen && (
        <Modal size='medium' onDismiss={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-8 py-8'>
            <InlineDatePicker
              fromDate={formattedStartDate.toDate()}
              selected={date}
              toDate={formattedEndDate.toDate()}
              onSelect={updatedDate => setDate(updatedDate)}
            />
            <Button
              aria-label='Add experience to trip'
              size='medium'
              onClick={onAddToTripClick}
            >
              Add to Trip
            </Button>
          </div>
        </Modal>
      )}
      <TripDetailsDrawerFooter>
        {isCreateEventLoading ? (
          <div className='flex h-12 flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        ) : (
          <Button
            fullWidth
            aria-label='Add experience to trip'
            size='large'
            variant='outlined'
            onClick={() => setIsModalOpen(true)}
          >
            Add to Trip
          </Button>
        )}
        <Button
          fullWidth
          aria-label='Book experience now'
          size='large'
          onClick={onBookClick}
        >
          Book Now
        </Button>
      </TripDetailsDrawerFooter>
    </>
  )
}
