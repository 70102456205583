import { useSearchParams } from 'react-router-dom'
import { getProductTypesAsObject } from 'src/utils'
import { TripDetailsExploreSearchExperiencesLoading } from './TripDetailsExploreSearchExperiences'
import { ExploreSearchParams } from '../../constants'

export const TripDetailsExploreSearchLoading = () => {
  const [searchParams] = useSearchParams()
  const { isExperience } = getProductTypesAsObject(
    searchParams.get(ExploreSearchParams.productType) ?? ''
  )

  if (isExperience) return <TripDetailsExploreSearchExperiencesLoading />

  // TODO: <TripDetailsExploreSearchHotelsLoading />
  return null
}
