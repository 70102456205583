import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_EXPERIENCE_PRODUCT_BY_ID_QUERY = gql(`
query GetExperienceProductById($nodeId: ID!) {
  node(id: $nodeId) {
    ... on ExperienceProduct {
      id
      cancellationPolicy {
        type
      }
      description
      destinations {
        destinationName
      }
      images {
        size720x480
      }
      itinerary {
        ... on ExperienceStandardItinerary {
          duration {
            fixedDurationInMinutes
            readableDurationRange
          }
          itineraryType
        }
        ... on ExperienceActivityItinerary {
          duration {
            fixedDurationInMinutes
            readableDurationRange
          }
          itineraryType
        }
        ... on ExperienceUnstructuredItinerary {
          duration {
            fixedDurationInMinutes
            readableDurationRange
          }
          itineraryType
        }
        ... on ExperienceHopOnHopOffItinerary {
          duration {
            fixedDurationInMinutes
            readableDurationRange
          }
          itineraryType
        }
      }
      languageGuides {
        language
        type
      }
      logistics {
        start {
          location {
            address
            latitude
            longitude
          }
        }
        travelerPickup {
          locations {
            pickupType
            location {
              latitude
              longitude
            }
          }
        }
      }
      reviews {
        combinedAverageRating
        totalReviews
      }
      ticketInfo {
        ticketTypeDescription
      }
      title
      productCode
    }
  }
}
`)

export const useLazyGetExperienceProductById = () =>
  useLazyQuery(GET_EXPERIENCE_PRODUCT_BY_ID_QUERY)
