import type { ReactNode } from 'react'
import classNames from 'classnames'

interface TripMapWrapperProps {
  children: ReactNode
  isDrawerOpen: boolean
}

export const TripMapWrapper = ({
  children,
  isDrawerOpen,
}: TripMapWrapperProps) => {
  return (
    <div
      className={classNames(
        'bg-grey-100 lg:top-77px lg:transition-margin-175 h-[calc(100svh-147px)] grow lg:sticky lg:h-[calc(100svh-77px)] lg:ease-[cubic-bezier(0,0,0.2,1)]',
        {
          'lg:-m-l-119': !isDrawerOpen,
        }
      )}
    >
      {children}
    </div>
  )
}
