import { Skeleton } from '@travelpass/design-system'
import { HeroLoading } from 'src/pages/trips/components/TripDrawerContent/components/Hero'
import { GuideDrawerOldContentFooterLoading } from './GuideDrawerOldContentFooterLoading'
import { GuideDrawerOldContentNotesLoading } from './GuideDrawerOldContentNotesLoading'

export const GuideDrawerOldContentLoading = ({
  isEdit,
}: {
  isEdit: boolean
}) => (
  <>
    <div className='grow space-y-4 overflow-y-auto'>
      <HeroLoading />
      <div className='p-x-9 space-y-4'>
        <div className='space-y-3'>
          <div className='lg:h-6.75 h-4.5 h-5.75 w-65'>
            <Skeleton />
          </div>
          <div className='lg:h-4.375 h-4.125 w-25'>
            <Skeleton />
          </div>
          <div className='lg:h-4.375 w-50 h-4.125'>
            <Skeleton />
          </div>
          <div className='lg:h-4.375 h-4.125 w-60'>
            <Skeleton />
          </div>
        </div>
        <GuideDrawerOldContentNotesLoading />
      </div>
    </div>
    <GuideDrawerOldContentFooterLoading isEdit={isEdit} />
  </>
)
