import { Skeleton } from '@travelpass/design-system'

export const HotelHeroApolloLoading = () => {
  return (
    <div className='flex w-full items-center justify-center'>
      <div className='max-w-100vw grid w-full grid-cols-1 gap-2 lg:w-[1440px] lg:grid-cols-5'>
        <div className='relative col-span-1 h-[300px] lg:col-span-3 lg:h-[564px]'>
          <Skeleton />
        </div>
        <div className='col-span-1 lg:col-span-2'>
          <div className='grid h-[70px] grid-cols-4 grid-rows-1 gap-2 lg:h-[564px] lg:grid-cols-2 lg:grid-rows-2'>
            <div>
              <Skeleton />
            </div>
            <div>
              <Skeleton />
            </div>
            <div>
              <Skeleton />
            </div>
            <div>
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
