import classNames from 'classnames'
import { getPriceWithDigits } from 'src/utils'
import {
  experiencesPriceSummaryRowCss,
  variantStyles,
} from '../experiencesPriceSummaryStyles'
import type { ExperiencesPriceSummaryRowProps } from '../types'

const ExperiencesPriceSummaryRow = ({
  title,
  variant = 'text',
  value,
}: ExperiencesPriceSummaryRowProps) => (
  <div
    className={experiencesPriceSummaryRowCss({ variant })}
    data-testid='ExperiencesPriceSummaryRow'
  >
    <div className={experiencesPriceSummaryRowTitleCss({ variant })}>
      {title}
    </div>
    <div className={experiencesPriceSummaryRowValueCss({ variant })}>
      {getPriceWithDigits(value)}
    </div>
  </div>
)

const experiencesPriceSummaryRowTitleCss = ({
  variant,
}: Partial<ExperiencesPriceSummaryRowProps>) =>
  classNames(variantStyles({ variant }), {
    'text-h6': variant === 'title',
  })

const experiencesPriceSummaryRowValueCss = ({
  variant,
}: Partial<ExperiencesPriceSummaryRowProps>) =>
  classNames(variantStyles({ variant }), {
    'font-roboto text-subtitle-1': variant === 'title',
  })

export { ExperiencesPriceSummaryRow }
