import { useEffect } from 'react'
import { MarkerWrapper } from 'src/common/components/Map'
import { StyledPlaceMarker } from 'src/pages/trips/components'
import { useGetGuidePlaceDetailsImagesLazyQuery } from 'src/pages/trips/hooks'
import { getEventImage } from 'src/pages/trips/utils'
import { getGuidePlaceLatLng } from 'src/utils'
import { GuideDraftMapPlaceMarkerPopup } from './GuideDraftMapPlaceMarkerPopup'
import { onGuideSessionStorageHoverIdChange } from '../../../details'

interface GuideDraftMapPlaceMarkerProps {
  isHovered: boolean
  isSelected: boolean
  onAddEvent: VoidFunction
  onClick: (point: google.maps.places.PlaceResult) => void
  point: google.maps.places.PlaceResult
}

export const GuideDraftMapPlaceMarker = ({
  isHovered,
  isSelected,
  onAddEvent,
  onClick,
  point,
}: GuideDraftMapPlaceMarkerProps) => {
  const [getPlaceDetailsImages, { data }] =
    useGetGuidePlaceDetailsImagesLazyQuery()
  const {
    formatted_address: address,
    geometry,
    place_id: googlePlaceId,
    name,
    rating,
    website,
  } = point ?? {}
  const { lat, lng } = getGuidePlaceLatLng(geometry?.location)

  useEffect(() => {
    if (isHovered)
      getPlaceDetailsImages({
        variables: {
          placeDetailsRequest: {
            placeId: googlePlaceId,
          },
        },
      })
  }, [isHovered])

  if (!lat || !lng) return

  return (
    <>
      <MarkerWrapper
        isHovered={isHovered || isSelected}
        position={{
          lat,
          lng,
        }}
        onClick={() => onClick(point)}
      >
        <div
          className='relative'
          onMouseEnter={() => onGuideSessionStorageHoverIdChange(googlePlaceId)}
          onMouseLeave={() => onGuideSessionStorageHoverIdChange('')}
        >
          <StyledPlaceMarker isHovered={isHovered} isSelected={isHovered} />
          {isHovered && (
            <div className='absolute top-[calc(100%+8px)] hidden -translate-x-2/4 md:block'>
              <GuideDraftMapPlaceMarkerPopup
                placeDetailsData={{
                  getPlaceDetails: {
                    address,
                    googlePlaceId,
                    imageLinks: [
                      getEventImage(data?.getPlaceDetails?.imageLinks?.[0]),
                    ],
                    latitude: lat?.toString(),
                    longitude: lng?.toString(),
                    name,
                    rating: rating?.toString(),
                    website,
                  },
                }}
                onAddEvent={onAddEvent}
              />
            </div>
          )}
        </div>
      </MarkerWrapper>
      {isHovered && (
        <div className='z-2 absolute bottom-0 left-0 right-0 md:hidden'>
          <GuideDraftMapPlaceMarkerPopup
            placeDetailsData={{
              getPlaceDetails: {
                address,
                googlePlaceId,
                imageLinks: [
                  getEventImage(data?.getPlaceDetails?.imageLinks?.[0]),
                ],
                latitude: lat?.toString(),
                longitude: lng?.toString(),
                name,
                rating: rating?.toString(),
                website,
              },
            }}
            onAddEvent={onAddEvent}
          />
        </div>
      )}
    </>
  )
}
