import type { ReactNode } from 'react'
import { Title } from '../Title/Title'

interface LegalLayoutProps {
  caption: string
  children: ReactNode
  subtitle?: string
  title: string
}

export const LegalLayout = ({
  caption,
  children,
  subtitle,
  title,
}: LegalLayoutProps) => (
  <div>
    <Title caption={caption} subtitle={subtitle} title={title} />
    {children}
  </div>
)
