import { useState } from 'react'
import { Link, Modal, ModalScrollContents } from '@travelpass/design-system'
import type { CarouselProps } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import type { MappedImage } from 'src/__generated__/graphql'
import { HotelRoomCarousel } from '../common'
import type { HotelRoom } from '../hotelRoomsTypes'

interface HotelRoomModalProps {
  hotelRoomData: HotelRoom
  images: HotelRoom['images']
  slideIndex?: CarouselProps['slideIndex']
  onClose(): void
  onImageError?(errorImage: MappedImage): void
}

export const HotelRoomModal = ({
  hotelRoomData,
  images,
  slideIndex,
  onClose,
  onImageError,
}: HotelRoomModalProps) => {
  const [showAll, setShowAll] = useState(!isMobile)
  const { amenities, description, name } = hotelRoomData ?? {}
  const constructAmenities = [...amenities]
    .slice(0, showAll ? amenities.length : 6)
    .sort()
  const constructedAmenities = constructAmenities.map(amenity => (
    <li key={amenity} className='truncate'>
      {amenity}
    </li>
  ))
  const constructedHeight = 'h-45 md:h-97'

  return (
    <Modal size='medium' title={name} onDismiss={onClose}>
      <ModalScrollContents>
        <div className={hotelRoomModalCss}>
          {/* carousel dots are hidden until the design system is updated to allow controlling the number of dots */}
          <HotelRoomCarousel
            keyBoardControl
            altSuffix={`${name} - modal`}
            height={constructedHeight}
            hideDots={true}
            images={images}
            slideIndex={slideIndex}
            onImageError={onImageError}
          />
          <div>{description}</div>
          <div className='flex flex-col gap-6'>
            <h6 className={hotelRoomModalTitleCss}>Amenities</h6>
            <ul className={hotelRoomModalItemsCss}>{constructedAmenities}</ul>
            {isMobile && (
              <Link
                label={showAll ? 'See less' : 'See all'}
                onClick={() => setShowAll(!showAll)}
              />
            )}
          </div>
        </div>
      </ModalScrollContents>
    </Modal>
  )
}

const hotelRoomModalCss = 'flex flex-col gap-6'

const hotelRoomModalItemsCss =
  'gap-x-4 grid grid-auto-flow-dense grid-cols-1 list-inside m-0 p-0 text-body-1 md:grid-cols-2'

const hotelRoomModalTitleCss = 'text-h6'
