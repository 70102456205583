import { useApolloClient, useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getGuideEventsQuery } from '../details/useGetGuideEventsQuery'

const createGuideFromGuideDraftMutation = gql(`
  mutation CreateGuideFromGuideDraft($input: CreateGuideFromGuideDraftInput!) {
    createGuideFromGuideDraft(input: $input) {
      guide {
        id
        guideDraft {
          id
          hasUnpublishedChanges
        }
        description
        firstPublishedAt
        images {
          id
          source
          url
        }
        name
        owner {
          id
        }
        status
        tags {
          id
          name
        }
        updatedAt
      }
    }
  }
`)

export const useCreateGuideFromGuideDraftMutation = (guideId: string) => {
  const apolloClient = useApolloClient()

  return useMutation(createGuideFromGuideDraftMutation, {
    refetchQueries: [
      {
        query: getGuideEventsQuery,
        variables: {
          id: guideId,
        },
      },
    ],
    onCompleted: data => {
      const { guideDraft, description, id, name, owner, status, updatedAt } =
        data?.createGuideFromGuideDraft?.guide ?? {}

      if (!id) return

      pushDataToDataLayer('guide_publish', {
        guide_description: description,
        guide_draft_id: guideDraft?.id,
        guide_id: id,
        guide_name: name,
        guide_status: status,
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })
    },
    update: (cache, { data }) => {
      const { id } = data?.createGuideFromGuideDraft?.guide?.guideDraft ?? {}

      if (!id) return

      cache.modify({
        id: apolloClient.cache.identify({
          id,
          __typename: 'GuideDraft',
        }),
        fields: {
          hasUnpublishedChanges() {
            /** @todo hardcoding `hasUnpublishedChanges` for now until we have a better way to handle this */
            return false
          },
        },
      })
    },
  })
}
