import { Button } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import {
  BookingDetailsSectionHeader,
  HotelCancellationCard,
  HotelPriceSummary,
} from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import type { BookingDetailsPriceNode } from './types'
import { bookingDetailsPriceCss } from '../../constants'
import {
  useBookingDetailsCancellationPolicyQuery,
  useBookingDetailsDatesQuery,
  useBookingDetailsIdsQuery,
  useBookingDetailsPoliciesQuery,
} from '../../hooks'

interface BookingDetailsPriceProps {
  bookingDetailsData: BookingDetailsPriceNode
  onPrintButtonClick(): void
}

export const BookingDetailsPrice = ({
  bookingDetailsData,
  onPrintButtonClick,
}: BookingDetailsPriceProps) => {
  const isInternationalGeocoderEnabled = useFlag(
    'isInternationalGeocoderEnabled'
  )
  const { bookingId } = useParams()
  const { bookingDetails, hotel, provider } = bookingDetailsData ?? {}
  const {
    allInTotal,
    additionalFees,
    dueLater,
    dueNow,
    subtotal,
    surchargeTotal,
    surcharges,
  } = bookingDetails?.price ?? {}
  const { bookingDetailsCancellationPolicyData } =
    useBookingDetailsCancellationPolicyQuery({ bookingId })
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = bookingDetailsCancellationPolicyData?.bookingDetails?.cancelPolicy ?? {}
  const { hotelId } = bookingDetailsIdsData ?? {}
  const { bookingDetailsPoliciesHotelData } = useBookingDetailsPoliciesQuery({
    bookingId,
    hotelId,
  })
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({ bookingId })
  const {
    arrival,
    bookingDetails: bookingDetailsDates,
    departure,
  } = bookingDetailsDatesData ?? {}
  const { occupancies } = bookingDetailsDates ?? {}
  const { fees } = bookingDetailsPoliciesHotelData ?? {}
  const { policyText, shortText } = constructedCancellationPolicies ?? {}
  const constructedRooms = occupancies?.length ?? 1
  const showInternationalDisclaimer =
    !!isInternationalGeocoderEnabled &&
    hotel?.country !== 'United States of America'

  return (
    <div>
      <BookingDetailsSectionHeader title='Price Details' />
      <div className={bookingDetailsPriceCss}>
        <HotelPriceSummary
          additionalFees={additionalFees}
          allInTotal={allInTotal?.amount}
          arrival={arrival}
          departure={departure}
          dueLater={dueLater?.amount}
          dueNow={dueNow?.amount}
          hotelFees={fees}
          provider={provider}
          rooms={constructedRooms}
          showInternationalDisclaimer={showInternationalDisclaimer}
          subtotal={subtotal?.amount}
          surchargeTotal={surchargeTotal?.amount}
          surcharges={surcharges}
        />
        <div className='hide-on-print'>
          <HotelCancellationCard
            fullyRefundableUntil={fullyRefundableUntil}
            nonRefundableAfter={nonRefundableAfter}
            partiallyRefundableUntil={partiallyRefundableUntil}
            policies={policyText}
            refundType={refundType}
            subtitle={shortText}
          />
        </div>
        <div className='hide-on-print'>
          <Button
            label='Print Receipt'
            size='small'
            startIcon='print'
            variant='text'
            onClick={onPrintButtonClick}
          />
        </div>
      </div>
    </div>
  )
}
