import classNames from 'classnames'

type BadgeVariant = 'primary' | 'secondary'

interface HotelResultsFiltersBadgeProps {
  count: number
  variant?: BadgeVariant
}

export const HotelResultsFiltersBadge = ({
  count,
  variant = 'primary',
}: HotelResultsFiltersBadgeProps) =>
  !!count && (
    <span
      className={classNames(
        'color-white type-small-text-desktop rounded-1/2 flex flex-col justify-center text-center',
        {
          'bg-valley h-4 w-4': variant === 'primary',
          'bg-orange h-4.5 w-4.5': variant === 'secondary',
        }
      )}
      data-testid='HotelResultsFiltersBadge'
    >
      {count}
    </span>
  )
