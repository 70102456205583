import { Button } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { Link } from 'src/__generated__/graphql'
import { DashboardProfileInfoLinkForm } from './DashboardProfileInfoLinkForm'
import { DashboardProfileInfoLinkVisualizer } from './DashboardProfileInfoLinkVisualizer'
import type { DashboardProfileFields } from '../types'

export const DashboardProfileInfoLinks = () => {
  const { getValues, setValue, setError, clearErrors, trigger, watch } =
    useFormContext<DashboardProfileFields>()
  const links: Link[] = watch('links') ?? []

  const onAdd = async () => {
    clearErrors(['linkTitle', 'linkURL'])
    const linkTitle = getValues('linkTitle')
    const linkUrl = getValues('linkURL')

    // Check if the title field is empty
    if (!linkTitle) {
      // Manually set an error for the socialLink field
      setError('linkTitle', {
        type: 'manual',
        message: 'A title is required',
      })
      return
    }

    // Check if the linkURL field is empty
    if (!linkUrl) {
      // Manually set an error for the socialLink field
      setError('linkURL', {
        type: 'manual',
        message: 'A URL is required',
      })
      clearErrors(['linkTitle'])
      return
    }

    // If not empty, trigger validation for the linkTitle and linkURL fields
    const isValid = await trigger(['linkTitle', 'linkURL'])

    if (isValid) {
      // Proceed to add the link, clear the field, and clear any errors
      clearErrors(['linkTitle', 'linkURL'])

      let updatedLinks = [...links, { title: linkTitle, url: linkUrl }]
      setValue('links', updatedLinks)
      // Clear input fields
      setValue('linkURL', '')
      setValue('linkTitle', '')
    }
  }

  const isRepeatedLink = (value: string) =>
    !!links?.find(({ url }) => value === url)

  return (
    <section className='space-y-6'>
      <header className='space-y-2'>
        <h6 className='type-h6-desktop c-black'>Add Additional Links</h6>
        <p className='type-body-2-desktop c-grey-800'>
          Add a few resources to your profile (link to a podcast, an Amazon
          store, Goodreads travel list, and more).
        </p>
      </header>
      {links?.map(({ title, url }, index) => (
        <DashboardProfileInfoLinkVisualizer
          key={`${title}-${index}`}
          index={index}
          links={links}
          title={title}
          url={url}
        />
      ))}
      <DashboardProfileInfoLinkForm isRepeatedLink={isRepeatedLink} />
      <Button size='small' startIcon='add' variant='text' onClick={onAdd}>
        Add New Link
      </Button>
    </section>
  )
}
