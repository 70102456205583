import { useEffect, useRef } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type {
  GetGuideEventsQueryInGuideDetailsQueryVariables,
  Guide,
} from 'src/__generated__/graphql'
import { useIsElementOnScreen } from 'src/common/hooks'
import type { GuideEventsData, GuideOwner } from '../../types'
import type { UseGetGuideEventsQuery } from '../../useGetGuideEventsQuery'
import { useGuideSessionStorageIds } from '../../useGuideSessionStorageIds'
import { GuideEventCard } from '../GuideEventCard'

interface GuideEventsProps {
  onGetMoreResults: UseGetGuideEventsQuery['onGetMoreResults']
  onSelectedIdChange(updatedSelectedId: string): void
  owner: GuideOwner
  paginatedEventCategoryId: string
  publishedEventConnection:
    | GuideEventsData['paginatedEventCategories']['edges'][0]['node']['publishedEvents']
    | GuideEventsData['uncategorizedPublishedEvents']
  selectedId: string
}

export const GuideEvents = ({
  onGetMoreResults,
  onSelectedIdChange,
  owner,
  paginatedEventCategoryId,
  publishedEventConnection,
  selectedId,
}: GuideEventsProps) => {
  const { hoverId } = useGuideSessionStorageIds()
  const fetchMoreRef = useRef({
    isLoading: false,
    cursor: '',
  })
  const lastElementRef = useRef<HTMLDivElement>(null)
  const isLastElementVisible = useIsElementOnScreen(lastElementRef, {
    threshold: 0.1,
  })
  const { endCursor, hasNextPage } = publishedEventConnection?.pageInfo ?? {}
  const debouncedGetMoreResults = debounce(
    async ({ after, id }: GetGuideEventsQueryInGuideDetailsQueryVariables) => {
      if (
        fetchMoreRef.current.isLoading ||
        after === fetchMoreRef.current.cursor
      )
        return

      fetchMoreRef.current.isLoading = true
      fetchMoreRef.current.cursor = after

      try {
        await onGetMoreResults({ after, id })
      } catch (error) {
        console.error(error)
      } finally {
        fetchMoreRef.current.isLoading = false
      }
    },
    300
  )

  useEffect(() => {
    if (isLastElementVisible && hasNextPage) {
      debouncedGetMoreResults({
        after: endCursor,
        id: paginatedEventCategoryId,
      })
    }
  }, [
    debouncedGetMoreResults,
    endCursor,
    hasNextPage,
    isLastElementVisible,
    paginatedEventCategoryId,
  ])

  if (!publishedEventConnection?.edges?.length) return

  return (
    <div className='space-y-6'>
      {publishedEventConnection?.edges?.map(({ node }) => (
        <GuideEventCard
          key={node?.id}
          guideEventCardData={node}
          isHovered={node?.id === hoverId}
          isSelected={node?.id === selectedId}
          owner={owner}
          onSelectedIdChange={onSelectedIdChange}
        />
      ))}
      {hasNextPage && (
        <div className='invisible m-0 h-0' ref={lastElementRef} />
      )}
      {fetchMoreRef.current.isLoading && <SkeletonDots />}
    </div>
  )
}
