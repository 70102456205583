import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import type { Area } from 'react-easy-crop'
import type { GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import {
  PresignedUrlType,
  ProfileImageSource,
  VisibilityLevel,
} from 'src/__generated__/graphql'
import { useGetPresignedUrl } from '../profile/components/hooks/useGetPresignedUrl'
import { useUpsertCurrentUserProfile } from '../profile/components/hooks/useUpsertCurrentUserProfile'
import { DEFAULT_PROFILE_IMAGE_URL } from '../profile/profileConstants'

const convertUrlToFile = async (
  imageUrl: string,
  fileName = 'croppedImage.jpeg'
): Promise<File> => {
  try {
    const response = await fetch(imageUrl)

    if (!response.ok) throw new Error('Network response was not ok')

    const blob = await response.blob()
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: new Date().getTime(),
    })

    return file
  } catch (error) {
    console.error('Error converting URL to file:', error)
    return null
  }
}

const createImage = (url: string): Promise<CanvasImageSource> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  flip = { horizontal: false, vertical: false }
): Promise<Blob> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (!context) return null

  // Set dimensions of the canvas to the dimensions of the cropping area
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // Fill the canvas with white color
  context.fillStyle = 'white'
  context.fillRect(0, 0, canvas.width, canvas.height)

  context.save() // Save the current state
  context.beginPath() // Start a new path
  context.rect(0, 0, canvas.width, canvas.height)
  context.clip() // Clip to the current path

  // Adjust for flipping
  if (flip.horizontal || flip.vertical) {
    context.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    context.translate(
      flip.horizontal ? -canvas.width : 0,
      flip.vertical ? -canvas.height : 0
    )
  }

  // Draw the image - ensuring it is centered in the cropped area
  context.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  context.restore() // Restore the original state (without the clip)

  return new Promise(resolve => {
    canvas.toBlob(file => resolve(file), 'image/jpeg')
  })
}

type UseDashboardProfileAvatar = {
  croppedAreaPixels: Area
  file: File
  isUpdateProfileInfoLoading: boolean
  onCroppedAreaPixelsChange: (updatedCroppedAreaPixels: Area) => void
  onDelete: () => void
  onFileChange: (updatedFile: File) => void
  onProfileImageSourceChange: (
    updatedProfileImageSource: ProfileImageSource
  ) => void
  onSubmit: () => void
  previewUrl: string
  profileImageSource: ProfileImageSource
}

const useDashboardProfileAvatar = ({
  onDismiss,
  userProfile,
}: {
  onDismiss: VoidFunction
  userProfile: GetCurrentUserQueryInDashboardQuery['currentUser']['userProfile']
}): UseDashboardProfileAvatar => {
  const [getPresignedUrl] = useGetPresignedUrl()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [file, setFile] = useState<File>(null)
  const [previewUrl, setPreviewUrl] = useState('')
  const [profileImageSource, setProfileImageSource] =
    useState<ProfileImageSource>(userProfile?.profileImageSource)
  const [updateProfileInfo, { loading: isUpdateProfileInfoLoading }] =
    useUpsertCurrentUserProfile()
  const { accountHandle, profileImageUrl, tags } = userProfile ?? {}

  useEffect(() => {
    let objectUrl: string

    if (croppedAreaPixels?.width) {
      const cropAndSetImage = async () => {
        try {
          const imgBlob = await getCroppedImg(
            previewUrl || profileImageUrl,
            croppedAreaPixels
          )
          objectUrl = URL.createObjectURL(imgBlob)
          onCroppedImageChange(objectUrl)
        } catch (error) {
          console.error('Error cropping image:', error)
        }
      }

      cropAndSetImage()
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [croppedAreaPixels, previewUrl, profileImageUrl])

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setPreviewUrl(objectUrl)

      // Cleanup: Revoke the object URL when it's no longer needed
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [file])

  const onCroppedAreaPixelsChange = (updatedCroppedAreaPixels: Area) =>
    setCroppedAreaPixels(updatedCroppedAreaPixels)

  const onCroppedImageChange = (updatedCroppedImage: string) =>
    setCroppedImage(updatedCroppedImage)

  const onDelete = async () => {
    if (profileImageSource === ProfileImageSource.DefaultImage) return

    if (profileImageSource === ProfileImageSource.ClaimsImage) {
      await updatePhoto(profileImageUrl, ProfileImageSource.ClaimsImage)
    } else {
      await updatePhoto(
        DEFAULT_PROFILE_IMAGE_URL,
        ProfileImageSource.DefaultImage
      )
    }
    onDismiss()
  }

  const onFileChange = (updatedFile: File) => setFile(updatedFile)

  const onProfileImageSourceChange = (
    updatedProfileImageSource: ProfileImageSource
  ) => setProfileImageSource(updatedProfileImageSource)

  const onSubmit = async () => {
    try {
      const croppedFile = await convertUrlToFile(croppedImage!)
      const updatedFile =
        profileImageSource !== ProfileImageSource.ClaimsImage
          ? croppedFile
          : file

      if (
        !updatedFile ||
        profileImageSource !== ProfileImageSource.UserUploadedImage
      )
        return onDismiss()

      const presignedUrlResponse = await getPresignedUrl({
        // Seems like we need to disable the cache here to get the latest presigned url
        fetchPolicy: 'no-cache',
        variables: {
          presignedUrlType: PresignedUrlType.ProfileImages,
        },
      })
      const presignedUrl = presignedUrlResponse.data?.getPresignedUrl?.url
      const updatedProfileImageUrl = presignedUrl?.split('?')[0]
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: updatedFile, // The file to upload
        headers: {
          'Content-Type': 'image/jpeg', // or the appropriate file type, e.g., 'image/png'
        },
      })

      if (response.ok) {
        if (updatedProfileImageUrl) {
          await updatePhoto(
            updatedProfileImageUrl,
            ProfileImageSource.UserUploadedImage
          )
        } else {
          await updatePhoto(
            DEFAULT_PROFILE_IMAGE_URL,
            ProfileImageSource.DefaultImage
          )
        }
        onDismiss()
      } else {
        addErrorSnack({
          title: 'Upload failed',
        })
      }
    } catch (error) {
      addErrorSnack({
        title: 'Upload failed',
      })
    }
  }

  const updatePhoto = async (
    imageUrl: string,
    imageSource: ProfileImageSource
  ) => {
    try {
      await updateProfileInfo({
        variables: {
          input: {
            userProfileRequest: {
              accountHandle,
              visibilityLevel: VisibilityLevel.Public,
              profileImageUrl: imageUrl,
              profileImageSource: imageSource,
              profileTagIds: tags?.map(tag => tag?.id) || [],
            },
          },
        },
      })
      addSuccessSnack({
        title: 'Uploaded profile image successfully',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    croppedAreaPixels,
    file,
    isUpdateProfileInfoLoading,
    onCroppedAreaPixelsChange,
    onDelete,
    onFileChange,
    onProfileImageSourceChange,
    onSubmit,
    previewUrl,
    profileImageSource,
  }
}

export type { UseDashboardProfileAvatar }
export { useDashboardProfileAvatar }
