import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { TripStatus } from 'src/__generated__/graphql'
import { BookingConfirmationTrips } from './BookingConfirmationTrips'

const { Upcoming } = TripStatus

const BOOKING_CONFIRMATION_TRIPS = gql(`
  query BookingConfirmationTripsCurrentUser($tripSearchInput: TripSearchInput!) {
    currentUser {
      id
      isAnonymous
      trips(tripSearchInput: $tripSearchInput, first: 100) {
        edges {
          node {
            id
            events {
              id
              booking {
                id
                externalConfirmationId
              }
            }
            name
          }
        }
      }
    }
  }
`)

interface BookingConfirmationTripsApolloProps {
  onlyMergeUser?: boolean
}

export const BookingConfirmationTripsApollo = ({
  onlyMergeUser,
}: BookingConfirmationTripsApolloProps) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(BOOKING_CONFIRMATION_TRIPS, {
    variables: {
      tripSearchInput: { status: Upcoming },
    },
  })

  const { currentUser: bookingConfirmationData } = data ?? {}

  if (hasError) return // TODO: Error component

  return (
    <BookingConfirmationTrips
      bookingConfirmationData={bookingConfirmationData}
      isLoading={isLoading}
      onlyMergeUser={onlyMergeUser ?? false}
    />
  )
}
