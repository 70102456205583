import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { GuideDraftImagesEditModal } from 'src/pages/guides/lists/common'
import { GuideDraftImagesEditModalOld } from 'src/pages/guides/lists-old/common'
import { isLastIndex } from 'src/utils'
import type { GuideData } from '../../types'
import { GuideImage } from '../GuideImage'
import { GuideImageGalleryModal } from '../GuideImageGallery'

interface GuideHeroProps {
  guideData: Pick<
    GuideData,
    'addresses' | 'id' | 'galleryImages' | 'images' | 'name'
  >
  isEdit: boolean
  isGuidePublishingV3Enabled: boolean
}

export const GuideHero = ({
  guideData,
  isEdit,
  isGuidePublishingV3Enabled,
}: GuideHeroProps) => {
  const [isModalImagesEditOpen, setIsModalImagesEditOpen] = useState(false)
  const [isModalImageGalleryOpen, setIsModalImageGalleryOpen] = useState(false)
  const { addresses, galleryImages, id, images, name } = guideData ?? {}

  const onImageClick = () => {
    if (isEdit) return setIsModalImagesEditOpen(true)

    return setIsModalImageGalleryOpen(true)
  }

  if (isEmpty(images)) return

  return (
    <>
      <div
        className={classNames(
          'of-hidden grid gap-1 lg:flex lg:flex-row lg:items-center',
          {
            'lg:[&>div:first-child]:w-194 lg:[&>div:first-child]:max-w-194 grid-cols-2 [&>div:nth-of-type(3)]:col-span-2':
              images?.[1],
            'grid-cols-1': !images?.[1],
          }
        )}
      >
        {images?.map(({ id, url }, index) => (
          <div key={id} className='of-hidden h-37.5 lg:h-92 relative lg:grow'>
            <button
              className='transition-transform-300 hover:scale-120 b-none m-none p-none relative left-0 top-0 h-full w-full grow cursor-pointer bg-transparent p-0 outline-none'
              onClick={onImageClick}
            >
              <GuideImage className='absolute inset-0' src={url} />
            </button>
            {isEdit && (
              <div className='[&>button]:bg-forest-dark absolute right-2 top-4 [&>button]:bg-opacity-50'>
                <IconButton
                  color='forestLight'
                  icon='modeEdit'
                  outline='round'
                  size='medium'
                  onClick={onImageClick}
                />
              </div>
            )}
            {!isEdit && isLastIndex(images, index) && (
              <div className='absolute bottom-4 right-4'>
                <Button
                  color='forestLight'
                  size='small'
                  startIcon='photoLibrary'
                  variant='outlined'
                  onClick={onImageClick}
                >
                  {galleryImages?.length?.toString()}
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      {isGuidePublishingV3Enabled && isModalImagesEditOpen && (
        <GuideDraftImagesEditModal
          guideDraftImagesEditData={{
            addresses,
            id,
            images,
          }}
          onDismiss={() => setIsModalImagesEditOpen(false)}
        />
      )}
      {!isGuidePublishingV3Enabled && isModalImagesEditOpen && (
        <GuideDraftImagesEditModalOld
          guideDraftImagesEditData={{
            addresses,
            id,
            images,
          }}
          onDismiss={() => setIsModalImagesEditOpen(false)}
        />
      )}
      {isModalImageGalleryOpen && (
        <GuideImageGalleryModal
          images={galleryImages}
          name={name}
          onDismiss={() => setIsModalImageGalleryOpen(false)}
        />
      )}
    </>
  )
}
