import { useEffect, useMemo, useState } from 'react'
import { useScreenQuery } from '@travelpass/design-system'
import isEqual from 'lodash.isequal'
import { useSearchParams } from 'react-router-dom'
import type { SearchHotelsQueryInHotelResultsQuery } from 'src/__generated__/graphql'
import { Map, constructMapCenter } from 'src/common/components/Map'
import { stringToNumber } from 'src/utils'
import { HotelResultsMapActions } from './HotelResultsMapActions'
import { HotelResultsMapEvents } from './HotelResultsMapEvents'
import { HotelResultsMapLoading } from './HotelResultsMapLoading'
import { HotelResultsMapMarkers } from './HotelResultsMapMarkers'
import { HotelResultsMapSearchThisArea } from './HotelResultsMapSearchThisArea'
import {
  HotelResultsSearchParams,
  initialMapZoom,
} from '../hotelResultsConstants'
import type { HotelResultsData } from '../hotelResultsTypes'

const removeIsCollected = data => data.map(({ isCollected, ...rest }) => rest)

interface HotelResultsMapProps {
  data: SearchHotelsQueryInHotelResultsQuery
  hotelResultsData: HotelResultsData
  hotelResultsFeaturedData: HotelResultsData
  isLoading?: boolean
  onFilteredIdsChange?(updatedFilteredIds: string[]): void
}

export const HotelResultsMap = ({
  data,
  hotelResultsData,
  hotelResultsFeaturedData,
  isLoading = false,
  onFilteredIdsChange = () => {},
}: HotelResultsMapProps) => {
  const [hotels, setHotels] = useState([])
  const { isMobileScreen } = useScreenQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showSearchThisArea, setShowSearchThisArea] = useState(false)
  const latitude = searchParams.get(HotelResultsSearchParams.latitude)
  const longitude = searchParams.get(HotelResultsSearchParams.longitude)

  const filteredData = useMemo(
    () => removeIsCollected(hotelResultsData),
    [hotelResultsData]
  )

  useEffect(() => setShowSearchThisArea(false), [latitude, longitude])

  useEffect(() => {
    setHotels(prevHotels => {
      // Check if the new data is deeply equal to the previous data
      if (!isEqual(prevHotels, filteredData)) return filteredData
      return prevHotels
    })
  }, [filteredData])

  const onClick = () => {
    searchParams.delete(HotelResultsSearchParams.activeId)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <div
      aria-hidden='true'
      className='h-full w-full'
      data-testid='HotelResultsMap'
    >
      <Map
        data={hotels?.map(item => ({
          item,
          lat: stringToNumber(item?.hotelAddress?.latitude),
          lng: stringToNumber(item?.hotelAddress?.longitude),
        }))}
        defaultCenter={constructMapCenter(latitude, longitude)}
        defaultZoom={initialMapZoom}
        enableZoomControl={!isMobileScreen}
        render={({ clusters, supercluster }) =>
          !isLoading && (
            <HotelResultsMapMarkers
              clusters={clusters}
              hotelResultsFeaturedData={hotelResultsFeaturedData}
              supercluster={supercluster}
              onFilteredIdsChange={onFilteredIdsChange}
            />
          )
        }
        superclusterRadius={17}
        onCenterChanged={() => !isLoading && setShowSearchThisArea(true)}
        onClick={onClick}
      >
        <HotelResultsMapEvents
          data={data}
          isLoading={isLoading}
          onChange={() => setShowSearchThisArea(false)}
        />
        {!isLoading && showSearchThisArea && (
          <HotelResultsMapSearchThisArea
            onClose={() => setShowSearchThisArea(false)}
          />
        )}
      </Map>
      {isLoading && <HotelResultsMapLoading />}
      <HotelResultsMapActions />
    </div>
  )
}
