import { useEffect, useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  Icon,
} from '@travelpass/design-system'
import Fuse from 'fuse.js'
import isEmpty from 'lodash.isempty'
import type { FilterBy } from 'src/__generated__/graphql'
import { parseBrandText } from './hotelResultsFiltersBrandsUtils'

const fuse = new Fuse([], {
  keys: ['value'],
  shouldSort: true,
})

interface HotelResultsFiltersBrandsProps {
  filters?: FilterBy
  selectedBrands: string[]
  allBrandsList: string[]
  onChange?(updatedFilters: FilterBy): void
}

export const HotelResultsFiltersBrands = ({
  filters = {},
  selectedBrands,
  allBrandsList,
  onChange = () => {},
}: HotelResultsFiltersBrandsProps) => {
  const [searchValue, setSearchValue] = useState('')

  const searchedBrands =
    fuse?.search(searchValue || '', {
      limit: 5,
    }) ?? []

  useEffect(() => {
    fuse.setCollection(allBrandsList)
  }, [allBrandsList])

  const onOptionChange = (value: string) => {
    setSearchValue('')
    if (selectedBrands?.includes(value)) {
      onChange({
        ...filters,
        brands: selectedBrands?.filter(brand => brand !== value),
      })
    } else {
      onChange({
        ...filters,
        brands: [...(selectedBrands ?? []), value],
      })
    }
  }

  return (
    <div className='space-y-5'>
      {/* TODO: remove px-0.5 (if possible) */}
      <div className='px-0.5'>
        <Autocomplete
          autoExpand
          aria-label='Search for brands'
          autoComplete='on'
          placeholder='Search'
          slotBefore={
            <div className='color-new-forest flex'>
              <Icon name='search' />
            </div>
          }
          value={searchValue}
          onChange={event => setSearchValue(event?.target?.value)}
          onOptionSelect={onOptionChange}
        >
          {searchedBrands?.map(({ item }) => (
            <AutocompleteOption
              key={item}
              className='color-black rounded-0 transition-background-color-200 type-body-1 focus:bg-grey-100 hover:bg-grey-100 aria-selected:bg-grey-100 w-full cursor-pointer bg-white px-4 py-4 ease-linear'
              value={item}
            >
              {parseBrandText(item)}
            </AutocompleteOption>
          ))}
        </Autocomplete>
      </div>
      {!isEmpty(selectedBrands) && (
        <div className='flex flex-wrap gap-4'>
          {selectedBrands.map(value => (
            <Chip
              key={value}
              includeCloseIcon
              label={parseBrandText(value)}
              onClick={() => onOptionChange(value)}
            />
          ))}
        </div>
      )}
    </div>
  )
}
