import { useEffect, useState } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { PageLayoutContainer, Helmet } from 'src/common/components'
import type { HelmetProps } from 'src/common/components/Helmet/Helmet'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getProfilePageUrl } from 'src/utils'
import { FollowersModal } from './components/FollowersModal/FollowersModal'
import { ProfileSearchBarContainer } from './components/ProfileSearchBar/ProfileSearchBarContainer'
import { useGetProfile } from './components/hooks/useGetProfile'
import { ProfileContentSection } from './content-section/ProfileContentSection'
import { ProfileInformationSection } from './information-section/ProfileInformationSection'
import type { FollowersModalType } from './types'
import { useGetProfileGuidesQuery } from './useGetProfileGuidesQuery'

export const Profile = () => {
  const { accountHandle } = useParams()
  const { profile, loading } = useGetProfile(accountHandle)
  const {
    isLoading: isProfileGuidesLoading,
    onGetMoreResults,
    profileGuidesData,
  } = useGetProfileGuidesQuery(profile?.accountHandle)
  const [showModal, setShowModal] = useState<FollowersModalType>(null)
  const { displayName, isUserOwner } = profile ?? {}
  const guidesCount = profileGuidesData?.totalCount ?? 0
  const pageName =
    profile && isUserOwner
      ? `My Profile | ${displayName || accountHandle}`
      : `${displayName} | Profile | ${accountHandle}`

  useEffect(() => {
    //It closes modal when user switches between profiles
    showModal && toggleModal(null)
  }, [accountHandle])

  /**
   * It handles Followers modal state
   * @param viewType
   * @returns
   */
  const toggleModal = (viewType: FollowersModalType) => {
    if (showModal == null) {
      pushDataToDataLayer('open_followers_modal', {
        viewType,
      })
    }
    setShowModal(viewType)
  }

  const metadata: HelmetProps = {
    canonicalUrl: getProfilePageUrl(profile?.accountHandle),
    metaDescription:
      'Check out my Travelpass profile! Find tips, recommendations, travel Guides, and more.',
    metaImage: profile?.profileImageUrl,
    pageName: pageName,
  }

  const showMetadata = !isEmpty(metadata) && !isEmpty(profile)

  return (
    <>
      {showMetadata && <Helmet {...metadata} />}
      {loading || isProfileGuidesLoading ? (
        <div className='mt-60 flex h-16 flex-col items-center justify-center'>
          <SkeletonDots />
        </div>
      ) : (
        <div className='md:pb-30 sm:pb-10'>
          {showModal && (
            <FollowersModal
              accountHandle={accountHandle}
              toggleModal={toggleModal}
              userId={profile?.userId}
              viewType={showModal}
              onClose={() => setShowModal(null)}
            />
          )}
          <div
            className='h-[310px] bg-cover bg-center bg-no-repeat'
            style={{
              backgroundImage: `url(${profile?.activeBannerImage?.mediumUrl || 'https://static.travelpass.com/assets/profile-banner-images/default.webp'})`,
              overflowClipMargin: 'unset',
            }}
          >
            <ProfileSearchBarContainer />
          </div>
          <PageLayoutContainer size='large'>
            <div className='mt--25 md:gap-26 mx-6 flex place-content-between gap-2 sm:flex-col lg:flex-row'>
              <ProfileInformationSection
                guidesCount={guidesCount}
                profile={profile}
                toggleModal={toggleModal}
              />
              <ProfileContentSection
                profile={profile}
                profileGuidesData={profileGuidesData}
                toggleModal={toggleModal}
                onGetMoreResults={onGetMoreResults}
              />
            </div>
          </PageLayoutContainer>
        </div>
      )}
    </>
  )
}
