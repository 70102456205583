import type { Dayjs } from 'dayjs'
import isEmpty from 'lodash.isempty'
import type {
  GenericAddress,
  UpdateAddressInput,
  UpdateEventInput,
} from 'src/__generated__/graphql'
import type { GeocoderType } from 'src/constants/user'
import {
  combineDateAndTimeToUTC,
  constructDisplayName,
  getDateFromUTC,
  stringTimeToDayjs,
} from 'src/utils'
import type { UpdateEventEventInput } from './updateEventConstants'
import type { EventFormValuesType } from '../../components/EventForm'
import type { GenericEventType } from '../../constants'
import { constructAddressInput } from '../../utils'

const updateStartLocation = ({
  formStartLocation,
  formEndLocation,
  eventStartLocation,
  eventEndLocation,
}: {
  formStartLocation?: GeocoderType
  formEndLocation?: GeocoderType
  eventStartLocation?: GenericAddress
  eventEndLocation?: GenericAddress
}): {
  updatedStartLocation: boolean
  updatedAddresses: UpdateAddressInput[]
} => {
  const updateData: {
    updatedStartLocation: boolean
    updatedAddresses: UpdateAddressInput[]
  } = {
    updatedStartLocation: false,
    updatedAddresses: [eventStartLocation, eventEndLocation],
  }
  // check if form has start location
  if (!isEmpty(formStartLocation) && !!eventStartLocation?.id) {
    // convert recieved geocoder to input address
    const formAddress1 = constructAddressInput(formStartLocation)
    // check if address is different based on googlePlaceId
    if (formAddress1?.googlePlaceId !== eventStartLocation?.googlePlaceId) {
      updateData.updatedStartLocation = true
      // check if event has an endLocation
      if (isEmpty(formEndLocation) || !eventEndLocation?.id) {
        // if no end location update start address
        updateData.updatedAddresses = [
          { ...formAddress1, id: eventStartLocation?.id },
        ]
      } else {
        // insert updated start address without modifying end address
        updateData.updatedAddresses = [
          { ...formAddress1, id: eventStartLocation?.id },
          eventEndLocation,
        ]
      }
    }
  }

  return updateData
}

const updateEndLocation = ({
  formStartLocation,
  formEndLocation,
  eventStartLocation,
  eventEndLocation,
  eventInputStartLocation,
}: {
  formStartLocation?: GeocoderType
  formEndLocation?: GeocoderType
  eventStartLocation?: GenericAddress
  eventEndLocation?: GenericAddress
  eventInputStartLocation?: GenericAddress
}): {
  updatedEndLocation: boolean
  updatedAddresses: UpdateAddressInput[]
} => {
  const updateData: {
    updatedEndLocation: boolean
    updatedAddresses: UpdateAddressInput[]
  } = {
    updatedEndLocation: false,
    updatedAddresses: [eventStartLocation, eventEndLocation],
  }

  // check if form has end location
  if (!isEmpty(formEndLocation) && !!eventEndLocation?.id) {
    // convert recieved geocoder to input address
    const formAddress2 = constructAddressInput(formEndLocation)
    // check if address is different based on googlePlaceId
    if (formAddress2?.googlePlaceId !== eventEndLocation?.googlePlaceId) {
      updateData.updatedEndLocation = true
      // check if event has an start location
      if (isEmpty(formStartLocation) && !!eventStartLocation?.id) {
        // if no start location update start address
        updateData.updatedAddresses = [
          { ...formAddress2, id: eventStartLocation?.id },
        ]
      } else if (!isEmpty(eventInputStartLocation)) {
        // insert updated end address without modifying updated start address
        updateData.updatedAddresses = [
          eventInputStartLocation,
          { ...formAddress2, id: eventEndLocation?.id },
        ]
      } else {
        // insert updated end address without modifying existing start address
        updateData.updatedAddresses = [
          eventStartLocation,
          { ...formAddress2, id: eventEndLocation?.id },
        ]
      }
    }
  }

  return updateData
}
const updateLocations = ({
  formStartLocation,
  formEndLocation,
  eventStartLocation,
  eventEndLocation,
}: {
  formStartLocation?: GeocoderType
  formEndLocation?: GeocoderType
  eventStartLocation?: GenericAddress
  eventEndLocation?: GenericAddress
}): {
  updatedLocations: boolean
  updatedAddresses: UpdateAddressInput[]
} => {
  const updateData: {
    updatedLocations: boolean
    updatedAddresses: UpdateAddressInput[]
  } = {
    updatedLocations: false,
    updatedAddresses: [],
  }

  const { updatedStartLocation, updatedAddresses } = updateStartLocation({
    formStartLocation,
    formEndLocation,
    eventStartLocation,
    eventEndLocation,
  })

  if (updatedStartLocation) {
    updateData.updatedLocations = true
    updateData.updatedAddresses = updatedAddresses
  }

  const { updatedEndLocation, updatedAddresses: updatedEndAddresses } =
    updateEndLocation({
      formStartLocation,
      formEndLocation,
      eventStartLocation,
      eventEndLocation,
      eventInputStartLocation: updateData?.updatedAddresses?.[0],
    })

  if (updatedEndLocation) {
    updateData.updatedLocations = true
    updateData.updatedAddresses = updatedEndAddresses
  }

  return updateData
}

const updateStartDate = ({
  formStartDate,
  formStartTime,
  eventStartDate,
  timezone,
}: {
  formStartDate?: Dayjs
  formStartTime?: string
  eventStartDate?: string
  timezone?: string
}): {
  startDateUpdated: boolean
  updatedStartDate: string | null
} => {
  const updateData: {
    startDateUpdated: boolean
    updatedStartDate: string | null
  } = {
    startDateUpdated: false,
    updatedStartDate: null,
  }
  // check if form contains values for updated start date or start time
  if (!isEmpty(formStartDate) || !isEmpty(formStartTime)) {
    const originalStartDate = getDateFromUTC(eventStartDate, timezone)

    // define default start time data
    const startDateData = {
      date: originalStartDate,
      time: null,
      timezone,
    }

    // if form contains a start date
    if (formStartDate) {
      // Check if date is different
      if (!originalStartDate.isSame(formStartDate, 'date')) {
        // identify date needs to be updated
        updateData.startDateUpdated = true
        // update output data
        startDateData.date = formStartDate
      }
    }

    // if form contains a start time
    if (formStartTime) {
      const noStartTime = formStartTime === 'None'
      const formStartTimeHourMin = noStartTime ? '12:00AM' : formStartTime
      const formStartTimeDjs = stringTimeToDayjs(formStartTimeHourMin)
      // Check if time is different
      if (
        originalStartDate.hour() !== formStartTimeDjs.hour() ||
        originalStartDate.minute() !== formStartTimeDjs.minute() ||
        noStartTime
      ) {
        // identify time needs to be updated
        updateData.startDateUpdated = true
        // update output data
        startDateData.time = formStartTimeHourMin
      }
    }

    if (updateData.startDateUpdated) {
      updateData.updatedStartDate = combineDateAndTimeToUTC(startDateData)
    }
  }
  return updateData
}

const updateEndDate = ({
  formEndDate,
  formStartTime,
  formEndTime,
  eventEndDate,
  timezone,
}: {
  formEndDate?: Dayjs
  formStartTime?: string
  formEndTime?: string
  eventEndDate?: string
  timezone?: string
}): {
  endDateUpdated: boolean
  updatedEndDate: string | null
} => {
  const updateData: {
    endDateUpdated: boolean
    updatedEndDate: string | null
  } = {
    endDateUpdated: false,
    updatedEndDate: null,
  }

  if (!isEmpty(formEndDate) || !isEmpty(formEndTime)) {
    const originalEndDate = getDateFromUTC(eventEndDate, timezone)

    // define default end time data
    const endDateData = {
      date: originalEndDate,
      time: null,
      timezone,
    }

    // if form contains a end date
    if (formEndDate) {
      // Check if date is different
      if (!originalEndDate.isSame(formEndDate, 'date')) {
        // identify date needs to be updated
        updateData.endDateUpdated = true
        // update output data
        endDateData.date = formEndDate
      }
    }

    // if form contains a end time
    if (formEndTime) {
      const noStartTime = formStartTime === 'None'
      const noEndTime = formEndTime === 'None'
      const formEndTimeHourMin = noEndTime ? '12:00AM' : formEndTime
      const formEndTimeDjs = stringTimeToDayjs(
        formEndTimeHourMin,
        endDateData?.date
      )

      // Check if time is different
      if (
        originalEndDate.hour() !== formEndTimeDjs.hour() ||
        originalEndDate.minute() !== formEndTimeDjs.minute() ||
        noStartTime ||
        noEndTime
      ) {
        // identify time needs to be updated
        updateData.endDateUpdated = true
        // EndTime cannot exist without StartTime
        const possibleOverride = noStartTime ? '12:00 AM' : formEndTimeHourMin
        // update output data
        endDateData.time = possibleOverride
      }
    }

    if (updateData.endDateUpdated) {
      updateData.updatedEndDate = combineDateAndTimeToUTC(endDateData)
    }
  }

  return updateData
}

const updateName = ({
  formName,
  eventName,
}: {
  formName?: string
  eventName?: string
}) => {
  const updateData: {
    nameUpdated: boolean
    updatedName: string | null
  } = {
    nameUpdated: false,
    updatedName: null,
  }
  if (!isEmpty(formName) && formName !== eventName) {
    const updatedEventName = constructDisplayName(formName)
    updateData.nameUpdated = true
    updateData.updatedName = updatedEventName
  }
  return updateData
}

const updateNotes = ({
  formNotes,
  eventNotes,
}: {
  formNotes?: string
  eventNotes?: string
}) => {
  const updateData: {
    notesUpdated: boolean
    updatedNotes: string | null
  } = {
    notesUpdated: false,
    updatedNotes: null,
  }
  if (formNotes !== eventNotes) {
    if (formNotes === '') {
      updateData.notesUpdated = true
    }
    if (formNotes?.length >= 1) {
      updateData.notesUpdated = true
      updateData.updatedNotes = formNotes
    }
  }
  return updateData
}

const updateUseEventTime = (formStartTime: string, timezone: string) => {
  // USE EVENT TIME - It is important that we set this explicitly to false
  if (!!timezone && !!formStartTime && formStartTime !== 'None') {
    return true
  }

  return false
}

// TODO make JSDOC
// Only data captured in the forms can be updated
// See EventFormValuesType for updatable fields
const generateUpdateEventEventInput = ({
  eventData,
  formValues,
  timezone,
}: {
  eventData: GenericEventType
  formValues: EventFormValuesType
  timezone: string
}): UpdateEventInput => {
  const eventInput: UpdateEventEventInput = { id: eventData?.id }
  // ADDRESSES
  const { updatedLocations, updatedAddresses } = updateLocations({
    formStartLocation: formValues?.startLocation,
    formEndLocation: formValues?.endLocation,
    eventStartLocation: eventData?.addresses?.[0],
    eventEndLocation: eventData?.addresses?.[1],
  })
  if (updatedLocations) {
    eventInput.addresses = updatedAddresses
  }

  // NAME
  const { nameUpdated, updatedName } = updateName({
    formName: formValues?.name,
    eventName: eventData?.name,
  })
  if (nameUpdated) {
    eventInput.name = updatedName
  }

  // NOTES
  const { notesUpdated, updatedNotes } = updateNotes({
    formNotes: formValues?.notes,
    eventNotes: eventData?.notes,
  })
  if (notesUpdated) {
    eventInput.notes = updatedNotes
  }

  // STATUS
  if (formValues.customBooked) {
    eventInput.status = formValues.customBooked
  }

  // START DATE
  const formStartDate = formValues?.date ?? formValues?.dates?.[0]
  const formStartTime =
    formValues?.startTime ?? formValues?.timeRange?.startTime
  const { startDateUpdated, updatedStartDate } = updateStartDate({
    formStartDate,
    formStartTime,
    eventStartDate: eventData?.startDate,
    timezone,
  })
  if (startDateUpdated) {
    eventInput.startDate = updatedStartDate
  }

  // END DATE
  const formEndDate = formValues?.dates?.[1] ?? formValues?.dates?.[0]
  const formEndTime = formValues?.endtime ?? formValues?.timeRange?.endTime
  const { endDateUpdated, updatedEndDate } = updateEndDate({
    formEndDate,
    formStartTime,
    formEndTime,
    eventEndDate: eventData?.endDate ?? eventData?.startDate,
    timezone,
  })
  if (endDateUpdated) {
    eventInput.endDate = updatedEndDate
  }

  eventInput.useEventTime = updateUseEventTime(formStartTime, timezone)

  return eventInput
}

export { generateUpdateEventEventInput }
