import classNames from 'classnames'
import type { MarkerColor } from 'src/component-library/theme/colors'

interface HotelMarkerProps {
  color: MarkerColor
  disableHover: boolean
  isSelected?: boolean
}

export const HotelMarker = ({
  color,
  disableHover,
  isSelected = false,
}: HotelMarkerProps): JSX.Element => {
  return (
    <svg
      className={classNames({
        'hover:scale-150': !disableHover,
        'scale-150': isSelected,
      })}
      fill='none'
      height='80'
      viewBox='0 0 81 80'
      width='81'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='40.5' cy='35' fill={color} r='21' />
      <path
        d='M40.0542 64.8856L34.7743 51.6857C34.6429 51.3573 34.8848 51 35.2385 51H46.2384C46.5982 51 46.8403 51.3686 46.6972 51.6988L40.9772 64.8987C40.8005 65.3065 40.2193 65.2983 40.0542 64.8856Z'
        fill={color}
      />
      <path
        d='M36.2119 35.4587C37.8027 35.4587 39.0869 34.1745 39.0869 32.5837C39.0869 30.9928 37.8027 29.7087 36.2119 29.7087C34.6211 29.7087 33.3369 30.9928 33.3369 32.5837C33.3369 34.1745 34.6211 35.4587 36.2119 35.4587ZM47.7119 29.7087H41.9619C40.9077 29.7087 40.0452 30.5712 40.0452 31.6253V36.417H32.3786V28.7503C32.3786 28.2232 31.9473 27.792 31.4202 27.792C30.8932 27.792 30.4619 28.2232 30.4619 28.7503V41.2087C30.4619 41.7357 30.8932 42.167 31.4202 42.167C31.9473 42.167 32.3786 41.7357 32.3786 41.2087V39.292H49.6286V41.2087C49.6286 41.7357 50.0598 42.167 50.5869 42.167C51.114 42.167 51.5452 41.7357 51.5452 41.2087V33.542C51.5452 31.4241 49.8298 29.7087 47.7119 29.7087Z'
        fill='white'
      />
    </svg>
  )
}
