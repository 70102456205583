import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__/gql'
import type { GetUserCompetitionInfoQueryVariables } from 'src/__generated__/graphql'

const GET_USER_COMPETITION_INFO = gql(`
  query GetUserCompetitionInfo($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      accountHandle
      displayName
      competitionInfo {
        id
        canVoteLeaderboard
        status
        voteCount
        currentPhase
        competitions{
          id
          currentPhase
          isActive
          name
        }
      }
      isFollowed
      isUserOwner
      profileImageUrl
      userId
    }
  }
`)

export const useGetUserCompetitionInfo = (
  variables: GetUserCompetitionInfoQueryVariables
) => useQuery(GET_USER_COMPETITION_INFO, { variables })
