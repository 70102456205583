import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_COLLECTIONS_FOR_DASHBOARD = gql(`
  query GetCollectionsForDashboard($first: Int, $after: String) {
    currentUser{
      id
      collections(first: $first, after: $after){
        totalCount
        pageInfo{
          hasNextPage
          endCursor
        }
        edges{
          cursor
          node{
            id
            isDefault
            name
            latitude
            longitude
            locationName
            previewImages
            collectedItems(first: 10) {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
              }
            }
          }
        }
      }
    }
  }
`)

export const useGetCollectionsForDashboard = () => {
  const [hasMoreResults, setHasMoreResults] = useState(true)

  let variables = { first: 10 }

  const { data, error, loading, fetchMore, refetch } = useQuery(
    GET_COLLECTIONS_FOR_DASHBOARD,
    { variables, fetchPolicy: 'no-cache' }
  )

  const { pageInfo, edges } = { ...data?.currentUser?.collections }

  useEffect(() => {
    setHasMoreResults(pageInfo?.hasNextPage)
  }, [pageInfo])

  return {
    loading,
    collections: edges,
    hasMoreResults,
    error,
    refetch,
    fetchMore,
    data,
  }
}
