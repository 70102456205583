import { Skeleton } from '@travelpass/design-system'

export const OverviewWeatherLoading = () => (
  <div className='b-grey-300 b-solid b-1 rounded-2 md:w-41 flex h-full flex-col justify-center space-y-2 p-4 text-center'>
    <div className='m-x-auto'>
      <div className='h-8 w-8'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className='text-center'>
      <div className='h-8.5 m-a w-25 lg:h-10'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
