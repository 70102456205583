import type { KeyboardEvent } from 'react'
import { useEffect, useState } from 'react'
import { Button, IconButton, Input, KeyCode } from '@travelpass/design-system'
import type { Note } from 'src/__generated__/graphql'
import type { OverviewNoteFormSubmitArguments } from './types'
import { OverviewNoteLinkCard } from '../OverviewNoteLinkCard'
import {
  getOverviewNoteLinkAsArray,
  getOverviewNoteLinkAsString,
} from '../OverviewNotes/overviewNotesUtils'

interface OverviewNoteFormLinksProps {
  isLoading: boolean
  note: Note
  onSubmit({
    showSnackbar,
    updatedNote,
    willClose,
  }: OverviewNoteFormSubmitArguments): void
}

export const OverviewNoteFormLinks = ({
  isLoading,
  note,
  onSubmit,
}: OverviewNoteFormLinksProps) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [link, setLink] = useState('')
  const [links, setLinks] = useState<string[]>([])
  const { body, openGraph } = note ?? {}
  const reversedOpenGraph = [...(openGraph ?? [])]?.reverse()

  useEffect(() => {
    setLink('')
    setLinks(getOverviewNoteLinkAsArray(body))
  }, [body])

  const onAddClick = () => {
    const updatedLink = link?.toLowerCase()?.trim()

    try {
      const validatedLink = new URL(updatedLink)
      const updatedIsInvalid = validatedLink.protocol !== 'https:'
      setIsInvalid(updatedIsInvalid)

      if (!updatedIsInvalid) {
        const updatedLinks = [...links, updatedLink]
        onSubmit({
          showSnackbar: false,
          updatedNote: {
            ...note,
            body: getOverviewNoteLinkAsString(updatedLinks),
          },
          willClose: false,
        })
      }
    } catch (_) {
      setIsInvalid(true)
    }
  }

  const onDeleteClick = (index: number) => {
    const updatedLinks = [...links].filter(
      (_, linkIndex) => index !== linkIndex
    )
    onSubmit({
      showSnackbar: false,
      updatedNote: {
        ...note,
        body: getOverviewNoteLinkAsString(updatedLinks),
      },
      willClose: false,
    })
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event ?? {}

    if (key === KeyCode.ENTER) {
      event.preventDefault()
      onAddClick()
    }
  }

  return (
    <div className='space-y-6'>
      {getOverviewNoteLinkAsArray(body)?.map((link, index) => {
        const { ogImage, ogTitle } = reversedOpenGraph?.[index] ?? {}

        return (
          <div key={index} className='flex w-full flex-row items-center'>
            <div className='max-w-[calc(100%-48px)] grow'>
              <OverviewNoteLinkCard
                ogImage={ogImage}
                ogTitle={ogTitle}
                ogUrl={link}
              />
            </div>
            <IconButton
              icon='deleteOutline'
              onClick={() => onDeleteClick(index)}
            />
          </div>
        )
      })}
      <div className='flex flex-row items-center'>
        <Input
          fullWidth
          aria-label='Add a URL to your note'
          autoComplete='url'
          errorText={
            isInvalid &&
            'This field must be a valid URL (e.g. https://travelpass.com).'
          }
          isInvalid={isInvalid}
          placeholder='Add URL'
          slotAfter={
            <Button
              aria-label='Add URL to note'
              isDisabled={isLoading}
              size='large'
              onClick={onAddClick}
            >
              Add
            </Button>
          }
          type='url'
          value={link}
          onChange={event => setLink(event.currentTarget.value)}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  )
}
