import { TimelineHopOnHopOff } from './TimelineHopOnHopOff'
import { TimelineStandard } from './TimelineStandard'
import type { ExperiencesItineraryTimelineProps } from '../../common/components/ExperiencesItineraryTimeline/types'
import { checkItineraryTimeline } from '../../common/components/utils'

interface ItinerarySwitchProps extends ExperiencesItineraryTimelineProps {
  expanded: boolean
}

const ItinerarySwitch = ({
  expanded,
  itinerary,
  size = 'medium',
}: ItinerarySwitchProps) => {
  const { itineraryType } = itinerary ?? {}
  if (!checkItineraryTimeline(itinerary)) return

  if (itineraryType === 'HOP_ON_HOP_OFF')
    return (
      <TimelineHopOnHopOff
        expanded={expanded}
        itinerary={itinerary}
        size={size}
      />
    )

  if (itineraryType === 'STANDARD')
    return (
      <TimelineStandard expanded={expanded} itinerary={itinerary} size={size} />
    )

  return
}

export { ItinerarySwitch }
