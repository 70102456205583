import { SkeletonDots } from '@travelpass/design-system'
import { ScrollerButton } from 'src/common/components'
import { TripLoadingCard } from './TripCard/TripCardLoading'

export const UserTripsLoading = () => (
  <div className='md:pl-18 flex w-full flex-col-reverse items-center justify-center px-12 md:flex-row md:pr-0 lg:px-12'>
    <div className='invisible hidden h-0 w-0 min-w-0 grow overflow-x-hidden md:visible md:flex md:h-auto md:w-auto md:flex-row md:items-center md:justify-start md:gap-6'>
      <span className='invisible hidden pr-5 lg:visible lg:block'>
        <ScrollerButton icon='arrowBackIos' size='small' />
      </span>
      <TripLoadingCard />
      <TripLoadingCard />
      <TripLoadingCard />
      <TripLoadingCard />
      <span className='invisible hidden pl-5 lg:visible lg:block'>
        <ScrollerButton isNext icon='arrowForwardIos' size='small' />
      </span>
    </div>
    <div className='visible block w-full space-y-6 md:invisible md:hidden'>
      <TripLoadingCard />
      <TripLoadingCard />
      <TripLoadingCard />
      <TripLoadingCard />
      <div className='flex w-full flex-row items-center justify-center pt-12'>
        <SkeletonDots />
      </div>
    </div>
  </div>
)
