import type { ComponentPropsWithoutRef } from 'react'
import isEmpty from 'lodash.isempty'
import { ResultsCard, ResultsCardCarousel } from 'src/common/components'
import { constructImageLinks } from 'src/utils'
import { HotelResultsCardRowContent } from './HotelResultsCardRowContent'
import type { HotelResultsItemData } from '../../hotelResultsTypes'

interface HotelResultsCardRowProps extends ComponentPropsWithoutRef<'button'> {
  hotelResultsCardData: HotelResultsItemData
}

export const HotelResultsCardRow = ({
  hotelResultsCardData,
  onMouseOut = () => {},
  onMouseOver = () => {},
  ...props
}: HotelResultsCardRowProps) => {
  const { onClick, ...otherProps } = props
  const {
    images: initialImages,
    isCollected,
    name,
  } = hotelResultsCardData ?? {}
  const images = constructImageLinks(initialImages, '500x240')
  const showCard = !isEmpty(hotelResultsCardData) && !!name

  return (
    showCard && (
      <>
        {/* TODO: remove event handlers in `section`.  */}
        <ResultsCard
          {...otherProps}
          isCollected={isCollected}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <section
            className='grid grid-cols-10'
            data-testid='HotelResultsCardRow'
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
          >
            <header className='of-hidden rounded-3 relative col-span-3 h-full'>
              <ResultsCardCarousel height='h-43' images={images} title={name} />
            </header>
            <div className='col-span-7'>
              <HotelResultsCardRowContent
                hotelResultsCardData={hotelResultsCardData}
                onBook={onClick}
              />
            </div>
          </section>
        </ResultsCard>
      </>
    )
  )
}
