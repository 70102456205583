import type { ReactNode } from 'react'

export const HotelResultsCardFeaturedContainer = ({
  children,
}: {
  children: ReactNode
}) => (
  <section className='min-h-25 of-hidden rounded-3 pointer-events-none relative h-full'>
    {children}
  </section>
)
