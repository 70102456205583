import { useState } from 'react'
import { DashboardAchievementsFollowers } from './DashboardAchievementsFollowers'
import { DashboardAchievementsStats } from './DashboardAchievementsStats'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'
import { DashboardTabs } from '../DashboardTabs'
import type { DashboardTabsProps } from '../DashboardTabs'

interface DashboardAchievementsProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
}

export const DashboardAchievements = ({
  dashboardData: dashboardData,
}: DashboardAchievementsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const tabs: DashboardTabsProps['tabs'] = [
    {
      icon: 'personAdd',
      label: 'New Followers',
      onClick: () => setActiveIndex(0),
    },
    {
      icon: 'stackedLineChart',
      label: 'Guide Stats',
      onClick: () => setActiveIndex(1),
    },
  ]

  return (
    <section>
      <DashboardTabs
        activeIndex={activeIndex}
        size='small'
        tabs={tabs}
        variant='dark'
      />
      <div className='shadow-3 rounded-3 rounded-tl-0 p-x-4 p-y-8 space-y-4 bg-white'>
        {activeIndex === 0 && (
          <DashboardAchievementsFollowers dashboardData={dashboardData} />
        )}
        {activeIndex === 1 && (
          <DashboardAchievementsStats dashboardData={dashboardData} />
        )}
      </div>
    </section>
  )
}
