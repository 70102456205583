import { EventCardLoading } from 'src/pages/trips/components'
import { SectionTitleLoading } from '../../TimelineSections'

export const TimelineStayLoading = () => (
  <div>
    <SectionTitleLoading />
    <div className='mb-2'>
      <EventCardLoading />
    </div>
    <div className='mb-2'>
      <EventCardLoading />
    </div>
    <div className='mb-2'>
      <EventCardLoading />
    </div>
  </div>
)
