import { Skeleton } from '@travelpass/design-system'

export const GuideHeaderOldActionsLoading = () => (
  <div className='flex flex-row items-center justify-end gap-3'>
    <div className='h-8 w-8'>
      <Skeleton variant='circular' />
    </div>
    <div className='h-8 w-8'>
      <Skeleton variant='circular' />
    </div>
    <div className='h-8 w-8'>
      <Skeleton variant='circular' />
    </div>
  </div>
)
