import { useWatch } from './useExperienceBookingForm'

export const ContactInformationSummary = () => {
  const [firstName, lastName, email, phone] = useWatch({
    name: [
      'bookerInfo.firstName',
      'bookerInfo.lastName',
      'communication.email',
      'communication.phone',
    ],
  })
  const displayPhoneNumber = `(${phone?.slice(0, 3)}) ${phone?.slice(3, 6)}-${phone?.slice(6)}`

  return (
    <div className='mt-2 space-y-6'>
      <h5 className='c-grey-800 type-h5'>
        {firstName} {lastName}
      </h5>
      <section className='c-grey-800 type-body-1-medium space-y-4'>
        <p>Email: {email}</p>
        <p>Phone: {displayPhoneNumber}</p>
      </section>
    </div>
  )
}
