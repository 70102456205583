import type { ChangeEvent } from 'react'
import { Checkbox } from '@travelpass/design-system'

interface TripMapTogglePinsProps {
  isChecked: boolean
  onChange(event: ChangeEvent<HTMLInputElement>): void
}

export const TripMapTogglePins = ({
  isChecked,
  onChange,
}: TripMapTogglePinsProps) => (
  <div className='rounded-3 shadow-1 absolute right-4 top-4 bg-white p-3 md:right-8 md:top-8'>
    <Checkbox
      isChecked={isChecked}
      label='Show all trip pins'
      onChange={event => onChange(event)}
    />
  </div>
)
