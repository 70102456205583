import { useQuery } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { logError } from 'src/utils'
import { currentUserExperienceBookingsExperienceImageGQL } from './experienceBookingsGql'

export const useExperienceImage = (productCode: string) => {
  const { data, error, loading } = useQuery(
    currentUserExperienceBookingsExperienceImageGQL,
    {
      skip: !productCode,
      variables: { productCode },
      notifyOnNetworkStatusChange: true,
    }
  )
  const rawImageLinks = data?.findProduct?.images?.size360x240
  const imageLink = rawImageLinks?.[0] ?? ''

  const hasError = !!error || isEmpty(imageLink)
  if (error) logError(error)

  return {
    imageLink,
    hasError,
    loading,
  }
}
