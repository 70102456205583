import { useEffect, useRef, useState } from 'react'
import { Carousel } from '@travelpass/design-system'
import classNames from 'classnames'
import type { UserImage } from 'src/__generated__/graphql'
import { getEventImage } from 'src/pages/trips/utils'

interface GuideImageGalleryProps {
  images: Partial<UserImage>[]
}

export const GuideImageGallery = ({ images }: GuideImageGalleryProps) => {
  const scrollContainerRef = useRef(null)
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)
  const [imageLinks, setImageLinks] = useState(images)
  const carouselImages: string[] = images.map(image => getEventImage(image.url))

  useEffect(() => {
    document?.getElementById(`wheel-images-${slideIndex}`)?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [slideIndex])

  const onError = (errorImage: string) =>
    setImageLinks(images => images.filter(image => errorImage !== image.url))

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }

  const onSlideIndexChange = (updatedSlideIndex: number) =>
    setSlideIndex(updatedSlideIndex)

  const onScroll = event => {
    const delta = Math.max(
      -1,
      Math.min(1, event.nativeEvent.deltaY || -event.nativeEvent.wheelDelta)
    )
    const container = scrollContainerRef.current
    container.scrollLeft += delta * 80
  }

  return (
    <div className='flex flex-col gap-4 md:h-[calc(100vh-360px)] md:flex-row md:gap-3'>
      <div className='md:w-244 w-full grow md:max-w-[calc(100%-180px)]'>
        <Carousel
          beforeChange={nextSlide => setSlideIndex(nextSlide)}
          borderRadius={12}
          disableInfinite={true}
          hideArrows={!!imageLinks.length}
          hideDots={true}
          isDisabled={!hasFirstImageLoaded}
          isLight={true}
          keyBoardControl={true}
          showArrowsOnHover={true}
          size='small'
          slideIndex={slideIndex}
        >
          {carouselImages?.map((image, index) => {
            return (
              <img
                key={index}
                alt=''
                className='h-[calc(75dvh-176px)] w-full object-cover md:h-[calc(100vh-360px)]'
                src={image}
                onError={() => onError}
                onLoad={onLoad}
              />
            )
          })}
        </Carousel>
      </div>
      <div
        className='w-100% md:w-45 md:display-unset ws-nowrap flex gap-3 overflow-x-auto md:h-auto md:overflow-y-auto'
        ref={scrollContainerRef}
        onWheel={onScroll}
      >
        {carouselImages?.map((image, index) => {
          return (
            <div
              key={index}
              className='flex flex-col items-start py-1 lg:items-center'
            >
              <button
                aria-label='Show Guide photo'
                className='m-0 cursor-pointer border-none bg-transparent p-0 outline-none'
                onClick={() => onSlideIndexChange(index)}
              >
                <img
                  alt=''
                  className={classNames(
                    'rounded-2 md:h-31.5 md:w-35.5 h-19 w-19 bg-gray-200 object-cover transition-all duration-300',
                    {
                      'border-3 border-forest border-solid opacity-100':
                        index === slideIndex,
                      'opacity-70': index !== slideIndex,
                    }
                  )}
                  draggable='false'
                  id={`wheel-images-${index}`}
                  loading='lazy'
                  src={image}
                />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
