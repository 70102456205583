import classNames from 'classnames'

export const HomeContainer = ({ children, size = 'none', ...props }) => (
  <section
    {...props}
    className={classNames(
      'm-a max-w-full px-6 md:px-12',
      {
        'w-274': size === 'md',
        'w-full': !size || size === 'none',
        'w-299': size === 'lg',
        'w-322': size === 'xl',
        'w-355': size === 'xxl',
      },
      props?.className
    )}
  >
    {children}
  </section>
)
