import type { InternationLocation } from './hotelResultsEmptyTypes'

export const internationalLocations: InternationLocation[] = [
  {
    latitude: 51.5074,
    longitude: -0.1278,
    src: 'https://static.travelpass.com/assets/results/international/london-england.webp',
    title: 'London, England',
  },
  {
    latitude: 40.4167754,
    longitude: -3.7037902,
    src: 'https://static.travelpass.com/assets/results/international/madrid-spain.webp',
    title: 'Madrid, Spain',
  },
  {
    latitude: 48.856614,
    longitude: 2.3522219,
    src: 'https://static.travelpass.com/assets/results/international/paris-france.webp',
    title: 'Paris, France',
  },
  {
    latitude: 48.1351253,
    longitude: 11.5819806,
    src: 'https://static.travelpass.com/assets/results/international/munich-germany.webp',
    title: 'Munich, Germany',
  },
  {
    latitude: 46.8139,
    longitude: -71.208,
    src: 'https://static.travelpass.com/assets/results/international/quebec-city-canada.webp',
    title: 'Quebec City, Canada',
  },
  {
    latitude: 40.7128,
    longitude: -74.006,
    src: 'https://static.travelpass.com/assets/results/international/new-york-city-united-states.webp',
    title: 'New York City, United States',
  },
  {
    latitude: -23.5557714,
    longitude: -46.6395571,
    src: 'https://static.travelpass.com/assets/results/international/san-paulo-brazil.webp',
    title: 'San Paulo, Brazil',
  },
  {
    latitude: 21.1619,
    longitude: -86.8515,
    src: 'https://static.travelpass.com/assets/results/international/cancun-mexico.webp',
    title: 'Cancun, Mexico',
  },
  {
    latitude: 41.9027835,
    longitude: 12.4963655,
    src: 'https://static.travelpass.com/assets/results/international/rome-italy.webp',
    title: 'Rome, Italy',
  },
]
