import { IconButton } from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import { GuideDraftImagesEditUpload } from './GuideDraftImagesEditUpload'
import type { UseGuideDraftImagesEdit } from '../../useGuideDraftImagesEdit'

const filledArray = Array(3).fill(0)

export const GuideDraftImagesEditSelected = ({
  onSelected,
  onUpload,
  images,
}: Pick<UseGuideDraftImagesEdit, 'images' | 'onSelected' | 'onUpload'>) => (
  <div className='lg:p-x-3 flex flex-row flex-wrap justify-center gap-1 md:grid md:grid-cols-3 lg:gap-6'>
    {filledArray?.map((_, index) => {
      if (!images?.[index])
        return <GuideDraftImagesEditUpload key={index} onUpload={onUpload} />

      return (
        <div
          key={index}
          className='b-solid transition-opacity-200 rounded-2 b-forest-light p-1.25 h-30 w-35 relative bg-transparent ease-linear hover:cursor-pointer lg:h-40 lg:w-auto lg:w-full'
        >
          <GuideImage className='rounded-1' src={images[index]} />
          <div className='absolute right--2 top--2'>
            <IconButton
              color='white'
              icon='clear'
              outline='round'
              size='small'
              onClick={() => onSelected(images[index])}
            />
          </div>
        </div>
      )
    })}
  </div>
)
