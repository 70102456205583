import { useEffect, useState } from 'react'
import { DateRangePicker } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { RoomFilters } from 'src/__generated__/graphql'
import { GuestConfigurationPopover } from 'src/common/components/Guests/GuestConfigurationPopover'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { DatesType, GuestsType } from 'src/constants/user'
import { constructDates, constructGuests, formatDate } from 'src/utils'
import { HotelRoomsSearchFormFiltersWrapper } from './HotelRoomsSearchFormFiltersWrapper'

interface HotelRoomsSearchFormProps {
  filters: RoomFilters
  onChange(updatedFilters: RoomFilters): void
  disableOnLoading: boolean
}

export const HotelRoomsSearchForm = ({
  filters,
  onChange,
  disableOnLoading,
}: HotelRoomsSearchFormProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { adults, arrival, departure, kids } = Object.fromEntries([
    ...searchParams,
  ])
  const constructedDates = constructDates({ arrival, departure })
  const constructedGuests = constructGuests({ adults, kids })
  const [guests, setGuests] = useState(constructedGuests)
  const [dates, setDates] = useState<DatesType>(constructedDates)
  const onGuestsChange = (updatedGuests: GuestsType) => setGuests(updatedGuests)

  useEffect(() => {
    const updatedAdults = guests?.[0]?.toString()
    const updatedArrival = dates[0] ? formatDate(dates?.[0]) : null
    const updatedDeparture = dates[1] ? formatDate(dates?.[1]) : null
    const updatedKids = guests?.[1]?.toString()
    const willSubmit =
      adults !== updatedAdults ||
      arrival !== updatedArrival ||
      departure !== updatedDeparture ||
      kids !== updatedKids
    if (willSubmit) {
      if (updatedArrival && updatedDeparture) {
        pushDataToDataLayer('hotelDatesUpdated', {
          arrival: updatedArrival,
          departure: updatedDeparture,
        })
        searchParams.set('departure', updatedDeparture)
        searchParams.set('arrival', updatedArrival)
      }
      searchParams.set('adults', updatedAdults)
      searchParams.set('kids', updatedKids)
      setSearchParams(searchParams, { replace: true })
    }
  }, [guests, dates])

  return (
    <div className='my-6'>
      <h3 className='type-h3 my-6 text-center'>Available Rooms</h3>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-3'>
        <div>
          <DateRangePicker
            label='Check-in – Check-out'
            numberOfMonths={1}
            selected={{
              from: dates?.[0],
              to: dates?.[1],
            }}
            onSelect={range => setDates([range.from, range?.to])}
          />
        </div>
        <div>
          <GuestConfigurationPopover
            guests={guests}
            onChange={onGuestsChange}
          />
        </div>
      </div>
      <div className='py-5 lg:py-4'>
        <HotelRoomsSearchFormFiltersWrapper
          disableOnLoading={disableOnLoading}
          filters={filters}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
