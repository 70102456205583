import { useEffect, useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { GuideHeaderOld } from '../../details/common'
import {
  GuideDraftCreateModal,
  GuideDraftGeocoder,
  GuideDraftMap,
  GuideDraftSections,
  GuideDraftStickyActionButtons,
} from '../common'
import type { GuideDraftData } from '../types'

const guideDraftData: GuideDraftData = {
  __typename: 'Guide',
  id: '0',
  description: '',
  insertedAt: null,
  name: '',
  status: null,
  timeZone: '',
  updatedAt: new Date(),
  paginatedEventCategories: null,
}

const NOOP = () => {}

export const GuideDraftCreate = () => {
  const { isAnonymous } = useFirebaseUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [guideDraftModal, setGuideDraftModal] = useState(false)
  const navigateBack = () => navigate(-1)
  const triggerButton = searchParams.get('triggerButton')

  useEffect(() => {
    if (isAnonymous) {
      dispatch('openSignin', {
        isCreateAccount: true,
      })
    } else {
      setGuideDraftModal(true)
    }
  }, [isAnonymous])

  return (
    <>
      {guideDraftModal && (
        <GuideDraftCreateModal
          redirect
          triggerButton={triggerButton}
          onDismiss={navigateBack}
        />
      )}
      <div className='lg:flex lg:flex-row'>
        <div
          className={classNames(
            'p-y-5 lg:w-100vw lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 lg:p-b-30 lg:p-t-3 lg:w-202.5 lg:z-3 bg-white',
            {
              'hidden lg:block': false,
            }
          )}
        >
          <PageLayoutContainer size='none'>
            <div className='space-y-4 lg:space-y-8'>
              <GuideHeaderOld />
              <div className='space-y-4'>
                <h3 className='color-forest-dark type-body-1-medium-desktop'>
                  Add places, experiences, and more to your Guide!
                </h3>
                <GuideDraftGeocoder
                  id={guideDraftData?.id}
                  location={null}
                  searchValue=''
                  slotAfter={
                    <div className='pointer-events-none absolute right-0'>
                      <IconButton
                        color='valley'
                        icon='search'
                        onClick={() => {}}
                      />
                    </div>
                  }
                  onMapMarkerCenterChange={NOOP}
                  onPlacePointsChange={NOOP}
                  onSearchValueChange={NOOP}
                />
              </div>
              <GuideDraftSections
                data={null}
                location={null}
                owner={null}
                selectedId=''
                onMapMarkerCenterChange={NOOP}
                onPlacePointsChange={NOOP}
                onSelectedIdChange={NOOP}
              />
              <GuideDraftStickyActionButtons
                guideId={guideDraftData?.id}
                selectedId=''
              />
            </div>
          </PageLayoutContainer>
        </div>
        <div
          className={classNames(
            'b-0 bg-grey-100 lg:transition-margin-175 lg:top-77px h-[calc(100svh-65px)] grow lg:sticky lg:h-[calc(100svh-77px)] lg:ease-[cubic-bezier(0,0,0.2,1)]',
            {
              'hidden lg:block': !false,
              'lg:-m-l-119': !false,
            }
          )}
        >
          <GuideDraftMap data={null} onSelectedIdChange={NOOP}>
            <></>
          </GuideDraftMap>
        </div>
        <div
          className={classNames(
            'p-x-12 z-3 fixed bottom-10 flex w-full flex-row items-center justify-center gap-3 md:hidden lg:hidden'
          )}
        >
          <Button
            aria-label='view map'
            fullWidth={true}
            size='medium'
            startIcon='mapOutlined'
            onClick={NOOP}
          >
            View Map
          </Button>
        </div>
      </div>
    </>
  )
}
