import { useEffect } from 'react'
import { DropdownOption } from '@travelpass/design-system'
import dayjs from 'dayjs'
import type { ExperienceBookingQuestion } from 'src/__generated__/graphql'
import { LocationRefOrFreeText } from './LocationRefOrFreeTextField'
import {
  BookingFormDropdown,
  BookingFormInput,
  useFormContext,
  rules,
  type BookingQuestionAnswerName,
} from './useExperienceBookingForm'

const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] /* 12 hours */
const mins = ['00', '15', '30', '45'] /* 15 min increments */
const times = hours.flatMap(hour => mins.map(min => `${hour}:${min}`))
const TIME_OPTIONS = [
  ...times.map(time => `${time} AM`),
  ...times.map(time => `${time} PM`),
]

// Client-side validation rules per Viator BookingQuestion id
const QUESTION_ID_RULES = {
  WEIGHT: {
    pattern: {
      // only allow up to 3 digits
      value: /^[0-9]{1,3}$/,
      message: 'Invalid weight (must be less than 999)',
    },
  },
}

export const BookingQuestionAnswer = ({
  question,
  travelerNum,
  field,
}: {
  question: ExperienceBookingQuestion
  travelerNum?: number
  field: BookingQuestionAnswerName
  conditionalFields?: ExperienceBookingQuestion[]
}) => {
  const { register, setValue } = useFormContext()
  useEffect(() => {
    setValue(`${field}.question`, question.id)
    /* save travelerNum if present and >0 (zero represents per_booking questions) */
    if (travelerNum) {
      setValue(`${field}.travelerNum`, travelerNum)
    }
  }, [question, travelerNum, field])

  const name = `${field}.answer` as const
  const required = question?.required !== 'OPTIONAL' ? rules.required : false
  const validationRules = QUESTION_ID_RULES[question.id]

  if (question.type === 'NUMBER_AND_UNIT') {
    const unit = `${field}.unit` as const
    return (
      <span className='flex flex-row items-end gap-4'>
        <div className='w-3/4'>
          <BookingFormInput
            fullWidth
            defaultValue=''
            id={name}
            label={question.label}
            name={name}
            placeholder={question.hint}
            required={required}
            rules={validationRules}
          />
        </div>
        <div className='w-1/4'>
          <BookingFormDropdown
            fullWidth
            defaultValue={question.units[0]}
            id={unit}
            label='Units'
            name={unit}
            required={true}
          >
            {question.units.map(answer => (
              <DropdownOption key={answer} value={answer}>
                {answer}
              </DropdownOption>
            ))}
          </BookingFormDropdown>
        </div>
      </span>
    )
  }

  if (question.type === 'STRING') {
    if (question.allowedAnswers) {
      return (
        <>
          <BookingFormDropdown
            defaultValue=''
            id={name}
            label={question.label}
            name={name}
            required={required}
          >
            {question.allowedAnswers.map(answer => (
              <DropdownOption key={answer} value={answer}>
                {answer}
              </DropdownOption>
            ))}
          </BookingFormDropdown>
        </>
      )
    } else {
      return (
        <BookingFormInput
          fullWidth
          defaultValue=''
          id={name}
          label={question.label}
          name={name}
          placeholder={question.hint}
          required={required}
        />
      )
    }
  }

  if (question.type === 'DATE') {
    return (
      <BookingFormInput
        fullWidth
        defaultValue=''
        format='##/##/####'
        id={name}
        label={question.label}
        name={name}
        placeholder='MM/DD/YYYY'
        required={required}
        rules={{
          validate: {
            isDate: (value: string) => {
              return dayjs(value, 'MMDDYYYY').isValid() || 'Invalid date'
            },
            // @todo: applies to some rules (eg. PASSPORT_EXPIRY) but not all (eg. BIRTHDATE)
            // isFutureDate: (value: string) => {
            //   return (
            //     dayjs(value, 'MMDDYYYY').isAfter(dayjs()) ||
            //     'Please provide a date in the future'
            //   )
            // },
          },
        }}
      />
    )
  }

  if (question.type === 'TIME') {
    return (
      <BookingFormDropdown
        fullWidth
        defaultValue='12:00 PM'
        label={question.label}
        name={name}
        required={required}
      >
        {TIME_OPTIONS.map(time => (
          <DropdownOption key={time} value={time}>
            {time}
          </DropdownOption>
        ))}
      </BookingFormDropdown>
    )
  }

  if (question.type === 'LOCATION_REF_OR_FREE_TEXT') {
    return (
      <LocationRefOrFreeText
        field={field}
        label={question.label}
        placeholder={question.hint}
        {...register(name, {
          validate: value => {
            return !!value?.trim()
          },
        })}
      />
    )
  }

  return null
}
