export const Fieldset = ({
  children,
  legend,
  ...props
}: {
  children: React.ReactNode
  legend: string
  id?: string
}) => {
  return (
    <fieldset
      className='b-0 border-b-1 b-grey400 b-solid m-0 mb-8 flex flex-col gap-2 p-0 pb-8 last-of-type:mb-0 last-of-type:border-b-0'
      {...props}
    >
      <legend className='text-subtitle-2 pb-4'>{legend}</legend>
      {children}
    </fieldset>
  )
}
