const locationPath =
  'https://static.travelpass.com/assets/landing-pages/ghost-tour/'

const tourId = '12066'

const constructLocations = [
  {
    image: `${locationPath}new-orleans-louisiana.webp`,
    city: 'New Orleans',
    state: 'Louisiana',
    description:
      'From the haunted corridors of the LaLaurie Mansion to the ghostly patrons of the city’s age-old taverns, New Orleans has earned its reputation as one of the most haunted cities in America. Come see why!',
    urlData: [
      {
        latitude: 29.951065,
        longitude: -90.071533,
      },
    ],
  },
  {
    image: `${locationPath}gettysburg-pennsylvania.webp`,
    city: 'Gettysburg',
    state: 'Pennsylvania',
    description:
      'Calling all history buffs who love the supernatural– this city is a must-visit. As the site of one of the bloodiest battles of the Civil War, generations have claimed to see spectral soldiers and ghostly apparitions retracing their final steps. Come walk the grounds and see if you can hear the eerie sounds of phantom drum beats.',
    urlData: [
      {
        latitude: 39.830898,
        longitude: -77.233337,
      },
    ],
  },
  {
    image: `${locationPath}charleston-south-carolina.webp`,
    city: 'Charleston',
    state: 'South Carolina',
    description:
      'With its cobblestone streets and antebellum mansions, Charleston carries a dark secret beneath its Southern charm. As night falls, you’ll hear haunting tales of pirates, Civil War soldiers, and the famous Annabel Lee. If you don’t believe in ghosts now, you just might after visiting this city!',
    urlData: [
      {
        latitude: 32.77647,
        longitude: -79.93103,
      },
    ],
  },
  {
    image: `${locationPath}chicago-illinois.webp`,
    city: 'Chicago',
    state: 'Illinois',
    description:
      'Did you know that the Windy City was home to the United States’ first serial killer? Come visit the lot of H. H. Holmes’ infamous “Murder Castle”, where he claimed to have murdered nearly 200 people in soundproof rooms, secret passageways, and trap doors',
    urlData: [
      {
        latitude: 41.878113,
        longitude: -87.629799,
      },
    ],
  },
  {
    image: `${locationPath}salem-massachusetts.webp`,
    city: 'Salem',
    state: 'Massachusetts',
    description:
      'Infamous for the Salem witch trials of 1692, the town is said to be haunted by the spirits of those that were unjustly executed. Come walk the cobblestone streets to learn more of the occult, and to celebrate the 30th anniversary of Disney’s Hocus Pocus, which was inspired by this haunted town! ',
    urlData: [
      {
        latitude: 42.521412,
        longitude: -70.898643,
      },
    ],
  },
  {
    image: `${locationPath}savannah-georgia.webp`,
    city: 'Savannah',
    state: 'Georgia',
    description:
      'Often referred to as America’s most haunted city, Savannah’s historic district is filled with tales of ghosts and hauntings, largely attributed to its history of battles, epidemics and tragic events.',
    urlData: [
      {
        latitude: 32.080608,
        longitude: -81.090103,
      },
    ],
  },
  {
    image: `${locationPath}san-francisco-california.webp`,
    city: 'San Francisco',
    state: 'California',
    description:
      'The famous Alcatraz prison, as well as tales from the city’s Gold Rush days and the infamous 1906 earthquake, contribute to San Francisco’s haunted reputation. You may encounter some disturbed, vengeful spirit presences here!',
    urlData: [
      {
        latitude: 37.774929,
        longitude: -122.419418,
      },
    ],
  },
  {
    image: `${locationPath}st-augustine-florida.webp`,
    city: 'St. Augustine',
    state: 'Florida',
    description:
      'Have you heard of the famous “Woman in Black”? You just might see her when you visit St. Augustine, one of the oldest cities in the U.S.! Brimming with paranormal activity, this city has a long history of alleged hauntings, with eerie encounters possible on every corner.',
    urlData: [
      {
        latitude: 29.892101,
        longitude: -81.314278,
      },
    ],
  },
  {
    image: `${locationPath}galveston-texas.webp`,
    city: 'Galveston',
    state: 'Texas',
    description:
      'Plagued by major hurricanes and tragic events, Galveston is extremely haunted to this day. St. Mary’s orphanage is an especially chilling place to visit, as the ghosts of nearly 100 children are rumored to walk the grounds. Be sure to visit during August, September, or October when the paranormal activity is at its highest!',
    urlData: [
      {
        latitude: 29.3057,
        longitude: -94.793325,
      },
    ],
  },
  {
    image: `${locationPath}new-york-new-york.webp`,
    city: 'New York',
    state: 'New York',
    description:
      'The city that never sleeps has many tales of haunted theaters, hotels, and landmarks, such as the Chelsea Hotel and the Morris-Jumel mansion (Hamilton fans, be sure to stop at this mansion for its connection to George Washington and Aaron Burr!)',
    urlData: [
      {
        latitude: 40.712982,
        longitude: -74.007202,
      },
    ],
  },
  {
    image: `${locationPath}key-west-florida.webp`,
    city: 'Key West',
    state: 'Florida',
    description:
      'Chucky fans, this is the ultimate haunted destination for you! Key West is an entire island said to be haunted by eerie Robert the Doll, the inspiration for Hollywood’s possessed toy. Featuring other haunted places like the Artist House, this city has a rich history of paranormal activity.',
    urlData: [
      {
        latitude: 24.554826,
        longitude: -81.802072,
      },
    ],
  },
  {
    image: `${locationPath}baltimore-maryland.webp`,
    city: 'Baltimore',
    state: 'Maryland',
    description:
      'Baltimore is a must-visit for those who love haunted cities, thanks to its eerie ambiance and an abundance of ghostly tales. With haunted locations like the historic Fort McHenry, Edgar Allen Poe’s final resting place in Westminster Church Cemetery, and the haunted ships in the Inner Harbor, Baltimore offers a spine-tingling journey into the paranormal world.',
    urlData: [
      {
        latitude: 39.291136,
        longitude: -76.613074,
      },
    ],
  },
  {
    image: `${locationPath}jerome-arizona.webp`,
    city: 'Jerome',
    state: 'Arizona',
    description:
      'Once a booming copper-mining town, it is impossible to walk around Jerome without encountering the supernatural. The Jerome Grand Hotel is one of the state’s most haunted places, and the very sidewalks you’ll walk on were built with the cremated bones of the townsfolk.',
    urlData: [
      {
        latitude: 34.748913,
        longitude: -112.11377,
      },
    ],
  },
]

export { constructLocations }
