import { createSearchParams } from 'react-router-dom'
import { bookPath, hotelsPath } from 'src/constants'
import { stringToNumber } from 'src/utils'

const constructHotelId = (hotelId: string) => stringToNumber(hotelId)

const constructHotelName = (hotelName: string) =>
  hotelName?.split('-')?.join(' ')

interface ContructBookingUrlProps {
  adults: number
  arrival: string
  departure: string
  eventId?: string
  hotelId: string
  kids: number
  rateToken: string
  tripId?: string
}

const constructBookingUrl = ({
  adults,
  arrival,
  departure,
  eventId,
  hotelId,
  kids,
  rateToken,
  tripId,
}: ContructBookingUrlProps): string => {
  const updatedSearchParamsObject = {
    arrival,
    departure,
    guests: (adults + kids).toString(),
    hotelId,
  }

  if (eventId) updatedSearchParamsObject['eventId'] = eventId

  if (tripId) updatedSearchParamsObject['tripId'] = tripId

  return `${hotelsPath}${bookPath}/${rateToken}?${createSearchParams(
    updatedSearchParamsObject
  )}`
}

const getReviewRatingDescription = (rating: number | string) => {
  const numRating = Number(rating)
  if (numRating < 5) return 'Poor'
  if (numRating >= 9) return 'Excellent'
  if (numRating >= 5 && numRating < 7) return 'Good'
  return 'Very Good'
}

export {
  constructHotelId,
  constructHotelName,
  constructBookingUrl,
  getReviewRatingDescription,
}
export type { ContructBookingUrlProps }
