import dayjs from 'dayjs'
import type { BookingCardData } from '../types'

const constructDisplayDateRange = ({
  startDate,
  endDate,
}: Partial<BookingCardData>) => {
  const updatedArrival = startDate.format('MMM D')
  const isSameMonth = dayjs(startDate).isSame(dayjs(endDate), 'month')

  const departureFormat = isSameMonth ? 'D, YYYY' : 'MMM D, YYYY'
  const updatedDeparture = endDate?.format(departureFormat)

  return `${updatedArrival} - ${updatedDeparture}`
}

const constructDisplayDate = ({
  startDate,
  endDate,
  startTime,
}: Partial<BookingCardData>) => {
  if (startDate?.isValid()) {
    if (endDate?.isValid()) {
      return constructDisplayDateRange({ startDate, endDate })
    } else {
      const dateStr = startDate?.format('MMM D, YYYY')
      const timeStr = startTime?.format('h:mm A')

      if (startTime?.isValid() && timeStr !== '12:00 AM') {
        return `${dateStr} @ ${timeStr}`
      } else {
        return dateStr
      }
    }
  }

  return ''
}

interface constructDisplayBottomTextArgs extends BookingCardData {
  condition?: boolean
}

const constructDisplayBottomText = ({
  adults,
  kids,
  roomCount,
}: constructDisplayBottomTextArgs) => {
  const validBottomText: {
    guestText: boolean | string
    roomText: boolean | string
  } = {
    guestText: false,
    roomText: false,
  }

  if (adults) {
    validBottomText.guestText = adults?.toString()
    if (kids) {
      const combineGuests = adults + kids
      validBottomText.guestText = combineGuests.toString()
    }
    const totalGuests = parseInt(validBottomText.guestText) ?? 1
    const guestTextPlural = totalGuests > 1 ? 's' : ''

    validBottomText.guestText = `${totalGuests.toString()} guest${guestTextPlural}`
  }
  // Marriot has a policy that 3rd parties (us) are only allowed to book up to 3
  if (roomCount && roomCount <= 3) {
    const roomTextPlural = roomCount > 1 ? 's' : ''
    validBottomText.roomText = `${roomCount} room${roomTextPlural}`
  }

  return validBottomText
}

export {
  constructDisplayBottomText,
  constructDisplayDateRange,
  constructDisplayDate,
}
