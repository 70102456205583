import type { DateRange } from '@travelpass/design-system'
import type { GeocoderType } from 'src/constants/user'

enum TripFormKeys {
  Address = 'address',
  Dates = 'dates',
  Name = 'name',
}

type TripFormValues = {
  address?: GeocoderType | null
  dates?: DateRange
  name?: string
}

export { type TripFormValues, TripFormKeys }
