import { NetworkStatus } from '@apollo/client'
import { SkeletonDots } from '@travelpass/design-system'
import { LoadMore } from 'src/common/components'

interface HotelResultsLoadMoreButtonProps {
  hasMoreResults: boolean
  loadMore?(): void
  networkStatus: NetworkStatus
}

export const HotelResultsLoadMoreButton = ({
  hasMoreResults,
  loadMore = () => {},
  networkStatus,
}: HotelResultsLoadMoreButtonProps) => {
  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore

  if (isMoreResultsLoading)
    return (
      <div className='py-8' data-testid='HotelResultsLoadMoreButton-skeleton'>
        <div className='flex h-10 flex-col justify-center'>
          <SkeletonDots />
        </div>
      </div>
    )

  return (
    <div>
      <LoadMore
        hasMoreResults={hasMoreResults}
        isMoreResultsLoading={isMoreResultsLoading}
        onGetMoreResults={loadMore}
      />
    </div>
  )
}
