import { Skeleton } from '@travelpass/design-system'
import {
  ResultsCardContent,
  ResultsCardReviewsLoading,
} from 'src/common/components'
import { HotelResultsCardAmenitiesLoading } from '../../HotelResultsCard'
import { HotelResultsCardRowAmenitiesContainer } from '../HotelResultsCardRowAmenitiesContainer'

export const HotelResultsCardRowContentLoading = () => (
  <ResultsCardContent>
    <section className='space-y-4'>
      <header className='flex justify-between gap-4'>
        <div className='grow space-y-1'>
          <div className='h-4 w-20 md:h-5'>
            <Skeleton />
          </div>
          <div className='w-30 h-4'>
            <Skeleton />
          </div>
        </div>
      </header>
      <div className='flex gap-6'>
        <div className='grow space-y-4'>
          <HotelResultsCardRowAmenitiesContainer>
            <HotelResultsCardAmenitiesLoading limit={6} />
          </HotelResultsCardRowAmenitiesContainer>
          <ResultsCardReviewsLoading />
        </div>
      </div>
    </section>
  </ResultsCardContent>
)
