import { TripRolePermissions } from 'src/__generated__/graphql'

const isOwner = (permission: TripRolePermissions) => {
  return permission === TripRolePermissions.Owner
}

const isPublic = (permission: TripRolePermissions) => {
  return permission === TripRolePermissions.Public
}

export { isOwner, isPublic }
