import { Carousel, Divider, useScreenQuery } from '@travelpass/design-system'
import { reviewItems } from './supportReviewsConstants'
import { StarIcon, SupportContainer } from '../common'

export const SupportReviews = () => {
  const { isMobileOrTablet } = useScreenQuery()

  return (
    <SupportContainer>
      <section className='pb-17 space-y-5 pt-10 lg:space-y-11'>
        <header className='space-y-3 text-center'>
          <p className='type-overline uppercase'>Reviews</p>
          <Divider className='bg-valley h-2px m-a w-27 max-w-full' />
          <h2 className='type-h3'>What Our Travelers Are Saying</h2>
        </header>
        <Carousel
          hideArrows
          hideDots={!isMobileOrTablet}
          size='small'
          slidesPerViewOnDesktop={3}
          slidesPerViewOnTablet={2}
        >
          {reviewItems.map(({ content, name }) => (
            <div key={name} className='p-b-12 p-x-3 p-t-3 lg:p-b-3'>
              <figure className='rounded-br-5 rounded-tl-5 shadow-3 m-0 space-y-4 bg-white px-8 py-10'>
                <div aria-hidden className='space-x-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <blockquote className='mx-0'>
                  <q className='min-h-25 type-body-1-desktop line-clamp-5'>
                    {content}
                  </q>
                </blockquote>
                <figcaption className='type-h5-desktop'>{name}</figcaption>
              </figure>
            </div>
          ))}
        </Carousel>
      </section>
    </SupportContainer>
  )
}
