import { useState } from 'react'
import classNames from 'classnames'

interface BookingCardHeroImageProps {
  hasFirstImageLoaded?: boolean
  imageLink?: string | null
  name?: string
  onError(): void
  onLoad(): void
}

export const BookingCardHeroImage = ({
  hasFirstImageLoaded,
  imageLink,
  name,
  onError,
  onLoad,
}: BookingCardHeroImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const onImgLoad = () => {
    onLoad()
    setIsLoading(false)
  }
  return (
    <div
      className={classNames('h-full w-full object-cover', {
        'opacity-100': !isLoading,
        'opacity-0': isLoading,
      })}
    >
      <span className='sr-only'>Image of {name}</span>
      <img
        alt={`${name} hero`}
        className={classNames(
          'bg-grey400 of-hidden rounded-2 h-41.25 flex w-full flex-col',
          {
            'animate-pulse': isLoading,
          }
        )}
        draggable={false}
        loading={hasFirstImageLoaded ? 'eager' : 'lazy'}
        src={imageLink}
        onError={onError}
        onLoad={onImgLoad}
      />
    </div>
  )
}
