import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import { RefundType } from 'src/__generated__/graphql'
import { Overview } from '../../components'
import { defaultExperienceEvent, type ExperienceEvent } from '../constants'

export const ExperienceProduct = ({
  data,
}: {
  data: ExperienceEvent | null
}) => {
  const features: {
    icon: AvailableIcons
    isHidden: boolean
    title: string
  }[] = [
    {
      icon: 'check',
      isHidden: data?.refundType === RefundType?.None,
      title: 'Free Cancellation',
    },
    {
      icon: 'accessTime',
      isHidden: !data?.duration,
      title: data?.duration ?? 'Duration',
    },
    {
      icon: 'smartPhone',
      isHidden: !data?.hasMobileTicket,
      title: 'Mobile Ticket',
    },
    {
      icon: 'language',
      isHidden: !data?.languages,
      title: data?.languages ?? 'Languages',
    },
  ]
  const hasDescription =
    data?.description && data.description !== defaultExperienceEvent.description
  const hasFeatures = !features.every(({ isHidden }) => isHidden)
  const hasContent = hasDescription || hasFeatures

  return hasContent ? (
    <div className='space-y-4 px-8 pt-8'>
      {hasDescription && <Overview content={data?.description} />}
      {hasFeatures && (
        <div className='grid grid-flow-dense grid-cols-2 gap-x-2 gap-y-1.5 pb-5'>
          {features.map(
            ({ icon, isHidden, title }, index) =>
              !isHidden && (
                <div
                  key={`${title}-${index}`}
                  className='text-body-1 flex flex-row items-center gap-2'
                >
                  <Icon name={icon} />
                  <p className='type-body-1'>{title}</p>
                </div>
              )
          )}
        </div>
      )}
    </div>
  ) : null
}
