import { Button, Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import type { GuideData } from 'src/pages/guides/details/types'
import { GuideDraftShareIntroSettings } from './GuideDraftShareIntroSettings'

interface GuideDraftShareIntroProps {
  guideData: GuideData
  isLoading: boolean
  onChange: VoidFunction
  onCopy: VoidFunction
  onShare: VoidFunction
}

export const GuideDraftShareIntro = ({
  guideData,
  isLoading,
  onChange,
  onCopy,
  onShare,
}: GuideDraftShareIntroProps) => (
  <section className='space-y-4'>
    <GuideDraftShareIntroSettings
      guideData={guideData}
      isLoading={isLoading}
      onChange={onChange}
      onShare={onShare}
    />
    <Divider />
    <div
      className={classNames('md:p-x-6', {
        'm-a w-fit': !guideData?.firstPublishedAt,
      })}
    >
      <Button size='large' startIcon='link' variant='outlined' onClick={onCopy}>
        Copy link
      </Button>
    </div>
  </section>
)
