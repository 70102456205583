import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { BookingDetailsSectionHeader } from 'src/common/components'
import type { ExperiencesBookingDetailsMock } from './ExperiencesBookingDetailsApollo/experiencesBookingDetailsApolloMock'
import { ExperiencesPriceSummary } from '../common/components'

interface ExperiencesBookingDetailsPriceProps {
  experiencesBookingDetailsData: ExperiencesBookingDetailsMock
  onPrintButtonClick(): void
}

export const ExperiencesBookingDetailsPrice = ({
  experiencesBookingDetailsData,
  onPrintButtonClick,
}: ExperiencesBookingDetailsPriceProps) => {
  const { paxMix, totalPrice } = experiencesBookingDetailsData ?? {}
  const { bookingFee, recommendedRetailPrice } = totalPrice?.price ?? {}
  const showPrice = !isEmpty(totalPrice?.price)

  return (
    showPrice && (
      <div>
        <BookingDetailsSectionHeader title='Price Details' />
        <div className={experiencesBookingDetailsPriceCss}>
          <ExperiencesPriceSummary
            fees={bookingFee?.amount}
            paxMix={paxMix}
            subtotal={recommendedRetailPrice?.amount}
            total={recommendedRetailPrice?.amount}
          />
          <div className='hide-on-print'>
            <Button
              label='Print Receipt'
              size='small'
              startIcon='print'
              variant='text'
              onClick={onPrintButtonClick}
            />
          </div>
        </div>
      </div>
    )
  )
}

const experiencesBookingDetailsPriceCss = 'flex flex-col gap-6'
