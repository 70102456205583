import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const likeGuideMutation = gql(`
  mutation LikeGuide($guideId: ID!) {
    likeListGuide(guideId: $guideId) {
      id
      likedByCurrentUser
      numberOfLikes
    }
  }
`)

export const useLikeGuideMutation = () =>
  useMutation(likeGuideMutation, {
    onCompleted: data => {
      if (data?.likeListGuide?.likedByCurrentUser) {
        pushDataToDataLayer('guide_like', {
          guide_id: data?.likeListGuide?.id,
          trigger_url: window.location.href,
        })
        return
      }
      pushDataToDataLayer('guide_unlike', {
        guide_id: data?.likeListGuide?.id,
        trigger_url: window.location.href,
      })
    },
  })
