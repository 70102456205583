import { Icon, Input } from '@travelpass/design-system'

export const FaqSearch = ({
  onSearchTermChange,
  searchTerm,
}: {
  searchTerm: string
  onSearchTermChange(value: string): void
}) => (
  <div className={containerCss}>
    <div className={textContainerCss}>
      <h5 className={textCss}>Search for topics</h5>
    </div>
    <Input
      fullWidth
      aria-label='Search'
      placeholder='Search'
      slotBefore={<Icon name='search' />}
      type='text'
      value={searchTerm}
      onChange={event => onSearchTermChange(event.target.value)}
    />
  </div>
)

const containerCss = 'space-y-4'
const textContainerCss = 'text-center'
const textCss = 'type-h5'
