import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Avatar,
  Button,
  ModalNext,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Confetti from 'react-confetti'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { explorePath } from 'src/constants'
import { FollowingDropdown } from './FollowingDropdown'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
import { useGetDivDimensions } from './hooks/useGetDivDimensions'
import { useFollowMutation } from '../profile/components/hooks/useFollowMutation'
import { useUnfollowMutation } from '../profile/components/hooks/useUnfollowMutation'

interface YouVotedModalProps {
  onClose: VoidFunction
  userProfile: ToggleVoteModalProps
}

const GET_IS_FOLLOWED = gql(`
  query getIsFollowed($userId: ID!) {
    viewUserProfile(userId: $userId) {
      id
      isFollowed
    }
  } 
  `)

export const YouVotedModal = ({ onClose, userProfile }: YouVotedModalProps) => {
  const { isTabletScreen, isDesktopScreen } = useScreenQuery()
  const [ref, { height, width }] = useGetDivDimensions()

  const { displayName, profileImageUrl, userId, isUserOwner } =
    userProfile ?? {}
  const { data, loading: isFollowedLoading } = useQuery(GET_IS_FOLLOWED, {
    variables: { userId },
  })
  const { isFollowed } = data?.viewUserProfile ?? {}
  const [follow] = useFollowMutation({})
  const [unfollow, { loading: unfollowLoading }] = useUnfollowMutation({})
  const variables = { input: { userId } }

  const [isConfettiEnabled, setIsConfettiEnabled] = useState(true)
  const [confettiPieces, setConfettiPieces] = useState(150)
  const [replaceBtnWithDropdown, setReplaceBtnWithDropdown] = useState(false)
  const [statusMessage, setStatusMessage] = useState<{
    type: 'info' | 'success' | 'error'
    message: string
  }>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiPieces(0)
    }, 2200)

    return () => clearTimeout(timer)
  }, [])

  const pushToDataLayer = (event: string) => {
    pushDataToDataLayer(event, {
      voted_user_id: userId,
      voted_user_name: displayName,
      timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
    })
  }

  const onFollowClick = async () => {
    if (isFollowed) {
      setStatusMessage({
        type: 'info',
        message: 'Would you like to unfollow this user?',
      })
      setReplaceBtnWithDropdown(true)
      return
    }
    try {
      const response = await follow({ variables })
      if (response?.data) {
        pushToDataLayer('follow_competition_voting')
        setStatusMessage({
          type: 'success',
          message: `You are now following ${displayName}!`,
        })
      }
    } catch {
      setStatusMessage({ type: 'error', message: 'An error occurred' })
    } finally {
      setTimeout(() => {
        setStatusMessage(null)
      }, 2000)
    }
  }

  const onOpenAllGuides = () => {
    window.open(explorePath, '_blank')
    pushToDataLayer('explore_competition_voting')
  }

  const onUnfollowChoice = async (choice: 'Yes' | 'No') => {
    if (choice === 'No') {
      setReplaceBtnWithDropdown(false)
      setStatusMessage(null)
      return
    }
    try {
      const response = await unfollow({
        variables,
      })
      if (response?.data) {
        setStatusMessage({
          type: 'success',
          message: `Successfully unfollowed ${displayName}!`,
        })
      }
    } catch {
      setStatusMessage({ type: 'error', message: 'An error occurred' })
    } finally {
      setTimeout(() => {
        setReplaceBtnWithDropdown(false)
        setStatusMessage(null)
      }, 2000)
    }
  }

  return (
    <ModalNext
      className='text-center'
      contentRef={ref}
      size='medium'
      title={`You voted for ${displayName}!`}
      onClose={onClose}
    >
      <div className='relative'>
        {isConfettiEnabled && (
          <Confetti
            gravity={0.05}
            height={height}
            numberOfPieces={confettiPieces}
            run={true}
            width={width}
            onConfettiComplete={() => setIsConfettiEnabled(false)}
          />
        )}
        <img
          alt=''
          className='-mr-5 inline hidden md:inline-block'
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-left.svg'
        />
        <Avatar size='xl' src={profileImageUrl} />
        <img
          alt=''
          className='-ml-5 inline hidden md:inline-block'
          src='https://static.travelpass.com/assets/job-campaign-2024/confetti-right.svg'
        />
        <h6 className='type-h6 mt-8'>
          Thanks for voting! Come back tomorrow and vote again to help{' '}
          {displayName} win! Remember, you can vote for as many travel-savvy
          profiles as you want once every 24hrs.
        </h6>
        <div className='flex flex-col items-center gap-6 pb-8 pt-12'>
          <div className='relative flex flex-col items-center justify-center gap-2 lg:flex-row-reverse'>
            <p
              className={classNames(
                `type-body-2 absolute left-0 right-0 top--6 ${statusMessage === null ? 'invisible' : ''}`,
                {
                  'c-success': statusMessage?.type === 'success',
                  'c-error': statusMessage?.type === 'error',
                  'c-grey-800': statusMessage?.type === 'info',
                }
              )}
            >
              {statusMessage?.message}
            </p>
            {!isUserOwner && (
              <>
                {replaceBtnWithDropdown ? (
                  <FollowingDropdown
                    loading={unfollowLoading}
                    onUnfollowChoice={onUnfollowChoice}
                  />
                ) : (
                  <Button
                    aria-label={`Follow ${displayName}'s profile`}
                    isDisabled={isFollowedLoading}
                    size={
                      isDesktopScreen
                        ? 'large'
                        : isTabletScreen
                          ? 'medium'
                          : 'small'
                    }
                    startIcon={
                      isFollowed ? 'checkCircleOutline' : 'personOutline'
                    }
                    variant='filled'
                    onClick={onFollowClick}
                  >
                    {isFollowed ? 'Following' : 'Follow'}
                  </Button>
                )}
              </>
            )}

            <Button
              size={
                isDesktopScreen ? 'large' : isTabletScreen ? 'medium' : 'small'
              }
              startIcon='formatListBulleted'
              variant='outlined'
              onClick={onOpenAllGuides}
            >
              Explore All guides
            </Button>
          </div>
          <Button
            endIcon='arrowForward'
            size='small'
            variant='text'
            onClick={() => {
              pushToDataLayer('learn_more_competition_voting')
              window.open('/competition/about', '_blank')
            }}
          >
            Learn more about the contest
          </Button>
        </div>
      </div>
    </ModalNext>
  )
}
