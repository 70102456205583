import { Button, MenuItem, Popover } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { GuideSorts } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { capitalizeFirstLetterOfWords } from 'src/utils'

export const GuidesSortBy = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const sortBy = searchParams.get('sort') as GuideSorts // unable to convert from string to enum in a way that doesn't induce rage or depression

  const label = sortBy
    ? Object.entries(GuideSorts)
        .find(([_, value]) => sortBy === value)[1]
        .replaceAll('_', ' ')
    : undefined
  const labelWithText = label ? `Sort by: ${label}` : 'Sort by...'

  return (
    <Popover
      placement='bottom-end'
      trigger={
        <Button
          aria-label='Sort guides'
          endIcon='expandMore'
          size='small'
          startIcon='sort'
          variant='outlined'
        >
          {labelWithText}
        </Button>
      }
    >
      {Object.entries(GuideSorts).map(([_, sortValue]) => {
        const label = capitalizeFirstLetterOfWords(
          sortValue.replaceAll('_', ' ')
        ).replace('To', 'to')

        return (
          <MenuItem
            key={sortValue}
            isHighlighted={sortValue === sortBy}
            label={label}
            value={sortValue}
            onClick={() => {
              setSearchParams(sp => {
                sp.set('sort', sortValue)
                return sp
              })
              pushDataToDataLayer('guides_results_sort_by', {
                value: sortValue,
              })
            }}
          />
        )
      })}
    </Popover>
  )
}
