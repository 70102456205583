import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  type DateRange,
  DateRangePicker,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { timeTemplate } from 'src/pages/trips/constants'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import { formatDate, getDateFromUTC } from 'src/utils'
import { AddEventByType } from './AddEventByType'
import { AddEventTabs } from './AddEventTabs'

export type Tabs = 'stays' | 'experiences' | 'custom'

const titleText = {
  stays: ' a Stay',
  experiences: ' an Experience',
  custom: ' a Custom Event',
}

export const AddEvent = ({
  tripId,
  activeTabIndex,
  defaultTab = 'stays',
  hoveredEventId,
  selectedEventId,
  setScrollToEventId,
  onBack,
  onHoverCard,
}: {
  tripId: string
  activeTabIndex: number
  defaultTab: Tabs
  hoveredEventId?: string
  selectedEventId?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  onBack: () => void
  onHoverCard: (eventId?: string) => void
}) => {
  const [dateRange, setDateRange] = useState<DateRange>()
  const [tab, setTab] = useState<Tabs>(defaultTab)
  const { isLoading, tripDetailsData } = useGetTripDetailsQuery(tripId)

  const {
    endDate: initialEndDate,
    startDate: initialStartDate,
    timeZone,
  } = tripDetailsData ?? {}
  const endDate = dayjs(getDateFromUTC(initialEndDate, timeZone))
  const endDateTime = endDate.format(timeTemplate)
  const startDate = dayjs(getDateFromUTC(initialStartDate, timeZone))
  const startDateTime = startDate.format(timeTemplate)
  const formattedEndDate = dayjs(formatDate(endDate))
  const formattedStartDate = dayjs(formatDate(startDate))

  useEffect(() => {
    if (tripDetailsData?.startDate) {
      const from = formattedStartDate.add(activeTabIndex, 'day')

      if (tab === 'stays' && !from.isSame(formattedEndDate))
        return setDateRange({
          from,
          to: from.add(1, 'day'),
        })

      return setDateRange({
        from,
      })
    }
  }, [tripDetailsData, tab])

  if (isLoading || !dateRange?.from) {
    return null
  }

  return (
    <div className='flex w-full flex-col gap-8'>
      <div>
        <div className='p-b-2.5'>
          <Button
            aria-label='Back to Itinerary'
            startIcon='chevronLeft'
            variant='text'
            onClick={onBack}
          >
            Itinerary
          </Button>
        </div>
        <AddEventTabs tab={tab} onTabUpdate={setTab} />
      </div>
      <div className='flex items-center justify-between'>
        <div className='text-h6'>
          Add
          {titleText[tab]}
        </div>
        <div className='basis-1/3'>
          {tab !== 'custom' ? (
            <DateRangePicker
              fromDate={formattedStartDate.toDate()}
              label=''
              selected={dateRange}
              toDate={formattedEndDate.toDate()}
              onSelect={({ from, to }) => setDateRange({ from, to })}
            />
          ) : (
            <div className='pt-13'></div>
          )}
        </div>
      </div>
      <AddEventByType
        dateRange={dateRange}
        endDateTime={endDateTime}
        hoveredEventId={hoveredEventId}
        selectedEventId={selectedEventId}
        setScrollToEventId={setScrollToEventId}
        startDateTime={startDateTime}
        tripId={tripId}
        type={tab}
        onComplete={onBack}
        onHoverCard={onHoverCard}
      />
    </div>
  )
}
