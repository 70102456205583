import { Button, SkeletonDots } from '@travelpass/design-system'
import { TripForm } from 'src/pages/trips/components/TripForm'
import type { TripFormValues } from 'src/pages/trips/constants'
import { useCreateTrip } from './useCreateTrip'

export const CreateTrip = () => {
  const { createTrip, loading } = useCreateTrip()
  const onSubmit = (formValues: TripFormValues) => {
    createTrip(formValues)
  }

  return (
    <div className='rd-3 max-w-183.25 shadow-1 md:pb-15 lg:px-13 w-full bg-white px-4 py-11 md:px-12 md:pt-11'>
      <header className='mb-12 flex w-full flex-row items-center justify-center md:mb-14 lg:mb-10'>
        <h1 className='type-h1'>Create a new trip</h1>
      </header>

      <TripForm onSubmit={onSubmit}>
        <div className='pt-6 md:pt-4'>
          {loading ? (
            <SkeletonDots />
          ) : (
            <div className='flex justify-center'>
              <Button
                aria-label='Create trip'
                size='large'
                startIcon='add'
                type='submit'
              >
                Create Trip
              </Button>
            </div>
          )}
        </div>
      </TripForm>
    </div>
  )
}
