import { PageLayoutContainer } from 'src/common/components'
import { ExperiencesBookingDetailsBreadcrumbsLoading } from '../ExperiencesBookingDetailsBreadcrumbs'
import { ExperiencesBookingDetailsHeroLoading } from '../ExperiencesBookingDetailsHero'
import {
  experiencesBookingDetailsCss,
  experiencesBookingDetailsHeaderCss,
  experiencesBookingDetailsWrapperCss,
} from '../experiencesBookingDetailsStyles'

export const ExperiencesBookingDetailsApolloLoading = () => (
  <div className={experiencesBookingDetailsWrapperCss}>
    <PageLayoutContainer size='medium'>
      <div className={experiencesBookingDetailsCss}>
        <div className={experiencesBookingDetailsHeaderCss}>
          <ExperiencesBookingDetailsBreadcrumbsLoading />
          <ExperiencesBookingDetailsHeroLoading />
        </div>
      </div>
    </PageLayoutContainer>
  </div>
)
