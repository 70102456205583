import { TravelpassLogo } from 'src/common/components'
import {
  ASSETS_PATH,
  travelersList,
} from '../../../home-old/HomeCompany/travelersConstants'

export const BlackFridayCompanyTravelers = () => {
  const randomStart = Math.floor(Math.random() * 3) * 3
  const randomTravelers = travelersList.slice(randomStart, randomStart + 3)
  const [traveler1, traveler2, traveler3] = randomTravelers

  return (
    <section className={blackFridayTravelersContainer}>
      <div className='sm:w-164px lg:w-236px'>
        <TravelpassLogo />
      </div>
      <h2 className={blackFridayTravelersH2}>
        We&apos;re Travelpass, a company <br className='md:hidden' />
        built by travelers <span className='c-valley'>just like you.</span>
      </h2>
      <div className='relative flex'>
        <div className={blackFridayTravelersImageBorder}></div>
        <div className={blackFridayTravelersImage1}>
          <div className='relative'>
            <div className={linearGradientClasses}></div>
            <img
              alt=''
              className={imageClasses}
              srcSet={imageSourceSet(traveler1.desktop, traveler1.mobile)}
            />
            <div className='absolute bottom-4 left-5'>
              <h5 className='type-h5 c-white'>{traveler1.title}</h5>
              <p className='type-body-2 c-white'>
                {traveler1.description} <br className='md:hidden' />
                at Travelpass
              </p>
            </div>
          </div>
        </div>
        <div className={blackFridayTravelersImage2}>
          <div className='relative'>
            <div className={linearGradientClasses}></div>
            <img
              alt=''
              className={imageClasses}
              srcSet={imageSourceSet(traveler2.desktop, traveler2.mobile)}
            />
            <div className='absolute bottom-4 left-5'>
              <h5 className='type-h5 c-white'>{traveler2.title}</h5>
              <p className='type-body-2 c-white'>
                {traveler2.description} <br className='md:hidden' />
                at Travelpass
              </p>
            </div>
          </div>
        </div>
        <div className={blackFridayTravelersImage3}>
          <div className='relative'>
            <div className={linearGradientClasses}></div>
            <img
              alt=''
              className={imageClasses}
              srcSet={imageSourceSet(traveler3.desktop, traveler3.mobile)}
            />
            <div className='absolute bottom-4 left-5'>
              <h5 className='type-h5 c-white'>{traveler3.title}</h5>
              <p className='type-body-2 c-white'>
                {traveler3.description} at Travelpass
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const imageSourceSet = (desktop, mobile) =>
  `${ASSETS_PATH + mobile} 375w, ${ASSETS_PATH + desktop} 768w`

const blackFridayTravelersContainer =
  'flex flex-col items-center sm:pt-67px md:pt-85px lg:pt-127px sm:mb-100px lg:mb-134px'
const blackFridayTravelersH2 =
  'type-h2 sm:mt-25px sm:mb-42px md:my-10 lg:mt-64px lg:mb-100px'
const blackFridayTravelersImageBorder =
  'absolute sm:left-25px left-0 right-0 m-auto sm:bottom-25px lg:bottom-103px rounded-30px b-r-2 b-canyon b-dashed sm:h-138px sm:w-300px lg:h-300px lg:w-707px'
const blackFridayTravelersImage1 = 'sm:pt-25px lg:pt-39px'
const blackFridayTravelersImage2 =
  'sm:pt-76px sm:ml--18px lg:pt-175px lg:ml--32px'
const blackFridayTravelersImage3 = 'hidden md:block md:ml--36px lg:ml--64px'
const imageClasses = 'rounded-30px sm:h-252px sm:w-164px lg:h-446px lg:w-290px'
const linearGradientClasses = `${imageClasses} absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/60%`
