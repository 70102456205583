import { HomeContainer } from '../../../home/HomeContainer'

export const BlackFridayCompanyAbout = () => {
  return (
    <section className={backgroundClasses}>
      <HomeContainer size='md'>
        <div className={blackFridayCompanyAbout}>
          <div>
            <p className={blackFridayCompanyAboutBox1}>
              Travelpass is designed{' '}
              <span className='c-valley'>for travelers, by travelers.</span>
            </p>
            <p className={blackFridayCompanyAboutBox2}>
              With each new product and update, we put you–an explorer at heart–
              at the center of all we create.
            </p>
          </div>
          <div>
            <p className={blackFridayCompanyAboutBox3}>
              With Travelpass, you can expect a stress-free, inspiring, and
              innovative travel experience.
              <br />
              The best part?
              <br />
              <br />
              We&apos;re just getting started.
            </p>
          </div>
        </div>
      </HomeContainer>
    </section>
  )
}

const backgroundClasses =
  'w-full bg-warm-grey lg:bg-cover sm:bg-[length:100vw_auto] bg-[position:center_bottom] bg-no-repeat lg:bg-auto lg:bg-[url(https://static.travelpass.com/assets/homev2-page/about.webp)] sm:bg-[url(https://static.travelpass.com/assets/homev2-page/about-mobile.webp)]'
const blackFridayCompanyAbout =
  'pb-70 lg:pt-50 pt-20 text-24px font-archivo font-not-oblique lg:font-700 font-400 sm:flex flex-col sm:items-center lg:flex lg:items-initial justify-center lg:space-x-5'
const blackFridayCompanyAboutBox1 =
  'bg-forest c-white sm:w-full md:w-586px p-14 rounded-bl-5 rounded-tr-5'
const blackFridayCompanyAboutBox2 =
  'sm:w-full md:w-586px p-14 bg-grey300 rounded-br-5 rounded-tl-5'
const blackFridayCompanyAboutBox3 =
  'sm:w-full md:w-586px lg:w-384px p-14 bg-white rounded-br-5 rounded-tl-5'
