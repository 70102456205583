import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  Divider,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { BookingQuestionEnum } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { PageLayoutContainer } from 'src/common/components/PageLayoutContainer'
import { StepCard as Card } from 'src/common/components/StepCard/StepCard'
import { useIsElementOnScreen } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { ExperiencesCancellationPolicyModal } from 'src/pages/experiences/common/components/ExperiencesCancellationPolicy'
import { generateExperienceDetailsUrl } from 'src/utils'
import { getTimeFromMilitaryTimeString } from 'src/utils/timeUtils'
import { BookingQuestionsFields } from './BookingQuestionsFields'
import { BookingQuestionsSummary } from './BookingQuestionsSummary'
import { CancellationCard } from './CancellationCard'
import { ContactInformationFields } from './ContactInformationFields'
import { ContactInformationFormValidation } from './ContactInformationFormValidation'
import { ContactInformationSummary } from './ContactInformationSummary'
import { ExperienceDurationDisplay } from './ExperienceDurationDisplay'
import { ExperiencesBookingForm } from './ExperiencesBookingForm'
import { ExperienceDetailsSummaryFeatures } from './ExperiencesDetailsSummaryFeatures'
import { PaymentOptionsAccordion } from './PaymentOptionsAccordion'
import { StepTransition } from './StepTransition'
import { StickyNav } from './StickyNav'
import { SummaryImageCarousel } from './SummaryImageCarousel'
import { TravelDetailsValidationButton } from './TravelDetailsValidationButton'
import { useExperienceHold } from './useExperienceHold'
import { useExperienceStepFlow } from './useExperienceStepFlow'
import { paxMixFormatter } from '../common/components/utils'

// Per https://partnerresources.viator.com/travel-commerce/merchant/pricing/#invoicing
// "It’s possible to make a booking in one of the following currencies: AUD, EUR, GBP, USD."
const CURRENCY_SYMBOL = {
  AUD: '$',
  EUR: '€',
  GBP: '£',
  USD: '$',
}

export const ExperiencesBooking = () => {
  const [
    isExperiencesCancellationPolicyModalOpen,
    setisExperiencesCancellationPolicyModalOpen,
  ] = useState(false)

  const navigate = useNavigate()
  const { data, loading } = useExperienceHold()
  const { addSuccessSnack } = useSnackbar()

  const experienceHold = data?.getExperienceHold
  const images = experienceHold?.product.images.size480x320
  const hasMobileTicket = experienceHold?.product?.ticketInfo?.hasMobileTicket
  const price = experienceHold?.grandTotal?.amount
  const amount = price ? parseFloat(price) : 0

  const total_quantity = data?.getExperienceHold?.paxMix.reduce(
    (total, ageBand) => total + ageBand.numberOfTravelers,
    0
  )

  const experienceProductMarketingData = {
    category_id: 'Experience',
    experience: experienceHold?.product?.id,
    experience_address:
      experienceHold?.product?.logistics?.start[0]?.location?.address,
    experience_city:
      experienceHold?.product?.logistics?.start[0]?.location?.city,
    experience_country:
      experienceHold?.product?.logistics?.start[0]?.location?.country,
    experience_postal_code:
      experienceHold?.product?.logistics?.start[0]?.location?.postalCode,
    experience_state:
      experienceHold?.product?.logistics?.start[0]?.location?.state,
    experience_latitude:
      experienceHold?.product?.logistics?.start[0]?.location?.latitude,
    experience_longitude:
      experienceHold?.product?.logistics?.start[0]?.location?.longitude,
    end_date: experienceHold?.travelDate,
    item_list_name: experienceHold?.product?.title,
    location: experienceHold?.product?.destinations[0]?.destinationName,
    start_date: experienceHold?.travelDate,
    start_time: experienceHold?.startTime,
    title: experienceHold?.product?.title,
    total_price: experienceHold?.grandTotal?.amount,
    total_quantity,
  }

  const formattedTime = getTimeFromMilitaryTimeString(experienceHold?.startTime)
  const formattedDate = dayjs(experienceHold?.travelDate).format(
    'dddd, MMMM D, YYYY'
  )
  const refundType = experienceHold?.product?.cancellationPolicy?.type
  const refundDescription =
    experienceHold?.product?.cancellationPolicy?.description

  const isProductAvailable = !!experienceHold
  const isPickupRequired =
    experienceHold?.product.bookingQuestions.findIndex(
      ({ id }) => id === BookingQuestionEnum.PickupPoint
    ) > -1

  const formattedDateTime = formattedTime
    ? `${formattedDate} - ${formattedTime}`
    : formattedDate

  const destinations = experienceHold?.product?.destinations
    ?.map(({ destinationName: name }) => name)
    .join(', ')

  const constructedExperienceRoute = generateExperienceDetailsUrl({
    id: experienceHold?.product?.id,
  })

  const languageGuides = useMemo(() => {
    if (experienceHold?.productOptionCode) {
      const productOption = experienceHold.product.productOptions?.find(
        option => option.productOptionCode === experienceHold.productOptionCode
      )

      if (!productOption) {
        addSuccessSnack({
          title:
            "Oh man! Looks like your selection is no longer available. We hate when that happens. We've got tons of other options for you with great ratings and great prices. Check them out!",
        })

        navigate(constructedExperienceRoute, { replace: true })
      }

      return productOption.languageGuides
    }

    if (experienceHold?.product?.languageGuides) {
      return experienceHold.product.languageGuides
    }

    return []
  }, [experienceHold])

  const pickupAllowed = useMemo(() => {
    let isAllowed =
      experienceHold?.product.logistics.travelerPickup.pickupOptionType ===
      'PICKUP_EVERYONE'

    if (!isAllowed && experienceHold?.productOptionCode) {
      const isPickupAndMeetAtStartPoint =
        experienceHold?.product.logistics.travelerPickup.pickupOptionType !==
        'PICKUP_AND_MEET_AT_START_POINT'
      const productOption = experienceHold?.product.productOptions.find(
        opt => opt.productOptionCode === experienceHold?.productOptionCode
      )
      const isAllowedByOption =
        productOption?.description?.includes('Pickup included') ?? false
      isAllowed = isPickupAndMeetAtStartPoint || isAllowedByOption
    }

    return isAllowed
  }, [experienceHold])

  useEffect(() => {
    if (experienceHold?.product?.id) {
      pushDataToDataLayer('experienceBookingCheckout', {
        ...experienceProductMarketingData,
      })
    }
  }, [experienceHold?.product?.id])

  const step = useExperienceStepFlow()

  const priceSummaryRef = useRef(null)
  const [, hasMounted] = useState(false)
  const setPriceSummaryRef = useCallback(node => {
    priceSummaryRef.current = node
    hasMounted(!!priceSummaryRef.current)
  }, [])
  const isPriceSummaryOnScreen = useIsElementOnScreen(priceSummaryRef)
  const showStickyNav = !isPriceSummaryOnScreen

  const onClickCancellationPolicyTerm = () => {
    setisExperiencesCancellationPolicyModalOpen(true)
  }

  const onClickStickyNav = e => {
    e.preventDefault()
    priceSummaryRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const breadcrumbs = [
    {
      label: 'Home',
      onClick: () => {
        navigate('/', { replace: true })
      },
    },
    {
      label: experienceHold?.product?.title,
      onClick: () => {
        navigate(constructedExperienceRoute, { replace: true })
      },
    },
    {
      label: 'Checkout',
    },
  ]

  return (
    <>
      <Helmet pageName='Book Your Experience' />
      <PageLayoutContainer>
        {loading ? (
          <SkeletonDots />
        ) : (
          <>
            {isExperiencesCancellationPolicyModalOpen && (
              <ExperiencesCancellationPolicyModal
                description={
                  experienceHold?.product?.cancellationPolicy?.description
                }
                onClose={() =>
                  setisExperiencesCancellationPolicyModalOpen(false)
                }
              />
            )}
            <ExperiencesBookingForm
              experienceHoldData={experienceProductMarketingData}
              isPickupRequired={isPickupRequired}
              productCode={experienceHold?.product?.productCode}
            >
              <div className='hidden pb-6 pt-4 md:block'>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <header className='space-y-4 pt-4'>
                <h1 className='text-h4 mb-4 lg:mb-8'>Checkout</h1>
              </header>
              <div className='flex w-full flex-col gap-4 pb-8 lg:flex-row-reverse lg:gap-8'>
                <div className='lg:w-1/2'>
                  <section className='bg-warm-grey rounded-4 border-1 border-grey-300 sticky top-6 space-y-6 border-solid p-6'>
                    <div
                      className='grid grid-cols-2 gap-4'
                      id='booking-summary'
                    >
                      <div className='rounded-4 overflow-hidden'>
                        <SummaryImageCarousel
                          images={images}
                          title={experienceHold?.product?.title}
                        />
                      </div>
                      <div className='space-y-4'>
                        <h1 className='type-h4 c-forest-light line-clamp-3'>
                          {experienceHold?.product?.title}
                        </h1>
                        <ExperienceDurationDisplay
                          destinations={destinations}
                          itinerary={experienceHold?.product.itinerary}
                        />
                      </div>
                    </div>
                    <Divider className='mb-6' />
                    <ExperienceDetailsSummaryFeatures
                      date={formattedDateTime}
                      hasMobileTicket={hasMobileTicket}
                      languages={languageGuides.map(l => l.languageName)}
                      travelers={paxMixFormatter(experienceHold?.paxMix)}
                    />
                    <p
                      className='flex scroll-mt-4 justify-between'
                      ref={setPriceSummaryRef}
                    >
                      <span
                        className='text-h6 text-newForest text-left'
                        role='cell'
                      >
                        Total Price
                      </span>
                      <span className='text-h6 text-newForest' role='cell'>
                        {
                          CURRENCY_SYMBOL[
                            'USD'
                          ] /* TODO: When we begin supporting international currency, this will need to be dynamic */
                        }
                        {price}
                      </span>
                    </p>
                    <small className='type-body-1 c-grey-800 block text-sm'>
                      *This payment will be processed in the United States
                    </small>
                    {isProductAvailable && (
                      <CancellationCard
                        description={refundDescription}
                        type={refundType}
                      />
                    )}
                  </section>
                </div>
                {showStickyNav && (
                  <StickyNav totalPrice={price} onClick={onClickStickyNav} />
                )}
                <div className='flex flex-col gap-4 lg:w-1/2'>
                  <Card aria-current={step.is.contact ? 'step' : null}>
                    <div>
                      <span
                        className={`float-right transition-opacity ${step.is.contact ? 'opacity-0' : ''}`}
                        hidden={step.is.contact}
                      >
                        <Button
                          label='Edit'
                          size='small'
                          startIcon='modeEdit'
                          variant='outlined'
                          onClick={() => step.to.contact()}
                        />
                      </span>
                      <h2
                        className={`type-h4 m-0 ${!step.is.contact ? 'c-grey-800' : ''}`}
                      >
                        1. Contact Information
                      </h2>
                    </div>
                    <StepTransition
                      active={step.is.contact}
                      edit={<ContactInformationFields />}
                      summary={<ContactInformationSummary />}
                    />
                    <div
                      className={
                        !step.is.contact ? 'hidden' : 'flex justify-center'
                      }
                      hidden={!step.is.contact}
                    >
                      <ContactInformationFormValidation
                        onClick={() => step.to.details()}
                      />
                    </div>
                  </Card>
                  <Card aria-current={step.is.details ? 'step' : null}>
                    <div>
                      <span
                        className={`float-right transition-opacity ${step.is.details ? 'opacity-0' : ''}`}
                        hidden={!step.passed.details}
                      >
                        <Button
                          label='Edit'
                          size='small'
                          startIcon='modeEdit'
                          variant='outlined'
                          onClick={() => step.to.details()}
                        />
                      </span>
                      <h2
                        className={`type-h4 m-0 ${step.is.details ? 'c-grey-800' : ''}`}
                      >
                        2. Travel Details
                      </h2>
                    </div>
                    {step.passed.contact && (
                      <>
                        <StepTransition
                          active={step.is.details}
                          edit={
                            <>
                              <BookingQuestionsFields
                                experienceProduct={experienceHold?.product}
                                languageGuides={languageGuides}
                                paxMix={experienceHold?.paxMix}
                                pickupAllowed={pickupAllowed}
                              />
                              <div className='flex justify-center'>
                                <TravelDetailsValidationButton
                                  onClick={step.to.payment}
                                />
                              </div>
                            </>
                          }
                          summary={
                            <BookingQuestionsSummary
                              languageGuides={languageGuides}
                            />
                          }
                        />
                      </>
                    )}
                  </Card>
                  <Card aria-current={step.is.payment ? 'step' : null}>
                    <h2 className='type-h4 m-0'>3. Billing Information</h2>
                    {step.passed.details && (
                      <PaymentOptionsAccordion
                        amount={amount}
                        onClickCancellationPolicyTerm={
                          onClickCancellationPolicyTerm
                        }
                      />
                    )}
                  </Card>
                </div>
              </div>
            </ExperiencesBookingForm>
          </>
        )}
      </PageLayoutContainer>
    </>
  )
}
