import { HotelPolicies, PageLayoutContainer } from 'src/common/components'
import { useHotelPoliciesStandardHotelQuery } from '../../hooks/useHotelPoliciesStandardHotelQuery'

export const HotelPolicy = () => {
  const { hotelPoliciesData } = useHotelPoliciesStandardHotelQuery()
  const { checkinInstructions, checkinSpecialInstructions, fees, policies } =
    hotelPoliciesData ?? {}
  return (
    <div className='flex-justify-center bg-warm-grey flex'>
      <PageLayoutContainer>
        <div className='lg:py-15 space-y-12 py-10'>
          <h2 className='type-h3 text-center'>Hotel Policies</h2>
          <HotelPolicies
            checkinInstructions={checkinInstructions}
            checkinSpecialInstructions={checkinSpecialInstructions}
            contentType='type-body-1'
            fees={fees}
            policies={policies}
            titleType='type-h4'
          />
        </div>
      </PageLayoutContainer>
    </div>
  )
}
