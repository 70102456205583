const bookingDetailsRoomAmenitiesClasses =
  'grid gap-y-6px gap-x-2 auto-flow-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3'

const bookingDetailsRoomAmenityClasses = 'flex flex-row gap-2 items-center'

const bookingDetailsRoomClasses = 'space-y-9'

const bookingDetailsRoomGroupClasses = 'space-y-4'

const bookingDetailsRoomSummaryClasses =
  'flex flex-col md:flex-row gap-5 lg:gap-13 items-center'

const bookingDetailsRoomSummaryContentClasses =
  'flex flex-col flex-grow-1 w-full items-center md:items-initial'

const bookingDetailsRoomSummaryImageClasses =
  'rounded-2 h-43 object-cover w-full max-w-full lg:max-w-89'

const bookingDetailsRoomTitleClasses = 'type-h4 pb-3'

export {
  bookingDetailsRoomAmenitiesClasses,
  bookingDetailsRoomAmenityClasses,
  bookingDetailsRoomClasses,
  bookingDetailsRoomGroupClasses,
  bookingDetailsRoomSummaryContentClasses,
  bookingDetailsRoomSummaryClasses,
  bookingDetailsRoomSummaryImageClasses,
  bookingDetailsRoomTitleClasses,
}
