import type { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import { getGeocode } from 'use-places-autocomplete'
import { explorePath } from 'src/constants'
import { BOUNDING_BOX_PARAMS } from 'src/pages/explore/bounding-box/constants/boundingBoxParams'
import { extractViewport } from 'src/pages/explore/bounding-box/utils/extractViewport'
import { COUNTRY_STATE_PARAMS } from 'src/pages/explore/country-state/constants/COUNTRY_STATE_PARAMS'
import { KEYWORD_SEARCH_PHRASE } from 'src/pages/explore/keyword-search/constants'

export const handleLocationSearch = async (
  selection: Suggestion,
  navigate: NavigateFunction,
  pathname: string,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  const [result] = await getGeocode({ placeId: selection?.place_id })
  const viewport = extractViewport(result?.geometry?.viewport)

  setSearchParams(sp => {
    sp.set(BOUNDING_BOX_PARAMS.ne.lat, String(viewport.ne.lat))
    sp.set(BOUNDING_BOX_PARAMS.ne.lng, String(viewport.ne.lng))
    sp.set(BOUNDING_BOX_PARAMS.sw.lat, String(viewport.sw.lat))
    sp.set(BOUNDING_BOX_PARAMS.sw.lng, String(viewport.sw.lng))
    sp.set('title', result?.formatted_address)
    sp.set(
      'location',
      result?.address_components?.[0]?.long_name ||
        result?.address_components?.[0]?.short_name ||
        result?.formatted_address
    )

    sp.delete('miles')
    sp.delete('lat')
    sp.delete('lng')
    sp.delete('locationTitle')
    sp.delete(KEYWORD_SEARCH_PHRASE)
    sp.delete(COUNTRY_STATE_PARAMS.country)
    sp.delete(COUNTRY_STATE_PARAMS.state)

    return sp
  })

  if (!pathname.includes('/bounding-box'))
    navigate({
      pathname: `${explorePath}/bounding-box`,
      search: searchParams.toString(),
    })
}
