import type { Dispatch, SetStateAction } from 'react'
import isEmpty from 'lodash.isempty'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import {
  DetailsMinimalExperienceHero,
  ExperiencesFeatures,
} from 'src/common/components'
import { Overview } from 'src/pages/trips/components/TripDrawerContent/components'
import { TripDetailsExploreDrawerExperienceFooter } from './TripDetailsExploreDrawerExperienceFooter'

interface TripDetailsExploreDrawerExperienceProps {
  tripId: string
  experienceProduct: ExperienceProduct
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  onEventAdded?: () => void
}

export const TripDetailsExploreDrawerExperience = ({
  tripId,
  experienceProduct,
  setScrollToEventId,
  onEventAdded,
}: TripDetailsExploreDrawerExperienceProps) => {
  return !isEmpty(experienceProduct) ? (
    <>
      <div className='space-y-4'>
        <div className='space-y-3'>
          <DetailsMinimalExperienceHero
            detailsMinimalExperienceHeroData={experienceProduct}
          />
        </div>
        <Overview content={experienceProduct.description} />
        <ExperiencesFeatures experiencesProductData={experienceProduct} />
      </div>
      <TripDetailsExploreDrawerExperienceFooter
        item={experienceProduct}
        setScrollToEventId={setScrollToEventId}
        tripId={tripId}
        onEventAdded={onEventAdded}
      />
    </>
  ) : null
}
