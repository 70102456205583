import { useState } from 'react'
import {
  Divider,
  IconButton,
  MenuItem,
  Popover,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { dashboardPath } from 'src/constants'
import { getGuideDetailsUrl } from 'src/utils'
import { GuideDraftEditModalOld } from '.'
import { GuideDraftDeleteModalOld } from './GuideDraftDeleteModalOld'
import { GuideDraftShareOld } from './GuideDraftShareOld'
import type { GuideDraftDataOld } from '../types'

interface GuideDraftMenuOldProps {
  guideDraftData: GuideDraftDataOld
}

export const GuideDraftMenuOld = ({
  guideDraftData,
}: GuideDraftMenuOldProps) => {
  const navigate = useNavigate()
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const { guide } = guideDraftData ?? {}

  const onViewGuide = () =>
    window.open(
      getGuideDetailsUrl({
        id: guide?.id,
        name: guide?.name,
      })
    )

  return (
    <>
      {/** @todo add a 'outlined' variant to the IconButton in the Design-System, replace the selectors below with outlined IconButton */}
      <Popover
        placement='bottom-end'
        trigger={
          <div className='[&>button]:b-1.5 [&>button]:b-solid [&>button]:b-new-forest hover:[&>button]:b-forest [&>button]:shadow-none'>
            <IconButton
              aria-label='Update your Guide'
              color='transparent'
              icon='moreVert'
              outline='round'
              size='medium'
            />
          </div>
        }
      >
        <div className='w-50 p-2'>
          <MenuItem
            label='Edit Details'
            startIcon='modeEditOutline'
            value='Edit Details'
            onClick={() => setIsModalEditOpen(true)}
          />
          <MenuItem
            aria-label='Share Guide'
            label='Share Guide'
            startIcon='iosShare'
            value='Share'
            onClick={() => setIsModalShareOpen(true)}
          />
          <MenuItem
            aria-label='View Guide'
            label='View Guide'
            startIcon='visibilityOutline'
            value='View Guide'
            onClick={onViewGuide}
          />
          <Divider />
          <MenuItem
            label='Delete Guide'
            startIcon='deleteOutline'
            value='Delete'
            onClick={() => setIsModalDeleteOpen(true)}
          />
        </div>
      </Popover>
      {isModalDeleteOpen && (
        <GuideDraftDeleteModalOld
          guideDraftData={guideDraftData}
          onDelete={() => navigate(dashboardPath)}
          onDismiss={() => setIsModalDeleteOpen(false)}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftEditModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
      {isModalShareOpen && (
        <GuideDraftShareOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalShareOpen(false)}
        />
      )}
    </>
  )
}
