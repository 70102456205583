export const UtahJazzHeader = () => {
  const imgPath = 'https://static.travelpass.com/assets/landing-pages/utah-jazz'
  const jazzFans =
    'https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/bdbc2f46-81c5-11ef-aeaa-7eaba45936ab'
  const deltaCenter =
    'https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/8b06f1cc-8101-11ef-9c51-0e13412df030'
  return (
    <>
      <header className='grid h-auto w-full grid-cols-10 gap-2 pb-2'>
        <div className='of-hidden bg-forest col-span-10 pb-6 md:col-span-6 lg:col-span-6 lg:pb-12'>
          <div className='h-full w-full space-y-6 px-4 py-6 lg:space-y-10 lg:px-12 lg:pt-12'>
            <img
              alt='Utah Jazz & Travelpass Logo Lockup'
              className='max-w-250px md:max-w-300px lg:max-w-350px mx-a block w-full'
              src={`${imgPath}/jazz-travelpass-logo-lockup.png`}
            />
            <h1 className='text-10 md:text-12 lg:text-18 font-900 line-height-108% max-w-500px md:max-w-800px mx-a m-0 block text-balance text-center uppercase text-white'>
              Travel&apos;s New{' '}
              <span className='text-valley'>Triple Threat</span>
            </h1>
            <h2 className='text-6 md:text-8 lg:text-10 font-900 line-height-115% max-w-800px mx-a m-0 block text-balance text-center uppercase tracking-wide text-white'>
              Explore, Plan, Book
            </h2>
          </div>
        </div>
        <div className='bg-grey-200 of-hidden col-span-5 md:col-span-4 lg:col-span-2'>
          <img
            alt='Fans cheering at a Utah Jazz basketball game'
            className='h-25 block w-full object-cover md:h-full'
            src={`${imgPath}/jazz-travelpass-partnership.webp`}
          />
        </div>
        <div className='bg-grey-200 of-hidden col-span-5 md:hidden lg:col-span-2 lg:block'>
          <img
            alt='Fans cheering at a Utah Jazz basketball game'
            className='h-25 block w-full object-cover md:h-full'
            src={`${imgPath}/jazz-travelpass-footer.webp`}
          />
        </div>
      </header>

      <div className='grid h-auto w-full grid-cols-10 gap-2 pb-2'>
        <div className='of-hidden col-span-5 md:col-span-4 lg:col-span-2'>
          <img
            alt='Delta Center'
            className='h-25 block w-full object-cover md:h-full'
            src={deltaCenter}
          />
        </div>
        <div className='bg-grey-200 of-hidden col-span-5 md:hidden lg:col-span-2 lg:block'>
          <img
            alt='Fans cheering at a Utah Jazz basketball game'
            className='h-25 block w-full object-cover md:h-full'
            src={jazzFans}
          />
        </div>
        <div className='of-hidden bg-forest order-first col-span-10 md:order-last md:col-span-6 lg:col-span-6'>
          <div className='h-full w-full place-content-center space-y-4 px-6 py-8 lg:p-12'>
            <p className='type-overline text-valley text-center uppercase'>
              Proud Partner of the Utah Jazz
            </p>
            <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo m-0 text-balance text-center text-white'>
              Get Tips From Real Travelers
            </h3>
            <div className='w-30 mx-a bg-valley h-1 rounded-full md:h-1.5 lg:w-40'></div>
            <p className='text-subtitle-2 font-400 max-w-650px mx-auto block text-balance text-center text-white'>
              With Travelpass, you&apos;ll always know where to stay, where to
              eat, and what to do no matter where you go.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
