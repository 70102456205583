import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { UpdateGuideMutationInGuideMutationVariables } from 'src/__generated__/graphql'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import {
  constructAddressInput,
  constructGeocoderFromAddress,
} from 'src/pages/trips/utils'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from './guideDraftConstants'
import type { GuideData } from '../details/types'
import { useUpdateGuideMutation } from '../useUpdateGuideMutation'

type GuideDraftEditFormErrors = {
  name: string
  description: string
  geocoder: string
  tagIds: string
}

type GuideDraftEditFormFields = Pick<GuideData, 'description' | 'name'> & {
  geocoder: GeocoderType
  tagIds: string[]
}

type GuideDraftEditFormHelperText = Pick<GuideData, 'description' | 'name'> & {
  tagIds: string
}

type UseUpdateGuideDraftForm = {
  formErrors: GuideDraftEditFormErrors
  formFields: GuideDraftEditFormFields
  formHelperText: GuideDraftEditFormHelperText
  isUpdatedGuideDraftLoading: boolean
  onFieldChange: <FieldKey extends keyof GuideDraftEditFormFields>(
    key: FieldKey,
    value: GuideDraftEditFormFields[FieldKey]
  ) => void
  onSubmit: VoidFunction
  onTagIdsChange: (updatedTagId: string) => void
}

const initialFormErrors: GuideDraftEditFormErrors = {
  name: '',
  description: '',
  geocoder: '',
  tagIds: '',
}

const initialFormFields: GuideDraftEditFormFields = {
  name: '',
  description: '',
  geocoder: initialGeocoder,
  tagIds: [],
}

const getErrorScrollId = (formErrors: GuideDraftEditFormErrors) => {
  if (formErrors.name) return 'guide-draft-edit-modal-name'

  if (formErrors.geocoder) return 'guide-draft-edit-modal-location'

  if (formErrors.description) return 'guide-draft-edit-modal-description'

  if (formErrors.tagIds) return 'guide-draft-edit-modal-tags'

  return ''
}

const getInitialFormFields = (
  guideData: GuideData
): GuideDraftEditFormFields => {
  const { name, description, addresses, tags } = guideData ?? {}

  return {
    name: name || '',
    description: description || '',
    geocoder: constructGeocoderFromAddress(addresses?.[0]) ?? initialGeocoder,
    tagIds: tags?.map(({ id }) => id) ?? [],
  }
}

const getTagHelperText = (tagIdsLength: number) => {
  if (!tagIdsLength) return

  if (tagIdsLength === 1) return '1 Tag Selected*'

  return `${tagIdsLength} Tags Selected*`
}

const onScrollIntoView = (scrollId: string) => {
  if (scrollId) {
    requestAnimationFrame(() => {
      const element = document.getElementById(scrollId)
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    })
  }
}

const maxTags = 5

const useUpdateGuideDraftForm = ({
  guideData,
  isLoading,
  onDismiss,
  scrollId,
}: {
  guideData: GuideData
  isLoading: boolean
  onDismiss: VoidFunction
  scrollId: string
}): UseUpdateGuideDraftForm => {
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [formErrors, setFormErrors] =
    useState<GuideDraftEditFormErrors>(initialFormErrors)
  const [formFields, setFormFields] =
    useState<GuideDraftEditFormFields>(initialFormFields)
  const [updateGuide, { loading: isUpdatedGuideDraftLoading }] =
    useUpdateGuideMutation()
  const formHelperText: GuideDraftEditFormHelperText = {
    description: `${formFields?.description?.length}/${guideDraftDescriptionMaxLength} characters`,
    name: `${formFields?.name?.length}/${guideDraftNameMaxLength} characters`,
    tagIds: getTagHelperText(formFields?.tagIds?.length),
  }

  useEffect(() => {
    validateFormFields(false)
  }, [formFields])

  useEffect(() => {
    if (!guideData?.id) return

    setFormFields(
      getInitialFormFields({
        id: guideData?.id,
        addresses: guideData?.addresses,
        description: guideData?.description,
        name: guideData?.name,
        tags: guideData?.tags,
      })
    )
  }, [
    guideData?.id,
    guideData?.addresses,
    guideData?.description,
    guideData?.name,
    guideData?.tags,
  ])

  useEffect(() => {
    if (isLoading) return

    onScrollIntoView(scrollId)
  }, [isLoading, scrollId])

  const onFieldChange = <FieldKey extends keyof GuideDraftEditFormFields>(
    key: FieldKey,
    value: GuideDraftEditFormFields[FieldKey]
  ) => {
    setFormFields(prevFields => ({
      ...prevFields,
      [key]: value,
    }))
  }

  const onSubmit = async () => {
    if (isUpdatedGuideDraftLoading || !validateFormFields()) return

    try {
      if (!guideData?.id) return

      const variables: UpdateGuideMutationInGuideMutationVariables = {
        updateGuideInput: {
          addresses: [],
          description: formFields.description,
          guideId: guideData.id,
          guideTagIds: formFields.tagIds,
          name: formFields.name,
        },
      }

      if (!isEmpty(formFields.geocoder?.center))
        variables.updateGuideInput.addresses = [
          constructAddressInput(formFields.geocoder),
        ]

      await updateGuide({
        variables,
      })
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide has been updated',
      })
      onDismiss()
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onTagIdsChange = (updatedTagId: string) => {
    let updatedTagIds: GuideDraftEditFormFields['tagIds'] = []

    if (formFields?.tagIds.includes(updatedTagId)) {
      updatedTagIds = formFields?.tagIds.filter(id => id !== updatedTagId)
    } else {
      updatedTagIds = [...formFields?.tagIds, updatedTagId]
    }

    if (updatedTagIds.length > maxTags) return

    onFieldChange('tagIds', updatedTagIds)
  }

  const validateFormFields = (validateAllFields = true) => {
    const updatedFormFields = {
      ...initialFormErrors,
    }
    const fieldsToValidate = validateAllFields
      ? Object.keys(formFields)
      : Object.keys(formErrors).filter(key => formErrors[key])

    fieldsToValidate.forEach(field => {
      if (field === 'name' && !formFields.name) {
        updatedFormFields.name = 'Guide Name is required.'
      }

      if (field === 'description') {
        if (!formFields.description) {
          updatedFormFields.description = 'Guide Description is required.'
        } else if (formFields.description.indexOf('http') !== -1) {
          updatedFormFields.description =
            'Guide Description cannot contain URLs.'
        }
      }

      if (field === 'geocoder' && !formFields.geocoder?.center?.length) {
        updatedFormFields.geocoder = 'Guide Location is required.'
      }

      if (field === 'tagIds' && !formFields.tagIds?.length) {
        updatedFormFields.tagIds = 'At least one tag is required.'
      }
    })

    if (validateAllFields) onScrollIntoView(getErrorScrollId(updatedFormFields))

    setFormErrors(updatedFormFields)

    return !Object.values(updatedFormFields).some(Boolean)
  }

  return {
    formErrors,
    formFields,
    formHelperText,
    isUpdatedGuideDraftLoading,
    onFieldChange,
    onSubmit,
    onTagIdsChange,
  }
}

export type { GuideDraftEditFormFields }
export { initialFormErrors, useUpdateGuideDraftForm }
