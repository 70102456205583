import { GuidesSearchBar } from '../../GuidesSearchBar'

export const WithoutTravelerSearch = () => {
  const mainText = 'Travel With Real Recommendations'

  return (
    <div className='relative'>
      <img
        alt=''
        className='-z-1 lg:min-h-500px ease absolute inset-0 h-full w-full object-cover transition-all duration-300'
        src='https://static.travelpass.com/assets/guides/guide-search-bg.webp'
      />
      <section className='py-34 lg:max-h-4xl ease space-y-10 bg-[size:100%] bg-no-repeat transition-all md:py-44'>
        <h1 className='type-h1 text-center max-md:px-2'>
          <span className='hidden lg:block'>{mainText}</span>
          <span className='block text-4xl lg:hidden'>{mainText}</span>
        </h1>
        <GuidesSearchBar
          hideSlotBefore
          className='max-w-150 mx-auto px-4 max-md:px-8'
        />
      </section>
    </div>
  )
}
