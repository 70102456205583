import { useState } from 'react'
import { Button, SkeletonDots, useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { DatesType } from 'src/constants/user'
import type { TripFormValues } from 'src/pages/trips/constants'
import { constructAddressInput } from 'src/pages/trips/utils'
import { convertDateFromUtcToDayjs, formatDate } from 'src/utils'
import { EditDateContent } from './EditDateContent'
import { getEditDates, getExtendedDates } from './getEditDate'
import { TripFormLoader } from './loader'
import { useGetTripForEdit } from './useGetTripForEdit'
import { TripForm } from '../../TripForm'
import { useEditTrip } from '../useEditTrip'

interface TripEditFormProps {
  successSnackTitle?: string
  tripId: string
  onClose: () => void
  onCompleted?: () => void
  onDeleteOpen: () => void
}

interface DateState {
  editedDates?: DatesType
  formValue?: TripFormValues
}

export const TripEditForm = ({
  successSnackTitle = 'Trip Successfully Edited',
  tripId,
  onClose,
  onCompleted = () => {},
  onDeleteOpen,
}: TripEditFormProps): JSX.Element => {
  const [isTripFormOpen, setIsTripFormOpen] = useState(true)
  const [dateState, setDateState] = useState<DateState>({})
  const { isLoading, tripDetailsData, tripForEditData } = useGetTripForEdit({
    tripId,
  })
  const { editedDates, formValue } = dateState ?? {}
  const { editTrip, loading: editLoading } = useEditTrip()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const { dailyEventBreakdown } = tripForEditData ?? {}
  const {
    startDate: tripStartDate,
    endDate: tripEndDate,
    timeZone,
    tripPreference,
    name,
  } = tripDetailsData ?? {}

  if (isLoading) return <TripFormLoader />

  const startDate = convertDateFromUtcToDayjs({
    date: tripStartDate,
    timezone: timeZone,
  })
  const endDate = convertDateFromUtcToDayjs({
    date: tripEndDate,
    timezone: timeZone,
  })

  const onOpenTripForm = () => setIsTripFormOpen(true)

  const getCalculatedExtendedDate = () => {
    if (!editedDates || !formValue?.dates) return

    return getExtendedDates({
      startDate: editedDates?.[0],
      endDate: editedDates?.[1],
      formStartDate: formValue.dates?.[0],
      fromEndDate: formValue.dates?.[1],
    })
  }

  const onEditTrip = ({ dates, name, address }: TripFormValues) => {
    editTrip({
      variables: {
        tripInput: {
          id: tripId,
          name,
          tripStart: formatDate(dates?.[0], 'YYYY-MM-DD'),
          tripEnd: formatDate(dates?.[1], 'YYYY-MM-DD'),
          tripPreference: {
            addresses: [constructAddressInput(address)],
          },
        },
      },
      onCompleted: data => {
        addSuccessSnack({ title: successSnackTitle })
        onCompleted()
        onClose()
      },
      onError: () => {
        addErrorSnack({ actionText: 'ERROR', title: 'Unable to edit trip.' })
      },
    })
  }

  const onSubmit = (formValues: TripFormValues) => {
    const { dates: formDates } = formValues ?? {}
    const editDate = getEditDates({
      tripStartDate: startDate,
      formStartDate: formDates?.[0],
      fromEndDate: formDates?.[1],
      dailyEventBreakdown,
    })

    if (isEmpty(editDate)) {
      onEditTrip(formValues)
    } else {
      setDateState({ editedDates: editDate, formValue: formValues })
      setIsTripFormOpen(false)
    }
  }

  const onCancelEditDate = () => {
    setDateState({})
    onOpenTripForm()
  }

  const [address] = tripPreference?.addresses ?? []

  return (
    <div>
      {isTripFormOpen ? (
        <div>
          <TripForm
            tripData={{
              name,
              dates: getCalculatedExtendedDate() || [startDate, endDate],
              address,
            }}
            onSubmit={onSubmit}
          >
            <Button
              aria-label='Delete trip'
              startIcon='deleteOutline'
              variant='text'
              onClick={onDeleteOpen}
            >
              Delete this trip
            </Button>
            {editLoading ? (
              <SkeletonDots />
            ) : (
              <div className='md:p-x-21.5 md:p-t-10 flex justify-center gap-6'>
                <Button
                  aria-label='Cancel trip edit'
                  fullWidth={true}
                  variant='outlined'
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  aria-label='Submit trip edit'
                  fullWidth={true}
                  type='submit'
                >
                  Submit
                </Button>
              </div>
            )}
          </TripForm>
        </div>
      ) : (
        <EditDateContent
          editedDates={editedDates}
          onCancelEditDate={onCancelEditDate}
          onOpenTripForm={onOpenTripForm}
        />
      )}
    </div>
  )
}
