import { useState } from 'react'
import type { RoomFilters } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { HotelRoomsApollo } from './HotelRoomsApollo'
import { HotelRoomsSearchForm } from './HotelRoomsSearchForm'

export const HotelRooms = () => {
  const [roomFilters, setRoomFilters] = useState<RoomFilters>({
    bedType: null,
    refundable: null,
  })
  const [disableOnLoading, setDisableOnLoading] = useState(false)
  const onSetdisabledOnLoading = (isDisabled: boolean) =>
    setDisableOnLoading(isDisabled)
  const onRoomFiltersChange = (updatedRoomFilters: RoomFilters) =>
    setRoomFilters(previousRoomFilters => ({
      ...previousRoomFilters,
      ...updatedRoomFilters,
    }))

  return (
    <PageLayoutContainer>
      <section className='my-15'>
        <HotelRoomsSearchForm
          disableOnLoading={disableOnLoading}
          filters={roomFilters}
          onChange={onRoomFiltersChange}
        />
        <HotelRoomsApollo
          filters={roomFilters}
          onSetdisabledOnLoading={onSetdisabledOnLoading}
        />
      </section>
    </PageLayoutContainer>
  )
}
