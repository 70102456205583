import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createPublishedEventCategoryMutation = gql(`
  mutation CreatePublishedEventCategoryMutationInGuideDraftOld($createCategoryInput: CreatePublishedEventCategoryInput!) {
    createPublishedEventCategory(createCategoryInput: $createCategoryInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
      publishedEventCategory {
        id
      }
    }
  }
`)

export const useCreateGuideDraftEventCategoryMutation = () =>
  useMutation(createPublishedEventCategoryMutation)
