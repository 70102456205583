import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { OverviewAtAGlanceLoading } from './OverviewAtAGlance'
import { OverviewNotesLoading } from './OverviewNotes'
import {
  TripContainer,
  TripContentWrapper,
  TripMapWrapper,
} from '../../components'
import { TripNavLoading } from '../../components/TripNav'
import { TripTopNavLoading } from '../../components/TripTopNav'

export const OverviewLoading = () => {
  const [searchParams] = useSearchParams()
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'

  return (
    <div>
      <TripTopNavLoading />
      <TripContainer>
        {!mobileMapView && (
          <TripContentWrapper>
            <TripNavLoading />
            <div className='space-y-6 md:space-y-10'>
              <OverviewAtAGlanceLoading />
              <OverviewNotesLoading />
            </div>
          </TripContentWrapper>
        )}
        <div
          className={classNames('lg:block', {
            hidden: !mobileMapView,
          })}
        >
          <TripMapWrapper isDrawerOpen={false}>
            <Skeleton />
          </TripMapWrapper>
        </div>
      </TripContainer>
    </div>
  )
}
