import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  Chip,
  Input,
  SkeletonDots,
} from '@travelpass/design-system'
import { Geocoder } from 'src/common/components'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { GuideDraftGroup, GuideDraftGroupHeader } from './GuideDraftGroup'
import type { GuideData } from '../../details/types'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../guideDraftConstants'
import { useUpdateGuideDraftForm } from '../useUpdateGuideDraftForm'

interface GuideDraftEditModalProps {
  guideData: Pick<
    GuideData,
    'addresses' | 'description' | 'id' | 'name' | 'tags'
  >
  onDismiss: VoidFunction
  scrollId?: string
}

export const GuideDraftEditModal = ({
  guideData,
  onDismiss,
  scrollId,
}: GuideDraftEditModalProps) => {
  const { loading: isLoading, tags } = useGetPredefinedGuideTagsQuery()
  const {
    formErrors,
    formFields,
    formHelperText,
    isUpdatedGuideDraftLoading,
    onFieldChange,
    onTagIdsChange,
    onSubmit,
  } = useUpdateGuideDraftForm({
    guideData,
    isLoading,
    onDismiss,
    scrollId,
  })
  const { description, geocoder, name, tagIds } = formFields ?? {}

  if (isLoading) return

  return (
    <Modal title='Edit Guide Details' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
          <GuideDraftGroup id='guide-draft-edit-modal-name'>
            <GuideDraftGroupHeader title='Guide Title' />
            <Input
              aria-label='Add a name for your Guide'
              errorText={formErrors?.name}
              fullWidth={true}
              helperText={formHelperText?.name}
              maxLength={guideDraftNameMaxLength}
              name='name'
              required={true}
              value={name}
              onChange={event => onFieldChange('name', event.target.value)}
            />
          </GuideDraftGroup>
          <GuideDraftGroup id='guide-draft-edit-modal-location'>
            <GuideDraftGroupHeader title='Location' />
            <Geocoder
              aria-label='Search and add a destination for this guide'
              config={{
                requestOptions: {
                  componentRestrictions: {
                    country: null,
                  },
                },
              }}
              errorText={formErrors?.geocoder}
              focusOnInput={false}
              fullWidth={true}
              geocoder={geocoder}
              placeholder='Search Destination'
              required={true}
              onResult={updatedGeocoder =>
                onFieldChange('geocoder', updatedGeocoder)
              }
            />
          </GuideDraftGroup>
          <GuideDraftGroup id='guide-draft-edit-modal-description'>
            <GuideDraftGroupHeader title='Guide Description' />
            <TextArea
              aria-label='Add a description for your guide'
              errorText={formErrors?.description}
              helperText={formHelperText?.description}
              maxLength={guideDraftDescriptionMaxLength}
              required={true}
              rows={4}
              value={description}
              onInput={event =>
                onFieldChange('description', event.currentTarget.value)
              }
            />
          </GuideDraftGroup>
          <GuideDraftGroup id='guide-draft-edit-modal-tags'>
            <GuideDraftGroupHeader
              subtitle='Select up to 5 tags to best describe your experience and help others discover your Guide'
              title='Tag Your Guide'
            />
            <div className='b-1 b-solid rounded-2 flex flex-row flex-wrap gap-x-1 gap-y-2 bg-white p-1 md:gap-3 md:p-6'>
              {tags?.map(({ id, name }) => (
                <div key={id}>
                  <Chip
                    isSelected={tagIds.includes(id)}
                    label={name}
                    onClick={() => onTagIdsChange(id)}
                  />
                </div>
              ))}
            </div>
            {formErrors.tagIds && (
              <p className='c-red font-2.5 type-subtext pl-3.5'>
                {formErrors?.tagIds}
              </p>
            )}
            {formHelperText?.tagIds && (
              <p className='type-body-1-medium c-forest-light text-right'>
                {formHelperText?.tagIds}
              </p>
            )}
          </GuideDraftGroup>
        </div>
      </ModalScrollContents>
      <ModalActions>
        {isUpdatedGuideDraftLoading && (
          <div className='flex h-10 flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        )}
        {!isUpdatedGuideDraftLoading && (
          <Button autoFocus={true} onClick={onSubmit}>
            Save
          </Button>
        )}
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
