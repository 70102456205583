import { Divider, Skeleton } from '@travelpass/design-system'

export const HotelRecommendedHeroLoading = () => (
  <div>
    <div className='flex flex-col gap-12'>
      <div className='h-220px'>
        <Skeleton />
      </div>
      <div className='h-24px w-300px'>
        <Skeleton />
      </div>
    </div>
    <div className='h-18px py-2'>
      <Skeleton />
    </div>
    <Divider />
  </div>
)
