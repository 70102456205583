import { Link } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'

const destinationLinks: { name: string; to: string }[] = [
  { name: 'Austin', to: '/destinations/tx/austin' },
  { name: 'Boston', to: '/destinations/ma/boston' },
  { name: 'Chicago', to: '/destinations/il/chicago' },
  { name: 'Denver', to: '/destinations/co/denver' },
  { name: 'Fort Lauderdale', to: '/destinations/fl/fort-lauderdale' },
  { name: 'Knoxville', to: '/destinations/tn/knoxville' },
  { name: 'Las Vegas', to: '/destinations/nv/las-vegas' },
  { name: 'Los Angeles', to: '/destinations/ca/los-angeles' },
  { name: 'Miami', to: '/destinations/fl/miami' },
  { name: 'Myrtle Beach', to: '/destinations/sc/myrtle-beach' },
  { name: 'Nashville', to: '/destinations/tn/nashville' },
  { name: 'New Orleans', to: '/destinations/la/new-orleans' },
  { name: 'New York City', to: '/destinations/ny/new-york-city' },
  { name: 'Orlando', to: '/destinations/fl/orlando' },
  { name: 'Philadelphia', to: '/destinations/pa/philadelphia' },
  { name: 'San Antonio', to: '/destinations/tx/san-antonio' },
  { name: 'San Diego', to: '/destinations/ca/san-diego' },
  { name: 'San Francisco', to: '/destinations/ca/san-francisco' },
  { name: 'Seattle', to: '/destinations/wa/seattle' },
  { name: 'Washington DC', to: '/destinations/dc/washington-dc' },
]

export const HomeFindYourNextDestination = () => {
  return (
    <PageLayoutContainer includeTopPadding>
      <div className='lg:gap-23 lg:pt-19 flex w-full flex-col items-center justify-evenly gap-6 md:flex-row lg:justify-evenly'>
        <img
          alt=''
          className='drag-none md:max-w-85 lg:max-w-102 max-w-3/5'
          loading='eager'
          src='https://static.travelpass.com/assets/home-page/guides/guides-1-l-no-bg.webp'
        />
        <section className='md:order-0 order-2 flex h-full flex-col items-center justify-center gap-8 text-left md:mt-6 md:items-start lg:mt-0'>
          <h2 className='type-h1 c-white'>
            Find Your Next<br></br>Destination
          </h2>
          {/* Same as <Separator /> component except for horizontal margins since we don't want it always centered */}
          <div
            aria-hidden='true'
            className='border-valley h-2px w-[6ch] border border-b-2 border-solid'
          ></div>
          <ul className='m-0 grid list-none grid-cols-4 gap-2.5 p-0 lg:gap-5'>
            {destinationLinks.map(({ name, to }) => (
              <li key={name}>
                <Link
                  className='decoration-none type-link c-white b-b-1 b-b-solid b-valley b-none hover:c-valley focus:outline-offset-3 focus:outline-valley [&>*]:hover:b-newForest! box-border w-fit cursor-pointer bg-transparent p-0 pb-1 transition-all duration-200 focus:outline-1'
                  to={to}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </PageLayoutContainer>
  )
}
