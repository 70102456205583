const experiencesBookingDetailsCss =
  'flex flex-col gap-6 m-a pb-10 pt-4 w-full md:pb-36'

const experiencesBookingDetailsContentCss = 'flex flex-col gap-12 md:gap-20'

const experiencesBookingDetailsHeaderCss =
  'flex flex-col gap-6 md:gap-12 md:pb-6'

const experiencesBookingDetailsWrapperCss = 'flex flex-col gap-4 rebrand'

export {
  experiencesBookingDetailsCss,
  experiencesBookingDetailsContentCss,
  experiencesBookingDetailsHeaderCss,
  experiencesBookingDetailsWrapperCss,
}
