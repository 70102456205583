import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createNote = gql(`
  mutation CreateNoteMutationInTripOverview($noteInput: CreateNoteInput!) {
    createNote(noteInput: $noteInput) {
      id
      insertedAt
      trip {
        id
        notes {
          id
        }
      }
    }
  }
`)

export const useCreateNoteTripOverviewMutation = () => useMutation(createNote)
