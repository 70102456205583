import type { ReactNode } from 'react'

interface HotelResultsMapActionsContainerProps {
  children: ReactNode
}

export const HotelResultsMapActionsContainer = ({
  children,
}: HotelResultsMapActionsContainerProps) => (
  <div className='absolute left-8 top-8 flex gap-4'>{children}</div>
)
