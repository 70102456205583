import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { Input, Button, useSnackbar } from '@travelpass/design-system'
import { AuthErrorCodes } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import {
  firebaseCreateAccountWithEmailAndPassword,
  firebasePasswordSignIn,
} from 'src/config/firebase/firebaseUtils'
import {
  emailValidationRegex,
  nameWithSpacesRegex,
} from 'src/constants/validation'
import { LinkCredentials } from './LinkCredentials'
import type { UpdateUserInfoProps } from './types'

export const CreateAccount = ({
  createAccountOrSignInMessage,
  errorText,
  isLoading,
  setAttemptingCreation,
  onChangeCreatedByEmail,
  onCloseModal,
  setErrorText,
  setIsCreatingAccount,
  updateUserInfo,
}: {
  createAccountOrSignInMessage: ReactNode
  errorText: string
  isLoading: boolean
  setAttemptingCreation(arg0: boolean): void
  onChangeCreatedByEmail(): void
  onCloseModal(): void
  setErrorText: Dispatch<SetStateAction<string>>
  setIsCreatingAccount: Dispatch<SetStateAction<boolean>>
  updateUserInfo(arg0: UpdateUserInfoProps): void
}): JSX.Element => {
  const isNewSignUpEnabled = useFlag('newSignUpFlow')
  const { addSuccessSnack } = useSnackbar()
  const { formState, handleSubmit, register } = useForm()
  const { errors } = formState
  const {
    email: emailError,
    password: passwordError,
    displayName: nameError,
  } = errors
  const { pathname } = useLocation()
  const isOnVotingPage =
    pathname.includes('/competition/leaderboard') ||
    pathname.includes('/profile')

  const onSubmit = async ({ email, displayName, password }, event) => {
    isNewSignUpEnabled && setAttemptingCreation(true)
    event.preventDefault()
    const response = await firebaseCreateAccountWithEmailAndPassword(
      email,
      password,
      displayName
    )
    if (response.status === 'error') {
      if (response?.err?.message.includes('Unauthorized email')) {
        setErrorText('Unauthorized email')
      }
      if (response?.err?.message.includes('Email cannot contain a plus sign')) {
        setErrorText(
          'Emails with “+” aren’t supported. Please remove it or use another address.'
        )
      }
      if (response?.err?.code === AuthErrorCodes.EMAIL_EXISTS) {
        const tryLogin = await firebasePasswordSignIn(email, password)
        if (tryLogin?.status === 'success') {
          onCloseModal()
          addSuccessSnack({ title: 'Successfully signed in' })
        } else if (
          tryLogin?.status === 'error' &&
          (tryLogin?.err?.code === 'auth/user-not-found' ||
            tryLogin?.err?.code === 'auth/wrong-password' ||
            tryLogin?.err?.code === 'auth/too-many-requests')
        ) {
          setErrorText('Please check your email address and password')
          setIsCreatingAccount(false)
        } else setErrorText('An error has occured, please try again later')
      }
      return
    }

    const externalId = response?.user?.uid ?? ''
    updateUserInfo({
      externalId,
      email,
      ...(isNewSignUpEnabled && { displayName }),
    })
    onChangeCreatedByEmail()
  }

  const constructNameErrorText = () => {
    if (nameError?.type === 'required') return 'Name is required'
    if (nameError?.type === 'validate')
      return 'The name must contain at least two alphabetic characters.'
  }

  const constructEmailErrorText = () => {
    if (emailError?.type === 'required') return 'Email is required'
    if (emailError?.type === 'validate')
      return 'Please enter a valid email address'
  }

  const constructEmailPasswordText = () => {
    if (passwordError?.type === 'required') return 'Password is required'
    if (passwordError?.type === 'minLength')
      return 'Password must be at least 6 characters in length'
  }

  return (
    <form
      noValidate
      className='mt-3 space-y-7'
      onSubmit={handleSubmit(onSubmit)}
    >
      <LinkCredentials
        setAttemptingCreation={setAttemptingCreation}
        updateUserInfo={updateUserInfo}
        onCloseModal={onCloseModal}
      />
      {isOnVotingPage && (
        <div className='text-body-2 c-red'>
          Please note, voting has been restricted to verified emails. If you’re
          creating a new account, you will need to return to vote AFTER clicking
          the verification link sent to your email.
        </div>
      )}
      {errorText && <div className='c-error'>{errorText}</div>}
      <section className='space-y-4'>
        {isNewSignUpEnabled && (
          <Input
            fullWidth
            errorText={constructNameErrorText()}
            helperText="Enter the name you'd like displayed on your profile. Max 25 characters."
            label='Name'
            maxLength={25}
            placeholder='Name'
            type='text'
            {...register('displayName', { required: true })}
          />
        )}
        <Input
          fullWidth
          errorText={constructEmailErrorText()}
          label='Email'
          placeholder='Email'
          type='text'
          {...register('email', {
            required: true,
            validate: (value: string) => {
              const regex = new RegExp(emailValidationRegex)
              return regex.test(value)
            },
          })}
        />
        <Input
          fullWidth
          errorText={constructEmailPasswordText()}
          helperText='Password must be at least 6 characters in length.'
          label='Password'
          placeholder='Password'
          type='password'
          {...register('password', { required: true, minLength: 6 })}
        />
      </section>
      <div className='flex justify-center'>
        <Button
          isDisabled={isLoading}
          label='Create Account'
          size='large'
          type='submit'
        />
      </div>
      <div className='type-body-2 [&_span]:c-new-forest flex flex-row items-center justify-center gap-4'>
        {createAccountOrSignInMessage}
      </div>
    </form>
  )
}
