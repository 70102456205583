import { Outlet } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { BasicTravelerSearch } from './BasicTravelerSearch'
import { FullTravelerSearch } from './FullTravelerSearch'
import { WithoutTravelerSearch } from './WithoutTravelerSearch'

export const ExploreHero = () => {
  const basicTravelerSearch = useFlag('basicTravelerSearch')
  const enableTravelersSearch = useFlag('enableTravelersSearch')

  const Component = () => {
    if (enableTravelersSearch) return <FullTravelerSearch />
    if (basicTravelerSearch) return <BasicTravelerSearch />
    return <WithoutTravelerSearch />
  }

  return (
    <>
      <Component />
      <Outlet />
    </>
  )
}
