import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const UPDATE_COLLECTION = gql(`
    mutation UpdateCollection($input: UpdateCollectionInput!){
        updateCollection(input: $input){
            collection{
                id
                name
                isDefault
                latitude
                longitude
                locationName
            }
        }
    }    
`)

export const useUpdateCollection = () => useMutation(UPDATE_COLLECTION)
