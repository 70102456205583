import classNames from 'classnames'
import { HotelReviewsAverages } from './HotelReviewsAverages'
import { getReviewRatingDescription } from '../../../utils'

interface HotelReviewsSummarySectionProps {
  customerReviewScore: number
  totalCustomerReviews?: number
  cleanlinessAverage?: number | null
  locationAverage?: number | null
  staffAverage?: number | null
}

export const HotelReviewsSummarySection = ({
  customerReviewScore,
  totalCustomerReviews,
  cleanlinessAverage,
  locationAverage,
  staffAverage,
}: HotelReviewsSummarySectionProps) => {
  return (
    <div className='gap-25 flex-justify-center flex'>
      <div className='flex flex-col gap-1'>
        <div
          className={classNames('rd-1 bg-grey800 w-fit px-2 py-1 text-center', {
            'bg-tealMedium': Number(customerReviewScore) >= 7,
          })}
        >
          <h3 className='type-h3 c-white'>{customerReviewScore}</h3>
        </div>
        <p
          className={classNames('type-body-1 c-grey800', {
            'c-tealMedium': Number(customerReviewScore) >= 7,
          })}
        >
          {getReviewRatingDescription(customerReviewScore)}
        </p>
        <p className='type-body-1'>{`${totalCustomerReviews} Reviews`}</p>
      </div>
      <div className='md:max-w-1/3 w-full max-w-full'>
        <HotelReviewsAverages
          cleanlinessAverage={cleanlinessAverage}
          locationAverage={locationAverage}
          staffAverage={staffAverage}
        />
      </div>
    </div>
  )
}
