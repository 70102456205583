import { Skeleton } from '@travelpass/design-system'
import type { HotelResultsCardAmenitiesProps } from './HotelResultsCardAmenities'
import { HotelResultsCardAmenityContainer } from './HotelResultsCardAmenityContainer'

export const HotelResultsCardAmenitiesLoading = ({
  limit = 3,
}: HotelResultsCardAmenitiesProps) => {
  return (
    <>
      {Array(limit)
        ?.fill(0)
        ?.map((_, index) => (
          <HotelResultsCardAmenityContainer key={index}>
            <span className='flex h-4 w-4'>
              <Skeleton />
            </span>
            <span className='flex h-3 w-9'>
              <Skeleton />
            </span>
          </HotelResultsCardAmenityContainer>
        ))}
    </>
  )
}
