import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { GuideDraftSectionAddModal } from './GuideDraftSection'
import { GuideSearchParam } from '../../details'
import { GuideStickyScrollUp } from '../../details/common'

interface GuideDraftStickyActionButtonsProps {
  guideDraftId: string
  selectedId: string
}

export const GuideDraftStickyActionButtons = ({
  guideDraftId,
  selectedId,
}: GuideDraftStickyActionButtonsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSectionAddModalOpen, setIsSectionAddModalOpen] = useState(false)

  const onMobileMapOpen = () => {
    searchParams.set(GuideSearchParam.mapFullView, 'true')
    setSearchParams(searchParams)
  }

  const onSectionAddClick = () => {
    setIsSectionAddModalOpen(true)
    pushDataToDataLayer('guide_draft_section_add_modal_opened', {
      guide_draft_id: guideDraftId,
      trigger_url: window.location.href,
    })
  }

  return (
    <>
      <GuideStickyScrollUp
        className={classNames('lg:max-w-194.75', {
          'w-[calc(100%-521px)]': selectedId,
          'w-full': !selectedId,
        })}
        defaultVisibility={true}
      >
        <Button
          aria-label='Add new section to the guide'
          size='small'
          startIcon='add'
          onClick={onSectionAddClick}
        >
          New Section
        </Button>
        <div className='m-a absolute bottom-0 right-6 top-0 flex flex-col justify-center lg:hidden'>
          <IconButton
            aria-label='Open Guide map'
            color='forestLight'
            icon='mapOutlined'
            outline='round'
            size='small'
            onClick={onMobileMapOpen}
          />
        </div>
      </GuideStickyScrollUp>
      {isSectionAddModalOpen && (
        <GuideDraftSectionAddModal
          guideDraftId={guideDraftId}
          onDismiss={() => setIsSectionAddModalOpen(false)}
        />
      )}
    </>
  )
}
