import type { Dayjs } from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import type {
  EventFormValues,
  GenericEventType,
} from 'src/pages/trips/constants'
import { constructGeocoderFromAddress } from 'src/pages/trips/utils'
import { combineDateAndTimeToUTC, constructDates } from 'src/utils'
import { drawerEditDefaultValues } from './drawerEditConstants'
import type { DrawerEditKeys, DrawerEditValues } from './drawerEditTypes'
import type { DrawerEvent } from '../../constants'

const createDrawerEditDefaultValues = (data: DrawerEvent | null) => {
  const defaultValues: DrawerEditValues = cloneDeep(drawerEditDefaultValues)

  if (data?.address) {
    const geocoder = constructGeocoderFromAddress(data.address)
    if (geocoder) {
      defaultValues.startLocation = geocoder
      defaultValues.endLocation = geocoder
    }
  }
  if (data?.name) defaultValues.name = data.name
  if (data?.notes && !isEmpty(data?.notes)) defaultValues.notes = data.notes
  if (data?.status) defaultValues.customBooked = data.status
  if (data?.startDate) {
    const dates = constructDates({
      arrival: data.startDate,
      departure: data.endDate,
      timezone: data.timezone,
    })
    defaultValues.dates = {
      from: dates[0],
      to: dates[1],
    }
  }
  if (data?.startTime && data.endTime) {
    defaultValues.times = {
      startTime: data.startTime,
      endTime: data.endTime,
    }
  }

  return defaultValues
}

const prepDrawerEditForEventUpdate = ({
  data,
  formValues,
  modifibleFields = [
    'customBooked',
    'dates',
    'endLocation',
    'name',
    'notes',
    'startLocation',
    'times',
  ],
}: {
  data: DrawerEvent
  formValues: DrawerEditValues
  modifibleFields: DrawerEditKeys[]
}): {
  eventData: GenericEventType
  formValues: EventFormValues
  timezone: string
} => {
  const eventData: GenericEventType = {
    ...data,
    startDate: combineDateAndTimeToUTC({
      date: data?.startDate,
      time: data?.startTime,
      timezone: data?.timezone,
    }),
    endDate: combineDateAndTimeToUTC({
      date: data?.endDate,
      time: data?.endTime,
      timezone: data?.timezone,
    }),
  }

  const updatedFormValues = cloneDeep(formValues)
  Object.keys(formValues).forEach(key => {
    const drawerFormKey = key as DrawerEditKeys
    if (
      !modifibleFields.includes(drawerFormKey) ||
      JSON.stringify(formValues[key]) ===
        JSON.stringify(drawerEditDefaultValues[key])
    ) {
      delete updatedFormValues[key]
    }
  })
  const updatedEvent: EventFormValues = {
    ...updatedFormValues,
    dates: undefined,
    notes: formValues?.notes,
    startLocation: null,
    endLocation: null,
  }
  if (modifibleFields.includes('dates')) {
    const datesArr: [Dayjs | null, Dayjs | null] = [
      formValues?.dates?.from,
      formValues?.dates?.to ?? null,
    ]

    updatedEvent.dates = datesArr
  }
  if (modifibleFields.includes('times')) {
    updatedEvent.timeRange = formValues.times
  }

  return {
    eventData,
    formValues: updatedEvent,
    timezone: data?.timezone,
  }
}

export { createDrawerEditDefaultValues, prepDrawerEditForEventUpdate }
