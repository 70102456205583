import { useState } from 'react'
import { Divider, Icon, Illustration, Link } from '@travelpass/design-system'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardAchievementsStatsProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
}

export const DashboardAchievementsStats = ({
  dashboardData,
}: DashboardAchievementsStatsProps) => {
  const [isGuideDraftCreateModalOpen, setIsGuideDraftCreateModalOpen] =
    useState(false)
  const [isGuideLikeModalOpen, setIsGuideLikeModalOpen] = useState(false)
  const { currentUser } = dashboardData ?? {}
  const { userGuides } = currentUser ?? {}
  const guideCount = userGuides?.totalCount ?? 0
  const guideCountRemaining = guideCount - userGuides?.edges?.length

  if (!guideCount)
    return (
      <>
        <section className='space-y-3 text-center'>
          <h5 className='type-h5-desktop c-black'>Guides</h5>
          <div className='w-25 m-auto'>
            <Illustration name='notes' />
          </div>
          <p className='c-forest-light type-h5-desktop'>
            Ready to Share Your Journey?
          </p>
          <p className='type-small-text-desktop c-grey-800'>
            Create your first Guide and share your travel experiences with
            others to start seeing stats!
          </p>
          <Link
            endIcon='arrowForward'
            onClick={() => setIsGuideDraftCreateModalOpen(true)}
          >
            Create your first guide
          </Link>
        </section>
        {isGuideDraftCreateModalOpen && (
          <GuideDraftCreateModalOld
            triggerButton='dashboardLeft'
            onDismiss={() => setIsGuideDraftCreateModalOpen(false)}
          />
        )}
      </>
    )

  return (
    <div className='space-y-6'>
      <section className='space-y-3'>
        <h5 className='type-h5-desktop c-black'>Guides ({guideCount})</h5>
        <div className='flex h-20 flex-row overflow-hidden'>
          {userGuides?.edges?.map(({ node }) => (
            <img
              key={node?.id}
              alt=''
              className='-m-l-5 first:m-l-0 b-2 b-solid b-white rounded-3 h-full w-1/4 object-cover shadow-lg'
              src={
                node?.paginatedImages?.edges?.[0]?.node?.url ?? node?.imageUrl
              }
            />
          ))}
          {!!guideCountRemaining && (
            <p className='type-h3-desktop c-beach -m-l-20 relative flex h-full w-1/4 items-center justify-center'>
              +{guideCountRemaining}
            </p>
          )}
        </div>
      </section>
      <section className='space-y-3'>
        <header className='space-y-2'>
          <h5 className='type-h5-desktop c-black [&>i]:c-valley flex flex-row items-center'>
            <Icon name='navigationCompass' size='small' />
            <span className='m-l-2'>All Time</span>
          </h5>
          <Divider className='m-b-2' />
        </header>
        <div className='grid w-full grid-cols-4 text-center'>
          <button
            className='b-none m-none p-none h-full w-full cursor-pointer bg-transparent outline-none'
            onClick={() => setIsGuideLikeModalOpen(true)}
          >
            <p className='c-forest-dark type-h5-desktop'>
              {currentUser?.guideLikesCount}
            </p>
            <p className='c-grey-700 type-small-text-desktop'>Likes</p>
          </button>
          <div>
            <p className='c-forest-dark type-h5-desktop'>
              {currentUser?.guideSharesCount}
            </p>
            <p className='c-grey-700 type-small-text-desktop'>Shares</p>
          </div>
          <div>
            <p className='c-forest-dark type-h5-desktop'>
              {currentUser?.guideViewsCount}
            </p>
            <p className='c-grey-700 type-small-text-desktop'>Views</p>
          </div>
          <div>
            <p className='c-forest-dark type-h5-desktop'>
              {currentUser?.guideSavesCount}
            </p>
            <p className='c-grey-700 type-small-text-desktop'>Saves</p>
          </div>
        </div>
      </section>
    </div>
  )
}
