import type { ReactNode } from 'react'
import { LoadMore, SectionHeader } from 'src/common/components'

interface BookingSectionProps {
  contentLength?: number
  children: ReactNode
  hasMoreResults?: boolean
  isLoading?: boolean
  isMoreResultsLoading?: boolean
  subtitle: string
  title: string
  onGetMoreResults(): void
}

export const BookingSection = ({
  children,
  hasMoreResults = false,
  isLoading = false,
  isMoreResultsLoading = false,
  contentLength = 0,
  subtitle,
  title,
  onGetMoreResults,
}: BookingSectionProps) => {
  const renderSubtitle = () => {
    const records = isLoading ? '-' : contentLength ?? 0
    const recordsDisplay = isLoading ? '-' : records
    const plural = isLoading || records === 1 ? '' : 's'
    return `${recordsDisplay} ${subtitle}${plural}`
  }
  return (
    <div>
      <SectionHeader subtitle={renderSubtitle()} title={title} />
      <div>{children}</div>
      {contentLength !== 0 && (
        <LoadMore
          hasMoreResults={hasMoreResults}
          isMoreResultsLoading={isLoading || isMoreResultsLoading}
          onGetMoreResults={onGetMoreResults}
        />
      )}
    </div>
  )
}
