import { useState } from 'react'
import debounce from 'lodash.debounce'
import { Helmet } from 'src/common/components'
import { Map } from 'src/common/components/Map'
import { stringToNumber } from 'src/utils'
import { GuidesEventsMapFilters } from './GuidesEventsMapFilters'
import { GuidesEventsMapGeocoder } from './GuidesEventsMapGeocoder'
import { GuidesEventsMapMarkers } from './GuidesEventsMapMarkers'
import { useGetGuidesEventsMapQuery } from './useGetGuidesEventsMapQuery'
import { GuideMapLoading } from '../details/common/GuideMap'

// Currently defaults to center of the US
const defaultCenter = {
  lat: 39.8283,
  lng: -98.5795,
}
const superclusterRadius = 60

export const GuidesEventsMap = () => {
  const [filters, setFilters] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const { guidesEventsMapData, isLoading } = useGetGuidesEventsMapQuery(filters)

  const onClose = debounce(() => {
    setSelectedId('')
  }, 100)

  const onFiltersChange = (updatedFilters: string[]) =>
    setFilters(updatedFilters)

  const onSelectedIdChange = (updatedSelectedId: string) =>
    setSelectedId(updatedSelectedId)

  return (
    <>
      <Helmet pageName='Travelpass | Explore - What to Do, Where to Eat, Where to Stay' />
      <div className='bg-grey-100 h-[calc(100svh-64px)] w-full md:h-[calc(100svh-76px)]'>
        <Map
          data={guidesEventsMapData?.map(
            ({ node: item }) =>
              !!item &&
              !!item?.addresses?.[0]?.lat &&
              !!item?.addresses?.[0]?.long && {
                item,
                lat: stringToNumber(item?.addresses?.[0]?.lat),
                lng: stringToNumber(item?.addresses?.[0]?.long),
              }
          )}
          defaultCenter={defaultCenter}
          defaultZoom={4}
          enableZoomControl={true}
          render={({ clusters, supercluster }) =>
            !isLoading && (
              <GuidesEventsMapMarkers
                clusters={clusters}
                selectedId={selectedId}
                supercluster={supercluster}
                onSelectedIdChange={onSelectedIdChange}
              />
            )
          }
          superclusterRadius={superclusterRadius}
          onClick={onClose}
        >
          <div className='bg-grey-100/90 p-x-4 p-y-3 shadow-2 absolute top-0 w-full items-center space-y-2 lg:flex lg:flex-row lg:gap-10'>
            <GuidesEventsMapGeocoder />
            <GuidesEventsMapFilters onFiltersChange={onFiltersChange} />
          </div>
        </Map>
        {isLoading && (
          <div className='absolute inset-0'>
            <GuideMapLoading />
          </div>
        )}
      </div>
    </>
  )
}
