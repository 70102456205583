import { Link } from '@travelpass/design-system'

export const ExperienceTerms = ({
  onClickCancellationPolicyTerm,
}: {
  onClickCancellationPolicyTerm: VoidFunction
}) => {
  return (
    <div className='type-small-text' data-testid='BookingAgreement'>
      By clicking on the button below, I acknowledge that I have reviewed the{' '}
      <Link
        label='Cancellation policy'
        onClick={() => onClickCancellationPolicyTerm()}
      />
      , and agree to the{' '}
      <Link href='/info/terms' label='Terms and conditions' target='_blank' />.
    </div>
  )
}
