import type { ReactNode } from 'react'

interface HotelResultsCardRowAmenitiesContainerProps {
  children: ReactNode
}

export const HotelResultsCardRowAmenitiesContainer = ({
  children,
}: HotelResultsCardRowAmenitiesContainerProps) => (
  <section className='grid grid-cols-3 gap-2'>{children}</section>
)
