import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { supportPath, tripsPath } from 'src/constants'

interface FooterProps {
  isDark?: boolean
  className?: string
}

export const FooterNav = ({ isDark, className }: FooterProps) => {
  const isTripsHideEnabled = useFlag('tripsHide')
  const footerRoutes: { isHidden?: boolean; name: string; to: string }[] = [
    {
      name: 'Search',
      to: '/search',
    },
    {
      name: 'Guides',
      to: '/guides/all',
    },
    {
      name: 'Trips',
      to: tripsPath,
      isHidden: isTripsHideEnabled,
    },
    {
      name: 'Support',
      to: supportPath,
    },
  ]

  return (
    <div className={classNames('flex flex-col gap-12 md:flex-row', className)}>
      {footerRoutes.map(
        ({ isHidden, name, to }) =>
          !isHidden && (
            <Link
              key={name}
              className={classNames(
                'type-button-small hover:c-valley uppercase no-underline transition-all hover:underline',
                isDark ? 'c-black' : 'c-warmGrey'
              )}
              to={to}
            >
              {name}
            </Link>
          )
      )}
    </div>
  )
}
