import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { TripCardActions } from './TripCardActions'
import { useGetGooglePlaceForContentCard } from './useGetGooglePlaceForContentCard'
import { generateDestinationsString, generateDateString } from '../../utils'

export const TripCardContent = ({
  endDate,
  name,
  startDate,
  timezone,
  tripPreference,
  onDeleteClick,
  onEditClick,
}: {
  endDate: string
  name: string
  startDate: string
  timezone: string
  tripPreference: GetTripDetailsQueryInTripsQuery['getTrip']['tripPreference']
  onDeleteClick?(): void
  onEditClick?(): void
}) => {
  const { addresses } = tripPreference ?? {}
  const destination = generateDestinationsString(addresses)
  const { googlePlace } = useGetGooglePlaceForContentCard({
    destination,
    googlePlaceId: addresses?.[0]?.googlePlaceId ?? '',
  })

  const dateDisplay = generateDateString({
    startDate,
    endDate,
    format: 'M/D/YY',
    timezone,
  })

  const destinationDisplay =
    destination.length === 0 ? googlePlace?.formattedAddress : destination

  return (
    <div className='h-34 space-y-2 p-4'>
      <span className='line-clamp-2 min-h-10'>
        <h3 className='type-h5'>{name ?? ''}</h3>
      </span>
      <div className='flex flex-row items-center justify-between'>
        <div className='w-7/10 space-y-2'>
          <p className='type-body-1 line-clamp-1'>{dateDisplay}</p>
          {!isEmpty(addresses) && (
            <div className='c-forestNew flex flex-row items-center gap-2'>
              <Icon name='placeOutline' size='small' />
              <p className='type-body-1 line-clamp-1'>{destinationDisplay}</p>
            </div>
          )}
        </div>
        <TripCardActions
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      </div>
    </div>
  )
}
