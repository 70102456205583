import type { ReactNode } from 'react'
import classNames from 'classnames'

interface GuideSectionsWrapperProps {
  children: ReactNode
  mapFullView: boolean
  selectedId: string
}

export const GuideSectionsWrapper = ({
  children,
  mapFullView,
  selectedId,
}: GuideSectionsWrapperProps) => (
  <div
    className={classNames(
      'lg:max-w-195 lg:z-3 lg:b-r-solid b-r-1 b-grey-400 bg-warm-grey max-w-full',
      {
        'absolute hidden lg:static lg:block': mapFullView,
        'lg:w-[calc(100%-520px)]': selectedId,
        'lg:w-full': !selectedId,
      }
    )}
  >
    {children}
  </div>
)
