import { IconButton, MenuItem, Popover } from '@travelpass/design-system'

export const TripCardActions = ({
  onDeleteClick,
  onEditClick,
}: {
  onDeleteClick?(): void
  onEditClick?(): void
}) => (
  <Popover
    placement='bottom-end'
    trigger={
      <div className='z-2 relative'>
        <IconButton icon='moreHoriz' />
      </div>
    }
  >
    <div className='w-50 p-2'>
      <MenuItem
        isHighlighted={false}
        label='Edit information'
        startIcon='modeEdit'
        value='Edit information'
        onClick={() => onEditClick?.()}
      />
      <MenuItem
        isHighlighted={false}
        label='Delete trip'
        startIcon='delete'
        value='Delete trip'
        onClick={() => onDeleteClick?.()}
      />
    </div>
  </Popover>
)
