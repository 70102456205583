const experiencesBookingDetailsHeroActionsCss = 'flex gap-3 justify-between'

const experiencesBookingDetailsHeroContentCss = 'flex flex-col gap-4'

const experiencesBookingDetailsHeroCopyButtonCss =
  'cursor-pointer ease-linear flex gap-3 items-center text-body-1 transition-opacity-200 active:opacity-70 hover:opacity-70'

const experiencesBookingDetailsHeroCss = 'flex flex-col gap-6'

const experiencesBookingDetailsHeroImageCss =
  'h-50 object-cover overflow-hidden rounded-4 w-full md:h-103'

const experiencesBookingDetailsHeroTitleCss = 'text-h4'

export {
  experiencesBookingDetailsHeroActionsCss,
  experiencesBookingDetailsHeroContentCss,
  experiencesBookingDetailsHeroCopyButtonCss,
  experiencesBookingDetailsHeroCss,
  experiencesBookingDetailsHeroImageCss,
  experiencesBookingDetailsHeroTitleCss,
}
