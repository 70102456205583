import { Button, EmptyState } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import {
  getHotelResultsFiltersFromSearchParams,
  setHotelResultsFiltersSearchParams,
} from '../hotelResultsUtils'

type HotelResultsEmptyVariant = 'default' | 'filteredIds'

interface HotelResultsEmptyProps {
  variant?: HotelResultsEmptyVariant
}

export const HotelResultsEmpty = ({
  variant = 'default',
}: HotelResultsEmptyProps) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const hasFilters = !isEmpty(
    getHotelResultsFiltersFromSearchParams(searchParams)
  )
  const subtitle = hasFilters
    ? 'Try adjusting some filters!'
    : 'Try adjusting your search to find what you are looking for.'
  const title = hasFilters
    ? "We're sorry, there aren't any hotels available that match all of those options."
    : 'No results found'

  const onClear = () =>
    setHotelResultsFiltersSearchParams({
      filters: {},
      onChange: (updatedSearchParams: URLSearchParams) =>
        setSearchParams(updatedSearchParams),
      searchParams,
    })

  const onFilteredIdsClick = () => {
    searchParams.set(HotelResultsSearchParams.mapFitBounds, 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <div className='flex flex-col items-center gap-2 py-9 lg:flex'>
      <div className='w-31'>
        <EmptyState name='noSearchResult' />
      </div>
      <section className='grow space-y-5'>
        <header className='space-y-2'>
          <h3 className='color-grey-800 max-w-91 type-h5'>{title}</h3>
          <p className='color-grey-800 type-body-1'>{subtitle}</p>
        </header>
        <div className='flex gap-2'>
          {variant === 'default' ? (
            <Button label='Go Back' onClick={() => navigate(-1)} />
          ) : (
            <Button label='Show All Results' onClick={onFilteredIdsClick} />
          )}
          {hasFilters && (
            <Button
              label='Clear Filters'
              variant='outlined'
              onClick={onClear}
            />
          )}
        </div>
      </section>
    </div>
  )
}
