import { Carousel } from '@travelpass/design-system'
import { awayGameDetails } from '../UtahJazzAwayGames/awayGameDetails'
import { AwayGameGuideCard } from '../UtahJazzAwayGames/components'

export const AwayGuides = () => {
  return (
    <>
      <Carousel
        borderRadius={12}
        hideArrows={false}
        hideDots={true}
        isLight={true}
        keyBoardControl={false}
        size='medium'
        slidesPerViewOnDesktop={3}
        slidesPerViewOnMobile={1}
        slidesPerViewOnTablet={2}
      >
        {awayGameDetails?.map(({ city, state, guide }) => (
          <div key={guide.id} className='mx-auto px-0 md:px-2'>
            <AwayGameGuideCard
              city={city}
              image={guide.image}
              owner={guide.owner}
              state={state}
              title={guide.title}
              url={guide.url}
            />
          </div>
        ))}
      </Carousel>
    </>
  )
}
