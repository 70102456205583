import { useQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { PageLayoutContainer } from 'src/common/components'
import { hotelResultsPath } from 'src/constants'
import {
  HotelResultsCard,
  HotelResultsCardLoading,
} from 'src/pages/hotels/results/common'
import type { GetHotelResultsCardUrl } from 'src/pages/hotels/results/hotelResultsTypes'
import { getHotelResultsCardUrl } from 'src/pages/hotels/results/hotelResultsUtils'
import { awayGameDetails } from '../awayGameDetails'

const getHotelsWithoutRates = gql(`
  query GetHotelsWithoutRates($searchHotelsWithoutRatesArgs: SearchHotelsWithoutRatesArgs!) {
    searchHotelsWithoutRates(searchHotelsWithoutRatesArgs: $searchHotelsWithoutRatesArgs) {
      hotelSearchResults {
        id
        amenities
        customerReviewScore
        description
        googlePlaceIds
        hotelAddress {
          city
          latitude
          longitude
          state
          stateAbbreviation
          streetAddress
        }
        images
        isFavorited
        isCollected
        name
        starRating
        totalCustomerReviews
      }
    }
  }
`)

export const AwayGameHotels = () => {
  const { teamName } = useParams()
  const [searchParams] = useSearchParams()
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getHotelsWithoutRates, {
    variables: {
      searchHotelsWithoutRatesArgs: {
        latitude: Number(latitude),
        longitude: Number(longitude),
      },
    },
  })

  const { hotelSearchResults } = data?.searchHotelsWithoutRates ?? {}
  const resultsData = hotelSearchResults?.slice(0, 6)

  const game = Object.values(awayGameDetails).find(
    game => game.team.toLowerCase().replaceAll(' ', '-') === teamName
  )

  const location = `${game.city}, ${game.state}`
  const exploreMore = `${hotelResultsPath}?location=${encodeURIComponent(location)}&latitude=${latitude}&longitude=${longitude}&arrival=${arrival}&departure=${departure}&sort=RECOMMENDED`

  const onCardClick = (
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  const hasNoResults = !resultsData?.length && !isLoading && !hasError
  const hasResults = !!resultsData?.length && !isLoading && !hasError

  if (hasNoResults) return null

  return (
    <section className='p-y-12' id='hotel-results'>
      <PageLayoutContainer>
        <h2 className='text-8 md:text-10 c-black font-900 m-b-2 m-0 text-balance text-center md:text-left'>
          Book A Hotel Near{' '}
          <span className='from-valley to-newForest bg-gradient-to-r bg-clip-text text-transparent'>
            {game.arena}
          </span>
        </h2>
        <p className='text-4 md:text-5 c-grey-800 font-400 m-y-0 m-b-8 text-balance text-center md:text-wrap md:text-left'>
          Choose from these recommended stays, or explore more hotels in{' '}
          {game.city}.
        </p>
        {isLoading && (
          <div className='p-x-5 md:p-x-0 grid gap-6 md:grid-cols-2 lg:grid-cols-3'>
            <HotelResultsCardLoading />
            <HotelResultsCardLoading />
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
          </div>
        )}
        {hasError && (
          <>
            <p>Oops! There was a problem. Sorry about that.</p>
          </>
        )}
        {hasResults && (
          <>
            <div className='p-x-5 md:p-x-0 grid gap-6 md:grid-cols-2 lg:grid-cols-3'>
              {resultsData?.map(resultData => (
                <HotelResultsCard
                  key={resultData.id}
                  className='bg-transparent shadow-none lg:group-has-[>:nth-child(4)]:w-1/4'
                  hotelResultsCardData={{
                    ...resultData,
                    nightlyAverage: undefined,
                  }}
                  onClick={() => onCardClick(resultData)}
                />
              ))}
            </div>
            <section className='flex flex-row justify-center'>
              <a
                className='rounded-2 c-forest font-700 text-4 bg-valley border-valleyDark/20 mt-10 w-auto border-2 border-solid px-6 py-3 text-center font-sans no-underline transition-shadow hover:cursor-pointer hover:shadow-lg'
                href={exploreMore}
              >
                Explore More Hotels
              </a>
            </section>
          </>
        )}
      </PageLayoutContainer>
    </section>
  )
}
