import debounce from 'lodash.debounce'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import type { GuideData } from './types'

interface useGuideMetricSaveProps {
  id: GuideData['id']
  name: GuideData['name']
}

export const useGuideMetricSave = ({ id, name }: useGuideMetricSaveProps) => {
  const { isAnonymous } = useFirebaseUser()

  const onSaveGuide = debounce(() => {
    if (isAnonymous) dispatch('openSignin', { isCreateAccount: false })
    else {
      dispatchToggleAddToCollectionsModal({
        isModalOpen: true,
        item: { id, name, type: CollectionItemIdType.Guide },
      })
    }
  }, 100)

  return onSaveGuide
}
