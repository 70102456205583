import { useEffect, useState } from 'react'
import { Checkbox, Icon, SkeletonDots } from '@travelpass/design-system'
import type { CollectionEdge } from 'src/__generated__/graphql'
import { useAddIntoCollection } from './hooks/useAddIntoCollection'
import { useRemoveFromCollection } from './hooks/useRemoveFromCollection'
import { StateBadge } from '../StateBadge'

interface AddToCollectionsListProps {
  collections: CollectionEdge[]
  collectionsLoading: boolean
  item: AddToItem
  refetch: () => void
}

export const AddToCollectionsList = ({
  collectionsLoading,
  item: { id, type },
  collections,
  refetch,
}: AddToCollectionsListProps) => {
  const { addItem, loading: addLoading } = useAddIntoCollection(id, type)
  const { removeItem, loading: removeLoading } = useRemoveFromCollection()
  const [statusBadgeState, setStatusBadgeState] = useState(null)

  const collectionNodes = collections?.map(({ node }) => node)
  const loading = addLoading || removeLoading

  useEffect(() => {
    if (!collectionsLoading) {
      setStatusBadgeState(
        collectionNodes?.map(({ id }) => ({
          id,
          loading: false,
          message: '',
          state: false,
        }))
      )
    }
  }, [collectionsLoading, collectionNodes?.length])

  const handleMutation = async (
    collectionId: string,
    existingCollectedItem: boolean
  ) => {
    return existingCollectedItem
      ? await removeItem({
          collectionId,
          itemId: id,
          type,
        })
      : await addItem(collectionId)
  }

  const clean = (collectionId: string) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === collectionId
          ? { ...badge, loading: false, message: '', state: false }
          : badge
      )
    )
  }

  const showLoading = (collectionId: string) => {
    setStatusBadgeState(prev =>
      prev.map(badge =>
        badge.id === collectionId
          ? { ...badge, loading: true, message: '' }
          : badge
      )
    )
  }

  const showSuccessMessage = (
    collectionId: string,
    existingCollectedItem: boolean
  ) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === collectionId
          ? {
              ...badge,
              loading: false,
              message: existingCollectedItem ? 'Deleted' : 'Saved',
              state: true,
            }
          : badge
      )
    )
  }

  const showErrorMessage = (collectionId: string) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === collectionId
          ? {
              ...badge,
              loading: false,
              message: 'Failed',
              state: true,
            }
          : badge
      )
    )
  }

  const handleOnClick = async (
    collectionId: string,
    existingCollectedItem: boolean
  ) => {
    if (loading) return
    try {
      showLoading(collectionId)
      const { errors: _errors } = await handleMutation(
        collectionId,
        existingCollectedItem
      )

      if (!_errors) showSuccessMessage(collectionId, existingCollectedItem)
    } catch (e) {
      showErrorMessage(collectionId)
    } finally {
      setTimeout(() => clean(collectionId), 3000)
      refetch()
    }
  }

  return (
    <>
      {collectionsLoading ? (
        <SkeletonDots />
      ) : (
        <div className='flex flex-col gap-y-2'>
          {collectionNodes?.map(
            ({ existingCollectedItem, id, name }, index) => (
              <button
                key={id}
                className='hover:bg-warmGrey type-body-1 b-none flex cursor-pointer justify-between rounded bg-transparent p-2 text-start transition-colors'
                onMouseDown={() => {
                  handleOnClick(id, existingCollectedItem)
                }}
              >
                <span className='inline-flex w-full items-center justify-between gap-4 [&_i]:block'>
                  <span className='c-forest-light'>
                    <Icon name='bookmarkBorder' />
                  </span>
                  <span className='inline-flex w-full justify-between pr-4'>
                    <span className='max-w-4/5 w-4/5'>{name}</span>
                    {statusBadgeState?.find(stateEntry => stateEntry.id === id)
                      ?.state && (
                      <StateBadge
                        message={statusBadgeState?.[index]?.message}
                      />
                    )}
                  </span>
                </span>
                {statusBadgeState?.find(stateEntry => stateEntry.id === id)
                  ?.loading ? (
                  <StateBadge loading />
                ) : (
                  <Checkbox isChecked={existingCollectedItem} label={null} />
                )}
              </button>
            )
          )}
        </div>
      )}
    </>
  )
}
