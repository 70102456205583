import { Button } from '@travelpass/design-system'
import { google as googleCalendarLink } from 'calendar-link'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceActivityItinerary,
  ExperienceBooking,
  ExperienceProduct,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { formatDate, getTimeFromMilitaryTimeString } from 'src/utils'

interface ExperiencesBookingDetailsSummaryDepartureProps {
  experiencesBookingDetailsData: ExperienceBooking
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsSummaryDeparture = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsSummaryDepartureProps) => {
  const { travelDate, startTime, bookingDetails } =
    experiencesBookingDetailsData ?? {}

  const { logistics, title } = experiencesBookingDetailsProductData ?? {}
  const { duration } =
    (experiencesBookingDetailsProductData?.itinerary as
      | ExperienceActivityItinerary
      | ExperienceStandardItinerary
      | ExperienceUnstructuredItinerary) ?? {}
  const { description: logisticsDescription, location } =
    logistics?.start?.[0] ?? {}
  const dateFormatTemplate = 'ddd, MMMM D, YYYY - hh:mm A'
  const { fixedDurationInMinutes } = duration ?? {}

  const formattedTime = getTimeFromMilitaryTimeString(startTime)
  const formattedDate = dayjs(travelDate).format('dddd, MMMM D, YYYY')
  const formattedDateTime = formattedTime
    ? `${formattedDate} - ${formattedTime}`
    : formattedDate
  const formattedTimeZone = dayjs()
    .tz(experiencesBookingDetailsData?.trip?.timeZone)
    .format('zzz')

  const constructedStartTime = formatDate(
    dayjs(`${travelDate} ${startTime}`, 'YYYY-MM-DD hh:mm A'),
    dateFormatTemplate
  )
  const constructedEndTime = formatDate(
    dayjs(constructedStartTime).add(fixedDurationInMinutes, 'm'),
    dateFormatTemplate
  )
  const showDeparture = !isEmpty(logistics)

  const pickupPointAddress = isEmpty(bookingDetails?.departurePoint)
    ? ''
    : [
        bookingDetails.departurePoint.name,
        bookingDetails.departurePoint.address?.addressLine1,
        `${bookingDetails.departurePoint.address?.city || ''}, ${
          bookingDetails.departurePoint.address?.state || ''
        } ${bookingDetails.departurePoint.address?.postalCode || ''}`,
        bookingDetails.departurePoint.address?.countryCode || '',
      ]
        .filter(Boolean)
        .join('\n')

  const onCalendarButtonClick = () =>
    window.open(
      googleCalendarLink({
        end: constructedEndTime,
        start: constructedStartTime,
        title,
      })
    )

  return (
    showDeparture && (
      <div
        className='flex flex-col gap-4'
        data-testid='ExperiencesBookingDetailsSummaryDeparture'
      >
        <h3 className='text-h6'>Departure Details</h3>
        <div className='flex flex-col gap-6 md:gap-9'>
          <div className='flex flex-col gap-4'>
            {pickupPointAddress && (
              <div className='space-y-2 whitespace-pre-wrap'>
                <p className='text-body-1'>Pickup point:</p>
                <p className='color-grey-800 text-body-2'>
                  {pickupPointAddress}
                </p>
                {bookingDetails.pickupAdditionalInfo && (
                  <p className='text-body-2'>
                    {bookingDetails.pickupAdditionalInfo}
                  </p>
                )}
              </div>
            )}
            <div className='flex flex-col gap-2'>
              <p className='type-body-1'>Departure Time:</p>
              <p className='color-grey-800 type-body-2 group'>
                {formattedDateTime}{' '}
                <span className='type-subtext c-canyonDark relative align-middle'>
                  {formattedTimeZone}
                </span>
              </p>
            </div>
            {!isEmpty(logistics?.start) && (
              <div className='flex flex-col gap-2'>
                <p className='text-body-1'>Departure Point:</p>
                <p className='color-grey-800 text-body-2'>
                  <span>{location?.name}</span>
                  <br />
                  <span>{location?.unstructuredAddress}</span>
                </p>
                <p className='color-grey-800 text-body-2'>
                  {logisticsDescription}
                </p>
              </div>
            )}
          </div>
          <div className='hide-on-print'>
            <Button
              label='Add to calendar'
              size='small'
              startIcon='event'
              variant='text'
              onClick={onCalendarButtonClick}
            />
          </div>
        </div>
      </div>
    )
  )
}
