import { useState } from 'react'
import classNames from 'classnames'

export const HeroImage = ({
  hasFirstImageLoaded,
  imageLink,
  index,
  name,
  onError,
  onLoad,
}: {
  hasFirstImageLoaded?: boolean
  imageLink: string
  index: number
  name?: string
  onError(): void
  onLoad(): void
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const onImgLoad = () => {
    onLoad()
    setIsLoading(false)
  }

  return (
    <div
      className={classNames(
        'bg-grey400 flex h-full w-full flex-row items-center justify-center',
        {
          'animate-pulse': isLoading,
        }
      )}
    >
      <img
        alt={`${name} hero #${index + 1}`}
        className={classNames('h-55 w-full object-cover', {
          'opacity-100': !isLoading,
          'opacity-0': isLoading,
        })}
        draggable={false}
        loading={hasFirstImageLoaded ? 'eager' : 'lazy'}
        src={imageLink}
        onError={onError}
        onLoad={onImgLoad}
      />
    </div>
  )
}
