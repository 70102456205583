import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { AvailableIcons } from '@travelpass/design-system'
import type { EventStatus } from 'src/__generated__/graphql'
import { EventCard } from '../../../components'

export const DraggableEventCard = ({
  endDate,
  eventId,
  imageUrl,
  name,
  isActive,
  startDate,
  timezone,
  bookedStatus,
  notes,
  useEventTime,
  onEventCardClick,
  onHover,
}: {
  cardIcon?: AvailableIcons
  isActive?: boolean
  imageUrl?: string
  eventId: string
  name: string
  bookedStatus?: EventStatus
  startDate?: string
  endDate?: string
  notes?: string
  timezone?: string
  useEventTime?: boolean
  onEventCardClick?(eventId: string): void
  onHover?: (eventId?: string) => void
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: eventId })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: '8px',
  }

  return (
    // dnd wrapper so that element is drag and droppable
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <EventCard
        isDraggable
        bookedStatus={bookedStatus}
        endDate={endDate}
        eventId={eventId}
        imageUrl={imageUrl}
        isActive={isActive}
        name={name}
        notes={notes}
        startDate={startDate}
        timezone={timezone}
        useEventTime={useEventTime}
        onEventCardClick={onEventCardClick}
        onHover={onHover}
      />
    </div>
  )
}
