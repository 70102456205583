import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  CompetitionPhase,
  GetCurrentUserQueryInDashboardQuery,
} from 'src/__generated__/graphql'
import {
  BookingTimeState,
  ExperienceBookingTimeState,
  GuideDraftStatus,
  GuideStatus,
  TripStatus,
} from 'src/__generated__/graphql'

/** @todo remove activityFeed when dashboardV2HideActivityFeed is enabled */
export const dashboardCurrentUserQuery = gql(`
  query GetCurrentUserQueryInDashboard($searchUserExperienceBookingsArgs: SearchUserExperienceBookingsArgs!, $searchUserHotelBookingsArgs: SearchUserHotelBookingsArgs! $tripSearchInput: TripSearchInput!, $userGuidesArgs: UserGuidesArgs!, $userGuideDraftsArgs: UserGuideDraftsArgs!, $phase: CompetitionPhase) {
    currentUser {
      id
      userRoles
      collections(first: 1) {
        totalCount
      }
      experienceBookings(first: 1, searchUserExperienceBookingsArgs: $searchUserExperienceBookingsArgs) {
        totalCount
      }
      firstName
      hotelBookings(first: 1, searchUserHotelBookingsArgs: $searchUserHotelBookingsArgs) {
        totalCount
      }
      searchPreferences {
        id
      }
      trips(first: 1, tripSearchInput: $tripSearchInput) {
        totalCount
      }
      userGuides(first: 5, userGuidesArgs: $userGuidesArgs) {
        edges {
          node {
            id
            name
            imageUrl
            paginatedImages(first: 1) {
              edges {
                node {
                  id
                  source
                  url
                }
              }
            }
          }
        }
        totalCount,
      }
      userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
        totalCount
      }
      guideLikesCount
      guideSavesCount
      guideSharesCount
      guideViewsCount
      userProfile {
        id
        accountHandle
        activeBannerImage {
          id
          extraSmallUrl
          largeUrl
          mediumUrl
          smallUrl
        }
        bio
        bragContinents
        bragCountries
        bragStates
        competitionInfo {
          currentPhase
          competitions{
            id
            currentPhase
            isActive
            name
          }
          competitionCheck{
            hasBio
            hasProfileImage
            hasVideoLink
            highQualityGuideCount
          }
          leaderboardRanking(phase: $phase)
          voteCount(phase: $phase)
          status
        }
        displayName
        followCount {
          countOfFollowed
          countOfFollowing
        }
        followersConnection(first: 10, timeRange: 7) {
          edges {
            node {
              id
              accountHandle
              profileImageUrl
              displayName
              isFollowed
              userId
            }
          }
          totalCount
        }
        guideCount(guideStatus: PUBLISHED)
        introVideoEmbed
        isUserOwner
        links {
          title
          url
        }
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
        profileImageSource
        profileImageUrl
        socialLinks {
          identifier
          network
        }
        tags {
          id
          category
          name
        }
        userId
      }
    }
  }
`)

export type UseGetDashboardQuery = {
  dashboardData: GetCurrentUserQueryInDashboardQuery
  hasError: ApolloError
  isLoading: boolean
  refetch
}

/** @todo waiting on BE to return follower feed */
export const useGetDashboardQuery = (
  phase: CompetitionPhase
): UseGetDashboardQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
    refetch,
  } = useQuery(dashboardCurrentUserQuery, {
    fetchPolicy: 'network-only',
    variables: {
      searchUserExperienceBookingsArgs: {
        bookingTimeState: ExperienceBookingTimeState.Upcoming,
      },
      searchUserHotelBookingsArgs: {
        bookingTimeState: BookingTimeState.Upcoming,
      },
      tripSearchInput: {
        status: TripStatus.Upcoming,
      },
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
      userGuideDraftsArgs: {
        includeStatuses: [GuideDraftStatus.Active],
      },
      phase,
    },
  })
  const dashboardData = data ?? null

  return {
    dashboardData,
    hasError,
    isLoading,
    refetch,
  }
}
