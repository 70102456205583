import { Divider, Skeleton } from '@travelpass/design-system'
import { BookingCardLoading } from 'src/pages/bookings/MyBookings/common/components'

export const DashboardBookingsLoading = () => (
  <section className='space-y-6' data-testid='DashboardBookingsLoading'>
    <div className='flex flex-row items-center justify-between'>
      <div className='w-35 lg:w-50 h-12'>
        <Skeleton variant='rounded' />
      </div>
      <div className='w-43.5 hidden h-8 md:block'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-8 w-8 md:hidden'>
        <Skeleton variant='circular' />
      </div>
    </div>
    <div className='space-y-9'>
      <div className='flex flex-col space-y-6'>
        <div className='space-y-3'>
          <div className='w-51 h-3 lg:h-3.5 lg:w-60'>
            <Skeleton />
          </div>
          <Divider />
        </div>
        <div className='flex h-0 h-auto w-0 w-auto min-w-0 grow flex-row items-center justify-start overflow-hidden'>
          <div className='pr-1'>
            <div className='h-8 w-8'>
              <Skeleton variant='rounded' />
            </div>
          </div>
          <div className='flex flex-row items-center gap-4 p-5'>
            <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
            <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
            <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
          </div>
        </div>
      </div>
    </div>
    <div className='space-y-6'>
      <div className='space-y-3'>
        <div className='m-t--1 w-45.5 h-3 lg:h-3.5 lg:w-52'>
          <Skeleton />
        </div>
        <Divider />
      </div>
      <div className='flex h-0 h-auto w-0 w-auto min-w-0 grow flex-row items-center justify-start overflow-hidden'>
        <div className='block pr-1'>
          <div className='h-8 w-8'>
            <Skeleton variant='rounded' />
          </div>
        </div>
        <div className='flex flex-row items-center gap-4 p-5'>
          <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
          <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
          <BookingCardLoading className='min-w-55 w-55 md:min-w-66 md:w-66' />
        </div>
      </div>
    </div>
  </section>
)
