import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { SkeletonDots } from '@travelpass/design-system'
import { useParams, useSearchParams } from 'react-router-dom'
import { TripSearchParams } from 'src/pages/trips/constants'
import { useLazyGetExperienceProductById } from './useLazyGetExperienceProductById'
import { TripDetailsExploreDrawerExperience } from '../../TripDetailsExploreDrawerExperience'

interface ExperienceDetailsContentProps {
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  onEventAdded?: () => void
}

export const ExperienceDetailsContent = ({
  setScrollToEventId,
  onEventAdded,
}: ExperienceDetailsContentProps) => {
  const { tripId } = useParams()
  const [searchParams] = useSearchParams()
  const eventId = searchParams.get(TripSearchParams.eventId)

  const [getExperienceProductById, experienceQuery] =
    useLazyGetExperienceProductById()

  useEffect(() => {
    if (eventId && !experienceQuery.loading) {
      getExperienceProductById({
        variables: {
          nodeId: eventId,
        },
      })
    }
  }, [eventId, experienceQuery.loading])

  const experienceProduct =
    experienceQuery.data?.node?.__typename === 'ExperienceProduct'
      ? experienceQuery.data.node
      : null

  const loading = experienceQuery.loading

  if (loading && !experienceProduct) return <SkeletonDots />

  return (
    <div className='flex flex-col gap-10'>
      <TripDetailsExploreDrawerExperience
        experienceProduct={experienceProduct}
        setScrollToEventId={setScrollToEventId}
        tripId={tripId}
        onEventAdded={onEventAdded}
      />
    </div>
  )
}
