import { Button } from '@travelpass/design-system'
import type { GuideDraftShareReviewButtonProps } from './GuideDraftShareReviewButton'
import { GuideDraftShareReviewButton } from './GuideDraftShareReviewButton'
import { getGuideDraftInvalidFields } from '../../../guideDraftUtils'
import type { GuideDraftDataOld } from '../../../types'

interface GuideDraftShareReviewProps {
  guideDraftData: GuideDraftDataOld
  isLoading: boolean
  onModalEditOpen: (updatedScrollId: string) => void
  onModalImagesEditOpen: VoidFunction
  onShare: VoidFunction
}

export const GuideDraftShareReview = ({
  guideDraftData,
  isLoading,
  onModalEditOpen,
  onModalImagesEditOpen,
  onShare,
}: GuideDraftShareReviewProps) => {
  const invalidFields = getGuideDraftInvalidFields(guideDraftData)
  const reviewButtons: GuideDraftShareReviewButtonProps[] = [
    {
      icon: 'placeOutline',
      isCompleted: !invalidFields.includes('addresses'),
      subtitle: 'Help others discover your recommendations.',
      title: 'Location',
      onClick: () => onModalEditOpen('guide-draft-edit-modal-location'),
    },
    {
      icon: 'cameraAlt',
      isCompleted: !invalidFields.includes('images'),
      subtitle: 'Personalize your Guide with up to three header images.',
      title: 'Header Image',
      onClick: onModalImagesEditOpen,
    },
    {
      icon: 'formatListBulleted',
      isCompleted: !invalidFields.includes('description'),
      subtitle:
        'Highlight your travel tips or explain what this Guide is about.',
      title: 'Description',
      onClick: () => onModalEditOpen('guide-draft-edit-modal-description'),
    },
    {
      icon: 'priceTag',
      isCompleted: !invalidFields.includes('tags'),
      subtitle: 'Choose up to five tags to describe your Guide.',
      title: 'Tags',
      onClick: () => onModalEditOpen('guide-draft-edit-modal-tags'),
    },
  ]

  return (
    <section className='space-y-6'>
      <p className='type-subtitle-2 c-black'>
        {invalidFields?.length > 1
          ? "There are a couple of things to do before you can publicly share your Guide on Travelpass.com. Peek below to see what's missing."
          : 'Finish up the last step to share your Guide on Travelpass.com.'}
      </p>
      <div className='max-w-98 m-auto space-y-6'>
        {reviewButtons
          .sort((a, b) => Number(a.isCompleted) - Number(b.isCompleted))
          .map(sortedReviewButton => (
            <GuideDraftShareReviewButton
              key={sortedReviewButton?.title}
              {...sortedReviewButton}
            />
          ))}
        {!invalidFields.length && (
          <div className='m-a w-fit'>
            <Button
              autoFocus={true}
              isDisabled={isLoading}
              size='large'
              onClick={onShare}
            >
              Share Publicly
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}
