import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const CREATE_COLLECTION = gql(`
    mutation CreateCollection($input: CreateCollectionInput!){
        createCollection(input: $input){
            collection{
                id
                name
                isDefault
                latitude
                longitude
                locationName
                user {
                    id
                    collections(first: 1){
                        totalCount
                    }
                }
            }
        }
    }    
`)

export const useCreateCollection = () => useMutation(CREATE_COLLECTION)
