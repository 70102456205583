import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { TripCardHeroImage } from './TripCardHeroImage'

export const TripCardHero = ({
  imageUrl,
  name,
}: {
  imageUrl: string
  name: string
}) => {
  const [hasImageLoaded, setHasImageLoaded] = useState(false)
  const [imageLink, setImageLink] = useState(imageUrl)

  const imageError = isEmpty(imageLink)

  const onError = () => setImageLink('')

  const onLoad = () => {
    if (!hasImageLoaded) setHasImageLoaded(true)
  }

  return (
    <div
      className={classNames(
        'of-hidden flex h-56 w-full flex-row rounded-xl object-center',
        {
          'animate-pulse': !hasImageLoaded && !imageError,
          'bg-grey400': hasImageLoaded && !imageError,
        }
      )}
    >
      {imageError ? (
        <div className='flex h-full w-full items-center'>
          <EmptyState name='noImages' />
        </div>
      ) : (
        <TripCardHeroImage
          hasFirstImageLoaded={hasImageLoaded}
          imageLink={imageLink}
          name={name}
          onError={onError}
          onLoad={onLoad}
        />
      )}
    </div>
  )
}
