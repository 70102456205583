export const ExperienceCardPricing = ({
  amount,
}: {
  amount?: string | null
}) => {
  const amountFloat = parseFloat(amount ?? '')
  return amountFloat > 0 ? (
    <p className='type-small-text c-grey-700 pt-1'>
      from
      <span className='type-body-2 c-forest pr-1'>
        ${amountFloat.toFixed(0)}
      </span>
    </p>
  ) : null
}
