import { useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GuideData } from './types'
import { useIncrementGuideShareCountMutation } from '../useIncrementGuideShareCountMutation'
import { useShareGuide } from '../useShareGuide'

export const useGuideMetricShare = (guideId: GuideData['id']) => {
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)

  const onGuideShare = debounce(async () => {
    await shareGuide({
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide URL copied',
        })
        pushDataToDataLayer('guide_share', {
          guide_id: guideId,
          trigger_url: window.location.href,
        })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId } })
      setHasShared(true)
    }
  }, 100)

  return onGuideShare
}
