const bookingDetailsOverviewAddressCss = 'color-grey-800 text-body-1'

const bookingDetailsOverviewCss =
  'flex flex-col gap-5 py-5 relative md:flex-row md:gap-13 md:py-9'

const bookingDetailsOverviewDatesCss = 'flex flex-col gap-6 w-full'

const bookingDetailsOverviewDividerCss =
  'bg-grey-300 h-1px w-full md:absolute md:bottom-0 md:h-full md:right-1/2 md:top-0 md:w-1px'

const bookingDetailsOverviewLocationCss = 'flex flex-col gap-5 w-full'

const bookingDetailsOverviewMapCss = 'h-38 overflow-hidden rounded-2'

export {
  bookingDetailsOverviewAddressCss,
  bookingDetailsOverviewCss,
  bookingDetailsOverviewDatesCss,
  bookingDetailsOverviewDividerCss,
  bookingDetailsOverviewLocationCss,
  bookingDetailsOverviewMapCss,
}
