import type { ReactNode } from 'react'
import { useCallback, useId, useRef, useState } from 'react'
import { Link } from '@travelpass/design-system'
import classNames from 'classnames'

type ReadMoreContentSectionProps = {
  children: ReactNode
  maxHeightClass?: `max-h-${number}`
}

export const ReadMoreContentSection = ({
  children,
  maxHeightClass = 'max-h-30',
}: ReadMoreContentSectionProps) => {
  const htmlId = useId()
  const maxHeight = useRef<number>()
  const [canOverflow, setCanOverflow] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const containerRefCb = useCallback(
    node => {
      if (node) {
        const nodeMaxH = window
          ? parseInt(
              window
                .getComputedStyle(node)
                .getPropertyValue('max-height')
                .replace('px', '')
            )
          : null

        if (nodeMaxH && nodeMaxH !== maxHeight.current) {
          maxHeight.current = nodeMaxH
        }

        const calcCanOverflow = node.scrollHeight > (maxHeight.current ?? 0)

        if (calcCanOverflow !== canOverflow) {
          setCanOverflow(calcCanOverflow)
        }
      }
    },
    [canOverflow, children, maxHeight.current]
  )

  const toggleExpanded = () => {
    setExpanded(isExpanded => !isExpanded)
  }

  return (
    <section>
      <div
        aria-expanded={canOverflow ? expanded : null}
        className={classNames(
          maxHeightClass,
          'print:max-h-none',
          canOverflow &&
            'overflow-mask-fade aria-expanded:overflow-mask-none overflow-hidden aria-expanded:max-h-none'
        )}
        id={htmlId}
        ref={containerRefCb}
      >
        {children}
      </div>
      {canOverflow && (
        <div className='mt-4 flex flex-row-reverse print:hidden'>
          <Link
            aria-controls={htmlId}
            aria-label='Toggle more content' // aria-label should win out over label, but testing is needed
            startIcon={expanded ? 'arrowDropUp' : 'arrowDropDown'}
            onClick={toggleExpanded}
          >
            {expanded ? 'Read less' : 'Read more'}
          </Link>
        </div>
      )}
    </section>
  )
}
