import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { BookingValidateRateQueryVariables } from 'src/__generated__/graphql'

export const VALIDATE_RATE = gql(`
  query BookingValidateRate($rateToken: String!) {
    validatedRate(rateToken: $rateToken) {
      acceptedCreditCards {
        name
        mandatoryText
      }
      available
      provider
      room {
        name
        images {
          caption
          href
        }
        rates {
          cancelPolicy {
            constructedCancellationPolicies {
              shortText
              policyText
            }
            fullyRefundableUntil
            nonRefundableAfter
            partiallyRefundableUntil
            refundType
          }
          policies {
            description
            type
          }
          price {
            allInTotal {
              amount
              currency
            }
            additionalFees {
              amount {
                amount
              }
              type
            }
            dueNow {
              amount
              currency
            }
            dueLater {
              amount
            }
            grandtotal {
              amount
              currency
            }
            nightly {
              amount {
                amount
                currency
              }
              date
              fees {
                amount {
                  amount
                  currency
                }
                type
              }
            }
            subtotal {
              amount
            }
            surchargeTotal {
              amount
            }
            surcharges {
              type
              amount {
                amount
              }
            }
          }
          rateToken
          standardAttributes
        }
      }
      frontEndRequirements {
        name
      }
    }
  }
`)

export const useBookingRateQuery = ({
  rateToken,
}: BookingValidateRateQueryVariables) =>
  useQuery(VALIDATE_RATE, {
    skip: !rateToken,
    variables: { rateToken },
  })
