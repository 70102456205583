import creditCardType from 'credit-card-type'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Cookies from 'js-cookie'
import type {
  CreateHotelBookingMutationVariables,
  FraudDetection,
  BookingValidateRateQuery,
  PaymentInput,
} from 'src/__generated__/graphql'
import {
  NoncePaymentType,
  CardType,
  FrontEndTaskName,
  Merchanted,
} from 'src/__generated__/graphql'
import { lucencyIdFromCookie } from 'src/utils'

dayjs.extend(customParseFormat)

const formatExpiration = (expiration: string) => {
  const expirationDate = dayjs(expiration, 'MMYY')
  return {
    expirationMonth: expirationDate.format('MM'),
    expirationYear: expirationDate.format('YYYY'),
  }
}

// TODO: we cannot assume this for international numbers
const formatPhone = (phone: string) => `+1${phone}`

const replaceNondigitChars = (str: string) => str.replace(/[^\d]+/g, '')

export const findCardType = (cardNumber: string): string => {
  return creditCardType(cardNumber)[0]?.niceType?.replace(' ', '')
}

export interface CreateHotelBookingInputData {
  address1: string
  address2: string | null
  cardHolder: string
  city: string
  country: string
  cvc: string
  email: string
  expiration: string
  firstName: string
  lastName: string
  number: string
  phone: string
  postalCode: string
  stateProvince: string
  sendSms: boolean | null
  braintreePayment: {}
}

interface GenerateCreateHotelBookingVariables {
  data: CreateHotelBookingInputData
  eventId?: string
  frontEndRequirements: BookingValidateRateQuery['validatedRate']['frontEndRequirements']
  rateToken: string
  tripId?: string
  webbedsDevicePayload: string
}

const getPaymentInput = ({
  address1,
  address2,
  cardHolder,
  city,
  country,
  cvc,
  expiration,
  number,
  phone,
  postalCode,
  stateProvince,
  braintreePayment,
}): PaymentInput => {
  if (braintreePayment) {
    const { nonce, details } = braintreePayment
    return {
      nonce: {
        token: nonce,
        type: NoncePaymentType.PaypalAccount,
        paypalAccount: {
          email: details.email,
          payerId: details.payerId,
        },
        lastName: details.lastName,
        firstName: details.lastName,
        country: details.countryCode,
        address1: details?.billingAddress?.line1,
        address2: details?.billingAddress?.line2,
        city: details?.billingAddress?.city,
        stateProvince: details?.billingAddress?.state,
        postalCode: details?.billingAddress?.postalCode,
      },
    }
  } else {
    const { expirationMonth, expirationYear } = formatExpiration(expiration)
    const formattedPhone = formatPhone(phone)
    const formattedCVC = replaceNondigitChars(cvc)
    const formattedNumber = replaceNondigitChars(number)
    const providedCardType = CardType[findCardType(number)]

    return {
      creditCard: {
        address1,
        address2,
        cardHolder,
        cardType: providedCardType,
        city,
        country,
        cvc: formattedCVC,
        expirationMonth,
        expirationYear,
        number: formattedNumber,
        phone: formattedPhone,
        postalCode,
        stateProvince,
      },
    }
  }
}

const generateCreateHotelBookingVariables = ({
  data,
  eventId,
  frontEndRequirements,
  rateToken,
  tripId,
  webbedsDevicePayload,
}: GenerateCreateHotelBookingVariables): CreateHotelBookingMutationVariables => {
  const { email, firstName, lastName, phone, sendSms } = data

  const getFraudProtection = () => {
    const { ExpediaFraudPrevention, Kount, WebBedsFraud } = FrontEndTaskName
    const includesExpedia = frontEndRequirements.some(
      ({ name }) => name === ExpediaFraudPrevention
    )
    const includesKount = frontEndRequirements.some(
      ({ name }) => name === Kount
    )
    const includesWebBeds = frontEndRequirements.some(
      ({ name }) => name === WebBedsFraud
    )

    let fraudChecks: FraudDetection = {}

    if (includesExpedia) {
      fraudChecks = {
        ...fraudChecks,
        expediaFraudPrevention: {
          trustWidgetData: window.trustApi.getTrustPayload(),
        },
      }
    }

    if (includesKount) {
      fraudChecks = {
        ...fraudChecks,
        kount: {
          // kount expects a 32 character string to be passed in
          // removing the dashes in lucencyId will make it 32 characters
          kountSessionId: lucencyIdFromCookie.replace(/-/g, ''),
        },
      }
    }

    if (includesWebBeds && webbedsDevicePayload) {
      fraudChecks = {
        ...fraudChecks,
        webBeds: {
          devicePayload: webbedsDevicePayload,
        },
      }
    }

    return fraudChecks
  }

  return {
    input: {
      bookingRequest: {
        customerUserAgent: navigator.userAgent,
        eventId,
        reservationContact: {
          firstName,
          lastName,
          email,
          phone: formatPhone(phone),
          sendSms,
        },
        fraudDetection: {
          priceline: { xRsClientCode: Cookies.get('RS-CLIENT') || '' },
          ...getFraudProtection(),
        },
        merchanted: Merchanted.WithCard,
        payment: getPaymentInput(data),
        rateToken,
        tripId,
        refererUrl: Cookies.get('refererUrl') || '',
      },
    },
  }
}

export { generateCreateHotelBookingVariables }
