import type {
  GetTripDetailsQueryInTripsQuery,
  GetTripEventsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { OverviewAtAGlanceItem } from './OverviewAtAGlanceItem'

interface OverviewAtAGlanceProps
  extends Pick<
    GetTripEventsQueryInTripsQuery['getTrip'],
    'numberOfEvents' | 'numberOfStayEvents'
  > {
  numberOfDays: GetTripDetailsQueryInTripsQuery['getTrip']['numberOfDays']
}

export const OverviewAtAGlance = ({
  numberOfDays,
  numberOfEvents,
  numberOfStayEvents,
}: OverviewAtAGlanceProps) => (
  <section className='space-y-3'>
    <div className='flex-items-center flex grow gap-3'>
      <div className='pr-4 md:pr-20'>
        <h2 className='max-w-29 type-h5 m-0'>Your Trip at a Glance</h2>
      </div>
      <div className='flex gap-6'>
        <OverviewAtAGlanceItem
          number={numberOfDays}
          suffix={numberOfDays === 1 ? 'Day' : 'Days'}
        />
        <OverviewAtAGlanceItem
          number={numberOfEvents}
          suffix={numberOfEvents === 1 ? 'Event' : 'Events'}
        />
        <OverviewAtAGlanceItem
          hideDivider={true}
          number={numberOfStayEvents}
          suffix={numberOfStayEvents === 1 ? 'Stay' : 'Stays'}
        />
      </div>
    </div>
  </section>
)
