import { StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  CollectionItemIdType,
  type PublishedEvent,
} from 'src/__generated__/graphql'
import { CollectedItemTip, CollectionDetailsMenu } from 'src/common/components'
import { defaultLocationImage } from 'src/constants'
import { getEventImage } from 'src/pages/trips/utils'

interface CollectionEventCardProps {
  event: PublishedEvent
  onRemove: Function
  onClick: Function
}

export const CollectionEventCard = ({
  event,
  onRemove,
  onClick,
}: CollectionEventCardProps) => {
  const { imageUrl, name, notes, id } = event

  return (
    <button
      className={classNames(
        'rounded-2 shadow-2 block w-full border-none bg-transparent p-0 text-start outline-none hover:cursor-pointer',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group'
        /** @desc fallback styling if coverImg fails to load */
      )}
      role='link'
      tabIndex={0}
      onClick={() => onClick()}
    >
      <div className='of-hidden rounded-2 relative h-40'>
        <img
          alt=''
          className='z--1 absolute inset-0 w-full object-cover transition-transform duration-200 ease-in-out group-focus-within:scale-105 group-hover:scale-105'
          draggable={false}
          src={getEventImage(imageUrl) || defaultLocationImage}
        />
        <div className='flex justify-between pl-4 pr-2 pt-2.5'>
          <CollectedItemTip label='Event' />
          <StopPropagation>
            <CollectionDetailsMenu
              item={{ name, id, type: CollectionItemIdType.Event }}
              onRemove={onRemove}
            />
          </StopPropagation>
        </div>
      </div>
      <div className='space-y-3 p-4'>
        <h2 className='type-h5 line-clamp-2'>{name}</h2>
        <p className='type-body-2 line-clamp-2 min-h-8 md:min-h-9'>{notes}</p>
      </div>
    </button>
  )
}
