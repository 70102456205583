import type { MouseEventHandler } from 'react'
import type { Trip } from 'src/__generated__/graphql'
import { TripCardContent } from './TripCardContent'
import { TripCardHero } from './TripCardHero'
import { getDestinationImage } from '../../utils'

export const TripCard = ({
  className,
  userTripData,
  onClick,
  onDeleteClick,
  onEditClick,
}: {
  className?: string
  userTripData: Trip
  onClick?(): void
  onDeleteClick?(): void
  onEditClick?(): void
}) => {
  const { endDate, name, startDate, timeZone, tripPreference } =
    userTripData ?? {}
  const { country, state } = tripPreference?.addresses?.[0] ?? {}
  const imageUrl = getDestinationImage({
    country: country ?? '',
    state: state ?? '',
  })
  const tripName = name ?? ''

  const onCardClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    onClick?.()
  }

  return (
    <div
      className={`h-90 min-w-45 rd-2 shadow-1 relative bg-white ${className}`}
    >
      <TripCardHero imageUrl={imageUrl} name={tripName ?? ''} />
      <TripCardContent
        endDate={endDate}
        name={tripName}
        startDate={startDate}
        timezone={timeZone ?? ''}
        tripPreference={tripPreference}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
      <button
        className='rd-4 z-1 hover:shadow-2 absolute inset-0 block h-full w-full cursor-pointer overflow-hidden whitespace-nowrap border-none bg-transparent transition-shadow duration-200 ease-linear'
        onClick={onCardClick}
      >
        <span className='sr-only'>
          Click to open a modal with information about the {name} trip
        </span>
      </button>
    </div>
  )
}
