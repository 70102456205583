import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useParams, useSearchParams } from 'react-router-dom'
import { Geocoder } from 'src/common/components'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { TripSearchParams } from 'src/pages/trips/constants'
import {
  getTripEventsQuery,
  useCreateTripEventMutation,
  useGetGuidePlaceDetailsLazyQuery,
} from 'src/pages/trips/hooks'
import { pushTripEventCreateToDataLayer } from 'src/pages/trips/utils'
import { geocoderLocationBiasConfig } from 'src/pages/trips/utils/geocoderUtils'
import { convertGoogleDataToEventInput } from './addEventGeocoderUtils'

export const AddEventGeocoderInput = ({
  latitude,
  longitude,
  startDate,
  isHotelSearch = false,
  onSelect,
  dates,
  placeholder = 'Add a place',
  label = 'Add a place',
  setScrollToEventId,
}: {
  latitude?: string
  longitude?: string
  startDate?: string
  isHotelSearch?: boolean
  onSelect?(): void
  dates?: [Dayjs, Dayjs]
  placeholder?: string
  label?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
}) => {
  const [geocoder, setGeocoder] = useState(initialGeocoder)
  const { tripId } = useParams()
  const [searchParams] = useSearchParams()
  const dateTab = searchParams.get(TripSearchParams.dateTab)
  const [getPlaceDetails, { data, loading }] =
    useGetGuidePlaceDetailsLazyQuery()
  const [addTripEvent] = useCreateTripEventMutation(tripId)
  const { addSuccessSnack, addMailSnack, addErrorSnack } = useSnackbar()

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      setGeocoder(updatedGeocoder)
      getPlaceDetails({
        variables: {
          placeDetailsRequest: {
            placeId: updatedGeocoder?.placeId,
            isHotelSearch: isHotelSearch,
          },
        },
      })
    }
  }

  const addEventfromGeocoder = async () => {
    /** @todo check with BE to see if standardHotelId can be returned in getPlaceType. */
    const eventInput = convertGoogleDataToEventInput({
      tripId,
      placeDetails: data?.getPlaceDetails ?? {},
      startDate,
      dateTab: parseInt(dateTab),
      dates,
      isHotelSearch,
      productId: data?.getPlaceDetails?.standardHotelId?.toString() ?? null,
    })

    try {
      addMailSnack({ title: 'Adding to timeline...' })
      onSelect?.()
      const newEvent = await addTripEvent({
        variables: { eventInput },
        refetchQueries: [
          {
            query: getTripEventsQuery,
            variables: {
              tripId,
            },
          },
        ],
      })
      const {
        type,
        status,
        productId: itemId,
        name: itemName,
        startDate,
        endDate,
        insertedAt,
        trip,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        tripId,
        tripName,
        triggerVariant: 'AddEventGeocoderInput',
      })
      addSuccessSnack({ title: 'Added to timeline' })
      setGeocoder(initialGeocoder)
      if (setScrollToEventId && newEvent?.data?.createEvent?.id) {
        setScrollToEventId(newEvent.data.createEvent.id)
      }
    } catch {
      addErrorSnack({ title: 'Server error' })
    }
  }

  useEffect(() => {
    if (!loading && data && geocoder !== initialGeocoder) {
      addEventfromGeocoder()
    }
  }, [data, geocoder])

  const locationBias = geocoderLocationBiasConfig(latitude, longitude)

  return (
    <div className='w-full'>
      <Geocoder
        aria-label={label}
        config={locationBias}
        focusOnInput={!isMobile}
        geocoder={geocoder}
        placeholder={placeholder}
        onResult={onGeocoderChange}
      />
    </div>
  )
}
