import type { ContextType } from 'react'
import { useMemo, useRef } from 'react'
import { Chip } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { useSearchParams } from 'react-router-dom'
import { Scroller, ScrollerButton } from 'src/common/components'
import type { experienceTagType } from 'src/constants'
import { experienceTagsPrioritized } from 'src/constants'
import { getTripExploreTagsAsArray } from './tripDetailsExploreSearchExperiencesTagsUtils'
import { ExploreSearchParams } from '../../../constants'

type experienceTag = experienceTagType & { isSelected: boolean }

type ExploreExperienceTag = experienceTagType & {
  isSelected?: boolean
}

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

export const TripDetailsExploreSearchExperiencesTags = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const initialTagIds = searchParams.get(ExploreSearchParams.tagIds)
  const tags = useMemo<ExploreExperienceTag[]>(() => {
    const tagIds = getTripExploreTagsAsArray(initialTagIds ?? '')

    return experienceTagsPrioritized
      .reduce(
        (total, current) => {
          if (tagIds.includes(current.tagId)) {
            total[0].push({
              ...current,
              isSelected: true,
            })
          } else {
            total[1].push(current)
          }

          return total
        },
        [[], []] as [experienceTag[], experienceTagType[]]
      )
      .flat()
  }, [initialTagIds])

  const onClick = ({
    isSelected = false,
    tagId,
  }: Partial<ExploreExperienceTag>) => {
    const tagIds = getTripExploreTagsAsArray(initialTagIds ?? '')
    let updatedTagIds: number[] = []
    scrollToFirstTag()

    if (isSelected) {
      updatedTagIds = tagIds.filter(currentTagId => tagId !== currentTagId)
    } else {
      if (tagId) {
        updatedTagIds = [...tagIds, tagId]
      }
    }

    searchParams.delete(ExploreSearchParams.activeExploreId)
    searchParams.set(ExploreSearchParams.tagIds, JSON.stringify(updatedTagIds))
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const scrollToFirstTag = () => {
    /** @todo: understand if/why getItemElementByIndex requires a string as an index... */
    const firstItem = apiRef?.current?.getItemElementByIndex('0')
    if (firstItem) {
      apiRef?.current?.scrollToItem(firstItem)
    }
  }

  const constructedTag = ({
    isSelected,
    tagId,
    tagName,
  }: ExploreExperienceTag) => (
    <div key={tagId} className='ws-nowrap'>
      <Chip
        isSelected={isSelected}
        label={tagName}
        onClick={() =>
          onClick({
            isSelected,
            tagId,
          })
        }
      />
    </div>
  )

  return (
    <Scroller
      LeftArrow={<ScrollerButton icon='arrowBackIos' size='small' />}
      RightArrow={<ScrollerButton isNext icon='arrowForwardIos' size='small' />}
      apiRef={apiRef}
      scrollContainerClassName='gap-1 children-[:first-child]:pl-3 children-[:last-child]:pr-3'
    >
      {tags.map(tag => constructedTag(tag))}
    </Scroller>
  )
}
