import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash.isempty'
import { FooterContainer } from 'src/common/components/Footer/FooterContainer'
import { ApolloProvider } from 'src/config/apollo'
import { FeatureFlagsList, useLDClient } from 'src/config/feature-flags'
import { PageRoutes } from 'src/pages'
import { TopNav } from 'src/top-nav/TopNav'
import { auth } from './firebaseUtils'
import { useInitializeTagManager } from '../analytics/googleTagManagerIntegration'
import { anonymousUserKey } from '../feature-flags/launchDarklyConstants'

export const GenerateAccessToken = () => {
  const flags = useFlags()
  const haveFlagsLoaded = !isEmpty(flags)
  const client = useLDClient()
  const user = auth.currentUser
  const { displayName, email, isAnonymous = true, uid } = user ?? {}

  useInitializeTagManager(user)

  useEffect(() => {
    if (!isAnonymous) {
      client?.identify({
        kind: 'user',
        key: email,
        name: displayName,
        email,
        custom: { id: uid },
      })
    } else {
      client?.identify({ key: anonymousUserKey, anonymous: true })
    }
  }, [isAnonymous])

  if (!haveFlagsLoaded) return null

  return (
    <ApolloProvider>
      <TopNav />
      <main className='min-h-[calc(100vh-400px)]' id='main-content'>
        <PageRoutes />
      </main>
      <FooterContainer />
      <FeatureFlagsList />
    </ApolloProvider>
  )
}
