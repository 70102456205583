import { Carousel } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import { awayGameSchedule } from '../UtahJazzAwayGames/awayGameSchedule'
import { ScheduleCard } from '../UtahJazzAwayGames/components'

interface Game {
  game: string
  teamName: string
  colors: {
    primary: string
    secondary: string
  }
  city: string
  state: string
  date: string
  fullDate: string
  time: string
  latitude: string
  longitude: string
  arrival: string
  departure: string
}

interface ScheduleMonth {
  month: string
  games: Game[]
}

const today = new Date().toISOString().split('T')[0]

const filterUpcomingGames = (schedule: ScheduleMonth[]): ScheduleMonth[] => {
  return schedule
    .map(month => ({
      month: month.month,
      games: month.games.filter(game => game.arrival > today),
    }))
    .filter(month => month.games.length > 0)
}

const upcomingGames = filterUpcomingGames(awayGameSchedule)

export const UtahJazzRoadFan = () => {
  const deltaCenter =
    'https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/8b06f1cc-8101-11ef-9c51-0e13412df030'

  const seeFullAwayGameSchedule = '/utah-jazz/away-game-schedule'

  return (
    <div
      className='w-full bg-cover bg-center bg-no-repeat'
      style={{ backgroundImage: `url(${deltaCenter})` }}
    >
      <div className='backdrop-blur-5 bg-warm-grey/90 w-full py-12 backdrop-grayscale'>
        <PageLayoutContainer>
          <div className='grid grid-cols-1 place-content-center gap-4 md:grid-cols-2 lg:grid-cols-3'>
            <div className='place-content-center lg:col-span-3'>
              <p className='type-overline text-grey-700 text-center uppercase tracking-widest'>
                For The Ultimate Fan
              </p>
              <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo text-forest-dark m-0 my-4 text-balance text-center'>
                Go On The Road With The Jazz
              </h3>
              <div className='w-30 mx-a bg-valley mb-4 h-1 rounded-full md:h-1.5 lg:w-40'></div>
              <p className='text-subtitle-2 font-400 text-grey-800 max-w-650px mx-auto mt-8 block text-balance text-center'>
                Represent the Jazz at every away game. Check the schedule below
                to start planning.
              </p>
            </div>
            <div className='place-content-center lg:col-span-3'>
              <Carousel
                borderRadius={0}
                hideArrows={false}
                hideDots={true}
                isLight={false}
                keyBoardControl={false}
                size='medium'
                slidesPerViewOnDesktop={3}
                slidesPerViewOnMobile={1}
                slidesPerViewOnTablet={1}
              >
                {upcomingGames.map(({ games }) =>
                  games.map(
                    ({
                      game,
                      arrival,
                      city,
                      colors,
                      date,
                      departure,
                      fullDate,
                      latitude,
                      longitude,
                      state,
                      teamName,
                      time,
                    }) => (
                      <div key={game} className='px-4 py-6 md:px-2 lg:px-2'>
                        <ScheduleCard
                          arrival={arrival}
                          city={city}
                          colors={colors}
                          date={date}
                          departure={departure}
                          fullDate={fullDate}
                          latitude={latitude}
                          longitude={longitude}
                          state={state}
                          teamName={teamName}
                          time={time}
                        />
                      </div>
                    )
                  )
                )}
              </Carousel>
              <a
                className='rounded-2 c-forest font-700 text-4 bg-valley border-valleyDark/20 mx-a max-w-300px mt-4 block w-auto border-2 border-solid px-6 py-3 text-center font-sans no-underline transition-shadow hover:cursor-pointer hover:shadow-lg'
                href={seeFullAwayGameSchedule}
              >
                See Full Away Game Schedule
              </a>
            </div>
          </div>
        </PageLayoutContainer>
      </div>
    </div>
  )
}
