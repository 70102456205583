import { Helmet } from 'src/common/components'
import { BlackFridayCompany } from './BlackFridayCompany'
import { BlackFridayDestinations } from './BlackFridayDestinations'
import { BlackFridayHero } from './BlackFridayHero'

export const BlackFriday = () => (
  <>
    <Helmet
      canonicalUrl='https://www.travelpass.com/black-friday'
      pageName='Black Friday Travel Deals & Vacation Savings'
    />
    <BlackFridayHero />
    <div className='z-2'>
      <BlackFridayDestinations />
    </div>
    <BlackFridayCompany />
  </>
)
