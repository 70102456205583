import type { ReactNode } from 'react'

interface ExperiencesItineraryTimelineStepProps {
  content?: string
  subtitle?: string
  title: string | ReactNode
}

const ExperiencesItineraryTimelineStep = ({
  content,
  subtitle,
  title,
}: ExperiencesItineraryTimelineStepProps) => {
  const showContent = !!content
  const showSubtitle = !!subtitle
  const showTitle = !!title
  const showStep = showContent || showSubtitle || showTitle

  return (
    showStep && (
      <div className='flex flex-col gap-2'>
        {showTitle && (
          <p className='color-grey-800 font-500 text-body-1'>{title}</p>
        )}
        {showSubtitle && (
          <p className='color-grey-800 text-body-2'>{subtitle}</p>
        )}
        {showContent && <p className='color-grey-700 text-body-2'>{content}</p>}
      </div>
    )
  )
}

export { ExperiencesItineraryTimelineStep }
export type { ExperiencesItineraryTimelineStepProps }
