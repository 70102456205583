import { useState } from 'react'
import { Button, Illustration } from '@travelpass/design-system'
import { TripCreateModal } from 'src/pages/trips/components'

export const DashboardTripsEmpty = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const onCreateClose = () => setIsCreateModalOpen(false)

  const onCreateOpen = () => setIsCreateModalOpen(true)

  return (
    <>
      <div className='space-y-10'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='type-h3'>My Trips</h2>
          <Button
            aria-label='Create a trip'
            startIcon='add'
            onClick={onCreateOpen}
          >
            New Trip
          </Button>
        </div>
        <div className='bg-warm-grey p-x-11 p-t-18.5 p-b-11 rounded-3 b-1 b-solid b-canyon-light'>
          <div className='flex flex-row items-center gap-10'>
            <div className='space-y-5'>
              <div className='m-a block w-64 md:hidden'>
                <Illustration name='scooter' />
              </div>
              <h3 className='c-forest-light type-h3'>
                Plan Your Next Adventure
              </h3>
              <p className='type-body-1'>
                Don’t just dream about your next trip - plan it! Break down your
                travel day-by-day, adding activities and booking as you go.
              </p>
              <div className='m-a flex flex-row justify-center md:block'>
                <Button
                  aria-label='Create a trip'
                  size='small'
                  startIcon='add'
                  variant='text'
                  onClick={onCreateOpen}
                >
                  Create a Trip
                </Button>
              </div>
            </div>
            <div className='min-w-62 hidden md:block'>
              <Illustration name='scooter' />
            </div>
          </div>
        </div>
      </div>
      {isCreateModalOpen && <TripCreateModal onDismiss={onCreateClose} />}
    </>
  )
}
