import type { ReactNode } from 'react'
import { Skeleton } from '@travelpass/design-system'

interface GuideDraftEventLoadingProps {
  children: ReactNode
}

export const GuideDraftEventLoading = ({
  children,
}: GuideDraftEventLoadingProps) => (
  <div className='m-b-2 group flex flex-row items-center gap-2'>
    <div className='c-forest-dark h-6 w-6 min-w-6'>
      <Skeleton variant='rounded' />
    </div>
    <div className='grow'>{children}</div>
  </div>
)
