import { Carousel, Link } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { PageLayoutContainer } from 'src/common/components'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocationExplore = ({
  destination,
}: DestinationLocationProps) => {
  const { address, explore } = destination ?? {}
  const { city } = address ?? {}
  const title = `Explore ${city}: Things to do`

  return !isEmpty(explore) ? (
    <div className='bg-forest-light p-y-10 block w-full'>
      <PageLayoutContainer>
        <div className='lg:flex-items-start block lg:flex'>
          <div className='lg:w-20% lg:m-r-5% block w-full'>
            <div className='type-h1 p-b-3 lg:p-t-14 lg:p-b-9 color-white'>
              Places to explore
            </div>
            <h2 className='type-subtitle-2 lg:line-height-6 color-grey-600'>
              {title}
            </h2>
          </div>
          <div className='lg:w-75% block w-full'>
            <Carousel
              isLight={true}
              slidesPerViewOnDesktop={2}
              slidesPerViewOnMobile={1}
              slidesPerViewOnTablet={2}
            >
              {explore?.map(explore => (
                <div
                  key={explore.activity}
                  className='position-relative w-85% md:w-97% lg:w-97% m-x-a m-y-10 border-rounded-8 block h-auto overflow-hidden bg-white p-3'
                >
                  <div className='w-100% p-x-7 m-y-3 block'>
                    <h3 className='type-h3 p-t-2 p-b-4 color-forest-dark'>
                      {explore.activity}
                    </h3>
                    {explore.summary.map(item => (
                      <p
                        key={item}
                        className='type-body-1 color-grey-700 p-b-3'
                      >
                        {item}
                      </p>
                    ))}
                    {explore.links && (
                      <div className='m-t-1'>
                        {explore.links.map(link => (
                          <div key={link.name} className='m-b-3'>
                            <Link href={link.href} label={link.name} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  ) : null
}
