import { Carousel } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { e } from 'unocss'
import type { ExperienceProduct } from 'src/__generated__/graphql'

export const SummaryImageCarousel = ({
  images = [],
  title,
}: {
  images: ExperienceProduct['images']['size240x160']
  title: string
}) => {
  const imgs = images.filter(Boolean)

  return (
    <Carousel isLight hideDots={isMobile || imgs.length <= 1}>
      {imgs.map((image, index) => {
        const alt = `${title} hero ${index + 1}`
        return (
          <img
            key={image}
            alt={alt}
            className='bg-grey-400 aspect-video h-full w-full animate-pulse object-cover'
            draggable='false'
            loading='lazy'
            src={image}
            onLoad={event => {
              event.currentTarget.classList.remove(
                'animate-pulse',
                'bg-grey-400'
              )
            }}
          />
        )
      })}
    </Carousel>
  )
}
