import { useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  TextArea,
  Tile,
  today,
  useSnackbar,
} from '@travelpass/design-system'
import load from 'load-script'
import isEmpty from 'lodash.isempty'
import { formatDate } from 'src/utils'
import type { BookingConfirmationNode } from './bookingConfirmationTypes'
import { useCreateAnonymousWebsiteReviewMutation } from './useCreateAnonymousWebsiteReviewMutation'
import { useFindWebsiteReviewsQuery } from './useFindWebsiteReviewsQuery'

interface BookingConfirmationSummarySurveyModalProps {
  bookingConfirmationData: BookingConfirmationNode
  onDismiss(): void
}

export const BookingConfirmationSummarySurveyModal = ({
  bookingConfirmationData,
  onDismiss,
}: BookingConfirmationSummarySurveyModalProps) => {
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [comment, setComment] = useState('')
  const [hasInputError, setHasInputError] = useState(false)
  const [npsValue, setNpsValue] = useState(0)
  const { id, email, externalConfirmationId, name, customerPhone } =
    bookingConfirmationData ?? {}
  const {
    isFindWebsiteReviewsLoading,
    hasFindWebsiteReviewsError,
    findWebsiteReviewsData,
  } = useFindWebsiteReviewsQuery(email, id)
  const [
    createAnonymousWebsiteReview,
    { loading: isCreateAnyonymousWebsiteReviewLoading },
  ] = useCreateAnonymousWebsiteReviewMutation()

  const nameToArray = name.split(' ')
  const firstName = nameToArray[0]
  const lastName = nameToArray[nameToArray.length - 1]

  const onClick = async () => {
    if (npsValue === 0) {
      setHasInputError(true)
      return
    }

    try {
      await createAnonymousWebsiteReview({
        variables: {
          createWebsiteReviewInput: {
            comment,
            rate: npsValue,
            bookingId: id,
            reviewerEmail: email,
          },
        },
      })

      if (npsValue > 6) {
        load(
          'https://www.sitejabber.com/js/v2/6408cf37cdf20/widgets.js',
          () => {
            window['STJR'].setConfig({
              id: 'InstantFeedbackWidget',
              url: 'travelpass.com',
              language: 'en',
              user: {
                first_name: firstName,
                last_name: lastName,
                email,
                phone: customerPhone,
              },
              order_date: formatDate(today, 'MM/DD/YYYY'),
              order_id: externalConfirmationId,
            })
            onDismiss()
          }
        )
        addSuccessSnack({
          title: 'Thank you for your feedback!',
        })
      } else {
        addSuccessSnack({
          title: 'Thank you for your feedback!',
        })
        onDismiss()
      }
    } catch (error) {
      console.error(error)
      addErrorSnack({
        title: 'Something went wrong. Please try again.',
      })
    }
  }

  return (
    !isFindWebsiteReviewsLoading &&
    !hasFindWebsiteReviewsError &&
    isEmpty(findWebsiteReviewsData) && (
      <Modal
        size='medium'
        title='Thanks For Booking Your Next Adventure With Us!'
        onDismiss={onDismiss}
      >
        <div className='space-y-5'>
          <p className='type-body-1'>
            We would love to learn more about your experience. On a scale of
            1-10, how likely are you to refer Travelpass to your friends and
            family?
          </p>
          <div>
            <div className='flex flex-wrap gap-2 text-center md:grid md:grid-cols-10'>
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <Tile
                    key={index}
                    pressed={index + 1 === npsValue}
                    size='small'
                    onClick={() => setNpsValue(index + 1)}
                  >
                    <div className='flex w-full items-center justify-center'>
                      {index + 1}
                    </div>
                  </Tile>
                ))}
            </div>
            {hasInputError && (
              <p className='type-subtext color-red m-1 pl-3.5 pt-1'>
                Rating is required
              </p>
            )}
          </div>
          {!!npsValue && npsValue < 7 && (
            <>
              <p className='type-body-1'>
                Thank you for sharing your experience with us. How would you
                recommend we can improve?
              </p>
              <TextArea
                placeholder='Additional comments...'
                value={comment}
                onInput={event => setComment(event.currentTarget.value)}
              />
            </>
          )}
        </div>
        <ModalActions>
          <Button
            disabled={isCreateAnyonymousWebsiteReviewLoading}
            label='Apply'
            type='button'
            onClick={onClick}
          />
          <Button label='Cancel' variant='outlined' onClick={onDismiss} />
        </ModalActions>
      </Modal>
    )
  )
}
