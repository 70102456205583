const ASSETS_PATH =
  'https://static.travelpass.com/assets/homev2-page/travelers/'

const travelersList = [
  {
    title: 'Grace Hennessy',
    description: 'CX Analyst',
    desktop: 'grace-hennesy.webp',
    mobile: 'grace-hennesy-mobile.webp',
  },
  {
    title: 'Lisa King',
    description: 'Senior Design Lead',
    desktop: 'lisa-king.webp',
    mobile: 'lisa-king-mobile.webp',
  },
  {
    title: 'Chiara Lane',
    description: 'Product Manager',
    desktop: 'chiara-lane.webp',
    mobile: 'chiara-lane-mobile.webp',
  },
  {
    title: 'Madeline Watts',
    description: 'Marketing Designer',
    desktop: 'madeline-watts.webp',
    mobile: 'madeline-watts-mobile.webp',
  },
  {
    title: 'Alexis Cortez',
    description: 'Social Media Manager',
    desktop: 'alexis-cortez.webp',
    mobile: 'alexis-cortez-mobile.webp',
  },
  {
    title: 'Alan Feulerlein',
    description: 'CTO',
    desktop: 'alan-feuerlein.webp',
    mobile: 'alan-feuerlein-mobile.webp',
  },
  {
    title: 'Austin Jones',
    description: 'Sales Manager',
    desktop: 'austin-jones.webp',
    mobile: 'austin-jones-mobile.webp',
  },
  {
    title: 'Claire Thomas',
    description: 'Senior Product Manager',
    desktop: 'claire-thomas.webp',
    mobile: 'claire-thomas-mobile.webp',
  },
  {
    title: 'Sydni Cooper',
    description: 'Data Engineer',
    desktop: 'sydni-cooper.webp',
    mobile: 'sydni-cooper-mobile.webp',
  },
]

export { ASSETS_PATH, travelersList }
