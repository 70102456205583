import { useEffect, useLayoutEffect } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  Icon,
  IconButton,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import {
  SEARCH_BAR_OPTIONS,
  SEARCH_BAR_STATES,
  TRAVELERS_RESULTS_QUERY_PREFIX,
} from 'src/top-nav/components/SearchBar/constants'
import { useSearchBarState } from 'src/top-nav/components/SearchBar/useSearchBarState'
import type { ExploreLocation } from '../exploreTypes'

interface TravelersSearchBarProps {
  onSelection?: (selection: ExploreLocation) => void
  hideSlotBefore?: boolean
  hideSearchButton?: boolean
  clearOnSelection?: boolean
  placeholder?: string
  className?: string
}

export const TravelersSearchBar = ({
  hideSearchButton = false,
  placeholder = 'Search',
  hideSlotBefore = false,
  className,
}: TravelersSearchBarProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultValue = searchParams.get('q')
  const spTags = searchParams.get('tags')
  const enableTravelersSearch = useFlag('enableTravelersSearch')

  const {
    isMobileScreen,
    autocompleteParentRef,
    setAsset,
    ready,
    autocompleteRef,
    value,
    setShowSearchTerm,
    handleSearchTermEnter,
    handleOptionSelection,
    handleButtonClick,
    travelersOptions,
    setValue,
    onClear,
    showTravelersOptions,
    errorMessage,
  } = useSearchBarState({
    closeOnClear: false,
    openDefaultState: SEARCH_BAR_STATES.open,
    clearOnSearch: false,
  })

  const searchTerm = value?.trim()

  useLayoutEffect(() => {
    setAsset(SEARCH_BAR_OPTIONS.travelers)
  }, [])

  useEffect(() => {
    if (defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const showErrorMessage = !showTravelersOptions && !!errorMessage.length

  const handleSearchButtonPress = () => {
    handleButtonClick()
    setTimeout(() => {
      autocompleteRef.current.blur()
    }, 0)
  }

  const deleteQSp = () =>
    setSearchParams(sp => {
      sp.delete('q')
      return sp
    })

  const customClear = () => {
    if (spTags?.length) {
      deleteQSp()
    }
    onClear()
  }

  const ClearButton = () => (
    <span
      className={classNames(
        'mr--3',
        !value?.trim() && 'pointer-events-none opacity-0'
      )}
    >
      <IconButton icon='clear' onClick={customClear} />
    </span>
  )

  const SlotBefore = () => {
    if (hideSlotBefore) return null
    return (
      <span className='color-new-forest flex'>
        <Icon name='search' />
      </span>
    )
  }

  return (
    <div
      className={classNames(
        'mx-auto flex w-full items-center justify-center [&>div:first-of-type]:w-full',
        className
      )}
      ref={autocompleteParentRef}
    >
      <Autocomplete
        autoExpand
        fullWidth
        className='rounded-r-none! w-full'
        id='guides-autocomplete-input'
        isDisabled={!ready}
        placeholder={placeholder}
        ref={autocompleteRef}
        slotAfter={<ClearButton />}
        slotBefore={<SlotBefore />}
        value={value}
        onChange={e => {
          setShowSearchTerm(true)
          setValue(e.target.value)
        }}
        onFocus={
          isMobileScreen
            ? () => {
                autocompleteParentRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            : null
        }
        onKeyDown={handleSearchTermEnter}
        onOptionSelect={handleOptionSelection}
      >
        {showTravelersOptions && (
          <div className='max-h-sm overflow-y-auto'>
            {travelersOptions?.map(traveler => (
              <AutocompleteOption
                key={traveler?.accountHandle}
                className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-4 bg-white px-4 py-2 text-left outline-none'
                value={traveler?.accountHandle}
              >
                <Avatar
                  border='none'
                  size='sm'
                  src={traveler?.profileImageUrl}
                />
                <span>
                  <span className='c-forest font-medium'>
                    {traveler?.displayName}
                  </span>
                  <br />
                  <span className='type-subtitle-2 text-xs'>
                    @{traveler?.accountHandle}
                  </span>
                </span>
              </AutocompleteOption>
            ))}
          </div>
        )}
        {showErrorMessage && (
          <span className='type-subtitle-2 mx-auto block px-4 text-sm'>
            {errorMessage}
          </span>
        )}
        {enableTravelersSearch && showTravelersOptions && searchTerm && (
          <AutocompleteOption
            className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey border-t-zinc-3 border-t-solid mt-2 flex w-full cursor-pointer flex-row items-center gap-2 border-t px-4 py-4 text-left outline-none'
            value={TRAVELERS_RESULTS_QUERY_PREFIX + searchTerm}
          >
            <Icon name='search' />
            <span className='c-valleyDark mt1'></span>
            Search for
            <span className='font500'>&quot;{searchTerm}&quot;</span>
          </AutocompleteOption>
        )}
      </Autocomplete>
      {!hideSearchButton && (
        <div className='-ml-2'>
          <IconButton
            color='valley'
            icon='search'
            size='large'
            onClick={handleSearchButtonPress}
          />
        </div>
      )}
    </div>
  )
}
