import classNames from 'classnames'

export const HotelFooter = () => {
  return (
    <div
      className={classNames(
        'lg:mb--75 h-75 lg:h-150 w-full lg:[clip-path:polygon(0_0,100%_0,100%_60%,0_60%)]',
        'bg-[position:bottom] bg-no-repeat sm:bg-[length:100vw_auto] lg:bg-auto lg:bg-cover',
        'bg-[url(https://static.travelpass.com/assets/homev2-page/about-mobile.webp)]',
        'lg:bg-[url(https://static.travelpass.com/assets/homev2-page/about.webp)]'
      )}
    />
  )
}
