import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { ResultsCardReviews } from 'src/common/components'
import {
  DrawerType,
  TripSearchParams,
  dateTemplate,
  timeTemplate,
} from 'src/pages/trips/constants'
import { getTripEventsQuery } from 'src/pages/trips/hooks'
import { pushTripEventCreateToDataLayer } from 'src/pages/trips/utils'
import { formatDate } from 'src/utils'
import { useCreateAddStayEvent } from './useCreateAddStayEvent'
import { useGetHotelsForStayEvent } from './useGetHotelsForStayEvent'
import { useTripDetailsMapPoints } from '../../../../components/TripDetailsMap/TripDetailsMapPointsProvider/useTripDetailsMapPoints'
import { EventCard } from '../../EventCard/EventCard'
import { HotelCardPricing } from '../../EventCard/HotelCardPricing'
import { SkeletonCard } from '../../EventCard/SkeletonCard'
import { AddEventError } from '../AddEventError'
import { EventScrollBox } from '../EventScrollBox'

export const RecommendedStays = ({
  latitude,
  longitude,
  departure,
  endDateTime,
  hoveredEventId,
  selectedEventId,
  setScrollToEventId,
  arrival,
  startDateTime,
  timeZone,
  tracker,
  tripId,
  onComplete,
  onHoverCard,
}: {
  latitude: string
  longitude: string
  departure: string
  endDateTime: string
  hoveredEventId?: string
  selectedEventId?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  startDateTime: string
  arrival: string
  timeZone?: string
  tracker: string
  tripId: string
  onComplete: () => void
  onHoverCard: (eventId: string) => void
}) => {
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setPoints } = useTripDetailsMapPoints()
  const { hotels, loading, error } = useGetHotelsForStayEvent({
    latitude,
    longitude,
    departure,
    arrival,
    tracker,
  })

  useEffect(() => {
    if (!loading) {
      setPoints(hotels)
    }
  }, [JSON.stringify(hotels), loading])

  const { createAddStayEvent } = useCreateAddStayEvent()

  const onSelect = async (hotelId: string) => {
    const hotel = hotels.find(hotel => hotel?.id === hotelId)
    const isSameDate = dayjs(departure).isSame(arrival, 'day')
    const updatedEndDate = dayjs(
      `${formatDate(dayjs(departure))}${endDateTime}`,
      `${dateTemplate}${timeTemplate}`
    )
      .utc()
      .format()
    const updatedStartDate = dayjs(
      `${formatDate(dayjs(arrival))}${startDateTime}`,
      `${dateTemplate}${timeTemplate}`
    )
      .utc()
      .format()

    try {
      const newEvent = await createAddStayEvent({
        variables: {
          eventInput: {
            addresses: [
              {
                lat: hotel?.lat?.toString(),
                long: hotel?.lng?.toString(),
              },
            ],
            description: hotel?.description,
            endDate: isSameDate ? updatedStartDate : updatedEndDate,
            externalTypeId: hotel?.id,
            name: hotel?.title,
            productId: hotel?.id,
            startDate: updatedStartDate,
            tripId,
            type: EventType.Stay,
            status: EventStatus.NotBooked,
            useEventTime: false,
          },
        },
        refetchQueries: [
          {
            query: getTripEventsQuery,
            variables: {
              tripId,
            },
          },
        ],
      })
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant: 'RecommendedStays',
        tripId,
        tripName,
      })
      searchParams.delete(TripSearchParams.activeExploreId)
      searchParams.delete(TripSearchParams.eventId)
      searchParams.delete(TripSearchParams.eventType)
      setSearchParams(searchParams, {
        replace: true,
      })
      addSuccessSnack({
        title: 'Hotel successfully added to trip',
      })

      if (setScrollToEventId && newEvent?.data?.createEvent?.id) {
        setScrollToEventId(newEvent.data.createEvent.id)
      }

      onComplete()
    } catch (error) {
      console.error(error)
      addErrorSnack({
        title: 'Something went wrong. Try again later.',
      })
    }
  }

  const onCardClick = (eventId: string) => {
    searchParams.set(TripSearchParams.eventId, eventId)
    searchParams.set(TripSearchParams.eventType, DrawerType.InternalHotel)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  if (error) {
    return <AddEventError />
  }

  if (loading) {
    return (
      <EventScrollBox>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </EventScrollBox>
    )
  }
  return (
    <EventScrollBox>
      {hotels?.map(hotel => {
        return (
          <div key={hotel?.id} className='w-324px md:w-543px'>
            <EventCard
              id={hotel?.id}
              imageUrl={hotel?.imageUrl}
              isActive={selectedEventId === hotel?.id}
              isHovered={hoveredEventId === hotel?.id}
              title={hotel?.title}
              variant='recommended'
              onClick={hotelId => onCardClick(hotelId)}
              onHover={onHoverCard}
              onSelect={onSelect}
            >
              <HotelCardPricing averageNightlyRate={hotel?.nightlyAverage} />
              <ResultsCardReviews
                average={hotel?.customerReviewScore}
                total={hotel?.totalReviews}
                type={EventType.Stay}
              />
            </EventCard>
          </div>
        )
      })}
    </EventScrollBox>
  )
}
