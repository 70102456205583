import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateNote = gql(`
  mutation UpdateNoteMutationInTripOverview($noteInput: UpdateNoteInput!) {
    updateNote(noteInput: $noteInput) {
      id
      trip {
        id
        notes {
          id
          body
          title
          type
          openGraph {
            ogImage
            ogTitle
            ogUrl
          }
          updatedAt
        }
      }
    }
  }
`)

export const useUpdateNoteTripOverviewMutation = () => useMutation(updateNote)
