import { Divider, Skeleton } from '@travelpass/design-system'
import { HotelResultsFeaturedItemsContainer } from './HotelResultsFeaturedItemsContainer'
import { HotelResultsListHeader } from '../HotelResultsList'
import {
  HotelResultsCardFeaturedLoading,
  HotelResultsCardLoading,
} from '../common'

export const HotelResultsFeaturedLoading = () => (
  <div className='lg:space-y-1'>
    <HotelResultsListHeader>
      <div className='w-41 h-4 md:h-5'>
        <Skeleton />
      </div>
    </HotelResultsListHeader>
    <div className='hidden md:block'>
      <HotelResultsFeaturedItemsContainer length={3}>
        <HotelResultsCardFeaturedLoading />
        <HotelResultsCardFeaturedLoading />
        <HotelResultsCardFeaturedLoading />
      </HotelResultsFeaturedItemsContainer>
    </div>
    <div className='display-inherit md:hidden'>
      <HotelResultsFeaturedItemsContainer length={3}>
        <HotelResultsCardLoading />
        <HotelResultsCardLoading />
        <HotelResultsCardLoading />
      </HotelResultsFeaturedItemsContainer>
    </div>
    <div className='hidden pt-2 lg:block'>
      <Divider />
    </div>
  </div>
)
