import { useForm, FormProvider } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { EventStatus } from 'src/__generated__/graphql'
import { TripSearchParams } from 'src/pages/trips/constants'
import {
  StayBottom,
  StayLoading,
  StayTop,
  StayMiddle,
  StayRoom,
  StayActions,
  StayError,
} from './components'
import { StayProduct } from './components/StayProduct'
import { useStayData } from './hooks'
import { DrawerEdit, Hero } from '../components'
import type { DrawerEditKeys } from '../components/DrawerEdit/drawerEditTypes'

export const Stay = () => {
  const methods = useForm()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const tripId = params?.tripId ?? null
  const eventId = searchParams.get(TripSearchParams.eventId) ?? null
  const { data, tripData, loading, error } = useStayData({ eventId, tripId })

  const isBooked = !loading && data?.status === EventStatus.Booked
  const isInternal = !!data?.productId
  const internalBooked = isBooked && isInternal
  const externalOrInternalUnbooked = !isBooked || !isInternal

  const editableFields: DrawerEditKeys[] = internalBooked
    ? ['customBooked', 'notes']
    : ['customBooked', 'dates', 'times', 'notes']

  if (loading) {
    return <StayLoading />
  }
  if (error) {
    return <StayError />
  }
  return (
    <FormProvider {...methods}>
      <section className='relative h-full'>
        <Hero hideArrows={false} images={data?.images} name={data?.name} />
        <StayTop data={data} />
        {internalBooked && <StayMiddle data={data} />}
        {externalOrInternalUnbooked && <div className='h-3' />}
        <DrawerEdit
          data={data}
          tripData={tripData}
          visibleFields={editableFields}
        />
        {internalBooked && data?.bookingRoom && <StayRoom data={data} />}
        {externalOrInternalUnbooked && <StayProduct data={data} />}
        <StayBottom data={data} />
        <StayActions data={data} />
      </section>
    </FormProvider>
  )
}
