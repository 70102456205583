import { GuideHeaderOldContentLoading } from './GuideHeaderOldContentLoading'
import { GuideHeaderOldSummaryLoading } from './GuideHeaderOldSummaryLoading'
import { GuideHeaderOldTagsLoading } from './GuideHeaderOldTagsLoading'

interface GuideHeaderOldLoadingProps {
  isEdit: boolean
}

export const GuideHeaderOldLoading = ({
  isEdit,
}: GuideHeaderOldLoadingProps) => {
  if (isEdit) return <GuideHeaderOldContentLoading isEdit={true} />

  return (
    <div className='p-y-6 space-y-5 md:space-y-6'>
      <GuideHeaderOldContentLoading isEdit={false} />
      <GuideHeaderOldTagsLoading />
      <GuideHeaderOldSummaryLoading />
    </div>
  )
}
