import { Button } from '@travelpass/design-system'
import cloneDeep from 'lodash.clonedeep'
import type { FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { Trip } from 'src/__generated__/graphql'
import { useUpdateEvent } from 'src/pages/trips/hooks'
import { drawerEditDefaultValues } from '../../components/DrawerEdit/drawerEditConstants'
import type { DrawerEditValues } from '../../components/DrawerEdit/drawerEditTypes'
import {
  createDrawerEditDefaultValues,
  prepDrawerEditForEventUpdate,
} from '../../components/DrawerEdit/drawerEditUtils'
import type { DrawerEvent } from '../../constants'
import { useDeleteDrawerEvent } from '../../hooks'

export const MiscellaneousActions = ({
  data,
  tripData,
  tripId,
}: {
  data: DrawerEvent | null
  tripData: Trip | null
  tripId: string | null
}) => {
  const { handleSubmit } = useFormContext()
  const { deleteDrawerEvent } = useDeleteDrawerEvent()
  const { updateEvent } = useUpdateEvent({
    tripId: tripData?.id || tripId || 'Error finding tripId',
    triggerVariant: 'TripMiscellaneousDrawer SaveButton',
  })

  const onDelete = () => {
    deleteDrawerEvent({
      eventId: data?.id,
      triggerVariant: 'TripMiscellaneousDrawer DeleteButton',
    })
  }

  const onSave = (formValues: FieldValues) => {
    const defaultValues = createDrawerEditDefaultValues(data)
    const drawerFields: DrawerEditValues = cloneDeep(defaultValues)
    Object.keys(drawerFields).forEach(key => {
      const defaultValue = drawerEditDefaultValues[key]
      const formValue = formValues?.[key] ?? null
      drawerFields[key] = formValue ?? defaultValue
    })

    if (data?.id) {
      const properTypes = prepDrawerEditForEventUpdate({
        data,
        formValues: drawerFields,
        modifibleFields: [
          'customBooked',
          'dates',
          'endLocation',
          'name',
          'notes',
          'startLocation',
          'times',
        ],
      })
      updateEvent(properTypes)
    }
  }

  return (
    <div className='shadow-1 border-grey300 border-t-1 absolute sticky bottom-0 left-0 flex w-full flex-row items-center justify-center gap-4 border-0 border-solid bg-white px-8 py-6'>
      <Button
        fullWidth
        aria-label='Delete event'
        startIcon='deleteOutline'
        variant='outlined'
        onClick={onDelete}
      >
        Delete
      </Button>
      <Button
        fullWidth
        aria-label='Update event'
        onClick={handleSubmit(onSave)}
      >
        Save
      </Button>
    </div>
  )
}
