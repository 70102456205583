import { ExperiencesItineraryTimelineHopOnHopOff } from './ExperiencesItineraryTimelineHopOnHopOff'
import { ExperiencesItineraryTimelineStandard } from './ExperiencesItineraryTimelineStandard'
import type { ExperiencesItineraryTimelineProps } from './types'
import { checkItineraryTimeline } from '../utils'

const ExperiencesItineraryTimeline = ({
  itinerary,
  size = 'medium',
}: ExperiencesItineraryTimelineProps) => {
  const { itineraryType } = itinerary ?? {}
  if (!checkItineraryTimeline(itinerary)) return

  if (itineraryType === 'HOP_ON_HOP_OFF')
    return (
      <ExperiencesItineraryTimelineHopOnHopOff
        itinerary={itinerary}
        size={size}
      />
    )

  if (itineraryType === 'STANDARD')
    return (
      <ExperiencesItineraryTimelineStandard itinerary={itinerary} size={size} />
    )

  return
}

export { ExperiencesItineraryTimeline }
