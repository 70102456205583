import { useEffect, useState } from 'react'
import { today } from '@travelpass/design-system'
import classNames from 'classnames'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { useTripDetailsMapPoints } from 'src/pages/trips/components'
import { getDateFromUTC } from 'src/utils'
import { OverviewActions } from './OverviewActions'
import { OverviewAtAGlance } from './OverviewAtAGlance'
import { OverviewLoading } from './OverviewLoading'
import { OverviewNoteFormDrawer } from './OverviewNoteForm'
import { OverviewNotes } from './OverviewNotes'
import { OverviewOnboarding } from './OverviewOnboarding'
import { OverviewWeather } from './OverviewWeather'
import {
  TripContainer,
  TripContentWrapper,
  TripMapWrapper,
  TripNav,
  TripTopNav,
} from '../../components'
import { TripDetailsMap } from '../../components/TripDetailsMap'
import { TripDrawer } from '../../components/TripDrawer'
import { getTripEventMarkers } from '../../utils'
import { getAnchorLocation } from '../../utils/mapUtils'
import { useGetTripOverviewQuery } from '../hooks'

export const Overview = () => {
  const { tripId } = useParams()
  const { isLoading, tripDetailsData, tripEventsData, tripOverviewData } =
    useGetTripOverviewQuery(tripId)
  const [activeNoteId, setActiveNoteId] = useState<string>('')
  const [hoveredEventId, setHoverEventId] = useState<string | undefined>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const { setPoints } = useTripDetailsMapPoints()
  const {
    name,
    endDate,
    guides,
    numberOfDays,
    startDate,
    timeZone,
    tripPreference,
  } = tripDetailsData ?? {}
  const {
    dailyEventBreakdown,
    eventFlags,
    numberOfEvents,
    numberOfStayEvents,
  } = tripEventsData ?? {}
  const { notes } = tripOverviewData ?? {}
  const anchorLocation = getAnchorLocation(tripPreference?.addresses)
  const todayWithTimezone = getDateFromUTC(today.toString(), timeZone)
  const endDateWithTimezone = getDateFromUTC(endDate, timeZone)
  const isTripPast = endDateWithTimezone.isBefore(todayWithTimezone, 'day')
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'

  useEffect(() => {
    setPoints(
      getTripEventMarkers({
        activeTabIndex: 0,
        scheduled: dailyEventBreakdown?.scheduled,
        startDate,
        timeZone,
      })
    )
  }, [dailyEventBreakdown?.scheduled, startDate, timeZone])

  const onActiveNoteIdChange = (updatedActiveNoteId: string) => {
    setActiveNoteId(updatedActiveNoteId)
  }

  const onClose = () => {
    setIsDrawerOpen(false)
    onActiveNoteIdChange('')
  }

  const onHoveredEventIdChange = (updatedHoveredId: string) =>
    setHoverEventId(updatedHoveredId)

  const onOpen = () => setIsDrawerOpen(true)

  if (isLoading && !tripOverviewData) return <OverviewLoading />

  return (
    <>
      <TripTopNav tripDetailsData={tripDetailsData} />
      <TripContainer>
        {!mobileMapView && tripId && (
          <TripContentWrapper>
            <TripNav name={name} />
            <div className='p-b-22 md:p-b-0 space-y-6 md:space-y-10'>
              <OverviewAtAGlance
                numberOfDays={numberOfDays}
                numberOfEvents={numberOfEvents}
                numberOfStayEvents={numberOfStayEvents}
              />
              {!isTripPast && (
                <div className='flex flex-col justify-center gap-6 md:flex-row md:gap-2'>
                  <div className='md:grow'>
                    <OverviewOnboarding
                      eventFlags={eventFlags}
                      guides={guides}
                      startDate={startDate}
                    />
                  </div>
                  <div>
                    <OverviewWeather endDate={endDate} startDate={startDate} />
                  </div>
                </div>
              )}
              <OverviewNotes
                activeNoteId={activeNoteId}
                notes={notes}
                tripId={tripId}
                tripName={name}
                onActiveNoteIdChange={onActiveNoteIdChange}
                onOpen={onOpen}
              />
            </div>
          </TripContentWrapper>
        )}
        <TripDrawer isOpen={isDrawerOpen} onClose={onClose}>
          <OverviewNoteFormDrawer
            activeNoteId={activeNoteId}
            notes={notes}
            tripName={name}
            onActiveNoteIdChange={onActiveNoteIdChange}
            onClose={onClose}
          />
        </TripDrawer>
        {anchorLocation && (
          <div
            className={classNames('grow lg:block', {
              hidden: !mobileMapView,
            })}
          >
            <TripMapWrapper isDrawerOpen={isDrawerOpen}>
              <TripDetailsMap
                anchorLocation={anchorLocation}
                hoveredEventId={hoveredEventId}
                isAddingEvent={false}
                selectedEventId=''
                setSelectedEventId={() => {}}
                onHoveredEventIdChange={onHoveredEventIdChange}
              />
            </TripMapWrapper>
          </div>
        )}
        <div
          className={classNames('block lg:hidden', {
            hidden: mobileMapView,
          })}
        >
          <OverviewActions onOpen={onOpen} />
        </div>
      </TripContainer>
    </>
  )
}
