import type { PaxMix } from 'src/__generated__/graphql'

const preFormatter = (ageBand: string, multiple: boolean) => {
  let formatted = ''
  if (ageBand === 'ADULT') formatted = 'Adult'
  if (ageBand === 'CHILD') formatted = 'Child'
  if (ageBand === 'INFANT') formatted = 'Infant'
  if (ageBand === 'SENIOR') formatted = 'Senior'
  if (ageBand === 'YOUTH') formatted = 'Youth'
  if (ageBand === 'TRAVELER') formatted = 'Traveler'
  if (multiple) {
    ageBand === 'CHILD' ? (formatted = 'Children') : (formatted += 's')
    return formatted
  }
  return formatted
}

export const paxMixFormatter = (paxMix: PaxMix[]): string => {
  return paxMix
    ?.map(mix => {
      const formatted = preFormatter(mix.ageBand, mix.numberOfTravelers > 1)
      return `${mix.numberOfTravelers} ${formatted}`
    })
    ?.join(', ')
}
