import { Helmet } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  UtahJazzBook,
  UtahJazzGuides,
  UtahJazzHeader,
  UtahJazzSignUp,
} from './components'

export const UtahJazz = () => {
  return (
    <>
      <Helmet canonicalUrl={canonicalUrl} pageName={pageName} />
      <UtahJazzHeader />
      <UtahJazzGuides />
      <UtahJazzBook />
      <UtahJazzSignUp />
    </>
  )
}

const canonicalUrl = baseUrl + window.location.pathname
const pageName = 'Travelpass & The Utah Jazz | Welcome, Jazz Fans!'
