import { useState } from 'react'
import { Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link, useSearchParams } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { TravelpassLogo } from 'src/common/components'
import { dashboardPath } from 'src/constants'
import { GuideDraftMenuOld } from 'src/pages/guides/lists-old/common'
import { GuideDraftShareOld } from 'src/pages/guides/lists-old/common/GuideDraftShareOld'
import {
  useGuideMetricLike,
  useGuideMetricSave,
  useGuideMetricShare,
} from '../..'
import type { GuideDraftDataOld } from '../../../lists-old'
import { GuideSearchParam } from '../../guideConstants'
import type { GuideData } from '../../types'
import { GuideBadge } from '../GuideBadge'

interface GuideStickyTopNavOldProps {
  guideData?: GuideData
  guideDraftData?: GuideDraftDataOld
  isVisible: boolean
  selectedId: string
}

/** @todo we need GuideStickyTopNavOld for GuideDraftData */
export const GuideStickyTopNavOld = ({
  guideData,
  guideDraftData,
  isVisible,
  selectedId,
}: GuideStickyTopNavOldProps) => {
  const { onGuideLike } = useGuideMetricLike(guideData?.id)
  const onGuideSave = useGuideMetricSave({
    id: guideData?.id,
    name: guideData?.name,
  })
  const onGuideShare = useGuideMetricShare(guideData?.id)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const isMapFullView =
    searchParams.get(GuideSearchParam.mapFullView) === 'true'
  const { isCollected, likedByCurrentUser } = guideData ?? {}
  const { guide } = guideDraftData ?? {}
  const { status } = guide ?? {}

  const onMobileMapOpen = () => {
    searchParams.set(GuideSearchParam.mapFullView, 'true')
    setSearchParams(searchParams)
  }

  if (!guideDraftData && !guideData) return

  if (guideDraftData) {
    return (
      <>
        <div
          className={classNames(
            'bg-mint b-0 b-solid z-5 b-b-1 b-grey-400 md:p-x-12 md:p-y-4.5 transition-transform-500 lg:max-w-194.75 sticky left-0 top-0 flex max-w-full transform flex-row items-center justify-between gap-3 p-4 ease-in-out lg:fixed',
            {
              'lg:-translate-y-full': !isVisible,
              'lg:translate-y-0': isVisible,
              'lg:w-[calc(100%-521px)]': selectedId,
              'lg:w-full': !selectedId,
            }
          )}
        >
          <div className='flex w-full flex-row items-start justify-between gap-2'>
            <div className='flex flex-col gap-2'>
              <h3 className='md:type-h4-desktop type-h3-mobile line-clamp-1'>
                {guideDraftData?.name}
              </h3>
              <div className='[&>div>button]:b-0 [&>div>button]:bg-grey-300 [&>div>button]:p-x-4 w-fit'>
                <div className='lg:hidden'>
                  <GuideBadge
                    size='small'
                    onClick={() => setIsModalShareOpen(true)}
                  >
                    <Icon
                      name={
                        status === GuideStatus.Published
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      size='small'
                    />
                    <span>
                      {status === GuideStatus.Published ? 'Public' : 'Private'}
                    </span>
                  </GuideBadge>
                </div>
                <div className='hidden lg:block'>
                  <GuideBadge
                    size='medium'
                    onClick={() => setIsModalShareOpen(true)}
                  >
                    <Icon
                      name={
                        status === GuideStatus.Published
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      size='small'
                    />
                    <span>
                      {status === GuideStatus.Published ? 'Public' : 'Private'}
                    </span>
                  </GuideBadge>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-center gap-3'>
              <div
                className={classNames('lg:hidden', { hidden: isMapFullView })}
              >
                {/** @todo replace the selectors below with outlined IconButton when it is ready */}
                <div className='[&>button]:b-1.5 [&>button]:b-solid [&>button]:b-new-forest hover:[&>button]:b-forest [&>button]:shadow-none'>
                  <IconButton
                    aria-label='Open Guide map'
                    color='transparent'
                    icon='map'
                    outline='round'
                    size='medium'
                    onClick={onMobileMapOpen}
                  />
                </div>
              </div>
              <GuideDraftMenuOld guideDraftData={guideDraftData} />
            </div>
          </div>
        </div>
        {isModalShareOpen && (
          <GuideDraftShareOld
            guideDraftData={guideDraftData}
            onDismiss={() => setIsModalShareOpen(false)}
          />
        )}
      </>
    )
  }

  return (
    <div
      className={classNames(
        'bg-mint b-0 b-solid z-5 b-b-1 b-grey-400 md:p-x-12 md:p-y-4.5 transition-transform-500 lg:max-w-194.75 sticky left-0 top-0 flex max-w-full transform flex-row items-center justify-between gap-3 p-4 ease-in-out lg:fixed',
        {
          'lg:-translate-y-full': !isVisible,
          'lg:translate-y-0': isVisible,
          'lg:w-[calc(100%-521px)]': selectedId,
          'w-full': !selectedId,
        }
      )}
    >
      <div className='flex w-[calc(100%-140px)] flex-row items-center gap-2 md:gap-3.5'>
        <Link to={dashboardPath}>
          <TravelpassLogo
            className='h-7 w-7 min-w-7 lg:h-8 lg:w-8 lg:min-w-8'
            variant='symbol-valley'
          />
        </Link>
        <h3 className='md:type-h4-desktop type-h3-mobile truncate'>
          {guideData?.name}
        </h3>
      </div>
      <div className='flex min-w-fit flex-row items-center gap-3'>
        <IconButton
          aria-label='Add this Guide to your collection'
          aria-pressed={isCollected}
          color='forestLight'
          icon='add'
          outline='round'
          size='medium'
          onClick={onGuideSave}
        />
        <IconButton
          aria-label='Share this guide'
          color='forestLight'
          icon='iosShare'
          outline='round'
          size='medium'
          onClick={onGuideShare}
        />
        <IconButton
          aria-label='Like this Guide'
          aria-pressed={likedByCurrentUser}
          color='forestLight'
          icon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
          outline='round'
          size='medium'
          onClick={onGuideLike}
        />
      </div>
    </div>
  )
}
