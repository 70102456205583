import { SkeletonDots } from '@travelpass/design-system'
import type { UserProfile } from 'src/__generated__/graphql'
import { EmptyList } from './EmptyList'
import { FollowerListItem } from './FollowerListItem'

interface FollowersListProps {
  emptyListMessage: string
  isLoading: boolean
  list: Pick<
    UserProfile,
    | 'id'
    | 'accountHandle'
    | 'displayName'
    | 'isFollowed'
    | 'isUserOwner'
    | 'profileImageUrl'
    | 'userId'
  >[]
}

export const FollowersList = ({
  emptyListMessage,
  isLoading,
  list,
}: FollowersListProps) => {
  if (isLoading)
    return (
      <div className='h-63 flex flex-col content-center justify-center'>
        <SkeletonDots numberOfDots={3} />
      </div>
    )

  if (!list || list.length == 0)
    return (
      <div className='h-100'>
        <EmptyList text={emptyListMessage} />
      </div>
    )

  return (
    <div className='h-100 flex flex-col gap-6'>
      {list?.map(follower => (
        <span key={follower.id}>
          <FollowerListItem follower={follower} />
        </span>
      ))}
    </div>
  )
}
