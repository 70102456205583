import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetGuidesEventsMapUserQueryInGuidesEventsMapQuery } from 'src/__generated__/graphql'

const getGuidesEventsMapUserQuery = gql(`
  query GetGuidesEventsMapUserQueryInGuidesEventsMap {
    currentUser {
      id
      userProfile {
        id
        following {
          id
          userId
        }
        userId
      }
    }
  }
`)

type UseGetGuidesEventsMapUserQuery = {
  guidesEventsMapUserData: GetGuidesEventsMapUserQueryInGuidesEventsMapQuery['currentUser']
  hasError: ApolloError
  isLoading: boolean
}

export const useGetGuidesEventsMapUserQuery =
  (): UseGetGuidesEventsMapUserQuery => {
    const {
      data,
      error: hasError,
      loading: isLoading,
    } = useQuery(getGuidesEventsMapUserQuery)

    return {
      guidesEventsMapUserData: data?.currentUser,
      hasError,
      isLoading,
    }
  }
