import { Button, Icon } from '@travelpass/design-system'
import { EventStatus } from 'src/__generated__/graphql'
import { HotelCancellationBadge } from 'src/common/components'
import { defaultDrawerEvent } from '../../constants'
import { type ExperienceEvent } from '../constants'

export const ExperienceMiddle = ({
  data,
}: {
  data: ExperienceEvent | null
}) => {
  const displayEndTime =
    data?.endTime !== defaultDrawerEvent?.endTime ? ` - ${data?.endTime}` : ''

  const onClick = () => {
    if (data?.bookingVoucherUrl) {
      window.open(data?.bookingVoucherUrl, '_blank')
    }
  }

  return (
    <div className='p-8'>
      <div className='space-y-2'>
        <div className='c-grey800 flex flex-row items-center justify-start gap-1.5'>
          <Icon name='calendar' />
          <p className='type-body-1 c-grey800'>
            {data?.startDate?.format('ddd, MMMM D, YYYY')}
          </p>
        </div>
        <div className='c-grey800 flex flex-row items-center justify-start gap-1.5'>
          <Icon name='personOutline' />
          <p className='type-body-1 c-grey800'>{data?.guests} Travelers</p>
        </div>
        <div className='c-grey800 flex flex-row items-center justify-start gap-1.5'>
          <Icon name='accessTime' />
          <p className='type-body-1 c-grey800'>
            {data?.startTime}
            {displayEndTime}
          </p>
        </div>
      </div>
      {!!data?.bookingConfirmation && (
        <div className='space-y-8 pt-8'>
          <p className='type-body-1 c-grey800'>
            <strong>Confirmation</strong> # {data?.bookingConfirmation}
          </p>
          <HotelCancellationBadge type={data?.refundType} />
          {data?.bookingVoucherUrl && (
            <Button
              aria-label='Get tickets'
              variant='outlined'
              onClick={onClick}
            >
              Get Tickets
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
