import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

export const MenuLink = ({ className, ...props }: LinkProps) => {
  return (
    <Link
      {...props}
      className={`type-body-1 b-none color-black focus:bg-warm-grey hover:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-2 text-left no-underline outline-none ${className || ''}`}
    />
  )
}
