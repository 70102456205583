import { Skeleton } from '@travelpass/design-system'

export const DashboardTripCardLoading = () => (
  <div className='h-90 min-w-55 rd-2 shadow-1 w-55 md:min-w-66 md:w-66 relative bg-white'>
    <div className='h-56 w-full'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-34 space-y-4 p-4'>
      <div className='space-y-1'>
        <div className='h-5 w-full'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-5 w-full'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='flex flex-row items-center justify-between'>
        <div className='h-3 w-10'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-3 w-10'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-3 w-10'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='h-4 w-full'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
