import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const REMOVE_ITEM_FROM_GUIDE_DRAFT = gql(`
  mutation RemoveItemFromGuideDraft($archivePublishedEventInput: ArchivePublishedEventInput!) {
    archivePublishedEvent(archivePublishedEventInput: $archivePublishedEventInput) {
      guideDraft {
        id
        name
        publishedEvents {
          id
          externalTypeId
          productId
          place {
            id
            googlePlaceId
          }
        }
      }
    }
  }
`)

export const useRemoveItemFromGuideDraft = (options?) => {
  return useMutation(REMOVE_ITEM_FROM_GUIDE_DRAFT, options)
}
