import { useEffect, useState } from 'react'
import { Modal, ModalScrollContents } from '@travelpass/design-system'
import classNames from 'classnames'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { HotelReviewsModalCardsApollo } from './HotelReviewsModalCards'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../../hooks/useHotelReviewsSummaryListHotelReviewsQuery'
import { getReviewRatingDescription } from '../../../utils'
import type { HotelReviewsFilterType } from '../HotelReviewsApollo/hotelReviewsApolloConstants'
import { HotelReviewsNav } from '../HotelReviewsNav'
import { HotelReviewsAverages } from '../common/HotelReviewsAverages'
import { HotelReviewsTravelerType } from '../common/HotelReviewsTravelerType'

interface HotelReviewsModalProps {
  onClose(): void
}

export const HotelReviewsModal = ({ onClose }: HotelReviewsModalProps) => {
  const [filters, setFilters] = useState<HotelReviewsFilterType>()
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const {
    averageOverall,
    cleanlinessAverage,
    locationAverage,
    staffAverage,
    totalReviews,
  } = hotelReviewsSummaryData ?? {}

  const onChange = (updatedFilters: HotelReviewsFilterType) =>
    setFilters(previousFilters => ({
      ...previousFilters,
      ...updatedFilters,
    }))

  useEffect(() => {
    if (filters)
      pushDataToDataLayer('filter_reviews', { filter_option: filters })
  }, [filters])

  return (
    <Modal size='large' onDismiss={onClose}>
      <ModalScrollContents>
        <div className='h-175 flex flex-col gap-8 py-8 md:flex-row md:gap-5'>
          <div className='md:min-w-65 flex flex-col gap-8 px-2 md:gap-12 lg:overflow-auto'>
            <div className='flex flex-col gap-1'>
              <div
                className={classNames(
                  'rd-1 bg-grey800 w-fit px-2 py-1 text-center',
                  {
                    'bg-tealMedium': Number(averageOverall) >= 7,
                  }
                )}
              >
                <h3 className='type-h3 c-white'>{averageOverall}</h3>
              </div>
              <div className='flex gap-1'>
                <p
                  className={classNames('type-body-1 c-grey800', {
                    'c-tealMedium': Number(averageOverall) >= 7,
                  })}
                >
                  {getReviewRatingDescription(averageOverall)}
                </p>
                <p className='type-body-1 c-grey800'>{`(${totalReviews})`}</p>
              </div>
            </div>
            <HotelReviewsAverages
              cleanlinessAverage={cleanlinessAverage}
              locationAverage={locationAverage}
              staffAverage={staffAverage}
            />
            <div className='sm:hidden lg:flex'>
              <HotelReviewsTravelerType filters={filters} onChange={onChange} />
            </div>
          </div>
          <div className='bg-grey-300 min-w-1px w-1px hidden md:flex' />
          <div className='flex flex-col gap-12 overflow-auto'>
            <HotelReviewsNav filters={filters} onChange={onChange} />
            <HotelReviewsModalCardsApollo filters={filters} />
          </div>
        </div>
      </ModalScrollContents>
    </Modal>
  )
}
