export const HotelCardPricing = ({
  averageNightlyRate,
}: {
  averageNightlyRate: string
}) => {
  const amountFloat = parseFloat(averageNightlyRate ?? '')

  return amountFloat > 0 ? (
    <p className='type-small-text c-grey-700 pt-1'>
      <span className='type-body-2 c-forest pr-1'>
        ${amountFloat.toFixed(0)}
      </span>
      Avg/ Night
    </p>
  ) : null
}
