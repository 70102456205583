import type { MutableRefObject } from 'react'
import { HotelHeroApollo } from './HotelHeroApollo'

interface HotelHeroProps {
  hotelRoomsRef: MutableRefObject<HTMLDivElement>
}

export const HotelHero = ({ hotelRoomsRef }: HotelHeroProps) => {
  return (
    <div className='bg-warm-grey'>
      <HotelHeroApollo hotelRoomsRef={hotelRoomsRef} />
    </div>
  )
}
