import {
  type Dispatch,
  type SetStateAction,
  useLayoutEffect,
  useRef,
} from 'react'
import isEmpty from 'lodash.isempty'
import { DrawerType } from 'src/pages/trips/constants'
import { useDeleteEvent } from 'src/pages/trips/hooks'
import type { TripDailyEvent } from 'src/pages/trips/types'
import { getDrawerType } from 'src/pages/trips/utils'
import { EventCard } from '../../../../components'
import { SectionTitle } from '../../TimelineSections'
import { AddStayCTA } from '../AddStayCTA'

export const TimelineStay = ({
  currentDayStay,
  timezone,
  selectedEventId,
  setSelectedEventId,
  setScrollToEventId,
  scrollToEventId,
  onClick,
  onHover,
}: {
  currentDayStay?: TripDailyEvent[]
  timezone: string
  selectedEventId: string | null
  setSelectedEventId: (eventId: string) => void
  setScrollToEventId: Dispatch<SetStateAction<string | null>>
  scrollToEventId: string | null
  onClick: () => void
  onHover: (eventId?: string) => void
}) => {
  const eventRefs = useRef({})

  const { deleteEvent } = useDeleteEvent()

  useLayoutEffect(() => {
    if (scrollToEventId && eventRefs.current[scrollToEventId]) {
      eventRefs.current[scrollToEventId].scrollIntoView({
        behavior: 'smooth',
      })
      setScrollToEventId(null)
    }
  }, [currentDayStay])

  return (
    <div>
      <SectionTitle title='Where to stay' />
      {!isEmpty(currentDayStay) ? (
        currentDayStay?.map(event => {
          const eventId = event?.id
          const eventType = getDrawerType(event)
          const isDeleteable =
            eventType !== DrawerType?.InternalHotelBooked &&
            eventType !== DrawerType?.InternalExperienceBooked

          return (
            <div
              key={`eventBlock-${eventId}`}
              className='mb-2'
              ref={ref => (eventRefs.current[eventId] = ref)}
            >
              {event ? (
                <EventCard
                  bookedStatus={event.status ?? undefined}
                  endDate={event.endDate}
                  eventId={eventId}
                  imageUrl={event.imageUrl ?? ''}
                  isActive={selectedEventId === eventId}
                  name={event.name ?? ''}
                  notes={event.notes ?? ''}
                  startDate={event.startDate}
                  timezone={timezone}
                  useEventTime={event.useEventTime ?? false}
                  onEventCardClick={setSelectedEventId}
                  onHover={onHover}
                />
              ) : null}
            </div>
          )
        })
      ) : (
        <AddStayCTA onClick={() => onClick()} />
      )}
    </div>
  )
}
