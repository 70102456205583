import { PageLayoutContainer } from 'src/common/components'
import { dispatch } from 'src/common/hooks/useListen'

export const UtahJazzSignUp = () => {
  const openSignInModal = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
    })
  }
  return (
    <div className='bg-warm-grey w-full py-12'>
      <PageLayoutContainer>
        <div className='space-y-4'>
          <p className='type-overline text-grey-700 text-center uppercase'>
            Join Travelpass
          </p>
          <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo text-forest-dark m-0 text-balance text-center'>
            Where Traveling Is Encouraged
          </h3>
          <div className='w-30 mx-a bg-valley mb-4 h-1 rounded-full md:h-1.5 lg:w-40'></div>
          <p className='text-subtitle-2 font-400 text-grey-800 max-w-650px mx-auto mt-8 block text-balance text-center'>
            Create a free account to join a community that&apos;s traveled to
            all 50 states, 121 countries, 6 continents, and counting.
          </p>
        </div>
        <button
          className='of-hidden rounded-2 c-forest font-700 text-4 bg-valley border-valley-dark/20 mx-a mt-10 block w-auto appearance-none border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-shadow hover:cursor-pointer hover:shadow-lg'
          onClick={openSignInModal}
        >
          Create Your Travelpass Account
        </button>
      </PageLayoutContainer>
    </div>
  )
}
