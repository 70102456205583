import { Divider } from '@travelpass/design-system'
import { ExperienceBookingTimeState } from 'src/__generated__/graphql'
import { ExperienceBookingsApollo } from './ExperienceBookingsApollo'

// TODO test
export const ExperienceBookings = () => (
  <div className='flex flex-col gap-6'>
    <ExperienceBookingsApollo
      bookingStatus={ExperienceBookingTimeState.Upcoming}
    />
    <Divider />
    <ExperienceBookingsApollo bookingStatus={ExperienceBookingTimeState.Past} />
    <Divider />
    <ExperienceBookingsApollo
      bookingStatus={ExperienceBookingTimeState.Cancelled}
    />
  </div>
)
