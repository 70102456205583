import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { FollowType, UserProfile } from 'src/__generated__/graphql'

const FOLLOW_COUNTS_QUERY = gql(`
  query FollowCounts($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      displayName
      followingUsers {
        ...FollowUserProfileFields
      }
      followedUsers {
        ...FollowUserProfileFields
      }
      followCount {
        countOfFollowing
        countOfFollowed
      }
    }
  }
`)

const SEARCH_FOLLOW_USER_PROFILES = gql(`
  query SearchFollowUserProfiles($searchInput: SearchInput!) {
    searchFollowUserProfiles(searchInput: $searchInput) {
      ...FollowUserProfileFields
    }
  }
`)

interface SearchFollowUserProfilesInput {
  searchString: string
  followTypeSearch: FollowType
  userId: string
  accountHandle: string
}

interface SearchFollowUserProfilesOutput {
  countOfFollowing: number
  countOfFollowed: number
  displayName: string
  allFollowedUsers: UserProfile[]
  allFollowingUsers: UserProfile[]
  followArray: UserProfile[]
  loading: boolean
  refetch: Function
}

export const useSearchFollowUserProfiles = ({
  searchString,
  followTypeSearch,
  userId,
  accountHandle,
}: SearchFollowUserProfilesInput): SearchFollowUserProfilesOutput => {
  const { data: followCountData, loading: followingCountsLoading } = useQuery(
    FOLLOW_COUNTS_QUERY,
    {
      variables: { accountHandle },
      skip: !accountHandle,
    }
  )
  const allFollowingUsers =
    followCountData?.viewUserProfile?.followingUsers || []
  const allFollowedUsers = followCountData?.viewUserProfile?.followedUsers || []
  const countOfFollowing =
    followCountData?.viewUserProfile?.followCount?.countOfFollowing
  const countOfFollowed =
    followCountData?.viewUserProfile?.followCount?.countOfFollowed
  const displayName = followCountData?.viewUserProfile?.displayName

  const {
    data: followData,
    loading: loadingSearchFollow,
    refetch,
  } = useQuery(SEARCH_FOLLOW_USER_PROFILES, {
    variables: {
      searchInput: {
        searchString,
        followTypeSearch,
        userId,
      },
    },
    skip: !searchString || searchString.length < 3,
  })

  const followArray = followData?.searchFollowUserProfiles || []

  return {
    loading: followingCountsLoading || loadingSearchFollow,
    allFollowedUsers,
    allFollowingUsers,
    countOfFollowed,
    countOfFollowing,
    displayName,
    followArray,
    refetch,
  }
}
