import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { defaultTimeFormat } from 'src/common/components/TimeRangePicker/timeRangeConstants'

const constructDisplayTime = ({
  startTime,
  endTime,
  timezone,
}: {
  startTime: Dayjs
  endTime: Dayjs
  timezone: string
}) => {
  if (startTime && startTime.isValid()) {
    const formattedStart = dayjs
      .tz(startTime, timezone)
      .format(defaultTimeFormat)
    if (timezone && endTime?.isValid()) {
      const formattedEnd = dayjs.tz(endTime, timezone).format(defaultTimeFormat)
      if (formattedStart === formattedEnd) return formattedStart

      return `${formattedStart} - ${formattedEnd}`
    } else {
      return formattedStart
    }
  }

  return ''
}

const constructDisplayNoteCount = (noteCount?: number) => {
  if (noteCount && noteCount > 0) {
    const plural = noteCount === 1 ? '' : 's'
    const noteStr = `Note${plural}`
    if (noteCount > 100) {
      return `100+ ${noteStr}`
    }
    return `${noteCount} ${noteStr}`
  }

  return ''
}

export { constructDisplayTime, constructDisplayNoteCount }
