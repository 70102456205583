import { Button, Modal, ModalScrollContents } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { AvailabilityDateRangeSelect } from './AvailabilityDateRangeSelect'
import { AvailabilityDatesScroller } from './AvailabilityDatesScroller'
import { AvailabilityOptionsList } from './AvailabilityOptionsList'
import { PaxMixPopover } from './PaxMixPopover'
import {
  ExperiencesDetailsSearchParams,
  useExperienceDetailsSearchParams,
} from './useExperienceDetailsSearchParams'
import { useExperienceProductDetails } from './useExperienceProductDetails'

export const AvailabilityOptionsModal = () => {
  const [, updateSearchParams] = useSearchParams()
  const { travelDate, guests } = useExperienceDetailsSearchParams()
  const { data } = useExperienceProductDetails()
  // @todo: validate travelDate before using to get availability
  // @todo: validate travelDate before rendering modal
  // @todo: validate guests before rendering modal?

  const guestsObj = JSON.parse(guests)

  const ageBands = data?.pricingInfo?.ageBands
  const includesAdultAgeBand = ageBands?.some(
    ({ ageBand }) => ageBand === 'ADULT'
  )
  // age bands from Viator are always in ascending order of age
  const oldestAgeBand =
    data?.pricingInfo?.ageBands?.[ageBands?.length - 1]?.ageBand
  /*
  if the product includes the 'ADULT' ageBand, we default to that ageBand. otherwise, we default to the oldest ageBand.
  this logic assumes that if a product requires adults for booking, it will always include the 'ADULT' ageBand,
  so we check for the presence of 'ADULT' rather than checking if the product requires adults for booking
  */
  const defaultGuest = { [includesAdultAgeBand ? 'ADULT' : oldestAgeBand]: 1 }

  if (isEmpty(guestsObj)) {
    updateSearchParams(sp => {
      sp.set(
        ExperiencesDetailsSearchParams.guests,
        JSON.stringify(defaultGuest)
      )
      return sp
    })
  }

  const handleDismiss = () => {
    updateSearchParams(
      sp => {
        sp.delete(ExperiencesDetailsSearchParams.travelDate)
        return sp
      },
      { replace: true }
    )
  }

  if (!travelDate || !guests) return null

  return (
    <Modal size='xl' title='Check Availability' onDismiss={handleDismiss}>
      <ModalScrollContents>
        <div className='bg-warm-grey rounded-4 grid grid-cols-1 items-end justify-evenly gap-4 p-4 md:grid-cols-3'>
          <PaxMixPopover />
          <AvailabilityDateRangeSelect includeTraveDate />
          <span className='mb-2px px-6 md:px-0'>
            <Button fullWidth label='Search Availability' size='large' />
          </span>
        </div>
        <AvailabilityDatesScroller />
        <AvailabilityOptionsList />
      </ModalScrollContents>
    </Modal>
  )
}
