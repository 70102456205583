import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createEvent = gql(`
  mutation CreateEventMutationInTripDetails($eventInput: CreateEventInput!) {
    createEvent(eventInput: $eventInput) {
      id
      endDate
      insertedAt
      name
      productId
      startDate
      status
      trip {
        id
        dailyEventBreakdown {
          scheduled {
            events {
              id
              addresses {
                lat
                long
                googlePlaceId
              }
              description
              name
              startDate
              endDate
              useEventTime
              trip {
                id
              }
              productId
              type
              status
            }
          }
        }
        name
      }
      type
    }
  }
`)

export const useTripsDetailsCreateEvent = () => useMutation(createEvent)
