import {
  EventType,
  UserImageSource,
  UserImageType,
} from 'src/__generated__/graphql'
import type { GuideEventsData } from '../../types'

export const guideEventsMock: GuideEventsData = {
  id: 'R3VpZGU6Z2RlXzAxSFZIMjVCNU1WWFJFWDNYS0UzSlE2MFFY',
  paginatedEventCategories: {
    edges: [
      {
        node: {
          id: 'UHVibGlzaGVkRXZlbnRDYXRlZ29yeTpwYmRldnRjYXRfMDFIVkgyNUI2RVBDWjBCODFOMUZKTUtTOTQ=',
          description:
            'Utah Olympic Park: 2002 Winter Olympics venue, training, year-round activities.',
          publishedEvents: {
            edges: [
              {
                node: {
                  id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSFkwSjdRTUpTTTJZNDRBWlJSMURWUUha',
                  addresses: [
                    {
                      id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFkwSjdRTU45S1RHOEdWWlgzNVlLOEZY',
                      addressLine1: '3419 Olympic Parkway',
                      city: 'Park City',
                      country: 'United States',
                      googlePlaceId: 'ChIJv0kuJgVsUocRhFXwW7C7DYs',
                      lat: '40.7117726',
                      long: '-111.5618448',
                      state: 'Utah',
                      __typename: 'GenericAddress',
                    },
                  ],
                  externalTypeId: null,
                  images: [
                    {
                      id: 'VXNlckltYWdlOnVzaW1nXzAxSjhRNjk5VzZKQU1DM0Q1Q0cySlRCMU5W',
                      source: UserImageSource.UserUploadedImage,
                      type: UserImageType.PublishedEvent,
                      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/150b245a-ca6f-4676-a005-4b71e157f0cd',
                      __typename: 'UserImage',
                    },
                    {
                      id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzBBSkNK',
                      source: UserImageSource.UserUploadedImage,
                      type: UserImageType.PublishedEvent,
                      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/e3d579c0-8e5f-4f3c-a42a-66152aafd902',
                      __typename: 'UserImage',
                    },
                  ],
                  imageUrl:
                    'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUGGfZldFFit_oekI5zreyb908Fcy2U25yAqFkLSQ3nwileYU0sAHegJa58lslFbQvdgRha8r6s904Y9bwPpNvdKkinjfh6QHuemhUJYVhJ4E1AXsxQYu0RCUZVS_40HznfM1rzKcUo1Lg87YnHxllp1xUrJzoY0dM-zeGey7WQt1H72_4NW&key=AIzaSyCeX5qSPxuHc7zIxQ5y1taYLPe6hxj8LSc',
                  likedByCurrentUser: false,
                  name: 'Utah Olympic Park',
                  notes:
                    'The Utah Olympic Park, built for the 2002 Winter Olympics, is a renowned facility in Park City offering ski jumping, bobsledding, and other winter sports activities. It serves as a training center, hosts events, and provides opportunities for visitors to experience the thrill of these sports firsthand.',
                  productId: null,
                  publishedEventLikedByUsers: {
                    pageInfo: {
                      hasNextPage: false,
                      hasPreviousPage: false,
                    },
                    totalCount: 1,
                  },
                  standardHotelId: null,
                  type: EventType.Experience,
                  __typename: 'PublishedEvent',
                },
                __typename: 'PublishedEventEdge',
              },
            ],
            pageInfo: {
              endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
              hasNextPage: false,
            },
            totalCount: 1,
            __typename: 'PublishedEventConnection',
          },
          name: 'What to do',
          __typename: 'PublishedEventCategory',
        },
      },
    ],
    pageInfo: {
      endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
      hasNextPage: false,
    },
    totalCount: 1,
    __typename: 'PublishedEventCategoryConnection',
  },
  uncategorizedPublishedEvents: {
    edges: [
      {
        node: {
          id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSjMzRDE1RFdNNjI5WlMxM1lFMURDQTg1',
          addresses: [
            {
              id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSjMzRDE1RFkyV0pGVjU4S1BLN00yVDhF',
              addressLine1: null,
              city: 'New York',
              country: 'United States',
              googlePlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g',
              lat: '40.7127753',
              long: '-74.0059728',
              state: 'New York',
              __typename: 'GenericAddress',
            },
          ],
          externalTypeId: null,
          images: [],
          imageUrl:
            'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUc7tXWB_kxvJUOIMVyTIDbqGz7xkDInNBJOFxykqr8fBcOJUKeesa9ZOkGtosLKHCQ4k5pB7pZwsTZewcy4PsB1gL5ZOye7wK58vl4WBAvhl7_SdDKIYCf-zdMoVYz6yiVPhEa9edn8rQEo1TYN5UVtttFaTW3_bb0YYh_dQJ2wXJWlO8q5&key=AIzaSyCdwt-67yAZ8iAsyKm2Rbf9E54T8UaBf7I',
          name: 'New York',
          notes: '',
          productId: null,
          publishedEventLikedByUsers: {
            pageInfo: {
              hasNextPage: false,
              hasPreviousPage: false,
            },
            totalCount: 0,
          },
          standardHotelId: null,
          type: EventType.Experience,
          __typename: 'PublishedEvent',
        },
        __typename: 'PublishedEventEdge',
      },
    ],
    pageInfo: {
      endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
      hasNextPage: false,
    },
    totalCount: 1,
    __typename: 'PublishedEventConnection',
  },
  __typename: 'Guide',
}
