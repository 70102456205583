import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const mutation = gql(`
  mutation EditTripNew($tripInput: UpdateTripInput!) {
    updateTrip(tripInput: $tripInput) {
      id
      owner {
        externalId
      }
      endDate
      isDeleted
      name
      startDate
      dailyEventBreakdown {
        scheduled {
          events {
            id
            startDate
            endDate
          }
        }
      }
      tripPreference {
        id
        addresses {
          id
          addressLine1
          city
          country
          lat
          long
          state
        }
      }
    }
  }
`)

export const useEditTrip = () => {
  const [editTrip, { loading, error }] = useMutation(mutation)

  return {
    editTrip,
    loading,
    error,
  }
}
