import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { GuideStatus } from 'src/__generated__/graphql'

export const USER_PROFILE_FOR_VOTE_MODAL = gql(`
  query userProfileForVoteModal($userId: ID!, $first: Int = 3, $after: String = null, $userGuidesArgs: UserGuidesArgs!) {
    viewUserProfile(userId: $userId) {
      id
      isFollowed
      user {
        userGuides(first: $first, after: $after, userGuidesArgs: $userGuidesArgs) {
          edges {
            node {
              id
              addresses {
                id
                city
                country
                state
              }
              description
              insertedAt
              images {
                id
                source
                type
                url
              }
              imageUrl
              likedByCurrentUser
              name
              numberOfLikes
              ownerProfile {
                id
                accountHandle
                displayName
                profileImageUrl
              }
              timeZone
              updatedAt
              viewCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount,
        }
      }
    }
  } 
  `)

export const useUserProfileForVoteModal = (
  userId: string,
  includeStatuses: [GuideStatus] = [GuideStatus.Published],
  first?: number
) => {
  return useQuery(USER_PROFILE_FOR_VOTE_MODAL, {
    variables: {
      first,
      userId,
      userGuidesArgs: { includeStatuses },
    },
  })
}
