import classNames from 'classnames'
import { EventType } from 'src/__generated__/graphql'
import { getMarkerColors } from 'src/pages/trips/utils'
import type { StyledMarkerVariant } from './types'

interface StyledMarkerProps {
  isHovered?: boolean
  isSelected: boolean
  type: EventType
  variant?: StyledMarkerVariant
}

export const StyledMarker = ({
  isHovered = false,
  isSelected = false,
  type,
  variant = 'timeline',
}: StyledMarkerProps): JSX.Element => {
  const { fill, stroke } = getMarkerColors({
    isHovered,
    isSelected,
    variant,
  })

  return (
    <svg
      className={classNames({
        'scale-150': isHovered || isSelected,
      })}
      height='51'
      preserveAspectRatio='xMinYMin meet'
      viewBox='0 0 45 51'
      width='45'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <filter
          filterUnits='objectBoundingBox'
          height='250%'
          id='trip-marker-bottom-shadow'
          width='154.5%'
          x='-27.3%'
          y='-75%'
        >
          <feGaussianBlur in='SourceGraphic' stdDeviation='1' />
        </filter>
        <filter
          filterUnits='objectBoundingBox'
          height='143.6%'
          id='trip-marker-shadow-1'
          width='151.5%'
          x='-25.8%'
          y='-19.2%'
        >
          <feMorphology
            in='SourceAlpha'
            operator='dilate'
            radius='.5'
            result='shadowSpreadOuter1'
          />
          <feOffset
            dy='1'
            in='shadowSpreadOuter1'
            result='shadowOffsetOuter1'
          />
          <feGaussianBlur
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
            stdDeviation='2.5'
          />
          <feComposite
            in='shadowBlurOuter1'
            in2='SourceAlpha'
            operator='out'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            in='shadowBlurOuter1'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
        </filter>
        <path
          d='M17.235 38.827c.229-.114.427-.28.577-.486l4.846-6.608C28.72 29.3 33 23.378 33 16.46 33 7.369 25.613 0 16.5 0S0 7.369 0 16.459c0 6.92 4.28 12.84 10.342 15.274l4.846 6.608c.15.206.348.372.577.486a1.647 1.647 0 0 0 1.47 0Z'
          id='trip-marker-shadow-2'
        />
      </defs>
      <g fill='none' fillRule='evenodd' transform='translate(6 5)'>
        <ellipse
          cx='16.5'
          cy='39'
          fill='#000'
          fillOpacity='.22'
          fillRule='nonzero'
          filter='url(#trip-marker-bottom-shadow)'
          rx='5.5'
          ry='2'
        />
        <use
          fill='#000'
          filter='url(#trip-marker-shadow-1)'
          xlinkHref='#trip-marker-shadow-2'
        />
        <use fill='#FFF' stroke={stroke} xlinkHref='#trip-marker-shadow-2' />
        <g>
          <circle cx='16.5' cy='16.5' fill={fill} r='13.5' />
          {type === EventType.Experience && (
            <path
              d='M17.647 10.67c.327 0 .642.14.858.38l.723.787h1.85a1.17 1.17 0 0 1 1.166 1.166v7a1.17 1.17 0 0 1-1.167 1.167h-9.333a1.17 1.17 0 0 1-1.167-1.167v-7a1.17 1.17 0 0 1 1.167-1.166h1.849l.718-.788c.221-.239.536-.379.863-.379Zm-1.236 2.917a2.918 2.918 0 0 0 0 5.833 2.918 2.918 0 0 0 0-5.833Zm0 1.165a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.Flight && (
            <path
              d='M21.476 17.522h.002a.474.474 0 0 1-.374.865l-3.672-1.077-.077-.023v2.935l.029.017 1.154.693a.194.194 0 0 1 .094.169v.297a.195.195 0 0 1-.244.193l-.343-.087-.974-.246-.216-.056-.196-.047-.014-.003-.015.004-.235.06v.001l-1.497.374a.197.197 0 0 1-.244-.193v-.297a.2.2 0 0 1 .094-.17l1.159-.692.03-.017v-2.929l-.078.022-3.671 1.077a.48.48 0 0 1-.61-.46c0-.17.09-.328.235-.41h.001l4.093-2.41.03-.018V12.24a.708.708 0 1 1 1.418 0V15.093l.03.017 4.091 2.41Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.FoodAndDrink && (
            <path
              d='M20.245 17.624v-.06h-.573a.969.969 0 0 1-.965-.966v-3.077c0-.362.195-.773.532-1.13a2.796 2.796 0 0 1 1.35-.786.457.457 0 0 1 .562.444v9.165a.454.454 0 0 1-.453.452.454.454 0 0 1-.453-.452v-3.59Zm-4.162-2.504h.06v-3.137c0-.25.204-.453.452-.453.25 0 .453.204.453.453v3.077a1.99 1.99 0 0 1-1.991 1.991h-.06V21.214a.454.454 0 0 1-.453.452.454.454 0 0 1-.453-.452V17.05h-.06a1.99 1.99 0 0 1-1.991-1.991v-3.077c0-.25.204-.453.453-.453.249 0 .453.204.453.453v3.137h1.145v-3.137c0-.25.204-.453.453-.453.25 0 .453.204.453.453v3.137h1.086Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.Miscellaneous && (
            <path
              d='m16.718 19.602 2.128 1.288a.512.512 0 0 0 .765-.554l-.565-2.42 1.883-1.631a.512.512 0 0 0-.293-.898l-2.477-.21-.969-2.287a.513.513 0 0 0-.944 0l-.969 2.282-2.477.21a.512.512 0 0 0-.292.897l1.882 1.631-.564 2.42a.512.512 0 0 0 .764.554l2.128-1.282Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.Shopping && (
            <path
              d='M21.27 12.912h-1.54c0-1.36-1.376-2.462-3.076-2.462-1.7 0-3.077 1.102-3.077 2.462h-1.538c-.847 0-1.539.553-1.539 1.23v7.385c0 .677.692 1.23 1.539 1.23h9.23c.846 0 1.539-.553 1.539-1.23v-7.385c0-.677-.693-1.23-1.539-1.23Zm-6.155 2.461c0 .339-.346.615-.769.615-.423 0-.769-.276-.769-.615v-1.23h1.538v1.23Zm1.539-3.692c.846 0 1.538.554 1.538 1.23h-3.077c0-.676.693-1.23 1.539-1.23Zm3.077 3.692c0 .339-.346.615-.77.615-.422 0-.769-.276-.769-.615v-1.23h1.539v1.23Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.Stay && (
            <path
              d='M16.916 17.728h.06v-2.977a1.11 1.11 0 0 1 1.107-1.106h3.5a2.273 2.273 0 0 1 2.273 2.273v4.667a.525.525 0 0 1-.523.523.525.525 0 0 1-.523-.523v-1.227H12.19v1.227a.525.525 0 0 1-.524.523.525.525 0 0 1-.523-.523V13c0-.287.236-.523.523-.523.288 0 .524.236.524.523V17.728h4.726Zm-.643-2.393c0 .935-.755 1.69-1.69 1.69-.935 0-1.69-.755-1.69-1.69 0-.936.755-1.69 1.69-1.69.935 0 1.69.754 1.69 1.69Z'
              fill='#FFFFFF'
            />
          )}
          {type === EventType.Transportation && (
            <path
              d='M20.537 13.01a.872.872 0 0 0-.829-.59h-6.416a.877.877 0 0 0-.829.59l-1.149 3.307c-.04.122-.064.25-.064.385v4.176a.874.874 0 1 0 1.75 0v-.291h7v.291c0 .479.39.875.875.875.478 0 .875-.39.875-.875v-4.176c0-.129-.023-.263-.064-.385l-1.15-3.308Zm-7.245 5.827a.874.874 0 1 1 0-1.75.874.874 0 1 1 0 1.75Zm6.416 0a.874.874 0 1 1 0-1.75.874.874 0 1 1 0 1.75Zm-7.291-2.917.74-2.228a.591.591 0 0 1 .555-.397h5.576c.251 0 .473.163.555.397l.74 2.228h-8.166Z'
              fill='#FFFFFF'
            />
          )}
        </g>
      </g>
    </svg>
  )
}
