import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { type Note } from 'src/__generated__/graphql'
import {
  pushTripNoteCreateToDataLayer,
  pushTripNoteDeleteToDataLayer,
  pushTripNoteUpdateToDataLayer,
} from 'src/pages/trips/utils'
import { OverviewNoteForm } from './OverviewNoteForm'
import type { OverviewNoteFormSubmitArguments } from './types'
import {
  useCreateNoteTripOverviewMutation,
  useDeleteNoteTripOverviewMutation,
  useUpdateNoteTripOverviewMutation,
} from '../../hooks'
import { initialNote } from '../OverviewNotes'

interface OverviewNoteFormDrawerProps {
  activeNoteId: Note['id']
  notes: Note[]
  onActiveNoteIdChange(updatedNoteId: string): void
  onClose(): void
  tripName: string
}

export const OverviewNoteFormDrawer = ({
  activeNoteId,
  notes,
  onActiveNoteIdChange,
  onClose,
  tripName,
}: OverviewNoteFormDrawerProps) => {
  const [createNote, { data: createNoteData, loading: isCreateNoteLoading }] =
    useCreateNoteTripOverviewMutation()
  const [deleteNote] = useDeleteNoteTripOverviewMutation()
  const [updateNote, { loading: isUpdateNoteLoading }] =
    useUpdateNoteTripOverviewMutation()
  const { tripId } = useParams()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [note, setNote] = useState<Note>(initialNote)
  const { id: createNoteId } = createNoteData?.createNote ?? {}
  const isLoading = isCreateNoteLoading || isUpdateNoteLoading
  const { id } = note ?? {}

  useEffect(() => {
    setNote(() => {
      if (activeNoteId) return notes?.find(({ id }) => id === activeNoteId)

      return initialNote
    })
  }, [activeNoteId, notes])

  useEffect(() => {
    if (createNoteId) onActiveNoteIdChange(createNoteId)
  }, [createNoteId])

  const onDelete = async () => {
    if (id) {
      try {
        const deleteNoteResponse = await deleteNote({
          variables: {
            id,
          },
        })
        const itemId = deleteNoteResponse?.data?.deleteNote?.id
        pushTripNoteDeleteToDataLayer({
          itemId: itemId,
          triggerVariant: 'OverviewNoteFormDrawer DeleteButton',
          tripId: tripId,
          tripName: tripName,
        })
        addSuccessSnack({ title: 'Note delete' })
        onClose()
      } catch (error) {
        console.error(error)
        addErrorSnack({ title: 'Unable to delete note' })
      }
    }
  }

  const onNoteChange = (updatedNote: Note) => setNote(updatedNote)

  const onSubmit = async ({
    showSnackbar = true,
    updatedNote = note,
    willClose = true,
  }: OverviewNoteFormSubmitArguments) => {
    const { id, body, title: initialTitle, type } = updatedNote ?? {}
    const title = initialTitle.trim()

    if (id) {
      try {
        const updateNoteResponse = await updateNote({
          variables: {
            noteInput: {
              body,
              id,
              title,
              tripId,
              type,
            },
          },
        })
        const noteId = updateNoteResponse?.data?.updateNote?.id
        const updatedNoteInTrip = updatedNote?.trip?.notes?.find(
          n => n.id === noteId
        )
        pushTripNoteUpdateToDataLayer({
          itemId: noteId,
          itemUpdatedAt: updatedNoteInTrip?.updatedAt,
          triggerVariant: 'OverviewNoteFormDrawer SaveButton',
          tripId: tripId,
          tripName: tripName,
        })

        if (showSnackbar) addSuccessSnack({ title: 'Note updated' })

        if (willClose) onClose()
      } catch (error) {
        console.error(error)

        if (showSnackbar) addErrorSnack({ title: 'Unable to update note' })
      }
      return
    }

    try {
      const createNoteResponse = await createNote({
        variables: {
          noteInput: {
            body,
            title,
            tripId,
            type,
          },
        },
      })
      pushTripNoteCreateToDataLayer({
        itemId: createNoteResponse?.data?.createNote?.id,
        insertedAt: createNoteResponse?.data?.createNote?.insertedAt,
        triggerVariant: 'OverviewNoteFormDrawer SaveButton',
        tripId: tripId,
        tripName: tripName,
      })

      if (showSnackbar) addSuccessSnack({ title: 'Note created' })

      if (willClose) onClose()
    } catch (error) {
      console.error(error)

      if (showSnackbar) addSuccessSnack({ title: 'Unable to create note' })
    }
  }

  return (
    <OverviewNoteForm
      isLoading={isLoading}
      note={note}
      onClose={onClose}
      onDelete={onDelete}
      onNoteChange={onNoteChange}
      onSubmit={onSubmit}
    />
  )
}
