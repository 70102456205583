import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'

export const getTripDetailsQuery = gql(`
  query GetTripDetailsQueryInTrips($tripId: ID!) {
    getTrip(id: $tripId) {
      id
      currentUserRole
      endDate
      guideDraft {
        id
        name
        status
      }
      guides {
        id
        insertedAt
        name
        status
        updatedAt
      }
      imageUrl
      name
      numberOfDays
      owner {
        id
      }
      startDate
      status
      timeZone
      tripPreference {
        id
        addresses {
          id
          addressLine1
          addressLine2
          city
          country
          googlePlaceId
          lat
          long
          state
          zipcode
        }
        adultGuests
        childrenGuests
      }
    }
  }
`)

type UseGetTripDetailsQuery = {
  hasError: ApolloError
  isLoading: boolean
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const useGetTripDetailsQuery = (
  tripId?: string
): UseGetTripDetailsQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getTripDetailsQuery, {
    skip: !tripId,
    variables: {
      tripId: tripId ?? '',
    },
  })

  return {
    hasError,
    isLoading,
    tripDetailsData: data?.getTrip ?? null,
  }
}
