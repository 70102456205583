import {
  Button,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import { type Collection } from 'src/__generated__/graphql'
import { useDeleteCollection } from './useDeleteCollection'

interface DeleteCollectionModalProps {
  onClose?(): void
  onCancel?(): void
  onConfirm?(shouldRefetch?: boolean): void
  collection?: Collection
}

export const DeleteCollectionModal = ({
  onClose,
  onConfirm,
  collection,
}: DeleteCollectionModalProps) => {
  const [deleteCollection] = useDeleteCollection()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const handleSubmit = async () => {
    try {
      const { errors } = await deleteCollection({
        variables: { input: { id: collection?.id } },
      })

      if (!errors) {
        addSuccessSnack({ title: 'Collection deleted successfully' })
        onConfirm && onConfirm(true)
      }
    } catch (error) {
      addErrorSnack({ title: 'Failed to delete collection' })
    }
  }

  return (
    <Modal size='medium' onDismiss={onClose}>
      <p className='text-h5 text-center'>
        Are you sure you want to delete your collection?
      </p>
      <p className='mt-10 text-center'>
        Deleting your collection will result in the loss of all associated
        information.
      </p>
      <ModalActions>
        <div className='gap3 flex w-full flex-col-reverse items-center md:flex-row'>
          <Button
            fullWidth
            label='Cancel'
            variant='outlined'
            onClick={onClose}
          />
          <Button fullWidth label='Delete Collection' onClick={handleSubmit} />
        </div>
      </ModalActions>
    </Modal>
  )
}
