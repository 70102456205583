import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_USER_COMPETITION_PHASE = gql(`
    query GetUserCompetitionPhase {
        currentUser {
            userProfile {
                competitionInfo {
                    currentPhase
                }
            }
        }
    }
`)

export const useGetUserCompetitionPhase = () =>
  useQuery(GET_USER_COMPETITION_PHASE)
