import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  NodeQueryInBookingDetailsDatesQuery,
  NodeQueryInBookingDetailsDatesQueryVariables,
} from 'src/__generated__/graphql'

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsDates($nodeId: ID!) {
    node(id: $nodeId) {
      ... on Booking {
        id
        arrival
        bookingDetails {
          occupancies {
            adults
            childAges
          }
        }
        departure
        status
        email
        user {
          id
        }
      }
    }
  }
`)

export const useBookingDetailsDatesQuery = ({
  bookingId,
}: {
  bookingId: NodeQueryInBookingDetailsDatesQueryVariables['nodeId']
}) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: !bookingId,
    variables: {
      nodeId: bookingId,
    },
  })

  const { node } = data ?? {}
  const bookingDetailsDatesData = node as Extract<
    NodeQueryInBookingDetailsDatesQuery['node'],
    { __typename?: 'Booking' }
  >

  return {
    hasError,
    bookingDetailsDatesData,
    isLoading,
  }
}
