import { useId } from 'react'
import type { CheckboxProps } from '@travelpass/design-system'
import { Checkbox } from '@travelpass/design-system'
import { useController } from 'react-hook-form'
import type { UseControllerProps } from 'react-hook-form'

type FormCheckboxProps<N> = UseControllerProps<N> &
  Omit<CheckboxProps, 'isChecked'>

export const FormCheckbox = <N,>({
  name,
  isDisabled = false,
  label,
  defaultChecked,
  ...props
}: FormCheckboxProps<N>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      required: props.required,
    },
  })

  const id = useId()
  const errorContentId = `${id}-error`

  return (
    <div>
      <Checkbox
        aria-errormessage={errorContentId}
        id={id}
        isChecked={field.value}
        isDisabled={isDisabled}
        label={label}
        name={field.name}
        // ref={field.ref} // TODO: add ref support to Checkbox
        onBlur={field.onBlur}
        onChange={field.onChange}
        {...props}
      />
      {!!error?.message && <p id={errorContentId}>{error?.message}</p>}
    </div>
  )
}
