import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { constructAddress } from 'src/utils'

const updateGuideMutation = gql(`
  mutation UpdateGuideMutationInGuide($updateGuideInput: UpdateGuideInput!) {
    updateGuide(updateGuideInput: $updateGuideInput) {
      guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        firstPublishedAt
        guideDraft {
          id
          hasUnpublishedChanges
        }
        images {
          id
          source
          url
        }
        imageUrl
        name
        owner {
          id
        }
        ownerProfile {
          id
          guides {
            id
          }
        }
        status
        tags {
          id
          name
        }
        updatedAt
      }
    }
  }
`)

export const useUpdateGuideMutation = () =>
  useMutation(updateGuideMutation, {
    onCompleted: data => {
      const {
        addresses,
        description,
        id,
        name,
        owner,
        status,
        tags,
        updatedAt,
      } = data?.updateGuide?.guide ?? {}
      const address = constructAddress({
        addressFirstLine: addresses?.[0]?.addressLine1,
        city: addresses?.[0]?.city,
        state: addresses?.[0]?.state,
      })
      pushDataToDataLayer('guide_update', {
        guide_description: description,
        /** @todo discuss in Guide Blooomreach Data meeting */
        guide_draft_id: id,
        guide_id: id,
        guide_location: address,
        guide_name: name,
        guide_status: status,
        travel_style: tags ?? [],
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })
    },
  })
