import type {
  AgeBandTypeEnum,
  EventStatus,
  EventType,
  GenericAddress,
  VoucherFormatEnum,
} from 'src/__generated__/graphql'

interface TripFormAddress {
  id?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  country?: string | null
  lat?: string | null
  long?: string | null
  state?: string | null
  zipcode?: string | null
  googlePlaceId?: string | null
}

type GenericEventType = {
  id: string
  addresses?: GenericAddress[]
  booking?: { id?: string; hotelName?: string; hotelId?: string }[]
  cost?: {
    amount?: string
    currency?: string
  }
  description?: string
  durationInMinutes?: number
  endDate?: string
  experienceBooking?: {
    id?: string
    externalConfirmationId?: string
    product?: {
      cancellationPolicy?: {
        description?: string
      }
    }
    paxMix?: {
      ageBand?: AgeBandTypeEnum
      numberOfTravelers?: number
    }[]
    voucherInfo?: {
      format?: VoucherFormatEnum
      url?: string
    }
  }[]
  externalTypeId?: string
  imageUrl?: string
  isCustom?: boolean
  isDeleted?: boolean
  name?: string
  notes?: string
  productId?: string
  startDate?: string
  status?: EventStatus
  trip?: {
    id: string
    startDate?: string
    endDate?: string
    timeZone?: string
  }
  type?: EventType
  useEventTime?: boolean
}

/**
 * Enum representing different types of drawers.
 * @todo remove booked and unbooked variables, requires standardizing drawers
 * @enum {number}
 */
enum DrawerType {
  /**
   * Represents a drawer for an event for an hotel
   * included in travelpass' inventory.
   */
  InternalHotel = 'InternalHotel',

  /**
   * Represents a drawer for an event for a booked
   * hotel inclucded in travelpass' inventory.
   */
  InternalHotelBooked = 'InternalHotelBooked',

  /**
   * Represents a drawer for an event for a unbooked
   * hotel included in travelpass' inventory.
   */
  InternalHotelUnbooked = 'InternalHotelUnbooked',

  /**
   * Represents a drawer for an event for an
   * hotel excluded from travelpass' inventory.
   */
  ExternalHotel = 'ExternalHotel',

  /**
   * Represents a drawer for an event for an experience
   * included in travelpass' inventory.
   */
  InternalExperience = 'InternalExperience',

  /**
   * Represents a drawer for an event for a booked
   * experience included in travelpass' inventory.
   */
  InternalExperienceBooked = 'InternalExperienceBooked',

  /**
   * Represents a drawer for an event for a unbooked
   * experience included in travelpass' inventory.
   */
  InternalExperienceUnbooked = 'InternalExperienceUnbooked',

  /**
   * Represents a drawer for a Goople Places event.
   */
  ExternalExperience = 'ExternalExperience',

  /**
   * Represents a drawer for a miscellanious event.
   */
  EventCustom = 'EventCustom',

  /**
   * Represents a drawer for a flight event.
   */
  EventFlight = 'EventFlight',

  /**
   * Represents a drawer for a transportation event.
   */
  EventTransportation = 'EventTransportation',
}

export { type TripFormAddress, type GenericEventType, DrawerType }
