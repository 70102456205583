import type { ChangeEvent, KeyboardEvent, RefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { KeyCode, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { useGetGuidePlaceDetailsLazyQuery } from 'src/pages/trips/hooks'
import { getGuidePlaceLatLng } from 'src/utils'
import { getGuideDraftCreatePublishedVariables } from './guideDraftUtils'
import type { GuideDraftDataOld } from './types'
import { useCreateGuideDraftEventMutation } from './useCreateGuideDraftEventMutation'
import type { UseGetGuideDraftQuery } from './useGetGuideDraftQuery'
import {
  GuideSearchParam,
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageGeocoder,
} from '../details'

const count = 20

const cacheExpiration = 24 * 60 * 60 // 24 hours

const cacheKey = 'upa-text-search'

type UseGuideDraftGeocoder = {
  isLoading: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  onOptionSelect: (place: google.maps.places.PlaceResult) => void
  onSectionIdChange: (updatedSectionId: string) => void
  options: google.maps.places.PlaceResult[]
  sectionId?: string
  showOptions: boolean
}

export const useGuideDraftGeocoder = ({
  autocompleteRef,
  id,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchValueChange,
  paginatedEventCategoryId,
  searchValue,
  willAddEvent,
}: {
  autocompleteRef: RefObject<HTMLInputElement>
  id: GuideDraftDataOld['id']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  paginatedEventCategoryId?: string
  searchValue?: string
  willAddEvent?: boolean
}): UseGuideDraftGeocoder => {
  const [createPublishedEvent] = useCreateGuideDraftEventMutation()
  const [getPlaceDetails] = useGetGuidePlaceDetailsLazyQuery()
  const sessionStorageGeocoder = useGuideSessionStorageGeocoder()
  const geocoderRef = useRef<google.maps.places.PlacesService>(null)
  const { addSuccessSnack, addMailSnack, addErrorSnack } = useSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<google.maps.places.PlaceResult[]>([])
  const [sectionId, setSectionId] = useState(paginatedEventCategoryId ?? '')
  const [searchParams, setSearchParams] = useSearchParams()
  const debouncedSearch = useCallback(
    debounce(updatedValue => {
      onSearch({
        updatedValue,
      })
    }, 300),
    []
  )
  const showOptions = searchValue?.trim() && !!options?.length

  useEffect(() => {
    if (window?.google?.maps) {
      geocoderRef.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    debouncedSearch(searchValue)
  }, [searchValue])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value
    onSearchValueChange(updatedValue)
  }

  const onClear = () => {
    setOptions([])
    onPlacePointsChange([])
    onSearchValueChange('')

    if (autocompleteRef.current) {
      autocompleteRef.current.value = ''
      autocompleteRef.current?.focus() // return focus to input
    }

    onResult(initialGeocoder)
  }

  const onKeyDown = event => {
    if (event.key === KeyCode.ESC) {
      onClear()
    }

    if (event.key === KeyCode.ENTER) {
      // prevent default to stop accidental form submit when user selects option with ENTER key
      event.preventDefault()

      if (showOptions) onOptionSelect(options?.[0])
    }
  }

  const onOptionSelect = (
    selectedSuggestion: google.maps.places.PlaceResult
  ) => {
    const { formatted_address, geometry, name, place_id, types } =
      selectedSuggestion ?? {}
    autocompleteRef.current.value = name

    if (!willAddEvent && geometry?.location) {
      const { lat, lng } = getGuidePlaceLatLng(geometry?.location)
      onResult({
        center: [lat, lng],
        placeName: name,
        placeId: place_id,
      })
      return
    }

    if (place_id === 'SEARCH') {
      searchParams.set(GuideSearchParam.mapFullView, 'true')
      setSearchParams(searchParams, {
        replace: true,
      })
      return
    }

    onSearchValueChange('')
    setOptions([])
    pushDataToDataLayer('select_geocoder_text_search_option', {
      item_category_id: paginatedEventCategoryId,
      item_id: place_id,
      item_location: formatted_address,
      item_name: name,
      item_types: types,
    })
    getGeocode({
      address: formatted_address,
    }).then(data => {
      const { address_components, geometry, types } = data?.[0]
      const { lat, lng } = getLatLng(data?.[0])
      onResult({
        addressComponents: address_components,
        center: [lat, lng],
        placeName: name,
        placeId: place_id,
        types,
        viewport: geometry?.viewport,
      })
    })
  }

  const onResult = async (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      if (!willAddEvent) {
        onGuideSessionStorageHoverIdChange(updatedGeocoder?.placeId)
        onMapMarkerCenterChange({
          lat: updatedGeocoder?.center?.[0],
          lng: updatedGeocoder?.center?.[1],
        })
      } else {
        try {
          const response = await getPlaceDetails({
            variables: {
              includeImageLinks: true,
              placeDetailsRequest: {
                placeId: updatedGeocoder?.placeId,
              },
            },
          })
          addMailSnack({
            title: 'Adding to guide...',
          })
          await createPublishedEvent({
            variables: getGuideDraftCreatePublishedVariables({
              googlePlaceId: updatedGeocoder?.placeId,
              guideDraftId: id,
              placeDetailsData: response.data,
              paginatedEventCategoryId: sectionId,
              type: response.data?.getPlaceDetails?.type,
            }),
          })
          addSuccessSnack({
            timeout: 1000,
            title: 'Added to Guide!',
          })
        } catch (error) {
          console.error(error)
          addErrorSnack({
            timeout: 1000,
            title: 'Server error',
          })
        }

        onPlacePointsChange([])
      }
    }
  }

  const onSearch = ({ updatedValue }: { updatedValue: string }) => {
    const config = {
      bounds: sessionStorageGeocoder?.bounds,
      location: {
        lat: sessionStorageGeocoder?.location?.lat ?? location?.latitude,
        lng: sessionStorageGeocoder?.location?.lng ?? location?.longitude,
      },
    }
    const validatedValue = updatedValue?.trim()
    const { lat, lng } = config?.location ?? ({} as google.maps.LatLng)
    const cacheItemKey = `${lat ?? ''}-${lng ?? ''}-${validatedValue}`

    if (!geocoderRef?.current || !validatedValue) return onClear()

    let cachedData: Record<
      string,
      {
        data: google.maps.places.PlaceResult[]
        maxAge: number
      }
    > = {}

    try {
      cachedData = JSON.parse(sessionStorage.getItem(cacheKey) || '{}')
    } catch (error) {
      console.error(error)
    }

    cachedData = Object.keys(cachedData).reduce(
      (total: typeof cachedData, key) => {
        if (cachedData[key].maxAge - Date.now() >= 0)
          total[key] = cachedData[key]

        return total
      },
      {}
    )

    if (cachedData[cacheItemKey]) {
      const updatedOptions =
        cachedData?.[cacheItemKey]?.data?.slice(0, count) ?? []
      onPlacePointsChange(updatedOptions)
      setOptions(updatedOptions)
      return
    }

    geocoderRef.current.textSearch(
      {
        ...config,
        query: validatedValue,
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const updatedOptions = results?.slice(0, count) ?? []
          onPlacePointsChange(updatedOptions)
          setOptions(updatedOptions)
          pushDataToDataLayer('geocoder_text_search_results', {
            item_category_id: paginatedEventCategoryId,
            item_options: updatedOptions,
            item_value: validatedValue,
          })
          cachedData[cacheItemKey] = {
            data: results,
            maxAge: Date.now() + cacheExpiration * 1000,
          }

          try {
            sessionStorage.setItem(cacheKey, JSON.stringify(cachedData))
          } catch (error) {
            console.error(error)
          }
        }
      }
    )
  }

  const onSectionIdChange = (updatedSectionId: string) =>
    setSectionId(updatedSectionId)

  return {
    isLoading,
    onChange,
    onKeyDown,
    onOptionSelect,
    onSectionIdChange,
    options,
    sectionId,
    showOptions,
  }
}
