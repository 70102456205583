import classNames from 'classnames'
import type { MarkerColor } from 'src/component-library/theme/colors'

interface DefaultMarkerProps {
  color: MarkerColor
  disableHover?: boolean
  isSelected?: boolean
}

export const DefaultMarker = ({
  color,
  disableHover,
  isSelected = false,
}: DefaultMarkerProps): JSX.Element => {
  return (
    <svg
      className={classNames({
        'hover:scale-150': !disableHover,
        'scale-150': isSelected,
      })}
      fill='none'
      height='64'
      viewBox='0 0 64 64'
      width='64'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M31.9997 16.1667C25.3497 16.1667 19.333 21.2651 19.333 29.1501C19.333 34.1851 23.2122 40.1068 30.9547 46.9309C31.5563 47.4534 32.4588 47.4534 33.0605 46.9309C40.7872 40.1068 44.6663 34.1851 44.6663 29.1501C44.6663 21.2651 38.6497 16.1667 31.9997 16.1667ZM31.9997 33.5834C34.623 33.5834 36.7497 31.4568 36.7497 28.8334C36.7497 26.2101 34.623 24.0834 31.9997 24.0834C29.3763 24.0834 27.2497 26.2101 27.2497 28.8334C27.2497 31.4568 29.3763 33.5834 31.9997 33.5834Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  )
}
