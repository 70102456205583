import { useEffect } from 'react'
import { useFlag } from 'src/common/hooks'
import { useUserGuestInfoQuery } from 'src/pages/experiences/booking/useUserGuestInfoQuery'
import { useFormContext, type BookingFormFields } from './useBookingForm'
import { CURRENT_USER_ADDRESS_FIELDS_KEYS } from '../bookingConstants'
import type { CurrentUserAddressFields } from '../bookingTypes'

export const useAutofillGuestInfo = () => {
  const checkoutV2 = useFlag('checkoutV2')
  const { getValues, setValue, reset } = useFormContext()

  const { data, loading } = useUserGuestInfoQuery()

  const currentUser = data?.currentUser
  const currentUserAsArray = Object.entries(currentUser ?? {})
  const currentUserSavedInfo = currentUserAsArray?.filter(([key, value]) => {
    return value && key !== '__typename' && key !== 'id'
  })
  const currentUserSavedAddressInfo = currentUserSavedInfo?.filter(
    ([key, value]: [keyof CurrentUserAddressFields, string]) => {
      return !!value && CURRENT_USER_ADDRESS_FIELDS_KEYS.includes(key)
    }
  )
  const hasSavedUserData = currentUserSavedInfo?.length > 0
  const hasSavedUserAddressData = currentUserSavedAddressInfo?.length > 0

  useEffect(() => {
    if (!checkoutV2 || loading) return

    if (hasSavedUserData) {
      reset()

      const mappedValues = {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        phone: currentUser?.phoneNumber,
        email: currentUser?.email,
        address1: currentUser?.addressFirstLine,
        address2: currentUser?.addressSecondLine,
        city: currentUser?.city,
        postalCode: currentUser?.zip,
        stateProvince: currentUser?.state,
        country: currentUser?.country,
      }

      Object.keys(mappedValues)?.map((key: keyof BookingFormFields) => {
        if (key === 'country') {
          mappedValues[key] && setValue(key, mappedValues[key])
        }
        if (!getValues(key) && mappedValues[key]) {
          setValue(key, mappedValues[key])
        }
      })
    }
  }, [currentUser, hasSavedUserData, loading])

  return checkoutV2 ? { hasSavedUserAddressData } : {}
}
