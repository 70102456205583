import { Skeleton } from '@travelpass/design-system'

export const ExperienceLoading = () => (
  <section className='flex h-full w-full flex-row items-center justify-center px-8'>
    <Skeleton variant='rounded' />
    <header>
      <h1 className='sr-only'>Content loading</h1>
    </header>
  </section>
)
