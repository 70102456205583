import { useState } from 'react'
import type { DraggableAttributes } from '@dnd-kit/core'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { AccordionButton } from '@reach/accordion'
import { Icon, Tag } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideDraftSectionDeleteModal } from './GuideDraftSectionDeleteModal'
import { GuideDraftSectionEditModal } from './GuideDraftSectionEditModal'
import type { GuideDraftDragItem } from '../../types'

const getCountText = (paginatedEventCategory: GuideDraftDragItem) =>
  `(${paginatedEventCategory?.publishedEvents?.length ?? 0})`

interface GuideDraftSectionHeaderProps {
  attributes: DraggableAttributes
  isDraggingOrOverlay: boolean
  isExpanded: boolean
  listeners: SyntheticListenerMap
  paginatedEventCategory: GuideDraftDragItem
}

export const GuideDraftSectionHeader = ({
  attributes,
  isDraggingOrOverlay,
  isExpanded,
  listeners,
  paginatedEventCategory,
}: GuideDraftSectionHeaderProps) => {
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const { description, name } = paginatedEventCategory ?? {}

  const onModalEditOpen = () => setIsModalEditOpen(true)

  return (
    <>
      <header className='p-x-8 lg:m-l--8 lg:p-l-8 group relative'>
        <button
          className={classNames(
            'c-forest-dark lg:op-100 transition-opacity-100 b-none group-hover:op-100 absolute left-0 top-0 w-6 cursor-pointer bg-transparent p-0 ease-linear group-hover:visible',
            {
              'lg:op-100 lg:visible': isDraggingOrOverlay,
              'lg:invisible': !isDraggingOrOverlay,
            }
          )}
          {...attributes}
          {...listeners}
        >
          <Icon name='draggable' />
        </button>
        <div className='of-hidden flex flex-row items-center gap-3'>
          <h4 className='color-grey-900 type-h6 line-clamp-3'>
            {name} {getCountText(paginatedEventCategory)}
          </h4>
          {/** @todo remove when IconButton supports a smaller size */}
          <button
            className='b-none relative cursor-pointer bg-transparent p-0 outline-none'
            onClick={onModalEditOpen}
          >
            <Tag
              aria-label={`Edit ${name}`}
              color='newForestLight'
              startIcon='modeEditOutline'
              text='Edit'
            />
          </button>
        </div>
        {description && isExpanded && (
          <p className='c-grey-700 type-body-2'>{description}</p>
        )}
        {/** @todo This is a one-off implementation that should be refactored in the future. */}
        {!description && isExpanded && (
          <button
            className='focus:outline-valley c-grey-900 b-none p-none type-body-2 transition-color-100 hover:c-forest-dark cursor-pointer bg-transparent ease-linear'
            onClick={onModalEditOpen}
          >
            Add a description for this section
          </button>
        )}
        <AccordionButton className='color-forest-light b-none absolute right-0 top-0 flex cursor-pointer flex-col items-center justify-center bg-transparent p-0'>
          <Icon name={isExpanded ? 'arrowUpIos' : 'arrowDownIos'} />
        </AccordionButton>
      </header>
      {isModalDeleteOpen && (
        <GuideDraftSectionDeleteModal
          paginatedEventCategory={paginatedEventCategory}
          onDismiss={() => {
            setIsModalEditOpen(true)
            setIsModalDeleteOpen(false)
          }}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftSectionEditModal
          paginatedEventCategory={paginatedEventCategory}
          onDismiss={() => setIsModalEditOpen(false)}
          onSectionDeleteOpen={() => {
            setIsModalEditOpen(false)
            setIsModalDeleteOpen(true)
          }}
        />
      )}
    </>
  )
}
