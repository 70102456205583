import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import {
  ExperiencesCancellationPolicyModal,
  ExperiencesLanguagesOfferedModal,
} from 'src/pages/experiences/common/components'

interface ExperiencesDetailsHeroActionsProps {
  experiencesDetailsData: ExperienceProduct
}

export const OverviewActions = ({
  experiencesDetailsData,
}: ExperiencesDetailsHeroActionsProps) => {
  const [isCancellationPolicyModalOpen, setIsCancellationPolicyModalOpen] =
    useState(false)
  const [isLanguagesOfferedModalOpen, setIsLanguagesOfferedModalOpen] =
    useState(false)
  const { cancellationPolicy, languageGuides } = experiencesDetailsData ?? {}
  const showCancellationPolicy = !isEmpty(cancellationPolicy)
  const showLanguages = !isEmpty(languageGuides)

  return (
    (showCancellationPolicy || showLanguages) && (
      <>
        {isCancellationPolicyModalOpen && (
          <ExperiencesCancellationPolicyModal
            description={cancellationPolicy?.description}
            onClose={() => setIsCancellationPolicyModalOpen(false)}
          />
        )}
        {isLanguagesOfferedModalOpen && (
          <ExperiencesLanguagesOfferedModal
            languageGuides={languageGuides}
            onClose={() => setIsLanguagesOfferedModalOpen(false)}
          />
        )}
        <div className='flex flex-col gap-1 md:flex-row md:items-center md:justify-between md:gap-2'>
          {showCancellationPolicy && (
            <Button
              label='Cancellation Policy'
              startIcon='listAlt'
              variant='text'
              onClick={() => setIsCancellationPolicyModalOpen(true)}
            />
          )}
          {showLanguages && (
            <Button
              label='Languages Offered'
              startIcon='language'
              variant='text'
              onClick={() => setIsLanguagesOfferedModalOpen(true)}
            />
          )}
        </div>
      </>
    )
  )
}
