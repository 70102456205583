import type { OperationVariables } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  SendBookingEmailInput,
  SendEmailMutationInBookingDetailsMutationVariables,
} from 'src/__generated__/graphql'
import { EmailType } from 'src/__generated__/graphql'

const { Share } = EmailType

const constructSendEmailArguments = ({
  bookingId,
  emailType = Share,
  toEmail,
}: SendBookingEmailInput): SendEmailMutationInBookingDetailsMutationVariables => ({
  input: {
    bookingId,
    emailType,
    toEmail,
  },
})

const sendEmailMutation = gql(`
  mutation SendEmailMutationInBookingDetails($input: SendBookingEmailInput!) {
    sendBookingEmail(input: $input) {
      messageId
    }
  }
`)

const useBookingDetailsSendEmailMutation = (options?: OperationVariables) => {
  const mutation = useMutation(sendEmailMutation, options ?? {})

  return mutation
}

export { constructSendEmailArguments, useBookingDetailsSendEmailMutation }
