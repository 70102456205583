import { Skeleton } from '@travelpass/design-system'

export const DashboardCollectionsCardLoading = () => (
  <div className='rounded-2 flex cursor-pointer flex-col bg-white'>
    <div className='h-50 flex'>
      <Skeleton variant='rounded' />
    </div>
    <div className='py-4 pl-3'>
      <div className='min-h-8'>
        <div className='h-4 w-10 lg:h-5'>
          <Skeleton />
        </div>
      </div>
      <div className='h-4 w-20'>
        <Skeleton />
      </div>
    </div>
  </div>
)
