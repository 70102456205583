import { useEffect, useState } from 'react'
import { Button, Input, Modal, useSnackbar } from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import { useUpdateTripDayName } from './useUpdateTripDayName'

export const TripUpdateDayNameButton = ({
  dayName,
  dayIndex,
  tripId,
}: {
  dayName: string
  dayIndex: number
  tripId: string
}) => {
  const [showNameUpdateModal, setShowNameUpdateModal] = useState(false)
  const { handleSubmit, formState, register, setValue } = useForm<{
    name: string
  }>()
  const { name: nameError } = formState?.errors

  const { updateTripDayName, loading } = useUpdateTripDayName()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  useEffect(() => {
    setValue('name', dayName)
  }, [dayName])

  const onSubmit = async ({ name }: { name: string }) => {
    updateTripDayName({
      variables: {
        tripId,
        dayIndex,
        dayName: name,
      },
      onCompleted: () => {
        addSuccessSnack({
          title: 'Day name successfully changed',
        })
        setShowNameUpdateModal(false)
      },
      onError: () => {
        addErrorSnack({
          title: 'Something went wrong, please try again later',
        })
      },
    })
  }

  return (
    <>
      {showNameUpdateModal && (
        <Modal
          size='medium'
          title='Name Your Day'
          onDismiss={() => setShowNameUpdateModal(false)}
        >
          <form
            noValidate
            className='flex flex-col gap-10'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              fullWidth
              errorText={nameError && 'Max 50 characters'}
              helperText='Max 50 characters'
              isInvalid={!!nameError}
              {...register('name', { maxLength: 50 })}
            />
            <div className='flex justify-center gap-2'>
              <Button
                aria-label='Cancel day name update'
                variant='outlined'
                onClick={() => setShowNameUpdateModal(false)}
              >
                cancel
              </Button>
              <Button aria-label='Update day name' type='submit'>
                save changes
              </Button>
            </div>
          </form>
        </Modal>
      )}
      <div className='-m-l-2.5'>
        <Button
          aria-label='Edit day name'
          endIcon='modeEdit'
          isDisabled={loading}
          size='small'
          variant='text'
          onClick={() => setShowNameUpdateModal(true)}
        >
          {dayName}
        </Button>
      </div>
    </>
  )
}
