import { useEffect, useRef, useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { useIsElementOnScreen } from 'src/common/hooks'
import type { experienceTagType } from 'src/constants'
import { experienceTagsPrioritized } from 'src/constants'
import { decodeAndValidateExpSearchParams } from 'src/utils/experienceSearchParamsUtils'
import { ExperienceTag } from './ExperienceTag'
import {
  generateAddTagRoute,
  generateRemoveTagRoute,
} from './experienceResultsTagUtils'
import './experienceTags.css'

export const ExperienceResultsTags = () => {
  const [selectedTags, setSelectedTags] = useState<[] | experienceTagType[]>([])
  const [unselectedTags, setUnselectedTags] = useState(
    experienceTagsPrioritized
  )
  const [lastTag, setLastTag] = useState<null | experienceTagType>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const scrollContainerRef = useRef(null)
  const lastTagRef = useRef(null)

  const [searchParams] = useSearchParams()

  // extract the selected tags from the url
  const { tags } = decodeAndValidateExpSearchParams(searchParams)
  const urlTags = tags ?? []

  // last tag of list is monitored to control scrolling buttons
  const isLastTagOnPage = useIsElementOnScreen(lastTagRef)
  const lastTagRoute =
    lastTag &&
    generateAddTagRoute({ urlTags, tagId: lastTag?.tagId, searchParams })

  // enable / disable buttons based on scroll position and visibility of last tag
  const onScroll = () => {
    const updatedLeftScroll = scrollContainerRef?.current?.scrollLeft !== 0

    setCanScrollLeft(updatedLeftScroll)
    setCanScrollRight(!isLastTagOnPage)
  }

  const onButtonClick = (scrollOffset: number) => {
    const scrollContainer = scrollContainerRef.current

    // Calculate the new scroll position
    const newScrollPosition = scrollContainer.scrollLeft + scrollOffset

    // Animate the scroll using smooth behavior
    scrollContainer.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    })
  }

  const renderContent = () => {
    const selectedTagComponents = selectedTags.map(({ tagName, tagId }) => {
      const route = generateRemoveTagRoute({ urlTags, tagId, searchParams })
      return (
        <ExperienceTag key={tagId} isSelected label={tagName} route={route} />
      )
    })

    const unselectedTagComponents = unselectedTags.map(({ tagName, tagId }) => {
      const route = generateAddTagRoute({ urlTags, tagId, searchParams })
      return <ExperienceTag key={tagId} label={tagName} route={route} />
    })

    return [...selectedTagComponents, ...unselectedTagComponents]
  }

  useEffect(() => {
    const updatedSelectedTags = []
    const updatedUnselectedTags = []

    // sort tags into arrays
    experienceTagsPrioritized.forEach(ogTag => {
      if (urlTags.includes(ogTag.tagId)) {
        updatedSelectedTags.push(ogTag)
      } else {
        updatedUnselectedTags.push(ogTag)
      }
    })

    // identify last tag
    const lastTagId = updatedUnselectedTags.length - 1
    const updatedLastTag = updatedUnselectedTags[lastTagId]
    updatedUnselectedTags.pop()

    setSelectedTags(updatedSelectedTags)
    setUnselectedTags(updatedUnselectedTags)
    setLastTag(updatedLastTag)
  }, [searchParams])

  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current

    if (scrollContainer && scrollContainer?.scrollLeft !== 0) {
      scrollContainer?.scrollTo({ left: 0 })
    }
  }, [selectedTags])

  return (
    // Container
    <div className='b-1 border-b-solid b-gray200 px-15 flex hidden items-center justify-between gap-2 overflow-hidden bg-white py-2 md:flex'>
      <div className='w-fit'>
        <IconButton
          color='valley'
          icon='chevronLeft'
          isDisabled={!canScrollLeft}
          size='large'
          onClick={() => onButtonClick(-500)}
        />
      </div>
      {/* content container */}
      <div className='flex h-full w-full items-center justify-center overflow-hidden'>
        {/* content */}
        <div
          className='element-with-scrollbar flex h-full w-full touch-pan-x snap-x flex-row items-center justify-start gap-2 overflow-x-auto overscroll-contain'
          ref={scrollContainerRef}
          role='presentation'
          onScroll={onScroll}
        >
          {renderContent()}
          <div ref={lastTagRef}>
            <ExperienceTag label={lastTag?.tagName} route={lastTagRoute} />
          </div>
        </div>
      </div>
      <div className='w-fit'>
        <IconButton
          color='valley'
          icon='chevronRight'
          isDisabled={!canScrollRight}
          size='large'
          onClick={() => onButtonClick(500)}
        />
      </div>
    </div>
  )
}
