import isEmpty from 'lodash.isempty'
import { PageLayoutContainer } from 'src/common/components'
import { DestinationFaqDetails } from '../common/DestinationFaqDetails'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocationFaqs = ({
  destination,
}: DestinationLocationProps) => {
  const { address, faqs } = destination ?? {}
  const { city } = address ?? {}
  const title = `Frequently Asked Questions about ${city}`

  return !isEmpty(faqs) ? (
    <div className='p-y-10 block w-full bg-white'>
      <PageLayoutContainer>
        <div className='lg:flex-items-start block lg:flex'>
          <div className='lg:w-20% lg:m-r-5% block w-full'>
            <div className='type-h1 p-b-3 lg:p-t-14 lg:p-b-9'>FAQs</div>
            <h2 className='type-subtitle-2 lg:line-height-6 color-grey-700'>
              {title}
            </h2>
          </div>
          <div className='lg:w-75% p-t-10 block w-full'>
            <DestinationFaqDetails destination={destination} />
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  ) : null
}
