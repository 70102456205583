import type { FirebaseOptions } from 'firebase/app'
import { Environment, env } from 'src/utils'

const firebaseConfigInt: FirebaseOptions = {
  apiKey: 'AIzaSyBgcPBVcNO82loCG0W6fbiwI3N3GUQZdqc',
  authDomain: 'travelpassgroup-dev.firebaseapp.com',
  projectId: 'travelpassgroup-dev',
  storageBucket: 'travelpassgroup-dev.appspot.com',
  messagingSenderId: '136185852772',
  appId: '1:136185852772:web:f9f3167cd349de69740071',
  measurementId: 'G-X2S0CYEXJ7',
}

const firebaseConfigStg: FirebaseOptions = {
  apiKey: 'AIzaSyCysKEVbdHQhdE4eUPHeE1aGnpksMqT52Q',
  authDomain: 'travelpassgroup-stg.firebaseapp.com',
  projectId: 'travelpassgroup-stg',
  storageBucket: 'travelpassgroup-stg.appspot.com',
  messagingSenderId: '372162419236',
  appId: '1:372162419236:web:2133413f54f04adcd0d6b1',
}

const firebaseConfigProd: FirebaseOptions = {
  apiKey: 'AIzaSyDp6httRGfg6KENfBW4WBJWY5nTlv7bJy0',
  authDomain: 'travelpassgroup-prod.firebaseapp.com',
  projectId: 'travelpassgroup-prod',
  storageBucket: 'travelpassgroup-prod.appspot.com',
  messagingSenderId: '1057092318510',
  appId: '1:1057092318510:web:e587d4c2e262bb916376bf',
  measurementId: 'G-EFL1C57SH4',
}

const firebaseConfigObject = {
  [Environment.INT]: firebaseConfigInt,
  [Environment.STG]: firebaseConfigStg,
  [Environment.PROD]: firebaseConfigProd,
}

const firebaseConfig = firebaseConfigObject[env]

export { firebaseConfig }
