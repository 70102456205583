import type { BookingEdge } from 'src/__generated__/graphql'
import '../common/styles/bookingsResultsCustomStyles.css'
import { HotelBooking } from './HotelBooking'
import { bookingsListContainerCss } from '../common/constants'

interface HotelBookingsListProps {
  bookings: BookingEdge[]
}

export const HotelBookingsList = ({ bookings }: HotelBookingsListProps) => (
  <div className={bookingsListContainerCss}>
    {bookings?.map(booking => (
      <HotelBooking
        key={booking?.node?.id}
        booking={booking}
        className='min-w-74'
      />
    ))}
  </div>
)
