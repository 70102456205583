import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { gql } from 'src/__generated__'
import type {
  ExperienceActivityItinerary,
  ExperienceProduct,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { DrawerType } from 'src/pages/trips/constants'
import type { TripMapPoint } from 'src/pages/trips/types'

const searchExperiencesQuery = gql(`
  query SearchExperiencesForRecommendedEvents($first: Int!, $searchExperiencesArgs: SearchExperiencesArgs!) {
    searchExperiences(first: $first, searchExperiencesArgs: $searchExperiencesArgs) {
      edges {
        node {
          id
          availabilitySchedule {
            summary {
              fromPrice {
                amount
              }
            }
          }
          description
          destinations {        
            latitude
            longitude
          }
          images {
            size720x480
          }
          itinerary {
            ... on ExperienceStandardItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceActivityItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceUnstructuredItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceHopOnHopOffItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
          }
          logistics {        
            start {
              location {
                latitude
                longitude
              }
            }
          }
          reviews {
            combinedAverageRating
            totalReviews
          }
          title
          productCode
        }
      }
    }
  }
`)

export const useGetRecommendedEventsForExperienceEvent = ({
  arrival,
  departure,
  latitude,
  longitude,
  tracker,
}: {
  arrival: string
  departure: string
  latitude: string
  longitude: string
  tracker: string
}) => {
  const arrivalFormatted = dayjs(arrival).format('YYYY-MM-DD')
  const departureFormatted = dayjs(departure).format('YYYY-MM-DD')
  let departureFinal =
    arrivalFormatted === departureFormatted
      ? dayjs(departure).add(1, 'day').format('YYYY-MM-DD')
      : departureFormatted

  const { data, loading, error } = useQuery(searchExperiencesQuery, {
    skip: !latitude || !longitude,
    variables: {
      first: 12,
      searchExperiencesArgs: {
        arrival: arrivalFormatted,
        departure: departureFinal,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        tracker,
      },
    },
  })

  const experienceEdges = data?.searchExperiences?.edges ?? null

  const experiences =
    experienceEdges?.reduce<RecommendedExperiencePoint[]>(
      (experiencePoint, edge) => {
        if (edge?.node) {
          const mapPoint = createMapPoint(edge?.node)
          if (mapPoint) experiencePoint.push(mapPoint)
        }

        return experiencePoint
      },
      []
    ) ?? null

  return {
    experiences,
    loading,
    error,
  }
}

type RecommendedExperiencePoint = TripMapPoint & {
  durationInMindes: number
  productCode: string
  description: string
  nightlyAverage?: string
}

const createMapPoint = (experience?: ExperienceProduct | null) => {
  if (experience) {
    const address1 = experience.logistics?.start?.[0]?.location ?? null
    const address2 = experience.destinations?.[0] ?? null

    const latitude = address1?.latitude ?? address2?.latitude
    const lat = latitude ? latitude : ''

    const longitude = address1?.longitude ?? address2?.longitude
    const lng = longitude ? longitude : ''

    const result: RecommendedExperiencePoint = {
      id: experience.id,
      imageUrl: experience.images?.size720x480?.[0] ?? '',
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      active: true,
      drawerType: DrawerType.InternalExperience,
      title: experience?.title ?? '',
      // Hotels are 1 - 10 scale. Experiences are 1 - 5. This just makes them relatively equal.
      customerReviewScore: experience.reviews?.combinedAverageRating ?? 0,
      durationInMindes: 0,
      nightlyAverage:
        experience?.availabilitySchedule?.summary?.fromPrice?.amount ?? '',
      productCode: experience.productCode ?? '',
      description: experience.description ?? '',
      type: EventType.Experience,
      totalReviews: experience.reviews?.totalReviews ?? 0,
      variant: 'explore',
    }

    const itinerary = experience?.itinerary ?? null
    if (itinerary?.__typename !== 'ExperienceMultidayItinerary') {
      const itineraryWithDuration = itinerary as
        | ExperienceActivityItinerary
        | ExperienceStandardItinerary
        | ExperienceUnstructuredItinerary

      result.durationInMindes =
        itineraryWithDuration?.duration?.fixedDurationInMinutes ?? 0
    }

    return result
  }
  return null
}
