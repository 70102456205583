import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import type { ExperienceSearchQuery } from 'src/__generated__/graphql'
import { ProductCard } from 'src/common/components'
import { initialArrivalDate, initialDepartureDate } from 'src/constants/user'
import {
  generateExperienceDetailsUrl,
  getDateDiff,
  GRAPHQL_DATE_FMT,
} from 'src/utils'
import { validateAndFormatImages } from './common'

interface ExperienceSearchResultsProps {
  experiences: ExperienceSearchQuery['experienceSearch']['edges']
}

export const ExperienceSearchResults = ({
  experiences,
}: ExperienceSearchResultsProps) => {
  const [searchParams] = useSearchParams()
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const isDefaultTravelDates =
    getDateDiff([arrival, initialArrivalDate.format(GRAPHQL_DATE_FMT)]) === 0 &&
    getDateDiff([departure, initialDepartureDate.format(GRAPHQL_DATE_FMT)]) ===
      0

  const managedDeparture = isDefaultTravelDates
    ? dayjs(arrival).add(3, 'd')
    : departure

  return (
    <>
      {experiences?.map(({ node } = {}) => {
        const {
          id,
          cancellationPolicy,
          images,
          fromPrice,
          readableDuration,
          pricingInfo,
          reviews,
          title,
          isFavorited,
          isCollected,
        } = node ?? {}

        const imageLinks = validateAndFormatImages(images?.size360x240)
        const { combinedAverageRating, totalReviews } = reviews ?? {}
        const route = generateExperienceDetailsUrl({
          arrival,
          departure: managedDeparture,
          id,
          name: title,
        })

        return (
          <ProductCard
            key={id}
            productCardData={{
              id,
              cancellationPolicy: cancellationPolicy?.type,
              customerReviewScore: combinedAverageRating,
              customerReviewScoreScale: 5,
              displayFrom: true,
              displayPriceUnit: true,
              duration: readableDuration,
              name: title,
              images: imageLinks,
              priceUnit: pricingInfo?.unitType,
              primaryPrice: fromPrice?.amount,
              route,
              strikethroughPrice: null,
              totalCustomerReviews: totalReviews,
              isFavorited,
              isCollected,
            }}
          />
        )
      })}
    </>
  )
}
