import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import type { UnderlineTabProps } from '@travelpass/design-system'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  UnderlineTab,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { type Note } from 'src/__generated__/graphql'
import {
  getOverviewNoteFormNavActiveIndex,
  getOverviewNoteFormNavNoteType,
} from './overviewNoteFormUtils'

const tabLabelsWithIcons: Pick<
  UnderlineTabProps['tabs'][0],
  'icon' | 'label'
>[] = [
  {
    icon: 'listAlt',
    label: 'Note',
  },
  {
    icon: 'taskAlt',
    label: 'Checklist',
  },
  {
    icon: 'link',
    label: 'Link',
  },
]

interface OverviewNoteFormNavProps {
  note: Note
  onNoteChange(updatedNote: Note): void
}

export const OverviewNoteFormNav = ({
  note,
  onNoteChange,
}: OverviewNoteFormNavProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [nextActiveIndex, setNextActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const tabs = tabLabelsWithIcons.reduce((total, { icon, label }, index) => {
    if (
      !note?.id ||
      (note?.id && note?.type === getOverviewNoteFormNavNoteType(index))
    ) {
      total.push({
        icon,
        label,
        onClick: (event: MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()

          if (index !== activeIndex) {
            if (note?.body === '') {
              return onNoteChange({
                ...note,
                body: '',
                type: getOverviewNoteFormNavNoteType(index),
              })
            }

            setShowModal(true)
            setNextActiveIndex(index)
          }
        },
      })
    }

    return total
  }, [])

  const { type } = note ?? {}

  const onDismiss = () => {
    setShowModal(false)
  }

  /** @todo should call a note mutation and change the note to blank eventually, most likely */
  const onContinue = () => {
    onNoteChange({
      ...note,
      body: '',
      type: getOverviewNoteFormNavNoteType(nextActiveIndex),
    })
    setShowModal(false)
  }

  useEffect(() => {
    setActiveIndex(getOverviewNoteFormNavActiveIndex(note))
  }, [type])

  return (
    <>
      <div className={classNames({ 'flex flex-col items-center': !note?.id })}>
        <UnderlineTab
          activeIndex={activeIndex}
          tabs={tabs}
          tabsNotCapitalized={true}
          willScroll={false}
        />
      </div>
      {showModal && (
        <Modal size='small' title='Are You Sure?' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2.5 p-y-2.5 space-y-5'>
              <h4 className='type-h6-desktop'>Your notes will be lost!</h4>
              <p className='type-body-1-desktop'>
                You have unsaved changes in your notes. If you switch tabs now,
                you&apos;ll lose what you&apos;ve entered.
              </p>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button aria-label='Switch note type' onClick={onContinue}>
              Continue
            </Button>
            <Button
              aria-label='Cancel switch note type'
              variant='outlined'
              onClick={onDismiss}
            >
              Cancel
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
