import { useEffect, useRef, useState } from 'react'
import type { LazyQueryExecFunction } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import {
  GuideUrlSource,
  type GenericAddress,
  type GetPresignedUrlsLazyQuery,
  type GetPresignedUrlsLazyQueryVariables,
  type UserImage,
  PresignedUrlType,
} from 'src/__generated__/graphql'
import { useGetPresignedUrlsLazyQuery } from 'src/common/hooks'
import {
  guideDraftImagesLimit,
  guideDraftImageMaxSize,
} from './guideDraftConstants'
import { checkGuideDraftFileTypeIsValid } from './guideDraftUtils'
import { useAddGuideDraftImagesMutation } from './useAddGuideDraftImagesMutation'
import { useRemoveGuideDraftImagesMutation } from './useRemoveGuideDraftImagesMutation'
import { useUpdateGuideDraftMutation } from './useUpdateGuideDraftMutation'

const guideDraftImagesErrorMessages = {
  required: 'At least one image is required.',
  size: 'Images must be less than 5MB.',
  sizeAndType: 'Images must be less than 5MB and either JPG or PNG.',
  type: 'Images must be either JPG or PNG.',
  uploaded:
    'One or more of your images failed to upload because it did not meet our community guidelines.',
}

const getGuideDraftImagesUploadBlob = (
  imageFile: File
): Promise<FileReader['result']> =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = event => {
      resolve(event?.target?.result)
    }
    reader.readAsArrayBuffer(imageFile)
  })

const getGuideDraftImagesUploadCategories = ({
  existingImages,
  images,
}: {
  existingImages: GuideDraftImagesEditData['images']
  images: string[]
}) => {
  const existingUrlsSet = new Set(existingImages.map(image => image.url))
  const createdUrls = images.filter(url => !existingUrlsSet.has(url))
  const deletedIds = existingImages.reduce((total, current) => {
    if (!images.includes(current?.url)) total.push(current?.id)

    return total
  }, [])
  const unchangedIds = existingImages.reduce((total, current) => {
    if (images.includes(current?.url)) total.push(current?.id)

    return total
  }, [])

  return {
    createdUrls,
    deletedIds,
    unchangedIds,
  }
}

const getGuideDraftImagesUploadErrorMessage = ({
  isSizeValid,
  isTypeValid,
}: {
  isSizeValid: boolean
  isTypeValid: boolean
}): string => {
  if (!isSizeValid && !isTypeValid)
    return guideDraftImagesErrorMessages.sizeAndType

  if (!isSizeValid) return guideDraftImagesErrorMessages.size

  if (!isTypeValid) return guideDraftImagesErrorMessages.type

  return ''
}

const getGuideDraftImagesUploadUrls = async ({
  createdUrls,
  getPresignedUrls,
}: {
  createdUrls: string[]
  getPresignedUrls: LazyQueryExecFunction<
    GetPresignedUrlsLazyQuery,
    GetPresignedUrlsLazyQueryVariables
  >
}): Promise<
  {
    source: GuideUrlSource
    url: string
  }[]
> => {
  if (isEmpty(createdUrls)) return []

  const presignedUrlsResponse = await getPresignedUrls({
    fetchPolicy: 'network-only',
    variables: {
      count: createdUrls.length,
      presignedUrlType: PresignedUrlType.GuideImages,
    },
  })
  const presignedUrls = presignedUrlsResponse?.data?.getPresignedUrls ?? []

  if (presignedUrls.length !== createdUrls.length) {
    console.error('Mismatch between number of images and presigned URLs')
    return []
  }

  const uploadedUrls = await Promise.all(
    createdUrls.map(async (image, index) => {
      try {
        const presignedUrl = presignedUrls[index]

        if (!presignedUrl) {
          console.error(
            `Failed to get presigned URL for image at index ${index}`
          )
          return null
        }

        const presignedUrlWithoutQuery = presignedUrl.split('?')[0]
        const blob = await fetch(image).then(response => response.blob())
        const uploadResponse = await fetch(presignedUrl, {
          method: 'PUT',
          body: blob,
        })

        if (!uploadResponse.ok) {
          console.error(`Failed to upload image at index ${index}`)
          return null
        }

        return {
          source:
            image.indexOf('pixabay') > -1
              ? GuideUrlSource.PixabayImage
              : GuideUrlSource.UserUploadedImage,
          url: presignedUrlWithoutQuery,
        }
      } catch (error) {
        console.error(error)
        return null
      }
    })
  )

  return uploadedUrls.filter(url => url !== null)
}

type GuideDraftImagesEditData = {
  addresses: GenericAddress[]
  id: string
  images: Partial<UserImage>[]
}

type UseGuideDraftImagesEdit = {
  imageError: string
  images: string[]
  isLoading: boolean
  onSelected: (updatedImage: string) => void
  onSubmit: VoidFunction
  onUpload: (updatedImagesFiles: FileList) => void
}

const useGuideDraftImagesEdit = ({
  guideDraftImagesEditData,
  onDismiss,
}: {
  guideDraftImagesEditData: GuideDraftImagesEditData
  onDismiss: VoidFunction
}): UseGuideDraftImagesEdit => {
  const [addGuideDraftImages] = useAddGuideDraftImagesMutation()
  const [getPresignedUrls] = useGetPresignedUrlsLazyQuery()
  const [imageError, setImageError] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const objectUrls = useRef<string[]>([])
  const [removeGuideDraftImages] = useRemoveGuideDraftImagesMutation()
  const [updateGuideDraft] = useUpdateGuideDraftMutation()

  useEffect(() => {
    return () => {
      objectUrls?.current?.forEach(url => URL.revokeObjectURL(url))
    }
  }, [])

  useEffect(() => {
    if (!guideDraftImagesEditData?.images?.length) return

    setImages(guideDraftImagesEditData?.images?.map(image => image?.url) ?? [])
  }, [guideDraftImagesEditData?.images])

  const onSelected = (updatedImage: string) => {
    const updatedImages = images.includes(updatedImage)
      ? images.filter(image => image !== updatedImage)
      : [...images, updatedImage]

    if (updatedImages.length > guideDraftImagesLimit) return

    setImages(updatedImages)
  }

  const onSubmit = async () => {
    if (isEmpty(images))
      return setImageError(guideDraftImagesErrorMessages.required)

    if (isLoading) return

    setIsLoading(true)
    const updatedSortOrder: string[] = []
    const { createdUrls, deletedIds, unchangedIds } =
      getGuideDraftImagesUploadCategories({
        existingImages: guideDraftImagesEditData?.images ?? [],
        images,
      })
    const uploadedUrls = await getGuideDraftImagesUploadUrls({
      createdUrls,
      getPresignedUrls,
    })

    if (createdUrls.length !== uploadedUrls.length)
      setImageError(guideDraftImagesErrorMessages.uploaded)

    try {
      if (deletedIds.length)
        await removeGuideDraftImages({
          variables: {
            input: {
              guideDraftId: guideDraftImagesEditData?.id,
              imageIds: deletedIds,
            },
          },
        })

      if (images?.length) {
        const addGuideDraftImagesResponses = []

        for (const { source, url } of uploadedUrls) {
          try {
            const response = await addGuideDraftImages({
              variables: {
                input: {
                  guideDraftId: guideDraftImagesEditData?.id,
                  source,
                  urls: [url],
                },
              },
            })
            addGuideDraftImagesResponses.push(response)
          } catch (error) {
            console.error(error)
          }
        }

        const filteredAddGuideDraftImageIds =
          addGuideDraftImagesResponses.reduce((total, current) => {
            const { id } =
              current?.data?.addGuideDraftImages?.addedImages?.[0] ?? {}

            if (id) total.push(id)

            return total
          }, [])
        updatedSortOrder.push(...filteredAddGuideDraftImageIds)
      }

      await updateGuideDraft({
        variables: {
          input: {
            guideDraftId: guideDraftImagesEditData?.id,
            imageSortOrder: unchangedIds.concat(updatedSortOrder),
          },
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      onDismiss()
    }
  }

  const onUpload = async (updatedImagesFiles: FileList) => {
    if (!updatedImagesFiles.length) return

    let updatedIsSizeValid = true
    let updatedIsTypeValid = true
    const validImages: string[] = []

    for (const imageFile of Array.from(updatedImagesFiles)) {
      const result = await getGuideDraftImagesUploadBlob(imageFile)

      if (result) {
        const isSizeValid = imageFile.size <= guideDraftImageMaxSize
        const isTypeValid = checkGuideDraftFileTypeIsValid({
          result,
          type: imageFile.type,
        })

        if (!isSizeValid) updatedIsSizeValid = false

        if (!isTypeValid) updatedIsTypeValid = false

        if (isSizeValid && isTypeValid) {
          const updatedObjectUrl = URL.createObjectURL(imageFile)
          validImages.push(updatedObjectUrl)
          objectUrls.current.push(updatedObjectUrl)
        }
      }
    }

    setImageError(
      getGuideDraftImagesUploadErrorMessage({
        isSizeValid: updatedIsSizeValid,
        isTypeValid: updatedIsTypeValid,
      })
    )
    setImages(previousImages => {
      const updatedImages = [...previousImages, ...validImages]

      return updatedImages.slice(0, guideDraftImagesLimit)
    })
  }

  return {
    imageError,
    images,
    isLoading,
    onSelected,
    onSubmit,
    onUpload,
  }
}

export { useGuideDraftImagesEdit }
export type { GuideDraftImagesEditData, UseGuideDraftImagesEdit }
