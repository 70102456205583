import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const CANCEL_EXPERIENCE_BOOKING_MUTATION = gql(
  `mutation Mutation($input: CancelExperienceBookingInput!) {
  cancelExperienceBooking(input: $input) {
    experienceBooking {
      id
      status
    }
    experienceCancellation {
      id
      reasonCode
      reasonText
      canceledBy
      status
      details {
        itemPrice {
          amount
          currency
        }
        refundAmount {
          amount
          currency
        }
        refundPercentage
      }
    }
  }
}`
)

export const useCancelExperienceBookingMutation = () =>
  useMutation(CANCEL_EXPERIENCE_BOOKING_MUTATION)
