import { Skeleton } from '@travelpass/design-system'
import { DashboardCollectionsCardLoading } from './DashboardCollectionCardLoading'

export const DashboardCollectionsLoading = () => (
  <div className='space-y-6'>
    <div className='w-42.5 h-6.5'>
      <Skeleton variant='rounded' />
    </div>
    <div className='grid grid-cols-1 content-center gap-6 md:grid-cols-2'>
      <DashboardCollectionsCardLoading />
      <DashboardCollectionsCardLoading />
      <DashboardCollectionsCardLoading />
      <DashboardCollectionsCardLoading />
      <DashboardCollectionsCardLoading />
      <DashboardCollectionsCardLoading />
    </div>
  </div>
)
