import { Skeleton } from '@travelpass/design-system'
import { Card } from '../Card/Card'

export const SkeletonCard = () => {
  return (
    <div className='w-550px max-w-full'>
      <Card>
        <div className='h-88px w-112px'>
          <Skeleton variant='rounded' />
        </div>
        <div className='flex flex-col gap-4 pr-2 pt-2'>
          <div className='h-10px w-240px'>
            <Skeleton variant='rounded' />
          </div>
          <div className='h-10px'>
            <Skeleton variant='rounded' />
          </div>
          <div className='h-10px'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </Card>
    </div>
  )
}
