import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'

export const GET_EXPERIENCE_HOLD_QUERY = gql(`
query ExperienceHold($bookingStateId: String!) {
  getExperienceHold(bookingStateId: $bookingStateId) {
    travelDate
    startTime
    productOptionCode
    grandTotal {
      amount
      currency
    }
    lineItems {
      ageBand
      numberOfTravelers
      subtotalPrice {
        price {
          partnerTotalPrice {
            amount
            currency
          }
        }
      }
    }

    paxMix {
      ageBand
      numberOfTravelers
    }

    totalPrice {
      price {
        recommendedRetailPrice {
          amount
          currency
        }
        partnerNetPrice {
          amount
          currency
        }
        bookingFee {
          amount
          currency
        }
        partnerTotalPrice {
          amount
          currency
        }
      }
      priceBeforeDiscount {
        recommendedRetailPrice {
          amount
          currency
        }
        partnerNetPrice {
          amount
          currency
        }
        bookingFee {
          amount
          currency
        }
        partnerTotalPrice {
          amount
          currency
        }
      }
    }
    
    product {
      cancellationPolicy {
        type
        description
      }
      destinations {
        destinationName
      }
      id
      title
      productCode
      images {
        size240x160
        size400x400
        size480x320
      }
      bookingQuestions {
        allowedAnswers
        grouping
        hint
        id
        label
        required
        type
        units
        maxLength
      }
      itinerary {
        ... on ExperienceStandardItinerary {
          duration {
            readableDurationRange
          }
        }
        ... on ExperienceActivityItinerary {
          duration {
            readableDurationRange
          }
        }
        ... on ExperienceHopOnHopOffItinerary {
          duration {
            readableDurationRange
          }
        }
        ... on ExperienceUnstructuredItinerary {
          duration {
            readableDurationRange
          }
        }
      }
      logistics {
        start {
          location {
            address
            city
            state
            country
            postalCode
            latitude
            longitude
          }
        }
        travelerPickup {
          pickupOptionType
          locations {
            location {
              name
              address
              city
              providerReference
              reference
              unstructuredAddress
            }
            pickupType
          }
          allowCustomTravelerPickup
        }
      }
      productOptions {
        productOptionCode
        description
        languageGuides {
          language
          languageName
          type
        }
      }
      languageGuides {
        language
        languageName
        type
      }
      ticketInfo {
        hasMobileTicket
      }
    }
  }
}
`)

export const useExperienceHold = () => {
  const { bookingFlowId: bookingStateId } = useParams()

  return useQuery(GET_EXPERIENCE_HOLD_QUERY, {
    variables: {
      bookingStateId,
    },
    skip: !bookingStateId,
  })
}
