import { Button } from '@travelpass/design-system'
import { Helmet, PageLayoutContainer } from 'src/common/components'

export const IntroducingTravelpass = () => {
  const onGetItineraryClick = e => {
    e.preventDefault()
    const node = document.getElementById('free-itinerary')
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='wfull h663px md:h763px from-forest from-43% to-43% relative flex items-center bg-gradient-to-b to-white'>
        <div className='wfull h563px md:h663px rounded-30px sm:max-w-90% md:max-w-90% lg:max-w-348 bg-top-center relative mx-auto bg-[url(https://static.travelpass.com/assets/homev2-page/trip-planner.webp)] bg-cover shadow-md'>
          <div className='wfull h563px md:h663px rounded-30px bg-forest/40 absolute bottom-0 left-0 right-0 top-0 md:bg-transparent'>
            <div className='md:items-initial sm:top-20% md:top-25% sm:w-350px md:ml-50px lg:ml-87px absolute flex flex-col sm:left-0 sm:right-0 sm:m-auto sm:items-center sm:text-center md:m-0 md:text-left'>
              <div className='w-276px'>
                <h1 className='type-h1-desktop c-white lg:w-370px drop-shadow-lg'>
                  Always Travel Like A Local
                </h1>
                <div className='my5 h-.5 w65px bg-valley' />
                <p className='type-body-1 c-white mb5 drop-shadow-md lg:w-80'>
                  Introducing Travelpass, your adventurous companion with the
                  inside scoop! We’re here to take the stress out of trip
                  planning.
                </p>
                <p className='type-body-1 c-white mb15 drop-shadow-md lg:w-80'>
                  With it’s My Trips feature, Travelpass makes it easy to start
                  crossing off your bucket list destinations, or explore your
                  own back yard.
                </p>
              </div>
              <div className='max-w-90%'>
                <Button
                  fullWidth
                  label='Get Your Free Itinerary'
                  size='large'
                  onClick={onGetItineraryClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='wfull pb30 md:pb10 bg-white'>
        <PageLayoutContainer>
          <div className='flex flex-col md:flex-row'>
            <div className='basis-1/2'>
              <img
                alt='My Trip, With Travelpass'
                className='w90% mxa mb10 md:mb0 block'
                src='https://static.travelpass.com/assets/homev2-page/trip-overview-explore.webp'
              />
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h1'>My Trips, With Travelpass</h2>
              <div className='my5 h-.5 w65px bg-valley' />
              <p className='type-subtitle-2 mb5 md:mb10'>
                Trip planning just got a lot easier.
              </p>
              <p className='type-body-1 my5'>
                Planning a trip with Travelpass is like always having a group of
                travel savvy friends to ask about your upcoming trip — you know
                you’re getting the best thing to see in that city, info about
                the top place for brunch, plus recommendations on the hotels for
                your trip.
              </p>
              <p className='type-body-1 my5'>
                Plan an epic trip, without the hours of research (seriously).
                Book top-rated experiences and recommended hotels, all with My
                Trips, which also keeps track of the restaurants and sites you
                want to visit. It’s a cinch to build a day-by-day plan for any
                trip, or just keep all of your ideas together.
              </p>
              <p className='type-body-1 my5'>
                Whether you’re planning an upcoming weekend adventure, or just
                putting together ideas for a future trip, you’re set. Just add
                the info to your future getaway on My Trips!
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>
      <div
        className='bg-forest md:h-447px lg:h-128.5 pb10 md:pb0 flex w-full justify-center overflow-visible sm:h-auto sm:flex-col-reverse md:flex-row'
        id='free-itinerary'
      >
        <div className='hfull md:pl-12.5 flex flex-col items-center justify-center gap-10 md:gap-11 md:pt-10 lg:pt-0'>
          <div className='max-w-90% lg:max-w-510px md:mr10 mt10 md:mt0'>
            <h2 className='type-h1 c-white lg:w-107'>
              Your Next (Epic) Trip Is Already Planned
            </h2>
            <div className='w16 h-.5 bg-valley my6' />
            <p className='type-body-1 c-white'>
              Get a free, expertly planned itinerary sent straight to your
              inbox, instantly!
            </p>
            <p className='type-body-1 c-white mt6'>
              Our team of travel experts have put together itineraries for our
              top getaways and some hidden gems to check out. Simply choose your
              favorite location from the list (with this many great options,
              it’s going to be hard!) and tell us where to send it!
            </p>
          </div>
          <div className='weblayer--form relative w-full py-4 md:mr-6'></div>
        </div>
        <img
          alt='friends'
          className='sm:mt--87px rounded-tl-6 rounded-br-6 sm:h-334px sm:w-343px md:h-368px md:w-378px lg:h-135 lg:w-138.5 sm:self-center md:mr-10 md:mt-0'
          src='https://static.travelpass.com/assets/homev2-page/destinations.webp'
        />
      </div>
    </>
  )
}

const canonicalUrl = 'https://www.travelpass.com/my-trips-with-travelpass'
const metaDescription =
  'Trip planning just got a lot easier. Travelpass’ My Trips feature makes it easy to start crossing off your bucket list destinations, or explore your own back yard.'
const pageName = 'Always Travel Like A Local'
