import { SkeletonDots } from '@travelpass/design-system'
import type { RadiusRange, GuideFilters } from 'src/__generated__/graphql'
import { GuideRecommendedCard } from 'src/common/components'
import type { OnClickOwnerProfile } from 'src/common/components/GuideRecommendedCard/GuideRecommendedCard'
import { useNearbyGuidesQuery } from './useNearbyGuidesQuery'
import { useGuidesSearchParams } from '../useGuidesSearchParams'

interface LookingForMoreProps {
  closerRadiusRange: RadiusRange
  fartherRadiusRange: RadiusRange
  filters?: GuideFilters
  onClickOwnerProfile: OnClickOwnerProfile
}

const LookingForMore = ({
  closerRadiusRange,
  fartherRadiusRange,
  filters,
  onClickOwnerProfile,
}: LookingForMoreProps) => {
  const { latitude: lat, longitude: long, location } = useGuidesSearchParams()
  const latitude = parseFloat(lat)
  const longitude = parseFloat(long)

  const {
    resultsData: closerGuide,
    loading: closerGuideLoading,
    error: closerGuideError,
  } = useNearbyGuidesQuery({
    location,
    variables: {
      guideSearchArgs: {
        filterBy: {
          durationDays: filters?.durationDays || null,
          guideType: filters?.guideType || null,
          tagIds: filters?.tagIds || null,
        },
        searchBy: {
          nearbyLocations: {
            latLong: {
              latitude,
              longitude,
            },
            radiusRange: {
              max: closerRadiusRange.max,
              min: closerRadiusRange.min || null,
            },
          },
        },
        tracker: null,
      },
      first: 1,
    },
  })

  const {
    resultsData: fartherGuide,
    loading: fartherGuideLoading,
    error: fartherGuideError,
  } = useNearbyGuidesQuery({
    location,
    variables: {
      guideSearchArgs: {
        filterBy: {
          durationDays: filters?.durationDays || null,
          guideType: filters?.guideType || null,
          tagIds: filters?.tagIds || null,
        },
        searchBy: {
          nearbyLocations: {
            latLong: {
              latitude,
              longitude,
            },
            radiusRange: {
              max: fartherRadiusRange.max,
              min: closerRadiusRange.max,
            },
          },
        },
        tracker: null,
      },
      first: 1,
    },
  })

  const closerGuideNode = closerGuide[0]?.node
  const fartherGuideNode = fartherGuide[0]?.node
  const isEmpty = !closerGuide?.length && !fartherGuide?.length

  if (isEmpty) return <></>

  return (
    <div className='bg-beach border-beachDark rounded-3 md:rounded-tr-12 md:rounded-tl-0 md:rounded-bl-12 md:rounded-br-0 flex flex-col gap-4 border-2 border-solid bg-none bg-contain bg-no-repeat p-6 lg:flex-row-reverse lg:gap-0 lg:bg-[url(https://static.travelpass.com/assets/guides/looking-for-more-graphic.png)] lg:bg-[position:110%] lg:p-7'>
      <section className='lg:py-34 space-y-[5px] text-center lg:space-y-5 lg:px-8 lg:text-left'>
        <h2 className='type-h2'>Looking for more?</h2>
        <h4 className='type-body-1-mobile md:type-h4'>
          Find other guides with destinations&nbsp;and<br></br>
          experiences&nbsp;that are similar to your search results
        </h4>
      </section>
      <section className='mx-auto md:grid md:grid-cols-2 md:gap-4'>
        {fartherGuideLoading ? (
          <SkeletonDots />
        ) : (
          !fartherGuideError && (
            <div
              className={`relative m-auto hidden w-full max-w-72 ${fartherGuide.length && !fartherGuideError && 'md:block'}`}
            >
              <span className='rounded-tr-3 rounded-bl-3 bg-orange c-white type-body-1 z-1 absolute right-0 top-0 inline-flex h-9 w-2/5 items-center justify-center'>
                Within {fartherRadiusRange.max} mi.
              </span>
              <GuideRecommendedCard
                guideRecommendedCardData={{
                  ...fartherGuideNode,
                }}
                smallSize={true}
                onClickOwnerProfile={onClickOwnerProfile}
              />
            </div>
          )
        )}
        {closerGuideLoading ? (
          <SkeletonDots />
        ) : (
          !closerGuideError && (
            <div
              className={`${(!closerGuide.length || closerGuideError) && 'hidden'} relative m-auto w-full max-w-72`}
            >
              <span className='rounded-tr-3 rounded-bl-3 bg-orange c-white type-body-1 z-1 absolute right-0 top-0 inline-flex h-9 w-2/5 items-center justify-center'>
                Within {closerRadiusRange.max} mi.
              </span>
              <GuideRecommendedCard
                guideRecommendedCardData={{ ...closerGuideNode }}
                smallSize={true}
                onClickOwnerProfile={onClickOwnerProfile}
              />
            </div>
          )
        )}
      </section>
    </div>
  )
}

export { LookingForMore }
