import isEmpty from 'lodash.isempty'
import type { ExperienceProduct } from 'src/__generated__/graphql'

export const checkExclusionsInclusions = ({
  exclusions,
  inclusions,
}: Partial<ExperienceProduct>) => {
  const isExclusionsEmpty = isEmpty(
    exclusions?.filter(({ description }) => !!description)
  )
  const isInclusionsEmpty = isEmpty(
    inclusions?.filter(({ description }) => !!description)
  )

  return !isExclusionsEmpty || !isInclusionsEmpty
}
