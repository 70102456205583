import classNames from 'classnames'
import { SearchBar } from '../guides/results/SearchBar'

export const Header = ({ displayLocation }: { displayLocation: string }) => {
  return (
    <div
      className={classNames(
        'space-y-6 bg-black/35 bg-[url(https://static.travelpass.com/assets/destinations-page/placeholder/destinations-placeholder.webp)] bg-cover pt-6 bg-blend-darken [background-position-y:350%] md:pt-12 md:[background-position-y:70%]',
        !displayLocation && 'md:py-54',
        !!displayLocation && 'md:py-8'
      )}
    >
      <header className='space-y-2'>
        <h1 className='type-h1 c-white text-shadow text-shadow-color-black/30 text-shadow-lg text-center'>
          Explore {displayLocation}
        </h1>
        <p className='type-body-1 c-white text-shadow text-shadow-color-black/70 text-shadow-lg text-center font-semibold'>
          Search USA, Canada, and Mexico
        </p>
      </header>
      <SearchBar />
    </div>
  )
}
