import type { ReactNode } from 'react'
import { useState } from 'react'
import type { AnimateLayoutChanges } from '@dnd-kit/sortable'
import {
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion, AccordionItem, AccordionPanel } from '@reach/accordion'
import { Divider } from '@travelpass/design-system'
import { GuideDraftSectionHeader } from './GuideDraftSectionHeader'
import type { GuideDraftDragItem } from '../../types'

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({
    ...args,
    wasDragging: true,
  })

interface GuideDraftSectionProps {
  children: ReactNode
  isOverlay?: boolean
  paginatedEventCategory: GuideDraftDragItem
}

export const GuideDraftSection = ({
  children,
  isOverlay = false,
  paginatedEventCategory,
}: GuideDraftSectionProps) => {
  const { publishedEvents } = paginatedEventCategory ?? {}
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    animateLayoutChanges,
    data: {
      children: publishedEvents ?? [],
    },
    id: paginatedEventCategory?.id,
  })
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const isDraggingOrOverlay = isDragging || isOverlay

  if (!paginatedEventCategory?.id)
    return (
      <SortableContext
        items={publishedEvents ?? []}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    )

  return (
    <SortableContext
      items={publishedEvents ?? []}
      strategy={verticalListSortingStrategy}
    >
      <Accordion
        collapsible={false}
        defaultIndex={[0]}
        ref={isOverlay ? undefined : setNodeRef}
        style={
          {
            transition,
            transform: CSS.Translate.toString(transform),
            opacity: isDragging ? 0.5 : undefined,
            '--columns': 1,
          } as React.CSSProperties
        }
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionItem>
          <GuideDraftSectionHeader
            attributes={attributes}
            isDraggingOrOverlay={isDraggingOrOverlay}
            isExpanded={!isDraggingOrOverlay && isExpanded}
            listeners={listeners}
            paginatedEventCategory={paginatedEventCategory}
          />
          <Divider className='m-b-6 m-y-3 pointer-events-none' />
          <AccordionPanel className='space-y-6'>
            {!isDraggingOrOverlay && isExpanded && children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </SortableContext>
  )
}
