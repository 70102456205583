import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, useSnackbar } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { BookingConfirmationTripsApollo } from 'src/common/components/BookingConfirmationTrips'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  useBookingDetailsCancellationPolicyQuery,
  useBookingDetailsDatesQuery,
} from '../..//hooks'
import { bookingDetailsStatusExpired } from '../../constants'
import { BookingDetailsCancelModal } from '../BookingDetailsCancelModal'

const addBookingToAccountQuery = gql(`
  mutation AddAnonymousBookingToAccount($input: ClaimAnonymousBookingInput!) {
    claimAnonymousBooking(input: $input) {
      updatedUser {
        id
        bookings {
          id
          email
          user {
            id
          }
        }
      }
    }
  }
`)

const bookingDetailsCurrentUser = gql(`
  query BookingDetailsCurrentUser {
    currentUser {
      id
      email
    }
  }
`)

export const BookingDetailsManageActions = () => {
  const alwaysRefundable = useFlag('alwaysRefundable')
  const { bookingId } = useParams()
  const [isBookingDetailsCancelModalOpen, setIsBookingDetailsCancelModalOpen] =
    useState(false)
  const { isAnonymous } = useFirebaseUser()
  const { bookingDetailsCancellationPolicyData } =
    useBookingDetailsCancellationPolicyQuery({ bookingId })
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({
    bookingId,
  })
  const [addBookingToAccount] = useMutation(addBookingToAccountQuery)
  const { data: currentUserData, loading: userLoading } = useQuery(
    bookingDetailsCurrentUser
  )
  const { nonRefundableAfter, refundType } =
    bookingDetailsCancellationPolicyData?.bookingDetails?.cancelPolicy ?? {}
  const {
    email: bookingEmail,
    status,
    user: bookingUser,
  } = bookingDetailsDatesData ?? {}
  const { id: bookingUserId } = bookingUser ?? {}
  const { id: currentUserId, email: currentUserEmail } =
    currentUserData?.currentUser ?? {}
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const shouldShowAddBookingToAccountButton =
    !userLoading &&
    !isAnonymous &&
    currentUserId !== bookingUserId &&
    currentUserEmail === bookingEmail

  const onClaimAnonymousBooking = async () => {
    try {
      await addBookingToAccount({ variables: { input: { bookingId } } })
      addSuccessSnack({ title: 'Successfully added booking to your account' })
    } catch {
      addErrorSnack({ title: 'Unable to add booking to your account' })
    }
  }

  const constructedActions = () => {
    const isRefundTypeNone = !alwaysRefundable && refundType === 'NONE'

    if (
      !alwaysRefundable &&
      dayjs().isAfter(dayjs(nonRefundableAfter)) &&
      !isRefundTypeNone
    ) {
      return (
        <div className='type-body-2 c-gray800'>
          {bookingDetailsStatusExpired}
        </div>
      )
    }

    if (!isRefundTypeNone && status !== 'CANCELLED')
      return (
        <div className='hide-on-print'>
          <Button
            label='Cancel Booking'
            size='large'
            startIcon='eventBusy'
            variant='outlined'
            onClick={() => setIsBookingDetailsCancelModalOpen(true)}
          />
        </div>
      )

    return <></>
  }

  return (
    <>
      {isBookingDetailsCancelModalOpen && (
        <BookingDetailsCancelModal
          onClose={() => setIsBookingDetailsCancelModalOpen(false)}
        />
      )}
      <div className='lg:items-initial flex flex-col sm:items-center'>
        {constructedActions()}
      </div>
      <BookingConfirmationTripsApollo onlyMergeUser />
      {shouldShowAddBookingToAccountButton && (
        <Button
          label='Add booking to my account'
          size='large'
          onClick={onClaimAnonymousBooking}
        />
      )}
    </>
  )
}
