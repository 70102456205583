import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createGuideFromTripMutation = gql(`
  mutation CreateGuideDraftFromTripMutationInTrips($input: CreateGuideDraftFromTripInput!) {
    createGuideDraftFromTrip(input: $input) {
      trip {
        id
        guide {
          id
          name
        }
      }
    }
  }
`)

export const useCreateGuideDraftFromTripMutation = () =>
  useMutation(createGuideFromTripMutation)
