import { useEffect, useState } from 'react'
import { Icon } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { DashboardAccountSettingsForm } from './DashboardAccountSettingsForm/DashboardAccountSettingsForm'
import { DashboardSearchParam } from '../../dashboardConstants'

interface DashboardAccountSettingsProps {
  userId: string
}

export const DashboardAccountSettings = ({
  userId,
}: DashboardAccountSettingsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const settingsTab = searchParams.get(DashboardSearchParam.settingsTab)

  useEffect(() => {
    if (settingsTab) setIsModalOpen(true)
  }, [settingsTab])

  const onDismiss = () => {
    setIsModalOpen(false)
    searchParams.delete(DashboardSearchParam.settingsTab)
    setSearchParams(searchParams)
  }

  return (
    <>
      <button
        className='shadow-3 rounded-3 decoration-none b-none color-inherit focus:outline-valley flex cursor-pointer flex-row items-center gap-2 bg-white p-3 text-left'
        type='button'
        onClick={() => setIsModalOpen(true)}
      >
        <div className='space-y-3'>
          <div className='flex flex-row items-center gap-2'>
            <Icon name='settings' />
            <h6 className='type-h6-desktop'>Account Settings</h6>
          </div>
          <p className='type-body-2-desktop color-grey-900'>
            Update your username and manage your account
          </p>
        </div>
        <Icon name='arrowForwardIos' />
      </button>
      {isModalOpen && (
        <DashboardAccountSettingsForm
          settingsTab={settingsTab}
          userId={userId}
          onDismiss={onDismiss}
        />
      )}
    </>
  )
}
