import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const useUpdateTripDayName = () => {
  const [updateTripDayName, { loading, error }] = useMutation(mutation)

  return {
    updateTripDayName,
    loading: false,
    error: false,
  }
}

const mutation = gql(`
  mutation UpdateTripDayName($tripId: ID!, $dayName: String!, $dayIndex: Int!) {
    updateTrip(
      tripInput: {id: $tripId, updateTripDays: [{name: $dayName, dayIndex: $dayIndex}]}
    ) {
      id
      dailyEventBreakdown {
        scheduled {
          dayName
        }
      }
    }
  }
`)
