import { useEffect, useState } from 'react'
import {
  Button,
  DateRangePicker,
  Input,
  TextArea,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import { Controller, type FieldValues, useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { EventType, type Trip } from 'src/__generated__/graphql'
import { Geocoder, TimeRangePicker } from 'src/common/components'
import { geocoderLocationBiasConfig } from 'src/pages/trips/utils'
import { getDateFromUTC } from 'src/utils'
import { drawerEditDefaultValues } from './drawerEditConstants'
import type { DrawerEditKeys, DrawerEditValues } from './drawerEditTypes'
import { createDrawerEditDefaultValues } from './drawerEditUtils'
import type { DrawerEvent } from '../../constants'
import { CustomBooked } from '../CustomBooked'

export const DrawerEditForm = ({
  data,
  hideButtons = false,
  tripData,
  visibleFields = [
    'customBooked',
    'dates',
    'endLocation',
    'name',
    'notes',
    'startLocation',
    'times',
  ],
  onCancel,
  onSave,
}: {
  data: DrawerEvent | null
  hideButtons: boolean
  tripData: Trip | null
  visibleFields: DrawerEditKeys[]
  onCancel?(): void
  onSave?(data?: DrawerEditValues): void
}) => {
  const [displayButtons, setDisplayButtons] = useState(false)
  const { handleSubmit, control, formState, register, setValue, reset } =
    useFormContext()

  const { name: nameError, notes: notesError } = formState?.errors ?? {}

  const isStay = data?.type === EventType.Stay
  const defaultValues = createDrawerEditDefaultValues(data)

  const tripStart = getDateFromUTC(
    tripData?.startDate,
    tripData?.timeZone ?? undefined
  )
  const tripEnd = getDateFromUTC(
    tripData?.endDate,
    tripData?.timeZone ?? undefined
  )

  const minDate = dayjs(tripStart.format('MM/DD/YYYY')).toDate()
  const maxDate = dayjs(tripEnd.format('MM/DD/YYYY')).toDate()

  const { lat, long } = tripData?.tripPreference?.addresses?.[0] ?? {}
  const locationBias = geocoderLocationBiasConfig(lat, long)

  const onFormTouch = () => {
    if (!displayButtons) {
      setDisplayButtons(true)
    }
  }
  const onCancelEvent = () => {
    reset(defaultValues)
    setDisplayButtons(false)
    onCancel?.()
  }
  const onSubmit = (formValues: FieldValues) => {
    setDisplayButtons(false)

    const drawerFields: DrawerEditValues = cloneDeep(defaultValues)
    Object.keys(drawerFields).forEach(key => {
      const defaultValue = drawerEditDefaultValues[key]
      const formValue = formValues?.[key] ?? null
      drawerFields[key] = formValue ?? defaultValue
    })
    onSave?.(drawerFields)
  }

  useEffect(() => {
    Object.keys(defaultValues).forEach(key => setValue(key, defaultValues[key]))
  }, [data?.id])

  const [searchParams] = useSearchParams()
  const isListGuide =
    searchParams.get('listGuide') || window.location.href.includes('list-guide')

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-8 px-8' role='presentation' onClick={onFormTouch}>
        {!isListGuide && visibleFields.includes('customBooked') && (
          <Controller
            control={control}
            defaultValue={drawerEditDefaultValues.customBooked}
            name='customBooked'
            render={({ field: { onChange: onCheckboxChange, value } }) => (
              <CustomBooked
                hideBookingNeeded={isStay}
                value={value}
                onChange={onCheckboxChange}
              />
            )}
          />
        )}
        {!isListGuide && visibleFields.includes('name') && (
          <Input
            fullWidth
            defaultValue={drawerEditDefaultValues.name}
            errorText={nameError && 'Name is required'}
            helperText='Name is required'
            isInvalid={!!nameError}
            label='Name'
            type='text'
            {...register('name', { required: true })}
          />
        )}
        {!isListGuide && visibleFields.includes('startLocation') && (
          <Controller
            control={control}
            defaultValue={drawerEditDefaultValues.startLocation}
            name='startLocation'
            render={({
              field: { onChange: onGeocoderChange, value: destination },
              fieldState: { error: destinationError },
            }) => (
              <Geocoder
                config={locationBias}
                focusOnInput={false}
                geocoder={destination ?? location}
                helperText={destinationError && 'Location is required'}
                isInvalid={!!destinationError}
                label='Start Location'
                onResult={onGeocoderChange}
              />
            )}
          />
        )}
        {!isListGuide && visibleFields.includes('endLocation') && (
          <Controller
            control={control}
            defaultValue={drawerEditDefaultValues.endLocation}
            name='endLocation'
            render={({
              field: { onChange: onGeocoderChange, value: destination },
              fieldState: { error: destinationError },
            }) => (
              <Geocoder
                config={locationBias}
                focusOnInput={false}
                geocoder={destination ?? location}
                helperText={destinationError && 'Location is required'}
                isInvalid={!!destinationError}
                label='End Location'
                onResult={onGeocoderChange}
              />
            )}
          />
        )}
        {!isListGuide && visibleFields.includes('dates') && (
          <Controller
            control={control}
            defaultValue={drawerEditDefaultValues.dates}
            name='dates'
            render={({ field: { onChange: onDateChange, value: dates } }) => {
              return (
                <DateRangePicker
                  canSelectSameDay={!isStay}
                  fromDate={minDate}
                  label={isStay ? 'Check In - Check Out' : 'Dates'}
                  selected={dates}
                  toDate={maxDate}
                  onSelect={onDateChange}
                />
              )
            }}
          />
        )}
        {!isListGuide && visibleFields.includes('times') && (
          <Controller
            control={control}
            defaultValue={drawerEditDefaultValues.times}
            name='times'
            render={({
              field: { onChange: onTimeRangeChange, value: timeRange },
            }) => (
              <TimeRangePicker
                timeRange={timeRange}
                onChange={onTimeRangeChange}
              />
            )}
          />
        )}
        {visibleFields.includes('notes') && (
          <TextArea
            defaultValue={drawerEditDefaultValues.notes}
            errorText={notesError && 'Max 3000 characters'}
            helperText='Max 3000 characters'
            label='Note'
            placeholder='Add a note here'
            {...register('notes', { maxLength: 3000 })}
          />
        )}
      </div>
      {!hideButtons && displayButtons && (
        <div className='mt-6 flex w-full flex-row items-center justify-start gap-7 px-8'>
          <Button
            aria-label='Cancel event changes'
            variant='outlined'
            onClick={onCancelEvent}
          >
            Cancel
          </Button>
          <Button aria-label='Submit event changes' type='submit'>
            Save
          </Button>
        </div>
      )}
    </form>
  )
}
