import { useEffect, useMemo } from 'react'
import { DropdownOption } from '@travelpass/design-system'
import groupBy from 'lodash.groupby'
import type {
  ExperienceBookingQuestion,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { BookingQuestionAnswer } from './BookingQuestionAnswer'
import { PickupPointField } from './PickupPointField'
import { conditionalQuestionsMap } from './bookingQuestionUtil'
import {
  useWatch,
  BookingFormDropdown,
  useFormContext,
  rules,
} from './useExperienceBookingForm'

export const TransferArrivalModeFields = ({
  question,
  conditionalFields,
  experienceProduct,
  pickupAllowed,
}: {
  question: ExperienceBookingQuestion
  conditionalFields: ExperienceBookingQuestion[]
  experienceProduct: ExperienceProduct
  pickupAllowed: boolean
}) => {
  const transferArrivalModeAnswer = useWatch({
    name: 'answers.transferArrivalMode.0.answer',
  })

  const { setValue, watch } = useFormContext()
  useEffect(() => {
    setValue('answers.transferArrivalMode.0.question', question.id)
  }, [setValue, question])

  const transferModeInputValue = watch('answers.transferArrivalMode.0.answer')

  useEffect(() => {
    conditionalFields.forEach((_, index) =>
      setValue(`answers.transferArrivalMode.${index + 1}.answer`, '')
    )
  }, [transferModeInputValue])

  const pickupLocationsByAnswer = useMemo(() => {
    const {
      AIRPORT = [],
      PORT = [],
      HOTEL = [],
      LOCATION = [],
      OTHER = [],
    } = groupBy(
      experienceProduct.logistics.travelerPickup.locations,
      'pickupType'
    )

    return {
      AIR: AIRPORT,
      SEA: PORT,
      OTHER: [...HOTEL, ...LOCATION, ...OTHER],
    }
  }, [experienceProduct.logistics.travelerPickup.locations])

  const conditionalAnswerFields = useMemo(() => {
    // derive which questions are related to each answer to render as fields
    const conditionalFieldsPerAnswer = question.allowedAnswers.map(answer => {
      let fieldIds = conditionalQuestionsMap.TRANSFER_ARRIVAL_MODE[answer]
      // isValid checks if all fieldIds exist in conditionalFields
      const isValid = fieldIds?.every(fieldId =>
        conditionalFields?.some(field => field.id === fieldId)
      )

      if (isValid) {
        const relatedFields = conditionalFields?.filter(field =>
          fieldIds?.includes(field.id)
        )

        return [answer, relatedFields]
      }

      return []
    })
    return Object.fromEntries(conditionalFieldsPerAnswer) // convert to object
  }, [question.allowedAnswers, conditionalFields])

  return (
    <div
      className='flex flex-col gap-3'
      id='transfer-arrival-mode-conditional-fields'
    >
      <BookingFormDropdown
        defaultValue=''
        id='answers.transferArrivalMode.0.answer'
        label={question.label}
        name='answers.transferArrivalMode.0.answer'
        required={rules.required}
      >
        {question.allowedAnswers?.map(answer => {
          if (conditionalAnswerFields[answer]) {
            return (
              <DropdownOption key={answer} value={answer}>
                {answer}
              </DropdownOption>
            )
          }
        })}
      </BookingFormDropdown>
      {conditionalAnswerFields[transferArrivalModeAnswer]?.map(
        (question, questionIndex) => {
          const fieldIndex = questionIndex + 1
          if (question.id === 'PICKUP_POINT' && pickupAllowed) {
            return (
              <PickupPointField
                key={question.id}
                allowCustomTravelerPickup={
                  experienceProduct.logistics.travelerPickup
                    .allowCustomTravelerPickup
                }
                locations={pickupLocationsByAnswer[transferArrivalModeAnswer]}
                name={`answers.transferArrivalMode.${fieldIndex}`}
                pickupPoint={question}
              />
            )
          } else {
            return (
              <BookingQuestionAnswer
                key={question.id}
                field={`answers.transferArrivalMode.${fieldIndex}`}
                question={question}
              />
            )
          }
        }
      )}
    </div>
  )
}
