import { Skeleton } from '@travelpass/design-system'
import { HotelResultsCardRowContentLoading } from './HotelResultsCardRowContent'

export const HotelResultsCardRowLoading = () => (
  <section className='grid grid-cols-10' data-testid='HotelResultsCardRow'>
    <header className='of-hidden rounded-3 relative col-span-3 h-full'>
      <div className='h-43'>
        <Skeleton />
      </div>
    </header>
    <div className='col-span-7'>
      <HotelResultsCardRowContentLoading />
    </div>
  </section>
)
