import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const incrementGuideShareCountMutation = gql(`
  mutation IncrementGuideShareCount($guideId: ID!) {
    incrementGuideShareCount(guideId: $guideId) {
      id
      shareCount
    }
  }
`)

export const useIncrementGuideShareCountMutation = () =>
  useMutation(incrementGuideShareCountMutation)
