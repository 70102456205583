import { Icon } from '@travelpass/design-system'

interface BookingCancellationBadgeProps {
  name?: string
}
export const BookingCancellationBadge = ({
  name,
}: BookingCancellationBadgeProps) => (
  <div className={bookingCancellationBadgeCss}>
    <h3 className='sr-only'>Your {name} booking has been cancelled</h3>
    <Icon name='eventBusy' size='small' />
    <h3 className={bookingCancellationText}>Cancelled</h3>
  </div>
)

const bookingCancellationBadgeCss =
  'flex gap-1 items-center justify-center c-white bg-error w-fit py-1 px-2 rd-1'

const bookingCancellationText = 'text-body-2 c-white font-semibold'
