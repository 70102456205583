import { Divider, UnderlineTab } from '@travelpass/design-system'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { Trip } from 'src/__generated__/graphql'
import {
  exploreSegment,
  overviewSegment,
  timelineSegment,
  tripExplorePath,
  tripOverviewPath,
  tripTimelinePath,
} from 'src/constants'
import { tripNavTabs } from './tripNavConstants'
import { pushTripNavClickToDataLayer } from '../../utils'

export const TripNav = ({ name }: { name: Trip['name'] }) => {
  const { tripId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const onTabSwitch = (index: number) => {
    if (index === 0)
      return {
        category: 'overview',
        url: `${tripOverviewPath}/${tripId}`,
      }

    if (index === 1)
      return {
        category: 'timeline',
        url: `${tripTimelinePath}/${tripId}`,
      }

    if (index === 2)
      return {
        category: 'explore',
        url: `${tripExplorePath}/${tripId}`,
      }
  }

  const tabs = tripNavTabs.map(({ icon, label }, index) => ({
    icon,
    label,
    onClick: () => {
      const { category, url } = onTabSwitch(index)
      const urlFormatted = `${window.location.origin}${url}`
      const triggerVariant = `TripNav ${category}Tab`
      pushTripNavClickToDataLayer({
        itemCategory: category,
        itemUrl: urlFormatted,
        triggerVariant,
        tripId,
        tripName: name,
      })
      navigate(url)
    },
  }))

  const activeTab =
    pathname.split('/trips/')?.[1]?.split(`/${tripId}`)?.[0] ?? ''

  const getActiveIndex = () => {
    if (activeTab === overviewSegment) return 0

    if (activeTab === timelineSegment) return 1

    if (activeTab === exploreSegment) return 2

    return 3
  }

  return (
    <>
      <div className='lg:top-77px lg:z-4 hidden bg-white md:block lg:sticky'>
        <UnderlineTab
          activeIndex={getActiveIndex()}
          tabs={tabs}
          tabsNotCapitalized={true}
          willScroll={false}
        />
        <div className='-m-x-2 md:-m-x-6 lg:-m-x-12'>
          <Divider className='bg-grey-200 h-1px -m-t-1px' />
        </div>
      </div>
      <div className='md:p-b-8 hidden md:block' />
    </>
  )
}
