import { EmptyStateFollowers } from './EmptyStateFollowers'

interface EmptyListProps {
  text: string
}

export const EmptyList = ({ text }: EmptyListProps) => {
  return (
    <div className='flex flex-col items-center py-10'>
      <div className='w-50% mb-10'>
        <EmptyStateFollowers />
      </div>
      <p className='type-body-1-desktop c-grey800'>{text}</p>
    </div>
  )
}
