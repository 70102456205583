import type { NavigateFunction } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { SortBy } from 'src/__generated__/graphql'
import {
  generateHotelResultsUrl,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
} from 'src/utils'

export const handleHotelSearch = async (
  selection: Suggestion,
  navigate: NavigateFunction
) => {
  const [result] = await getGeocode({
    placeId: selection.place_id,
  })
  const { lat, lng } = getLatLng(result)
  const placeCountry =
    getGeocoderPlaceCountryFromAddressComponents(result.address_components) ??
    ''

  const placeFilteredType = getGeocoderPlaceTypeFromAddressComponents(
    result.address_components
  )

  // per logic in src/common/components/SearchHotels/searchHotelsUtils.ts#L47-L48
  // 'lodging' means hotel/"specific place". if hotel, omit place_id to trigger specificPlace search on Hotels page
  const placeId = (result.types?.includes('lodging') && result.place_id) || ''

  /** @todo how to preserve existing URL search params when using this util? eg. utm */
  const url = generateHotelResultsUrl({
    adults: 2,
    kids: 0,
    // arrival,
    // departure,
    location: selection.description,
    latitude: lat,
    longitude: lng,
    placeCountry,
    placeId,
    placeShortName: placeFilteredType?.shortName ?? '',
    placeType: placeFilteredType?.type ?? '',
    sort: !placeId ? SortBy.Recommended : SortBy.Distance,
    generateDefaultDates: false,
  })
  navigate(url)
}
