import { useEffect, useRef } from 'react'
import { Tile } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { Scroller, ScrollerButton } from 'src/common/components'
import { formatDate } from 'src/utils'
import { useGetExperienceAvailabilitySchedule } from './useGetExperienceAvailabilitySchedule'
import { useSearchParamTravelDateState } from './useSearchParamTravelDateState'

export const AvailabilityDatesScroller = () => {
  const { data, loading, error } = useGetExperienceAvailabilitySchedule()
  const [travelDate, setTravelDate] = useSearchParamTravelDateState()
  const scrollerRef = useRef(null)

  const pricesPerDay = data?.pricesPerDay

  useEffect(() => {
    // scroll to selected travel date tile once data is available
    if (travelDate && Boolean(data)) {
      const selectedDateTile =
        scrollerRef?.current?.getItemElementById(travelDate)
      if (selectedDateTile) scrollerRef?.current?.scrollToItem(selectedDateTile)
    }
  }, [travelDate, data])

  if (loading || !pricesPerDay?.length) return null

  // if(error) return null // @todo: handle error state

  return (
    <div className='my-6' data-testid='ExperiencesDetailsDates'>
      <Scroller
        LeftArrow={
          <div className='pr-1'>
            <ScrollerButton icon='arrowBackIos' size='small' />
          </div>
        }
        RightArrow={
          <div className='pl-1'>
            <ScrollerButton isNext icon='arrowForwardIos' size='small' />
          </div>
        }
        apiRef={scrollerRef}
        scrollContainerClassName='gap-2.5 children-[:first-child]:pl-4 children-[:last-child]:pr-4'
      >
        {pricesPerDay.map(
          ({ date, offeredThisDay }) =>
            !!date && (
              <div key={date} className='ws-nowrap'>
                <Tile
                  aria-label={`Check for availability on ${date}`}
                  isDisabled={!offeredThisDay}
                  pressed={travelDate === date}
                  onClick={() => setTravelDate(date)}
                >
                  <span className='capitalize'>
                    {formatDate(dayjs(date), 'ddd, MMM DD')}
                  </span>
                </Tile>
              </div>
            )
        )}
      </Scroller>
    </div>
  )
}
