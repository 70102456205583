import { EmptyState } from '@travelpass/design-system'
import { SupportContainer } from '../common'

export const SupportFaqEmpty = () => (
  <SupportContainer>
    <div className='p-b-10 p-t-20 lg:p-t-25 flex flex-col items-center gap-2 lg:flex'>
      <div className='w-31'>
        <EmptyState name='noSearchResult' />
      </div>
      <section className='grow space-y-5'>
        <h3 className='color-grey-800 max-w-91 type-h5'>No results found</h3>
        <p className='color-grey-800 type-body-1'>
          Try adjusting your search to find what you are looking for.
        </p>
      </section>
    </div>
  </SupportContainer>
)
