import type { ExperienceHopOnHopOffRoute } from 'src/__generated__/graphql'

interface TimelineHopOnHopOffHeaderProps {
  route: ExperienceHopOnHopOffRoute
}

export const TimelineHopOnHopOffHeader = ({
  route,
}: TimelineHopOnHopOffHeaderProps) => {
  const { duration, name, operatingSchedule, pointsOfInterest, stops } =
    route ?? {}
  const { readableDurationRange } = duration ?? {}

  const constructedPointsOfInterest = () => {
    const pointsOfInterestLength = pointsOfInterest?.length

    if (pointsOfInterestLength)
      return (
        <p className='color-grey-700 m-0'>
          {pointsOfInterestLength}{' '}
          {pointsOfInterestLength > 1
            ? 'Points of Interest'
            : 'Point of Interest'}
        </p>
      )
  }

  const constructedStops = () => {
    const stopsLength = stops?.length

    if (stopsLength)
      return (
        <p className='color-grey-700 m-0'>
          {stopsLength} {stopsLength > 1 ? 'Stops' : 'Stop'}
        </p>
      )
  }

  return (
    <div className='text-body-1 flex flex-col gap-2'>
      <p className='bg-canyon color-white rounded-2 w-fit p-2'>{name}</p>
      {constructedStops()}
      {constructedPointsOfInterest()}
      {!!readableDurationRange && (
        <p className='color-grey-800 m-0'>
          <span className='font-500'>Duration:</span>{' '}
          <span>{readableDurationRange}</span>
        </p>
      )}
      <p className='color-grey-800 m-0'>
        <span className='font-500'>Operating Schedule:</span>{' '}
        <span>{operatingSchedule}</span>
      </p>
    </div>
  )
}
