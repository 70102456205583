import type { Hotel } from 'src/__generated__/graphql'

const getCollectionHotelCardAddress = (hotel: Hotel) => {
  const { city, state, country } = hotel ?? {}

  if (city && state) return `${city}, ${state}`
  if (city && country) return `${city}, ${country}`
  if (state && country) return `${state}, ${country}`
  if (city) return city
  if (state) return state
  if (country) return country

  return null
}

export { getCollectionHotelCardAddress }
