import type { Dayjs } from 'dayjs'
import type { DailyEventBreakdown } from 'src/__generated__/graphql'
import type { DatesType } from 'src/constants/user'

export const getExtendedDates = ({
  startDate,
  endDate,
  formStartDate,
  fromEndDate,
}): DatesType => {
  const allDates = [startDate, endDate, formStartDate, fromEndDate]

  const earliestDate = allDates?.reduce((prevDate, currentDate) =>
    prevDate?.isBefore(currentDate) ? prevDate : currentDate
  )

  const latestDate = allDates?.reduce((prevDate, currentDate) =>
    prevDate?.isAfter(currentDate) ? prevDate : currentDate
  )
  return [earliestDate, latestDate]
}

export const getEditDates = ({
  tripStartDate,
  formStartDate,
  fromEndDate,
  dailyEventBreakdown,
}: {
  tripStartDate: Dayjs
  formStartDate: Dayjs
  fromEndDate: Dayjs
  dailyEventBreakdown: DailyEventBreakdown
}): DatesType => {
  const { foundStartIndex, foundEndIndex } =
    dailyEventBreakdown?.scheduled?.reduce(
      (acc, { events }, index) => {
        if (events.length > 0) {
          return {
            foundStartIndex:
              acc.foundStartIndex === -1 ? index : acc.foundStartIndex,
            foundEndIndex: index,
          }
        }
        return acc
      },
      { foundStartIndex: -1, foundEndIndex: -1 }
    )

  if (foundStartIndex === undefined || foundStartIndex === -1) {
    return null
  }

  const startDate = tripStartDate.add(foundStartIndex, 'day')
  const endDate = tripStartDate.add(foundEndIndex, 'day')

  if (
    (startDate.isAfter(formStartDate) || startDate.isSame(formStartDate)) &&
    (endDate.isBefore(fromEndDate) || endDate.isSame(fromEndDate))
  ) {
    return null
  }

  return [startDate, endDate]
}
