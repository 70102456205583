import { Icon } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { internationalLocations } from './hotelResultsEmptyConstants'
import type { InternationLocation } from './hotelResultsEmptyTypes'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

interface HotelResultsEmptyInternationalProps {
  country: string
}

export const HotelResultsEmptyInternational = ({
  country,
}: HotelResultsEmptyInternationalProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onClick = ({
    latitude,
    longitude,
    title,
  }: Partial<InternationLocation>) => {
    searchParams.delete(HotelResultsSearchParams.placeCountry)
    searchParams.delete(HotelResultsSearchParams.placeId)
    searchParams.delete(HotelResultsSearchParams.placeShortName)
    searchParams.delete(HotelResultsSearchParams.placeType)
    searchParams.set(HotelResultsSearchParams.latitude, latitude.toString())
    searchParams.set(HotelResultsSearchParams.longitude, longitude.toString())
    searchParams.set(HotelResultsSearchParams.location, title)
    setSearchParams(searchParams)
  }

  return (
    !!country && (
      <section className='space-y-9 py-9'>
        <header className='space-y-3 text-center'>
          <h1 className='m-a type-h5 w-92 max-w-full'>
            Whoopsie daisy! Looks like {country}, isn&apos;t quite ready for you
            and your bad self yet!
          </h1>
          <p className='color-grey-900 type-body-1'>
            Check out some of other international offerings!
          </p>
        </header>
        <div className='[&>button:nth-of-type(4),&>button:nth-of-type(5)]:lg:col-span-3 grid grid-cols-2 gap-3 md:grid-cols-6 [&>button:nth-of-type(9)]:lg:col-span-6 [&>button]:md:col-span-2 [&>button]:lg:col-span-2'>
          {internationalLocations.map(({ latitude, longitude, src, title }) => (
            // TODO: convert to a link.
            <button
              key={title}
              className='b-none h-38 rounded-2 transition-opacity-200 md:h-65 hover:op-75 relative cursor-pointer overflow-hidden bg-transparent text-left ease-linear'
              onClick={() => onClick({ latitude, longitude, title })}
            >
              <img
                alt={title}
                className='absolute left-0 top-0 h-full w-full object-cover'
                src={src}
              />
              <span className='color-white absolute bottom-0 flex w-full max-w-full justify-between py-6 pl-3 pr-6'>
                <span className='color-white type-h5'>{title}</span>
                <span className='b-solid b-1.5 b-white rounded-1/2 hidden h-7 w-7 flex-col md:flex md:items-center md:justify-center'>
                  <Icon name='arrowForward' size='small' />
                </span>
              </span>
            </button>
          ))}
        </div>
      </section>
    )
  )
}
