import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const REMOVE_EVENT_FROM_COLLECTION = gql(`
    mutation RemoveEventFromCollection($input: DeleteCollectedEventInput!, $itemId: String){
        deleteCollectedEvent(input: $input){
            collectedEvent{
                event{
                    id
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: EVENT)
                    id
                    name
                }
            }
        }
    }
`)

export const useRemoveEventFromCollection = () =>
  useMutation(REMOVE_EVENT_FROM_COLLECTION)
