import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { FooterLink } from 'src/common/components/Footer/FooterLink'
import { privacyPath, termsPath } from 'src/constants'

export const UtahJazzFooter = () => {
  const currentYear = dayjs().format('YYYY')
  const navigate = useNavigate()
  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/utah-jazz/'

  return (
    <footer className='bg-forestDark c-warmGrey pb17 pt19.5 wfull'>
      <PageLayoutContainer>
        <a className='w300px md:w350px ma block' href='/'>
          <img
            alt='Utah Jazz + Travelpass Logo lockup'
            className='wfull block'
            src={`${imagePath}jazz-travelpass-logo-lockup.png`}
          />
        </a>
        <p className='mt20 c-beach text-body-1 text-center'>
          &copy; {currentYear} Travelpass, Inc. All Rights Reserved
        </p>
        <div className='flex items-center justify-center gap-4'>
          <FooterLink
            label='Terms of Use'
            onClick={() => navigate(termsPath)}
          />
          <span>|</span>
          <FooterLink
            label='Privacy Policy'
            onClick={() => navigate(privacyPath)}
          />
        </div>
      </PageLayoutContainer>
    </footer>
  )
}
