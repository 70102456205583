import { Skeleton } from '@travelpass/design-system'

interface GuideDrawerContentFooterLoadingProps {
  isEdit: boolean
}

export const GuideDrawerContentFooterLoading = ({
  isEdit,
}: GuideDrawerContentFooterLoadingProps) => (
  <div className='b-grey-400 b-t-1 b-b-1 h-20.5 shadow-1 p-x-9 p-y-5 flex flex-row items-center justify-end gap-3 border-0 border-solid bg-white'>
    {isEdit ? (
      <div className='flex w-full flex-row items-center justify-between gap-3'>
        <div className='h-10 w-full'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-10 w-full'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    ) : (
      <div className='flex flex-row items-center justify-end gap-3'>
        <div className='h-8 w-8'>
          <Skeleton variant='circular' />
        </div>
        <div className='h-8 w-8'>
          <Skeleton variant='circular' />
        </div>
        <div className='h-8 w-8'>
          <Skeleton variant='circular' />
        </div>
      </div>
    )}
  </div>
)
