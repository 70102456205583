import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const searchExperiencesQuery = gql(`
  query SearchExperiencesQueryInTripsDetailsExploreExperiences($first: Int!, $searchExperiencesArgs: SearchExperiencesArgs!) {
    searchExperiences(first: $first, searchExperiencesArgs: $searchExperiencesArgs) {
      edges {
        node {
          id
          cancellationPolicy {
            type
          }
          description
          destinations {
            destinationName
            latitude
            longitude
          }
          images {
            size720x480
          }
          isFavorited
          itinerary {
            ... on ExperienceStandardItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
              itineraryType
            }
            ... on ExperienceActivityItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
              itineraryType
            }
            ... on ExperienceUnstructuredItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
              itineraryType
            }
            ... on ExperienceHopOnHopOffItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
              itineraryType
            }
          }
          languageGuides {
            language
            type
          }
          logistics {
            travelerPickup {
              locations {
                location {
                  latitude
                  longitude
                }
              }
            }
            start {
              location {
                latitude
                longitude
              }
            }
          }
          reviews {
            combinedAverageRating
            totalReviews
          }
          ticketInfo {
            ticketTypeDescription
          }
          title
          productCode
        }
      }
    }
  }
`)

export const useTripsDetailsExploreExperiences = () =>
  useLazyQuery(searchExperiencesQuery)
