import dayjs from 'dayjs'
import type { Trip } from 'src/__generated__/graphql'
import { getDateFromUTC } from 'src/utils'

type TripTimelineActiveDates = {
  endDate: string
  startDate: string
}

export const getTripTimelineActiveDates = ({
  activeTabIndex,
  endDate,
  startDate,
  timeZone,
}: {
  activeTabIndex: number
  endDate: Trip['endDate']
  startDate: Trip['startDate']
  timeZone: Trip['timeZone']
}): TripTimelineActiveDates => {
  const activeStartDate = getDateFromUTC(startDate, timeZone).add(
    activeTabIndex,
    'day'
  )

  const activeDates: TripTimelineActiveDates = {
    endDate: activeStartDate.add(1, 'day').toISOString(),
    startDate: activeStartDate.toISOString(),
  }

  if (activeStartDate.isSame(dayjs(endDate), 'day')) {
    activeDates.endDate = activeDates.startDate
  }

  return activeDates
}
