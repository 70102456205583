import type { ReactNode } from 'react'

interface HotelResultsListHeaderProps {
  children: ReactNode
}

export const HotelResultsListHeader = ({
  children,
}: HotelResultsListHeaderProps) => (
  <header className='lg:flex-row-with-gap-4 hidden lg:flex lg:min-h-8 lg:items-center lg:justify-between'>
    {children}
  </header>
)
