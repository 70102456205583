import { type MouseEventHandler } from 'react'
import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import type { EventStatus } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { EventCardContent } from './EventCardContent'
import { EventCardHero } from './EventCardHero'
import { getEventImage } from '../../utils'
import { useDeleteDrawerEvent } from '../TripDrawerContent/hooks'

// isButtonsHidden is a temp prop, will be removed in next PR
export const EventCard = ({
  isActive = false,
  isButtonsHidden = false,
  isDraggable = false,
  imageUrl,
  eventId,
  name,
  bookedStatus,
  startDate,
  endDate,
  notes,
  timezone,
  useEventTime,
  onEventCardClick = () => {},
  onHover,
}: {
  isActive?: boolean
  isButtonsHidden?: boolean
  isDraggable?: boolean
  imageUrl?: string
  eventId: string
  name: string
  bookedStatus?: EventStatus
  startDate?: string
  endDate?: string
  notes?: string
  timezone?: string
  useEventTime?: boolean
  onEventCardClick?(eventId: string): void
  onHover?: (eventId?: string) => void
}) => {
  const preppedImageURL = getEventImage(imageUrl)
  const { deleteDrawerEvent } = useDeleteDrawerEvent()

  const onCardClick: MouseEventHandler<HTMLButtonElement> = () => {
    onEventCardClick(eventId)
  }

  const onDelete: MouseEventHandler<HTMLButtonElement> = () => {
    deleteDrawerEvent({ eventId, triggerVariant: 'TripTimeline EventCard' })
  }

  return (
    <div
      className='group flex flex-row items-center'
      onMouseEnter={() => onHover?.(eventId)}
      onMouseLeave={() => onHover?.('')}
    >
      <div className='flex w-16 flex-row items-center justify-center'>
        <div className='flex group-hover:flex md:hidden'>
          {isDraggable && <IconButton icon='draggable' />}
        </div>
      </div>
      <div
        className={classNames(
          'transition-outline rd-4 h-25 min-w-45 relative flex w-full flex-row items-center text-inherit duration-200 ease-linear',
          {
            'bg-mintLight outline-forestLight outline outline-2': isActive,
            'bg-grey-100 b-1 b-solid b-grey200': !isActive,
          }
        )}
      >
        {preppedImageURL && (
          <EventCardHero imageUrl={preppedImageURL} name={name} />
        )}
        <EventCardContent
          bookedStatus={bookedStatus}
          endTime={endDate}
          name={name}
          notes={notes}
          startTime={startDate}
          timezone={timezone}
          useEventTime={useEventTime}
        />
        <button
          className='z-1 rd-4 absolute inset-0 block h-full w-full cursor-pointer overflow-hidden whitespace-nowrap border-none bg-transparent transition-shadow duration-150 ease-linear'
          onClick={onCardClick}
        >
          <span className='sr-only'>
            Click to open a modal with information about the {name} event
          </span>
        </button>
      </div>
      {!isButtonsHidden && (
        <div className='flex w-16 flex-row items-center justify-center'>
          <div className='flex group-hover:flex md:hidden'>
            {!!onDelete && (
              <IconButton icon='deleteOutline' onClick={onDelete} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
