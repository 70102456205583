import { useEffect } from 'react'
import { NetworkStatus } from '@apollo/client'
import { Button, Divider } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type { Exact } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { constructCityAndState } from 'src/common/components/Geocoder/geocoderUtils'
import { useFlag } from 'src/common/hooks'
import {
  HotelResultsEmpty,
  HotelResultsEmptyInternational,
} from './HotelResultsEmpty'
import {
  HotelResultsFeatured,
  HotelResultsFeaturedLoading,
} from './HotelResultsFeatured'
import { HotelResultsList, HotelResultsListLoading } from './HotelResultsList'
import { HotelResultsLoadMoreButton } from './HotelResultsLoadMoreButton'
import {
  HotelResultsSearchParams,
  supportedInternationalCountries,
} from './hotelResultsConstants'
import type { UseGetHotelResultsQuery } from './hotelResultsTypes'
import { getHotelResultsPathName } from './hotelResultsUtils'

export const HotelResultsContent = ({
  filteredIds = [],
  hasError,
  hasMoreResults,
  hotelResultsData,
  hotelResultsFeaturedData,
  isLoading,
  loadMore,
  networkStatus,
}: Exact<
  Partial<UseGetHotelResultsQuery> & {
    filteredIds?: string[]
  }
>) => {
  const isInternationalGeocoderEnabled = useFlag(
    'isInternationalGeocoderEnabled'
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore
  const pathName = getHotelResultsPathName({
    networkStatus,
    searchParams,
  })
  const placeCountry = searchParams.get(HotelResultsSearchParams.placeCountry)
  const showFeatured = !!hotelResultsFeaturedData?.[0]?.nightlyAverage
  const showList = !isEmpty(hotelResultsData)

  const { cityAndState, city } = constructCityAndState(pathName)

  useEffect(() => {
    const hoverId = searchParams.get(HotelResultsSearchParams.hoverId)
    if (hoverId) onCardMouseOut()
  }, [])

  const onCardMouseOut = () => {
    searchParams.delete(HotelResultsSearchParams.hoverId)
    setSearchParams(searchParams, { replace: true })
  }

  const onCardMouseOver = (id: string) => {
    searchParams.set(HotelResultsSearchParams.hoverId, id)
    setSearchParams(searchParams, { replace: true })
  }

  const onMapButtonClick = () => {
    searchParams.set(HotelResultsSearchParams.mapExpanded, 'true')
    setSearchParams(searchParams)
  }

  if (isLoading && !isMoreResultsLoading)
    return (
      <>
        <Helmet
          pageName={`${city} Hotels | Book a Hotel in ${cityAndState || city}`}
        />
        <HotelResultsFeaturedLoading />
        <HotelResultsListLoading />
        <HotelResultsLoadMoreButton
          hasMoreResults
          networkStatus={NetworkStatus.fetchMore}
        />
      </>
    )

  if (hasError || (!showFeatured && !showList))
    return (
      <>
        <Helmet pageName={pathName} />
        {isInternationalGeocoderEnabled &&
        placeCountry &&
        !supportedInternationalCountries.includes(placeCountry) ? (
          <HotelResultsEmptyInternational country={placeCountry} />
        ) : (
          <HotelResultsEmpty />
        )}
      </>
    )

  return (
    <>
      <Helmet
        pageName={`${city} Hotels | Book a Hotel in ${cityAndState || city}`}
      />
      <HotelResultsFeatured
        hotelResultsFeaturedData={hotelResultsFeaturedData}
        onCardMouseOut={onCardMouseOut}
        onCardMouseOver={onCardMouseOver}
      />
      {showFeatured && showList && (
        <div className='hidden pt-2 lg:block'>
          <Divider />
        </div>
      )}
      <HotelResultsList
        filteredIds={filteredIds}
        hasFeatured={showFeatured}
        hotelResultsData={hotelResultsData}
        onCardMouseOut={onCardMouseOut}
        onCardMouseOver={onCardMouseOver}
      />
      {(!isEmpty(filteredIds) || !showList) && (
        <HotelResultsLoadMoreButton
          hasMoreResults={hasMoreResults}
          loadMore={loadMore}
          networkStatus={networkStatus}
        />
      )}
      <div className='pointer-events-none fixed bottom-9 left-0 w-full lg:hidden'>
        <div className='pointer-events-auto m-auto w-fit'>
          <Button
            label='Map'
            startIcon='mapOutlined'
            onClick={onMapButtonClick}
          />
        </div>
      </div>
    </>
  )
}
