import { Modal, ModalScrollContents } from '@travelpass/design-system'
import type { ExperienceItinerary } from 'src/__generated__/graphql'
import { Timeline } from './Timeline'
import {
  checkItineraryOverview,
  checkItineraryTimeline,
} from '../../common/components/utils'

interface TimelineModalProps {
  onDismiss: () => void
  itinerary: ExperienceItinerary
}

export const TimelineModal = ({
  onDismiss: onClose,
  itinerary,
}: TimelineModalProps) => {
  const showTimeline =
    checkItineraryOverview(itinerary) || checkItineraryTimeline(itinerary)

  return (
    showTimeline && (
      <Modal title='Timeline' onDismiss={onClose}>
        <ModalScrollContents>
          <Timeline expanded={true} itinerary={itinerary} />
        </ModalScrollContents>
      </Modal>
    )
  )
}
