import { SkeletonDots } from '@travelpass/design-system'

export const ConfirmationLoading = () => (
  <div className='h-40vh flex flex-col items-center justify-center gap-8 text-center'>
    <div className='type-h1'>We are confirming your booking...</div>
    <div className='type-h4 mb-4'>
      This could take a few minutes, so please don&apos;t refresh your browser
      or use the back button.
    </div>
    <div className='h-20vh flex items-center justify-center'>
      <SkeletonDots />
    </div>
  </div>
)
