import { useState } from 'react'
import {
  Button,
  Icon,
  Modal,
  ModalScrollContents,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import type {
  ExperienceInclusion,
  ExperienceExclusion,
} from 'src/__generated__/graphql'
import { SectionHeader } from 'src/common/components'

interface ExperiencesDetailsSummaryProps {
  inclusions: ExperienceInclusion[]
  exclusions: ExperienceExclusion[]
}

export const InclusionsExclusions = ({
  inclusions,
  exclusions,
}: ExperiencesDetailsSummaryProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { isTabletScreen } = useScreenQuery()
  const inclusionDescriptions = inclusions
    ?.map(incl => incl.description) // @todo: filter out empty values on backend
    .filter(Boolean)

  const exclusionDescriptions = exclusions
    ?.map(excl => excl.description) // @todo: filter out empty values on backend
    .filter(Boolean)

  const itemsCount =
    (inclusionDescriptions?.length ?? 0) + (exclusionDescriptions?.length ?? 0)
  const showViewMore = itemsCount > 4

  const hasInclusions = inclusionDescriptions?.length > 0

  return (
    <>
      {isModalOpen && (
        <Modal title="What's Included" onDismiss={() => setIsModalOpen(false)}>
          <ModalScrollContents>
            <div className='space-y-6 p-6 md:space-y-12 md:py-12'>
              <div className='color-grey-800 grid gap-4 md:grid-cols-2'>
                <ul
                  className={classNames('m-0 list-none space-y-2 p-0', {
                    hidden: !hasInclusions,
                  })}
                >
                  {inclusionDescriptions?.map((title, index) => (
                    <li
                      key={`${title}-${index}`}
                      className='flex gap-2 align-baseline'
                    >
                      <Icon name='check' size='small' />
                      {title}
                    </li>
                  ))}
                </ul>
                <ul className='m-0 list-none space-y-2 p-0'>
                  {exclusionDescriptions?.map((title, index) => (
                    <li
                      key={`${title}-${index}`}
                      className='flex gap-2 align-baseline'
                    >
                      <Icon name='clear' size='small' />
                      {title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </ModalScrollContents>
        </Modal>
      )}
      <div className='space-y-12'>
        <header className='flex flex-row justify-between'>
          <SectionHeader title="What's Included, What's Not" />
          {showViewMore && (
            <div className='hidden md:block'>
              <Button
                label='View More'
                size={isTabletScreen ? 'medium' : 'large'}
                variant='outlined'
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          )}
        </header>
        <div className='color-grey-800 grid gap-4 md:grid-cols-2'>
          <ul
            className={classNames('m-0 list-none space-y-2 p-0', {
              hidden: !hasInclusions,
            })}
          >
            {inclusionDescriptions?.slice(0, 4).map((title, index) => (
              <li
                key={`${title}-${index}`}
                className='flex gap-2 align-baseline'
              >
                <Icon name='check' size='small' />
                {title}
              </li>
            ))}
          </ul>
          <ul className='m-0 list-none space-y-2 p-0'>
            {exclusionDescriptions?.slice(0, 4).map((title, index) => (
              <li
                key={`${title}-${index}`}
                className='flex gap-2 align-baseline'
              >
                <Icon name='clear' size='small' />
                {title}
              </li>
            ))}
          </ul>
        </div>
        {showViewMore && (
          <div className='flex justify-center md:hidden'>
            <Button
              label='View More'
              size='small'
              variant='outlined'
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        )}
      </div>
    </>
  )
}
