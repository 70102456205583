import { useState } from 'react'
import { Divider, UnderlineTab } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { TripSearchParams } from 'src/pages/trips/constants'
import { type Tabs } from './AddEvent'

export const AddEventTabs = ({
  onTabUpdate,
  tab = 'stays',
}: {
  onTabUpdate: (tab: Tabs) => void
  tab: Tabs
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(() => {
    if (tab == 'stays') return 0

    if (tab == 'experiences') return 1

    return 2
  })

  const clearEventFromUrl = () => {
    searchParams.delete(TripSearchParams.eventId)
    searchParams.delete(TripSearchParams.eventType)
    setSearchParams(searchParams, {
      replace: true,
    })
  }
  return (
    <div className='m-a flex flex-col items-center justify-center'>
      <div>
        <UnderlineTab
          activeIndex={activeTab}
          tabs={[
            {
              label: 'Stays',
              onClick: () => {
                setActiveTab(0)
                onTabUpdate('stays')
                clearEventFromUrl()
              },
            },
            {
              label: 'Experiences',
              onClick: () => {
                setActiveTab(1)
                onTabUpdate('experiences')
                clearEventFromUrl()
              },
            },
            {
              label: 'Custom',
              onClick: () => {
                setActiveTab(2)
                onTabUpdate('custom')
                clearEventFromUrl()
              },
            },
          ]}
          willScroll={false}
        />
        <Divider className='bg-grey-200 h-1px -m-t-1px w-full' />
      </div>
    </div>
  )
}
