import { useState } from 'react'
import { Icon, Input, Button, useSnackbar } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { EmailType } from 'src/__generated__/graphql'
import {
  BookingDetailsSectionHeader,
  HotelCancellationCard,
} from 'src/common/components'
import { isOwner, isPublic } from 'src/pages/trips/utils/permissionUtils'
import { copyToClipboard } from 'src/utils'
import { BookingDetailsManageActions } from './BookingDetailsManageActions'
import { BookingDetailsManageContact } from './BookingDetailsManageContact'
import type {
  BookingDetailsHotel,
  BookingDetailsNode,
} from './bookingDetailsManageTypes'
import {
  constructSendEmailArguments,
  useBookingDetailsCancellationPolicyQuery,
  useBookingDetailsIdsQuery,
  useBookingDetailsSendEmailMutation,
} from '../..//hooks'

const { Confirmation } = EmailType

interface BookingDetailsManageProps {
  bookingDetailsData: BookingDetailsNode
  bookingDetailsHotelData: BookingDetailsHotel
}

export const BookingDetailsManage = ({
  bookingDetailsData,
  bookingDetailsHotelData,
}: BookingDetailsManageProps) => {
  const { bookingId } = useParams()
  const { addSuccessSnack } = useSnackbar()
  const [isSendEmailDisabled, setIsSendEmailDisabled] = useState(false)
  const { bookingDetailsCancellationPolicyData } =
    useBookingDetailsCancellationPolicyQuery({ bookingId })
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({ bookingId })
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = bookingDetailsCancellationPolicyData?.bookingDetails?.cancelPolicy ?? {}
  const { externalConfirmationId, trip } = bookingDetailsIdsData ?? {}
  const { email } = bookingDetailsData ?? {}
  const { policyText, shortText } = constructedCancellationPolicies ?? {}
  const displayCancellationBtn =
    isOwner(trip?.currentUserRole) || isPublic(trip?.currentUserRole)
  const [sendEmail] = useBookingDetailsSendEmailMutation()
  const constructedSendEmailArguments = () =>
    constructSendEmailArguments({
      bookingId,
      emailType: Confirmation,
      toEmail: email,
    })

  const onCopyInputClick = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  const onResendEmailButtonClick = async () => {
    if (!isSendEmailDisabled) {
      setIsSendEmailDisabled(true)
      await sendEmail({
        onCompleted: () => {
          addSuccessSnack({ title: 'Successfully sent email' })
          setIsSendEmailDisabled(false)
        },
        onError: error => {
          addSuccessSnack({ title: 'Please try again' })
          setIsSendEmailDisabled(false)
          console.error(error)
        },
        variables: constructedSendEmailArguments(),
      })
    }
  }

  return (
    <div>
      <BookingDetailsSectionHeader title='Manage Booking' />
      <div className='space-y-8' data-testid='BookingDetailsManage'>
        <BookingDetailsManageContact
          bookingDetailsHotelData={bookingDetailsHotelData}
        />

        {displayCancellationBtn && (
          <>
            <div className='space-y-4'>
              <div>
                <div className='hide-on-print'>
                  <Input
                    fullWidth
                    readOnly
                    id='resend-email'
                    label='Email sent to'
                    slotAfter={
                      <Button
                        isDisabled={isSendEmailDisabled}
                        label='Resend Email'
                        size='large'
                        startIcon='send'
                        onClick={onResendEmailButtonClick}
                      />
                    }
                    value={email ?? ''}
                  />
                </div>
              </div>
              {!!externalConfirmationId && (
                <div data-testid='BookingDetailsManage-copy-input'>
                  <div className='hide-on-print'>
                    <Input
                      fullWidth
                      readOnly
                      id='confirmation-number'
                      label='Confirmation Number'
                      slotBefore={
                        <div className='c-canyon'>
                          <Icon name='contentCopy' />
                        </div>
                      }
                      value={`Confirmation number: ${externalConfirmationId}`}
                      onClick={onCopyInputClick}
                    />
                  </div>
                </div>
              )}
            </div>
            <HotelCancellationCard
              fullyRefundableUntil={fullyRefundableUntil}
              nonRefundableAfter={nonRefundableAfter}
              partiallyRefundableUntil={partiallyRefundableUntil}
              policies={policyText}
              refundType={refundType}
              subtitle={shortText}
            />
            <BookingDetailsManageActions />
          </>
        )}
      </div>
    </div>
  )
}
