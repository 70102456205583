import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface DashboardProfileProgressButtonProps
  extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode
  isCompleted: boolean
  onClick: VoidFunction
}

export const DashboardProfileProgressButton = ({
  children,
  isCompleted,
  onClick,
  ...props
}: DashboardProfileProgressButtonProps) => (
  <button
    {...props}
    className='p-x-4 p-y-1 border-1px border-grey-200 rounded-3 transition-shadow-100 hover:shadow-1 flex w-full flex-row items-center gap-3 border-solid bg-transparent text-left outline-none ease-linear hover:cursor-pointer'
    onClick={onClick}
  >
    <div
      className={classNames({
        '[&>i>svg]:color-green-500': isCompleted,
        '[&>i>svg]:color-grey-400': !isCompleted,
      })}
    >
      <Icon name='checkCircleOutline' />
    </div>
    <span
      className={classNames('type-body-1 color-grey-800 grow', {
        'line-through': isCompleted,
        'no-underline': !isCompleted,
      })}
    >
      {children}
    </span>
    <div className='[&>i>svg]:color-new-forest'>
      <Icon name='arrowForward' />
    </div>
  </button>
)
