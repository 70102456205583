import { useEffect, useRef, useState } from 'react'
import { KeyCode, useScreenQuery } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { useFlag } from 'src/common/hooks'
import { useSearchProfiles } from 'src/pages/profile/components/ProfileSearchBar/useSearchProfiles'
import {
  SEARCH_BAR_OPTIONS,
  SEARCH_BAR_STATES,
  TRAVELERS_RESULTS_QUERY_PREFIX,
} from './constants'
import {
  checkCountryStateSearch,
  handleCountryStateSearch,
} from './functions/handleCountryStateSearch'
import { handleDestinationSearch } from './functions/handleDestinationSearch'
import { handleExperienceSearch } from './functions/handleExperienceSearch'
import { handleHotelSearch } from './functions/handleHotelSearch'
import { handleKeywordSearch } from './functions/handleKeywordSearch'
import { handleLocationSearch } from './functions/handleLocationSearch'
import { handleProfileRedirection } from './functions/handleProfileRedirection'
import { handleTravelerSearch } from './functions/handleTravelerSearch'

type HandleClearAndFocus = ({
  newValue,
  focusesInput,
  shouldFetchData,
}: {
  newValue: string | null
  focusesInput?: boolean
  shouldFetchData?: boolean
}) => void

export const useSearchBarState = ({
  closeOnClear = true,
  openDefaultState = SEARCH_BAR_STATES.closed,
  clearOnSearch = true,
}: {
  closeOnClear?: boolean
  openDefaultState?: SEARCH_BAR_STATES
  clearOnSearch?: boolean
} = {}) => {
  const enableTravelersSearch = useFlag('enableTravelersSearch')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  const [state, setState] = useState<SEARCH_BAR_STATES>(openDefaultState)

  const [errorMessage, setErrorMessage] = useState('')

  const openSearchBar = () => setState(SEARCH_BAR_STATES.open)
  const closeSearchBar = () => setState(SEARCH_BAR_STATES.closed)
  const isSearchBarOpen = state === SEARCH_BAR_STATES.open
  const isSearchBarClosed = state === SEARCH_BAR_STATES.closed

  const [asset, setAsset] = useState<SEARCH_BAR_OPTIONS>(
    SEARCH_BAR_OPTIONS.travelers
  )

  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)

  const { isMobileScreen } = useScreenQuery()

  const [showSearchTerm, setShowSearchTerm] = useState(false)

  const isGuideSearch = asset === SEARCH_BAR_OPTIONS.guides
  const isHotelSearch = asset === SEARCH_BAR_OPTIONS.hotels
  const isExperienceSearch = asset === SEARCH_BAR_OPTIONS.experiences
  const isDestinationSearch = asset === SEARCH_BAR_OPTIONS.destinations
  const isTravelersSearch = asset === SEARCH_BAR_OPTIONS.travelers

  const geocoderConfig = isGuideSearch
    ? {
        requestOptions: { componentRestrictions: { country: [] } },
        cacheKey: CACHE_KEYS.withoutRestriction,
      }
    : undefined

  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch(geocoderConfig)

  const {
    searchProfiles,
    data: travelersData,
    error: travelersQueryError,
    loading: travelersQueryLoading,
  } = useSearchProfiles()

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      searchProfiles({
        variables: {
          searchString: value,
        },
      })
    }, 300)

    if (isTravelersSearch && value?.trim()) {
      debouncedSearch()
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [isTravelersSearch, value, searchProfiles])

  useEffect(() => {
    setValue('')
  }, [asset])

  const searchTermEnabled =
    isSearchBarOpen && isGuideSearch && showSearchTerm && value?.trim()

  const geocoderOptions = suggestions?.data || []
  const showGeocoderOptions =
    isSearchBarOpen && Boolean(geocoderOptions?.length) && !isTravelersSearch

  const travelersOptions = travelersData?.searchUserProfiles
  const showTravelersOptions =
    isTravelersSearch && !!travelersOptions?.length && value?.trim()

  const searchTerm = value?.trim()

  const handleClearAndFocus: HandleClearAndFocus = ({
    newValue,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput?: boolean
    shouldFetchData?: boolean
  }) => {
    typeof newValue === 'string' && setValue(newValue, shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    closeOnClear && closeSearchBar()
  }

  const onClear = () =>
    handleClearAndFocus({
      newValue: '',
    })

  const handleOptionSelection = async (selection: Suggestion | string) => {
    if (isGuideSearch) {
      if (typeof selection === 'string') {
        if (selection?.trim().length)
          handleKeywordSearch(
            selection,
            searchParams,
            setSearchParams,
            navigate,
            pathname
          )
      } else if (await checkCountryStateSearch(selection))
        handleCountryStateSearch(
          selection,
          searchParams,
          setSearchParams,
          navigate,
          pathname,
          isMobileScreen
        )
      else
        handleLocationSearch(
          selection,
          navigate,
          pathname,
          searchParams,
          setSearchParams
        )
    }

    if (
      isTravelersSearch &&
      typeof selection === 'string' &&
      selection?.trim().length
    ) {
      if (selection.includes(TRAVELERS_RESULTS_QUERY_PREFIX))
        handleTravelerSearch(
          selection.replace(TRAVELERS_RESULTS_QUERY_PREFIX, ''),
          navigate,
          setSearchParams
        )
      else handleProfileRedirection(selection, navigate)
    }

    if (typeof selection !== 'string') {
      if (isHotelSearch) {
        handleHotelSearch(selection, navigate)
      }

      if (isExperienceSearch) {
        handleExperienceSearch(selection, navigate)
      }

      if (isDestinationSearch) {
        handleDestinationSearch(selection, navigate, searchParams)
      }
    }

    clearOnSearch && onClear()
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER && searchTerm?.trim().length >= 3) {
      if (isGuideSearch) handleOptionSelection(searchTerm)
      if (isTravelersSearch)
        enableTravelersSearch
          ? handleOptionSelection(TRAVELERS_RESULTS_QUERY_PREFIX + searchTerm)
          : handleOptionSelection(travelersOptions[0].accountHandle)
      else if (geocoderOptions?.length)
        handleOptionSelection(geocoderOptions[0])
      clearOnSearch && onClear()
      closeSearchBar()

      setTimeout(() => {
        autocompleteRef.current.blur()
      }, 0)
    }
    if (
      (e.key === KeyCode.ENTER && !searchTerm?.trim()) ||
      (e.key === KeyCode.ENTER && searchTerm?.trim().length < 3)
    )
      setErrorMessage('Please type at least 3 characters to search travelers.')
    if (e.key === KeyCode.ESC && clearOnSearch) onClear()
  }

  const handleSearchByButtonClick = () => {
    if (searchTerm?.trim().length < 3)
      setErrorMessage('Please type at least 3 characters to search travelers.')
    else if (isGuideSearch) handleOptionSelection(searchTerm)
    else if (!isTravelersSearch) handleOptionSelection(geocoderOptions[0])
    else if (isTravelersSearch)
      enableTravelersSearch
        ? handleOptionSelection(TRAVELERS_RESULTS_QUERY_PREFIX + searchTerm)
        : handleOptionSelection(travelersOptions[0].accountHandle)
    else document.getElementById('guides-autocomplete-input')?.focus()
  }

  const handleButtonClick = () => {
    if (isSearchBarOpen) handleSearchByButtonClick()
    if (isSearchBarClosed) openSearchBar()
  }

  const handleClearErrorMessages = () =>
    errorMessage.length ? setErrorMessage('') : null

  useEffect(() => {
    if (isTravelersSearch && travelersQueryError)
      setErrorMessage(travelersQueryError?.message)
  }, [travelersQueryError, isTravelersSearch])

  useEffect(() => {
    if (
      isTravelersSearch &&
      !travelersQueryLoading &&
      !travelersQueryError &&
      !travelersOptions?.length &&
      searchTerm?.trim()?.length > 3
    )
      setErrorMessage('No results found')
    else if (isTravelersSearch && !travelersQueryLoading && !errorMessage)
      setErrorMessage('')
  }, [
    travelersOptions,
    travelersQueryError,
    isTravelersSearch,
    searchTerm,
    travelersQueryLoading,
  ])

  useEffect(() => {
    if (
      !isTravelersSearch &&
      !isGuideSearch &&
      searchTerm?.trim()?.length > 3 &&
      !geocoderOptions?.length &&
      ready
    )
      setErrorMessage('No results found')
  }, [isTravelersSearch, isGuideSearch, searchTerm, geocoderOptions, ready])

  return {
    isMobileScreen,
    handleClearErrorMessages,
    isSearchBarOpen,
    isSearchBarClosed,
    autocompleteParentRef,
    isHomePage,
    asset,
    setAsset,
    errorMessage,
    ready,
    autocompleteRef,
    state,
    value,
    setShowSearchTerm,
    handleSearchTermEnter,
    handleOptionSelection,
    showGeocoderOptions,
    handleButtonClick,
    travelersOptions,
    searchTerm,
    searchTermEnabled,
    geocoderOptions,
    setValue,
    onClear,
    showTravelersOptions,
  }
}
