import { useState } from 'react'
import { EventType } from 'src/__generated__/graphql'
import { HomeHeroContents } from 'src/pages/home-old/HomeHero/HomeHeroContents'
import { HomeContainer } from '../../../home/HomeContainer'

const heroVideo =
  'https://static.travelpass.com/assets/landing-pages/black-friday/black-friday-loop.mp4'

export const BlackFridayHero = () => {
  const [showHeroBgVideo, setShowVideoBgVideo] = useState(true)

  return (
    <section className={mainContainer}>
      {showHeroBgVideo && (
        <video autoPlay loop muted playsInline className={videoBg}>
          <source src={heroVideo} type='video/mp4' />
          <track kind='captions' />
        </video>
      )}
      <div className={gradientStyles} />
      <div className='z-2 relative'>
        <HomeContainer size='md'>
          <div className='z-3 mt45 md:mt80 lg:mt140'>
            <div className='rounded-3 shadow-lg'>
              <HomeHeroContents type={EventType.Stay} />
            </div>
            <p className='type-body-1 mt5 md:mt10 mb--17 text-center'>
              <sup>*</sup>Book by November 29, 11:59 PM in hotel property local
              time and travel by the end of 2024.
            </p>
          </div>
        </HomeContainer>
      </div>
    </section>
  )
}

const mainContainer =
  'bg-warmGrey overflow-hidden pb42 pt0 relative md:pb80 md:pt14'
const gradientStyles =
  'absolute bottom-20 left-0 w-full h-[calc(100%-240px)] md:h-[calc(100%-600px)] bg-gradient-to-t from-warmGrey from-50% to-transparent'
const videoBg =
  'w-full h-auto md:h-130 lg:h-240 absolute top-0 lg:top--15 left-0 object-contain md:object-cover'
