import { type StayEvent } from '../constants'

export const StayMiddle = ({ data }: { data: StayEvent | null }) => (
  <div className='p-8'>
    <div className='flex flex-row items-center justify-between'>
      <div className='m-w-100'>
        <h2 className='type-h6 pb-2'>Checkin</h2>
        <p className='type-body-2'>
          {data?.startDate?.format('ddd, MMMM D, YYYY')}
        </p>
        <p className='type-body-2'>{data?.startTime}</p>
      </div>
      <div className='m-w-100'>
        <h3 className='type-h6 pb-2'>Checkout</h3>
        <p className='type-body-2'>
          {data?.endDate?.format('ddd, MMMM D, YYYY')}
        </p>
        <p className='type-body-2'>{data?.endTime}</p>
      </div>
    </div>

    {!!data?.bookingConfirmation && (
      <span className='text-nowrap pt-8'>
        <p className='type-body-1 line-clamp-1'>
          <strong>Confirmation</strong> # {data?.bookingConfirmation}
        </p>
      </span>
    )}
  </div>
)
