import type { ReactNode } from 'react'

interface HotelResultsCardAmenitiesContainerProps {
  children: ReactNode
}

export const HotelResultsCardAmenitiesContainer = ({
  children,
}: HotelResultsCardAmenitiesContainerProps) => (
  <div className='flex min-h-4 flex-wrap gap-4'>{children}</div>
)
