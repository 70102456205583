import type { ExperienceLineItems } from 'src/__generated__/graphql'
import { getPriceWithDigits, stringToNumber } from 'src/utils'

export const PerPersonBreakdown = ({
  lineItems,
}: {
  lineItems: ExperienceLineItems[]
}) => {
  const numberOfTravelers = lineItems?.reduce(
    (total, { numberOfTravelers }) => total + numberOfTravelers,
    0
  )

  const subtotalPrice = lineItems?.reduce(
    (total, { subtotalPrice }) =>
      total +
      stringToNumber(subtotalPrice?.price?.recommendedRetailPrice?.amount),
    0
  )

  // @todo: does this actually need to be calculated client-side? or is is already available somewhere?
  const pricePerTraveler = getPriceWithDigits(
    (subtotalPrice / numberOfTravelers).toString() ?? '0'
  )

  return (
    <span>{`${numberOfTravelers} Traveler${
      numberOfTravelers > 1 ? 's' : ''
    } x ${pricePerTraveler}`}</span>
  )
}
