import type { KeyboardEvent } from 'react'
import { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  IconButton,
  KeyCode,
  Input,
  Button,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type { Trip } from 'src/__generated__/graphql'
import { tripTimelinePath } from 'src/constants'
import { BookingConfirmationTripsSelectTripPopover } from './BookingConfirmationTripsSelectTripPopover'
import type { SelectedTrip } from './types'

const mergeTripMutation = gql(`
  mutation MergeTripInBookingConfirmation($tripInput: MergeTripInput!) {
    mergeTrip(tripInput: $tripInput) {
      id
      events {
        id
        booking {
          id
        }
      }
      owner {
        externalId
      }
    }
  }
`)

interface BookingConfirmationTripsSelectTripProps {
  currentTripId: string
  trips: Trip[]
}

const BookingConfirmationTripsSelectTrip = ({
  currentTripId,
  trips,
}: BookingConfirmationTripsSelectTripProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const navigate = useNavigate()
  const { addErrorSnack } = useSnackbar()

  const [mergeTrip] = useMutation(mergeTripMutation, {
    onCompleted: ({ mergeTrip }) => {
      const { id, owner } = mergeTrip ?? {}
      const { externalId } = owner ?? {}
      const searchParams = createSearchParams({
        tripSharedBy: externalId ?? '',
      })
      navigate(`${tripTimelinePath}/${id}?${searchParams}`)
    },
  })

  const ref = useRef(null)
  const [selectedTrip, setSelectedTrip] = useState<SelectedTrip>({})

  const onButtonClick = async () => {
    try {
      await mergeTrip({
        variables: {
          tripInput: {
            absorbedId: currentTripId,
            primaryId: selectedTrip?.id ?? '',
          },
        },
      })
    } catch {
      addErrorSnack({ title: 'Unable to add this booking to your trip' })
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event ?? {}

    if (key === KeyCode.ENTER) onPopoverToggle()
  }

  const onPopoverChange = (updatedTrip: SelectedTrip) =>
    setSelectedTrip(updatedTrip)

  const onPopoverClose = () => setIsPopoverOpen(false)

  const onPopoverToggle = () =>
    setIsPopoverOpen(previousPopoverOpen => !previousPopoverOpen)

  return (
    <div className={bookingConfirmationTripsSelectTripCss}>
      <BookingConfirmationTripsSelectTripPopover
        currentTripId={currentTripId}
        isOpen={isPopoverOpen}
        ref={ref}
        selectedTrip={selectedTrip}
        trips={trips.map(trip => ({
          id: trip.id,
          name: trip.name ?? '',
        }))}
        onChange={onPopoverChange}
        onClose={onPopoverClose}
      >
        <div className={bookingConfirmationTripsSelectTripInputCss} ref={ref}>
          <Input
            fullWidth
            readOnly
            aria-label='Select a trip'
            id='booking-confirmation-select-trip'
            name='booking-confirmation-select-trip'
            placeholder='Select a trip'
            slotAfter={<IconButton icon='expandMore' />}
            value={selectedTrip?.name ?? ''}
            onClick={onPopoverToggle}
            onKeyDown={onKeyDown}
          />
        </div>
      </BookingConfirmationTripsSelectTripPopover>
      <div className={bookingConfirmationTripsSelectTripButtonCss}>
        <Button
          fullWidth
          isDisabled={isEmpty(selectedTrip)}
          label='Add To Trip'
          size='large'
          variant='outlined'
          onClick={onButtonClick}
        />
      </div>
    </div>
  )
}

const bookingConfirmationTripsSelectTripButtonCss = 'w-full md:w-48'

const bookingConfirmationTripsSelectTripCss = 'flex flex-col gap-4 md:flex-row'
const bookingConfirmationTripsSelectTripInputCss = 'grow'

export { BookingConfirmationTripsSelectTrip }
