import { Button, useSnackbar } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { dashboardPath } from 'src/constants'
import { copyToClipboard } from 'src/utils'
import { FollowButton } from '../components/FollowButton/FollowButton'
import type { ProfileFields } from '../types'

interface ProfileActionButtonsProps {
  isUserOwner: boolean
  profile: ProfileFields
}

export const ProfileActionButtons = ({
  isUserOwner,
  profile,
}: ProfileActionButtonsProps) => {
  const navigate = useNavigate()
  const { addSuccessSnack } = useSnackbar()
  const { isFollowed } = profile ?? {}

  const onShare = async () => {
    const url = document.location.href
    copyToClipboard(url)
    addSuccessSnack({
      title: 'Successfully copied to the clipboard',
    })
    pushDataToDataLayer('profile_shared', { url })
  }

  return (
    <div className='flex flex-row justify-center gap-2'>
      {isUserOwner && (
        <Button
          aria-label='Edit your profile'
          size='small'
          startIcon='modeEdit'
          type='button'
          variant='filled'
          onClick={() => navigate(`${dashboardPath}?editProfile=true`)}
        >
          Edit
        </Button>
      )}
      {!isUserOwner && (
        <FollowButton
          accountHandle={profile?.accountHandle}
          displayName={profile?.displayName}
          isFollowed={isFollowed}
          label={isFollowed ? 'unfollow' : 'follow'}
          userId={profile?.userId}
          variant={isFollowed ? 'outlined' : 'filled'}
        />
      )}
      {onShare && (
        <Button
          aria-label='Share your profile'
          size='small'
          startIcon='iosShare'
          type='button'
          variant='outlined'
          onClick={onShare}
        >
          Share
        </Button>
      )}
    </div>
  )
}
