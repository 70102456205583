import { Divider, Skeleton } from '@travelpass/design-system'
import { DashboardTripCardLoading } from './DashboardTripCardLoading'

export const DashboardTripsLoading = () => (
  <section className='space-y-6 lg:space-y-12'>
    <div className='flex flex-row items-center justify-between'>
      <div className='h-5.25 w-17.5 md:w-25 lg:h-6'>
        <Skeleton />
      </div>
      <div className='w-35 hidden h-8 md:block'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-8 w-8 md:hidden'>
        <Skeleton variant='circular' />
      </div>
    </div>
    <div className='space-y-9'>
      <div className='space-y-6'>
        <div className='space-y-3'>
          <div className='w-35 h-3 lg:h-3.5'>
            <Skeleton />
          </div>
          <Divider />
        </div>
        <div className='flex h-0 h-auto w-0 w-auto min-w-0 grow flex-row items-center justify-start overflow-hidden'>
          <div className='block pr-1'>
            <div className='h-8 w-8'>
              <Skeleton variant='rounded' />
            </div>
          </div>
          <div className='p-b-5 p-l-4 p-r-5 p-t-5 flex flex-row items-center gap-4'>
            <DashboardTripCardLoading />
            <DashboardTripCardLoading />
            <DashboardTripCardLoading />
          </div>
        </div>
      </div>
      <div className='space-y-6'>
        <div className='space-y-3'>
          <div className='w-18.5 lg:w-22.5 h-3 lg:h-3.5'>
            <Skeleton />
          </div>
          <Divider />
        </div>
        <div className='flex h-0 h-auto w-0 w-auto min-w-0 grow flex-row items-center justify-start overflow-hidden'>
          <div className='block pr-1'>
            <div className='h-8 w-8'>
              <Skeleton variant='rounded' />
            </div>
          </div>
          <div className='p-b-5 p-l-4 p-r-5 p-t-5 flex flex-row items-center gap-4'>
            <DashboardTripCardLoading />
            <DashboardTripCardLoading />
            <DashboardTripCardLoading />
          </div>
        </div>
      </div>
    </div>
  </section>
)
