import { useState } from 'react'
import { Divider, ModalNext } from '@travelpass/design-system'
import { privacyPath } from 'src/constants'

interface TermsAndConditionsModalProps {
  buttonText?: string
  controlledByParent?: boolean
  onClose?: VoidFunction
}

export const TermsAndConditionsModal = ({
  buttonText = 'See Terms And Conditions',
  controlledByParent = false,
  onClose = null,
}: TermsAndConditionsModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {!controlledByParent && (
        <button
          className='b-none p-unset type-button c-newForest bg-transparent'
          type='button'
          onClick={() => setIsOpen(true)}
        >
          {buttonText}
        </button>
      )}
      {(controlledByParent || isOpen) && (
        <ModalNext
          size='large'
          title='Travelpass Dream Vacation Contest Terms and Conditions'
          onClose={() => (onClose ? onClose() : setIsOpen(false))}
        >
          <ul className='list-none space-y-4'>
            <h2>1. Eligibility</h2>
            <li>
              1.1. The contest is open to entrants who are at least 18 years old
              at the time of entry.
            </li>
            <li>
              1.2. Employees, officers, directors, agents, and representatives
              of Travelpass Group and its parent companies, subsidiaries,
              affiliates, advertising agencies, and their immediate family
              members (spouse, parents, siblings, and children){' '}
              <span className='underline'>
                <strong>are</strong>
              </span>{' '}
              eligible to participate.
            </li>
            <li>1.3. The contest is void where prohibited by law.</li>
            <h2>2. How to Enter</h2>
            <li>2.1. No purchase is necessary to enter or win.</li>
            <li>
              2.2. To enter, visit Travelpass.com directly or through a referral
              link provided by a Travelpass Group employee. Complete the
              following requirements with all required information:
            </li>
            <ul className='list-none space-y-2'>
              <li>
                2.2.1. Create a public profile on Travelpass.com, including
                name, profile picture, and bio.
              </li>
              <li>
                2.2.2. Create at least three Guides for a destination of your
                choosing that meets the following minimum requirements:
              </li>
              <ol className='space-y-2' type='i'>
                <li>Written Guide description</li>
                <li>Minimum of five events included</li>
                <li>Minimum of five personalized notes included on events</li>
                <li>
                  At least one personal photo added to the Guide (either in the
                  Guide header or in an event)
                </li>
              </ol>
            </ul>
            <li>
              2.3. Limit one entry per person. Multiple entries from the same
              person will be disqualified.
            </li>
            <li>
              2.4. All entries must be received by 11:59 p.m., MST on Friday,
              November 1, 2024, to be eligible to win.
            </li>
            <li>
              2.5. Votes for a profile during Round 1 will be allowed until
              11:59 p.m., MST on Friday, November 8, 2024.
            </li>
            <li>
              2.6. The top 20 vote earners from Round 1 will move on to Round 2:
            </li>
            <ul className='list-none space-y-2'>
              <li>
                2.6.1. Vote totals will be reset to zero to start Round 2 on
                November 11, 2024.
              </li>
              <li>
                2.6.2. Voting for Round 2 opens on Monday, November 11, 2024, at
                10:00 a.m., MST.
              </li>
              <li>
                2.6.3. Voters may vote for any profiles once in a 24-hour period
                (as measured by Travelpass account)
              </li>
              <li>
                2.6.4. Voting for Round 2 ends at 11:59 p.m., MST on Thursday,
                November 21, 2024.
              </li>
              <li>
                2.6.5. The contestant with the highest number of votes at the
                close of Round 2 will be the winner of the grand prize.
              </li>
            </ul>
            <h2>3. Grand Prize</h2>
            <li>
              3.1. One (1) grand prize winner will be awarded $20,000 USD,
              issued as a check.
            </li>
            <li>
              3.2. The winner will be responsible for any associated taxes from
              the prize.
            </li>
            <li>
              3.3. The prize must be accepted as awarded and cannot be
              transferred or substituted.
            </li>
            <li>
              3.4. Any expenses above the maximum prize value shall be the
              responsibility of the winner.
            </li>
            <h2>4. Other Prizes</h2>
            <li>
              4.1. One (1) $5,000 travel credit referral prize will be awarded
              to the Travelpass Group employee who refers the most entrants to
              this contest.
            </li>
            <ul className='list-none space-y-2'>
              <li>
                4.1.1. A referral is successful and counts towards the referral
                prize tracking only when the referred person completes all the
                steps necessary to enter to win.
              </li>
              <li>
                4.1.2. Travelpass Group will provide tracking to inform
                employees how many successful referrals they have made as part
                of this contest.
              </li>
              <li>
                4.1.3. The winner will be responsible for any associated taxes
                from the prize.
              </li>
              <li>
                4.1.4. The prize will be issued by check from Travelpass Group
                and must be accepted as awarded and cannot be transferred or
                substituted.
              </li>
              <li>
                4.1.5. Any expenses above the maximum prize value shall be the
                responsibility of the winner.
              </li>
            </ul>
            <li>
              4.2. Travelpass Group will provide additional smaller prizes in
              weekly drawings for employees of Travelpass Group.
            </li>
            <ul className='list-none space-y-2'>
              <li>
                4.2.1. Employees receive one entry into the weekly drawing for
                each successful referral of a contact that completes the
                requirements to enter to win.
              </li>
              <li>
                4.2.2. The prize must be accepted as awarded and cannot be
                transferred, substituted, or redeemed for cash.
              </li>
            </ul>
            <h2>5. Winner Selection</h2>
            <li>
              5.1. The grand prize winner will be the contestant receiving the
              most votes in Round 2 of the contest.
            </li>
            <li>
              5.2. If the selected winner does not respond within three days of
              notification, an alternate winner may be selected.
            </li>
            <h2>6. Winner Responsibilities</h2>
            <li>
              6.1. The winner is responsible for any applicable federal, state,
              and local taxes, as well as any other costs or expenses not
              specified as part of the prize.
            </li>
            <li>
              6.2. The winner must provide all necessary documentation,
              including identification and a signed affidavit of eligibility and
              release before the prize is awarded.
            </li>
            <li>
              6.3. The winner agrees to participate in promotional activities as
              required by Travelpass Group, including but not limited to
              providing photos and a testimonial.
            </li>
            <h2>7. General Conditions</h2>
            <li>
              7.1. Travelpass Group reserves the right to cancel, suspend, or
              modify the contest if any fraud, technical failure, or other
              factor beyond its control impairs the integrity or proper
              functioning of the contest, as determined by Travelpass Group in
              its sole discretion.
            </li>
            <li>
              7.2. Travelpass Group is not responsible for any entry that is
              lost, late, misdirected, incomplete, or corrupted.
            </li>
            <li>
              7.3. By entering the contest, participants agree to release and
              hold harmless Travelpass Group, its affiliates, and their
              respective officers, directors, employees, and agents from any
              liability arising from participation in the contest or the
              acceptance or use of the prize.
            </li>
            <h2>8. Privacy</h2>
            <li>
              8.1. Information collected from participants is subject to
              Travelpass Group’s privacy policy, available at{' '}
              <a href={privacyPath}>https://www.travelpass.com/info/privacy.</a>
            </li>
            <h2>9. Governing Law</h2>
            <li>
              9.1. The contest is governed by the laws of Utah, USA, without
              regard to its conflict of laws principles.
            </li>
            <li>
              9.2. Any disputes arising from or related to the contest shall be
              resolved in the courts located in South Jordan, Utah.
            </li>
            <h2>10. Sponsor</h2>
            <li>
              10.1. The contest is sponsored by Travelpass Group, located at
              4700 W Daybreak Village Pkwy, Ste 100, South Jordan, UT, 84009.
            </li>
            <li>
              10.2. For any questions or concerns regarding the contest, please
              contact DreamVacation@travelpassgroup.com
            </li>
            <Divider />
            <p>
              By entering this contest, participants agree to abide by these
              terms and conditions.
            </p>
          </ul>
        </ModalNext>
      )}
    </>
  )
}
