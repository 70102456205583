import type { RefObject } from 'react'
import { forwardRef, useRef, useState } from 'react'
import { Button } from '@travelpass/design-system'
import dayjs from 'dayjs'
import {
  Accordion,
  AccordionItemGroup,
  AccordionPanel,
  useAccordionTrigger,
} from 'src/common/components/Accordion'
import { AvailabilityDateRangeSelect } from './AvailabilityDateRangeSelect'
import { AvailabilityPaxMix } from './AvailabilityPaxMix'
import { useGetExperienceAvailabilitySchedule } from './useGetExperienceAvailabilitySchedule'
import { useGetExperiencePrice } from './useGetExperiencePrice'

const MAX_DISPLAYED = 3

export const Availability = forwardRef((_, ref: RefObject<HTMLDivElement>) => {
  const { data: price } = useGetExperiencePrice()
  const containerRef = useRef(null)
  const { data, loading } = useGetExperienceAvailabilitySchedule()
  const [showMore, setShowMore] = useState(false)

  const pricesPerDay = data?.pricesPerDay
  const hasPrices = pricesPerDay?.length > 0
  const displayShowMoreButton = pricesPerDay?.length > MAX_DISPLAYED
  const pricesPerDayToDisplay = showMore
    ? pricesPerDay
    : pricesPerDay?.slice(0, MAX_DISPLAYED) // limit to 3 items to display until user clicks view more

  return (
    <div
      className='shadow-3 b-1 b-grey-400 b-solid md:max-w-500px mx-auto space-y-6 rounded p-6'
      ref={containerRef}
    >
      <div className='b-b-1 b-b-grey-300 b-b-solid pb-2'>
        <h4 className='type-h4'>
          Select Dates for Pricing{' '}
          {price.amount || (
            <span className='bg-grey300 w-5ch inline-block h-5 animate-pulse'></span>
          )}
        </h4>
        <span className='type-small-text'>per {price.unit}</span>
      </div>
      <AvailabilityDateRangeSelect />
      {hasPrices && (
        <Accordion>
          {pricesPerDayToDisplay.map(
            ({ date, offeredThisDay, fromPriceBeforeDiscount }) => (
              <AccordionItemGroup
                key={date}
                className='b-b-1 b-b-grey-300 b-b-solid'
              >
                <AccordionButton
                  available={offeredThisDay}
                  date={dayjs(date).format('ddd, MMM DD')}
                  title={
                    offeredThisDay
                      ? `From $${fromPriceBeforeDiscount.amount}`
                      : 'Sold Out'
                  }
                />
                <AccordionPanel>
                  <AvailabilityPaxMix travelDate={date} />
                </AccordionPanel>
              </AccordionItemGroup>
            )
          )}
          {displayShowMoreButton && (
            <div className='flex flex-col items-center pt-6'>
              <Button
                label={showMore ? 'Collapse' : 'View all'}
                size='small'
                variant='outlined'
                onClick={() => {
                  if (showMore) {
                    containerRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })
                  }
                  setShowMore(!showMore)
                }}
              />
            </div>
          )}
        </Accordion>
      )}
      {!loading && !hasPrices && (
        <span className='type-small-text text-center'>No availability</span>
      )}
    </div>
  )
})

Availability.displayName = 'Availability'

const AccordionButton = ({
  title,
  date,
  available,
}: {
  title: string
  date: string
  available: boolean
}) => {
  const { id, ariaExpanded, onClick } = useAccordionTrigger()

  return (
    <h4 className='m-0 flex items-center justify-between p-2'>
      <div>
        <span className='type-body-2'>{date}</span>
        <p className='type-h5'>{title}</p>
      </div>
      <Button
        aria-controls={id}
        aria-expanded={ariaExpanded}
        isDisabled={!available}
        label={ariaExpanded ? 'Close' : 'Select'}
        size='small'
        onClick={onClick}
      />
    </h4>
  )
}
