import { Modal } from '@travelpass/design-system'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { ExperiencesLanguagesOffered } from './ExperiencesLanguagesOffered'

interface ExperiencesLanguagesOfferedModalProps {
  languageGuides: ExperienceProduct['languageGuides']
  onClose(): void
}

export const ExperiencesLanguagesOfferedModal = ({
  languageGuides,
  onClose,
}: ExperiencesLanguagesOfferedModalProps) => (
  <Modal size='medium' title='Languages Offered' onDismiss={onClose}>
    <div className={experiencesCancellationPolicyModalCss}>
      <ExperiencesLanguagesOffered languageGuides={languageGuides} />
    </div>
  </Modal>
)

const experiencesCancellationPolicyModalCss = 'flex flex-col gap-4 pb-8'
