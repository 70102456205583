// TODO: add rating if a review will never not be 5 stars.
export const reviewItems: { content: string; name: string }[] = [
  {
    content:
      'Very detailed info for rooms and availability. Booking went very smoothly with no technical delay. Received confirmation text within seconds.',
    name: 'Deborah S.',
  },
  {
    content:
      'I was in a hurry and a bit frazzled. The reservation process took about 4 minutes! Wonderful!',
    name: 'Gary O.',
  },
  {
    content:
      'Thank you for making my return trip smooth. Means so much when dealing with a beloved family member in the hospital to know I have a peaceful place to sleep and recharge at night.',
    name: 'Cristine F.',
  },
  {
    content:
      'This was almost too easy! No tricky pop ups...very straightfroward and plenty of information to make a good decision on what type of room will work for us. Thanks!',
    name: 'Linda C.',
  },
  {
    content:
      'Excellent room rate in comparison to other online offers. Saved over $25! This is our anniversary weekend. Looking forward to the get-a-way.',
    name: 'Timothy W.',
  },
]
