import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const deleteNote = gql(`
  mutation DeleteNoteMutationInTripOverview($id: ID!) {
    deleteNote(id: $id) {
      id
      notes {
        id
      }
    }
  }
`)

export const useDeleteNoteTripOverviewMutation = () => useMutation(deleteNote)
