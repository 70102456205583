import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query GetPlaceDetailsLazyQueryInGuides($placeDetailsRequest: PlaceDetailsRequest!, $includeImageLinks: Boolean = false, $includeWebsite: Boolean = false) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      address
      city
      country
      countryCode
      description
      googlePlaceId
      imageLinks @include(if: $includeImageLinks)
      latitude
      longitude
      name
      postalCode
      rating
      standardHotelId
      state
      type
      website @include(if: $includeWebsite)
    }
  }
`)

export const useGetGuidePlaceDetailsLazyQuery = () =>
  useLazyQuery(getPlaceDetailsQuery)
