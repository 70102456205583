import { Icon } from '@travelpass/design-system'
import {
  HotelCancellationBadge,
  HotelPriceSummary,
} from 'src/common/components'
import { formatDate } from 'src/utils'
import { defaultBookedRoom, type StayEvent } from '../constants'

export const StayRoom = ({ data }: { data: StayEvent | null }) => {
  const bookedRoom = data?.bookingRoom ?? defaultBookedRoom
  const arrival = data?.startDate ? formatDate(data?.startDate) : ''
  const departure = data?.endDate ? formatDate(data?.endDate) : ''

  const plural = (value?: number) => (value === 1 ? '' : 's')
  return (
    <div className='px-8 pt-8'>
      <h2 className='type-h6'>Selected Room:</h2>
      <span className='px-4'>
        <h3 className='type-body-2'>{bookedRoom?.name}</h3>
      </span>
      <div className='h-25 mb-8 flex flex-row items-center justify-start gap-4'>
        <div className='w-4/10 rd-3.5 h-full overflow-hidden'>
          <img
            alt='Hotel Room'
            className='h-full w-full object-cover'
            draggable='false'
            loading='lazy'
            src={bookedRoom?.image}
          />
        </div>
        <div className='flex h-full flex-col items-start justify-between'>
          <div className='flex flex-row items-center justify-center gap-2'>
            <Icon name='personOutline' size='medium' />
            <p className='type-body-2'>
              {bookedRoom?.guests} guest{plural(bookedRoom?.guests)}
            </p>
          </div>
          <div className='flex flex-row items-center justify-center gap-2'>
            <Icon name='bed' size='medium' />
            <p className='type-body-2'>
              {bookedRoom?.roomCount} room{plural(bookedRoom?.roomCount)}
            </p>
          </div>
          <div className='flex flex-row items-center justify-center gap-2'>
            <Icon name='nightlight' size='medium' />
            <p className='type-body-2'>
              {bookedRoom?.nightCount} night{plural(bookedRoom?.nightCount)}
            </p>
          </div>
        </div>
      </div>
      <HotelPriceSummary
        showLegal
        additionalFees={bookedRoom?.additionalFees}
        allInTotal={bookedRoom?.allInTotal}
        arrival={arrival}
        departure={departure}
        dueLater={bookedRoom?.dueLater}
        dueNow={bookedRoom?.dueNow}
        hotelFees={bookedRoom?.hotelFees}
        rooms={bookedRoom?.roomCount}
        subtotal={bookedRoom?.subtotal}
        surchargeTotal={bookedRoom?.surchargeTotal}
        surcharges={bookedRoom?.surcharges}
      />
      <div className='pt-6'>
        <HotelCancellationBadge type={bookedRoom?.refundType} />
      </div>
    </div>
  )
}
