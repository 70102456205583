import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { TripDetailsExploreListApolloLoading } from './TripDetailsExploreList'
import { TripDetailsExploreNavLoading } from './TripDetailsExploreNav'
import {
  TripContainer,
  TripContentWrapper,
  TripMapWrapper,
} from '../../components'
import { TripNavLoading } from '../../components/TripNav'
import { TripTopNavLoading } from '../../components/TripTopNav'

export const TripExploreLoading = () => {
  const [searchParams] = useSearchParams()
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'

  return (
    <>
      <TripTopNavLoading />
      <TripContainer>
        {!mobileMapView && (
          <TripContentWrapper>
            <TripNavLoading />
            <div className='space-y-3 md:space-y-4'>
              <div className='space-y-4'>
                <TripDetailsExploreNavLoading />
              </div>
              <TripDetailsExploreListApolloLoading />
            </div>
          </TripContentWrapper>
        )}
        <div
          className={classNames('lg:block', {
            hidden: !mobileMapView,
          })}
        >
          <TripMapWrapper isDrawerOpen={false}>
            <Skeleton />
          </TripMapWrapper>
        </div>
      </TripContainer>
    </>
  )
}
