import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { useSearchParams } from 'react-router-dom'
import { stringToNumber } from 'src/utils'
import type { ExploreMarker } from '../../constants'
import { ExploreSearchParams } from '../../constants'

interface TripDetailsExploreMapEventsProps {
  points: ExploreMarker[]
}

/** @todo remove and use TripDetailsMapEvents */
export const TripDetailsExploreMapEvents = ({
  points,
}: TripDetailsExploreMapEventsProps) => {
  const map = useGoogleMap()
  const [searchParams] = useSearchParams()
  const activeExploreId = searchParams.get(ExploreSearchParams.activeExploreId)

  useEffect(() => {
    if (activeExploreId) {
      const { lat, long } =
        points?.find(({ id }) => id === activeExploreId) ?? {}

      if (lat && long) {
        map?.setCenter({
          lat: stringToNumber(lat),
          lng: stringToNumber(long),
        })
      }
    }
  }, [activeExploreId])

  return <></>
}
