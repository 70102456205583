import {
  Carousel,
  SkeletonDots,
  useScreenQuery,
} from '@travelpass/design-system'
import {
  GuideRecommendedCard,
  useOnClickOwnerProfile,
  ButtonLink,
} from 'src/common/components'
import { useGetPlanPageGuides } from '../hooks/useGetPlanPageGuides'

export const PlanGetInspired = () => {
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const { data, loading } = useGetPlanPageGuides()
  const curatedGuides = data?.curatedGuides?.slice(0, 3)

  const onClickOwnerProfile = useOnClickOwnerProfile()

  return (
    <div className='space-y-8 md:space-y-10'>
      <header className='space-y-5 text-center'>
        <h1 className='type-h2 c-black'>Explore Top Travel Guides</h1>
        <div className='border-2px border-valley w-30 mx-auto border-solid'></div>
      </header>
      <section>
        {isMobileOrTablet &&
          (loading ? (
            <div className='h-66 py-32'>
              <SkeletonDots />
            </div>
          ) : (
            <div className='px-10'>
              <Carousel
                borderRadius={12}
                hideArrows={true}
                keyBoardControl={true}
                size='small'
                slidesPerViewOnDesktop={2}
                slidesPerViewOnMobile={1}
                slidesPerViewOnTablet={2}
              >
                {curatedGuides?.map((guide, index) => (
                  <div
                    key={index}
                    className='mx-auto mb-8 max-w-[calc(100%-24px)]'
                  >
                    <GuideRecommendedCard
                      key={index}
                      guideRecommendedCardData={guide}
                      onClickOwnerProfile={onClickOwnerProfile}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          ))}
        {isDesktopScreen &&
          (loading ? (
            <div className='h-100 py-48'>
              <SkeletonDots />
            </div>
          ) : (
            <div className='max-w-304 mx-auto grid grid-cols-3 gap-6'>
              {curatedGuides?.map((guide, index) => (
                <GuideRecommendedCard
                  key={index}
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              ))}
            </div>
          ))}
      </section>
      <div className='px-8% text-center'>
        <ButtonLink
          className='mx-auto inline-flex w-full justify-center md:w-fit'
          to='/guides/all'
        >
          See More Guides
        </ButtonLink>
      </div>
    </div>
  )
}
