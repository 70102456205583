import { Button, Carousel } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
} from 'src/utils'
import { outdoorAdventuresLocations } from './outdoorAdventuresConstants'

export const OutdoorAdventuresLocations = () => {
  const navigate = useNavigate()

  const onOutdoorExperiencesClick = ({ loc, lat, long }) => {
    const url = generateExperiencesResultsUrl({
      location: loc,
      latitude: lat,
      longitude: long,
      filters: {
        tags: [22046],
      },
    })
    navigate(url)
  }

  const onHotelsClick = ({ loc, lat, long }) => {
    const url = generateHotelResultsUrl({
      location: loc,
      latitude: lat,
      longitude: long,
    })
    navigate(url)
  }

  const constructedOutdoorAdventuresLocations = () =>
    outdoorAdventuresLocations.map(
      ({ image, city, state, description, urlData }) => (
        <div key={city} className='p2'>
          <div className={cardContainer}>
            <div className={cardContainerInner}>
              <div className={cardImageContainer}>
                <img alt={city} className={cardImage} src={image} />
              </div>
              <div className={cardContentContainer}>
                <h3 className={cardTitle}>
                  {city}
                  {state && `, ${state}`}
                </h3>
                <div className={divider}></div>
                <p className={cardDescription}>{description}</p>
                <div className='mt10'>
                  {urlData.map(({ latitude, longitude }) => (
                    <div key={city} className={buttonContainer}>
                      <Button
                        fullWidth
                        label={`${city} Outdoor Adventures`}
                        size='large'
                        onClick={() =>
                          onOutdoorExperiencesClick({
                            loc: city,
                            lat: latitude,
                            long: longitude,
                          })
                        }
                      />
                      <Button
                        fullWidth
                        label={`${city} Hotels`}
                        size='large'
                        onClick={() =>
                          onHotelsClick({
                            loc: city,
                            lat: latitude,
                            long: longitude,
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )

  return (
    <Carousel
      isLight={true}
      size='medium'
      slidesPerViewOnDesktop={1}
      slidesPerViewOnMobile={1}
      slidesPerViewOnTablet={1}
    >
      {constructedOutdoorAdventuresLocations()}
    </Carousel>
  )
}

const cardContainer =
  'md:w450px lg:wfull mxa mt10 py6 px4 bg-white border-rounded-6 shadow-1 lg:my0 lg:py8 lg:px6'
const cardContainerInner = 'flex flex-col lg:flex-row gap6'
const cardImageContainer =
  'flex-auto w-full lg:w40 h200px lg:h400px shadow-lg border-rounded-4 overflow-hidden'
const cardImage = 'block object-cover w-full h-full'
const cardContentContainer = 'flex-auto w-full lg:w60'
const cardTitle = 'type-h3 my3'
const divider = 'w20 h3px bg-valley'
const cardDescription = 'type-body-1 mt3'
const buttonContainer = 'w90% ma flex flex-col gap3 justify-center items-center'
