import { Button, Modal, ModalActions } from '@travelpass/design-system'
import { firebaseSignOut } from 'src/config/firebase/firebaseUtils'

export const AccountDeletedModal = ({ onClose }: { onClose: VoidFunction }) => {
  const onConfirm = async () => {
    await firebaseSignOut()
    onClose()
  }

  return (
    <Modal
      size='medium'
      title='Account scheduled for deletion'
      onDismiss={onConfirm}
    >
      This account is scheduled for deletion. Please sign out, and create an
      account with a different email address to continue using&nbsp;Travelpass.
      <ModalActions>
        <Button label='Sign out' onClick={onConfirm} />
      </ModalActions>
    </Modal>
  )
}
