import { Divider, Skeleton } from '@travelpass/design-system'

export const SectionTitleLoading = () => (
  <div className='flex flex-col'>
    <div className='w-25 h-3 lg:h-3.5'>
      <Skeleton />
    </div>
    <Divider className='mb-3 mt-1' />
  </div>
)
