import { WeatherIcon } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import {
  useGetTripDetailsQuery,
  useGetTripWeatherQuery,
} from 'src/pages/trips/hooks'
import { OverviewWeatherLoading } from './OverviewWeatherLoading'
import { getWeatherIcon } from './overviewWeatherUtils'

interface OverviewWeatherProps
  extends Pick<
    GetTripDetailsQueryInTripsQuery['getTrip'],
    'endDate' | 'startDate'
  > {}

export const OverviewWeather = ({
  endDate,
  startDate,
}: OverviewWeatherProps) => {
  useGetTripDetailsQuery()
  const { tripId } = useParams()
  const { isLoading, tripWeatherData } = useGetTripWeatherQuery(tripId)
  const { maxTemp, minTemp, primaryCondition } =
    tripWeatherData?.historicalWeather ?? {}
  const showWeather = maxTemp && minTemp

  /** @todo should be returned on the BE */
  const getMonth = () => {
    const startDateAsDate = dayjs(startDate)
    const endDateAsDate = dayjs(endDate)
    if (startDateAsDate.isSame(endDateAsDate, 'month')) {
      return startDateAsDate.format('MMMM')
    }

    return `${startDateAsDate.format('MMM')} - ${endDateAsDate.format('MMM')}`
  }

  if (isLoading) return <OverviewWeatherLoading />

  return (
    showWeather && (
      <div className='b-grey-300 b-solid b-1 rounded-2 md:w-41 flex h-full flex-col justify-center space-y-2 p-4 text-center'>
        <div className='m-x-auto'>
          <WeatherIcon name={getWeatherIcon(primaryCondition)} size='large' />
        </div>
        <div className='text-center'>
          <p className='color-blue-light font-roboto type-h2-mobile'>
            {minTemp}°/ {maxTemp}°
          </p>
          <p className='color-grey-700 type-small-text'>
            Avg. temp in <span className='whitespace-nowrap'>{getMonth()}</span>
          </p>
        </div>
      </div>
    )
  )
}
