import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import { useSearchParams } from 'react-router-dom'
import { TripSearchParams } from 'src/pages/trips/constants'
import { getGuidePlaceLatLng } from 'src/utils'

interface TripExploreMapEventsProps {
  placePoints: google.maps.places.PlaceResult[]
}

export const TripExploreMapEvents = ({
  placePoints,
}: TripExploreMapEventsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const map = useGoogleMap()
  const selectedId = searchParams.get(TripSearchParams.selectedId)

  useEffect(() => {
    if (placePoints && selectedId) {
      const place =
        placePoints?.find(placePoint => placePoint?.place_id === selectedId) ??
        {}
      const { location } = place?.geometry ?? {}
      const { lat, lng } = getGuidePlaceLatLng(location) ?? {}

      if (lat && lng) {
        map?.setCenter({
          lat,
          lng,
        })
      } else {
        searchParams.delete(TripSearchParams.selectedId)
        setSearchParams(searchParams, {
          replace: true,
        })
      }
    }
  }, [placePoints, selectedId])

  return <></>
}
