import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { experiencesSegment, hotelsSegment } from 'src/constants'
import { ExperienceBookings } from './ExperienceBookings'
import { HotelBookings } from './HotelBookings'

export const MyBookingsContent = () => {
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  return (
    <div className='bg-beach'>
      <PageLayoutContainer>
        <div className='h-full min-h-screen py-10'>
          {tab === hotelsSegment && <HotelBookings />}
          {tab === experiencesSegment && <ExperienceBookings />}
        </div>
      </PageLayoutContainer>
    </div>
  )
}
