import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { OutdoorAdventuresLocations } from './OutdoorAdventuresLocations'

export const OutdoorAdventures = () => {
  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/outdoor-adventures/'

  const navigate = useNavigate()

  const onExploreMoreClick = () => {
    const url = '/'
    navigate(url)
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className={mainContainer}>
        <div className={videoContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={videoBg}
            poster=''
            preload='metadata'
          >
            <source
              src={`${imagePath}outdoor-adventures-loop.mp4`}
              type='video/mp4'
            />
            <track kind='captions' />
          </video>
        </div>

        <div className={lowerContainer}>
          <PageLayoutContainer>
            <div className={lowerContainerInner}>
              <div className={titleContainer}>
                <h1 className={h1Styles}>
                  <span className={h1Upper}>
                    Discover Nature’s Untamed Beauty With These
                  </span>
                  <span className={h1Lower}>Outdoor Adventures</span>
                </h1>
              </div>
              <div className={carouselContainer}>
                <OutdoorAdventuresLocations />
              </div>
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div className={bottomContainer}>
        <PageLayoutContainer>
          <h2 className={bottomContainerTitleTop}>Eager for more adventure?</h2>
          <h2 className={bottomContainerTitle}>
            Travelpass has incredible, top-rated activities available in all of
            your favorite cities.
          </h2>
          <div className={buttonContainer}>
            <Button
              fullWidth
              label='Explore More'
              size='large'
              variant='filledDark'
              onClick={onExploreMoreClick}
            />
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}

const canonicalUrl = 'https://www.travelpass.com/outdoor-adventures'
const metaDescription = ''
const pageName = 'Outdoor Adventures'

const mainContainer =
  'relative bg-gradient-to-t from-forestLight from-83% lg:from-5% to-transparent to-92% lg:to-30% pt40 lg:pt-10 pb20 lg:pb60'
const videoContainer =
  'absolute h-full top-0 bottom-0 left-0 right-0 z--2 bg-forest'
const videoBg =
  'absolute top-0 right-0 bottom-0 left-0 aspect-auto w-full z-1 lg:z--1'
const lowerContainer = 'relative lg:z-10 w-full lg:w-50%'
const lowerContainerInner =
  'lg:flex lg:flex-col w-full max-w-1280px ma items-start'
const titleContainer = 'w-full mt-30 lg:mt-0'
const h1Styles =
  'color-white flex flex-col font-archivo font-not-oblique font-700 m0 text-center tracking-5%'
const h1Upper =
  'leading-34px mb3 text-21px lg:leading-19 lg:text-6 lg:text-shadow-lg'
const h1Lower =
  'leading-15 text-16 lg:mb-10 lg:leading-24 lg:text-24 lg:text-shadow-lg'
const carouselContainer = 'w-full max-w-800px mxa'
const bottomContainer = 'w-full py16 bg-forestLight text-center'
const bottomContainerTitleTop =
  'type-h2 text-8 leading-8 lg:text-14 lg:leading-14 font-900 c-beach max-w65% ma mb8'
const bottomContainerTitle = 'type-h2 c-beach max-w65% ma'
const buttonContainer = 'w230px mxa mt14'
