import type { DropAnimation, UniqueIdentifier } from '@dnd-kit/core'
import { DragOverlay } from '@dnd-kit/core'
import { createPortal } from 'react-dom'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSection } from './GuideDraftSection'
import type {
  GuideDraftDataOld,
  GuideDraftDragItem,
  GuideDraftDragItems,
  GuideDraftPublishedEvent,
} from '../../types'
import {
  GuideDraftEvent,
  GuideDraftEventCard,
  GuideDraftEvents,
} from '../GuideDraftEvents'

interface GuideDraftSectionsOverlayProps {
  activeId: UniqueIdentifier
  containers: UniqueIdentifier[]
  dropAnimation: DropAnimation
  isGuideNewEventAddingEnabled: boolean
  items: GuideDraftDragItems
  owner: GuideOwner
  paginatedEventCategories: GuideDraftDataOld['paginatedEventCategories']
}

export const GuideDraftSectionsOverlay = ({
  activeId,
  containers,
  dropAnimation,
  isGuideNewEventAddingEnabled,
  items,
  owner,
  paginatedEventCategories,
}: GuideDraftSectionsOverlayProps) => {
  const { category, event } = Object.values(items ?? {}).reduce<{
    category?: GuideDraftDragItem
    event?: GuideDraftPublishedEvent
  }>((total, current) => {
    const currentItem = current?.publishedEvents?.find(
      event => event?.id === activeId
    )

    if (currentItem) {
      total = {
        category: current,
        event: currentItem,
      }
    }

    return total
  }, {})

  return createPortal(
    <DragOverlay dropAnimation={dropAnimation}>
      {activeId ? (
        containers?.includes(activeId) ? (
          <GuideDraftSection
            isGuideNewEventAddingEnabled={isGuideNewEventAddingEnabled}
            isOverlay={true}
            paginatedEventCategory={items?.[activeId]}
          >
            <GuideDraftEvents
              isGuideNewEventAddingEnabled={isGuideNewEventAddingEnabled}
              isSortingContainer={false}
              owner={owner}
              paginatedEventCategories={paginatedEventCategories}
              paginatedEventCategory={items?.[activeId]}
              selectedId={null}
              onMapMarkerCenterChange={null}
              onSelectedIdChange={null}
            />
          </GuideDraftSection>
        ) : (
          <GuideDraftEvent
            isOverlay={true}
            isSortingContainer={false}
            publishedEventId={event?.id}
          >
            <GuideDraftEventCard
              isHovered={false}
              isSelected={false}
              owner={owner}
              paginatedEventCategories={paginatedEventCategories}
              paginatedEventCategoryId={category?.id}
              publishedEvent={event}
              onMapMarkerCenterChange={null}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              onSelectedIdChange={() => {}}
            />
          </GuideDraftEvent>
        )
      ) : null}
    </DragOverlay>,
    document.body
  )
}
