import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { GuideSessionStorage } from './guideConstants'

const hoverIdEventName = 'guideHoverId'

// Debounce function to optimize event dispatch
const debouncedDispatchEvent = debounce((eventName: string) => {
  window.dispatchEvent(new Event(eventName))
}, 50)

// Function to handle hoverId changes
const onGuideSessionStorageHoverIdChange = (updatedHoverId: string) => {
  const previousHoverId =
    sessionStorage.getItem(GuideSessionStorage.hoverId) ?? ''

  if (updatedHoverId !== previousHoverId) {
    sessionStorage.setItem(GuideSessionStorage.hoverId, updatedHoverId)
    debouncedDispatchEvent(hoverIdEventName)
  }
}

// Custom hook to use both hoverId from sessionStorage
const useGuideSessionStorageIds = () => {
  const [hoverId, setHoverId] = useState(
    sessionStorage.getItem(GuideSessionStorage.hoverId) ?? ''
  )

  useEffect(() => {
    const hoverIdListener = () =>
      setHoverId(sessionStorage.getItem(GuideSessionStorage.hoverId) ?? '')
    window.addEventListener(hoverIdEventName, hoverIdListener)

    return () => {
      window.removeEventListener(hoverIdEventName, hoverIdListener)
    }
  }, [])

  return { hoverId }
}

export { onGuideSessionStorageHoverIdChange, useGuideSessionStorageIds }
