import { useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import type { Guide, PublishedEvent } from 'src/__generated__/graphql'
import { GuideSection } from './GuideSection'
import { GuideSectionsEmpty } from './GuideSectionsEmpty'
import { GuideSectionsLoading } from './GuideSectionsLoading'
import { getGuideEventMarkers } from '../../guideUtils'
import type { GuideOwner } from '../../types'
import { useGetGuideEventsQuery } from '../../useGetGuideEventsQuery'
import { GuideEvents } from '../GuideEvents'

interface GuideSectionsProps {
  eventCount: number
  guideId: Guide['id']
  onPointsChange: (updatedPoints: PublishedEvent[]) => void
  onSelectedIdChange(updatedSelectedId: string): void
  owner: GuideOwner
  selectedId: string
}

export const GuideSections = ({
  eventCount,
  guideId,
  onPointsChange,
  onSelectedIdChange,
  owner,
  selectedId,
}: GuideSectionsProps) => {
  const { data, guideEventsData, hasError, isLoading, onGetMoreResults } =
    useGetGuideEventsQuery(guideId)
  const { paginatedEventCategories, uncategorizedPublishedEvents } =
    guideEventsData ?? {}

  useEffect(() => {
    if (!isEmpty(data) && !isLoading) {
      const updatedPoints = getGuideEventMarkers({
        paginatedEventCategories,
        uncategorizedPublishedEvents,
      })
      onPointsChange(updatedPoints)
    }
  }, [data, isLoading])

  if (isLoading) return <GuideSectionsLoading />

  if (hasError || !eventCount)
    return <GuideSectionsEmpty isUserOwner={owner?.isUserOwner} />

  return (
    <div className='p-t-4 grow space-y-6'>
      {!!uncategorizedPublishedEvents?.edges?.length && (
        <div className='space-y-3 md:space-y-4'>
          <GuideEvents
            owner={owner}
            paginatedEventCategoryId={null}
            publishedEventConnection={uncategorizedPublishedEvents}
            selectedId={selectedId}
            onGetMoreResults={onGetMoreResults}
            onSelectedIdChange={onSelectedIdChange}
          />
        </div>
      )}
      {paginatedEventCategories?.edges?.map(({ node }) => {
        return (
          <GuideSection
            key={node?.id}
            owner={owner}
            paginatedEventCategory={node}
            selectedId={selectedId}
            onGetMoreResults={onGetMoreResults}
            onSelectedIdChange={onSelectedIdChange}
          />
        )
      })}
    </div>
  )
}
