import { gql } from 'src/__generated__'

const currentUserExperienceBookingsGQL = gql(` 
query currentUserExperienceBookingsQuery($after: String, $first: Int, $searchUserExperienceBookingsArgs: SearchUserExperienceBookingsArgs!) {
  currentUser {
    id
    experienceBookings(after: $after, first: $first, searchUserExperienceBookingsArgs: $searchUserExperienceBookingsArgs) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          externalConfirmationId
          status
          travelDate
          startTime
          productCode
          productName
          paxMix {
            numberOfTravelers
          }
        }
      }
    }
  }
}
`)

const currentUserExperienceBookingsExperienceImageGQL = gql(` 
query currentUserExperienceBookingsExperienceImageQuery($productCode: String!) {
    findProduct(productCode: $productCode) {
      id
      images {
        size360x240
      }
    }
  }
`)

export {
  currentUserExperienceBookingsGQL,
  currentUserExperienceBookingsExperienceImageGQL,
}
