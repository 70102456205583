import type { UnderlineTabProps } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import { explorePath, travelersPath } from 'src/constants'
import { GuidesSearchBar } from '../../GuidesSearchBar'
import { TravelersSearchBar } from '../../TravelersSearchBar'

const NavlinkTab = props => {
  return (
    <NavLink
      {...props}
      className='text-body-1 c-grey-800 border-b-solid [&.active]:border-b-valley [&.active]:c-forest inline-flex items-center gap-2 border-b-2 border-b-transparent bg-transparent p-4 no-underline transition-all ease-in hover:bg-black/5 md:px-8 md:py-6 [&.active]:border-b-2'
      state={{ preventScroll: true }}
    />
  )
}

type SEARCH_TYPE = 'guide' | 'traveler'

const tabs: (Omit<UnderlineTabProps['tabs'][0], 'onClick'> & {
  path: string
  end?: boolean | ((props: boolean) => boolean)
})[] = [
  {
    icon: 'listAlt',
    label: 'Travel Guides',
    path: explorePath,
    end: isTravelersPath => (isTravelersPath ? true : false),
  },
  {
    icon: 'personOutline',
    label: 'Travelers',
    path: travelersPath,
  },
]

const mainTextOptions: Record<SEARCH_TYPE, string> = {
  guide: 'Travel With Real Recommendations',
  traveler: 'Get Inspired By Travelers Like You',
}

export const FullTravelerSearch = () => {
  const { pathname } = useLocation()

  const searchType: SEARCH_TYPE = pathname.includes(travelersPath)
    ? 'traveler'
    : 'guide'

  const isGuideSearch = searchType === 'guide'
  const isTravelerSearch = searchType === 'traveler'

  const mainText = mainTextOptions[searchType]

  return (
    <div className='relative'>
      <picture
        className={classNames(
          backgroundImageCss,
          isGuideSearch ? 'opacity-100' : 'opacity-0'
        )}
      >
        <source
          media='(min-width: 768px)' // Matches md breakpoint
          srcSet='https://static.travelpass.com/assets/guides/guide-search-bg.webp'
        />
        <source
          media='(max-width: 767px)' // Matches max-md breakpoint
          srcSet='https://static.travelpass.com/assets/explore/guide-search-bg-mobile.webp'
        />
        <img
          alt='Guide Search Background'
          className={backgroundImageCss}
          src='https://static.travelpass.com/assets/explore/guide-search-bg-mobile.webp' // Fallback
        />
      </picture>

      <picture
        className={classNames(
          backgroundImageCss,
          isTravelerSearch ? 'opacity-100' : 'opacity-0'
        )}
      >
        <source
          media='(min-width: 768px)'
          srcSet='https://static.travelpass.com/assets/explore/traveler-search-bg.webp'
        />
        <source
          media='(max-width: 767px)'
          srcSet='https://static.travelpass.com/assets/travelers/traveler-search-bg-mobile.webp'
        />
        <img
          alt='Traveler Search Background'
          className={backgroundImageCss}
          src='https://static.travelpass.com/assets/travelers/traveler-search-bg-mobile.webp' // Fallback
        />
      </picture>

      <section className='py-34 lg:max-h-4xl ease space-y-10 bg-[size:100%] bg-no-repeat transition-all md:py-44'>
        <h1 className='type-h1 text-center max-md:px-2'>
          <span className='hidden lg:block'>{mainText}</span>
          <span className='block text-4xl lg:hidden'>{mainText}</span>
        </h1>
        <nav aria-label='Search by category' className='flex justify-center'>
          {tabs.map(({ label, path, icon, end }) => (
            <NavlinkTab
              key={path}
              end={typeof end === 'function' ? end(isTravelerSearch) : end}
              to={path}
            >
              <Icon name={icon} />
              {label}
            </NavlinkTab>
          ))}
        </nav>
        {isTravelerSearch ? (
          <TravelersSearchBar
            hideSlotBefore
            className='max-w-150 mx-auto px-4 max-md:px-8'
          />
        ) : (
          <GuidesSearchBar
            hideSlotBefore
            className='max-w-150 mx-auto px-4 max-md:px-8'
          />
        )}
      </section>
    </div>
  )
}

const backgroundImageCss =
  '-z-1 lg:min-h-500px absolute inset-0 h-full w-full object-cover ease transition-all duration-300'
