import { Outlet } from 'react-router-dom'
import { Helmet } from 'src/common/components'

export const Travelers = () => {
  return (
    <>
      <Helmet pageName='Travelers Search' />
      <Outlet />
    </>
  )
}
