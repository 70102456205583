import type {
  ListHotelReviewsArgs,
  TravelerType,
  HotelReviewsListHotelReviewsQueryVariables,
} from 'src/__generated__/graphql'
import type { HotelReviewsFilterType } from './hotelReviewsApolloConstants'
import { sortItems, travelerTypeItems } from './hotelReviewsApolloConstants'

export const createHotelReviewsArguments = ({
  comment,
  cursor,
  sort,
  pageSize = 20,
  standardHotelId,
  travelerTypes,
}: ListHotelReviewsArgs): HotelReviewsListHotelReviewsQueryVariables => ({
  listHotelReviewsArgs: {
    comment,
    cursor,
    pageSize,
    removeEmpty: true,
    sort,
    standardHotelId,
    travelerTypes,
  },
})

export const getSortValue = (
  sortValue: HotelReviewsFilterType['sort'] | null
) => !!sortValue && sortItems.find(({ value }) => sortValue === value)

export const getTravelerType = (travelerTypeValue: TravelerType | null) =>
  !!travelerTypeValue &&
  travelerTypeItems.find(({ value }) => travelerTypeValue === value)
