import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { Scroller, ScrollerButton } from 'src/common/components'
import { tripTimelinePath } from 'src/constants'
import { TripSearchParams } from 'src/pages/trips/constants'
import { getDateFromUTC } from 'src/utils'
import { DateTab } from './DateTab'

const CURRENT_DAY = 1

export const DateTabs = ({
  activeTabIndex,
  status,
  startDate: firstTripDate,
  endDate,
  timeZone,
  updateActiveTabIndex,
}: {
  activeTabIndex: number
  status: string
  startDate: string
  endDate: string
  timeZone: string
  updateActiveTabIndex(tabIndex: number): void
}) => {
  const [tabs, setTabs] = useState<
    {
      title: string
      subtitle?: string
      isTitleCase: boolean
      isSelected: boolean
      onClick: () => void
    }[]
  >([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { tripId } = useParams()
  const dateTab = searchParams.get(TripSearchParams.dateTab)
  const tripSharedBy = searchParams.get(TripSearchParams.tripSharedBy)
  const startDate = getDateFromUTC(firstTripDate, timeZone)
  const numDays =
    dayjs(endDate).tz(timeZone).diff(dayjs(firstTripDate), 'day') + CURRENT_DAY

  const onTabChange = (newValue: number) => {
    const searchParams = createSearchParams({
      tripSharedBy,
      dateTab: newValue.toString(),
    })
    updateActiveTabIndex(newValue)
    navigate(`${tripTimelinePath}/${tripId}?${searchParams}`, {
      replace: true,
    })
  }

  useEffect(() => {
    dateTab && updateActiveTabIndex(parseInt(dateTab))
  }, [dateTab])

  useEffect(() => {
    if (!isNaN(numDays)) {
      const updatedTabs: {
        title: string
        subtitle?: string
        isTitleCase: boolean
        isSelected: boolean
        onClick: () => void
      }[] = []

      Array(numDays)
        ?.fill(0)
        ?.forEach((_, index) => {
          const hasFirstDate = isEmpty(firstTripDate)
          const date = hasFirstDate ? null : startDate?.add(index, 'day')

          const title = hasFirstDate
            ? `Day ${index + 1}`
            : `${date?.format('DD')}`

          const subtitle = hasFirstDate ? undefined : `${date?.format('ddd')}`

          updatedTabs.push({
            title,
            subtitle,
            isTitleCase: true,
            isSelected: activeTabIndex === index,
            onClick: () => onTabChange(index),
          })
        })

      setTabs(updatedTabs)
      const dateTabNum = dateTab ? parseInt(dateTab) : 0

      updateActiveTabIndex(dateTabNum)
    }
  }, [status, activeTabIndex, numDays])

  return (
    <Scroller
      allowTouch
      LeftArrow={
        <div className='hidden flex-row items-center justify-center pr-2 md:flex'>
          <ScrollerButton icon='arrowBackIos' size='small' />
        </div>
      }
      RightArrow={
        <div className='hidden flex-row items-center justify-center pl-2 md:flex'>
          <ScrollerButton isNext icon='arrowForwardIos' size='small' />
        </div>
      }
      scrollContainerClassName='space-x-2.5'
      wrapperClassName='w-full relative'
      onInit={api => {
        /** @todo: understand if/why getItemElementByIndex requires a string as an index... */
        const scrollToItem = api?.getItemElementByIndex(
          activeTabIndex.toString()
        )

        if (scrollToItem) {
          api?.scrollToItem(scrollToItem)
        }
      }}
    >
      {tabs?.map((tabData, index) => (
        <DateTab
          key={`underlineTab-${tabData?.title}-${index}`}
          {...tabData}
          isSelected={tabData?.isSelected}
          isTitleCase={tabData?.isTitleCase}
          subtitle={tabData?.subtitle}
          title={tabData?.title}
          onClick={tabData?.onClick}
        />
      ))}
    </Scroller>
  )
}
