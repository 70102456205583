import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import { MenuLink } from './MenuLink'

interface MenuTitleProps {
  to: string
  title: string
  icon: AvailableIcons
}

export const MenuTitle = ({ to, title, icon }: MenuTitleProps) => (
  <MenuLink className='type-h5' to={to}>
    <span className='c-grey800 contents'>
      <Icon name={icon} />
    </span>
    {title}
  </MenuLink>
)
