import type { Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { Experience } from './Experience'
import { ExperienceDetailsContent } from './ExperienceDetailsContent'
import {
  HotelRecommendedContent,
  HotelRecommendedHero,
} from './HotelRecommendedContent'
import { Miscellaneous } from './Miscellaneous'
import { Stay } from './Stay'
import { DrawerType, TripSearchParams } from '../../constants'

export const TripDrawerContent = ({
  timezone,
  setScrollToEventId,
  onEventAdded,
}: {
  timezone?: string
  setScrollToEventId?: Dispatch<SetStateAction<string | null>>
  onEventAdded?: () => void
}) => {
  const [searchParams] = useSearchParams()
  const eventType = searchParams.get(TripSearchParams.eventType) ?? null
  const eventId = searchParams.get(TripSearchParams.eventId) ?? null
  const isRecommended =
    eventType === DrawerType.InternalExperience ||
    eventType === DrawerType.InternalHotel

  if (!eventId) return null

  return (
    <div
      className={classNames('h-[calc(100%-50px)] overflow-y-auto', {
        'px-8': isRecommended,
      })}
    >
      {eventType === DrawerType.InternalHotel && <HotelRecommendedHero />}
      <DrawerSelection
        eventId={eventId}
        eventType={eventType}
        setScrollToEventId={setScrollToEventId}
        timezone={timezone}
        onEventAdded={onEventAdded}
      />
    </div>
  )
}

const DrawerSelection = ({
  eventType,
  eventId,
  timezone,
  setScrollToEventId,
  onEventAdded,
}: {
  eventType: string
  eventId?: string
  timezone?: string
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  onEventAdded?: () => void
}) => {
  switch (eventType) {
    case DrawerType.InternalHotelBooked:
      return <Stay />
    case DrawerType.InternalHotelUnbooked:
      return <Stay />
    case DrawerType.ExternalHotel:
      return <Stay />
    case DrawerType.InternalExperienceBooked:
      return <Experience />
    case DrawerType.InternalExperienceUnbooked:
      return <Experience />
    case DrawerType.ExternalExperience:
      return <Experience />
    case DrawerType.EventCustom:
      return <Miscellaneous />
    case DrawerType.EventTransportation:
      return <Miscellaneous />
    case DrawerType.InternalExperience:
      return (
        <ExperienceDetailsContent
          setScrollToEventId={setScrollToEventId}
          onEventAdded={onEventAdded}
        />
      )
    case DrawerType.InternalHotel:
      return (
        <HotelRecommendedContent
          setScrollToEventId={setScrollToEventId}
          onEventAdded={onEventAdded}
        />
      )

    default:
      return null
  }
}
