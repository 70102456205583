import { useEffect } from 'react'
import { isAndroid } from 'react-device-detect'
import { appStoreNativeAppRoute, googlePlayNativeAppRoute } from 'src/constants'

export const NativeAppRedirect = () => {
  useEffect(() => {
    window.location.href = isAndroid
      ? googlePlayNativeAppRoute
      : appStoreNativeAppRoute
  }, [])

  return <></>
}
