import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { OrderDirection, TripStatus } from 'src/__generated__/graphql'

export const currentUserTripsGQL = gql(`
  query CurrentUserTrips($tripSearchInput: TripSearchInput!) {
    currentUser {
      id
      externalId
      trips(tripSearchInput: $tripSearchInput, first: 100) {
        edges {
          node {
            id
            currentUserRole
            endDate
            name
            startDate
            timeZone
            tripPreference {
              id
              addresses {
                id
                country
                state
              }
            }
          }
        }
      }
    }
  }
`)

export const useCurrentUserTrips = (tripStatus: TripStatus) => {
  const sortDirection =
    tripStatus === TripStatus.Past ? OrderDirection.Desc : OrderDirection.Asc

  const variables = { tripSearchInput: { status: tripStatus, sortDirection } }
  const {
    loading,
    error,
    data,
    refetch: refetchQuery,
  } = useQuery(currentUserTripsGQL, {
    fetchPolicy: 'cache-and-network',
    variables,
    notifyOnNetworkStatusChange: true,
  })

  const trips =
    data?.currentUser?.trips?.edges?.[0]?.__typename === 'TripEdge'
      ? data?.currentUser?.trips?.edges?.map(edge => edge?.node)
      : null

  const currentUser = data?.currentUser ?? null

  const refetch = () => refetchQuery(variables)

  return { trips, currentUser, loading, error, refetch }
}
