import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import type { ExperienceSeason } from 'src/__generated__/graphql'
import { getArrivalDate, getDepartureDate } from 'src/utils'
import { DayOfTheWeek } from './types'

const dateTemplate = 'MM/DD/YYYY'
const dateTimeTemplate = 'hh:mm A'

const formatDateTime = (time: string): string => {
  const today = dayjs().format(dateTemplate)
  return dayjs(`${today} ${time}`).format(dateTimeTemplate)
}

const getFormattedEndDate = (endDate, startDate) => {
  if (!endDate && !startDate) return
  if (endDate) return getDepartureDate({ date: endDate, format: dateTemplate })

  return dayjs().add(384, 'days').format(dateTemplate)
}

export const Time = ({
  endDate,
  operatingHours,
  startDate,
}: Pick<ExperienceSeason, 'endDate' | 'operatingHours' | 'startDate'>) => {
  if (isEmpty(operatingHours) || !startDate) return null

  const uniqueOperatingHours = operatingHours?.reduce((total, current) => {
    const { closesAt, opensAt } = current?.operatingHours?.[0] ?? {}

    const hoursExist = total.some(
      item => item.opensAt === opensAt && item.closesAt === closesAt
    )

    if (!hoursExist) total.push({ closesAt, opensAt })

    return total
  }, [])

  let content = null
  if (uniqueOperatingHours.length === 1) {
    const { closesAt, opensAt } = uniqueOperatingHours[0]
    const firstDay = DayOfTheWeek[operatingHours?.[0]?.dayOfWeek ?? 'MONDAY']
    const lastDay =
      DayOfTheWeek[operatingHours?.[operatingHours?.length - 1]?.dayOfWeek]

    content = (
      <p className=''>
        {firstDay} - {lastDay}: {formatDateTime(opensAt)} -{' '}
        {formatDateTime(closesAt)}
      </p>
    )
  } else if (!isEmpty(operatingHours)) {
    content = operatingHours.map(({ dayOfWeek, operatingHours }) => {
      const { closesAt, opensAt } = operatingHours[0] ?? {}
      const day = DayOfTheWeek[dayOfWeek]

      return (
        <p key={day} className=''>
          {day}: {formatDateTime(opensAt)} - {formatDateTime(closesAt)}
        </p>
      )
    })
  }

  return (
    <section className='space-y-6'>
      <h3 className='color-grey-800 type-body-1-medium'>Start Time</h3>
      <p className='color-grey-800 type-body-1'>
        Confirm time with the local provider in advance of your experience.
      </p>
      <h4 className='color-grey-800 type-body-1-medium'>Opening hours</h4>
      <p className='color-grey-800 type-body-1'>
        {startDate &&
          getArrivalDate({
            date: startDate,
            format: dateTemplate,
          })}{' '}
        - {getFormattedEndDate(startDate, endDate)}
      </p>
      {content && <p className='color-grey-800 type-body-1'>{content}</p>}
    </section>
  )
}
