import type { UnderlineTabProps } from '@travelpass/design-system'
import { Divider, UnderlineTab } from '@travelpass/design-system'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

export const BookingDetailsNav = ({ activeIndex, tabs }: UnderlineTabProps) => (
  <div className={bookingDetailsNavWrapperCss} data-testid='BookingDetailsNav'>
    <div className={bookingDetailsNavCss}>
      <ScrollMenu scrollContainerClassName='booking-details-nav-buttons'>
        <UnderlineTab
          activeIndex={activeIndex}
          tabs={tabs}
          willScroll={false}
        />
      </ScrollMenu>
    </div>
    <Divider className='-mt-3px' />
  </div>
)

const bookingDetailsNavCss =
  'mx--6 all-[.booking-details-nav-buttons]:px-6 all-[.booking-details-nav-buttons]:py-0.5 all-[.booking-details-nav-buttons]:md:px-0 md:mx-0'

const bookingDetailsNavWrapperCss = 'bg-white hide-on-print sticky top-0 z-1'
