import { useMemo } from 'react'
import { Geocoder } from 'src/common/components'
import { initialGeocoder } from 'src/constants/user'
import {
  useController,
  type BookingQuestionAnswerName,
  useFormContext,
  rules,
} from './useExperienceBookingForm'
import { useGetProductLocationBiasedGeocoderConfig } from './useGetProductLocationBiasedGeocoderConfig'

interface LocationRefOrFreeTextProps {
  defaultValue?: string
  label?: string
  placeholder?: string
  required?: boolean
  field: BookingQuestionAnswerName
}

export const LocationRefOrFreeText = ({
  field: fieldName,
  defaultValue = '',
  label = 'Select a location',
  required,
  placeholder,
}: LocationRefOrFreeTextProps) => {
  const locationBiasedConfig = useGetProductLocationBiasedGeocoderConfig()
  const name = `${fieldName}.answer` as const

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    rules: {
      required,
    },
  })

  const { setValue, resetField } = useFormContext()
  const { onChange, onBlur, value } = field

  const geocoder = useMemo(() => {
    // convert to geocoder format
    const initialValue = structuredClone(initialGeocoder)
    initialValue.placeName = value
    return initialValue
  }, [value])

  const handleResultFreetext = ({ placeName }) => {
    if (placeName) {
      onChange(placeName)
      setValue(`${fieldName}.unit`, 'FREETEXT')
    } else {
      handleResultClear()
    }
  }

  const handleResultClear = () => {
    onChange('')
    resetField(`${fieldName}.unit`)
  }

  return (
    <Geocoder
      config={locationBiasedConfig}
      errorText={error ? rules.required : ''}
      focusOnInput={false}
      geocoder={geocoder}
      helperText={error?.message}
      id={name}
      isInvalid={!!error}
      label={label}
      name={name}
      placeholder={placeholder}
      required={true}
      onBlur={onBlur}
      onClear={handleResultClear}
      onResult={handleResultFreetext}
    />
  )
}
