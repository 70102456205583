import { initialMaxStars, initialMinStars } from 'src/constants'

type StarItem = {
  label: string
  value: string
  condition: (minStars: string) => boolean
}

export const starItems: StarItem[] = [
  {
    label: '1-2',
    value: initialMinStars,
    condition: minStars => minStars === initialMinStars || !minStars,
  },
  {
    label: '3',
    value: '3.0',
    condition: minStars => minStars === '3.0',
  },
  {
    label: '4',
    value: '4.0',
    condition: minStars => minStars === '4.0',
  },
  {
    label: '5',
    value: initialMaxStars,
    condition: minStars => minStars === initialMaxStars,
  },
]
