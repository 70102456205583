import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const REMOVE_HOTEL_FROM_COLLECTION = gql(`
    mutation RemoveHotelFromCollection($input: DeleteCollectedHotelInput!, $itemId: String){
        deleteCollectedHotel(input: $input){
            collectedHotel{
                id
                hotelId
                hotel{
                    id
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: HOTEL)
                    id
                    name
                }
            }
        }
    }
`)

export const useRemoveHotelFromCollection = () =>
  useMutation(REMOVE_HOTEL_FROM_COLLECTION)
