import client from 'braintree-web/client'
import type { Client } from 'braintree-web/client'
import { Environment, env } from 'src/utils'

export const BraintreeClientToken: string = {
  [Environment.INT]: 'sandbox_5rvybfbf_6qzptsdz65kknrqp',
  [Environment.STG]: 'production_x6pbnsk4_8kpvhkjc5gbjhvrp',
  [Environment.PROD]: 'production_w3grtx6y_8kpvhkjc5gbjhvrp',
}[env]

export const merchantAccountId: string = {
  [Environment.INT]: 'travelpassgroup',
  [Environment.STG]: 'ReservationCounterLLC_instant',
  [Environment.PROD]: 'ReservationCounterLLC_instant',
}[env]

let braintreeClient: Client | null = null

export const getBraintreeClient = async () => {
  if (!braintreeClient) {
    braintreeClient = client.create({
      authorization: BraintreeClientToken,
    }) as Client
  }

  return braintreeClient
}
