import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { getPriceWithDigits } from 'src/utils'
import { useExperienceDetailsSearchParams } from './useExperienceDetailsSearchParams'
import { useExperienceProductDetails } from './useExperienceProductDetails'

const GET_EXPERIENCE_PRICE = gql(`
  query ExperiencePrice($startDate: Date!, $endDate: Date!, $nodeId: ID!) {
    node(id: $nodeId) {
      ... on ExperienceProduct {
        id
        availabilitySchedule {
          summary(arrival: $startDate, departure: $endDate) {
            fromPriceBeforeDiscount {
              amount
            }
          }
        }
      }
    }
  }
`)

export const useGetExperiencePrice = () => {
  const { experienceId } = useParams()
  const { startDate, endDate } = useExperienceDetailsSearchParams()

  const { data, loading, error } = useQuery(GET_EXPERIENCE_PRICE, {
    variables: {
      nodeId: experienceId,
      startDate,
      endDate,
    },
    skip: !experienceId || !startDate || !endDate,
  })

  // some ExperienceProduct fields are uncachable/mergeable;
  // pricingInfo is one of them, so querying for it individually would lose ageBands data.
  const { data: fullExperienceProduct } = useExperienceProductDetails()

  const experienceProduct =
    data?.node?.__typename === 'ExperienceProduct' ? data.node : null

  const amount =
    experienceProduct?.availabilitySchedule?.summary?.fromPriceBeforeDiscount
      ?.amount &&
    getPriceWithDigits(
      experienceProduct.availabilitySchedule.summary.fromPriceBeforeDiscount
        .amount
    )

  const unit =
    fullExperienceProduct?.pricingInfo?.type === 'PER_PERSON'
      ? 'person'
      : fullExperienceProduct?.pricingInfo?.unitType?.toLowerCase()

  return {
    data: {
      amount,
      unit,
    },
    loading,
    error,
  }
}
