import { useEffect, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { isIOS } from 'react-device-detect'
import { GuideDraftStatus, ParticipantStatus } from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { getGuideDraftsForUserQueryOld } from 'src/pages/dashboard'
import { useLazyUserCompetitionStatus } from 'src/pages/dashboard/useUserCompetitionStatus'
import { getGuideDraftDetailsUrl, getGuideOwnerUrl } from 'src/utils'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../guideDraftConstants'
import { getCreateGuideDraftVariables } from '../guideDraftUtils'
import { useCreateGuideDraftMutation } from '../useCreateGuideDraftMutation'

interface GuideDraftCreateModalProps {
  redirect?: boolean
  onDismiss?(): void
  triggerButton: string
}

export const GuideDraftCreateModal = ({
  redirect,
  onDismiss,
  triggerButton,
}: GuideDraftCreateModalProps) => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const [getCompetitionStatus] = useLazyUserCompetitionStatus()
  const [createGuideDraft] = useCreateGuideDraftMutation(triggerButton)
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [isNameInvalid, setIsNameInvalid] = useState(false)
  const [name, setName] = useState('')
  const [geocoder, setGeocoder] = useState(initialGeocoder)
  const [description, setDescription] = useState('')

  useEffect(() => {
    pushDataToDataLayer('modalOpened', {
      modalType: 'published_guide',
    })
  }, [])

  const onGeocoderChange = (updatedGeocoder: GeocoderType) =>
    setGeocoder(updatedGeocoder)

  const handleCompetitionStatusChange = async () => {
    const { data: competitionData } = await getCompetitionStatus()
    const isReady =
      competitionData?.currentUser?.userProfile?.competitionInfo?.status ===
      ParticipantStatus.Ready

    if (isReady)
      pushDataToDataLayer('competition_ready', {
        user_id: competitionData.currentUser.userProfile.userId,
        status: competitionData.currentUser.userProfile.competitionInfo.status,
        first_name: competitionData.currentUser.firstName,
        last_name: competitionData.currentUser.lastName,
        profile_url: `${window.location.origin}${getGuideOwnerUrl(competitionData?.currentUser?.userProfile?.accountHandle)}`,
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
  }

  const onSubmit = async () => {
    const validatedDescription = description?.trim() ?? null
    const validatedName = name?.trim()
    const updatedIsDescriptionInvalid =
      validatedDescription?.indexOf('http') !== -1
    const updatedIsNameInvalid = !validatedName
    setIsDescriptionInvalid(updatedIsDescriptionInvalid)
    setIsNameInvalid(updatedIsNameInvalid)

    if (!updatedIsDescriptionInvalid && !updatedIsNameInvalid) {
      try {
        const newTab = isIOS && window.open('about:blank', '_blank')
        const result = await createGuideDraft({
          variables: getCreateGuideDraftVariables({
            description: validatedDescription,
            geocoder,
            name: validatedName,
          }),
          refetchQueries: [
            {
              query: getGuideDraftsForUserQueryOld,
              variables: {
                userGuideDraftsArgs: {
                  includeStatuses: [GuideDraftStatus.Active],
                },
              },
            },
          ],
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide created',
        })

        if (enableCompetition2024) handleCompetitionStatusChange()

        const { guide } = result?.data?.createListGuide ?? {}
        const guideUrl = getGuideDraftDetailsUrl({
          id: guide?.strippedId,
          name: guide?.name,
          isUserOwner: true,
        })

        if (redirect) {
          if (newTab) newTab.close()

          window.location.href = guideUrl
        } else {
          onDismiss()

          // Check if the new tab was successfully opened
          if (newTab) {
            newTab.location.href = guideUrl
          } else {
            window.open(guideUrl)
          }
        }
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
      }
    }
  }

  return (
    <Modal size='medium' title='Create Your Guide' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='w-full space-y-4 p-8'>
          <Input
            aria-label='Add a name for your Guide'
            errorText={isNameInvalid && 'Guide Name is required'}
            fullWidth={true}
            helperText={`${name.length}/${guideDraftNameMaxLength} characters`}
            isInvalid={isNameInvalid}
            label='Guide Name'
            maxLength={guideDraftNameMaxLength}
            placeholder='e.g., Best Restaurants in Seattle'
            required={true}
            onChange={event => setName(event.target.value)}
          />
          <Geocoder
            aria-label='Add a location for your guide'
            config={{
              requestOptions: {
                componentRestrictions: {
                  country: null,
                },
              },
            }}
            focusOnInput={false}
            fullWidth={true}
            geocoder={geocoder}
            label='Location'
            placeholder='e.g., Salt Lake City, Utah'
            onResult={onGeocoderChange}
          />
          <p className='type-small-text-desktop'>
            Add a brief description to let other travelers know what they can
            discover in your Guide.
          </p>
          <TextArea
            aria-label='Add a description for your Guide'
            errorText={
              isDescriptionInvalid && 'Guide Description can not have URLs'
            }
            helperText={`${description.length}/${guideDraftDescriptionMaxLength} characters`}
            maxLength={guideDraftDescriptionMaxLength}
            onInput={event => setDescription(event.currentTarget.value)}
          />
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Create Guide'
          autoFocus={true}
          size='large'
          onClick={onSubmit}
        >
          Create Guide
        </Button>
        <Button
          aria-label='Cancel guide creation'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
