import type { AvailableIcons } from '@travelpass/design-system'

interface tabItem {
  icon: AvailableIcons
  label: string
}

const tripNavTabs: Pick<tabItem, 'icon' | 'label'>[] = [
  {
    icon: 'luggage',
    label: 'Overview',
  },
  {
    icon: 'formatListBulleted',
    label: 'Itinerary',
  },
  {
    icon: 'language',
    label: 'Explore',
  },
]

export { tripNavTabs }
