import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updatePublishedEventCategoryMutation = gql(`
  mutation UpdatePublishedEventCategoryMutationInGuideDraftOld($updateCategoryInput: UpdatePublishedEventCategoryInput!) {
    updatePublishedEventCategory(updateCategoryInput: $updateCategoryInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventCategoryMutation = () =>
  useMutation(updatePublishedEventCategoryMutation)
