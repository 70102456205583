import { IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { isUrlValid } from 'src/utils'
import type { DashboardProfileFields } from '../types'

interface DashboardProfileInfoLinkFormProps {
  isRepeatedLink: (value: string) => boolean
}

export const DashboardProfileInfoLinkForm = ({
  isRepeatedLink,
}: DashboardProfileInfoLinkFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<DashboardProfileFields>()

  return (
    <div className='flex flex-row gap-2.5'>
      <div className='md:p-r-14.5 flex w-full flex-row gap-3'>
        <Input
          {...register('linkTitle', {
            maxLength: {
              value: 30,
              message: 'Title cannot be more than 30 characters',
            },
          })}
          aria-label='Add a link title'
          errorText={errors.linkTitle?.message}
          fullWidth={true}
          placeholder='Title (e.g. "My Amazon Store")'
        />
        <Input
          {...register('linkURL', {
            validate: {
              isUrlValid: (value: string) =>
                value === '' || isUrlValid(value) || 'Please enter a valid URL',
              isUrlUnique: (value: string) =>
                value === '' ||
                !isRepeatedLink(value) ||
                'This link was already added.',
            },
          })}
          aria-label='Add a link URL'
          autoCapitalize='off'
          autoCorrect='off'
          errorText={errors.linkURL?.message}
          fullWidth={true}
          placeholder='Add a link'
        />
      </div>
    </div>
  )
}
