import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updatePublishedEventCategoryMutation = gql(`
  mutation UpdatePublishedEventCategoryMutationInGuideDraft($updateCategoryInput: UpdatePublishedEventCategoryInput!) {
    updatePublishedEventCategory(updateCategoryInput: $updateCategoryInput) {
      guide {
        id
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        status
      }
    }
  }
`)

export const useUpdateGuideDraftEventCategoryMutation = () =>
  useMutation(updatePublishedEventCategoryMutation)
