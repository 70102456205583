import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const GET_EXPERIENCE_CANCEL_QUOTE_QUERY = gql(
  `query ExperienceCancellationQuote($input: GetExperienceCancelQuoteInput!) {
  getExperienceCancelQuote(input: $input) {
    experienceCancellationQuote {
      refundDetails {
        itemPrice {
          amount
          currency
        }
        refundAmount {
          amount
          currency
        }
      }
      reasons {
        code
        text
      }
    }
  }
}`
)

export const useGetExperienceCancelQuoteQuery = (bookingId: string) => {
  return useQuery(GET_EXPERIENCE_CANCEL_QUOTE_QUERY, {
    variables: {
      input: { bookingId },
    },
    skip: !bookingId,
  })
}
