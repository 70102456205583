import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { ExploreCard } from './ExploreCard'

export const TripDetailsExploreCardLoading = () => (
  <ExploreCard>
    <div className='flex flex-col gap-3'>
      <div className='h-35 flex flex-col items-center justify-center md:h-28'>
        <Skeleton variant='rounded' />
      </div>
      <div className='flex min-h-16 flex-col gap-1'>
        <div className='type-h5-mobile h-18px w-25 line-clamp-2 min-h-9'>
          <Skeleton />
        </div>
        <div className='h-16px w-35'>
          <Skeleton />
        </div>
      </div>
    </div>
  </ExploreCard>
)
