import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'

export const OverviewNoteLoading = () => (
  <div className='bg-grey-100 rounded-2 transition-all-100 w-full space-y-3 p-4 outline-none ease-linear'>
    <div className='flex flex-row items-center gap-2'>
      <div className='h-6 w-6'>
        <Skeleton variant='circular' />
      </div>
      <div className='h-4.5 w-15 lg:h-6'>
        <Skeleton />
      </div>
    </div>
    <div className='w-full space-y-2'>
      <div className='h-4.5 lg:h-5'>
        <Skeleton />
      </div>
    </div>
  </div>
)
