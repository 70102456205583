export enum ExperiencesDetailsSearchParams {
  arrival = 'arrival',
  departure = 'departure',
  eventId = 'eventId',
  guests = 'guests',
  pickupPoint = 'pickupPoint',
  token = 'token',
  tripId = 'tripId',
  travelDate = 'travelDate',
}
