import { useState } from 'react'
import { Button, Icon, useScreenQuery } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { Lodging } from 'src/__generated__/graphql'
import { HotelAmenitiesModal } from 'src/common/components'
import {
  constructAdditionalHotelAmenities,
  constructPopularHotelAmenities,
} from 'src/utils'

interface HotelAmenitiesProps {
  amenities: Lodging['amenities']
}

export const HotelAmenities = ({ amenities }: HotelAmenitiesProps) => {
  const { isMobileOrTablet } = useScreenQuery()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const constructedPopularAmenities = () =>
    constructPopularHotelAmenities(amenities)
      ?.slice(0, 9)
      ?.map(({ icon, label }, index) => (
        <p
          key={`${label}-${index}`}
          className='type-body-1 flex items-center gap-2'
        >
          <Icon name={icon} />
          <span>{label}</span>
        </p>
      ))

  const updatedAmenities = constructAdditionalHotelAmenities(amenities)

  const constructedAdditionalAmenities = updatedAmenities?.map(
    (amenity, index) => (
      <li
        key={`${amenity}-${index}`}
        className='c-forest font-400 tracking-0 text-4 line-height-5 m-0 overflow-hidden text-ellipsis whitespace-nowrap'
      >
        {amenity}
      </li>
    )
  )

  const showAmenities = !isEmpty(amenities)

  return showAmenities ? (
    <>
      {isModalOpen && (
        <HotelAmenitiesModal
          amenities={amenities}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div className='grid-items-center lg:py-15 bg-warm-grey flex flex-col gap-6 py-10 lg:gap-12'>
        <h2 className='type-h3'>Amenities</h2>
        <div className='flex flex-col gap-12'>
          <div className='flex flex-col gap-4'>
            <h6 className='type-h5'>Popular</h6>
            <div className='grid grid-flow-dense gap-x-1.5 gap-y-2 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-3 lg:p-3'>
              {constructedPopularAmenities()}
            </div>
          </div>
          {!isMobileOrTablet && (
            <div className='flex flex-col gap-4'>
              <h6 className='type-h5'>Additional</h6>
              <ul className='m-0 grid list-inside grid-flow-dense grid-cols-1 gap-x-10 gap-y-3 p-3 lg:grid-cols-3'>
                {constructedAdditionalAmenities}
              </ul>
            </div>
          )}
        </div>
        {isMobileOrTablet && (
          <Button
            label='View All'
            variant='outlined'
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
    </>
  ) : null
}
