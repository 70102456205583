import { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  DrawerActions,
  DrawerNew,
  DrawerScrollContents,
} from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { Bucket, FilterBy } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelResultsFiltersAmenities } from './HotelResultsFiltersAmenities'
import { HotelResultsFiltersBrands } from './HotelResultsFiltersBrands'
import { HotelResultsFiltersPrice } from './HotelResultsFiltersPrice'
import { HotelResultsFiltersReviews } from './HotelResultsFiltersReviews'
import { HotelResultsFiltersStars } from './HotelResultsFiltersStars'
import { HotelResultsFiltersSectionWithTitle } from './common'
import { SearchPreferencesToggle } from './common/SearchPreferencesToggle'
import { convertFromBucketsToBrandStrings } from './common/utils'
import {
  getHotelResultsFiltersFromSearchParams,
  setHotelResultsFiltersSearchParams,
} from '../../hotelResultsUtils'

interface HotelResultsFiltersDrawerProps {
  hotelResultsBrandData?: Bucket[]
  isOpen?: boolean
  onDismiss?(): void
  handleShowQuiz?(newVal: boolean): void
  showQuiz?: boolean
}

export const HotelResultsFiltersDrawer = ({
  hotelResultsBrandData = [],
  isOpen = false,
  onDismiss = () => {},
  handleShowQuiz = _ => {},
}: HotelResultsFiltersDrawerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<FilterBy>({})
  const [allBrandsList, setAllBrandsList] = useState<string[]>([])
  const [applyFilters, setApplyFilters] = useState(false)
  const enableApplySearchPrefFilters = useFlag('enableApplySearchPrefFilters')

  useEffect(() => {
    setFilters(getHotelResultsFiltersFromSearchParams(searchParams))
  }, [searchParams])

  // UseEffect to figure out the "all brands" list
  useEffect(() => {
    const initialBrands = hotelResultsBrandData
      ?.slice()
      ?.sort((brand, nextBrand) => brand.value.localeCompare(nextBrand.value))
      ?.filter(brand => !filters?.brands?.includes(brand?.value)) // Give me all brands not currently selected

    setAllBrandsList(convertFromBucketsToBrandStrings(initialBrands))
  }, [filters.brands, hotelResultsBrandData])

  const onFiltersChange = (filterSectionToUpdate: FilterBy) =>
    setFilters({ ...filterSectionToUpdate })

  const onClear = () => {
    setHotelResultsFiltersSearchParams({
      filters: {},
      onChange: (updatedSearchParams: URLSearchParams) =>
        setSearchParams(updatedSearchParams),
      searchParams,
    })
    onFiltersChange({})
    setApplyFilters(false)
    onDismiss()
  }

  const onSubmit = () => {
    setHotelResultsFiltersSearchParams({
      filters,
      onChange: (updatedSearchParams: URLSearchParams) =>
        setSearchParams(updatedSearchParams),
      searchParams,
    })
    onDismiss()
  }

  return (
    <>
      <DrawerNew isOpen={isOpen} placement='right' onDismiss={onDismiss}>
        <DrawerScrollContents title='Filters'>
          <section className='space-y-6 lg:space-y-8'>
            {enableApplySearchPrefFilters && (
              <SearchPreferencesToggle
                applyFilters={isApplied => setApplyFilters(isApplied)}
                filters={filters}
                handleShowQuiz={handleShowQuiz}
                hotelResultsBrandData={hotelResultsBrandData}
                isPreferencesApplied={applyFilters}
                onAllBrandsChange={brands => setAllBrandsList(brands)}
                onFiltersChange={onFiltersChange}
              />
            )}
            <HotelResultsFiltersSectionWithTitle title='Hotel Name or Brand'>
              <HotelResultsFiltersBrands
                allBrandsList={allBrandsList}
                filters={filters}
                selectedBrands={filters?.brands || []}
                onChange={onFiltersChange}
              />
            </HotelResultsFiltersSectionWithTitle>
            <Divider />
            <HotelResultsFiltersSectionWithTitle title='Price Range'>
              <HotelResultsFiltersPrice
                filters={filters}
                onChange={onFiltersChange}
              />
            </HotelResultsFiltersSectionWithTitle>
            <Divider />
            <HotelResultsFiltersSectionWithTitle title='Guest Rating'>
              <HotelResultsFiltersReviews
                filters={filters}
                onChange={onFiltersChange}
              />
            </HotelResultsFiltersSectionWithTitle>
            <Divider />
            <HotelResultsFiltersSectionWithTitle title='Hotel Star Rating'>
              <HotelResultsFiltersStars
                filters={filters}
                onChange={onFiltersChange}
              />
            </HotelResultsFiltersSectionWithTitle>
            <Divider />
            <HotelResultsFiltersSectionWithTitle title='Amenities (select all that apply)'>
              <HotelResultsFiltersAmenities
                filters={filters}
                onChange={onFiltersChange}
              />
            </HotelResultsFiltersSectionWithTitle>
          </section>
        </DrawerScrollContents>
        <DrawerActions>
          <Button label='Clear' variant='outlined' onClick={onClear} />
          <Button label='Apply' type='button' onClick={onSubmit} />
        </DrawerActions>
      </DrawerNew>
    </>
  )
}
