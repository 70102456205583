import { TextArea } from '@travelpass/design-system'
import type { Note } from 'src/__generated__/graphql'

interface OverviewNoteFormTextProps {
  note: Note
  onNoteChange(updatedNote: Note): void
}

export const OverviewNoteFormText = ({
  note,
  onNoteChange,
}: OverviewNoteFormTextProps) => {
  const { body } = note ?? {}

  return (
    <TextArea
      aria-label='Add a note'
      placeholder='Start adding notes here'
      rows={20}
      value={body ?? ''}
      onInput={event =>
        onNoteChange({ ...note, body: event.currentTarget.value })
      }
    />
  )
}
