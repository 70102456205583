import { useRef, useState } from 'react'
import { Icon, Input } from '@travelpass/design-system'
import classNames from 'classnames'
import { guideDraftImageAcceptedTypes } from '../../guideDraftConstants'
import type { UseGuideDraftImagesEdit } from '../../useGuideDraftImagesEdit'

interface GuideDraftImagesEditUploadProps {
  onUpload: UseGuideDraftImagesEdit['onUpload']
}

export const GuideDraftImagesEditUpload = ({
  onUpload,
}: GuideDraftImagesEditUploadProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isDragging, setIsDragging] = useState(false)

  return (
    <div>
      <div className='hidden'>
        <Input
          accept={guideDraftImageAcceptedTypes}
          ref={ref}
          type='file'
          onChange={events => onUpload(events?.target?.files)}
        />
      </div>
      <button
        className={classNames(
          'b-2 b-solid transition-border-color-100 rounded-2 focus:b-canyon over:b-canyon h-30 w-35 w-full cursor-pointer select-none bg-white p-1 text-center outline-none ease-linear lg:h-40 lg:w-full lg:p-2',
          {
            'b-canyon': isDragging,
            'b-grey-200': !isDragging,
          }
        )}
        onClick={() => ref?.current?.click()}
        onDragEnter={event => {
          event.stopPropagation()
          event.preventDefault()
          setIsDragging(true)
        }}
        onDragLeave={() => {
          setIsDragging(false)
        }}
        onDragOver={event => {
          event.stopPropagation()
          event.preventDefault()
        }}
        onDrop={event => {
          event.stopPropagation()
          event.preventDefault()
          onUpload(event?.dataTransfer?.files)
          setIsDragging(false)
        }}
      >
        <div className='pointer-events-none space-y-1'>
          <div className='c-canyon op-50'>
            <Icon name='upload' size='large' />
          </div>
          <p className='c-grey-700 type-body-1'>
            Choose from below or upload file
          </p>
          <p className='c-grey-500 type-body-2'>jpg, png (5mb max)</p>
        </div>
      </button>
    </div>
  )
}
