import type { BookingEdge } from 'src/__generated__/graphql'
import { BookingStatus } from 'src/__generated__/graphql'
import { hotelsSegment } from 'src/constants'
import { getDateFromUTC } from 'src/utils'
import { useHotelImage } from './useHotelImage'
import { BookingCard, BookingCardLoading } from '../common/components'

interface HotelBookingProps {
  booking: BookingEdge
  className?: string
  onClick?: () => void
}

export const HotelBooking = ({
  booking,
  className,
  onClick,
}: HotelBookingProps) => {
  const { id, arrival, departure, hotelId, hotelName, status, bookingDetails } =
    booking?.node ?? {}
  const { adults, childAges } = bookingDetails?.occupancies?.[0] ?? {}
  const cancelled = status === BookingStatus.Cancelled
  const startDate = arrival && getDateFromUTC(arrival)
  const endDate = departure && getDateFromUTC(departure)

  const route = `/${hotelsSegment}/confirmation/${id}`

  const { imageLink, loading } = useHotelImage(hotelId)

  if (loading) return <BookingCardLoading className='min-w-74 w-full' />

  return (
    <BookingCard
      key={id}
      bookingCardData={{
        adults,
        cancelled,
        endDate,
        id,
        image: imageLink,
        kids: childAges?.length,
        name: hotelName,
        roomCount: 1,
        route,
        startDate,
      }}
      className={className}
      onBookingCardClick={onClick}
    />
  )
}
