import { useEffect, useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import type { MappedImage } from 'src/__generated__/graphql'

interface HotelRoomDetailsImageProps {
  image: MappedImage
  name: string
}

export const HotelRoomDetailsImage = ({
  image,
  name,
}: HotelRoomDetailsImageProps) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [image])

  return (
    <div className='rounded-3 of-hidden h-75 w-full content-center'>
      {hasError && (
        <div className='m-a w-80'>
          <EmptyState name='noImages' />
        </div>
      )}
      {!hasError && (
        <img
          alt={image?.caption || name}
          className='h-full w-full object-cover'
          loading='lazy'
          src={image?.href}
          onError={() => setHasError(true)}
        />
      )}
    </div>
  )
}
