import type { KeyboardEvent, FormEvent } from 'react'
import { useState } from 'react'
import {
  Button,
  Input,
  KeyCode,
  Modal,
  ModalActions,
} from '@travelpass/design-system'
import { NoteType, type Note } from 'src/__generated__/graphql'
import { TripDetailsDrawerFooter } from 'src/pages/trips/components'
import { OverviewNoteFormLinks } from './OverviewNoteFormLinks'
import { OverviewNoteFormList } from './OverviewNoteFormList'
import { OverviewNoteFormNav } from './OverviewNoteFormNav'
import { OverviewNoteFormText } from './OverviewNoteFormText'
import type { OverviewNoteFormSubmitArguments } from './types'

interface OverviewNoteFormProps {
  isLoading: boolean
  note: Note
  onClose(): void
  onDelete(): void
  onNoteChange(updatedNote: Note): void
  onSubmit({
    showSnackbar,
    updatedNote,
    willClose,
  }: OverviewNoteFormSubmitArguments): void
}

export const OverviewNoteForm = ({
  isLoading,
  note,
  onClose,
  onDelete,
  onNoteChange,
  onSubmit,
}: OverviewNoteFormProps) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { id, title, type } = note ?? {}

  const onFormSubmit = ({
    event,
    showSnackbar,
    updatedNote,
    willClose,
  }: OverviewNoteFormSubmitArguments & {
    event?: FormEvent<HTMLFormElement>
  }) => {
    event?.preventDefault()
    const updatedIsInvalid = !title
    setIsInvalid(updatedIsInvalid)

    if (!updatedIsInvalid)
      onSubmit({
        showSnackbar,
        updatedNote,
        willClose,
      })
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event ?? {}

    if (key === KeyCode.ENTER) {
      event.preventDefault()
      onFormSubmit({})
    }
  }

  return (
    <>
      <form
        className='b-grey-300 p-b-6 p-x-6 md:p-t-2 md:p-x-8 h-[calc(100%-138px)] overflow-auto'
        onSubmit={event => onFormSubmit({ event })}
      >
        <div className='space-y-6'>
          <Input
            errorText={isInvalid && 'Title is required'}
            fullWidth={true}
            helperText='Max 255 characters'
            isInvalid={isInvalid}
            label='Title'
            maxLength={255}
            placeholder='New Note'
            value={title ?? ''}
            onChange={event =>
              onNoteChange({
                ...note,
                title: event.currentTarget.value,
              })
            }
            onKeyDown={onKeyDown}
          />
          <OverviewNoteFormNav note={note} onNoteChange={onNoteChange} />
          {type === NoteType.FreeText && (
            <OverviewNoteFormText note={note} onNoteChange={onNoteChange} />
          )}
          {type === NoteType.Checklist && (
            <OverviewNoteFormList note={note} onNoteChange={onNoteChange} />
          )}
          {type === NoteType.Link && (
            <OverviewNoteFormLinks
              isLoading={isLoading}
              note={note}
              onSubmit={onFormSubmit}
            />
          )}
        </div>
        <TripDetailsDrawerFooter>
          {id && (
            <Button
              fullWidth
              aria-label='Delete note'
              startIcon='deleteOutline'
              variant='outlined'
              onClick={() => setShowModal(true)}
            >
              Delete
            </Button>
          )}
          {!id && (
            <Button
              fullWidth
              aria-label='Cancel note changes'
              variant='outlined'
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
          <Button fullWidth aria-label='Save note changes' type='submit'>
            Save
          </Button>
        </TripDetailsDrawerFooter>
      </form>
      {showModal && (
        <Modal
          size='medium'
          title='Are you sure you want to delete this note?'
          onDismiss={() => setShowModal(false)}
        >
          <ModalActions>
            <Button
              aria-label='Delete note'
              onClick={() => {
                onDelete()
                setShowModal(false)
              }}
            >
              Delete
            </Button>
            <Button
              aria-label='Cancel delete note'
              variant='outlined'
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
