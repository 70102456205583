enum DestinationSearchParam {
  arrival = 'arrival',
  customerReviewScore = 'customerReviewScore',
  departure = 'departure',
  latitude = 'latitude',
  location = 'location',
  longitude = 'longitude',
  placeId = 'placeId',
  priceRange = 'priceRange',
  starRating = 'starRating',
}

type DestinationType = {
  address: {
    city: string
    latitude: string
    longitude: string
    state: string
    stateCode: string
  }
  descriptions: string[]
  images: string[]
  bestAreas?: {
    area: string
    summary: string
    image: string
    imageAlt: string
    link?: string
  }[]
  bestAttractions?: {
    attraction: string
    summary: string
    image: string
    imageAlt: string
  }[]
  explore?: {
    activity: string
    summary: string[]
    links?: {
      name: string
      href: string
    }[]
  }[]
  timeToVisit?: {
    weather: {
      season: string
      climate: string
    }[]
    tourism: {
      highSeason: string
      lowSeason: string
    }
    priceTrends: string
  }[]
  faqs?: {
    question: string
    answer: string[]
  }[]
  metaDescription: string
  order: number
} | null

const destinationPath =
  'https://static.travelpass.com/assets/destinations-page/'

const destinations = Object.freeze({
  caLosAngeles: {
    address: {
      city: 'Los Angeles',
      latitude: '34.0522342',
      longitude: '-118.2436849',
      state: 'California',
      stateCode: 'CA',
    },
    descriptions: [
      'Experience everything La La Land has to offer, from the world-class shopping on Rodeo Drive to the famous Griffith Observatory! Take a step “behind"the scenes" and book a Warner Bros. Studio Tour for a taste of Hollywood, or explore Zuma Beach, the perfect place for body surfers and beach volleyball.',
    ],
    images: [
      `${destinationPath}los-angeles-california/book-a-hotel.webp`,
      `${destinationPath}los-angeles-california/where-to-stay.webp`,
    ],
    bestAreas: [],
    bestAttractions: [],
    explore: [],
    timeToVisit: [],
    faqs: [],
    metaDescription:
      'Unlock the glamour of Los Angeles! Book a hotel in Los Angeles in the heart of Hollywood glitz, sun-soaked beaches, and cultural landmarks. Secure premier deals near the Walk of Fame, Rodeo Drive, and vibrant art scenes. Experience the diverse allure of Los Angeles, California – Book your stay today!',
    order: 4,
  },

  caSanDiego: {
    address: {
      city: 'San Diego',
      latitude: '32.715738',
      longitude: '-117.1610838',
      state: 'California',
      stateCode: 'CA',
    },
    descriptions: [
      "Calling all adventure seekers and nature lovers: San Diego is your perfect getaway! With its year-round sunshine, world-renowned zoo, and endless outdoor activities, this city offers plenty to keep you busy. From surfing and hiking to whale watching and kayaking, you'll find yourself wishing you never had to leave.",
    ],
    images: [
      `${destinationPath}san-diego-california/book-a-hotel.webp`,
      `${destinationPath}san-diego-california/where-to-stay.webp`,
    ],
    metaDescription:
      "Experience San Diego's coastal charm! Book a hotel in San Diego and immerse yourself in California's sunlit beaches, historic attractions, and vibrant downtown. Secure top deals on premier accommodations near the renowned San Diego Zoo, lively Gaslamp Quarter, and serene bay views. Explore America's Finest City – Reserve now!",
    order: 15,
  },
  caSanFrancisco: {
    address: {
      city: 'San Francisco',
      latitude: '37.7749295',
      longitude: '-122.4194155',
      state: 'California',
      stateCode: 'CA',
    },
    descriptions: [
      "Channel your inner bohemian in this famously hilly city! Featuring stunning views of the Bay, San Francisco offers a unique blend of art, culture, and natural beauty, with iconic landmarks like the Golden Gate Bridge, Fisherman's Wharf, and Alcatraz Island. Love a good movie moment? Check out one of the local tours offered on the filming locations of the famous Princess Diaries!",
    ],
    images: [
      `${destinationPath}san-francisco-california/book-a-hotel.webp`,
      `${destinationPath}san-francisco-california/where-to-stay.webp`,
    ],
    metaDescription:
      'Embrace the beauty of San Francisco! Book a hotel in San Francisco amidst iconic Golden Gate views, historic cable cars, and bustling piers. Secure top deals near world-class dining, tech hubs, and vibrant neighborhood. Experience the unique charm of the City by the Bay!',
    order: 17,
  },

  coDenver: {
    address: {
      city: 'Denver',
      latitude: '39.7392358',
      longitude: '-104.990251',
      state: 'Colorado',
      stateCode: 'CO',
    },
    descriptions: [
      'A gateway to the Rocky Mountains and a hub of outdoor adventure, Denver offers plenty of hiking, skiing, and mountain biking opportunities, as well as a thriving arts and culture scene, with plenty of museums, galleries, and music venues to explore. Hidden gem for history buffs: the Molly Brown House Museum sheds new light on the incredible heroine of the fated ship, making it a must-see!',
    ],
    images: [
      `${destinationPath}denver-colorado/book-a-hotel.webp`,
      `${destinationPath}denver-colorado/where-to-stay.webp`,
    ],
    metaDescription:
      "Experience Denver's Mile-High magic! Secure your hotel amidst the Rockies' backdrop, bustling breweries, and vibrant arts districts. Snag top deals on accommodations near outdoor adventures and urban delights. Dive into Denver's unique blend of nature and culture – Book now!",
    order: 18,
  },

  dcWashingtonDc: {
    address: {
      city: 'Washington DC',
      latitude: '38.9071923',
      longitude: '-77.0368707',
      state: 'DC',
      stateCode: 'DC',
    },
    descriptions: [
      'Washington D.C. offers a wealth of cultural and historic attractions, including the National Mall and the Smithsonian museums, as well as plenty of shopping, dining, and nightlife options. Check out our favorite hidden gem, The Mansion on O Street, for an eclectic high tea experience or to explore around to find its 70 “s"cret doors", including one leading to an impressive wine cellar! Want extra time to enjoy your visit?',
    ],
    images: [
      `${destinationPath}washington-dc-dc/book-a-hotel.webp`,
      `${destinationPath}washington-dc-dc/where-to-stay.webp`,
    ],
    metaDescription:
      "Step into the heart of American history in Washington DC! Book a hotel in Washington DC near iconic monuments, world-class museums, and vibrant neighborhoods. Secure unbeatable deals on accommodations in the epicenter of politics, culture, and heritage. Explore the capital's timeless allure.",
    order: 13,
  },

  flFortLauderdale: {
    address: {
      city: 'Fort Lauderdale',
      latitude: '26.1224386',
      longitude: '-80.13731740000001',
      state: 'Florida',
      stateCode: 'FL',
    },
    descriptions: [
      "Eager to spend time in the great outdoors, but want to avoid the crazy hikes? With miles of golden sand and crystal-clear water, Fort Lauderdale offers the perfect laid-back vibe for our-not-so-adventurous adventurers. Treat yourself with a mimosa while enjoying the beach, or escape the mundane and explore Butterfly World, the world's largest butterfly park.",
    ],
    images: [
      `${destinationPath}fort-lauderdale-florida/book-a-hotel.webp`,
      `${destinationPath}fort-lauderdale-florida/where-to-stay.webp`,
    ],
    metaDescription:
      "Savor Fort Lauderdale's sunlit elegance! Book your hotel stay amidst Florida's glistening beaches, luxury yachts, and vibrant boulevards. Secure exclusive deals near top attractions, waterfront dining, and tropical escapes. Dive into Fort Lauderdale's coastal allure – Reserve today!",
    order: 12,
  },

  flMiami: {
    address: {
      city: 'Miami',
      latitude: '25.7616798',
      longitude: '-80.1917902',
      state: 'Florida',
      stateCode: 'FL',
    },
    descriptions: [
      'A vibrant, cosmopolitan city known for its sunny beaches, trendy nightlife, and diverse cultural scene, Miami offers a unique blend of Latin American and Caribbean flavors. With plenty of art, music, and cuisine to explore, this is a city you will love to get lost in!',
    ],
    images: [
      `${destinationPath}miami-florida/book-a-hotel.webp`,
      `${destinationPath}miami-florida/where-to-stay.webp`,
    ],
    metaDescription:
      "Discover Miami's sun-soaked beaches and vibrant nightlife! Book your hotel in the heart of Florida's tropical oasis. Secure top deals on luxury accommodations near South Beach, world-class dining, and iconic Art Deco architecture. Dive into Miami's allure – Book a hotel in Miami today!",
    order: 10,
  },

  flOrlando: {
    address: {
      city: 'Orlando',
      latitude: '28.5383832',
      longitude: '-81.3789269',
      state: 'Florida',
      stateCode: 'FL',
    },
    descriptions: [
      "Home to some of the world's most famous theme parks, including Walt Disney World and Universal Studios, Orlando is a popular family-friendly destination with endless entertainment options. Whether you're looking for a serene retreat by the pool, or an enchanting adventure in Hogsmeade, this tropical paradise has something for everyone!",
    ],
    images: [
      `${destinationPath}orlando-florida/book-a-hotel.webp`,
      `${destinationPath}orlando-florida/where-to-stay.webp`,
    ],
    metaDescription:
      "Dive into Orlando's magic! Book a hotel in Orlando and discover a world of theme park wonders, from enchanted castles to wild adventures. Secure the best deals near top attractions, shopping hubs, and gourmet dining. Experience the thrill and family fun of Orlando!",
    order: 2,
  },

  gaAtlanta: {
    address: {
      city: 'Atlanta',
      latitude: '33.748752',
      longitude: '-84.38768449999999',
      state: 'Georgia',
      stateCode: 'GA',
    },
    descriptions: [
      'Atlanta beckons with its vibrant mix of Southern charm, rich history, and bustling cultural scene. Explore iconic attractions like the Georgia Aquarium and the Martin Luther King Jr. National Historic Site, savor the culinary delights, and immerse yourself in the lively arts and parks. Atlanta offers a unique, dynamic experience for every visitor, blending tradition and modernity in the heart of the South.',
    ],
    images: [
      `${destinationPath}atlanta-georgia/book-a-hotel.webp`,
      `${destinationPath}atlanta-georgia/where-to-stay.webp`,
    ],
    metaDescription:
      'Experience the best of Atlanta, Georgia! Dive into the heart of the South with attractions like the Georgia Aquarium, vibrant arts scene, and rich history. Perfect for families, foodies, and adventure seekers. Start your unforgettable journey to Atlanta now!',
    order: 24,
  },

  ilChicago: {
    address: {
      city: 'Chicago',
      latitude: '41.8781136',
      longitude: '-87.6297982',
      state: 'Illinois',
      stateCode: 'IL',
    },
    descriptions: [
      'The "Windy City" is a perfect place for history lovers to escape without breaking the bank. With its rich history and culture, Chicago offers something for everyone, from world-class museums and art galleries to top-rated shopping and dining. Be sure to explore the city\'s iconic architecture via a river boat tour, and don\'t miss the incredible live entertainment to be found while walking the Magnificent Mile!',
    ],
    images: [
      `${destinationPath}chicago-illinois/book-a-hotel.webp`,
      `${destinationPath}chicago-illinois/where-to-stay.webp`,
    ],
    metaDescription:
      "Experience the Windy City's magic! Book your Chicago hotel stay and be steps away from iconic skyscrapers, the Magnificent Mile, and deep-dish delights. Secure exclusive deals on accommodations in the heart of the city's culture, history, and lakeside beauty. Dive into Chicago!",
    order: 6,
  },

  laNewOrleans: {
    address: {
      city: 'New Orleans',
      latitude: '29.95106579999999',
      longitude: '-90.0715323',
      state: 'Louisiana',
      stateCode: 'LA',
    },
    descriptions: [
      "Known for its vibrant music, food, and culture, New Orleans is a unique destination with a rich history and diverse cultural heritage, offering everything from jazz clubs and street performers to classic Cajun and Creole cuisine. Don't sleep on this city during the fall – some of the most haunted places in the country call New Orleans home!",
    ],
    images: [
      `${destinationPath}new-orleans-louisiana/book-a-hotel.webp`,
      `${destinationPath}new-orleans-louisiana/where-to-stay.webp`,
    ],
    metaDescription:
      "Unearth the soul of the Crescent City! Book a hotel in New Orleans and be captivated by jazz rhythms, historic French Quarter charm, and Creole cuisine. Grab top deals on stays near Bourbon Street festivities and the mystique of the Mississippi. Immerse in New Orleans' vibrant culture. Visit New Orleans today!",
    order: 11,
  },

  maBoston: {
    address: {
      city: 'Boston',
      latitude: '42.3600825',
      longitude: '-71.0588801',
      state: 'Massachusetts',
      stateCode: 'MA',
    },
    descriptions: [
      'A historic city with a rich colonial past, Boston offers a unique blend of old-world charm and modern innovation, with plenty of museums, landmarks, and cultural events to explore, from the Freedom Trail to the Boston Marathon. The bar scene is lively during the summer months, and some of the best ice cream in the country can be found on these cobblestone streets!',
    ],
    images: [
      `${destinationPath}boston-massachusetts/book-a-hotel.webp`,
      `${destinationPath}boston-massachusetts/where-to-stay.webp`,
    ],
    metaDescription:
      "Step into Boston's rich history! Reserve your hotel in the heart of this iconic city, moments away from the Freedom Trail, historic harbors, and legendary seafood. Snag exclusive deals on accommodations amidst cobblestone streets and modern cityscapes. Discover Boston's charm!",
    order: 16,
  },

  nyNewYorkCity: {
    address: {
      city: 'New York City',
      latitude: '40.7127753',
      longitude: '-74.0059728',
      state: 'New York',
      stateCode: 'NY',
    },
    descriptions: [
      'New York City is the city that never sleeps, and for good reason. From Broadway shows to once-in-a-lifetime culinary experiences, The Big Apple has something new for even the most experienced of travelers. Wander the streets of the East Village for some excellent local bookstores and art shops, or head to the Lexington Candy Shop to travel back in time and order a milkshake at the old-fashioned soda counter.',
    ],
    images: [
      `${destinationPath}new-york-city-new-york/book-a-hotel.webp`,
      `${destinationPath}new-york-city-new-york/where-to-stay.webp`,
    ],
    metaDescription:
      "Immerse in New York City's electric pulse! Book your hotel amidst iconic skyscrapers, Broadway lights, and Central Park serenity. Secure exclusive deals on accommodations in the world's crossroads of culture, fashion, and finance. Experience the Big Apple's boundless energy – Reserve now!",
    order: 9,
  },

  nvLasVegas: {
    address: {
      city: 'Las Vegas',
      latitude: '36.1147065',
      longitude: '-115.1728484',
      state: 'Nevada',
      stateCode: 'NV',
    },
    descriptions: [
      'For the traveler who loves non-stop thrills, Las Vegas is a must. This "Entertainment Capital of the World" offers you endless opportunities for exciting entertainment, from world-class casinos and shows to gourmet dining and unique exhibits. For those wanting to experience the bizarre and new, don\'t miss the chance to immerse yourself in Meow Wolf\'s interactive Omega Mart!',
    ],
    images: [
      `${destinationPath}las-vegas-nevada/book-a-hotel.webp`,
      `${destinationPath}las-vegas-nevada/where-to-stay.webp`,
    ],
    metaDescription:
      "Book a hotel in Las Vegas! Dive into the heart of Nevada's entertainment capital, steps away from world-class casinos, shows, and dining. Grab exclusive deals on top-rated accommodations and experience the glitz and glamour of the Las Vegas Strip. Book today!",
    order: 0,
  },

  paPhiladelphia: {
    address: {
      city: 'Philadephia',
      latitude: '39.9525839',
      longitude: '-75.1652215',
      state: 'Pennsylvania',
      stateCode: 'PA',
    },
    descriptions: [
      "Discover America's birthplace in Philadelphia, a city with a deep sense of history and pride. Walk in the footsteps of the founding fathers and visit Independence Hall and the Liberty Bell, or take a stroll through the charming streets of Old City. With a burgeoning food scene, top-notch museums, and plenty of parks and green spaces, Philadelphia is a city that combines the best of the past and present. Be sure to check out the Reading Terminal Market to find delicious Amish goods!",
    ],
    images: [
      `${destinationPath}philadephia-pennsylvania/book-a-hotel.webp`,
      `${destinationPath}philadephia-pennsylvania/where-to-stay.webp`,
    ],
    metaDescription:
      "Discover Philadelphia's rich tapestry! Secure your hotel stay in the cradle of America's history, moments from the Liberty Bell, vibrant markets, and world-class museums. Snag exclusive deals on accommodations in a city where colonial charm meets modern dynamism. Experience Philly's heart – Book now!",
    order: 19,
  },

  scMyrtleBeach: {
    address: {
      city: 'Myrtle Beach',
      latitude: '33.6890603',
      longitude: '-78.8866943',
      state: 'South Carolina',
      stateCode: 'SC',
    },
    descriptions: [
      'With over 60 miles of stunning coastline, Myrtle Beach offers the perfect sandy getaway for those looking for a glimpse of the slower life. Think local coffee shops and reading on the beach, or delicious hand-rolled ice cream and family dinners after a full day swimming under the sun. What more could you want?',
    ],
    images: [
      `${destinationPath}myrtle-beach-south-carolina/book-a-hotel.webp`,
      `${destinationPath}myrtle-beach-south-carolina/where-to-stay.webp`,
    ],
    metaDescription:
      "Book a hotel in Myrtle Beach! Explore South Carolina's coastal paradise with sun-kissed beaches and exciting boardwalks. Find unbeatable hotel deals close to top attractions, shopping, and dining. Dive into the waves and relax in style – Book your Myrtle Beach experience now!",
    order: 5,
  },

  tnChattanooga: {
    address: {
      city: 'Chattanooga',
      latitude: '35.0457984',
      longitude: '-85.3093995',
      state: 'Tennessee',
      stateCode: 'TN',
    },
    descriptions: [
      'Chattanooga stands as a prime example of a city that blends natural beauty with cultural richness. Nestled along the Tennessee River and framed by the rugged Appalachians, Chattanooga boasts a variety of outdoor activities. The city’s revitalized downtown district is teeming with vibrant arts, dining, and entertainment scenes, epitomized by the iconic Tennessee Aquarium and the historic Tivoli Theater.',
    ],
    images: [
      `${destinationPath}chattanooga-tennessee/book-a-hotel.webp`,
      `${destinationPath}chattanooga-tennessee/where-to-stay.webp`,
    ],
    metaDescription:
      'Book your stay in Chattanooga, Tennessee! Experience the perfect blend of scenic mountain views and vibrant city life. Find top-rated hotels near iconic attractions like Ruby Falls and the Tennessee Aquarium. Secure the best deals and immerse yourself in Chattannooga’s charm today!',
    order: 21,
  },

  tnKnoxville: {
    address: {
      city: 'Knoxville',
      latitude: '35.9606384',
      longitude: '-83.9207392',
      state: 'Tennessee',
      stateCode: 'TN',
    },
    descriptions: [
      "Knoxville, TN, uniquely marries its rich historical heritage with a vibrant cultural scene. As the gateway to the Great Smoky Mountains, it offers uparalleled access to outdoor adventures, from hiking to river activities. Downtown, the city's soul is captured in its preserved 19th century buildings, lively Market Square, and a blossoming arts district. Knoxville's blend of natural beauty, history, and modern creativity makes it a distinctive destination in the American South.",
    ],
    images: [
      `${destinationPath}knoxville-tennessee/book-a-hotel.webp`,
      `${destinationPath}knoxville-tennessee/where-to-stay.webp`,
    ],
    metaDescription:
      'Embark on your Knoxville, TN journey with a dream hotel stay. Discover the perfect blend of comfort, convenience, and southern charm to enhance your travel.',
    order: 22,
  },

  tnNashville: {
    address: {
      city: 'Nashville',
      latitude: '36.1626638',
      longitude: '-86.7816016',
      state: 'Tennessee',
      stateCode: 'TN',
    },
    descriptions: [
      'Escape to Nashville, a thriving city that holds so much more than just country music! The "Music City" is home to the legendary Grand Ole Opry, as well as a thriving food and arts scene, with plenty of live music, museums, and historic sites to explore. Arts enthusiasts, you\'ll love it here!',
    ],
    images: [
      `${destinationPath}nashville-tennessee/book-a-hotel.webp`,
      `${destinationPath}nashville-tennessee/where-to-stay.webp`,
    ],
    metaDescription:
      "Embrace Nashville's musical heartbeat! Book a hotel in Nashville – Tennessee's legendary Music City. Find top deals near the iconic Grand Ole Opry, Broadway honky-tonks, and culinary delights. Dive into a blend of country roots and modern allure – Book your Nashville experience today!",
    order: 1,
  },

  txAustin: {
    address: {
      city: 'Austin',
      latitude: '30.267153',
      longitude: '-97.7430608',
      state: 'Texas',
      stateCode: 'TX',
    },
    descriptions: [
      "Travelers who love to constantly be on the go will never have a dull moment in this city! Trendy and eclectic with a vibrant music scene and a unique blend of Southern charm and modern innovation, Austin offers plenty of live music, festivals, and outdoor activities. Foodies will especially love the unique dishes served up at Austin's highly-rated restaurants!",
    ],
    images: [
      `${destinationPath}austin-texas/book-a-hotel.webp`,
      `${destinationPath}austin-texas/where-to-stay.webp`,
    ],
    metaDescription:
      "Experience Austin's eclectic vibe! Book your hotel in the Live Music Capital of the World, steps from vibrant nightlife, Texas BBQ, and serene lake views. Secure the best deals near iconic festivals, tech hubs, and cultural landmarks. Feel the rhythm of Austin – Reserve now!",
    order: 8,
  },

  txDallas: {
    address: {
      city: 'Dallas',
      latitude: '32.7766642',
      longitude: '-96.79698789999999',
      state: 'Texas',
      stateCode: 'TX',
    },
    descriptions: [
      "Everything's bigger in Texas, including the hospitality! In Dallas, you'll find a vibrant city with a rich history and culture. Take in the stunning skyline, visit the iconic Dealey Plaza and Sixth Floor Museum, or indulge in some authentic Texas BBQ (we highly recommend Catteleack Barbeque)! With world-class museums, an impressive arts district, and plenty of sports teams to cheer for, Dallas is the perfect destination for a mix of city excitement and Southern charm.",
    ],
    images: [
      `${destinationPath}dallas-texas/book-a-hotel.webp`,
      `${destinationPath}dallas-texas/where-to-stay.webp`,
    ],
    metaDescription:
      "Dive into Dallas's dynamic charm! Book a hotel in Dallas at the heart of Texas's modern metropolis, steps from bustling arts districts, historic landmarks, and upscale shopping. Secure top deals on accommodations amidst the blend of Southern hospitality and urban flair. Explore Dallas's vibrant core!",
    order: 14,
  },

  txSanAntonio: {
    address: {
      city: 'San Antonio',
      latitude: '29.4251905',
      longitude: '-98.4945922',
      state: 'Texas',
      stateCode: 'TX',
    },
    descriptions: [
      'Known for its historic landmarks, including the Alamo and the Riverwalk, San Antonio offers a unique blend of old-world charm and modern amenities, with plenty of cultural attractions, shopping, and dining to explore. For those wanting to channel their inner Indiana Jones, the caves of the Natural Bridge Caverns feature stunning underground lakes and unique rock formations that are especially fun to zipline over!',
    ],
    images: [
      `${destinationPath}san-antonio-texas/book-a-hotel.webp`,
      `${destinationPath}san-antonio-texas/where-to-stay.webp`,
    ],
    metaDescription:
      "Experience San Antonio's rich tapestry! Secure your hotel in the heart of Texas culture, steps away from the iconic River Walk, historic missions, and vibrant markets. Snag the best deals on accommodations amidst a blend of heritage and modern allure. Explore San Antonio's gems – Book now!",
    order: 7,
  },

  utSaltLakeCity: {
    address: {
      city: 'Salt Lake City',
      latitude: '40.7607793',
      longitude: '-111.8910474',
      state: 'Utah',
      stateCode: 'UT',
    },
    descriptions: [
      'Nestled amidst the majestic peaks of the Wasatch Range, Salt Lake City offers a unique blend of natural beauty, vibrant culture, and historical charm that beckons travelers from around the globe. Here, outdoor enthusiasts can explore breathtaking landscapes of nearby national parks or enjoy world-class skiing and snowboarding in some of the country’s most renowned resorts. The city itself, steeped in the rich heritage of the West, boasts a lively arts scene, diverse culinary delights, and the tranquil splendor of the Great Salt Lake.',
    ],
    images: [
      `${destinationPath}salt-lake-city-utah/book-a-hotel.webp`,
      `${destinationPath}salt-lake-city-utah/where-to-stay.webp`,
    ],
    metaDescription:
      'Explore the best of Salt Lake City, Utah! From breathtaking ski resorts in the Wasatch Range to the serene Great Salt Lake, vibrant arts scene, and historic landmarks, Salt Lake City is perfect for adventure seekers and culture enthusiasts alike. Start planning your Salt Lake City adventure now!',
    order: 23,
  },

  waSeattle: {
    address: {
      city: 'Seattle',
      latitude: '47.6062095',
      longitude: '-122.3320708',
      state: 'Washington',
      stateCode: 'WA',
    },
    descriptions: [
      'Known for its stunning natural beauty, including the nearby Olympic Mountains and Mount Rainier, Seattle is a vibrant city with a thriving arts and music scene, as well as plenty of outdoor activities, shopping, and dining. Be sure to experience the ferry and go explore nearby Poulsbo, a quaint Norwegian town on Bainbridge Island!',
    ],
    images: [
      `${destinationPath}seattle-washington/book-a-hotel.webp`,
      `${destinationPath}seattle-washington/where-to-stay.webp`,
    ],
    metaDescription:
      "Discover Seattle's mesmerizing skyline! Book a hotel in Seattle amidst the Space Needle's shadow, vibrant Pike Place Market, and serene Puget Sound views. Grab exclusive deals on accommodations at the nexus of tech innovation and rich maritime history. Dive into Seattle's unique blend – Book today!",
    order: 3,
  },
})

const destinationTitlePrefix = 'Book Top Hotels in'

export { DestinationSearchParam, destinations, destinationTitlePrefix }
export type { DestinationType }
