import { Button } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { PaxMixConfiguration } from './PaxMixConfiguration'
import type { PaxMixState } from './PaxMixState'
import { ExperiencesDetailsSearchParams } from './useExperienceDetailsSearchParams'
import { useExperienceProductDetails } from './useExperienceProductDetails'
import { useSearchParamPaxMixState } from './useSearchParamPaxMixState'

const hasRequiredAdults = (paxMix: PaxMixState, requiresAdultForBooking) => {
  if (!requiresAdultForBooking) return true
  return Object.entries(paxMix).some(([ageBand, count]) => {
    const hasAdultOrSenior = ageBand === 'ADULT' || ageBand === 'SENIOR'
    return hasAdultOrSenior && count > 0
  })
}

export const AvailabilityPaxMix = ({ travelDate }: { travelDate: string }) => {
  const { data } = useExperienceProductDetails()
  const [, updateSearchParams] = useSearchParams()
  const { paxMix, onUpdate } = useSearchParamPaxMixState()

  const totalGuests = Object.values(paxMix)?.reduce(
    (total, numberOfTravelers) => total + numberOfTravelers,
    0
  )

  const meetsMinTravelersRequirement =
    totalGuests >= data?.bookingRequirements?.minTravelersPerBooking

  const meetsAdultRequirement =
    data?.pricingInfo.type !== 'PER_UNIT'
      ? hasRequiredAdults(
          paxMix,
          data?.bookingRequirements?.requiresAdultForBooking
        )
      : true

  const isValid = meetsMinTravelersRequirement || meetsAdultRequirement

  const handleOnClick = () => {
    updateSearchParams(
      sp => {
        const paxMixCopy = { ...paxMix }
        for (let key in paxMixCopy) {
          // remove falsy values from paxMix
          if (!paxMixCopy[key]) delete paxMixCopy[key]
        }
        // apparently cannot update these as separate calls, else the earlier one gets removed from the URL
        sp.set(
          ExperiencesDetailsSearchParams.guests,
          JSON.stringify(paxMixCopy)
        )
        sp.set(ExperiencesDetailsSearchParams.travelDate, travelDate)
        return sp
      },
      { replace: true }
    )
    pushDataToDataLayer('select_day', { date: travelDate })
  }

  if (!data?.bookingRequirements) return null

  return (
    <div className='py-4'>
      <PaxMixConfiguration
        ageBands={data.pricingInfo?.ageBands}
        maxTravelersPerBooking={data.bookingRequirements.maxTravelersPerBooking}
        minTravelersPerBooking={data.bookingRequirements.minTravelersPerBooking}
        paxMix={paxMix}
        requiresAdultForBooking={
          data.bookingRequirements.requiresAdultForBooking
        }
        onChange={onUpdate}
      />
      <div className='flex justify-center'>
        <Button
          isDisabled={!isValid}
          label='Check availability'
          size='small'
          onClick={handleOnClick}
        />
      </div>
    </div>
  )
}
