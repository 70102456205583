import type { Note } from 'src/__generated__/graphql'
import { NoteType } from 'src/__generated__/graphql'

const getOverviewNoteFormNavActiveIndex = (note: Note) => {
  const { type, id } = note ?? {}

  if (id) return 0

  if (type === NoteType.FreeText) return 0

  if (type === NoteType.Checklist) return 1

  return 2
}

const getOverviewNoteFormNavNoteType = (activeIndex: number) => {
  if (activeIndex === 0) return NoteType.FreeText

  if (activeIndex === 1) return NoteType.Checklist

  return NoteType.Link
}

export { getOverviewNoteFormNavActiveIndex, getOverviewNoteFormNavNoteType }
