import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MarkerWrapper, Map } from 'src/common/components/Map'
import { centerMap, getDefaultZoom } from 'src/common/components/Map/utils'
import { HotelResultsCardMinimalRow } from 'src/pages/hotels/results/common'
import { useTripDetailsMapPoints } from 'src/pages/trips/components'
import { TripDetailsMapEvents } from './TripDetailsMapEvents'
import { StyledMarker } from './markers'
import { TripSearchParams } from '../../constants'
import type { TripAnchorLocation } from '../../types'
import { getEventImage } from '../../utils'
import { MobileMapsHeader } from '../MobileMapsHeader'
import { TripMapTogglePins } from '../TripMapTogglePins'

// LOGIC: conditionally render map based on whether event cards are present in the timeline
// map should be its original form if no event cards, otherwise, pin should appear on hovered event card

interface TripDetailsMapProps {
  hoveredEventId?: string
  isAddingEvent: boolean
  setSelectedEventId: (selectedEventId: string) => void
  selectedEventId: string | null
  anchorLocation: TripAnchorLocation
  onHoveredEventIdChange?(updatedHoveredId: string): void
}

export const TripDetailsMap = ({
  hoveredEventId,
  isAddingEvent,
  setSelectedEventId,
  selectedEventId,
  anchorLocation,
  onHoveredEventIdChange,
}: TripDetailsMapProps) => {
  const [searchParams] = useSearchParams()
  const [showAllPoints, setShowAllPoints] = useState(false)
  const { points } = useTripDetailsMapPoints()
  const isListGuide = searchParams.get(TripSearchParams.listGuide)
  const showTogglePins = !isAddingEvent

  if (!anchorLocation) return null

  return (
    <div className='h-full'>
      <div className='block lg:hidden'>
        <MobileMapsHeader />
      </div>
      <Map
        defaultCenter={{
          lat: anchorLocation.lat,
          lng: anchorLocation.lng,
        }}
        defaultZoom={getDefaultZoom({
          city: anchorLocation.city,
          state: anchorLocation.state,
          country: anchorLocation.country,
        })}
        maxZoom={20}
        minZoom={3}
        onDataChanged={map => {
          if (points.length > 0) {
            centerMap({
              map,
              points: points.map(p => ({
                lat: p.lat,
                lng: p.lng,
              })),
            })
          } else if (anchorLocation) {
            map.panTo({
              lat: anchorLocation.lat,
              lng: anchorLocation.lng,
            })
          }
        }}
      >
        <TripDetailsMapEvents points={points} />
        {points.map(point => {
          const image = getEventImage(point.imageUrl)
          const isHovered = point.id === hoveredEventId
          const isSelected = point.id === selectedEventId

          if (!isListGuide && showTogglePins && !showAllPoints && !point.active)
            return

          return (
            <MarkerWrapper
              key={point.id}
              isHovered={isHovered || isSelected}
              position={{
                lat: point.lat,
                lng: point.lng,
              }}
              onClick={() => point.active && setSelectedEventId(point.id)}
            >
              <div
                className='relative'
                onMouseEnter={() => onHoveredEventIdChange?.(point.id)}
                onMouseLeave={() => onHoveredEventIdChange?.('')}
              >
                <StyledMarker
                  isHovered={isHovered || isSelected}
                  isSelected={isSelected}
                  type={point.type}
                  variant={point?.variant}
                />
                {isHovered && (
                  <div className='w-340px absolute top-[calc(100%+8px)] -translate-x-2/4'>
                    <HotelResultsCardMinimalRow
                      hotelResultsCardMinimalRowData={{
                        customerReviewScore: point.customerReviewScore,
                        id: point.id,
                        images: [image],
                        title: point.title,
                        totalCustomerReviews: point.totalReviews,
                        type: point.type,
                      }}
                      imageDimensions=''
                    />
                  </div>
                )}
              </div>
            </MarkerWrapper>
          )
        })}
        {!isListGuide && showTogglePins && (
          <TripMapTogglePins
            isChecked={showAllPoints}
            onChange={event => setShowAllPoints(!!event.target.checked)}
          />
        )}
      </Map>
    </div>
  )
}
