import { Chip } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { Scroller } from 'src/common/components'
import type { GuideData } from '../../types'

interface GuideHeaderOldTagsProps {
  tags: GuideData['tags']
}

export const GuideHeaderOldTags = ({ tags }: GuideHeaderOldTagsProps) => {
  if (isEmpty(tags)) return

  return (
    <Scroller
      allowTouch={true}
      hideScrollBar={true}
      scrollContainerClassName='flex hidden flex-row items-center gap-1'
    >
      {tags?.map(({ id, name }) => (
        <div key={id} className='pointer-events-none'>
          <Chip label={name} onClick={() => {}} />
        </div>
      ))}
    </Scroller>
  )
}
