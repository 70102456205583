import { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type { Supercluster } from 'supercluster'
import { HotelResultsMapMarker } from './HotelResultsMapMarker'
import { HotelResultsMapMarkerCluster } from './HotelResultsMapMarkerCluster'
import { HotelResultsMapMarkerLocation } from './HotelResultsMapMarkerLocation'
import {
  HotelResultsSearchParams,
  HotelResultsSessionStorage,
} from '../hotelResultsConstants'
import type {
  GetHotelResultsCardUrl,
  HotelResultsData,
} from '../hotelResultsTypes'
import { getHotelResultsCardUrl } from '../hotelResultsUtils'

interface HotelResultsMapMarkersProps {
  clusters: Supercluster.PointFeature[]
  hotelResultsFeaturedData: HotelResultsData
  supercluster: Supercluster
  onFilteredIdsChange?(updatedFilteredIds: string[]): void
}

export const HotelResultsMapMarkers = ({
  clusters,
  hotelResultsFeaturedData,
  supercluster,
  onFilteredIdsChange = () => {},
}: HotelResultsMapMarkersProps) => {
  const [visitedIds, setVisitedIds] = useState(() => {
    try {
      return JSON.parse(
        sessionStorage.getItem(HotelResultsSessionStorage.visitedIds) ?? '{}'
      )
    } catch (error) {
      console.error(error)
      return {}
    }
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const activeId = searchParams.get(HotelResultsSearchParams.activeId)
  const hoverId = searchParams.get(HotelResultsSearchParams.hoverId)

  useEffect(() => {
    const updatedFilteredIds =
      clusters?.reduce((total, { properties }) => {
        const { cluster: isCluster, cluster_id, item } = properties ?? {}
        if (isCluster) {
          supercluster
            ?.getLeaves(cluster_id, Infinity)
            ?.forEach(({ properties }) => total.push(properties?.item?.id))
        } else {
          total.push(item?.id)
        }

        return total
      }, []) ?? []
    onFilteredIdsChange(updatedFilteredIds)
  }, [JSON.stringify(clusters)])

  const onActiveIdChange = (updatedActiveId: string) => {
    if (activeId === updatedActiveId) {
      searchParams.delete(HotelResultsSearchParams.activeId)
    } else {
      searchParams.set(HotelResultsSearchParams.activeId, updatedActiveId)
    }

    onVisitedIdsChange(updatedActiveId)

    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onVisitedIdsChange = (updatedVisitedId: string) => {
    setVisitedIds(updatedVisitedIds => {
      updatedVisitedIds[updatedVisitedId] = 1

      sessionStorage.setItem(
        HotelResultsSessionStorage.visitedIds,
        JSON.stringify(updatedVisitedIds)
      )

      return updatedVisitedIds
    })
  }

  const onPopupClick = (
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  return (
    <>
      {clusters?.map(({ geometry, properties }) => {
        const { coordinates } = geometry ?? {}
        const {
          cluster: isCluster,
          cluster_id,
          item,
          point_count,
        } = properties ?? {}

        if (isCluster) {
          return (
            <HotelResultsMapMarkerCluster
              key={cluster_id}
              hotelResultsMapMarkerClusterData={{
                id: cluster_id,
                latitude: coordinates[1],
                longitude: coordinates[0],
                total: point_count,
              }}
              hoverId={hoverId}
              supercluster={supercluster}
            />
          )
        }

        return (
          <HotelResultsMapMarker
            key={item.id}
            activeId={activeId}
            hotelResultsMapMarkerData={item}
            hoverId={hoverId}
            visitedIds={visitedIds}
            onActiveIdChange={onActiveIdChange}
            onPopupClick={onPopupClick}
          />
        )
      })}
      {hotelResultsFeaturedData?.map(item => (
        <HotelResultsMapMarker
          key={item.id}
          isFeatured
          activeId={activeId}
          hotelResultsMapMarkerData={item}
          hoverId={hoverId}
          visitedIds={visitedIds}
          onActiveIdChange={onActiveIdChange}
          onPopupClick={onPopupClick}
        />
      ))}
      <HotelResultsMapMarkerLocation
        hotelResultsFeaturedData={hotelResultsFeaturedData}
      />
    </>
  )
}
