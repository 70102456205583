import { useState } from 'react'
import { IconButton, MenuItem, Popover } from '@travelpass/design-system'

interface CollectionMenuProps {
  onEdit(): void
  onDelete(): void
}

export const CollectionMenu = ({ onEdit, onDelete }: CollectionMenuProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div className='[&>div>button]:shadow-none'>
      <Popover
        placement='bottom-end'
        trigger={
          <IconButton
            aria-label='Collection Options'
            color='white'
            icon='moreHoriz'
            outline='square'
            size='small'
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          />
        }
      >
        <MenuItem
          aria-label='Edit Collection name/saved items'
          label='Edit'
          startIcon='modeEdit'
          value='Edit'
          onClick={onEdit}
        />
        <MenuItem
          aria-label='Delete Collection'
          label='Delete'
          startIcon='delete'
          value='Delete Collection'
          onClick={onDelete}
        />
      </Popover>
    </div>
  )
}
