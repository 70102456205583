import { useMemo } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceItinerary,
  ExperienceStandardItinerary,
  ExperienceStandardItineraryItem,
} from 'src/__generated__/graphql'
import { VerticalStep, VerticalStepper } from 'src/common/components'
import { ExperiencesItineraryTimelineStep } from '../../common/components/ExperiencesItineraryTimeline/ExperiencesItineraryTimelineStep'
import { experiencesItineraryTimelineCustomCss } from '../../common/components/ExperiencesItineraryTimeline/experiencesItineraryTimelineStyles'

interface TimelineStandardProps {
  expanded: boolean
  itinerary: ExperienceItinerary
  size?: 'small' | 'medium'
}

export const TimelineStandard = ({
  expanded,
  itinerary,
  size = 'medium',
}: TimelineStandardProps) => {
  const { itineraryItems } = (itinerary as ExperienceStandardItinerary) ?? {}
  const locations = useMemo(
    () =>
      itineraryItems?.filter(
        ({ passByWithoutStopping }) => !passByWithoutStopping
      ),
    []
  )
  const passByLocations = useMemo(
    () =>
      itineraryItems?.filter(
        ({ passByWithoutStopping }) => !!passByWithoutStopping
      ),
    []
  )
  const initialStepsVisible = 2
  const showTimeline = !isEmpty(itineraryItems)

  const constructedContent = ({
    admissionIncluded,
    duration,
  }: Partial<ExperienceStandardItineraryItem>) => {
    const { readableDurationRange } = duration ?? {}
    const content = []

    if (readableDurationRange) content.push(readableDurationRange)

    if (admissionIncluded === 'YES') content.push('Admission Ticket Included')

    return content.join(' • ')
  }

  const getStepVisibility = (index: number): boolean => {
    return expanded || index < initialStepsVisible
  }

  return (
    showTimeline && (
      <div className={experiencesItineraryTimelineStandardCss({ size })}>
        <div className={experiencesItineraryTimelineCustomCss}>
          <VerticalStepper>
            {passByLocations?.map(
              ({ description, pointOfInterestLocation }, index) =>
                getStepVisibility(index) && (
                  <VerticalStep
                    key={`${pointOfInterestLocation?.location?.name}-${index}`}
                  >
                    <ExperiencesItineraryTimelineStep
                      subtitle={description}
                      title={
                        <>
                          {pointOfInterestLocation?.location?.name}{' '}
                          <span className='color-grey-700'>(Pass By)</span>
                        </>
                      }
                    />
                  </VerticalStep>
                )
            )}
            {locations?.map(
              (
                {
                  admissionIncluded,
                  description,
                  duration,
                  pointOfInterestLocation,
                },
                index
              ) =>
                getStepVisibility(passByLocations?.length + index) && (
                  <VerticalStep key={index} label={index + 1}>
                    <ExperiencesItineraryTimelineStep
                      content={constructedContent({
                        admissionIncluded,
                        duration,
                      })}
                      subtitle={description}
                      title={pointOfInterestLocation?.location?.name}
                    />
                  </VerticalStep>
                )
            )}
          </VerticalStepper>
        </div>
      </div>
    )
  )
}

const experiencesItineraryTimelineStandardCss = ({
  size,
}: Partial<TimelineStandardProps>) =>
  classNames({
    'flex flex-col md:gap-4': size !== 'small',
  })
