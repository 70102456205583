import type { ReactNode } from 'react'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'

import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

export const FaqCard = ({
  faqItem,
  isLast = false,
}: {
  faqItem: {
    item: {
      content: string[] | ReactNode[]
      title: string
    }
  }
  isLast?: boolean
}) => {
  const { content, title } = faqItem?.item ?? {}

  return (
    <AccordionItem
      className={classNames('b-grey-300 b-solid b-t-1 b-0', {
        'b-b-1': isLast,
      })}
    >
      <AccordionButton className={accordionButtonCss}>
        <p className={accordionTextCss}>{title}</p>
        <Icon name='expandMore' />
      </AccordionButton>
      <AccordionPanel>
        <div className={containerCss}>{content}</div>
      </AccordionPanel>
    </AccordionItem>
  )
}

const accordionButtonCss =
  'flex flex-row items-center justify-between h-16 p-4 w-full bg-white text-left pointer b-0'
const accordionTextCss = 'type-body-1'
const containerCss = 'space-y-4 pl-3 pr-4 py-6'
