import isEmpty from 'lodash.isempty'
import type { ExperienceLanguageGuide } from 'src/__generated__/graphql'

interface ExperiencesLanguagesOfferedProps {
  languageGuides: ExperienceLanguageGuide[]
}

export const ExperiencesLanguagesOffered = ({
  languageGuides,
}: ExperiencesLanguagesOfferedProps) => {
  const languageFormat = new Intl.DisplayNames('en', {
    type: 'language',
  })
  // TODO: BE should return languages
  const languages = languageGuides?.reduce((total, { language, type }) => {
    try {
      const constructedType = type ? ` (${type.toLowerCase()})` : ''

      total.push(`${languageFormat?.of(language)}${constructedType}`)
    } catch (error) {
      console.error(error)
    }

    return total
  }, [])

  return (
    !isEmpty(languages) && (
      <ul className={experiencesLanguagesOfferedCss}>
        {languages?.map(language => (
          <li key={language} className={experiencesLanguagesOfferedItemCss}>
            {language}
          </li>
        ))}
      </ul>
    )
  )
}

const experiencesLanguagesOfferedCss = 'list-inside p-0'

const experiencesLanguagesOfferedItemCss = 'color-grey-800 text-body-1'
