import { ExperienceSearchParam } from '../../results'

export const constructFilterChange = (searchParams: URLSearchParams) => {
  const constructTotal: number[] = []
  const customerReviewScore = searchParams.get(
    ExperienceSearchParam.customerReviewScore
  )
  const duration = searchParams.get(ExperienceSearchParam.duration)
  const priceRange = searchParams.get(ExperienceSearchParam.priceRange)
  const tags = searchParams.get(ExperienceSearchParam.tags)
  const timeOfDay = searchParams.get(ExperienceSearchParam.timeOfDay)
  const tagsArray = tags && JSON.parse(tags)

  constructTotal.push(customerReviewScore ? 1 : 0)
  constructTotal.push(priceRange ? 1 : 0)
  constructTotal.push(timeOfDay ? 1 : 0)
  constructTotal.push(duration ? 1 : 0)
  constructTotal.push(tags ? tagsArray?.length : 0)

  const constructedTotal = constructTotal.reduce(
    (total, count) => total + count,
    0
  )

  return constructedTotal
}
