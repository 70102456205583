import { FormCheckbox } from 'src/common/components/FormCheckbox'
import { FormInput } from 'src/common/components/FormInput'
import { useFlag } from 'src/common/hooks'
import { type BookingFormFields, rules } from '../../hooks'
import { BookingSendSmsLabel } from '../BookingGuest/BookingSendSmsLabel'

const Input = FormInput<BookingFormFields>

export const GuestInformationFields = () => {
  const isBookingSendSmsEnabled = useFlag('isBookingSendSmsEnabled')

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-4'>
        <Input
          fullWidth
          label='First Name'
          name='firstName'
          placeholder='eg. John'
          required={rules.required}
        />
        <Input
          fullWidth
          label='Last Name'
          name='lastName'
          placeholder='eg. Smith'
          required={rules.required}
        />
      </div>
      <Input
        isNumericString
        autoComplete='tel'
        format='(###) ###-####'
        label='Phone Number'
        name='phone'
        placeholder='(000) 000-0000'
        required={rules.required}
        rules={rules.phone}
        type='tel'
      />
      <Input
        autoComplete='email'
        label='Email Address'
        name='email'
        placeholder='Johnsmith@email.com'
        required={rules.required}
        rules={rules.email}
      />
      {isBookingSendSmsEnabled && (
        <FormCheckbox label={<BookingSendSmsLabel />} name='sendSms' />
      )}
    </div>
  )
}
