import type {
  ExperiencePickupLocation,
  ExperienceStartEndPoint,
} from 'src/__generated__/graphql'
import { constructAddress } from 'src/utils'

export const toAddressPoints = (
  points: (ExperiencePickupLocation | ExperienceStartEndPoint)[] = []
) =>
  points.map(({ location }) => {
    return (
      location?.reference &&
      constructAddress({
        addressFirstLine: location.address,
        city: location.city,
        state: location.state,
        zipcode: location.postalCode,
      })
    )
  })
