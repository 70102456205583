import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { gql } from 'src/__generated__'
import { extractRecommendedData } from '../utils'

const searchExperiencesQuery = gql(`
  query ExperienceRecommendedData($first: Int!, $searchExperiencesArgs: SearchExperiencesArgs!) {
    searchExperiences(first: $first, searchExperiencesArgs: $searchExperiencesArgs) {
      edges {
        node {
          id
          availabilitySchedule {
            summary {
              fromPrice {
                amount
              }
            }
          }
          description
          destinations {        
            latitude
            longitude
          }
          images {
            size720x480
          }
          itinerary {
            ... on ExperienceStandardItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceActivityItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceUnstructuredItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
            ... on ExperienceHopOnHopOffItinerary {
              duration {
                fixedDurationInMinutes
                readableDurationRange
              }
            }
          }
          logistics {        
            start {
              location {
                latitude
                longitude
              }
            }
          }
          reviews {
            combinedAverageRating
            totalReviews
          }
          title
          productCode
        }
      }
    }
  }
`)

export const useExperienceRecommendedData = ({
  arrival,
  departure,
  latitude,
  longitude,
  tracker,
}: {
  arrival: string
  departure: string
  latitude: string
  longitude: string
  tracker: string
}) => {
  const arrivalFormatted = dayjs(arrival).format('YYYY-MM-DD')
  const departureFormatted = dayjs(departure).format('YYYY-MM-DD')

  const { data, loading, error } = useQuery(searchExperiencesQuery, {
    skip: !latitude || !longitude,
    variables: {
      first: 12,
      searchExperiencesArgs: {
        arrival: arrivalFormatted,
        departure: departureFormatted,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        tracker,
      },
    },
  })

  const experienceNodes =
    data?.searchExperiences?.edges?.map(({ node }) => node) ?? null

  const experiences = extractRecommendedData(experienceNodes)

  return {
    experiences,
    loading,
    error,
  }
}
