import type { ChangeEventHandler } from 'react'
import { useEffect, useState } from 'react'
import {
  Checkbox,
  Chip,
  Icon,
  IconButton,
  Link,
  Input,
} from '@travelpass/design-system'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { isMobile } from 'react-device-detect'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ResultsFiltersSection } from 'src/common/components'
import type { experienceTagType } from 'src/constants'
import { experienceTagsPrioritized } from 'src/constants'

interface ResultsFiltersTagsProps {
  filters: ExperienceFilters
  onChange(updatedFilters: ExperienceFilters): void
}

export const ResultsFiltersTags = ({
  filters,
  onChange,
}: ResultsFiltersTagsProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedTags, setSelectedTags] = useState<[] | experienceTagType[]>([])
  const [showAll, setShowAll] = useState(false)

  // get currently selected tags, and clone all tags
  const currentTags = filters?.tags ?? []
  const allTags = cloneDeep(experienceTagsPrioritized)

  const constructTags = () => {
    // find tags releated to search term
    const tagsReleatedToSearch = allTags?.filter(({ tagName }) =>
      tagName.toLowerCase().includes(searchValue.toLowerCase())
    )

    // possibly abridge results
    const visibleTags = tagsReleatedToSearch?.slice(
      0,
      showAll ? allTags.length : isMobile ? 8 : 12
    )

    // create checkbox elements for constructed tags
    return visibleTags?.map(({ tagName, tagId }) => (
      <Checkbox
        key={`${tagId} checkbox`}
        id={`${tagId} checkbox`}
        isChecked={currentTags?.includes(tagId) ?? false}
        label={tagName}
        onClick={() => onTagsChange(tagId)}
      />
    ))
  }

  // conditionally render a button to clear search
  const slotAfter = searchValue !== '' && (
    <IconButton icon='clear' onClick={() => setSearchValue('')} />
  )

  const onTagsChange = (value: number) => {
    // identify if tag is currently selected
    const shouldRemoveValue = currentTags?.includes(value) ?? false

    // unselect if selected, select if unselected
    const updatedTags = shouldRemoveValue
      ? currentTags?.filter(tag => tag !== value)
      : currentTags ?? []

    if (!shouldRemoveValue) {
      updatedTags.push(value)
    }

    onChange({
      ...filters,
      tags: updatedTags,
    })
  }

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event?.target ?? {}
    setSearchValue(value)
  }

  useEffect(() => {
    // identify the currently selected tagIds and retrieve associated tag
    if (!isEmpty(currentTags)) {
      const updatedSelectedTags = []

      allTags?.forEach(tag => {
        if (currentTags?.includes(tag.tagId)) updatedSelectedTags.push(tag)
      })

      setSelectedTags(updatedSelectedTags)
    }
  }, [filters])

  return (
    <ResultsFiltersSection title='Type'>
      <div className={resultsFiltersTagsCss}>
        {/* Search bar for tags */}
        <Input
          label='Search Experience Types'
          name='search-tags'
          placeholder='Type'
          slotAfter={slotAfter}
          slotBefore={<Icon name='search' />}
          value={searchValue}
          onChange={onSearchChange}
        />
        {/* Displays selected tags */}
        {!isEmpty(currentTags) && (
          <div className={resultsFiltersTagsChipsCss}>
            {selectedTags?.map(({ tagId, tagName }) => (
              <Chip
                key={`${tagId} chip`}
                includeCloseIcon
                label={tagName}
                onClick={() => onTagsChange(tagId)}
              />
            ))}
          </div>
        )}
        {/* Displays selected and unselected tags */}
        <div className={resultsFiltersTagsItemsCss}>{constructTags()}</div>
        <div className={resultsFiltersTagsButtonWrapperCss}>
          <Link
            label={showAll ? 'See less' : 'See all'}
            onClick={() => setShowAll(!showAll)}
          />
        </div>
      </div>
    </ResultsFiltersSection>
  )
}

const resultsFiltersTagsButtonWrapperCss = 'flex flex-col items-end mt-6'

const resultsFiltersTagsChipsCss = 'flex gap-4 flex-wrap'

const resultsFiltersTagsCss = 'flex flex-col gap-5'

const resultsFiltersTagsItemsCss =
  'grid grid-cols-1 grid-flow-dense gap-y-1 gap-x-0 md:gap-y-0 md:gap-x-4 md:grid-cols-2 lg:grid-cols-3'
