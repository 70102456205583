interface HeroImageProps {
  alt: string
  onClick: () => void
  src: string
  srcSet: string
}

export const HeroImage = ({ alt, onClick, src, srcSet }: HeroImageProps) => {
  return (
    <button
      aria-label='Open image gallery'
      className='m-0 h-full w-full cursor-pointer border-none bg-transparent p-0'
      onClick={onClick}
    >
      <img
        alt={alt}
        className='h-75 max-h-full w-full bg-gray-200 object-cover md:transition-all md:duration-300 md:hover:scale-110 lg:h-full'
        loading='lazy'
        src={src}
        srcSet={srcSet}
      />
    </button>
  )
}
