import { Carousel } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { PageLayoutContainer } from 'src/common/components'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocationTimeToVisit = ({
  destination,
}: DestinationLocationProps) => {
  const { address, timeToVisit } = destination ?? {}
  const title = `The best time to visit ${address?.city}`

  const constructedWeather = () =>
    timeToVisit?.map(item => (
      <div
        key={address?.city}
        className='w-85% md:w-97% lg:w-97% m-x-a m-y-10 border-rounded-8 block h-auto bg-white p-8 shadow-lg'
      >
        <div className='type-h3'>Weather in {address?.city}</div>
        <div className='flex flex-wrap'>
          {item.weather.map(item => (
            <div key={item.season} className='p-t-6 w-50% p-x-2'>
              <h3 className='type-h5 color-forest-light p-b-1'>
                {item.season}
              </h3>
              <p className='type-body-1 color-grey-700'>{item.climate}</p>
            </div>
          ))}
        </div>
      </div>
    ))

  const constructedTourism = () =>
    timeToVisit?.map(item => (
      <div
        key={address?.city}
        className='w-85% md:w-97% lg:w-97% m-x-a m-y-10 border-rounded-8 block h-auto bg-white p-8 shadow-lg'
      >
        <div className='type-h3 p-b-5'>
          Popular times to visit {address?.city}
        </div>
        <div className='type-h5 color-forest-light p-b-1'>High Season</div>
        <div className='type-body-1 color-grey-700'>
          {item.tourism.highSeason}
        </div>
        <div className='type-h5 color-forest-light p-t-6 p-b-1'>Low Season</div>
        <div className='type-body-1 color-grey-700'>
          {item.tourism.lowSeason}
        </div>
      </div>
    ))

  const constructedPriceTrends = () =>
    timeToVisit?.map(item => (
      <div
        key={address?.city}
        className='w-85% md:w-97% lg:w-97% m-x-a m-y-10 border-rounded-8 block h-auto bg-white p-8 shadow-lg'
      >
        <div className='type-h3 p-b-5'>Hotel Price Trends</div>
        <div className='type-body-1 color-grey-700 m-b-3'>
          {item.priceTrends}
        </div>
      </div>
    ))

  return !isEmpty(timeToVisit) ? (
    <div className='bg-light-mint p-y-10 block w-full'>
      <PageLayoutContainer>
        <div className='lg:flex-items-start block lg:flex'>
          <div className='lg:w-20% lg:m-r-5% block w-full'>
            <div className='type-h1 p-b-3 lg:p-t-14 lg:p-b-9'>When to go</div>
            <h2 className='type-subtitle-2 lg:line-height-6 color-grey-700'>
              {title}
            </h2>
          </div>
          <div className='lg:w-75% block w-full'>
            <Carousel
              slidesPerViewOnDesktop={1}
              slidesPerViewOnMobile={1}
              slidesPerViewOnTablet={1}
            >
              {constructedWeather()}
              {constructedTourism()}
              {constructedPriceTrends()}
            </Carousel>
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  ) : null
}
