import { Carousel } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { PageLayoutContainer } from 'src/common/components'
import type { DestinationType } from '../destinationConstants'

interface DestinationLocationProps {
  destination: DestinationType
}

export const DestinationLocationBestAttractions = ({
  destination,
}: DestinationLocationProps) => {
  const { address, bestAttractions } = destination ?? {}
  const { city, state } = address ?? {}
  const title = `The best attractions and places to see in ${city}`

  return !isEmpty(bestAttractions) ? (
    <div className='p-y-10 block w-full bg-[url(https://static.travelpass.com/assets/home-page/mountain-with-pattern.webp)]'>
      <PageLayoutContainer>
        <div className='lg:flex-items-start block lg:flex'>
          <div className='lg:w-20% lg:m-r-5% block w-full'>
            <div className='type-h1 p-b-3 lg:p-t-14 lg:p-b-9 color-white'>
              What to see
            </div>
            <h2 className='type-subtitle-2 lg:line-height-6 color-grey-600'>
              {title}
            </h2>
          </div>
          <div className='lg:w-75% block w-full'>
            <Carousel
              isLight={true}
              size='medium'
              slidesPerViewOnDesktop={2}
              slidesPerViewOnMobile={1}
              slidesPerViewOnTablet={2}
            >
              {bestAttractions?.map(
                ({ attraction, summary, image, imageAlt }) => (
                  <div
                    key={attraction}
                    className='position-relative w-85% md:w-97% lg:w-97% lg:h-475px m-x-a m-y-10 border-rounded-8 bg-grey-100 block h-auto overflow-hidden p-3 shadow-lg'
                  >
                    <div className='w-100% h-200px border-rounded-5 m-0 block overflow-auto'>
                      <img
                        alt={
                          imageAlt
                            ? imageAlt
                            : `${attraction} in ${city}, ${state}`
                        }
                        className='block h-full w-full object-cover'
                        src={image}
                      />
                    </div>

                    <div className='w-100% p-x-7 m-y-3 block'>
                      <h3 className='type-h4 p-t-2 color-forest-dark'>
                        {attraction}
                      </h3>
                      <div className='type-body-1 color-grey-700 m-t-3'>
                        {summary}
                      </div>
                    </div>
                  </div>
                )
              )}
            </Carousel>
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  ) : null
}
