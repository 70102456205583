import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'

interface HotelOverviewDescriptionProps {
  descriptions: string[]
}

export const HotelOverviewDescription = ({
  descriptions,
}: HotelOverviewDescriptionProps) => {
  return (
    descriptions && (
      <div className='sm:py-4'>
        <div className='grid gap-2 space-y-2 pb-6 md:pb-3'>
          <h4 className='type-h4'>Overview</h4>
          <ReadMoreContentSection maxHeightClass='max-h-25'>
            <p className='color-grey-900 type-body-1'>{descriptions}</p>
          </ReadMoreContentSection>
        </div>
      </div>
    )
  )
}
