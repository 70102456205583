import { Skeleton } from '@travelpass/design-system'

export const GuideDrawerOldContentNotesLoading = () => (
  <div className='bg-warm-grey color-grey-800 p-x-8 p-y-3 rounded-2 space-y-4'>
    <div className='c-forest-light flex flex-row items-center gap-1'>
      <div className='h-6 w-6'>
        <Skeleton variant='circular' />
      </div>
      <div className='h-4.5 w-24 lg:h-5'>
        <Skeleton />
      </div>
    </div>
    <div className='space-y-2'>
      <div className='lg:h-4.5 h-4'>
        <Skeleton />
      </div>
      <div className='lg:h-4.5 h-4'>
        <Skeleton />
      </div>
      <div className='lg:h-4.5 h-4'>
        <Skeleton />
      </div>
      <div className='flex flex-row items-center gap-2'>
        <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-6 min-h-6 w-5 min-w-6 cursor-pointer space-x-2 bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
          <Skeleton variant='circular' />
        </div>
        <div className='h-3 w-10'>
          <Skeleton />
        </div>
      </div>
    </div>
  </div>
)
