import type { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import { explorePath } from 'src/constants'
import { BOUNDING_BOX_PARAMS } from 'src/pages/explore/bounding-box/constants/boundingBoxParams'
import { COUNTRY_STATE_PARAMS } from 'src/pages/explore/country-state/constants/COUNTRY_STATE_PARAMS'
import { KEYWORD_SEARCH_PHRASE } from 'src/pages/explore/keyword-search/constants'

export const handleKeywordSearch = (
  selection: string,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  navigate: NavigateFunction,
  pathname: string
) => {
  // do not set a new value for the input since we want the user's search term to remain
  setSearchParams(sp => {
    sp.set('title', selection)
    sp.set(KEYWORD_SEARCH_PHRASE, selection)

    sp.delete(COUNTRY_STATE_PARAMS.country)
    sp.delete(COUNTRY_STATE_PARAMS.state)
    sp.delete('location')
    sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
    sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
    sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
    sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

    return sp
  })
  if (!pathname.includes('/keyword'))
    navigate({
      pathname: `${explorePath}/keyword`,
      search: searchParams.toString(),
    })
}
