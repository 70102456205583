import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { getGuideAddress } from 'src/utils'
import { GuideHeaderActions } from './GuideHeaderActions'
import type { GuideData } from '../../types'

interface GuideHeaderSummaryProps {
  guideData: GuideData
  isEdit: boolean
}

export const GuideHeaderSummary = ({
  guideData,
  isEdit,
}: GuideHeaderSummaryProps) => {
  const {
    addresses,
    collectedCount,
    eventCounts,
    id,
    isCollected,
    likedByCurrentUser,
    name,
    numberOfLikes,
    shareCount,
    viewCount,
  } = guideData ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const guideAddress = getGuideAddress({
    city,
    country,
    state,
  })

  if (isEmpty(guideAddress) && !eventCounts?.numberEvents && !viewCount) return

  return (
    <div className='md:p-l-4 p-y-1 flex flex-row flex-wrap gap-x-3 gap-y-1 md:items-center'>
      <div className='flex grow flex-row items-center gap-3'>
        {guideAddress && (
          <div className='[&>i]:c-orange flex flex-row items-center gap-3'>
            <Icon name='placeOutline' size='small' />
            <span className='type-body-2 c-black'>{guideAddress}</span>
          </div>
        )}
        {!!eventCounts?.numberEvents && (
          <>
            <div className='w-1px h-16px md:h-17.5px hidden bg-black md:block' />
            <span className='type-body-2 c-black hidden md:block'>{`${eventCounts?.numberEvents} Recommendations`}</span>
          </>
        )}
        {!!viewCount && (
          <>
            <div className='w-1px h-16px md:h-17.5px hidden bg-black md:block' />
            <span className='type-body-2 c-black hidden md:block'>{`${viewCount} Views`}</span>
          </>
        )}
      </div>
      {!isEdit && (
        <GuideHeaderActions
          guideData={{
            collectedCount,
            id,
            isCollected,
            likedByCurrentUser,
            name,
            numberOfLikes,
            shareCount,
          }}
        />
      )}
    </div>
  )
}
