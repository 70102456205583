import { SortBy } from 'src/__generated__/graphql'
import type { DatesType, GeocoderType, GuestsType } from 'src/constants/user'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
} from 'src/utils'

export const getHomeHeroContentsArgumentsAsString = ({
  dates,
  geocoder,
  guests = [],
  isExperience = false,
}: {
  dates: DatesType
  geocoder: GeocoderType
  guests?: GuestsType
  isExperience?: boolean
}) => {
  const [arrival, departure] = dates ?? []
  const {
    addressComponents,
    center,
    placeId,
    placeName: location,
    viewport,
  } = geocoder ?? {}
  const [latitude, longitude] = center
  const [adults, kids] = guests ?? []
  const mapBounds = JSON.stringify(viewport) ?? ''
  const placeCountry =
    getGeocoderPlaceCountryFromAddressComponents(addressComponents)
  const placeFilteredType =
    getGeocoderPlaceTypeFromAddressComponents(addressComponents)

  if (isExperience)
    return generateExperiencesResultsUrl({
      arrival,
      departure,
      latitude,
      location,
      longitude,
    })

  return generateHotelResultsUrl({
    adults,
    arrival,
    departure,
    kids,
    latitude,
    location,
    longitude,
    mapBounds,
    placeCountry: placeCountry ?? '',
    placeId: placeId ?? '',
    placeShortName: placeFilteredType?.shortName ?? '',
    placeType: placeFilteredType?.type ?? '',
    sort: !placeId ? SortBy.Recommended : SortBy.Distance,
  })
}
