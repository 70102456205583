import { useEffect } from 'react'
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth'
import { logError, logInfo } from 'src/utils'
import { googleCloudConfig } from './googleCloudConfig'
import { auth } from '../firebase/firebaseUtils'

const GoogleOneTapLoginWrapper = () => {
  useEffect(() => {
    const unsubscribeUser = auth.onAuthStateChanged(currentUser => {
      try {
        if (!currentUser || currentUser?.isAnonymous) {
          // @ts-ignore
          window.google.accounts.id.initialize({
            client_id: googleCloudConfig.clientId,
            callback: handleCredentialResponse,
            use_fedcm_for_prompt: true,
          })
          // @ts-ignore
          window.google.accounts.id.prompt() // Display the One Tap prompt
        }
      } catch (error) {
        logError(error)
      }
    })
    return () => unsubscribeUser()
  }, [])

  const handleCredentialResponse = async response => {
    try {
      // Use the Google token to sign in with Firebase
      const credential = GoogleAuthProvider.credential(response.credential)
      const result = await signInWithCredential(auth, credential)

      // User is signed in
      logInfo(`User signed in: ${result.user}`)
    } catch (error) {
      logError(error)
    }
  }

  return null
}

export { GoogleOneTapLoginWrapper }
