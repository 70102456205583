import { Icon } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { MapButton, MapButtons } from 'src/common/components/Map'
import { stringToBoolean } from 'src/utils'
import { HotelResultsMapActionsContainer } from './HotelResultsMapActionsContainer'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

export const HotelResultsMapActions = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const mapExpanded = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.mapExpanded)
  )

  const onClick = () => {
    if (mapExpanded) {
      searchParams.delete(HotelResultsSearchParams.mapExpanded)
    } else {
      searchParams.set(HotelResultsSearchParams.mapExpanded, 'true')
    }

    setSearchParams(searchParams)
  }

  if (mapExpanded)
    return (
      <div data-testid='HotelResultsMapActions-map'>
        <div className='hidden lg:block'>
          <HotelResultsMapActionsContainer>
            <MapButtons>
              <MapButton
                label={
                  <>
                    <span>Show List</span>
                    <Icon name='arrowForwardIos' size='small' />
                  </>
                }
                onClick={onClick}
              />
            </MapButtons>
          </HotelResultsMapActionsContainer>
        </div>
        <button
          className='b-none rounded-tl-5 rounded-tr-5 shadow-1 transition-box-shadow-200 active:shadow-2 focus:shadow-2 hover:shadow-2 absolute bottom-0 left-0 block w-full cursor-pointer bg-white py-6 ease-linear lg:hidden'
          onClick={onClick}
        >
          <span className='bg-grey-300 m-a rounded-0.5 absolute left-0 right-0 top-2 h-1 w-11' />
          <span className='color-forest-light type-subtitle-2-mobile'>
            Hotel List View
          </span>
        </button>
      </div>
    )

  return (
    <div data-testid='HotelResultsMapActions-list'>
      <HotelResultsMapActionsContainer>
        <MapButtons>
          <MapButton
            label={<Icon name='arrowBackIos' size='small' />}
            onClick={onClick}
          />
        </MapButtons>
      </HotelResultsMapActionsContainer>
    </div>
  )
}
