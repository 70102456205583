import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import {
  HotelResultsCard,
  HotelResultsCardLoading,
} from 'src/pages/hotels/results/common'
import type { HotelResultsItemData } from '../../hotels/results/hotelResultsTypes'
import { getHotelResultsCardUrl } from '../../hotels/results/hotelResultsUtils'

const searchHotelsQuery = gql(`
  query GetSearchHotelsInLandingPage($searchHotelsWithoutRatesArgs: SearchHotelsWithoutRatesArgs!) {
    searchHotelsWithoutRates(searchHotelsWithoutRatesArgs: $searchHotelsWithoutRatesArgs) {
      hotelSearchResults {
        id
        amenities
        customerReviewScore
        description
        hotelAddress {
          city
          latitude
          longitude
          postalCode
          state
          stateAbbreviation
          streetAddress
        }
        images
        name
        phoneNumber
        totalCustomerReviews
      }
    }
  }
`)

export const LandingPageSearchHotelsResults = ({
  hotelIds,
}: {
  hotelIds: number[]
}) => {
  const [searchParams] = useSearchParams()
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(searchHotelsQuery, {
    skip: !hotelIds,
    variables: {
      searchHotelsWithoutRatesArgs: {
        filters: {
          hotelIds,
        },
      },
    },
  })
  const { hotelSearchResults } = data?.searchHotelsWithoutRates ?? {}

  const onCardClick = (hotelResultsCardData: HotelResultsItemData) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  if (isLoading)
    return (
      <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3'>
        <HotelResultsCardLoading />
        <HotelResultsCardLoading />
        <HotelResultsCardLoading />
      </div>
    )

  if (hasError) return <div>Error</div>

  return (
    <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3'>
      {hotelSearchResults?.map(hotel => (
        <HotelResultsCard
          key={hotel?.id}
          hotelResultsCardData={{
            amenities: hotel?.amenities,
            customerReviewScore: hotel?.customerReviewScore,
            hotelAddress: hotel?.hotelAddress,
            id: hotel?.id,
            images: hotel?.images,
            name: hotel?.name,
            totalCustomerReviews: hotel?.totalCustomerReviews,
          }}
          onClick={() => onCardClick(hotel)}
        />
      ))}
    </div>
  )
}
