import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { GhostTourLocations } from './GhostTourLocations'

export const GhostTour = () => {
  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/ghost-tour/'

  const navigate = useNavigate()

  const onExploreMoreClick = () => {
    const url = '/'
    navigate(url)
  }

  return (
    <>
      <div className={bgGradientContainer}>
        <div className={videoContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={bgVideo}
            poster={`${imagePath}ghost-tour-loop-poster.png`}
          >
            <source src={`${imagePath}ghost-tour-loop.mp4`} type='video/mp4' />
            <track kind='captions' />
          </video>
        </div>

        <div className={contentContainer}>
          <PageLayoutContainer>
            <div className={contentContainerInner}>
              <div className={titleContainer}>
                <h1 className={h1Styles}>
                  <span className={titleUpper}>
                    Do You Dare To Explore America’s
                  </span>
                  <span className={titleLower}>Haunted Cities?</span>
                </h1>
              </div>
              <div className={carouselContainer}>
                <GhostTourLocations />
              </div>
            </div>
          </PageLayoutContainer>
        </div>
      </div>
      <div className={bottomContainer}>
        <PageLayoutContainer>
          <h2 className={bottomContainerTitleTop}>Eager For More Adventure?</h2>
          <h2 className={bottomContainerTitle}>
            Travelpass has incredible, top-rated activities available in all of
            your favorite cities.
          </h2>
        </PageLayoutContainer>
        <div className={buttonContainer}>
          <Button
            fullWidth
            label='Explore More'
            size='large'
            onClick={onExploreMoreClick}
          />
        </div>
      </div>
    </>
  )
}

const bgGradientContainer =
  'relative bg-gradient-to-t from-forestDark to-transparent from-85% to-95% lg:from-10% lg:to-40% pt-25 pb-20 lg:pt-20 lg:pb-60'
const videoContainer =
  'absolute h-full bg-forestDark top-0 bottom-0 left-0 right-0 z--2'
const bgVideo =
  'aspect-auto w-full absolute top-0 right-0 bottom-0 left-0 saturate-30 z-1 lg:z--1'
const contentContainer = 'relative lg:z-10 w-full lg:w-50%'
const contentContainerInner =
  'lg:flex lg:flex-col w-full max-w-1280px ma items-start'
const titleContainer = 'w-full mt-30 lg:mt-0'
const h1Styles =
  'color-white flex flex-col font-archivo font-not-oblique font-700 m-0 text-center tracking-5%'
const titleUpper = 'leading-34px mb3 text-21px lg:leading-19 lg:text-6'
const titleLower = 'leading-15 text-16 lg:mb-10 lg:leading-24 lg:text-24'
const carouselContainer = 'w-full max-w-800px mx-a'
const bottomContainer = 'w-full bg-forestDark py6 text-center'
const bottomContainerTitleTop =
  'type-h2 text-8 leading-8 lg:text-14 lg:leading-14 font-900 max-w65% ma mb8 c-white'
const bottomContainerTitle = 'type-h2 c-white max-w65% ma'
const buttonContainer = 'w230px mxa mt14'
