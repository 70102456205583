import cloneDeep from 'lodash.clonedeep'
import type {
  ExperienceActivityItinerary,
  ExperienceProduct,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { arrayTypeNotNull } from 'src/utils'
import { defaultExperienceEvent, type ExperienceEvent } from '../constants'

const constructRecommendedExperience = (
  experience?: ExperienceProduct
): ExperienceEvent => {
  const recommendedExperience = cloneDeep(defaultExperienceEvent)

  recommendedExperience.id = experience?.id ?? defaultExperienceEvent?.id

  const addressA = experience?.logistics?.start?.[0]?.location ?? null
  const addressB = experience?.destinations?.[0] ?? null
  if (addressA) {
    const { latitude, longitude } = addressA

    if (latitude && longitude) {
      recommendedExperience.address.lat = latitude
      recommendedExperience.address.long = longitude
    }
  } else if (addressB) {
    const { latitude, longitude } = addressB

    if (latitude && longitude) {
      recommendedExperience.address.lat = latitude
      recommendedExperience.address.long = longitude
    }
  }

  const itinerary = experience?.itinerary ?? null
  if (itinerary?.__typename !== 'ExperienceMultidayItinerary') {
    const itineraryWithDuration = itinerary as
      | ExperienceActivityItinerary
      | ExperienceStandardItinerary
      | ExperienceUnstructuredItinerary

    recommendedExperience.duration =
      itineraryWithDuration?.duration?.fixedDurationInMinutes?.toString() ??
      null
  }

  const description = experience?.description ?? null
  if (description) recommendedExperience.description = description

  const images = experience?.images?.size720x480 ?? null
  const imagesNotNull = arrayTypeNotNull(images ?? [])
  if (images) recommendedExperience.images = imagesNotNull

  recommendedExperience.name = experience?.title ?? defaultExperienceEvent?.name

  recommendedExperience.nightlyAverage =
    experience?.availabilitySchedule?.summary?.fromPrice?.amount ?? null

  return recommendedExperience
}

const extractRecommendedData = (
  experiences?: ExperienceProduct[]
): ExperienceEvent[] => {
  if (experiences) {
    return experiences.map(experience =>
      constructRecommendedExperience(experience)
    )
  }

  return []
}

export { extractRecommendedData }
