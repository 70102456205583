import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'

export const ValentinesDaySolo = () => {
  const onSedonaHotelClick = () => {
    const url =
      '/destinations/az/sedona/hotels/110214762/Enchantment-Resort-Sedona-Arizona?adults=1'
    navigate(url)
  }
  const onSedonaExperienceClick = () => {
    const url =
      '/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzAyNDM4/Half-Day-Emerald-Cove-Kayak-Tour'
    navigate(url)
  }
  const onNewYorkHotelClick = () => {
    const url =
      '/destinations/ny/new-york/hotels/110181803/Arlo-Midtown-New-York-New-York?adults=1'
    navigate(url)
  }
  const onNewYorkExperienceClick = () => {
    const url =
      '/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjkwNDEy/New-York-Helicopter-Tour%3A-Ultimate-Manhattan-Sightseeing'
    navigate(url)
  }
  const onTripsClick = () => {
    const url = '/trips'
    navigate(url)
  }
  const navigate = useNavigate()

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='wfull bg-warm-grey'>
        <PageLayoutContainer>
          <div className='pt10 md:py10 flex flex-col items-start justify-center md:flex-row md:items-center lg:items-start'>
            <div className='basis-1/2'>
              <header className='flex flex-col'>
                <h1 className='c-forest font-archivo font-not-oblique font-700 m0 lg:mt10 md:mr10 tracking-5% flex flex-col text-center'>
                  <span className='leading-12 text-10 lg:leading-18 lg:text-14'>
                    It&apos;s All About You This
                  </span>
                  <span className='leading-14 text-14 lg:leading-22 lg:text-20'>
                    Valentine&apos;s Day
                  </span>
                </h1>
                <div className='ma bg-forest rounded-tl-8 rounded-br-8 px10 py7 mt10 md:mr--25 lg:mr--25 lg:mt25 md:mb0 z-2 outline-offset-5 outline-forest shadow-2 flex w-full flex-col gap-4 outline-dashed outline-2'>
                  <h4 className='type-h3 c-valley'>
                    Calling all solo travelers!
                  </h4>
                  <p className='type-body-1 c-white'>
                    We&apos;ve put together the start of two very dreamy
                    itineraries in two of our favorite destinations, just
                    perfect for a Valentine&apos;s Day trip for one. Each spot
                    is charmingly romantic, whether you&apos;re looking for a
                    quiet escape or one full of activities to keep you busy.
                  </p>
                  <p className='type-body-1 c-white'>
                    Take a peek below (and best of luck picking just one!):
                  </p>
                </div>
              </header>
            </div>
            <div className='hidden basis-1/2 md:block'>
              <img
                alt='Solo traveler walking down a river through a slot canyon'
                className='w90% ma rounded-tl-8 rounded-br-8 shadow-2 block'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/2be5b380-ba1a-11ee-b862-7aae32ad67d6'
              />
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <PageLayoutContainer>
        <div className='gap8 py10 flex flex-col items-center md:flex'>
          <div className='basis-1/2'>
            <img
              alt='Sedona, Arizona'
              className='w100% md:w90% ma rounded-bl-8 rounded-tr-8 shadow-2 block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/f9fb381c-ba1f-11ee-b8cb-9eaad44f8ae9'
            />
          </div>
          <div className='basis-1/2'>
            <h2 className='type-h2'>A Solo Trip To Sedona, Arizona</h2>
            <p className='type-body-1 my4'>
              If you&apos;ve been dreaming of red rock desert hikes and
              spectacular hiking scenery, it&apos;s time to make it happen.
              We&apos;re huge fans of going all-in on this experience by staying
              at your own private adobe casita at the Enchantment Resort, so you
              can wake up early and step outside your door to see spectacular
              sunrises. The views from your hotel alone are incredible.
            </p>
            <div className='w330px mxa my10 md:ml0'>
              <Button
                fullWidth
                label='Private Casita, Anyone?'
                size='large'
                onClick={onSedonaHotelClick}
              />
            </div>
            <p className='type-body-1 my4'>
              The picturesque spots in Sedona also give you plenty of things to
              fall in love with. Pack your hiking shoes for iconic sites along
              the Cathedral/Templeton Trail or test your love of adventure by
              tackling Devil&apos;s Bridge Trail ... or go kayaking through
              Emerald Canyon! Plan on taking home some incredible pictures (and
              memories).
            </p>
            <div className='w330px mxa mt10 md:ml0'>
              <Button
                fullWidth
                label='Kayak Your Heart Out'
                size='large'
                onClick={onSedonaExperienceClick}
              />
            </div>
          </div>
        </div>
        <div className='gap8 py10 flex flex-col flex-col-reverse items-center md:flex-row'>
          <div className='basis-1/2'>
            <h2 className='type-h2'>A Solo Trip To NYC</h2>
            <p className='type-body-1 my4'>
              New York gives you the chance to join the crowds for a charmed
              “meet-cute” or relish the chance to explore on your own on this
              romantic holiday. In any case, choosing a hotel that lets you do
              both is a big part of your Valentine’s solo trip. We love the chic
              Arlo Midtown Hotel for this exact reason! Have a relaxing night at
              the bar/lounge or experience the city at night — Times Square and
              Madison Square Garden are both close by.
            </p>
            <div className='w330px mxa my10 md:ml0'>
              <Button
                fullWidth
                label='Stay at Arlo Midtown'
                size='large'
                onClick={onNewYorkHotelClick}
              />
            </div>
            <p className='type-body-1 my4'>
              Since you’ll be exploring in February, warming up with some hot
              chocolate at Burdick Chocolate is a must. It just so happens that
              the famous Dominique Bakery is just a short walk away (plan on
              visiting both). Things to do in NYC are nearly endless, but a
              lifetime adventure like a helicopter tour of the city seems like a
              good addition to the shows, museums, and parks to explore. You’re
              worth it!
            </p>
            <div className='w330px mxa my10 md:ml0'>
              <Button
                fullWidth
                label='Book Helicopter Views'
                size='large'
                onClick={onNewYorkExperienceClick}
              />
            </div>
          </div>
          <div className='basis-1/2'>
            <img
              alt='NYC at sunset'
              className='w100% md:w90% ma rounded-tl-8 rounded-br-8 shadow-2 block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/4f029af6-ba2c-11ee-9858-06c4617ef884'
            />
          </div>
        </div>
      </PageLayoutContainer>
      <div className='wfull h600px md:h900px relative bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/55b4e26e-bba8-11ee-bfa9-6652013ef636)] bg-cover bg-center'>
        <div className='top0 left0 h500px md:h650px lg:h750px absolute w-full bg-gradient-to-b from-white to-50%'>
          <div className='w90% md:max-w-650px lg:max-w-750px lg:mt16 mb8 mxa bg-forest c-white rounded-tl-8 rounded-br-8 p8'>
            <p className='type-h4 c-white font-300 text-center leading-6 lg:leading-8'>
              Hopefully at least one of these Valentine&apos;s Day trips caught
              your eye! You can create a trip with these ideas (or add your
              own!) by following the button below.
            </p>
          </div>
          <div className='w250px mxa'>
            <Button
              fullWidth
              label='Create My Trip'
              size='large'
              onClick={onTripsClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const canonicalUrl =
  'https://www.travelpass.com/best-unique-valentines-day-getaways-for-solo-travelers'
const metaDescription =
  'We’ve put together the start of two very dreamy itineraries in two of our favorite destinations, just perfect for a Valentine’s Day trip for one. Each spot is charmingly romantic, whether you&apos;re looking for a quiet escape or one full of activities to keep you busy.'
const pageName = 'Best Unique Valentine’s Day Getaways For Solo Travelers'
