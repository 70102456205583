import type { ReactNode } from 'react'
import { IconButton } from '@travelpass/design-system'
import type { CardVariant } from '../Card'
import { Card, CardContent, CardImageHero } from '../Card'

export const EventCard = ({
  onClick,
  onHover,
  onSelect,
  id,
  title,
  imageUrl,
  isActive = false,
  isHovered = false,
  isSmall = false,
  children,
  variant,
}: {
  onClick: (id: string) => void
  onHover?: (eventId?: string) => void
  onSelect: (id: string) => void
  id: string
  title: string
  imageUrl: string
  isActive?: boolean
  isHovered?: boolean
  isSmall?: boolean
  children?: ReactNode
  variant?: CardVariant
}) => {
  return (
    <Card
      isActive={isActive}
      isHovered={isHovered}
      variant={variant}
      onClick={() => onClick(id)}
      onMouseOut={() => onHover?.()}
      onMouseOver={() => onHover?.(id)}
    >
      <div className='w-100px h-100px'>
        <CardImageHero imageName={title} imageUrl={imageUrl} />
      </div>
      <CardContent>
        <div className='flex h-full py-2'>
          <div
            className={`w-160px flex flex-col gap-1 pr-4 ${getNameSize({
              isSmall,
            })}`}
          >
            <h6 className='type-h6 line-clamp-1'>{title}</h6>
            {children}
          </div>
          <div className='flex items-center md:mr-3'>
            <IconButton
              color='white'
              icon='add'
              outline='round'
              size='small'
              onClick={event => {
                event.stopPropagation()
                onSelect(id)
              }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const getNameSize = ({ isSmall }: { isSmall }) =>
  isSmall ? 'md:w-205px' : 'md:w-361px'
