import { useEffect, useState } from 'react'
import { Icon } from '@travelpass/design-system'
import creditCardType from 'credit-card-type'
import isEmpty from 'lodash.isempty'
import { useWatch } from 'react-hook-form'
import { FormInput } from 'src/common/components/FormInput'
import { rules, type BookingFormFields } from '../../hooks'

const Input = FormInput<BookingFormFields>

const useDetectAmexCardNumber = () => {
  const [isAmexCard, setIsAmexCard] = useState(false)
  const number = useWatch({ name: 'number' })

  useEffect(() => {
    const cardTypes = isEmpty(number)
      ? []
      : creditCardType(number).map(card => card.niceType)

    setIsAmexCard(cardTypes.includes('American Express'))
  }, [number])

  return isAmexCard
}

export const CreditCardDetailFields = () => {
  const isAmexCard = useDetectAmexCardNumber()

  return (
    <div className='grid grid-cols-2 gap-4'>
      <div className='col-span-2 col-start-1'>
        <Input
          fullWidth
          autoComplete='cc-name'
          label='Name on Card'
          name='cardHolder'
          placeholder='Cardholder name'
          required={rules.required}
        />
      </div>
      <div className='col-span-2 col-start-1'>
        <Input
          fullWidth
          autoComplete='cc-number'
          format={!isAmexCard ? '#### #### #### ####' : '#### ###### #####'}
          label='Debit/Credit card number'
          name='number'
          placeholder='0000 0000 0000 0000'
          required={rules.required}
          rules={isAmexCard ? rules.numberAmex : rules.number}
          slotBefore={<Icon name='creditCard' />}
        />
      </div>
      <Input
        fullWidth
        isNumericString
        autoComplete='cc-exp'
        format='##/##'
        label='Expiration'
        name='expiration'
        placeholder='MM/YY'
        required={rules.required}
        rules={rules.expiration}
        type='tel'
      />
      <Input
        fullWidth
        autoComplete='cc-csc'
        format={!isAmexCard ? '###' : '####'}
        label='Security code'
        name='cvc'
        placeholder='CVC'
        required={rules.required}
        rules={isAmexCard ? rules.cvcAmex : rules.cvc}
        type='tel'
      />
    </div>
  )
}
