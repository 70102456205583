import { useState } from 'react'
import { Chip, MenuItem } from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'
import type { RoomFilters } from 'src/__generated__/graphql'
import { Refundable } from 'src/__generated__/graphql'
import { bedTypeItems } from '../HotelRooms/HotelRoomsApollo'

interface HotelRoomsSearchFormFiltersProps {
  filters: RoomFilters
  onChange(updatedFilters: RoomFilters): void
  disableOnLoading: boolean
}

export const HotelRoomsSearchFormFilters = ({
  filters,
  onChange,
  disableOnLoading,
}: HotelRoomsSearchFormFiltersProps) => {
  const { bedType, refundable } = filters ?? {}
  const [isBedTypeDrawerOpen, setIsBedTypeDrawerOpen] = useState(false)

  const onBedTypeChange = (value: RoomFilters['bedType']) => {
    if (value === bedType) return
    setIsBedTypeDrawerOpen(false)
    onChange({ bedType: value })
  }
  const onFreeCancellationClick = () => {
    const refundType = refundable === Refundable.Full ? null : Refundable.Full
    onChange({ refundable: refundType })
  }

  const bedTypeOptions = bedTypeItems.map(({ title, value }) => {
    const isHighlighted = bedType === value || (title === 'Any' && !bedType)
    return (
      <MenuItem
        key={value}
        isHighlighted={isHighlighted}
        label={title}
        value={value}
        onClick={() => onBedTypeChange(value)}
      />
    )
  })

  return (
    <div className='flex gap-2 py-4'>
      <Popover
        containerStyle={{ zIndex: '450' }}
        content={<div className='w-30 shadow-2 bg-white'>{bedTypeOptions}</div>}
        isOpen={isBedTypeDrawerOpen}
        positions={['bottom']}
        onClickOutside={() => setIsBedTypeDrawerOpen(false)}
      >
        <div>
          <Chip
            icon='arrowDropDown'
            isDisabled={disableOnLoading}
            isSelected={!!bedType}
            label='Bed Size'
            onClick={() => setIsBedTypeDrawerOpen(true)}
          />
        </div>
      </Popover>
      <Chip
        isDisabled={disableOnLoading}
        isSelected={refundable === Refundable.Full}
        label='Free Cancellation'
        onClick={onFreeCancellationClick}
      />
    </div>
  )
}
