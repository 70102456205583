import { useRef } from 'react'
import type { DateRange } from '@travelpass/design-system'
import {
  DateRangePicker,
  Input,
  useSnackbar,
  Button,
  TextArea,
} from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { Geocoder, TimeRangePicker } from 'src/common/components'
import type { GeocoderType, DatesType } from 'src/constants/user'
import { CustomBookEvent } from 'src/pages/trips/components'
import type { EventFormValuesType } from 'src/pages/trips/components/EventForm/resources'
import { TripSearchParams } from 'src/pages/trips/constants'
import {
  getTripEventsQuery,
  useGetTripDetailsQuery,
} from 'src/pages/trips/hooks'
import { geocoderLocationBiasConfig } from 'src/pages/trips/utils/geocoderUtils'
import { generateEventInput } from 'src/pages/trips/utils/mutationUtils'
import {
  formatDate,
  getDateDiff,
  getDateFromUTC,
  getTimeFromUTC,
} from 'src/utils'
import { useCreateCustomEvent } from './useCreateCustomEvent'

export const CustomAddEventForm = ({
  dateRange,
  onComplete,
  tripId,
}: {
  dateRange: DateRange
  onComplete: () => void
  tripId: string
}) => {
  const {
    control,
    formState: { isValid, errors, submitCount },
    handleSubmit,
    register,
    setValue,
  } = useForm()
  const { isLoading, tripDetailsData } = useGetTripDetailsQuery(tripId)
  const [searchParams, setSearchParams] = useSearchParams()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const { notes: notesError } = errors ?? {}
  const { createCustomEvent, loading: createLoading } = useCreateCustomEvent()
  const divRef = useRef(null)
  const {
    endDate: initialEndDate,
    startDate: initialStartDate,
    timeZone,
    tripPreference,
  } = tripDetailsData ?? {}
  const { lat, long } = tripPreference?.addresses?.[0] ?? {}
  const endDate = dayjs(getDateFromUTC(initialEndDate, timeZone))
  const startDate = dayjs(getDateFromUTC(initialStartDate, timeZone))
  const formattedEndDate = dayjs(formatDate(endDate))
  const formattedStartDate = dayjs(formatDate(startDate))

  const onSubmit = async (formValues: EventFormValuesType) => {
    const eventInput = generateEventInput({
      eventType: EventType.Miscellaneous,
      formValues,
      tripId,
      timezone: timeZone,
    })

    createCustomEvent({
      variables: {
        eventInput: {
          ...eventInput,
          isCustom: true,
        },
      },
      refetchQueries: [
        {
          query: getTripEventsQuery,
          variables: {
            tripId,
          },
        },
      ],
      onCompleted: () => {
        addSuccessSnack({ title: 'Custom Event Created' })
        const updatedActiveTabIndex =
          getDateDiff([
            formatDate(getDateFromUTC(eventInput?.startDate, timeZone)),
            formatDate(formattedStartDate),
          ]) ?? 0
        searchParams.set(
          TripSearchParams.dateTab,
          updatedActiveTabIndex.toString()
        )
        setSearchParams(searchParams, {
          replace: true,
        })
        onComplete()
      },
      onError: () => {
        addErrorSnack({ title: 'Unable to create event' })
      },
    })
  }

  if (isLoading) return null

  const defaultDates = [dateRange?.from, dateRange.to ?? dateRange.from]

  const validateLocationField = (value: GeocoderType) => {
    if (value?.placeName === '') {
      return 'Location is required'
    }
  }

  const defaultBookedValue = false
  const defaultToogleValue = status !== EventStatus.Na

  const onDateSelect = onChangeHandler => {
    return (range: { from?: Dayjs; to?: Dayjs }) => {
      const updatedDates: DatesType = [range?.from, range?.to]
      onChangeHandler(updatedDates)
    }
  }

  const locationBias = geocoderLocationBiasConfig(lat, long)

  return (
    <form
      noValidate
      className='flex h-[calc(100%-232px)] flex-col'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name='customBooked'
        render={({ field: { onChange: handleOnChangeCheckbox } }) => (
          <div className='px-1 pb-2'>
            <CustomBookEvent
              defaultCheckboxValue={defaultBookedValue}
              defaultToggleValue={defaultToogleValue}
              onChangeCheckbox={handleOnChangeCheckbox}
            />
          </div>
        )}
      />
      <div className='flex flex-1 flex-col gap-4 pb-4 pr-2' ref={divRef}>
        <div>
          <Input fullWidth label='Name' type='text' {...register('name')} />
        </div>
        <Controller
          control={control}
          name='startLocation'
          render={({
            field: { onChange: onGeocoderChange, value: destination },
            fieldState: { error: destinationError },
          }) => (
            <Geocoder
              required
              config={locationBias}
              errorText={destinationError && 'Location is required'}
              focusOnInput={false}
              geocoder={destination ?? location}
              isInvalid={!!destinationError}
              label='Start Location'
              onResult={onGeocoderChange}
            />
          )}
          rules={{
            required: true,
            validate: { validateLocationField },
          }}
        />
        <Controller
          control={control}
          name='endLocation'
          render={({
            field: { onChange: onGeocoderChange, value: destination },
            fieldState: { error: destinationError },
          }) => (
            <Geocoder
              required
              config={locationBias}
              errorText={destinationError && 'Location is required'}
              focusOnInput={false}
              geocoder={destination ?? location}
              isInvalid={!!destinationError}
              label='End Location'
              onResult={onGeocoderChange}
            />
          )}
          rules={{
            required: true,
            validate: { validateLocationField },
          }}
        />
        <div>
          <Controller
            control={control}
            defaultValue={defaultDates}
            name='dates'
            render={({ field: { onChange, value: dates } }) => (
              <DateRangePicker
                canSelectSameDay
                fromDate={formattedStartDate?.toDate()}
                label='Start Date – End Date'
                numberOfMonths={1}
                selected={{
                  from: dates[0],
                  to: dates[1] ?? null,
                }}
                toDate={formattedEndDate?.toDate()}
                onSelect={onDateSelect(onChange)}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          defaultValue={{
            timeRange: {
              startTime: getTimeFromUTC({
                utcDate: tripDetailsData?.startDate,
                timezone: timeZone,
              }),
              endTime: getTimeFromUTC({
                utcDate: tripDetailsData?.endDate,
                timezone: timeZone,
              }),
            },
          }}
          name='timeRange'
          render={({
            field: { onChange: onTimeRangeChange, value: timeRange },
          }) => (
            <TimeRangePicker
              timeRange={timeRange}
              onChange={onTimeRangeChange}
            />
          )}
        />
        <div className='h-auto'>
          <TextArea
            errorText={notesError && 'Max 3000 characters'}
            helperText='Max 3000 characters'
            label='Notes'
            {...register('notes', { maxLength: 3000 })}
          />
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-2 p-2'>
        <div>
          <Button
            aria-label='Add custom event to trip'
            isDisabled={createLoading}
            size='large'
            type='submit'
          >
            Add
          </Button>
        </div>
        {Object.keys(errors).length > 0 && submitCount > 0 && (
          <span className='c-redMedium pt-6px'>Some fields are required!</span>
        )}
      </div>
    </form>
  )
}
