import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { ReviewBadge } from 'src/common/components'
import { getProductTypesAsObject } from 'src/utils'
import { ExploreCard } from './ExploreCard'
import type { ExploreItem } from '../../constants'
import { ExploreSearchParams } from '../../constants'

interface TripDetailsExploreCardProps {
  hoveredEventId?: string
  tripDetailsExploreCardData: ExploreItem
  onClick?(): void
  onMouseOut?(): void
  onMouseOver?(): void
}

export const TripDetailsExploreCard = ({
  hoveredEventId,
  tripDetailsExploreCardData,
  onClick,
  onMouseOut,
  onMouseOver,
}: TripDetailsExploreCardProps) => {
  const [searchParams] = useSearchParams()
  const [hasImageError, setHasImageError] = useState(false)
  const { isExperience } = getProductTypesAsObject(
    searchParams.get(ExploreSearchParams.productType) ?? ''
  )
  const { id, image, isFavorited, reviewAverage, reviewTotal, title } =
    tripDetailsExploreCardData ?? {}
  const showReviews = !!reviewAverage && !!reviewTotal
  const showCard = !!id && !!title
  const isActive = searchParams.get(ExploreSearchParams.activeExploreId) === id

  const constructedImage = () => {
    if (image && !hasImageError)
      return (
        <img
          alt={`${title} hero`}
          className='rounded-14px h-full w-full object-cover'
          src={image}
          onError={() => setHasImageError(true)}
        />
      )

    return (
      <div className='w-100px'>
        <EmptyState name='noImages' />
      </div>
    )
  }

  return showCard ? (
    <>
      <ExploreCard
        isHovered={id === hoveredEventId}
        onClick={() => onClick?.()}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      >
        <div
          className={classNames(
            'b-solid b-2 rounded-14px transition-colors-150 pointer-events-none absolute bottom-0 left-0 right-0 top-0 ease-in',
            {
              'b-forest-light': isActive,
              'b-transparent': !isActive,
            }
          )}
        />
        <section className='flex flex-col gap-3'>
          <div className='h-35 flex flex-col items-center justify-center md:h-28'>
            {constructedImage()}
          </div>
          <header className='flex min-h-16 flex-col gap-1'>
            <h3 className='type-h5-mobile line-clamp-2 min-h-9'>{title}</h3>
            {showReviews && (
              <ReviewBadge
                customerReviewScore={reviewAverage}
                maxScore={isExperience ? 5 : 10}
                size='small'
                totalCustomerReviews={reviewTotal}
                variant='text'
              />
            )}
          </header>
        </section>
      </ExploreCard>
    </>
  ) : null
}
