import type { ReactNode } from 'react'

interface HotelResultsFiltersSectionWithTitleProps {
  children: ReactNode
  title: string
}

export const HotelResultsFiltersSectionWithTitle = ({
  children,
  title,
}: HotelResultsFiltersSectionWithTitleProps) =>
  !!title && (
    <section className='space-y-4'>
      <header>
        <h3 className='color-grey-900 type-h3-mobile lg:type-body-1-medium-desktop'>
          {title}
        </h3>
      </header>
      <div>{children}</div>
    </section>
  )
