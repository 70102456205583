import { Fragment } from 'react'
import { useScreenQuery } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { HotelResultsListContainer } from './HotelResultsListContainer'
import { HotelResultsListHeader } from './HotelResultsListHeader'
import { HotelResultsListNav } from './HotelResultsListNav'
import { HotelResultsEmpty } from '../HotelResultsEmpty'
import { HotelResultsCard, HotelResultsCardRow } from '../common'
import { HotelResultsListView } from '../hotelResultsConstants'
import type {
  GetHotelResultsCardUrl,
  HotelResultsData,
} from '../hotelResultsTypes'
import {
  getHotelResultsCardUrl,
  getHotelResultsListViewFromSearchParams,
} from '../hotelResultsUtils'

interface HotelResultsListProps {
  filteredIds?: string[]
  hasFeatured?: boolean
  hotelResultsData: HotelResultsData
  onCardMouseOut?(): void
  onCardMouseOver?(updatedId: string): void
}

export const HotelResultsList = ({
  filteredIds = [],
  hasFeatured = false,
  hotelResultsData,
  onCardMouseOut = () => {},
  onCardMouseOver = () => {},
}: HotelResultsListProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [searchParams] = useSearchParams()
  const listView = getHotelResultsListViewFromSearchParams({
    isMobile: isMobileScreen,
    searchParams,
  })

  const onCardClick = (
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  if (isEmpty(hotelResultsData)) return

  if (isEmpty(filteredIds)) return <HotelResultsEmpty variant='filteredIds' />

  return (
    <>
      <AddToGuideModalListener />
      <AddToCollectionsModalListener />
      <section className='lg:space-y-1'>
        <HotelResultsListHeader>
          <div>
            {hasFeatured && (
              <h2 className='type-h6'>Other Recommended Hotels</h2>
            )}
          </div>
          <HotelResultsListNav />
        </HotelResultsListHeader>
        <HotelResultsListContainer listView={listView}>
          {hotelResultsData.map(
            hotel =>
              filteredIds.includes(hotel?.id) && (
                <Fragment key={hotel.id}>
                  {listView === HotelResultsListView.list ? (
                    <HotelResultsCardRow
                      hotelResultsCardData={hotel}
                      onClick={() => onCardClick(hotel)}
                      onMouseOut={() => onCardMouseOut()}
                      onMouseOver={() => onCardMouseOver(hotel.id)}
                    />
                  ) : (
                    <HotelResultsCard
                      hotelResultsCardData={hotel}
                      onClick={() => onCardClick(hotel)}
                      onMouseOut={() => onCardMouseOut()}
                      onMouseOver={() => onCardMouseOver(hotel.id)}
                    />
                  )}
                </Fragment>
              )
          )}
        </HotelResultsListContainer>
      </section>
    </>
  )
}
