import { Icon, Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import { DateSummary } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { initialRooms } from 'src/constants/user'
import { BookingSidebarRoomImage } from './BookingSidebarRoomImage'
import { nightCount } from './bookingSidebarUtils'

interface BookingSidebarRoomProps {
  hotelData: BookingHotelDetailsQuery['lodging']
  rateData: BookingValidateRateQuery
  bookingRateLoading: boolean
}

export const BookingSidebarRoom = ({
  rateData,
  hotelData,
  bookingRateLoading,
}: BookingSidebarRoomProps) => {
  const { city, name, state, checkin, checkout, imageLinks } = { ...hotelData }
  const checkoutV2 = useFlag('checkoutV2')
  const [searchParams] = useSearchParams()
  const { images, name: roomName } = bookingRateLoading
    ? { images: [], name: '' }
    : rateData?.validatedRate?.room

  const showImage =
    bookingRateLoading || !isEmpty(checkoutV2 ? imageLinks : images)
  const guests = parseInt(searchParams.get('guests') || '0', 10)
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const nights = nightCount(searchParams)

  const getRoomName = () => {
    if (bookingRateLoading)
      return (
        <div className='h-18px md:h-20px'>
          <Skeleton variant='rounded' />
        </div>
      )

    return <h6 className='type-h6 truncate pr-5'>{roomName}</h6>
  }

  return (
    <div className='space-y-4 lg:space-y-6'>
      {checkoutV2 ? (
        <p className='type-overline font-500'>Room Details</p>
      ) : (
        <h4 className='type-h4'>Selected Room:</h4>
      )}

      <div className='flex flex-row items-center gap-4 lg:gap-6'>
        <div className={classNames({ 'hidden md:block': checkoutV2 })}>
          <BookingSidebarRoomImage
            bookingRateLoading={bookingRateLoading}
            city={city}
            images={checkoutV2 ? imageLinks : images}
            name={name}
            state={state}
          />
        </div>
        <div
          className={classNames('flex-grow-1', {
            'max-w-full': !showImage || checkoutV2,
            'sm:max-w-[calc(100%-137px)]': !checkoutV2,
            'md:max-w-[calc(100%-168px)] lg:max-w-[calc(100%-205px)]':
              showImage,
          })}
        >
          <div className='space-y-1 lg:space-y-2'>
            {checkoutV2 && (
              <DateSummary
                arrival={arrival}
                checkin={checkin}
                checkout={checkout}
                departure={departure}
              />
            )}
            {!isMobile && getRoomName()}
            {!checkoutV2 && (
              <>
                <p className='type-body-1 flex flex-row items-center gap-2'>
                  <Icon name='personOutline' size='small' /> {guests} Guest
                  {guests === 1 ? '' : 's'}
                </p>
                <p className='type-body-1 flex flex-row items-center gap-2'>
                  <Icon name='nightlight' size='small' /> {nights} Night
                  {nights === 1 ? '' : 's'}
                </p>
                <p className='type-body-1 inline-flex flex-row items-center gap-2'>
                  <Icon name='bed' size='small' /> {initialRooms} Room
                  {initialRooms === 1 ? '' : 's'}
                </p>
              </>
            )}
            {checkoutV2 && (
              <>
                <p className='type-body-1 inline-flex flex-row items-center gap-2'>
                  <Icon name='personOutline' /> {guests} Guest
                  {guests === 1 ? ', ' : 's, '}
                  {initialRooms} Room
                  {initialRooms === 1 ? '' : 's'}
                </p>
                <p className='type-body-1'>
                  <span className='inline-flex flex-row items-center gap-2'>
                    <Icon name='nightlight' /> {nights} Night
                    {nights === 1 ? '' : 's'}
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {isMobile && getRoomName()}
    </div>
  )
}
