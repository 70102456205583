import dayjs from 'dayjs'
import type { ExperienceLanguageGuide } from 'src/__generated__/graphql'
import { useFormContext } from './useExperienceBookingForm'
import { useExperienceHold } from './useExperienceHold'

export const BookingQuestionsSummary = ({
  languageGuides,
}: {
  languageGuides: ExperienceLanguageGuide[]
}) => {
  const { data } = useExperienceHold()
  const questionLabelLookup = Object.fromEntries(
    (data?.getExperienceHold?.product?.bookingQuestions ?? []).map(question => [
      question.id,
      question.label,
    ])
  )

  const { getValues } = useFormContext()
  const booking = getValues('answers.booking')
  const transferArrivalMode = getValues('answers.transferArrivalMode')
  const transferDepartureMode = getValues('answers.transferDepartureMode')
  const languageGuide = getValues('languageGuide')

  const locations =
    data?.getExperienceHold?.product?.logistics?.travelerPickup?.locations ?? []

  const getLocationName = (id: string) => {
    const foundLocation = locations.find(
      ({ location }) => location?.reference === id
    )
    return foundLocation?.location?.name || id
  }

  const [groupAnswers, ...travelerAnswers] = booking // group answers occupy the first index (for simplicity) but should be displayed last
  const answers = []
    .concat(groupAnswers, transferArrivalMode, transferDepartureMode)
    .flat()
    .filter(
      bqa => Boolean(bqa?.answer) /* filter out questions that have no answer */
    )

  const travelerNames = travelerAnswers
    .map(answers =>
      answers.filter(answer => {
        return (
          answer?.question === 'FULL_NAMES_FIRST' ||
          answer?.question === 'FULL_NAMES_LAST'
        )
      })
    )
    .map(
      ([first_name, last_name]) =>
        `${first_name?.answer || ''} ${last_name?.answer || ''}`
    )

  const foundLanguageGuide = languageGuides?.find(
    guide =>
      guide.language === languageGuide?.language &&
      guide.type === languageGuide?.type
  )

  const hasGroupAnswers = groupAnswers?.length > 0 || foundLanguageGuide

  return (
    <div className='space-y-4'>
      {travelerNames.length > 0 && (
        <div className='b-0 border-b-1 b-grey400 b-solid pb-4 last:border-b-0'>
          <h3 className='c-grey-800 type-h5 my-1'>Travelers</h3>
          {travelerNames.map((name, index) => (
            <p key={name + index} className='c-grey-800 type-body-1 m-0'>
              {name}
            </p>
          ))}
        </div>
      )}
      {hasGroupAnswers && (
        <>
          <h3 className='c-grey-800 type-h5 my-1'>Group Options</h3>
          {answers.map(({ answer, question, travelerNum, unit }) => {
            const label = questionLabelLookup[question]
            let display = answer
            if (unit && unit !== 'FREETEXT') display = display + ' ' + unit
            if (unit === 'LOCATION_REFERENCE') display = getLocationName(answer)
            if (question === 'TRANSFER_DEPARTURE_DATE')
              display = dayjs(answer, 'MMDDYYYY').format('MM/DD/YYYY')

            return (
              <p key={question + travelerNum} className='m-0'>
                <span className='type-h6 c-grey-800'>{label}:&nbsp;</span>
                <span className='type-body-1 c-grey-800'>{display}</span>
              </p>
            )
          })}
          {foundLanguageGuide && (
            <p className='m-0'>
              <span className='type-h6 c-grey-800'>Language:&nbsp;</span>
              <span className='type-body-1 c-grey-800'>
                {foundLanguageGuide.languageName} - {foundLanguageGuide.type}
              </span>
            </p>
          )}
        </>
      )}
    </div>
  )
}
