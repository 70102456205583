import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_CURRENT_USER_PROFILE_ACCOUNT_HANDLE_AND_AVATAR = gql(`
  query CurrentUserProfileAccountHandleAndAvatar {
    currentUser {
      id
      userProfile {
        id
        accountHandle
        displayName
        profileImageUrl
        profileImageSource
      }
    }
  } 
`)

export const useGetCurrentUserProfileAccountHandleAndAvatar = (skip = false) =>
  useQuery(GET_CURRENT_USER_PROFILE_ACCOUNT_HANDLE_AND_AVATAR, {
    skip,
  })
