import { Helmet } from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  MyTripsAbout,
  MyTripsAnon,
  MyTripsBookings,
  MyTripsList,
  MyTripsCreate,
} from './components'

export const MyTrips = () => {
  const { isAnonymous } = useFirebaseUser()

  if (isAnonymous) return <MyTripsAnon />

  return (
    <>
      <Helmet pageName='My Trips' />
      <div className='max-w-1440px bg-warmGrey mx-auto my-0 w-full'>
        <MyTripsCreate />
        <MyTripsList />
        <MyTripsAbout />
        <MyTripsBookings />
      </div>
    </>
  )
}
