import './stepTransition.css'

export const StepTransition = ({
  active,
  summary,
  edit,
}: {
  active: boolean
  summary: React.ReactNode
  edit: React.ReactNode
}) => {
  return (
    <div className='transition-height relative overflow-y-hidden'>
      <div aria-expanded={!active} className='step-transition'>
        {summary}
      </div>
      <div aria-expanded={active} className='step-transition'>
        {edit}
      </div>
    </div>
  )
}
