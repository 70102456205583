import { geocoderLocationBiasConfig } from 'src/pages/trips/utils/geocoderUtils'
import { useProductIdContext } from './ProductIdContext'
import { useGetProductStartLocation } from './useGetProductStartLocation'

export const useGetProductLocationBiasedGeocoderConfig = () => {
  const productId = useProductIdContext()
  const { data: productStartLocation } = useGetProductStartLocation(productId)
  const locationBiasedConfig =
    productStartLocation &&
    geocoderLocationBiasConfig(
      productStartLocation.latitude,
      productStartLocation.longitude
    )

  return locationBiasedConfig
}
