import type { GenericAddress } from 'src/__generated__/graphql'
import { defaultLocationImage } from 'src/constants'
import { getDateFromUTC } from 'src/utils'

// TOTO test me
const getDestinationImage = ({
  country: countryRaw,
  state: stateRaw,
}: {
  country?: string
  state?: string
}) => {
  const country = countryRaw?.toLowerCase() ?? null
  if (!countryRaw && !stateRaw) {
    return defaultLocationImage
  } else if (country === 'mexico') {
    return 'https://static.travelpass.com/assets/trip-state-pics/mexico.webp'
  } else if (country === 'canada') {
    return 'https://static.travelpass.com/assets/trip-state-pics/canada.webp'
  } else {
    const state = stateRaw?.toLowerCase() ?? null
    switch (state) {
      case 'alabama':
        // Image for Alabama
        return 'https://static.travelpass.com/assets/trip-state-pics/alabama.webp'
      case 'alaska':
        // Image for Alaska
        return 'https://static.travelpass.com/assets/trip-state-pics/alaska.webp'
      case 'arizona':
        // Image for Arizona
        return 'https://static.travelpass.com/assets/trip-state-pics/arizona.webp'
      case 'arkansas':
        // Image for Arkansas
        return 'https://static.travelpass.com/assets/trip-state-pics/arkansas.webp'
      case 'california':
        // Image for California
        return 'https://static.travelpass.com/assets/trip-state-pics/california.webp'
      case 'colorado':
        // Image for Colorado
        return 'https://static.travelpass.com/assets/trip-state-pics/colorado.webp'
      case 'connecticut':
        // Image for Connecticut
        return 'https://static.travelpass.com/assets/trip-state-pics/connecticut.webp'
      case 'delaware':
        // Image for Delaware
        return 'https://static.travelpass.com/assets/trip-state-pics/delaware.webp'
      case 'florida':
        // Image for Florida
        return 'https://static.travelpass.com/assets/trip-state-pics/florida.webp'
      case 'georgia':
        // Image for Georgia
        return 'https://static.travelpass.com/assets/trip-state-pics/georgia.webp'
      case 'hawaii':
        // Image for Hawaii
        return 'https://static.travelpass.com/assets/trip-state-pics/hawaii.webp'
      case 'idaho':
        // Image for Idaho
        return 'https://static.travelpass.com/assets/trip-state-pics/idaho.webp'
      case 'illinois':
        // Image for Illinois
        return 'https://static.travelpass.com/assets/trip-state-pics/illinois.webp'
      case 'indiana':
        // Image for Indiana
        return 'https://static.travelpass.com/assets/trip-state-pics/indiana.webp'
      case 'iowa':
        // Image for Iowa
        return 'https://static.travelpass.com/assets/trip-state-pics/iowa.webp'
      case 'kansas':
        // Image for Kansas
        return 'https://static.travelpass.com/assets/trip-state-pics/kansas.webp'
      case 'kentucky':
        // Image for Kentucky
        return 'https://static.travelpass.com/assets/trip-state-pics/kentucky.webp'
      case 'louisiana':
        // Image for Louisiana
        return 'https://static.travelpass.com/assets/trip-state-pics/louisiana.webp'
      case 'maine':
        // Image for Maine
        return 'https://static.travelpass.com/assets/trip-state-pics/maine.webp'
      case 'maryland':
        // Image for Maryland
        return 'https://static.travelpass.com/assets/trip-state-pics/maryland.webp'
      case 'massachusetts':
        // Image for Massachusetts
        return 'https://static.travelpass.com/assets/trip-state-pics/massachusetts.webp'
      case 'michigan':
        // Image for Michigan
        return 'https://static.travelpass.com/assets/trip-state-pics/michigan.webp'
      case 'minnesota':
        // Image for Minnesota
        return 'https://static.travelpass.com/assets/trip-state-pics/minnesota.webp'
      case 'mississippi':
        // Image for Mississippi
        return 'https://static.travelpass.com/assets/trip-state-pics/mississippi.webp'
      case 'missouri':
        // Image for Missouri
        return 'https://static.travelpass.com/assets/trip-state-pics/missouri.webp'
      case 'montana':
        // Image for Montana
        return 'https://static.travelpass.com/assets/trip-state-pics/montana.webp'
      case 'nebraska':
        // Image for Nebraska
        return 'https://static.travelpass.com/assets/trip-state-pics/nebraska.webp'
      case 'nevada':
        // Image for Nevada
        return 'https://static.travelpass.com/assets/trip-state-pics/nevada.webp'
      case 'new hampshire':
        // Image for New Hampshire
        return 'https://static.travelpass.com/assets/trip-state-pics/new-hampshire.webp'
      case 'new jersey':
        // Image for New Jersey
        return 'https://static.travelpass.com/assets/trip-state-pics/new-jersey.webp'
      case 'new mexico':
        // Image for New Mexico
        return 'https://static.travelpass.com/assets/trip-state-pics/new-mexico.webp'
      case 'new york':
        // Image for New York
        return 'https://static.travelpass.com/assets/trip-state-pics/new-york.webp'
      case 'north carolina':
        // Image for North Carolina
        return 'https://static.travelpass.com/assets/trip-state-pics/north-carolina.webp'
      case 'north dakota':
        // Image for North Dakota
        return 'https://static.travelpass.com/assets/trip-state-pics/north-dakota.webp'
      case 'ohio':
        // Image for Ohio
        return 'https://static.travelpass.com/assets/trip-state-pics/ohio.webp'
      case 'oklahoma':
        // Image for Oklahoma
        return 'https://static.travelpass.com/assets/trip-state-pics/oklahoma.webp'
      case 'oregon':
        // Image for Oregon
        return 'https://static.travelpass.com/assets/trip-state-pics/oregon.webp'
      case 'pennsylvania':
        // Image for Pennsylvania
        return 'https://static.travelpass.com/assets/trip-state-pics/pennsylvania.webp'
      case 'rhode island':
        // Image for Rhode Island
        return 'https://static.travelpass.com/assets/trip-state-pics/rhode-island.webp'
      case 'south carolina':
        // Image for South Carolina
        return 'https://static.travelpass.com/assets/trip-state-pics/south-carolina.webp'
      case 'south dakota':
        // Image for South Dakota
        return 'https://static.travelpass.com/assets/trip-state-pics/south-dakota.webp'
      case 'tennessee':
        // Image for Tennessee
        return 'https://static.travelpass.com/assets/trip-state-pics/tennessee.webp'
      case 'texas':
        // Image for Texas
        return 'https://static.travelpass.com/assets/trip-state-pics/texas.webp'
      case 'utah':
        // Image for Utah
        return 'https://static.travelpass.com/assets/trip-state-pics/utah.webp'
      case 'vermont':
        // Image for Vermont
        return 'https://static.travelpass.com/assets/trip-state-pics/vermont.webp'
      case 'virginia':
        // Image for Virginia
        return 'https://static.travelpass.com/assets/trip-state-pics/virginia.webp'
      case 'washington':
        // Image for Washington
        return 'https://static.travelpass.com/assets/trip-state-pics/washington.webp'
      case 'west virginia':
        // Image for West Virginia
        return 'https://static.travelpass.com/assets/trip-state-pics/west-virginia.webp'
      case 'wisconsin':
        // Image for Wisconsin
        return 'https://static.travelpass.com/assets/trip-state-pics/wisconsin.webp'
      case 'wyoming':
        // Image for Wyoming
        return 'https://static.travelpass.com/assets/trip-state-pics/wyoming.webp'
      default:
        return ''
    }
  }
}

// TOTO test me
// displays state if city not found, supports mixed destinations
const generateDestinationsString = (
  addresses: (GenericAddress | undefined | null)[] | null | undefined
) => {
  const validAddresses =
    addresses?.filter(Boolean).map(address => ({
      city: address?.city ?? undefined,
      state: address?.state ?? undefined,
    })) ?? []

  return validAddresses?.reduce((acc, address, i) => {
    const { city = null, state = null } = address ?? {}
    let destStr

    if (city) destStr = city
    if (!city && state) destStr = state

    if (destStr) {
      if (acc !== '') {
        if (i === validAddresses?.length - 1) {
          return [acc, destStr]?.join(', and ')
        } else {
          return [acc, destStr]?.join(', ')
        }
      }

      return destStr
    }

    return acc
  }, '')
}
// TOTO test me
const generateDateString = ({
  startDate,
  endDate,
  format,
  timezone,
}: {
  startDate: string
  endDate: string
  format: string
  timezone: string
}) => {
  const arrival = startDate
    ? getDateFromUTC(startDate, timezone).format(format)
    : null
  const departure = endDate
    ? getDateFromUTC(endDate, timezone).format(format)
    : null
  return arrival && departure
    ? arrival === departure
      ? arrival
      : `${arrival} - ${departure}`
    : ''
}

export { generateDateString, getDestinationImage, generateDestinationsString }
