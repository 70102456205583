import type { GeocoderType } from 'src/constants/user'
import { constructAddressInput } from '../../utils'

const getTripNameFromGeocoder = ({
  geocoder,
  currentNameValue,
}: {
  geocoder: GeocoderType
  currentNameValue: string | null
}) => {
  const { city, state, country } = constructAddressInput(geocoder)

  if (!currentNameValue) {
    if (city) {
      return city
    } else if (state) {
      return state
    } else if (country) {
      return country
    }
  }

  return null
}

const validateGeocoder = (geocoder?: GeocoderType | null) => {
  const latitude = geocoder?.center?.[0] ?? null
  const longitude = geocoder?.center?.[1] ?? null
  return !!latitude && !!longitude
}

export { getTripNameFromGeocoder, validateGeocoder }
