import { Link } from 'react-router-dom'

const destinationLinks: { name: string; to: string }[] = [
  { name: 'Las Vegas', to: '/destinations/nv/las-vegas' },
  { name: 'Myrtle Beach', to: '/destinations/sc/myrtle-beach' },
  { name: 'Miami', to: '/destinations/fl/miami' },
  { name: 'San Diego', to: '/destinations/ca/san-diego' },
  { name: 'Nashville', to: '/destinations/tn/nashville' },
  { name: 'Chicago', to: '/destinations/il/chicago' },
  { name: 'New Orleans', to: '/destinations/la/new-orleans' },
  { name: 'Boston', to: '/destinations/ma/boston' },
  { name: 'Knoxville', to: '/destinations/tn/knoxville' },
  { name: 'Orlando', to: '/destinations/fl/orlando' },
  { name: 'San Antonio', to: '/destinations/tx/san-antonio' },
  { name: 'Fort Lauderdale', to: '/destinations/fl/fort-lauderdale' },
  { name: 'San Francisco', to: '/destinations/ca/san-francisco' },
  { name: 'Seattle', to: '/destinations/wa/seattle' },
  { name: 'Austin', to: '/destinations/tx/austin' },
  { name: 'Washington DC', to: '/destinations/dc/washington-dc' },
  { name: 'Denver', to: '/destinations/co/denver' },
  { name: 'Los Angeles', to: '/destinations/ca/los-angeles' },
  { name: 'New York City', to: '/destinations/ny/new-york-city' },
  { name: 'Philadelphia', to: '/destinations/pa/philadelphia' },
]

export const BlackFridayDestinations = () => {
  return (
    <section className={blackFridayDestinationsContainer}>
      <img
        alt='friends'
        className='sm:mt--77px rounded-tl-6 rounded-br-6 sm:h-334px sm:w-343px md:h-368px md:w-378px lg:h-135 lg:w-138.5 sm:self-center md:ml-10 md:mt-0'
        src='https://static.travelpass.com/assets/homev2-page/destinations.webp'
      />
      <div className='md:pl-12.5 flex h-full flex-col items-center justify-center gap-10 md:gap-11 md:pt-10 lg:pt-0'>
        <div>
          <h1 className='type-h1 lg:w-107 c-forestDark w-80'>
            Black Friday Deals On Popular Destinations
          </h1>
          <div className='h-.5 bg-valley my-6 w-16' />
          <ul className='lg:w-510px m-0 grid list-none gap-4 p-0 sm:grid-cols-3 lg:grid-cols-4'>
            {destinationLinks.map(({ name, to }) => (
              <li key={name}>
                <Link
                  className='decoration-none type-link c-forestDark b-b-1 b-b-solid b-valley b-none hover:c-newForest focus:outline-offset-3 focus:outline-valley [&>*]:hover:b-newForest! box-border h-[22px] w-fit cursor-pointer bg-transparent p-0 pb-1 transition-all duration-200 focus:outline-1 lg:h-6'
                  to={to}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

const blackFridayDestinationsContainer =
  'flex justify-center sm:flex-col md:flex-row bg-warmGrey w-full sm:h-693px md:h-447px lg:h-128.5 mt-0 md:mt--45.5'
