import { useRef } from 'react'
import { Icon, StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import type { Hotel } from 'src/__generated__/graphql'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { CollectedItemTip, CollectionDetailsMenu } from 'src/common/components'

import {
  constructPopularHotelAmenities,
  generateHotelDetailsUrl,
  getAdultGuests,
  getKidGuests,
} from 'src/utils'
import { getCollectionHotelCardAddress } from './collectionUtils'

interface CollectionHotelCardProps {
  hotel: Hotel
  onRemove: Function
  hotelId: number
}

export const CollectionHotelCard = ({
  hotel,
  hotelId,
  onRemove,
}: CollectionHotelCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const { images, name, city, state, stateCode, amenities } = hotel

  const handleOnRemove = () => {
    onRemove({
      item: hotel,
      type: CollectionItemIdType.Hotel,
    })
  }

  return (
    <div
      className={classNames(
        'rounded-2 shadow-2 block hover:cursor-pointer',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group'
        /** @desc fallback styling if coverImg fails to load */
      )}
      role='link'
      tabIndex={0}
      onClick={() => anchorRef?.current?.click?.()}
    >
      <div className='of-hidden rounded-2 h-50 relative'>
        <img
          alt=''
          className='z--1 absolute inset-0 h-full w-full object-cover transition-transform duration-200 ease-in-out group-focus-within:scale-105 group-hover:scale-105'
          draggable={false}
          src={images?.[0]?.links?.[0]?.providerHref}
        />
        <div className='z-20 flex justify-between pl-4 pr-2 pt-2.5'>
          <CollectedItemTip label='Hotel' />
          <StopPropagation>
            <CollectionDetailsMenu
              item={{
                name,
                id: hotelId.toString(),
                type: CollectionItemIdType.Hotel,
              }}
              onRemove={handleOnRemove}
            />
          </StopPropagation>
        </div>
      </div>
      <div className='space-y-3 p-4'>
        <div className='space-y-3'>
          <a
            className='decoration-none'
            href={generateHotelDetailsUrl({
              adults: getAdultGuests(),
              city: city,
              kids: getKidGuests(),
              id: hotelId,
              name: name,
              state: state,
              stateAbbreviation: stateCode,
            })}
            ref={anchorRef}
            rel='noreferrer'
            target='_blank'
          >
            <h2 className='type-h5 line-clamp-2'>{name}</h2>
          </a>
          <div className='line-clamp-1 flex flex-row items-center gap-1'>
            <div className='color-orange'>
              <Icon name='placeOutline' size='small' />
            </div>
            <span className='type-small-text color-black'>
              {getCollectionHotelCardAddress(hotel)}
            </span>
          </div>
          <div className='flex gap-2'>
            {constructPopularHotelAmenities(amenities)
              ?.slice(0, 2)
              .map(({ icon, label }, index) => (
                <div
                  key={index}
                  className='c-grey-800 type-small-text-desktop line-clamp-1 flex items-center gap-1'
                >
                  <Icon name={icon} size='small' />
                  <span className='truncate'>{label}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
