import { Skeleton } from '@travelpass/design-system'
import { DashboardFeedCardLoading } from './DashboardFeedCard/DashboardFeedCardLoading'

interface DashboardFeedLoadingProps {
  isDashboardAllFeedEnabled: boolean
}

/** @todo add dropdown */
export const DashboardFeedLoading = ({
  isDashboardAllFeedEnabled,
}: DashboardFeedLoadingProps) => (
  <div className='space-y-4'>
    {isDashboardAllFeedEnabled && (
      <div className='max-w-50 h-12'>
        <Skeleton variant='rounded' />
      </div>
    )}
    <DashboardFeedCardLoading />
    <DashboardFeedCardLoading />
    <DashboardFeedCardLoading />
  </div>
)
