import type { MouseEventHandler } from 'react'
import { Checkbox } from '@travelpass/design-system'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ResultsFiltersSection } from 'src/common/components'
import { constructTimeOfDay, updatedTimeOfDay } from '../common/utils'

interface ResultsFiltersTimeOfDayProps {
  filters: ExperienceFilters
  onChange(updatedFilters: ExperienceFilters): void
}

export const ResultsFiltersTimeOfDay = ({
  filters,
  onChange,
}: ResultsFiltersTimeOfDayProps) => {
  const { timeOfDay } = filters ?? {}
  const constructedTimeOfDay = constructTimeOfDay(filters)

  const onTimeOfDayChange: MouseEventHandler<HTMLInputElement> = event => {
    const { id, checked } = event?.currentTarget ?? {}
    onChange({
      ...filters,
      timeOfDay: updatedTimeOfDay({ id, checked, timeOfDay }),
    })
  }

  return (
    <ResultsFiltersSection title='Time of Day'>
      <div className={resultsFiltersTimeOfDayCss}>
        {constructedTimeOfDay.map(({ label, id, isChecked }) => (
          <div key={id}>
            <Checkbox
              id={id}
              isChecked={!!isChecked}
              label={label}
              onClick={onTimeOfDayChange}
            />
          </div>
        ))}
      </div>
    </ResultsFiltersSection>
  )
}

const resultsFiltersTimeOfDayCss =
  'grid grid-cols-1 grid-flow-dense gap-y-2 gap-x-0 md:gap-x-4 md:grid-cols-2'
