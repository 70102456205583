import { useEffect, useState } from 'react'
import { MarkerWrapper } from 'src/common/components/Map'
import { getGeocoderLatitude, getGeocoderLongitude, getPrice } from 'src/utils'
import { HotelResultsMapMarkerIcon } from './HotelResultsMapMarkerIcon'
import { HotelResultsMapMarkerPopup } from './HotelResultsMapMarkerPopup'
import type { MarkerIconVariant } from './hotelResultsMapTypes'
import type {
  GetHotelResultsCardUrl,
  HotelResultsItemData,
} from '../hotelResultsTypes'

interface HotelResultsMapMarkerProps {
  activeId?: string
  hoverId?: string
  hotelResultsMapMarkerData: HotelResultsItemData
  isFeatured?: boolean
  onActiveIdChange?(updatedActiveId: string): void
  onPopupClick?(
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ): void
  visitedIds?: Record<string, number>
}

export const HotelResultsMapMarker = ({
  activeId,
  hotelResultsMapMarkerData,
  hoverId,
  isFeatured = false,
  onActiveIdChange = () => {},
  onPopupClick = () => {},
  visitedIds = {},
}: HotelResultsMapMarkerProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [variant, setVariant] = useState<MarkerIconVariant>('default')
  const {
    customerReviewScore,
    id,
    hotelAddress,
    images,
    name,
    nightlyAverage,
    totalCustomerReviews,
  } = hotelResultsMapMarkerData ?? {}
  const { latitude: initialLatitude, longitude: initialLongitude } =
    hotelAddress ?? {}
  const isActive = activeId === id
  const isHovering = hoverId === id
  const isVisited = visitedIds?.[id] === 1
  const latitude = getGeocoderLatitude(initialLatitude)
  const longitude = getGeocoderLongitude(initialLongitude)
  const price = getPrice(nightlyAverage)

  useEffect(() => {
    setIsHovered(isActive || isHovering)
    setVariant(() => {
      if (isActive || isHovering) return 'active'

      if (isFeatured) return 'featured'

      if (isVisited) return 'visited'

      return 'default'
    })
  }, [isActive, isHovering, isVisited])

  return (
    !!price && (
      <>
        <MarkerWrapper
          isHovered={isFeatured || isHovered}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          onClick={() => onActiveIdChange(id)}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseOut={() => setIsHovered(isActive || isHovering)}
            onMouseOver={() => setIsHovered(true)}
          >
            <HotelResultsMapMarkerIcon title={price} variant={variant} />
          </div>
        </MarkerWrapper>
        {isActive && (
          <HotelResultsMapMarkerPopup
            hotelResultsMapMarkerPopupData={{
              customerReviewScore,
              id,
              images,
              latitude,
              longitude,
              price: nightlyAverage,
              title: name,
              totalCustomerReviews,
            }}
            onClick={onPopupClick}
          />
        )}
      </>
    )
  )
}
