import { useState } from 'react'
import { Button, Divider, MenuItem, Popover } from '@travelpass/design-system'
import classNames from 'classnames'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getGuideDraftGeocoderSectionName } from '../../guideDraftUtils'
import type { GuideDraftData } from '../../types'
import { GuideDraftSectionAddModal } from '../GuideDraftSection'

const defaultSection = 'Uncategorized'

interface GuideDraftGeocoderSectionPopoverProps {
  guideId: string
  onSectionIdChange: (updatedSectionId: string) => void
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  sectionId: string
}

export const GuideDraftGeocoderSectionPopover = ({
  guideId,
  onSectionIdChange,
  paginatedEventCategories,
  sectionId,
}: GuideDraftGeocoderSectionPopoverProps) => {
  const [isModalSectionAddOpen, setIsModalSectionAddOpen] = useState(false)
  const sectionName = getGuideDraftGeocoderSectionName({
    paginatedEventCategories,
    sectionId,
  })

  const onModalSectionAddOpen = () => {
    pushDataToDataLayer('guide_draft_section_add_modal_opened', {
      guide_id: guideId,
      trigger_url: window.location.href,
    })
    setIsModalSectionAddOpen(true)
  }

  return (
    <>
      <Popover
        placement='bottom-start'
        trigger={
          /** @todo checked with design and they are updated the design system component */
          <div
            className={classNames(
              '[&>button]:c-forest-light [&>button>div]:max-w-30 [&>button>div]:truncate [&>button]:p-0 [&>button]:normal-case',
              {
                'lg:before:[&>button>div]:content-["Select_"]': !sectionId,
              }
            )}
          >
            <Button
              aria-label={`Select ${sectionName} to add event to`}
              endIcon='expandMore'
              size='small'
              variant='text'
            >
              {sectionName}
            </Button>
          </div>
        }
      >
        <div className='w-50 p-2'>
          <MenuItem
            isHighlighted={sectionId === ''}
            label={defaultSection}
            value=''
            onClick={() => onSectionIdChange('')}
          />
          {paginatedEventCategories?.edges?.map(({ node }) => (
            <MenuItem
              key={node?.id}
              isHighlighted={node?.id === sectionId}
              label={node?.name}
              value={node?.id}
              onClick={() => onSectionIdChange(node?.id)}
            />
          ))}
          <Divider />
          <MenuItem
            label='New Section'
            startIcon='add'
            value=''
            onClick={onModalSectionAddOpen}
          />
        </div>
      </Popover>
      {isModalSectionAddOpen && (
        <GuideDraftSectionAddModal
          callback={result =>
            onSectionIdChange(
              result?.data?.createPublishedEventCategory?.publishedEventCategory
                ?.id
            )
          }
          guideId={guideId}
          onDismiss={() => setIsModalSectionAddOpen(false)}
        />
      )}
    </>
  )
}
