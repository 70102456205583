import { useRef, useState, type ContextType } from 'react'
import { Chip, IconButton, useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller } from 'src/common/components'
import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { GuideDraftGeocoder } from '../GuideDraftGeocoder'

const chipLabels = [
  'Food & Drink',
  'Hotels',
  'Shopping',
  'Things to Do',
  'Parks',
]

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface GuideDraftMapSearchProps {
  id: GuideDraftData['id']
  location: UseGetGuideDraftQuery['location']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  searchValue: string
}

export const GuideDraftMapSearch = ({
  id,
  location,
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchValueChange,
  searchValue,
}: GuideDraftMapSearchProps) => {
  const { isMobileOrTablet } = useScreenQuery()
  const [mobileSearchExpanded, setMobileSearchExpanded] = useState(false)
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const chips = chipLabels.map(label => ({
    label,
    value: label,
    onClick: () => onSearchValueChange(label === searchValue ? '' : label),
  }))
  const selectedChip =
    searchValue?.trim() ||
    chipLabels.findIndex(label => label === searchValue) !== -1

  const onMobileSearchExpanded = () =>
    setMobileSearchExpanded(
      updatedMobileSearchExpanded => !updatedMobileSearchExpanded
    )

  return (
    <div className='p-x-3 p-y-2 shadow-2 lg:p-y-3 of-visible absolute left-0 right-0 top-0 h-16 bg-white/60 lg:h-auto'>
      {isMobileOrTablet && (
        <div
          className={classNames(
            'p-x-3 top-7px transition-transform-175 absolute inset-0 ease-[cubic-bezier(0,0,0.2,1)] lg:hidden',
            {
              '-translate-x-full': !mobileSearchExpanded,
            }
          )}
        >
          <GuideDraftGeocoder
            id={id}
            location={location}
            searchValue={searchValue}
            slotAfter={
              <div className='absolute right-0'>
                <IconButton
                  color='valley'
                  icon='search'
                  onClick={() => setMobileSearchExpanded(false)}
                />
              </div>
            }
            willAddEvent={false}
            onMapMarkerCenterChange={onMapMarkerCenterChange}
            onPlacePointsChange={onPlacePointsChange}
            onSearchValueChange={onSearchValueChange}
          />
        </div>
      )}
      <div
        className={classNames(
          'p-l-14 transition-opacity-200 lg:p-l-0 relative h-full w-full ease-linear lg:visible lg:opacity-100',
          {
            'invisible opacity-0': mobileSearchExpanded,
            'visible opacity-100': !mobileSearchExpanded,
          }
        )}
      >
        <div className='absolute bottom-0 left-0 top-0 lg:hidden'>
          <IconButton
            color='valley'
            icon='search'
            onClick={onMobileSearchExpanded}
          />
        </div>
        <div className='p-t-2.5 lg:p-t-0'>
          {selectedChip && (
            <div className='flex flex-col items-center'>
              <Chip
                includeCloseIcon={true}
                label={`Searching: ${searchValue}`}
                value={searchValue}
                onClick={() => onSearchValueChange('')}
              />
            </div>
          )}
          {!selectedChip && (
            <div className='m-a w-fit max-w-full'>
              <Scroller
                allowTouch={true}
                apiRef={apiRef}
                scrollContainerClassName='gap-1'
              >
                {chips.map(({ label, onClick, value }) => (
                  <div key={value} className='ws-nowrap'>
                    <Chip label={label} value={value} onClick={onClick} />
                  </div>
                ))}
              </Scroller>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
