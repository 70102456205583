import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  TripDetailsExploreMap,
  TripDetailsExploreEmpty,
  TripDetailsExplore,
  TripDetailsExploreDrawer,
  TripExploreLoading,
} from './components'
import { ExploreSearchParams } from './constants'
import {
  useTripsDetailsExploreExperiences,
  useTripsDetailsExploreHotels,
} from './hooks'
import {
  TripTopNav,
  TripMapWrapper,
  TripContainer,
  TripContentWrapper,
  TripNav,
} from '../components'
import { TripDrawer } from '../components/TripDrawer'
import { TripSearchParams } from '../constants'
import { useGetTripDetailsQuery, useGetTripEventsQuery } from '../hooks'

export const TripExplore = () => {
  const { tripId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [hoveredEventId, setHoveredEventId] = useState('')
  const experiencesQueryResult = useTripsDetailsExploreExperiences()
  const hotelsQueryResult = useTripsDetailsExploreHotels()
  const {
    hasError: hasTripDetailsError,
    isLoading: isTripDetailsLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    isLoading: isTripEventsLoading,
    hasError: hasTripEventsError,
    tripEventsData,
  } = useGetTripEventsQuery(tripId)
  const { name } = tripDetailsData ?? {}
  const activeExploreId = searchParams.get(ExploreSearchParams.activeExploreId)
  const isLoading = isTripDetailsLoading || isTripEventsLoading
  const mapFullView = searchParams.get(TripSearchParams.mapFullView)
  const mobileMapView = mapFullView == 'true'

  const onClose = () => {
    searchParams.delete(ExploreSearchParams.activeExploreId)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onHoveredEventIdChange = (updatedHoveredId: string) =>
    setHoveredEventId(updatedHoveredId)

  const setMobileMapOpen = () => {
    searchParams.set('mapFullView', 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  if (isLoading) return <TripExploreLoading />

  if (hasTripDetailsError || hasTripEventsError)
    return <TripDetailsExploreEmpty />

  return (
    <>
      <TripTopNav tripDetailsData={tripDetailsData} />
      <TripContainer>
        {!mobileMapView && (
          <TripContentWrapper>
            <TripNav name={name} />
            <TripDetailsExplore
              experiencesQueryResult={experiencesQueryResult}
              hotelsQueryResult={hotelsQueryResult}
              hoveredEventId={hoveredEventId}
              tripDetailsData={tripDetailsData}
              onHoveredEventIdChange={onHoveredEventIdChange}
            />
            <div className='-translate-1/4 z-3 fixed bottom-1 right-1 block lg:hidden'>
              <IconButton
                aria-label='menu'
                color='forestLight'
                icon='mapOutlined'
                size='large'
                onClick={setMobileMapOpen}
              />
            </div>
          </TripContentWrapper>
        )}
        {/* TODO add mobile map component */}
        <TripDrawer isOpen={!!activeExploreId} onClose={onClose}>
          <TripDetailsExploreDrawer
            experiencesResult={experiencesQueryResult?.[1]}
            hotelsResult={hotelsQueryResult?.[1]}
            tripDetailsData={tripDetailsData}
          />
        </TripDrawer>
        {tripId && (
          <div
            className={classNames('grow lg:block', {
              hidden: !mobileMapView,
            })}
          >
            <TripMapWrapper isDrawerOpen={!!activeExploreId}>
              <TripDetailsExploreMap
                experiencesResult={experiencesQueryResult?.[1]}
                hotelsResult={hotelsQueryResult?.[1]}
                hoveredEventId={hoveredEventId}
                tripDetailsData={tripDetailsData}
                tripEventsData={tripEventsData}
                onHoveredEventIdChange={onHoveredEventIdChange}
              />
            </TripMapWrapper>
          </div>
        )}
      </TripContainer>
    </>
  )
}
