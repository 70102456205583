import type { MouseEventHandler } from 'react'
import { Checkbox } from '@travelpass/design-system'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ResultsFiltersSection } from 'src/common/components'
import { constructDuration, getDuration } from '../common/utils'

interface ResultsFiltersDurationProps {
  filters: ExperienceFilters
  onChange(updatedFilters: ExperienceFilters): void
}

export const ResultsFiltersDuration = ({
  filters,
  onChange,
}: ResultsFiltersDurationProps) => {
  const { duration } = filters ?? {}
  const { minHours, maxHours } = duration ?? {}
  const constructedDuration = constructDuration(filters)

  const onDurationChange: MouseEventHandler<HTMLInputElement> = event => {
    const { id, checked } = event?.currentTarget ?? {}
    const updatedDuration = getDuration({ id, checked, minHours, maxHours })

    onChange({
      ...filters,
      duration: updatedDuration,
    })
  }

  return (
    <ResultsFiltersSection title='Duration'>
      <div className={resultsFiltersDurationCss}>
        {constructedDuration.map(({ label, id, isChecked }) => (
          <div key={id}>
            <Checkbox
              id={id}
              isChecked={!!isChecked}
              label={label}
              onClick={onDurationChange}
            />
          </div>
        ))}
      </div>
    </ResultsFiltersSection>
  )
}

const resultsFiltersDurationCss =
  'grid grid-cols-1 grid-flow-dense gap-y-2 gap-x-0 md:gap-x-4 md:grid-cols-2 lg:grid-cols-3'
