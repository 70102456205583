import { useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { constructHotelId } from 'src/pages/hotels/details/utils'
import { logError } from 'src/utils'
import { HotelReviewsModalCards } from './HotelReviewsModalCards'
import { HotelEmpty } from '../../../HotelEmpty'
import { HotelLoading } from '../../../HotelLoading'
import type { HotelReviewsFilterType } from '../../HotelReviewsApollo/hotelReviewsApolloConstants'
import { createHotelReviewsArguments } from '../../HotelReviewsApollo/hotelReviewsApolloUtils'

const HOTEL_REVIEWS_MODAL_CARDS = gql(`
  query HotelReviewsModalCardsListHotelReviews(
    $listHotelReviewsArgs: ListHotelReviewsArgs!
  ) {
    listHotelReviews(listHotelReviewsArgs: $listHotelReviewsArgs) {
      cursor
      reviews {
        addedDate
        averageRate
        id
        name
        negativeComments
        positiveComments
        travelerType
      }
    }
  }
`)

interface HotelReviewsModalCardsApolloProps {
  filters: HotelReviewsFilterType
}

export const HotelReviewsModalCardsApollo = ({
  filters,
}: HotelReviewsModalCardsApolloProps) => {
  const { hotelId: hotelIdValue } = useParams()
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const hotelId = constructHotelId(hotelIdValue)
  const initialPageSize = 5
  const { comment, sort, travelerTypes } = filters ?? {}

  const emptyState = () => {
    if (!!comment || !!sort || isEmpty(travelerTypes))
      return (
        <HotelEmpty
          subtitle='Try adjusting your filters for more.'
          title="We're sorry, there are no reviews with all of those options."
        />
      )

    return (
      <HotelEmpty
        subtitle='Try adjusting your filters for more.'
        title='No reviews with your search.'
      />
    )
  }

  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
    networkStatus,
  } = useQuery(HOTEL_REVIEWS_MODAL_CARDS, {
    notifyOnNetworkStatusChange: true,
    skip: !hotelId,
    variables: createHotelReviewsArguments({
      comment,
      pageSize: initialPageSize,
      sort,
      standardHotelId: hotelId,
      travelerTypes,
    }),
    onCompleted: data => {
      const { cursor } = data?.listHotelReviews ?? {}
      setHasMoreResults(!!cursor)
    },
  })

  const { listHotelReviews: hotelReviewsModalCardsData } = data ?? {}

  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore

  const onGetMoreResults = async () => {
    const { cursor } = data?.listHotelReviews ?? {}

    if (cursor) {
      await onGetMoreResultsQuery({
        variables: createHotelReviewsArguments({
          comment,
          cursor,
          sort,
          standardHotelId: hotelId,
          travelerTypes,
        }),
        updateQuery: (previousResults, { fetchMoreResult }) => {
          const updatedResults = cloneDeep(fetchMoreResult)
          const { reviews: previousHotelReviewsModalCardsData } =
            previousResults?.listHotelReviews ?? {}
          const { reviews: currentHotelReviewsModalCardsData } =
            fetchMoreResult?.listHotelReviews ?? {}
          const { cursor } = fetchMoreResult?.listHotelReviews ?? {}

          setHasMoreResults(!!cursor)

          const updatedResultsData = previousHotelReviewsModalCardsData.concat(
            currentHotelReviewsModalCardsData
          )

          updatedResults.listHotelReviews.reviews = updatedResultsData

          return updatedResults
        },
      }).catch(error => logError(error))
    } else {
      setHasMoreResults(false)
    }
  }

  if (isLoading && !isMoreResultsLoading) return <HotelLoading />

  if (hasError || isEmpty(hotelReviewsModalCardsData?.reviews))
    return emptyState()

  return (
    <HotelReviewsModalCards
      hasMoreResults={hasMoreResults}
      hotelReviewsModalCardsData={hotelReviewsModalCardsData}
      isMoreResultsLoading={isMoreResultsLoading}
      keyword={comment}
      onGetMoreResults={onGetMoreResults}
    />
  )
}
