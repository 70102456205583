import { useState } from 'react'
import { Button, Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import { ExploreFilterChips } from './ExploreFilterChips'
import { ExploreFilters } from './ExploreFilters/ExploreFilters'
import { SortBy } from './SortBy'

interface FiltersAndSortContainerProps {
  totalCount: number
  searchedTerm: string
  loading?: boolean
}

export const FiltersAndSortContainer = ({
  totalCount,
  searchedTerm,
  loading = false,
}: FiltersAndSortContainerProps) => {
  const [showFilters, setShowFilters] = useState(false)
  return (
    <section className='mb-5% space-y-7'>
      {(!!totalCount || loading) && (
        <h6
          className={classNames(
            'type-h6 w-fit transition-all ease-in',
            loading && 'blur-2 animate-pulse bg-zinc-300'
          )}
        >
          <span
            className={classNames(
              loading && 'blur-6',
              'transition-all ease-in'
            )}
          >
            {totalCount} result{totalCount > 1 && <span>s</span>}{' '}
            {searchedTerm?.trim() ? (
              <span>for &quot;{searchedTerm}&quot;</span>
            ) : (
              <span>found</span>
            )}
          </span>
        </h6>
      )}
      <ExploreFilters
        isOpen={showFilters}
        onDismiss={() => setShowFilters(false)}
        onFiltersApply={() => setShowFilters(false)}
      />
      <section className='flex items-center justify-between'>
        <div className='lg:hidden'>
          <Button
            aria-label='Filters'
            label='Filter & Sort'
            size='small'
            startIcon='tune'
            variant='outlined'
            onClick={() => setShowFilters(true)}
          />
        </div>
        <div className='hidden lg:block'>
          <Button
            aria-label='Filters'
            label='Filters'
            size='small'
            startIcon='tune'
            variant='outlined'
            onClick={() => setShowFilters(true)}
          />
        </div>
        <div className='hidden lg:block'>
          <SortBy />
        </div>
      </section>
      <ExploreFilterChips />
      <Divider />
    </section>
  )
}
