import { Button, Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideDraftShareIntroSettings } from './GuideDraftShareIntroSettings'
import type { GuideDraftDataOld } from '../../../types'

interface GuideDraftShareIntroProps {
  guideDraftData: GuideDraftDataOld
  isLoading: boolean
  onChange: VoidFunction
  onCopy: VoidFunction
  onShare: VoidFunction
}

export const GuideDraftShareIntro = ({
  guideDraftData,
  isLoading,
  onChange,
  onCopy,
  onShare,
}: GuideDraftShareIntroProps) => (
  <section className='space-y-4'>
    <GuideDraftShareIntroSettings
      guideDraftData={guideDraftData}
      isLoading={isLoading}
      onChange={onChange}
      onShare={onShare}
    />
    <Divider />
    <div
      className={classNames('md:p-x-6', {
        'm-a w-fit': !guideDraftData?.guide?.firstPublishedAt,
      })}
    >
      <Button size='large' startIcon='link' variant='outlined' onClick={onCopy}>
        Copy link
      </Button>
    </div>
  </section>
)
