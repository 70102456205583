import { useEffect, useRef } from 'react'
import type { HTMLAttributes, ReactNode } from 'react'

type EventTypes =
  | 'click'
  | 'input'
  | 'focus'
  | 'blur'
  | 'keydown'
  | 'keyup'
  | 'mousedown'
  | 'mouseup'
  | 'touchstart'
  | 'touchend'

const EVENT_TYPES = [
  'click',
  'input',
  'focus',
  'blur',
  'keydown',
  'keyup',
  'mousedown',
  'mouseup',
  'touchstart',
  'touchend',
]

interface InteractionWrapperProps extends HTMLAttributes<HTMLDivElement> {
  onInteraction: () => void
  children: ReactNode
  eventTypes?: EventTypes[]
}

export const InteractionWrapper: React.FC<InteractionWrapperProps> = ({
  onInteraction,
  children,
  eventTypes = EVENT_TYPES,
  ...props
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleInteraction = () => {
      onInteraction()
    }

    const wrapper = wrapperRef.current
    if (wrapper) {
      eventTypes.forEach(event =>
        wrapper.addEventListener(event, handleInteraction)
      )
    }

    return () => {
      if (wrapper) {
        eventTypes.forEach(event =>
          wrapper.removeEventListener(event, handleInteraction)
        )
      }
    }
  }, [onInteraction])

  return (
    <div ref={wrapperRef} {...props}>
      {children}
    </div>
  )
}
