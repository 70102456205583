import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_EXPERIENCE_INTO_COLLECTION = gql(`
    mutation AddExperienceIntoCollection($input: UpsertCollectedExperienceInput!, $itemId: String){
        upsertCollectedExperience(input: $input){
            collectedExperience{
                id
                productId
                product{
                    id
                    isCollected
                    title
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: EXPERIENCE)
                    id
                    name
                }
            }
        }
    }
`)

/**
 * It has an "add" on the name for pattern reasons,
 * but this hook allow to add and update an Experience inside a Collection
 */
export const useAddExperienceIntoCollection = () =>
  useMutation(ADD_EXPERIENCE_INTO_COLLECTION)
