import classNames from 'classnames'

const bookingConfirmationTripsContentCss =
  'flex flex-col gap-8 grow w-full items-center md:items-initial'

const bookingConfirmationTripsCss = (showAlternative = false) =>
  classNames(
    'flex flex-col gap-12 items-center justify-center text-center md:text-left',
    {
      'md:flex-row-reverse': showAlternative,
      'md:flex-row': !showAlternative,
    }
  )

const bookingConfirmationTripsImageCss = (showAlternative = false) =>
  classNames('h-auto max-w-full w-47', {
    'md:w-66': showAlternative,
    'md:w-62': !showAlternative,
  })

export {
  bookingConfirmationTripsContentCss,
  bookingConfirmationTripsCss,
  bookingConfirmationTripsImageCss,
}
