import type { ExperienceItinerary } from 'src/__generated__/graphql'

export const ExperienceDurationDisplay = ({
  destinations = '',
  itinerary,
}: {
  destinations?: string
  itinerary: ExperienceItinerary
}) => {
  const hasDurationField =
    itinerary?.__typename === 'ExperienceActivityItinerary' ||
    itinerary?.__typename === 'ExperienceStandardItinerary' ||
    itinerary?.__typename === 'ExperienceHopOnHopOffItinerary' ||
    itinerary?.__typename === 'ExperienceUnstructuredItinerary'

  return (
    <p className='type-h6'>
      {hasDurationField && `${itinerary.duration.readableDurationRange} / `}
      {destinations}
    </p>
  )
}
