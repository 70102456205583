import { useQuery, type QueryHookOptions } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  Exact,
  GetRecommendedTravelersQuery,
} from 'src/__generated__/graphql'

export const RECOMMENDED_TRAVELERS_QUERY = gql(`
    query GetRecommendedTravelers {
        currentUser {
            userProfile {
                userRecommendations {
                    recommendedUserProfile {
                        id
                        userId
                        isUserOwner
                        accountHandle
                        bio
                        isFollowed
                        displayName
                        followersConnection(first: 1) {
                            pageInfo {
                                hasPreviousPage
                                hasNextPage
                            }
                            totalCount
                        }
                        followingConnection(first: 1) {
                            pageInfo {
                                hasPreviousPage
                                hasNextPage
                            }
                            totalCount
                        }
                        guideCount
                        profileImageUrl
                        profileImageSource
                        tags {
                            id
                            category
                            name
                        }
                    }
                    reason
                }
            }
        }
    }
`)

export const useRecommendedTravelers = (
  props?: QueryHookOptions<
    GetRecommendedTravelersQuery,
    Exact<{ [key: string]: never }>
  >
) => useQuery(RECOMMENDED_TRAVELERS_QUERY, props)
