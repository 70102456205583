import { useGoogleMap } from '@react-google-maps/api'
import { Button } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

interface HotelResultsMapSearchThisAreaProps {
  onClose?(): void
}

export const HotelResultsMapSearchThisArea = ({
  onClose = () => {},
}: HotelResultsMapSearchThisAreaProps) => {
  const map = useGoogleMap()
  const [searchParams, setSearchParams] = useSearchParams()

  const onButtonClick = () => {
    const bounds = map?.getBounds()
    const center = bounds?.getCenter()
    const latitude = center?.lat()
    const longitude = center?.lng()

    searchParams.delete(HotelResultsSearchParams.placeShortName)
    searchParams.delete(HotelResultsSearchParams.placeType)
    searchParams.set(HotelResultsSearchParams.latitude, latitude?.toString())
    searchParams.set(HotelResultsSearchParams.location, 'Map area')
    searchParams.set(HotelResultsSearchParams.longitude, longitude?.toString())
    searchParams.set(HotelResultsSearchParams.mapSearchThisArea, 'true')

    if (bounds) {
      try {
        searchParams.set(
          HotelResultsSearchParams.mapBounds,
          JSON.stringify(bounds)
        )
      } catch (error) {
        console.error(error)
      }
    }

    onClose()
    setSearchParams(searchParams)
  }

  return (
    <div className='m-a absolute left-0 right-0 top-6 flex w-fit flex-col items-center lg:top-3'>
      <Button label='Search this area' size='small' onClick={onButtonClick} />
    </div>
  )
}
