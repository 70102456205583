import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_PRODUCT_START_LOCATION = gql(`
  query GetExperienceProductStartLocation($nodeId: ID!) {
    node(id: $nodeId) {
      ... on ExperienceProduct {
        destinations {
          latitude
          longitude
        }
      }
    }
  }
`)

export const useGetProductStartLocation = (productId: string) => {
  const { data, loading, error } = useQuery(GET_PRODUCT_START_LOCATION, {
    variables: { nodeId: productId },
    skip: !productId,
  })

  const product =
    data?.node?.__typename === 'ExperienceProduct' ? data.node : null
  const firstDestinationLocation = product?.destinations?.[0] || null

  return {
    data: firstDestinationLocation,
    loading,
    error,
  }
}
