import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const REMOVE_EXPERIENCE_FROM_COLLECTION = gql(`
    mutation RemoveExperienceFromCollection($input: DeleteCollectedExperienceInput!, $itemId: String){
        deleteCollectedExperience(input: $input){
            collectedExperience{
                id
                productId
                product{
                    id
                    isCollected
                    title
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: EXPERIENCE)
                    id
                    name
                }
            }
        }
    }
`)

export const useRemoveExperienceFromCollection = () =>
  useMutation(REMOVE_EXPERIENCE_FROM_COLLECTION)
