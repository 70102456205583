import { SkeletonDots } from '@travelpass/design-system'
import { bookingsListContainerCss, cardsPerPage } from '../../constants'
import { BookingCardLoading } from '../BookingCard'
import '../../styles/bookingsResultsCustomStyles.css'

interface BookingsLoadingProps {
  cancelled?: boolean
}

export const BookingsLoading = ({ cancelled }: BookingsLoadingProps) => (
  <div className={bookingsListContainerCss}>
    {Array(cardsPerPage)
      .fill(0)
      ?.map((_, i) => (
        <BookingCardLoading
          key={`booking-loading-card${i}`}
          cancelled={cancelled}
          className='min-w-74 w-full'
        />
      ))}
    <div className='flex h-16 flex-col items-center justify-center py-6'>
      <SkeletonDots />
    </div>
  </div>
)
