import { useEffect, useState } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import type { Supercluster } from 'supercluster'
import { MarkerWrapper } from 'src/common/components/Map'
import { GuidesEventsMapClusterIcon } from './GuidesEventsMapClusterIcon'

interface GuidesEventsMapClusterProps {
  guidesEventsMapClusterData: {
    id: string
    latitude: number
    longitude: number
    total: number
  }
  hoverId?: string
  onHoverIdChange: (updatedHoveredId: string) => void
  supercluster: Supercluster
}

export const GuidesEventsMapCluster = ({
  guidesEventsMapClusterData,
  hoverId,
  onHoverIdChange,
  supercluster,
}: GuidesEventsMapClusterProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const map = useGoogleMap()
  const { id, latitude, longitude, total } = guidesEventsMapClusterData ?? {}
  const isHovering = supercluster
    ?.getLeaves(id, Infinity)
    ?.find(({ properties }) => properties?.item?.id === hoverId)
  const showCluster = !!id && !!latitude && !!longitude && !!total
  const totalWithText = `${total > 99 ? '99+' : total} options`

  useEffect(() => {
    onHoverIdChange(isHovering)
  }, [isHovering])

  const onClick = () => {
    const expansionZoom =
      Math.min(supercluster?.getClusterExpansionZoom(id), 20) || 0

    if (expansionZoom > 0) {
      map?.setZoom(expansionZoom)
      map?.panTo({
        lat: latitude,
        lng: longitude,
      })
    }
  }

  return (
    showCluster && (
      <MarkerWrapper
        isHovered={isHovered}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        onClick={onClick}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onBlur={() => {}}
          onFocus={() => {}}
          onMouseOut={() => setIsHovered(isHovering)}
          onMouseOver={() => setIsHovered(true)}
        >
          <GuidesEventsMapClusterIcon
            isCluster
            title={totalWithText}
            variant={isHovering ? 'active' : 'default'}
          />
        </div>
      </MarkerWrapper>
    )
  )
}
