import { PageLayoutContainer } from 'src/common/components'
import { AwayGuides } from './AwayGuides'
import { LocalGuides } from './LocalGuides'
import { UtahJazzRoadFan } from './UtahJazzRoadFan'

export const UtahJazzGuides = () => {
  return (
    <>
      <div className='w-full bg-white py-12'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col gap-4'>
            <p className='type-overline text-grey-700 text-center uppercase tracking-widest'>
              Close To Home
            </p>
            <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo text-forest-dark m-0 text-balance text-center'>
              Explore Your Own Backyard
            </h3>
            <div className='w-30 mx-a bg-valley mb-4 h-1 rounded-full md:h-1.5 lg:w-40'></div>
            <LocalGuides />
          </div>
        </PageLayoutContainer>
      </div>
      <UtahJazzRoadFan />
      <div className='bg-warm-grey w-full pb-48 pt-12 md:py-12'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col gap-4'>
            <p className='type-overline text-grey-700 text-center uppercase tracking-widest'>
              Join The Jazz
            </p>
            <h3 className='text-6 md:text-8 lg:text-12 line-height-115% font-700 font-archivo text-forest-dark m-0 text-balance text-center'>
              Plan Your Away Game Getaway
            </h3>
            <div className='w-30 mx-a bg-valley mb-4 h-1 rounded-full md:h-1.5 lg:w-40'></div>
            <AwayGuides />
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}
