import { FormInput } from 'src/common/components/FormInput'
import type { BookingFormFields } from './useExperienceBookingForm'
import { rules } from './useExperienceBookingForm'

const Input = FormInput<BookingFormFields>

export const ContactInformationFields = () => {
  return (
    <>
      <div className='items-end lg:flex lg:flex-row lg:gap-4'>
        <div className='lg:w-1/2'>
          <Input
            fullWidth
            label='First Name'
            name='bookerInfo.firstName'
            placeholder='eg. John'
            required={rules.required}
          />
        </div>
        <div className='lg:w-1/2'>
          <Input
            fullWidth
            label='Last Name'
            name='bookerInfo.lastName'
            placeholder='eg. Smith'
            required={rules.required}
          />
        </div>
      </div>
      <Input
        fullWidth
        isNumericString
        autoComplete='tel'
        format='(###) ###-####'
        label='Phone Number'
        name='communication.phone'
        placeholder='(000) 000-0000'
        required={rules.required}
        rules={rules.phone}
        type='tel'
      />
      <Input
        fullWidth
        autoComplete='email'
        label='Email Address'
        name='communication.email'
        placeholder='Johnsmith@email.com'
        required={rules.required}
        rules={rules.email}
      />
    </>
  )
}
