import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { BookingCardHeroImage } from './BookingCardHeroImage'
import type { BookingCardData } from './types'

interface BookingCardHeroProps {
  bookingCardData: BookingCardData
}

export const BookingCardHero = ({ bookingCardData }: BookingCardHeroProps) => {
  const { image, name } = bookingCardData ?? {}
  const [hasImageLoaded, setHasImageLoaded] = useState(false)
  const [imageLink, setImageLink] = useState(image)

  const onError = () => setImageLink(null)

  const onLoad = () => {
    if (!hasImageLoaded) setHasImageLoaded(true)
  }

  return isEmpty(imageLink) ? (
    <div className='h-37.5 w-200px flex w-full flex-col items-center justify-center'>
      <EmptyState name='noImages' />
    </div>
  ) : (
    <BookingCardHeroImage
      hasFirstImageLoaded={hasImageLoaded}
      imageLink={imageLink}
      name={name}
      onError={onError}
      onLoad={onLoad}
    />
  )
}
