import { useLocation } from 'react-router-dom'
import {
  exploreSegment,
  timelineSegment,
  tripsSegment,
  utahJazzSegment,
  overviewSegment,
  guideDraftSegment,
  guidesSegment,
} from 'src/constants'
import { UtahJazzFooter } from 'src/pages/landing-pages/UtahJazz/UtahJazzFooter'
import { Footer } from './Footer'

export const FooterContainer = () => {
  const location = useLocation()
  const isGuideDraftPage = location.pathname.includes(
    `${guidesSegment}/${guideDraftSegment}`
  )
  const isTripPage =
    location.pathname.includes(`${tripsSegment}/${exploreSegment}`) ||
    location.pathname.includes(`${tripsSegment}/${overviewSegment}`) ||
    location.pathname.includes(`${tripsSegment}/${timelineSegment}`)

  const isJazzLandingPage = location.pathname.includes(`${utahJazzSegment}`)

  if (isTripPage || isGuideDraftPage) return <></>
  if (isJazzLandingPage) return <UtahJazzFooter />

  return <Footer />
}
