import { Checkbox } from '@travelpass/design-system'

interface FlagsSectionProps {
  onChange: (flag) => void
  title: string
  sectionFlags
  allFlags
}

export const FlagsSection = ({
  onChange,
  title,
  allFlags,
  sectionFlags,
}: FlagsSectionProps) => {
  const FlagToggle = ({ flag }: { flag: string }) => (
    <Checkbox
      key={flag}
      isChecked={allFlags[flag]}
      label={flag}
      onChange={() => onChange(flag)}
    />
  )

  return (
    <section>
      <h2 className='type-h5'>{title}</h2>
      <ul className='list-none space-y-2 p-0'>
        {Object.keys(sectionFlags).map(flag => (
          <li key={flag}>
            <FlagToggle flag={flag} />
          </li>
        ))}
      </ul>
    </section>
  )
}
