import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { ProfileCurrentUser } from 'src/common/hooks/useUserProfileQuery'

const GET_CURRENT_USER_GUEST_INFO_QUERY = gql(`
  query GetCurrentUserGuestInfo {
    currentUser {
      ...ProfileCurrentUser
    }
  }
`)

export const useUserGuestInfoQuery = () =>
  useQuery(GET_CURRENT_USER_GUEST_INFO_QUERY)
