import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const CREATE_EXPERIENCE_BOOKING_MUTATION = gql(`
mutation CreateExperienceBooking($input: CreateExperienceBookingInput!) {
  createExperienceBooking(input: $input) {
    experienceBooking {
      id
      externalConfirmationId
      experiencePostBookingStateId
      product {
        id
      }
      trip {
        id
        events {
          id
          experienceBooking {
            id
            externalConfirmationId
          }
        }
      }
    }
  }
}
`)

export const useCreateExperienceBookingMutation = () =>
  useMutation(CREATE_EXPERIENCE_BOOKING_MUTATION)
