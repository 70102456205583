import { Illustration } from '@travelpass/design-system'

export const TripExploreListEmpty = () => (
  <div className='b-grey-300 b-solid b-1px bg-grey-100 p-x-12 p-y-4 rounded-2 min-w-154 w-154 flex flex-row items-center gap-8'>
    <div className='p-y-7 grow space-y-4'>
      <h4 className='type-h4-desktop'>Start Exploring</h4>
      <p className='type-body-2-desktop'>
        Explore and uncover exciting additions for your trip. Start saving your
        favorites now for easy access later!
      </p>
    </div>
    {/** @todo add the 'world' illustration to the design-system */}
    <div className='min-w-51'>
      <Illustration name='jeep' />
    </div>
  </div>
)
