import { useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { useIncrementGuideEventLikeCountMutation } from './useIncrementGuideEventLikeCountMutation'

export const useGuideEventMetricLike = (id: PublishedEvent['id']) => {
  const { isAnonymous } = useFirebaseUser()
  const [incrementGuideEventLikeCount] =
    useIncrementGuideEventLikeCountMutation()
  const { addErrorSnack } = useSnackbar()

  const onGuideEventLike = debounce(async () => {
    if (isAnonymous) {
      dispatch('openSignin', {
        isCreateAccount: false,
      })
    } else {
      try {
        incrementGuideEventLikeCount({
          variables: {
            publishedEventId: id,
          },
        })
      } catch (errror) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }, 100)

  return onGuideEventLike
}
