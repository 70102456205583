import type { ListHotelReviewsArgs } from 'src/__generated__/graphql'
import {
  HotelReviewsSortBy as SortBy,
  TravelerType,
} from 'src/__generated__/graphql'

type HotelReviewsFilterType = {
  comment?: ListHotelReviewsArgs['comment']
  sort?: ListHotelReviewsArgs['sort']
  travelerTypes?: ListHotelReviewsArgs['travelerTypes']
}

const { HighToLow, LowToHigh, MostRecent } = SortBy

const {
  BusinessTraveler,
  FamilyWithOlderChildren,
  FamilyWithYoungerChildren,
  Group,
  MatureCouple,
  SeniorTraveler,
  SoloTraveler,
  YoungCouple,
} = TravelerType

const sortItems: { title: string; value: SortBy }[] = [
  {
    title: 'High to low rating',
    value: HighToLow,
  },
  {
    title: 'Low to high rating',
    value: LowToHigh,
  },
  {
    title: 'Most Recent',
    value: MostRecent,
  },
]

const travelerTypeItems: { title: string; value: TravelerType }[] = [
  {
    title: 'Any',
    value: null,
  },
  {
    title: 'Business traveler',
    value: BusinessTraveler,
  },
  {
    title: 'Family with older children',
    value: FamilyWithOlderChildren,
  },
  {
    title: 'Family with younger children',
    value: FamilyWithYoungerChildren,
  },
  {
    title: 'Group',
    value: Group,
  },
  {
    title: 'Mature couple',
    value: MatureCouple,
  },
  {
    title: 'Senior traveler',
    value: SeniorTraveler,
  },
  {
    title: 'Solo traveler',
    value: SoloTraveler,
  },
  {
    title: 'Young couple',
    value: YoungCouple,
  },
]

export { sortItems, travelerTypeItems }
export type { HotelReviewsFilterType }
