import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface GuideDraftShareReviewButtonProps {
  icon: AvailableIcons
  isCompleted: boolean
  onClick: VoidFunction
  subtitle: string
  title: string
}

const GuideDraftShareReviewButton = ({
  icon,
  isCompleted,
  onClick,
  subtitle,
  title,
}: GuideDraftShareReviewButtonProps) => (
  <button
    className={classNames(
      'p-x-4 p-y-3 b-1 b-grey-300 rounded-3 transition-shadow-100 hover:shadow-3 flex w-full flex-row items-center gap-3 border-solid bg-transparent text-left outline-none ease-linear hover:cursor-pointer',
      {
        '[&>i]:c-valley [&>span>span]:line-through': isCompleted,
        '[&>i]:c-new-forest': !isCompleted,
      }
    )}
    onClick={onClick}
  >
    <span
      className={classNames(
        'rounded-1/2 flex h-11 w-11 min-w-11 flex-col items-center justify-center',
        {
          'bg-valley/10 [&>i>svg]:color-valley': isCompleted,
          'bg-grey-100 [&>i>svg]:color-grey-600': !isCompleted,
        }
      )}
    >
      <Icon name={icon} />
    </span>
    <span className='flex grow flex-col gap-1'>
      <span className='type-h6 color-black'>{title}</span>
      <span className='type-body-1 color-black'>{subtitle}</span>
    </span>
    <Icon name={isCompleted ? 'checkCircleOutline' : 'arrowForward'} />
  </button>
)

export type { GuideDraftShareReviewButtonProps }
export { GuideDraftShareReviewButton }
