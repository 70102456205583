import type { ComponentPropsWithoutRef, SyntheticEvent } from 'react'
import { useState } from 'react'
import isEmpty from 'lodash.isempty'
import { EventType } from 'src/__generated__/graphql'
import {
  ResultsCard,
  ResultsCardContent,
  ResultsCardImage,
  ResultsCardReviews,
} from 'src/common/components'
import { getPrice } from 'src/utils'
import type { HotelResultsCardMinimalRowData } from './types'

interface HotelResultsCardMinimalRowProps
  extends ComponentPropsWithoutRef<'button'> {
  hotelResultsCardMinimalRowData: HotelResultsCardMinimalRowData
  imageDimensions?: string
}

export const HotelResultsCardMinimalRow = ({
  hotelResultsCardMinimalRowData,
  imageDimensions,
  ...props
}: HotelResultsCardMinimalRowProps) => {
  const {
    customerReviewScore,
    images: initialImages,
    price: initialPrice,
    title,
    totalCustomerReviews,
    type,
  } = hotelResultsCardMinimalRowData ?? {}
  const [images, setImages] = useState(() => initialImages)
  const nightlyAverage = !!initialPrice && getPrice(initialPrice)
  const showCard = !isEmpty(hotelResultsCardMinimalRowData) && !!title

  const onError = (event: SyntheticEvent<HTMLImageElement, Event>) =>
    setImages(updatedImages =>
      updatedImages?.filter(image => event.currentTarget.src !== image)
    )

  return (
    showCard && (
      <ResultsCard {...props}>
        <div
          className='pointer-events-none grid grid-cols-3'
          data-testid='HotelResultsCardMinimalRow'
        >
          <div className='relative col-span-1 h-full'>
            <ResultsCardImage
              alt={`${title} featured`}
              imageDimensions={imageDimensions}
              src={images?.[0]}
              onError={onError}
            />
          </div>
          <div className='col-span-2'>
            <ResultsCardContent>
              <section className='space-y-5 py-2'>
                <header>
                  <h1 className='type-h6-desktop truncate'>{title}</h1>
                </header>
                <div className='flex items-center justify-between gap-1'>
                  <div>
                    <ResultsCardReviews
                      average={customerReviewScore}
                      total={totalCustomerReviews}
                      type={type ?? EventType.Stay}
                      variant='minimal'
                    />
                  </div>
                  {nightlyAverage && (
                    <p className='font-roboto type-h4-mobile'>
                      {nightlyAverage}
                    </p>
                  )}
                </div>
              </section>
            </ResultsCardContent>
          </div>
        </div>
      </ResultsCard>
    )
  )
}
