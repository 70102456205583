import {
  Helmet,
  PageLayoutContainer,
  SearchBooking,
} from 'src/common/components'
import { SignInAndSignUpButtons } from 'src/common/components/SignInAndSignUpButtons'
import { baseUrl, findMyBookingPath } from 'src/constants'

export const FindMyBooking = () => (
  <>
    <Helmet
      canonicalUrl={`${baseUrl}${findMyBookingPath}`}
      pageName='Find Booking'
    />
    <PageLayoutContainer>
      <div className='h-100vh lg:h-70vh flex w-full flex-col items-center justify-center gap-4 lg:flex-row'>
        <SignInAndSignUpButtons helperText='To view your bookings:' />
        <div className='bg-grey-400 h-1px lg:w-1px m-8 w-full lg:h-full' />
        <div className='lg:w-50% w-full p-0 text-center lg:p-9'>
          <SearchBooking />
        </div>
      </div>
    </PageLayoutContainer>
  </>
)
