import { useState } from 'react'
import { IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { DashboardProfileFields } from '../types'

export const DashboardProfileInfoLinkVisualizer = ({
  links,
  index,
  title,
  url,
}) => {
  //State
  //Hooks
  const { setValue } = useFormContext<DashboardProfileFields>()

  const onDelete = (linkIndex: number) => {
    let updatedLinks = links.filter((_, i) => i !== linkIndex)
    setValue('links', updatedLinks)
  }

  return (
    <div className='flex flex-row gap-2.5'>
      <div className='flex w-full flex-row gap-3'>
        <Input
          aria-label='Link title'
          fullWidth={true}
          readOnly={true}
          value={title}
        />
        <Input
          aria-label='Link URL'
          fullWidth={true}
          readOnly={true}
          value={url}
        />
      </div>
      <div className='w-12 min-w-12'>
        <IconButton
          aria-label='Remove link'
          icon='deleteOutline'
          onClick={() => onDelete(index)}
        />
      </div>
    </div>
  )
}
