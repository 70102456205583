import { Button, IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'

export const OverviewActions = ({ onOpen }: { onOpen(): void }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onMobileMapOpen = () => {
    searchParams.set('mapFullView', 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <div className='p-x-8 p-y-6 z-3 fixed bottom-0 left-0 flex h-24 w-full flex-row items-center justify-center gap-4 bg-white'>
      <Button
        fullWidth
        aria-label='Add a note to your trip'
        size='large'
        startIcon='add'
        onClick={onOpen}
      >
        Add Note
      </Button>
      <IconButton
        aria-label='Menu'
        color='white'
        icon='mapOutlined'
        outline='round'
        size='large'
        onClick={onMobileMapOpen}
      />
    </div>
  )
}
