import {
  Button,
  Illustration,
  Modal,
  ModalActions,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import {
  BookingTimeState,
  ExperienceBookingTimeState,
  GuideDraftStatus,
  TripStatus,
} from 'src/__generated__/graphql'
import { dashboardCurrentUserQuery } from 'src/pages/dashboard'
import { useDeleteTrip } from './useDeleteTrip'
import { currentUserTripsGQL } from '../../home/components/MyTripsList/hooks/useCurrentUserTrips'

export const TripDeleteModal = ({
  tripId,
  tripName,
  onDelete,
  onClose,
}: {
  tripId: string
  tripName: string
  onDelete?: () => void
  onClose: () => void
}) => {
  const { deleteTrip, loading } = useDeleteTrip()
  const { addSuccessSnack } = useSnackbar()

  /** @todo remove this refetch when possible */
  const onConfirm = async () => {
    await deleteTrip({
      variables: {
        tripId,
      },
      refetchQueries: [
        {
          query: refetch,
          variables: {
            tripSearchInput: {
              status: TripStatus.Upcoming,
              sortDirection: 'ASC',
            },
          },
        },
        {
          query: currentUserTripsGQL,
          variables: {
            tripSearchInput: {
              status: TripStatus.Upcoming,
            },
          },
        },
        {
          query: dashboardCurrentUserQuery,
          variables: {
            searchUserExperienceBookingsArgs: {
              bookingTimeState: ExperienceBookingTimeState.Upcoming,
            },
            searchUserHotelBookingsArgs: {
              bookingTimeState: BookingTimeState.Upcoming,
            },
            tripSearchInput: {
              status: TripStatus.Upcoming,
            },
            userGuideDraftsArgs: {
              includeStatuses: [GuideDraftStatus.Active],
            },
          },
        },
      ],
    })
    addSuccessSnack({ title: 'Trip deleted' })
    onClose()
    onDelete?.()
  }

  return (
    <Modal
      size='small'
      title={`Are you sure you want to delete your ${tripName} trip?`}
      onDismiss={onClose}
    >
      {/** @todo /tablet designs? */}
      <div className='m-a p-t-4 p-b-20 w-89 flex flex-col items-center justify-center gap-3'>
        <div className='w-17'>
          <Illustration name='guide' />
        </div>
        <p className='c-grey-800 type-body-1-desktop text-center'>
          Deleting your trip will also remove any related published guides.
          However, this will NOT cancel any bookings and reservations.
        </p>
      </div>
      <ModalActions>
        {loading ? (
          <SkeletonDots />
        ) : (
          <div className='flex w-full flex-row items-center gap-10'>
            <Button
              aria-label='Cancel trip deletion'
              fullWidth={true}
              variant='outlined'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              aria-label='Delete trip'
              fullWidth={true}
              variant='error'
              onClick={onConfirm}
            >
              Delete Trip
            </Button>
          </div>
        )}
      </ModalActions>
    </Modal>
  )
}

const refetch = gql(`
  query GetCurrentUserTripsAfterTripDelete($tripSearchInput: TripSearchInput!) {
    currentUser {
      id
      externalId
      trips(tripSearchInput: $tripSearchInput, first: 100) {
        edges{
          node {
            id
            currentUserRole
          }
        }
      }
    }
  }
`)
