import { useSnackbar } from '@travelpass/design-system'
import { ResultsCardReviews, Scroller } from 'src/common/components'
import { useCreateAddExperienceEvent } from 'src/pages/trips/timeline/components/AddEvents/ExperienceEvent/useCreateAddExperienceEvent'
import { EventCard } from 'src/pages/trips/timeline/components/EventCard/EventCard'
import { ExperienceCardPricing } from 'src/pages/trips/timeline/components/EventCard/ExperienceCardPricing'
import { SkeletonCard } from 'src/pages/trips/timeline/components/EventCard/SkeletonCard'
import { SplitDivider } from 'src/pages/trips/timeline/components/SplitDivider/SplitDivider'
import { type ExperienceEvent } from '../constants'
import { useExperienceRecommendedData } from '../hooks'
import {
  getCreateExperienceVariables,
  getRecommendedHookArgs,
} from '../utils/experienceRecommenedUtils'

export const ExperienceRecommended = ({
  data,
  tripId,
}: {
  data: ExperienceEvent | null
  tripId: string | null
}) => {
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const { createAddExperienceEvent } = useCreateAddExperienceEvent()

  const recommenedHookArgs = getRecommendedHookArgs(data)
  const { experiences, loading } =
    useExperienceRecommendedData(recommenedHookArgs)

  const onCreateError = () => {
    addErrorSnack({
      title: 'Something went wrong. Try again later.',
    })
  }

  const onSelect = async (experienceId: string) => {
    const createExperienceVariables = getCreateExperienceVariables({
      experiences,
      experienceId,
      data,
      tripId,
    })

    if (createExperienceVariables) {
      await createAddExperienceEvent({
        variables: createExperienceVariables,
        onCompleted: () => {
          addSuccessSnack({
            title: 'Experience successfully added to trip',
          })
        },
        onError: () => onCreateError(),
      })
    } else {
      onCreateError()
    }
  }

  if (loading) {
    return (
      <div className='space-y-3 px-8 pb-8'>
        <div className='w-315px md:w-380px'>
          <SkeletonCard />
        </div>
      </div>
    )
  }

  return (
    <div className='space-y-3 px-8 pb-8'>
      <SplitDivider>Recommended for you</SplitDivider>
      <Scroller allowTouch hideScrollBar scrollContainerClassName='gap-2'>
        {experiences.map((experience: ExperienceEvent) => (
          <div key={experience.id} className='w-315px md:w-380px'>
            <EventCard
              isSmall
              id={experience.id}
              imageUrl={experience.images?.[0]}
              title={experience.name}
              variant='recommended'
              onClick={() => {}}
              onSelect={onSelect}
            >
              <ExperienceCardPricing amount={experience.nightlyAverage} />
              <ResultsCardReviews
                average={experience.rating}
                total={experience.totalReviews}
                type={experience.type}
              />
            </EventCard>
          </div>
        ))}
      </Scroller>
    </div>
  )
}
