import { Breadcrumbs, Button } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { tripTimelinePath, tripsPath } from 'src/constants'
import type { ExperiencesBookingDetailsMock } from '../ExperiencesBookingDetailsApollo/experiencesBookingDetailsApolloMock'

interface ExperiencesBookingDetailsBreadcrumbsProps {
  experiencesBookingDetailsData: ExperiencesBookingDetailsMock
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsBreadcrumbs = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsBreadcrumbsProps) => {
  const navigate = useNavigate()

  const { trip } = experiencesBookingDetailsData ?? {}
  const { title: productLabel } = experiencesBookingDetailsProductData ?? {}
  const { id: tripId, name: tripLabel } = trip ?? {}
  const constructedTripPath = `${tripTimelinePath}/${tripId}`

  const showBreadcrumb = !!tripId && !!tripLabel && !!productLabel

  const onSearchButtonClick = () => {
    if (history.length === 1) {
      return navigate(constructedTripPath)
    }

    return navigate(-1)
  }

  const breadcrumbs = [
    { label: 'My Trips', onClick: () => navigate(tripsPath) },
    {
      label: tripLabel,
      onClick: () => navigate(constructedTripPath),
    },
    {
      label: productLabel,
      onClick: () => {},
    },
  ]

  const constructedBreadcrumbs = () => {
    if (isMobile)
      return (
        <Button
          label='Back'
          size='small'
          startIcon='arrowBackIos'
          type='button'
          variant='text'
          onClick={onSearchButtonClick}
        />
      )

    return <Breadcrumbs breadcrumbs={breadcrumbs} />
  }

  return (
    showBreadcrumb && (
      <div className={experiencesBookingDetailsBreadcrumbsCss}>
        {constructedBreadcrumbs()}
      </div>
    )
  )
}

const experiencesBookingDetailsBreadcrumbsCss = 'hide-on-print'
