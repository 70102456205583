import { Input, TextArea } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { accountHandleValidationRegex } from 'src/constants/validation'
import type { DashboardProfileFields } from './types'

const accountHandleMaxLength = 25
const bioTextAreaMaxLength = 375
const displayNameMaxLength = 25
const minCharacters = 3

export const DashboardProfileInfoAbout = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<DashboardProfileFields>()
  const accountHandle = watch('accountHandle')
  const biography = watch('bio')
  const displayName = watch('displayName')

  return (
    <section className='space-y-6' id='dashboard-profile-info-about'>
      <h6 className='type-h6-desktop color-black'>About You</h6>
      <div className='flex flex-col gap-6 lg:flex-row lg:gap-10'>
        <Input
          {...register('displayName', {
            required: 'Please add a display name',
            minLength: {
              value: minCharacters,
              message: `Display name must be at least ${minCharacters} characters long`,
            },
          })}
          aria-label='Display name for Profile page'
          errorText={errors?.displayName?.message}
          fullWidth={true}
          helperText={`${displayName?.length}/${displayNameMaxLength}`}
          label='Name'
          maxLength={displayNameMaxLength}
          placeholder='Display name'
        />
        <Input
          {...register('accountHandle', {
            required: 'Please add an account handle',
            minLength: {
              value: minCharacters,
              message: `Account Handle must be at least ${minCharacters} characters long`,
            },
            validate: (value: string) =>
              accountHandleValidationRegex.test(value) ||
              'Account handle should only contain letters, numbers, and underscores. No spaces.',
          })}
          aria-label='Display account handle for Profile page'
          errorText={errors?.accountHandle?.message}
          fullWidth={true}
          helperText={`${accountHandle?.length}/${accountHandleMaxLength}. Use only letters, numbers, ".", and "_". No spaces.`}
          label='Account Handle'
          maxLength={accountHandleMaxLength}
          placeholder='e.g. john_doe'
          slotBefore={<span className='c-grey-600'>@</span>}
        />
      </div>
      <TextArea
        {...register('bio', {
          maxLength: {
            value: bioTextAreaMaxLength,
            message: `Bio cannot be more than ${bioTextAreaMaxLength} characters`,
          },
        })}
        aria-label='Bio for Profile page'
        errorText={errors?.bio?.message}
        helperText={`${biography?.length}/${bioTextAreaMaxLength}`}
        label='My Bio'
        maxLength={bioTextAreaMaxLength}
        placeholder='Introduce yourself! Share your travel style, favorite destinations, and more with your followers and other travelers.'
        rows={5}
      />
    </section>
  )
}
