import { useEffect, useState } from 'react'
import { Illustration } from '@travelpass/design-system'
import type { SearchTravelersQuery } from 'src/__generated__/graphql'
import {
  TravelerCard,
  TravelerCardLoading,
} from 'src/common/components/TravelerCard'

interface TravelerResultsContentProps {
  empty: boolean
  loading: boolean
  results: SearchTravelersQuery['userProfileSearch']['edges']
}

export const TravelerResultsContent = ({
  empty,
  loading,
  results,
}: TravelerResultsContentProps) => {
  const [previousResults, setPreviousResults] = useState(results)

  // update previous results after loading new results is complete
  useEffect(() => {
    if (previousResults !== results && !loading) setPreviousResults(results)
  }, [loading, results])

  if (loading) {
    return previousResults?.length ? (
      <section className='grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6 lg:grid-cols-3 [&>div]:pointer-events-none [&>div]:opacity-50'>
        {previousResults?.map(({ node }) => (
          <TravelerCard key={node.id} traveler={{ ...node }} />
        ))}
      </section>
    ) : (
      <section className='grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6 lg:grid-cols-3 [&>div]:pointer-events-none [&>div]:opacity-50'>
        <TravelerCardLoading />
        <TravelerCardLoading className='hidden md:block' />
        <TravelerCardLoading className='hidden lg:block' />
      </section>
    )
  }

  if (empty) {
    return (
      <section className='mx-auto w-fit space-y-10 px-6 text-center'>
        <div className='max-w-186px max-h-137px mx-auto'>
          <Illustration name='resting' />
        </div>
        <p className='type-body-1 c-grey-800'>
          Sorry, it looks like we weren&apos;t able to find any travelers
          matching your search.{' '}
        </p>
      </section>
    )
  }

  return (
    <section className='grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6 lg:grid-cols-3'>
      {results?.map(({ node }) => (
        <TravelerCard key={node.id} traveler={{ ...node }} />
      ))}
    </section>
  )
}
