import { forwardRef, useEffect, useState } from 'react'
import {
  Avatar,
  Input,
  Button,
  Autocomplete,
  KeyCode,
} from '@travelpass/design-system'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { Link, useNavigate } from 'react-router-dom'
import { getDetails, type Suggestion } from 'use-places-autocomplete'
import type { CurrentUserCompetitionInfoQuery } from 'src/__generated__/graphql'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import {
  competitionAboutPath,
  infoSegment,
  privacySegment,
  termsSegment,
} from 'src/constants'
import { rules } from 'src/utils/rules'
import { BasicProfileInfoForm } from './BasicProfileInfoForm'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'
import { hasUserEnteredCompetition } from './utils'
import './phoneInput.css'

export type CompetitionUserProfile =
  CurrentUserCompetitionInfoQuery['currentUser']['userProfile']

export type PersonalInformationInputFields = {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  addressLine1: string
  city: string
  state: string
  country: string
  zipcode: string
  acceptedTermsAndConditions: boolean
  agreedToSMS: boolean
}

export const Apply = () => {
  const { isAnonymous } = useFirebaseUser()
  const { data: userCompData } = useGetCurrentUserCompetitionInfo()
  const { data: currentUserData } = useUserProfileQuery()
  const { firstName, lastName, email, phoneNumber } =
    currentUserData?.currentUser ?? {}
  const to = useNavigate()
  const [step, setStep] = useState(1)
  const [selectedCountry, setSelectedCountry] = useState('US')
  const enableSmsCompetition2024 = useFlag('enableSmsCompetition2024')
  // we only want to show the current profile info if the user first visited the page while signed in
  const [showCurrentProfileBanner, setShowCurrentProfileBanner] =
    useState(false)

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue: setFormValue,
    getValues,
  } = useForm<PersonalInformationInputFields>({
    defaultValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
    },
  })

  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: {
        types: ['address'],
        componentRestrictions: {
          country: [],
        },
      },
      cacheKey: CACHE_KEYS.withoutRestriction,
    })

  const { userProfile } = userCompData?.currentUser ?? {}
  const info = userProfile?.competitionInfo
  const hasEnteredCompetition = hasUserEnteredCompetition(info?.status)
  const options = suggestions?.data

  useEffect(() => {
    if (!isAnonymous) setShowCurrentProfileBanner(true)
    else openSigninModal()
  }, [isAnonymous])

  useEffect(() => {
    if (hasEnteredCompetition) to('/dashboard?tab=guides')
  }, [hasEnteredCompetition])

  useEffect(() => {
    if (!isAnonymous) {
      setFormValue('firstName', firstName)
      setFormValue('lastName', lastName)
      setFormValue('email', email)
      setFormValue('phoneNumber', phoneNumber)
    }
    return () => reset()
  }, [currentUserData])

  const openSigninModal = () => {
    dispatch('openSignin', {
      isCreateAccount: isAnonymous,
      onClose: isLoggedIn => {
        if (isLoggedIn) {
          setShowCurrentProfileBanner(false)
          return
        }
        return to(competitionAboutPath)
      },
    })
  }

  const handleOptionSelection = async (selection: Suggestion) => {
    const result = await getDetails({
      placeId: selection.place_id,
      fields: ['address_components'],
    })
    if (typeof result === 'string') return
    const addressLine1 = selection.structured_formatting?.main_text || ''
    const addressComponents = result?.address_components

    setFormValue('addressLine1', addressLine1)
    setValue(addressLine1)

    const usesAlternativeCityDesignation = !addressComponents.some(
      ({ types }) => types.includes('locality') || types.includes('postal_town')
    )

    addressComponents.forEach(({ types, short_name }) => {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      if (types.includes('country')) setFormValue('country', short_name)

      if (types.includes('postal_code')) setFormValue('zipcode', short_name)

      if (types.includes('administrative_area_level_1')) {
        setFormValue('state', short_name)
      }
      if (usesAlternativeCityDesignation) {
        /* so far the only alternative city designation I have found is administrative_area_level_2 (Brazil).
           Other specific cases may need to be added in the future. */
        if (types.includes('administrative_area_level_2')) {
          setFormValue('city', short_name)
        }
      } else {
        // postal_town is used in the UK
        if (types.includes('locality') || types.includes('postal_town')) {
          setFormValue('city', short_name)
        }
      }
    })

    clearSuggestions()
  }

  const onSubmit: SubmitHandler<PersonalInformationInputFields> = () => {
    return setStep(2)
  }

  const previousStep = () => {
    setStep(1)
  }

  const CustomInput = forwardRef((props, ref) => (
    <Input
      {...register('phoneNumber', {
        pattern: rules.internationalPhone,
        required: rules.required,
      })}
      fullWidth
      required
      errorText={errors?.['phoneNumber']?.message}
      label='Mobile Number'
      //@ts-ignore
      ref={ref} // Pass the ref to Input
      {...props} // Spread the rest of the props
    />
  ))
  CustomInput.displayName = 'CustomInput'

  return (
    <div className='bg-warm-grey'>
      <section className='relative bg-white bg-[url(https://static.travelpass.com/assets/job-campaign-2024/apply-squiggle-top.webp)] bg-no-repeat max-md:bg-none md:py-5'>
        <div className='z2 relative flex justify-center'>
          <iframe
            allowFullScreen
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            frameBorder='0'
            height='315'
            referrerPolicy='strict-origin-when-cross-origin'
            src='https://www.youtube.com/embed/z3VhyBhdu1E?si=HbTxWxnpaxIPuUjg'
            title='Travelpass Friends and Family Contest - How It Works'
            width='560'
          ></iframe>
        </div>
        <img
          alt=''
          className='max-w-53% absolute bottom-0 right-0 hidden md:block'
          src='https://static.travelpass.com/assets/job-campaign-2024/apply-squiggle-bottom.webp'
        />
      </section>
      {step === 1 && (
        <section className='max-w-650px job-promotion mx-auto mb-16 mt-12 space-y-11 md:mt-16 md:space-y-12'>
          <h1 className='text-8 lg:text-9 mb-8 text-center'>
            Personal Information
          </h1>
          {showCurrentProfileBanner && (
            <div className='flex flex-col items-center gap-10 md:flex-row'>
              <Avatar
                border='none'
                size='lg'
                src={userProfile?.profileImageUrl}
              />
              <section className='space-y-3'>
                <p className='type-body-1-medium'>
                  Looks like you&apos;re already signed in as:
                </p>
                <div className='space-y-1'>
                  <p className='type-h4'>{userProfile?.displayName}</p>
                  <p className='type-body-2'>{userProfile?.accountHandle}</p>
                </div>
                <p className='type-body-2'>
                  Not your account?{' '}
                  <button
                    className='b-none c-forestLight type-body-2 cursor-pointer bg-transparent'
                    onClick={openSigninModal}
                  >
                    Sign in here
                  </button>
                </p>
              </section>
            </div>
          )}
          <form
            className='space-y-2.5 px-4 md:space-y-8'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-row gap-2'>
              <Input
                {...register('firstName', { required: rules.required })}
                fullWidth
                required
                errorText={errors?.['firstName']?.message}
                label='First name'
              />
              <Input
                {...register('lastName', {
                  required: rules.required,
                })}
                fullWidth
                required
                errorText={errors?.['lastName']?.message}
                label='Last name'
              />
            </div>
            <div className='md:grid-col-span-3'>
              <PhoneInput
                defaultCountry='US'
                inputComponent={CustomInput}
                value={getValues().phoneNumber}
                onChange={phone => setFormValue('phoneNumber', phone)}
                onCountryChange={country => {
                  if (country != 'US') setFormValue('agreedToSMS', false)
                  setSelectedCountry(country)
                }}
              />
            </div>
            <div className='grid grid-cols-1 items-baseline gap-2.5'>
              <Input
                {...register('email', {
                  required: rules.required,
                  pattern: rules.email,
                })}
                fullWidth
                isDisabled
                required
                errorText={errors?.['email']?.message}
                label='Email'
              />
            </div>
            <div className='space-y-2'>
              <Autocomplete
                {...register('addressLine1', { required: rules.required })}
                autoExpand
                fullWidth
                required
                autoComplete='off'
                errorText={errors?.['addressLine1']?.message}
                isDisabled={!ready}
                label='Street address'
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={e => {
                  // prevent enter from submitting form
                  if (e.key === KeyCode.ENTER) e.preventDefault()
                }}
                onOptionSelect={handleOptionSelection}
              >
                {options.map(option => (
                  <GeocoderOption key={option.place_id} option={option} />
                ))}
              </Autocomplete>
              <div className='flex flex-row gap-2'>
                <Input
                  {...register('city', { required: rules.required })}
                  fullWidth
                  required
                  errorText={errors?.['city']?.message}
                  label='City'
                />
                {/** @todo: replace with Dropdown of states, same as hotels */}
                <Input
                  required
                  {...register('state', { required: rules.required })}
                  fullWidth
                  errorText={errors?.['state']?.message}
                  label='State'
                />
              </div>
              <div className='flex flex-row gap-2'>
                <Input
                  {...register('zipcode', { required: rules.required })}
                  fullWidth
                  required
                  errorText={errors?.['zipcode']?.message}
                  label='ZIP code'
                />
                <Input
                  required
                  {...register('country', { required: rules.required })}
                  fullWidth
                  errorText={errors?.['country']?.message}
                  label='Country'
                />
              </div>
            </div>
            <div className='bg-warm-grey space-y-6 rounded-lg pt-12'>
              <p className='type-h5'>Contest Terms and Conditions</p>
              <ul className='p-unset list-none space-y-4'>
                <li>1. Eligibility</li>
                <li>
                  1.1. The contest is open to entrants who are at least 18 years
                  old at the time of entry.
                </li>
                <li>
                  1.2. Employees, officers, directors, agents, and
                  representatives of Travelpass Group and its parent companies,
                  subsidiaries, affiliates, and advertising agencies, and their
                  immediate family members (spouse, parents, siblings, and
                  children){' '}
                  <span className='underline'>
                    <strong>are</strong>
                  </span>{' '}
                  eligible to participate.
                </li>
                <li>1.3. The contest is void where prohibited by law.</li>
              </ul>
              <TermsAndConditionsModal buttonText='See Full Terms And Conditions' />
            </div>
            <div>
              {errors?.['acceptedTermsAndConditions']?.message && (
                <p className='type-body-1 c-error m-0 text-center'>
                  You must agree to the Terms and Conditions.
                </p>
              )}
              <label className='type-body-1 inline-flex gap-2 p-2'>
                <input
                  type='checkbox'
                  {...register('acceptedTermsAndConditions', {
                    required: 'You must agree to the rules and regulations',
                  })}
                  required
                />
                <span>I have read and agree to the Terms and Conditions</span>
              </label>
              {enableSmsCompetition2024 && (
                <label className='type-body-1 flex flex-row items-baseline gap-2 p-2'>
                  <input
                    type='checkbox'
                    {...register('agreedToSMS')}
                    disabled={selectedCountry != 'US'}
                  />
                  <span>
                    I agree to receive recurring automated marketing messages at
                    the phone number provided, even if that number is on a state
                    or national do not call registry. Consent is not a condition
                    of purchase. Reply STOP to unsubscribe. Reply HELP for help.
                    Message frequency varies. Msg & data rates may apply. SMS
                    available for USA numbers only. View our{' '}
                    <Link to={`/${infoSegment}/${privacySegment}`}>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link to={`/${infoSegment}/${termsSegment}`}>
                      Terms of Use
                    </Link>
                    .
                  </span>
                </label>
              )}
            </div>
            <div className='flex justify-center pt-2.5'>
              <Button
                aria-label='Nexts'
                endIcon='arrowForward'
                label='Next'
                size='large'
                type='submit'
              />
            </div>
          </form>
        </section>
      )}
      {step === 2 && (
        <BasicProfileInfoForm
          changeStep={previousStep}
          getPersonalInformation={getValues}
          userProfile={userProfile}
        />
      )}
    </div>
  )
}
