import type { ReactNode } from 'react'
import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'
import { getEventImage } from 'src/pages/trips/utils'
import type { CardVariant } from './types'

export const Card = ({
  children,
  isActive = false,
  isHovered = false,
  onClick,
  onMouseOver,
  onMouseOut,
  variant = 'default',
}: {
  children: ReactNode
  isActive?: boolean
  isHovered?: boolean
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  variant?: CardVariant
}) => (
  <div
    className={classNames(
      'rounded-4 border-1 border-grey200 relative box-border flex gap-2 border-solid hover:drop-shadow-lg md:gap-3',
      {
        'drop-shadow-lg': isHovered,
        'bg-mintLight outline-forestLight outline outline-2': isActive,
        'bg-grey-100': !isActive && variant === 'default',
        'bg-white': !isActive && variant === 'recommended',
      }
    )}
    role='button'
    tabIndex={0}
    onBlur={onMouseOut}
    onClick={onClick}
    onFocus={onMouseOver}
    onMouseOut={onMouseOut}
    onMouseOver={onMouseOver}
  >
    {children}
  </div>
)

export const CardImageHero = ({
  imageUrl,
  imageName,
}: {
  imageUrl: string
  imageName: string
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const onError = () => setHasError(true)

  const onLoad = () => {
    if (isLoading) setIsLoading(false)
  }
  const preppedImageURL = getEventImage(imageUrl)

  return (
    <div
      className={classNames(
        'of-hidden rounded-14px flex h-full flex-row object-center',
        {
          'animate-pulse': isLoading && imageUrl,
          'bg-grey400': !isLoading && imageUrl,
        }
      )}
    >
      {!preppedImageURL || hasError ? (
        <div className='flex h-full w-full items-center'>
          <EmptyState name='noImages' />
        </div>
      ) : (
        <>
          <span className='sr-only'>Image of {imageName}</span>
          <img
            alt={`${imageName} hero`}
            className={classNames('w-full object-cover', {
              'opacity-100': !isLoading,
              'opacity-0': isLoading,
            })}
            draggable={false}
            loading={isLoading ? 'eager' : 'lazy'}
            src={preppedImageURL}
            onError={onError}
            onLoad={onLoad}
          />
        </>
      )}
    </div>
  )
}

export const CardContent = ({ children }) => {
  return <div className='flex-1 p-1'>{children}</div>
}
