import { useState } from 'react'
import { Link } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceHopOnHopOffItinerary,
  ExperienceItinerary,
} from 'src/__generated__/graphql'
import { VerticalStep, VerticalStepper } from 'src/common/components'
import { isFirstIndex } from 'src/utils'
import { ExperiencesItineraryTimelineHopOnHopOffHeader } from './ExperiencesItineraryTimelineHopOnHopOffHeader'
import { ExperiencesItineraryTimelineStep } from '../ExperiencesItineraryTimelineStep'
import { experiencesItineraryTimelineCustomCss } from '../experiencesItineraryTimelineStyles'
import type { ExperiencesItineraryTimelineProps } from '../types'

interface ExperiencesItineraryTimelineHopOnHopOffProps {
  itinerary: ExperienceItinerary
  size?: ExperiencesItineraryTimelineProps['size']
}
export const ExperiencesItineraryTimelineHopOnHopOff = ({
  itinerary,
  size = 'medium',
}: ExperiencesItineraryTimelineHopOnHopOffProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { routes } = (itinerary as ExperienceHopOnHopOffItinerary) ?? {}
  const initialStepsVisible = 2
  const showTimeline = !isEmpty(routes)

  const getStepVisibility = (index: number): boolean => {
    if (index > initialStepsVisible && !isExpanded) return false

    return true
  }

  const getRouteVisibility = (routeIndex: number): boolean => {
    if (!isFirstIndex(routeIndex) && !isExpanded) return false

    return true
  }

  return (
    showTimeline && (
      <div className={experiencesItineraryTimelineHopOnHopOffCss({ size })}>
        {routes?.map(
          (route, routeIndex) =>
            getRouteVisibility(routeIndex) && (
              <div
                key={`route-${routeIndex}`}
                className={experiencesItineraryTimelineHopOnHopOffRouteCss}
              >
                <ExperiencesItineraryTimelineHopOnHopOffHeader route={route} />
                {!isEmpty(route?.stops) && (
                  <div className={experiencesItineraryTimelineCustomCss}>
                    <VerticalStepper>
                      {route?.stops?.map(
                        ({ description, stopLocation }, index) =>
                          getStepVisibility(index) && (
                            <VerticalStep
                              key={`route-stop-${stopLocation}-${routeIndex}-${index}`}
                              label={index + 1}
                            >
                              <ExperiencesItineraryTimelineStep
                                subtitle={description}
                                title={stopLocation?.name}
                              />
                            </VerticalStep>
                          )
                      )}
                    </VerticalStepper>
                  </div>
                )}
                {!isEmpty(route?.pointsOfInterest) && isExpanded && (
                  <>
                    <p
                      className={
                        experiencesItineraryTimelineHopOnHopOffRoutePointsTitleCss
                      }
                    >
                      Points of Interest passed by on this route
                    </p>
                    <div className={experiencesItineraryTimelineCustomCss}>
                      <VerticalStepper>
                        {route?.pointsOfInterest?.map(({ location }, index) => (
                          <VerticalStep
                            key={`route-point-${location?.name}-${routeIndex}-${index}`}
                          >
                            <ExperiencesItineraryTimelineStep
                              title={
                                <>
                                  {location?.name}{' '}
                                  <span className='color-grey-700'>
                                    (Pass By)
                                  </span>
                                </>
                              }
                            />
                          </VerticalStep>
                        ))}
                      </VerticalStepper>
                    </div>
                  </>
                )}
              </div>
            )
        )}
        <div className='hide-on-print'>
          <Link
            aria-pressed={!!isExpanded}
            label={isExpanded ? 'Read Less' : 'Read More'}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      </div>
    )
  )
}

const experiencesItineraryTimelineHopOnHopOffCss = ({
  size,
}: Partial<ExperiencesItineraryTimelineHopOnHopOffProps>) =>
  classNames({
    'flex flex-col gap-4': size === 'small',
    'flex flex-col gap-12': size !== 'small',
  })

const experiencesItineraryTimelineHopOnHopOffRouteCss =
  'color-grey-800  flex flex-col gap-12'

const experiencesItineraryTimelineHopOnHopOffRoutePointsTitleCss = 'font-500'
