import type { DateRange } from '@travelpass/design-system'
import { SortBy } from 'src/__generated__/graphql'
import type { DatesType, GeocoderType, GuestsType } from 'src/constants/user'
import {
  getArrivalDate,
  getDateDiff,
  getDepartureDate,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
} from 'src/utils'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

const getHotelResultsSearchDates = ({
  currentDates,
  isArrival = true,
  previousDates,
}: {
  currentDates: DateRange
  isArrival?: Boolean
  previousDates: DatesType
}): DateRange => {
  if (isArrival) {
    const currentDate = currentDates?.from

    if (
      getDateDiff([previousDates?.[1]?.toString(), currentDate?.toString()]) >=
      1
    ) {
      return {
        from: currentDate,
        to: previousDates?.[1],
      }
    } else {
      return {
        from: currentDate,
      }
    }
  } else {
    const currentDate = currentDates?.to ? currentDates?.to : currentDates?.from

    if (
      getDateDiff([currentDate?.toString(), previousDates?.[0].toString()]) >= 1
    ) {
      return {
        from: previousDates?.[0],
        to: currentDate,
      }
    } else {
      return {
        from: currentDate,
      }
    }
  }
}

const setHotelResultsSearchSearchParams = ({
  dates,
  geocoder,
  guests,
  onChange,
  searchParams,
}: {
  dates: DatesType
  geocoder: GeocoderType
  guests: GuestsType
  onChange?(updatedSearchParams: URLSearchParams): void
  searchParams: URLSearchParams
}) => {
  const placeCountry = getGeocoderPlaceCountryFromAddressComponents(
    geocoder?.addressComponents
  )
  const placeFilteredType = getGeocoderPlaceTypeFromAddressComponents(
    geocoder?.addressComponents
  )
  searchParams.delete(HotelResultsSearchParams.mapBounds)
  searchParams.set(HotelResultsSearchParams.adults, guests?.[0]?.toString())
  searchParams.set(
    HotelResultsSearchParams.arrival,
    getArrivalDate({
      date: dates?.[0],
    })
  )
  searchParams.set(
    HotelResultsSearchParams.departure,
    getDepartureDate({
      date: dates?.[1],
    })
  )
  searchParams.set(HotelResultsSearchParams.kids, guests?.[1]?.toString())
  searchParams.set(
    HotelResultsSearchParams.latitude,
    geocoder?.center?.[0]?.toString()
  )
  searchParams.set(HotelResultsSearchParams.location, geocoder?.placeName)
  searchParams.set(
    HotelResultsSearchParams.longitude,
    geocoder?.center?.[1]?.toString()
  )

  if (geocoder?.placeId) {
    searchParams.set(HotelResultsSearchParams.placeId, geocoder?.placeId)
    searchParams.set(HotelResultsSearchParams.sort, SortBy.Distance)
  } else {
    searchParams.delete(HotelResultsSearchParams.placeId)
    searchParams.set(HotelResultsSearchParams.sort, SortBy.Recommended)
  }

  if (geocoder?.viewport) {
    searchParams.set(
      HotelResultsSearchParams.mapBounds,
      JSON.stringify(geocoder?.viewport)
    )
  }

  if (placeCountry) {
    searchParams.set(HotelResultsSearchParams.placeCountry, placeCountry)
  } else {
    searchParams.delete(HotelResultsSearchParams.placeCountry)
  }

  if (placeFilteredType?.shortName) {
    searchParams.set(
      HotelResultsSearchParams.placeShortName,
      placeFilteredType.shortName
    )
    searchParams.set(HotelResultsSearchParams.placeType, placeFilteredType.type)
  } else {
    searchParams.delete(HotelResultsSearchParams.placeShortName)
    searchParams.delete(HotelResultsSearchParams.placeType)
  }

  if (!window.location.search.includes(searchParams?.toString()))
    onChange(searchParams)
}

export { getHotelResultsSearchDates, setHotelResultsSearchSearchParams }
