import { Skeleton } from '@travelpass/design-system'

export const HotelRoomsApolloLoading = () => {
  return (
    <div className='grid grid-cols-1 gap-10 lg:max-w-[1200px] lg:grid-cols-2'>
      <div className='flex flex-col gap-6 lg:mx-6 lg:max-h-[840px] lg:overflow-auto'>
        <div className='h-[200px] lg:h-[134px] lg:w-full'>
          <Skeleton />
        </div>
        <div className='h-[200px] lg:h-[134px] lg:w-full'>
          <Skeleton />
        </div>
        <div className='h-[200px] lg:h-[134px] lg:w-full'>
          <Skeleton />
        </div>
      </div>
      <div className='hidden lg:block'>
        <div className='h-full w-full'>
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
