import { SkeletonDots } from '@travelpass/design-system'
import { AvailabilityOption } from './AvailabilityOption'
import { useAvailabilityOptionsList } from './useAvailabilityOptionsList'

export const AvailabilityOptionsList = () => {
  const {
    allItemsUnavailable,
    error,
    getOptionDetails,
    groupedBookableItems,
    hasFreeCancellation,
    loading,
    maxTravelersPerBooking,
    pricing,
  } = useAvailabilityOptionsList()

  return (
    <>
      {loading && (
        <div className='flex justify-center'>
          <SkeletonDots />
        </div>
      )}
      {(allItemsUnavailable || error) && (
        <p className='text-body-1 px-8 py-8 text-center md:px-32'>
          Shoot! Looks like this experience is sold out for your dates and/or
          party size. Try searching for a new date or adjusting the number of
          travelers.
        </p>
      )}
      {!allItemsUnavailable && (
        <div className='space-y-8 p-2.5 pt-0'>
          {groupedBookableItems?.map(({ productOptionCode, bookableItems }) => {
            const { title, description } = getOptionDetails(productOptionCode)
            const key = bookableItems
              .map(({ bookingFlowToken }) => bookingFlowToken)
              .join('')

            return (
              <AvailabilityOption
                key={key}
                bookableItems={bookableItems}
                description={description}
                hasFreeCancellation={hasFreeCancellation}
                maxTravelersPerBooking={maxTravelersPerBooking}
                pricing={pricing}
                title={title}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
