import { Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { DashboardProfileFields } from './types'
import { validateYoutubeLink } from '../../../dashboardUtils'

export const DashboardProfileInfoEmbedYoutubeVideo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<DashboardProfileFields>()

  return (
    <section
      className='md:p-r-14.5 space-y-6'
      id='dashboard-profile-embed-youtube-video'
    >
      <h6 className='type-h6-desktop c-black'>Embed A YouTube Video</h6>
      <Input
        {...register('introVideoEmbed', {
          validate: value =>
            value === '' ||
            validateYoutubeLink(value) ||
            'Please use a valid youtube link or embed link.',
        })}
        aria-label='Display Embed video for Profile page'
        errorText={errors?.introVideoEmbed?.message}
        fullWidth={true}
        placeholder='Add a video link here.'
      />
    </section>
  )
}
