import type { ReactNode } from 'react'
import { Button } from '@travelpass/design-system'
import type { ExperienceOperatorContact } from 'src/__generated__/graphql'
import { formatPhoneNumber } from 'src/utils'

type ContactItem = {
  phone: string
  subtitle: ReactNode
  title: string
}

interface ExperiencesBookingDetailsManageContactProps {
  experienceOperatorContact: ExperienceOperatorContact
}

export const ExperiencesBookingDetailsManageContact = ({
  experienceOperatorContact,
}: ExperiencesBookingDetailsManageContactProps) => {
  const { name, phone } = experienceOperatorContact ?? {}
  const formattedPhone = formatPhoneNumber(phone)

  const items: ContactItem[] = [
    {
      phone: formattedPhone,
      subtitle: (
        <>
          <p>For questions about your tour, contact:</p>
          <p>{name}</p>
        </>
      ),
      title: 'Contact Tour Operator',
    },
    {
      // It's vital that for experience bookings we use this number. It will bypass the regular IVR and put our users in a client queue.
      phone: '+1 833-897-9178',
      subtitle: (
        <p>
          For questions about your payment and booking status contact Travelpass
          directly.
        </p>
      ),
      title: 'Contact Travelpass',
    },
  ]

  return (
    <div className='grid gap-8 md:grid-cols-2' id='contact-information'>
      {items?.map(
        ({ phone, subtitle, title }) =>
          !!phone && (
            <section key={title} className='space-y-4'>
              <h3 className='text-h6'>{title}</h3>
              <div className='text-body-2 c-grey800'>
                {subtitle}
                <br />
                <Button
                  label={phone}
                  startIcon='phone'
                  variant='text'
                  onClick={() => (window.location.href = `tel:${phone}`)}
                />
              </div>
            </section>
          )
      )}
    </div>
  )
}
