import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createExperienceHoldMutation = gql(`
  mutation CreateExperienceHoldMutationInExperiences($bookingFlowToken: String!) {
    createExperienceHold(bookingFlowToken: $bookingFlowToken) {
      externalConfirmationId
      bookingHoldInfo {
        bookingStateId
      }
    }
  }
`)

export const useExperiencesCreateExperienceHoldMutation = () =>
  useMutation(createExperienceHoldMutation)
