import { createContext, useContext } from 'react'

export const AccordionContext = createContext<{
  expandedPanelId: string
  onSelect: (id: string) => void
  multiple: boolean
}>({
  expandedPanelId: null,
  onSelect: () => {},
  multiple: false,
})

export const AccordionItemGroupId = createContext<string>(null)

export const useAccordionGroupState = () => {
  const { expandedPanelId, onSelect, multiple } = useContext(AccordionContext)
  const htmlId = useContext(AccordionItemGroupId)

  return {
    id: htmlId,
    expanded: expandedPanelId === htmlId,
    onClick: () => onSelect(htmlId),
    multiple,
  }
}

export const useAccordionTrigger = () => {
  const id = useContext(AccordionItemGroupId)
  const { expanded, onClick } = useAccordionGroupState()

  return {
    id,
    ariaExpanded: expanded,
    onClick,
  }
}
