import { Icon } from '@travelpass/design-system'
import type {
  ExperienceActivityFoodMenu,
  ExperienceActivityItinerary,
} from 'src/__generated__/graphql'
import { ExperienceCourseType } from 'src/__generated__/graphql'
import { checkItineraryActivityMenu } from './utils'

const { Dessert, Main, Starter } = ExperienceCourseType ?? {}

export const ExperiencesItineraryActivityMenu = ({
  foodMenus,
}: Partial<ExperienceActivityItinerary>) => {
  const constructedCourse = (course: ExperienceActivityFoodMenu['course']) => {
    if (course === Dessert) return 'Dessert'

    if (course === Main) return 'Main'

    if (course === Starter) return 'Starter'
  }
  const showActivityMenu = checkItineraryActivityMenu({ foodMenus })

  return (
    // showActivityMenu && (
    <div className={experiencesItineraryActivityMenuCss}>
      {foodMenus?.map(({ course, dishDescription, dishName }, index) => (
        <div
          key={`${dishName}-${index}`}
          className={experiencesItineraryActivityMenuItemCss}
        >
          <Icon name='arrowRight' />
          <div className={experiencesItineraryActivityMenuItemContentCss}>
            <p className={experiencesItineraryActivityMenuItemTitleCss}>
              {constructedCourse(course)}
            </p>
            {!!dishName && <p>{dishName}</p>}
            <p>{dishDescription}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

const experiencesItineraryActivityMenuCss = 'flex flex-col gap-6'

const experiencesItineraryActivityMenuItemContentCss =
  'flex flex-col gap-2 pt-0.5'

const experiencesItineraryActivityMenuItemCss =
  'color-grey-900 flex gap-2 text-body-1'

const experiencesItineraryActivityMenuItemTitleCss = 'color-black font-500'
