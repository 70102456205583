import { useCallback, useRef, useState } from 'react'
import { Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import {
  type ListHotelReviewsResults,
  type BookingHotelDetailsQuery,
  type BookingValidateRateQuery,
  EventType,
} from 'src/__generated__/graphql'
import {
  HotelCancellationCard,
  HotelCancellationCardLoading,
  HotelCancellationModal,
  HotelPriceSummary,
  HotelPriceSummaryLoading,
  DateSummary,
  ResultsCardReviews,
} from 'src/common/components'
import {
  constructDueLater,
  constructDueNow,
  constructFees,
  constructTotal,
} from 'src/common/components/HotelPriceSummary/hotelPriceSummaryUtils'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { initialRooms } from 'src/constants/user'
import { useHotelReviewsSummaryListHotelReviewsQuery } from 'src/pages/hotels/details/hooks'
import { BookingSidebarAdditionalInfo } from './BookingSidebarAdditionalInfo'
import { BookingSidebarRoom } from './BookingSidebarRoom'
import { BookingSidebarRoomImage } from './BookingSidebarRoomImage'
import { StickyNav } from '../StickyNav'

interface BookingTermsProps {
  hotelData: BookingHotelDetailsQuery
  bookingHotelLoading: boolean
  rateData: BookingValidateRateQuery
  bookingRateLoading: boolean
  hotelReviewsSummaryData: ListHotelReviewsResults
}

export const BookingSidebar = ({
  hotelData,
  bookingHotelLoading,
  rateData,
  bookingRateLoading,
  hotelReviewsSummaryData,
}: BookingTermsProps) => {
  const checkoutV2 = useFlag('checkoutV2')
  const isInternationalGeocoderEnabled = useFlag(
    'isInternationalGeocoderEnabled'
  )
  const [isHotelCancellationModalOpen, setIsHotelCancellationModalOpen] =
    useState(false)
  const [searchParams] = useSearchParams()
  const { address, checkin, checkout, country, city, fees, name, state } =
    hotelData?.lodging ?? {}
  const { provider, room } = rateData?.validatedRate ?? {}
  const { cancelPolicy, price } = room?.rates?.[0] ?? {}
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = cancelPolicy ?? {}
  const { policyText, shortText } = constructedCancellationPolicies ?? {}
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const {
    allInTotal,
    additionalFees,
    dueLater,
    dueNow,
    subtotal,
    surchargeTotal,
    surcharges,
  } = price ?? {}
  const showInternationalDisclaimer =
    !!isInternationalGeocoderEnabled && country !== 'United States of America'

  const constructedFees = constructFees(additionalFees)
  const constructedDueLater = constructDueLater(
    constructedFees,
    dueLater?.amount
  )
  const constructedDueNow = constructDueNow(dueNow?.amount)
  const constructedTotalPrice = constructTotal(
    constructedDueLater,
    constructedDueNow
  )
  const priceSummaryRef = useRef(null)
  const [, hasMounted] = useState(false)
  const setPriceSummaryRef = useCallback(node => {
    priceSummaryRef.current = node
    hasMounted(!!priceSummaryRef.current)
  }, [])
  const isPriceSummaryOnScreen = useIsElementOnScreen(priceSummaryRef)
  const showStickyNav =
    !isPriceSummaryOnScreen && !bookingHotelLoading && !bookingRateLoading

  const onClickStickyNav = e => {
    e.preventDefault()
    priceSummaryRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <>
      {isHotelCancellationModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={policyText}
          onClose={() => setIsHotelCancellationModalOpen(false)}
        />
      )}
      <div className='space-y-6'>
        <section className='bg-warm-grey rounded-4 border-1 border-grey-300 space-y-6 border-solid p-6'>
          <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-2 space-y-4'>
              <div
                className={classNames({
                  'md:hidden': checkoutV2,
                  hidden: !checkoutV2,
                })}
              >
                <BookingSidebarRoomImage
                  bookingRateLoading={bookingRateLoading}
                  city={city}
                  images={
                    checkoutV2
                      ? hotelData?.lodging?.imageLinks
                      : rateData?.validatedRate?.room?.images
                  }
                  name={name}
                  state={state}
                />
              </div>
              <h1 className='type-h4 font-600 line-clamp-2'>{name}</h1>
              <p className='type-body-1 c-black'>
                {address}, {city}, {state}
              </p>
              {checkoutV2 && (
                <ResultsCardReviews
                  average={hotelReviewsSummaryData?.averageOverall}
                  total={hotelReviewsSummaryData?.totalReviews}
                  type={EventType.Stay}
                />
              )}
            </div>
          </div>
          <Divider className='mb-6' />
          {!checkoutV2 && (
            <DateSummary
              arrival={arrival}
              checkin={checkin}
              checkout={checkout}
              departure={departure}
            />
          )}
          <BookingSidebarRoom
            bookingRateLoading={bookingRateLoading}
            hotelData={hotelData?.lodging}
            rateData={rateData}
          />
          {bookingHotelLoading || bookingRateLoading ? (
            <HotelCancellationCardLoading />
          ) : (
            <HotelCancellationCard
              fullyRefundableUntil={fullyRefundableUntil}
              nonRefundableAfter={nonRefundableAfter}
              partiallyRefundableUntil={partiallyRefundableUntil}
              policies={policyText}
              refundType={refundType}
              subtitle={shortText}
            />
          )}
        </section>
        {bookingHotelLoading || bookingRateLoading ? (
          <HotelPriceSummaryLoading />
        ) : (
          <section
            className='bg-warm-grey rounded-4 border-1 border-grey-300 scroll-mt-2 space-y-6 border-solid p-6'
            ref={setPriceSummaryRef}
          >
            <HotelPriceSummary
              showLegal
              additionalFees={additionalFees}
              allInTotal={allInTotal?.amount}
              arrival={arrival}
              departure={departure}
              dueLater={dueLater?.amount}
              dueNow={dueNow?.amount}
              hotelFees={fees}
              provider={provider}
              rooms={initialRooms}
              showInternationalDisclaimer={showInternationalDisclaimer}
              subtotal={subtotal?.amount}
              surchargeTotal={surchargeTotal?.amount}
              surcharges={surcharges}
            />
          </section>
        )}
        {showStickyNav && (
          <StickyNav
            totalPrice={constructedTotalPrice}
            onClick={onClickStickyNav}
          />
        )}
        <BookingSidebarAdditionalInfo
          bookingHotelData={hotelData}
          bookingHotelLoading={bookingHotelLoading}
          bookingRateData={rateData}
        />
      </div>
    </>
  )
}
