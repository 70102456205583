import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { NativeAppCta, PageLayoutContainer } from 'src/common/components'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  BookingDetailsHeroApollo,
  BookingDetailsManageApollo,
  BookingDetailsOverview,
  BookingDetailsPriceApollo,
  BookingDetailsRoomApollo,
  BookingDetailsBreadcrumbsApollo,
  BookingDetailsNav,
} from './components'
import {
  bookingDetailsTabs,
  bookingDetailsContentCss,
  bookingDetailsCss,
  bookingDetailsHeaderCss,
  bookingDetailsScrollMarginCss,
} from './constants'

export const BookingDetails = () => {
  const isTripsHideEnabled = useFlag('tripsHide')
  const { isAnonymous } = useFirebaseUser()
  const bookingDetailsRef = useRef(null)
  const bookingDetailsManageRef = useRef<HTMLDivElement>(null)
  const bookingDetailsOverviewRef = useRef<HTMLDivElement>(null)
  const bookingDetailsRoomRef = useRef<HTMLDivElement>(null)
  const bookingDetailsPriceRef = useRef<HTMLDivElement>(null)
  const isBookingDetailsManageOnScreen = useIsElementOnScreen(
    bookingDetailsManageRef
  )
  const isBookingDetailsOverviewOnScreen = useIsElementOnScreen(
    bookingDetailsOverviewRef
  )
  const isBookingDetailsRoomOnScreen = useIsElementOnScreen(
    bookingDetailsRoomRef
  )
  const isBookingDetailsPriceOnScreen = useIsElementOnScreen(
    bookingDetailsPriceRef
  )
  const constructedElementsOnScreen = [
    isBookingDetailsOverviewOnScreen,
    isBookingDetailsRoomOnScreen,
    isBookingDetailsManageOnScreen,
    isBookingDetailsPriceOnScreen,
  ]
  const constructedRefs = [
    bookingDetailsOverviewRef,
    bookingDetailsRoomRef,
    bookingDetailsManageRef,
    bookingDetailsPriceRef,
  ]
  const activeIndex = constructedElementsOnScreen.findIndex(
    refOnScreen => !!refOnScreen
  )

  const constructedTabs = constructedRefs.map((ref, index) => ({
    label: bookingDetailsTabs?.[index] ?? '',
    onClick: () => ref?.current.scrollIntoView({ behavior: 'smooth' }),
  }))

  const onPrintButtonClick = useReactToPrint({
    content: () => bookingDetailsRef.current,
  })

  return (
    <div className='rebrand flex flex-col gap-4' ref={bookingDetailsRef}>
      <PageLayoutContainer size='medium'>
        <div className={bookingDetailsCss}>
          <div className={bookingDetailsHeaderCss}>
            <div className='hide-on-print'>
              {!isAnonymous && !isTripsHideEnabled && (
                <BookingDetailsBreadcrumbsApollo />
              )}
            </div>
            <BookingDetailsHeroApollo />
          </div>
          <BookingDetailsNav activeIndex={activeIndex} tabs={constructedTabs} />
          <div className={bookingDetailsContentCss}>
            <div
              className={bookingDetailsScrollMarginCss}
              ref={bookingDetailsOverviewRef}
            >
              <BookingDetailsOverview />
            </div>
            <div
              className={bookingDetailsScrollMarginCss}
              ref={bookingDetailsRoomRef}
            >
              <BookingDetailsRoomApollo />
            </div>
            <div
              className={bookingDetailsScrollMarginCss}
              ref={bookingDetailsManageRef}
            >
              <BookingDetailsManageApollo />
            </div>
            <div
              className={bookingDetailsScrollMarginCss}
              ref={bookingDetailsPriceRef}
            >
              <BookingDetailsPriceApollo
                onPrintButtonClick={onPrintButtonClick}
              />
            </div>
            <NativeAppCta />
          </div>
        </div>
      </PageLayoutContainer>
    </div>
  )
}
