import { Radio } from '@travelpass/design-system'
import type { FilterBy } from 'src/__generated__/graphql'
import { initialMaxScore, initialMinScore } from 'src/constants'
import { reviewItems } from './hotelResultsFiltersReviewsConstants'

interface HotelResultsFiltersReviewsProps {
  filters: FilterBy
  onChange?(updatedFilters: FilterBy): void
}

export const HotelResultsFiltersReviews = ({
  filters,
  onChange = () => {},
}: HotelResultsFiltersReviewsProps) => {
  const { minScore } = filters?.customerReviewScore ?? {}

  const onClick = (value: string) => {
    const updatedFilters = { ...filters }
    const isMinScore = value === initialMinScore
    updatedFilters.customerReviewScore = {
      maxScore: initialMaxScore,
      minScore: value,
    }

    if (isMinScore) delete updatedFilters.customerReviewScore

    onChange(updatedFilters)
  }

  return (
    <div className='lg:grid lg:grid-cols-5 lg:gap-1'>
      {reviewItems.map(({ label, value }) => (
        <Radio
          key={value}
          isChecked={
            (!minScore && initialMinScore === value) || minScore === value
          }
          label={label}
          startIcon='thumbUpAlt'
          onClick={() => onClick(value)}
        />
      ))}
    </div>
  )
}
