import { DashboardProfileInfoTravelTrackerQuestion } from './DashboardProfileInfoTravelTrackerQuestion'

export const DashboardProfileInfoTravelTracker = () => (
  <section className='space-y-6' id='dashboard-profile-info-travel-tracker'>
    <header className='space-y-2'>
      <h6 className='type-h6-desktop c-black'>Track Your Travels</h6>
      <p className='type-body-2-desktop c-grey-800'>
        Oh the places you&apos;ve explored! Share your travel stats here.
      </p>
    </header>
    <div className='flex flex-col items-start justify-start gap-2 md:flex-row md:items-center md:gap-6'>
      <DashboardProfileInfoTravelTrackerQuestion
        maxNumber={7}
        name='bragContinents'
        question='Continents'
      />
      <DashboardProfileInfoTravelTrackerQuestion
        maxNumber={199}
        name='bragCountries'
        question='Countries'
      />
      <DashboardProfileInfoTravelTrackerQuestion
        maxNumber={50}
        name='bragStates'
        question='States'
      />
    </div>
  </section>
)
