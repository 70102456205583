import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import {
  TripContainer,
  TripContentWrapper,
  TripMapWrapper,
  TripNavLoading,
  TripTopNavLoading,
} from 'src/pages/trips/components'
import { DateTabsLoading } from '../DateTabs'
import { TimelineEventsLoading } from '../TimelineEvents'
import { TripUpdateDayNameButtonLoading } from '../TripUpdateDayNameButton'

export const TimelineLoading = () => {
  const [searchParams] = useSearchParams()
  const mapFullView = searchParams.get('mapFullView')
  const mobileMapView = mapFullView == 'true'

  return (
    <>
      <TripTopNavLoading />
      <TripContainer>
        {!mobileMapView && (
          <TripContentWrapper>
            <TripNavLoading />
            <div className='-m-t-3'>
              <div className='p-y-3 lg:top-140px lg:z-4 space-y-3 bg-white lg:sticky'>
                <div className='space-y-3'>
                  <div className='flex flex-col gap-2'>
                    <DateTabsLoading />
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <TripUpdateDayNameButtonLoading />
                </div>
              </div>
              <div className='p-t-3'>
                <div className='space-y-6'>
                  <TimelineEventsLoading />
                </div>
              </div>
            </div>
          </TripContentWrapper>
        )}
        <div
          className={classNames('lg:block', {
            hidden: !mobileMapView,
          })}
        >
          <TripMapWrapper isDrawerOpen={false}>
            <Skeleton />
          </TripMapWrapper>
        </div>
      </TripContainer>
    </>
  )
}
