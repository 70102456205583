import type { AvailableIcons } from '@travelpass/design-system'
import {
  bookingsPath,
  competitionAboutPath,
  competitionFaqPath,
  competitionLeaderboardPath,
  dashboardPath,
  explorePath,
  travelersPath,
} from 'src/constants'

export type TITLE_LINK = {
  title: string
  href: string
  icon: AvailableIcons
  tag?: string
  disabled?: boolean
  onClick?: () => void
}

export type LINK_ITEM = {
  title: string
  href: string
  tag?: string
  disabled?: boolean
  onClick?: () => void
}

type MENU_LINK_GROUP = {
  main: TITLE_LINK
  links: LINK_ITEM[]
}

export const MY_HOME_LINKS: MENU_LINK_GROUP = {
  main: {
    title: 'My Home',
    href: '/',
    icon: 'home',
  },
  links: [
    {
      title: 'Feed',
      href: `${dashboardPath}?tab=feed`,
    },
    {
      title: 'Guides',
      href: `${dashboardPath}?tab=guides`,
    },
    {
      title: 'Collections',
      href: `${dashboardPath}?tab=collections`,
    },
    {
      title: 'Bookings',
      href: `${dashboardPath}?tab=bookings`,
    },
    {
      title: 'Account Settings',
      href: `${dashboardPath}?settingsTab=true`,
    },
  ],
}

export const COMPETITION_LINKS: MENU_LINK_GROUP = {
  main: {
    title: 'Contest',
    href: '/competition/about#competition-nav',
    icon: 'businessCenter',
  },
  links: [
    {
      title: 'About',
      href: competitionAboutPath,
    },
    {
      title: 'Leaderboard',
      href: competitionLeaderboardPath,
    },
    {
      title: 'FAQ',
      href: `${competitionFaqPath}#competition-nav`,
    },
  ],
}

export const EXPLORE_LINKS: MENU_LINK_GROUP = {
  main: {
    title: 'Explore',
    href: '/explore',
    icon: 'map',
  },
  links: [
    {
      title: 'Guides',
      href: explorePath,
    },
    {
      title: 'Travelers',
      href: travelersPath,
    },
    {
      title: 'Map',
      href: '/',
      tag: 'Coming Soon',
      disabled: true,
    },
  ],
}

export const PLAN_LINK: TITLE_LINK = {
  href: '/plan',
  icon: 'luggage',
  title: 'Plan',
}

export const FIND_MY_BOOKING_LINK: TITLE_LINK = {
  href: bookingsPath,
  icon: 'search',
  title: 'Find My Booking',
}

export const BOOK_LINKS: MENU_LINK_GROUP = {
  main: {
    title: 'Book',
    href: '/book',
    icon: 'search',
  },
  links: [
    {
      title: 'Hotels',
      href: '/book/hotels',
    },
    {
      title: 'Experiences',
      href: '/book/experiences',
    },
  ],
}
