import { useEffect, useState } from 'react'
import { Icon, useScreenQuery } from '@travelpass/design-system'
import { Outlet, NavLink, useSearchParams, useLocation } from 'react-router-dom'
import { ButtonLink } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { competitionLeaderboardPath } from 'src/constants'
import { EnterCompetitionButton } from './EnterCompetitionButton'
import {
  COMPETITION_IMAGE_SOURCE_PREFIX,
  COMPETITION_SESSION_STORAGE,
} from './competitionConstants'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'
import { hasUserEnteredCompetition } from './utils'
import { hasUserEnteredPhaseTwo } from './utils/hasUserEnteredPhaseTwo'
import { VideoModal } from '../dashboard/common/CompetitionDashboardHeader/modals/VideoModal'

const NavlinkTab = props => (
  <NavLink
    {...props}
    className='text-body-1 c-grey-800 [&.active]:c-forest border-b-solid border-b-grey-200 [&.active]:border-b-valley inline-flex items-center gap-2 border-b-2 bg-transparent p-4 no-underline transition-all hover:bg-black/5 md:px-8 md:py-6 [&.active]:border-b-2'
    state={{ preventScroll: true }}
  />
)

const PageContainer = props => (
  <div
    {...props}
    className={`max-w-340 mx-auto w-full ${props?.className || ''}`}
  >
    {props?.children}
  </div>
)

export const Competition = () => {
  const phase2Flag = useFlag('contestPhase2')
  const competitionPhaseTwoStarted = useFlag('competitionPhaseTwoStarted')
  const { data } = useGetCurrentUserCompetitionInfo()
  const { competitions, status, currentPhase } = {
    ...data?.currentUser?.userProfile?.competitionInfo,
  }
  const hasEnteredPhaseTwo = hasUserEnteredPhaseTwo(competitions, currentPhase)
  const hasEnteredCompetition = hasUserEnteredCompetition(status)
  const [searchParams] = useSearchParams()
  const referer = searchParams.get('referer')
  const [showModal, setShowModal] = useState(false)
  const { isMobileScreen, isTabletScreen, isMobileOrTablet } = useScreenQuery()
  const location = useLocation()

  const getMainImageSource = () => {
    if (isMobileScreen) return '/hero-header-image-mobile.webp'
    if (isTabletScreen) return '/hero-header-image-tablet.webp'
    return '/hero-balloons.webp'
  }

  useEffect(() => {
    if (referer) sessionStorage.setItem(COMPETITION_SESSION_STORAGE, referer)
  }, [referer])

  useEffect(() => {
    const elementId = location.hash.replace('#', '')

    const scrollToElement = () => {
      const element = document.getElementById(elementId)
      if (element) element.scrollIntoView({ behavior: 'smooth' })
      else console.error(`Element with ID ${elementId} not found.`)
    }

    // Try scrolling to the element after a delay
    const timeoutId = setTimeout(scrollToElement, 100)
    return () => clearTimeout(timeoutId)
  }, [location])

  return (
    <div className='bg-warm-grey job-promotion'>
      <div className='max-h-691px pointer-events-none relative mx-auto flex w-full flex-col bg-white bg-[url(https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-bottom-mobile.png)] bg-[position:_bottom_right] bg-no-repeat md:flex-row lg:flex-row-reverse lg:justify-between lg:bg-[url(https://static.travelpass.com/assets/job-campaign-2024/hero-squiggle-bottom.png)] lg:bg-[position:_75%_100%]'>
        <img
          alt='young woman strolling with hot air balloons in background'
          className='lg:max-w-3/5 max-w-386px lg:max-h-691px z-1 h-full max-md:self-center'
          src={`${COMPETITION_IMAGE_SOURCE_PREFIX}${getMainImageSource()}`}
        />
        <img
          alt='traveler polaroid photos'
          className='scale-112 z-2 md:max-w-3/5 absolute hidden max-h-full translate-x--20 translate-y-20 lg:block'
          src={`${COMPETITION_IMAGE_SOURCE_PREFIX}/hero-polaroids.webp`}
        />
        <button
          aria-label='click to play video'
          className='max-w-922px b-none z-1 md:w-421px w-386px h-311px lg:left-unset pointer-events-auto absolute cursor-pointer bg-transparent [align-content:_center] max-md:self-center md:left-0 md:h-full lg:right-0 lg:w-3/5'
          onClick={() => setShowModal(true)}
        >
          <img
            alt=''
            className='md:hidden lg:mx-auto lg:block'
            src={`${COMPETITION_IMAGE_SOURCE_PREFIX}${isMobileScreen ? '/play-icon-tablet.webp' : '/play-icon.webp'}`}
          />
        </button>
        <section>
          <img
            alt=''
            className='max-h-62.5 absolute left-0 top-0'
            src={`${COMPETITION_IMAGE_SOURCE_PREFIX}${isMobileOrTablet ? '/hero-squiggle-top-mobile-tablet.png' : '/hero-squiggle-top.webp'}`}
          />
          <div className='lg:pl-30 md:pr-19 md:mt-20% pl-7% space-y-6 max-md:pb-7 md:space-y-4 md:pl-9 lg:space-y-8'>
            <h1 className='c-forest type-h1 max-md:text-38px'>
              {phase2Flag
                ? hasEnteredPhaseTwo
                  ? "Congrats, You're In The Top 20"
                  : 'Vote To Choose The Winner'
                : 'Win The Trip Of A Lifetime!'}
            </h1>
            <div className='border-orange border-1 hidden w-16 border-solid md:block lg:w-28 lg:border-2'></div>
            {competitionPhaseTwoStarted && !hasEnteredCompetition ? (
              <>
                <p className='c-forest type-body-1 hidden md:block'>
                  The entry period for the $20,000 dream vacation has closed,
                  but you can still see who&apos;s in the lead! Check out the
                  contest leaderboard to vote for your favorites, follow the top
                  travel Guides, and get inspired for your next adventure.
                </p>
                <ButtonLink
                  className='pointer-events-auto'
                  to={competitionLeaderboardPath}
                >
                  View Leaderboard
                </ButtonLink>
              </>
            ) : (
              <>
                <p className='c-forest type-body-1 hidden md:block'>
                  {phase2Flag
                    ? hasEnteredPhaseTwo
                      ? 'Get the most votes by November 21 to win the $20,000 dream vacation!'
                      : "We're giving away $20,000 for a dream vacation! Check out the 20 travelers who are still in the running and vote for your favorites."
                    : 'You could win a $20,000 dream vacation! To enter, just set up a public profile on Travelpass.com, create three travel Guides, and get votes (see timeline and entry requirements below).'}
                </p>
                <EnterCompetitionButton
                  competitionPhaseTwoStarted={competitionPhaseTwoStarted}
                  hasEnteredCompetition={hasEnteredCompetition}
                  hasEnteredPhaseTwo={hasEnteredPhaseTwo}
                />
              </>
            )}
          </div>
        </section>
      </div>
      <PageContainer>
        <nav
          aria-label='Competition pages'
          className='mt-16 text-center'
          id='competition-nav'
        >
          <NavlinkTab to='/competition/about#competition-nav'>
            <Icon name='language' /> About
          </NavlinkTab>
          <NavlinkTab to={competitionLeaderboardPath}>
            <Icon name='thumbUpOffAlt' /> Leaderboard
          </NavlinkTab>
          <NavlinkTab to='/competition/faq#competition-nav'>
            <Icon name='questionMark' />
            FAQ
          </NavlinkTab>
        </nav>
      </PageContainer>
      <PageContainer className='py-10'>
        <Outlet />
      </PageContainer>
      <footer className='h-473px c-white my-auto space-y-6 bg-[url(https://static.travelpass.com/assets/job-campaign-2024/footer.webp)] bg-cover bg-center bg-no-repeat text-center [align-content:_center]'>
        <h1 className='type-h1 c-white'>Win Your Dream Vacation</h1>
        <p>A $20,000 trip anywhere in the world could be yours.</p>
        <EnterCompetitionButton
          competitionPhaseTwoStarted={competitionPhaseTwoStarted}
          hasEnteredCompetition={hasEnteredCompetition}
          hasEnteredPhaseTwo={hasEnteredPhaseTwo}
          variant='valley'
        />
      </footer>
      {showModal && (
        <VideoModal
          src='https://www.youtube.com/embed/z3VhyBhdu1E?si=HbTxWxnpaxIPuUjg&autoplay=1'
          title='Travelpass Friends and Family Contest - How It Works'
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  )
}
