import type { ChangeEvent, KeyboardEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Checkbox, KeyCode } from '@travelpass/design-system'
import type { Note } from 'src/__generated__/graphql'
import { isLastIndex } from 'src/utils'
import {
  getOverviewNoteListAsArray,
  getOverviewNoteListAsString,
  initialNoteListItem,
  type OverviewNoteListItem,
} from '../OverviewNotes'

interface OverviewNoteFormListProps {
  note: Note
  onNoteChange(updatedNote: Note): void
}

export const OverviewNoteFormList = ({
  note,
  onNoteChange,
}: OverviewNoteFormListProps) => {
  const listRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [items, setItems] = useState<OverviewNoteListItem[]>([
    {
      ...initialNoteListItem,
    },
  ])
  const { body } = note ?? {}
  const { current: listRefCurrent } = listRef ?? {}

  useEffect(() => {
    listRefCurrent
      ?.querySelectorAll('input[type="text"]')
      ?.[activeIndex]?.focus()
  }, [activeIndex])

  useEffect(() => {
    setItems(getOverviewNoteListAsArray(body))
  }, [body])

  const onCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault()
    const { currentTarget } = event ?? {}
    const updatedItems = [...items]

    if (currentTarget.type === 'checkbox') {
      updatedItems[index].isChecked = currentTarget.checked
    } else {
      updatedItems[index].title = currentTarget.value
    }

    onNoteChange({
      ...note,
      body: getOverviewNoteListAsString(items),
    })
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, currentTarget } = event ?? {}
    const { value } = currentTarget ?? {}
    const hasPreviousItem = index > 0
    const hasNextItem = !isLastIndex(items, index)
    const isValueEmpty = !value.trim()

    if (key === KeyCode.BACKSPACE && hasPreviousItem && isValueEmpty) {
      setActiveIndex(index - 1)
      setItems(previousItems =>
        previousItems.filter((_, itemIndex) => index !== itemIndex)
      )
    }

    if (key === KeyCode.DELETE && hasNextItem && isValueEmpty) {
      setItems(previousItems =>
        previousItems.filter((_, itemIndex) => index !== itemIndex)
      )
    }

    if (key === KeyCode.ENTER) {
      event.preventDefault()
      setActiveIndex(index + 1)

      setItems(previousItems => {
        const updatedItems = [...previousItems]
        updatedItems.splice(index + 1, 0, {
          ...initialNoteListItem,
        })

        return updatedItems
      })
    }

    if (key === KeyCode.UP && hasPreviousItem) {
      setActiveIndex(index - 1)
    }

    if (key === KeyCode.DOWN && hasNextItem) {
      setActiveIndex(index + 1)
    }
  }

  return (
    <div ref={listRef}>
      {items.map(({ isChecked, title }, index) => (
        <div key={index} className='flex flex-row items-center'>
          <Checkbox
            isChecked={isChecked}
            label={' '}
            onChange={event => onCheckboxChange(event, index)}
          />
          <input
            autoFocus={index === 0}
            className='b-none type-body-2-desktop flex-grow bg-transparent p-0 outline-none'
            placeholder={index === activeIndex ? 'Add an item' : ''}
            type='text'
            value={title ?? ''}
            onChange={event => onCheckboxChange(event, index)}
            onFocus={() => setActiveIndex(index)}
            onKeyDown={event => onKeyDown(event, index)}
          />
        </div>
      ))}
    </div>
  )
}
