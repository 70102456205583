import { datadogRum } from '@datadog/browser-rum'
import { env, isProdEnv } from 'src/utils'
import sessionSampleRate from './utils'

const initRUM = () => {
  datadogRum.init({
    applicationId: 'b73fd73a-d62a-42d8-81ab-0f4af285da56',
    clientToken: 'pub2c3edffc88a6584f0d769ccb28ecb4c8',
    site: 'datadoghq.com',
    service: 'travelpass-frontend',
    env,
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: sessionSampleRate(false),
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

if (isProdEnv) {
  initRUM()
}
