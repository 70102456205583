import { useState } from 'react'

enum STEPS {
  CONTACT = 1,
  DETAILS = 2,
  PAYMENT = 3,
}

export const useExperienceStepFlow = () => {
  const [step, setStep] = useState(STEPS.CONTACT)

  const to = {
    contact: () => setStep(STEPS.CONTACT),
    details: () => setStep(STEPS.DETAILS),
    payment: () => setStep(STEPS.PAYMENT),
  }
  const passed = {
    contact: step >= STEPS.DETAILS,
    details: step >= STEPS.PAYMENT,
  }

  const is = {
    contact: step === STEPS.CONTACT,
    details: step === STEPS.DETAILS,
    payment: step === STEPS.PAYMENT,
  }

  return {
    current: step,
    to,
    is,
    passed,
  }
}
