import type { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import { resultsSegment, travelersResultsPath } from 'src/constants'

export const handleTravelerSearch = (
  query: string,
  navigate: NavigateFunction,
  setSearchParams: SetURLSearchParams
) => {
  if (window.location.pathname.includes(resultsSegment)) {
    setSearchParams?.(sp => {
      sp.set('q', query)
      return sp
    })
  } else {
    navigate(`${travelersResultsPath}?q=${query}`)
  }
}
