import classNames from 'classnames'
import { maxReviewScore } from 'src/constants/user'
import type {
  ReviewBadgeSize,
  ReviewBadgeVariant,
} from './reviewBadgeConstants'

interface ReviewBadgeProps {
  customerReviewScore: number
  size?: ReviewBadgeSize
  maxScore?: number
  totalCustomerReviews?: number
  variant?: ReviewBadgeVariant
}

export const ReviewBadge = ({
  customerReviewScore,
  maxScore = maxReviewScore,
  size = 'medium',
  totalCustomerReviews,
  variant = 'filled',
}: ReviewBadgeProps) => {
  const reviewGrade = customerReviewScore / 5

  // Source: https://linear.app/travelpass/issue/BOOK-1954/experience-details-page-updates-display-logic-for-review-scores-wip
  const createCustomerRating = () => {
    if (reviewGrade === 1) return 'Excellent'
    else if (reviewGrade >= 0.8) return 'Very Good'
    else if (reviewGrade >= 0.6) return 'Good'
    else return 'Poor'
  }

  const customerRating = createCustomerRating()

  return (
    <div className='flex flex-row items-center gap-2'>
      <div
        className={classNames(
          'color-white flex flex-row items-center justify-center gap-1 rounded-md leading-none',
          variant != 'text' && {
            'px-1.5 py-[5px]': size === 'large',
            'px-1.5 py-1': size === 'medium',
            'px-1.5 py-0.5': size === 'small',
          },
          variant != 'text' && {
            'bg-tealMedium': reviewGrade >= 0.8,
            'bg-gray': reviewGrade < 0.8,
          },
          { 'leading-5': size === 'large' }
        )}
      >
        <div>
          {customerReviewScore}/{maxScore}
        </div>
      </div>
      {totalCustomerReviews && (
        <p
          className={classNames({
            'text-tealMedium': reviewGrade >= 0.8,
            'text-gray-900': reviewGrade < 0.8,
          })}
        >
          {customerRating}{' '}
          <span className='text-gray'>{`(${totalCustomerReviews})`}</span>
        </p>
      )}
    </div>
  )
}
