import { useState } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { gql } from 'src/__generated__'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import { useMiscellaneousPlacesData } from './useMiscellaneousPlacesData'
import { groupMiscellaneousData } from '../utils'

const nodeQuery = gql(`
  query MiscellaneousEventData($eventId: ID!) {
    node(id: $eventId) {
      ... on Event {
        id
        addresses {
          id
          addressLine1
          addressLine2
          city
          country
          googlePlaceId
          lat
          long
          state
          zipcode
        }
        endDate
        imageUrl
        name
        notes
        startDate
        status
        useEventTime
      }
    }
  }
`)

const defaultDataSources = {
  hasPlacesData: false,
}

export const useMiscellaneousData = ({
  eventId,
  tripId,
}: {
  eventId: string | null
  tripId: string | null
}) => {
  const [dataSources, setDataSources] =
    useState<typeof defaultDataSources>(defaultDataSources)
  const {
    hasError: tripError,
    isLoading: tripLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    getMiscellaneousPlacesData,
    data: placeData,
    loading: placeLoading,
    error: placeError,
  } = useMiscellaneousPlacesData()
  const {
    data: eventNodeData,
    loading: eventLoading,
    error: eventError,
  } = useQuery(nodeQuery, {
    variables: { eventId: eventId ?? 'Failed to load eventId' },
    skip: !eventId,
    onCompleted: ({ node }) => {
      const eventNode = node?.__typename === 'Event' ? node : null
      const placeId = eventNode?.addresses?.[0]?.googlePlaceId ?? null

      const updatedDataSources: typeof defaultDataSources =
        cloneDeep(defaultDataSources)

      if (placeId) {
        updatedDataSources.hasPlacesData = true

        getMiscellaneousPlacesData({
          variables: {
            placeDetailsRequest: {
              isHotelSearch: false,
              placeId,
            },
          },
        })
      }

      setDataSources(updatedDataSources)
    },
  })
  const loading = eventLoading || placeLoading || tripLoading
  const error = eventError || placeError || tripError
  const eventData =
    eventNodeData?.node?.__typename === 'Event' ? eventNodeData.node : null
  const data = loading
    ? null
    : groupMiscellaneousData({
        eventData,
        placeData: dataSources?.hasPlacesData ? placeData : null,
        tripData: tripDetailsData,
      })

  return {
    data,
    tripData: tripDetailsData,
    loading,
    error,
  }
}
