import { Button, Illustration } from '@travelpass/design-system'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { tripsPath } from 'src/constants'

export const TripShareCTA = () => {
  return (
    <div className='bg-beach border-beachDark rounded-3 flex flex-col items-center space-y-5 border-2 border-solid p-6'>
      <section className='space-y-4 text-center'>
        <h3 className='type-h3'>Share your travel tips with the world</h3>
        <p className='type-body-1'>
          Showcase your adventures and reveal incredible destinations by
          publishing your trips
        </p>
        <Illustration name='jeep' />
      </section>
      <Button
        aria-label='Learn more about trips'
        variant='filled'
        onClick={() => {
          pushDataToDataLayer('guide_search_trip_share_cta')
          window.open(tripsPath, '_blank')
        }}
      >
        learn more
      </Button>
    </div>
  )
}
