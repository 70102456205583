import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { GuideStatus } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const archiveGuideDraft = gql(`
  mutation ArchiveGuideDraftMutationInGuideDraftOld($input: ArchiveGuideDraftInput!, $userGuidesArgs: UserGuidesArgs!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    archiveGuideDraft(input: $input) {
      guideDraft {
        id
        guide {
          id
          name
          status
          updatedAt
        }
        owner {
          id
          userGuides(first: 5, userGuidesArgs: $userGuidesArgs) {
            edges {
              node {
                id
              }
            }
            totalCount
          }
          userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
            totalCount
          }
        }
        status
      }
    }
  }
`)

export const useArchiveGuideDraftMutationOld = () =>
  useMutation(archiveGuideDraft, {
    onCompleted: data => {
      const { guide, id, owner } = data?.archiveGuideDraft?.guideDraft ?? {}

      pushDataToDataLayer('guide_draft_delete', {
        guide_draft_id: id,
        guide_id: guide?.id,
        guide_name: guide?.name,
        guide_status: GuideStatus.Archived,
        trigger_url: window.location.href,
        updated_at: guide?.updatedAt,
        user_id: owner?.id,
      })
    },
  })
