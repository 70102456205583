import { useState } from 'react'
import { Icon, Illustration } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { PageLayoutContainer } from 'src/common/components'
import type { DestinationType } from '../../destinationConstants'

interface DestinationLocationAboutProps {
  destination: DestinationType
}

export const DestinationLocationAbout = ({
  destination,
}: DestinationLocationAboutProps) => {
  const [hasImageError, setHasImageError] = useState(false)
  const { address, descriptions, images } = destination ?? {}
  const { city } = address ?? {}
  const description = descriptions?.[0]
  const image = images?.[1]
  const title = `Why Visit ${city}?`

  return (
    <div className='bg-white'>
      <PageLayoutContainer>
        <div className='flex-items-center flex-column flex flex-col gap-14 md:gap-16 lg:flex-row lg:gap-24'>
          <div className='flex-grow-1 lg:p-l-8 flex flex-col sm:gap-4 lg:gap-9'>
            <h2 className='type-h1 flex-items-center flex-justify-center lg:flex-justify-start relative flex w-full'>
              <span className='color-valley top-5px absolute left-[-40px] hidden lg:block'>
                <Icon
                  name='navigationCompass'
                  size={isMobile ? 'medium' : 'large'}
                />
              </span>
              {title}
            </h2>
            <p className='type-body-1 color-grey-900 lg:max-w-422px w-full'>
              {description}
            </p>
          </div>
          {!hasImageError && (
            <div className='transform-rotate-7deg max-w-80% md:max-w-595px p-r-0 lg:p-r-20px relative w-full'>
              <div className='transform-rotate-216deg absolute left-[-20px] top-[-23px] md:left-[-57px]'>
                <div className='c-beachDark w-22 hidden lg:block'>
                  <Illustration name='splash' />
                </div>
                <div className='c-beachDark w-37px md:w-22 block lg:hidden'>
                  <Illustration name='splash' />
                </div>
              </div>
              <img
                alt={title}
                className='rounded-6 w-full'
                src={image}
                onError={() => setHasImageError(true)}
              />
            </div>
          )}
        </div>
      </PageLayoutContainer>
    </div>
  )
}
