import { Button, Divider, Icon, Illustration } from '@travelpass/design-system'
import type { GetTripOverviewQueryInTripsQuery } from 'src/__generated__/graphql'
import { pushTripAddNoteClickToDataLayer } from 'src/pages/trips/utils'
import { OverviewNote } from './OverviewNote'

interface OverviewNotesProps {
  activeNoteId: string
  notes: GetTripOverviewQueryInTripsQuery['getTrip']['notes']
  onActiveNoteIdChange(updatedNoteId: string): void
  onOpen(): void
  tripId: string
  tripName: string
}

export const OverviewNotes = ({
  activeNoteId,
  notes,
  onActiveNoteIdChange,
  onOpen,
  tripId,
  tripName,
}: OverviewNotesProps) => {
  const onNoteClick = (updatedActiveNoteId: string) => {
    onOpen()
    onActiveNoteIdChange(updatedActiveNoteId)
    pushTripAddNoteClickToDataLayer({
      tripId,
      tripName,
      triggerVariant: 'TripOverview AddNoteButton',
    })
  }

  return (
    <section className='space-y-3'>
      <header className='space-y-2'>
        <div className='flex flex-row items-center justify-between gap-3'>
          <h2 className='type-h4'>Notes</h2>
          <div className='hidden lg:block'>
            <Button
              aria-label='Add a note to your trip'
              startIcon='add'
              variant='text'
              onClick={() => onNoteClick('')}
            >
              Add
            </Button>
          </div>
        </div>
        <Divider />
      </header>
      {notes?.length === 0 && (
        <button
          aria-label='Add a note to your trip'
          className='b-solid b-transparent b-2 bg-mint-light m-none p-l-9 p-y-2 rounded-2 hover:b-forest-light flex w-full cursor-pointer flex-row items-center gap-4 outline-none'
          onClick={onOpen}
        >
          <div className='w-25'>
            <Illustration name='notes' />
          </div>
          <div className='color-new-forest flex flex-row items-center gap-2'>
            <Icon name='add' />
            <span className='color-new-forest type-body-1-medium'>
              Add Note, Link, or Checklist
            </span>
          </div>
        </button>
      )}
      {notes?.map((note, index) => (
        <OverviewNote
          key={index}
          activeNoteId={activeNoteId}
          note={note}
          onClick={onNoteClick}
        />
      ))}
    </section>
  )
}
