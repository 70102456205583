import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getGuidesEventMap = gql(`
  query GetGuidesEventMapQueryInGuidesEventMap($id: ID!) {
    getPublishedEvent(id: $id) {
      id
      addresses {
        id
        addressLine1
        city
        country
        googlePlaceId
        state
      }
      description
      imageUrl
      name
      notes
      guide {
        id
        name
        ownerProfile {
          id
          accountHandle
          displayName
          profileImageUrl
        }
      }
      type
    }
  }
`)

export const useGetGuidesEventMapLazyQuery = () =>
  useLazyQuery(getGuidesEventMap)
