import { NetworkStatus } from '@apollo/client'
import dayjs from 'dayjs'
import isEmpty from 'lodash.isempty'
import type {
  Facet,
  FilterBy,
  Options,
  SearchHotelsArgs,
  SearchHotelsQueryInHotelResultsQueryVariables,
} from 'src/__generated__/graphql'
import { SortBy, Unit } from 'src/__generated__/graphql'
import {
  initialMaxScore,
  initialMaxStars,
  initialMinStars,
  initialSize,
} from 'src/constants'
import {
  generateHotelDetailsUrl,
  getAdultGuests,
  getArrivalDate,
  getDepartureDate,
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGeocoderPlaceName,
  getKidGuests,
  getKidGuestsAges,
  getTracker,
  stringToBoolean,
  stringToNumber,
  validateDates,
} from 'src/utils'
import {
  HotelResultsListView,
  HotelResultsSearchParams,
  filterKeys,
  initialSort,
} from './hotelResultsConstants'
import type { GetHotelResultsCardUrl } from './hotelResultsTypes'

const getHotelResultsBucketsByBrand = (facets: Facet[]) =>
  facets?.find(facet => facet.name === 'brand')?.buckets ?? []

const getHotelResultsCardUrl = ({
  hotelResultsCardData,
  searchParams,
}: GetHotelResultsCardUrl): string => {
  const { hotelAddress, id, name } = hotelResultsCardData ?? {}
  const { city, state, stateAbbreviation } = hotelAddress ?? {}

  return generateHotelDetailsUrl({
    adults: getAdultGuests(searchParams.get(HotelResultsSearchParams.adults)),
    arrival: getArrivalDate({
      date: searchParams.get(HotelResultsSearchParams.arrival),
    }),
    city,
    departure: getDepartureDate({
      date: searchParams.get(HotelResultsSearchParams.departure),
    }),
    kids: getKidGuests(searchParams.get(HotelResultsSearchParams.kids)),
    id,
    name,
    state,
    stateAbbreviation,
  })
}

const getHotelResultsFiltersFromSearchParams = (
  searchParams: URLSearchParams
): FilterBy => {
  const filters: FilterBy = {}
  const brands = searchParams.get(HotelResultsSearchParams.brands)
  const includeAccessibility = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeAccessibility) ?? ''
  )
  const includeAirportShuttle = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeAirportShuttle) ?? ''
  )
  const includeBreakfast = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeBreakfast) ?? ''
  )
  const includeFitnessCenter = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeFitnessCenter) ?? ''
  )
  const includeHotTub = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeHotTub) ?? ''
  )
  const includeNonSmoking = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeNonSmoking) ?? ''
  )
  const includeParking = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeParking) ?? ''
  )
  const includePetFriendly = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includePetFriendly) ?? ''
  )
  const includePool = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includePool) ?? ''
  )
  const includeSpaServices = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeSpaServices) ?? ''
  )
  const includeWifi = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.includeWifi) ?? ''
  )
  const maxPrice = stringToNumber(
    searchParams.get(HotelResultsSearchParams.maxPrice)
  )
  const maxScore = searchParams.get(HotelResultsSearchParams.maxScore)
  const maxStars = searchParams.get(HotelResultsSearchParams.maxStars)
  const minPrice = stringToNumber(
    searchParams.get(HotelResultsSearchParams.minPrice)
  )
  const minScore = searchParams.get(HotelResultsSearchParams.minScore)
  const minStars = searchParams.get(HotelResultsSearchParams.minStars)

  if (brands) {
    try {
      filters.brands = JSON.parse(decodeURIComponent(brands))
    } catch (error) {
      console.error(error)
    }
  }

  if (includeAccessibility) filters.includeAccessibility = includeAccessibility

  if (includeAirportShuttle)
    filters.includeAirportShuttle = includeAirportShuttle

  if (includeBreakfast) filters.includeBreakfast = includeBreakfast

  if (includeFitnessCenter) filters.includeFitnessCenter = includeFitnessCenter

  if (includeHotTub) filters.includeHotTub = includeHotTub

  if (includeNonSmoking) filters.includeNonSmoking = includeNonSmoking

  if (includeParking) filters.includeParking = includeParking

  if (includePetFriendly) filters.includePetFriendly = includePetFriendly

  if (includePool) filters.includePool = includePool

  if (includeSpaServices) filters.includeSpaServices = includeSpaServices

  if (includeWifi) filters.includeWifi = includeWifi

  if (maxPrice || minPrice) {
    filters.priceRange = {
      maxPrice: maxPrice ?? minPrice + 5000,
      minPrice: minPrice ?? 0,
    }
  }

  if (maxScore || minScore) {
    filters.customerReviewScore = {
      minScore,
      maxScore: maxScore ?? initialMaxScore,
    }
  }

  if (maxStars || minStars) {
    filters.starRating = {
      maxStars: maxStars ?? initialMaxStars,
      minStars: minStars ?? initialMinStars,
    }
  }

  return filters
}

const getHotelResultsListViewFromSearchParams = ({
  isMobile = false,
  searchParams,
}: {
  isMobile?: boolean
  searchParams: URLSearchParams
}) => {
  if (isMobile) return HotelResultsListView.grid

  const listView = searchParams.get(HotelResultsSearchParams.listView)

  return HotelResultsListView[listView ?? HotelResultsListView.grid]
}

const getHotelResultsPathName = ({
  networkStatus,
  searchParams,
}: {
  networkStatus: NetworkStatus
  searchParams: URLSearchParams
}): string => {
  if (networkStatus === NetworkStatus.ready)
    return getGeocoderPlaceName(
      searchParams.get(HotelResultsSearchParams.location)
    )

  if (networkStatus === NetworkStatus.error) return 'Error'

  return 'Loading'
}

const getHotelResultsVariablesFromSearchParams = ({
  options = {},
  searchParams,
}: {
  options?: Options | null
  searchParams: URLSearchParams
}): SearchHotelsQueryInHotelResultsQueryVariables => {
  const adults = getAdultGuests(
    searchParams.get(HotelResultsSearchParams.adults)
  )
  const arrival = getArrivalDate({
    date: searchParams.get(HotelResultsSearchParams.arrival),
  })
  const bounds = searchParams.get(HotelResultsSearchParams.mapBounds)
  const childAges = getKidGuestsAges(
    searchParams.get(HotelResultsSearchParams.kids) ?? ''
  )
  const departure = getDepartureDate({
    date: searchParams.get(HotelResultsSearchParams.departure),
  })
  const filters = getHotelResultsFiltersFromSearchParams(searchParams)
  const latitude = getGeocoderLatitude(
    searchParams.get(HotelResultsSearchParams.latitude)
  )
  const longitude = getGeocoderLongitude(
    searchParams.get(HotelResultsSearchParams.longitude)
  )
  const placeId = searchParams.get(HotelResultsSearchParams.placeId) ?? ''
  const placeShortName =
    searchParams.get(HotelResultsSearchParams.placeShortName) ?? ''
  const placeType = searchParams.get(HotelResultsSearchParams.placeType) ?? ''
  const sort =
    Object.values(SortBy).find(
      value => value === searchParams.get(HotelResultsSearchParams.sort)
    ) ?? initialSort
  const tracker = getTracker(
    getGeocoderPlaceName(searchParams.get(HotelResultsSearchParams.location))
  )

  const { validArrival, validDeparture } = validateDates({ arrival, departure })

  const searchHotelsArgs: SearchHotelsArgs = {
    adults,
    arrival: getArrivalDate({ date: validArrival }),
    childAges,
    departure: getDepartureDate({ date: validDeparture }),
    options,
    size: placeId ? 24 : initialSize,
    sort,
    tracker,
  }

  if (!isEmpty(filters)) searchHotelsArgs.filters = filters

  if (placeType === 'administrative_area_level_1') {
    searchHotelsArgs.searchTerms = {
      stateCode: placeShortName,
    }
  } else if (placeType === 'country') {
    searchHotelsArgs.searchTerms = {
      countryCode: placeShortName,
    }
  } else if (placeType === 'street_address' || placeType === 'street_number') {
    searchHotelsArgs.latitude = latitude
    searchHotelsArgs.longitude = longitude
    searchHotelsArgs.radius = 5
  } else if (placeType === 'locality') {
    searchHotelsArgs.latitude = latitude
    searchHotelsArgs.longitude = longitude
    searchHotelsArgs.radius = 20
  } else if (placeType === 'neighborhood') {
    searchHotelsArgs.latitude = latitude
    searchHotelsArgs.longitude = longitude
    searchHotelsArgs.radius = 10
  } else if (bounds) {
    try {
      const { east, north, south, west } =
        JSON.parse(decodeURIComponent(bounds)) ?? {}
      searchHotelsArgs.boundingBox = {
        northwest: {
          latitude: stringToNumber(north),
          longitude: stringToNumber(west),
        },
        southeast: {
          latitude: stringToNumber(south),
          longitude: stringToNumber(east),
        },
      }
    } catch (error) {
      console.error(error)
      searchHotelsArgs.latitude = latitude
      searchHotelsArgs.longitude = longitude
      searchHotelsArgs.radius = 20
    }
  } else {
    searchHotelsArgs.latitude = latitude
    searchHotelsArgs.longitude = longitude
    searchHotelsArgs.radius = 20
  }

  return {
    searchHotelsArgs,
    specificLodgingArgs: {
      adults,
      arrival: getArrivalDate({ date: validArrival }),
      childAges,
      departure: getDepartureDate({ date: validDeparture }),
      googlePlaceId: placeId,
      options,
      tracker,
    },
    unit: Unit.Imperial,
  }
}

const setHotelResultsFiltersSearchParams = ({
  filters,
  onChange,
  searchParams,
}: {
  filters: FilterBy
  onChange?(updatedSearchParams: URLSearchParams): void
  searchParams: URLSearchParams
}) => {
  filterKeys.forEach(key => {
    const filter = filters?.[key]

    if (!filter) {
      searchParams.delete(HotelResultsSearchParams?.[key])
    } else if (typeof filter === 'object' && !isEmpty(filter)) {
      if (key === 'customerReviewScore') {
        const { maxScore, minScore } = filter ?? {}

        if (maxScore)
          searchParams.set(
            HotelResultsSearchParams.maxScore,
            maxScore?.toString()
          )

        if (minScore)
          searchParams.set(
            HotelResultsSearchParams.minScore,
            minScore?.toString()
          )
      } else if (key === 'priceRange') {
        const { maxPrice, minPrice } = filter ?? {}

        if (maxPrice)
          searchParams.set(
            HotelResultsSearchParams.maxPrice,
            maxPrice?.toString()
          )

        if (minPrice)
          searchParams.set(
            HotelResultsSearchParams.minPrice,
            minPrice?.toString()
          )
      } else if (key === 'starRating') {
        const { maxStars, minStars } = filter ?? {}

        if (maxStars)
          searchParams.set(
            HotelResultsSearchParams.maxStars,
            maxStars?.toString()
          )

        if (minStars)
          searchParams.set(
            HotelResultsSearchParams.minStars,
            minStars?.toString()
          )
      } else {
        try {
          searchParams.set(
            HotelResultsSearchParams?.[key],
            encodeURIComponent(JSON.stringify(filter))
          )
        } catch (error) {
          console.error(error)
        }
      }
    } else {
      searchParams.set(HotelResultsSearchParams?.[key], filter.toString())
    }
  })

  onChange?.(searchParams)
}

export {
  getHotelResultsBucketsByBrand,
  getHotelResultsCardUrl,
  getHotelResultsFiltersFromSearchParams,
  getHotelResultsListViewFromSearchParams,
  getHotelResultsPathName,
  getHotelResultsVariablesFromSearchParams,
  setHotelResultsFiltersSearchParams,
}
