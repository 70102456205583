import { useState } from 'react'
import { Button, ModalNext, useSnackbar } from '@travelpass/design-system'
import dayjs from 'dayjs'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getProfilePageUrl, copyToClipboard } from 'src/utils'

enum SHARE_TYPES {
  copy = 'copy',
  linkedin = 'linkedin',
  facebook = 'facebook',
  twitter = 'twitter',
}

export const useShareProfileModal = (accountHandle: string) => {
  const { addSuccessSnack } = useSnackbar()
  const [isShareModalOpened, setIsShareModalOpened] = useState(false)
  const openShareProfile = () => setIsShareModalOpened(true)
  const closeShareProfile = () => setIsShareModalOpened(false)

  const intURL = 'https://int.travelpass.com'
  const originURL = window?.location?.origin
  const baseURL = originURL.includes('localhost') ? intURL : originURL

  const profileURL = baseURL + getProfilePageUrl(accountHandle)

  const inputCss =
    'bg-transparent outline-none py-3.5 px-0 color-current-color text-4 placeholder:color-grey-700 w-full flex flex-row items-center b-1px b-solid b-grey-300 rd-2 my-0.5 p-0 pl-3.5 gap-2.5 [&:focus-within]:b-canyon c-zinc-400'

  const onProfileURLCopy = () => {
    copyToClipboard(profileURL)
    onProfileShare(SHARE_TYPES.copy)
    closeShareProfile()
    addSuccessSnack({
      title: 'Profile link copied',
    })
  }

  const onProfileShare = (type: SHARE_TYPES) => {
    pushDataToDataLayer('competitionShare', {
      url: profileURL,
      type,
      timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
    })
  }

  const onSocialProfileShare = (type: Exclude<SHARE_TYPES, 'copy'>) => {
    onProfileShare(type)
    closeShareProfile()
  }

  const Modal = () =>
    isShareModalOpened ? (
      <ModalNext
        className='[&>div]:px-10'
        size='small'
        title='Share Profile'
        onClose={closeShareProfile}
      >
        <div className='space-y-8 pb-8'>
          <div className='relative'>
            <input readOnly className={inputCss} value={profileURL} />
            <div className='absolute bottom-0 right-0 top-0 flex items-center'>
              <Button startIcon='linkDiagonal' onClick={onProfileURLCopy}>
                Copy
              </Button>
            </div>
          </div>
          <div className='flex gap-2 [&>*]:w-full'>
            <FacebookShareButton
              url={profileURL}
              onShareWindowClose={() =>
                onSocialProfileShare(SHARE_TYPES.facebook)
              }
            >
              <div className='flex items-center gap-4 rounded-md bg-[#0965fe] px-5 py-2 font-medium'>
                <FacebookIcon size={32} />
                <span className='c-white'>Share</span>
              </div>
            </FacebookShareButton>
            <TwitterShareButton
              url={profileURL}
              onShareWindowClose={() =>
                onSocialProfileShare(SHARE_TYPES.twitter)
              }
            >
              <div className='flex items-center gap-4 rounded-md bg-black px-5 py-2 font-medium'>
                <XIcon size={32} />
                <span className='c-white'>Tweet</span>
              </div>
            </TwitterShareButton>
            <LinkedinShareButton
              url={profileURL}
              onShareWindowClose={() =>
                onSocialProfileShare(SHARE_TYPES.linkedin)
              }
            >
              <div className='flex items-center gap-4 rounded-md bg-[#0077b5] px-5 py-2 font-medium'>
                <LinkedinIcon size={32} />
                <span className='c-white'>Share</span>
              </div>
            </LinkedinShareButton>
          </div>
        </div>
      </ModalNext>
    ) : null

  return { Modal, open: openShareProfile, close: closeShareProfile }
}
