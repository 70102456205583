import { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import { Divider, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { Guide } from 'src/__generated__/graphql'
import type { GuidePaginatedEventCategory, GuideOwner } from '../../types'
import type { UseGetGuideEventsQuery } from '../../useGetGuideEventsQuery'
import { GuideEvents } from '../GuideEvents'

interface GuideSectionProps {
  onGetMoreResults: UseGetGuideEventsQuery['onGetMoreResults']
  onSelectedIdChange(updatedSelectedId: string): void
  owner: GuideOwner
  paginatedEventCategory?: GuidePaginatedEventCategory
  selectedId: string
}

export const GuideSection = ({
  onGetMoreResults,
  onSelectedIdChange,
  owner,
  paginatedEventCategory,
  selectedId,
}: GuideSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { description, id, name, publishedEvents } =
    paginatedEventCategory ?? {}

  if (isEmpty(publishedEvents?.edges)) return

  return (
    <Accordion
      index={Number(!isExpanded)}
      onChange={() => setIsExpanded(previousIsExpanded => !previousIsExpanded)}
    >
      <AccordionItem className='space-y-3 md:space-y-4'>
        {/** @todo look into tabbing/outline */}
        <AccordionButton className='b-none w-full cursor-pointer space-y-3 bg-transparent p-0 text-left'>
          <div className='flex flex-row items-center justify-between'>
            <div className='space-y-1.5'>
              <h2 className='c-black type-h4-mobile lg:type-h2-desktop'>
                {name}
              </h2>
              <Divider className='w-48px lg:w-64px bg-valley h-2px lg:h-4px' />
            </div>
            <div className='c-valley'>
              <Icon name={isExpanded ? 'arrowUpIos' : 'arrowDownIos'} />
            </div>
          </div>
          {description && (
            <p className='c-grey-800 m-t-3 type-body-2-desktop'>
              {description}
            </p>
          )}
        </AccordionButton>
        <div className='hidden md:block'>
          <Divider />
        </div>
        <AccordionPanel>
          <div className='relative space-y-3 md:space-y-5'>
            <GuideEvents
              owner={owner}
              paginatedEventCategoryId={id}
              publishedEventConnection={publishedEvents}
              selectedId={selectedId}
              onGetMoreResults={onGetMoreResults}
              onSelectedIdChange={onSelectedIdChange}
            />
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
