import { useEffect, useState } from 'react'
import { DateRangePicker, type DateRange } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { GRAPHQL_DATE_FMT } from 'src/utils'
import {
  useExperienceDetailsSearchParams,
  ExperiencesDetailsSearchParams,
} from './useExperienceDetailsSearchParams'

const TODAY = dayjs()

const useUpdateAvailabilityArrivalAndDepartureSearchParams = () => {
  const [, updateSearchParams] = useSearchParams()
  return (range: DateRange, includeTraveDate) => {
    if (range?.to) {
      // update search params with valid final selection to trigger query update
      updateSearchParams(sp => {
        if (includeTraveDate) {
          sp.set(
            ExperiencesDetailsSearchParams.travelDate,
            range.from.format(GRAPHQL_DATE_FMT)
          )
        }
        sp.set(
          ExperiencesDetailsSearchParams.arrival,
          range.from.format(GRAPHQL_DATE_FMT)
        )
        sp.set(
          ExperiencesDetailsSearchParams.departure,
          range.to.format(GRAPHQL_DATE_FMT)
        )
        return sp
      })
    }
  }
}

export const AvailabilityDateRangeSelect = ({
  includeTraveDate = false,
}: {
  includeTraveDate?: boolean
}) => {
  const { startDate, endDate } = useExperienceDetailsSearchParams()
  const updateSearchParams =
    useUpdateAvailabilityArrivalAndDepartureSearchParams()

  const [selected, setSelected] = useState<DateRange>(() => ({
    from: dayjs(startDate),
    to: dayjs(endDate),
  }))

  // sync state with search params when they change
  useEffect(() => {
    if (startDate && endDate)
      setSelected({ from: dayjs(startDate), to: dayjs(endDate) })
    else {
      const range = { from: dayjs(), to: dayjs() }
      setSelected(range)
      updateSearchParams(range, includeTraveDate)
    }
  }, [startDate, endDate])

  const onSelect = (range: DateRange) => {
    setSelected(range)
    if (range?.to) {
      // update search params with valid final selection to trigger query update
      updateSearchParams(range, includeTraveDate)
      pushDataToDataLayer('search_availability')
    }
  }

  const disabled = [
    {
      before: TODAY.toDate(),
    },
    {
      before: !selected.to && selected.from.subtract(2, 'week').toDate(),
      after: !selected.to && selected.from.add(2, 'week').toDate(),
    },
  ]

  return (
    <DateRangePicker
      canSelectSameDay={true}
      disabled={disabled}
      label='Travel Dates'
      numberOfMonths={1}
      selected={selected}
      selectedValueFormat='MMM DD'
      onSelect={onSelect}
    />
  )
}
