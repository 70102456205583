import { SkeletonDots } from '@travelpass/design-system'
import { Helmet } from 'src/common/components'
import { ExperienceResultsListLoading } from './ExperienceResultsListLoading'
import { ExperienceResultsTagsLoading } from './ExperienceResultsTags'

interface ExperienceResultsLoadingProps {
  showList?: boolean
  showNav?: boolean
}
export const ExperienceResultsLoading = ({
  showList,
  showNav,
}: ExperienceResultsLoadingProps) => (
  <>
    <Helmet pageName='Search' />
    {/* Tags */}
    {showNav && <ExperienceResultsTagsLoading />}
    {/* ProductCards */}
    {showList && <ExperienceResultsListLoading />}
    {/* Load More Button */}
    <div className='flex h-16 flex-col items-center justify-center py-6'>
      <SkeletonDots />
    </div>
  </>
)
