import { useState } from 'react'
import classNames from 'classnames'

export const TripCardHeroImage = ({
  hasFirstImageLoaded,
  imageLink,
  name,
  onError,
  onLoad,
}: {
  hasFirstImageLoaded?: boolean
  imageLink?: string | null
  name?: string
  onError(): void
  onLoad(): void
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const onImgLoad = () => {
    onLoad()
    setIsLoading(false)
  }
  return (
    <img
      alt={`${name} hero`}
      className={classNames('h-full w-full object-cover', {
        'opacity-100': !isLoading,
        'opacity-0': isLoading,
      })}
      draggable={false}
      loading={hasFirstImageLoaded ? 'eager' : 'lazy'}
      src={imageLink ?? undefined}
      onError={onError}
      onLoad={onImgLoad}
    />
  )
}
