import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceHopOnHopOffItinerary,
  ExperienceItinerary,
} from 'src/__generated__/graphql'
import { VerticalStep, VerticalStepper } from 'src/common/components'
import { TimelineHopOnHopOffHeader } from './TimelineHopOnHopOffHeader'
import { ExperiencesItineraryTimelineStep } from '../../common/components/ExperiencesItineraryTimeline/ExperiencesItineraryTimelineStep'
import type { ExperiencesItineraryTimelineProps } from '../../common/components/ExperiencesItineraryTimeline/types'

interface TimelineHopOnHopOffProps {
  expanded: boolean
  itinerary: ExperienceItinerary
  size?: ExperiencesItineraryTimelineProps['size']
}
export const TimelineHopOnHopOff = ({
  expanded,
  itinerary,
  size = 'medium',
}: TimelineHopOnHopOffProps) => {
  const { routes } = (itinerary as ExperienceHopOnHopOffItinerary) ?? {}
  const showTimeline = !isEmpty(routes)

  return (
    showTimeline && (
      <div className={timelineHopOnHopOffCss({ size })}>
        {routes?.map((route, routeIndex) => (
          <div
            key={`route-${routeIndex}`}
            className='color-grey-800 flex flex-col gap-12'
          >
            <TimelineHopOnHopOffHeader route={route} />
            {!isEmpty(route?.stops) && expanded && (
              <div className='all-[ol>:last-child]:bg-white'>
                <VerticalStepper>
                  {route?.stops?.map(({ description, stopLocation }, index) => (
                    <VerticalStep
                      key={`route-stop-${stopLocation}-${routeIndex}-${index}`}
                      label={index + 1}
                    >
                      <ExperiencesItineraryTimelineStep
                        subtitle={description}
                        title={stopLocation?.name}
                      />
                    </VerticalStep>
                  ))}
                </VerticalStepper>
              </div>
            )}
            {!isEmpty(route?.pointsOfInterest) && expanded && (
              <>
                <p className='font-500'>
                  Points of Interest passed by on this route
                </p>
                <div className='all-[ol>:last-child]:bg-white'>
                  <VerticalStepper>
                    {route?.pointsOfInterest?.map(({ location }, index) => (
                      <VerticalStep
                        key={`route-point-${location?.name}-${routeIndex}-${index}`}
                      >
                        <ExperiencesItineraryTimelineStep
                          title={
                            <>
                              {location?.name}{' '}
                              <span className='color-grey-700'>(Pass By)</span>
                            </>
                          }
                        />
                      </VerticalStep>
                    ))}
                  </VerticalStepper>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    )
  )
}

const timelineHopOnHopOffCss = ({ size }: Partial<TimelineHopOnHopOffProps>) =>
  classNames({
    'flex flex-col gap-4': size === 'small',
    'flex flex-col gap-12': size !== 'small',
  })
