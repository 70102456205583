import { useMemo, useState } from 'react'
import { Link } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import {
  constructAddress,
  formatDate,
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
  getDateFromUTC,
  getProductTypesAsObject,
} from 'src/utils'
import type {
  ExploreExperience,
  ExploreHotel,
  ExploreItem,
} from '../../constants'
import { ExploreSearchParams } from '../../constants'
import { constructExperienceItems, constructHotelItems } from '../../utils'

import { TripDetailsExploreCard } from '../TripDetailsExploreCard'
import { TripDetailsExploreEmpty } from '../TripDetailsExploreEmpty'
import { getTripExploreTagsAsArray } from '../TripDetailsExploreSearch'

interface TripDetailsExploreListProps {
  hoveredEventId?: string
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  tripDetailsExploreExperiencesData: ExploreExperience[]
  tripDetailsExploreHotelsData: ExploreHotel[]
  onHoveredEventIdChange?(updatedHoveredId: string): void
  onRefetch?(): void
}

export const TripDetailsExploreList = ({
  hoveredEventId,
  tripDetailsData,
  tripDetailsExploreExperiencesData,
  tripDetailsExploreHotelsData,
  onHoveredEventIdChange,
  onRefetch,
}: TripDetailsExploreListProps) => {
  const [hasError, setHasError] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const productType = searchParams.get(ExploreSearchParams.productType)
  const { endDate, startDate, timeZone, tripPreference } = tripDetailsData ?? {}
  const {
    addressLine1,
    addressLine2,
    city,
    country,
    lat: latitude,
    long: longitude,
    state,
    zipcode,
  } = tripPreference?.addresses?.[0] ?? {}
  const { isExperience } = getProductTypesAsObject(productType ?? '')
  const items: ExploreItem[] = useMemo(() => {
    let updatedItems: ExploreItem[] = []
    setHasError(false)

    if (isExperience) {
      updatedItems = constructExperienceItems(tripDetailsExploreExperiencesData)
    } else {
      updatedItems = constructHotelItems(tripDetailsExploreHotelsData)
    }

    setHasError(isEmpty(updatedItems))

    return updatedItems
  }, [
    productType,
    tripDetailsExploreExperiencesData,
    tripDetailsExploreHotelsData,
  ])
  const location = constructAddress({
    addressFirstLine: addressLine1 ?? '',
    addressSecondLine: addressLine2 ?? '',
    city: city ?? '',
    country: country ?? '',
    state: state ?? '',
    zipcode: zipcode ?? '',
  })

  const getEmptyTitle = () => {
    const tagIds = getTripExploreTagsAsArray(
      searchParams.get(ExploreSearchParams.tagIds) ?? ''
    )

    if (!isEmpty(tagIds)) return 'No results found matching those filters.'

    return 'No results found.'
  }

  const onClick = () => {
    const arrival = formatDate(getDateFromUTC(startDate, timeZone ?? ''))
    const departure = formatDate(getDateFromUTC(endDate, timeZone ?? ''))

    if (isExperience)
      return window.open(
        generateExperiencesResultsUrl({
          arrival,
          departure,
          latitude: latitude ?? '0',
          location,
          longitude: longitude ?? '0',
        })
      )

    return window.open(
      generateHotelResultsUrl({
        arrival,
        departure,
        latitude: latitude ?? '0',
        location,
        longitude: longitude ?? '0',
      })
    )
  }

  const onCardClick = ({ id }) => {
    if (searchParams.get(ExploreSearchParams.activeExploreId) !== id) {
      searchParams.set(ExploreSearchParams.activeExploreId, id)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }

  if (hasError) return <TripDetailsExploreEmpty title={getEmptyTitle()} />

  return (
    <div className='flex flex-col gap-5 overflow-y-hidden px-4 pr-6 pt-4 md:gap-4'>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        {items?.map(item => {
          const { id } = item ?? {}

          return (
            <TripDetailsExploreCard
              key={item?.id}
              hoveredEventId={hoveredEventId}
              tripDetailsExploreCardData={item}
              onClick={() => onCardClick({ id })}
              onMouseOut={() => onHoveredEventIdChange?.('')}
              onMouseOver={() => onHoveredEventIdChange?.(id)}
            />
          )
        })}
      </div>
      <div className='flex flex-col items-center'>
        <Link label='View More' onClick={onClick} />
      </div>
    </div>
  )
}
