import type { ReactNode } from 'react'
import classNames from 'classnames'

interface HotelResultsFeaturedItemsContainerProps {
  children: ReactNode
  length: number
}

export const HotelResultsFeaturedItemsContainer = ({
  children,
  length,
}: HotelResultsFeaturedItemsContainerProps) => (
  <div className={hotelResultsFeaturedItemsCss(length)}>{children}</div>
)

const hotelResultsFeaturedItemsCss = (length: number) =>
  classNames('gap-4 grid md:gap-3', {
    'md:grid-cols-2 md:h-42': length !== 1,
    'md:grid-rows-2 md:h-87 children-[:first-child]:md:row-span-2':
      length === 3,
  })
