import type { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import { getGeocode } from 'use-places-autocomplete'
import { explorePath } from 'src/constants'
import { BOUNDING_BOX_PARAMS } from 'src/pages/explore/bounding-box/constants/boundingBoxParams'
import { KEYWORD_SEARCH_PHRASE } from 'src/pages/explore/keyword-search/constants'

export const handleCountryStateSearch = async (
  selection: Suggestion,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  navigate: NavigateFunction,
  pathname: string,
  isMobileScreen: boolean
) => {
  const [result] = await getGeocode({ placeId: selection?.place_id })
  const country = result.address_components?.find(
    addressComponent => addressComponent.types[0] === 'country'
  )?.long_name

  const state = result.address_components?.find(
    addressComponent =>
      addressComponent.types[0] === 'administrative_area_level_1'
  )?.long_name

  setSearchParams(sp => {
    sp.set('title', result?.formatted_address)
    sp.set(
      'location',
      result?.address_components?.[0]?.long_name ||
        result?.address_components?.[0]?.short_name ||
        result?.formatted_address
    )
    country ? sp.set('country', country) : sp.delete('country')
    state ? sp.set('state', state) : sp.delete('state')

    sp.delete('miles')
    sp.delete('lat')
    sp.delete('lng')
    sp.delete('locationTitle')
    sp.delete(KEYWORD_SEARCH_PHRASE)
    sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
    sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
    sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
    sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

    return sp
  })

  if (!pathname.includes('/country-state'))
    navigate({
      pathname: `${explorePath}/country-state`,
      search: searchParams.toString(),
    })
}

export const checkCountryStateSearch = async (selection: Suggestion) => {
  const [result] = await getGeocode({ placeId: selection?.place_id })

  const country = result.address_components?.[0]?.types.find(
    type => type === 'country'
  )

  const state = result.address_components?.[0]?.types.find(
    type => type === 'administrative_area_level_1'
  )

  return !!country || !!state
}
