import { EventStatus, EventType } from 'src/__generated__/graphql'
import { defaultExperienceEvent, type ExperienceEvent } from '../constants'

const getRecommendedHookArgs = (data: ExperienceEvent | null) => {
  const latitude = data?.address?.lat ?? defaultExperienceEvent?.address?.lat
  const longitude = data?.address?.long ?? defaultExperienceEvent?.address?.long
  const arrival =
    data?.startDate?.toISOString() ??
    defaultExperienceEvent?.startDate?.toISOString()
  const departure =
    data?.endDate?.toISOString() ??
    defaultExperienceEvent?.endDate?.toISOString()

  return {
    latitude,
    longitude,
    arrival,
    departure,
    tracker: arrival,
  }
}

const getCreateExperienceVariables = ({
  experiences,
  experienceId,
  data,
  tripId,
}: {
  experiences: ExperienceEvent[]
  experienceId: string
  data: ExperienceEvent | null
  tripId: string | null
}) => {
  const experience = experiences.find(e => e.id === experienceId)
  if (experience) {
    const arrival = data?.startDate?.toISOString()
    const departure = data?.endDate?.toISOString()

    return {
      eventInput: {
        addresses: [
          {
            lat: experience?.address?.lat,
            long: experience?.address?.long,
          },
        ],
        description: experience.description,
        durationInMinutes: parseFloat(experience?.duration),
        endDate: departure,
        externalTypeId: experience.id,
        name: experience.name,
        startDate: arrival,
        tripId,
        type: EventType.Experience,
        productId: experience.id,
        status: EventStatus.NotBooked,
        useEventTime: false,
      },
    }
  }

  return null
}
export { getRecommendedHookArgs, getCreateExperienceVariables }
