import isEmpty from 'lodash.isempty'
import type { Fee } from 'src/__generated__/graphql'
import { getDateDiff, stringToNumber } from 'src/utils'

interface feeValue {
  type: string
  amount: { amount: string }
}

const calculateDueLaterTotal = (
  organizedFees: feeValue[] | null,
  payLaterAmount: string
) => {
  const dueLaterTotal = {
    otherFees: 0.0,
    payLaterAmount: 0.0,
    reserveNowPayLater: 0.0,
  }

  if (organizedFees) {
    const organizedFeesTotal = organizedFees.reduce(
      (sum, fee) => sum + parseFloat(fee?.amount?.amount),
      0
    )

    dueLaterTotal.otherFees = organizedFeesTotal
  }

  if (payLaterAmount) {
    dueLaterTotal.reserveNowPayLater = parseFloat(payLaterAmount)
  } else {
    delete dueLaterTotal.reserveNowPayLater
  }

  return String(
    Object.values(dueLaterTotal).reduce((sum, price) => sum + price, 0)
  )
}

export const nightCount = (searchParams: URLSearchParams) => {
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  return getDateDiff([departure, arrival])
}

const organizeAdditionalFees = (additionalFees: Fee[]): [] | feeValue[] => {
  const organizedFees = []

  const resortFee: feeValue = {
    type: 'Resort Fees:',
    amount: {
      amount: '0',
    },
  }

  if (additionalFees && !isEmpty(additionalFees)) {
    additionalFees?.forEach(fee => {
      if (fee.type === 'Resort Fee') {
        resortFee.amount.amount = fee?.amount?.amount
        organizedFees.unshift(resortFee)
      } else {
        organizedFees.push(fee)
      }
    })
  }

  if (!isEmpty(organizedFees)) return organizedFees
  return null
}

export const calculatePriceBreakdown = bookingRateData => {
  const { additionalFees, dueNow, dueLater, subtotal, surchargeTotal } =
    bookingRateData?.validatedRate?.room?.rates[0]?.price ?? {}

  const dueNowAmount = dueNow?.amount
  const dueLaterAmount = dueLater?.amount
  const organizedFees = organizeAdditionalFees(additionalFees)
  const dueLaterTotal = calculateDueLaterTotal(organizedFees, dueLaterAmount)
  const totalPrice =
    stringToNumber(dueNowAmount) + stringToNumber(dueLaterTotal)

  return {
    dueNowAmount,
    dueLaterAmount,
    dueLaterTotal,
    organizedFees,
    surchargeTotal,
    subtotal,
    totalPrice,
  }
}
