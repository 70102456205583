import { IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { HotelResultsListView } from '../hotelResultsConstants'
import { getHotelResultsListViewFromSearchParams } from '../hotelResultsUtils'

export const HotelResultsListNav = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const listView = getHotelResultsListViewFromSearchParams({ searchParams })

  const onClick = (listView: HotelResultsListView) => {
    searchParams.set('listView', listView)
    setSearchParams(searchParams)
  }

  return (
    <div aria-hidden className='flex'>
      <IconButton
        data-testid='HotelResultsListNav-list-button'
        icon='formatListBulleted'
        isDisabled={listView === HotelResultsListView.list}
        size='small'
        onClick={() => onClick(HotelResultsListView.list)}
      />
      <IconButton
        data-testid='HotelResultsListNav-grid-button'
        icon='gridView'
        isDisabled={listView === HotelResultsListView.grid}
        size='small'
        onClick={() => onClick(HotelResultsListView.grid)}
      />
    </div>
  )
}
