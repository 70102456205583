import { useState } from 'react'
import { Icon } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import type {
  ExperienceCancellationPolicy,
  RefundEligibility,
  Trip,
} from 'src/__generated__/graphql'
import { CancellationPolicyTypeEnum } from 'src/__generated__/graphql'
import { getDateFromUTC } from 'src/utils'
import {
  experiencesCancellationPolicyCardContentCss,
  experiencesCancellationPolicyCardCss,
  experiencesCancellationPolicyCardSubtitleCss,
  experiencesCancellationPolicyCardTitleCss,
} from './experiencesCancellationPolicyCardStyles'
import { ExperiencesCancellationPolicyModal } from '../ExperiencesCancellationPolicyModal'

interface ExperiencesCancellationPolicyCardProps {
  description?: ExperienceCancellationPolicy['description']
  startTimestamp?: RefundEligibility['startTimestamp']
  timeZone?: Trip['timeZone']
  type?: CancellationPolicyTypeEnum
}

const ExperiencesCancellationPolicyCard = ({
  description,
  startTimestamp,
  timeZone,
  type,
}: ExperiencesCancellationPolicyCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const date = getDateFromUTC(startTimestamp, timeZone)
    ?.add(1, 'day')
    ?.format('MMM D, YYYY')
  const showCard = !!description && !!startTimestamp && !!type

  const constructedSubtitle = () => {
    if (!!date && type !== CancellationPolicyTypeEnum.AllSalesFinal)
      return `up to 24 hours before ${date}`

    return 'This reservation does not qualify for a refund.'
  }

  const constructedTitle = () => {
    if (type !== CancellationPolicyTypeEnum.AllSalesFinal)
      return 'Free Cancellation'

    return 'Non Refundable'
  }

  return (
    showCard && (
      <>
        {isModalOpen && (
          <ExperiencesCancellationPolicyModal
            description={description}
            startTimestamp={startTimestamp}
            timeZone={timeZone}
            type={type}
            onClose={() => setIsModalOpen(false)}
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={experiencesCancellationPolicyCardCss}
          onClick={() => setIsModalOpen(true)}
        >
          <Icon name='event' size={isMobile ? 'medium' : 'large'} />
          <div className={experiencesCancellationPolicyCardContentCss}>
            <p className={experiencesCancellationPolicyCardTitleCss}>
              {constructedTitle()}
            </p>
            <p className={experiencesCancellationPolicyCardSubtitleCss}>
              {constructedSubtitle()}
            </p>
          </div>
        </div>
      </>
    )
  )
}

export { ExperiencesCancellationPolicyCard }
export type { ExperiencesCancellationPolicyCardProps }
