import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import {
  CancellationPolicyTypeEnum,
  EventStatus,
  ExperienceCancellationType,
  RefundType,
} from 'src/__generated__/graphql'
import { experiencesPath } from 'src/constants'
import { getEventImage } from 'src/pages/trips/utils'
import {
  generateExperienceDetailsUrl,
  getDateFromUTC,
  getLanguageNameByCode,
  getTimeFromUTC,
  stringTimeToDayjs,
} from 'src/utils'
import type {
  EventNode,
  PlaceNode,
  ExperienceEvent,
  ExperienceNode,
} from '../constants'
import { defaultExperienceEvent } from '../constants'

const convertToStringArray = (array?: (string | null)[] | null) => {
  if (array && !isEmpty(array)) {
    return array.reduce((result: string[], currentValue) => {
      if (currentValue && typeof currentValue === 'string') {
        result.push(currentValue)
      }
      return result
    }, [])
  }

  return []
}

const groupAddress = ({
  eventData,
  productData,
  placeData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode | null
  placeData?: PlaceNode
}) => {
  const updatedData = {
    address: defaultExperienceEvent.address,
  }
  const eventAddress = eventData?.addresses?.[0] ?? null
  const pickupAddress =
    productData?.logistics?.travelerPickup?.locations?.[0]?.location ?? null
  const startAddAddress = productData?.logistics?.start?.[0]?.location ?? null
  const placesAddress = placeData?.address ?? null

  const {
    addressLine1: defaultAddressLine1,
    city: defaultCity,
    country: defaultCountry,
    googlePlaceId: defaultGooglePlaceId,
    id: defaultId,
    lat: defaultLat,
    long: defaultLong,
    state: defaultState,
    zipcode: defaultZipcode,
  } = defaultExperienceEvent.address

  if (eventAddress?.state) {
    updatedData.address = {
      addressLine1: eventAddress?.addressLine1 ?? defaultAddressLine1,
      city: eventAddress?.city ?? defaultCity,
      country: eventAddress?.country ?? defaultCountry,
      googlePlaceId: eventAddress?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: eventAddress?.lat ?? defaultLat,
      long: eventAddress?.long ?? defaultLong,
      state: eventAddress?.state ?? defaultState,
      zipcode: eventAddress?.zipcode ?? defaultZipcode,
    }
  } else if (pickupAddress) {
    updatedData.address = {
      addressLine1: pickupAddress?.address ?? defaultAddressLine1,
      city: pickupAddress?.city ?? defaultCity,
      country: pickupAddress?.country ?? defaultCountry,
      googlePlaceId: defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: pickupAddress?.latitude ?? defaultLat,
      long: pickupAddress?.longitude ?? defaultLong,
      state: pickupAddress?.state ?? defaultState,
      zipcode: pickupAddress?.postalCode ?? defaultZipcode,
    }
  } else if (startAddAddress) {
    updatedData.address = {
      addressLine1: startAddAddress?.address ?? defaultAddressLine1,
      city: startAddAddress?.city ?? defaultCity,
      country: startAddAddress?.country ?? defaultCountry,
      googlePlaceId: defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: startAddAddress?.latitude ?? defaultLat,
      long: startAddAddress?.longitude ?? defaultLong,
      state: startAddAddress?.state ?? defaultState,
      zipcode: startAddAddress?.postalCode ?? defaultZipcode,
    }
  } else if (placesAddress) {
    updatedData.address = {
      addressLine1: placeData?.address ?? defaultAddressLine1,
      city: placeData?.city ?? defaultCity,
      country: placeData?.country ?? defaultCountry,
      googlePlaceId: placeData?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: placeData?.latitude ?? defaultLat,
      long: placeData?.longitude ?? defaultLong,
      state: placeData?.state ?? defaultState,
      zipcode: placeData?.postalCode ?? defaultZipcode,
    }
  }

  return updatedData?.address
}

const groupDates = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    startDate: defaultExperienceEvent.startDate,
    endDate: defaultExperienceEvent.endDate,
  }
  const timezone = tripData?.timeZone ?? defaultExperienceEvent.timezone
  const { startDate, endDate } = eventData ?? {}

  if (startDate) {
    updatedData.startDate = getDateFromUTC(startDate, timezone)
  }
  if (endDate) {
    updatedData.endDate = getDateFromUTC(endDate, timezone)
  }

  return updatedData
}

const groupTime = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    duration: defaultExperienceEvent.duration,
    startTime: defaultExperienceEvent.startTime,
    endTime: defaultExperienceEvent.endTime,
    timezone: defaultExperienceEvent.timezone,
  }
  const { timeZone } = tripData ?? {}
  const { startDate, endDate } = eventData ?? {}

  if (timeZone) updatedData.timezone = timeZone

  if (eventData?.useEventTime) {
    if (startDate) {
      updatedData.startTime = getTimeFromUTC({
        utcDate: dayjs(startDate)?.toISOString(),
        timezone: timeZone ?? defaultExperienceEvent.timezone,
      })
    }
    if (endDate) {
      updatedData.endTime = getTimeFromUTC({
        utcDate: dayjs(endDate)?.toISOString(),
        timezone: timeZone ?? defaultExperienceEvent.timezone,
      })
    }
    if (startDate && endDate) {
      const startTimeDjs = stringTimeToDayjs(updatedData.startTime)
      const endTimeDjs = stringTimeToDayjs(updatedData.endTime)

      if (startTimeDjs?.isValid && endTimeDjs?.isValid()) {
        const duration = endTimeDjs.diff(startTimeDjs, 'hours')
        updatedData.duration = `About ${duration} hrs`
      }
    }
  }

  return updatedData
}

const groupDescription = ({
  eventData,
  productData,
  placeData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    description: defaultExperienceEvent.description,
  }
  const productDescription = productData?.description
  const placeDescription = placeData?.description ?? null
  const eventDescription = eventData?.description ?? null

  if (productDescription) {
    updatedData.description = productDescription
  } else if (placeDescription) {
    updatedData.description = placeDescription
  } else if (eventDescription) {
    updatedData.description = eventDescription
  }

  return updatedData?.description
}

const groupImages = ({
  eventData,
  productData,
  placeData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    images: defaultExperienceEvent.images,
  }
  const productImages = productData?.images?.size720x480 ?? null
  const placeImages = placeData?.imageLinks ?? null

  if (productImages) {
    updatedData.images = convertToStringArray(productImages)
  } else if (placeImages) {
    updatedData.images = convertToStringArray(placeImages)
  } else if (eventData?.imageUrl) {
    updatedData.images = [eventData?.imageUrl]
  }

  if (!isEmpty(updatedData.images)) {
    const images = updatedData.images
      ?.slice(0, 5)
      ?.map(image => getEventImage(image))

    updatedData.images = convertToStringArray(images)
  }

  return updatedData.images
}

const groupName = ({
  eventData,
  productData,
  placeData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    name: defaultExperienceEvent.name,
  }
  const eventName = eventData?.name ?? null
  const productName = productData?.title ?? null
  const placeName = placeData?.name ?? null

  if (eventName) {
    updatedData.name = eventName
  } else if (productName) {
    updatedData.name = productName
  } else if (placeName) {
    updatedData.name = placeName
  }

  return updatedData.name
}

const groupRating = ({
  placeData,
  productData,
}: {
  placeData?: PlaceNode
  productData?: ExperienceNode
}) => {
  const updatedData = {
    rating: defaultExperienceEvent.rating,
  }

  const productRating = productData?.reviews?.combinedAverageRating ?? null
  const placeRating = placeData?.rating ? parseFloat(placeData?.rating) : null

  if (productRating) {
    updatedData.rating = productRating
  } else if (placeRating) {
    updatedData.rating = placeRating
  }

  return updatedData.rating
}

const groupUrls = ({
  eventData,
  productData,
  placeData,
  tripData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode
  placeData?: PlaceNode
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    bookingDetailsUrl: defaultExperienceEvent.bookingDetailsUrl,
    bookingVoucherUrl: defaultExperienceEvent.bookingVoucherUrl,
    externalUrl: defaultExperienceEvent.externalUrl,
    productDetailsUrl: defaultExperienceEvent.productDetailsUrl,
  }

  const bookingId =
    eventData?.experienceBooking?.[0]?.externalConfirmationId ?? null
  if (eventData?.status === EventStatus.Booked && bookingId) {
    updatedData.bookingDetailsUrl = `${experiencesPath}/confirmation/${bookingId}`
    updatedData.bookingVoucherUrl =
      eventData.experienceBooking?.[0]?.voucherInfo?.url ?? null
  }

  // experience details url
  if (productData?.id && productData?.title) {
    const { city, state } = productData?.logistics?.start?.[0]?.location ?? {}

    updatedData.productDetailsUrl = generateExperienceDetailsUrl({
      city: city ?? 'city',
      id: productData?.id,
      name: productData?.title,
      state: state ?? 'state',
      tripId: tripData?.id || undefined,
      arrival: tripData?.startDate,
      departure: tripData?.endDate,
      eventId: eventData.id,
    })
  }

  // experience url
  if (placeData?.website) {
    updatedData.externalUrl = placeData?.website
  }

  return updatedData
}

const getTotalGuests = (eventData?: EventNode | null) => {
  const paxMix = eventData?.experienceBooking?.[0]?.paxMix ?? null
  const sum = paxMix?.reduce((total, paxMixData) => {
    total += paxMixData?.numberOfTravelers ?? 0
    return total
  }, 0)

  if (!!sum && sum > 0) return sum

  return defaultExperienceEvent.guests
}

const getRefundType = (eventData?: EventNode | null) => {
  const cancellationPolicy =
    eventData?.experienceBooking?.[0]?.bookingDetails?.cancellationPolicy
      ?.type ?? null

  if (cancellationPolicy)
    if (cancellationPolicy === CancellationPolicyTypeEnum?.Custom) {
      return ExperienceCancellationType.Custom
    } else if (cancellationPolicy === CancellationPolicyTypeEnum?.Standard) {
      return RefundType.Full
    } else {
      return RefundType.None
    }

  return defaultExperienceEvent.refundType
}

const getHasMobileTicket = (productData?: ExperienceNode | null) => {
  const ticketTypeDescription =
    productData?.ticketInfo?.ticketTypeDescription ?? null

  if (
    !!ticketTypeDescription &&
    ticketTypeDescription === 'Mobile or paper ticket accepted'
  ) {
    return true
  }

  return defaultExperienceEvent.hasMobileTicket
}

const getLanguages = (productData?: ExperienceNode | null) => {
  const languageGuides = productData?.languageGuides ?? null
  const firstLanguage = languageGuides?.[0]?.language ?? null

  if (!isEmpty(languageGuides) && firstLanguage) {
    const constructedFirstLanguage = getLanguageNameByCode(firstLanguage)
    const title = `Offered in ${constructedFirstLanguage}`

    if (!!languageGuides && languageGuides.length > 1) {
      return `${title} and ${languageGuides.length - 1} more`
    } else {
      return title
    }
  }

  return defaultExperienceEvent.languages
}

const groupProductId = ({
  eventData,
  productData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode | null
}) => {
  const productIdFromEvent = eventData?.productId ?? null
  const productIdFromBooking =
    eventData?.experienceBooking?.[0]?.product?.productCode ?? null
  const productIdFromProduct = productData?.id ?? null

  if (productIdFromEvent) {
    return productIdFromEvent
  } else if (productIdFromBooking) {
    return productIdFromBooking
  } else if (productIdFromProduct) {
    return productIdFromProduct
  }

  return null
}

const groupExperienceData = ({
  eventData,
  productData,
  placeData,
  tripData,
}: {
  eventData?: EventNode | null
  productData?: ExperienceNode | null
  placeData?: PlaceNode
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const data: ExperienceEvent = cloneDeep(defaultExperienceEvent)

  data.address = groupAddress({ eventData, productData, placeData })

  const { startDate, endDate } = groupDates({ eventData, tripData })
  data.startDate = startDate
  data.endDate = endDate

  const { duration, startTime, endTime, timezone } = groupTime({
    eventData,
    tripData,
  })
  data.duration = duration
  data.startTime = startTime
  data.endTime = endTime
  data.timezone = timezone

  data.bookingConfirmation =
    eventData?.experienceBooking?.[0]?.externalConfirmationId ?? null

  data.description = groupDescription({
    eventData,
    productData,
    placeData,
  })

  if (eventData?.id) data.id = eventData?.id

  data.guests = getTotalGuests(eventData)

  data.hasMobileTicket = getHasMobileTicket(productData)

  data.images = groupImages({ eventData, productData, placeData })

  data.languages = getLanguages(productData)

  data.productId = groupProductId({ eventData, productData })

  data.name = groupName({ eventData, productData, placeData })

  if (eventData?.notes) data.notes = eventData?.notes

  data.phone = placeData?.phoneNumber ?? null

  data.rating = groupRating({ placeData, productData })

  data.refundType = getRefundType(eventData)

  data.status = eventData?.status ?? defaultExperienceEvent?.status

  const {
    bookingDetailsUrl,
    bookingVoucherUrl,
    productDetailsUrl,
    externalUrl,
  } = groupUrls({
    eventData,
    productData,
    placeData,
    tripData,
  })
  data.bookingDetailsUrl = bookingDetailsUrl
  data.bookingVoucherUrl = bookingVoucherUrl
  data.productDetailsUrl = productDetailsUrl
  data.externalUrl = externalUrl

  return data
}

export { groupExperienceData }
