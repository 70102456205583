import { EmptyState } from '@travelpass/design-system'

export const MiscellaneousError = () => (
  <section className='flex h-full w-full flex-col items-center justify-center gap-4'>
    <EmptyState name='error' />
    <header className='text-center'>
      <h1 className='type-h6 pb-2'>An Unknown Error Occured</h1>
      <p className='type-body-2'>Please refresh the page</p>
    </header>
  </section>
)
