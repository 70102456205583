enum GuideSearchParam {
  name = 'name',
  mapFullView = 'mapFullView',
}

enum GuideSessionStorage {
  geocoderBounds = 'geocoderBounds',
  geocoderLocation = 'geocoderLocation',
  hoverId = 'hoverId',
}

export { GuideSearchParam, GuideSessionStorage }
