import Fuse from 'fuse.js'
import { FAQ_PAGE_SIZE, FUSE_OPTIONS } from './infoConstants'

const formatFaqItems = (faqItems, hasPagination, currentPage) =>
  !hasPagination
    ? faqItems
    : faqItems.slice(
        FAQ_PAGE_SIZE * currentPage - FAQ_PAGE_SIZE,
        FAQ_PAGE_SIZE * currentPage
      )

const generateFuseObject = (data?) => new Fuse(data, FUSE_OPTIONS)

const searchFuseObject = (fuseObject, value) =>
  fuseObject.search(value?.trim() === '' ? ' ' : value)

export { formatFaqItems, generateFuseObject, searchFuseObject }
