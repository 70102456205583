import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { constructAddress } from 'src/utils'

const createListGuideMutation = gql(`
  mutation CreateGuideDraftMutationInGuideDraft($createListGuideInput: CreateListGuideInput!, $userGuidesArgs: UserGuidesArgs!) {
    createListGuide(createListGuideInput: $createListGuideInput) {
      guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
          zipcode
        }
        description
        insertedAt
        images {
          id
          source
          url
        }
        imageUrl
        name
        owner {
          id
          userGuides(first: 1, userGuidesArgs: $userGuidesArgs) {
            totalCount
          }
        }
        ownerProfile {
          id
        }
        status
        strippedId
        updatedAt
      }
    }  
  }
`)

export const useCreateGuideDraftMutation = (triggerButton: string) =>
  useMutation(createListGuideMutation, {
    onCompleted: data => {
      const {
        addresses,
        description,
        id,
        insertedAt,
        name,
        owner,
        status,
        updatedAt,
      } = data?.createListGuide?.guide ?? {}
      const address = constructAddress({
        addressFirstLine: addresses?.[0]?.addressLine1,
        city: addresses?.[0]?.city,
        state: addresses?.[0]?.state,
      })

      if (!id) return

      pushDataToDataLayer('guide_draft_create', {
        guide_description: description,
        /** @todo discuss in Guide Blooomreach Data meeting */
        guide_draft_id: id,
        guide_id: id,
        guide_location: address,
        guide_name: name,
        guide_status: status,
        inserted_at: insertedAt,
        trigger_button: triggerButton,
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })
    },
  })
