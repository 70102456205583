import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import { tripTimelinePath } from 'src/constants'
import { BookingConfirmationTripsSelectTrip } from './BookingConfirmationTripsSelectTrip'
import { BookingConfirmationTripsSignIn } from './BookingConfirmationTripsSignIn'
import type { ConfirmationTrips } from './bookingConfirmationTripsConstants'

interface BookingConfirmationTripsActionsProps {
  claimedAnonymousUser: boolean
  currentTripId: string
  filteredTrips: ConfirmationTrips
  isInitialUserAnonymous: boolean
  onClaimedAnonymousUser?(): void
}

export const BookingConfirmationTripsActions = ({
  claimedAnonymousUser,
  currentTripId,
  filteredTrips,
  isInitialUserAnonymous,
  onClaimedAnonymousUser,
}: BookingConfirmationTripsActionsProps) => {
  const navigate = useNavigate()
  const showTrips = !!currentTripId && !isEmpty(filteredTrips)

  if (isInitialUserAnonymous && !claimedAnonymousUser) {
    return (
      <BookingConfirmationTripsSignIn
        onClaimedAnonymousUser={onClaimedAnonymousUser}
      />
    )
  }

  if (showTrips) {
    return (
      <BookingConfirmationTripsSelectTrip
        currentTripId={currentTripId}
        trips={filteredTrips}
      />
    )
  }

  return (
    <Button
      label='View My Trip'
      variant='outlined'
      onClick={() => navigate(`${tripTimelinePath}/${currentTripId}`)}
    />
  )
}
