import { useEffect, useState } from 'react'
import { Button, Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { EventType, type PublishedEvent } from 'src/__generated__/graphql'
import { GuideDraftEventImagesUploadModalOld } from 'src/pages/guides/lists-old/common'
import { getGuideAddress } from 'src/utils'
import { GuideDrawerOldContentFooter } from './GuideDrawerOldContentFooter'
import { GuideDrawerOldContentLoading } from './GuideDrawerOldContentLoading'
import { GuideDrawerOldContentNotes } from './GuideDrawerOldContentNotes'
import { GuideDrawerOldHero } from './GuideDrawerOldHero'
import type { GuideOwner } from '../../types'
import { useGetGuideDrawerQuery } from '../../useGetGuideDrawerQuery'

interface GuideDrawerOldContentProps {
  guideDraftId: string
  isEdit: boolean
  onSelectedIdChange: (updatedSelectedId: string) => void
  owner: GuideOwner
  selectedEvent: PublishedEvent
}

/** @todo tests (separate tests for GuideDraft/Guide drawer contents) */
export const GuideDrawerOldContent = ({
  guideDraftId,
  isEdit,
  onSelectedIdChange,
  owner,
  selectedEvent,
}: GuideDrawerOldContentProps) => {
  const {
    guideDrawerData,
    guideDrawerImages,
    guideDrawerNumberOfGuidesCount,
    guideDrawerWebsite,
    isLoading,
  } = useGetGuideDrawerQuery(selectedEvent)
  const [isHoursExpanded, setIsHoursExpanded] = useState(false)
  const [isModalImageUploadOpen, setIsModalImageUploadOpen] = useState(false)
  const [notes, setNotes] = useState('')
  const {
    address: addressFirstLine,
    city,
    country,
    openingHours,
    phoneNumber,
    state,
  } = guideDrawerData ?? {}
  const { weekdayText } = openingHours ?? {}
  const { name } = selectedEvent ?? {}
  const address = getGuideAddress({
    addressLine1: addressFirstLine,
    city,
    country,
    state,
  })
  const isWebsiteInternal = guideDrawerWebsite?.includes('travelpass.com')

  useEffect(() => {
    if (!selectedEvent?.id) return

    setNotes(selectedEvent?.notes ?? '')
  }, [selectedEvent?.id, selectedEvent?.notes])

  if (isLoading || isEmpty(selectedEvent))
    return <GuideDrawerOldContentLoading isEdit={isEdit} />

  return (
    <>
      <div className='p-b-8 grow space-y-4 overflow-y-auto'>
        <GuideDrawerOldHero
          imageLinks={guideDrawerImages}
          ownerName={owner?.name}
          selectedEvent={selectedEvent}
        />
        {isEdit && (
          <div className='flex flex-col items-center'>
            <Button
              size='small'
              startIcon='camera'
              variant='outlined'
              onClick={() => setIsModalImageUploadOpen(true)}
            >
              {selectedEvent?.images?.length ? 'Edit photos' : 'Add photos'}
            </Button>
          </div>
        )}
        <div className='p-x-9 space-y-4'>
          <div className='space-y-3'>
            <h2 className='type-subtitle-1 line-clamp-1'>{name}</h2>
            {address && (
              <p className='[&>i]:c-orange c-grey-800 type-body-2 flex flex-row gap-1'>
                <Icon name='placeOutline' size='small' />
                <span>{address}</span>
              </p>
            )}
            {phoneNumber && (
              <p className='c-grey-800 type-body-2 flex flex-row gap-1'>
                <Icon name='phone' size='small' />
                <span>{phoneNumber}</span>
              </p>
            )}
            {weekdayText && (
              <div className='c-grey-800 type-body-2 flex flex-row items-start gap-1'>
                <Icon name='accessTime' size='small' />
                <div
                  className={classNames('space-y-1', {
                    'max-h-4.125 md:max-h-4.375 overflow-hidden':
                      !isHoursExpanded,
                  })}
                >
                  {weekdayText.map((availableString, index) => (
                    <p key={index} className='type-body-2 c-grey-800 m-0'>
                      {availableString}
                    </p>
                  ))}
                </div>
                <div className='[&>button]:m-b--2 [&>button]:justify-start [&>button]:p-0'>
                  <IconButton
                    aria-expanded={isHoursExpanded}
                    aria-label='See more hours'
                    icon={isHoursExpanded ? 'expandLess' : 'expandMore'}
                    size='small'
                    onClick={() => setIsHoursExpanded(!isHoursExpanded)}
                  />
                </div>
              </div>
            )}
            {guideDrawerWebsite && (
              <div className='[&>a>span]:type-body-2 flex cursor-pointer flex-row gap-1 [&>a>span]:line-clamp-1'>
                <Icon name='language' size='small' />
                <a
                  className='type-body-2 c-grey-800 decoration-none line-clamp-1'
                  href={guideDrawerWebsite}
                  target='blank'
                >
                  {isWebsiteInternal &&
                    (selectedEvent?.type === EventType.Stay
                      ? 'See Hotel Details'
                      : 'See Experience Details')}
                  {!isWebsiteInternal && guideDrawerWebsite}
                </a>
              </div>
            )}
          </div>
          <GuideDrawerOldContentNotes
            isEdit={isEdit}
            notes={notes}
            owner={owner}
            onNotesChange={setNotes}
          />
          {!isEdit && guideDrawerNumberOfGuidesCount > 1 && (
            <div className='m-x-a w-fit'>
              <div className='rounded-2 bg-mint p-x-2 p-y-0.5 [&>*]:c-new-forest flex flex-row items-center gap-1'>
                <Icon name='listAlt' size='small' />
                <span className='type-body-1-medium'>{`Appears in ${guideDrawerNumberOfGuidesCount} Guides`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <GuideDrawerOldContentFooter
        guideDraftId={guideDraftId}
        isEdit={isEdit}
        notes={notes}
        selectedEvent={selectedEvent}
        onSelectedIdChange={onSelectedIdChange}
      />
      {isModalImageUploadOpen && (
        <GuideDraftEventImagesUploadModalOld
          guideDraftId={guideDraftId}
          selectedEvent={selectedEvent}
          onDismiss={() => setIsModalImageUploadOpen(false)}
        />
      )}
    </>
  )
}
