import type { ContextType } from 'react'
import { useMemo, useRef } from 'react'
import { Chip } from '@travelpass/design-system'
import classNames from 'classnames'
import cloneDeep from 'lodash.clonedeep'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { useSearchParams } from 'react-router-dom'
import type { FilterBy } from 'src/__generated__/graphql'
import mainAmenities from 'src/common/MainAmenities'
import { Scroller, ScrollerButton } from 'src/common/components'
import { getPrice } from 'src/utils'
import {
  getHotelResultsFiltersFromSearchParams,
  setHotelResultsFiltersSearchParams,
} from '../hotelResultsUtils'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

type SearchChip = {
  label: string
  value: string
}

export const HotelResultsNavChips = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { chips, filters } = useMemo<{
    chips: SearchChip[]
    filters: FilterBy
  }>(() => {
    const filters = getHotelResultsFiltersFromSearchParams(searchParams)
    const chips = Object.keys(filters).map(key => {
      let label = ''
      const filter = filters?.[key]

      if (key.indexOf('include') !== -1) {
        label = mainAmenities.find(item => item.id === key)?.label
      } else if (key === 'brands') {
        label = `${filter?.length}+ Brands`
      } else if (key === 'customerReviewScore') {
        label = `${parseInt(filter?.minScore)}+ Guest Rating`
      } else if (key === 'priceRange') {
        const minPrice = getPrice(filter?.minPrice ?? 0)
        label = filter?.maxPrice
          ? `${minPrice} - ${getPrice(filter?.maxPrice)}`
          : `${minPrice} - ${getPrice(minPrice + 5000)}+`
      } else if (key === 'starRating') {
        label = `${parseInt(filter?.minStars)}+ Star Rating`
      }

      return { label, value: key }
    })

    return {
      chips,
      filters,
    }
  }, [searchParams])
  const apiRef = useRef({} as ScrollVisibilityApiType)

  const onClick = (updatedValue: SearchChip['value']) => {
    const updatedFilters = cloneDeep(filters)
    delete updatedFilters?.[updatedValue]
    setHotelResultsFiltersSearchParams({
      filters: updatedFilters,
      onChange: (updatedSearchParams: URLSearchParams) =>
        setSearchParams(updatedSearchParams),
      searchParams,
    })
  }

  return (
    !!chips?.length && (
      <div className='relative' data-testid='HotelResultsNavChips'>
        <Scroller
          LeftArrow={
            <div className={classNames('left-0', scrollButtonClasses)}>
              <ScrollerButton icon='arrowBackIos' size='small' />
            </div>
          }
          RightArrow={
            <div className={classNames('right-0', scrollButtonClasses)}>
              <ScrollerButton isNext icon='arrowForwardIos' size='small' />
            </div>
          }
          apiRef={apiRef}
          scrollContainerClassName='gap-1'
        >
          {chips.map(({ label, value }) => (
            <div key={value} className='ws-nowrap'>
              <Chip
                includeCloseIcon
                label={label}
                value={value}
                onClick={() => onClick(value)}
              />
            </div>
          ))}
        </Scroller>
      </div>
    )
  )
}

const scrollButtonClasses =
  'absolute bg-white h-fit top-0 w-fit z-1 [&>:disabled]:hidden'
