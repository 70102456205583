import { Chip } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'

type NativeAnchor = React.ComponentPropsWithRef<'a'>

interface ExperienceTagProps extends NativeAnchor {
  isSelected?: boolean
  label: string
  route: string
}

/**
 * @param {boolean | undefined} props.isSelected - control whether the button is isSelected
 * @param {string} props.label - label displayed on Tag
 * @param {string} props.route - route anchor goes to
 */

export const ExperienceTag = (props: ExperienceTagProps): JSX.Element => {
  const { isSelected = false, label, route } = props
  const navigate = useNavigate()

  return (
    <Chip
      includeCloseIcon={false}
      isSelected={isSelected}
      label={label}
      onClick={() => navigate(route)}
    ></Chip>
  )
}
