import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface Tab {
  label: string
  icon?: AvailableIcons
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

interface DashboardTabsProps {
  activeIndex: number
  variant?: 'light' | 'dark'
  fullWidth?: boolean
  size?: 'medium' | 'small'
  tabs: Tab[]
}

const DashboardTabs = ({
  activeIndex,
  variant = 'light',
  fullWidth = false,
  size = 'medium',
  tabs,
}: DashboardTabsProps) => {
  return (
    <div className='flex flex-row items-center'>
      {tabs?.map(({ label, icon, onClick }, index) => {
        const isTabActive = index === activeIndex
        return (
          <div
            key={label}
            className={classNames(
              'border-1px hover:rounded-t-2 transition-all-200 min-w-fit border-solid ease-linear',
              {
                'rounded-t-2 border-b-transparent': isTabActive,
                'border-b-1px border-l-transparent border-r-transparent border-t-transparent':
                  !isTabActive,
                'border-grey-200 hover:bg-grey-200': variant === 'light',
                'border-white hover:bg-white': variant === 'dark',
                'bg-warm-grey bg-opacity-50':
                  isTabActive && variant === 'light',
                'bg-transparent': !isTabActive && variant === 'dark',
                'bg-white':
                  (isTabActive && variant === 'dark') ||
                  (!isTabActive && variant === 'light'),
                'w-full': fullWidth,
              }
            )}
            data-selected={isTabActive.toString()}
          >
            <button
              aria-selected={isTabActive}
              className={classNames(
                'c-grey-800 flex cursor-pointer flex-col items-center justify-center border-none bg-transparent px-3 py-2 text-lg outline-none sm:text-sm md:py-4',
                {
                  'type-button-desktop': size === 'medium',
                  'type-small-text-desktop': size === 'small',
                  'aria-selected:c-forest-light': variant === 'light',
                  'aria-selected:c-black': variant === 'dark',
                  'w-full': fullWidth,
                }
              )}
              role='tab'
              onClick={onClick}
            >
              <div
                className={classNames('flex flex-row items-center', {
                  'gap-2': size === 'medium',
                  'gap-1': size === 'small',
                })}
              >
                {icon && <Icon name={icon} size={size} />}
                {label}
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export { DashboardTabs }
export type { DashboardTabsProps }
