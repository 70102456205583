interface HotelReviewHighlightedTextProps {
  text: string
  keyword: string
}

export const HotelReviewHighlightedText = ({
  text,
  keyword,
}: HotelReviewHighlightedTextProps) => {
  if (!keyword || !text) {
    return <span>{text}</span>
  }
  const keywordRegex = new RegExp(`(${keyword})`, 'gi')
  const textParts = text.split(keywordRegex)

  return (
    <span>
      {textParts.map((text, index) =>
        keywordRegex.test(text) ? (
          <span key={index} className='bg-orange'>
            {text}
          </span>
        ) : (
          <span key={index}>{text}</span>
        )
      )}
    </span>
  )
}
