import { Button } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import { CuratedUserProfiles } from 'src/common/components/CuratedUserProfiles'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'

interface FeaturedTravelersProps {
  onClick: VoidFunction
}

export const FeaturedTravelers = ({ onClick }: FeaturedTravelersProps) => {
  const { isAnonymous } = useFirebaseUser()

  return (
    <PageLayoutContainer>
      <div className='space-y-10 py-12 text-center md:py-20'>
        <div className='space-y-3 text-balance text-center max-md:px-4 md:space-y-4'>
          <span className='type-overline uppercase tracking-widest text-white'>
            FEATURED TRAVELERS
          </span>
          <h2 className='type-h2 mt-2 text-center text-white'>
            Discover A Few Of Our Favorite Profiles
          </h2>
          <div className='bg-valley w-1/8 mx-auto h-1.5 rounded-full max-md:hidden' />
        </div>
        <CuratedUserProfiles />
        <div className='space-y-2 text-balance text-center text-white max-md:px-4 lg:pt-24'>
          <h2 className='type-h2 c-current capitalize'>
            Share Your Travel Stories
          </h2>
          <p className='type-subtitle-2 c-white'>
            Whether it&apos;s the best dish to order or a whole itinerary for
            your favorite vacation spot, we know you have a tip or two to share.
          </p>
          <div className='flex justify-center pt-5'>
            {isAnonymous ? (
              <Button
                endIcon='chevronRight'
                label='Sign Up'
                size='large'
                variant='transparent'
                onClick={onClick}
              />
            ) : (
              <Button
                endIcon='chevronRight'
                label='Create A Guide'
                size='large'
                variant='transparent'
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
    </PageLayoutContainer>
  )
}
