import type { NavigateFunction } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { generateExperiencesResultsUrl } from 'src/utils'

export const handleExperienceSearch = async (
  selection: Suggestion,
  navigate: NavigateFunction
) => {
  const [result] = await getGeocode({
    placeId: selection.place_id,
  })
  const { lat, lng } = getLatLng(result)
  const url = generateExperiencesResultsUrl({
    location: result.formatted_address,
    latitude: lat,
    longitude: lng,
    generateDefaultDates: false,
  })
  navigate(url)
}
