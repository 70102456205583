import { FormProvider, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { EventStatus } from 'src/__generated__/graphql'
import { TripSearchParams } from 'src/pages/trips/constants'
import {
  ExperienceActions,
  ExperienceBottom,
  ExperienceError,
  ExperienceLoading,
  ExperienceMiddle,
  ExperienceProduct,
  ExperienceRecommended,
  ExperienceTop,
} from './components'
import { useExperienceData } from './hooks'
import { DrawerEdit, Hero } from '../components'
import type { DrawerEditKeys } from '../components/DrawerEdit/drawerEditTypes'

export const Experience = () => {
  const methods = useForm()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const tripId = params?.tripId ?? null
  const eventId = searchParams.get(TripSearchParams.eventId) ?? null

  const { data, tripData, loading, error } = useExperienceData({
    eventId,
    tripId,
  })

  const isBooked = !loading && data?.status === EventStatus.Booked
  const isInternal = !!data?.productId
  const internalBooked = isBooked && isInternal
  const externalOrInternalUnbooked = !isBooked || !isInternal

  const editableFields: DrawerEditKeys[] = internalBooked
    ? ['notes']
    : ['customBooked', 'dates', 'times', 'notes']

  if (loading) {
    return <ExperienceLoading />
  }
  if (error) {
    return <ExperienceError />
  }

  return (
    <FormProvider {...methods}>
      <section className='relative h-full'>
        <Hero hideArrows={false} images={data?.images} />
        <ExperienceTop data={data} />
        {internalBooked && <ExperienceMiddle data={data} />}
        {externalOrInternalUnbooked && <div className='h-3' />}
        <DrawerEdit
          data={data}
          tripData={tripData}
          visibleFields={editableFields}
        />
        {externalOrInternalUnbooked && <ExperienceProduct data={data} />}
        <ExperienceBottom data={data} />
        <ExperienceRecommended data={data} tripId={tripId} />
        <ExperienceActions data={data} />
      </section>
    </FormProvider>
  )
}
