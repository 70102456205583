import { Button } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { Map, constructMapCenter } from 'src/common/components/Map'
import { initialMapZoom } from 'src/common/components/Map/constants'
import { TripSearchParams } from 'src/pages/trips/constants'
import { TripExploreMapEvents } from './TripExploreMapEvents'
import { TripExploreMapPlaceMarker } from './TripExploreMapPlaceMarker'

interface tripExploreMapProps {
  placePoints: google.maps.places.PlaceResult[]
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripExploreMap = ({
  placePoints,
  tripDetailsData,
}: tripExploreMapProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { addresses } = tripDetailsData?.tripPreference ?? {}
  const [address] = addresses ?? []
  const { lat, long } = address ?? {}
  const mapFullView = searchParams.get('mapFullView')

  const onBack = () => {
    searchParams.delete(TripSearchParams.mapFullView)
    setSearchParams(searchParams)
  }

  const onClick = () => {
    searchParams.delete(TripSearchParams.selectedId)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <div
      className={classNames('bg-grey-100 h-full w-full lg:block', {
        visible: mapFullView,
        hidden: !mapFullView,
      })}
    >
      <Map
        defaultCenter={constructMapCenter(lat, long)}
        defaultZoom={initialMapZoom}
        maxZoom={20}
        minZoom={3}
        onClick={onClick}
      >
        <TripExploreMapEvents placePoints={placePoints} />
        {placePoints?.map(placePoint => (
          <TripExploreMapPlaceMarker
            key={placePoint?.place_id}
            tripExploreMapMarkerPlaceData={placePoint}
          />
        ))}
        <div className='m-a absolute bottom-0 left-0 right-0 w-fit lg:hidden'>
          <Button
            aria-label='Back to List'
            size='small'
            startIcon='arrowBack'
            onClick={onBack}
          >
            Back to List
          </Button>
        </div>
      </Map>
    </div>
  )
}
