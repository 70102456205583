import { EmptyState, Link } from '@travelpass/design-system'

export const DashboardFeedEndCta = () => (
  <div className='max-w-92 m-x-a flex flex-col items-center gap-6 text-center'>
    <div className='w-18.75'>
      <EmptyState name='noResults' />
    </div>
    <div className='space-y-3'>
      <h5 className='type-subtitle-1 c-forest-light'>
        It looks like you are all caught up!
      </h5>
      <p className='type-body-1 c-grey-800'>
        Explore more and find other Guides to inspire your next adventure!
      </p>
    </div>
    <div className='[&>a]:c-forest-light'>
      <Link
        endIcon='arrowForward'
        href='/explore'
        showUnderlineOnlyOnHover={true}
      >
        Continue Exploring
      </Link>
    </div>
  </div>
)
