import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl, termsPath } from 'src/constants'
import { TermsContent } from './TermsContent'
import { LegalLayout } from '../common/components/LegalLayout/LegalLayout'

export const Terms = () => {
  return (
    <PageLayoutContainer includeTopPadding>
      <Helmet
        canonicalUrl={`${baseUrl}${termsPath}`}
        pageName='Terms and Conditions'
      />
      <LegalLayout
        caption='OUR POLICIES'
        subtitle="Travelpass' Terms and Conditions were updated on January 6, 2023."
        title='Terms and conditions'
      >
        <TermsContent />
      </LegalLayout>
    </PageLayoutContainer>
  )
}
