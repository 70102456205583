import type { ChangeEventHandler } from 'react'
import { useEffect, useState } from 'react'
import { Checkbox, Toggle } from '@travelpass/design-system'
import { EventStatus } from 'src/__generated__/graphql'

export const CustomBooked = ({
  hideBookingNeeded = false,
  value,
  onChange,
}: {
  hideBookingNeeded?: boolean
  value?: EventStatus
  onChange: (updatedStatus: EventStatus) => void
}) => {
  const [isToggleChecked, setIsToggleChecked] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  const onToggleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const checked = event?.target?.checked ?? null
    onChange(checked ? EventStatus.NotBooked : EventStatus.Na)

    if (!checked) setIsCheckboxChecked(false)
    setIsToggleChecked(checked)
  }

  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event.target.checked ? EventStatus.Booked : EventStatus.NotBooked)

    setIsCheckboxChecked(event.target.checked)
  }

  useEffect(() => {
    if (value === EventStatus.Booked) {
      setIsToggleChecked(true)
      setIsCheckboxChecked(true)
    } else if (value === EventStatus.NotBooked) {
      setIsToggleChecked(true)
      setIsCheckboxChecked(false)
    } else {
      setIsToggleChecked(false)
      setIsCheckboxChecked(false)
    }
  }, [value])
  return (
    <div className='flex w-full items-center justify-between'>
      <div className='flex items-center justify-center gap-3'>
        {!hideBookingNeeded && (
          <>
            <p className='type-body-1-medium'>Booking needed?</p>
            <Toggle checked={isToggleChecked} onChange={onToggleChange} />
          </>
        )}
      </div>
      <Checkbox
        isChecked={isCheckboxChecked}
        isDisabled={!isToggleChecked}
        label='Booked'
        onChange={onCheckboxChange}
      />
    </div>
  )
}
