import { Button, Icon, Tag, useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { BookingStatusEnum } from 'src/__generated__/graphql'
import { copyToClipboard, generateExperienceDetailsUrl } from 'src/utils'
import {
  experiencesBookingDetailsHeroActionsCss,
  experiencesBookingDetailsHeroContentCss,
  experiencesBookingDetailsHeroCopyButtonCss,
  experiencesBookingDetailsHeroCss,
  experiencesBookingDetailsHeroImageCss,
  experiencesBookingDetailsHeroTitleCss,
} from './experiencesBookingDetailsHeroStyles'
import type { ExperiencesBookingDetailsMock } from '../ExperiencesBookingDetailsApollo/experiencesBookingDetailsApolloMock'

interface ExperiencesBookingDetailsHeroProps {
  experiencesBookingDetailsData: ExperiencesBookingDetailsMock
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsHero = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsHeroProps) => {
  const navigate = useNavigate()
  const { addSuccessSnack } = useSnackbar()
  const { externalConfirmationId, status } = experiencesBookingDetailsData ?? {}
  const { id, images, logistics, title } =
    experiencesBookingDetailsProductData ?? {}
  const { city, state } = logistics?.start?.[0]?.location ?? {}
  const constructedImage = images?.size480x320?.[0]
  const showActions = !isEmpty(logistics)
  const showHero = !!title
  const isCancelled = status === BookingStatusEnum.Canceled

  const onCopyButtonClick = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  const onProductButtonClick = () => {
    const path = generateExperienceDetailsUrl({
      city,
      id,
      state,
      name: title,
    })

    navigate(path)
  }

  return (
    showHero && (
      <div className={experiencesBookingDetailsHeroCss}>
        <div className={experiencesBookingDetailsHeroContentCss}>
          <h1 className={experiencesBookingDetailsHeroTitleCss}>{title}</h1>
          {!!externalConfirmationId && (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div
              className={experiencesBookingDetailsHeroCopyButtonCss}
              onClick={onCopyButtonClick}
            >
              <Icon name='contentCopy' />
              <span>Confirmation number: {externalConfirmationId}</span>
            </div>
          )}
          {isCancelled && (
            <Tag
              color='red'
              startIcon='eventBusy'
              text='This experience has been cancelled.'
            />
          )}
          {!!constructedImage && (
            <img
              alt={`${title} hero - Experiences Booking Details`}
              className={experiencesBookingDetailsHeroImageCss}
              src={constructedImage}
            />
          )}
        </div>
        {showActions && (
          <div className='hide-on-print'>
            <div className={experiencesBookingDetailsHeroActionsCss}>
              <Button
                endIcon='arrowForward'
                label='View Listing'
                variant='text'
                onClick={onProductButtonClick}
              />
            </div>
          </div>
        )}
      </div>
    )
  )
}
