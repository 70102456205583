import { Button, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import type { GuideSorts } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  PageLayoutContainer,
  useOnClickOwnerProfile,
  Helmet,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { ProfileModalListener } from 'src/pages/guides/results/ProfileModalListener'
import { getTracker } from 'src/utils'
import { BOUNDING_BOX_PARAMS } from './constants/boundingBoxParams'
import { ExploreZeroState } from '../components/ExploreZeroState'
import { FiltersAndSortContainer } from '../components/FiltersAndSortContainer'
import { EXPLORE_SORT_SP } from '../constants'
import { useGetGuides } from '../hooks/useGetGuides'

// magic number to fill the page
const N_GUIDES = 20

const resultsGridClassNames =
  'grid grid-cols-1 gap-2 py-6 md:grid-cols-2 md:gap-5 lg:grid-cols-3'

const LoadingGuides = () => (
  <div
    className={classNames(
      resultsGridClassNames,
      'max-md:[&_div:nth-child(3)_~_div]:hidden',
      'max-lg:[&_div:nth-child(4)_~_div]:hidden',
      'children:w-full!'
    )}
  >
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
    <GuideRecommendedCardLoading />
  </div>
)

export const BoundingBox = () => {
  const [searchParams] = useSearchParams()
  const onClickOwnerProfile = useOnClickOwnerProfile()

  const sort = searchParams.get(EXPLORE_SORT_SP)
  const location = searchParams.get('location')
  const title = searchParams.get('title')
  const swLAT = Number(searchParams.get(BOUNDING_BOX_PARAMS.sw.lat))
  const swLNG = Number(searchParams.get(BOUNDING_BOX_PARAMS.sw.lng))
  const neLAT = Number(searchParams.get(BOUNDING_BOX_PARAMS.ne.lat))
  const neLNG = Number(searchParams.get(BOUNDING_BOX_PARAMS.ne.lng))
  const accountHandles = searchParams.get('createdBy')?.split(',')
  const tagIds = searchParams.get('tags')?.split(',')

  const {
    data,
    fetchMore,
    loading: guidesLoading,
  } = useGetGuides({
    variables: {
      first: N_GUIDES + 1,
      guideSearchArgs: {
        tracker: getTracker(location + '-general'),
        searchBy: {
          boundingBox: {
            sw: { latitude: swLAT, longitude: swLNG },
            ne: { latitude: neLAT, longitude: neLNG },
          },
        },
        sortBy: sort as GuideSorts,
        filterBy: { accountHandles, tagIds },
      },
    },
    skip: !swLAT && !swLNG && !neLAT && !neLNG,
  })
  const {
    edges: guides,
    pageInfo: guidePageInfo,
    totalCount: guidesCount,
  } = { ...data?.guideSearch }
  const cursor = guidePageInfo?.hasNextPage && guidePageInfo.endCursor

  const {
    data: eventsData,
    fetchMore: fetchMoreEvents,
    loading: eventsLoading,
  } = useGetGuides({
    variables: {
      first: N_GUIDES + 1,
      guideSearchArgs: {
        tracker: getTracker(location + '-events'),
        searchBy: {
          boundingBox: {
            sw: { latitude: swLAT, longitude: swLNG },
            ne: { latitude: neLAT, longitude: neLNG },
            eventsOnly: true,
          },
        },
        sortBy: sort as GuideSorts,
        filterBy: { accountHandles, tagIds },
      },
    },
    skip: guides?.length >= N_GUIDES,
  })
  const {
    edges: events,
    pageInfo,
    totalCount: eventsCount,
  } = { ...eventsData?.guideSearch }
  const cursorEvents = pageInfo?.hasNextPage && pageInfo.endCursor

  const {
    data: expandedData,
    fetchMore: fetchMoreExpanded,
    loading: expandedLoading,
  } = useGetGuides({
    variables: {
      first: N_GUIDES + 1,
      guideSearchArgs: {
        tracker: getTracker(location + '-expanded'),
        searchBy: {
          boundingBox: {
            sw: { latitude: swLAT, longitude: swLNG },
            ne: { latitude: neLAT, longitude: neLNG },
            eventsOnly: true,
            expandBounds: true,
          },
        },
        sortBy: sort as GuideSorts,
        filterBy: { accountHandles, tagIds: tagIds },
      },
    },
    skip: (guides?.length || 0) + (events?.length || 0) >= N_GUIDES,
  })

  const {
    edges: expanded,
    totalCount: expandedCount,
    pageInfo: expandedPageInfo,
  } = {
    ...expandedData?.guideSearch,
  }
  const cursorExpanded =
    expandedPageInfo?.hasNextPage && expandedPageInfo.endCursor

  const anyIsLoading = guidesLoading || eventsLoading || expandedLoading
  const count = (guidesCount || 0) + (eventsCount || 0) + (expandedCount || 0)
  const showEmptyState = !anyIsLoading && count <= 0

  return (
    <div className='mb-8 space-y-8'>
      <Helmet
        pageName={`Explore${location ? ` ${location} Travel Guides` : ''}`}
      />
      <PageLayoutContainer>
        <div className='space-y-12 py-10'>
          <FiltersAndSortContainer
            loading={guidesLoading}
            searchedTerm={title}
            totalCount={count}
          />
          {showEmptyState && <ExploreZeroState />}
          {!!guides?.length && (
            <section>
              <header>
                <h2 className='type-h3 color-grey-900 [&_i]:color-valley flex flex-row items-center gap-2 capitalize'>
                  <Icon name='navigationCompass' size='medium' />
                  {location} Guides {!!guidesCount && `(${guidesCount})`}
                </h2>
              </header>
              <div className={resultsGridClassNames}>
                {guides?.map(({ node: guide }, index) => (
                  <GuideRecommendedCard
                    key={`guide-card-${index}`}
                    hideAddTo
                    guideRecommendedCardData={guide}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </div>
              {cursor && (
                <div className='mx-auto grid w-full place-items-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={guidesLoading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMore({ variables: { after: cursor } })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </section>
          )}
          {guidesLoading && <LoadingGuides />}
          {!!events?.length && (
            <section className=''>
              <header>
                <h2 className='type-h3 color-grey-900 [&_i]:color-valley flex flex-row items-center gap-2 capitalize'>
                  <Icon name='navigationCompass' size='medium' />
                  Guides with events in {location}{' '}
                  {!!eventsCount && `(${eventsCount})`}
                </h2>
              </header>
              <div className={resultsGridClassNames}>
                {events?.map(({ node: guide }, index) => (
                  <GuideRecommendedCard
                    key={`guide-card-${index}`}
                    hideAddTo
                    guideRecommendedCardData={guide}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </div>
              {cursorEvents && (
                <div className='mx-auto grid w-full place-items-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={eventsLoading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMoreEvents({
                        variables: { after: cursorEvents },
                      })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </section>
          )}
          {eventsLoading && <LoadingGuides />}
          {!!expanded?.length && (
            <section className=''>
              <header>
                <h2 className='type-h3 color-grey-900 [&_i]:color-valley flex flex-row items-center gap-2 capitalize'>
                  <Icon name='navigationCompass' size='medium' />
                  Guides with events near {location}{' '}
                  {!!expandedCount && `(${expandedCount})`}
                </h2>
              </header>
              <div className={resultsGridClassNames}>
                {expanded?.map(({ node: guide }, index) => (
                  <GuideRecommendedCard
                    key={`guide-card-${index}`}
                    guideRecommendedCardData={guide}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </div>
              {cursorExpanded && (
                <div className='mx-auto grid w-full place-items-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={expandedLoading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMoreExpanded({
                        variables: { after: cursorExpanded },
                      })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </section>
          )}
          {expandedLoading && <LoadingGuides />}
        </div>
      </PageLayoutContainer>
      <AddToCollectionsModalListener />
      <ProfileModalListener />
    </div>
  )
}
