import { initialMaxScore, initialMinScore } from 'src/constants'

export const reviewItems = [
  {
    label: 'Any',
    value: initialMinScore,
  },
  {
    label: '7+',
    value: '7.0',
  },
  {
    label: '8+',
    value: '8.0',
  },
  {
    label: '9+',
    value: '9.0',
  },
  {
    label: '10',
    value: initialMaxScore,
  },
]
