import React, { createContext, useContext, ReactNode, useState } from 'react'

// Utility to create a context with a generic type
export const createGenericContext = <T,>() => {
  const context = createContext<T | undefined>(undefined)

  const useGenericContext = () => {
    const ctx = useContext(context)
    if (!ctx) {
      //   throw new Error('useGenericContext must be used within a Provider')
      return null
    }
    return ctx
  }

  return [useGenericContext, context.Provider] as const
}
