import { Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { onlyNumbers, stringToNumber } from 'src/utils'
import type { DashboardProfileFields } from '../types'

const getMaxLength = (name: string) => {
  if (name === 'bragContinents') return 1

  if (name === 'bragStates') return 2

  if (name === 'bragCountries') return 2

  return
}

interface DashboardProfileInfoTravelTrackerQuestionProps {
  question: string
  name: 'bragStates' | 'bragContinents' | 'bragCountries'
  maxNumber: number
}

export const DashboardProfileInfoTravelTrackerQuestion = ({
  question,
  name,
  maxNumber,
}: DashboardProfileInfoTravelTrackerQuestionProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<DashboardProfileFields>()

  const validate = (number: number) => {
    if (number <= maxNumber) return true

    return `Please enter a number up to ${maxNumber}`
  }

  return (
    <div className='md:min-w-auto my-1 flex min-w-40 flex-row items-center justify-between gap-5 md:p-0'>
      <p className='type-body-1 my-0 grow'>{question}</p>
      <div className='w-14 justify-self-end'>
        <Input
          fullWidth
          {...register(name, {
            onChange: event => {
              setValue(name, stringToNumber(onlyNumbers(event.target.value)))
            },
            validate,
          })}
          aria-label='Add how many places you visited'
          errorText={errors[name]?.message}
          maxLength={getMaxLength(name)}
          onClick={event =>
            event.currentTarget.querySelector('input')?.select()
          }
        />
      </div>
    </div>
  )
}
