import { useState } from 'react'
import { Icon, Popover, useScreenQuery } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { SearchProductsModal } from 'src/common/components'
import { getHotelResultsNavSearchLabelsFromSearchParams } from './hotelResultsNavUtils'

const PulseDot = () => (
  <span className='relative grid p-1' data-testid='PulseDot'>
    <span className='bg-valley/80 absolute inline-flex h-full w-full animate-ping rounded-full opacity-75'></span>
    <span className='bg-valley relative inline-flex h-4 w-4 rounded-full'></span>
  </span>
)

export const HotelResultsNavSearch = () => {
  const [searchParams] = useSearchParams()
  const { isMobileOrTablet } = useScreenQuery()

  const [shouldPromptForDates, togglePromptForDates] = useState(
    !searchParams.has('arrival') || !searchParams.has('departure')
  )
  // if dates are missing & on mobile, automatically show tooltip
  const displayDatesTooltip = isMobileOrTablet && shouldPromptForDates
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { dates, guests, location } =
    getHotelResultsNavSearchLabelsFromSearchParams(searchParams)

  const SearchButton = (
    <button
      key='search-button'
      className='b-grey-300 b-solid b-1 transition-border-color-200 rounded-7 active:b-grey-500 focus:b-grey-500 hover:b-grey-500 [&>i]:color-new-forest flex w-full min-w-0 grow cursor-pointer items-center gap-2 bg-transparent px-4 py-2 text-left ease-linear lg:hidden'
      type='button'
      onClick={() => {
        setIsModalOpen(true)
        togglePromptForDates(false)
      }}
    >
      {displayDatesTooltip ? <PulseDot /> : <Icon name='search' />}
      <span className='min-w-0 grow space-y-0.5'>
        <span className='color-grey-900 type-h4-mobile block truncate'>
          {location}
        </span>
        <span className='color-grey-900 type-body-2-mobile block'>
          {dates} | {guests}
        </span>
      </span>
    </button>
  )

  return (
    <>
      {isModalOpen && (
        <SearchProductsModal
          checkSearchParams
          checkTypes
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {displayDatesTooltip ? (
        <div className='w-full'>
          <Popover
            className='text-body-1 bg-mint shadow-2 border-1 focus-visible:outline-emerald border-grey-300 mx-6 rounded border-solid px-4 text-center'
            initialOpen={true}
            placement='bottom'
            trigger={SearchButton}
          >
            <p>
              Select arrival and departure dates to further refine
              your&nbsp;search
            </p>
          </Popover>
        </div>
      ) : (
        SearchButton
      )}
    </>
  )
}
