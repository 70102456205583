const bookingDetailsContentCss = 'flex flex-col gap-12 md:gap-20'

const bookingDetailsCss = 'flex flex-col gap-6 m-a pb-10 pt-4 w-full md:pb-36'

const bookingDetailsHeaderCss = 'flex flex-col gap-6 md:gap-12 md:pb-6'

const bookingDetailsManageContactCss = 'flex flex-col gap-8 md:flex-row'

const bookingDetailsPriceCss = 'flex flex-col gap-6'

const bookingDetailsScrollMarginCss = 'scroll-m-25'

export {
  bookingDetailsContentCss,
  bookingDetailsCss,
  bookingDetailsHeaderCss,
  bookingDetailsManageContactCss,
  bookingDetailsPriceCss,
  bookingDetailsScrollMarginCss,
}
