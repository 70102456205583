import classNames from 'classnames'
import { getReviewRatingDescription } from 'src/pages/hotels/details/utils'

interface HotelGradeReviewProps {
  grade: number
}

export const HotelGradeReview = ({ grade }: HotelGradeReviewProps) => {
  return (
    <div className='grid-items-center flex gap-1'>
      <div
        className={classNames('rd-1 bg-grey800 w-fit px-2 py-1 text-center', {
          'bg-tealMedium': Number(grade) >= 7,
        })}
      >
        <h3 className='type-body-1-medium c-white'>{grade}</h3>
      </div>
      <p
        className={classNames('type-body-1 c-grey800', {
          'c-tealMedium': Number(grade) >= 7,
        })}
      >
        {getReviewRatingDescription(grade)}
      </p>
    </div>
  )
}
