import { useState } from 'react'
import {
  Button,
  Popover,
  MenuItem,
  useScreenQuery,
} from '@travelpass/design-system'
import {
  sortItems,
  type HotelReviewsFilterType,
} from './HotelReviewsApollo/hotelReviewsApolloConstants'
import { getSortValue } from './HotelReviewsApollo/hotelReviewsApolloUtils'
import { HotelReviewsTravelerTypeDrawer } from './HotelReviewsTravelerTypeDrawer'
import { HotelReviewsSearch } from './common/HotelReviewsSearch'

interface HotelReviewsNavProps {
  filters: HotelReviewsFilterType
  onChange(updatedFilters: HotelReviewsFilterType): void
}

export const HotelReviewsNav = ({
  filters,
  onChange,
}: HotelReviewsNavProps) => {
  const [isTravelerTypeDrawerOpen, setIsTravelerTypeDrawerOpen] =
    useState(false)
  const sort = filters?.sort
  // const sortValue = getSortValue(sort)?.title
  const { isDesktopScreen } = useScreenQuery()

  return (
    <>
      <HotelReviewsTravelerTypeDrawer
        filters={filters}
        isOpen={isTravelerTypeDrawerOpen}
        onChange={onChange}
        onClose={() => setIsTravelerTypeDrawerOpen(false)}
      />
      <div className='grid grid-cols-6 items-center gap-4'>
        <div className='col-span-6 lg:col-span-4'>
          <HotelReviewsSearch filters={filters} onChange={onChange} />
        </div>
        <div className='col-span-3 lg:hidden'>
          <Button
            fullWidth
            label='Filters'
            startIcon='tune'
            variant='outlined'
            onClick={() => setIsTravelerTypeDrawerOpen(true)}
          />
        </div>
        <div className='col-span-3 lg:col-span-2'>
          <Popover
            placement='bottom-end'
            trigger={
              <Button
                fullWidth
                endIcon='expandMore'
                label='Sort&nbsp;by'
                size={isDesktopScreen ? 'large' : 'medium'}
                variant='outlined'
              />
            }
          >
            {sortItems.map(({ title, value }) => (
              <MenuItem
                key={value}
                isHighlighted={sort === value}
                label={title}
                value={value}
                onClick={() =>
                  onChange({
                    sort: value,
                  })
                }
              />
            ))}
          </Popover>
        </div>
      </div>
    </>
  )
}
