import { datadogLogs } from '@datadog/browser-logs'
import { env, isProdEnv } from 'src/utils/environmentUtils'
import sessionSampleRate from './utils'

const initDatadogLogs = () => {
  datadogLogs.init({
    clientToken: 'pub2c3edffc88a6584f0d769ccb28ecb4c8',
    forwardErrorsToLogs: true,
    sessionSampleRate: sessionSampleRate(true),
    service: 'travelpass-frontend',
    env,
    version: import.meta.env.VITE_APP_VERSION,
    beforeSend: log => {
      if (log.http && log.http.status_code === 0) {
        // aborted requests
        return false
      }

      // other logs/errors
      return true
    },
  })
}

if (isProdEnv) {
  initDatadogLogs()
}
