import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import type { Collection } from 'src/__generated__/graphql'
import { CollectionFormModal } from 'src/common/components/CollectionFormModal'
import { DeleteCollectionModal } from 'src/common/components/DeleteCollectionModal'
import { DashboardCollectionCard } from './DashboardCollectionCard'
import { DashboardCollectionsLoading } from './DashboardCollectionsLoading'
import { useGetCollectionsForDashboard } from './useGetCollectionsForDashboard'

/** @todo tests for this */
export const DashboardCollections = () => {
  const [selectedCollection, setSelectedCollection] = useState<Collection>()
  const {
    collections,
    loading: isLoading,
    error: hasError,
    refetch,
    hasMoreResults,
    fetchMore,
    data,
  } = useGetCollectionsForDashboard()
  const [showFormModal, setShowFormModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const ariaLabel = 'Create a collection'

  const pageInfo = data?.currentUser?.collections?.pageInfo
  const cursor = pageInfo?.hasNextPage && pageInfo?.endCursor

  const closeFormModal = () => setShowFormModal(false)

  const closeDeleteModal = () => setShowDeleteModal(false)

  if (isLoading) return <DashboardCollectionsLoading />

  /** @todo ask Product about the proper empty state here */
  if (hasError)
    return (
      <p className='type-h6-desktop mt-10'>
        Error while loading Collections...
      </p>
    )

  return (
    <>
      <section className='space-y-6'>
        <Button
          aria-label={ariaLabel}
          size='small'
          startIcon='add'
          variant='text'
          onClick={() => {
            selectedCollection && setSelectedCollection(null)
            setShowFormModal(true)
          }}
        >
          New Collection
        </Button>
        <div className='grid grid-cols-1 content-center gap-6 md:grid-cols-2'>
          {collections?.map((collection, i) => (
            <DashboardCollectionCard
              key={i}
              collection={collection?.node}
              openDeleteModal={collection => {
                setSelectedCollection(collection)
                setShowDeleteModal(true)
              }}
              openFormModal={collection => {
                setSelectedCollection(collection)
                setShowFormModal(true)
              }}
            />
          ))}
        </div>
        {hasMoreResults && (
          <div className='flex flex-col items-center'>
            <Button
              label='View More'
              variant='outlined'
              onClick={async () => {
                await fetchMore({
                  variables: {
                    after: cursor,
                  },
                })
              }}
            />
          </div>
        )}
      </section>
      {showFormModal && (
        <CollectionFormModal
          collection={selectedCollection}
          onClose={closeFormModal}
          onConfirm={shouldRefetch => {
            closeFormModal()
            shouldRefetch && refetch()
          }}
          onDelete={collection => {
            setSelectedCollection(collection)
            closeFormModal()
            setShowDeleteModal(true)
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteCollectionModal
          collection={selectedCollection}
          onClose={closeDeleteModal}
          onConfirm={shouldRefetch => {
            closeDeleteModal()
            shouldRefetch && refetch()
          }}
        />
      )}
    </>
  )
}
