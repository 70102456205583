import { useState } from 'react'
import type { Supercluster } from 'supercluster'
import { GuidesEventsMapCluster } from './GuidesEventsMapCluster'
import { GuidesEventsMapMarker } from './GuidesEventsMapMarker'

interface GuidesEventsMapMarkersProps {
  clusters: Supercluster.PointFeature[]
  selectedId: string
  onSelectedIdChange: (updatedSelectedId: string) => void
  supercluster: Supercluster
}

export const GuidesEventsMapMarkers = ({
  clusters,
  selectedId,
  supercluster,
  onSelectedIdChange,
}: GuidesEventsMapMarkersProps) => {
  const [hoverId, setHoverId] = useState('')

  const onHoverIdChange = (updatedHoverId: string) => {
    if (hoverId === updatedHoverId) return setHoverId('')

    setHoverId(updatedHoverId)
  }

  return (
    <>
      {clusters?.map(({ geometry, properties }) => {
        const { coordinates } = geometry ?? {}
        const {
          cluster: isCluster,
          cluster_id,
          item,
          point_count,
        } = properties ?? {}

        if (isCluster) {
          return (
            <GuidesEventsMapCluster
              key={cluster_id}
              guidesEventsMapClusterData={{
                id: cluster_id,
                latitude: coordinates[1],
                longitude: coordinates[0],
                total: point_count,
              }}
              hoverId={hoverId}
              supercluster={supercluster}
              onHoverIdChange={onHoverIdChange}
            />
          )
        }

        return (
          <GuidesEventsMapMarker
            key={item.id}
            isHovered={item.id === hoverId}
            isSelected={item.id === selectedId}
            point={item}
            onHoverIdChange={onHoverIdChange}
            onSelectedIdChange={onSelectedIdChange}
          />
        )
      })}
    </>
  )
}
