import { useEffect, useState } from 'react'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { FeaturedTravelers } from './components/FeaturedTravelers'
import { FilteredTravelers } from './components/FilteredTravelers'
import { RecommendedTravelers } from './components/RecommendedTravelers'
import { GuideDraftCreateModal } from '../guides/lists/common'
import { GuideDraftCreateModalOld } from '../guides/lists-old/common'

export const FeaturedAndRecommended = () => {
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const { isAnonymous } = useFirebaseUser()
  const [showGuideModal, setShowGuideModal] = useState(false)
  const [openGuideAfterLogin, setOpenGuideAfterLogin] = useState(false)
  const [triggeredBySignUp, setTriggeredBySignUp] = useState(false)

  const openSignInModal = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
      disableAlternateModal: true,
    })
    setTriggeredBySignUp(true)
  }

  const handleFeaturedTravelersClick = () => {
    if (isAnonymous) {
      setOpenGuideAfterLogin(true)
      openSignInModal()
    } else setShowGuideModal(true)
  }

  useEffect(() => {
    if (!isAnonymous && openGuideAfterLogin && triggeredBySignUp) {
      setOpenGuideAfterLogin(false)
      setTriggeredBySignUp(false)
    }
  }, [isAnonymous, openGuideAfterLogin, triggeredBySignUp])

  return (
    <>
      <RecommendedTravelers />
      <section className='bg-top-center bg-[url(https://static.travelpass.com/assets/travelers/travelers-beach-background.webp)] bg-cover bg-no-repeat md:bg-[url(https://static.travelpass.com/assets/travelers/travelers-beach-background.webp)]'>
        <FilteredTravelers />
        <FeaturedTravelers onClick={handleFeaturedTravelersClick} />
        {isGuidePublishingV3Enabled && showGuideModal && (
          <GuideDraftCreateModal
            triggerButton='featuredRecommended'
            onDismiss={() => setShowGuideModal(false)}
          />
        )}
        {!isGuidePublishingV3Enabled && showGuideModal && (
          <GuideDraftCreateModalOld
            triggerButton='featuredRecommended'
            onDismiss={() => setShowGuideModal(false)}
          />
        )}
      </section>
    </>
  )
}
