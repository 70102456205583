const cityImages =
  'https://static.travelpass.com/assets/landing-pages/food-tours/'

const foodTourLocations = [
  {
    image: `${cityImages}miami-florida.webp`,
    city: 'Miami',
    state: 'Florida',
    description:
      'Miami’s food tours are a tasty journey through the city’s diverse culinary landscape, heavily influenced by Latin American and Caribbean flavors. Each tour offers travelers a chance to experience everything from the freshest seafood to deliciously authentic Cuban sandwiches. Wherever you go, the menu is sure to have you saying, “more, por favor!”',
    urlData: [
      {
        latitude: '25.761681',
        longitude: '-80.191788',
      },
    ],
  },
  {
    image: `${cityImages}new-york-city.webp`,
    city: 'New York',
    state: 'New York',
    description:
      'Foodies unite- it’s impossible to have a bad experience when eating your way through New York! Our food tours will take you straight into the diverse culinary heart of the city, offering a taste of everything from historic delis to trendy food halls. There’s something for every kind of traveler in the Big Apple, and your stomach (and camera roll) will soon be filled with unforgettable eats, everywhere from Little Italy’s pastas to the sizzling street food of Queens.',
    urlData: [
      {
        latitude: '40.776676',
        longitude: '-73.971321',
      },
    ],
  },
  {
    image: `${cityImages}chicago-illinois.webp`,
    city: 'Chicago',
    state: 'Illinois',
    description:
      'Chicago’s food tours feature some twists on classic favorites, showcasing iconic dishes like deep-dish pizza, Chicago-style hot dogs, and Italian beef. These guided experiences often wind through the lively streets of neighborhoods like the West Loop, offering a taste of the city’s robust food scene set against the backdrop of its famous architecture.',
    urlData: [
      {
        latitude: '41.881832',
        longitude: '-87.623177',
      },
    ],
  },
  {
    image: `${cityImages}seattle-washington.webp`,
    city: 'Seattle',
    state: 'Washington',
    description:
      'Seattle, Washington’s food tours highlight the city’s affinity for fresh, locally-sourced seafood and artisan coffee, reflecting its deep connection with the Pacific Northwest’s natural bounty. Travelers get to immerse themselves in the sights and sounds of Pike Place Market and beyond, discovering hidden gems and the innovation that fuels the Emerald City’s food culture.',
    urlData: [
      {
        latitude: '47.608013',
        longitude: '-122.335167',
      },
    ],
  },
  {
    image: `${cityImages}austin-texas.webp`,
    city: 'Austin',
    state: 'Texas',
    description:
      'If your favorite way to learn about a culture is through food, then Austin, Texas is about to become a popular travel destination of yours! Celebrated for its dynamic food tours that offer an authentic taste of the city’s famous barbeque, Tex-Mex cuisine, and food trucks, Austin is the place to be for a foodie. Our food tours provide a window into the thriving city’s soul, blending live music, local lore, and a community of passionate chefs that feed a lively and eclectic food scene.',
    urlData: [
      {
        latitude: '30.266666',
        longitude: '-97.733330',
      },
    ],
  },
  {
    image: `${cityImages}las-vegas-nevada.webp`,
    city: 'Las Vegas',
    state: 'Nevada',
    description:
      'For the traveler who loves to go all out, Las Vegas food tours offer a dazzling array of gourmet experiences, from the opulent buffets and celebrity chef restaurants on the Strip to the burgeoning culinary scene downtown. These tours are a chance to indulge in the extravagant flavors that mirror the city’s show-stopping, high-stakes atmosphere! There’s never a dull moment in Vegas, and there’s never a dull dish to eat when you’re traveling with Travelpass!',
    urlData: [
      {
        latitude: '36.188110',
        longitude: '-115.176468',
      },
    ],
  },
  {
    image: `${cityImages}los-angeles-california.webp`,
    city: 'Los Angeles',
    state: 'California',
    description:
      'Los Angeles food tours will take you on an adventure all across the city’s sprawling culinary scene, featuring a mosaic of flavors from its street food vendors, fusion eateries, and upscale restaurants. Whether you’re a fan of organic, farm-to-table eats or buzzy, novelty restaurants, our tours have something for everyone and will have you enjoying the latest of food trends while soaking up the warm California sun!',
    urlData: [
      {
        latitude: '34.052235',
        longitude: '-118.243683',
      },
    ],
  },
  {
    image: `${cityImages}nashville-tennessee.webp`,
    city: 'Nashville',
    state: 'Tennessee',
    description:
      'Between the live music, honky tonks, and cheerful bars, you’ll find that our food tours are the best way to get to know Nashville, TN! Each tour offers a rhythmic stroll through the city’s savory Southern cuisine, with highlights like hot chicken, barbeque, and biscuits that sing with flavor. Once you take a crunchy bite of those traditional fried green tomatoes or dip into some shrimp and grits, you’ll never think of Nashville the same way again!',
    urlData: [
      {
        latitude: '36.174465',
        longitude: '-86.767960',
      },
    ],
  },
]

export { foodTourLocations }
