import type { Dayjs } from 'dayjs'
import isEmpty from 'lodash.isempty'
import type {
  CreateEventInput,
  GetPlaceDetailsLazyQueryInGuidesQuery,
} from 'src/__generated__/graphql'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { getEventType } from 'src/pages/trips/utils'
import { getDateFromUTC, getUTCDate } from 'src/utils'

export const convertGoogleDataToEventInput = ({
  tripId,
  placeDetails,
  startDate,
  dateTab,
  dates,
  isHotelSearch = false,
  productId,
}: {
  tripId: string
  placeDetails: GetPlaceDetailsLazyQueryInGuidesQuery['getPlaceDetails']
  startDate: string
  dateTab?: number
  dates?: [Dayjs, Dayjs]
  isHotelSearch?: boolean
  productId?: string
  status?: EventStatus
}): CreateEventInput => {
  const {
    name,
    type,
    address,
    city,
    state,
    country,
    postalCode,
    latitude,
    longitude,
    googlePlaceId,
  } = placeDetails ?? {}

  const startDateDjs = getDateFromUTC(startDate)
  const currentTabDateDjs = dateTab
    ? startDateDjs?.add(dateTab, 'day')
    : startDateDjs

  const defaultUTCDate = getUTCDate(currentTabDateDjs)

  const inputStartTime = !isEmpty(dates)
    ? getUTCDate(dates?.[0])
    : defaultUTCDate

  const inputEndTime = !isEmpty(dates) ? getUTCDate(dates?.[1]) : defaultUTCDate
  const eventType = getEventType(type)
  const receivedEventType = isHotelSearch ? EventType.Stay : eventType

  const inputAddress = {
    addressLine1: address,
    googlePlaceId,
    lat: latitude,
    long: longitude,
    city,
    state,
    country,
    zipcode: postalCode,
  }

  return {
    tripId,
    name,
    startDate: inputStartTime,
    endDate: inputEndTime,
    type: receivedEventType,
    addresses: [inputAddress],
    isCustom: false,
    productId,
    status:
      eventType === EventType.Stay || eventType === EventType.Experience
        ? EventStatus.NotBooked
        : EventStatus.Na,
    useEventTime: false,
  }
}
