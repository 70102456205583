import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { TripForm } from 'src/pages/trips/components/TripForm'
import type { TripFormValues } from 'src/pages/trips/constants'
import { useCreateTrip } from '../../home/components/CreateTrip/useCreateTrip'

interface TripCreateModalProps {
  onDismiss?(): void
}

export const TripCreateModal = ({ onDismiss }: TripCreateModalProps) => {
  const { createTrip } = useCreateTrip()
  const onSubmit = (formValues: TripFormValues) => {
    createTrip(formValues)
  }

  return (
    <Modal title='Create a new trip' onDismiss={onDismiss}>
      <ModalScrollContents>
        <TripForm onSubmit={onSubmit}>
          <ModalActions>
            <Button aria-label='Create trip' startIcon='add' type='submit'>
              Create Trip
            </Button>
            <Button
              aria-label='Cancel trip creation'
              variant='outlined'
              onClick={onDismiss}
            >
              Cancel
            </Button>
          </ModalActions>
        </TripForm>
      </ModalScrollContents>
    </Modal>
  )
}
