import type { LazyQueryResultTuple, QueryResult } from '@apollo/client'
import type {
  EventType,
  ExperienceProductEdge,
  HotelSearchResultWithoutRates,
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQuery,
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQueryVariables,
  SearchHotelsQueryInTripsDetailsExploreHotelsQuery,
  SearchHotelsQueryInTripsDetailsExploreHotelsQueryVariables,
} from 'src/__generated__/graphql'
import type { MarkerColor } from 'src/component-library/theme/colors'
import type { StyledMarkerVariant } from '../../components/TripDetailsMap/markers'

enum ExploreSearchParams {
  activeExploreId = 'activeExploreId',
  productType = 'productType',
  tagIds = 'tagIds',
}

type ExploreExperience = ExperienceProductEdge

type ExploreExperiencesQueryResult = LazyQueryResultTuple<
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQuery,
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQueryVariables
>

type ExploreExperiencesResult = QueryResult<
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQuery,
  SearchExperiencesQueryInTripsDetailsExploreExperiencesQueryVariables
>

type ExploreItem = {
  id: string
  image?: string
  isFavorited?: boolean
  reviewAverage?: number
  reviewTotal?: number
  title: string
}

type ExploreHotel = HotelSearchResultWithoutRates

type ExploreHotelsQueryResult = LazyQueryResultTuple<
  SearchHotelsQueryInTripsDetailsExploreHotelsQuery,
  SearchHotelsQueryInTripsDetailsExploreHotelsQueryVariables
>

type ExploreHotelsResult = QueryResult<
  SearchHotelsQueryInTripsDetailsExploreHotelsQuery,
  SearchHotelsQueryInTripsDetailsExploreHotelsQueryVariables
>

type ExploreMarker = {
  // TODO: remove color when tripPinsV2 is released
  color: MarkerColor
  customerReviewScore?: number
  id: string
  image?: string
  lat: string
  long: string
  title: string
  totalReviews?: number
  type: EventType
  variant?: StyledMarkerVariant
}

const dateTemplate = 'YYYY-MM-DD'

const initialSize = 9

const timeTemplate = 'THH:mm:ssZ[Z]'

export { ExploreSearchParams, dateTemplate, initialSize, timeTemplate }
export type {
  ExploreExperience,
  ExploreExperiencesQueryResult,
  ExploreExperiencesResult,
  ExploreItem,
  ExploreHotel,
  ExploreHotelsQueryResult,
  ExploreHotelsResult,
  ExploreMarker,
}
