import { ExperienceSortBy } from 'src/__generated__/graphql'
import { ExperienceSearchParam } from 'src/pages/experiences/results'
import { capitalizeFirstLetterOfWords } from 'src/utils'
import { initialSort } from './filtersAndSortConstants'

export const constructResultsSortArguments = (
  searchParams?: URLSearchParams
): ExperienceSortBy => {
  const sort = searchParams?.get(ExperienceSearchParam.sort)
  const updatedSort = capitalizeFirstLetterOfWords(
    sort?.toLowerCase()?.replace(/_/g, ' ')
  ).replace(/ /g, '')

  return ExperienceSortBy?.[updatedSort] ?? initialSort
}
