import { useMemo, useState } from 'react'
import { Link } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceItinerary,
  ExperienceStandardItinerary,
  ExperienceStandardItineraryItem,
} from 'src/__generated__/graphql'
import { VerticalStep, VerticalStepper } from 'src/common/components'
import { ExperiencesItineraryTimelineStep } from './ExperiencesItineraryTimelineStep'
import { experiencesItineraryTimelineCustomCss } from './experiencesItineraryTimelineStyles'

interface ExperiencesItineraryTimelineStandardProps {
  itinerary: ExperienceItinerary
  size?: 'small' | 'medium'
}

export const ExperiencesItineraryTimelineStandard = ({
  itinerary,
  size = 'medium',
}: ExperiencesItineraryTimelineStandardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { itineraryItems } = (itinerary as ExperienceStandardItinerary) ?? {}
  const locations = useMemo(
    () =>
      itineraryItems?.filter(
        ({ passByWithoutStopping }) => !passByWithoutStopping
      ),
    []
  )
  const passByLocations = useMemo(
    () =>
      itineraryItems?.filter(
        ({ passByWithoutStopping }) => !!passByWithoutStopping
      ),
    []
  )
  const initialStepsVisible = 7
  const showExpandButton = itineraryItems?.length > initialStepsVisible
  const showTimeline = !isEmpty(itineraryItems)

  const constructedContent = ({
    admissionIncluded,
    duration,
  }: Partial<ExperienceStandardItineraryItem>) => {
    const { readableDurationRange } = duration ?? {}
    const content = []

    if (readableDurationRange) content.push(readableDurationRange)

    if (admissionIncluded === 'YES') content.push('Admission Ticket Included')

    return content.join(' • ')
  }

  const getStepVisibility = (index: number): boolean => {
    if (index > initialStepsVisible && !isExpanded) return false

    return true
  }

  return (
    showTimeline && (
      <div className={experiencesItineraryTimelineStandardCss({ size })}>
        <div className={experiencesItineraryTimelineCustomCss}>
          <VerticalStepper>
            {passByLocations?.map(
              ({ description, pointOfInterestLocation }, index) =>
                getStepVisibility(index + 1) && (
                  <VerticalStep
                    key={`${pointOfInterestLocation?.location?.name}-${index}`}
                  >
                    <ExperiencesItineraryTimelineStep
                      subtitle={description}
                      title={
                        <>
                          {pointOfInterestLocation?.location?.name}{' '}
                          <span className='color-grey-700'>(Pass By)</span>
                        </>
                      }
                    />
                  </VerticalStep>
                )
            )}
            {locations?.map(
              (
                {
                  admissionIncluded,
                  description,
                  duration,
                  pointOfInterestLocation,
                },
                index
              ) =>
                getStepVisibility(passByLocations?.length + index + 1) && (
                  <VerticalStep key={index} label={index + 1}>
                    <ExperiencesItineraryTimelineStep
                      content={constructedContent({
                        admissionIncluded,
                        duration,
                      })}
                      subtitle={description}
                      title={pointOfInterestLocation?.location?.name}
                    />
                  </VerticalStep>
                )
            )}
          </VerticalStepper>
        </div>
        {showExpandButton && (
          <div className='hide-on-print'>
            <Link
              aria-pressed={!!isExpanded}
              label={isExpanded ? 'Read Less' : 'Read More'}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        )}
      </div>
    )
  )
}

const experiencesItineraryTimelineStandardCss = ({
  size,
}: Partial<ExperiencesItineraryTimelineStandardProps>) =>
  classNames({
    'flex flex-col md:gap-4': size !== 'small',
  })
