import type { Competition } from 'src/__generated__/graphql'
import { CompetitionPhase } from 'src/__generated__/graphql'

export const hasUserEnteredPhaseTwo = (
  competitions: Competition[],
  userCurrentPhase: CompetitionPhase
) => {
  /** @description There's a competition ongoing which is on phase 2 */
  const foundCompetition = competitions?.some(
    ({ currentPhase, isActive }) =>
      currentPhase == CompetitionPhase.Phase_2 && isActive
  )
  const isCurrentUserOnPhaseTwo = userCurrentPhase == CompetitionPhase.Phase_2
  return foundCompetition && isCurrentUserOnPhaseTwo
}
