import { getGeocoderLatitude, getGeocoderLongitude } from 'src/utils'
import type { MapPoint } from './constants'
import type { LatLng } from './types'

interface FormatGeoJsonPoints<T extends LatLng> {
  items: T[]
}

export function formatDataToGeoJsonPoints<T extends LatLng>({
  items,
}: FormatGeoJsonPoints<T>): GeoJSON.Feature<GeoJSON.Point>[] {
  return items.map(item => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [item.lng, item.lat],
    },
    properties: { cluster: false, ...item },
  }))
}

export const constructMapCenter = (
  latitude: string,
  longitude: string
): MapPoint => ({
  lat: getGeocoderLatitude(latitude),
  lng: getGeocoderLongitude(longitude),
})

export const centerMap = ({
  map,
  points,
}: {
  map: google.maps.Map
  points: LatLng[]
}) => {
  const bounds = new google.maps.LatLngBounds()
  points.forEach(point => {
    const position = new google.maps.LatLng(point.lat, point.lng)
    bounds.extend(position)
  })
  map.fitBounds(bounds)
}

export const getDefaultZoom = ({
  city,
  state,
  country,
}: {
  city?: string
  state?: string
  country: string
}) => {
  if (city) return 13

  if (state) return 7

  if (country) return 4

  return 6
}
