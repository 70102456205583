import type { ReactNode } from 'react'

interface TripContentWrapperProps {
  children: ReactNode
}

export const TripContentWrapper = ({ children }: TripContentWrapperProps) => (
  <div className='b-0 p-x-2 p-y-5 w-100vw z-3 md:p-x-6 lg:b-r-grey400 lg:b-r-1px lg:b-solid lg:p-x-12 lg:w-174 bg-white'>
    {children}
  </div>
)
