import type { ReactNode } from 'react'

interface HotelResultsCardAmenityContainerProps {
  children: ReactNode
}

export const HotelResultsCardAmenityContainer = ({
  children,
}: HotelResultsCardAmenityContainerProps) => (
  <p className='color-grey-800 type-small-text-desktop flex items-center gap-1'>
    {children}
  </p>
)
