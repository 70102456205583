import { useState } from 'react'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { GuideDraftEventDeleteModal } from 'src/pages/guides/lists/common'
import { useArchiveGuideDraftEventMutation } from 'src/pages/guides/lists/useArchiveGuideDraftEventMutation'
import { useUpdateGuideDraftEventMutation } from 'src/pages/guides/lists/useUpdateGuideDraftEventMutation'
import { useGuideEventMetricLike, useGuideEventMetricShare } from '../..'
import { GuideEventSavePopover } from '../GuideEventSavePopover'

interface GuideDrawerFooterProps {
  guideId: string
  isEdit: boolean
  notes: string
  onSelectedIdChange: (updatedSelectedId: string) => void
  selectedEvent: PublishedEvent
}

export const GuideDrawerContentFooter = ({
  guideId,
  isEdit,
  notes,
  onSelectedIdChange,
  selectedEvent,
}: GuideDrawerFooterProps) => {
  const { id, likedByCurrentUser, name } = selectedEvent ?? {}
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const onGuideEventLike = useGuideEventMetricLike(id)
  const onGuideEventShare = useGuideEventMetricShare(id)
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [updatePublishedEvent] = useUpdateGuideDraftEventMutation()

  const onDelete = async () => {
    try {
      if (!!notes && !isModalDeleteOpen) {
        setIsModalDeleteOpen(true)
        return
      }
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId: id,
          },
        },
      })
      setIsModalDeleteOpen(false)
      onSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onSubmit = async () => {
    try {
      await updatePublishedEvent({
        variables: {
          publishedEventInput: {
            guideId: guideId,
            notes,
            publishedEventId: id,
          },
        },
      })
      onSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  return (
    <>
      <div className='b-grey-400 b-t-1 b-b-1 h-20.5 shadow-1 p-x-9 p-y-5 sticky bottom-0 flex flex-row items-center justify-end gap-3 border-0 border-solid bg-white'>
        {isEdit && (
          <>
            <Button
              fullWidth
              aria-label='Delete event from guide'
              startIcon='deleteOutline'
              variant='outlined'
              onClick={onDelete}
            >
              Delete
            </Button>
            <Button fullWidth aria-label='Save event note' onClick={onSubmit}>
              Save
            </Button>
          </>
        )}
        {!isEdit && (
          <>
            <GuideEventSavePopover
              id={id}
              name={name}
              trigger={
                <IconButton
                  aria-label='Save this Guide event'
                  color='white'
                  icon='add'
                  outline='round'
                  size='medium'
                />
              }
            />
            <IconButton
              aria-label='Share this Guide event'
              color='white'
              icon='iosShare'
              outline='round'
              size='medium'
              onClick={onGuideEventShare}
            />
            <IconButton
              aria-label='Like this Guide event'
              aria-pressed={likedByCurrentUser}
              color='white'
              icon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
              outline='round'
              size='medium'
              onClick={onGuideEventLike}
            />
          </>
        )}
      </div>
      {isModalDeleteOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsModalDeleteOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
