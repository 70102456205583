import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import Slider from 'rc-slider'
import type { Area, Point } from 'react-easy-crop'
import Cropper from 'react-easy-crop'

interface ImageCropperProps {
  crop?: Point
  aspect?: number
  image: string
  onCropComplete: (croppedAreaPixels: Area) => void
  zoom?: number
  cropShape?: 'round' | 'rect'
}

export const ImageCropper = ({
  image,
  aspect = 1,
  onCropComplete,
  crop = { x: 0, y: 0 },
  zoom = 1,
  cropShape = 'round',
}: ImageCropperProps) => {
  const [cropValue, setCropValue] = useState<Point>(crop)
  const [zoomValue, setZoomValue] = useState(zoom)
  const [slider, setSlider] = useState(1)

  const onChange = (value: number) => {
    setSlider(value)
    setZoomValue(value)
  }

  const onButtonClick = (value: number) => {
    const updatedZoom = zoomValue + value
    if (updatedZoom <= 3 && updatedZoom >= 1) onChange(updatedZoom)
  }

  return (
    <>
      <div className='w-100vw md:w-25vw h-25vh relative max-w-full'>
        <Cropper
          aspect={aspect}
          crop={cropValue}
          cropShape={cropShape}
          image={image}
          zoom={zoomValue}
          onCropChange={setCropValue}
          onCropComplete={(_, croppedArea: Area) => onCropComplete(croppedArea)}
          onZoomChange={onChange}
        />
      </div>
      <div className='flex w-full justify-center'>
        <IconButton icon='minus' onClick={() => onButtonClick(-0.1)} />
        <div className='w-30%'>
          <Slider
            className={classNames(
              'select-none py-5',
              '[&>.rc-slider-handle]:bg-forest-light [&>.rc-slider-handle]:b-none [&>.rc-slider-handle]:mt--1 [&>.rc-slider-handle]:op-100 [&>.rc-slider-handle]:shadow-1 [&>.rc-slider-handle]:transition-background-color-100 [&>.rc-slider-handle]:h-4 [&>.rc-slider-handle]:w-4 [&>.rc-slider-handle]:ease-linear',
              '[&>.rc-slider-handle.rc-slider-handle-dragging]:b-none [&>.rc-slider-handle.rc-slider-handle-dragging]:bg-forest [&>.rc-slider-handle.rc-slider-handle-dragging]:shadow-1',
              '[&>.rc-slider-rail]:bg-grey-400 [&>.rc-slider-rail]:rounded-0 [&>.rc-slider-rail]:h-2',
              '[&>.rc-slider-track]:bg-forest [&>.rc-slider-track]:b-forest [&>.rc-slider-track]:h-2'
            )}
            max={3}
            min={1}
            step={0.1}
            value={slider}
            onChange={onChange}
          />
        </div>
        <IconButton icon='add' onClick={() => onButtonClick(0.1)} />
      </div>
    </>
  )
}
