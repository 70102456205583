import type { ReactElement } from 'react'
import { MenuItem, Popover } from '@travelpass/design-system'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { dispatchToggleAddToGuideModal } from 'src/common/components/Guides/dispatchToggleAddToGuideModal'

interface GuideEventSavePopoverProps {
  id: PublishedEvent['id']
  name: PublishedEvent['name']
  trigger: ReactElement
}

export const GuideEventSavePopover = ({
  id,
  name,
  trigger,
}: GuideEventSavePopoverProps) => {
  if (!id || !name || !trigger) return null

  return (
    <Popover placement='bottom' trigger={trigger}>
      <MenuItem
        label='Add to Guide'
        startIcon='listAlt'
        value='addToGuide'
        onClick={() =>
          dispatchToggleAddToGuideModal({
            isModalOpen: true,
            item: {
              id,
              name,
              type: CollectionItemIdType.Event,
            },
          })
        }
      />
      <MenuItem
        label='Add to Collection'
        startIcon='bookmarkBorder'
        value='addToCollection'
        onClick={() =>
          dispatchToggleAddToCollectionsModal({
            isModalOpen: true,
            item: {
              id,
              name,
              type: CollectionItemIdType.Event,
            },
          })
        }
      />
    </Popover>
  )
}
