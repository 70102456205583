import { useEffect } from 'react'
import {
  Button,
  MAX_MOBILE_WIDTH,
  MIN_TABLET_WIDTH,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import {
  GuideDraftStatus,
  type GetTripDetailsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import {
  getTripDetailsQuery,
  useCreateGuideDraftFromTripMutationOld,
} from 'src/pages/trips/hooks'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { useCreateGuideDraftFromTripMutation } from '../hooks'

const sizes = `(max-width: ${MAX_MOBILE_WIDTH}px) ${MAX_MOBILE_WIDTH}px, ${MIN_TABLET_WIDTH}px`

interface TripPublishGuideDraftProps {
  onDismiss: () => void
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripPublishGuideDraft = ({
  onDismiss,
  tripDetailsData,
}: TripPublishGuideDraftProps) => {
  const isGuidePublishingV3Enabled = useFlag('guidePublishingV3')
  const [
    createGuideDraftFromTrip,
    { loading: isCreateGuideDraftFromTripLoading },
  ] = useCreateGuideDraftFromTripMutation()
  const [
    createGuideDraftFromTripOld,
    { loading: isCreateGuideDraftFromTripLoadingOld },
  ] = useCreateGuideDraftFromTripMutationOld()
  const navigate = useNavigate()
  const { addSuccessSnack } = useSnackbar()
  const { id, guideDraft } = tripDetailsData ?? {}

  useEffect(() => {
    if (
      !isEmpty(guideDraft) &&
      guideDraft?.status === GuideDraftStatus.Active
    ) {
      navigate(
        getGuideDraftDetailsUrl({
          id: guideDraft?.id ?? '',
          isUserOwner: true,
          name: guideDraft?.name ?? '',
        })
      )
    }
  }, [guideDraft])

  const onSubmit = async () => {
    if (
      isCreateGuideDraftFromTripLoading ||
      isCreateGuideDraftFromTripLoadingOld
    )
      return

    try {
      /** @todo remove refetch if possible */
      if (isGuidePublishingV3Enabled) {
        await createGuideDraftFromTrip({
          variables: {
            input: {
              tripId: id,
            },
          },
          refetchQueries: [
            {
              query: getTripDetailsQuery,
              variables: {
                tripId: id ?? '',
              },
            },
          ],
        })
      } else {
        await createGuideDraftFromTripOld({
          variables: {
            input: {
              tripId: id,
            },
          },
          refetchQueries: [
            {
              query: getTripDetailsQuery,
              variables: {
                tripId: id ?? '',
              },
            },
          ],
        })
      }
      addSuccessSnack({
        title: 'Your guide is now being published.',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal title='Publish to the Travelpass Community' onDismiss={onDismiss}>
      <ModalScrollContents>
        <section className='space-y-3 md:space-y-4'>
          <div className='md:p-x-11 space-y-3'>
            <h4 className='color-black type-h6-desktop'>
              Create a Guide From Your Trip
            </h4>
            <p>
              Creating a Guide will let you customize your details and share
              your experience.
            </p>
          </div>
          <img
            alt=''
            className='m-a block h-auto max-w-full'
            sizes={sizes}
            src='https://static.travelpass.com/assets/guides/publish-guide.webp'
            srcSet={`https://static.travelpass.com/assets/guides/publish-guide-vertical.webp ${MAX_MOBILE_WIDTH}w, https://static.travelpass.com/assets/guides/publish-guide.webp ${MIN_TABLET_WIDTH}w`}
          />
        </section>
      </ModalScrollContents>
      <ModalActions>
        <Button disabled={isCreateGuideDraftFromTripLoading} onClick={onSubmit}>
          Create Guide
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
