import { Skeleton, useScreenQuery } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { HotelResultsListContainer } from './HotelResultsListContainer'
import { HotelResultsListHeader } from './HotelResultsListHeader'
import { HotelResultsCardLoading, HotelResultsCardRowLoading } from '../common'
import { HotelResultsListView } from '../hotelResultsConstants'
import { getHotelResultsListViewFromSearchParams } from '../hotelResultsUtils'

export const HotelResultsListLoading = () => {
  const { isMobileScreen } = useScreenQuery()
  const [searchParams] = useSearchParams()
  const listView = getHotelResultsListViewFromSearchParams({
    isMobile: isMobileScreen,
    searchParams,
  })

  return (
    <div className='lg:space-y-1'>
      <HotelResultsListHeader>
        <div className='flex h-8 flex-col justify-center'>
          <div className='w-41 h-4 md:h-5'>
            <Skeleton />
          </div>
        </div>
      </HotelResultsListHeader>
      <HotelResultsListContainer listView={listView}>
        {listView === HotelResultsListView.list ? (
          <>
            <HotelResultsCardRowLoading />
            <HotelResultsCardRowLoading />
            <HotelResultsCardRowLoading />
            <HotelResultsCardRowLoading />
          </>
        ) : (
          <>
            <HotelResultsCardLoading />
            <HotelResultsCardLoading />
            <HotelResultsCardLoading />
            <HotelResultsCardLoading />
          </>
        )}
      </HotelResultsListContainer>
    </div>
  )
}
