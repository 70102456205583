import { Button, Divider, Illustration } from '@travelpass/design-system'
import { EventType } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { HomeHeroContents } from 'src/pages/home-old/HomeHero/HomeHeroContents'

export const BookAnExperience = () => {
  const onExploreExperiencesClick = e => {
    e.preventDefault()
    const node = document.getElementById('explore-experiences')
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='md:h-650px lg:h-800px h-auto w-full bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/681eef4a-da6a-11ee-8fb7-ae0aca37e831)] bg-cover bg-center'>
        <PageLayoutContainer>
          <div
            className='md:h-650px lg:h-800px my-2 flex h-auto w-full flex-col justify-center gap-8 md:my-0'
            id='explore-experiences'
          >
            <div className='bg-forestDark/60 rounded-6 w-full p-4 md:p-10'>
              <h1 className='c-white font-archivo font-not-oblique font-700 text-10 md:text-16 lg:text-22 m-0 text-center drop-shadow-lg'>
                Make Every Trip Unforgettable
              </h1>
              <p className='type-subtitle-1 c-white font-300 md:max-w-650px lg:max-w-850px lg:line-height-8 mx-a mb-8 w-full text-center drop-shadow-lg'>
                Travel better with Travelpass experiences. From catamaran
                cruises to foodie tours, we have just the thing to take your
                trip to the next level.
              </p>
              <HomeHeroContents type={EventType.Experience} />
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='mt-10 w-full py-10'>
        <PageLayoutContainer size='medium'>
          <div className='flex flex-col md:flex-row md:items-center'>
            <div className='basis-1/2'>
              <div className='w-200px md:w-250px mx-a'>
                <Illustration name='jeep' />
              </div>
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h2 text-center md:text-left'>
                Never Wonder What To Do
              </h2>
              <p className='type-body-1 font-300 text-center md:text-left'>
                Search thousands of different experiences, designed for every
                dream destination and every type of traveler.
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <PageLayoutContainer size='medium'>
        <Divider />
      </PageLayoutContainer>

      <div className='w-full py-10'>
        <PageLayoutContainer size='medium'>
          <div className='flex flex-col md:flex-row md:items-center'>
            <div className='basis-1/2'>
              <div className='w-200px md:w-250px mx-a'>
                <Illustration name='cardLady' />
              </div>
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h2 text-center md:text-left'>
                Build The Ultimate Vacation
              </h2>
              <p className='type-body-1 font-300 text-center md:text-left'>
                Book Travelpass experiences that complement your Travelpass
                hotel booking for seamless trip management (and enjoyment).
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <PageLayoutContainer size='medium'>
        <Divider />
      </PageLayoutContainer>

      <div className='w-full py-10'>
        <PageLayoutContainer size='medium'>
          <div className='flex flex-col md:flex-row md:items-center'>
            <div className='basis-1/2'>
              <div className='w-200px md:w-250px mx-a'>
                <Illustration name='suitcase' />
              </div>
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h2 text-center md:text-left'>
                Book The Best
              </h2>
              <p className='type-body-1 font-300 text-center md:text-left'>
                Find top-rated experiences, with transparent pricing, tour
                details, and thousands of five-star reviews.
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='bg-forest w-full py-10'>
        <h2 className='type-h2 c-white text-center'>Travelpass Experiences</h2>
        <p className='type-body-1 font-300 c-white mx-a max-w-450px mt-4 text-center'>
          Discover the best things to do, whether you&apos;re traveling far or
          staying close to home.
        </p>
        <div className='w-300px mx-a mt-8'>
          <Button
            fullWidth
            label='Explore Experiences'
            size='large'
            variant='outlined'
            onClick={onExploreExperiencesClick}
          />
        </div>
      </div>
    </>
  )
}

const canonicalUrl =
  'https://www.travelpass.com/book-an-experience-with-travelpass'
const pageName = 'Book an Experience with Travelpass'
const metaDescription =
  'Find top-rated experiences, tours, activities and attractions for every type of traveler with Travelpass Experiences. Explore things to do in your dream destination, or look for activities close to home.'
