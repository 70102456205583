import { Icon } from '@travelpass/design-system'

export const UniqueTrip = () => (
  <div className='bg-warmGrey pl-8% lg:pr-0% pr-8% pt-80px pb-50px flex flex-col gap-10 lg:flex-row'>
    <section className='c-forestLight lg:w-30% max-w-90% lg:max-w-50% flex w-full flex-col gap-y-8'>
      <h2 className='type-h2 font700 w-full text-center lg:text-left'>
        Your Trip, Your Way!
      </h2>
      <div className='flex justify-center lg:justify-start'>
        <div className='border-3px border-valley w-26 border-solid'></div>
      </div>

      <article className='flex w-full gap-5'>
        <Icon name='postAddIcon' size='large' />
        <div className='flex flex-col gap-3'>
          <h4 className='type-h4-desktop'>Add Events</h4>
          <p className='type-body-1'>
            Easily create a day-by-day itinerary filled with recommended tours,
            museum visits, restaurants to try, and more.
          </p>
        </div>
      </article>

      <article className='flex w-full gap-5'>
        <Icon name='bookmarkAddedIcon' size='large' />
        <div className='flex flex-col gap-3'>
          <h4 className='type-h4-desktop'>Plan A Perfect Day</h4>
          <p className='type-body-1'>
            Browse and book a hotel or experience you love, then see it
            instantly appear on your itinerary.
          </p>
        </div>
      </article>

      <article className='flex w-full gap-5'>
        <Icon name='travelExploreIcon' size='large' />
        <div className='flex flex-col gap-3'>
          <h4 className='type-h4-desktop'>Go Anywhere</h4>
          <p className='type-body-1'>
            Trips is seamlessly integrated with navigation maps and rideshares,
            making it easy to travel around your destination.
          </p>
        </div>
      </article>
    </section>
    <img
      alt='plan'
      className='max-w-90% lg:max-w-50% w-full'
      src='https://static.travelpass.com/assets/plan/unique-trip-banner.webp'
    />
  </div>
)
