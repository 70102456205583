import isEmpty from 'lodash.isempty'
import type { GetTripEventsQueryInTripsQuery } from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { MarkerColors } from 'src/component-library/theme/colors'
import { constructImageLink } from 'src/utils'
import type {
  ExploreExperience,
  ExploreHotel,
  ExploreMarker,
} from '../../constants'
import { getExperienceAsLatLong } from '../../utils'

const dedupeTripExploreMarkers = (markers: ExploreMarker[]) =>
  markers?.reduce((total, current) => {
    if (
      !isEmpty(current) &&
      total.findIndex(
        ({ lat, long }) => current?.lat === lat && current?.long === long
      ) === -1
    )
      total.push(current)

    return total
  }, []) ?? []

const getTripExploreExperiencesAsMarkerObject = (
  tripDetailsExploreExperiencesData: ExploreExperience[]
): ExploreMarker[] =>
  tripDetailsExploreExperiencesData?.reduce((total, current) => {
    const { id, images, destinations, logistics, reviews, title } =
      current?.node ?? {}
    const { combinedAverageRating, totalReviews } = reviews ?? {}
    const { lat, long } = getExperienceAsLatLong({ destinations, logistics })

    if (!!lat && !!long)
      total.push({
        color: MarkerColors.BOOKED,
        customerReviewScore: combinedAverageRating ?? 0,
        id,
        image: images?.size720x480?.[0] ?? '',
        lat,
        long,
        totalReviews: totalReviews ?? 0,
        title,
        type: EventType.Experience,
        variant: 'explore',
      })

    return total
  }, []) ?? []

const getTripExploreEventsAsMarkerObject = (
  tripDetailsExploreEventsData: GetTripEventsQueryInTripsQuery['getTrip']
): ExploreMarker[] =>
  tripDetailsExploreEventsData?.dailyEventBreakdown?.scheduled
    ?.flatMap(schedule => schedule.events)
    ?.reduce((total, current) => {
      const { addresses, id, imageUrl, name, type } = current ?? {}
      const { lat, long } = addresses?.[0] ?? {}

      if (!!lat && !!long)
        total.push({
          color: MarkerColors.NOT_IN_ACTIVE_VIEW,
          id,
          image: imageUrl ?? '',
          lat,
          long,
          title: name,
          type,
        })

      return total
    }, []) ?? []

const getTripExploreHotelsAsMarkerObject = (
  tripDetailsExploreHotelsData: ExploreHotel[]
): ExploreMarker[] =>
  tripDetailsExploreHotelsData?.reduce((total, current) => {
    const {
      customerReviewScore,
      hotelAddress,
      name,
      id,
      images,
      totalCustomerReviews,
    } = current ?? {}
    const { latitude: lat, longitude: long } = hotelAddress || {}
    const image = images?.[0] ? constructImageLink(images?.[0], '720x480') : ''

    if (!!lat && !!long)
      total.push({
        id,
        color: MarkerColors.BOOKED,
        customerReviewScore: customerReviewScore ?? 0,
        image,
        lat,
        long,
        title: name,
        totalReviews: totalCustomerReviews ?? 0,
        type: EventType.Stay,
        variant: 'explore',
      })

    return total
  }, []) ?? []

export {
  dedupeTripExploreMarkers,
  getTripExploreExperiencesAsMarkerObject,
  getTripExploreEventsAsMarkerObject,
  getTripExploreHotelsAsMarkerObject,
}
