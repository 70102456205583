import { useEffect, useMemo, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  getArrivalDate,
  getDepartureDate,
  getGeocoderLatitude,
  getGeocoderLongitude,
  getGeocoderPlaceName,
  getTracker,
  logError,
} from 'src/utils'
import type { ResultsRefetchArguments } from './common/utils'
import {
  constructResultsFiltersArguments,
  constructResultsSortArguments,
} from './common/utils'
import { ExperienceSearchParam } from '../results'

/**
 * @desc attempting to destructure the location string to get the parts from Google Places location string
 */
const destructureLocation = string => {
  const locationParts = string?.split(',').map(part => part?.trim())
  switch (locationParts?.length) {
    case 1: {
      const [city] = locationParts
      return {
        city,
      }
    }
    case 2: {
      const [city, state] = locationParts
      return {
        city,
        state,
      }
    }
    case 3: {
      const [city, state, country] = locationParts
      return {
        city,
        state,
        country,
      }
    }
    case 4: {
      const [_name, city, state, country] = locationParts
      return {
        city,
        state,
        country,
      }
    }
    // assume something went wrong
    default: {
      return {
        city: null,
        state: null,
        country: null,
      }
    }
  }
}

const PAGE_SIZE = 48

const EXPERIENCE_SEARCH_QUERY = gql(`
query ExperienceSearch($experienceSearchArgs: ExperienceSearchArgs!, $first: Int, $after: String) {
  experienceSearch(experienceSearchArgs: $experienceSearchArgs, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        fromPrice {
          amount
          currency
        }
        cancellationPolicy {
          type
        }
        images {
          size360x240
        }
        readableDuration
        pricingInfo {
          unitType
        }
        reviews {
          combinedAverageRating
          totalReviews
        }
        title
        isCollected
        isFavorited
      }
    }
  }
}
`)

export const useExperienceSearchQuery = () => {
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [searchParams] = useSearchParams()

  const constructedResultsArguments = useMemo(() => {
    const latitude = searchParams.get(ExperienceSearchParam.latitude)
    const location = searchParams.get(ExperienceSearchParam.location)
    const longitude = searchParams.get(ExperienceSearchParam.longitude)
    const urlArrival = searchParams.get(ExperienceSearchParam.arrival)
    const urlDeparture = searchParams.get(ExperienceSearchParam.departure)

    const constructedLocation = getGeocoderPlaceName(location)
    const constructedResultsSortArguments =
      constructResultsSortArguments(searchParams)
    const constructedResultsFiltersArguments =
      constructResultsFiltersArguments(searchParams)

    const arrival = getArrivalDate({ date: urlArrival })
    const departure = getArrivalDate({ date: urlDeparture })
    return {
      first: PAGE_SIZE,
      experienceSearchArgs: {
        arrival,
        departure: getDepartureDate({ date: departure }),
        filters: constructedResultsFiltersArguments,
        searchBy: {
          latLong: {
            latitude: getGeocoderLatitude(latitude),
            longitude: getGeocoderLongitude(longitude),
          },
        },
        sortBy: constructedResultsSortArguments,
        tracker: getTracker(constructedLocation),
      },
    }
  }, [searchParams])

  useEffect(() => {
    onRefetch({
      arrival: getArrivalDate({ date: searchParams.get('arrival') }),
      departure: getDepartureDate({ date: searchParams.get('departure') }),
      latitude: getGeocoderLatitude(searchParams.get('latitude')),
      longitude: getGeocoderLongitude(searchParams.get('longitude')),
    })
  }, [searchParams])

  const {
    data,
    error: getExpResultsError,
    loading,
    networkStatus,
    fetchMore: onGetMoreResultsQuery,
    refetch,
  } = useQuery(EXPERIENCE_SEARCH_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: constructedResultsArguments,
    onCompleted: data => {
      const experiences = data?.experienceSearch?.edges?.map(e => e.node.id)

      pushDataToDataLayer('experienceSearchResults', {
        experiences, // assumption: we should not need to send the whole result payload to GTM
        searchArgs: constructedResultsArguments,
        location: destructureLocation(searchParams.get('location')),
      })
    },
  })

  const isRefetching = networkStatus === NetworkStatus.refetch
  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore
  const isLoading = (!isMoreResultsLoading && loading) || isRefetching

  const { pageInfo } = data?.experienceSearch ?? {}
  const resultsData = data?.experienceSearch?.edges ?? []

  const onRefetch = async (
    updatedResultsArguments: ResultsRefetchArguments
  ) => {
    const arrival = getArrivalDate({ date: searchParams.get('arrival') })
    const departure = getArrivalDate({ date: searchParams.get('departure') })

    const constructedLocation = getGeocoderPlaceName(
      searchParams.get('location')
    )
    const constructedResultsSortArguments =
      constructResultsSortArguments(searchParams)
    const constructedResultsFiltersArguments =
      constructResultsFiltersArguments(searchParams)

    const constructedResultsArguments = {
      first: PAGE_SIZE,
      experienceSearchArgs: {
        arrival,
        departure: getDepartureDate({ date: departure }),
        filters: constructedResultsFiltersArguments,
        searchBy: {
          latLong: {
            latitude: getGeocoderLatitude(searchParams.get('latitude')),
            longitude: getGeocoderLongitude(searchParams.get('longitude')),
          },
        },
        sortBy: constructedResultsSortArguments,
        tracker: getTracker(constructedLocation),
      },
      arrival,
      departure,
    }

    if (updatedResultsArguments?.filters) {
      delete constructedResultsArguments.experienceSearchArgs.filters
    }

    const mergedResultsArguments = merge(constructedResultsArguments, {
      experienceSearchArgs: {
        ...updatedResultsArguments,
      },
    })

    await refetch(mergedResultsArguments)
  }

  const onGetMoreResults = async () => {
    if (hasMoreResults) {
      await onGetMoreResultsQuery({
        variables: {
          ...constructedResultsArguments,
          after: pageInfo?.endCursor,
        },
        updateQuery: (previousResults, { fetchMoreResult }) => {
          const updatedResults = cloneDeep(fetchMoreResult)
          const { edges: previousResultsData } =
            previousResults?.experienceSearch ?? {}
          const { pageInfo, edges: currentResultsData } =
            fetchMoreResult?.experienceSearch ?? {}
          setHasMoreResults(pageInfo.hasNextPage)

          const updatedResultsData =
            previousResultsData.concat(currentResultsData)

          updatedResults.experienceSearch.edges = updatedResultsData
          return updatedResults
        },
      }).catch(error => logError(error))
    } else {
      setHasMoreResults(false)
    }
  }

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      setHasMoreResults(true)
    } else {
      setHasMoreResults(false)
    }
  }, [pageInfo])

  return {
    resultsData,
    hasError: getExpResultsError,
    hasMoreResults,
    isLoading,
    loading,
    isMoreResultsLoading,
    onGetMoreResults,
    onRefetch,
  }
}
