import { Avatar } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import type { UserProfile } from 'src/__generated__/graphql'
import { getProfilePageUrl } from 'src/utils'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../profileConstants'
import { FollowButton } from '../FollowButton/FollowButton'

interface FollowerListItemProps {
  follower: Pick<
    UserProfile,
    | 'id'
    | 'accountHandle'
    | 'displayName'
    | 'isFollowed'
    | 'isUserOwner'
    | 'profileImageUrl'
    | 'userId'
  >
}

export const FollowerListItem = ({ follower }: FollowerListItemProps) => {
  const {
    accountHandle,
    profileImageUrl,
    displayName,
    userId,
    isFollowed,
    isUserOwner,
  } = follower ?? {}
  const url = getProfilePageUrl(accountHandle)

  if (!follower) return

  return (
    <div className='flex flex-row items-center gap-4'>
      <a
        className='hidden no-underline md:block'
        href={url}
        rel='noreferrer'
        target='_blank'
      >
        <Avatar
          border='none'
          size='sm'
          src={profileImageUrl ? profileImageUrl : DEFAULT_PROFILE_IMAGE_URL}
        />
      </a>
      <a
        className='no-underline md:hidden'
        href={url}
        rel='noreferrer'
        target='_blank'
      >
        <Avatar
          border='none'
          size='xs'
          src={profileImageUrl ? profileImageUrl : DEFAULT_PROFILE_IMAGE_URL}
        />
      </a>
      <div className='flex grow flex-row items-center justify-between gap-8'>
        <Link className='shrink-1 flex flex-col gap-1 no-underline' to={url}>
          <p className='type-h6-mobile type-h5-desktop line-clamp-1 break-all'>
            {displayName}
          </p>
          <p className='type-body-2 c-gray font-100 line-clamp-1 break-all'>
            {`@${accountHandle}`}
          </p>
        </Link>
        <div className='w-28'>
          {!isUserOwner && (
            <FollowButton
              accountHandle={accountHandle}
              displayName={displayName}
              fullWidth={true}
              isFollowed={isFollowed}
              label={isFollowed ? 'unfollow' : 'follow'}
              userId={userId}
              variant={isFollowed ? 'outlined' : 'filled'}
            />
          )}
        </div>
      </div>
    </div>
  )
}
