import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { FastFriendsLocations } from './FastFriendsLocations'

export const FastFriends = () => {
  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/fast-friends/'
  const navigate = useNavigate()

  const onExploreMoreClick = () => {
    const url = '/'
    navigate(url)
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className={topContainer}>
        <div className={videoContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={videoBg}
            preload='metadata'
          >
            <source
              src={`${imagePath}fast-friends-loop.mp4`}
              type='video/mp4'
            />
            <track kind='captions' />
          </video>
        </div>
        <div className={lowerContainer}>
          <PageLayoutContainer>
            <div className={lowerContainerInner}>
              <div className={h1Container}>
                <h1 className={h1Styles}>
                  <span className={h1Upper}>
                    Explore Great Places And Make Some
                  </span>
                  <span className={h1Lower}>Fast Friends</span>
                </h1>
              </div>
              <div className={carouselContainer}>
                <FastFriendsLocations />
              </div>
            </div>
          </PageLayoutContainer>
        </div>
      </div>
      <div className={bottomContainer}>
        <PageLayoutContainer>
          <h2 className={bottomContainerTitleTop}>Eager for more adventure?</h2>
          <h2 className={bottomContainerTitle}>
            Travelpass has incredible, top-rated activities available in all of
            your favorite cities.
          </h2>
          <div className={buttonContainer}>
            <Button
              fullWidth
              label='Explore More'
              size='large'
              onClick={onExploreMoreClick}
            />
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}

const canonicalUrl = 'https://www.travelpass.com/fast-friends'
const metaDescription = 'Explore great places and make some fast friends'
const pageName = 'Fast Friends'

const topContainer =
  'relative bg-gradient-to-t from-warmGrey from-83% lg:from-5% to-transparent to-92% lg:to-30% pt40 lg:pt10 pb20 lg:pb60'
const videoContainer = 'absolute h-full top-0 bottom-0 left-0 right-0 z--2'
const videoBg =
  'absolute top-0 right-0 bottom-0 left-0 aspect-auto w-full z-1 lg:z--1'
const lowerContainer = 'relative lg:z-10 w-full lg:w50%'
const lowerContainerInner =
  'lg:flex lg:flex-col w-full max-w1280px ma items-start'
const h1Container = 'w-full mt-30 lg:mt-0'
const h1Styles =
  'c-forest lg:c-white flex flex-col font-archivo font-not-oblique font-700 m0 text-center tracking-5%'
const h1Upper =
  'leading-34px mb3 text-21px lg:leading-19 lg:text-6 text-shadow-lg'
const h1Lower =
  'leading-15 text-16 lg:mb10 lg:leading-24 lg:text-24 text-shadow-lg'
const carouselContainer = 'w-full max-w-800px mxa'
const bottomContainer = 'w-full py16 bg-warmGrey text-center'
const bottomContainerTitleTop =
  'type-h2 text-8 leading-8 lg:text-14 lg:leading-14 font-900 color-forest max-w65% ma mb8'
const bottomContainerTitle = 'type-h2 color-forest max-w65% ma'
const buttonContainer = 'w230px mxa mt14'
