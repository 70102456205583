import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query ExperiencePlacesData($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      address
      amenities
      city
      country
      description
      googlePlaceId
      imageLinks
      longitude
      latitude
      name
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      starRating
      state
      website
    }
  }
`)

export const useExperiencePlacesData = () => {
  const [
    getExperiencePlacesData,
    { data: experiencePlacesData, loading, error },
  ] = useLazyQuery(getPlaceDetailsQuery)
  const data = experiencePlacesData?.getPlaceDetails ?? null

  return {
    getExperiencePlacesData,
    data,
    error,
    loading,
  }
}
