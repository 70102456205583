import { useEffect, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { ExperienceBookingTimeState } from 'src/__generated__/graphql'
import { logError } from 'src/utils'
import { ExperienceBookingsList } from './ExperienceBookingsList'
import { currentUserExperienceBookingsGQL } from './experienceBookingsGql'
import {
  BookingSection,
  BookingsEmpty,
  BookingsLoading,
} from '../common/components'
import { cardsPerPage } from '../common/constants'

interface ExperienceBookingsApolloProps {
  bookingStatus: ExperienceBookingTimeState
}

export const ExperienceBookingsApollo = ({
  bookingStatus,
}: ExperienceBookingsApolloProps) => {
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const variables = {
    first: cardsPerPage,
    searchUserExperienceBookingsArgs: {
      bookingTimeState: bookingStatus,
    },
  }

  const { data, error, loading, networkStatus, fetchMore } = useQuery(
    currentUserExperienceBookingsGQL,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  )
  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore

  const { edges, pageInfo, totalCount } =
    data?.currentUser?.experienceBookings ?? {}

  const noContent = !!error || isEmpty(edges)

  const onGetMoreResults = async () => {
    if (hasMoreResults) {
      await fetchMore({
        variables: {
          ...variables,
          after: pageInfo?.endCursor,
        },
        updateQuery: (previousResults, { fetchMoreResult }) => {
          const updatedResults = cloneDeep(fetchMoreResult)

          const { edges: previousResultsData } =
            previousResults?.currentUser?.experienceBookings ?? {}
          const { pageInfo, edges: currentResultsData } =
            fetchMoreResult?.currentUser?.experienceBookings ?? {}

          setHasMoreResults(pageInfo?.hasNextPage)

          const updatedResultsData =
            previousResultsData?.concat(currentResultsData) ?? []

          updatedResults.currentUser.experienceBookings.edges =
            updatedResultsData

          return updatedResults
        },
      }).catch(error => logError(error))
    } else {
      setHasMoreResults(false)
    }
  }

  const renderContent = () => {
    if (loading && !isMoreResultsLoading) {
      const isCancelled = bookingStatus === ExperienceBookingTimeState.Cancelled
      return <BookingsLoading cancelled={isCancelled} />
    }
    if (noContent) {
      return <BookingsEmpty />
    }
    return (
      <>
        <ExperienceBookingsList bookings={edges} />
        {isMoreResultsLoading && <BookingsLoading />}
      </>
    )
  }

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      setHasMoreResults(true)
    } else {
      setHasMoreResults(false)
    }
  }, [pageInfo])

  return (
    <BookingSection
      contentLength={totalCount}
      hasMoreResults={!noContent && hasMoreResults}
      isLoading={loading}
      isMoreResultsLoading={isMoreResultsLoading}
      subtitle='experience'
      title={bookingStatus}
      onGetMoreResults={onGetMoreResults}
    >
      {renderContent()}
    </BookingSection>
  )
}
