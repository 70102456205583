import type { NavigateFunction } from 'react-router-dom'
import type { Suggestion } from 'use-places-autocomplete'
import { getGeocode, getLatLng } from 'use-places-autocomplete'

export const handleDestinationSearch = async (
  selection: Suggestion,
  navigate: NavigateFunction,
  searchParams: URLSearchParams
) => {
  const [result] = await getGeocode({
    placeId: selection.place_id,
  })
  const { lat, lng } = getLatLng(result)
  searchParams.set('displayName', result.formatted_address)
  searchParams.set('location', result.formatted_address)
  searchParams.set('latitude', lat.toString())
  searchParams.set('longitude', lng.toString())
  const url = `/destinations/results/?${searchParams.toString()}`
  navigate(url)
}
