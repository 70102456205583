import type { CreateEventInput } from 'src/__generated__/graphql'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { defaultTimeRange } from 'src/common/components/TimeRangePicker/timeRangeConstants'
import { combineDateAndTimeToUTC } from 'src/utils'
import { constructAddressInput } from './geocoderUtils'
import type { EventFormValuesType } from '../components/EventForm'

const getUseEventTime = ({
  eventType,
  startTime,
  endTime,
}: {
  eventType: EventType
  startTime: string
  endTime: string
}) => {
  if (
    eventType === EventType.Transportation ||
    eventType === EventType.Miscellaneous
  ) {
    return (
      startTime !== defaultTimeRange.startTime ||
      endTime !== defaultTimeRange.endTime
    )
  }
  return false
}

const generateMiscellaneousEventInput = ({
  formValues,
  eventType,
  tripId,
  timezone,
}: {
  formValues: EventFormValuesType
  eventType: EventType
  tripId: string
  timezone: string
}): CreateEventInput => {
  const {
    startLocation,
    endLocation,
    name,
    dates,
    timeRange,
    notes,
    customBooked,
  } = formValues ?? {}

  const { startTime, endTime } = timeRange ?? {}

  const useEventTime = getUseEventTime({ eventType, startTime, endTime })
  const startDate = combineDateAndTimeToUTC({
    date: dates?.[0],
    time: startTime,
    timezone,
  })
  const endDate = combineDateAndTimeToUTC({
    date: dates?.[1] ?? dates?.[0],
    time: endTime === defaultTimeRange.endTime ? startTime : endTime,
    timezone,
  })

  const eventInput: CreateEventInput = {
    name,
    tripId,
    type: eventType,
    startDate,
    endDate,
    notes,
    isCustom: true,
    status: customBooked ? customBooked : EventStatus.Na,
    useEventTime,
  }

  if (startLocation) {
    eventInput.addresses = [constructAddressInput(startLocation)]
    if (endLocation) {
      eventInput.addresses.push(constructAddressInput(endLocation))
    }
  }

  return eventInput
}

const generateOtherEventInput = ({
  formValues,
  eventType,
  tripId,
  timezone,
}: {
  formValues: EventFormValuesType
  eventType: EventType
  tripId: string
  timezone: string
}): CreateEventInput => {
  const {
    startLocation,
    endLocation,
    name,
    dates,
    timeRange,
    notes,
    customBooked,
  } = formValues ?? {}
  const { startTime, endTime } = timeRange ?? {}

  const useEventTime = getUseEventTime({ eventType, startTime, endTime })
  const startDate = combineDateAndTimeToUTC({
    date: dates[0],
    time: startTime === 'None' ? '12:00 AM' : startTime,
    timezone,
  })
  const endDate = combineDateAndTimeToUTC({
    date: dates[1],
    time: endTime === defaultTimeRange.endTime ? startTime : endTime,
    timezone,
  })

  const eventInput: CreateEventInput = {
    name,
    tripId,
    type: eventType,
    startDate,
    endDate,
    notes,
    isCustom: true,
    status: customBooked ? customBooked : EventStatus.Na,
    useEventTime,
  }

  if (startLocation) {
    eventInput.addresses = [constructAddressInput(startLocation)]
    if (endLocation) {
      eventInput.addresses.push(constructAddressInput(endLocation))
    }
  }

  return eventInput
}

const generateEventInput = ({
  formValues,
  eventType,
  tripId,
  timezone,
}: {
  formValues: EventFormValuesType
  eventType: EventType
  tripId: string
  timezone: string
}): CreateEventInput => {
  if (eventType === EventType.Miscellaneous) {
    const miscellaneousEventInput = generateMiscellaneousEventInput({
      formValues,
      eventType,
      tripId,
      timezone,
    })

    return miscellaneousEventInput
  } else {
    const otherEventInput = generateOtherEventInput({
      formValues,
      eventType,
      tripId,
      timezone,
    })

    return otherEventInput
  }
}

export { generateEventInput }
