import { Carousel, useScreenQuery } from '@travelpass/design-system'
import { CityCard } from './components/CityCard'
import { Header } from './components/header'

export type City = {
  city: string
  state: string
  img: string
  href: string
}

const cities: City[] = [
  {
    city: 'San Diego',
    state: 'California',
    img: 'https://static.travelpass.com/assets/featured-cities/san-diego.webp',
    href: '/destinations/ca/san-diego',
  },
  {
    city: 'Seattle',
    state: 'Washington',
    img: 'https://static.travelpass.com/assets/featured-cities/seattle.webp',
    href: '/destinations/wa/seattle',
  },
  {
    city: 'Boston',
    state: 'Massachusetts',
    img: 'https://static.travelpass.com/assets/featured-cities/boston.webp',
    href: '/destinations/ma/boston',
  },
]

export const FeaturedCities = () => {
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()

  return (
    <div className='pt-25 bg-warm-grey md:bg-top-center mt--35 bg-bottom-center mb-60 bg-[url(https://static.travelpass.com/assets/book/hero.webp)] bg-cover bg-no-repeat max-md:[background-position-y:-50vw] md:bg-[url(https://static.travelpass.com/assets/book/hero-md.webp)]'>
      <section className='translate-y-30 max-w-300 mx-auto space-y-8'>
        <Header
          className='c-white text-shadow-lg'
          description='Get Inspired In These Beautiful Destinations'
          title='Featured Cities'
        />
        {isMobileOrTablet && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {cities.map(city => (
              <div
                key={city.city}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <CityCard {...city} />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && (
          <ul className='ps-none mt-18 grid list-none gap-8 overflow-y-auto md:grid-cols-3'>
            {cities.map(city => (
              <li key={city.city}>
                <CityCard {...city} />
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  )
}
