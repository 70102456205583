import { GuideHeaderContentLoading } from './GuideHeaderContentLoading'
import { GuideHeaderSummaryLoading } from './GuideHeaderSummaryLoading'
import { GuideHeaderTagsLoading } from './GuideHeaderTagsLoading'

interface GuideHeaderLoadingProps {
  isEdit: boolean
}

export const GuideHeaderLoading = ({ isEdit }: GuideHeaderLoadingProps) => {
  if (isEdit) return <GuideHeaderContentLoading isEdit={true} />

  return (
    <div className='p-y-6 space-y-5 md:space-y-6'>
      <GuideHeaderContentLoading isEdit={false} />
      <GuideHeaderTagsLoading />
      <GuideHeaderSummaryLoading />
    </div>
  )
}
