import { Divider } from '@travelpass/design-system'
import type { ExperienceProduct } from 'src/__generated__/graphql'
import { ExperiencesFeatures, SectionHeader } from 'src/common/components'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'
import { OverviewActions } from './OverviewActions'

type OverviewProps = {
  overviewData: ExperienceProduct
}

export const Overview = ({ overviewData }: OverviewProps) => {
  const { description } = overviewData
  return (
    <>
      <div className='space-y-2 pb-6 md:pb-3'>
        <SectionHeader title='Overview' />
        <ReadMoreContentSection maxHeightClass='max-h-40'>
          <p className='color-grey-800 type-body-1'>
            {description ?? 'Description unavailable'}
          </p>
        </ReadMoreContentSection>
      </div>
      <div className='flex flex-col gap-4 pb-2 md:gap-6 md:pb-6'>
        <ExperiencesFeatures experiencesProductData={overviewData} />
        <Divider />
      </div>
      <OverviewActions experiencesDetailsData={overviewData} />
    </>
  )
}
