import creditCardType from 'credit-card-type'
import isEmpty from 'lodash.isempty'
import amexIcon from '../../assets/amex.svg'
import discoverIcon from '../../assets/discover.svg'
import mastercardIcon from '../../assets/mastercard.svg'
import visaIcon from '../../assets/visa.svg'
import { useWatch } from '../../hooks'

const CARD_TYPES = {
  amex: {
    alt: 'American Express',
    src: amexIcon,
  },
  discover: {
    alt: 'Discover',
    src: discoverIcon,
  },
  mastercard: {
    alt: 'Mastercard',
    src: mastercardIcon,
  },
  visa: {
    alt: 'Visa',
    src: visaIcon,
  },
}

export const CreditCardsDisplay = () => {
  const ccNumber = useWatch({ name: 'number' })
  const hasCcNumber = !isEmpty(ccNumber)
  const creditCardTypes = hasCcNumber
    ? creditCardType(ccNumber).map(cardType => cardType.niceType)
    : []

  return (
    <div className={creditCardsDisplayContainerClasses}>
      {Object.values(CARD_TYPES).map(({ alt, src }) => (
        <img
          key={alt}
          alt={alt}
          className={creditCardImageClasses}
          data-highlight={creditCardTypes.includes(alt) || !hasCcNumber}
          loading='lazy'
          src={src}
        />
      ))}
    </div>
  )
}

const creditCardsDisplayContainerClasses =
  'hidden lg:flex flex-row gap-1 items-center ml-auto h-30px'

const creditCardImageClasses =
  'w-auto h-full object-contain rounded-5px opacity-40 data-[highlight=true]:opacity-100'
