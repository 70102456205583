import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { FindWebsiteReviewsQueryInBookingConfirmationQueryVariables } from 'src/__generated__/graphql'

const findWebsiteReviewsQuery = gql(`
  query FindWebsiteReviewsQueryInBookingConfirmation($reviewerEmail: String!, $bookingId: ID!) {
    findWebsiteReviews(reviewerEmail: $reviewerEmail, bookingId: $bookingId) {
      id
    }
  }
`)

export const useFindWebsiteReviewsQuery = (
  reviewerEmail: FindWebsiteReviewsQueryInBookingConfirmationQueryVariables['reviewerEmail'],
  bookingId: FindWebsiteReviewsQueryInBookingConfirmationQueryVariables['bookingId']
) => {
  const {
    data,
    error: hasFindWebsiteReviewsError,
    loading: isFindWebsiteReviewsLoading,
  } = useQuery(findWebsiteReviewsQuery, {
    variables: { reviewerEmail, bookingId },
    skip: !reviewerEmail && !bookingId,
  })

  const findWebsiteReviewsData = data?.findWebsiteReviews ?? {}

  return {
    findWebsiteReviewsData,
    hasFindWebsiteReviewsError,
    isFindWebsiteReviewsLoading,
  }
}
