import { useParams, useSearchParams } from 'react-router-dom'
import { Helmet } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  AwayGameGuide,
  AwayGameHero,
  AwayGameHotels,
  AwayGameSignUp,
} from './components'

export const AwayGame = () => {
  const { teamName } = useParams()
  const [searchParams] = useSearchParams()

  const date = searchParams.get('date')

  const awayTeamName = (teamName: string) =>
    teamName
      ?.split('-')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ')

  const opponent = awayTeamName(teamName)

  const canonicalUrl = baseUrl + window.location.pathname
  const pageName = `Utah Jazz vs ${opponent} | ${date ? date : 'Utah Jazz Away Game Getaways'}`
  const metaDescription = `Watch the Utah Jazz take on the ${opponent}.`

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <AwayGameHero />
      <AwayGameGuide />
      <AwayGameHotels />
      <AwayGameSignUp />
    </>
  )
}
