import { Illustration } from '@travelpass/design-system'
import { ButtonLink, PageLayoutContainer } from 'src/common/components'
import paperPlaneIcon from '../assets/paper-plane.svg'

export const GuideCreateGuideAction = () => (
  <section className='bg-warm-grey'>
    <PageLayoutContainer>
      <div className='of-hidden p-y-5 lg:of-initial lg:p-y-4 relative'>
        <img
          alt=''
          className='bottom--6.5 m-a w-90 lg:left--51 lg:right-a absolute left-0 right-0 h-auto max-w-full lg:bottom-0 lg:top-0'
          src={paperPlaneIcon}
        />
        <div className='relative flex flex-col gap-4 text-center lg:flex-row lg:items-center lg:gap-8 lg:text-left'>
          <header className='space-y-1 lg:space-y-2'>
            <h4 className='type-h5-mobile lg:type-h3-desktop'>
              Share your travel tips and suggestions with the world
            </h4>
            <p className='type-body-2-mobile type-body-1-desktop'>
              Showcase your adventures by publishing your travel guides and
              recommendation lists
            </p>
          </header>
          <div className='m-a min-w-fit'>
            <ButtonLink to='/list/create'>
              <span className='hidden md:block'>Create Your Own Guide</span>
              <span className='md:hidden'>Create Your Guide</span>
            </ButtonLink>
          </div>
          <div className='w-47 hidden lg:block'>
            <Illustration name='jeep' />
          </div>
        </div>
      </div>
    </PageLayoutContainer>
  </section>
)
