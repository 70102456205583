import { useState } from 'react'
import classNames from 'classnames'

interface EventCardHeroImageProps {
  hasFirstImageLoaded?: boolean
  imageLink?: string | null
  name?: string
  onError(): void
  onLoad(): void
}

export const EventCardHeroImage = ({
  hasFirstImageLoaded,
  imageLink,
  name,
  onError,
  onLoad,
}: EventCardHeroImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const onImgLoad = () => {
    onLoad()
    setIsLoading(false)
  }
  return (
    <>
      <span className='sr-only'>Image of {name}</span>
      <img
        alt={`${name} hero`}
        className={classNames('h-full w-full object-cover', {
          'opacity-100': !isLoading,
          'opacity-0': isLoading,
        })}
        draggable={false}
        loading={hasFirstImageLoaded ? 'eager' : 'lazy'}
        src={imageLink}
        onError={onError}
        onLoad={onImgLoad}
      />
    </>
  )
}
