import { useState } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type {
  ExperienceBooking,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { BookingDetailsSectionHeader } from 'src/common/components'
import { getLanguageNameByCode } from 'src/utils'
import { ExperiencesBookingDetailsSummaryDeparture } from './ExperiencesBookingDetailsSummaryDeparture'
import {
  ExperiencesExclusionsInclusions,
  checkExclusionsInclusions,
} from '../../common/components'

interface ExperiencesBookingDetailsSummaryProps {
  experiencesBookingDetailsData: ExperienceBooking
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsSummary = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsSummaryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { languageGuide, voucherInfo } = experiencesBookingDetailsData ?? {}
  const { exclusions, inclusions, logistics } =
    experiencesBookingDetailsProductData ?? {}
  const constructedLanguageTitle = getLanguageNameByCode(
    languageGuide?.language
  )
  const showExclusionsInclusions = checkExclusionsInclusions({
    exclusions,
    inclusions,
  })
  const showLanguageGuide =
    !isEmpty(languageGuide) && !!constructedLanguageTitle
  const showSummary =
    showExclusionsInclusions || showLanguageGuide || !isEmpty(logistics)
  const showVoucherInfo = !isEmpty(voucherInfo)

  return (
    showSummary && (
      <div>
        <BookingDetailsSectionHeader title='Experience Details' />
        <div
          className='flex flex-col gap-9 md:gap-12'
          data-testid='ExperiencesBookingDetailsSummary'
        >
          <div className='grid gap-9 md:grid-cols-2 md:gap-4'>
            <ExperiencesBookingDetailsSummaryDeparture
              experiencesBookingDetailsData={experiencesBookingDetailsData}
              experiencesBookingDetailsProductData={
                experiencesBookingDetailsProductData
              }
            />
            {showLanguageGuide && (
              <div className='flex flex-col gap-6 md:gap-9'>
                <div className='flex flex-col gap-4'>
                  <h3 className='text-h6'>Tour Language</h3>
                  <div className='color-grey-800 text-body-2 flex items-center gap-2'>
                    <Icon name='language' size='small' />
                    {constructedLanguageTitle}
                  </div>
                </div>
                {showVoucherInfo && (
                  <div className='hide-on-print'>
                    <Button
                      label='Get Tickets'
                      size='large'
                      onClick={() => window.open(voucherInfo?.url)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {showExclusionsInclusions && (
            <div className='flex flex-col gap-6 md:gap-9'>
              <div className='flex flex-col gap-4'>
                <h3 className='text-h6'>Inclusions & Exclusions</h3>
                <ExperiencesExclusionsInclusions
                  exclusions={exclusions}
                  inclusions={inclusions}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}
