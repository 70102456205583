export const reviewsData = {
  data: [
    {
      content:
        'Very detailed info for rooms and availability. Booking went very smoothly with no technical delay. Received confirmation text within seconds.',
      date: 'March 2, 2022',
      name: 'Deborah S.',
      rating: 5,
    },
    {
      content:
        'I was in a hurry and a bit frazzled. The reservation process took about 4 minutes! Wonderful!',
      date: 'December 24, 2021',
      name: 'Gary O.',
      rating: 5,
    },
    {
      content:
        'Thank you for making my return trip smooth. Means so much when dealing with a beloved family member in the hospital to know I have a peaceful place to sleep and recharge at night.',
      date: 'April 18, 2022',
      name: 'Cristine F.',
      rating: 5,
    },
    {
      content:
        'This was almost too easy! No tricky pop ups...very straightfroward and plenty of information to make a good decision on what type of room will work for us. Thanks!',
      date: 'November 15, 2021',
      name: 'Linda C.',
      rating: 5,
    },
    {
      content:
        'Excellent room rate in comparison to other online offers. Saved over $25! This is our anniversary weekend. Looking forward to the get-a-way.',
      date: 'August 22, 2022',
      name: 'Timothy W.',
      rating: 5,
    },
  ],
}
