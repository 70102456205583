import { Fragment } from 'react'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { ResultsCardBannerVariant } from 'src/common/components'
import { HotelResultsFeaturedItemsContainer } from './HotelResultsFeaturedItemsContainer'
import { HotelResultsFeaturedLodgingEmpty } from './HotelresultsFeaturedLodgingEmpty'
import { LODGING_EMPTY_CASES } from './lodgingEmptyCasesConstants'
import { HotelResultsListHeader } from '../HotelResultsList'
import {
  HotelResultsCard,
  HotelResultsCardFeatured,
  HotelResultsCardFeaturedSingle,
} from '../common'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import type {
  GetHotelResultsCardUrl,
  HotelResultsData,
} from '../hotelResultsTypes'
import { getHotelResultsCardUrl } from '../hotelResultsUtils'

interface HotelResultsFeaturedProps {
  hotelResultsFeaturedData: HotelResultsData
  onCardMouseOut?(): void
  onCardMouseOver?(updatedId: string): void
}

export const HotelResultsFeatured = ({
  hotelResultsFeaturedData,
  onCardMouseOut = () => {},
  onCardMouseOver = () => {},
}: HotelResultsFeaturedProps) => {
  const [searchParams] = useSearchParams()
  const placeId = searchParams.get(HotelResultsSearchParams.placeId)
  const showFeatured =
    !!hotelResultsFeaturedData?.[0]?.nightlyAverage || !placeId
  const optionsUnavailable =
    placeId && !showFeatured && !isEmpty(hotelResultsFeaturedData)
  const noCarrying = !optionsUnavailable && !showFeatured && placeId

  const onCardClick = (
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  if (optionsUnavailable)
    return (
      <HotelResultsFeaturedLodgingEmpty
        emptyCase={LODGING_EMPTY_CASES.NO_OPTIONS_AVAILABLE}
      />
    )

  if (noCarrying)
    return (
      <HotelResultsFeaturedLodgingEmpty
        emptyCase={LODGING_EMPTY_CASES.INVALID_LODGING}
      />
    )

  return (
    showFeatured &&
    !!hotelResultsFeaturedData?.length && (
      <section className='lg:space-y-1'>
        <HotelResultsListHeader>
          <h2 className='type-h6'>Our Top Picks For You</h2>
        </HotelResultsListHeader>
        <HotelResultsFeaturedItemsContainer
          length={hotelResultsFeaturedData?.length}
        >
          {hotelResultsFeaturedData?.map((hotelResultsCardData, index) => (
            <Fragment key={index}>
              <div className='hidden md:block'>
                {hotelResultsFeaturedData?.length > 1 ? (
                  <HotelResultsCardFeatured
                    hotelResultsCardData={hotelResultsCardData}
                    onClick={() => onCardClick(hotelResultsCardData)}
                    onMouseOut={() => onCardMouseOut()}
                    onMouseOver={() => onCardMouseOver(hotelResultsCardData.id)}
                  />
                ) : (
                  <HotelResultsCardFeaturedSingle
                    hotelResultsCardData={hotelResultsCardData}
                    onClick={() => onCardClick(hotelResultsCardData)}
                    onMouseOut={() => onCardMouseOut()}
                    onMouseOver={() => onCardMouseOver(hotelResultsCardData.id)}
                  />
                )}
              </div>
              <div className='display-inherit md:hidden'>
                <HotelResultsCard
                  bannerVariant={ResultsCardBannerVariant.featured}
                  hotelResultsCardData={hotelResultsCardData}
                  onClick={() => onCardClick(hotelResultsCardData)}
                  onMouseOut={() => onCardMouseOut()}
                  onMouseOver={() => onCardMouseOver(hotelResultsCardData.id)}
                />
              </div>
            </Fragment>
          ))}
        </HotelResultsFeaturedItemsContainer>
      </section>
    )
  )
}
