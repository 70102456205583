import { Button, EmptyState } from '@travelpass/design-system'

// TODO make button refetch query
export const UserTripsError = ({ onClick }: { onClick: () => void }) => (
  <div className='flex w-full flex-row items-center justify-center'>
    <div className='rd-20px bg-warmGrey flex h-full w-full flex-col items-center gap-3 p-6 p-8 text-center'>
      <div className='flex items-center justify-center'>
        <EmptyState name='emptyInbox' />
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-h5 c-black m-0'>
          Unexpected Error, Please Try Again
        </h2>
        <h3 className='text-body-1 c-grey700 m-0'>
          Errors happen just like an unexpected rainy day.
        </h3>
      </div>
      <div className='my-4'>
        <Button fullWidth aria-label='Retry' size='large' onClick={onClick}>
          Retry
        </Button>
      </div>
    </div>
  </div>
)
