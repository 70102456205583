import type { InputProps } from '@travelpass/design-system'
import { Input } from '@travelpass/design-system'
import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import type { NumberFormatProps } from 'react-number-format'

type SupportedInputProps = Omit<
  InputProps,
  'name' | 'defaultValue' | 'type' | 'required' | 'pattern'
>
type FormInputProps<N> = {
  name: UseControllerProps<N>['name']
  defaultValue?: UseControllerProps['defaultValue']
  rules?: UseControllerProps['rules']
  required?: boolean | string
} & Pick<NumberFormatProps, 'type' | 'isNumericString' | 'format' | 'pattern'> &
  SupportedInputProps

export const FormInput = <N,>({
  name,
  defaultValue,
  required,
  ...props
}: FormInputProps<N>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    rules: {
      required,
      ...props.rules,
    },
  })

  const isRequired = Boolean(required)

  if (props.format) {
    const { onChange, onBlur, name, value, ref } = field
    const onValueChange = v => {
      // intercept form onChange to ensure that the correct value is being saved
      onChange(v.value)
    }
    return (
      <NumberFormat<SupportedInputProps>
        customInput={Input}
        {...props}
        errorText={error?.message}
        getInputRef={ref}
        isInvalid={!!error}
        name={name}
        ref={ref}
        required={isRequired}
        value={value}
        onBlur={onBlur}
        onValueChange={onValueChange}
      />
    )
  }

  return (
    <Input
      {...props}
      errorText={error?.message}
      isInvalid={!!error}
      required={isRequired}
      {...field}
    />
  )
}
