import { EmptyState } from '@travelpass/design-system'

export const GuideEmpty = () => (
  <div className='p-y-50 flex flex-col items-center'>
    <div className='w-50 lg:w-70'>
      <EmptyState name='noSearchResult' />
    </div>
    <div className='space-y-6 text-center'>
      <p className='c-black type-h3'>This Guide could not be found</p>
      <p className='c-black type-h6'>
        This Guide no longer exists or was removed by the creator
      </p>
    </div>
  </div>
)
