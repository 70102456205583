import { GuideDraftEventCardLoading } from './GuideDraftEventCard'
import { GuideDraftEventLoading } from './GuideDraftEventLoading'

export const GuideDraftEventsLoading = () => (
  <div className='space-y-4'>
    <GuideDraftEventLoading>
      <GuideDraftEventCardLoading />
    </GuideDraftEventLoading>
    <GuideDraftEventLoading>
      <GuideDraftEventCardLoading />
    </GuideDraftEventLoading>
    <GuideDraftEventLoading>
      <GuideDraftEventCardLoading />
    </GuideDraftEventLoading>
    <GuideDraftEventLoading>
      <GuideDraftEventCardLoading />
    </GuideDraftEventLoading>
  </div>
)
