import { Drawer } from '@travelpass/design-system'
import type { HotelReviewsFilterType } from './HotelReviewsApollo/hotelReviewsApolloConstants'
import { HotelReviewsTravelerType } from './common/HotelReviewsTravelerType'

interface HotelReviewsTravelerTypeDrawerProps {
  filters: HotelReviewsFilterType
  isOpen: boolean
  onChange(updatedFilters: HotelReviewsFilterType): void
  onClose(): void
}

export const HotelReviewsTravelerTypeDrawer = ({
  filters,
  isOpen,
  onChange,
  onClose,
}: HotelReviewsTravelerTypeDrawerProps) => (
  <Drawer isOpen={isOpen} onClose={onClose}>
    <HotelReviewsTravelerType filters={filters} onChange={onChange} />
  </Drawer>
)
