import { Skeleton } from '@travelpass/design-system'
import {
  ResultsCardContent,
  ResultsCardReviewsLoading,
} from 'src/common/components'
import {
  HotelResultsCardAmenitiesContainer,
  HotelResultsCardAmenitiesLoading,
} from './HotelResultsCardAmenities'
import { HotelResultsCardHeader } from './HotelResultsCardHeader'

export const HotelResultsCardLoading = props => (
  <div className={`space-y-1 ${props?.className || ''}`}>
    <HotelResultsCardHeader>
      <div className='h-50'>
        <Skeleton />
      </div>
    </HotelResultsCardHeader>
    <ResultsCardContent>
      <div className='space-y-1'>
        <div className='h-9 md:h-12'>
          <div className='h-4.5 w-30 md:h-6'>
            <Skeleton />
          </div>
        </div>
        <HotelResultsCardAmenitiesContainer>
          <HotelResultsCardAmenitiesLoading />
        </HotelResultsCardAmenitiesContainer>
        <div className='flex flex-col items-end'>
          <div className='h-6 w-8'>
            <Skeleton />
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <ResultsCardReviewsLoading />
          <div className='h-2.5 w-12 md:h-4'>
            <Skeleton />
          </div>
        </div>
      </div>
    </ResultsCardContent>
  </div>
)
