import { Modal } from '@travelpass/design-system'
import type { ExperienceImage } from 'src/__generated__/graphql'
import { HeroModalImages } from './HeroModalImages'

export interface HeroModalProps {
  onCloseModal: () => void
  experienceName: string
  experienceImages: [
    ExperienceImage['size720x480'],
    ExperienceImage['size674x446'],
  ]
  initialSlideIndex?: number
}

export const HeroModal = ({
  onCloseModal,
  experienceName,
  experienceImages,
  initialSlideIndex = 0,
}: HeroModalProps) => {
  return (
    <Modal
      size='xl'
      title={experienceName || 'Current Experience'}
      onDismiss={onCloseModal}
    >
      {/* {isLoading && <HotelLoading />} */}
      {/* {!isLoading && ( */}
      <HeroModalImages
        experienceImages={experienceImages}
        experienceName={experienceName || ''}
        initialSlideIndex={initialSlideIndex}
      />
      {/* )} */}
    </Modal>
  )
}
