import { Divider } from '@travelpass/design-system'
import { EventType } from 'src/__generated__/graphql'
import { ResultsCardReviews } from 'src/common/components'
import { constructAddress } from 'src/utils'
import { defaultExperienceEvent, type ExperienceEvent } from '../constants'

export const ExperienceTop = ({ data }: { data: ExperienceEvent | null }) => {
  const address = data?.address?.state
    ? constructAddress({
        ...data?.address,
        addressFirstLine: data?.address?.addressLine1,
      })
    : 'Address failed to load'

  return (
    <header className='px-8 pt-8'>
      <h2 className='type-h6 mb-1 line-clamp-1'>{data?.name}</h2>
      <p className='type-body-2 line-clamp-1'>{address}</p>
      <div className='pb-3 pt-1.5'>
        {data?.rating !== defaultExperienceEvent?.rating && (
          <ResultsCardReviews
            average={data?.rating}
            type={EventType.Experience}
          />
        )}
      </div>
      <Divider />
    </header>
  )
}
