import { Skeleton } from '@travelpass/design-system'

export const TripLoadingCard = () => (
  <div className={card} id='trip-loading'>
    <div className='h-56 w-full'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-34 space-y-4 p-4'>
      <div className='space-y-1'>
        <div className='h-5 w-full'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-5 w-full'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='flex flex-row items-center justify-between'>
        <div className='w-19 h-3'>
          <Skeleton variant='rounded' />
        </div>
        <div className='w-19 h-3'>
          <Skeleton variant='rounded' />
        </div>
        <div className='w-19 h-3'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='h-4 w-full'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)

const card = 'w-full h-90 min-w-45 md:w-71 bg-white rd-2 shadow-1'
