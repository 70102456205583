import { Breadcrumbs } from '@travelpass/design-system'
import { useNavigate, useParams } from 'react-router-dom'
import { tripsPath, tripTimelinePath } from 'src/constants'
import type { BookingBreadcrumbsNode } from './types'
import {
  useBookingDetailsIdsQuery,
  useBookingDetailsLocationQuery,
} from '../../hooks'

interface BookingDetailsBreadcrumbsProps {
  bookingDetailsData: BookingBreadcrumbsNode
}

export const BookingDetailsBreadcrumbs = ({
  bookingDetailsData,
}: BookingDetailsBreadcrumbsProps) => {
  const navigate = useNavigate()
  const { bookingId } = useParams()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const { hotelId, trip } = bookingDetailsIdsData ?? {}
  const { bookingDetailsLocationData } = useBookingDetailsLocationQuery({
    hotelId,
  })

  const { name: tripLabel } = bookingDetailsData ?? {}
  const { name: productLabel } = bookingDetailsLocationData ?? {}
  const { id: tripId } = trip ?? {}

  const breadcrumbs = [
    { label: 'My Trips', onClick: () => navigate(tripsPath) },
    {
      label: tripLabel,
      onClick: () => navigate(`${tripTimelinePath}/${tripId}`),
    },
    {
      label: productLabel,
      onClick: () => {},
    },
  ]

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
