import { Modal } from '@travelpass/design-system'
import type { ExperiencesCancellationPolicyProps } from './ExperiencesCancellationPolicy'
import { ExperiencesCancellationPolicy } from './ExperiencesCancellationPolicy'

interface ExperiencesCancellationPolicyModalProps
  extends ExperiencesCancellationPolicyProps {
  onClose(): void
}

export const ExperiencesCancellationPolicyModal = ({
  onClose,
  ...props
}: ExperiencesCancellationPolicyModalProps) => (
  <Modal size='medium' title='Cancellation Policy' onDismiss={onClose}>
    <div className='pb-8'>
      <ExperiencesCancellationPolicy {...props} />
    </div>
  </Modal>
)
