import { Skeleton } from '@travelpass/design-system'

export const TripFormLoader = () => (
  <div className={tripFormWrapper}>
    <div className='md:h-18px h-4 w-3/4 md:w-full'>
      <Skeleton />
    </div>
    <div className='md:h-18px h-4 w-3/4 md:w-full'>
      <Skeleton />
    </div>
    <div className='md:h-18px h-4 w-3/4 md:w-full'>
      <Skeleton />
    </div>
  </div>
)

const tripFormWrapper = 'flex flex-col gap-4 pr-18'
