import { useEffect, useRef } from 'react'
import type { PayPalButtonsComponentOptions } from '@paypal/paypal-js'
import { getPaypalInstance, CURRENCY, INTENT } from './paypal'

interface PayPalButtonProps {
  amount: number
  className?: string
  onApprove: (token: string) => void
  onClick?: PayPalButtonsComponentOptions['onClick']
  onCancel?: PayPalButtonsComponentOptions['onCancel']
}

export const PayPalButton = ({
  amount,
  className,
  onApprove,
  onClick,
  onCancel,
}: PayPalButtonProps) => {
  const buttonContainerRef = useRef(null)

  useEffect(() => {
    getPaypalInstance().then(paypalInstance => {
      window.paypal
        .Buttons({
          style: {
            color: 'blue',
            label: 'checkout',
            shape: 'pill',
          },
          createOrder: () => {
            return paypalInstance.createPayment({
              flow: 'checkout',
              amount,
              currency: CURRENCY,
              intent: INTENT,
            })
          },
          onApprove: (data, _actions) => {
            return paypalInstance
              .tokenizePayment(data)
              .then(tokenizePayment => onApprove(tokenizePayment))
          },
          onClick,
          onCancel,
        })
        .render(buttonContainerRef.current)
    })
  }, [])

  return (
    <div
      className={`[&_iframe]:important:z-2 ${className || ''}`}
      ref={buttonContainerRef}
    />
  )
}
