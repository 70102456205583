import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'

export const ValentinesDayCouples = () => {
  const onWyomingHotelClick = () => {
    const url =
      '/destinations/wy/teton-village/hotels/101332195/Teton-Mountain-Lodge-and-Spa-Teton-Village-Wyoming'
    navigate(url)
  }
  const onWyomingExperienceClick = () => {
    const url =
      '/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MTkxMDUw/Grand-Teton-and-National-Elk-Refuge-Winter-Wonderland-Full-Day-Adventure'
    navigate(url)
  }
  const onSanFranciscoHotelClick = () => {
    const url =
      '/destinations/ca/san-francisco/hotels/101934564/Mansion-on-Sutter-San-Francisco-California'
    navigate(url)
  }
  const onSanFranciscoExperienceClick = () => {
    const url =
      '/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MTk0NjIx/Small-Group%3A-S.F%2C-Muir-Woods-and-Sausalito-%2B-Optional-Alcatraz'
    navigate(url)
  }
  const onTripsClick = () => {
    const url = '/trips'
    navigate(url)
  }
  const navigate = useNavigate()

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='wfull bg-top-center pt20 lg:pt30 pb20 relative flex flex-col items-center justify-center bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/750aa960-b94f-11ee-a3a7-c6110c4a9853)] bg-cover'>
        <header className='z-2'>
          <h1 className='c-white font-archivo font-not-oblique font-700 m0 flex flex-col text-center drop-shadow-lg'>
            <span className='leading-34px text-12 lg:leading-19 lg:mb--38px lg:text-20'>
              Fall In Love
            </span>
            <span className='leading-22 text-16 lg:leading-53 lg:text-29 mb10 lg:mb20'>
              All Over Again
            </span>
          </h1>
          <PageLayoutContainer>
            <div className='rounded-6 p8 wfull md:max-w-650px lg:max-w-800px ma bg-white'>
              <p className='type-h4 c-forest font-300 text-center leading-6 lg:leading-8'>
                We’ve put together the start of two very dreamy getaways in two
                top destinations for Valentine’s Day this year. Each spot is
                charmingly romantic, whether you’re looking for a quiet holiday
                or one full of activities for the two of you.
              </p>
              <p className='type-h4 c-forest font-300 mt4 text-center leading-6 lg:leading-8'>
                Take a peek below (and best of luck picking just one!)
              </p>
            </div>
          </PageLayoutContainer>
        </header>
      </div>

      <PageLayoutContainer>
        <div className='gap8 py10 flex flex-col flex-col-reverse items-center md:flex-row lg:items-start'>
          <div className='basis-1/2'>
            <h2 className='type-h2'>A Trip For Two In Jackson Hole, WY</h2>
            <p className='type-body-1 my4'>
              If escaping to a snowy mountain town is your idea of romance, come
              to Jackson Hole, WY for a snowed-in experience. Snuggle up by one
              of the fireplaces at the Teton Mountain Lodge &amp; Spa or book a
              massage at the onsite spa for a cozy and romantic evening.
            </p>
            <div className='w330px mxa my10 md:ml0'>
              <Button
                fullWidth
                label='Your Snowy Getaway'
                size='large'
                onClick={onWyomingHotelClick}
              />
            </div>
            <p className='type-body-1 my4'>
              Or, venture out to skate on the ice rink, go snowshoeing, or take
              a private sleigh ride as a part of your tour of Teton National
              Park. If your guide has luck on their side, you might spot moose,
              wolves, eagles, or coyotes on your tour of the park.
            </p>
            <div className='w330px mxa mt10 md:ml0'>
              <Button
                fullWidth
                label='A Cozy Sleigh Ride'
                size='large'
                onClick={onWyomingExperienceClick}
              />
            </div>
          </div>
          <div className='wfull basis-1/2'>
            <img
              alt='Jackson Hole, Wyoming'
              className='w100% md:w90% ma rounded-tl-8 rounded-br-8 shadow-2 block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/fe0a0450-bbd3-11ee-95fa-32128dbf798e'
            />
          </div>
        </div>

        <div className='gap8 py10 flex flex-col items-center md:flex-row lg:items-start'>
          <div className='basis-1/2'>
            <img
              alt='San Francisco, California'
              className='w100% md:w90% ma rounded-bl-8 rounded-tr-8 shadow-2 block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e0ac458c-bbd6-11ee-b794-267651b5d626'
            />
          </div>
          <div className='basis-1/2'>
            <h2 className='type-h2'>A Trip For Two In San Francisco, CA</h2>
            <p className='type-body-1 my4'>
              Come get the true San Francisco experience by staying in the
              towering Victorian Mansion on Sutter. This picture-perfect hotel
              has just the right amount of charm and elegance for a romantic
              getaway. The decorative stylings and thoughtful touches (like a
              cozy fireplace lobby and fresh flowers around the hotel and rooms)
              make it an easy choice for a Valentine’s getaway.
            </p>
            <div className='w330px mxa my10 md:ml0'>
              <Button
                fullWidth
                label='A Victorian Valentine'
                size='large'
                onClick={onSanFranciscoHotelClick}
              />
            </div>
            <p className='type-body-1 my4'>
              While the hotel alone is worth an escape to San Francisco, there’s
              more than enough to keep you both busy in the city. A picnic in
              front of the “The Painted Ladies” is a must, as well as biking
              across the famous Golden Gate Bridge, or wandering hand-in-hand
              around the eclectic Mission District neighborhood. And, if you’re
              like us, an easy way to say “I love you” is sharing a box of
              croissants at Arsicault, still warm from the oven (it’s worth
              waking up early for).
            </p>
            <p className='type-body-1 my4'>
              Then, to truly top off your SF adventure, hop over to the bay for
              a private tour out to the towering redwoods at the Muir Woods.
            </p>
            <div className='w330px mxa mt10 md:ml0'>
              <Button
                fullWidth
                label='The Romantic Redwoods'
                size='large'
                onClick={onSanFranciscoExperienceClick}
              />
            </div>
          </div>
        </div>
      </PageLayoutContainer>
      <div className='wfull h600px md:h900px relative bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/750aa960-b94f-11ee-a3a7-c6110c4a9853)] bg-cover bg-center'>
        <div className='top0 left0 h500px md:h650px lg:h750px absolute w-full bg-gradient-to-b from-white to-50%'>
          <div className='w90% md:max-w-650px lg:max-w-750px lg:mt16 mb8 mxa bg-forest c-white rounded-tl-8 rounded-br-8 p8'>
            <p className='type-h4 c-white font-300 text-center leading-6 lg:leading-8'>
              Hopefully at least one of these Valentine&apos;s Day trips caught
              your eye! You can create a trip with these ideas (or add your
              own!) by following the button below.
            </p>
          </div>
          <div className='w250px mxa'>
            <Button
              fullWidth
              label='Create My Trip'
              size='large'
              onClick={onTripsClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const canonicalUrl =
  'https://www.travelpass.com/best-romantic-valentines-day-getaways-for-couples'
const metaDescription =
  'We’ve put together the start of two very dreamy getaways in two top destinations for Valentine’s Day this year. Each spot is charmingly romantic, whether you’re looking for a quiet holiday or one full of activities for the two of you.'
const pageName = 'Best Romantic Valentine’s Day Getaways For Couples'
