import { useMemo, useRef, useState } from 'react'
import { dequal } from 'dequal'
import type { BBox, GeoJsonProperties } from 'geojson'
import Supercluster from 'supercluster'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import type { MapBounds } from '../constants'
import type { LatLng } from '../types'
import { formatDataToGeoJsonPoints } from '../utils'
export const useCreateSupercluster = <T extends LatLng>({
  bounds,
  data,
  radius = 75,
  zoom,
}: {
  bounds: MapBounds
  data: T[]
  radius?: Supercluster.Options['radius']
  zoom: number
}) => {
  const points = _useCreateClusterPoints({
    data,
  })
  const clusterBounds = _useCreateClusterBounds({ bounds })

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: clusterBounds,
    zoom,
    options: {
      radius,
      maxZoom: 20,
      map: a => {
        return a
      },
      reduce: (acc, item) => {
        acc[item.id] = true
        return acc
      },
    },
  })

  return {
    clusters,
    supercluster,
  }
}

const _useCreateClusterPoints = <T extends LatLng>({ data }: { data: T[] }) => {
  const points = useMemo(() => {
    if (data) {
      return formatDataToGeoJsonPoints<T>({
        items: data,
      })
    } else {
      return []
    }
  }, [data])

  return points
}

const _useCreateClusterBounds = ({
  bounds,
}: {
  bounds: MapBounds
}): GeoJSON.BBox => {
  const jsonBounds = bounds?.toJSON()

  return [
    jsonBounds?.west || 0,
    jsonBounds?.south || 0,
    jsonBounds?.east || 0,
    jsonBounds?.north || 0,
  ]
}
//TODO: This useSupercluster hook is a copy from the npm package use-supercluster
//The only change is the removal of the options variable from the useEffect dependency array
//We need this change to prevent infinite loops
interface UseSuperclusterArgument<P, C> {
  points: Array<Supercluster.PointFeature<P>>
  bounds?: BBox
  zoom: number
  options?: Supercluster.Options<P, C>
  disableRefresh?: boolean
}

const useSupercluster = <
  P extends GeoJsonProperties = Supercluster.AnyProps,
  C extends GeoJsonProperties = Supercluster.AnyProps,
>({
  points,
  bounds,
  zoom,
  options,
  disableRefresh,
}: UseSuperclusterArgument<P, C>) => {
  const superclusterRef = useRef<Supercluster<P, C>>()
  const pointsRef = useRef<Array<Supercluster.PointFeature<P>>>()
  const [clusters, setClusters] = useState<
    Array<Supercluster.ClusterFeature<C> | Supercluster.PointFeature<P>>
  >([])
  const zoomInt = Math.round(zoom)

  useDeepCompareEffectNoCheck(() => {
    if (disableRefresh === true) {
      return
    }

    if (
      !superclusterRef.current ||
      !dequal(pointsRef.current, points) ||
      !dequal(
        (
          superclusterRef.current as typeof superclusterRef.current & {
            options: typeof options
          }
        ).options,
        options
      )
    ) {
      superclusterRef.current = new Supercluster(options)
      superclusterRef.current.load(points)
    }

    if (bounds) {
      setClusters(superclusterRef.current.getClusters(bounds, zoomInt))
    }

    pointsRef.current = points
  }, [points, bounds, zoomInt, disableRefresh])

  return { clusters, supercluster: superclusterRef.current }
}
