import { Divider } from '@travelpass/design-system'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { TripSearchParams } from 'src/pages/trips/constants'
import { MiscellaneousActions } from './components'
import { MiscellaneousError } from './components/MiscellaneousError'
import { MiscellaneousLoading } from './components/MiscellaneousLoading'
import { useMiscellaneousData } from './hooks'
import { DrawerEdit, Hero } from '../components'

export const Miscellaneous = () => {
  const methods = useForm()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const tripId = params?.tripId ?? null
  const eventId = searchParams.get(TripSearchParams.eventId) ?? null

  const { data, tripData, loading, error } = useMiscellaneousData({
    tripId,
    eventId,
  })

  if (loading) {
    return <MiscellaneousLoading />
  }
  if (error) {
    return <MiscellaneousError />
  }

  return (
    <FormProvider {...methods}>
      <section className='relative h-full'>
        <Hero hideArrows={false} images={data?.images} />
        <header className='px-8 pt-8'>
          <h2 className='type-h6 mb-1 line-clamp-1'>{data?.name}</h2>
          <Divider />
        </header>
        <div className='h-8' />
        <DrawerEdit
          hideButtons
          data={data}
          tripData={tripData}
          visibleFields={[
            'customBooked',
            'name',
            'startLocation',
            'endLocation',
            'dates',
            'times',
            'notes',
          ]}
        />
        <MiscellaneousActions data={data} tripData={tripData} tripId={tripId} />
      </section>
    </FormProvider>
  )
}
