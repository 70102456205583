export const Header = ({
  title,
  description,
  ...props
}: {
  title: string
  description: string
  className?: string
}) => (
  <header
    {...props}
    className={`space-y-6 text-balance text-center ${props?.className || ''}`}
  >
    <p className='type-overline c-current capitalize'>{title}</p>
    <h2 className='type-h3 c-current capitalize'>{description}</h2>
  </header>
)
