import { Button, EmptyState } from '@travelpass/design-system'

export const HotelRecommendedHeroError = ({
  onClick,
}: {
  onClick?: () => void
}) => (
  <div className='flex w-full flex-row items-center justify-center pb-2'>
    <div className='border-grey400 rd-20px bg-warmGrey flex h-full w-full flex-col items-center border border-solid p-6 text-center'>
      <div className='w-250px flex items-center justify-center'>
        <EmptyState name='emptyInbox' />
      </div>
      <h2 className='type-h5 c-black m-0'>
        Unexpected Error, Please Try Again
      </h2>
      <div className='my-4'>
        <Button fullWidth aria-label='Retry' size='large' onClick={onClick}>
          Retry
        </Button>
      </div>
    </div>
  </div>
)
