import { Button } from '@travelpass/design-system'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useFormContext } from './useExperienceBookingForm'

export const TravelDetailsValidationButton = ({
  onClick,
}: {
  onClick: VoidFunction
}) => {
  const { trigger } = useFormContext()
  const validate = async () => {
    const isValid = await trigger(['answers'])
    if (isValid) {
      pushDataToDataLayer('add_traveler_details')
      onClick()
    }
  }

  return (
    <Button
      data-testid='experience-checkout-details-next'
      endIcon='arrowForward'
      label='Next'
      onClick={validate}
    />
  )
}
