import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { constructPopularHotelAmenities } from 'src/utils'
import { HotelResultsCardAmenityContainer } from './HotelResultsCardAmenityContainer'

interface HotelResultsCardAmenitiesProps {
  amenities?: string[]
  limit?: number
}

const HotelResultsCardAmenities = ({
  amenities: initialAmenities = [],
  limit = 3,
}: HotelResultsCardAmenitiesProps) => {
  const amenities = constructPopularHotelAmenities(initialAmenities)?.slice(
    0,
    limit
  )

  return (
    !isEmpty(amenities) && (
      <>
        {amenities?.map(({ icon, label }) => (
          <HotelResultsCardAmenityContainer key={label}>
            <Icon name={icon} size='small' />
            <span className='truncate'>{label}</span>
          </HotelResultsCardAmenityContainer>
        ))}
      </>
    )
  )
}

export { HotelResultsCardAmenities }
export type { HotelResultsCardAmenitiesProps }
