import { Modal, ModalScrollContents } from '@travelpass/design-system'
import type { ExperienceAdditionalInfo } from 'src/__generated__/graphql'

interface AdditionalInfoModalProps {
  infoItems: ExperienceAdditionalInfo[]
  onDismiss: () => void
}

export const AdditonalInfoModal = ({
  infoItems,
  onDismiss,
}: AdditionalInfoModalProps) => {
  return (
    <Modal size='medium' title='Additional Info' onDismiss={onDismiss}>
      <ModalScrollContents>
        <ul className='type-body-1 color-grey-800 space-y-3'>
          {infoItems?.map((item, index) => (
            <li key={index}>{item?.description}</li>
          ))}
        </ul>
      </ModalScrollContents>
    </Modal>
  )
}
