import { Divider } from '@travelpass/design-system'
import { stringToNumber } from 'src/utils'
import { ExperiencesPriceSummaryRow } from './ExperiencesPriceSummaryRow'
import type { ExperiencesPriceSummaryProps } from './experiencesPriceSummaryConstants'
import {
  experiencesPriceSummaryCss,
  experiencesPriceSummaryOverviewCss,
  experiencesPriceSummaryTitleCss,
} from './experiencesPriceSummaryStyles'

export const ExperiencesPriceSummary = ({
  fees,
  paxMix,
  subtotal,
  total,
}: ExperiencesPriceSummaryProps) => {
  const numberOfTravelers = paxMix?.reduce(
    (total, current) => total + current?.numberOfTravelers,
    0
  )
  const pricePerTraveler = (
    stringToNumber(subtotal) / numberOfTravelers
  )?.toString()
  const showFees = !!fees
  const showTotal = !!total
  const showTravelers = !!numberOfTravelers && !!pricePerTraveler

  const constructedTravelersText = () => {
    if (numberOfTravelers > 1) return `${numberOfTravelers} Travelers`

    return '1 Traveler'
  }

  return (
    showTotal && (
      <div>
        <div className={experiencesPriceSummaryCss}>
          <h3 className={experiencesPriceSummaryTitleCss}>Price Summary</h3>
          <div className={experiencesPriceSummaryOverviewCss}>
            {showTravelers && (
              <ExperiencesPriceSummaryRow
                title={constructedTravelersText()}
                value={pricePerTraveler}
              />
            )}
            {showFees && (
              <ExperiencesPriceSummaryRow title='Taxes & Fees:' value={fees} />
            )}
          </div>
        </div>
        <Divider className='mb-8 mt-4' />
        <ExperiencesPriceSummaryRow
          title='Total'
          value={total}
          variant='title'
        />
      </div>
    )
  )
}
