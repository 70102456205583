import { forwardRef } from 'react'
import { Input as DesignInput, DropdownOption } from '@travelpass/design-system'
import type { UseFormReturn } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { countries } from 'src/constants'
import { emailValidationRegex } from 'src/constants/validation'
import { rules } from 'src/utils'
import type { DashboardAccountSettingsFields } from '../../../useDashboardAccountSettingsForm'
import 'app/src/pages/competition/phoneInput.css'

const Input = FormInput<DashboardAccountSettingsFields>

interface DashboardAccountSettingsFormInfoProps {
  methods: UseFormReturn<DashboardAccountSettingsFields>
}

export const DashboardAccountSettingsFormInfo = ({
  methods,
}: DashboardAccountSettingsFormInfoProps) => {
  const { getValues, register, setValue } = methods ?? {}

  const CustomInput = forwardRef((props, ref) => (
    <DesignInput
      {...register('phoneNumber', {
        pattern: rules.internationalPhone,
      })}
      fullWidth={true}
      label='Phone Number'
      //@ts-ignore
      ref={ref}
      {...props}
    />
  ))
  CustomInput.displayName = 'CustomInput'

  return (
    <div className='space-y-4 md:space-y-8'>
      <section className='space-y-4'>
        <h3 className='type-h6'>Legal Name</h3>
        <div className='flex flex-col gap-x-8 gap-y-4 md:flex-row'>
          <Input
            fullWidth={true}
            label='First Name *'
            maxLength={45}
            name='firstName'
            placeholder='First Name'
            rules={{
              required: 'First name is required',
              pattern: {
                message: 'At least two alpha characters are required',
                value: /^[a-zA-Z]{2,}$/,
              },
            }}
          />
          <Input
            fullWidth={true}
            label='Last Name *'
            name='lastName'
            placeholder='Last Name'
            rules={{
              required: 'Last name is required',
            }}
          />
        </div>
      </section>
      <section className='space-y-4'>
        <h3 className='type-h6'>Contact</h3>
        <div className='flex flex-col gap-x-8 gap-y-4'>
          <PhoneInput
            defaultCountry='US'
            focusInputOnCountrySelection={false}
            inputComponent={CustomInput}
            value={getValues().phoneNumber}
            onChange={phone => setValue('phoneNumber', phone)}
            onCountryChange={() => {}}
          />
          <Input
            fullWidth={true}
            isDisabled={true}
            label='Email *'
            name='email'
            placeholder='you@email.com'
            rules={{
              required: 'Email is required',
              validate: (value: string) => {
                const regex = new RegExp(emailValidationRegex)

                return regex.test(value) || 'Email Address is invalid'
              },
            }}
          />
        </div>
      </section>
      <section className='space-y-4'>
        <h3 className='type-h6'>Address</h3>
        <Input
          fullWidth={true}
          label='Street address'
          name='addressFirstLine'
          placeholder='Street address'
        />
        <Input
          fullWidth={true}
          label='Apt, suite. (optional)'
          name='addressSecondLine'
          placeholder='Apt, suite. (optional)'
        />
        <div className='grid grid-cols-1 gap-4 gap-x-8 lg:grid-cols-2'>
          <Input label='Zip code' name='zip' placeholder='Zip code' />
          <FormDropdown
            defaultValue=''
            label='Country'
            name='country'
            placeholder='Country'
          >
            {countries.map(({ label, value }) => (
              <DropdownOption key={value} value={value}>
                {label}
              </DropdownOption>
            ))}
          </FormDropdown>
          <Input label='City' name='city' placeholder='City' />
          <Input label='State' name='state' placeholder='State' />
        </div>
      </section>
    </div>
  )
}
