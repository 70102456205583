import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateTripEventSortGQL = gql(`
mutation UpdateTripEventSort($tripInput: UpdateTripInput!) {
    updateTrip(tripInput: $tripInput) {
        id
        name
        startDate
        endDate
        status
        timeZone
        dailyEventBreakdown {
          scheduled {
            dayName
          }
        }
        dailyEventBreakdown {
          scheduled {
            events {
              productId
              id
              isDeleted
              type
              startDate
              endDate
              booking {
                id
                hotelName
                hotelId
              }
              experienceBooking {
                id
              }
              notes
              imageUrl
              name
              isCustom
              status
              useEventTime
              addresses {
                id
                googlePlaceId
              }
              trip {
                id
                endDate
                startDate
                timeZone
              } 
            }
          }
        }
        tripPreference {
          id
          addresses {
            id
            lat
            long
          }
        }
    }
  }
`)

export const useSortTripEvents = () => {
  const [sortTripEvents, { data, loading, error }] = useMutation(
    updateTripEventSortGQL
  )

  return {
    sortTripEvents,
    data,
    loading,
    error,
  }
}
