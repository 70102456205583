import type { ReactNode } from 'react'

interface TripDetailsDrawerFooterProps {
  children: ReactNode
}

export const TripDetailsDrawerFooter = ({
  children,
}: TripDetailsDrawerFooterProps) => (
  <div className='b-0 b-grey-300 b-solid border-t-1 shadow-1 absolute bottom-0 left-0 flex w-full items-center justify-center gap-2 bg-white p-6'>
    {children}
  </div>
)
