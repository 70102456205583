import { useParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { AwayGameGuideCard } from './AwayGameGuideCard'
import { awayGameDetails } from '../awayGameDetails'

export const AwayGameGuide = () => {
  const { teamName } = useParams()
  const game = Object.values(awayGameDetails).find(
    game => game.team.toLowerCase().replaceAll(' ', '-') === teamName
  )
  const guide = game.guide

  const onGuideButtonClick = () => {
    const url = guide.url
    window.open(url, '_self')
  }

  return (
    <section
      className='bg-top-center block h-full w-full bg-cover bg-no-repeat'
      style={{ backgroundImage: `url(${game.cityImg})` }}
    >
      <div className='p-y-12 h-100% relative block w-full bg-white/90 backdrop-saturate-0'>
        <PageLayoutContainer>
          <div className='grid grid-cols-1 place-content-center gap-6 md:grid-cols-2'>
            <div className='order-last place-content-center md:order-first'>
              <AwayGameGuideCard
                city={game.city}
                image={guide.image}
                owner={guide.owner}
                state={game.state}
                title={guide.title}
                url={guide.url}
              />
              <button
                className='of-hidden rounded-2 c-forest font-700 text-4 bg-valley border-valleyDark/20 mx-auto mt-10 block w-auto appearance-none border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-shadow hover:cursor-pointer hover:shadow-lg md:hidden'
                onClick={onGuideButtonClick}
              >
                See The {game.city} Guide
              </button>
            </div>
            <div className='place-content-center'>
              <h2 className='text-8 md:text-10 c-black font-900 m-b-2 m-0 text-balance text-center md:text-left'>
                Plan The Perfect{' '}
                <span className='from-valley to-newForest bg-gradient-to-r bg-clip-text text-transparent'>
                  {game.city} Getaway
                </span>
              </h2>
              <p className='text-4 md:text-5 c-grey-900 font-400 m-y-0 text-balance text-center md:text-left'>
                Experience {game.city} with insider tips and advice from
                Travelpass. Our official away game travel guide highlights the
                best things to do and places to eat in the city.
              </p>
              <button
                className='of-hidden rounded-2 c-forest font-700 text-4 bg-valley border-valleyDark/20 mt-10 hidden w-auto appearance-none border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-shadow hover:cursor-pointer hover:shadow-lg md:block'
                onClick={onGuideButtonClick}
              >
                See The {game.city} Guide
              </button>
            </div>
          </div>
        </PageLayoutContainer>
      </div>
    </section>
  )
}
