import { Skeleton } from '@travelpass/design-system'

export const DateTabsLoading = () => (
  <div className='flex flex-row items-center gap-4'>
    <div className='h-16 w-14'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-16 w-14'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
