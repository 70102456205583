import { useRef, useState } from 'react'
import { MenuItem, IconButton, Button } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { Tabs } from '../../constants'

/** @todo remove the 'isListGuide Prop here if possible */

export const MobileMapsHeader = ({
  onHotelsClicked,
  onExperiencesClicked,
  isListGuide,
}: {
  isListGuide?: boolean
  onHotelsClicked?: () => void
  onExperiencesClicked?: () => void
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const pathSegments = window.location.pathname.split('/')
  const secondSegment = pathSegments[pathSegments?.length - 2]
  const menuItems: {
    title: string
    onClick: () => void
  }[] = [
    {
      title: 'Hotels',
      onClick: onHotelsClicked,
    },
    {
      title: 'Experiences',
      onClick: onExperiencesClicked,
    },
  ]

  const setMobileMapClosed = () => {
    searchParams.set('mapFullView', 'false')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const getTripSegment = (segment: string) => {
    if (isListGuide) return 'Guide'
    return segment === Tabs.timeline
      ? 'Timeline'
      : segment === Tabs.explore
        ? 'Explore'
        : null
  }

  return (
    <div className='flex items-center justify-between px-6 py-4'>
      <Button
        aria-label='Go back'
        size='small'
        startIcon='arrowBackIos'
        variant='text'
        onClick={setMobileMapClosed}
      >
        {getTripSegment(secondSegment)}
      </Button>
      {secondSegment === Tabs.explore && (
        <Popover
          containerStyle={{ zIndex: '4' }}
          content={
            <div className='rd-4 min-w-42 b-solid b-0 relative right-6 top-7 flex flex-col gap-2 overflow-hidden bg-white p-2'>
              {menuItems.map(({ title, onClick }, index) => (
                <MenuItem
                  key={title + index + 'mobiletitle'}
                  label={title}
                  value={title}
                  onClick={onClick}
                />
              ))}
            </div>
          }
          isOpen={isMenuOpen}
          positions={['bottom']}
          ref={ref}
          onClickOutside={() => setIsMenuOpen(false)}
        >
          <div ref={ref}>
            <IconButton
              aria-label='menu'
              color='forestLight'
              icon='tune'
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </div>
        </Popover>
      )}
    </div>
  )
}
