import { Button } from '@travelpass/design-system'
import { useFormContext } from './useExperienceBookingForm'

export const ContactInformationFormValidation = ({
  onClick,
}: {
  onClick: VoidFunction
}) => {
  const { trigger } = useFormContext()
  const validate = async () => {
    const isValid = await trigger([
      'bookerInfo.firstName',
      'bookerInfo.lastName',
      'communication.email',
      'communication.phone',
    ])
    if (isValid) onClick()
  }

  return (
    <Button
      data-testid='experience-checkout-contact-next'
      endIcon='arrowForward'
      onClick={validate}
    >
      Next
    </Button>
  )
}
