import { useEffect, useState } from 'react'
import { Divider } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { stringToBoolean } from 'src/utils'
import { HotelResultsContent } from './HotelResultsContent'
import { HotelResultsMap } from './HotelResultsMap'
import { HotelResultsNav } from './HotelResultsNav'
import { HotelResultsSearch } from './HotelResultsSearch'
import { useGetHotelResultsQuery } from './common/useGetHotelResultsQuery'
import { HotelResultsSearchParams } from './hotelResultsConstants'
import './hotelResultsCustomStyles.css'

export const HotelResults = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filteredIds, setFilteredIds] = useState<string[]>([])
  const [isFilteredIdsLoading, setIsFilteredIdsLoading] = useState(true)
  const {
    data,
    hasMoreResults,
    hasError,
    hotelResultsBrandData,
    hotelResultsData,
    hotelResultsFeaturedData,
    isLoading,
    loadMore,
    networkStatus,
  } = useGetHotelResultsQuery()

  const extendedLoading = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.extendedLoading) ?? 'true'
  )
  const mapExpanded = stringToBoolean(
    searchParams.get(HotelResultsSearchParams.mapExpanded)
  )

  useEffect(() => {
    if (extendedLoading && isLoading) setIsFilteredIdsLoading(true)
  }, [extendedLoading, isLoading])

  const onFilteredIdsChange = (updatedFilteredIds: string[]) => {
    setFilteredIds(updatedFilteredIds)

    if (extendedLoading) {
      setIsFilteredIdsLoading(true)

      window.scrollTo(0, 0)
    } else if (!isEmpty(updatedFilteredIds)) {
      setTimeout(() => {
        searchParams.delete(HotelResultsSearchParams.extendedLoading)
        setSearchParams(searchParams, {
          replace: true,
        })
      }, 1000)
    }

    setTimeout(() => setIsFilteredIdsLoading(false), 600)
  }

  return (
    <section
      className={classNames('bg-white', {
        'fixed left-0 top-16 h-full w-full lg:static lg:left-auto lg:top-auto lg:h-auto lg:w-auto':
          mapExpanded,
      })}
    >
      <div className='lg:top-19 lg:z-1 lg:sticky lg:bg-white'>
        <HotelResultsSearch />
        <Divider />
        <HotelResultsNav
          hotelResultsBrandData={hotelResultsBrandData}
          hotelResultsData={hotelResultsData}
        />
      </div>
      <div className='lg:flex lg:pl-8'>
        <div
          className={classNames(
            'lg:max-w-174 lg:transition-margin-200 lg:w-70vw max-w-full space-y-4 px-3 lg:space-y-1 lg:pl-0 lg:pr-4 lg:ease-[cubic-bezier(0.45,0.05,0.795,0.035)]',
            {
              'lg:m-l-[calc(-1*min(728px,70vw))] invisible absolute lg:visible lg:static lg:block':
                mapExpanded,
            }
          )}
        >
          <HotelResultsContent
            filteredIds={filteredIds}
            hasError={hasError}
            hasMoreResults={hasMoreResults}
            hotelResultsData={hotelResultsData}
            hotelResultsFeaturedData={hotelResultsFeaturedData}
            isLoading={isLoading || isFilteredIdsLoading}
            loadMore={loadMore}
            networkStatus={networkStatus}
          />
        </div>
        <div
          className={classNames(
            'bg-grey-100 h-[calc(100svh-149px)] lg:sticky lg:top-60 lg:h-[calc(100svh-240px)] lg:grow',
            {
              relative: mapExpanded,
              'lg:b-l-grey-300 lg:b-l-solid lg:b-l-1 lg:w-initial invisible absolute w-full lg:visible':
                !mapExpanded,
            }
          )}
        >
          <HotelResultsMap
            data={data}
            hotelResultsData={hotelResultsData}
            hotelResultsFeaturedData={hotelResultsFeaturedData}
            isLoading={isLoading}
            onFilteredIdsChange={onFilteredIdsChange}
          />
        </div>
      </div>
    </section>
  )
}
