import { useRef, type ContextType } from 'react'
import { Link } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller, ScrollerButton } from 'src/common/components'
import type { UseGetDashboardFeedQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { DashboardFeedSuggestedGuideCard } from './DashboardFeedSuggestedGuideCard'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface DashboardFeedSuggestedGuidesProps {
  dashboardFeedSuggestedGuidesData: UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData']
}

export const DashboardFeedSuggestedGuides = ({
  dashboardFeedSuggestedGuidesData,
}: DashboardFeedSuggestedGuidesProps) => {
  const apiRef = useRef({} as ScrollVisibilityApiType)

  if (!dashboardFeedSuggestedGuidesData?.length) return

  return (
    <section className='of-hidden rounded-2 b-1 b-solid b-grey-200 relative h-full space-y-3 bg-white p-4 md:p-6'>
      <header className='flex flex-row gap-2'>
        <div className='grow space-y-1.5'>
          <h4 className='type-h6 font-700 c-forest-dark flex flex-row items-center justify-center gap-2'>
            <span className='line-clamp-2 grow'>Featured Guides</span>
            <span className='min-w-fit'>
              <Link href='/guides/all?sort=RECOMMENDED' target='_blank'>
                See All
              </Link>
            </span>
          </h4>
          <p className='type-small-text-desktop type-body-2-mobile c-grey-900 line-clamp-2'>
            Don&apos;t miss out on these new and noteworthy Guides, picked just
            for you.
          </p>
        </div>
      </header>
      <div className='m-r--4 lg:m-r--6 relative'>
        <Scroller
          LeftArrow={
            <div className='z-1 m-a absolute bottom-0 left-2 top-0 h-fit'>
              <ScrollerButton
                color='white'
                icon='arrowBackIos'
                outline='round'
                size='small'
              />
            </div>
          }
          RightArrow={
            <div className='z-1 m-a absolute bottom-0 right-2 top-0 h-fit'>
              <ScrollerButton
                color='white'
                icon='arrowForwardIos'
                isNext={true}
                outline='round'
                size='small'
              />
            </div>
          }
          allowTouch={true}
          apiRef={apiRef}
          hideScrollBar={true}
          scrollContainerClassName='gap-1 p-r-4 lg:p-r-6'
        >
          {dashboardFeedSuggestedGuidesData.map(guide => (
            <DashboardFeedSuggestedGuideCard
              key={guide?.id}
              dashboardFeedSuggestedGuideItem={guide}
            />
          ))}
        </Scroller>
      </div>
    </section>
  )
}
