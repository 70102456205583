import type { Dispatch, SetStateAction, ReactNode } from 'react'
import { Link, Input, Button, useSnackbar } from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import { firebasePasswordSignIn } from 'src/config/firebase/firebaseUtils'
import { emailValidationRegex } from 'src/constants/validation'
import { LinkCredentials } from './LinkCredentials'
import type { alternateModal } from './types'

export const SignInWithAccount = ({
  createAccountOrSignInMessage,
  errorText,
  onCloseModal,
  onOpenAlternateModal,
  setErrorText,
}: {
  createAccountOrSignInMessage: ReactNode
  errorText: string
  onCloseModal(): void
  onOpenAlternateModal(modal: alternateModal): void
  setErrorText: Dispatch<SetStateAction<string>>
}): JSX.Element => {
  const { formState, handleSubmit, register, reset } = useForm()
  const { errors } = formState
  const { email: emailError, password: passwordError } = errors

  const { addSuccessSnack } = useSnackbar()

  const onSubmit = async ({ email, password }, event) => {
    event.preventDefault()
    const response = await firebasePasswordSignIn(email, password)

    if (response?.status === 'success') {
      onCloseModal()
      addSuccessSnack({ title: 'Successfully signed in' })
      reset()
    } else if (
      response?.status === 'error' &&
      (response?.err?.code === 'auth/user-not-found' ||
        response?.err?.code === 'auth/wrong-password')
    ) {
      setErrorText('Please check your email address and password')
    } else if (response?.err?.code === 'auth/too-many-requests') {
      setErrorText('Too many requests, please try again later')
    } else {
      setErrorText('An error has occur, please try again')
    }
  }

  const constructEmailErrorText = () => {
    if (emailError?.type === 'required') return 'Email is required'
    if (emailError?.type === 'validate')
      return 'Please enter a valid email address'
  }

  return (
    <div className='mt-3 space-y-7'>
      <LinkCredentials onCloseModal={onCloseModal} />
      {errorText && <div className='c-error'>{errorText}</div>}
      <form noValidate className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
        <Input
          fullWidth
          errorText={constructEmailErrorText()}
          label='Email'
          placeholder='Email'
          type='email'
          {...register('email', {
            required: true,
            validate: (value: string) => {
              const regex = new RegExp(emailValidationRegex)
              return regex.test(value)
            },
          })}
        />
        <Input
          fullWidth
          errorText={passwordError && 'Password is required'}
          label='Password'
          placeholder='Password'
          type='password'
          {...register('password', { required: true })}
        />
        <div className='mt-4 flex flex-col gap-4'>
          <div className='[&_span]:c-new-forest flex self-end'>
            <Link
              showUnderlineOnlyOnHover={true}
              onClick={() => onOpenAlternateModal('forgotPassword')}
            >
              Forgot password?
            </Link>
          </div>
          <Button fullWidth label='Sign In' type='submit' />
        </div>
      </form>
      <div className='type-body-2 [&_span]:c-new-forest flex flex-row items-center justify-center gap-4'>
        {createAccountOrSignInMessage}
      </div>
    </div>
  )
}
