import { Skeleton } from '@travelpass/design-system'
import { TripDetailsExploreCardLoading } from '../TripDetailsExploreCard'

export const TripDetailsExploreListApolloLoading = () => (
  <div className='flex flex-col gap-5 overflow-y-hidden px-4 pr-6 pt-4 md:gap-4'>
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
      {Array(9)
        .fill(0)
        .map((_, index) => (
          <TripDetailsExploreCardLoading key={index} />
        ))}
    </div>
    <div className='flex flex-col items-center'>
      <div className='w-19 h-8'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
