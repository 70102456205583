import { Link } from 'react-router-dom'
import { BookingCardContent } from './BookingCardContent'
import { BookingCardHero } from './BookingCardHero'
import type { BookingCardData } from './types'

export interface BookingCardProps {
  bookingCardData: BookingCardData
  className?: string
  onBookingCardClick?(booking: BookingCardData): void
}

export const BookingCard = ({
  bookingCardData,
  className,
  onBookingCardClick = () => {},
}: BookingCardProps) => {
  const { name, route } = bookingCardData ?? {}

  const onCardClick = () => {
    onBookingCardClick(bookingCardData)
  }

  return (
    <div
      className={`rounded-2 shadow-2 relative flex w-full flex-col bg-white text-inherit ${className}`}
    >
      <BookingCardHero bookingCardData={bookingCardData} />
      <BookingCardContent bookingCardData={bookingCardData} />
      <Link
        className='z-1 rounded-2 focus:shadow-2 hover:shadow-2 absolute inset-0 block h-full w-full overflow-hidden whitespace-nowrap bg-none outline-none transition-shadow duration-200 ease-linear focus:border-2 focus:border-red-500'
        draggable='false'
        rel='noopener'
        target='_blank'
        to={route}
        onClick={onCardClick}
      >
        <span className='sr-only'>
          More information about your {name} booking, opens in a new tab
        </span>
      </Link>
    </div>
  )
}
