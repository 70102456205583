import isEmpty from 'lodash.isempty'
import type { GeocodeResult } from 'use-places-autocomplete'
import type { GenericAddressInput } from 'src/__generated__/graphql'
import type { GeocoderType } from 'src/constants/user'
import type { TripFormAddress } from '../constants/tripConstants'

const assembleAddressLine1 = ({
  streetNumber,
  route,
}: {
  streetNumber: string | null
  route: string | null
}): string | null => {
  const addressPieces: string[] = []
  if (streetNumber) addressPieces.push(streetNumber)
  if (route) addressPieces.push(route)
  if (!isEmpty(addressPieces)) return addressPieces.join(' ')

  return null
}

const constructAddressInput = (
  geocodeData:
    | {
        addressComponents?: GeocodeResult['address_components']
        center: number[]
        placeId?: string
      }
    | null
    | undefined
): GenericAddressInput => {
  const { addressComponents, center, placeId } = geocodeData ?? {}
  const validAddress: GenericAddressInput = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    lat: null,
    long: null,
    googlePlaceId: null,
  }
  const preAssembledAddress: {
    streetNumber: null | string
    route: null | string
  } = {
    streetNumber: null,
    route: null,
  }

  // Latitude and Longitude
  const [lat, lng] = center ?? []
  if (lat && lng) {
    validAddress.lat = lat.toString()
    validAddress.long = lng.toString()
  }

  addressComponents?.forEach(({ long_name, types }) => {
    // Country
    if (types.includes('country')) validAddress.country = long_name
    // State
    if (types.includes('administrative_area_level_1')) {
      validAddress.state = long_name
    }
    // City
    if (types.includes('locality')) validAddress.city = long_name
    // Address
    if (types.includes('street_number')) {
      preAssembledAddress.streetNumber = long_name
    }
    if (types.includes('route')) {
      preAssembledAddress.route = long_name
    }
    // ZipCode
    if (types.includes('postal_code')) {
      validAddress.zipcode = long_name
    }
  })

  if (placeId) validAddress.googlePlaceId = placeId

  // Address Line 1
  const assembledAddressLine1 = assembleAddressLine1(preAssembledAddress)
  if (assembledAddressLine1) {
    validAddress.addressLine1 = assembledAddressLine1
  }

  return validAddress
}

const assembleAddressComponent = ({
  type,
  value,
}: {
  type: string
  value: string
}) => {
  return {
    long_name: value,
    short_name: value,
    types: [type, 'political'],
  }
}

const constructGeocoderFromAddress = (address: TripFormAddress | null) => {
  const { addressLine1, city, country, state, lat, long, googlePlaceId } =
    address ?? {}

  const latitude = lat ? parseFloat(lat) : null
  const longitude = long ? parseFloat(long) : null

  if (!!latitude && !!longitude) {
    const validGeoCoder: GeocoderType = {
      center: [latitude, longitude],
      placeName: state ?? '',
      placeId: googlePlaceId ?? '',
    }

    const addressComponents: {
      long_name: string
      short_name: string
      types: string[]
    }[] = []
    const placeName: {
      address: null | string
      city: null | string
      state: null | string
      country: null | string
    } = { address: null, city: null, state: null, country: null }

    if (addressLine1) {
      placeName.address = addressLine1
      const addressComponent = assembleAddressComponent({
        type: 'route',
        value: addressLine1,
      })
      addressComponents.push(addressComponent)
    }

    if (city) {
      placeName.city = city
      const addressComponent = assembleAddressComponent({
        type: 'locality',
        value: city,
      })
      addressComponents.push(addressComponent)
    }

    if (state) {
      placeName.state = state
      const addressComponent = assembleAddressComponent({
        type: 'administrative_area_level_1',
        value: state,
      })
      addressComponents.push(addressComponent)
    }

    if (country) {
      placeName.country = country
      const addressComponent = assembleAddressComponent({
        type: 'country',
        value: country,
      })
      addressComponents.push(addressComponent)
    }

    if (!isEmpty(addressComponents)) {
      validGeoCoder.addressComponents = addressComponents
    }

    const updatedPlaceName = Object.keys(placeName)
      .reduce((values: string[], key: string) => {
        const value = placeName[key]
        if (value !== null) {
          values.push(value)
        }
        return values
      }, [])
      .join(', ')

    if (!isEmpty(updatedPlaceName)) {
      validGeoCoder.placeName = updatedPlaceName
    }

    return validGeoCoder
  }
  return null
}

export { constructGeocoderFromAddress, constructAddressInput }
