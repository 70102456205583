import type { Event, Trip } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'

const pushTripAddNoteClickToDataLayer = ({
  triggerVariant,
  tripId,
  tripName,
}: {
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_note_add_click', {
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripAddToTripClickToDataLayer = ({
  itemCategory,
  itemDate,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemCategory: Event['type']
  itemDate: Event['startDate']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_add_to_trip_click', {
    item_category: itemCategory,
    item_date: itemDate,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripEventCreateToDataLayer = ({
  itemCategory,
  itemId,
  itemInsertedAt,
  itemEndDate,
  itemName,
  itemStartDate,
  itemStatus,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemCategory: Event['type']
  itemId: Event['productId']
  itemInsertedAt: Event['insertedAt']
  itemEndDate: Event['endDate']
  itemName: Event['name']
  itemStartDate: Event['startDate']
  itemStatus: Event['status']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_event_create', {
    item_category: itemCategory,
    item_id: itemId,
    item_inserted_at: itemInsertedAt,
    item_end_date: itemEndDate,
    item_name: itemName,
    item_start_date: itemStartDate,
    item_status: itemStatus,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripEventDeleteToDataLayer = ({
  itemCategory,
  itemId,
  itemName,
  itemEndDate,
  itemStartDate,
  itemStatus,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemCategory: Event['type']
  itemId: Event['id']
  itemName: Event['name']
  itemEndDate: Event['endDate']
  itemStartDate: Event['startDate']
  itemStatus: Event['status']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_event_delete', {
    item_category: itemCategory,
    item_id: itemId,
    item_name: itemName,
    item_end_date: itemEndDate,
    item_start_date: itemStartDate,
    item_status: itemStatus,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripEventUpdateToDataLayer = ({
  itemCategory,
  itemId,
  itemName,
  itemEndDate,
  itemStartDate,
  itemStatus,
  itemUpdatedAt,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemCategory: Event['type']
  itemId: Event['id']
  itemName: Event['name']
  itemEndDate: Event['endDate']
  itemStartDate: Event['startDate']
  itemStatus: Event['status']
  itemUpdatedAt: Event['updatedAt']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_event_update', {
    item_category: itemCategory,
    item_id: itemId,
    item_name: itemName,
    item_end_date: itemEndDate,
    item_start_date: itemStartDate,
    item_status: itemStatus,
    item_updated_at: itemUpdatedAt,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripNavClickToDataLayer = ({
  itemCategory,
  itemUrl,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemCategory: string
  itemUrl: string
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_nav_click', {
    item_category: itemCategory,
    item_url: itemUrl,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripNoteCreateToDataLayer = ({
  itemId,
  insertedAt,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemId: Trip['notes'][0]['id']
  insertedAt: Trip['notes'][0]['insertedAt']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_note_create', {
    item_id: itemId,
    item_inserted_at: insertedAt,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripNoteDeleteToDataLayer = ({
  itemId,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemId: Trip['notes'][0]['id']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) => {
  pushDataToDataLayer('trip_note_delete', {
    item_id: itemId,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })
}

const pushTripNoteUpdateToDataLayer = ({
  itemId,
  itemUpdatedAt,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemId: Trip['notes'][0]['id']
  itemUpdatedAt: Trip['notes'][0]['updatedAt']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_note_update', {
    item_id: itemId,
    item_updated_at: itemUpdatedAt,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

const pushTripStayAddClickToDataLayer = ({
  itemEndDate,
  itemCategory,
  itemStartDate,
  triggerVariant,
  tripId,
  tripName,
}: {
  itemEndDate: Event['endDate']
  itemCategory: Event['type']
  itemStartDate: Event['startDate']
  triggerVariant: string
  tripId: Trip['id']
  tripName: Trip['name']
}) =>
  pushDataToDataLayer('trip_stay_add_click', {
    item_end_date: itemEndDate,
    item_category: itemCategory,
    item_start_date: itemStartDate,
    trigger_url: window.location.href,
    trigger_variant: triggerVariant,
    trip_id: tripId,
    trip_name: tripName,
  })

export {
  pushTripAddNoteClickToDataLayer,
  pushTripAddToTripClickToDataLayer,
  pushTripEventCreateToDataLayer,
  pushTripEventDeleteToDataLayer,
  pushTripEventUpdateToDataLayer,
  pushTripNavClickToDataLayer,
  pushTripNoteCreateToDataLayer,
  pushTripNoteDeleteToDataLayer,
  pushTripNoteUpdateToDataLayer,
  pushTripStayAddClickToDataLayer,
}
