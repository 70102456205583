import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { gql } from 'src/__generated__'
import type { HotelSearchResult } from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { DrawerType } from 'src/pages/trips/constants'
import type { TripMapPoint } from 'src/pages/trips/types'

const searchHotelsQuery = gql(`
  query SearchHotelsForStayEvent($searchHotelsArgs: SearchHotelsArgs!) {
    searchHotels(searchHotelsArgs: $searchHotelsArgs) {
      hotelSearchResults {
        id
        customerReviewScore
        description
        hotelAddress {
          latitude
          longitude
        }
        images
        name
        nightlyAverage
        totalCustomerReviews
      }
    }
  }
`)

export const useGetHotelsForStayEvent = ({
  latitude,
  longitude,
  arrival,
  departure,
  tracker,
}: {
  latitude: string
  longitude: string
  arrival: string
  departure: string
  tracker: string
}) => {
  const arrivalFormatted = dayjs(arrival).format('YYYY-MM-DD')
  const departureFormatted = dayjs(departure).format('YYYY-MM-DD')
  let departureFinal =
    arrivalFormatted === departureFormatted
      ? dayjs(departure).add(1, 'day').format('YYYY-MM-DD')
      : departureFormatted

  const { data, loading, error } = useQuery(searchHotelsQuery, {
    skip: !latitude || !longitude,
    variables: {
      searchHotelsArgs: {
        adults: 2,
        arrival: arrivalFormatted,
        departure: departureFinal,
        tracker,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        size: 4,
        radius: 20,
      },
    },
  })

  return {
    hotels: data?.searchHotels?.hotelSearchResults?.map(createMapPoint) ?? [],
    loading,
    error,
  }
}

const createMapPoint = (hotel: HotelSearchResult) => {
  const result: TripMapPoint & {
    nightlyAverage: string
    description: string
    customerReviewScore: number
  } = {
    id: hotel.id,
    imageUrl: hotel?.images?.[0],
    title: hotel.name,
    lat: parseFloat(hotel.hotelAddress.latitude),
    lng: parseFloat(hotel.hotelAddress.longitude),
    active: true,
    drawerType: DrawerType.InternalHotel,
    totalReviews: hotel.totalCustomerReviews,
    type: EventType.Stay,
    nightlyAverage: hotel.nightlyAverage,
    description: hotel.description,
    customerReviewScore: hotel.customerReviewScore,
    variant: 'explore',
  }

  return result
}
