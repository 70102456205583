import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { PickerLabel } from '@travelpass/design-system'
import { Picker } from '@travelpass/design-system'
import { TripStatus } from 'src/__generated__/graphql'
import { UserTrips } from './components'

const labels: [PickerLabel, PickerLabel] = [
  { label: 'Upcoming', value: TripStatus.Upcoming },
  { label: 'Past', value: TripStatus.Past },
]

export const MyTripsList = () => {
  const [tripStatus, setTripStatus] = useState<TripStatus>(TripStatus.Upcoming)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === TripStatus.Past) {
      setTripStatus(TripStatus.Past)
    } else {
      setTripStatus(TripStatus.Upcoming)
    }
  }

  return (
    <section className='lg:pt-25 space-y-12 py-16 lg:pb-0'>
      <header className='flex flex-col items-center justify-center'>
        <h1 className='type-h2'>Your trips</h1>
        <div className='h-.5 bg-valley mt-5 w-16' />
      </header>
      <div className='flex w-full flex-row items-center justify-center px-6'>
        <Picker
          defaultValue={TripStatus.Upcoming}
          labels={labels}
          onChange={onChange}
        />
      </div>
      <UserTrips tripStatus={tripStatus} />
    </section>
  )
}
