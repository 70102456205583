import { Radio } from '@travelpass/design-system'
import type { TravelerType } from 'src/__generated__/graphql'
import { isFirstIndex } from 'src/utils'
import type { HotelReviewsFilterType } from '../HotelReviewsApollo/hotelReviewsApolloConstants'
import { travelerTypeItems } from '../HotelReviewsApollo/hotelReviewsApolloConstants'

interface HotelReviewsTravelerTypeProps {
  filters: HotelReviewsFilterType
  onChange(updatedFilters: HotelReviewsFilterType): void
}

export const HotelReviewsTravelerType = ({
  filters,
  onChange,
}: HotelReviewsTravelerTypeProps) => {
  const { travelerTypes } = filters ?? {}

  const onTravelerTypeChange = (value: TravelerType) => {
    onChange({
      travelerTypes: travelerTypes?.includes(value) ? null : [value],
    })
  }

  return (
    <div className='flex flex-col gap-4'>
      <h6 className='type-body-1'>Traveler Type:</h6>
      <div className='flex flex-col gap-2'>
        {travelerTypeItems.map(({ title, value }, index) => (
          <Radio
            key={`hotel-reviews-traveler-type-${index}`}
            id={`hotel-reviews-traveler-type-${index}`}
            isChecked={
              travelerTypes
                ? travelerTypes?.includes(value)
                : isFirstIndex(index)
            }
            label={title}
            value={value}
            onClick={() => onTravelerTypeChange(value)}
          />
        ))}
      </div>
    </div>
  )
}
