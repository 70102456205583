import { useSnackbar } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import {
  ResultsCardReviews,
  Scroller,
  ScrollerButton,
} from 'src/common/components'
import { DrawerType, TripSearchParams } from 'src/pages/trips/constants'
import { getTripEventsQuery } from 'src/pages/trips/hooks'
import { pushTripEventCreateToDataLayer } from 'src/pages/trips/utils'
import { useCreateAddStayEvent } from './useCreateAddStayEvent'
import { useGetHotelsForStayEvent } from './useGetHotelsForStayEvent'
import { EventCard } from '../../EventCard/EventCard'
import { HotelCardPricing } from '../../EventCard/HotelCardPricing'
import { SkeletonCard } from '../../EventCard/SkeletonCard'

export const RecommendedStaysHorizontal = ({
  tripId,
  latitude,
  longitude,
  arrival,
  departure,
  tracker,
}: {
  tripId: string
  latitude: string
  longitude: string
  arrival: string
  departure: string
  tracker: string
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const { hotels, loading } = useGetHotelsForStayEvent({
    latitude,
    longitude,
    departure,
    arrival,
    tracker,
  })
  const { createAddStayEvent } = useCreateAddStayEvent()

  const onCardClick = (eventId: string) => {
    searchParams.set(TripSearchParams.eventId, eventId)
    searchParams.set(TripSearchParams.eventType, DrawerType.InternalHotel)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onSelect = async (hotelId: string) => {
    const hotel = hotels?.find(hotel => hotel?.id === hotelId)
    try {
      const newEvent = await createAddStayEvent({
        variables: {
          eventInput: {
            addresses: [
              {
                lat: hotel?.lat?.toString(),
                long: hotel?.lng?.toString(),
              },
            ],
            description: hotel?.description,
            endDate: departure,
            externalTypeId: hotel?.id,
            name: hotel?.title,
            productId: hotel?.id,
            startDate: arrival,
            tripId,
            type: EventType?.Stay,
            status: EventStatus?.NotBooked,
            useEventTime: false,
          },
        },
        refetchQueries: [
          {
            query: getTripEventsQuery,
            variables: {
              tripId,
            },
          },
        ],
      })
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant: 'Recommended',
        tripId,
        tripName,
      })
      addSuccessSnack({
        title: 'Hotel successfully added to trip',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        title: 'Something went wrong. Try again later.',
      })
    }
  }

  if (loading) {
    return (
      <div className='flex gap-2'>
        <div className='w-315px md:w-380px'>
          <SkeletonCard />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Scroller
        allowTouch
        hideScrollBar
        LeftArrow={
          !isMobile && (
            <div className='p-r-1'>
              <ScrollerButton icon='arrowBackIos' size='small' />
            </div>
          )
        }
        RightArrow={
          !isMobile && (
            <div className='p-l-1'>
              <ScrollerButton isNext icon='arrowForwardIos' size='small' />
            </div>
          )
        }
        scrollContainerClassName={gap}
      >
        {hotels?.map(hotel => {
          return (
            <div key={hotel?.id} className='w-315px md:w-380px'>
              <EventCard
                id={hotel?.id}
                imageUrl={hotel?.imageUrl}
                isSmall={true}
                title={hotel?.title}
                variant='recommended'
                onClick={(hotelId: string) => onCardClick(hotelId)}
                onSelect={onSelect}
              >
                <HotelCardPricing averageNightlyRate={hotel?.nightlyAverage} />
                <ResultsCardReviews
                  average={hotel?.customerReviewScore}
                  total={hotel?.totalReviews}
                  type={hotel?.type}
                />
              </EventCard>
            </div>
          )
        })}
      </Scroller>
    </div>
  )
}

const gap = 'gap-2'
