import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import type {
  Fee,
  GetTripDetailsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { EventStatus } from 'src/__generated__/graphql'
import { hotelsPath } from 'src/constants'
import { getEventImage } from 'src/pages/trips/utils'
import {
  generateHotelDetailsUrl,
  getDateDiff,
  getDateFromUTC,
  getTimeFromUTC,
} from 'src/utils'
import type {
  StayEvent,
  EventNode,
  HotelNode,
  PlaceNode,
  HotelReviewsNode,
} from '../constants'
import { defaultBookedRoom, defaultStayEvent } from '../constants'

const convertToStringArray = (array?: (string | null)[] | null) => {
  if (array && !isEmpty(array)) {
    return array.reduce((result: string[], currentValue) => {
      if (currentValue && typeof currentValue === 'string') {
        result.push(currentValue)
      }
      return result
    }, [])
  }

  return []
}

const groupAddress = ({
  eventData,
  hotelData,
  placeData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    address: defaultStayEvent.address,
  }
  const eventAddress = eventData?.addresses?.[0] ?? null
  const hotelAddress = hotelData?.address ?? null
  const placesAddress = placeData?.address ?? null
  const {
    addressLine1: defaultAddressLine1,
    city: defaultCity,
    country: defaultCountry,
    googlePlaceId: defaultGooglePlaceId,
    id: defaultId,
    lat: defaultLat,
    long: defaultLong,
    state: defaultState,
    zipcode: defaultZipcode,
  } = defaultStayEvent.address

  if (eventAddress?.state) {
    updatedData.address = {
      addressLine1: eventAddress?.addressLine1 ?? defaultAddressLine1,
      city: eventAddress?.city ?? defaultCity,
      country: eventAddress?.country ?? defaultCountry,
      googlePlaceId: eventAddress?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: eventAddress?.lat ?? defaultLat,
      long: eventAddress?.long ?? defaultLong,
      state: eventAddress?.state ?? defaultState,
      zipcode: eventAddress?.zipcode ?? defaultZipcode,
    }
  } else if (hotelAddress) {
    updatedData.address = {
      addressLine1: hotelData?.address ?? defaultAddressLine1,
      city: hotelData?.city ?? defaultCity,
      country: hotelData?.country ?? defaultCountry,
      googlePlaceId: hotelData?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: hotelData?.latitude ?? defaultLat,
      long: hotelData?.longitude ?? defaultLong,
      state: hotelData?.state ?? defaultState,
      zipcode: hotelData?.postalCode ?? defaultZipcode,
    }
  } else if (placesAddress) {
    updatedData.address = {
      addressLine1: placeData?.address ?? defaultAddressLine1,
      city: placeData?.city ?? defaultCity,
      country: placeData?.country ?? defaultCountry,
      googlePlaceId: placeData?.googlePlaceId ?? defaultGooglePlaceId,
      id: eventAddress?.id ?? defaultId,
      lat: placeData?.latitude ?? defaultLat,
      long: placeData?.longitude ?? defaultLong,
      state: placeData?.state ?? defaultState,
      zipcode: placeData?.postalCode ?? defaultZipcode,
    }
  }

  return updatedData?.address
}

const groupDates = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    startDate: defaultStayEvent.startDate,
    endDate: defaultStayEvent.endDate,
  }
  const timezone = tripData?.timeZone ?? defaultStayEvent.timezone
  const { startDate, endDate } = eventData ?? {}

  if (startDate) {
    updatedData.startDate = getDateFromUTC(startDate, timezone)
  }
  if (endDate) {
    updatedData.endDate = getDateFromUTC(endDate, timezone)
  }

  return updatedData
}

const groupTime = ({
  eventData,
  tripData,
}: {
  eventData?: EventNode | null
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const updatedData = {
    startTime: defaultStayEvent.startTime,
    endTime: defaultStayEvent.endTime,
    timezone: defaultStayEvent.timezone,
  }
  const { timeZone } = tripData ?? {}
  const { startDate, endDate } = eventData ?? {}
  if (timeZone) updatedData.timezone = timeZone

  if (eventData?.useEventTime) {
    if (startDate) {
      updatedData.startTime = getTimeFromUTC({
        utcDate: dayjs(startDate)?.toISOString(),
        timezone: timeZone ?? defaultStayEvent.timezone,
      })
    }
    if (endDate) {
      updatedData.endTime = getTimeFromUTC({
        utcDate: dayjs(endDate)?.toISOString(),
        timezone: timeZone ?? defaultStayEvent.timezone,
      })
    }
  }

  return updatedData
}

const groupAmenites = ({
  hotelData,
  placeData,
}: {
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    amenities: defaultStayEvent.amenities,
  }

  const hotelAmenities = hotelData?.amenities ?? null
  const placeAmenities = placeData?.amenities ?? null

  if (hotelAmenities && !isEmpty(hotelAmenities)) {
    updatedData.amenities = convertToStringArray(hotelAmenities)
  } else if (placeAmenities && !isEmpty(placeAmenities)) {
    updatedData.amenities = convertToStringArray(placeAmenities)
  }

  return updatedData.amenities
}

const groupDescription = ({
  eventData,
  hotelData,
  placeData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    description: defaultStayEvent.description,
  }
  const hotelDescription = hotelData?.descriptions?.[0]?.text ?? null
  const placeDescription = placeData?.description ?? null
  const eventDescription = eventData?.description ?? null

  if (hotelDescription) {
    const hotelDesciptionsJoined = hotelData?.descriptions
      ?.map(description => description?.text)
      .join(' ')
    if (hotelDesciptionsJoined) updatedData.description = hotelDesciptionsJoined
  } else if (placeDescription) {
    updatedData.description = placeDescription
  } else if (eventDescription) {
    updatedData.description = eventDescription
  }

  return updatedData?.description
}

const groupHours = ({ placeData }: { placeData?: PlaceNode }) => {
  const updatedData = {
    hours: defaultStayEvent.hours,
  }
  const placeHours = placeData?.openingHours?.weekdayText ?? null

  if (placeHours && !isEmpty(placeHours)) {
    updatedData.hours = convertToStringArray(placeHours)
  }

  return updatedData.hours
}

const groupImages = ({
  eventData,
  hotelData,
  placeData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    images: defaultStayEvent.images,
  }
  const hotelImages = hotelData?.imageUrls ?? null
  const placeImages = placeData?.imageLinks ?? null

  if (hotelImages) {
    updatedData.images = convertToStringArray(hotelImages)
  } else if (placeImages) {
    updatedData.images = convertToStringArray(placeImages)
  } else if (eventData?.imageUrl) {
    updatedData.images = [eventData?.imageUrl]
  }

  if (!isEmpty(updatedData.images)) {
    const images = updatedData.images
      ?.slice(0, 5)
      ?.map(image => getEventImage(image))

    updatedData.images = convertToStringArray(images)
  }

  return updatedData.images
}

const groupName = ({
  eventData,
  hotelData,
  placeData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    name: defaultStayEvent.name,
  }
  const eventName = eventData?.name ?? null
  const hotelName = hotelData?.name ?? null
  const placeName = placeData?.name ?? null

  if (eventName) {
    updatedData.name = eventName
  } else if (hotelName) {
    updatedData.name = hotelName
  } else if (placeName) {
    updatedData.name = placeName
  }

  return updatedData.name
}

const groupPhone = ({
  hotelData,
  placeData,
}: {
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    phone: defaultStayEvent.phone,
  }

  const hotelPhone = hotelData?.phoneNumbers?.[0] ?? null
  const placePhone = placeData?.phoneNumber ?? null
  if (hotelPhone) {
    updatedData.phone = hotelPhone
  } else if (placePhone) {
    updatedData.phone = placePhone
  }

  return updatedData.phone
}

const groupRating = ({
  placeData,
  hotelReviewData,
}: {
  placeData?: PlaceNode
  hotelReviewData?: HotelReviewsNode
}) => {
  const updatedData = {
    rating: defaultStayEvent.rating,
  }

  const hotelRating = hotelReviewData?.averageOverall ?? 0
  const placeRating = placeData?.rating ? parseFloat(placeData?.rating) : 0

  if (hotelRating) updatedData.rating = hotelRating
  else if (placeRating) updatedData.rating = placeRating
  return updatedData.rating
}

const checkStars = (stars: string | null) => {
  if (stars) {
    const starFloat = parseFloat(stars)
    if (!isNaN(starFloat)) return starFloat
  }
  return defaultStayEvent.stars
}

const groupStars = ({
  hotelData,
  placeData,
}: {
  hotelData?: HotelNode
  placeData?: PlaceNode
}) => {
  const updatedData = {
    rating: defaultStayEvent.rating,
  }

  const hotelStars = hotelData?.rating ?? null
  const placeStars = placeData?.starRating ?? null

  if (hotelStars) {
    updatedData.rating = checkStars(hotelStars)
  } else if (placeStars) {
    updatedData.rating = checkStars(placeStars)
  }

  return updatedData.rating
}
const groupUrls = ({
  eventData,
  hotelData,
  placeData,
  data,
  tripId,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  placeData?: PlaceNode
  data: StayEvent
  tripId?: string
}) => {
  const updatedData = {
    bookingDetailsUrl: defaultStayEvent.bookingDetailsUrl,
    productDetailsUrl: defaultStayEvent.productDetailsUrl,
    externalUrl: defaultStayEvent.externalUrl,
  }

  const bookingId = eventData?.booking?.[0]?.id ?? null
  if (eventData?.status === EventStatus.Booked && bookingId) {
    updatedData.bookingDetailsUrl = `${hotelsPath}/confirmation/${bookingId}`
  }

  // hotel details url
  if (hotelData?.id && hotelData?.name) {
    updatedData.productDetailsUrl = generateHotelDetailsUrl({
      city: hotelData?.city ?? 'city',
      id: hotelData?.id,
      name: hotelData?.name,
      state: hotelData?.state ?? 'state',
      stateAbbreviation: hotelData?.stateCode ?? 'statecode',
      tripId,
      arrival: data?.startDate,
      departure: data?.endDate,
      eventId: eventData.id,
    })
  }
  // hotel url
  if (hotelData?.webAddress) {
    updatedData.externalUrl = hotelData?.webAddress
  } else if (placeData?.website) {
    updatedData.externalUrl = placeData?.website
  }

  return updatedData
}

const groupRoomData = ({
  eventData,
  data,
}: {
  eventData?: EventNode | null
  data: StayEvent
}) => {
  const updatedData = cloneDeep(defaultBookedRoom)
  const bookingDetails = eventData?.booking?.[0]?.bookingDetails ?? null
  updatedData.name =
    bookingDetails?.hotelDetails?.room?.name ?? defaultBookedRoom?.name
  updatedData.image =
    bookingDetails?.hotelDetails?.room?.images?.[0]?.href ??
    defaultBookedRoom?.image

  const adultGuests = bookingDetails?.occupancies?.[0]?.adults ?? 1
  const childGuest = bookingDetails?.occupancies?.[0]?.childAges?.length ?? 1
  const guests = adultGuests && childGuest ? adultGuests + childGuest : 1
  updatedData.guests = guests ?? defaultBookedRoom?.guests

  const nightCount = getDateDiff([
    data?.endDate?.format('MM/DD/YYYY'),
    data?.startDate?.format('MM/DD/YYYY'),
  ])

  updatedData.nightCount = nightCount ?? defaultBookedRoom?.nightCount

  const { allInTotal, dueLater, dueNow, additionalFees, surchargeTotal } =
    bookingDetails?.price ?? {}
  const additionalFeesTruthy = additionalFees?.reduce(
    (result: Fee[], currentValue) => {
      if (
        currentValue &&
        currentValue?.amount?.amount &&
        typeof currentValue?.amount?.amount === 'string'
      ) {
        result.push(currentValue)
      }
      return result
    },
    []
  )
  updatedData.allInTotal = allInTotal?.amount
  updatedData.additionalFees =
    additionalFeesTruthy ?? defaultBookedRoom.additionalFees
  updatedData.dueLater = dueLater?.amount ?? defaultBookedRoom?.dueLater
  updatedData.dueNow = dueNow?.amount ?? defaultBookedRoom?.dueNow
  updatedData.refundType =
    bookingDetails?.cancelPolicy?.refundType ?? defaultBookedRoom?.refundType
  updatedData.surchargeTotal =
    surchargeTotal?.amount ?? defaultBookedRoom?.surchargeTotal
  const { surcharges, subtotal } =
    bookingDetails?.price?.pricingPerRoomOccupancy?.[0] ?? {}
  updatedData.subtotal = subtotal?.amount ?? defaultBookedRoom?.subtotal
  updatedData.surcharges = surcharges ?? defaultBookedRoom?.surcharges

  if (updatedData.name === defaultBookedRoom.name) return null
  return updatedData
}

const groupProductId = ({
  eventData,
  hotelData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
}) => {
  const productIdFromEvent = eventData?.productId ?? null
  const productIdFromBooking = eventData?.booking?.[0]?.hotelId ?? null
  const productIdFromProduct = hotelData?.id ?? null

  if (productIdFromEvent) {
    return productIdFromEvent
  } else if (productIdFromBooking) {
    return productIdFromBooking
  } else if (productIdFromProduct) {
    return productIdFromProduct
  }

  return null
}

const groupStayData = ({
  eventData,
  hotelData,
  hotelReviewData,
  placeData,
  tripData,
}: {
  eventData?: EventNode | null
  hotelData?: HotelNode
  hotelReviewData?: HotelReviewsNode
  placeData?: PlaceNode
  tripData?: GetTripDetailsQueryInTripsQuery['getTrip'] | null
}) => {
  const data: StayEvent = cloneDeep(defaultStayEvent)

  data.address = groupAddress({
    eventData,
    hotelData,
    placeData,
  })

  data.amenities = groupAmenites({ hotelData, placeData })

  const { startDate, endDate } = groupDates({ eventData, tripData })
  data.startDate = startDate
  data.endDate = endDate

  const { startTime, endTime, timezone } = groupTime({ eventData, tripData })
  data.startTime = startTime
  data.endTime = endTime
  data.timezone = timezone

  data.bookingConfirmation =
    eventData?.booking?.[0]?.externalConfirmationId ??
    defaultStayEvent?.bookingConfirmation

  data.description = groupDescription({
    eventData,
    hotelData,
    placeData,
  })

  data.hours = groupHours({ placeData })

  if (eventData?.id) data.id = eventData?.id
  data.images = groupImages({ eventData, hotelData, placeData })

  data.name = groupName({ eventData, hotelData, placeData })

  if (eventData?.notes) data.notes = eventData?.notes

  data.phone = groupPhone({ hotelData, placeData })
  data.productId = groupProductId({ eventData, hotelData })

  data.rating = groupRating({ placeData, hotelReviewData })
  data.totalReviews =
    hotelReviewData?.totalReviews ?? defaultStayEvent?.totalReviews

  data.stars = groupStars({ hotelData, placeData })

  data.status = eventData?.status ?? defaultStayEvent?.status

  const { bookingDetailsUrl, productDetailsUrl, externalUrl } = groupUrls({
    eventData,
    hotelData,
    placeData,
    data,
    tripId: tripData?.id,
  })
  data.bookingDetailsUrl = bookingDetailsUrl
  data.productDetailsUrl = productDetailsUrl
  data.externalUrl = externalUrl

  data.bookingRoom = groupRoomData({ eventData, data })
  return data
}

export { groupStayData }
