import { useGetExperiencePrice } from './useGetExperiencePrice'

export const FromPrice = () => {
  const { data: price } = useGetExperiencePrice()

  if (!price.amount) return null

  return (
    <p className='m-0'>
      <span className='type-h4'>From {price.amount}</span>
      <span className='type-small-text'>/{price.unit}</span>
    </p>
  )
}
