import { Avatar, Button, IconButton } from '@travelpass/design-system'
import type { FollowersModalType } from 'src/pages/profile/types'
import { getProfilePageUrl } from 'src/utils'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface ProfileDisplayProps {
  currentUser: UseGetDashboardQuery['dashboardData']['currentUser']
  onModalInfoOpen: VoidFunction
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const CompetitionProfileDisplay = ({
  currentUser,
  onModalInfoOpen,
  toggleFollowersModal,
}: ProfileDisplayProps) => {
  const { userProfile } = { ...currentUser }
  const { accountHandle, displayName, profileImageUrl, followCount } = {
    ...userProfile,
  }
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(accountHandle)}`

  return (
    <div className='lg:hidden'>
      <div className='rounded-t-8px flex justify-between bg-white px-6 py-5'>
        <div className='flex flex-row gap-2'>
          <a
            className='[&>img]:border-1 self-start'
            href={ownerUrl}
            rel='noreferrer'
            target='_blank'
          >
            <Avatar border='sm' size='sm' src={profileImageUrl} />
          </a>
          <div>
            <p className='type-h4'>{displayName}</p>
            <div className='[&>button]:p-x-0 [&>button>div]:color-black flex flex-row gap-1.5 pt-3'>
              <Button
                label={`${followCount?.countOfFollowing ?? '0'} Followers`}
                size='small'
                variant='text'
                onClick={() => toggleFollowersModal('followers')}
              />
              <Button
                label={`${followCount?.countOfFollowed ?? '0'} Following`}
                size='small'
                variant='text'
                onClick={() => toggleFollowersModal('following')}
              />
            </div>
          </div>
        </div>
        <IconButton
          icon='modeEdit'
          size='small'
          onClick={() => onModalInfoOpen()}
        />
      </div>
    </div>
  )
}
