import { useSearchParams } from 'react-router-dom'
import { ExperiencesDetailsSearchParams } from './useExperienceDetailsSearchParams'

export const useSearchParamTravelDateState = () => {
  const [searchParams, updateSearchParams] = useSearchParams()
  const travelDate =
    searchParams.get(ExperiencesDetailsSearchParams.travelDate) ?? ''

  const onClick = (updatedTravelDate: string) => {
    updateSearchParams(
      sp => {
        // @todo: ensure that updatedTravelDate is formatted correctly?
        sp.set(ExperiencesDetailsSearchParams.travelDate, updatedTravelDate)
        return sp
      },
      { replace: true }
    )
  }

  return [travelDate, onClick] as const
}
