import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { SortBy } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
} from 'src/utils'

export const SpringBreak = () => {
  const navigate = useNavigate()

  const onCharlestonHotelClick = () => {
    const url = generateHotelResultsUrl({
      latitude: 32.7833163,
      longitude: -79.9319664,
      location: 'Charleston, SC, USA',
      sort: SortBy.Recommended,
    })
    navigate(url)
  }

  const onCharlestonExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      latitude: 32.7833163,
      longitude: -79.9319664,
      location: 'Charleston, SC, USA',
    })
    navigate(url)
  }

  const onPlayaDelCarmenHotelsClick = () => {
    const url = generateHotelResultsUrl({
      latitude: 20.6295586,
      longitude: -87.0738851,
      location: 'Playa Del Carmen, Quintana Roo, Mexico',
      sort: SortBy.Recommended,
    })
    navigate(url)
  }

  const onPlayaDelCarmenExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      latitude: 20.6295586,
      longitude: -87.0738851,
      location: 'Playa del Carmen, Quintana Roo, Mexico',
    })
    navigate(url)
  }

  const onZionNationalParkHotelsClick = () => {
    const url = generateHotelResultsUrl({
      latitude: 37.2982022,
      longitude: -113.0263005,
      location: 'Zion National Park, Utah, USA',
      sort: SortBy.Recommended,
    })
    navigate(url)
  }

  const onZionNationalParkExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      latitude: 37.2982022,
      longitude: -113.0263005,
      location: 'Zion National Park, Utah, USA',
    })
    navigate(url)
  }

  const onHoustonHotelsClick = () => {
    const url = generateHotelResultsUrl({
      latitude: 29.7604267,
      longitude: -95.3698028,
      location: 'Houston, TX, USA',
      sort: SortBy.Recommended,
    })
    navigate(url)
  }

  const onHoustonExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      latitude: 29.7604267,
      longitude: -95.3698028,
      location: 'Houston, TX, USA',
    })
    navigate(url)
  }

  const onStartPlanningClick = () => {
    const url = '/'
    navigate(url)
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='bg-warmGrey w-full'>
        <PageLayoutContainer>
          <div className='h-350px lg:h-600px rounded-6 relative my-8 flex w-full flex-col justify-center gap-4 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/42d4087a-d677-11ee-933d-761d2ff439c9)] bg-cover bg-center px-10 md:my-10 lg:px-0'>
            <div className='from-forest rounded-6 absolute bottom-0 left-0 right-0 top-0 flex w-full items-center bg-gradient-to-t from-10%'>
              <div className='ma flex flex-col gap-2 lg:gap-6'>
                <h1 className='type-h1 c-white lg:text-16 text-center drop-shadow-lg'>
                  The Best Spring Break Destinations
                </h1>
                <p className='type-subtitle-2 font-400 c-white max-w-90% md:max-w-450px lg:max-w-650px lg:line-height-6 mx-a text-center drop-shadow-lg'>
                  Whether you’re looking for an unexpected adventure or the very
                  best Spring Break destinations by a beach, we have you
                  covered.
                </p>
              </div>
            </div>
          </div>
        </PageLayoutContainer>

        <h2 className='type-h1 mb-6 text-center md:mb-8'>Our Top Picks</h2>
        <div className='w60px h2px bg-valley mx-a mb-6 md:mb-10'></div>

        <div className='wfull py10 bg-forest'>
          <PageLayoutContainer>
            <div className='flex flex-col flex-col-reverse items-start gap-8 md:flex-row md:items-center'>
              <div className='gap4 lg:gap8 flex flex-1 flex-col'>
                <div>
                  <h2 className='type-h1 c-white mb4'>
                    The Trip For <span className='c-valley'>Everyone</span>
                  </h2>
                  <p className='type-subtitle-1 c-white'>
                    Charleston, South Carolina
                  </p>
                </div>
                <div className='w60px h2px bg-valley'></div>
                <p className='type-subtitle-2 font-300 c-white lg:line-height-6'>
                  With miles of beaches nearby, a busy nightlife, along with
                  top-tier shopping and restaurants, Charleston, South Carolina
                  is the destination for whatever you&apos;re looking for. Visit
                  in the spring for delightful weather and a few festivals,
                  markets, and parades.
                </p>
                <p className='type-subtitle-2 font-300 c-white lg:line-height-6'>
                  With foodie crawls, dolphin-spotting tours, sunset cruises and
                  more, there&apos;s almost too many things to do in Charleston
                  ... though we have a soft spot for taking a horse-drawn
                  carriage to the city&apos;s most pituresque churches and
                  sites.
                </p>
                <div className='flex flex-col items-center justify-around gap-6 md:flex-row'>
                  <Button
                    fullWidth
                    label='See Hotels'
                    size='large'
                    variant='outlined'
                    onClick={onCharlestonHotelClick}
                  />
                  <Button
                    fullWidth
                    label='See Experiences'
                    size='large'
                    variant='outlined'
                    onClick={onCharlestonExperiencesClick}
                  />
                </div>
              </div>
              <div className='flex-1'>
                <div className='wfull object-fit rounded-tl-6 rounded-br-6 border-valley relative overflow-hidden border-2 border-solid'>
                  <div className='bg-forestDark/70 py3 px7 absolute bottom-7 right-0'>
                    <p className='c-white type-body-1 font-700'>
                      Charleston, South Carolina
                    </p>
                  </div>
                  <img
                    alt='Charleston, South Carolina'
                    className='wfull block'
                    src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/02467414-d29e-11ee-a8e2-ca0846e21716'
                  />
                </div>
              </div>
            </div>
          </PageLayoutContainer>
        </div>

        <div className='wfull py10 bg-warmGrey'>
          <PageLayoutContainer>
            <div className='gap8 flex flex-col items-start md:flex-row md:items-center'>
              <div className='flex-1'>
                <div className='wfull object-fit rounded-tl-6 rounded-br-6 border-valley relative overflow-hidden border-2 border-solid'>
                  <div className='bg-forestDark/70 py3 px7 absolute bottom-7 right-0 md:left-0 md:right-auto'>
                    <p className='c-white type-body-1 font-700'>
                      Playa Del Carmen, Mexico
                    </p>
                  </div>
                  <img
                    alt='Playa Del Carmen, Mexico'
                    className='wfull block'
                    src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e1ddaff4-d4c2-11ee-9b0b-fa25b43940d0'
                  />
                </div>
              </div>
              <div className='gap4 lg:gap8 flex flex-1 flex-col'>
                <div>
                  <h2 className='type-h1 mb4'>
                    <span className='c-valley'>Almost</span> Cancun, Mexico
                  </h2>
                  <p className='type-subtitle-1'>Playa Del Carmen, Mexico</p>
                </div>
                <div className='w60px h2px bg-valley'></div>
                <p className='type-subtitle-2 font-300 lg:line-height-6'>
                  Visit Playa Del Carmen, not Cancun, for a more affordable
                  spring break trip to Mexico. You’ll still see palm trees and
                  boutique hotels right by the beach just with lower prices,
                  less crowds, and a chiller vibe than found in Cancun. It’s an
                  under-the-radar destination we can’t wait for you to discover.
                </p>
                <p className='type-subtitle-2 font-300 lg:line-height-6'>
                  When you need a break from relaxing, come tour the towering
                  ruins of Chichen Itza, swim in jungle cenotes, then visit
                  Valladolid, a sleepy town we’re smitten with. There’s a lot to
                  keep you busy in this part of Mexico!
                </p>
                <div className='flex flex-col items-center justify-around gap-6 md:flex-row'>
                  <Button
                    fullWidth
                    label='See Hotels'
                    size='large'
                    variant='filled'
                    onClick={onPlayaDelCarmenHotelsClick}
                  />
                  <Button
                    fullWidth
                    label='See Experiences'
                    size='large'
                    variant='filled'
                    onClick={onPlayaDelCarmenExperiencesClick}
                  />
                </div>
              </div>
            </div>
          </PageLayoutContainer>
        </div>

        <div className='wfull py10 bg-forest'>
          <PageLayoutContainer>
            <div className='gap8 flex flex-col flex-col-reverse items-start md:flex-row md:items-center'>
              <div className='gap4 lg:gap8 flex flex-1 flex-col'>
                <div>
                  <h2 className='type-h1 c-white mb4'>
                    A <span className='c-valley'>True Escape</span> And Serious
                    Adventure
                  </h2>
                  <p className='type-subtitle-1 c-white'>
                    Zion National Park, Utah
                  </p>
                </div>
                <div className='w60px h2px bg-valley'></div>
                <p className='type-subtitle-2 font-300 c-white lg:line-height-6'>
                  Early spring is the best time to visit Zion National Park,
                  which has plenty of hiking trails for all skill levels.
                  Staying right by the park entrance means your day of adventure
                  can be as long or as short as you need it to be (a good asset
                  if you’re traveling with kids).
                </p>
                <p className='type-subtitle-2 font-300 c-white lg:line-height-6'>
                  You can make this getaway as relaxing or action-packed as you
                  want. Experience one of Utah’s most stunning views in the
                  scenic Peek-A-Boo canyon, rent ATVs, or fit in a visit to
                  nearby Bryce Canyon National Park.
                </p>
                <div className='flex flex-col items-center justify-around gap-6 md:flex-row'>
                  <Button
                    fullWidth
                    label='See Hotels'
                    size='large'
                    variant='outlined'
                    onClick={onZionNationalParkHotelsClick}
                  />
                  <Button
                    fullWidth
                    label='See Experiences'
                    size='large'
                    variant='outlined'
                    onClick={onZionNationalParkExperiencesClick}
                  />
                </div>
              </div>
              <div className='flex-1'>
                <div className='wfull object-fit rounded-tl-6 rounded-br-6 border-valley relative overflow-hidden border-2 border-solid'>
                  <div className='bg-forestDark/70 py3 px7 absolute bottom-7 right-0'>
                    <p className='c-white type-body-1 font-700'>
                      Zion National Park, Utah
                    </p>
                  </div>
                  <img
                    alt='Zion National Park, Utah'
                    className='wfull block'
                    src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/028343ca-d4c5-11ee-93f3-a65382347988'
                  />
                </div>
              </div>
            </div>
          </PageLayoutContainer>
        </div>

        <div className='wfull py10 bg-warmGrey'>
          <PageLayoutContainer>
            <div className='gap8 flex flex-col items-start md:flex-row md:items-center'>
              <div className='flex-1'>
                <div className='wfull object-fit rounded-tl-6 rounded-br-6 border-valley relative overflow-hidden border-2 border-solid'>
                  <div className='bg-forestDark/70 py3 px7 absolute bottom-7 right-0 md:left-0 md:right-auto'>
                    <p className='c-white type-body-1 font-700'>
                      Houston, Texas
                    </p>
                  </div>
                  <img
                    alt='Houston, Texas'
                    className='wfull block'
                    src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/a22e8b82-d68c-11ee-b9e4-761d2ff439c9'
                  />
                </div>
              </div>
              <div className='gap4 lg:gap8 flex flex-1 flex-col'>
                <div>
                  <h2 className='type-h1 mb4'>
                    Fine Food, Art, Music, And{' '}
                    <span className='c-valley'>More</span>
                  </h2>
                  <p className='type-subtitle-1'>Houston, Texas</p>
                </div>
                <div className='w60px h2px bg-valley'></div>
                <p className='type-subtitle-2 font-300 lg:line-height-6'>
                  Visit Houston, Texas, for lots of museums and lush gardens,
                  warm weather, and plenty of live music in the evenings. The
                  food here is another big perk, with an incredible BBQ scene,
                  along with dozens of local Tex-Mex spots.
                </p>
                <p className='type-subtitle-2 font-300 lg:line-height-6'>
                  Stay busy in the city with foodie tours and museums, or visit
                  nearby Galveston — it’s a quick drive from Houston, and boasts
                  quite the history. Admire towering mansions, spot dolphins, or
                  see the haunted side with a ghost tour on your day trip.
                </p>
                <div className='flex flex-col items-center justify-around gap-6 md:flex-row'>
                  <Button
                    fullWidth
                    label='See Hotels'
                    size='large'
                    variant='filled'
                    onClick={onHoustonHotelsClick}
                  />
                  <Button
                    fullWidth
                    label='See Experiences'
                    size='large'
                    variant='filled'
                    onClick={onHoustonExperiencesClick}
                  />
                </div>
              </div>
            </div>
          </PageLayoutContainer>
        </div>

        <div className='wfull py20 bg-forest'>
          <PageLayoutContainer>
            <div className='gap8 flex flex-col items-center'>
              <h2 className='type-h1 c-white text-center'>
                Choose Your Own <span className='c-valley'>Adventure</span>
              </h2>
              <p className='type-subtitle-2 font-300 lg:line-height-6 max-w-800px c-white text-center'>
                Did one of those destinations or experiences catch your eye?
                Book your next trip (or even a day trip experience close to
                home!) on Travelpass, below:
              </p>
              <Button
                label='Book My Next Trip!'
                size='large'
                variant='outlined'
                onClick={onStartPlanningClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>
    </>
  )
}

const canonicalUrl =
  'https://www.travelpass.com/the-best-spring-break-destinations'
const metaDescription =
  'Discover the ultimate Spring Break destinations! From sun-soaked beaches to thrilling adventures, find your perfect getaway with top recommendations, exclusive deals, and travel tips. Plan your unforgettable spring vacation now!'
const pageName = 'The BEST Spring Break Destinations'
