import { useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import type { GetExperienceAvailabilityCheckQuery } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { bookSegment, experiencesPath } from 'src/constants'
import { getPriceWithDigits } from 'src/utils'
import { useExperiencesCreateExperienceHoldMutation } from '../common/hooks'

export const useAvailabilityOption = (
  bookableItems: GetExperienceAvailabilityCheckQuery['availabilityCheck']['groupedBookableItems'][0]['bookableItems']
) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [selectedOptionToken, setSelectedOptionToken] = useState<string>(() => {
    if (bookableItems.length === 1) return bookableItems[0].bookingFlowToken
    return null
  })

  const [createExperienceHold, { loading }] =
    useExperiencesCreateExperienceHoldMutation()

  const handleCreateExperienceHold = async bookingFlowToken => {
    if (loading || !bookingFlowToken) return

    const response = await createExperienceHold({
      variables: {
        bookingFlowToken,
      },
    })

    pushDataToDataLayer('select_time', {
      time: bookableItems.find(
        item => item.bookingFlowToken === selectedOptionToken
      ).startTime,
    })

    const bookingStateId =
      response.data?.createExperienceHold?.bookingHoldInfo?.bookingStateId

    if (bookingStateId) {
      // console.log('booking hold created')

      // clean up url search params
      // const searchParamsForNextRoute = createSearchParams()

      navigate(
        `${experiencesPath}/${bookSegment}/${bookingStateId}?${searchParams}`
      )
    }
  }

  const price =
    bookableItems[0].totalPrice?.price?.grandtotal?.amount &&
    getPriceWithDigits(bookableItems[0].totalPrice.price.grandtotal.amount)
  const hasAvailableItems = bookableItems.some(({ available }) => available)

  return {
    selectedOptionToken,
    setSelectedOptionToken,
    price,
    hasAvailableItems,
    handleCreateExperienceHold,
    loading,
  }
}
