import { ProgressBar } from '@travelpass/design-system'
import { maxReviewScore } from 'src/constants/user'
import { stringToNumber } from 'src/utils'

interface HotelReviewsAveragesProps {
  cleanlinessAverage?: number | null
  locationAverage?: number | null
  staffAverage?: number | null
}

export const HotelReviewsAverages = ({
  cleanlinessAverage,
  locationAverage,
  staffAverage,
}: HotelReviewsAveragesProps) => {
  const averages = [
    {
      isHidden: !cleanlinessAverage,
      title: 'Cleanliness',
      value: cleanlinessAverage,
    },
    {
      isHidden: !staffAverage,
      title: 'Staff',
      value: staffAverage,
    },
    {
      isHidden: !locationAverage,
      title: 'Location',
      value: locationAverage,
    },
  ]

  return (
    <div className='w-full! flex flex-col gap-6'>
      {averages.map(
        ({ isHidden, title, value }) =>
          !isHidden && (
            <div key={title} className='flex w-full flex-col'>
              <div className='flex-justify-between flex flex-row'>
                <p className='type-body-1'>{title}</p>
                <p className='type-body-1'>{`${value}/${maxReviewScore}`}</p>
              </div>
              <ProgressBar
                percentage={stringToNumber(
                  ((value / maxReviewScore) * 100).toFixed(2)
                )}
              />
            </div>
          )
      )}
    </div>
  )
}
