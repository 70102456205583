import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'

interface BookingCardIconTextProps {
  iconName: AvailableIcons
  content?: string | boolean
}

export const BookingCardIconText = ({
  iconName,
  content,
}: BookingCardIconTextProps) =>
  !!content && (
    <div className={bookingCardIconTextCss}>
      <Icon name={iconName} size='small' />
      <p className={bookingCardSubTitleCss}>{content}</p>
    </div>
  )

const bookingCardIconTextCss = 'flex items-center gap-1 c-grey800'
const bookingCardSubTitleCss = 'line-clamp-1 text-body-2 c-grey800'
