import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const ProfileCurrentUser = gql(`
  fragment ProfileCurrentUser on User {
    id
    firstName
    lastName
    email
    phoneNumber
    addressFirstLine
    addressSecondLine
    city
    state
    country
    zip
  }
`)

export const CURRENT_USER_QUERY = gql(`
  query getCurrentUser {
    currentUser {
      isDeleted
      userRoles
      ...ProfileCurrentUser
    }
  }
`)

export const useUserProfileQuery = () => useQuery(CURRENT_USER_QUERY)
