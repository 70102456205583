import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { bookingsPath } from 'src/constants'

export const MyTripsBookings = () => {
  const navigate = useNavigate()
  const onClick = () => navigate(bookingsPath)
  return (
    <section className='flex flex-col items-center justify-center gap-16 bg-white pt-16 md:flex-row md:gap-0 md:pt-0'>
      <div className='md:w-6/10 lg:w-5/10 lg:pl-30 flex w-full flex-col items-center justify-center gap-5 md:items-start md:pl-20'>
        <header className='flex flex-col items-center justify-center md:items-start'>
          <h1 className='type-h3'>Looking for your booking?</h1>
          <div className='bg-valley mt-5 h-0.5 w-16' />
        </header>
        <span className='lg:w-7/10 lg:pb-7.5'>
          <p className='type-body-2'>
            View and manage your bookings in the “my bookings” tab.
          </p>
        </span>
        <Button
          aria-label='View and manage your bookings'
          size='large'
          onClick={onClick}
        >
          My Bookings
        </Button>
      </div>
      <div className='h-98.25 md:h-66.5 lg:h-109.5 relative flex w-full flex-row items-center justify-center'>
        <div className='h-1/10 z-1 absolute left-0 top-0 w-full bg-gradient-to-b from-white/90 to-transparent md:h-full md:w-1/2 md:bg-gradient-to-r md:from-white' />
        <img
          alt=''
          className='h-full w-full object-cover'
          src='https://static.travelpass.com/assets/my-trips/my_trips_bookings.webp'
        />
      </div>
    </section>
  )
}
