import { useQuery } from '@apollo/client'
import { Link } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { PageLayoutContainer } from 'src/common/components'
import { hotelResultsPath } from 'src/constants'
import {
  HotelResultsCard,
  HotelResultsCardLoading,
} from '../hotels/results/common'
import type { GetHotelResultsCardUrl } from '../hotels/results/hotelResultsTypes'
import { getHotelResultsCardUrl } from '../hotels/results/hotelResultsUtils'

const GET_HOTELS_WITHOUT_RATES = gql(`
  query GetHotelsWithoutRates($searchHotelsWithoutRatesArgs: SearchHotelsWithoutRatesArgs!) {
    searchHotelsWithoutRates(searchHotelsWithoutRatesArgs: $searchHotelsWithoutRatesArgs) {
      hotelSearchResults {
        id
        amenities
        customerReviewScore
        description
        googlePlaceIds
        hotelAddress {
          city
          latitude
          longitude
          state
          stateAbbreviation
          streetAddress
        }
        images
        isFavorited
        isCollected
        name
        starRating
        totalCustomerReviews
      }
    }
  }
`)

export const Hotels = () => {
  const [searchParams] = useSearchParams()
  const location = searchParams.get('location')
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  // Format the dates to "YYYY-MM-DD" format
  const arrivalDate = dayjs().format('YYYY-MM-DD')
  const departureDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
  const viewMoreUrl = `${hotelResultsPath}?arrival=${arrivalDate}&departure=${departureDate}&location=${encodeURIComponent(location)}&latitude=${latitude}&longitude=${longitude}&sort=RECOMMENDED`

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(GET_HOTELS_WITHOUT_RATES, {
    variables: {
      searchHotelsWithoutRatesArgs: {
        latitude: Number(latitude),
        longitude: Number(longitude),
      },
    },
  })
  const { hotelSearchResults } = data?.searchHotelsWithoutRates ?? {}
  const resultsData = hotelSearchResults?.slice(0, 4)

  const locationString = location.split(',')
  const locationTrimmed = locationString[0]

  const onCardClick = (
    hotelResultsCardData: GetHotelResultsCardUrl['hotelResultsCardData']
  ) =>
    window.open(getHotelResultsCardUrl({ hotelResultsCardData, searchParams }))

  const hasNoResults = !resultsData?.length && !isLoading && !hasError
  const hasResults = !!resultsData?.length && !isLoading && !hasError

  if (hasNoResults) return null

  return (
    <PageLayoutContainer>
      <section className='space-y-6'>
        {isLoading && (
          <div className='grid gap-5 md:grid-cols-2 lg:grid-cols-4'>
            <HotelResultsCardLoading />
            <HotelResultsCardLoading />
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
            <div className='max-lg:hidden'>
              <HotelResultsCardLoading />
            </div>
          </div>
        )}
        {hasError && (
          <>
            <p>Oops! There was a problem.</p>
          </>
        )}
        {hasResults && (
          <>
            <header className='flex flex-col items-center space-y-6 text-center'>
              <h6 className='c-grey-800 type-h6 uppercase'>
                {locationTrimmed} Hotels
              </h6>
              <div className='border-3px border-valley w-26 border-solid'></div>{' '}
              <h2 className='type-h1'>Unwind in Style</h2>
            </header>
            <div className='flex flex-col items-center gap-4 md:flex-row md:justify-center'>
              {resultsData?.map(resultData => (
                <HotelResultsCard
                  key={resultData.id}
                  className='h-96 w-2/3 bg-transparent shadow-none md:w-1/2 lg:w-1/3 lg:group-has-[>:nth-child(4)]:w-1/4 [&:nth-of-type(n+3)]:hidden lg:[&:nth-of-type(n+3)]:block'
                  hotelResultsCardData={{
                    ...resultData,
                    nightlyAverage: undefined,
                  }}
                  onClick={() => onCardClick(resultData)}
                />
              ))}
            </div>
            <section className='flex flex-row justify-center'>
              <Link href={viewMoreUrl} label='View More' target='_blank' />
            </section>
          </>
        )}
      </section>
    </PageLayoutContainer>
  )
}
