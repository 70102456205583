import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const REMOVE_GUIDE_FROM_COLLECTION = gql(`
    mutation RemoveGuideFromCollection($input: DeleteCollectedGuideInput!, $itemId: String){
        deleteCollectedGuide(input: $input){
            collectedGuide{
                guide{
                    id
                    collectedCount
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: GUIDE)
                    id
                    name
                }
            }
        }
    }    
`)

export const useRemoveGuideFromCollection = () =>
  useMutation(REMOVE_GUIDE_FROM_COLLECTION)
