import type { ExperienceStartEndPoint } from 'src/__generated__/graphql'
import { Address } from './Address'
import { toAddressPoints } from './util/toAddressPoints'

export const MeetingPoints = ({
  start,
  end,
}: {
  start: ExperienceStartEndPoint[]
  end: ExperienceStartEndPoint[]
}) => {
  const endPoints = toAddressPoints(end)
  const startPoints = toAddressPoints(start)
  const hasSingleEndPoint = end.length === 1
  const isSameStartAndEnd = start?.every(
    startPoint =>
      end?.some(
        endPoint =>
          startPoint.location?.reference === endPoint.location?.reference
      ) ?? false
  )

  let endContent

  if (isSameStartAndEnd) {
    endContent = (
      <p className='color-grey-800 type-body-1'>
        This activity ends back at the meeting point.
      </p>
    )
  } else if (hasSingleEndPoint) {
    const FIRST = 0
    const title = end[FIRST].location.name
    const address = endPoints[FIRST]
    endContent = !!address && !!title && (
      <Address
        address={endPoints[0]}
        description={end[0].description}
        name={end[0].location.name}
      />
    )
  }

  if (!(end.length && start.length)) return null

  return (
    <>
      {start[0] && (
        <>
          <h3 className='color-grey-800 type-body-1-medium'>Meeting Point</h3>
          <Address
            address={startPoints[0]}
            description={start[0].description}
            name={start[0].location?.name}
          />
        </>
      )}
      {endContent && (
        <>
          <h3 className='color-grey-800 type-body-1-medium'>
            End Point{hasSingleEndPoint ? '' : 's'}
          </h3>
          {endContent}
        </>
      )}
    </>
  )
}
