import { Skeleton } from '@travelpass/design-system'

export const TravelerCardLoading = ({ className }: { className?: string }) => (
  <div
    className={`rounded-8px border-width-1px border-grey400 rounded-4px min-h-388px relative flex h-full flex-col gap-y-3 border-solid bg-white pt-8 md:gap-y-2 md:pt-4 ${className}`}
  >
    <div className='h-22 mx-auto w-1/2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='min-h-35 h-35 max-h-35 min-w-35 w-35 max-w-35 mx-auto my-5'>
      <Skeleton variant='circular' />
    </div>
    <div className='h-13 px-5'>
      <Skeleton variant='rounded' />
    </div>
    <div className='h-77px mx-auto mb-6 w-1/2'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
