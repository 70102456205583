import type { MouseEvent } from 'react'
import { Link, Modal } from '@travelpass/design-system'

interface BookingSendSmsModalProps {
  onClose(): void
  onPrivacyModalOpen(): void
  onTermsModalOpen(): void
}

export const BookingSendSmsModal = ({
  onClose,
  onPrivacyModalOpen,
  onTermsModalOpen,
}: BookingSendSmsModalProps) => {
  const onPrivacyClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onPrivacyModalOpen()
  }

  const onTermsClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onTermsModalOpen()
  }

  return (
    <Modal size='medium' onDismiss={onClose}>
      <div className='type-body-1 c-grey-800'>
        Upon checking this box you are consenting to a recurring program to
        receive SMS message where rates may apply for texts sent. This opt-in is
        separate from making your purchase. By choosing this option you agree
        that you have read and accept our{' '}
        <Link label='SMS terms and conditions' onClick={onTermsClick} /> and{' '}
        <Link label='privacy policy' onClick={onPrivacyClick} />.
      </div>
    </Modal>
  )
}
