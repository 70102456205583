import type { CreateTripInput } from 'src/__generated__/graphql'
import type { TripFormValues } from 'src/pages/trips/constants'
import { constructAddressInput } from 'src/pages/trips/utils'
import { formatDate } from 'src/utils'

const generateCreateTripInput = ({
  address,
  dates,
  name,
}: TripFormValues): CreateTripInput => {
  const formattedAddress = constructAddressInput(address)

  return {
    name: name,
    tripStart: formatDate(dates?.[0], 'YYYY-MM-DD'),
    tripEnd: formatDate(dates?.[1], 'YYYY-MM-DD'),
    tripPreference: {
      addresses: [formattedAddress],
    },
  }
}

export { generateCreateTripInput }
