import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const CREATE_HOTEL_BOOKING = gql(`
  mutation CreateHotelBooking($input: CreateHotelBookingInput!) {
    createHotelBooking(input: $input) {
      booking {
        email
        externalConfirmationId
        arrival
        departure
        id
        bookingDetails {
          price {
            allInTotal {
              amount
              currency
            }
            grandtotal {
              amount
              currency
            }
            predictedCommission {
              amount
              currency
            }
            predictedCommissionRate
            serviceFee {
              amount {
                amount
                currency
              }
              type
            }
            subtotal {
              amount
              currency
            }
            surchargeTotal {
              amount
              currency
            }
          }
        }
      }
      trip {
        id
        events {
          id
          booking {
            id
            externalConfirmationId
          }
        }
      }
    }
  }
`)

export const useCreateHotelBookingMutation = () =>
  useMutation(CREATE_HOTEL_BOOKING)
