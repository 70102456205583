import { useEffect, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import { BookingTimeState } from 'src/__generated__/graphql'
import { logError } from 'src/utils'
import { HotelBookingsList } from './HotelBookingsList'
import { currentUserHotelBookingsGQL } from './hotelBookingsGql'
import {
  BookingSection,
  BookingsEmpty,
  BookingsLoading,
} from '../common/components'
import { cardsPerPage } from '../common/constants'

interface HotelBookingsApolloProps {
  bookingStatus: BookingTimeState
}

export const HotelBookingsApollo = ({
  bookingStatus,
}: HotelBookingsApolloProps) => {
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const variables = {
    first: cardsPerPage,
    searchUserHotelBookingsArgs: {
      bookingTimeState: bookingStatus,
    },
  }

  const { data, error, loading, networkStatus, fetchMore } = useQuery(
    currentUserHotelBookingsGQL,
    { variables, notifyOnNetworkStatusChange: true }
  )

  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore

  const { edges, pageInfo, totalCount } = data?.currentUser?.hotelBookings ?? {}

  const noContent = !!error || isEmpty(edges)

  const onGetMoreResults = async () => {
    if (hasMoreResults) {
      await fetchMore({
        variables: {
          ...variables,
          after: pageInfo?.endCursor,
        },
        updateQuery: (previousResults, { fetchMoreResult }) => {
          const updatedResults = cloneDeep(fetchMoreResult)

          const { edges: previousResultsData } =
            previousResults?.currentUser?.hotelBookings ?? {}
          const { pageInfo, edges: currentResultsData } =
            fetchMoreResult?.currentUser?.hotelBookings ?? {}

          setHasMoreResults(pageInfo?.hasNextPage)

          const updatedResultsData =
            previousResultsData?.concat(currentResultsData) ?? []

          updatedResults.currentUser.hotelBookings.edges = updatedResultsData

          return updatedResults
        },
      }).catch(error => logError(error))
    } else {
      setHasMoreResults(false)
    }
  }

  const renderContent = () => {
    if (loading && !isMoreResultsLoading) {
      const isCancelled = bookingStatus === BookingTimeState.Cancelled
      return <BookingsLoading cancelled={isCancelled} />
    }
    if (noContent) {
      return <BookingsEmpty />
    }

    return (
      <>
        <HotelBookingsList bookings={edges} />
        {isMoreResultsLoading && <BookingsLoading />}
      </>
    )
  }

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      setHasMoreResults(true)
    } else {
      setHasMoreResults(false)
    }
  }, [pageInfo])

  return (
    <BookingSection
      contentLength={totalCount}
      hasMoreResults={!noContent && hasMoreResults}
      isLoading={loading}
      isMoreResultsLoading={isMoreResultsLoading}
      subtitle='hotel'
      title={bookingStatus}
      onGetMoreResults={onGetMoreResults}
    >
      {renderContent()}
    </BookingSection>
  )
}
