import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Carousel, SkeletonDots } from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import { useIsElementOnScreen } from 'src/common/hooks'
import { TravelerCard } from '../TravelerCard'

const CURATED_USER_PROFILES = gql(`
    query CuratedUserProfiles {
      curatedUserProfiles(type: HOME){
        id
        isUserOwner
        userId
        displayName
        profileImageUrl
        accountHandle
        guideCount
        bio
        isFollowed
        followersConnection(first: 1, timeRange: 0){
          totalCount
        }
        followingConnection(first: 1, timeRange: 0){
          totalCount
        }
      }
    }
  `)

export const CuratedUserProfiles = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isIntercepting = useIsElementOnScreen(ref, {})
  const [getProfilesData, { data, loading }] = useLazyQuery(
    CURATED_USER_PROFILES
  )
  const profiles = data?.curatedUserProfiles
  const profilesAvailable = !!profiles?.length

  useEffect(() => {
    if (isIntercepting) getProfilesData()
  }, [isIntercepting])

  return (
    <>
      <div className='lg:h-90 md:max-w-300 block min-h-10 md:mx-auto' ref={ref}>
        {loading && <SkeletonDots />}
        {profilesAvailable && (
          <>
            <div className='grid hidden h-full w-full gap-12 px-6 md:grid-cols-2 md:gap-6 md:px-12 lg:grid lg:grid-cols-3'>
              {profiles.slice(0, 3).map(profile => (
                <TravelerCard key={profile.id} traveler={profile} />
              ))}
            </div>
            <div className='lg:hidden'>
              <Carousel
                isLight
                borderRadius={12}
                hideArrows={true}
                keyBoardControl={true}
                size='small'
                slidesPerViewOnDesktop={2}
                slidesPerViewOnMobile={1}
                slidesPerViewOnTablet={2}
              >
                {profiles.map(profile => (
                  <div
                    key={profile.id}
                    className='mx-auto mb-8 max-w-[calc(100%-24px)]'
                  >
                    <TravelerCard key={profile.id} traveler={profile} />
                  </div>
                ))}
              </Carousel>
            </div>
          </>
        )}
      </div>
    </>
  )
}
