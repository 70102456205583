import { useEffect, useState } from 'react'
import { useScreenQuery } from '@travelpass/design-system'
import { HotelRoomCard } from '../HotelRoomCard'
import { HotelRoomDetails } from '../HotelRoomDetails'
import type { HotelRooms as HotelRoomsType } from '../hotelRoomsTypes'

interface HotelRoomsListProps {
  hotelRoomsData: HotelRoomsType
}

export const HotelRoomsList = ({ hotelRoomsData }: HotelRoomsListProps) => {
  const { isDesktopScreen } = useScreenQuery()
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(0)

  useEffect(() => {
    if (selectedRoomIndex === -1) setSelectedRoomIndex(0)
  }, [isDesktopScreen])

  const handleRoomSelection = (index: number, selectOnly: boolean) => {
    if (selectOnly && selectedRoomIndex === index) return
    if (!selectOnly && selectedRoomIndex === index)
      return setSelectedRoomIndex(-1)
    setSelectedRoomIndex(index)
  }

  return (
    <div className='grid grid-cols-1 gap-10 lg:max-w-[1200px] lg:grid-cols-2'>
      <div className='rd-2 lg:b-solid border-1 border-color-grey-200 flex flex-col gap-6 lg:max-h-[840px] lg:overflow-auto lg:px-6 lg:py-3'>
        {hotelRoomsData.map((room, index) => {
          return (
            <HotelRoomCard
              key={`room-${index}`}
              handleRoomSelection={handleRoomSelection}
              isSelected={selectedRoomIndex === index}
              room={room}
              roomIndex={index}
            />
          )
        })}
      </div>
      {hotelRoomsData[selectedRoomIndex] && (
        <div className='hidden lg:block'>
          <HotelRoomDetails room={hotelRoomsData[selectedRoomIndex]} />
        </div>
      )}
    </div>
  )
}
