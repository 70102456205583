import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { guideDraftPublishedEventFragment } from './useGetGuideDraftQuery'

const updatePublishedSortOrderMutation = gql(`
  mutation UpdatePublishedEventSortOrderMutationInGuideDraftOld($first: Int = 100, $input: UpdatePublishedEventSortOrderInput!) {  
    updatePublishedEventSortOrder(input: $input) {
      publishedEvent {
        id
        guideDraft {
          id
          guide {
            id
            status
          }
          hasUnpublishedChanges
          paginatedEventCategories(first: 100) {
            edges {
              node {
                id
                description
                name
                publishedEvents(first: $first) {
                  edges {
                    node {
                      ...GuideDraftPublishedEventFieldsOld
                    }
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                  }
                  totalCount
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
          }
          uncategorizedPublishedEvents(first: $first) {
            edges {
              node {
                ...GuideDraftPublishedEventFieldsOld
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
          }
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventSortOrderMutation = () =>
  useMutation(updatePublishedSortOrderMutation)
