import { useMutation } from '@apollo/client'
import { useSnackbar } from '@travelpass/design-system'
import { useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { logError } from 'src/utils'
import { getTripEventsQuery } from './useGetTripEventsQuery'
import { pushTripEventDeleteToDataLayer } from '../utils'

const deleteTripEventGQL = gql(`
  mutation DeleteTripEvent($eventInput: DeleteEventInput!) {
    deleteEvent(eventInput: $eventInput) {
      id
      endDate
      isDeleted
      name
      startDate
      status
      trip {
        id
        name
      }
      type
    }
  }
`)

export const useDeleteEvent = () => {
  const [searchParams] = useSearchParams()
  const { tripId } = useParams()
  const [deleteEventMutation, { loading, error, data }] = useMutation(
    deleteTripEventGQL,
    {
      refetchQueries: [
        {
          query: getTripEventsQuery,
          variables: {
            tripId: tripId,
          },
        },
      ],
    }
  )

  const { addErrorSnack, addMailSnack, addSuccessSnack } = useSnackbar()

  const deleteEvent = async ({
    eventId,
    triggerVariant,
  }: {
    eventId: string
    triggerVariant: string
  }) => {
    addMailSnack({ title: 'Deleting event' })
    try {
      const response = await deleteEventMutation({
        variables: { eventInput: { id: eventId } },
      })
      addSuccessSnack({ title: 'Event deleted' })
      const {
        deleteEvent: {
          name: eventName,
          startDate,
          endDate,
          status: bookedStatus,
          type: eventType,
          trip: { name: tripName },
        },
      } = response?.data ?? {}
      pushTripEventDeleteToDataLayer({
        itemCategory: eventType,
        itemId: eventId,
        itemName: eventName,
        itemEndDate: endDate,
        itemStartDate: startDate,
        itemStatus: bookedStatus,
        triggerVariant,
        tripId,
        tripName,
      })
    } catch {
      logError(error)
      addErrorSnack({ title: 'Error deleting event' })
    }
  }
  return {
    deleteEvent,
    loading,
    error,
    data,
  }
}
