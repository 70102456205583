import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query GetPlaceDetailsImagesLazyQueryInGuides($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      imageLinks
    }
  }
`)

export const useGetGuidePlaceDetailsImagesLazyQuery = () =>
  useLazyQuery(getPlaceDetailsQuery)
