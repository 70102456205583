import { Divider, Skeleton } from '@travelpass/design-system'
import { GuideHeaderOldActionsLoading } from './GuideHeaderOldActionsLoading'

export const GuideHeaderOldSummaryLoading = () => (
  <div>
    <Divider />
    <div className='md:p-l-4 p-y-1 flex flex-row flex-wrap gap-x-3 gap-y-1 md:items-center'>
      <div className="md:after:[&>div]:w-1px flex grow flex-row items-center gap-3 md:after:[&>div:last-child]:hidden md:after:[&>div]:h-3 md:after:[&>div]:bg-black md:after:[&>div]:content-['']">
        <div className='flex flex-row items-center gap-3'>
          <div className='h-4 w-40'>
            <Skeleton />
          </div>
        </div>
        <div className='hidden flex-row items-center gap-3 md:flex'>
          <div className='h-4 w-32'>
            <Skeleton />
          </div>
        </div>
        <div className='hidden flex-row items-center gap-3 md:flex'>
          <div className='w-15 h-4'>
            <Skeleton />
          </div>
        </div>
      </div>
      <GuideHeaderOldActionsLoading />
    </div>
    <Divider />
  </div>
)
