import type { MouseEvent, MouseEventHandler } from 'react'
import classNames from 'classnames'

export const FooterLink = ({
  hideUnderline = false,
  label,
  onClick,
}: {
  hideUnderline?: boolean
  label: string
  onClick?(event: MouseEvent): void
}) => {
  const onButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    onClick?.(event)
    event.currentTarget.blur()
  }

  return (
    <button
      className={footerLinkCss({ hideUnderline })}
      type='button'
      onClick={onButtonClick}
    >
      {label && <span>{label}</span>}
    </button>
  )
}

const footerLinkCss = ({ hideUnderline }) =>
  classNames(
    'type-body-1 bg-none bg-transparent border-none text-inherit pointer p-0 text-left hover:c-valley active:c-valley transition-colors',
    {
      underline: !hideUnderline,
      'no-underline': hideUnderline,
    }
  )
