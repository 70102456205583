export enum SEARCH_BAR_STATES {
  open = 'OPEN',
  closed = 'CLOSED',
}

export enum SEARCH_BAR_OPTIONS {
  guides = 'GUIDES',
  travelers = 'TRAVELERS',
  hotels = 'HOTELS',
  experiences = 'EXPERIENCES',
  destinations = 'DESTINATIONS',
}

export const assetOptions = Object.keys(SEARCH_BAR_OPTIONS).map(key => ({
  label: key,
  value: SEARCH_BAR_OPTIONS[key],
}))

export const TRAVELERS_RESULTS_QUERY_PREFIX = 'travelers-results-query-'
