import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createAnonymousWebsiteReviewMutation = gql(`
  mutation CreateAnonymousWebsiteReviewMutationInBookingConfirmation($createWebsiteReviewInput: CreateWebsiteReviewInput!) {
    createAnonymousWebsiteReview(createWebsiteReviewInput: $createWebsiteReviewInput) {
      id,
    }
  }
`)

export const useCreateAnonymousWebsiteReviewMutation = () =>
  useMutation(createAnonymousWebsiteReviewMutation)
