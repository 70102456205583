import { TripDetailsMapPointsStoreProvider } from 'src/pages/trips/components'
import { Timeline } from './components'

export const TripTimeline = () => {
  return (
    <TripDetailsMapPointsStoreProvider>
      <Timeline />
    </TripDetailsMapPointsStoreProvider>
  )
}
