import { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  Divider,
  DrawerActions,
  DrawerNew,
  DrawerScrollContents,
} from '@travelpass/design-system'
import classNames from 'classnames'
import Slider from 'rc-slider'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { explorePath } from 'src/constants'
import { CreatedByFilter } from './CreatedByFilter'
import { KeywordsFilter } from './KeywordsFilter'
import { LocationSearchBar } from './LocationSearchBar'
import { TagsFilter } from './TagsFilter'
import { BOUNDING_BOX_PARAMS } from '../../bounding-box/constants/boundingBoxParams'
import { MILES_DEFAULT_VALUE } from '../../constants'
import { COUNTRY_STATE_PARAMS } from '../../country-state/constants/COUNTRY_STATE_PARAMS'
import type {
  ExploreFiltersType,
  ExploreFilterUpdateFunction,
} from '../../exploreTypes'
import { KEYWORD_SEARCH_PHRASE } from '../../keyword-search/constants'
import { getExploreFiltersFromURL } from '../../utils/exploreUtils'
import { SortBy } from '../SortBy'

interface ExploreFiltersProps {
  isOpen: boolean
  onFiltersApply: Function
  onDismiss(): void
}

export const ExploreFilters = ({
  isOpen,
  onDismiss,
  onFiltersApply,
}: ExploreFiltersProps) => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const filtersParams = getExploreFiltersFromURL(searchParams)
  const { accountHandles, tags, miles, locationTitle, lat, lng } = filtersParams

  const isLocationSearch =
    pathname.includes('/bounding-box') || pathname.includes('/country-state')
  const isKeywordSearch = pathname.includes('/keyword')

  const emptyFilters = {
    createdBy: [],
    tags: [],
    location: undefined,
    miles: undefined,
  }

  const profiles = accountHandles.map(accountHandle => ({
    accountHandle,
    displayName: accountHandle,
  }))

  const initialFilters: ExploreFiltersType = {
    createdBy: [...profiles],
    tags: [...tags],
    location:
      !!lat && !!lng && !!locationTitle
        ? {
            lat,
            lng,
            title: locationTitle,
            location: locationTitle,
          }
        : undefined,
    miles: miles,
  }
  const [filters, setFilters] = useState<ExploreFiltersType>(initialFilters)
  const updateFilters: ExploreFilterUpdateFunction = (key, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [key]: value }))
  }

  const onViewResults = (filters: ExploreFiltersType) => {
    const { location, createdBy, tags, miles, keyword } = { ...filters }
    setSearchParams(sp => {
      if (keyword) {
        sp.set(KEYWORD_SEARCH_PHRASE, keyword)
        sp.set('title', keyword)

        // because involves route changing
        sp.delete(COUNTRY_STATE_PARAMS.country)
        sp.delete(COUNTRY_STATE_PARAMS.state)
        sp.delete('location')
        sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
        sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
        sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
        sp.delete(BOUNDING_BOX_PARAMS.sw.lng)
      }

      if (location) {
        // sp.set('title', location.location)
        sp.set('lat', String(location.lat))
        sp.set('lng', String(location.lng))
        sp.set('locationTitle', String(location.title))
      }
      if (!location) {
        sp.delete('lat')
        sp.delete('lng')
        sp.delete('locationTitle')
      }

      if (miles) sp.set('miles', String(miles))
      if (!miles) sp.delete('miles')

      if (createdBy?.length > 0)
        sp.set(
          'createdBy',
          createdBy.map(({ accountHandle }) => accountHandle).toString()
        )
      if (createdBy?.length <= 0) sp.delete('createdBy')

      if (tags?.length > 0) sp.set('tags', tags.toString())
      if (tags?.length <= 0) sp.delete('tags')

      return sp
    })

    if (keyword && !pathname.includes('/keyword'))
      navigate({
        pathname: `${explorePath}/keyword`,
        search: searchParams.toString(),
      })

    onFiltersApply()
  }

  useEffect(() => {
    if (!filters?.miles && filters?.location?.lat && filters?.location?.lng)
      updateFilters('miles', MILES_DEFAULT_VALUE)
  }, [filters])

  useEffect(() => {
    if (!miles && lat && lng) {
      setSearchParams(sp => {
        sp.set('miles', String(MILES_DEFAULT_VALUE))
        return sp
      })
      updateFilters('miles', MILES_DEFAULT_VALUE)
    }
  }, [miles, lat, lng])

  useEffect(() => setFilters(initialFilters), [searchParams])

  return (
    <DrawerNew isOpen={isOpen} placement='left' onDismiss={onDismiss}>
      <DrawerScrollContents title='Filter & Sort'>
        <div className='space-y-5'>
          <div className='lg:hidden'>
            <SortBy variant='text' />
          </div>
          <Divider />
          {isKeywordSearch && (
            <>
              <h4 className='type-h5'>Location</h4>
              <LocationSearchBar
                clearOnSelection
                placeholder='Search By City or ZIP'
                onSelection={location => updateFilters('location', location)}
              />
              {filters?.location && (
                <Chip
                  includeCloseIcon
                  isSelected={false}
                  label={
                    (typeof filters?.location === 'string'
                      ? filters?.location
                      : filters?.location?.title) || locationTitle
                  }
                  onClick={() => updateFilters('location', null)}
                />
              )}
              <p className='c-black text-[15px]'>
                Within {filters?.miles || '_'} Miles
              </p>
              <Slider
                className={classNames(
                  'z0 select-none',
                  '[&>.rc-slider-handle]:bg-forest-light [&>.rc-slider-handle]:b-none [&>.rc-slider-handle]:mt--4 [&>.rc-slider-handle]:op-100 [&>.rc-slider-handle]:shadow-1 [&>.rc-slider-handle]:transition-background-color-100 [&>.rc-slider-handle]:h-8 [&>.rc-slider-handle]:w-8 [&>.rc-slider-handle]:ease-linear',
                  '[&>.rc-slider-handle.rc-slider-handle-dragging]:b-none [&>.rc-slider-handle.rc-slider-handle-dragging]:bg-forest [&>.rc-slider-handle.rc-slider-handle-dragging]:shadow-1',
                  '[&>.rc-slider-rail]:bg-grey-400 [&>.rc-slider-rail]:rounded-0 [&>.rc-slider-rail]:h-0.5',
                  '[&>.rc-slider-track]:bg-forest [&>.rc-slider-track]:b-forest [&>.rc-slider-track]:h-0.5'
                )}
                disabled={!filters?.location?.lat && !filters?.location?.lng}
                max={250}
                min={0}
                value={filters?.miles}
                onChange={(value: number) => updateFilters('miles', value)}
              />
              <Divider />
            </>
          )}
          {isLocationSearch && (
            <KeywordsFilter
              keyword={filters?.keyword}
              onSelection={updateFilters}
            />
          )}
          <CreatedByFilter
            travelers={filters?.createdBy}
            onSelection={updateFilters}
          />
          <TagsFilter tags={filters?.tags} onSelection={updateFilters} />
        </div>
      </DrawerScrollContents>
      <DrawerActions>
        <Button
          label='Clear All'
          size='small'
          variant='outlined'
          onClick={() => {
            setFilters(emptyFilters)
            onViewResults(emptyFilters)
          }}
        />
        <Button
          label='View Results'
          size='small'
          type='button'
          onClick={() => onViewResults(filters)}
        />
      </DrawerActions>
    </DrawerNew>
  )
}
