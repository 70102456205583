import { PageLayoutContainer } from 'src/common/components'
import { dispatch } from 'src/common/hooks/useListen'

const bgImg =
  'https://static.travelpass.com/assets/homev2-page/travelers-bg-desktop.webp'

export const AwayGameSignUp = () => {
  const openSignInModal = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
    })
  }
  return (
    <section className='bg-warm-grey py-12'>
      <PageLayoutContainer>
        <h2 className='text-8 md:text-10 font-900 m-0 mb-2 text-center text-black'>
          Get Started With{' '}
          <span className='from-valley to-newForest bg-gradient-to-r bg-clip-text text-transparent'>
            Travelpass
          </span>
        </h2>
        <p className='text-4 md:text-5 c-grey-800 font-400 m-y-0 m-b-8 text-balance text-center'>
          Create a free account to join a community that&apos;s traveled to all
          50 states, 121 countries, 6 continents, and counting.
        </p>
        <button
          className='of-hidden rounded-2 c-forest font-700 text-4 bg-valley border-valley-dark/20 mx-a mt-10 block w-auto appearance-none border-2 border-solid px-6 py-3 text-center font-sans outline-none transition-shadow hover:cursor-pointer hover:shadow-lg'
          onClick={openSignInModal}
        >
          Create Your Travelpass Account
        </button>
      </PageLayoutContainer>
    </section>
  )
}
