import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { SectionHeader } from 'src/common/components'
import { useIsElementOnScreen } from 'src/common/hooks'
import { useGetStaticMapLazy } from './useGetStaticMapLazy'

export const StaticMap = () => {
  const { experienceId } = useParams()
  const containerRef = useRef(null)
  const isVisible = useIsElementOnScreen(containerRef)
  const [getStaticMapUrl, { called, error, loading, data }] =
    useGetStaticMapLazy()
  useEffect(() => {
    if (called || loading || !experienceId || !isVisible) return

    getStaticMapUrl({
      variables: { nodeId: experienceId, height: 640, width: 400, zoom: 13 },
    })
  }, [getStaticMapUrl, called, loading, data, experienceId, isVisible])

  const mapSrc =
    data?.node?.__typename === 'ExperienceProduct'
      ? data?.node?.staticMap
      : undefined
  if (error || loading) return <></>

  return (
    <div className='flex flex-col gap-8' ref={containerRef}>
      <SectionHeader title='Location'></SectionHeader>
      <img
        alt='Google Static Map'
        className='rounded-5 w-85 h-61 md:w-160 md:h-100 mx-auto object-cover'
        loading='lazy'
        src={mapSrc}
      />
    </div>
  )
}
