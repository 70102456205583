import { Link } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { profilePath } from 'src/constants'
import type { FollowersModalType, ProfileFields } from '../types'

interface FollowersSectionProps {
  profile: ProfileFields
  toggleModal: (viewType: FollowersModalType) => void
}

export const FollowersSection = ({
  profile,
  toggleModal,
}: FollowersSectionProps) => {
  const { followingUsers } = profile ?? {}
  const hasFollowers = !isEmpty(followingUsers)
  const remainingFollowersCount = Math.max(followingUsers?.length - 3, 0) ?? 0

  const followersList = followingUsers
    ?.slice(0, 3)
    .filter(i => !!i)
    .map(({ accountHandle }, index) => (
      <span key={accountHandle} className='type-link pr-1 leading-snug'>
        <Link
          href={`${profilePath}/${accountHandle}`}
          showUnderlineOnlyOnHover={true}
        >
          {`@${accountHandle}`}
        </Link>
        {index >= followingUsers?.slice(0, 3).length - 1 ? '' : ', '}
      </span>
    ))

  return (
    <>
      {hasFollowers && (
        <p className='my-0 flex flex-wrap items-center justify-center'>
          <span className='type-body-1 pr-1'>Followed by</span>
          {followersList}
          {remainingFollowersCount > 0 && (
            <span className='type-body-1 pr-1 leading-snug'>
              <Link
                showUnderlineOnlyOnHover={true}
                onClick={() => toggleModal('followers')}
              >
                {`+ ${remainingFollowersCount.toString()} more`}
              </Link>
            </span>
          )}
        </p>
      )}
    </>
  )
}
