import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const searchHotelsQuery = gql(`
  query SearchHotelsQueryInTripsDetailsExploreHotels($searchHotelsWithoutRatesArgs: SearchHotelsWithoutRatesArgs!) {
    searchHotelsWithoutRates(searchHotelsWithoutRatesArgs: $searchHotelsWithoutRatesArgs) {
      hotelSearchResults {
        id
        amenities
        customerReviewScore
        description
        googlePlaceIds
        hotelAddress {
          city
          latitude
          longitude
          state
          stateAbbreviation
          streetAddress
        }
        images
        isFavorited
        name
        starRating
        totalCustomerReviews
      }
    }
  }
`)

export const useTripsDetailsExploreHotels = () =>
  useLazyQuery(searchHotelsQuery)
