import { Button, Carousel } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
} from 'src/utils'
import { constructLocations } from './ghostTourConstants'

export const GhostTourLocations = () => {
  const navigate = useNavigate()

  const onGhostToursClick = ({ loc, lat, long }) => {
    const url = generateExperiencesResultsUrl({
      location: loc,
      latitude: lat,
      longitude: long,
      filters: {
        tags: [12066],
      },
    })
    navigate(url)
  }

  const onHotelsClick = ({ loc, lat, long }) => {
    const url = generateHotelResultsUrl({
      location: loc,
      latitude: lat,
      longitude: long,
    })
    navigate(url)
  }

  const constructedLocations = () =>
    constructLocations.map(({ image, city, state, description, urlData }) => (
      <div key={city} className='p2'>
        <div className={cardContainer}>
          <div className={cardContainerInner}>
            <div className={imageContainer}>
              <img alt={city} className={cityImage} src={image} />
            </div>
            <div className={titleContainer}>
              <h3 className={titleStyle}>
                {city}, {state}
              </h3>
              <div className={divider}></div>
              <p className={descriptionStyle}>{description}</p>
              <div className='mt-10'>
                {urlData.map(({ latitude, longitude }) => (
                  <div key={city} className={buttonContainer}>
                    <Button
                      fullWidth
                      label={`${city} Ghost Tours`}
                      size='large'
                      onClick={() =>
                        onGhostToursClick({
                          loc: city,
                          lat: latitude,
                          long: longitude,
                        })
                      }
                    />

                    <Button
                      fullWidth
                      label={`Hotels in ${city}`}
                      size='large'
                      onClick={() =>
                        onHotelsClick({
                          loc: city,
                          lat: latitude,
                          long: longitude,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

  return (
    <Carousel
      isLight={true}
      size='medium'
      slidesPerViewOnDesktop={1}
      slidesPerViewOnMobile={1}
      slidesPerViewOnTablet={1}
    >
      {constructedLocations()}
    </Carousel>
  )
}

const cardContainer =
  'md:w-450px lg:w-full mx-a my-10 py-6 px-4 bg-white border-rounded-6 shadow-1 lg:my-0 lg:py-8 lg:px-6'
const cardContainerInner = 'flex flex-col lg:flex-row gap-6'
const imageContainer =
  'flex-auto w-full lg:w-40 h-200px lg:h-300px lg:h-400px shadow-lg border-rounded-4 overflow-hidden'
const cityImage = 'block object-cover w-full h-full saturate-30'
const titleContainer = 'flex-auto w-full lg:w-60'
const titleStyle = 'type-h3 my-3'
const divider = 'w-20 h-3px bg-valley'
const descriptionStyle = 'type-body-1 mt-3'
const buttonContainer = 'flex flex-col gap-3 justify-center items-center'
