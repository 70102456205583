const createImage = (url: string): Promise<CanvasImageSource> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

const convertUrlToFile = async (
  imageUrl: string,
  fileName = 'croppedImage.jpeg'
) => {
  try {
    // Fetch the blob from the URL
    const response = await fetch(imageUrl)
    if (!response.ok) throw new Error('Network response was not ok')
    const blob = await response.blob()

    // Create a File object from the blob
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: new Date().getTime(),
    })

    return file
  } catch (error) {
    console.error('Error converting URL to file:', error)
    return null
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
const getCroppedImg = async (
  imageSrc,
  pixelCrop,
  flip = { horizontal: false, vertical: false }
) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) return null

  // Set dimensions of the canvas to the dimensions of the cropping area
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.save() // Save the current state
  ctx.beginPath() // Start a new path
  ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI) // Draw a circle
  ctx.clip() // Clip to the current path

  // Adjust for flipping
  if (flip.horizontal || flip.vertical) {
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(
      flip.horizontal ? -canvas.width : 0,
      flip.vertical ? -canvas.height : 0
    )
  }

  // Draw the image - ensuring it is centered in the cropped area
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  ctx.restore() // Restore the original state (without the clip)

  return new Promise(resolve => {
    canvas.toBlob(file => resolve(URL.createObjectURL(file)), 'image/jpeg')
  })
}

export { convertUrlToFile, getCroppedImg }
