import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { USER_PROFILE_FRAGMENT } from './fragments/userProfileFragment'

const upsertCurrentUserProfile = gql(`
  mutation UpsertCurrentUserProfile($input: UpsertProfileInput!) {
    upsertProfile(input: $input) {
      userProfile {
        ...UserProfileFields
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
      }
    }
  }
`)

export const useUpsertCurrentUserProfile = () =>
  useMutation(upsertCurrentUserProfile)
