import { Icon } from '@travelpass/design-system'
import type { AvailableIcons } from '@travelpass/design-system'

interface SummaryLineProps {
  children: React.ReactNode
  iconName: AvailableIcons
  className?: string
}

const SummaryLine = ({
  children,
  iconName,
  className = '',
}: SummaryLineProps) => {
  return (
    <p
      className={`text-body-1 color-grey-800 mb-2 flex items-center gap-2 pr-2 ${className}`}
    >
      <Icon name={iconName} />
      {children}
    </p>
  )
}

interface ExperienceDetailsSummaryFeaturesProps {
  travelers: string
  date: string
  languages: string[]
  hasMobileTicket?: boolean
}

export const ExperienceDetailsSummaryFeatures = ({
  travelers,
  date,
  languages,
  hasMobileTicket = false,
}: ExperienceDetailsSummaryFeaturesProps) => {
  return (
    <div className='mb-6 grid grid-flow-dense md:grid-cols-2'>
      <SummaryLine iconName='person'>{travelers}</SummaryLine>
      <SummaryLine iconName='calendar'>{date}</SummaryLine>
      {hasMobileTicket && (
        <SummaryLine iconName='smartPhone'>Mobile ticket</SummaryLine>
      )}
      {!!languages.length && (
        <SummaryLine iconName='language'>
          Offered in {languages.join(', ')}
        </SummaryLine>
      )}
    </div>
  )
}
