import { useMemo, useEffect } from 'react'
import { DropdownOption } from '@travelpass/design-system'
import type { ExperienceBookingQuestion } from 'src/__generated__/graphql'
import { BookingQuestionAnswer } from './BookingQuestionAnswer'
import { conditionalQuestionsMap } from './bookingQuestionUtil'
import {
  useWatch,
  BookingFormDropdown,
  useFormContext,
  rules,
} from './useExperienceBookingForm'

export const TransferDepartureModeFields = ({
  allowOther,
  question,
  conditionalFields,
}: {
  allowOther: boolean
  question: ExperienceBookingQuestion
  conditionalFields: ExperienceBookingQuestion[]
}) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('answers.transferDepartureMode.0.question', question.id)
  }, [setValue, question])

  const transferDepartureModeAnswer = useWatch({
    name: 'answers.transferDepartureMode.0.answer',
  })

  const conditionalAnswerFields = useMemo(() => {
    // TODO Remove this once we're sure we don't need it anymore
    // derive which questions are related to each answer to render as fields
    // const conditionalFieldsPerAnswer = question.allowedAnswers.map(answer => {
    //   let fieldIds =
    //     conditionalQuestionsMap.TRANSFER_DEPARTURE_MODE[answer] || []
    //   // isValid checks if all fieldIds exist in conditionalFields
    //   const questionFields = fieldIds
    //     .map(fieldId => conditionalFields.find(field => field.id === fieldId))
    //     .filter(Boolean)

    //   if (questionFields.length) {
    //     return [answer, questionFields]
    //   }

    //   return []
    // })

    const conditionalFieldsPerAnswer = question.allowedAnswers.map(answer => {
      let fieldIds = conditionalQuestionsMap.TRANSFER_DEPARTURE_MODE[answer]
      // isValid checks if all fieldIds exist in conditionalFields

      const isValid = fieldIds?.every(fieldId =>
        conditionalFields?.some(field => field.id === fieldId)
      )

      if (isValid) {
        const relatedFields = conditionalFields?.filter(field =>
          fieldIds?.includes(field.id)
        )

        return [answer, relatedFields]
      }

      return []
    })

    return Object.fromEntries(conditionalFieldsPerAnswer) // convert to object
  }, [question.allowedAnswers, conditionalFields])

  return (
    <div
      className='flex flex-col gap-3'
      id='transfer-departure-mode-conditional-fields'
    >
      <BookingFormDropdown
        defaultValue=''
        id='answers.transferDepartureMode.0.answer'
        label={question.label}
        name='answers.transferDepartureMode.0.answer'
        required={rules.required}
      >
        {question.allowedAnswers.map(answer => {
          if (conditionalAnswerFields[answer]) {
            return (
              <DropdownOption key={answer} value={answer}>
                {answer}
              </DropdownOption>
            )
          }
          // 'OTHER' should be an option for TRANSFER_DEPARTURE_MODE whenever 'OTHER' is an option for TRANSFER_ARRIVAL_MODE
          // https://linear.app/travelpass/issue/MAV-708/booking-questions-departure-mode-other
          if (answer === 'OTHER' && allowOther) {
            return (
              <DropdownOption key={answer} value={answer}>
                {answer}
              </DropdownOption>
            )
          }
        })}
      </BookingFormDropdown>
      {conditionalAnswerFields[transferDepartureModeAnswer]?.map(
        (question, questionIndex) => {
          const fieldIndex = questionIndex + 1
          return (
            <BookingQuestionAnswer
              key={question.id}
              field={`answers.transferDepartureMode.${fieldIndex}`}
              question={question}
            />
          )
        }
      )}
    </div>
  )
}
