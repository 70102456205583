import { useState } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { gql } from 'src/__generated__'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import { useExperiencePlacesData } from './useExperiencePlacesData'
import { useExperienceProductData } from './useExperienceProductData'
import { groupExperienceData } from '../utils'

const nodeQuery = gql(`
  query ExperienceEventData($eventId: ID!) {
    node(id: $eventId) {
      ... on Event {
        id
        addresses {
          id
          addressLine1
          addressLine2
          city
          country
          googlePlaceId
          lat
          long
          state
          zipcode
        }
        description
        experienceBooking {
          id
          bookingDetails {
            cancellationPolicy {
              type
            }
          }
          externalConfirmationId
          paxMix {
            ageBand
            numberOfTravelers
          }
          product {
              id
              productCode
            }
          voucherInfo {
            url
          }
        }
        imageUrl
        name
        notes
        productId
        startDate
        endDate
        status
        useEventTime
      }
    }
  }
`)

const defaultDataSources = {
  hasPlacesData: false,
  hasProductData: false,
}

export const useExperienceData = ({
  eventId,
  tripId,
}: {
  eventId: string | null
  tripId: string | null
}) => {
  const [dataSources, setDataSources] =
    useState<typeof defaultDataSources>(defaultDataSources)
  const {
    getExperiencePlacesData,
    data: placeData,
    loading: placeLoading,
    error: placeError,
  } = useExperiencePlacesData()
  const {
    getExperienceProductData,
    data: productData,
    loading: productLoading,
    error: productError,
  } = useExperienceProductData()
  const {
    hasError: tripError,
    isLoading: tripLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    data: eventNodeData,
    loading: eventLoading,
    error: eventError,
    refetch,
  } = useQuery(nodeQuery, {
    variables: { eventId: eventId ?? 'Failed to load eventId' },
    skip: !eventId,
    onCompleted: ({ node }) => {
      const eventNode = node?.__typename === 'Event' ? node : null

      const { addresses, experienceBooking, productId } = eventNode ?? {}

      const updatedDataSources: typeof defaultDataSources =
        cloneDeep(defaultDataSources)

      const placeId = addresses?.[0]?.googlePlaceId ?? null
      if (placeId) {
        updatedDataSources.hasPlacesData = true
        getExperiencePlacesData({
          variables: {
            placeDetailsRequest: {
              isHotelSearch: true,
              placeId,
            },
          },
        })
      }

      const productCode =
        productId ?? experienceBooking?.[0]?.product?.productCode
      if (productCode) {
        updatedDataSources.hasProductData = true
        getExperienceProductData({
          variables: { productCode },
        })
      }

      setDataSources(updatedDataSources)
    },
  })

  const loading = eventLoading || productLoading || placeLoading || tripLoading
  const error = eventError || productError || placeError || tripError

  const eventData =
    eventNodeData?.node?.__typename === 'Event' ? eventNodeData.node : null

  const data = loading
    ? null
    : groupExperienceData({
        eventData,
        productData: dataSources?.hasProductData ? productData : null,
        placeData: dataSources?.hasPlacesData ? placeData : null,
        tripData: tripDetailsData,
      })

  return {
    data,
    tripData: tripDetailsData,
    loading,
    error,
    refetch,
  }
}
