import type { ReactNode } from 'react'
import classNames from 'classnames'

// Removed a handful of props, bc A) not used, and B) outdated design system
export const Title = ({
  alignText = 'left',
  caption,
  subtitle,
  title,
}: {
  alignText?: 'left' | 'right' | 'center'
  caption: string
  subtitle?: ReactNode
  title: string
}) => (
  <div className={titleContainerCss}>
    <h6 className={captionCss(alignText)}>{caption}</h6>
    <h3 className={titleCss(alignText)}>{title}</h3>
    {subtitle && <div className={subtitleCss(alignText)}>{subtitle}</div>}
  </div>
)

const titleContainerCss = 'mb-14'

const captionCss = (alignText: 'left' | 'right' | 'center' | 'justify') =>
  classNames('mb-2 type-h6', {
    'text-left': alignText === 'left',
    'text-right': alignText === 'right',
    'text-center': alignText === 'center',
    'text-justify': alignText === 'justify',
  })

const titleCss = (alignText: 'left' | 'right' | 'center') =>
  classNames('type-h3', {
    'text-left': alignText === 'left',
    'text-right': alignText === 'right',
    'text-center': alignText === 'center',
  })

const subtitleCss = (alignText: 'left' | 'right' | 'center') =>
  classNames('flex flex-row mt-8 type-subtitle-2', {
    'text-left': alignText === 'left',
    'text-right': alignText === 'right',
    'text-center': alignText === 'center',
    'justify-start': alignText === 'left',
    'justify-end': alignText === 'right',
    'justify-center': alignText === 'center',
  })
