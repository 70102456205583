import type { CancellationPolicy as CancellationPolicyType } from 'src/__generated__/graphql'
import { SectionHeader } from 'src/common/components'

interface CancellationPolicyProps {
  description?: CancellationPolicyType['description']
}

export const CancellationPolicy = ({
  description,
}: CancellationPolicyProps) => {
  if (!description) return null

  return (
    <div className='space-y-12'>
      <SectionHeader title='Cancellation Policy' />
      <p className='color-grey-800 text-body-1'>{description}</p>
    </div>
  )
}
