import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Link, SkeletonDots } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer, Helmet } from 'src/common/components'
import { useIsElementOnScreen } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { baseUrl } from 'src/constants'
import { generateExperienceDetailsUrl } from 'src/utils'
import { AdditionalInfo } from './AdditionalInfo'
import { Availability } from './Availability'
import { AvailabilityOptionsModal } from './AvailabilityOptionsModal'
import { CancellationPolicy } from './CancellationPolicy'
import { Footer } from './Footer'
import { FromPrice } from './FromPrice'
import { Hero } from './Hero'
import { HeroLoading } from './Hero/HeroLoading'
import { InclusionsExclusions } from './InclusionsExclusions'
import { MeetingPoints } from './MeetingPoints'
import { Overview } from './Overview'
import { PickupPoints } from './PickupPoints'
import { StaticMap } from './StaticMap'
import { Time } from './Time'
import { Timeline } from './Timeline'
import { useExperienceProductDetails } from './useExperienceProductDetails'
import { checkExclusionsInclusions } from '../common/components'

const onClickSmoothScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  const id = e.currentTarget.getAttribute('href')
  const element = document.querySelector(id)
  element?.scrollIntoView({ behavior: 'smooth' })
}

export const ExperiencesDetails = () => {
  const { data: experience, loading, error } = useExperienceProductDetails()

  const inclusionDescriptions = experience?.inclusions
    ?.map(incl => incl.description) // @todo: filter out empty values on backend
    .filter(Boolean)

  const exclusionDescriptions = experience?.exclusions
    ?.map(excl => excl.description) // @todo: filter out empty values on backend
    .filter(Boolean)

  const hasWhatsIncluded =
    (inclusionDescriptions?.length ?? 0) +
      (exclusionDescriptions?.length ?? 0) >
    0

  const availabilityCardRef = useRef(null)
  const [, hasMounted] = useState(false)
  const setAvailabilityCardRef = useCallback(node => {
    availabilityCardRef.current = node
    // calculate mounted based on if the ref is set
    hasMounted(!!availabilityCardRef.current)
  }, [])
  const isElementOnScreen = useIsElementOnScreen(availabilityCardRef)

  const {
    additionalInfo,
    cancellationPolicy,
    exclusions,
    destinations,
    id,
    images,
    inclusions,
    itinerary,
    title,
  } = experience ?? {}
  const { destinationName, latitude, longitude } = destinations?.[0] ?? {}
  const [searchParams] = useSearchParams()
  const start_date = searchParams.get('arrival')
  const end_date = searchParams.get('departure')
  const hasExclusionsOrInclusions = checkExclusionsInclusions({
    exclusions,
    inclusions,
  })
  const canonicalUrl = baseUrl.concat(
    generateExperienceDetailsUrl({
      id,
      name: title,
    })?.split('?')[0]
  )

  useEffect(() => {
    if (!isEmpty(experience)) {
      pushDataToDataLayer('experiencePageVisited', {
        category_id: 'Experience',
        destination_name: destinationName,
        end_date,
        experience_image_links: {
          '240x160': images?.size240x160,
          '360x240': images?.size360x240,
          '540x360': images?.size540x360,
          '674x446': images?.size674x446,
        },
        item_list_name: title,
        latitude,
        longitude,
        start_date,
        title,
      })
    }
  }, [experience])

  if (loading)
    return (
      <div className='mt-6 space-y-8 text-center'>
        <HeroLoading />
        <SkeletonDots />
      </div>
    )

  if (error) return <></>

  const travelerPickup = experience?.logistics?.travelerPickup
  const season =
    experience?.availabilitySchedule?.bookableItems?.[0]?.seasons?.[0]

  if (experience)
    return (
      <>
        <Helmet canonicalUrl={canonicalUrl} pageName={experience?.title} />
        <div className='space-y-4 lg:space-y-8'>
          <Hero heroData={experience} />
          <PageLayoutContainer>
            <div
              className='grid gap-8 md:grid-cols-12'
              ref={setAvailabilityCardRef}
            >
              <section className='md:col-span-7' id='overview'>
                <Overview overviewData={experience} />
              </section>
              <section className='md:col-span-5' id='availability'>
                <Availability />
                <AvailabilityOptionsModal />
              </section>
            </div>
          </PageLayoutContainer>
          <div
            className={classNames(
              'border-b-1 border-b-solid border-grey-300 transition-ease-in z-3 sticky top-0 bg-white transition-opacity duration-200',
              isElementOnScreen ? 'invisible opacity-0' : ''
            )}
          >
            <PageLayoutContainer>
              <div className='flex justify-between gap-6 py-4 align-baseline'>
                <div className='children-[a]:text-center hidden items-center gap-8 overflow-x-auto md:flex'>
                  {hasWhatsIncluded && (
                    <Link
                      showUnderlineOnlyOnHover
                      href='#inclusions-exclusions'
                      onClick={onClickSmoothScroll}
                    >
                      What&apos;s Included
                    </Link>
                  )}
                  <Link
                    showUnderlineOnlyOnHover
                    href='#logistics'
                    onClick={onClickSmoothScroll}
                  >
                    Meeting and Pickup
                  </Link>
                  <Link
                    showUnderlineOnlyOnHover
                    href='#additional-info'
                    onClick={onClickSmoothScroll}
                  >
                    Additional Info
                  </Link>
                  <Link
                    showUnderlineOnlyOnHover
                    href='#cancellation-policy'
                    onClick={onClickSmoothScroll}
                  >
                    Cancellation Policy
                  </Link>
                  <Link
                    showUnderlineOnlyOnHover
                    href='#location'
                    onClick={onClickSmoothScroll}
                  >
                    Location
                  </Link>
                  {/* <Link showUnderlineOnlyOnHover href='#reviews'>
                Reviews
              </Link> */}
                </div>
                <div className='grid grid-cols-2 items-center gap-4'>
                  <FromPrice />
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      availabilityCardRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }}
                  >
                    View options
                  </Button>
                </div>
              </div>
            </PageLayoutContainer>
          </div>
          {hasExclusionsOrInclusions && (
            <section
              className='bg-warmGrey scroll-mt-16 py-8 lg:py-16'
              id='inclusions-exclusions'
            >
              <PageLayoutContainer>
                <InclusionsExclusions
                  exclusions={exclusions}
                  inclusions={inclusions}
                />
              </PageLayoutContainer>
            </section>
          )}
          <PageLayoutContainer>
            <div
              className='logistics grid scroll-mt-24 gap-8 py-8 md:grid-cols-2 lg:py-16'
              id='logistics'
            >
              <section
                // has-[+*:empty] uses the CSS has() pseudo-function to select for an adjacent sibling '+',
                // which is any element '*', which is empty. The 'meeting-and-pickup' section itself will never be empty,
                // therefore the only possible empty element is the 'timeline' section.
                className='space-y-6 has-[+*:empty]:col-span-2'
                id='meeting-and-pickup'
              >
                <h2 className='text-h4'>Meeting and Pickup</h2>
                <p className='color-grey-800 type-body-1'>
                  View meeting points and pickup points below.
                </p>
                <MeetingPoints
                  end={experience.logistics?.end}
                  start={experience.logistics?.start}
                />
                <PickupPoints
                  details={travelerPickup?.additionalInfo}
                  points={travelerPickup?.locations}
                />
                {season && (
                  <Time
                    endDate={season?.endDate}
                    operatingHours={season?.operatingHours}
                    startDate={season?.startDate}
                  />
                )}
              </section>
              <section id='timeline'>
                <Timeline expanded={false} itinerary={itinerary} />
              </section>
            </div>
          </PageLayoutContainer>
          <section
            className='bg-warmGrey scroll-mt-16 py-8 lg:py-16'
            id='additional-info'
          >
            <PageLayoutContainer>
              <AdditionalInfo infoItems={additionalInfo} />
            </PageLayoutContainer>
          </section>
          <section
            className='scroll-mt-16 py-8 lg:py-16'
            id='cancellation-policy'
          >
            <PageLayoutContainer>
              <CancellationPolicy
                description={cancellationPolicy?.description}
              />
            </PageLayoutContainer>
          </section>
          <section
            className='bg-warmGrey scroll-mt-16 py-8 lg:py-16'
            id='location'
          >
            <PageLayoutContainer>
              <StaticMap />
            </PageLayoutContainer>
          </section>
          {/* <section id='reviews'>
          <PageLayoutContainer>Reviews</PageLayoutContainer>
        </section> */}
        </div>
        <Footer />
      </>
    )
}
