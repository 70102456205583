import { Skeleton } from '@travelpass/design-system'

export const GuideStickyTopNavOldLoading = () => (
  <div className='b-0 b-solid z-4 b-b-1 b-grey-400 md:p-x-12 md:p-y-4.5 sticky left-0 top-0 flex max-w-full flex-row items-center justify-between gap-3 p-4 ease-in-out lg:hidden'>
    <div className='flex w-full flex-row items-start justify-between gap-2'>
      <div className='flex flex-col gap-2'>
        <div className='w-50 h-6 md:h-7'>
          <Skeleton />
        </div>
        <div className='w-26 md:h-6.5 h-5'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='flex flex-row items-center gap-3'>
        <div className='h-8 w-8'>
          <Skeleton variant='circular' />
        </div>
        <div className='h-8 w-8'>
          <Skeleton variant='circular' />
        </div>
      </div>
    </div>
  </div>
)
