import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Checkbox, Toggle } from '@travelpass/design-system'
import classNames from 'classnames'
import { EventStatus } from 'src/__generated__/graphql'

interface CustomBookEventProps {
  defaultCheckboxValue?: boolean
  defaultToggleValue?: boolean
  onChangeCheckbox?: (checkboxChecked: string) => void
  isCheckboxOnly?: boolean
}

export const CustomBookEvent = ({
  onChangeCheckbox,
  isCheckboxOnly = false,
  defaultCheckboxValue = false,
  defaultToggleValue = false,
}: CustomBookEventProps) => {
  const [isCheckboxChecked, setIsCheckboxChecked] =
    useState(defaultCheckboxValue)
  const [isToggleChecked, setIsToggleChecked] = useState(defaultToggleValue)

  useEffect(() => {
    setIsCheckboxChecked(defaultCheckboxValue)
  }, [defaultCheckboxValue])

  const onToggleChange = event => {
    onChangeCheckbox?.(
      event.target.checked ? EventStatus.NotBooked : EventStatus.Na
    )
    setIsToggleChecked(event.target.checked)
  }

  return (
    <div
      className={classNames(
        'flex w-full items-center',
        isCheckboxOnly ? 'justify-end' : 'justify-between'
      )}
    >
      {!isCheckboxOnly && (
        <ToggleSection
          isToggleChecked={isToggleChecked}
          onToggleChange={onToggleChange}
        />
      )}
      <CheckboxSection
        isCheckboxChecked={isCheckboxChecked}
        isCheckboxOnly={isCheckboxOnly}
        isToggleChecked={isToggleChecked}
        setIsCheckboxChecked={setIsCheckboxChecked}
        onChangeCheckbox={onChangeCheckbox}
      />
    </div>
  )
}

interface ToggleSectionProps {
  isToggleChecked: boolean
  onToggleChange(event: ChangeEvent<HTMLInputElement>): void
}

const ToggleSection = ({
  onToggleChange,
  isToggleChecked,
}: ToggleSectionProps) => (
  <div className='flex items-center justify-center gap-3'>
    <div className='type-body-1-medium'>Booking needed?</div>
    <Toggle checked={isToggleChecked} onChange={onToggleChange} />
  </div>
)

interface CheckboxSectionProps {
  isCheckboxOnly?: boolean
  isCheckboxChecked: boolean
  setIsCheckboxChecked: Dispatch<SetStateAction<boolean>>
  onChangeCheckbox?: (checkboxChecked: string) => void
  isToggleChecked: boolean
}

const CheckboxSection = ({
  isCheckboxOnly,
  isCheckboxChecked,
  isToggleChecked,
  setIsCheckboxChecked,
  onChangeCheckbox,
}: CheckboxSectionProps) => {
  const BOOKED_LABEL = 'Booked'
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked)
    onChangeCheckbox?.(
      e.target.checked ? EventStatus.Booked : EventStatus.NotBooked
    )
  }, [])
  return (
    <Checkbox
      isChecked={isCheckboxChecked}
      isDisabled={!isToggleChecked && !isCheckboxOnly}
      label={BOOKED_LABEL}
      onChange={onChange}
    />
  )
}
