import { useParams } from 'react-router-dom'
import { useGetTripEventsQuery } from 'src/pages/trips/hooks'
import { TripExploreListEmpty } from './TripExploreListEmpty'
import { TripExploreListLoading } from './TripExploreListLoading'
import { TripExploreCard } from '../TripExploreCard/'

export const TripExploreList = () => {
  const { tripId } = useParams()
  const { isLoading, hasError, tripEventsData } = useGetTripEventsQuery(tripId)

  /** @todo fix this data, using dailyeventbreakdown until we have the 'saved' events */
  const events =
    tripEventsData?.dailyEventBreakdown?.scheduled
      ?.map(day => day.events)
      .flat() ?? []

  if (isLoading) return <TripExploreListLoading />

  if (hasError || !events.length) return <TripExploreListEmpty />

  return (
    <div className='p-t-6 p-x-10 space-y-4'>
      {events?.map((tripEvent, index) => (
        <TripExploreCard key={index} tripEventData={tripEvent} />
      ))}
    </div>
  )
}
