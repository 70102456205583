import { useEffect, useState } from 'react'
import { Button, Divider, Link } from '@travelpass/design-system'
import type { HotelReviewsListHotelReviewsQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { HotelReviewsModal } from './HotelReviewsModal'
import { HotelReview } from './common/HotelReview'
import { HotelReviewsSummarySection } from './common/HotelReviewsSummarySection'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../hooks/useHotelReviewsSummaryListHotelReviewsQuery'

interface HotelReviewsProps {
  hotelReviewsData: HotelReviewsListHotelReviewsQuery['listHotelReviews']
}

export const HotelReviews = ({ hotelReviewsData }: HotelReviewsProps) => {
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const [isHotelReviewsModalOpen, setIsHotelReviewsModalOpen] = useState(false)
  const {
    averageOverall,
    cleanlinessAverage,
    locationAverage,
    staffAverage,
    totalReviews,
  } = hotelReviewsSummaryData ?? {}
  const { reviews } = hotelReviewsData ?? {}

  const onHotelReviewsModalClose = () => setIsHotelReviewsModalOpen(false)

  const onHotelReviewsModalOpen = () => setIsHotelReviewsModalOpen(true)

  const showReviews = reviews?.length >= 1
  const showViewAllButton = reviews?.length >= 4

  useEffect(() => {
    isHotelReviewsModalOpen && pushDataToDataLayer('view_reviews')
  }, [isHotelReviewsModalOpen])

  return (
    <>
      {isHotelReviewsModalOpen && (
        <HotelReviewsModal onClose={onHotelReviewsModalClose} />
      )}
      {showReviews && (
        <PageLayoutContainer>
          <div className='flex-justify-center grid-items-center flex'>
            <div className='grid-items-center lg:py-15 flex w-full flex-col gap-12 py-10'>
              <h3 className='type-h3'>Guest Reviews</h3>
              <Divider />
              <div className='flex w-full flex-col gap-5'>
                <HotelReviewsSummarySection
                  cleanlinessAverage={cleanlinessAverage}
                  customerReviewScore={averageOverall}
                  locationAverage={locationAverage}
                  staffAverage={staffAverage}
                  totalCustomerReviews={totalReviews}
                />
                <Divider className='my-5' />
                <div className='flex-justify-center flex'>
                  <div className='grid-auto-flow-dense grid grid-cols-1 gap-10 lg:grid-cols-2'>
                    {reviews?.map((hotelReviewData, index) => (
                      <div
                        key={`${hotelReviewData?.id}-${index}`}
                        className='flex flex-col'
                      >
                        <HotelReview
                          showExcerpt
                          hotelReviewData={hotelReviewData}
                        />
                        {showViewAllButton && (
                          <div className='flex flex-col items-end lg:hidden'>
                            <Link
                              label='Read More'
                              onClick={onHotelReviewsModalOpen}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {showViewAllButton && (
                <Button
                  label='VIEW ALL'
                  variant='outlined'
                  onClick={onHotelReviewsModalOpen}
                />
              )}
            </div>
          </div>
        </PageLayoutContainer>
      )}
    </>
  )
}
