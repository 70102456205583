import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import type {
  BookingConfirmationTripsCurrentUserQuery,
  Trip,
} from 'src/__generated__/graphql'
import { HotelSearchParams } from 'src/pages/hotels/details/constants'
import { BookingConfirmationTripsActions } from './BookingConfirmationTripsActions'
import { BookingConfirmationTripsApolloLoading } from './BookingConfirmationTripsApolloLoading'
import {
  bookingConfirmationTripsContentCss,
  bookingConfirmationTripsCss,
  bookingConfirmationTripsImageCss,
} from './bookingConfirmationTripsStyles'
import {
  constructContent,
  constructFilteredTrips,
} from './bookingConfirmationTripsUtils'

interface BookingConfirmationTripsProps {
  bookingConfirmationData: BookingConfirmationTripsCurrentUserQuery['currentUser']
  isLoading: boolean
  onlyMergeUser: boolean
}

export const BookingConfirmationTrips = ({
  bookingConfirmationData,
  isLoading,
  onlyMergeUser,
}: BookingConfirmationTripsProps) => {
  const [searchParams] = useSearchParams()
  const [claimedAnonymousUser, setClaimedAnonymousUser] = useState(false)
  const [isInitialUserAnonymous, setIsInitialUserAnonymous] = useState(false)
  const { id, isAnonymous } = bookingConfirmationData ?? {}
  const trips =
    bookingConfirmationData?.trips?.edges?.map(edge => edge?.node) ?? []
  const currentTripId = searchParams.get(HotelSearchParams.tripId)
  const filteredTrips = useMemo(
    () =>
      constructFilteredTrips({
        currentTripId,
        trips,
      }),
    [bookingConfirmationData?.trips, currentTripId]
  )
  const { name } =
    trips
      ?.filter(
        (trip: Trip | null | undefined): trip is Trip =>
          trip !== null && trip !== undefined
      )
      ?.find(({ id }) => id === currentTripId) ?? {}
  const { imageSrc, subtitle, title } = constructContent({
    isFirstTrip: !filteredTrips?.length,
    name: name ?? '',
    showAlternative: true,
  })

  useEffect(() => {
    if (!isInitialUserAnonymous) setIsInitialUserAnonymous(isAnonymous ?? false)
  }, [id, isAnonymous])

  const constructedSubtitle = () =>
    subtitle?.map(item => (
      <p key={item} className='text-body-1'>
        {item}
      </p>
    ))

  if (
    onlyMergeUser &&
    ((claimedAnonymousUser && isInitialUserAnonymous) ||
      !isInitialUserAnonymous)
  )
    return null

  if (isLoading && !isInitialUserAnonymous)
    return <BookingConfirmationTripsApolloLoading />

  return !!currentTripId && !isAnonymous ? (
    <div className={bookingConfirmationTripsCss(true)}>
      <img
        alt='Plan Your Trip - Booking Confirmation'
        className={bookingConfirmationTripsImageCss(true)}
        src={imageSrc}
      />
      <div className={bookingConfirmationTripsContentCss}>
        <div className='flex flex-col gap-4'>
          <h2 className='text-h5'>{title}</h2>
          {constructedSubtitle()}
        </div>
        <BookingConfirmationTripsActions
          claimedAnonymousUser={claimedAnonymousUser}
          currentTripId={currentTripId}
          filteredTrips={filteredTrips}
          isInitialUserAnonymous={false} // TODO: fix after TripsV1 launch
          onClaimedAnonymousUser={() => setClaimedAnonymousUser(true)}
        />
      </div>
    </div>
  ) : null
}
