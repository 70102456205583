import type { ReactNode } from 'react'
import classNames from 'classnames'

export const ExploreCard = ({
  children,
  isHovered = false,
  onClick,
  onMouseOut,
  onMouseOver,
}: {
  children: ReactNode
  isHovered?: boolean
  onClick?(): void
  onMouseOut?(): void
  onMouseOver?(): void
}) => (
  <div
    className={classNames(
      'rounded-5 shadow-1 transition-shadow-200 relative p-2 ease-linear',
      {
        'focus:shadow-2 hover:shadow-2': onClick,
        'shadow-2': isHovered,
      }
    )}
    data-testid='ExploreCard'
  >
    {onClick && (
      <button
        className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent outline-none'
        data-testid='ExploreCard-button'
        draggable='false'
        tabIndex={-1}
        type='button'
        onBlur={() => {}}
        onClick={() => onClick?.()}
        onFocus={() => {}}
        onMouseOut={() => onMouseOut?.()}
        onMouseOver={() => onMouseOver?.()}
      >
        <span className='sr-only'>
          More information about this card, opens in a new tab
        </span>
      </button>
    )}
    {children}
  </div>
)
