import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchHotels } from 'src/common/components'
import type { DatesType, GeocoderType } from 'src/constants/user'
import { initialDates } from 'src/constants/user'
import { constructDates, constructGeocoder, stringToNumber } from 'src/utils'
import type { DestinationType } from './destinationConstants'
import {
  DestinationSearchParam,
  destinationTitlePrefix,
} from './destinationConstants'

interface DestinationHeroProps {
  destination: DestinationType
}

export const DestinationHero = ({ destination }: DestinationHeroProps) => {
  const [searchParams] = useSearchParams()
  const arrival = searchParams.get(DestinationSearchParam.arrival)
  const departure = searchParams.get(DestinationSearchParam.departure)
  const latitude = searchParams.get(DestinationSearchParam.latitude)
  const longitude = searchParams.get(DestinationSearchParam.longitude)
  const placeId = searchParams.get(DestinationSearchParam.placeId)
  const placeName = searchParams.get(DestinationSearchParam.location)
  const updatedLatitude = stringToNumber(latitude)
  const updatedLongitude = stringToNumber(longitude)
  const [geocoder, setGeocoder] = useState(() =>
    constructGeocoder({
      latitude: updatedLatitude,
      longitude: updatedLongitude,
      placeId,
      placeName,
    })
  )
  const [dates, setDates] = useState(() =>
    arrival && departure ? constructDates({ arrival, departure }) : initialDates
  )
  const [hasImageError, setHasImageError] = useState(false)
  const { address, images } = destination ?? {}
  const { city } = address ?? {}
  const image = images?.[0]
  const title = `${destinationTitlePrefix} ${city}`

  const onChangeGeocoder = (updatedGeocoder: GeocoderType) => {
    setGeocoder(updatedGeocoder)
  }

  const onChangeDates = (updatedDates: DatesType) => {
    setDates(updatedDates)
  }

  return (
    <div className='bg-grey-400 p-y-0 md:p-y-10 relative'>
      {!hasImageError && (
        <img
          alt={title}
          className='pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover'
          src={image}
          onError={() => setHasImageError(true)}
        />
      )}

      <div
        className='md:border-rounded-5 md:max-w-96% lg:max-w-973px lg:p-x-19 z-1 relative m-auto flex flex-col gap-6 overflow-hidden bg-transparent p-8 lg:gap-8'
        data-testid='DestinationHero'
      >
        <h1 className='type-h2'>{title}</h1>
        <SearchHotels
          checkSearchParams
          checkTypes
          dates={dates}
          focusOnGeocoderInput={false}
          geocoder={geocoder}
          onChangeDates={onChangeDates}
          onChangeGeocoder={onChangeGeocoder}
        />
        <div className='-z-1 absolute bottom-0 left-0 right-0 top-0 block h-full w-full bg-white opacity-[70%] md:opacity-[90%]'></div>
      </div>
    </div>
  )
}
