import type { ExperienceProductAgeBand } from 'src/__generated__/graphql'
import { AgeBandTypeEnum } from 'src/__generated__/graphql'
import { stringToNumber } from 'src/utils'
import type { ExperiencesGuestsType } from './experiencesGuestsConstants'

const { Adult, Traveler } = AgeBandTypeEnum ?? {}

const constructExperiencesGuests = (
  ageBands: ExperienceProductAgeBand[]
): ExperiencesGuestsType => {
  const hasMinTravelersPerBooking =
    ageBands?.findIndex(
      ({ minTravelersPerBooking }) => minTravelersPerBooking > 0
    ) > -1

  return (
    ageBands?.map(({ ageBand, minTravelersPerBooking }) => {
      if (
        !hasMinTravelersPerBooking &&
        (ageBand === Adult || ageBand === Traveler)
      ) {
        return { ageBand, value: 1 }
      }

      return {
        ageBand,
        value: minTravelersPerBooking,
      }
    }) ?? []
  )
}

const getExperiencesGuestSubtitle = ({
  endAge,
  startAge,
}: Partial<ExperienceProductAgeBand>): string => `Ages ${startAge}-${endAge}`

const getExperiencesGuestTitle = (type: string = 'adult'): string => {
  const types = {
    adult: 'Adults',
    child: 'Children',
    infant: 'Infants',
    senior: 'Seniors',
    traveler: 'Travelers',
    youth: 'Youth',
  }

  return types?.[type?.toLowerCase() ?? 'adult']
}

const getExperiencesGuestsTotal = (guests: ExperiencesGuestsType): number =>
  guests?.reduce((total, current) => current?.value + total, 0) || 1

const getExperiencesGuestsTotalLabel = (
  guests: ExperiencesGuestsType
): string => {
  const count = getExperiencesGuestsTotal(guests)

  if (count === 1) return '1 Traveler'

  return `${count} Travelers`
}

const getExperiencesGuestsAsString = (guests: ExperiencesGuestsType): string =>
  JSON.stringify(
    guests?.map(({ ageBand, value }) => `${ageBand}:${value}`) ?? []
  )

const getExperiencesGuestsStringAsObject = (
  guests: string
): ExperiencesGuestsType => {
  if (!guests) return []

  try {
    const updatedGuests = JSON.parse(guests)

    return (
      updatedGuests?.reduce((total, current) => {
        const [ageBand, value] = current?.split(':') ?? []

        if (Object.values(AgeBandTypeEnum).includes(ageBand))
          total.push({ ageBand, value: stringToNumber(value) })

        return total
      }, []) ?? []
    )
  } catch (error) {
    console.error(error)
    return []
  }
}

export {
  constructExperiencesGuests,
  getExperiencesGuestSubtitle,
  getExperiencesGuestTitle,
  getExperiencesGuestsTotal,
  getExperiencesGuestsTotalLabel,
  getExperiencesGuestsAsString,
  getExperiencesGuestsStringAsObject,
}
