import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_EVENT_INTO_COLLECTION = gql(`
    mutation AddEventIntoCollection($input: CreateCollectedEventInput!, $itemId: String){
        createCollectedEvent(input: $input){
            collectedEvent{
                event{
                    id
                    isCollected
                    name
                }
                collection{
                    existingCollectedItem(itemId: $itemId, itemIdType: EVENT)
                    id
                    name
                }
            }
        }
    }
`)

export const useAddEventIntoCollection = () =>
  useMutation(ADD_EVENT_INTO_COLLECTION)
