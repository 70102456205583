import { useEffect, useState } from 'react'
import { DropdownOption, Icon, Button } from '@travelpass/design-system'
import creditCardType from 'credit-card-type'
import isEmpty from 'lodash.isempty'
import { useWatch } from 'react-hook-form'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { countries } from 'src/constants/countries'
import { states } from 'src/utils'
import { CardPaymentMandatoryText } from './CardPaymentMandatoryText'
import { rules, type BookingFormFields } from '../../hooks'
import { BookingTerms } from '../BookingReview/BookingTerms'

const Input = FormInput<BookingFormFields>

const useDetectAmexCardNumber = () => {
  const [isAmexCard, setIsAmexCard] = useState(false)
  const number = useWatch({ name: 'number' })

  useEffect(() => {
    const cardTypes = isEmpty(number)
      ? []
      : creditCardType(number).map(card => card.niceType)

    setIsAmexCard(cardTypes.includes('American Express'))
  }, [number])

  return isAmexCard
}

export const CardPaymentFields = ({
  bookingHotelData,
  bookingRateData,
}: {
  bookingHotelData: BookingHotelDetailsQuery
  bookingRateData: BookingValidateRateQuery
}) => {
  const isAmexCard = useDetectAmexCardNumber()

  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='col-span-2 col-start-1'>
          <Input
            fullWidth
            autoComplete='cc-number'
            format={!isAmexCard ? '#### #### #### ####' : '#### ###### #####'}
            label='Debit/Credit card number'
            name='number'
            placeholder='0000 0000 0000 0000'
            required={rules.required}
            rules={isAmexCard ? rules.numberAmex : rules.number}
            slotBefore={<Icon name='creditCard' />}
          />
        </div>
        <Input
          fullWidth
          isNumericString
          autoComplete='cc-exp'
          format='##/##'
          label='Expiration'
          name='expiration'
          placeholder='MM/YY'
          required={rules.required}
          rules={rules.expiration}
          type='tel'
        />
        <Input
          fullWidth
          autoComplete='cc-csc'
          format={!isAmexCard ? '###' : '####'}
          label='Security code'
          name='cvc'
          placeholder='CVC'
          required={rules.required}
          rules={isAmexCard ? rules.cvcAmex : rules.cvc}
          type='tel'
        />
        <div className='col-span-2 col-start-1'>
          <Input
            fullWidth
            autoComplete='cc-name'
            label='Name on Card'
            name='cardHolder'
            placeholder='Cardholder name'
            required={rules.required}
          />
        </div>
        <div className='col-span-2 col-start-1'>
          <Input
            fullWidth
            autoComplete='address-line1'
            label='Address Line 1'
            name='address1'
            placeholder='Address'
            required={rules.required}
          />
        </div>
        <div className='col-span-2 col-start-1'>
          <Input
            fullWidth
            autoComplete='address-line2'
            label='Address Line 2'
            name='address2'
            placeholder='Suite or Apt #'
          />
        </div>
        <Input
          fullWidth
          autoComplete='address-level2'
          label='City'
          name='city'
          placeholder='City'
          required={rules.required}
        />
        <Input
          fullWidth
          autoComplete='postal-code'
          label='Zip Code'
          name='postalCode'
          placeholder='Enter Zip Code'
          required={rules.required}
          rules={rules.postalCode}
        />
        <FormDropdown
          fullWidth
          label='State'
          name='stateProvince'
          placeholder='State'
          required={rules.required}
        >
          {states.map(({ value, label }) => (
            <DropdownOption key={value} value={value}>
              {label}
            </DropdownOption>
          ))}
        </FormDropdown>
        <FormDropdown fullWidth required label='Country' name='country'>
          {countries.map(({ value, label }) => (
            <DropdownOption key={value} value={value}>
              {label}
            </DropdownOption>
          ))}
        </FormDropdown>
        <CardPaymentMandatoryText />
      </div>
      <BookingTerms
        bookingHotelData={bookingHotelData}
        bookingRateData={bookingRateData}
      />
      <div className='flex flex-row-reverse'>
        <Button
          fullWidth
          label='Complete booking'
          type='submit'
          variant='filled'
        />
      </div>
    </div>
  )
}
