import { DropdownOption } from '@travelpass/design-system'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { countries } from 'src/constants/countries'
import { states } from 'src/utils'
import { rules, type BookingFormFields } from '../../hooks'

const Input = FormInput<BookingFormFields>

export const AllAddressFields = () => {
  return (
    <div className='grid grid-cols-2 gap-4'>
      <section className='col-span-2 col-start-1'>
        <Input
          fullWidth
          autoComplete='address-line1'
          label='Address Line 1'
          name='address1'
          placeholder='Address'
          required={rules.required}
        />
      </section>
      <section className='col-span-2 col-start-1'>
        <Input
          fullWidth
          autoComplete='address-line2'
          label='Address Line 2'
          name='address2'
          placeholder='Suite or Apt #'
        />
      </section>
      <Input
        fullWidth
        autoComplete='address-level2'
        label='City'
        name='city'
        placeholder='City'
        required={rules.required}
      />
      <Input
        fullWidth
        autoComplete='postal-code'
        label='Zip Code'
        name='postalCode'
        placeholder='Enter Zip Code'
        required={rules.required}
        rules={rules.postalCode}
      />
      <FormDropdown
        fullWidth
        label='State'
        name='stateProvince'
        placeholder='State'
        required={rules.required}
      >
        {states.map(({ value, label }) => (
          <DropdownOption key={value} value={value}>
            {label}
          </DropdownOption>
        ))}
      </FormDropdown>
      <FormDropdown fullWidth required label='Country' name='country'>
        {countries.map(({ value, label }) => (
          <DropdownOption key={value} value={value}>
            {label}
          </DropdownOption>
        ))}
      </FormDropdown>
    </div>
  )
}
