import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { SearchTravelersQueryVariables } from 'src/__generated__/graphql'

const SEARCH_TRAVELERS = gql(`
  query SearchTravelers ($after: String, $competitionReady: Boolean = false, $first: Int!, $searchString: String, $tagIds: [ID] = null, $travelpassCurated: Boolean = false) {
    userProfileSearch(after: $after, competitionReady: $competitionReady, first: $first, searchString: $searchString, tagIds: $tagIds, travelpassCurated: $travelpassCurated) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          isUserOwner
          userId
          accountHandle
          bio
          isFollowed
          displayName
          followersConnection(first: 1) {
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
            totalCount
          }
          followingConnection(first: 1) {
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
            totalCount
          }
          guideCount
          isFollowed
          profileImageUrl
          profileImageSource
          tags {
            id
            category
            name
          }
        }
      }
      totalCount
    }
  }  
`)

export const useSearchTravelers = ({
  after,
  first,
  searchString,
  tagIds,
  travelpassCurated,
}: SearchTravelersQueryVariables) =>
  useQuery(SEARCH_TRAVELERS, {
    variables: {
      after,
      first,
      searchString,
      tagIds,
      travelpassCurated,
    },
  })
