import {
  Button,
  Divider,
  Icon,
  Input,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import type {
  ExperienceBooking,
  ExperienceProduct,
} from 'src/__generated__/graphql'
import { BookingStatusEnum } from 'src/__generated__/graphql'
import { copyToClipboard } from 'src/utils'
import { CancelBookingButtonWithModal } from './CancelBookingButtonWithModal'
import { ExperiencesBookingDetailsManageContact } from './ExperiencesBookingDetailsManageContact'
import { ExperiencesCancellationPolicyCard } from '../common/components'

export interface ExperiencesBookingDetailsManageProps {
  experiencesBookingDetailsData: ExperienceBooking
  experiencesBookingDetailsProductData: ExperienceProduct
}

export const ExperiencesBookingDetailsManage = ({
  experiencesBookingDetailsData,
  experiencesBookingDetailsProductData,
}: ExperiencesBookingDetailsManageProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { externalConfirmationId, trip } = experiencesBookingDetailsData
  const { cancellationPolicy } = experiencesBookingDetailsData.bookingDetails
  const zeroRefundStartWindow = cancellationPolicy?.refundEligibility?.find(
    ({ dayRangeMin }) => dayRangeMin === 0
  )?.startTimestamp
  const bookingDateTime = dayjs(
    `${experiencesBookingDetailsData?.travelDate} ${experiencesBookingDetailsData?.startTime}`
  )
  const isAfterBooking = dayjs().isAfter(bookingDateTime)
  const emailSubjectLine = `CANCEL ${experiencesBookingDetailsData?.externalConfirmationId}`
  const emailBody =
    'For all post-travel cancellation requests, you will need to include a detailed description of the issue.'

  const handleCopyConfirmationNumber = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  const isAlreadyCanceled =
    experiencesBookingDetailsData?.status === BookingStatusEnum.Canceled

  return (
    <section className='flex flex-col gap-4'>
      <h2 className='color-black text-caption-1 uppercase' id='manage-booking'>
        Manage Booking
      </h2>
      <div className='md:mx--6'>
        <Divider />
      </div>
      <ExperiencesBookingDetailsManageContact
        experienceOperatorContact={
          experiencesBookingDetailsData?.experienceOperatorContact
        }
      />
      {/* TODO: Pending resend email functionality
      <div className='print-hidden'>
        <Input
          fullWidth
          readOnly
          id='resend-email'
          label='Email sent to'
          slotAfter={
            <Button
              isDisabled={isSendEmailDisabled}
              label='Resend Email'
              size='large'
              startIcon='send'
              onClick={onResendEmailButtonClick}
            />
          }
          value={email ?? ''}
        />
      </div> */}
      {!!externalConfirmationId && (
        <div className='print-hidden' id='copy-confirmation-number'>
          <Input
            fullWidth
            readOnly
            aria-label={`Confirmation number ${externalConfirmationId}, click to copy`}
            slotBefore={
              <span className='color-canyon'>
                <Icon name='contentCopy' />
              </span>
            }
            value={`Confirmation number: ${externalConfirmationId}`}
            onClick={handleCopyConfirmationNumber}
          />
        </div>
      )}
      <ExperiencesCancellationPolicyCard
        description={cancellationPolicy?.description}
        startTimestamp={zeroRefundStartWindow}
        timeZone={trip?.timeZone}
        type={cancellationPolicy?.type}
      />
      {!isAlreadyCanceled && !isAfterBooking && (
        <CancelBookingButtonWithModal
          bookingDetails={experiencesBookingDetailsData}
          productDetails={experiencesBookingDetailsProductData}
        />
      )}
      {isAfterBooking && (
        <Button
          data-href={encodeURI(
            `mailto:support@travelpass.com?Subject=${emailSubjectLine}&Body=${emailBody}`
          )}
          label='Request post-travel cancellation'
          variant='error'
          onClick={() =>
            (window.location.href = encodeURI(
              `mailto:support@travelpass.com?Subject=${emailSubjectLine}&Body=${emailBody}`
            ))
          }
        ></Button>
      )}
    </section>
  )
}
