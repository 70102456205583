import { Divider } from '@travelpass/design-system'

export const PrivacyNoticeContent = (): JSX.Element => (
  <div className={privacyNoticeContentCss}>
    <div className={bodyCss}>
      The following tables list the categories of information and specific
      pieces of information Travelpass.com collects about you. The tables also
      explain the business purpose for collecting information about you, the
      sources from which Travelpass.com gathers that information, and the third
      parties with whom Travelpass.com disclosed that information.
    </div>
    <table className={tableCss}>
      <tr>
        <td className={tableColCss}>Category of Information</td>
        <td className={tableColCss}>Personal Identifiers</td>
      </tr>
      <tr>
        <td className={tableColCss}>Specific Pieces of Information</td>
        <td className={tableColCss}>
          The personal identifiers includes: full name, billing address, phone
          number, and email address.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Business Purpose(s) for Using Personal Information
        </td>
        <td className={tableColCss}>
          Performing services on behalf of the business or service provider
          including maintaining or servicing accounts; providing customer
          service; processing or fulfilling orders and transactions; verifying
          customer information; processing payments; providing financing;
          providing advertising or marketing services; providing analytic
          services; or providing similar services on behalf of the business or
          service provider
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Commercial Purpose for Using Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com uses the personal identifiers to provide complete
          travel bookings, provide travel confirmations and updates, manage
          customer accounts, respond to consumer requests, and notify customers
          about special offers, products and services.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Sources from Which Travelpass.com Collected Information
        </td>
        <td className={tableColCss}>
          The personal identifiers are collected from customers.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties to Whom Travelpass.com Sold Personal
          Information
        </td>
        <td className={tableColCss}>
          Travelpass.com does not sell the personal identifying information to
          third parties.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties with Whom Travelpass.com Disclosed
          Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com shares the personal identifying information with its
          affiliates, subsidiaries, suppliers, third party vendors, business
          partners, and referral websites.
        </td>
      </tr>
    </table>
    <Divider />
    <table className={tableCss}>
      <tr>
        <td className={tableColCss}>Category of Information </td>
        <td className={tableColCss}>Commercial Information</td>
      </tr>
      <tr>
        <td className={tableColCss}>Specific Pieces of Information </td>
        <td className={tableColCss}>
          The commercial information includes: credit card number, card
          expiration date, hotel bookings, hotels viewed, and hotel search
          history.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Business Purpose(s) for Using Personal Information
        </td>
        <td className={tableColCss}>
          - Performing services on behalf of the business or service provider
          including maintaining or servicing accounts; providing customer
          service; processing or fulfilling orders and transactions; verifying
          customer information; processing payments; providing financing;
          providing advertising or marketing services; providing analytic
          services; or providing similar services on behalf of the business or
          service provider
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Commercial Purpose for Using Personal Information{' '}
        </td>
        <td className={tableColCss}>
          Travelpass.com uses the commercial information to provide complete
          travel bookings, provide travel confirmations and updates, manage
          customer accounts, respond to consumer requests, and notify customers
          about special offers, products and services.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Sources from Which Travelpass.com Collected Information{' '}
        </td>
        <td className={tableColCss}>
          The commercial information is collected from customers.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties to Whom Travelpass.com Sold Personal
          Information
        </td>
        <td className={tableColCss}>
          Travelpass.com does not sell the commercial information to third
          parties.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties with Whom Travelpass.com Disclosed
          Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com shares the commercial information with its affiliates,
          subsidiaries, suppliers, third party vendors, business partners, and
          referral websites.
        </td>
      </tr>
    </table>
    <Divider />
    <table className={tableCss}>
      <tr>
        <td className={tableColCss}>Category of Information </td>
        <td className={tableColCss}>Internet Data</td>
      </tr>
      <tr>
        <td className={tableColCss}>Specific Pieces of Information </td>
        <td className={tableColCss}>
          The internet data includes: IP address, device ID, cookies, browser
          type, and referring website.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Business Purpose(s) for Using Personal Information
        </td>
        <td className={tableColCss}>
          <ol className={tableColListCss}>
            <li className={tableColTitle}>- Detecting security incidents</li>
            <li>
              - Protecting against malicious or illegal activity and prosecuting
              those responsible
            </li>
            <li>
              - Debugging to identify and repair errors that impair existing
              intended functionality
            </li>
            <li>
              - Performing services on behalf of the business or service
              provider including maintaining or servicing accounts; providing
              customer service; processing or fulfilling orders and
              transactions; verifying customer information; processing payments;
              providing financing; providing advertising or marketing services;
              providing analytic services; or providing similar services on
              behalf of the business or service provider
            </li>
          </ol>
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Commercial Purpose for Using Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com uses the internet data to complete travel bookings,
          measure interest in and improve the company’s products, services, and
          website, customize the user’s website experience, troubleshoot
          problems, and prevent potentially prohibited or illegal activities.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Sources from Which Travelpass.com Collected Information
        </td>
        <td className={tableColCss}>
          The internet data is collected from users of Travelpass.com and
          applications
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties to Whom Travelpass.com Sold Personal
          Information
        </td>
        <td className={tableColCss}>
          Travelpass.com does not sell the internet data to third parties.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties with Whom Travelpass.com Disclosed
          Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com shares the internet data with its affiliates,
          subsidiaries, suppliers, third party vendors, business partners, and
          referral websites.
        </td>
      </tr>
    </table>
    <Divider />
    <table className={tableCss}>
      <tr>
        <td className={tableColCss}>Category of Information</td>
        <td className={tableColCss}>Inferences</td>
      </tr>
      <tr>
        <td className={tableColCss}>Specific Pieces of Information</td>
        <td className={tableColCss}>
          The inference data includes information about customers&apos; hotel
          room and travel preferences.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Business Purpose(s) for Using Personal Information{' '}
        </td>
        <td className={tableColCss}>
          - Performing services on behalf of the business or service provider
          including maintaining or servicing accounts; providing customer
          service; processing or fulfilling orders and transactions; verifying
          customer information; processing payments; providing financing;
          providing advertising or marketing services; providing analytic
          services; or providing similar services on behalf of the business or
          service provider
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Commercial Purpose for Using Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com uses the inference data to personalize the
          customers&apos; booking experience and notify customers about special
          offers and products or services that may be of interest to them.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Sources from Which Travelpass.com Collected Information
        </td>
        <td className={tableColCss}>
          The inference data is collected from customers.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties to Whom Travelpass.com Sold Personal
          Information
        </td>
        <td className={tableColCss}>
          Travelpass.com does not sell the inference data to third parties.
        </td>
      </tr>
      <tr>
        <td className={tableColCss}>
          Categories of third Parties with Whom Travelpass.com Disclosed
          Personal Information
        </td>
        <td className={tableColCss}>
          Travelpass.com shares the inference data with its affiliates,
          subsidiaries, and third party service providers, and referral
          websites.
        </td>
      </tr>
    </table>
    <h4 className={titleCss}>Your Rights Regarding the Data We Collect:</h4>
    <div className={bodyCss}>
      As a consumer in California, you have the right to request access to
      information, request the deletion of information, request Travelpass.com
      disclose information, and request information about how Travelpass.com
      sells or discloses your information to third parties for a business
      purpose.
    </div>
    <div className={bodyCss}>
      Regarding information we sell or disclose to third parties, you have the
      right to opt out of such sale or disclosure at any time. You can exercise
      your right to opt out on our website: n/a.
    </div>
    <div className={bodyCss}>
      We are required to respond to any of your requests to exercise these above
      rights within 45 days. Should we be unable to comply within 45 days, we
      will contact you and let you know whether we can complete your request or
      whether we need more time to complete your request.
    </div>
    <h4 className={titleCss}>Exercising Your Rights:</h4>
    <div className={bodyCss}>
      If you want to exercise your rights, you may contact us or our Privacy
      Point of Contact at:
    </div>
    <table className={tableCss}>
      <tr>
        <td className={tableColCss}>Organization</td>
        <td className={tableColCss}>Privacy Point of Contact</td>
      </tr>
      <tr>
        <td className={tableColCss}>
          <ol className={tableColListCss}>
            <li className={tableColTitle}>Travelpass Group, LLC</li>
            <li>
              Address: 4700 West Daybreak Parkway STE 100 N, South Jordan, UT
              84009
            </li>
            <li>Phone: (801) 562-4555</li>
            <li>Email: privacy@travelpassgroup.com</li>
          </ol>
        </td>
        <td className={tableColCss}>
          <ol className={tableColListCss}>
            <li className={tableColTitle}>Data Protection Officer</li>
            <li>
              Address: 4700 West Daybreak Parkway STE 100 N, South Jordan, UT
              84009
            </li>
            <li>Phone: (801) 562-4555</li>
            <li>Email: privacy@travelpassgroup.com</li>
          </ol>
        </td>
      </tr>
    </table>
    <div className={bodyCss}>
      The Privacy Point of Contact listed above, as well as employees who work
      with the Privacy Point of Contact, have received training to help you
      exercise your rights under the California Consumer Privacy Act.
    </div>
    <h4 className={titleCss}>Withdraw Consent:</h4>
    <div className={bodyCss}>
      If our legal basis for processing your information is based on your
      consent, you may withdraw your consent at any time by contacting the
      Privacy Point of Contact above and informing him/her that you withdraw
      your consent.
    </div>
    <h4 className={titleCss}>Contacting Authorities:</h4>
    <div className={bodyCss}>
      If you would like to lodge a complaint against us, you may contact your
      local authority. If you have trouble locating an authority, please contact
      Privacy Point of Contact above and they will help you.
    </div>
    <h4 className={titleCss}>Changes to Our Privacy Statement:</h4>
    <div className={bodyCss}>
      We keep this privacy statement under regular review and will place any
      updates on our website.
    </div>
    <div className={bodyCss}>
      <p className='mt-16 text-center'>END OF PRIVACY NOTICE</p>
    </div>
  </div>
)

const privacyNoticeContentCss = 'pb-20'

const bodyCss = 'type-body-1 mt-4'

const titleCss = 'type-h6 mt-16 uppercase c-forestDark'

const tableCss = 'mt-4 text-left w-full border-collapse'

const tableColCss = 'b-1 b-solid b-black p-4'

const tableColTitle = 'font-bold w-4/12'

const tableColListCss = 'p-0 m-0 list-none'
