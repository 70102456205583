import { Radio } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ResultsFiltersSection } from 'src/common/components'
import { initialExpFilters } from 'src/constants'

interface ResultsFiltersRatingProps {
  filters: ExperienceFilters
  onChange(updatedFilters: ExperienceFilters): void
}

export const ResultsFiltersRating = ({
  filters,
  onChange,
}: ResultsFiltersRatingProps) => {
  const { customerReviewScore } = filters ?? {}
  const { minScore } = customerReviewScore ?? {}
  const constructedReviews = [
    {
      id: 'Any',
      isChecked: minScore === '1.0' || isEmpty(minScore),
      label: 'Any',
      score: '1.0',
    },
    {
      id: 'reviews7AndUp',
      isChecked: minScore === '2.0',
      label: '2+',
      score: '2.0',
    },
    {
      id: 'reviews8AndUp',
      isChecked: minScore === '3.0',
      label: '3+',
      score: '3.0',
    },
    {
      id: 'reviews9AndUp',
      isChecked: minScore === '4.0',
      label: '4+',
      score: '4.0',
    },
    {
      id: 'reviews10',
      isChecked: minScore === initialExpFilters.customerReviewScore.maxScore,
      label: '5',
      score: initialExpFilters.customerReviewScore.maxScore,
    },
  ]

  const onReviewsChange = (score: string) =>
    onChange({
      ...filters,
      customerReviewScore: {
        maxScore: initialExpFilters.customerReviewScore.maxScore,
        minScore: score,
      },
    })

  return (
    <ResultsFiltersSection title='Customer Review Rating'>
      <div className={resultsFiltersRatingCss}>
        {constructedReviews.map(({ label, id, isChecked, score }) => (
          <div key={id}>
            <Radio
              id={id}
              isChecked={!!isChecked}
              label={label}
              startIcon='thumbUpAlt'
              onClick={() => onReviewsChange(score)}
            />
          </div>
        ))}
      </div>
    </ResultsFiltersSection>
  )
}

const resultsFiltersRatingCss =
  'grid grid-cols-1 grid-flow-dense gap-y-1 gap-x-0 md:gap-y-0 md:gap-x-4 md:grid-cols-5'
