import { useState } from 'react'
import { Icon, Link } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { MainAmenity } from 'src/common/MainAmenities'
import { constructPopularHotelAmenities } from 'src/utils'

enum Display {
  MORE = 100,
  Less = 6,
}

const errorMessage: MainAmenity = {
  icon: 'errorOutline',
  label: 'Amenities not available.',
}

export const Amenities = ({ amenities = [] }: { amenities?: string[] }) => {
  const [displayAmount, setDisplayAmount] = useState<Display>(Display.Less)

  const amenitiesAndIcons = isEmpty(amenities)
    ? [errorMessage]
    : constructPopularHotelAmenities(amenities)

  const showViewMore = amenitiesAndIcons?.length > Display.Less

  const onClick = () => {
    if (displayAmount === Display.Less) {
      setDisplayAmount(Display.MORE)
    } else {
      setDisplayAmount(Display.Less)
    }
  }

  return (
    <div className='flex flex-col gap-2 pb-8 md:pb-6'>
      <h2 className='text-subtitle-2'>Amenities</h2>
      <div className='grid grid-flow-dense grid-cols-2 gap-x-2 gap-y-1.5'>
        {amenitiesAndIcons
          ?.slice(0, displayAmount)
          ?.map(({ icon, label }, index) => (
            <div
              key={`${label}-${index}`}
              className='text-body-1 flex flex-row items-center gap-2'
            >
              <Icon name={icon} />
              <p className='type-body-1'>{label}</p>
            </div>
          ))}
      </div>
      {showViewMore && (
        <div className='flex w-full flex-row items-center justify-end'>
          <Link
            label={`View ${displayAmount === Display.Less ? 'More' : 'Less'}`}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  )
}
