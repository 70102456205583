import { Skeleton } from '@travelpass/design-system'
import {
  BookingDetailsSectionHeaderLoading,
  HotelCancellationCardLoading,
  HotelPriceSummaryLoading,
} from 'src/common/components'
import { bookingDetailsPriceCss } from '../../constants'

export const BookingDetailsPriceApolloLoading = () => (
  <div>
    <BookingDetailsSectionHeaderLoading />
    <div className={bookingDetailsPriceCss}>
      <HotelPriceSummaryLoading />
      <HotelCancellationCardLoading />
      <div className='h-24px w-150px'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
