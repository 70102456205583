import type { UniqueIdentifier } from '@dnd-kit/core'
import type { AvailableIcons } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type {
  CreatePublishedEventMutationInGuideDraftMutationVariables,
  GetPlaceDetailsLazyQueryInGuidesQuery,
  UpdateGuideMutationInGuideMutationVariables,
  CreateGuideDraftMutationInGuideDraftMutationVariables,
} from 'src/__generated__/graphql'
import { GuideStatus } from 'src/__generated__/graphql'
import { type GeocoderType } from 'src/constants/user'
import { constructAddressInput, getEventType } from 'src/pages/trips/utils'
import { guideDraftImageAcceptedTypes } from './guideDraftConstants'
import type {
  GuideDraftData,
  GuideDraftDragItem,
  GuideDraftDragItems,
  GuideDraftPublishedEvent,
} from './types'
import type { GuideData } from '../details/types'

const checkGuideDraftFileTypeIsValid = ({
  result,
  type,
}: {
  result: FileReader['result']
  type: File['type']
}): boolean => {
  // Necessary because a file could have differing extensions/mime types
  const bytes = new Uint8Array(result as ArrayBuffer).subarray(0, 12)

  return (
    guideDraftImageAcceptedTypes.indexOf(type) !== -1 &&
    !checkGuideDraftFileTypeIsWebp(bytes)
  )
}

/** @todo look into using a library for this (possibly https://github.com/sindresorhus/file-type) */
const checkGuideDraftFileTypeIsWebp = (bytes: Uint8Array): boolean =>
  bytes[0] === 0x52 &&
  bytes[1] === 0x49 &&
  bytes[2] === 0x46 &&
  bytes[3] === 0x46 &&
  bytes[8] === 0x57 &&
  bytes[9] === 0x45 &&
  bytes[10] === 0x42 &&
  bytes[11] === 0x50

const getCreateGuideDraftVariables = ({
  description,
  geocoder,
  name,
}: {
  description: string
  geocoder: GeocoderType
  name: string
}): CreateGuideDraftMutationInGuideDraftMutationVariables => {
  const variables: CreateGuideDraftMutationInGuideDraftMutationVariables = {
    createListGuideInput: {
      name,
      status: GuideStatus.ViewableWithLink,
    },
    userGuidesArgs: {
      includeStatuses: [GuideStatus.Published],
    },
  }

  if (description) variables.createListGuideInput.description = description

  if (!isEmpty(geocoder?.center))
    variables.createListGuideInput.addresses = [constructAddressInput(geocoder)]

  return variables
}

/** @todo write test for paginatedEventCategoryId */
const getGuideDraftCreatePublishedVariables = ({
  googlePlaceId,
  guideId,
  placeDetailsData,
  paginatedEventCategoryId,
  type,
}: {
  googlePlaceId: GeocoderType['placeId']
  guideId: string
  placeDetailsData: GetPlaceDetailsLazyQueryInGuidesQuery
  paginatedEventCategoryId?: string
  type?: GetPlaceDetailsLazyQueryInGuidesQuery['getPlaceDetails']['type']
}): CreatePublishedEventMutationInGuideDraftMutationVariables => {
  const {
    address,
    city,
    country,
    latitude,
    longitude,
    name,
    postalCode,
    state,
  } = placeDetailsData?.getPlaceDetails ?? {}
  const inputAddress = {
    addressLine1: address,
    city,
    country,
    googlePlaceId,
    lat: latitude,
    long: longitude,
    state,
    zipcode: postalCode,
  }
  const inputType = getEventType(type)

  const publishedEventInput: CreatePublishedEventMutationInGuideDraftMutationVariables['publishedEventInput'] =
    {
      addresses: [inputAddress],
      guideId,
      name,
      type: inputType,
    }

  if (paginatedEventCategoryId)
    publishedEventInput.publishedEventCategoryId = paginatedEventCategoryId

  return {
    publishedEventInput,
  }
}

const getGuideDraftDragItems = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideDraftData['uncategorizedPublishedEvents']
}): GuideDraftDragItems => {
  const updatedUncategorizedEventCategory: GuideDraftDragItem = {
    id: null,
    description: null,
    name: null,
    publishedEvents:
      uncategorizedPublishedEvents?.edges?.map(({ node }) => node) ?? [],
    pageInfo: uncategorizedPublishedEvents?.pageInfo,
  }
  const updatedPaginatedEventCategories: GuideDraftDragItem[] =
    paginatedEventCategories?.edges?.map(({ node }) => {
      return {
        ...node,
        publishedEvents:
          node?.publishedEvents?.edges?.map(({ node }) => node) ?? [],
        pageInfo: node?.publishedEvents?.pageInfo,
      }
    }) ?? []
  const updatedItems: GuideDraftDragItem[] = [
    updatedUncategorizedEventCategory,
    ...updatedPaginatedEventCategories,
  ]

  return updatedItems?.reduce((total, current) => {
    total[current?.id] = current

    return total
  }, {})
}

const getGuideDraftEventMarkers = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideDraftData['uncategorizedPublishedEvents']
}): GuideDraftPublishedEvent[] => {
  if (
    isEmpty(paginatedEventCategories) &&
    isEmpty(uncategorizedPublishedEvents)
  )
    return []

  const flattenedPublishedEvents = paginatedEventCategories?.edges?.flatMap(
    paginatedEventCategories =>
      paginatedEventCategories?.node?.publishedEvents?.edges ?? []
  )

  return flattenedPublishedEvents
    .concat(uncategorizedPublishedEvents?.edges ?? [])
    ?.reduce((total, publishedEvent) => {
      const { addresses } = publishedEvent?.node ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(publishedEvent?.node)

      return total
    }, [])
}

const getGuideDraftGeocoderOptionVariant = (
  option: google.maps.places.PlaceResult
) => {
  const variant: {
    color: `c-${string}`
    icon: AvailableIcons
  } = {
    color: 'c-black',
    icon: 'place',
  }

  if (option?.place_id === 'SEARCH') {
    variant.color = 'c-forest-light'
    variant.icon = 'search'
  }

  if (option?.types?.includes('airport')) {
    variant.icon = 'airplaneModeActive'
  }

  if (option?.types?.includes('lodging')) {
    variant.icon = 'business'
  }

  return variant
}

const getGuideDraftGeocoderSectionName = ({
  paginatedEventCategories,
  sectionId,
}: {
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  sectionId: string
}) => {
  if (!sectionId) return 'Section'

  return paginatedEventCategories?.edges?.find(
    ({ node }) => node?.id === sectionId
  )?.node?.name
}

const getGuideDraftInvalidFields = ({
  addresses,
  description,
  images,
  tags,
}: Pick<GuideData, 'addresses' | 'description' | 'images' | 'tags'>) => {
  const invalidFields: string[] = []

  if (!addresses?.length) invalidFields.push('addresses')

  if (!description) invalidFields.push('description')

  if (!images?.length) invalidFields.push('images')

  if (!tags?.length) invalidFields.push('tags')

  return invalidFields
}

/**
 * We need to filter out uncategorized by checking if the id is 'null' string.
 * The id is 'null' because of SortableContext and requiring a unique identifier null
 * @link https://docs.dndkit.com/presets/sortable/sortable-context#identifier
 *
 */
const getGuideDraftPublishedEventCategorySortOrder = (
  paginatedEventCategoryIds: UniqueIdentifier[]
): UpdateGuideMutationInGuideMutationVariables['updateGuideInput']['publishedEventCategorySortOrder'] =>
  paginatedEventCategoryIds?.filter(id => id !== 'null') as string[]

export {
  checkGuideDraftFileTypeIsValid,
  getCreateGuideDraftVariables,
  getGuideDraftCreatePublishedVariables,
  getGuideDraftDragItems,
  getGuideDraftEventMarkers,
  getGuideDraftInvalidFields,
  getGuideDraftGeocoderOptionVariant,
  getGuideDraftGeocoderSectionName,
  getGuideDraftPublishedEventCategorySortOrder,
}
