import { useMutation } from '@apollo/client'
import { useSnackbar } from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import { logError } from 'src/utils'
import { generateUpdateEventEventInput } from './updateEventUtils'
import type { EventFormValuesType } from '../../components/EventForm'
import type { GenericEventType } from '../../constants'
import { pushTripEventUpdateToDataLayer } from '../../utils'
import { getTripEventsQuery } from '../useGetTripEventsQuery'

const updateTripEventGQL = gql(`
  mutation UpdateTripEvent($eventInput: UpdateEventInput!) {
    updateEvent(eventInput: $eventInput) {
      id
      addresses {
        id
        addressLine1
        addressLine2
        city
        country
        googlePlaceId
        lat
        long
        state
        zipcode
      }
      booking {
        id
        hotelName
        hotelId
      }
      description
      endDate
        experienceBooking {
          id
          externalConfirmationId
          product {
            cancellationPolicy {
              description
            }
          }
          paxMix {
            numberOfTravelers
          }
        }
      imageUrl
      isCustom
      name
      notes
      startDate
      status
      trip {
        id
        name
      }
      type
      updatedAt
      useEventTime
    }
  }
`)

/** @todo remove refetchQueries if possible */
export const useUpdateEvent = ({
  triggerVariant,
  tripId,
}: {
  triggerVariant: string
  tripId: string
}) => {
  const [updateEventMutation, { data, loading, error }] = useMutation(
    updateTripEventGQL,
    {
      refetchQueries: [
        {
          query: getTripEventsQuery,
          variables: {
            tripId,
          },
        },
      ],
    }
  )

  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const updateEvent = async ({
    eventData,
    formValues,
    timezone = 'Etc/UCT',
  }: {
    eventData: GenericEventType
    formValues: EventFormValuesType
    timezone?: string
  }) => {
    const eventInput = generateUpdateEventEventInput({
      eventData,
      formValues,
      timezone,
    })

    try {
      const response = await updateEventMutation({ variables: { eventInput } })
      const { endDate, id, name, startDate, status, trip, type, updatedAt } =
        response?.data?.updateEvent ?? {}
      const { id: tripId, name: tripName } = trip ?? {}
      pushTripEventUpdateToDataLayer({
        itemCategory: type,
        itemId: id,
        itemName: name,
        itemEndDate: endDate,
        itemStartDate: startDate,
        itemStatus: status,
        itemUpdatedAt: updatedAt,
        triggerVariant,
        tripId,
        tripName,
      })
      addSuccessSnack({ title: 'Successfully updated event' })
    } catch {
      addErrorSnack({ title: 'Error updating event' })
      logError(error)
    }
  }

  return {
    updateEvent,
    data,
    loading,
    error,
  }
}
