import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { TermsContent } from '../info/terms'

export const TermsModal = ({ onClose }: { onClose(): void }): JSX.Element => {
  return (
    <Modal size='medium' title='Terms and Conditions' onDismiss={onClose}>
      <ModalScrollContents>
        <TermsContent />
      </ModalScrollContents>
    </Modal>
  )
}
