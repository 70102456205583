import type { ChangeEvent } from 'react'
import { Input } from '@travelpass/design-system'
import type { FilterBy, PriceRange } from 'src/__generated__/graphql'
import { stringToNumber } from 'src/utils'

interface HotelResultsFiltersPriceFieldsProps {
  filters: FilterBy
  onChange?(updatedFilters: FilterBy): void
}

type InputChangeEvent = ChangeEvent<HTMLInputElement>

export const HotelResultsFiltersPriceFields = ({
  filters,
  onChange = () => {},
}: HotelResultsFiltersPriceFieldsProps) => {
  const { maxPrice, minPrice } = filters?.priceRange ?? {}

  const onInputBlur = (isMaxPrice = false) => {
    if (isNaN(minPrice) || isNaN(maxPrice)) return

    if (minPrice > maxPrice) {
      if (isMaxPrice)
        return onChange({
          ...filters,
          priceRange: {
            maxPrice: minPrice + 5,
            minPrice: minPrice,
          },
        })

      return onChange({
        ...filters,
        priceRange: {
          maxPrice: minPrice,
          minPrice: 0,
        },
      })
    }
  }

  const onInputChange = (priceRange: PriceRange) => {
    onChange({
      ...filters,
      priceRange,
    })
  }

  return (
    <div
      className='grid grid-cols-2 gap-8'
      data-testid='HotelResultsFiltersPriceFields'
    >
      <Input
        fullWidth
        label='Min'
        maxLength={4}
        placeholder='0'
        slotBefore={<>$</>}
        value={minPrice ?? ''}
        onBlur={() => onInputBlur()}
        onChange={(event: InputChangeEvent) =>
          onInputChange({
            maxPrice,
            minPrice: stringToNumber(event.currentTarget.value),
          })
        }
      />
      <Input
        fullWidth
        label='Max'
        maxLength={4}
        placeholder='0'
        slotBefore={<>$</>}
        value={maxPrice ?? ''}
        onBlur={() => onInputBlur(true)}
        onChange={(event: InputChangeEvent) =>
          onInputChange({
            maxPrice: stringToNumber(event.currentTarget.value),
            minPrice,
          })
        }
      />
    </div>
  )
}
