interface GuideLandingPageCardProps {
  owner: {
    name: string
    profileImage: string
  }
  title: string
  image: string
  city: string
  state: string
  href: string
}

export const GuideLandingPageCard = ({
  owner,
  title,
  image,
  city,
  state,
  href,
}: GuideLandingPageCardProps) => {
  return (
    <div
      className='of-hidden rounded-4 h-270px md:min-h-475px lg:min-h-475px hover:shadow-1 hover:z-5 relative bg-transparent transition ease-linear'
      data-testid='ResultsCard'
    >
      <a
        className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent outline-none'
        data-testid='ResultsCard-anchor'
        draggable='false'
        href={href}
        rel='noreferrer'
        target='_blank'
      >
        <span className='sr-only'>
          More information about &quot;{title}&quot; guide, opens in a new tab.
        </span>
      </a>

      <div className='of-hidden ws-nowrap pointer-events-none flex h-full flex-col justify-end'>
        <div className='of-hidden absolute inset-0 h-full w-full bg-transparent object-cover'>
          <img
            alt=''
            className='flex h-full w-full object-cover opacity-100'
            draggable='false'
            loading='lazy'
            src={image}
          />
        </div>
        <div className='absolute inset-0 bg-gradient-to-t from-black/75 from-0% via-black/55 via-50% to-transparent to-75% md:from-10% md:via-30% md:to-50% lg:from-10% lg:via-30% lg:to-50%'></div>
        <div className='p-x-4 p-y-4 relative space-y-3'>
          <div className='relative space-y-3'>
            <h4 className='color-white type-h3-desktop lg:min-h-18 line-clamp-2 min-h-11 text-wrap drop-shadow-md'>
              {title}
            </h4>
            <div className='h-2px bg-valley-dark/50 w-full'></div>
            <p className='color-white type-overline-desktop min-h-4 truncate'>
              {city}, {state}
            </p>
          </div>
          <div
            className='relative flex flex-row items-center gap-3'
            role='button'
            tabIndex={0}
          >
            <section className='transition-opacity-200 pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50'>
              <img
                alt='avatarImage'
                className='h-12 max-h-12 min-h-12 w-12 min-w-12 max-w-12 rounded-full object-cover'
                id='avatarImage'
                src={owner.profileImage}
              />
            </section>
            <section className='space-y-1'>
              <p className='transition-opacity-200 color-white type-body-1-desktop pointer-events-auto cursor-pointer ease-linear hover:opacity-50 active:opacity-50'>
                {owner.name}
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
