import classNames from 'classnames'

interface GuidesEventsMapClusterIconProps {
  isCluster?: boolean
  title: string
  variant?: 'default' | 'active'
}

export const GuidesEventsMapClusterIcon = ({
  isCluster = false,
  title,
  variant = 'default',
}: GuidesEventsMapClusterIconProps) => {
  const icon = isCluster ? (
    <svg
      className='pointer-events-none'
      height='43px'
      viewBox='0 0 94 43'
      width='94px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M35.5333333,65 C30.8205013,65 27,68.612596 27,73.0689635 L27,85.1724087 C27,89.6287964 30.8205013,93.2413722 35.5333333,93.2413722 L60.3760887,93.2413722 C62.6169421,93.2413722 66.1709867,96.3791904 68.4,98.6130829 C69.1845333,99.3993026 69.5728267,99.7924628 69.95,99.9131947 C70.3102133,100.028581 70.6195733,100.028883 70.98,99.914405 C71.3576,99.79448 71.7522667,99.4016224 72.54,98.6159071 C74.7797867,96.3818128 78.2577867,93.2413722 80.58632,93.2413722 L104.47,93.2413722 C109.182853,93.2413722 113.01,89.6287964 113.01,85.1724087 L113.01,73.0689635 C113.01,68.612596 109.182853,65 104.47,65 L35.5333333,65 Z'
          id='path-1'
        />
        <filter
          filterUnits='objectBoundingBox'
          height='145.7%'
          id='filter-2'
          width='118.6%'
          x='-9.3%'
          y='-11.4%'
        >
          <feOffset
            dx='0'
            dy='4'
            in='SourceAlpha'
            result='shadowOffsetOuter1'
          />
          <feGaussianBlur
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
            stdDeviation='2'
          />
          <feComposite
            in='shadowBlurOuter1'
            in2='SourceAlpha'
            operator='out'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            in='shadowBlurOuter1'
            type='matrix'
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0'
          />
        </filter>
      </defs>
      <g
        fill='none'
        fillRule='evenodd'
        id='Page-1'
        stroke='none'
        strokeWidth='1'
      >
        <g id='Path-Copy-2' transform='translate(-23, -65)'>
          <use
            fill='black'
            fillOpacity='1'
            filter='url(#filter-2)'
            xlinkHref='#path-1'
          />
          <path
            className={classNames(
              'transition-fill,stroke-200 stroke-black ease-linear group-hover:fill-black group-hover:stroke-black',
              {
                'fill-black': variant === 'active',
                'fill-white': variant === 'default',
              }
            )}
            d='M104.47,66 C106.557677,66 108.451314,66.7965049 109.821443,68.0910626 C111.169907,69.3651494 112.01,71.1234957 112.01,73.0689635 L112.01,85.1724087 C112.01,87.117883 111.169909,88.8762279 109.821449,90.1503122 C108.451319,91.4448704 106.557679,92.2413722 104.47,92.2413722 L80.58632,92.2413722 C78.0845502,92.2413722 74.2701609,95.4777257 71.8338007,97.907894 C71.2383023,98.5018668 70.96921,98.8686091 70.6772833,98.9613244 C70.0253065,98.8873182 69.821373,98.6187302 69.4208965,98.2199129 C66.6629687,95.4565329 62.7769401,92.2413722 60.3760887,92.2413722 L35.5333333,92.2413722 C33.4463865,92.2413722 31.5548055,91.4453604 30.1864096,90.1514303 C28.8388377,88.8771911 28,87.1183533 28,85.1724087 L28,73.0689635 C28,71.1230254 28.8388404,69.3641862 30.1864148,68.0899445 C31.5548102,66.7960149 33.446389,66 35.5333333,66 Z'
            fillRule='evenodd'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      className='pointer-events-none'
      height='43px'
      viewBox='0 0 72 43'
      width='72px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M45.5333333,23 C40.8205013,23 37,26.612596 37,31.0689635 L37,43.1724087 C37,47.6287964 40.8205013,51.2413722 45.5333333,51.2413722 L59.4,51.2413722 C61.6408533,51.2413722 65.1706667,54.3791904 67.39968,56.6130829 C68.1842133,57.3993026 68.5765333,57.7924628 68.9537067,57.9131947 C69.31392,58.0285808 69.616,58.0288834 69.9764267,57.914405 C70.3540267,57.79448 70.7479467,57.4016224 71.53568,56.6159071 L71.53568,56.6158062 C73.7754667,54.3818128 77.3381333,51.2413722 79.6666667,51.2413722 L92.4666667,51.2413722 C97.17952,51.2413722 101,47.6287964 101,43.1724087 L101,31.0689635 C101,26.612596 97.17952,23 92.4666667,23 L45.5333333,23 Z'
          id='path-1'
        />
        <filter
          filterUnits='objectBoundingBox'
          height='145.7%'
          id='filter-2'
          width='125.0%'
          x='-12.5%'
          y='-11.4%'
        >
          <feOffset
            dx='0'
            dy='4'
            in='SourceAlpha'
            result='shadowOffsetOuter1'
          />
          <feGaussianBlur
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
            stdDeviation='2'
          />
          <feComposite
            in='shadowBlurOuter1'
            in2='SourceAlpha'
            operator='out'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            in='shadowBlurOuter1'
            type='matrix'
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0'
          />
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-33, -23)'>
          <use
            fill='black'
            fillOpacity='1'
            filter='url(#filter-2)'
            xlinkHref='#path-1'
          />
          <path
            className={classNames(
              'transition-fill,stroke-200 ease-linear group-hover:fill-black group-hover:stroke-black',
              {
                'fill-black stroke-black': variant === 'active',
                'fill-white stroke-black': variant === 'default',
              }
            )}
            d='M92.4666667,24 C94.553618,24 96.4451952,24.7960125 97.8135879,26.0899395 C99.1611629,27.3641817 100,29.1230231 100,31.0689635 L100,43.1724087 C100,45.1183556 99.1611656,46.8771956 97.8135932,48.1514353 C96.4452,49.4453628 94.5536205,50.2413722 92.4666667,50.2413722 L79.6666667,50.2413722 C77.1659874,50.2413722 73.2638056,53.4797647 70.8299927,55.9072828 C70.191802,56.5429188 69.9448847,56.8751999 69.67371,56.9613244 C69.0287128,56.8872221 68.8220397,56.6189079 68.4207685,56.2198818 C65.6619849,53.4558868 61.8011172,50.2413722 59.4,50.2413722 L45.5333333,50.2413722 C43.4463865,50.2413722 41.5548055,49.4453604 40.1864096,48.1514303 C38.8388377,46.8771911 38,45.1183533 38,43.1724087 L38,31.0689635 C38,29.1230254 38.8388404,27.3641862 40.1864148,26.0899445 C41.5548102,24.7960149 43.446389,24 45.5333333,24 Z'
            fillRule='evenodd'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  )

  return (
    title && (
      <div className='h-35px group'>
        <p
          className={classNames(
            'transition-color-200 type-body-1-medium-mobile group-hover:color-white absolute left-0 top-0 w-full truncate p-1 text-center ease-linear',
            {
              'color-black': variant === 'default',
              'color-white': variant === 'active',
            }
          )}
        >
          {title}
        </p>
        {icon}
      </div>
    )
  )
}
