import { useState } from 'react'
import { Carousel, EmptyState, useScreenQuery } from '@travelpass/design-system'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'

interface HotelRoomCardImageProps {
  images: HotelRoomType['images']
  roomName: HotelRoomType['name']
}

export const HotelRoomCardImage = ({
  images,
  roomName,
}: HotelRoomCardImageProps) => {
  const { isMobileOrTablet } = useScreenQuery()
  const [hasError, setHasError] = useState(false)

  const imgTag = (image, index) => {
    return (
      <div className='rd-t-3 lg:rd-3 of-hidden h-[200px] w-full content-center bg-white lg:h-[134px] lg:w-[196px]'>
        {hasError && (
          <div className='w-54.5 lg:w-42 m-a'>
            <EmptyState name='noImages' />
          </div>
        )}
        {!hasError && (
          <img
            key={`${roomName}-${index}-${image?.caption}`}
            alt={image?.caption || roomName}
            className='h-full w-full object-cover'
            loading='lazy'
            src={image?.href}
            onError={() => setHasError(true)}
          />
        )}
      </div>
    )
  }

  return isMobileOrTablet && images.length ? (
    <Carousel
      isLight
      keyBoardControl
      showArrowsOnHover
      borderRadius={12}
      size='small'
    >
      {images.map((image, index) => {
        return imgTag(image, index)
      })}
    </Carousel>
  ) : (
    imgTag(images[0], 0)
  )
}
