import { EmptyState } from '@travelpass/design-system'

interface HotelEmptyProps {
  subtitle?: string
  title: string
}

export const HotelEmpty = ({ subtitle = '', title }: HotelEmptyProps) => {
  return (
    <div className='mx-auto flex max-w-60 flex-col items-center gap-4 pb-10 pt-5 text-center md:max-w-fit md:flex-row md:gap-0 md:py-12 md:text-left'>
      <div className='w-50'>
        <EmptyState name='noSearchResult' />
      </div>
      <div className='flex grow flex-col gap-2'>
        <p className='type-subtitle-2'>{title}</p>
        <p className='type-subtitle-2'>{subtitle}</p>
      </div>
    </div>
  )
}
