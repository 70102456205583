import { CollectionItemIdType } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useAddEventIntoCollection } from './useAddEventIntoCollection'
import { useAddExperienceIntoCollection } from './useAddExperienceIntoCollection'
import { useAddGuideIntoCollection } from './useAddGuideIntoCollection'
import { useAddHotelIntoCollection } from './useAddHotelIntoCollection'

export const useAddIntoCollection = (
  itemId: string,
  type: CollectionItemIdType
) => {
  const [addHotel, { loading: loadingHotel }] = useAddHotelIntoCollection()
  const [addEvent, { loading: loadingEvent }] = useAddEventIntoCollection()
  const [addGuide, { loading: loadingGuide }] = useAddGuideIntoCollection()
  const [addExperience, { loading: loadingExperience }] =
    useAddExperienceIntoCollection()

  const _callAddHotel = async (collectionId: string) => {
    const result = await addHotel({
      variables: { itemId, input: { collectionId, hotelId: parseInt(itemId) } },
    })
    const data = result?.data?.createCollectedHotel?.collectedHotel
    pushDataToDataLayer('add_to_collections', {
      collection_name: data?.collection?.name,
      items: [
        {
          item_id: data?.hotel?.id,
          item_name: data?.hotel?.name,
          item_category: CollectionItemIdType.Hotel,
        },
      ],
    })
    return result
  }

  const _callAddEvent = async (collectionId: string) => {
    const result = await addEvent({
      variables: { itemId, input: { collectionId, eventId: itemId } },
    })
    const data = result?.data?.createCollectedEvent?.collectedEvent
    pushDataToDataLayer('add_to_collections', {
      collection_name: data?.collection?.name,
      items: [
        {
          item_id: data?.event?.id,
          item_name: data?.event?.name,
          item_category: CollectionItemIdType.Event,
        },
      ],
    })
    return result
  }

  const _callAddGuide = async (collectionId: string) => {
    const result = await addGuide({
      variables: { itemId, input: { collectionId, guideId: itemId } },
    })
    const data = result?.data?.addCollectedGuide?.collectedGuide
    pushDataToDataLayer('add_to_collections', {
      collection_name: data?.collection?.name,
      items: [
        {
          item_id: data?.guide?.id,
          item_name: data?.guide?.name,
          item_category: CollectionItemIdType.Guide,
        },
      ],
    })
    return result
  }

  const _callAddExperience = async (collectionId: string) => {
    const result = await addExperience({
      variables: { itemId, input: { collectionId, productId: itemId } },
    })
    const data = result?.data?.upsertCollectedExperience?.collectedExperience
    pushDataToDataLayer('add_to_collections', {
      collection_name: data?.collection?.name,
      items: [
        {
          item_id: data?.product?.id,
          item_name: data?.product?.title,
          item_category: CollectionItemIdType.Experience,
        },
      ],
    })
    return result
  }

  const addItem = async (collectionId: string) => {
    switch (type) {
      case CollectionItemIdType.Hotel:
        return _callAddHotel(collectionId)
      case CollectionItemIdType.Event:
        return _callAddEvent(collectionId)
      case CollectionItemIdType.Guide:
        return _callAddGuide(collectionId)
      case CollectionItemIdType.Experience:
        return _callAddExperience(collectionId)
      default:
        throw new Error('Invalid type')
    }
  }

  const loading =
    loadingHotel || loadingEvent || loadingGuide || loadingExperience

  return {
    addItem,
    loading,
  }
}
