import { Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { ResultsCard, ResultsCardContent } from 'src/common/components'
import { getEventImage } from 'src/pages/trips/utils'
import { constructAddress } from 'src/utils'
import type { GuideOwner } from '../../types'
import { GuideEventCardDescription } from '../GuideEventCard'
import { GuideImage } from '../GuideImage'

interface GuideMapMarkerPopupProps {
  onClick: () => void
  owner: GuideOwner
  point: PublishedEvent
}

export const GuideMapMarkerPopup = ({
  onClick,
  owner,
  point,
}: GuideMapMarkerPopupProps) => {
  const { addresses, images, imageUrl, name, notes } = point ?? {}
  const { addressLine1: addressFirstLine, city, state } = addresses?.[0] ?? {}
  const address = constructAddress({
    addressFirstLine,
    city,
    state,
  })
  const src = getEventImage(images?.[0]?.url ?? imageUrl)
  const showCard = !isEmpty(point) && !!name

  return (
    showCard && (
      <ResultsCard
        className='shadow-1 b-1 b-solid b-grey-400'
        onClick={onClick}
      >
        <div>
          <div className='of-hidden rounded-3 w-25 md:w-42 absolute bottom-0 left-0 top-0'>
            <GuideImage alt='' className='shadow-1' src={src} />
          </div>
          <div className='p-l-25 md:p-l-42 w-full'>
            <ResultsCardContent>
              <div className='space-y-6'>
                <div>
                  <h4 className='c-grey-900 type-body-1-medium line-clamp-2'>
                    {name}
                  </h4>
                  <p className='c-grey-800 type-small-text flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='line-clamp-1'>{address}</span>
                  </p>
                </div>
                <div className='min-h-5'>
                  <GuideEventCardDescription notes={notes} owner={owner} />
                </div>
              </div>
            </ResultsCardContent>
          </div>
        </div>
      </ResultsCard>
    )
  )
}
