import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import {
  DateRangePicker,
  Icon,
  Input,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import type { GenericAddress } from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import type { DatesType, GeocoderType } from 'src/constants/user'
import { initialGeocoder, initialDates } from 'src/constants/user'
import { getTripNameFromGeocoder, validateGeocoder } from './tripFormUtils'
import { TripFormKeys, type TripFormValues } from '../../constants'
import { constructGeocoderFromAddress } from '../../utils'

interface TripFormProps {
  children: ReactNode
  hideDates?: boolean
  tripData?: {
    name?: string
    dates?: DatesType
    address?: GenericAddress
  }
  onSubmit?: (formValues: TripFormValues) => void
}

export const TripForm = ({
  children,
  hideDates = false,
  tripData,
  onSubmit,
}: TripFormProps) => {
  const [intializedtripData, setInitializedtripData] = useState(false)
  const [maxDate, setMaxDate] = useState<undefined | Date>(undefined)
  const { formState, handleSubmit, register, control, setValue, getValues } =
    useForm({ mode: 'onChange' })
  const { isMobileScreen } = useScreenQuery()
  const { name: nameError, address: addressError } = formState?.errors ?? {}

  const onGeocoderSelect = (geocoder: GeocoderType) => {
    const autoName = getTripNameFromGeocoder({
      geocoder,
      currentNameValue: getValues('name'),
    })

    if (autoName) setValue(TripFormKeys.Name, autoName)
  }

  const updateMaxDate = (fromDate: Dayjs) => {
    // BE has constraint that limits trips to 60 days total
    const updatedMaxDate = fromDate.add(59, 'days').toDate()
    setMaxDate(updatedMaxDate)
  }
  const onSubmitForm = (formValues: TripFormValues) => {
    onSubmit?.(formValues)
  }

  useEffect(() => {
    if (tripData && !intializedtripData) {
      if (tripData?.name) {
        setValue(TripFormKeys.Name, tripData?.name)
      }

      if (tripData?.address) {
        const geocoderFromAddress = constructGeocoderFromAddress(
          tripData?.address ?? null
        )
        const updatedAddress =
          tripData && geocoderFromAddress
            ? geocoderFromAddress
            : initialGeocoder
        setValue(TripFormKeys.Address, updatedAddress)
      }

      const tripDates = tripData?.dates ?? []
      if (tripDates?.[0]?.isValid()) {
        const updatedDates = tripDates?.[0]?.isValid()
          ? tripDates
          : initialDates
        setValue(TripFormKeys.Dates, updatedDates)
      }

      setInitializedtripData(true)
    }
  }, [tripData])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className='flex h-full w-full flex-col justify-center gap-6'>
        <div className='flex w-full flex-col gap-3.5'>
          <div className='w-full'>
            {/* Address */}
            <Controller
              control={control}
              name={TripFormKeys.Address}
              render={({
                field: { onChange: onGeocoderChange, value: address },
              }) => {
                return (
                  <div className='h-full w-full'>
                    <Geocoder
                      errorText={!!addressError && 'Address is required'}
                      focusOnInput={false}
                      geocoder={address ?? initialGeocoder}
                      helperText={!addressError && 'Type to search'}
                      isInvalid={!!addressError}
                      label='Where are you going?'
                      placeholder='Destination'
                      onResult={event => {
                        onGeocoderChange(event)
                        onGeocoderSelect(event)
                      }}
                    />
                  </div>
                )
              }}
              rules={{
                required: 'Please select a destination.',
                validate: validateGeocoder,
              }}
            />
          </div>
          <div className='md:gap-13 flex w-full flex-col gap-3.5 md:flex-row'>
            <div
              className={classNames('flex w-full', {
                'md:w-1/2': !hideDates,
              })}
            >
              {/* Name */}
              <div className='h-full w-full'>
                <Input
                  fullWidth
                  errorText={nameError?.message?.toString()}
                  helperText='Name is required'
                  isInvalid={!!nameError}
                  label='Name'
                  placeholder='Name your trip'
                  slotBefore={
                    <span className='color-new-forest'>
                      <Icon name='modeEdit' />
                    </span>
                  }
                  type='text'
                  {...register(TripFormKeys.Name, {
                    required: 'Name is required',
                    maxLength: {
                      value: 36,
                      message: 'Max 36 characters',
                    },
                  })}
                />
              </div>
            </div>
            {!hideDates && (
              <div className='flex w-full md:w-1/2'>
                {/* Dates */}
                <Controller
                  control={control}
                  defaultValue={[dayjs(), dayjs().add(1, 'days')]}
                  name={TripFormKeys.Dates}
                  render={({
                    field: { onChange: onDateChange, value: dates },
                  }) => {
                    const onDateSelect = (range: {
                      from: Dayjs
                      to?: Dayjs
                    }) => {
                      const updatedDates: DatesType = [range.from, range?.to]
                      updateMaxDate(range.from)
                      onDateChange(updatedDates)
                    }
                    return (
                      <div className='h-full w-full'>
                        <DateRangePicker
                          canSelectSameDay={false}
                          label='Dates'
                          numberOfMonths={isMobileScreen ? 1 : 2}
                          selected={{ from: dates?.[0], to: dates?.[1] }}
                          selectedValueFormat='M/DD'
                          toDate={maxDate}
                          onSelect={onDateSelect}
                        />
                      </div>
                    )
                  }}
                  rules={{
                    required: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </form>
  )
}
