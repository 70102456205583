import { Environment, env } from 'src/utils'

const googleCloudConfigInt = {
  clientId:
    '136185852772-3e7no2q5lgh943155okufehh187easjf.apps.googleusercontent.com',
}

const googleCloudConfigStg = {
  clientId:
    '372162419236-s84elkruh1gn1sdnn7aorrtv21b1nvup.apps.googleusercontent.com',
}

const googleCloudConfigProd = {
  clientId:
    '1057092318510-dbfk6tmidkvu6ef3hvbcd0gfm6c8ihf3.apps.googleusercontent.com',
}

const googleCloudConfigObject = {
  [Environment.INT]: googleCloudConfigInt,
  [Environment.STG]: googleCloudConfigStg,
  [Environment.PROD]: googleCloudConfigProd,
}

const googleCloudConfig = googleCloudConfigObject[env]

export { googleCloudConfig }
