import { useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import { PrivacyModal } from 'src/common/components'
import { BookingSendSmsModal } from './BookingSendSmsModal'
import { BookingSendSmsTermsModal } from './BookingSendSmsTermsModal'

export const BookingSendSmsLabel = () => {
  const [isBookingSendSmsModalOpen, setIsBookingSendSmsModalOpen] =
    useState(false)
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)

  const onBookingSendSmsModalClose = () => setIsBookingSendSmsModalOpen(false)

  const onBookingSendSmsModalOpen = () => setIsBookingSendSmsModalOpen(true)

  const onPrivacyModalClose = () => {
    setIsPrivacyModalOpen(false)
    onBookingSendSmsModalOpen()
  }

  const onPrivacyModalOpen = () => {
    onBookingSendSmsModalClose()
    setIsPrivacyModalOpen(true)
  }

  const onTermsModalClose = () => {
    setIsTermsModalOpen(false)
    onBookingSendSmsModalOpen()
  }

  const onTermsModalOpen = () => {
    onBookingSendSmsModalClose()
    setIsTermsModalOpen(true)
  }

  return (
    <>
      {isBookingSendSmsModalOpen && (
        <BookingSendSmsModal
          onClose={onBookingSendSmsModalClose}
          onPrivacyModalOpen={onPrivacyModalOpen}
          onTermsModalOpen={onTermsModalOpen}
        />
      )}
      {isPrivacyModalOpen && <PrivacyModal onClose={onPrivacyModalClose} />}
      {isTermsModalOpen && (
        <BookingSendSmsTermsModal onClose={onTermsModalClose} />
      )}
      <div className='flex flex-row items-center'>
        <div>Text me my reservation</div>
        <IconButton icon='info' onClick={onBookingSendSmsModalOpen} />
      </div>
    </>
  )
}
