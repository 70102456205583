import type { ReactElement } from 'react'
import { Icon } from '@travelpass/design-system'

interface OverviewOnboardingActionProps {
  children: ReactElement
  isCompleted: boolean
}

export const OverviewOnboardingAction = ({
  children,
  isCompleted,
  ...props
}: OverviewOnboardingActionProps) => {
  return (
    <div className='flex flex-row flex-col items-center gap-2'>
      <div
        aria-pressed={isCompleted}
        className='b-solid b-2 rounded-1/2 md:m-a b-grey400 color-grey400 aria-pressed:b-tealMedium aria-pressed:bg-tealMedium aria-pressed:color-white flex h-5 w-5 flex-col items-center justify-center bg-white'
        {...props}
      >
        <Icon name='check' size='small' />
      </div>
      {children}
    </div>
  )
}
