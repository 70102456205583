import { useSearchParams } from 'react-router-dom'
import { TripSearchParams } from 'src/pages/trips/constants'
import { useDeleteEvent } from 'src/pages/trips/hooks'

export const useDeleteDrawerEvent = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    deleteEvent,
    error: deleteDrawerEventError,
    loading: deleteDrawerEventLoading,
  } = useDeleteEvent()

  const deleteDrawerEvent = ({
    eventId,
    callBack,
    triggerVariant,
  }: {
    eventId?: string
    callBack?: () => void
    triggerVariant: string
  }) => {
    deleteEvent({ eventId, triggerVariant })
    searchParams.delete(TripSearchParams.eventId)
    searchParams.delete(TripSearchParams.eventType)
    setSearchParams(searchParams, {
      replace: true,
    })
    callBack?.()
  }

  return { deleteDrawerEvent, deleteDrawerEventError, deleteDrawerEventLoading }
}
