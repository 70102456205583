import { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ExperienceSortBy } from 'src/__generated__/graphql'
import { ResultsFiltersPrice, ResultsFiltersSort } from 'src/common/components'
import { updateExperiencesResultsUrl } from 'src/utils'
import { ResultsFiltersDuration } from './ResultsFiltersDuration'
import { ResultsFiltersRating } from './ResultsFiltersRating'
import { ResultsFiltersTags } from './ResultsFiltersTags'
import { ResultsFiltersTimeOfDay } from './ResultsFiltersTimeOfDay'
import {
  constructResultsFiltersArguments,
  constructResultsSortArguments,
  initialSort,
  sortItems,
} from '../common/utils'

interface ResultsFiltersModalProps {
  onClose(): void
}

export const ResultsFiltersModal = ({ onClose }: ResultsFiltersModalProps) => {
  const [sort, setSort] = useState<ExperienceSortBy>(
    ExperienceSortBy.CustomerReviewScore
  )
  const [filters, setFilters] = useState<ExperienceFilters>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const urlSort = constructResultsSortArguments(searchParams)
    setSort(urlSort)
    const urlFilters = constructResultsFiltersArguments(searchParams)
    setFilters(urlFilters)
  }, [])

  const onSortChange = (updatedSort: ExperienceSortBy) => setSort(updatedSort)

  const onFilterChange = (updatedFilters: ExperienceFilters) => {
    setFilters(updatedFilters)
  }

  const onClear = () => {
    const updatedFilters = constructResultsFiltersArguments()
    const updatedArguments = {
      searchParams,
      filterValues: {
        ...updatedFilters,
        tags: [],
      },
    }
    if (isMobile) {
      const updatedSort = constructResultsSortArguments(searchParams)
      updatedArguments['sortValue'] = initialSort
      setSort(updatedSort)
    }
    setFilters(updatedFilters)
    onClose()
    navigate(updateExperiencesResultsUrl(updatedArguments))
  }

  const onSubmit = () => {
    onClose()
    navigate(
      updateExperiencesResultsUrl({
        searchParams,
        filterValues: filters,
        sortValue: sort,
      })
    )
  }

  return (
    <Modal size='medium' title='Filters' onDismiss={onClose}>
      <ModalScrollContents>
        <form
          noValidate
          className='mb-9 flex flex-col gap-10 gap-9 sm:flex lg:mb-10 lg:flex'
          onSubmit={onSubmit}
        >
          <div className='sm:block md:hidden'>
            <ResultsFiltersSort
              sort={sort}
              sortItems={sortItems}
              onChange={onSortChange}
            />
          </div>
          <ResultsFiltersPrice
            filters={filters}
            title='Price'
            onChange={onFilterChange}
          />
          <ResultsFiltersTags filters={filters} onChange={onFilterChange} />
          <ResultsFiltersTimeOfDay
            filters={filters}
            onChange={onFilterChange}
          />
          <ResultsFiltersDuration filters={filters} onChange={onFilterChange} />
          <ResultsFiltersRating filters={filters} onChange={onFilterChange} />
        </form>
      </ModalScrollContents>
      <ModalActions>
        <Button label='Apply' type='button' onClick={onSubmit} />
        <Button label='Clear' variant='outlined' onClick={onClear} />
      </ModalActions>
    </Modal>
  )
}
