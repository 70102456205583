import { ProductCardLoading } from 'src/common/components'
import './experienceResultsCustomStyles.css'

interface ExperienceResultsListLoadingProps {
  isMoreResultsLoading?: boolean
}

export const ExperienceResultsListLoading = ({
  isMoreResultsLoading = false,
}: ExperienceResultsListLoadingProps) => {
  const constructedResultsList = Array(24)
    .fill(0)
    .map((_, index) => (
      <ProductCardLoading key={`ProductCardLoading${index}`} displayFrom />
    ))

  if (isMoreResultsLoading) return <>{constructedResultsList}</>

  return <div className={resultsListCss}>{constructedResultsList}</div>
}

const resultsListCss =
  'experience-results-list gap-4 grid grid-cols-1 grid-flow-dense pt-3 pb-6'
