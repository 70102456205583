import { create } from 'braintree-web/paypal-checkout'
import { Environment, env } from 'src/utils'
import {
  getBraintreeClient,
  BraintreeClientToken,
  merchantAccountId,
} from './braintree'

const PayPalClientId: string = {
  [Environment.INT]:
    'ASmufBhHuViQI0jVxMPbgsTVdiSz3NVDily3Ps2LrGU-zilNKlDEPL8J9wtJeO9xiRSiACpwlSA9qzHS',
  [Environment.STG]:
    'AYVkUhELhGDeUwGeVQ0CLakfw2f5x6EmmwHo41906un6V8xePxdWc6BcMsDPHYMELcL1DqU6e_Q24YbH',
  [Environment.PROD]:
    'AYVkUhELhGDeUwGeVQ0CLakfw2f5x6EmmwHo41906un6V8xePxdWc6BcMsDPHYMELcL1DqU6e_Q24YbH',
}[env]

export const INTENT = 'authorize'
// TODO: share this down via a Provider? https://github.com/paypal/react-paypal-js/blob/main/src/context/scriptProviderContext.ts
export const CURRENCY = 'USD'

let paypalInstance

export const getPaypalInstance = async () => {
  const braintree = await getBraintreeClient()
  if (!paypalInstance) {
    paypalInstance = await create({
      client: braintree,
      authorization: PayPalClientId,
      merchantAccountId,
    })
    /* sideEffect-fully load the PayPal SDK */
    if (!window.paypal) {
      await paypalInstance.loadPayPalSDK({
        'client-id': PayPalClientId,
        dataAttributes: {
          'user-id-token': BraintreeClientToken,
        },
        intent: INTENT,
        currency: CURRENCY,
        'disable-funding': 'credit,card,venmo',
      })
    }
  }

  return paypalInstance
}

// TODO: support multiple currencies by reloading the SDK on change
// see also: https://github.com/paypal/react-paypal-js/blob/main/src/context/scriptProviderContext.ts
// const updatePaypalCurrency = async currency => {
//   const braintree = await getBraintreeClient()
//   // TODO: destroy previous script instance
//   paypalInstance = await create({
//     client: braintree,
//     authorization: PayPalClientId,
//     merchantAccountId,
//   })
//   await paypalInstance.loadPayPalSDK({
//     'client-id': PayPalClientId,
//     dataAttributes: {
//       'user-id-token': BraintreeClientToken,
//     },
//     intent: INTENT,
//     currency: currency,
//     'disable-funding': 'credit,card,venmo',
//   })
// }
