import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl, privacyNoticePath } from 'src/constants'
import { PrivacyNoticeContent } from './PrivacyNoticeContent'
import { LegalLayout } from '../common/components/LegalLayout/LegalLayout'

export const PrivacyNotice = () => {
  return (
    <PageLayoutContainer includeTopPadding>
      <Helmet
        canonicalUrl={`${baseUrl}${privacyNoticePath}`}
        pageName='Privacy-notice'
      />
      <LegalLayout
        caption='OUR POLICIES'
        subtitle="Travelpass' Privacy Notice was last updated on April 14, 2023."
        title='Privacy Notice'
      >
        <PrivacyNoticeContent />
      </LegalLayout>
    </PageLayoutContainer>
  )
}
