import { useState } from 'react'
import type { UnderlineTabProps } from '@travelpass/design-system'
import { UnderlineTab } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuidesSearchBar } from '../../GuidesSearchBar'
import { TravelersSearchBar } from '../../TravelersSearchBar'

export const BasicTravelerSearch = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const updateTabIndex = (n: number) => setTabIndex(n)

  const tabs: Omit<UnderlineTabProps['tabs'][0], 'onClick'>[] = [
    {
      icon: 'listAlt',
      label: 'Travel Guides',
    },
    {
      icon: 'personOutline',
      label: 'Travelers',
    },
  ]

  const mainTextOptions = [
    'Travel With Real Recommendations',
    'Get Inspired By Travelers Like You',
  ]

  const mainText = mainTextOptions[tabIndex]

  const showTravelersSearchContent = tabIndex === 1

  return (
    <div className='relative'>
      <img
        alt=''
        className={classNames(
          backgroundImageCss,
          tabIndex === 0 ? 'opacity-100' : 'opacity-0'
        )}
        src='https://static.travelpass.com/assets/guides/guide-search-bg.webp'
      />
      <img
        alt=''
        className={classNames(
          backgroundImageCss,
          showTravelersSearchContent ? 'opacity-100' : 'opacity-0'
        )}
        src='https://static.travelpass.com/assets/explore/traveler-search-bg.webp'
      />
      <section
        className={classNames(
          'py-34 lg:max-h-4xl ease space-y-10 bg-[size:100%] bg-no-repeat transition-all md:py-44'
        )}
      >
        <h1 className='type-h1 text-center max-md:px-2'>
          <span className='hidden lg:block'>{mainText}</span>
          <span className='block text-4xl lg:hidden'>{mainText}</span>
        </h1>
        <div className='[&>div>div>div:hover]:bg-zinc-50/50! mx-auto w-fit [&>div>div>div>button]:md:px-8'>
          <UnderlineTab
            tabsNotCapitalized
            activeIndex={tabIndex}
            tabs={tabs.map((tab, i) => ({
              ...tab,
              onClick: () => updateTabIndex(i),
            }))}
            willScroll={false}
          />
        </div>
        {showTravelersSearchContent ? (
          <TravelersSearchBar
            hideSlotBefore
            className='max-w-150 mx-auto px-4 max-md:px-8'
          />
        ) : (
          <GuidesSearchBar
            hideSlotBefore
            className='max-w-150 mx-auto px-4 max-md:px-8'
          />
        )}
      </section>
    </div>
  )
}

const backgroundImageCss =
  '-z-1 lg:min-h-500px absolute inset-0 h-full w-full object-cover ease transition-all duration-300'
