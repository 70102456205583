import { Divider } from '@travelpass/design-system'
import type {
  CancellationPolicy,
  CancellationPolicyTypeEnum,
  RefundEligibility,
  Trip,
} from 'src/__generated__/graphql'
import { VerticalStep, VerticalStepper } from 'src/common/components'
import { getDateFromUTC } from 'src/utils'

interface ExperiencesCancellationPolicyProps {
  description?: CancellationPolicy['description']
  startTimestamp?: RefundEligibility['startTimestamp']
  timeZone?: Trip['timeZone']
  type?: CancellationPolicyTypeEnum
}

const ExperiencesCancellationPolicy = ({
  description,
  startTimestamp,
  timeZone,
  type,
}: ExperiencesCancellationPolicyProps) => {
  const constructedDescription = () => {
    if (description) return description

    return 'Experience cancellation policy not specified.'
  }

  const constructedStepper = () => {
    if (!startTimestamp || !timeZone || type === 'ALL_SALES_FINAL') return

    const date = getDateFromUTC(startTimestamp, timeZone)
    const formattedDate = date.format('MMMM D, YYYY')
    const formattedTime = date.format('h:mm A (zzz)')

    return (
      <div className='all-[ol>:last-child]:bg-white space-y-4'>
        <VerticalStepper>
          <VerticalStep>
            <p className='type-subtitle-2-mobile'>{formattedDate}</p>
            <p className='color-grey-800 type-body-2-desktop'>
              {`Until ${formattedTime}`}
            </p>
            <p className='color-new-forest type-body-2-desktop'>
              Free cancellation with full refund
            </p>
          </VerticalStep>
          <VerticalStep>
            <p className='type-subtitle-2-mobile'>{formattedDate}</p>
            <p className='color-grey-800 type-body-2-desktop'>
              {`After ${formattedTime}`}
            </p>
            <p className='color-red-medium type-body-2-desktop'>
              Non-refundable after this time
            </p>
          </VerticalStep>
        </VerticalStepper>
        <Divider />
      </div>
    )
  }

  return (
    <section className='space-y-4'>
      {constructedStepper()}
      <p className='color-grey-800 text-body-1'>{constructedDescription()}</p>
    </section>
  )
}

export { ExperiencesCancellationPolicy }
export type { ExperiencesCancellationPolicyProps }
