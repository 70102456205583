import { Divider, EmptyState } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { LODGING_EMPTY_CASES } from './lodgingEmptyCasesConstants'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

export const HotelResultsFeaturedLodgingEmpty = ({
  emptyCase,
}: {
  emptyCase: LODGING_EMPTY_CASES
}) => {
  const [searchParams] = useSearchParams()
  const location =
    searchParams.get(HotelResultsSearchParams.location) ?? 'this hotel'

  const emptyCasesMessages = {
    [LODGING_EMPTY_CASES.INVALID_LODGING]: `Oh no! We don't carry ${location} currently, but check out these
          other great options nearby:`,
    [LODGING_EMPTY_CASES.NO_OPTIONS_AVAILABLE]: `Oh no! It looks like we don't have any rooms available for ${location} on those dates. Try adjusting your dates, or check out these other great options nearby:`,
  }

  return (
    <div className='space-y-9 pt-6 lg:space-y-12 lg:pt-10'>
      <div className='flex flex-col items-center text-center lg:flex lg:text-left'>
        <div className='w-31'>
          <EmptyState name='noGPS' />
        </div>
        <p className='text-body-1 grow'>{emptyCasesMessages[emptyCase]}</p>
      </div>
      <div>
        <h2 className='type-h6'>Nearby Hotels</h2>
        <div className='pt-2'>
          <Divider />
        </div>
      </div>
    </div>
  )
}
