import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetBannerImagesInDashboardQuery } from 'src/__generated__/graphql'

const getBannerImagesQuery = gql(`
  query GetBannerImagesInDashboard {
    getBannerImages {
      id
      extraSmallUrl
      smallUrl
      mediumUrl
      largeUrl
    }
  }
`)

type UseGetDashboardBannerImagesQuery = {
  bannerImagesData: GetBannerImagesInDashboardQuery['getBannerImages']
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardBannerImagesQuery =
  (): UseGetDashboardBannerImagesQuery => {
    const {
      data,
      error: hasError,
      loading: isLoading,
    } = useQuery(getBannerImagesQuery)
    const bannerImagesData = data?.getBannerImages ?? []

    return {
      bannerImagesData,
      hasError,
      isLoading,
    }
  }

export type { UseGetDashboardBannerImagesQuery }
export { useGetDashboardBannerImagesQuery }
