import { useEffect, useState } from 'react'
import { MenuItem } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { ExperienceSortBy } from 'src/__generated__/graphql'
import { updateExperiencesResultsUrl } from 'src/utils'
import {
  constructResultsFiltersArguments,
  constructResultsSortArguments,
  sortItems,
} from '../common/utils'

interface ResultsSortProps {
  onClose?(): void
}

export const ResultsSort = ({ onClose }: ResultsSortProps) => {
  const [sort, setSort] = useState<ExperienceSortBy>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => setSort(constructResultsSortArguments(searchParams)), [])

  const onChange = (updatedSort: ExperienceSortBy) => {
    onClose?.()

    if (sort !== updatedSort) {
      setSort(updatedSort)
      const urlFilters = constructResultsFiltersArguments(searchParams)

      navigate(
        updateExperiencesResultsUrl({
          searchParams,
          sortValue: updatedSort,
          filterValues: urlFilters,
        })
      )
    }
  }

  return (
    <div className='flex flex-col'>
      {sortItems.map(({ label, value }) => (
        <MenuItem
          key={value}
          isHighlighted={sort === value}
          label={label}
          value={value}
          onClick={onChange}
        />
      ))}
    </div>
  )
}
