import { Button, useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import type { FollowersModalType, ProfileFields } from 'src/pages/profile/types'

interface ProfileStatisticsProps {
  guidesCount: number
  profile: ProfileFields
  toggleModal: (viewType: FollowersModalType) => void
}

export const ProfileStatistics = ({
  guidesCount,
  profile,
  toggleModal,
}: ProfileStatisticsProps) => {
  const { isMobileOrTablet } = useScreenQuery()
  const { followCount } = profile ?? {}
  const { countOfFollowing = 0, countOfFollowed = 0 } = followCount ?? {}

  return (
    <div
      className={classNames('type-h3 sm:type-h6 my-3 flex select-none', {
        'justify-center': isMobileOrTablet,
        'justify-start': !isMobileOrTablet,
      })}
    >
      <div className={containerCss}>
        <p className={valueCss}>{guidesCount}</p>
        <Button
          label='Guides'
          size='small'
          variant='text'
          onClick={() => null}
        />
      </div>
      <div className={containerCss}>
        <p className={valueCss}>{countOfFollowed}</p>
        <Button
          label='Following'
          size='small'
          variant='text'
          onClick={() => {
            toggleModal('following')
          }}
        />
      </div>
      <div className={containerCss}>
        <p className={valueCss}>{countOfFollowing}</p>
        <Button
          label='Followers'
          size='small'
          variant='text'
          onClick={() => {
            toggleModal('followers')
          }}
        />
      </div>
    </div>
  )
}

const containerCss = 'flex items-center mx-1'
const valueCss = 'type-h3 sm:type-h6'
