import { CollectionItemIdType } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useRemoveEventFromCollection } from './useRemoveEventFromCollection'
import { useRemoveExperienceFromCollection } from './useRemoveExperienceFromCollection'
import { useRemoveGuideFromCollection } from './useRemoveGuideFromCollection'
import { useRemoveHotelFromCollection } from './useRemoveHotelFromCollection'

export const useRemoveFromCollection = () => {
  const [removeHotel, { loading: loadingHotel }] =
    useRemoveHotelFromCollection()
  const [removeEvent, { loading: loadingEvent }] =
    useRemoveEventFromCollection()
  const [removeGuide, { loading: loadingGuide }] =
    useRemoveGuideFromCollection()
  const [removeExperience, { loading: loadingExperience }] =
    useRemoveExperienceFromCollection()

  const _callRemoveHotel = async (
    collectionId: string,
    itemId: string,
    onCompleted?: Function
  ) =>
    await removeHotel({
      variables: {
        itemId,
        input: { idSet: { collectionId, hotelId: itemId } },
      },
      onCompleted: ({ deleteCollectedHotel }) => {
        const { hotel } = deleteCollectedHotel?.collectedHotel
        pushDataToDataLayer('remove_from_collections', {
          collection_name:
            deleteCollectedHotel?.collectedHotel?.collection?.name,
          items: [
            {
              item_id: hotel?.id,
              item_name: hotel?.name,
              item_category: CollectionItemIdType.Hotel,
            },
          ],
        })
        if (onCompleted) onCompleted(hotel?.isCollected)
      },
    })

  const _callRemoveEvent = async (
    collectionId: string,
    itemId: string,
    onCompleted?: Function
  ) =>
    await removeEvent({
      variables: {
        itemId,
        input: { idSet: { collectionId, eventId: itemId } },
      },
      onCompleted: ({ deleteCollectedEvent }) => {
        const data = deleteCollectedEvent?.collectedEvent
        pushDataToDataLayer('remove_from_collections', {
          collection_name: data?.collection?.name,
          items: [
            {
              item_id: data?.event?.id,
              item_name: data?.event?.name,
              item_category: CollectionItemIdType.Event,
            },
          ],
        })
        onCompleted &&
          onCompleted(deleteCollectedEvent?.collectedEvent?.event?.isCollected)
      },
    })

  const _callRemoveGuide = async (
    collectionId: string,
    itemId: string,
    onCompleted?: Function
  ) =>
    await removeGuide({
      variables: { itemId, input: { collectionId, guideId: itemId } },
      onCompleted: ({ deleteCollectedGuide }) => {
        const data = deleteCollectedGuide?.collectedGuide
        pushDataToDataLayer('remove_from_collections', {
          collection_name: data?.collection?.name,
          items: [
            {
              item_id: data?.guide?.id,
              item_name: data?.guide?.name,
              item_category: CollectionItemIdType.Guide,
            },
          ],
        })
        onCompleted &&
          onCompleted(deleteCollectedGuide?.collectedGuide?.guide?.isCollected)
      },
    })

  const _callRemoveExperience = async (
    collectionId: string,
    itemId: string,
    onCompleted?: Function
  ) =>
    await removeExperience({
      variables: {
        itemId,
        input: { idSet: { collectionId, productId: itemId } },
      },
      onCompleted: ({ deleteCollectedExperience }) => {
        const { product } = deleteCollectedExperience?.collectedExperience
        pushDataToDataLayer('remove_from_collections', {
          collection_name:
            deleteCollectedExperience?.collectedExperience?.collection?.name,
          items: [
            {
              item_id: product?.id,
              item_name: product?.title,
              item_category: CollectionItemIdType.Experience,
            },
          ],
        })
        if (onCompleted) onCompleted(product?.isCollected)
      },
    })

  const removeItem = async ({
    collectionId,
    itemId,
    type,
    onCompleted,
  }: {
    collectionId: string
    itemId: string
    type: CollectionItemIdType
    onCompleted?: Function
  }) => {
    switch (type) {
      case CollectionItemIdType.Hotel:
        return _callRemoveHotel(collectionId, itemId, onCompleted)
      case CollectionItemIdType.Event:
        return _callRemoveEvent(collectionId, itemId, onCompleted)
      case CollectionItemIdType.Guide:
        return _callRemoveGuide(collectionId, itemId, onCompleted)
      case CollectionItemIdType.Experience:
        return _callRemoveExperience(collectionId, itemId, onCompleted)
      default:
        throw new Error('Invalid type')
    }
  }

  const loading =
    loadingHotel || loadingEvent || loadingGuide || loadingExperience

  return { removeItem, loading }
}
