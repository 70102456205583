import { Divider, Skeleton } from '@travelpass/design-system'
import { OverviewNoteLoading } from './OverviewNote'

export const OverviewNotesLoading = () => (
  <div className='w-full space-y-3'>
    <div className='space-y-2'>
      <div className='flex flex-row items-center justify-between gap-3'>
        <div className='h-5 w-20 lg:h-6'>
          <Skeleton />
        </div>
        <div className='hidden lg:block'>
          <div className='w-21 h-7'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
      <Divider />
    </div>
    <OverviewNoteLoading />
    <OverviewNoteLoading />
    <OverviewNoteLoading />
  </div>
)
