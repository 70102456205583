import type { ReactNode } from 'react'
import {
  ApolloProvider as ApolloProv,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'
import { useScreenQuery } from '@travelpass/design-system'
import { env, isProdEnv } from 'src/utils'
import { auth } from '../firebase/firebaseUtils'
import { getTenantId } from '../graphql/graphqlUtils'

interface ApolloProviderProps {
  children: ReactNode
}

export const ApolloProvider = ({ children }: ApolloProviderProps) => {
  const { isMobileScreen } = useScreenQuery()
  const tenantId = getTenantId(isMobileScreen)
  const envURLPiece = isProdEnv ? '' : `${env}.`

  const httpLink = createHttpLink({
    uri: ({ operationName }) =>
      `https://api.${envURLPiece}travelpass.com/graphql?operationName=${operationName}`,
  })

  const headerLink = setContext(async (_request, previousContext) => {
    const accessToken = await auth?.currentUser?.getIdToken()
    return {
      headers: {
        ...previousContext.headers,
        'graphql-client-name': 'travelpass.com',
        ...(accessToken && { authorization: `Bearer ${accessToken}` }),
        'tenant-id': tenantId,
      },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            keywordGuideSearch: relayStylePagination(['searchArgs']),
            guideSearch: relayStylePagination(['guideSearchArgs']),
            competitionLeaderboard: relayStylePagination([
              'competitionLeaderboardRequest',
            ]),
            userProfileSearch: relayStylePagination([
              'first',
              'searchString',
              'competitionReady',
              'travelpassCurated',
              'tagIds',
            ]),
          },
        },
        Collection: {
          fields: {
            collectedItems: relayStylePagination(),
          },
        },
        User: {
          fields: {
            collections: relayStylePagination(),
          },
        },
      },
    }),
    link: headerLink.concat(httpLink),
    connectToDevTools: true,
  })

  return <ApolloProv client={client}>{children}</ApolloProv>
}
