import type { Dispatch, SetStateAction } from 'react'

import { Button } from '@travelpass/design-system'

/**
 * @todo fix pagination
 * i.e. on Covid-19 currentPage starts at ends on 7
 * i.e. on Covid-19 totalPages is 6.6
 */
export const FaqPagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  totalPages: number
}) => {
  const onPageButtonClick = (isNextPage: boolean) =>
    setCurrentPage(previousCurrentPage =>
      isNextPage ? ++previousCurrentPage : --previousCurrentPage
    )

  return (
    <div className={contentCss}>
      <div className={leftButtonContainerCss}>
        <Button
          isDisabled={currentPage <= 1}
          label='Back'
          size='large'
          variant='outlined'
          onClick={() => onPageButtonClick(false)}
        />
      </div>
      <div className={rightButtonContainerCss}>
        <Button
          isDisabled={currentPage > totalPages}
          label='Next'
          size='large'
          onClick={() => onPageButtonClick(true)}
        />
      </div>
    </div>
  )
}

const contentCss = 'flex flex-row gap-6 w-full justify-between items-center'
const leftButtonContainerCss = 'w-1/2 flex flex-row justify-start'
const rightButtonContainerCss = 'w-1/2 flex flex-row justify-end'
