import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  type GetProfileGuidesQueryInProfileQuery,
  type Scalars,
  GuideStatus,
} from 'src/__generated__/graphql'

const viewUserProfileQuery = gql(`
  query GetProfileGuidesQueryInProfile($accountHandle: String!, $after: String, $first: Int = 10, $userGuidesArgs: UserGuidesArgs!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      user {
        userGuides(first: $first, after: $after, userGuidesArgs: $userGuidesArgs) {
          edges {
            node {
              id
              addresses {
                id
                city
                country
                state
              }
              description
              insertedAt
              imageUrl
              likedByCurrentUser
              name
              numberOfLikes
              paginatedImages(first: 1) {
                edges {
                  node {
                    id
                    source
                    url
                  }
                }
              }
              ownerProfile {
                id
                displayName
                profileImageUrl
              }
              timeZone
              updatedAt
              viewCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount,
        }
      }
    }
  }
`)

export type UseGetProfileGuidesQuery = {
  profileGuidesData: GetProfileGuidesQueryInProfileQuery['viewUserProfile']['user']['userGuides']
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

export const useGetProfileGuidesQuery = (
  accountHandle: string
): UseGetProfileGuidesQuery => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
  } = useQuery(viewUserProfileQuery, {
    skip: !accountHandle,
    variables: {
      accountHandle,
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
    },
  })

  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          viewUserProfile: {
            ...previousResult.viewUserProfile,
            user: {
              ...fetchMoreResult?.viewUserProfile?.user,
              userGuides: {
                ...fetchMoreResult?.viewUserProfile?.user?.userGuides,
                edges:
                  previousResult?.viewUserProfile?.user?.userGuides?.edges?.concat(
                    fetchMoreResult?.viewUserProfile?.user?.userGuides?.edges
                  ),
              },
            },
          },
        }),
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  return {
    profileGuidesData: data?.viewUserProfile?.user?.userGuides ?? null,
    hasError,
    isLoading,
    onGetMoreResults,
  }
}
