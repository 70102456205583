const featuredGuides = [
  {
    id: 1,
    owner: {
      name: 'Alexis',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ42GCHBFS0Z6MDMNPYBXT2G/profile_images/2fa792ee-bdb4-409b-af9c-a101b1bbca87',
    },
    title: 'Boston Musts',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ42GCHBFS0Z6MDMNPYBXT2G/guide_images/51d7d270-5154-467f-8c9f-941cd0dd9519',
    city: 'Boston',
    state: 'Massachusetts',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdSN1NZNEIyWjdRTVpHSlFHRTJISzJa/Boston-Musts',
  },
  {
    id: 2,
    owner: {
      name: 'Lisa Squires',
      profileImage:
        'https://static.travelpass.com/assets/users/default-avatar-lg.webp',
    },
    title: "Let's Go to Brevard, NC!",
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GH225X0Y4EYAGSG763ETFN4E/guide_images/64d348a1-c424-4a20-97ee-5772ee7113b2',
    city: 'Brevard',
    state: 'North Carolina',
    href: "https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjIxV0NHM1lBS003NUJIR1g2QThYSFY4/Let's-Go-to-Brevard%2C-NC!",
  },
  {
    id: 3,
    owner: {
      name: 'April H',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PV440KMSSA94ZT16GKCRA/profile_images/5bf3add5-63be-45d7-abcf-679cc8db096b',
    },
    title: 'Quick trip to Portland',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PV440KMSSA94ZT16GKCRA/guide_images/8be3eb29-8fa9-4ed1-9518-322f7e127267',
    city: 'Portland',
    state: 'Oregon',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdRVDlDWUhNRTJSMFhNUlZKRDFFSjRZ/Quick-trip-to-Portland',
  },

  {
    id: 4,
    owner: {
      name: 'Robert Jenkins',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1MR04KP5CTJMP9NCM3DZ2J/profile_images/8b36901d-b4b9-4d48-aad5-e07e20be05d5',
    },
    title: 'Things to do in Greenville',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1MR04KP5CTJMP9NCM3DZ2J/guide_images/b9a050a5-6672-461c-85e0-b11ca17c6b58',
    city: 'Greenville',
    state: 'South Carolina',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCNEJCNUIyNDgzRUdTR0E5ODFXV05O/Things-to-do-in-Greenville',
  },
  {
    id: 5,
    owner: {
      name: 'Alexis',
      profileImage:
        'https://lh3.googleusercontent.com/a/ALm5wu3XYtsYxkT8WzR4dG310IIeuTdLT-C2ptwRujXH=s192-c',
    },
    title: 'Bookstore Guide to Manhattan',
    image:
      'https://travelpass-guides-prd.s3.us-west-2.amazonaws.com/bookstore-guide-to-manhattan-1.webp',
    city: 'New York',
    state: 'New York',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdWSjg5Q1E1UUVaUk0wWjhQMU1LTjZC/Bookstore-Guide-to-Manhattan',
  },
  {
    id: 6,
    owner: {
      name: 'Nick',
      profileImage:
        'https://lh3.googleusercontent.com/a/ACg8ocJmiicZJUag-BvmSImotW5ub-ImomTASXFgS3U08Mwx3w=s192-c',
    },
    title: 'Things to do in Asheville',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GTAHHM0JQRJBQ6QHA5TGJQ51/guide_images/bae3c605-2627-4490-829c-c332485f4888',
    city: 'Asheville',
    state: 'North Carolina',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFczUTFGM1JDWkJEUFkzMkVDWEpRTjEy/Things-to-do-in-Asheville',
  },
  {
    id: 7,
    owner: {
      name: 'Emily Henkel',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ22PZY8G730VDDQ693R2B6B/profile_images/a3838818-8c70-4889-b148-9a6193fbc440',
    },
    title: 'I Go To Kanab A Lot',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ22PZY8G730VDDQ693R2B6B/guide_images/0aedd3cf-7b1d-4091-944a-1235c44e3c84',
    city: 'Kanab',
    state: 'Utah',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCMk1FTlJGQlBBWU0wWlM0QUJaQUhF/I-Go-To-Kanab-A-Lot',
  },
  {
    id: 8,
    owner: {
      name: 'Kendrick Morris',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/travelpass-profiles-prd/profile_images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB',
    },
    title: 'Years of Yosemite Experience',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GFVG3F4KJQV36GZ5V0TW9QKB/guide_images/c41747f5-d02e-4fc6-91b9-0c4b2d6e81ac',
    city: 'California',
    state: 'United States',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjFGVFkyMVdHOTY4SFdYTlJYMUEyMzRY/Years-of-Yosemite-Experience',
  },
  {
    id: 9,
    owner: {
      name: 'Rachael Folland',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GYDAXFJ2Q9ZD5BVV9YVP2NQT/profile_images/45910efa-1f65-4069-a6af-8825dedb3879',
    },
    title: 'All About Alabama',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GYDAXFJ2Q9ZD5BVV9YVP2NQT/guide_images/f4bc8996-2590-406f-8523-f7e5bf21cdb6',
    city: 'Alabama',
    state: 'United States',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFo4Q0QxWFZZWDRCMDBUVkFDRENKU0ZZ/All-About-Alabama',
  },
  {
    id: 10,
    owner: {
      name: 'Morgan Milovich',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1NX9G0DJ8JB7CT9HXYW5EB/profile_images/03e6b85e-b31c-4c0d-ab04-831becd92f4d',
    },
    title: 'Redfish Lake',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1NX9G0DJ8JB7CT9HXYW5EB/guide_images/88d784ff-e24f-4a2a-80c2-8d234763a97a',
    city: 'Stanley',
    state: 'Idaho',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjIwMThYV1IyUlpSTTBNMkhITjdURjY5/Redfish-Lake',
  },
  {
    id: 11,
    owner: {
      name: 'Jessica',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GNK7GS41FMFS1WW7X05C5VYN/profile_images/5613499d-21f0-433f-b1f4-ded926bf7e13',
    },
    title: 'Savannah',
    image: 'https://static.travelpass.com/assets/trip-state-pics/georgia.webp',
    city: 'Savannah',
    state: 'Georgia',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFZUNzMxQU5OQVRUREg5VEU1RVc5UDhZ/Savannah',
  },
  {
    id: 12,
    owner: {
      name: 'Syd',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/profile_images/6ac2a80f-e6fa-4b9d-a8be-8351badbd4ae',
    },
    title: 'New Orleans',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/guide_images/6b227edb-cfa0-4e5f-9d2a-3be3191e929d',
    city: 'New Orleans',
    state: 'Louisiana',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSjNRWDhYOFlYTjJENUM5NE44WTA4M0NW/New-Orleans',
  },
  {
    id: 13,
    owner: {
      name: 'Angela Jensen',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HDEXG1PMR4WRMC3F9FHF7EJH/profile_images/0e16bb68-1fb7-4bc1-8a03-9575fe60f803',
    },
    title: 'Best Restaurants in Denver',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HDEXG1PMR4WRMC3F9FHF7EJH/guide_images/e9ef358f-3b6f-4946-8b33-be5086e3d85b',
    city: 'Denver',
    state: 'Colorado',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFZBNjRaWjMzTkMxWjVZUjkyRkQyNU1L/Best-Restaurants-in-Denver',
  },
  {
    id: 14,
    owner: {
      name: 'tanner paige',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01HJ7179MPZQ461T793NY9FX81/profile_images/7f811ff8-8e08-4e60-9d73-0990909986a8',
    },
    title: 'Philadelphia for the win',
    image:
      'https://static.travelpass.com/assets/trip-state-pics/pennsylvania.webp',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFg5UkE2V05GWjVWWUdaV1lNMEtLTUYz/Philadelphia-for-the-win',
  },
  {
    id: 15,
    owner: {
      name: 'Sarah Dorich',
      profileImage:
        'https://lh3.googleusercontent.com/a/ALm5wu2uAriDp_6lcAAmkyewAkQJkQtj_RqRF5o189LTvQ=s192-c',
    },
    title: 'A fun day in Boise, Idaho',
    image: 'https://static.travelpass.com/assets/trip-state-pics/idaho.webp',
    city: 'Boise',
    state: 'Idaho',
    href: 'https://www.travelpass.com/guides/R3VpZGU6Z2RlXzAxSFdCNDk5VjVIM1NXVDZGQTlBM0FRNFFY/A-fun-day-in-Boise-Idaho',
  },
]

export { featuredGuides }
