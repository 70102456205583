import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetUsersLikeGuideQueryInGuideQuery,
  Scalars,
} from 'src/__generated__/graphql'

const getGuideQuery = gql(`
  query GetUsersLikeGuideQueryInGuide($after: String, $guideId: ID!) {
    node(id: $guideId) {
      ... on Guide {
        paginatedGuidesLikedByUsers(after: $after, first: 25) {
          edges {
            node {
              id
              userProfile {
                id
                accountHandle
                displayName
                isFollowed
                isUserOwner
                profileImageUrl
                userId
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`)

type UseGetUsersLikeGuideQuery = {
  usersLikeGuideConnection: Extract<
    GetUsersLikeGuideQueryInGuideQuery['node'],
    { __typename?: 'Guide' }
  >['paginatedGuidesLikedByUsers']
  usersLikeGuideData: Array<
    Extract<
      GetUsersLikeGuideQueryInGuideQuery['node'],
      { __typename?: 'Guide' }
    >['paginatedGuidesLikedByUsers']['edges'][0]['node']['userProfile']
  >
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => Promise<void>
}

export const useGetUsersLikeGuideQuery = (
  guideId: string
): UseGetUsersLikeGuideQuery => {
  const {
    data,
    error: hasError,
    fetchMore: onGetMoreResultsQuery,
    loading: isLoading,
  } = useQuery(getGuideQuery, {
    skip: !guideId,
    variables: {
      guideId: guideId,
    },
  })

  const onGetMoreResults = async ({
    after,
  }: {
    after: Scalars['String']['input']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  const nodeData = data?.node as Extract<
    GetUsersLikeGuideQueryInGuideQuery['node'],
    { __typename?: 'Guide' }
  >

  const usersLikeGuideData =
    nodeData?.paginatedGuidesLikedByUsers?.edges?.map(
      edge => edge.node.userProfile
    ) ?? []

  const usersLikeGuideConnection = nodeData?.paginatedGuidesLikedByUsers ?? null

  return {
    usersLikeGuideConnection,
    usersLikeGuideData,
    hasError,
    isLoading,
    onGetMoreResults,
  }
}
