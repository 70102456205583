import { EmptyState, SkeletonDots } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import type { ExperienceBooking } from 'src/__generated__/graphql'
import { Helmet, NativeAppCta } from 'src/common/components'
import { BookingConfirmationTripsApollo } from 'src/common/components/BookingConfirmationTrips'
import { ExperiencesConfirmationSummary } from '../common/components'
import { useGetExperienceBookingDetailsQuery } from '../common/hooks'

export const ExperiencesConfirmation = () => {
  const { externalConfirmationId } = useParams()
  const { data, loading } = useGetExperienceBookingDetailsQuery(
    externalConfirmationId
  )

  const experienceBooking =
    data?.findExperienceBookingByExternalConfirmationId as ExperienceBooking
  const itinerary = experienceBooking?.product?.itinerary
  const hasDurationField =
    itinerary?.__typename === 'ExperienceActivityItinerary' ||
    itinerary?.__typename === 'ExperienceHopOnHopOffItinerary' ||
    itinerary?.__typename === 'ExperienceStandardItinerary' ||
    itinerary?.__typename === 'ExperienceUnstructuredItinerary'

  return (
    <>
      <Helmet pageName='Your Experience is Confirmed' />
      <div className='max-w-175 m-auto pb-10'>
        <div className='flex flex-col items-center text-center'>
          <div className='w-62'>
            <EmptyState name='done' />
          </div>
          <h1 className='text-h3 mb-4'>Booking Confirmed!</h1>
        </div>
        <div className='mb-10'>
          {loading || !experienceBooking ? (
            <SkeletonDots />
          ) : (
            <>
              <p className='text-subtitle-2 mb-10 text-center'>
                Receipt and booking details sent to:{' '}
                {experienceBooking.communication?.email}
              </p>
              <div className='rd-5 bg-warmGrey lg:px-25 px-4 py-6 md:p-10 lg:py-12'>
                <ExperiencesConfirmationSummary
                  arrival={experienceBooking.travelDate}
                  durationRange={
                    hasDurationField && itinerary.duration.readableDurationRange
                  }
                  externalConfirmationId={externalConfirmationId}
                  paxMix={experienceBooking.paxMix}
                  startTime={experienceBooking?.startTime}
                  title={experienceBooking.productName}
                />
              </div>
            </>
          )}
        </div>
        <div className='mb-10'>
          <BookingConfirmationTripsApollo />
        </div>
        <NativeAppCta />
      </div>
    </>
  )
}
