import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query StayGoogleData($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      address
      amenities
      city
      country
      description
      googlePlaceId
      imageLinks
      longitude
      latitude
      name
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      starRating
      state
      website
    }
  }
`)

export const useStayPlacesData = () => {
  const [getStayPlacesData, { data: stayPlacesData, loading, error }] =
    useLazyQuery(getPlaceDetailsQuery)
  const data = stayPlacesData?.getPlaceDetails ?? null

  return {
    getStayPlacesData,
    data,
    loading,
    error,
  }
}
