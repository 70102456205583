import type { AvailableIcons } from '@travelpass/design-system'
import { Checkbox, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import type { Note } from 'src/__generated__/graphql'
import { NoteType } from 'src/__generated__/graphql'
import { OverviewNoteLinkCard } from '../../OverviewNoteLinkCard'
import {
  getOverviewNoteLinkAsArray,
  getOverviewNoteListAsArray,
} from '../overviewNotesUtils'

interface OverviewNoteProps {
  onClick?: (noteId: string) => void
  note: Note
  activeNoteId: string
}

export const OverviewNote = ({
  activeNoteId,
  note,
  onClick,
}: OverviewNoteProps) => {
  const { id, title, body, type, openGraph } = note ?? {}
  const reversedOpenGraph = [...(openGraph ?? [])]?.reverse()

  const getNoteContent = () => {
    if (type === NoteType.FreeText) {
      return body?.split('\n').map((item, index) => (
        <p key={index} className='type-body-1'>
          {item}
        </p>
      ))
    }

    if (type === NoteType.Checklist)
      return getOverviewNoteListAsArray(body)?.map((item, index) => (
        <div key={index} className='p-l-3'>
          <Checkbox isChecked={item.isChecked} label={item.title} />
        </div>
      ))

    return getOverviewNoteLinkAsArray(body)?.map((link, index) => {
      const { ogImage, ogTitle } = reversedOpenGraph?.[index] ?? {}

      return (
        <OverviewNoteLinkCard
          key={index}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogUrl={link}
        />
      )
    })
  }

  const getIcon = (): AvailableIcons => {
    if (type == NoteType.FreeText) return 'listAlt'

    if (type == NoteType.Checklist) return 'taskAlt'

    return 'link'
  }

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <div
      className={classNames(
        'bg-grey-100 rounded-2 transition-all-100 w-full space-y-3 p-4 outline-none ease-linear',
        {
          'b-forest-light b-solid b-2 bg-mint-light': id === activeNoteId,
          'b-transparent': id !== activeNoteId,
        }
      )}
      data-testid='OverviewNote'
      onClick={() => onClick(id)}
    >
      <div className='flex flex-row items-center gap-2'>
        <Icon name={getIcon()} />
        <h3 className='type-h5 line-clamp-1'>{title}</h3>
      </div>
      <div
        className={classNames('w-full', {
          'space-y-2': type !== NoteType.Link,
          'space-y-3': type === NoteType.Link,
        })}
      >
        {getNoteContent()}
      </div>
    </div>
  )
}
