import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  NodeQueryInBookingDetailsIdsQuery,
  NodeQueryInBookingDetailsIdsQueryVariables,
} from 'src/__generated__/graphql'

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsIds($nodeId: ID!) {
    node(id: $nodeId) {
      ... on Booking {
        id
        email
        hotelId
        externalConfirmationId
        trip {
          id
          currentUserRole
        }
      }
    }
  }
`)

export const useBookingDetailsIdsQuery = ({
  bookingId,
}: {
  bookingId: NodeQueryInBookingDetailsIdsQueryVariables['nodeId']
}) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: !bookingId,
    variables: {
      nodeId: bookingId,
    },
  })

  const { node } = data ?? {}
  const bookingDetailsIdsData = node as Extract<
    NodeQueryInBookingDetailsIdsQuery['node'],
    { __typename?: 'Booking' }
  >

  return {
    hasError,
    bookingDetailsIdsData,
    isLoading,
  }
}
