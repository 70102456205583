import { Button, ModalNext } from '@travelpass/design-system'

interface PublishGuidesModalProps {
  onClose: () => void
  onClick: () => void
}

export const PublishGuidesModal = ({
  onClose,
  onClick,
}: PublishGuidesModalProps) => (
  <ModalNext
    actions={
      <div className='flex w-full justify-center'>
        <Button label='Publish A Guide' size='medium' onClick={onClick} />
      </div>
    }
    size='large'
    title='Publish A Guide'
    onClose={onClose}
  >
    <section className='space-y-5 px-5'>
      <div className='flex w-full items-center justify-center'>
        <iframe
          allowFullScreen
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          frameBorder='0'
          height='315'
          referrerPolicy='strict-origin-when-cross-origin'
          src='https://www.youtube.com/embed/55evBU8N_0U?si=P_N3YhqsCE4t7jDF&autoplay=1'
          title='What Makes A High Quality Guide? - YouTube video'
          width='560'
        ></iframe>
      </div>
      <p className='type-h6'>What counts as a high-quality Guide?</p>
      <p className='type-body-1'>
        To be eligible to win the $20,000 prize, you must create and share three
        high-quality Guides with the Travelpass community. High-quality Guides
        are detailed, engaging, and relatable. We want to hear your personal
        experiences, recommendations, and practical tips about a destination or
        topic!
      </p>
      <p className='type-h6'>
        To count towards contest eligibility, Guides must include:
      </p>
      <ul className='type-body-1 p0 list-none'>
        <li>- A description or overview for your travel Guide.</li>
        <li>
          - At least five recommendations (places to stay, things to do, places
          to eat, etc.), with notes. More are strongly encouraged!
        </li>
        <li>
          - Your own photos to personalize your Guide. While your Guides can use
          the photos suggested by Travelpass, at least one photo from your photo
          library must be uploaded to each Guide (more are encouraged!).
        </li>
      </ul>
    </section>
  </ModalNext>
)
