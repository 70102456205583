import { Link } from 'react-router-dom'

const destinationLinks: { name: string; to: string }[] = [
  { name: 'Las Vegas', to: '/destinations/nv/las-vegas' },
  { name: 'Myrtle Beach', to: '/destinations/sc/myrtle-beach' },
  { name: 'Miami', to: '/destinations/fl/miami' },
  { name: 'San Diego', to: '/destinations/ca/san-diego' },
  { name: 'Nashville', to: '/destinations/tn/nashville' },
  { name: 'Chicago', to: '/destinations/il/chicago' },
  { name: 'New Orleans', to: '/destinations/la/new-orleans' },
  { name: 'Boston', to: '/destinations/ma/boston' },
  { name: 'Knoxville', to: '/destinations/tn/knoxville' },
  { name: 'Orlando', to: '/destinations/fl/orlando' },
  { name: 'San Antonio', to: '/destinations/tx/san-antonio' },
  { name: 'Fort Lauderdale', to: '/destinations/fl/fort-lauderdale' },
  { name: 'San Francisco', to: '/destinations/ca/san-francisco' },
  { name: 'Seattle', to: '/destinations/wa/seattle' },
  { name: 'Austin', to: '/destinations/tx/austin' },
  { name: 'Washington DC', to: '/destinations/dc/washington-dc' },
  { name: 'Denver', to: '/destinations/co/denver' },
  { name: 'Los Angeles', to: '/destinations/ca/los-angeles' },
  { name: 'New York City', to: '/destinations/ny/new-york-city' },
  { name: 'Philadelphia', to: '/destinations/pa/philadelphia' },
]

export const FindYourNextDestination = () => {
  return (
    <div className='bg-warm-grey lg:gap-18 flex w-full flex-col items-center justify-evenly gap-6 md:-mb-9 md:flex-row lg:justify-center'>
      <section className='md:mb-18 md:order-0 lg:mb-27 order-2 mb-6 flex h-full flex-col justify-center gap-6 md:mt-6 lg:mt-0 lg:gap-12'>
        <div>
          <h2 className='type-h2'>Find Your Next Destination</h2>
        </div>
        <ul className='m-0 grid list-none grid-cols-3 gap-2.5 p-0 lg:grid-cols-4 lg:gap-5'>
          {destinationLinks.map(({ name, to }) => (
            <li key={name}>
              <Link
                className='decoration-none type-link b-b-1 b-b-solid b-valley b-none hover:c-newForest focus:outline-offset-3 focus:outline-valley [&>*]:hover:b-newForest! box-border w-fit cursor-pointer bg-transparent p-0 pb-1 transition-all duration-200 focus:outline-1'
                to={to}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <img
        alt='friends'
        className='max-w-1/2 md:max-w-43% -mt-9'
        src='https://static.travelpass.com/assets/home-page/destination-circle-image.webp'
      />
    </div>
  )
}
