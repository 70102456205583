import { Button, Illustration, Toggle } from '@travelpass/design-system'
import type { Bucket, FilterBy } from 'src/__generated__/graphql'
import mainAmenities from 'src/common/MainAmenities'
import { HotelResultsFiltersSectionWithTitle } from './HotelResultsFiltersSectionWithTitle'
import {
  convertBrandSelectionNames,
  convertFromBucketsToBrandStrings,
} from './utils'
import { useGetUserFullSearchPreferences } from '../getUserFullSearchPreferences'

interface SearchPreferencesToggleProps {
  filters: FilterBy
  hotelResultsBrandData: Bucket[]
  handleShowQuiz(newVal: boolean): void
  isPreferencesApplied: boolean
  onAllBrandsChange: (brands: string[]) => void
  applyFilters(isApplied: boolean): void
  onFiltersChange: (filterSectionToUpdate: FilterBy) => void
}

export const SearchPreferencesToggle = ({
  filters,
  hotelResultsBrandData,
  handleShowQuiz,
  isPreferencesApplied,
  onAllBrandsChange,
  applyFilters,
  onFiltersChange,
}: SearchPreferencesToggleProps) => {
  // Get user's existing search preferences
  const { data } = useGetUserFullSearchPreferences()
  const { searchPreferences } = { ...data?.currentUser }
  const { brandSelections, hotelStarRating, selectedAmenities } = {
    ...searchPreferences,
  }

  const handleFiltersToggle = () => {
    if (!isPreferencesApplied) {
      // Apply the filters - Merge in the saved filters
      onAllBrandsChange([
        ...convertFromBucketsToBrandStrings(hotelResultsBrandData),
        ...brandSelections,
      ])
      onFiltersChange({
        brands: convertBrandSelectionNames(brandSelections),
        starRating: {
          maxStars: '5.0',
          minStars: hotelStarRating?.toFixed(1),
        },
        ...getSelectedAmenities(),
      })
    } else {
      // "Unapply" the filters - Remove the saved filters
      onAllBrandsChange([
        ...convertFromBucketsToBrandStrings(hotelResultsBrandData),
      ])
      onFiltersChange({
        brands: filters?.brands?.filter(
          brand => !convertBrandSelectionNames(brandSelections).includes(brand)
        ),
        starRating: null,
      })
    }
    applyFilters(!isPreferencesApplied)
  }

  const getSelectedAmenities = () =>
    selectedAmenities.reduce((acc, { displayName }) => {
      const matchingAmenity = mainAmenities.find(
        ({ label, matches }) =>
          label === displayName || matches.includes(displayName)
      )
      if (matchingAmenity) acc[matchingAmenity.id] = true
      return acc
    }, {})

  return (
    <>
      {searchPreferences !== null ? (
        <HotelResultsFiltersSectionWithTitle title='Saved Hotel Preferences'>
          <div className='flex justify-between'>
            <p className='type-body-1'>Apply my saved hotel preferences</p>
            <Toggle
              checked={isPreferencesApplied}
              onChange={handleFiltersToggle}
            />
          </div>
        </HotelResultsFiltersSectionWithTitle>
      ) : (
        <div className='flex justify-between'>
          <div className='bg-beach flex flex-col items-start gap-y-5 p-3'>
            <p className='type-body-1-medium'>
              Looks like you haven&apos;t filled out your search preferences
              yet!
            </p>
            <div className='flex'>
              <div className='flex flex-col gap-y-5'>
                <p className='type-body-1'>
                  Take 2 minutes to tell us more about your preferences, so that
                  we can easily apply them to your search results.
                </p>
                <Button
                  label='Take our quiz'
                  variant='outlined'
                  onClick={() => handleShowQuiz(true)}
                />
              </div>
              <div className='w-70%'>
                <Illustration name='jeepNoPics' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
