import { Input, Popover, Icon } from '@travelpass/design-system'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { PaxMixConfiguration } from './PaxMixConfiguration'
import { useExperienceProductDetails } from './useExperienceProductDetails'
import { useSearchParamPaxMixState } from './useSearchParamPaxMixState'

const PersonIcon = (
  <span className='c-new-forest'>
    <Icon name='personOutline' />
  </span>
)

export const PaxMixPopover = () => {
  const { data } = useExperienceProductDetails()
  const { paxMix, onUpdate, onClick: onDismiss } = useSearchParamPaxMixState()
  // @todo: generate a default paxMix based on ageBands, minTravelersPerBooking, requiresAdultForBooking
  // https://partnerresources.viator.com/travel-commerce/merchant/agebands-pax-mix/?source=specs

  if (!data) return null

  const totalGuests = Object.values(paxMix)?.reduce(
    (total, numberOfTravelers) => total + numberOfTravelers,
    0
  )

  return (
    <Popover
      trigger={
        <Input
          fullWidth
          readOnly
          label='Travelers'
          slotBefore={PersonIcon}
          value={`${totalGuests || 'No'} Traveler${totalGuests !== 1 ? 's' : ''}`}
        />
      }
      onDismiss={() => {
        pushDataToDataLayer('search_availability')
        onDismiss()
      }}
    >
      <div className='max-w-75 p-4 pb-0'>
        <PaxMixConfiguration
          ageBands={data.pricingInfo?.ageBands}
          maxTravelersPerBooking={
            data.bookingRequirements?.maxTravelersPerBooking
          }
          minTravelersPerBooking={
            data.bookingRequirements?.minTravelersPerBooking
          }
          paxMix={paxMix}
          requiresAdultForBooking={
            data.bookingRequirements.requiresAdultForBooking
          }
          onChange={onUpdate}
        />
      </div>
    </Popover>
  )
}
