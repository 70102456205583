import { useState } from 'react'
import { Button, useScreenQuery } from '@travelpass/design-system'
import type {
  ExperienceStandardItinerary,
  ExperienceItinerary,
  ExperienceHopOnHopOffItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { SectionHeader } from 'src/common/components'
import { ItinerarySwitch } from './ItinerarySwitch'
import { TimelineModal } from './TimelineModal'
import { ExperiencesItineraryOverview } from '../../common/components'
import {
  checkItineraryOverview,
  checkItineraryTimeline,
} from '../../common/components/utils'

interface TimelineProps {
  expanded: boolean
  itinerary: ExperienceItinerary
}

export const Timeline = ({ expanded, itinerary }: TimelineProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [showTimelineModal, setShowTimelineModal] = useState(false)
  const hasItineraryTimeline = checkItineraryTimeline(itinerary)

  const { itineraryItems: standardItineraryItems } =
    itinerary as ExperienceStandardItinerary
  const { routes } = itinerary as ExperienceHopOnHopOffItinerary
  const { pointsOfInterest, stops } = routes?.length === 1 && (routes[0] ?? {})
  const { unstructuredDescription, unstructuredItinerary } =
    (itinerary as ExperienceUnstructuredItinerary) ?? {}
  const hasStopsOrPointsOfInterest =
    stops?.length > 0 || pointsOfInterest?.length > 0
  const showTimeline = checkItineraryOverview(itinerary) || hasItineraryTimeline

  return (
    <>
      {showTimelineModal && (
        <TimelineModal
          itinerary={itinerary}
          onDismiss={() => setShowTimelineModal(false)}
        />
      )}
      {showTimeline && (
        <div className='flex flex-col gap-6 md:gap-12'>
          {!expanded && <SectionHeader title='What to Expect' />}
          <ExperiencesItineraryOverview
            unstructuredDescription={unstructuredDescription}
            unstructuredItinerary={unstructuredItinerary}
          />
          <ItinerarySwitch expanded={expanded} itinerary={itinerary} />
          {!expanded &&
            (routes?.length > 1 ||
              hasStopsOrPointsOfInterest ||
              standardItineraryItems?.length > 2) && (
              <div className='flex items-center justify-center md:justify-start'>
                <Button
                  aria-label='View more stops'
                  label='View More'
                  size={isMobileScreen ? 'small' : 'medium'}
                  variant='outlined'
                  onClick={() => setShowTimelineModal(true)}
                />
              </div>
            )}
        </div>
      )}
    </>
  )
}
