import { EventStatus, EventType } from 'src/__generated__/graphql'
import {
  initialDates,
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialAdminstrativeAreaLvl1,
  initialCountry,
  initialPostalCode,
} from 'src/constants/user'

import type { DrawerEvent, DrawerEventAddress } from './drawerTypes'

const defaultDrawerEventAddress: DrawerEventAddress = {
  addressLine1: null,
  city: null,
  country: initialCountry,
  googlePlaceId: 'Address failed to load',
  id: 'Address failed to load',
  lat: initialGeocoderLatitude.toString(),
  long: initialGeocoderLongitude.toString(),
  state: initialAdminstrativeAreaLvl1,
  zipcode: initialPostalCode,
}

const defaultDrawerEvent: DrawerEvent = {
  id: 'ID failed to load',
  address: defaultDrawerEventAddress,
  images: [],
  productId: null,
  name: 'Name failed to load',
  notes: '',
  startDate: initialDates[0],
  endDate: initialDates[1],
  startTime: 'None',
  endTime: 'None',
  status: EventStatus?.Na,
  timezone: 'Etc/UCT',
  type: EventType.Miscellaneous,
}

export { defaultDrawerEvent }
