export const localGuides = [
  {
    id: '1',
    owner: {
      name: 'Utah Jazz',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J8NKN9VJW1V6KKY0SPHTBJKA/profile_images/e5d30ad8-f547-42d8-8de6-32f420883d41',
    },
    title: 'Utah Jazz Home Game Guide',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01J8NKN9VJW1V6KKY0SPHTBJKA/guide_images/86d79a3e-0822-49ef-994e-08633610dbd6',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01J8NPDV84FD91?name=Utah-Jazz-Home-Game-Guide',
  },
  {
    id: '2',
    owner: {
      name: 'Colin',
      profileImage:
        'https://lh3.googleusercontent.com/a/ACg8ocKwfMxIzgA0OVd3nmFounrxnl5dEdpMV0SN1F7brJzjJmqMkDQ=s192-c',
    },
    title: "An Eater's Guide to Salt Lake City",
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GEQ67STJ06TZ88653H2BB7N8/guide_images/7282f7ce-f4da-4ebd-b800-5c34503b2fe3',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01HV7090P45Z805QX331VJ26KD',
  },
  {
    id: '3',
    owner: {
      name: 'Nick',
      profileImage:
        'https://lh3.googleusercontent.com/a/ACg8ocJmiicZJUag-BvmSImotW5ub-ImomTASXFgS3U08Mwx3w=s192-c',
    },
    title: 'Salt Lake City Bars',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GTAHHM0JQRJBQ6QHA5TGJQ51/guide_images/ae192d5e-8b88-4a8f-82b3-411abd58e8ce',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01HVSFZCW1DVVDSPNQ53J3QM8K?name=Salt-Lake-City-Bars',
  },
  {
    id: '4',
    owner: {
      name: 'Syd',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/profile_images/6ac2a80f-e6fa-4b9d-a8be-8351badbd4ae',
    },
    title: 'Best Casual Restaurants in SLC',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1P8VBYR4NMKHJVVAYFSKE8/guide_images/b65fd549-5389-4466-8c7b-131dc645dff1',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01HWAY3DK1HQYHRQRP43YNB56R?name=Best-Casual-Restaurants-in-SLC',
  },
  {
    id: '5',
    owner: {
      name: 'Sarah Dorich',
      profileImage:
        'https://lh3.googleusercontent.com/a/ALm5wu2uAriDp_6lcAAmkyewAkQJkQtj_RqRF5o189LTvQ=s192-c',
    },
    title: 'Kid & Dog Friendly Hikes Near SLC UT',
    image: 'https://static.travelpass.com/assets/trip-state-pics/utah.webp',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01J06XSW7NJD701W3GQM2W7S7E?name=Kid-&-Dog-Friendly-Hikes-Near-SLC-UT',
  },
  {
    id: '6',
    owner: {
      name: 'Madeline Watts',
      profileImage:
        'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PWQFH8WSNECFZDPGV510P/profile_images/6255e1b8-39bc-44ba-b98c-f8fad2d5ef66',
    },
    title: "Salt Lake's Best Trail Running",
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GF1PWQFH8WSNECFZDPGV510P/guide_images/64df229f-ee25-4340-b136-68cae9d5f071',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01J861AY7DZDH1?name=Salt-Lakes-Best-Trail-Running',
  },
  {
    id: '7',
    owner: {
      name: 'Matt',
      profileImage:
        'https://lh3.googleusercontent.com/a/ACg8ocLHgomN6peLNIM4cdhLHKwVPYQXM8lIlae79AVHr1AaSC4LOA-9=s192-c',
    },
    title: 'SLC Recommendations',
    image:
      'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-prd/images/usr_01GKSDSMKB39VGHGKMT7QPPHA5/guide_images/ad1eb74b-9d3b-49f0-bd68-fcdcf975afca',
    city: 'Salt Lake City',
    state: 'UT',
    url: 'https://www.travelpass.com/guides/01HWB0VTXN8PWFXW1YT6PQB220?name=SLC-Recommendations',
  },
]
