import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { TripDetailsExploreDrawerExperience } from 'src/pages/trips/components'
import { getProductTypesAsObject } from 'src/utils'
import { TripDetailsExploreDrawerHotel } from './TripDetailsExploreDrawerHotel'
import { TripDetailsExploreDrawerLoading } from './TripDetailsExploreDrawerLoading'
import type {
  ExploreExperience,
  ExploreExperiencesResult,
  ExploreHotelsResult,
} from '../../constants'
import { ExploreSearchParams } from '../../constants'

interface TripDetailsExploreDrawerProps {
  experiencesResult: ExploreExperiencesResult
  hotelsResult: ExploreHotelsResult
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripDetailsExploreDrawer = ({
  experiencesResult,
  hotelsResult,
  tripDetailsData,
}: TripDetailsExploreDrawerProps) => {
  const [searchParams] = useSearchParams()
  const activeExploreId = searchParams.get(ExploreSearchParams.activeExploreId)
  const { data: experiencesData, loading: isExperiencesLoading } =
    experiencesResult ?? {}
  const { data: hotelsData, loading: isHotelsLoading } = hotelsResult ?? {}
  const tripDetailsExploreExperiencesData =
    (experiencesData?.searchExperiences?.edges as ExploreExperience[]) ?? []
  const tripDetailsExploreHotelsData =
    hotelsData?.searchHotelsWithoutRates?.hotelSearchResults ?? []
  const { isExperience } = getProductTypesAsObject(
    searchParams.get(ExploreSearchParams.productType)
  )

  const constructedDrawer = () => {
    if (isExperiencesLoading || isHotelsLoading)
      return <TripDetailsExploreDrawerLoading />

    if (isExperience) {
      const experience = tripDetailsExploreExperiencesData?.find(
        ({ node }) => node?.id === activeExploreId
      )?.node ?? { id: activeExploreId }

      return (
        <TripDetailsExploreDrawerExperience
          experienceProduct={experience}
          tripId={tripDetailsData?.id}
        />
      )
    }

    return (
      <TripDetailsExploreDrawerHotel
        tripDetailsData={tripDetailsData}
        tripDetailsExploreHotelsData={tripDetailsExploreHotelsData}
      />
    )
  }

  return (
    <div className='flex max-h-[calc(100%-80px)] flex-col gap-10 overflow-y-auto px-6'>
      {constructedDrawer()}
    </div>
  )
}
