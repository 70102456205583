import { EventType, RefundType } from 'src/__generated__/graphql'
import { initialAdultGuests } from 'src/constants/user'
import type { StayEvent, StayEventBookedRoom } from './stayTypes'
import { defaultDrawerEvent } from '../../constants'

const defaultBookedRoom: StayEventBookedRoom = {
  allInTotal: '0.00',
  additionalFees: [],
  dueLater: '0.00',
  dueNow: '0.00',
  guests: initialAdultGuests,
  hotelFees: [],
  image: '',
  name: 'Name failed to load',
  nightCount: 1,
  refundType: RefundType.None,
  roomCount: 1,
  subtotal: '0.00',
  surchargeTotal: '0.00',
  surcharges: null,
}

const defaultStayEvent: StayEvent = {
  ...defaultDrawerEvent,
  amenities: [],
  bookingConfirmation: null,
  bookingDetailsUrl: null,
  bookingRoom: null,
  description: 'Description failed to load',
  externalUrl: null,
  hours: [],
  phone: null,
  productDetailsUrl: null,
  rating: 0,
  totalReviews: 0,
  stars: 0,
  type: EventType.Stay,
}

export { defaultStayEvent, defaultBookedRoom }
