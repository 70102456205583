import classNames from 'classnames'

interface VideoPreviewProps {
  embedVideoLink: string
  fullWidth?: boolean
  height?: string
}

export const VideoPreview = ({
  embedVideoLink,
  fullWidth,
  height = '205',
}: VideoPreviewProps) => {
  if (!embedVideoLink) return null
  const getYoutubeVideoIdFromLink = (link: string) => {
    if (link?.includes('embed')) return link?.split('embed/')[1]?.split('?')[0]
    if (link?.includes('watch')) return link?.split('v=')[1]?.split('&')[0]
    if (link?.includes('shorts'))
      return link?.split('shorts/')[1]?.split('?')[0]
    if (link?.includes('youtu.be'))
      return link?.split('youtu.be/')[1]?.split('?')[0]
    return ''
  }

  const validYoutubeLink = getYoutubeVideoIdFromLink(embedVideoLink)
  if (!validYoutubeLink) return null

  return (
    <div
      className={classNames(
        'rd-4 min-w-77 flex justify-center overflow-hidden align-middle',
        { 'lg:w-full': fullWidth }
      )}
    >
      <iframe
        allowFullScreen
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        className={classNames({ 'lg:w-full': fullWidth })}
        height={height}
        referrerPolicy='strict-origin-when-cross-origin'
        src={`https://www.youtube.com/embed/${validYoutubeLink}?controls=1&enablejsapi=1`}
        title='YouTube video player'
      ></iframe>
    </div>
  )
}
