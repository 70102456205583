import { Avatar, Divider, Icon, Modal } from '@travelpass/design-system'
import { profilePath } from 'src/constants'
import { constructAddress, getGuideDetailsUrl } from 'src/utils'

export const EventDetailModal = ({ event, onClose }) => {
  const accountHandle = event?.guide?.ownerProfile?.accountHandle
  const { id, name } = event?.guide ?? {}

  return (
    <Modal size='large' onDismiss={onClose}>
      <div className='flex flex-col gap-6 md:flex-row'>
        <div className='relative h-full min-h-40 w-full md:min-h-0 md:w-1/3'>
          <img
            alt='event'
            className='absolute inset-0 h-full w-full rounded-lg object-cover'
            src={event?.imageUrl}
          />
        </div>
        <div className='flex flex-col space-y-6 md:w-2/3'>
          <div className='space-y-2'>
            <h2 className='type-h4 font-bold'>{event?.name}</h2>
            <Divider />
            <div className='flex items-center text-gray-600'>
              <span className='type-small-text color-grey-800 flex items-center gap-2'>
                <Icon name='placeOutline' size='small' />
                {constructAddress({
                  addressFirstLine: event?.addresses[0]?.addressLine1,
                  addressSecondLine: event?.addresses[0]?.addressLine2,
                  city: event?.addresses[0]?.city,
                  hideCountry: true,
                  hideZipcode: true,
                  state: event?.addresses[0]?.state,
                })}
              </span>
            </div>
            {event?.guide?.name && (
              <a
                className='color-gray-800 flex w-fit items-center gap-2 no-underline hover:underline'
                href={getGuideDetailsUrl({ id, name })}
                rel='noreferrer'
                target='_blank'
              >
                <Icon name='viewList' size='small' />

                <span className='type-small-text color-grey-800'>
                  {`From "${event?.guide?.name}"`}
                </span>
              </a>
            )}
          </div>
          {!event?.notes && <div className='h-34.25'></div>}
          {event?.notes && (
            <div className='flex flex-1 flex-col gap-2.5 rounded-lg bg-gray-100 p-4'>
              <h3 className='c-forestLight type-body-2 flex items-center gap-1 font-semibold'>
                <Icon name='stickyNote2' size='small' />
                Note
              </h3>
              <p className='type-small-text-desktop color-grey-800 line-clamp-4 flex-1'>
                {event.notes}
              </p>
              {accountHandle && (
                <div className='flex items-center gap-2'>
                  <a
                    aria-label={`View profile of ${accountHandle}`}
                    className='b-0 of-hidden transition-opacity-200 focus:none cursor-pointer p-0 ease-linear hover:opacity-50 active:opacity-50'
                    href={`${profilePath}/${accountHandle}`}
                  >
                    <Avatar
                      border='none'
                      size='sm'
                      src={event?.guide?.ownerProfile?.profileImageUrl}
                    />
                  </a>
                  <div>
                    <a
                      aria-label={`View profile of ${accountHandle}`}
                      className='b-0 decoration-none of-hidden text-color-primary-forest transition-opacity-200 type-body-1 cursor-pointer bg-transparent p-0 not-italic ease-linear hover:opacity-50 active:opacity-50 md:truncate'
                      href={`${profilePath}/${accountHandle}`}
                      rel='noreferrer'
                      target='_BLANK'
                    >
                      {accountHandle}
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* For When we want to bring in the action buttons */}
      {/* <div className='mt-6 flex justify-end'>
          <button className='mr-2 rounded-lg bg-green-600 px-4 py-2 text-white'>
            Add To Trip
          </button>
          <button className='rounded-lg bg-green-600 px-4 py-2 text-white'>
            Add To Guide
          </button>
        </div> */}
    </Modal>
  )
}
