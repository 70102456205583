import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'

export const MardiGras = () => {
  const navigate = useNavigate()

  const onTripsClick = () => {
    const url = '/trips'
    navigate(url)
  }

  return (
    <>
      <Helmet
        canonicalUrl={canoncialUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <div className='wfull bg-center-right relative bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/1fbdfd0a-c07e-11ee-99a5-7e99de8c00ee)] bg-cover'>
        <div className='wfull md:from-newForest pt10 pb5 lg:pt20 lg:pb40 flex flex-col items-center justify-center bg-gradient-to-t from-white from-10% backdrop-blur-sm'>
          <header className='flex flex-col'>
            <h1 className='c-purpleDark md:c-white font-archivo font-not-oblique font-700 m0 tracking-5% flex flex-col text-center drop-shadow-md'>
              <span className='leading-34px mb--17px text-21px lg:leading-19 lg:mb--38px lg:text-12 c-white drop-shadow-lg'>
                Make The Most Of
              </span>
              <span className='leading-22 text-16 lg:leading-53 lg:text-39 drop-shadow-lg'>
                Mardi Gras
              </span>
            </h1>
            <div className='wfull h4px bg-orange mb10'></div>
          </header>
          <p className='type-subtitle-1 c-newForest md:c-white w90% max-w-950px ma text-center'>
            Say hello to the start of your ideal New Orleans itinerary, full of
            all the highlights you don’t want to miss, plus some insider info
            for all things Mardi Gras!
          </p>
        </div>
      </div>

      <div className='md:bg-newForest py10 w-full bg-white'>
        <PageLayoutContainer>
          <div className='flex flex-col flex-col-reverse md:flex-row md:items-center lg:items-start'>
            <div className='basis-1/2'>
              <h2 className='type-h2 c-purpleDark md:c-white'>
                Mardi Gras Parades + Parties
              </h2>
              <div className='w100px h2px bg-orange my4'></div>
              <p className='type-body-1 font-700 md:font-400 c-newForest md:c-white'>
                With so many Mardi Gras parades and festivals happening, you can
                easily find the type of party you’re looking for. For all
                events, costumes are more than welcome. Go all out, or find a
                little purple, green, or gold glitter to add to any outfit or
                look.
              </p>
              <ul className='px5 lg:pb20'>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Don’t miss the Krewe of Zulu parade on Fat Tuesday, at 8 a.m.
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  The biggest and loudest parties and parades tend to happen
                  around the French Quarter, Canal Street, and Bourbon Street,
                  which can be especially rowdy on Mardi Gras.
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Generally, Uptown parades and celebrations are on the tamer
                  side, like the more family-friendly parades on St.Charles
                  Avenue (hunt for a spot between First Street and Napoleon
                  Avenue).
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Insider Tip: Events happen for weeks leading up to Fat
                  Tuesday. Some say the best parades and biggest celebrations
                  actually happen the weekend before Fat Tuesday if you can
                  visit a little early!
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Insider Tip:{' '}
                  <a
                    className='font-900 c-purpleMedium md:c-orange'
                    href='https://www.mardigrasneworleans.com/parades/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Use this handy parade tracker
                  </a>{' '}
                  to get a schedule of when (and where) the parades are
                  happening.
                </li>
              </ul>
            </div>
            <div className='rounded-tl-8 rounded-br-8 py30 mb10 md:rounded-0 md:py0 md:mb0 basis-1/2 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/28600062-c082-11ee-997e-cef88c745288)] bg-cover bg-center md:bg-none'>
              <img
                alt='Mardi Gras Mask'
                className='md:w90% md:max-w-350px lg:max-w-90% ma lg:mr0 rounded-tl-20 rounded-br-20 z-3 shadow-2 relative hidden md:block lg:mt--20'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/28600062-c082-11ee-997e-cef88c745288'
              />
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='py10 w-full bg-white'>
        <PageLayoutContainer>
          <div className='flex flex-col md:flex-row md:items-center lg:items-start'>
            <div className='rounded-tl-8 rounded-br-8 py30 mb10 md:rounded-0 md:py0 md:mb0 basis-1/2 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e66aa44c-c08a-11ee-9ea5-8ad6ab45579b)] bg-cover bg-center md:bg-none'>
              <img
                alt='Beignets'
                className='md:w90% md:max-w-350px lg:max-w-90% ma lg:ml0 lg:mt--30 rounded-tl-20 rounded-br-20 z-3 shadow-2 relative hidden md:block'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e66aa44c-c08a-11ee-9ea5-8ad6ab45579b'
              />
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h2 c-purpleDark'>
                Grab A Bite + A Few Drinks
              </h2>
              <div className='w100px h2px bg-orange my4'></div>
              <p className='type-body-1 font-700 md:font-400 c-newForest'>
                Any trip to New Orleans should include a long list of food and
                drinks to try (the options here are amazing!). Here are a few
                tip-top recommendations, just keep in mind most eateries will be
                extremely busy during Mardi Gras. Best to make reservations
                where you can and plan on longer waiting times.
              </p>
              <ul className='px5 lg:pb20'>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  Snag a quintessential beignet breakfast at Cafe Du Monde.
                </li>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  Taste all the favorite local dishes in a{' '}
                  <a
                    className='font-900 c-purpleMedium'
                    href='https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzI4MjA2/New-Orleans-Food-Walking-Tour-of-the-French-Quarter-with-Small-Group-Option'
                  >
                    foodie tour of the French Quarter.
                  </a>
                </li>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  Head to Atchafalaya, for classic Louisiana dishes along with
                  stellar Bloody Marys.
                </li>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  Tour the Sazerac House museum and distillery for a little
                  history (and several tastings).
                </li>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  <a
                    className='font-900 c-purpleMedium'
                    href='https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzEzNDM4/Haunted-Pub-Crawl-in-New-Orleans'
                  >
                    Book a haunted pub crawl
                  </a>{' '}
                  and visit the spookiest bars in the city.
                </li>
                <li className='type-body-1 c-forest marker:c-orange py1'>
                  The Hurricane is “the drink” of New Orleans. Order one at Pat
                  O’Brien’s, which invented the famous fruity rum cocktail.
                </li>
              </ul>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='wfull md:bg-purpleDark py10 lg:pt10 lg:pb25 bg-white'>
        <PageLayoutContainer>
          <div className='flex flex-col flex-col-reverse md:flex-row md:items-center lg:items-start'>
            <div className='basis-1/2'>
              <h2 className='type-h2 c-purpleDark md:c-white'>
                The Best Of The Big Easy
              </h2>
              <div className='w100px h2px bg-orange my4'></div>
              <p className='type-body-1 font-700 md:font-400 c-newForest md:c-white'>
                If you’re headed to New Orleans for Mardi Gras, take advantage
                of the parties and celebrations, but don’t miss out on what else
                the city has to offer! Because a handful of restaurants, tours,
                and activities are closed on Fat Tuesday, consider staying an
                extra day or two to explore the city, post-holiday.
              </p>
              <ul className='px5 lg:pb20'>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  <a
                    className='font-900 c-purpleDark md:c-orange'
                    href='https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzIwNjA1/French-Quarter-Historical-Sights-and-Stories-Walking-Tour'
                  >
                    Tour the vibrant French Quarter
                  </a>
                  , full of sights, history, and local stories.
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  See the haunted side of NOLA with a{' '}
                  <a
                    className='font-900 c-purpleDark md:c-orange'
                    href='https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzI5NDgw/New-Orleans-Original-Ghost-%2CVoodoo-%26-Vampire-Tour-by-Locals'
                  >
                    vampire and voodoo ghost tour
                  </a>
                  .
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Get a closer look at the fantastic floats (and how they’re
                  made) at the Mardi Gras World Museum.
                </li>
                <li className='type-body-1 c-forest md:c-white marker:c-orange py1'>
                  Head to Frenchman’s Street, sometimes called the local version
                  of Bourbon Street, for plenty of live music.
                </li>
              </ul>
            </div>
            <div className='rounded-tl-8 rounded-br-8 py30 mb10 md:rounded-0 md:py0 md:mb0 basis-1/2 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/cd45c3de-c148-11ee-aaff-a66e7e53b1a2)] bg-cover bg-center md:bg-none'>
              <img
                alt='Saint Louis Cathedral and Jackson Square'
                className='md:w90% md:max-w-350px lg:max-w-90% ma lg:mr0 lg:mt--30 rounded-tl-20 rounded-br-20 z-3 shadow-2 relative hidden md:block'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/cd45c3de-c148-11ee-aaff-a66e7e53b1a2'
              />
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='py10 w-full bg-white'>
        <PageLayoutContainer>
          <div className='flex flex-col md:flex-row md:items-center lg:items-start'>
            <div className='rounded-tl-8 rounded-br-8 py30 mb10 md:rounded-0 md:py0 md:mb0 basis-1/2 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/7fe4105a-c152-11ee-b372-96ec5dae9401)] bg-cover bg-center md:bg-none'>
              <img
                alt='New Orleans, Louisiana'
                className='md:w90% md:max-w-350px lg:max-w-90% ma lg:ml0 lg:mt--30 rounded-tl-20 rounded-br-20 z-3 shadow-2 relative hidden md:block'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/7fe4105a-c152-11ee-b372-96ec5dae9401'
              />
            </div>
            <div className='basis-1/2'>
              <h2 className='type-h2 c-purpleDark'>
                Stay In This Part Of The City
              </h2>
              <div className='w100px h2px bg-orange my4'></div>
              <p className='type-body-1 font-400 c-newForest mb4'>
                Consider staying in the Central Business District (CBD), the
                Warehouse District, or on the outskirts of the French Quarter.
                That way, you’re still close to the parade action, and can
                easily head Uptown or Downtown. Skip out on Bourbon Street
                unless you plan to be out all night (or can sleep through almost
                anything).
              </p>
              <p className='type-body-1 font-400 c-forest'>
                We love the rooftop views at the{' '}
                <a
                  className='font-900 c-purpleDark md:c-orange'
                  href='https://www.travelpass.com/destinations/la/new-orleans/hotels/103108170/NOPSI-Hotel%2C-New-Orleans-New-Orleans-Louisiana'
                >
                  Nopsi Hotel
                </a>{' '}
                and the location of the{' '}
                <a
                  className='font-900 c-purpleDark md:c-orange'
                  href='https://www.travelpass.com/destinations/la/new-orleans/hotels/101855463/Le-Pavillon-New-Orleans-New-Orleans-Louisiana'
                >
                  Le Pavillion
                </a>{' '}
                and{' '}
                <a
                  className='font-900 c-purpleDark md:c-orange'
                  href='https://www.travelpass.com/destinations/la/new-orleans/hotels/101311598/Drury-Plaza-Hotel-New-Orleans-New-Orleans-Louisiana'
                >
                  Drury Plaza Hotels
                </a>
                . All of these top choices are within walking distance of some
                major parade routes and other NOLA attractions.
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <div className='wfull bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/9e0747f6-bfa8-11ee-ba19-be7ee76f3650)] bg-cover bg-center'>
        <div className='wfull py0 md:pb80 md:pt30 bg-gradient-to-b from-white backdrop-blur-sm md:to-40%'>
          <div className='wfull md:max-w-650px lg:max-w-850px ma px10 py20 md:p10 md:rounded-8 shadow-2 bg-white/20 backdrop-blur-sm md:bg-white/60'>
            <p className='type-subtitle-1 c-forest lg:line-height-8 font-400 text-center'>
              Are you ready to make this all happen? You can easily create a
              trip with these ideas (or add your own!) by following the button
              below.
            </p>
            <div className='w300px mxa mt10'>
              <Button
                fullWidth
                label='Create My Trip'
                size='large'
                onClick={onTripsClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const canoncialUrl =
  'https://www.travelpass.com/the-ultimate-new-orleans-mardi-gras-itinerary'
const metaDescription =
  'Say hello to the start of your ideal New Orleans itinerary, full of all the highlights you don’t want to miss, plus some insider info for all things Mardi Gras!'
const pageName = 'Make The Most Of Mardi Gras'
