import type { SetURLSearchParams } from 'react-router-dom'
import type { KeywordSearchArgs, KeywordSorts } from 'src/__generated__/graphql'
import { BOUNDING_BOX_PARAMS } from '../bounding-box/constants/boundingBoxParams'
import { COUNTRY_STATE_PARAMS } from '../country-state/constants/COUNTRY_STATE_PARAMS'
import { KEYWORD_SEARCH_PHRASE } from '../keyword-search/constants'

export const buildKeywordSearchArgs = (sp: URLSearchParams) => {
  let args: KeywordSearchArgs = {
    keywordPhrase: sp.get(KEYWORD_SEARCH_PHRASE),
  }

  if (sp.get('sort')) args.sort = sp.get('sort') as KeywordSorts

  if (sp.get('lat') && sp.get('lng')) {
    args.latLong = {
      latitude: Number(sp.get('lat')),
      longitude: Number(sp.get('lng')),
    }
  }

  if (sp.get('tags')) args.tagIds = sp.get('tags').split(',')
  if (sp.get('createdBy')) args.accountHandles = sp.get('createdBy').split(',')
  if (sp.get('miles')) args.radius = Number(sp.get('miles'))

  return args
}

export const getExploreFiltersFromURL = (sp: URLSearchParams) => {
  const miles = Number(sp.get('miles'))
  const lat = Number(sp.get('lat'))
  const lng = Number(sp.get('lng'))
  const locationTitle = sp.get('locationTitle')
  const keyword = sp.get(KEYWORD_SEARCH_PHRASE)
  const accountHandles = sp.get('createdBy')?.split(',') || []
  const tags = sp.get('tags')?.split(',') || []
  return {
    miles,
    lat,
    lng,
    locationTitle,
    keyword,
    accountHandles,
    tags,
  }
}

export const isThereExploreFiltersFromURL = (
  sp: URLSearchParams,
  pathname: string
) => {
  const filters = ['miles', 'lat', 'lng', 'locationTitle', 'createdBy', 'tags']

  if (!pathname.includes('/keyword')) filters.push(KEYWORD_SEARCH_PHRASE)

  return filters.some(filter => sp.has(filter))
}

export const clearGuideSearch = (setSP: SetURLSearchParams) =>
  setSP(sp => {
    sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
    sp.delete(BOUNDING_BOX_PARAMS.sw.lng)
    sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
    sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
    sp.delete(KEYWORD_SEARCH_PHRASE)
    sp.delete(COUNTRY_STATE_PARAMS.country)
    sp.delete(COUNTRY_STATE_PARAMS.state)
    sp.delete('locationTitle')
    sp.delete('location')
    sp.delete('title')
    return sp
  })
