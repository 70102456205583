import { useEffect } from 'react'
import { Dropdown, DropdownOption } from '@travelpass/design-system'
import type { ExperienceLanguageGuide } from 'src/__generated__/graphql'
import { useController } from './useExperienceBookingForm'

export const LanguageGuideField = ({
  languageGuides,
}: {
  languageGuides: ExperienceLanguageGuide[]
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'languageGuide',
    rules: { required: true },
  })

  // setting defaultValue in useController doesn't work, so we need to set it manually
  useEffect(() => {
    if (
      languageGuides.length &&
      (field.value === null || field.value === undefined)
    ) {
      setLanguageGuideFormValue(languageGuides[0])
    }
  }, [field.value, languageGuides])

  const currentOption = languageGuides?.find(
    guide =>
      guide.language === field.value?.language &&
      guide.type === field.value?.type
  )

  const value = currentOption
    ? `${currentOption.languageName} - ${currentOption.type}`
    : ''

  const langOptions = languageGuides?.map(
    ({ languageName, type }) => `${languageName} - ${type}`
  )

  const setLanguageGuideFormValue = ({
    language,
    type,
  }: ExperienceLanguageGuide) => {
    field.onChange({ language, type })
  }

  const onChangeLang = selectedLangOption => {
    const langIndex = langOptions.findIndex(
      langOption => langOption === selectedLangOption
    )

    if (langIndex !== -1) {
      setLanguageGuideFormValue(languageGuides[langIndex])
    } else {
      console.error(
        'Selected language option not found in product.languageGuides'
      )
    }
  }

  if (languageGuides?.length <= 1) return null

  return (
    <Dropdown
      fullWidth
      errorText={error?.message}
      id='languageGuide'
      label='Language'
      name={field.name}
      required={true}
      value={value}
      onBlur={field.onBlur}
      onChange={onChangeLang}
    >
      {langOptions.map(langOption => (
        <DropdownOption key={langOption} value={langOption}>
          {langOption}
        </DropdownOption>
      ))}
    </Dropdown>
  )
}
