import { EmptyState } from '@travelpass/design-system'

interface BookingsEmptyProps {
  subtitle?: string
  title?: string
}

export const BookingsEmpty = ({
  subtitle = 'No bookings were found during this time period',
  title = 'No results found',
}: BookingsEmptyProps) => (
  <div className={resultsEmptyCss} data-testid='BookingsEmpty'>
    <div className={resultsEmptyContainerCss}>
      <div className='w-125'>
        <EmptyState name='noSearchResult' />
      </div>
      <div className={resultsEmptyRowCss}>
        <h6 className={resultsEmptyTitleCss}>{title}</h6>
        <p className={resultsEmptyContentCss}>{subtitle}</p>
      </div>
    </div>
  </div>
)

const resultsEmptyContentCss = 'text-body-1 c-grey800'
const resultsEmptyRowCss = 'flex flex-col gap-4 grow'
const resultsEmptyTitleCss = 'text-subtitle-1 c-grey800'

const resultsEmptyCss =
  'h-116 flex flex-row items-center justify-center max-w-60 mx-auto pb-10 pt-5 text-center md:flex-row md:gap-2 md:max-w-125 md:py-13 md:text-left'

const resultsEmptyContainerCss =
  'flex flex-col gap-4 items-center justify-center'
