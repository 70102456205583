import type { HotelRoom } from './hotelRoomsTypes'

type HotelRoomImageSize = 'small' | 'large'

interface ConstructHotelRoomImages {
  hideDots?: boolean
  images?: HotelRoom['images']
  size?: HotelRoomImageSize
}

export const constructHotelRoomImages = ({
  hideDots = false,
  images,
  size = 'small',
}: ConstructHotelRoomImages) => {
  const updatedImages = images.map(({ caption, href }) => ({
    caption,
    href: size === 'small' ? href.replace('_z.jpg', '_b.jpg') : href,
  }))

  return hideDots ? updatedImages : updatedImages.splice(0, 5) // TODO: fix dot pagination
}
