import { updateExperiencesResultsUrl } from 'src/utils'

interface generateTagRouteArgs {
  urlTags: number[]
  tagId: number
  searchParams: URLSearchParams
}

const generateAddTagRoute = ({
  urlTags,
  tagId,
  searchParams,
}: generateTagRouteArgs) => {
  const updatedTags = [...urlTags, tagId]
  const addTagRoute = updateExperiencesResultsUrl({
    searchParams,
    filterValues: { tags: updatedTags },
  })

  return addTagRoute
}

const generateRemoveTagRoute = ({
  urlTags,
  tagId,
  searchParams,
}: generateTagRouteArgs) => {
  const removeTag = urlTags.filter(sTag => sTag !== tagId)

  const removeTagRoute = updateExperiencesResultsUrl({
    searchParams,
    filterValues: { tags: removeTag },
  })

  return removeTagRoute
}

export { generateAddTagRoute, generateRemoveTagRoute }
