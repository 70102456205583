import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useState } from 'react'
import type { TripMapPoint } from 'src/pages/trips/types'

export const TripDetailsMapPointsStore = createContext<{
  points: TripMapPoint[]
  setPoints: Dispatch<SetStateAction<TripMapPoint[]>>
}>({
  points: [],
  setPoints: () => {},
})
TripDetailsMapPointsStore.displayName = 'TripDetailsMapPointsStore'

export const TripDetailsMapPointsStoreProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [points, setPoints] = useState<TripMapPoint[]>([])

  return (
    <TripDetailsMapPointsStore.Provider
      value={{
        points,
        setPoints,
      }}
    >
      {children}
    </TripDetailsMapPointsStore.Provider>
  )
}
