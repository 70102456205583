import classNames from 'classnames'

interface StyledMarkerProps {
  isHovered?: boolean
  isSelected: boolean
}

export const StyledPlaceMarker = ({
  isHovered = false,
  isSelected = false,
}: StyledMarkerProps): JSX.Element => (
  <svg
    className={classNames({
      'scale-150': isHovered || isSelected,
    })}
    height='34'
    preserveAspectRatio='xMinYMin meet'
    viewBox='0 0 34 40'
    width='40'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#styled-place-marker-filter)'>
      <path
        clipRule='evenodd'
        d='M16 2.332c-7 0-13.334 5.367-13.334 13.667 0 5.3 4.083 11.533 12.233 18.716.634.55 1.584.55 2.217 0C25.249 27.532 29.333 21.3 29.333 16c0-8.3-6.334-13.667-13.334-13.667Zm0 18.333a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z'
        fill='#66D210'
        fillRule='evenodd'
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
        height='38.796'
        id='styled-place-marker-filter'
        width='32.667'
        x='.666'
        y='.332'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx='1' dy='1' />
        <feGaussianBlur stdDeviation='1.5' />
        <feColorMatrix values='0 0 0 0 0.141176 0 0 0 0 0.145098 0 0 0 0 0.184314 0 0 0 0.25 0' />
        <feBlend
          in2='BackgroundImageFix'
          mode='multiply'
          result='effect1_dropShadow_1_4'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1_4'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)
