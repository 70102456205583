import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { useExperienceDetailsSearchParams } from './useExperienceDetailsSearchParams'

const GET_EXPERIENCE_AVAILABILITY_SCHEDULE = gql(`
  query ExperienceAvailabilitySchedule($startDate: Date!, $endDate: Date!, $nodeId: ID!) {
    node(id: $nodeId) {
      ... on ExperienceProduct {
        id
        availabilitySchedule {
          productCode
          pricesPerDay(startDate: $startDate, endDate: $endDate) {
            date
            offeredThisDay
            fromPriceBeforeDiscount {
              amount
            }
          }
        }
      }
    }
  }
`)

export const useGetExperienceAvailabilitySchedule = () => {
  const { experienceId } = useParams()
  const { startDate, endDate } = useExperienceDetailsSearchParams()

  const { data, loading, error } = useQuery(
    GET_EXPERIENCE_AVAILABILITY_SCHEDULE,
    {
      variables: {
        nodeId: experienceId,
        startDate,
        endDate,
      },
      skip: !experienceId || !startDate || !endDate,
    }
  )

  const availabilitySchedule =
    data?.node?.__typename === 'ExperienceProduct'
      ? data.node.availabilitySchedule
      : null

  return {
    data: availabilitySchedule,
    loading,
    error,
  }
}
