import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import { HotelConfirmationSummary } from 'src/common/components/HotelConfirmationSummary/HotelConfirmationSummary'
import { BookingConfirmationSummarySurveyModal } from './BookingConfirmationSummarySurveyModal'
import type {
  BookingConfirmationHotel,
  BookingConfirmationNode,
} from './bookingConfirmationTypes'

interface BookingConfirmationSummaryProps {
  bookingConfirmationData: BookingConfirmationNode
  bookingConfirmationHotelData: BookingConfirmationHotel
}

export const BookingConfirmationSummary = ({
  bookingConfirmationData,
  bookingConfirmationHotelData,
}: BookingConfirmationSummaryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const {
    arrival,
    bookingDetails,
    departure,
    email,
    id: bookingId,
    externalConfirmationId,
  } = bookingConfirmationData ?? {}
  const {
    imageLinks,
    name: hotelName,
    id: hotelId,
  } = bookingConfirmationHotelData ?? {}
  const { hotelDetails, occupancies } = bookingDetails ?? {}
  const { name: roomName } = hotelDetails?.room ?? {}

  return (
    <>
      <div className='flex flex-col gap-11 md:gap-16'>
        <div className='flex flex-col items-center text-center'>
          <div className='w-250px'>
            <EmptyState name='done' />
          </div>
          <div className='flex flex-col gap-5'>
            <h1 className='text-h3'>Booking Confirmed!</h1>
            <p className='text-subtitle-2'>
              Receipt and booking details sent to: {email}
            </p>
          </div>
        </div>
        <div className='rd-5 bg-warmGrey lg:px-25 px-4 py-6 md:p-10 lg:py-12'>
          <HotelConfirmationSummary
            arrival={arrival}
            bookingId={bookingId}
            departure={departure}
            externalConfirmationId={externalConfirmationId}
            hotelName={hotelName}
            imageLinks={imageLinks}
            occupancies={occupancies}
            roomName={roomName}
          />
        </div>
      </div>
      {isModalOpen && (
        <BookingConfirmationSummarySurveyModal
          bookingConfirmationData={bookingConfirmationData}
          onDismiss={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}
