import type { ExperienceHopOnHopOffRoute } from 'src/__generated__/graphql'

interface ExperiencesItineraryTimelineHopOnHopOffHeaderProps {
  route: ExperienceHopOnHopOffRoute
}

export const ExperiencesItineraryTimelineHopOnHopOffHeader = ({
  route,
}: ExperiencesItineraryTimelineHopOnHopOffHeaderProps) => {
  const { duration, name, operatingSchedule, pointsOfInterest, stops } =
    route ?? {}
  const { readableDurationRange } = duration ?? {}

  const constructedPointsOfInterest = () => {
    const pointsOfInterestLength = pointsOfInterest?.length

    if (pointsOfInterestLength)
      return (
        <p className='color-grey-700'>
          {pointsOfInterestLength}{' '}
          {pointsOfInterestLength > 1
            ? 'Points of Interest'
            : 'Point of Interest'}
        </p>
      )
  }

  const constructedStops = () => {
    const stopsLength = stops?.length

    if (stopsLength)
      return (
        <p className='color-grey-700'>
          {stopsLength} {stopsLength > 1 ? 'Stops' : 'Stop'}
        </p>
      )
  }

  return (
    <div className={experiencesItineraryTimelineHopOnHopOffRouteHeaderCss}>
      <p className={experiencesItineraryTimelineHopOnHopOffRouteHeaderTitleCss}>
        {name}
      </p>
      {constructedStops()}
      {constructedPointsOfInterest()}
      {!!readableDurationRange && (
        <p className='color-grey-800'>
          <span className='font-500'>Duration:</span>{' '}
          <span>{readableDurationRange}</span>
        </p>
      )}
      <p className='color-grey-800'>
        <span className='font-500'>Operating Schedule:</span>{' '}
        <span>{operatingSchedule}</span>
      </p>
    </div>
  )
}

const experiencesItineraryTimelineHopOnHopOffRouteHeaderCss =
  'flex flex-col gap-2 text-body-1'

const experiencesItineraryTimelineHopOnHopOffRouteHeaderTitleCss =
  'bg-canyon color-white p-2 rounded-2 w-fit'
