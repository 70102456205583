import { useParams, useSearchParams } from 'react-router-dom'
import type { Trip } from 'src/__generated__/graphql'
import { TripSearchParams } from 'src/pages/trips/constants'
import { useUpdateEvent } from 'src/pages/trips/hooks'
import { DrawerEditForm } from './DrawerEditForm'
import type { DrawerEditKeys, DrawerEditValues } from './drawerEditTypes'
import { prepDrawerEditForEventUpdate } from './drawerEditUtils'
import type { DrawerEvent } from '../../constants'

export const DrawerEdit = ({
  data,
  hideButtons = false,
  tripData,
  visibleFields = [
    'customBooked',
    'dates',
    'endLocation',
    'name',
    'notes',
    'startLocation',
    'times',
  ],
}: {
  data: DrawerEvent | null
  hideButtons?: boolean
  tripData: Trip | null
  visibleFields?: DrawerEditKeys[]
}) => {
  const [searchParams] = useSearchParams()
  const { tripId } = useParams()
  const triggerVariant = `Trip ${searchParams.get(TripSearchParams.eventType)}DrawerEdit SaveButton`
  const { updateEvent } = useUpdateEvent({
    triggerVariant: triggerVariant,
    tripId: tripData?.id || tripId || 'Error finding tripId',
  })

  const onSubmit = (formValues: DrawerEditValues) => {
    if (data?.id) {
      const properTypes = prepDrawerEditForEventUpdate({
        data,
        formValues,
        modifibleFields: visibleFields,
      })
      updateEvent(properTypes)
    }
  }

  return (
    <DrawerEditForm
      data={data}
      hideButtons={hideButtons}
      tripData={tripData}
      visibleFields={visibleFields}
      onSave={onSubmit}
    />
  )
}
