/**
 * this mapping is the "harded coded logic" for mapping/grouping conditional booking questions to their respective dependent question
 *
 * https://docs.viator.com/partner-api/technical/#section/Booking-concepts/Booking-questions
 * https://partnerresources.viator.com/travel-commerce/merchant/implementing-booking-questions/#conditional-booking-questions
 */
export const conditionalQuestionsMap = {
  TRANSFER_ARRIVAL_MODE: {
    AIR: [
      'TRANSFER_AIR_ARRIVAL_AIRLINE',
      'TRANSFER_AIR_ARRIVAL_FLIGHT_NO',
      'TRANSFER_ARRIVAL_TIME',
      'TRANSFER_ARRIVAL_DROP_OFF',
      'PICKUP_POINT',
    ],
    RAIL: [
      'TRANSFER_RAIL_ARRIVAL_LINE',
      'TRANSFER_RAIL_ARRIVAL_STATION',
      'TRANSFER_ARRIVAL_TIME',
      'TRANSFER_ARRIVAL_DROP_OFF',
    ],
    SEA: [
      'TRANSFER_PORT_ARRIVAL_TIME',
      'TRANSFER_PORT_CRUISE_SHIP',
      'TRANSFER_ARRIVAL_DROP_OFF',
      'PICKUP_POINT',
    ],
    OTHER: ['PICKUP_POINT'],
  },
  TRANSFER_DEPARTURE_MODE: {
    AIR: [
      'TRANSFER_AIR_DEPARTURE_AIRLINE',
      'TRANSFER_AIR_DEPARTURE_FLIGHT_NO',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP',
    ],
    RAIL: [
      'TRANSFER_RAIL_DEPARTURE_LINE',
      'TRANSFER_RAIL_DEPARTURE_STATION',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP',
    ],
    SEA: [
      'TRANSFER_PORT_CRUISE_SHIP',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_PORT_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP',
    ],
  },
}
