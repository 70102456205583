import { Checkbox } from '@travelpass/design-system'
import type { FilterBy } from 'src/__generated__/graphql'
import mainAmenities from 'src/common/MainAmenities'

interface HotelResultsFiltersAmenitiesProps {
  filters: FilterBy
  onChange?(updatedFilters: FilterBy): void
}

export const HotelResultsFiltersAmenities = ({
  filters,
  onChange = () => {},
}: HotelResultsFiltersAmenitiesProps) => {
  const onClick = (value: string) => {
    const updatedFilters = { ...filters }

    if (updatedFilters?.[value]) delete updatedFilters?.[value]
    else updatedFilters[value] = true

    onChange(updatedFilters)
  }

  return (
    <div className='lg:grid lg:grid-cols-2 lg:gap-1'>
      {mainAmenities.map(({ label, id }) => (
        <Checkbox
          key={id}
          isChecked={!!filters?.[id]}
          label={label}
          value={id}
          onClick={() => onClick(id)}
        />
      ))}
    </div>
  )
}
