import type { ReactNode } from 'react'
import { PageLayoutContainer } from 'src/common/components'

interface SupportContainerProps {
  children: ReactNode
}

export const SupportContainer = ({ children }: SupportContainerProps) => (
  <PageLayoutContainer size='none'>
    <div className='max-w-249 m-a'>{children}</div>
  </PageLayoutContainer>
)
