import { useSearchParams } from 'react-router-dom'
import { MarkerWrapper } from 'src/common/components/Map'
import { stringToNumber } from 'src/utils'
import { HotelResultsSearchParams } from '../hotelResultsConstants'
import type { HotelResultsData } from '../hotelResultsTypes'

interface HotelResultsMapMarkerLocationProps {
  hotelResultsFeaturedData: HotelResultsData
}

export const HotelResultsMapMarkerLocation = ({
  hotelResultsFeaturedData = [],
}: HotelResultsMapMarkerLocationProps) => {
  const [searchParams] = useSearchParams()
  const latitude = searchParams.get(HotelResultsSearchParams.latitude)
  const longitude = searchParams.get(HotelResultsSearchParams.longitude)
  const location = searchParams.get(HotelResultsSearchParams.location)
  const mapBounds = searchParams.get(HotelResultsSearchParams.mapBounds)
  const { isLodging } =
    hotelResultsFeaturedData?.find(({ isLodging }) => isLodging) ?? {}
  const showMarker = !isLodging && location !== 'Map area' && !!mapBounds

  return (
    showMarker && (
      <MarkerWrapper
        isHovered
        position={{
          lat: stringToNumber(latitude),
          lng: stringToNumber(longitude),
        }}
      >
        <svg
          data-testid='HotelResultsMapMarkerLocation'
          fill='none'
          height='30'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            d='M12 0C5.7 0 0 4.83 0 12.3c0 4.77 3.675 10.38 11.01 16.845.57.495 1.425.495 1.995 0C20.325 22.68 24 17.07 24 12.3 24 4.83 18.3 0 12 0Zm0 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z'
            fill='#35343C'
            fillRule='evenodd'
          />
        </svg>
      </MarkerWrapper>
    )
  )
}
