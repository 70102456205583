const FAQ_PAGE_SIZE = 5
const FIRST_PAGE = 1
const FUSE_OPTIONS = {
  keys: ['title'],
  shouldSort: false,
  threshold: 0.5,
}
const MY_TRIPS_LINK = '/find-my-booking'
const SEARCH_LINK = '/'

export { FAQ_PAGE_SIZE, FIRST_PAGE, FUSE_OPTIONS, MY_TRIPS_LINK, SEARCH_LINK }
