import type { MutableRefObject } from 'react'
import { useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  MINI_IMAGE_SIZE_MOBILE,
  MINI_IMAGE_SIZE_DESKTOP,
} from 'src/pages/hotels/details/constants'

interface HotelModalImagesScrollProps {
  images: string[]
  slideIndex: number
  scrollContainerRef: MutableRefObject<HTMLDivElement>
  onClick: (index: number) => void
  hotelName?: string
}

export const HotelModalImagesScroll = ({
  images,
  slideIndex,
  scrollContainerRef,
  onClick,
  hotelName = '',
}: HotelModalImagesScrollProps) => {
  const { isMobileScreen } = useScreenQuery()
  const handleScroll = e => {
    const delta = Math.max(
      -1,
      Math.min(1, e.nativeEvent.deltaY || -e.nativeEvent.wheelDelta)
    )
    const container = scrollContainerRef.current
    container.scrollLeft += delta * 80
  }

  const imgSize = isMobileScreen
    ? MINI_IMAGE_SIZE_MOBILE.split('x')
    : MINI_IMAGE_SIZE_DESKTOP.split('x')

  return (
    <div
      className='w-100% md:w-45 md:display-unset ws-nowrap flex gap-3 overflow-x-auto md:h-auto md:overflow-y-auto'
      ref={scrollContainerRef}
      onWheel={handleScroll}
    >
      {images?.map((image, index) => {
        const imageAlt = `${hotelName} hero modal grid image ${index + 1}`
        return (
          <div key={imageAlt} className='flex-column flex justify-center py-1'>
            <button
              aria-label='Show hotel photo'
              className='m-0 cursor-pointer border-none bg-transparent p-0 outline-none'
              onClick={() => onClick(index)}
            >
              <img
                alt={imageAlt}
                className={classNames(
                  'rounded-2 bg-gray-200 transition-all duration-300',
                  {
                    'border-3 border-forest border-solid opacity-100':
                      index == slideIndex,
                  },
                  { 'opacity-70': index != slideIndex }
                )}
                draggable='false'
                height={imgSize[1]}
                id={`wheel-images-${index}`}
                loading='lazy'
                src={image}
                width={imgSize[0]}
              />
            </button>
          </div>
        )
      })}
    </div>
  )
}
