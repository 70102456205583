import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const DELETE_COLLECTION = gql(`
    mutation DeleteCollection($input: DeleteCollectionInput!){
        deleteCollection(input: $input){
            collection{
                id
                name
                isDefault
                latitude
                longitude
                locationName
                user {
                    id
                    collections(first: 1){
                        totalCount
                    }
                }
            }
        }
    }    
`)

export const useDeleteCollection = () => useMutation(DELETE_COLLECTION)
