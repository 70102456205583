import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'

export const GuideDraftEventCardLoading = () => (
  <section
    className={classNames('b-2 b-solid rounded-4 flex w-full flex-row gap-2', {
      'b-forestLight bg-mintLight': false,
      'b-grey-300': true,
    })}
  >
    <div className='min-w-25 w-25 md:min-w-35 md:w-35 pointer-events-none relative'>
      <Skeleton variant='rounded' />
    </div>
    <div className='p-x-3.5 p-y-3.5 max-w-[calc(100%-100px)] grow space-y-2 md:max-w-[calc(100%-140px)]'>
      <div className='space-y-1'>
        <div className='flex w-full flex-row items-center'>
          <div className='h-5 w-20'>
            <Skeleton />
          </div>
        </div>
        <div className='c-orange flex flex-row items-center gap-1'>
          <div className='h-4 w-4'>
            <Skeleton variant='circular' />
          </div>
          <div className='h-3'>
            <Skeleton />
          </div>
        </div>
      </div>
      <div className='min-h-7.5'>
        <div className='p-x-1 flex flex-row gap-2'>
          <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-5 min-h-5 w-5 min-w-5 cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
            <Skeleton variant='circular' />
          </div>
          <div className='w-25 h-2.5 lg:h-4'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </section>
)
