import { useParams } from 'react-router-dom'
import {
  ExperienceCancellationType,
  type AgeBandTypeEnum,
  type PassengerAgeMix,
} from 'src/__generated__/graphql'
import { getTracker } from 'src/utils'
import type { PaxMixState } from './PaxMixState'
import { useExperienceDetailsSearchParams } from './useExperienceDetailsSearchParams'
import { useExperienceProductDetails } from './useExperienceProductDetails'
import { useGetExperienceAvailabilityCheck } from './useGetExperienceAvailabilityCheck'

const toPaxMix = (guests: string): PassengerAgeMix[] => {
  const guestsObj: PaxMixState = JSON.parse(guests)
  const paxMix = Object.entries(guestsObj).map(([key, value]) => ({
    ageBand: key as AgeBandTypeEnum,
    numberOfTravelers: value,
  }))
  return paxMix
}

export const useAvailabilityOptionsList = () => {
  const { experienceId } = useParams()
  const { guests, travelDate } = useExperienceDetailsSearchParams()
  const { data: experienceDetails } = useExperienceProductDetails()
  // @todo: validate travelDate before using to get availability
  // @todo: validate travelDate before rendering modal

  const {
    data: availabilityCheck,
    loading,
    error,
  } = useGetExperienceAvailabilityCheck({
    paxMix: guests && toPaxMix(guests),
    productCode: experienceDetails?.productCode,
    tracker: getTracker(experienceId), // replace with actual tracker
    travelDate,
  })

  const pricing = experienceDetails?.pricingInfo
  const hasFreeCancellation =
    experienceDetails?.cancellationPolicy.type ===
    ExperienceCancellationType.Full
  // @todo: ask if backend can flatten the extraneous availailityCheck.availabilityCheck nesting
  const groupedBookableItems =
    availabilityCheck?.availabilityCheck?.groupedBookableItems

  const getOptionDetails = (productOptionCode: string) => {
    const option = productOptionsLookup[productOptionCode]
    const title = option?.title ?? experienceDetails?.title
    const description = option?.description ?? experienceDetails?.description
    return { title, description }
  }

  const allItemsUnavailable = groupedBookableItems?.every(({ bookableItems }) =>
    bookableItems?.every(({ available }) => !available)
  )

  // lookup helper to find the productOption by productOptionCode
  const productOptionsLookup = experienceDetails?.productOptions
    ? Object.fromEntries(
        experienceDetails.productOptions.map(productOption => [
          productOption.productOptionCode,
          productOption,
        ])
      )
    : {}

  return {
    allItemsUnavailable,
    error,
    groupedBookableItems,
    getOptionDetails,
    loading,
    maxTravelersPerBooking:
      experienceDetails?.bookingRequirements?.maxTravelersPerBooking,
    hasFreeCancellation,
    pricing,
  }
}
