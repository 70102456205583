import type { Dispatch, SetStateAction } from 'react'
import isEmpty from 'lodash.isempty'
import type { GetTripEventsQueryInTripsQuery } from 'src/__generated__/graphql'
import { TimelineDo } from './TimelineDo'
import { TimelineStay } from './TimelineStay'
import { type Tabs } from '../AddEvents/AddEvent/AddEvent'

export const TimelineEvents = ({
  dailyEventBreakdown,
  activeTabIndex,
  selectedEventId,
  timezone,
  setSelectedEventId,
  setScrollToEventId,
  scrollToEventId,
  onClick,
  onHover,
}: {
  dailyEventBreakdown?: GetTripEventsQueryInTripsQuery['getTrip']['dailyEventBreakdown']
  activeTabIndex: number
  selectedEventId: string | null
  timezone: string
  setSelectedEventId: (selectedEventId: string) => void
  setScrollToEventId: Dispatch<SetStateAction<string | null>>
  scrollToEventId: string | null
  onClick: (tab: Tabs) => void
  onHover: (eventId?: string) => void
}) => {
  const currentDay = !isEmpty(dailyEventBreakdown)
    ? dailyEventBreakdown?.scheduled?.[activeTabIndex]?.events
    : []

  const currentDayEvents =
    currentDay?.filter(event => !event?.isDeleted && event?.type !== 'STAY') ??
    []

  const currentDayStays =
    currentDay?.filter(event => !event?.isDeleted && event?.type === 'STAY') ??
    []

  return (
    <div className='flex flex-col gap-5'>
      <TimelineStay
        currentDayStay={currentDayStays}
        scrollToEventId={scrollToEventId}
        selectedEventId={selectedEventId}
        setScrollToEventId={setScrollToEventId}
        setSelectedEventId={setSelectedEventId}
        timezone={timezone}
        onClick={() => onClick('stays')}
        onHover={onHover}
      />
      <TimelineDo
        activeTabIndex={activeTabIndex}
        currentDayEvents={currentDayEvents}
        scrollToEventId={scrollToEventId}
        selectedEventId={selectedEventId}
        setScrollToEventId={setScrollToEventId}
        setSelectedEventId={setSelectedEventId}
        timezone={timezone}
        onClick={() => onClick('experiences')}
        onHover={onHover}
      />
    </div>
  )
}
