import type { ReactNode } from 'react'

export const HotelResultsCardFeaturedContentContainer = ({
  children,
}: {
  children: ReactNode
}) => (
  <div className='absolute bottom-0 left-0 right-0 space-y-3 bg-gradient-to-t from-black/60 to-transparent px-3 py-5'>
    {children}
  </div>
)
